import React, { useCallback } from "react";
import { createStyles, Snackbar, SnackbarContent, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import IconButton from "@material-ui/core/IconButton";
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon
} from "@material-ui/icons";
import classNames from "classnames";
import { amber, green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { SnackbarContentProps } from "@material-ui/core/SnackbarContent";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar, getSnackbar } from "../modules/ui";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: green[600]
    },
    error: {
      backgroundColor: theme.palette.error.dark
    },
    info: {
      backgroundColor: theme.palette.primary.dark
    },
    warning: {
      backgroundColor: amber[700]
    },
    icon: {
      fontSize: 20
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing.unit
    },
    message: {
      display: "flex",
      alignItems: "center"
    }
  });

export default withStyles(styles)(function SnackBarNotification(
  props: WithStyles<typeof styles> & SnackbarContentProps
): JSX.Element {
  const { variant, message, show } = useSelector(getSnackbar);
  const dispatch = useDispatch();
  const handleClose = useCallback(() => dispatch(closeSnackbar()), [dispatch]);
  const { classes, ...other } = props;
  const Icon = variantIcon[variant];
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      className="snackbar"
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classNames(classes[variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        {...other}
      />
    </Snackbar>
  );
});

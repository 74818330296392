import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import colors from "../../theme/colors";

interface Props {
  onClick: () => void;
  text?: string;
}

const AddButton = ({ onClick, text }: Props): JSX.Element => (
  <Button
    variant="contained"
    style={{
      margin: 10,
      backgroundColor: colors.green,
      color: colors.white
    }}
    mini
    onClick={(): void => onClick()}
  >
    <FontAwesomeIcon icon={faPlus} style={{ margin: 5 }} />
    {text || "Add"}
  </Button>
);

export default AddButton;

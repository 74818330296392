import { withStyles } from "@material-ui/styles";
import { merge } from "lodash";

import {
  createStyles,
  ExpansionPanelDetails,
  Grid,
  WithStyles,
  Switch,
  FormControlLabel,
  Typography
} from "@material-ui/core";
import React, { ChangeEventHandler, ReactNode, useState } from "react";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RCSlider from "rc-slider";

import ExpansionPanelSummary from "../../../../../components/ExpansionPanelSummary";
import DatePicker from "../../../../../components/inputs/DatePicker";
import CustomExpansionPanel from "../../../../../components/ExpansionPanel";

const styles = createStyles({
  root: {
    width: "100%"
  }
});

interface NewEvent {
  target: {
    name: string;
    value: string;
  };
}

interface Props extends WithStyles<typeof styles> {
  datetime_end: string;
  datetime_start: string;
  handleSearchFilter: (event: NewEvent | NewEvent[]) => void;
  onDateStartChange: ChangeEventHandler<HTMLInputElement>;
  onDateEndChange: ChangeEventHandler<HTMLInputElement>;
  expanded?: boolean;
}

const marks = {
  0: <Typography>0+</Typography>,
  15: <Typography>15+</Typography>,
  30: <Typography>30+</Typography>,
  40: <Typography>40+</Typography>,
  50: <Typography>50+</Typography>,
  60: <Typography>60+</Typography>,
  90: <Typography>90+</Typography>,
  100: <Typography>∞</Typography>
};

const Filters = withStyles(styles)(
  ({
    classes,
    handleSearchFilter,
    datetime_end,
    datetime_start,
    onDateStartChange,
    onDateEndChange,
    expanded
  }: Props): JSX.Element => {
    return (
      <div className={classes.root}>
        <CustomExpansionPanel expanded={expanded}>
          <ExpansionPanelSummary
            expandIcon={<FontAwesomeIcon size="xs" icon={faSlidersH} />}
          />
          <ExpansionPanelDetails>
            <Grid container spacing={32} direction="row">
              <Grid item lg={4}>
                <DatePicker
                  fullWidth
                  name="datetime_start"
                  label="Followup Start Date"
                  value={datetime_start}
                  onChange={(event: any): void => {
                    onDateStartChange(event.toString());
                    const newEvent = {
                      target: {
                        name: "followup_datetime_start",
                        value: event.toString()
                      }
                    };

                    handleSearchFilter(merge({}, newEvent));
                  }}
                />
              </Grid>
              <Grid item lg={4}>
                <DatePicker
                  fullWidth
                  name="datetime_end"
                  label="Followup End Date"
                  value={datetime_end}
                  onChange={(event: any): void => {
                    onDateEndChange(event.toString());
                    const newEvent = {
                      target: {
                        name: "followup_datetime_end",
                        value: event.toString()
                      }
                    };
                    handleSearchFilter(newEvent);
                  }}
                />
              </Grid>
              <Grid item lg={4}>
                <Typography style={{ paddingBottom: 20 }}>Aging</Typography>
                <RCSlider.Range
                  step={null}
                  min={0}
                  max={100}
                  marks={marks}
                  defaultValue={[0, 100]}
                  onAfterChange={(value: any): void => {
                    if (value[1] === value[0]) {
                      return handleSearchFilter({
                        target: {
                          name: "receivable_age_gte",
                          value: value[1] === 100 ? 10000 : value[1]
                        }
                      });
                    }
                    const agingGte = {
                      target: {
                        name: "receivable_age_lte",
                        value: value[1] === 100 ? 10000 : value[1]
                      }
                    };
                    const agingLte = {
                      target: {
                        name: "receivable_age_gte",
                        value: value[0]
                      }
                    };
                    handleSearchFilter([agingLte, agingGte]);
                  }}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </CustomExpansionPanel>
      </div>
    );
  }
);

export default Filters;

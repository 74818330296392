const equipmentTypes = [
  {
    "value": "V",
    "name": "Dry Van (V)"
  },
  {
    "value": "F",
    "name": "Flatbed (F)"
  },
  {
    "value": "R",
    "name": "Reefer (R)"
  },
  {
    "value": "AC",
    "name": "Auto Carrier (AC)"
  },
  {
    "value": "BT",
    "name": "B Train Flatbed (BT)"
  },
  {
    "value": "CV",
    "name": "Cargo Van (CV)"
  },
  {
    "value": "VC",
    "name": "Curtain Van (VC)"
  },
  {
    "value": "DD",
    "name": "Double Drop (DD)"
  },
  {
    "value": "DR",
    "name": "Drayage (DR)"
  },
  {
    "value": "DT",
    "name": "Dump Trailer (DT)"
  },
  {
    "value": "FZ",
    "name": "Flatbed Hazmat (FZ)"
  },
  {
    "value": "MX",
    "name": "Flatbed Maxi (MX)"
  },
  {
    "value": "HB",
    "name": "Hopper Bottom (HB)"
  },
  {
    "value": "HS",
    "name": "Hot Shot (HS)"
  },
  {
    "value": "LB",
    "name": "Low Boy (LB)"
  },
  {
    "value": "PO",
    "name": "Power Only (PO)"
  },
  {
    "value": "RC",
    "name": "Rail Container (RC)"
  },
  {
    "value": "RN",
    "name": "Reefer Intermodal (RN)"
  },
  {
    "value": "RZ",
    "name": "Reefer Hazmat (RZ)"
  },
  {
    "value": "RGN",
    "name": "RGN Flatbed (RGN)"
  },
  {
    "value": "SD",
    "name": "Step Deck (SD)"
  },
  {
    "value": "ST",
    "name": "Straight Truck (ST)"
  },
  {
    "value": "T",
    "name": "Tanker (T)"
  },
  {
    "value": "VN",
    "name": "Van Intermodal (VN)"
  },
  {
    "value": "VZ",
    "name": "Van HazMat (VZ)"
  },
  {
    "value": "WLK",
    "name": "Van Walking Floor (WLK)"
  },
  {
    "value": "VV",
    "name": "Vented Van (VV)"
  },
  {
    "value": "CO",
    "name": "Container"
  },
  {
    "value": "other",
    "name": "Other"
  }
];

export default equipmentTypes;

import { createAction } from "redux-starter-kit";
import {
  UPDATE_TERMS_FAILURE,
  UPDATE_TERMS_REQUEST,
  UPDATE_TERMS_SUCCESS
} from "../types";

const updateTerms = (factoringId, termId, term) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPDATE_TERMS_REQUEST)());
    const response = await api.factoring.updateTerms(factoringId, termId, term);
    dispatch(createAction(UPDATE_TERMS_SUCCESS)({}));
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_TERMS_FAILURE)(err));
    throw err;
  }
};

export default updateTerms;

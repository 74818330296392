import React from "react";
import { Redirect } from "react-router-dom";
import { Grid, Paper, Typography } from "@material-ui/core";

import Table from "../../../../components/Table";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import CSVButton from "../../../../components/buttons/CSVButton";
import MasterStar from "../../../../components/icons/MasterStar";

export default ({
  tabs,
  navIndex,
  handleRoute,
  columns,
  invoices,
  isLoading,
  isSelected,
  allSelected,
  handleSelect,
  handleSelectAllClick,
  companyName,
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  handleSort,
  sortDirection,
  sortBy,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter,
  handleCSV,
  filters,
  master
}) => (
  <Can
    perform="admin-debtor-payment:view"
    yes={() => (
      <InternalLayout title="Debtor Invoices">
        <Grid container style={{ paddingBottom: "24px" }}>
          <Grid item>
            <Grid container spacing={16}>
              <Grid item>{master === true && <MasterStar />}</Grid>
              <Grid item>
                <Typography variant="h6">{companyName}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <NavigationTabs
          tabs={tabs}
          value={navIndex}
          handleRoute={handleRoute}
        />
        <Paper style={{ marginTop: "32px" }}>
          <Grid direction="row-reverse" container style={{ padding: "32px" }}>
            <Grid item>
              <CSVButton handleCSV={handleCSV} />
            </Grid>
          </Grid>
          <Table
            isLoading={isLoading}
            columns={columns}
            rows={invoices}
            select
            isSelected={isSelected}
            allSelected={allSelected}
            handleSelect={handleSelect}
            handleSelectAllClick={handleSelectAllClick}
            sort
            handleSort={handleSort}
            sortDirection={sortDirection}
            sortBy={sortBy}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            allowEmpty
            filter
            filters={filters}
            handleFilterChange={handleSearchFilter}
          />
        </Paper>
      </InternalLayout>
    )}
    no={() => <Redirect to="/" />}
  />
);

import { assign } from "lodash";
import {
  FETCH_QUERYFUNDINGREQUESTS_FAILURE,
  FETCH_QUERYFUNDINGREQUESTS_REQUEST,
  FETCH_QUERYFUNDINGREQUESTS_SUCCESS
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_QUERYFUNDINGREQUESTS_REQUEST:
      return {
        ...state,
        id: action.payload.id,
        fundingRequests: assign({}, state.fundingRequests, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          offset: action.payload.offset,
          ordering: action.payload.ordering,
          filters: action.payload.filters
        })
      };
    case FETCH_QUERYFUNDINGREQUESTS_SUCCESS:
      return {
        ...state,
        fundingRequests: assign({}, state.fundingRequests, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count
        })
      };
    case FETCH_QUERYFUNDINGREQUESTS_FAILURE:
      return {
        ...state,
        fundingRequests: assign({}, state.fundingRequests, {
          loading: false
        })
      };
    default:
      return state;
  }
};

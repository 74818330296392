const deleteAttachment = (paymentProfileId, attachmentId) => async (
  _,
  __,
  { api }
) => {
  const response = await api.factoring.deletePaymentProfileAttachment(
    paymentProfileId,
    attachmentId
  );
  return response;
};

export default deleteAttachment;

import React from "react";
import { Button, Typography, Grid, createStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Colors from "../../theme/colors";

const styles = createStyles({
  verticalCenter: {
    marginTop: "10%"
  },
  baseStyle: {
    width: "100%",
    height: 250,
    borderWidth: 2,
    borderColor: Colors.gray,
    borderStyle: "dashed",
    borderRadius: 5,
    marginTop: 20,
    backgroundColor: "#eef0f3"
  },
  activeStyle: {
    borderStyle: "solid",
    borderColor: "#6c6",
    backgroundColor: "#eef0f3"
  },
  rejectStyle: {
    borderStyle: "solid",
    borderColor: "#c66",
    backgroundColor: "#eee"
  }
});

const onDrop = (
  acceptedFiles,
  rejectedFiles,
  pickFiles,
  files,
  requestId,
  type
) => {
  if (acceptedFiles) {
    pickFiles(_.concat(files, acceptedFiles), requestId, type);
  }
};

const DropZoneUpload = ({ pickFiles, files, classes, requestId, type }) => (
  <Dropzone
    accept={["image/*", "text/*", "application/pdf"]}
    onDrop={(acceptedFiles, rejectedFiles) =>
      onDrop(acceptedFiles, rejectedFiles, pickFiles, files, requestId, type)
    }
  >
    {({
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject
    }) => {
      let styles = classNames([classes.baseStyle]);
      styles = isDragActive
        ? classNames([classes.baseStyle, classes.activeStyle])
        : styles;
      styles = isDragReject
        ? classNames([classes.baseStyle, classes.rejectStyle])
        : styles;

      return (
        <div {...getRootProps()} className={styles}>
          <input {...getInputProps()} />
          <Grid
            container
            xs={12}
            alignContent="center"
            alignItems="center"
            direction="column"
          >
            <CloudUploadIcon
              style={{ color: Colors.secondary, marginTop: 20 }}
              fontSize="large"
            />
            <Button variant="contained" color="secondary" size="medium">
              Upload Documents
            </Button>
            <Typography
              align="center"
              variant="body1"
              style={{
                marginTop: 10,
                fontWeight: 400,
                color: Colors.gray
              }}
            >
              {isDragAccept ? "Drop" : "Drag"}
              {` files here...`}
            </Typography>
          </Grid>
          {isDragReject && (
            <div>
              <Typography
                align="center"
                variant="body1"
                style={{
                  marginTop: 10,
                  fontWeight: 400,
                  color: Colors.gray
                }}
              >
                Unsupported file type...
              </Typography>
            </div>
          )}
        </div>
      );
    }}
  </Dropzone>
);
DropZoneUpload.propTypes = {
  pickFiles: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  requestId: PropTypes.string.isRequired
};
export default withStyles(styles)(DropZoneUpload);

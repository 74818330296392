import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import formatPennies from "../../../../../../helpers/format/formatPennies";
import AvatarCard from "../../../../../../components/AvatarCard";
import colors from "../../../../../../theme/colors";

const getColor = (activeReserve: number): string => {
  if (activeReserve > 0) {
    return colors.green;
  }

  if (activeReserve < 0) {
    return colors.danger;
  }

  return colors.link;
};

interface Props {
  activeReserve: number;
}

const ActiveReserveCard = ({ activeReserve }: Props): JSX.Element => (
  <AvatarCard
    title="Active Reserve"
    color={getColor(activeReserve)}
    icon={<FontAwesomeIcon icon={faDollarSign} size="lg" />}
    subheader={formatPennies(activeReserve)}
  />
);

export default ActiveReserveCard;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_DEBTOR_FAILURE,
  FETCH_DEBTOR_REQUEST,
  FETCH_DEBTOR_SUCCESS
} from "../types";

const queryDebtor = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_DEBTOR_REQUEST)({}));
    const response = await api.debtors.getDebtor(id);
    const data = normalize(response, schema.debtorProfile);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_DEBTOR_SUCCESS)({
        id: response.id
      })
    );
  } catch (err) {
    dispatch(createAction(FETCH_DEBTOR_FAILURE)(err));
    throw err;
  }
};

export default queryDebtor;

import { assign, concat } from "lodash";
import {
  CREATE_LINEITEM_REQUEST,
  CREATE_LINEITEM_SUCCESS,
  CREATE_LINEITEM_FAILURE
} from "../types";

const updateNOADates = (state, { type, payload }) => {
  switch (type) {
    case CREATE_LINEITEM_REQUEST:
      return {
        ...state,
        loadingLineItems: true
      };
    case CREATE_LINEITEM_SUCCESS:
      return {
        ...state,
        lineItems: assign({}, state.lineItems, {
          [payload.fundingRequest]: concat(
            state.lineItems[payload.fundingRequest],
            [payload.lineItem]
          )
        }),
        loadingLineItems: false
      };
    case CREATE_LINEITEM_FAILURE:
      return {
        ...state,
        loadingLineItems: false
      };
    default:
      return {
        ...state
      };
  }
};

export default updateNOADates;

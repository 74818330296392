import React from 'react';
import { Chip, Typography, Tooltip } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import formatDate from '../../../../helpers/format/formatDate';
import formatDays from '../../../../helpers/format/formatDays';
import HaulPayStatus from '../../../../components/StatusFormat';
import formatStatus from '../../../../helpers/format/formatStatus';
import formatPennies from '../../../../helpers/format/formatPennies';

const columns = (status: string[], setStatus: Function, userType: string) => [
  {
    key: 'invoice_number',
    name: 'Invoice #',
    filter: 'text',
    filter_key: 'invoice_number',
    sort: true,
  },
  {
    key: 'user_load_number',
    name: 'Load #',
    filter: 'text',
    filter_key: 'user_load_number',
    formKey: 'user_load_number',
  },
  {
    key: "purchase_order_number",
    name: "PO #",
    filter: "text",
    filter_key: "purchase_order_number"
  },
  {
    key: 'factoring_company_profile.name',
    name: 'Client',
    filter: 'text',
    filter_key: 'factoring_company_profile_name',
    formKey: 'carrier',
    colSpan: 3,
  },
  {
    key: 'invoice_amount',
    name: 'Invoice Amount',
    formKey: 'amount',
    sort: true,
  },
  {
    key: 'outstanding_balance',
    name: 'Amount Due',
    formKey: 'amount_due',
    sort: true,
  },
  {
    key: ['reserve_info.reserve_amount', 'reserve_info.has_negative_reserve'],
    name: 'Reserve',
    format: (
      reserveAmount: number,
      hasNegativeReserve: boolean
    ): JSX.Element => (
      <Typography
        variant="caption"
        color={hasNegativeReserve ? 'error' : 'textPrimary'}
      >
        {formatPennies(reserveAmount)}
      </Typography>
    ),
  },
  {
    key: 'invoice_date',
    name: 'Invoice Date',
    format: formatDate,
    sort: true,
  },
  {
    key: 'followup_date',
    name: 'Next Follow-Up',
  },
  {
    key: 'response_category',
    name: 'Response Category',
    filter_key: 'response_category',
    filter: 'dropdown',
    filter_values: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'No Response',
        value: 'no_response',
      },
      { label: 'Resubmit Invoice', value: 'resubmit_invoice' },
      { label: 'Document Issue', value: 'document_issue' },
      { label: 'Promise To Pay', value: 'promise_to_pay' },
      { label: 'Follow Up', value: 'follow_up' },
      { label: 'Debtor Issue', value: 'debtor_issue'},
      { label: 'Document Issue A', value: 'document_issue_a' },
      { label: 'Document Issue B', value: 'document_issue_b' },
      { label: 'Document Issue C', value: 'document_issue_c' },
      { label: "Payment Issue", value: "payment_issue" },
      { label: "Internal CF", value: "internal_cf" },
      { label: 'Issue', value: 'issue' }
    ],
  },
  {
    key: 'debtor.dtp_allowance',
    name: 'Net Terms',
    default: 30,
    sort: true,
  },
  {
    key: 'receivable_status',
    name: 'Status',
    filter: 'dropdown',
    selectProps: {
      name: 'receivable_status',
      value: status, // override the value with our new state value
      multiple: true,
      displayEmpty: true,
      renderValue: (selected: string[]): JSX.Element => (
        <div>
          {!isEmpty(selected) &&
            selected.map((selection: string) => (
              <Chip key={selection} label={selection} />
            ))}
        </div>
      ),
    },
    filter_key: 'receivable_status',
    filter_value: status,
    filter_update_value: setStatus,
    filter_values: [
      {
        label: '--',
        value: null,
      },
      {
        label: 'Open',
        value: 'open',
      },
      {
        label: 'Paid',
        value: 'paid',
      },
      {
        label: 'Short Paid',
        value: 'short_paid',
      },
      {
        label: 'Write Off',
        value: 'write_off',
      },
      {
        label: 'Double Paid',
        value: 'double_paid',
      },
      {
        label: 'Client Settled',
        value: 'client_settled',
      },
      {
        label: 'Over Paid',
        value: 'over_paid',
      },
      {
        label: 'Non Factored Open',
        value: 'non_factored_open',
      },
      {
        label: 'Non Factored Short Paid',
        value: 'non_factored_short_paid',
      },
      {
        label: 'Non Factored Over Paid',
        value: 'non_factored_overpaid',
      },
    ],
    format: (value: string): JSX.Element => (
      <HaulPayStatus status={formatStatus(value)} />
    ),
  },
  {
    key: 'receivable_age',
    name: 'Age',
    format: formatDays,
    sort: true,
  },
  {
    key: "bank_description",
    name: "Bank Description",
    format: (value: string): JSX.Element => (
      <Tooltip title={value}>
        <Typography
          variant="body1"
        >
          {(value || "").length > 10 ? value.slice(0, 10) + "..." : value}
        </Typography>
      </Tooltip>
    )
  },
  {
    key: 'more',
    name: 'More',
    formKey: 'actions',
  },
];

export default columns;

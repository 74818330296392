import { bindActionCreators } from "redux";
import { compose, lifecycle, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";
import { find, findIndex, get } from "lodash";
import withSelect from "../../../../components/withSelect";

import {
  clearPurchaseItems,
  createLineItemsCategories,
  createPaymentsFile,
  createPurchasesCSV,
  createReceivableCSV,
  deleteLineItemsCategories,
  fetchFundingRequestReceivables,
  fetchOutstandingTotals,
  fetchPurchaseItems,
  fetchPurchases,
  fetchReceivables,
  getDebtors,
  getIsUploading,
  getOutstandingTotals,
  getPurchaseItems,
  getPurchases,
  getReceivables,
  getReceivablesById,
  purchasesActionsTable,
  receivablesActionsTable,
  receivablesDebtorsActionsTable,
  selectorsPurchasesTable,
  selectorsReceivablesDebtorsTable,
  selectorsRecivablesTable,
  updateLineItemsCategories
} from "../../../../modules/finances";

import {
  createLineItem,
  fetchLineItemCategories,
  fetchLineItems,
  getLineItemCategories,
  getLineItems
} from "../../../../modules/factoring";

import {
  clearOpenFundingRequests,
  createDebtorPayment,
  getIsLoading as openFundingRequestsIsLoading,
  getOpenFundingRequests,
  queryOpenFundingRequests
} from "../../../../modules/debtor";

import { openModal } from "../../../../modules/modal";

import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../modules/ui";

import LineItems from "./LineItems";
import tabs from "../tabs";

const mapStateToProps = (
  state,
  { selected = [], selectedReceivable = {} }
) => ({
  receivables: getReceivables(state),
  receivablesTable: {
    isLoading: selectorsRecivablesTable.getIsLoading(state),
    sortDirection: selectorsRecivablesTable.getSortDirection(state),
    sortBy: selectorsRecivablesTable.getSortBy(state),
    count: selectorsRecivablesTable.getCount(state),
    page: selectorsRecivablesTable.getPage(state),
    rowsPerPage: selectorsRecivablesTable.getRowsPerPage(state),
    rowsPerPageOptions: selectorsRecivablesTable.getRowsPerPageOptions(state),
    filters: selectorsRecivablesTable.getFilters(state)
  },
  receivablesDebtorsTable: {
    isLoading: selectorsReceivablesDebtorsTable.getIsLoading(state),
    sortDirection: selectorsReceivablesDebtorsTable.getSortDirection(state),
    sortBy: selectorsReceivablesDebtorsTable.getSortBy(state),
    count: selectorsReceivablesDebtorsTable.getCount(state),
    page: selectorsReceivablesDebtorsTable.getPage(state),
    rowsPerPage: selectorsReceivablesDebtorsTable.getRowsPerPage(state),
    rowsPerPageOptions: selectorsReceivablesDebtorsTable.getRowsPerPageOptions(
      state
    )
  },
  openFundingRequests: getOpenFundingRequests(state),
  openFundingRequestsIsLoading: openFundingRequestsIsLoading(state),
  paymentsIsUploading: getIsUploading(state),
  purchases: getPurchases(state),
  purchasesTable: {
    isLoading: selectorsPurchasesTable.getIsLoading(state),
    sortDirection: selectorsPurchasesTable.getSortDirection(state),
    sortBy: selectorsPurchasesTable.getSortBy(state),
    count: selectorsPurchasesTable.getCount(state),
    page: selectorsPurchasesTable.getPage(state),
    rowsPerPage: selectorsPurchasesTable.getRowsPerPage(state),
    rowsPerPageOptions: selectorsPurchasesTable.getRowsPerPageOptions(state)
  },
  purchaseItems: getPurchaseItems(state),
  LineItemCategories: getLineItemCategories(state),
  dialog: getDialog(state),
  outstandingTotals: getOutstandingTotals(state),
  debtors: getDebtors(state),
  selectedReceivables: getReceivablesById(selected)(state),
  receivableLineItems: getLineItems(
    get(selectedReceivable, "funding_request.id", "")
  )(state)
});

const mapDispatchToProps = (dispatch, { selectedReceivable = {} }) => ({
  receivablesActionsTable: () => ({
    ...bindActionCreators(
      {
        handleSort: receivablesActionsTable.handleSort,
        handleChangePage: receivablesActionsTable.handleChangePage,
        handleChangeRowsPerPage:
          receivablesActionsTable.handleChangeRowsPerPage,
        handleSearchFilter: receivablesActionsTable.handleSearchFilter
      },
      dispatch
    )
  }),
  receivablesDebtorsActionsTable: () => ({
    ...bindActionCreators(
      {
        handleSort: receivablesDebtorsActionsTable.handleSort,
        handleChangePage: receivablesDebtorsActionsTable.handleChangePage,
        handleChangeRowsPerPage:
          receivablesDebtorsActionsTable.handleChangeRowsPerPage,
        handleSearchFilter: receivablesDebtorsActionsTable.handleSearchFilter
      },
      dispatch
    )
  }),
  purchasesActionsTable: () => ({
    ...bindActionCreators(
      {
        handleSort: purchasesActionsTable.handleSort,
        handleChangePage: purchasesActionsTable.handleChangePage,
        handleChangeRowsPerPage: purchasesActionsTable.handleChangeRowsPerPage,
        handleSearchFilter: purchasesActionsTable.handleSearchFilter
      },
      dispatch
    )
  }),
  ...bindActionCreators(
    {
      fetchReceivables,
      queryOpenFundingRequests,
      clearOpenFundingRequests,
      fetchPurchases,
      createPaymentsFile,
      openSnackbar,
      createReceivableCSV,
      fetchPurchaseItems,
      clearPurchaseItems,
      fetchLineItemCategories,
      closeDialog,
      openDialog,
      createLineItemsCategories,
      updateLineItemsCategories,
      deleteLineItemsCategories,
      openModal,
      createPurchasesCSV,
      fetchOutstandingTotals,
      fetchFundingRequestReceivables,
      fetchLineItems,
      createDebtorPayment,
      createLineItem: createLineItem(
        get(selectedReceivable, "funding_request.id", "")
      )
    },
    dispatch
  )
});

export default compose(
  withSelect,
  withStateHandlers(({ selectedReceivable = {} }) => ({ selectedReceivable }), {
    setSelectedReceivable: () => selectedReceivable => ({
      selectedReceivable
    })
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      selected,
      clearSelected,
      createReceivableCSV
    }) => ({
      tabs,
      statusIndex: findIndex(tabs, tab => pathname === tab.route) || 0,
      filterStatus: (find(tabs, tab => pathname === tab.route) || {}).value,
      handleRoute: route => push(route),
      handleCSV: () => createReceivableCSV(selected),
      handleSetStatus: async () => {
        clearSelected();
      }
    })
  ),
  lifecycle({
    async componentDidMount() {
      const { fetchLineItemCategories } = this.props;
      await fetchLineItemCategories();
    }
  })
)(LineItems);

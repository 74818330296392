import { createAction } from "redux-starter-kit";

import {
  SEND_MEMBER_INVITE_REQUEST,
  SEND_MEMBER_INVITE_SUCCESS,
  SEND_MEMBER_INVITE_FAILURE
} from "../types";

const sendInvite = ({ groupId, emails = [], phones = [] }) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(
      createAction(SEND_MEMBER_INVITE_REQUEST)({
        groupId
      })
    );
    const response = await api.groups.inviteMembers({
      groupId,
      emails,
      phones
    });
    dispatch(createAction(SEND_MEMBER_INVITE_SUCCESS)({ groupId }));
    return response;
  } catch (err) {
    dispatch(createAction(SEND_MEMBER_INVITE_FAILURE)(err));
    throw err;
  }
};

export default sendInvite;

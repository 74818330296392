import React, { ChangeEventHandler } from "react";
import {
  Chip,
  createStyles,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  WithStyles
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import colors from "../../theme/colors";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: "100%",
      maxWidth: "100%"
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: theme.spacing.unit / 4
    },
    underline: {
      "&:hover:not($disabled):before,&:before": {
        borderBottom: `2px solid ${colors.borderInputDisableColor} !important`
      },
      "&:after": {
        borderBottom: `2px solid ${colors.borderInputColor}`
      }
    },
    underlineAddress: {
      "&:hover:not($disabled):before,&:before": {
        borderBottom: `2px solid ${colors.borderInputDisableColor} !important`
      },
      "&:after": {
        borderBottom: `2px solid ${colors.borderInputColor} !important`
      }
    },
    cssFocused: {
      color: `${colors.borderInputColor} !important`
    },
    errorLabel: {
      color: `${colors.inputLabelErrorColor} !important`
    },
    errorInput: {
      borderBottom: `2px solid ${colors.inputLabelErrorColor} !important`
    },
    input: {
      color: `${colors.textColor} !important`,
      fontFamily: "Avenir !important",
      fontSize: "16px !important",
      fontWeight: 500,
      lineHeight: `${1.5} !important`,
      opacity: 0.9
    }
  });

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface Item {
  value: string;
  name: string;
}

interface Props extends WithStyles<typeof styles> {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string[];
  label?: string;
  name?: string;
  onBlur: ChangeEventHandler<HTMLDivElement>;
  items: Item[];
  withStyle: boolean;
  error?: string;
  disabled?: boolean;
}

const MultiChoiceSelect = ({
  classes,
  onChange,
  value,
  label,
  name,
  onBlur,
  items = [],
  withStyle,
  error,
  disabled
}: Props): JSX.Element => {
  const underlineClasses = classNames({
    [classes.underline]: true
  });
  const inputClasses = withStyle
    ? {
        underline: underlineClasses,
        input: classes.input,
        error: classes.errorInput
      }
    : {};
  const labelClasses = withStyle
    ? {
        focused: classes.cssFocused,
        error: classes.errorLabel
      }
    : {};
  return (
    <FormControl fullWidth>
      <InputLabel
        classes={{
          ...labelClasses
        }}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={
          (onChange as unknown) as ChangeEventHandler<HTMLSelectElement>
        }
        disabled={disabled}
        input={
          <Input
            fullWidth
            classes={{
              ...inputClasses
            }}
            id={name}
            name={name}
          />
        }
        onBlur={onBlur}
        renderValue={(selected): JSX.Element => (
          <div className={classes.chips}>
            {(selected as string[]).map(
              (select): JSX.Element => (
                <Chip key={select} label={select} className={classes.chip} />
              )
            )}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {items.map(
          (item): JSX.Element => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          )
        )}
      </Select>
      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
    </FormControl>
  );
};

export default withStyles(styles, { withTheme: true })(MultiChoiceSelect);

import React from "react";
import {
  createStyles,
  IconButton,
  InputAdornment,
  WithStyles
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { DatePicker as DatePickerUI } from "material-ui-pickers";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { DatePickerModalProps } from "material-ui-pickers/DatePicker/DatePickerModal";
import colors from "../../theme/colors";

const styles = createStyles({
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottom: `2px solid ${colors.borderInputDisableColor}`
    },
    "&:after": {
      borderBottom: `2px solid ${colors.borderInputColor}`
    }
  },
  cssFocused: {
    color: `${colors.borderInputColor} !important`
  },
  errorLabel: {
    color: `${colors.inputLabelErrorColor} !important`
  },
  input: {
    color: `${colors.textColor}`,
    fontFamily: "Avenir",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1.5,
    opacity: 0.9
  }
});

interface Ref {
  pickerRef?: any;
}

const MDatePicker = ({
  classes,
  pickerRef,
  ...props
}: WithStyles<typeof styles> & DatePickerModalProps & Ref): JSX.Element => {
  const underlineClasses = classNames({
    [classes.underline]: true
  });
  return (
    <DatePickerUI
      {...props}
      ref={pickerRef}
      format="MM-dd-yyyy"
      clearable
      InputLabelProps={{
        classes: {
          focused: classes.cssFocused,
          error: classes.errorLabel
        }
      }}
      InputProps={{
        classes: {
          underline: underlineClasses,
          input: classes.input
        },
        endAdornment: (
          <InputAdornment position="start">
            <IconButton aria-label="open calender">
              <FontAwesomeIcon icon={faCalendar} size="xs" />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default withStyles(styles)(MDatePicker);

import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import Yup from "../../../../../../../YupValidation";
import {
    DialogTitle,
    Dialog,
    DialogContent,
    DialogActions
} from "../../../../../../../components/Dialog";
import MailingAddressInfo from "../../../../../../../components/MailingAddressInfo";

const validatorSchema = () => {
    const schema = {
        legalAccountName: Yup.string().required("Please fill out this field"),
        mailingAddressOne: Yup.string().required("Please fill out this field"),
        mailingAddressCity: Yup.string().required("Please fill out this field"),
        mailingAddressState: Yup.string().required("Please fill out this field"),
        mailingAddressZip: Yup.string().required("Please fill out this field"),
    }
    return Yup.object().shape(schema);
}

const CHECK = ({ open, address = {}, handleExit, createAccount, selectNone, handleUpdateFactoringMailingAdress }) => (
    <Dialog open={open} maxWidth="sm" fullWidth>
        <Formik
            enableReinitialize
            initialValues={{
                legalAccountName: '',
                mailingAddressOne: address.street_one,
                mailingAddressTwo: address.street_two,
                mailingAddressCity: address.city,
                mailingAddressState: address.state,
                mailingAddressZip: address.zip,
                mailingAddressCountry: address.country,
            }}
            validationSchema={validatorSchema}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    await handleUpdateFactoringMailingAdress({
                        mailing_address: {
                            street_one: values.mailingAddressOne,
                            street_two: values.mailingAddressTwo,
                            city: values.mailingAddressCity,
                            state: values.mailingAddressState,
                            zip: values.mailingAddressZip,
                            country: values.mailingAddressCountry
                        }
                    })
                    await createAccount({
                        street_one: values.mailingAddressOne,
                        street_two: values.mailingAddressTwo,
                        city: values.mailingAddressCity,
                        state: values.mailingAddressState,
                        zip: values.mailingAddressZip,
                        country: values.mailingAddressCountry
                    },
                    values.legalAccountName,
                    );
                    selectNone();
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({
                handleSubmit,
                values,
                errors,
                handleChange,
                handleBlur,
                isSubmitting
            }) => (
                <React.Fragment>
                    <DialogTitle onClose={handleExit} />
                    <DialogContent>
                        <Grid container direction="column" spacing={16}>
                            <MailingAddressInfo values={values} handleBlur={handleBlur} handleChange={handleChange} errors={errors} displayLegalName={true} />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => selectNone()}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={() => handleSubmit()}
                        >
                            Add Bank
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )}
        </Formik>
    </Dialog>
);

export default CHECK;

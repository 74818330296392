import { denormalize } from "normalizr";

import { receivableEntry } from "../../../api/schema";

export default state => {
  try {
    const items = state.receivablesEntries.ids;
    const denormalized = denormalize(
      { items },
      { items: [receivableEntry] },
      state.entities
    );
    return denormalized.items || [];
  } catch (err) {
    return [];
  }
};

import React from "react";
import { has, get } from "lodash";

export const CellRenderer = ({ column = {}, row = [], additional }) => {
  if (typeof row[column.key] === "function") {
    return get(row, column.key)({
      ...additional
    });
  }
  return has(column, "format")
    ? column.format(get(row, column.key, column.default || "--"))
    : get(row, column.key, column.default || "--");
};

export const LabelRenderer = ({ column = {} }) => column.name;

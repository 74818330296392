import { MenuItem } from '@material-ui/core';
import React, { ChangeEventHandler } from 'react';
import CustomTextField from '../../../../../components/inputs/CustomTextField';

interface Props {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

const AgainstWhoInput = ({
  onChange,
  onBlur,
  value,
  disabled,
}: Props): JSX.Element => (
  <CustomTextField
    fullWidth
    select
    name="againstWho"
    id="againstWho"
    label="Against Who"
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    disabled={disabled}
  >
    <MenuItem value="client">Client</MenuItem>
    <MenuItem value="invited_carrier">Invited Carrier</MenuItem>
    <MenuItem value="debtor">Debtor</MenuItem>
    <MenuItem value="organization">ComFreight</MenuItem>
    <MenuItem value="proof">Proof</MenuItem>
  </CustomTextField>
);

export default AgainstWhoInput;

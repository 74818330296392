import React from "react";
import { Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from "recharts";
import formatPennies from "../../../../../helpers/format/formatPennies";

export default ({ data = [] }) => (
  <React.Fragment>
    {isEmpty(data) ? (
      <Typography>No Funding Request Data Found</Typography>
    ) : (
      <LineChart width={600} height={250} data={data} margin={{ left: 50 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="week_start" tick={false} />
        <YAxis
          yAxisId="amount"
          v
          orientation="left"
          tickFormatter={formatPennies}
        />
        <YAxis yAxisId="count" orientation="right" />
        <Legend />
        <Line
          name="Count of Document Issue Invoices"
          dot={false}
          dataKey="amount_declined"
          stroke="#e80000"
          yAxisId="count"
        />
        <Line
          name="Count of Invoices Factored"
          dot={false}
          dataKey="amount_count"
          stroke="#e86b00"
          yAxisId="count"
        />
        <Line
          name="Value of Approved Invoices Factored $"
          dataKey="amount_approved"
          fill="#4286f4"
          stroke="#4286f4"
          yAxisId="amount"
        />
      </LineChart>
    )}
  </React.Fragment>
);

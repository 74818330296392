import React from "react";
import {
  Grid,
  List,
  Checkbox,
  FormControlLabel,
  createStyles,
  withStyles,
  FormGroup,
  Switch
} from "@material-ui/core";

import ListItem from "../../../../../components/ListItem";
import ProfileSection from "../../../../../components/ProfileSection";

const styles = () =>
  createStyles({
    root: {
      width: "100%",
      justifyContent: "space-between",
      margin: 0
    },
    checkbox: {
      padding: 0
    }
  });

export default withStyles(styles)(({ values, classes, setFieldValue }) => {
  return (
    <List dense>
      <ListItem>
        <ProfileSection>Client Approval - Pending Tasks</ProfileSection>
      </ListItem>
      <ListItem>
        <Grid container direction="column" spacing={8}>
          <Grid item>
            <FormControlLabel
              classes={{ root: classes.root }}
              control={
                <Checkbox
                  classes={{ root: classes.checkbox }}
                  checked={values.needAccountInfo}
                  onChange={() =>
                    setFieldValue("needAccountInfo", !values.needAccountInfo)
                  }
                />
              }
              label="Need Account Info"
              labelPlacement="start"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              classes={{ root: classes.root }}
              control={
                <Checkbox
                  classes={{ root: classes.checkbox }}
                  checked={values.needNOA}
                  onChange={() => setFieldValue("needNOA", !values.needNOA)}
                />
              }
              label="Need NOA"
              labelPlacement="start"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              classes={{ root: classes.root }}
              control={
                <Checkbox
                  classes={{ root: classes.checkbox }}
                  checked={values.needFactorInfo}
                  onChange={() =>
                    setFieldValue("needFactorInfo", !values.needFactorInfo)
                  }
                />
              }
              label="Need Factor Info"
              labelPlacement="start"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              classes={{ root: classes.root }}
              control={
                <Checkbox
                  classes={{ root: classes.checkbox }}
                  checked={values.needVerifiedIdentity}
                  onChange={() =>
                    setFieldValue(
                      "needVerifiedIdentity",
                      !values.needVerifiedIdentity
                    )
                  }
                />
              }
              label="Verifying Bank Acct or NOA"
              labelPlacement="start"
            />
          </Grid>
          {values.status === "needs_review" && (
            <Grid>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.autoSendPaymentEmail}
                      onChange={() => {
                        setFieldValue(
                          "autoSendPaymentEmail",
                          !values.autoSendPaymentEmail
                        );
                      }}
                      value="autoSendPaymentEmail"
                    />
                  }
                  label="Send Payment Methods Link Every 2 days"
                />
              </FormGroup>
            </Grid>
          )}
          <Grid>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.enablePayViaCheck}
                    onChange={() => {
                      setFieldValue(
                        'enablePayViaCheck',
                        !values.enablePayViaCheck
                      );
                    }}
                    value="enablePayViaCheck"
                  />
                }
                label="Enable Pay Via Check"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
});

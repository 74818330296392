import { Button, Grid, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";

import { closeDialog } from "../modules/ui";
import { DialogTitle, Dialog, DialogContent } from "./Dialog";
import colors from "../theme/colors";

function FileTypeConfirmation({ open, customHandleClose }) {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => dispatch(closeDialog()));
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={customHandleClose || handleClose} />
      <DialogContent>
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          spacing="24"
          direction="column"
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="4x"
              color={colors.warning}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              We only support PDFs, JPGs, JPEGs, PNGs, and HEICs.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              Please check that the file extension of the file you're uploading
              matches these document types and try again.
            </Typography>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={customHandleClose || handleClose}
            >
              Ok
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

FileTypeConfirmation.propTypes = {
  customHandleClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default FileTypeConfirmation;

import { MenuItem } from '@material-ui/core';
import React, { ChangeEventHandler } from 'react';
import CustomTextField from '../../../../../components/inputs/CustomTextField';

interface Category {
  id: string;
  name: string;
}

interface Props {
  categories: Category[];
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: ChangeEventHandler<HTMLInputElement>;
  value: string;
  disabled?: boolean;
}

function CategoryInput({
  categories,
  onChange,
  onBlur,
  value,
  disabled,
}: Props): JSX.Element {
  return (
    <CustomTextField
      fullWidth
      select
      name="category"
      id="Category"
      label="Category"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
    >
      {categories.map(
        (category: Category): JSX.Element => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        )
      )}
    </CustomTextField>
  );
}

export default CategoryInput;

import React from "react";
import { Grid, MenuItem, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { get } from "lodash";

import colors from "../../../../theme/colors";
import formatPennies from "../../../../helpers/format/formatPennies";
import CustomTextField from "../../../../components/inputs/CustomTextField";

const periodsArray = [
  {
    value: "monthly",
    label: "Monthly"
  },
  {
    value: "weekly",
    label: "Weekly"
  },
  {
    value: "daily",
    label: "Daily"
  }
];

const styles = () => ({
  h4: {
    fontSize: "36px !important",
    fontWeight: "bold"
  }
});

const renderPeriod = value => {
  switch (value) {
    case "monthly":
      return "Month";
    case "weekly":
      return "Week";
    case "daily":
      return "day";
    default:
      return "Monthly";
  }
};

const Revenue = ({
  onPeriodChange,
  period,
  stats,
  fetchRevenueByPeriods,
  classes
}) => (
  <Paper>
    <Grid container style={{ padding: 10 }}>
      <Grid container item xs={12} direction="row">
        <Grid item xs={8} container alignItems="center">
          <Typography variant="title">Revenue</Typography>
        </Grid>
        <Grid container item xs={4} alignItems="flex-start" justify="flex-end">
          <CustomTextField
            select
            fullWidth
            label="Period"
            placeholder="Period"
            name="period"
            value={period}
            onChange={async event => {
              onPeriodChange(event.target.value);
              await fetchRevenueByPeriods(event.target.value);
            }}
          >
            {periodsArray.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomTextField>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row">
        <Grid
          item
          container
          xs={12}
          justify="center"
          alignContent="stretch"
          style={{ padding: 20 }}
        >
          <Typography
            variant="h4"
            classes={{
              h4: classes.h4
            }}
            color="secondary"
          >
            {formatPennies(get(stats, "revenues.revenue", 0))}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={6}
          justify="center"
          alignItems="center"
          style={{ borderRight: `1px solid ${colors.divider}`, height: 50 }}
        >
          <Typography variant="caption" color="textPrimary">
            {`Last ${renderPeriod(period)}`}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={6}
          justify="center"
          alignItems="center"
          style={{ height: 50 }}
        >
          <Typography variant="caption" color="textPrimary">
            {`This ${renderPeriod(period)}`}
          </Typography>
        </Grid>
        <Grid item container xs={6} justify="center" alignItems="center">
          <Typography variant="h6" color="textPrimary">
            {formatPennies(get(stats, "revenues.lastMonthRevenue", 0))}
          </Typography>
        </Grid>
        <Grid item container xs={6} justify="center" alignItems="center">
          <Typography variant="h6" color="textPrimary">
            {formatPennies(get(stats, "revenues.currentMonthRevenue", 0))}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="center">
        <div
          style={{
            minWidth: 100,
            minHeight: 30,
            backgroundColor:
              get(stats, "revenues.increasePercentage", 0) > 0
                ? colors.green
                : colors.danger,
            borderRadius: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography variant="body1" style={{ color: colors.white }}>
            {get(stats, "revenues.increasePercentage", 0).toFixed(2)}%
          </Typography>
        </div>
      </Grid>
    </Grid>
  </Paper>
);

export default withStyles(styles)(Revenue);

import {
  Button,
  CircularProgress,
  WithStyles,
  createStyles
} from "@material-ui/core";
import React, { CSSProperties } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { ButtonProps } from "@material-ui/core/Button/Button";

import Colors from "../../theme/colors";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center"
    },
    wrapper: {
      margin: theme.spacing.unit,
      position: "relative",
      width: "100%"
    },
    buttonProgress: {
      color: Colors.secondary,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    }
  });

interface Props extends WithStyles<typeof styles> {
  progressStyle?: CSSProperties;
  loading: boolean;
  wrapperStyle?: CSSProperties;
}

const CircularButton = ({
  children,
  loading,
  classes,
  style,
  onClick,
  progressStyle = {},
  wrapperStyle = {},
  ...props
}: Props & ButtonProps): JSX.Element => (
  <div className={classes.wrapper} style={wrapperStyle}>
    <Button
      {...props}
      style={style}
      disabled={loading || props.disabled}
      onClick={onClick}
    >
      {children}
    </Button>
    {loading && (
      <CircularProgress
        size={24}
        className={classes.buttonProgress}
        style={progressStyle}
      />
    )}
  </div>
);

export default withStyles(styles)(CircularButton);

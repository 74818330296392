import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_DEBTORRELATION_FAILURE,
  FETCH_DEBTORRELATION_REQUEST,
  FETCH_DEBTORRELATION_SUCCESS
} from "../types";

const fetchDebtorRelation = fundingRequestId => (
  debtorId,
  factoringId
) => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_DEBTORRELATION_REQUEST)());
    const response = await api.factoring.getDebtorRelation(
      debtorId,
      factoringId
    );
    const data = normalize(response, schema.debtorRelation);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_DEBTORRELATION_SUCCESS)({
        [fundingRequestId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_DEBTORRELATION_FAILURE)(err));
    throw err;
  }
};

export default fetchDebtorRelation;

import { assign } from "lodash";
import {
  QUERY_CONNECTED_DEBTORS_REQUEST,
  QUERY_CONNECTED_DEBTORS_SUCCESS,
  QUERY_CONNECTED_DEBTORS_FAILURE
} from "../types";

// const sameRequest = (state, payload) =>
//   state.status === payload.status &&
//   state.limit === payload.limit &&
//   state.offset === payload.offset &&
//   state.ordering === payload.ordering;

export default (
  state = {
    members: {
      loading: false,
      ids: []
    }
  },
  action
) => {
  switch (action.type) {
    case QUERY_CONNECTED_DEBTORS_REQUEST:
      return {
        ...state,
        connectedDebtors: assign({}, state.connectedDebtors, {
          ids: [],
          loading: true
          // status: action.payload.status,
          // limit: action.payload.limit,
          // offset: action.payload.offset,
          // ordering: action.payload.ordering,
          // filters: action.payload.filters
        })
      };
    case QUERY_CONNECTED_DEBTORS_SUCCESS:
      // if (sameRequest(state, action.payload)) {
      return {
        connectedDebtors: assign({}, state.connectedDebtors, {
          loading: false,
          id: action.payload.id,
          ids: action.payload.items,
          count: action.payload.count
        })
      };
    // }
    // return state;
    case QUERY_CONNECTED_DEBTORS_FAILURE:
      return {
        connectedDebtors: assign({}, state.connectedDebtors, {
          loading: false
        })
      };
    // return state;
    default:
      return state;
  }
};

import React from "react";
import formatPennies from "../../../../../helpers/format/formatPennies";
import formatStatus from "../../../../../helpers/format/formatStatus";
import HaulPayStatus from "../../../../../components/StatusFormat";

const lineItemsColumns = [
  {
    key: "category_name",
    name: "Category",
    default: "-",
    primary: true
  },
  {
    key: "amount_requested",
    name: "Amount",
    format: formatPennies,
    primary: true
  },
  {
    key: "discount_rate",
    name: "Discount Rate",
    default: "-"
  },
  {
    key: "description",
    name: "Description",
    sort: true
  },

  {
    key: "approval_status",
    name: "Approved",
    primary: true,
    format: (value: string): JSX.Element => (
      <HaulPayStatus status={formatStatus(value)} />
    )
  }
];

export default lineItemsColumns;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@material-ui/core";
import { Redirect } from "react-router-dom";

import Table from "../../../components/Table";
import NavigationTabs from "../../../components/NavigationTabs";
import Filters from "./components/Filters";
import InternalLayout from "../../../components/layouts/InternalLayout";
import Can from "../../../components/Can";
import { docReviewCount } from "../../../modules/factoringClients";
import { getUserType } from "../../../modules/auth";

const FactorClients = ({
  tabs,
  columns,
  statusIndex,
  handleChangePage,
  factorClients,
  rowsPerPageOptions,
  rowsPerPage,
  count,
  sortDirection,
  sortBy,
  page,
  handleChangeRowsPerPage,
  handleSort,
  handleSearchFilter,
  loading,
  filters,
  handleCSV,
  datetime_end,
  datetime_start,
  onDateStartChange,
  onDateEndChange,
  filterStatus
}) => {
  const type = useSelector(getUserType);
  return (
    <Can
      perform="admin-factorclients:view"
      yes={() => {
        const [badge, setBadge] = useState(10);
        const dispatch = useDispatch();
        useEffect(() => {
          async function f() {
            const badgeCount = await dispatch(docReviewCount());
            setBadge(badgeCount);
          }
          f();
        }, [statusIndex]);
        return (
          <InternalLayout title="Factor Clients">
            <React.Fragment>
              <NavigationTabs
                tabs={tabs.map(tab => {
                  if (tab.value === "document_review") {
                    return Object.assign({}, tab, { badge });
                  }
                  return tab;
                })}
                value={statusIndex}
              />
              <Paper style={{ marginTop: "32px" }}>
                <Filters
                  filters={filters}
                  handleFilterChange={handleSearchFilter}
                  handleCSV={handleCSV}
                  datetime_end={datetime_end}
                  datetime_start={datetime_start}
                  onDateStartChange={onDateStartChange}
                  onDateEndChange={onDateEndChange}
                />
                <div style={{ paddingLeft: "16px" }}>
                  <Table
                    columns={columns(filters.inactive, filterStatus).filter(
                      column => {
                        if (type === "factoring_remote_admin") {
                          if (
                            column.name === "Phone" ||
                            column.name === "Stats"
                          ) {
                            return false;
                          }
                        }
                        return true;
                      }
                    )}
                    rows={factorClients}
                    rowsPerPageOptions={rowsPerPageOptions}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    sort
                    handleSort={handleSort}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    filter
                    handleFilterChange={handleSearchFilter}
                    isLoading={loading}
                    allowEmpty
                    filters={filters}
                  />
                </div>
              </Paper>
            </React.Fragment>
          </InternalLayout>
        );
      }}
      no={() => <Redirect to="/" />}
    />
  );
};

export default FactorClients;

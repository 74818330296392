import { createAction } from "redux-starter-kit";
import {
  FETCH_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS
} from "../types";

const updateUser = user => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(UPDATE_USER_PROFILE_REQUEST)());
    const response = await api.user.updateUser(user);
    dispatch(createAction(UPDATE_USER_PROFILE_SUCCESS)(response));
    dispatch(createAction(FETCH_USER_PROFILE_SUCCESS)(response));
  } catch (err) {
    dispatch(createAction(UPDATE_USER_PROFILE_FAILURE)(err));
    throw err;
  }
};

export default updateUser;

import React from "react";
import { Grid, MenuItem, Paper, Typography } from "@material-ui/core";
import Table from "../../../../components/Table";
import CustomTextField from "../../../../components/inputs/CustomTextField";

const userTypeArray = [
  {
    value: "",
    label: "Type"
  },
  {
    value: "debtor",
    label: "Debtor"
  },
  {
    value: "Broker",
    label: "broker"
  },
  {
    value: "carrier",
    label: "Carrier"
  }
];

const columns = [
  {
    key: "company_name",
    name: "Name",
    filter: "text",
    filter_key: "company_name"
  },
  {
    key: "outstanding",
    name: "Outstanding"
  },
  {
    key: "percentage",
    name: "%",
    default: "-"
  }
];

const OutstandingAR = ({ userType, onUserChange }) => (
  <Paper>
    <Grid container style={{ padding: 10 }}>
      <Grid container item xs={12} direction="row">
        <Grid item xs={9} container alignItems="center">
          <Typography variant="title">Outstanding AR</Typography>
        </Grid>
        <Grid container item xs={3} alignItems="flex-start" justify="flex-end">
          <CustomTextField
            select
            fullWidth
            label="Type"
            placeholder="Type"
            name="userType"
            value={userType || ""}
            onChange={value => onUserChange(value.target.value)}
          >
            {userTypeArray.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomTextField>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row">
        <Table columns={columns} allowEmpty />
      </Grid>
    </Grid>
  </Paper>
);

export default OutstandingAR;

import { createReducer } from "redux-starter-kit";
import * as TYPES from "./types";
import fetchAdminStatsReducer from "./reducers/fetchAdminStats";
import fetchRevenueByPeriodsReducer from "./reducers/fetchRevenueByPeriods";

const INITIAL_STATE_STATS = {
  revenues: {
    isLoading: false
  },
  loading: false,
  items: []
};

const statsReducer = createReducer(INITIAL_STATE_STATS, {
  [TYPES.FETCH_ADMIN_STATS_FAILURE]: fetchAdminStatsReducer,
  [TYPES.FETCH_ADMIN_STATS_REQUEST]: fetchAdminStatsReducer,
  [TYPES.FETCH_ADMIN_STATS_SUCCESS]: fetchAdminStatsReducer,
  [TYPES.FETCH_ADMIN_REVENUE_BY_PERIOD_FAILURE]: fetchRevenueByPeriodsReducer,
  [TYPES.FETCH_ADMIN_REVENUE_BY_PERIOD_REQUEST]: fetchRevenueByPeriodsReducer,
  [TYPES.FETCH_ADMIN_REVENUE_BY_PERIOD_SUCCESS]: fetchRevenueByPeriodsReducer
});

export default {
  stats: statsReducer
};

export { default as fetchAdminStats } from "./actions/fetchAdminStats";
export {
  default as fetchRevenueByPeriods
} from "./actions/fetchRevenueByPeriods";

export { default as getStats } from "./selectors/getStats";

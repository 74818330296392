import { createReducer } from "redux-starter-kit";

import * as TYPES from "./types";
import fetchUserTokenReducer from "./reducers/fetchUserToken";
import fetchGroupsReducer from "./reducers/fetchGroups";
import setEmailReducer from "./reducers/setEmail";
import logoutReducer from "./reducers/logout";
import currentUserReducer from "./reducers/currentUser";

const initial: TYPES.State = {
  token: undefined,
  loading: false,
  tokenPayload: {},
  email: "",
  currentUserId: undefined,
  type: undefined,
  groups: [],
  authError: ""
};

const reducer = createReducer(initial, {
  [TYPES.FETCH_TOKEN_FAILURE]: fetchUserTokenReducer,
  [TYPES.FETCH_TOKEN_REQUEST]: fetchUserTokenReducer,
  [TYPES.FETCH_TOKEN_SUCCESS]: fetchUserTokenReducer,
  [TYPES.FETCH_GROUPS_FAILURE]: fetchGroupsReducer,
  [TYPES.FETCH_GROUPS_REQUEST]: fetchGroupsReducer,
  [TYPES.FETCH_GROUPS_SUCCESS]: fetchGroupsReducer,
  [TYPES.FETCH_CURRENTUSER_REQUEST]: currentUserReducer,
  [TYPES.FETCH_CURRENTUSER_SUCCESS]: currentUserReducer,
  [TYPES.FETCH_CURRENTUSER_FAILURE]: currentUserReducer,
  [TYPES.USER_LOGIN_SUCCESS]: currentUserReducer,
  [TYPES.SET_AUTH_EMAIL]: setEmailReducer,
  [TYPES.LOGOUT]: logoutReducer
});

export default reducer;

export { default as login } from "./actions/login";
export { default as forgetPassword } from "./actions/forgetPassword";
export { default as fetchUserToken } from "./actions/fetchUserToken";
export { default as setEmail } from "./actions/setEmail";
export { default as logout } from "./actions/logout";
export { default as validateEmail } from "./actions/validateEmail";

export { default as getLoading } from "./selectors/getLoading";
export { default as getUserToken } from "./selectors/getUserToken";
export { default as getEmail } from "./selectors/getEmail";
export { default as getAuthError } from "./selectors/getAuthError";
export { default as getRoles } from "./selectors/getRoles";
export { default as getCurrentUserId } from "./selectors/getCurrentUserId";
export { default as isLoggedIn } from "./selectors/isLoggedIn";
export { default as getUserType } from "./selectors/getUserType";
export { default as getCurrentUser } from "./selectors/getCurrentUser";
export { default as getAuthPhoneNumber } from "./selectors/getPhoneNumber";

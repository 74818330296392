import Yup from "../../../../../../../YupValidation";

const validationSchema = (isGeneratedToken, status) => {
  const schema = {
    ...(() => {
      if (status === "load") {
        return {
          loadLength: Yup.number()
            .required("Please fill out this field")
            .positive()
            .integer(),
          equipmentType: Yup.string().required("Please fill out this field"),
          loadWeight: Yup.number()
            .required("Please fill out this field")
            .positive()
            .integer(),
          firstPickUp: Yup.object().shape({
            formatted_address: Yup.string().required(
              "Please fill out this field"
            )
          }),
          finalDelivery: Yup.object().shape({
            formatted_address: Yup.string().required(
              "Please fill out this field"
            )
          }),
          pickUpOpen: Yup.string().required("Please fill out this field"),
          pickUpClose: Yup.string().required("Please fill out this field"),
          deliverBy: Yup.string().required("Please fill out this field"),
          ...(() =>
            !isGeneratedToken
              ? {
                  loadNumber: Yup.string().required(
                    "Please fill out this field"
                  )
                }
              : {})()
        };
      }
      return {};
    })()
  };

  return Yup.object().shape(schema);
};

export default validationSchema;

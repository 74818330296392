import formatStatus from "../../../../helpers/format/formatStatus";
import formatAddress from "../../../../helpers/format/formatAddress";

const columns = [
  {
    key: "company_name",
    name: "Name"
    // filter: "text",
    // filter_key: "company_name",
    // sort: true
  },
  {
    key: "address",
    name: "Address",
    // sort: true,
    format: formatAddress
  },
  {
    key: "phone",
    name: "Phone"
    // sort: true
  },
  {
    key: "mc",
    name: "MC #"
    // filter: "text",
    // filter_key: "mc",
    // sort: true
  },
  {
    key: "dot",
    name: "DOT #"
    // filter: "text",
    // filter_key: "dot",
    // sort: true
  },
  {
    key: "tax_id",
    name: "Tax ID"
    // filter: "text",
    // filter_key: "tax_id",
    // sort: true
  },
  {
    key: "credit_approved",
    name: "HP Status",
    format: formatStatus
  },
  {
    key: "factoring_client_count",
    name: "Connected Client"
    // sort: true
  },
  {
    key: "funding_request_count",
    name: "Total FRs"
    // sort: true
  },
  {
    key: "transferred_debtor_count",
    name: "Transferred Debtor"
    // sort: true
  },
  {
    key: "more",
    name: "More"
  }
];

export default columns;

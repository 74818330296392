import { assign } from "lodash";
import { PayloadAction } from "redux-starter-kit";
import { FETCH_LINEITEMS_SUCCESS, State } from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case FETCH_LINEITEMS_SUCCESS:
      return {
        ...state,
        lineItems: assign({}, state.lineItems, action.payload)
      };
    default:
      return state;
  }
};

import axios from "axios";

const getDebtors = async (factoring, dba, mc) => {
  const dbaResponse = await axios({
    url: `/factoring/debtors/`,
    method: "GET",
    params: {
      factoring,
      dba,
      limit: 20
    }
  });

  const mcResponse = await axios({
    url: `/factoring/debtors/`,
    method: "GET",
    params: {
      factoring,
      mc,
      limit: 20
    }
  });
  return mcResponse.data.results.concat(dbaResponse.data.results);
};

export default async ({ factoring, dba, mc }) => {
  try {
    return await getDebtors(factoring, dba, mc);
  } catch (e) {
    return [];
  }
};

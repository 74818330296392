import React, { ReactNode } from "react";
import { Button } from "@material-ui/core";

interface Props {
  children?: ReactNode;
  mc?: string;
  dot?: string;
}

const FMCSALink = ({
  mc,
  dot,
  children = "FMCSA Report"
}: Props): JSX.Element | null =>
  !mc && !dot ? null : (
    // @ts-ignore
    <form
      method="post"
      action="https://safer.fmcsa.dot.gov/query.asp"
      target="_blank"
    >
      <input name="searchtype" value="ANY" type="hidden" />
      <input name="query_type" value="queryCarrierSnapshot" type="hidden" />
      {mc ? (
        <input name="query_param" value="MC_MX" type="hidden" />
      ) : (
        <input name="query_param" value="USDOT" type="hidden" />
      )}
      <input name="query_string" value={mc || dot} type="hidden" />
      <a
        href="https://safer.fmcsa.dot.gov/query.asp"
        color="secondary"
        onClick={(): null => null}
      >
        <Button
          style={{ background: "transparent", border: 0, padding: 0 }}
          type="submit"
        >
          {children}
        </Button>
      </a>
    </form>
  );

export default FMCSALink;

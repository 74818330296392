import React from "react";
import {
  Divider,
  FormControl,
  Grid,
  List,
  MenuItem,
  Select
} from "@material-ui/core";
import PropTypes from "prop-types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { isArray, isString, get } from "lodash";

import openFileDialog from "../../helpers/openFileDialog";
import UploadFileView from "./UploadFileView";
import ErrorFileView from "./ErrorFileView";

const getBase64FromFile = file => {
  const reader = new FileReader();
  reader.onload = () => {
    file.base64 = reader.result;
  };

  reader.readAsDataURL(get(file, "file", file));
};

const VisibilityMapping = vis => {
  if (Array.isArray(vis)) {
    if (
      (vis.includes("shipper") ||
      vis.includes("debtor")) &&
      vis.includes("carrier")
    ) {
      return "Carrier & Shipper/Debtor";
    }

    if (vis.includes("shipper") ||  vis.includes("debtor")) {
      return "Shipper/Debtor Only";
    }

    if (vis.includes("carrier")) {
      return "Carrier Only";
    }
  }
};

const UploadListFilesView = ({
  files,
  categories,
  categoriesHandler,
  uploadBtnRef,
  pickFiles,
  requestId,
  multiple,
  noCategories = false,
  single,
  defaultCategoryValue = "",
  openDialog,
  visibleTo,
  visibleToHandler
}) => {
  return (
    <Grid>
      <List dense>
        {files.map((file, i) => (
          <Grid
            container
            spacing={16}
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
            style={{ width: "100%" }}
          >
            <Grid
              item
              sm={noCategories ? 12 : visibleTo ? 6 : 9}
              xs={12}
              zeroMinWidth
            >
              {file.status === "error" ? (
                <ErrorFileView
                  getBase64FromFile={getBase64FromFile}
                  item={file}
                />
              ) : (
                <UploadFileView
                  getBase64FromFile={getBase64FromFile}
                  item={file}
                />
              )}
              <Divider component="li" />
            </Grid>
            {!noCategories &&
              categories &&
              isArray(categories) &&
              categories.length > 0 && (
                <Grid item sm={3} xs={12} zeroMinWidth>
                  <FormControl fullWidth>
                    <Select
                      inputProps={{ id: "category" }}
                      value={
                        file && file.categories
                          ? file.categories.split(",")
                          : [defaultCategoryValue]
                      }
                      onChange={event => categoriesHandler(event, i)}
                      displayEmpty
                      multiple={multiple}
                    >
                      <MenuItem value="" disabled>
                        Category
                      </MenuItem>
                      {categories.map(category =>
                        isString(category) ? (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        ) : (
                          <MenuItem key={category.value} value={category.value}>
                            {category.text}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            {visibleTo && (
              <Grid item sm={3} xs={12} zeroMinWidth>
                <FormControl fullWidth>
                  <Select
                    inputProps={{ id: "visible_to" }}
                    value={VisibilityMapping(file.visible_to)}
                    onChange={async event => {
                      let visibility = [];
                      const value = event.target.value;
                      if (value === "Carrier Only") {
                        visibility = ["carrier"];
                      } else if (value === "Shipper/Debtor Only") {
                        visibility = ["shipper", "debtor"];
                      } else if (value === "Carrier & Shipper/Debtor") {
                        visibility = ["carrier", "shipper", "debtor"];
                      }
                      await visibleToHandler(visibility, i);
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Visible To
                    </MenuItem>
                    {[
                      "Carrier Only",
                      "Shipper/Debtor Only",
                      "Carrier & Shipper/Debtor"
                    ].map(text => (
                      <MenuItem key={text} value={text}>
                        {text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        ))}
      </List>
      {!single && (
        <AddCircleIcon
          style={{ float: "right", minHeight: 60 }}
          color="disabled"
          fontSize="large"
          onClick={() => openFileDialog(uploadBtnRef)}
        />
      )}
      <input
        type="file"
        ref={ref => (uploadBtnRef = ref)}
        style={{ display: "none" }}
        onChange={e => {
          if (
            e.currentTarget.files[0].type &&
            e.currentTarget.files[0].type.match("image.*|application/pdf") ===
              null
          ) {
            openDialog();
          }
          pickFiles(e.target.files[0], requestId);
          e.currentTarget.value = "";
        }}
      />
    </Grid>
  );
};

UploadListFilesView.propTypes = {
  files: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  categoriesHandler: PropTypes.func.isRequired,
  requestId: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired
};

export default UploadListFilesView;

import { createAction } from "redux-starter-kit";
import * as TYPES from "../types";

const updateVisibleTo = payload => async (dispatch, getState, { api }) => {
  const { files } = getState().upload;
  const newFiles = files.slice(0);
  files[payload.position].visible_to = payload.visible_to;

  const file = files[payload.position];
  let response = {};
  response = await api.factoring.updateAttachment(
    file.requestId,
    file.file_id,
    {
      visible_to: payload.visible_to
    }
  );

  dispatch(
    createAction(TYPES.UPDATE_VISIBLE_TO)({
      ...payload,
      files: newFiles
    })
  );
  return response;
};

export default updateVisibleTo;

import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, DialogActions } from "@material-ui/core";
import { range, values, filter, head } from "lodash";
import { Formik } from "formik";

import {
  getDialog,
  closeDialog,
  openSnackbar
} from "../../../../../modules/ui";
import { DialogTitle, Dialog, DialogContent } from "../../../../../components/Dialog";
import BoldInput from "../../../../../components/inputs/BoldInput";
import SubmitButton from "./Submit";
import { inviteMembers } from "../../../../../modules/groups";

interface Props {
  groupId: string;
}
interface InputProps {
  handleChange: (
    eventOrPath: React.ChangeEvent<any>
  ) => void | ((eventOrValue: unknown) => void);
  type: string;
  label: string;
}

function InviteInput(props: InputProps) {
  const [count, setCount] = useState(1);
  return (
    <Grid container direction="column" spacing={8}>
      {range(count).map(c => (
        <Grid item>
          <Grid container spacing={8} alignItems="center">
            <Grid item xs={10}>
              <BoldInput
                fullWidth
                onChange={props.handleChange}
                label={props.label}
                name={`${props.type}[${c}]`}
              />
            </Grid>
            {c === count - 1 && c < 100 && (
              <Grid item xs={1}>
                <Button
                  variant="contained"
                  style={{ marginTop: "26px" }}
                  onClick={() => setCount(count + 1)}
                >
                  +
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default ({ groupId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => dispatch(closeDialog()), [dispatch]);
  const dialog = useSelector(getDialog);
  const [count, setCount] = useState(1);
  return (
    <Dialog
      open={dialog.variant === "group-invite-member"}
      maxWidth="sm"
      fullWidth
    >
      <div style={{ margin: 16 }}>
        <Formik
          initialValues={{
            emails: [],
            phones: []
          }}
          onSubmit={async (formValues: any, { setSubmitting }: any) => {
            try {
              const emails = filter(values(formValues), email => email !== "");
              await dispatch(
                inviteMembers({
                  groupId,
                  emails: formValues.emails,
                  phones: formValues.phones
                })
              );
              dispatch(closeDialog());
              dispatch(openSnackbar("success", "Invited Members!"));
            } catch (err) {
              dispatch(openSnackbar("error", head(err) as string));
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit, handleChange }) => (
            <React.Fragment>
              <DialogTitle
                onClose={() => {
                  setCount(1);
                  handleClose();
                }}
              >
                Add New Members
              </DialogTitle>
              <DialogContent>
                <InviteInput
                  label="Carrier Email"
                  handleChange={handleChange}
                  type="emails"
                />
                <InviteInput
                  label="Carrier Phone"
                  handleChange={handleChange}
                  type="phones"
                />
              </DialogContent>
              <DialogActions>
                <SubmitButton onClick={() => handleSubmit()} />
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

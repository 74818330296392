import React from 'react';
import { createStyles, Fab, withStyles, WithStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import colors from '../../../../../../theme/colors';
import { openDialog } from '../../../../../../modules/ui';
import Can from 'apps/haulpay-frontend/src/components/Can';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = () =>
  createStyles({
    button: {
      boxShadow:
        '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
      background: colors.purple,
      color: colors.white,
      textTransform: 'none',
    },
  });

interface Props extends WithStyles<typeof styles> {
  onClick: () => void;
}

export default withStyles(styles)(({ classes }: Props): JSX.Element => {
  const dispatch = useDispatch();
  return (
    <Can
      perform="admin-factor_client-profile:makePaymentProfile"
      yes={() => (
        <Fab
          variant="extended"
          size="medium"
          className={classes.button}
          onClick={(): void => {
            dispatch(openDialog('create-payment-profile'));
          }}
        >
          Make Payment Profile
        </Fab>
      )}
    />
  );
});

import React from "react";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch
} from "@material-ui/core";
import { Formik, FormikConfig } from "formik";

import { DialogActions, DialogTitle, Dialog, DialogContent } from "../../../../../components/Dialog";
import CustomTextField from "../../../../../components/inputs/CustomTextField";
import PenniesInput from "../../../../../components/inputs/PenniesInput";

interface FormValues {
  fuelAdvance: boolean;
  fee: number;
  rate: number;
}

interface Props {
  handleClose: () => void;
  open: boolean;
  fuelAdvanceForm: FormikConfig<FormValues>;
}

const FuelAdvanceDialog = ({
  fuelAdvanceForm,
  open,
  handleClose
}: Props): JSX.Element => (
  <Formik {...fuelAdvanceForm}>
    {({
      values,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      handleChange
    }): JSX.Element => (
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle onClose={handleClose}>Fuel Advance Settings</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={16}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.fuelAdvance}
                      onChange={(): void => {
                        setFieldValue("fuelAdvance", !values.fuelAdvance);
                      }}
                      value="fuelAdvance"
                    />
                  }
                  label="Fuel Advance?"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <PenniesInput
                label="Fuel Advance Fee"
                name="fee"
                fullWidth
                withStyle
                value={values.fee}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Grid item>
              <CustomTextField
                label="Fuel Advance Rate"
                name="rate"
                fullWidth
                value={values.rate}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void => handleSubmit()}
            disabled={isSubmitting}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )}
  </Formik>
);

export default FuelAdvanceDialog;

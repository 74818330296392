import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  FETCH_GROUP_FAILURE,
  FETCH_GROUP_REQUEST,
  FETCH_GROUP_SUCCESS
} from "../types";

const fetchGroup = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(
      createAction(FETCH_GROUP_REQUEST)({
        id
      })
    );
    const response = await api.groups.getAdminGroup(id);
    const data = normalize(response, schema.group);
    dispatch(addEntities(data.entities));
    dispatch(createAction(FETCH_GROUP_SUCCESS)({ id }));
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_GROUP_FAILURE)(err));
    throw err;
  }
};

export default fetchGroup;

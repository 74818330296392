import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { Formik } from 'formik';
import SaveIcon from '@material-ui/icons/Save';

import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from '../../../../../components/Dialog';
import CustomTextField from '../../../../../components/inputs/CustomTextField';
import DatePicker from '../../../../../components/inputs/DatePicker';
import RoundedButton from '../../../../../components/buttons/RoundedButton';
import Colors from '../../../../../theme/colors';

const GridColumn = ({ children, numbers, ...props }) => (
  <Grid direction="column" item xs={numbers} {...props}>
    {children}
  </Grid>
);
const FOLLOWUP_STATUS_OPTIONS = userType => [
  {
    text: 'Resubmit Invoice',
    value: 'resubmit_invoice'
  },
  {
    text: 'Document Issue',
    value: 'document_issue'
  },
  {
    text: 'Promised To Pay',
    value: 'promise_to_pay'
  },
  {
    text: 'Debtor Issue',
    value: 'debtor_issue'
  },
  {
    text: 'Document Issue A',
    value: 'document_issue_a'
  },
  { 
    label: "Internal CF", 
    value: "internal_cf"
  }, 
  ...(() =>
    userType !== 'factoring_remote_admin'
      ? [
          {
            text: 'Document Issue B',
            value: 'document_issue_b'
          },
          {
            text: 'Document Issue C',
            value: 'document_issue_c'
          },
          {
            text: 'Pending Claim',
            value: 'pending_claim'
          },
          { 
            label: "Payment Issue", 
            value: "payment_issue" 
          }, 

          {
            text: 'Issue',
            value: 'issue'
          }
        ]
      : [])()
];
const FollowupStatusInput = ({
  userType,
  values,
  handleChange,
  handleBlur
}) => (
  <CustomTextField
    fullWidth
    select
    label="Followup Status"
    name="response_category"
    id="response_category"
    onChange={handleChange}
    onBlur={handleBlur}
    value={values.response_category}
  >
    {FOLLOWUP_STATUS_OPTIONS(userType).map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.text}
      </MenuItem>
    ))}
  </CustomTextField>
);

export default ({
  open,
  handleClose,
  values = {
    response_category: '',
    followup_date: new Date(),
    notes: ''
  },
  receivables,
  createDebtorPayment,
  openModal,
  userType
}) => (
  <Dialog open={open} maxWidth="md">
    <DialogTitle onClose={handleClose}>Add FollowUp</DialogTitle>
    <Formik
      onSubmit={async (fields, formikActions) => {
        try {
          await receivables.map(value =>
            createDebtorPayment(value.debtor, {
              ...fields,
              funding_requests: [value.funding_request]
            })
          );
          formikActions.setSubmitting(false);
        } catch (e) {}
      }}
      initialValues={values}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        errors,
        touched
      }) => {
        return (
          <React.Fragment>
            <DialogContent>
              <Grid
                container
                spacing={16}
                alignItems="center"
                style={{ padding: 10 }}
              >
                <GridColumn numbers={6}>
                  <FollowupStatusInput
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </GridColumn>
                <GridColumn numbers={6}>
                  <DatePicker
                    fullWidth
                    label="Next Followup Date"
                    name="followup_date"
                    id="followup_date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.followup_date}
                  />
                </GridColumn>
                <GridColumn numbers={12}>
                  <CustomTextField
                    fullWidth
                    label="Note/Details"
                    name="notes"
                    id="notes"
                    multiline
                    rows="5"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.notes}
                  />
                </GridColumn>
              </Grid>
            </DialogContent>
            <DialogActions>
              <RoundedButton
                variant="contained"
                color="primary"
                progressStyle={{ color: Colors.primary }}
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                <SaveIcon fontSize="small" />
                Save
              </RoundedButton>
            </DialogActions>
          </React.Fragment>
        );
      }}
    </Formik>
  </Dialog>
);

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";
import { addEntities } from "../../entities";
import {
  FETCH_QUERY_NOA_FAILURE,
  FETCH_QUERY_NOA_REQUEST,
  FETCH_QUERY_NOA_SUCCESS
} from "../types";

const debounced = debounce(
  async (dispatch, schema, api, limit, offset, ordering, filters) => {
    dispatch(
      createAction(FETCH_QUERY_NOA_REQUEST)({
        limit,
        offset,
        ordering,
        filters
      })
    );
    const response = await api.factoring.queryFetchNOA(
      ordering,
      limit,
      offset,
      filters
    );
    const data = normalize(response.results, [schema.noa]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_QUERY_NOA_SUCCESS)({
        items: data.result,
        count: response.count,
        limit,
        offset,
        ordering,
        filters
      })
    );
    return response;
  },
  1000,
  { trailing: true }
);

export default (ordering, limit, offset, filters = {}) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    await debounced(dispatch, schema, api, limit, offset, ordering, filters);
  } catch (err) {
    dispatch(createAction(FETCH_QUERY_NOA_FAILURE)(err));
    throw err;
  }
};

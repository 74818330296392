import { denormalize } from "normalizr";
import { get } from "lodash";
import { factoringProfile } from "../../../api/schema";

const getFactorClient = state => {
  try {
    const item = get(state, "factoringClient.id");

    const denormalized = denormalize(
      { item },
      { item: factoringProfile },
      state.entities
    );

    return denormalized.item || {};
  } catch (err) {
    return {};
  }
};

export default getFactorClient;

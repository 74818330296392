import { assign } from "lodash";
import {
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_REQUEST,
  DELETE_NOTE_SUCCESS
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case DELETE_NOTE_REQUEST:
      return {
        ...state,
        loadingFactorCompanyNotes: true
      };
    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        loadingFactorCompanyNotes: false,
        factorCompanyNotes: assign({}, state.factorCompanyNotes, {
          [action.payload.factorCompanyId]: state.factorCompanyNotes[
            action.payload.factorCompanyId
          ].filter(note => {
            return note !== action.payload.note;
          })
        })
      };
    case DELETE_NOTE_FAILURE:
      return {
        ...state,
        loadingFactorCompanyNotes: false
      };
    default:
      return state;
  }
};

import React from "react";

import MoneyColor from "../../../../../components/MoneyColor";
import formatPennies from "../../../../../helpers/format/formatPennies";
import formatStatus from "../../../../../helpers/format/formatStatus";
import formatDate from "../../../../../helpers/format/formatDate";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../../../../theme/colors";
import { Grid, IconButton } from "@material-ui/core";

const columns = [
  {
    key: ["amount", "isReversal", "amountRequested"],
    name: "Amnt",
    format: (amount: number, isReversal: boolean, amountRequested: number): JSX.Element => (
      <Grid container>
        {isReversal ?
              <IconButton>
                <FontAwesomeIcon
                  icon={faLongArrowAltRight}
                  color={colors.secondary}
                />
              </IconButton>
          : <MoneyColor amount={amount}>{formatPennies(amount || amountRequested)}</MoneyColor>
          }
     </Grid>
    )
  },
  {
    key: ["amount", "isReversal", "amountFunded"],
    name: "Amnt Funded",
    format: (amount: number, isReversal: boolean, amountFunded: number): JSX.Element => (
      <Grid container>
        {isReversal ?
          <MoneyColor amount={amount}>{formatPennies(amount)}</MoneyColor>
          : <MoneyColor amount={amountFunded}>{formatPennies(amountFunded)}</MoneyColor>
          }
     </Grid>
      
    )
  },
  {
    key: "payment_status",
    name: "Payment Status",
    format: formatStatus
  },
  {
    key: "approval_status",
    name: "Approval Status",
    format: formatStatus
  },
  {
    key: "category",
    name: "Category"
  },
  {
    key: "requestedAt",
    name: "Requested At",
    format: formatDate
  },
  {
    key: "actions",
    name: "Action"
  }
];

export default columns;

import { createAction } from "redux-starter-kit";
import {
  UPLOAD_USER_FILE_FAILURE,
  UPLOAD_USER_FILE_REQUEST,
  UPLOAD_USER_FILE_SUCCESS
} from "../types";

const uploadFile = data => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(UPLOAD_USER_FILE_REQUEST)());
    const response = await api.documents.upload(data);
    dispatch(createAction(UPLOAD_USER_FILE_SUCCESS)(response));
  } catch (err) {
    dispatch(createAction(UPLOAD_USER_FILE_FAILURE)(err));
    throw err;
  }
};

export default uploadFile;

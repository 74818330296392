import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import fetchAll from "../../../helpers/fetchAll";
import {
  FETCH_LINEITEMCATEGORIES_FAILURE,
  FETCH_LINEITEMCATEGORIES_REQUEST,
  FETCH_LINEITEMCATEGORIES_SUCCESS
} from "../types";

export default params => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_LINEITEMCATEGORIES_REQUEST)());
    const items = await fetchAll(() => api.factoring.getLineItemCategories(params));
    const data = normalize(items, [schema.lineItemCategory]);
    dispatch(addEntities(data.entities));
    dispatch(createAction(FETCH_LINEITEMCATEGORIES_SUCCESS)(data.result));
    return items;
  } catch (err) {
    dispatch(createAction(FETCH_LINEITEMCATEGORIES_FAILURE)(err));
    throw err;
  }
};

import throttle from "lodash/throttle";
import Yup from "../../../../YupValidation";
import { validateEmail } from "../../../../api/user";

const isNumeric = val => {
  const reg = /^\d+$/;
  const match = val.match(reg);
  return !!match;
};
const asyncEmailValidationMethod = async value => {
  if (!value) {
    return;
  }
  try {
    const response = await validateEmail(value);
    return response.status === "success";
  } catch (e) {}
  return false;
};
export const throttleEmailValidation = throttle(
  asyncEmailValidationMethod,
  1000
);
const InvitedValidationSchema = (action, landing) => {
  const schema = {
    company_name: Yup.string().required("Please fill out this field"),
    owner_first_name: Yup.string()
      .min(2, "Too Short First Name.")
      .max(50, "Too Long First Name.")
      .required("Please fill out this field"),
    owner_last_name: Yup.string()
      .min(2, "Too Short First Name.")
      .max(50, "Too Long First Name.")
      .required("Please fill out this field"),
    owner_phone_number: Yup.string()
      .required("Please fill out this field")
      .phone()
      .checkPhone(),
    mc: Yup.string()
      .min(1, "too short MC")
      .max(8, "too long MC")
      .when(["dot", "tax_id"], {
        is: "" || undefined,
        then: Yup.string().required("Please fill out this field"),
        otherwise: Yup.string().notRequired()
      })
      .test("mcIsNumber", "MC must be a number", val => !val || isNumeric(val)),
    dot: Yup.string()
      .min(1, "too short DOT")
      .test(
        "dotIsNumber",
        "DOT must be a number",
        val => !val || isNumeric(val)
      ),
    tax_id: Yup.string()
      .test(
        "containsDashes",
        "TAX ID must be 9 numbers.",
        val => !val || val.length === 9
      )
      .test(
        "containsDashes",
        "TAX ID must not contains dashes.",
        val => !val || !val.includes("-")
      )
      .test(
        "taxidIsNumber",
        "TAX ID must be a number.",
        val => !val || isNumeric(val)
      )
  };
  if (!landing) {
    schema.address = Yup.string().required("Please fill out this field");
    schema.terms = Yup.boolean()
      .oneOf([true], "Must Accept Terms and Conditions")
      .required("Must Accept Terms and Conditions");
  }
  if (!action) {
    schema.password = Yup.string().required("Please fill out this field");
    schema.email = Yup.string()
      .email("Please Enter a Valid Email.")
      .required("Please fill out this field")
      .test(
        "unique-email",
        `This email already exists. Please <a href="${process.env.REACT_APP_COMFREIGHT_URL}login?next=/haul-pay/join">Click Here</a> to login and complete`,
        value => throttleEmailValidation(value)
      );
  } else {
    schema.email = Yup.string()
      .email("Please Enter a Valid Email.")
      .required("Please fill out this field");
  }
  return Yup.object().shape(schema);
};

export default InvitedValidationSchema;

const formatLocation = (address = {}) => {
  // All fields
  if (
    address.street_one &&
    address.street_two &&
    address.city &&
    address.state &&
    address.zip
  ) {
    return `${address.street_one} ${address.street_two}, ${address.city}, ${
      address.state
    } ${address.zip}`;
  }
  // Missing Street Two
  if (address.street_one && address.city && address.state && address.zip) {
    return `${address.street_one}, ${address.city}, ${address.state} ${
      address.zip
    }`;
  }

  // Only City / State
  if (address.city && address.state) {
    return `${address.city}, ${address.state}`;
  }
};

export default formatLocation;

const tabs = [
  {
    label: "All",
    route: "/admin/factorclients/",
    value: "all",
    permission: "admin-factorclients-all:view",
  },
  {
    label: "Lead In",
    route: "/admin/factorclients/lead_in",
    value: "lead_in",
    permission: "admin-factorclients-lead_in:view",
  },
  {
    label: "New",
    route: "/admin/factorclients/new",
    value: "new",
    permission: "admin-factorclients-new:view",
  },
  {
    label: "Doc Review",
    route: "/admin/factorclients/document_review",
    value: "document_review",
    badge: "0",
    permission: "admin-factorclients-document_review:view",
  },
  {
    label: "Doc Issue",
    route: "/admin/factorclients/document_issue",
    value: "document_issue",
    permission: "admin-factorclients-document_issue:view",
  },
  {
    label: "Pending",
    route: "/admin/factorclients/pending",
    value: "pending",
    permission: "admin-factorclients-pending:view",
  },
  {
    label: "Contract Out",
    route: "/admin/factorclients/contract_out",
    value: "contract_out",
    permission: "admin-factorclients-contract_out:view",
  },
  {
    label: "UCC Hold",
    route: "/admin/factorclients/ucc_hold",
    value: "ucc_hold",
    permission: "admin-factorclients-ucc_hold:view",
  },
  {
    label: "No Auth.",
    route: "/admin/factorclients/no_auth",
    value: "not_authorized",
    permission: "admin-factorclients-not_authorized:view",
  },
  {
    label: "Approved",
    route: "/admin/factorclients/approved",
    value: "approved",
    permission: "admin-factorclients-approved:view",
  },
  {
    label: "Declined",
    route: "/admin/factorclients/declined",
    value: "declined",
    permission: "admin-factorclients-declined:view",
  },
  {
    label: "Dead",
    route: "/admin/factorclients/dead",
    value: "dead",
    permission: "admin-factorclients-dead:view",
  }
];

export default tabs;

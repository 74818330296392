import { Grid, IconButton, List, Tooltip, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { AccountCircle } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { get } from "lodash";

import Can from "../../../../components/Can";
import Link from "../../../../components/Link";
import BoldInput from "../../../../components/inputs/BoldInput";
import ListItem from "../../../../components/ListItem";
import Card from "../../../../components/Card";
import formatStatus from "../../../../helpers/format/formatStatus";
import formatAddress from "../../../../helpers/format/formatAddress";
import formatPhone from "../../../../helpers/format/formatPhone";
import getPhone from "../../../../helpers/getPhone";
import getExtension from "../../../../helpers/getExtension";
import {
  getFactoringProfileById,
  getPaymentProfile
} from "../../../../modules/factoring";
import maskEIN from "../../../../helpers/format/maskEIN";
import CarrierAutoComplete from "../../../../components/inputs/CarrierAutoComplete";

interface Props {
  factoring: string;
  paymentProfile: string;
  factoringCompany: {};
  status?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  factoringClientId?: string;
}

function FactoringClient({ id }: any) {
  const client = useSelector(getFactoringProfileById(id));
  return (
    <List dense>
      <ListItem>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          direction="row-reverse"
        >
          <Grid item>
            <Tooltip title="View Profile">
              <Link to={`/admin/factorclients/${id}/profile`}>
                <IconButton color="primary">
                  <AccountCircle />
                </IconButton>
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Name"
          value={get(client, ["company_profile", "name"], "")}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Phone"
          value={`${formatPhone(
            getPhone(get(client, ["owner_profile", "phone_number_cell"]))
          )}${
            getExtension(get(client, ["owner_profile", "phone_number_cell"]))
              ? `;${getExtension(
                  get(client, ["owner_profile", "phone_number_cell"])
                )}`
              : ""
          }`}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Pre & Post Doc Issue Email"
          value={get(client, ["document_issue_email"], "")}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="MC"
          value={get(client, ["company_profile", "mc"], "")}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="DOT"
          value={get(client, ["company_profile", "dot"], "")}
          disabled
        />
      </ListItem>
      <Can
        perform="taxid:view"
        yes={() => (
          <ListItem>
            <BoldInput
              fullWidth
              label="TAX ID"
              value={
                (get(client, ["company_profile", "tax_id"], "") || "").includes(
                  "tok"
                )
                  ? maskEIN(get(client, ["company_profile", "tax_id"], ""))
                  : get(client, ["company_profile", "tax_id"], "")
              }
              disabled
            />
          </ListItem>
        )}
      />
      <ListItem>
        <BoldInput
          fullWidth
          label="Factoring Status"
          value={formatStatus(get(client, ["factoring_approved"], "pending"))}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Address"
          value={formatAddress(get(client, ["company_profile", "address"], {}))}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Contact"
          value={`${get(client, ["owner_profile", "name_first"], "")} ${get(
            client,
            ["owner_profile", "name_last"],
            ""
          )}`}
          disabled
        />
      </ListItem>
    </List>
  );
}

function CarrierInfo({
  id,
  factoringCompany,
  status,
  setFieldValue,
  factoringClientId
}: any) {
  const [client, setClient] = useState(useSelector(getPaymentProfile(id)));
  return (
    <List dense>
      <ListItem>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          direction="row-reverse"
        >
          <Grid item>
            <Tooltip title="View Profile">
              <Link to={`/admin/payment/${id}/profile`}>
                <IconButton color="primary">
                  <AccountCircle />
                </IconButton>
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </ListItem>
      {["pending_delivery", "incomplete", "special_pending"].includes(
        status
      ) && (
        <ListItem>
          <Grid container>
            <CarrierAutoComplete
              onNoOptionClick={false}
              value={{
                ...client,
                label: get(client, ["company_name"], "")
              }}
              label="Carrier Name"
              placeholder="Select a Carrier"
              onChange={(carrier: any) => {
                setClient(carrier);
                setFieldValue("paymentProfileId", get(carrier, ["id"], ""));
              }}
              brokerClientId={factoringClientId}
            />
          </Grid>
        </ListItem>
      )}
      <ListItem>
        <BoldInput
          fullWidth
          label="Name"
          value={get(client, ["company_name"], "")}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Phone"
          value={`${formatPhone(
            getPhone(get(client, ["company_phone_number"]))
          )}${
            getExtension(get(client, ["company_phone_number"]))
              ? `;${getExtension(get(client, ["company_phone_number"]))}`
              : ""
          }`}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Email"
          value={get(client, ["owner_email"], "")}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="MC"
          value={get(client, ["company_mc"], "")}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="DOT"
          value={get(client, ["company_dot"], "")}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Payment Profile Status"
          value={formatStatus(get(client, ["status"], ""))}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Address"
          value={formatAddress({
            city: get(client, ["company_address_city"], ""),
            country: get(client, ["company_address_country"], ""),
            state: get(client, ["company_address_state"], ""),
            street_one: get(client, ["company_address_street_one"], ""),
            street_two: get(client, ["company_address_street_two"], ""),
            zip: get(client, ["company_address_zip"], "")
          })}
          disabled
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Contact"
          value={`${get(client, ["owner_name_first"], "")} ${get(
            client,
            ["owner_name_last"],
            ""
          )}`}
          disabled
        />
      </ListItem>
      {get(factoringCompany, ["name"], "") && (
        <ListItem>
          <BoldInput
            fullWidth
            label="Carrier's Factor"
            value={get(factoringCompany, ["name"], "")}
            disabled
          />
        </ListItem>
      )}
    </List>
  );
}

function FactoringClientCarrierInfo({
  factoring,
  paymentProfile,
  factoringCompany,
  status,
  setFieldValue,
  factoringClientId
}: Props): JSX.Element {
  const [tab, setTab] = useState(0);
  return (
    <Card>
      <Tabs
        value={tab}
        onChange={(event, index) => {
          setTab(index);
        }}
        indicatorColor="secondary"
        textColor="secondary"
        fullWidth
      >
        <Tab label="Factoring Client Info" />
        <Tab label="Carrier Info" />
      </Tabs>
      {tab === 0 && <FactoringClient id={factoring} />}
      {tab === 1 && (
        <CarrierInfo
          id={paymentProfile}
          factoringCompany={factoringCompany}
          status={status}
          setFieldValue={setFieldValue}
          factoringClientId={factoringClientId}
        />
      )}
    </Card>
  );
}

export default FactoringClientCarrierInfo;

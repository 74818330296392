import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';

import { Link } from 'react-router-dom';
import Table from '../../../../components/Table';
import columns from './columns';

import {
  fetchNOA,
  getCount,
  getNOA,
  getFilters,
  getIsLoading,
  getPage,
  getRowsPerPage,
  getRowsPerPageOptions,
  getSortBy,
  getSortDirection,
  handleChangePage as changePage,
  handleChangeRowsPerPage as changeRowsPerPage,
  handleSearchFilter as searchFilter,
  handleSort as sort
} from '../../../../modules/NOA';
import { sendNOA, updateNOADates } from '../../../../modules/factoring';
import {
  openDialog,
  getDialog,
  closeDialog,
  openSnackbar
} from '../../../../modules/ui';

import NoaSummary from '../../../../components/NOASummary';
import { placeNOAForm } from '../../FactorClient/ConnectedDebtors';
import { noaActiveForm } from '../../FundingRequest';
import MoreLinks from '../../Debtor/ConnectedClients/components/MoreLinks';
import AuditLogDialog from '../../Debtor/ConnectedClients/components/AuditLogDialog';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import { deleteDebtorRelationship, updateFactoringDebtor } from '../../../../modules/debtor';
import NOADropDown from '../../../../components/NOADropDown';


export default function NOAPanel() {
  const [formattedRow, setFormattedRow] = useState([]);
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const NOA = useSelector(getNOA);
  const sortDirection = useSelector(getSortDirection);
  const sortBy = useSelector(getSortBy);
  const count = useSelector(getCount);
  const page = useSelector(getPage);
  const rowsPerPage = useSelector(getRowsPerPage);
  const rowsPerPageOptions = useSelector(getRowsPerPageOptions);
  const filters = useSelector(getFilters);
  const dialog = useSelector(getDialog);
  const handleSort = useCallback((...args) => dispatch(sort(...args)), [
    dispatch
  ]);
  const handleChangePage = useCallback(
    (...args) => dispatch(changePage(...args)),
    [dispatch]
  );
  const handleChangeRowsPerPage = useCallback(
    (...args) => dispatch(changeRowsPerPage(...args)),
    [dispatch]
  );
  const handleSearchFilter = useCallback(
    (...args) => dispatch(searchFilter(...args)),
    [dispatch]
  );
  const handleOpenDialog = useCallback(
    (...args) => dispatch(openDialog(...args)),
    [dispatch]
  );
  const handleCloseDialog = useCallback(
    (...args) => dispatch(closeDialog(...args)),
    [dispatch]
  );
  const handleConfirmDeleteRelationship = useCallback(
    async (id, factoringId) => {
      try {
        await dispatch(deleteDebtorRelationship(id, factoringId));
        handleOpenDialog('success', 'Removed Connected Client!');
      } catch (err) {
        handleOpenDialog('error', head(err));
      } finally {
        await dispatch(fetchNOA('-created', rowsPerPage, page, filters));
        handleCloseDialog();
      }
    },
    [dispatch]
  );

  const openSnackbarCallback = useCallback(
    (...args) => dispatch(openSnackbar(...args)),
    [dispatch]
  );
  const sendNOAHandler = useCallback((...args) => dispatch(sendNOA(...args)));
  const updateNOADatesHandler = useCallback((...args) =>
    dispatch(updateNOADates()(...args))
  );

  useEffect(() => {
    async function fetchNOAData() {
      try {
        await dispatch(
          fetchNOA(
            sortDirection === 'asc' ? sortBy : `-${sortBy}`,
            rowsPerPage,
            0,
            {}
          )
        );
      } catch (e) {}
    }
    fetchNOAData();
  }, []);

  const NOAPanelForm = relationship => ({
    onSubmit: async (values, { setSubmitting }) => {
      const factoringId = get(relationship, 'factoring.id');
      try {
        await dispatch(updateFactoringDebtor(relationship.debtor, factoringId, values));
        openSnackbarCallback("success", "NOA Status Successfully updated!");
      } catch (err) {
        openSnackbarCallback("error", head(err));
      } finally {
        setSubmitting(false);
      }
    }
  })
  

  useEffect(() => {
    setFormattedRow(
      map(NOA, row => {
        return {
          ...row,
          debtor_name: (
            <Link to={`/admin/debtors/${row.debtor}/profile`}>
              {row.debtor_name}
            </Link>
          ),
          client_name: (
            <Link to={`/admin/factorclients/${row.factoring.id}/profile`}>
              {row.factoring.company_profile.name}
            </Link>
          ),
          noa_summary: ({ values, setFieldValue, handleBlur }) => {
            return (
            <NOADropDown 
                fullWidth
                row={{
                  ...row,
                  relationship_created: row.created
                }}
                setFieldValue={setFieldValue}
                updatedValue={values.noa}
                onBlur={handleBlur}
                disabled={isEmpty(row.attachments)}
                placeNOAForm={placeNOAForm(
                  openSnackbarCallback,
                  row.attachments,
                  sendNOAHandler,
                  row.debtor,
                  get(row, 'factoring.id')
                )}
                clientName={get(row, 'factoring.company_profile.name', '')}
                debtorName={row.debtor_name}
                helperText={isEmpty(row.attachments) && 'No NOA on File.'}
            />
          )
            },
          more: ({ handleSubmit }) => (
            <MoreLinks
              relationshipId={row.id}
              handleSubmit={handleSubmit}
              handleAuditLogClick={() =>
                handleOpenDialog('auditlog', 'Audit Log', '', {
                  relationshipId: row.id
                })
              }
              handleDeleteRelation={() => {
                handleOpenDialog(
                  'delete-relation',
                  'Delete Debtor Relationship',
                  'Are you sure?',
                  {
                    factoring: row.factoring.id,
                    debtor: row.debtor
                  }
                );
              }}
            />
          )
        };
      })
    );
  }, [NOA]);

  return (
    <div style={{ paddingLeft: 16 }}>
      <Table
        columns={columns}
        rows={formattedRow}
        isLoading={isLoading}
        handleSort={handleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        rowsPerPageOptions={rowsPerPageOptions}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        filter
        filters={filters}
        handleFilterChange={handleSearchFilter}
        allowEmpty
        formikRow={NOAPanelForm}
      />
      {dialog.variant === 'auditlog' && (
        <AuditLogDialog
          open={dialog.show}
          handleClose={handleCloseDialog}
          relationshipId={get(dialog, ['data', 'relationshipId'])}
        />
      )}
      {dialog.variant === 'delete-relation' && (
        <ConfirmDialog
          open={dialog.show}
          title={dialog.title}
          message={dialog.message}
          handleConfirm={() =>
            handleConfirmDeleteRelationship(
              get(dialog, ['data', 'debtor']),
              get(dialog, ['data', 'factoring'])
            )
          }
          handleExit={() => handleCloseDialog()}
        />
      )}
    </div>
  );
}

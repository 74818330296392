import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  UPDATE_FACTORINGNOTE_FAILURE,
  UPDATE_FACTORINGNOTE_REQUEST,
  UPDATE_FACTORINGNOTE_SUCCESS
} from "../types";

const updateFactoringNote = (factoringId, noteId, note, attachments) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPDATE_FACTORINGNOTE_REQUEST)());
    const response = await api.factoring.updateFactoringClientNote(
      factoringId,
      noteId,
      note,
      attachments
    );
    const data = normalize(response, schema.notes);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(UPDATE_FACTORINGNOTE_SUCCESS)({
        [factoringId]: [data.result]
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_FACTORINGNOTE_FAILURE)(err));
    throw err;
  }
};

export default updateFactoringNote;

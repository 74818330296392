import axios, { AxiosRequestConfig } from "axios";
import omitEmptyKeys from "../../helpers/omitEmptyKeys";
import deleteCommaKeys from "../../helpers/deleteCommaKeys";

export const ledgerWalletHistory = async (
    userId: string, 
    factoringId: string,
    limit: number,
    ordering: string,
    filters: Record<string, string>
    ): Promise<any> => {
    const url = `/factoring/ledger_wallet/${userId}/history/${factoringId}/`;
    const options: AxiosRequestConfig = {
        url,
        method: 'GET',
        params: {
            ordering,
            limit,
            ...deleteCommaKeys(omitEmptyKeys(filters))
        }
    };
    const response = await axios(options);
    return response.data;
};

export const ledgerWalletBalance = async (userId: string, factoringId: string): Promise<any> => {
    const url = `/factoring/ledger_wallet/${userId}/balance/${factoringId}/`;
    const options: AxiosRequestConfig = {
        url,
        method: 'GET',
    };
    const response = await axios(options);
    return response.data;
};

export const stripeWalletBalance = async (userId: string, factoringId: string): Promise<any> => {
    const url = `/cmftstripe/${userId}/balance/${factoringId}/`;
    const options: AxiosRequestConfig = {
        url,
        method: 'GET',
    };
    const response = await axios(options);
    return response.data;
};

export const stripeWalletHistory = async (
    userId: string, 
    factoringId: string, 
    limit: number,
    ordering: string,
    filters: Record<string, string>
    ) => {
    const url = `/cmftstripe/${userId}/transactions/${factoringId}/`;
    const options: AxiosRequestConfig = {
        url,
        method: 'GET',
        params: {
            ordering,
            limit,
            ...deleteCommaKeys(omitEmptyKeys(filters))
        }
    };
    const response = await axios(options);
    return response.data;
};


export default {
    stripeWalletBalance,
    ledgerWalletBalance,
    stripeWalletHistory,
    ledgerWalletHistory,
}
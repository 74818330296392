import { createAction } from "redux-starter-kit";
import {
  FETCH_TERMS_REQUEST,
  FETCH_TERMS_SUCCESS,
  FETCH_TERMS_FAILURE
} from "../types";

const fetchTerms = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_TERMS_REQUEST)());
    const response = await api.factoring.getTerms(id);
    dispatch(
      createAction(FETCH_TERMS_SUCCESS)({
        // [id]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_TERMS_FAILURE)(err));
    throw err;
  }
};

export default fetchTerms;

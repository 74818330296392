import {
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE
} from "../types";

const currentUser = (state, { type, payload }) => {
  switch (type) {
    case CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfileSuccess: payload
      };
    case CURRENT_USER_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default currentUser;

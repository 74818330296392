import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import React from "react";

const NestedMoreButton = ({ id }) => (
  <Link to={`/admin/fundingrequest/${id}`}>
    <IconButton key="close" aria-label="Close" style={{ color: "#2196f3" }}>
      <EditIcon />
    </IconButton>
  </Link>
);

export default NestedMoreButton;

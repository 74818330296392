import { Formik, FormikConfig } from "formik";
import { Card, Grid } from "@material-ui/core";
import React from "react";
import Header from "./Header";
import PaperSheet from "../PaperSheet";
import LoginForm from "./LoginForm";

interface Values {
  email: string;
  password: string;
}

interface Props {
  form: FormikConfig<Values>;
  userType?: string;
  loginError?: string;
}

const PureLoginScreen = ({ form, userType, loginError='' }: Props): JSX.Element => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justify="center"
    lg={3}
    style={{ margin: "auto" }}
  >
    <Card style={{ margin: 20 }}>
      <Grid item style={{ padding: 20 }}>
        <div style={{ paddingTop: "32px" }}>
          <Header userType={userType} />
        </div>
      </Grid>
      <Grid item style={{ padding: 20 }}>
        {loginError && (
          <div style={{ margin: "20px" }}>
            <PaperSheet variant="error" message={loginError} />
          </div>
        )}
        <Formik {...form}>
          {(formik): JSX.Element => (
            <LoginForm {...formik} authLoading={formik.isSubmitting} />
          )}
        </Formik>
      </Grid>
    </Card>
  </Grid>
);

export default PureLoginScreen;

import { assign } from "lodash";
import {
  FETCH_QUERY_NOA_FAILURE,
  FETCH_QUERY_NOA_REQUEST,
  FETCH_QUERY_NOA_SUCCESS
} from "../types";

export default (
  state = {
    loading: false,
    ids: [],
    count: 0,
    offset: 0,
    limit: 20,
    ordering: undefined,
    rowsPerPageOptions: [5, 10, 20, 50, 100, 200]
  },
  action
) => {
  switch (action.type) {
    case FETCH_QUERY_NOA_REQUEST:
      return assign({}, state, {
        ids: [],
        loading: true,
        limit: action.payload.limit,
        offset: action.payload.offset,
        ordering: action.payload.ordering,
        filters: action.payload.filters
      });
    case FETCH_QUERY_NOA_SUCCESS:
      return assign({}, state, {
        loading: false,
        ids: action.payload.items,
        count: action.payload.count
      });
    case FETCH_QUERY_NOA_FAILURE:
      return assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
};

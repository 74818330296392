import React, { MouseEventHandler } from "react";
import { Button, Typography } from "@material-ui/core";

import SpinnerButton from "../buttons/SpinnerButton";
import { DialogActions, DialogTitle, Dialog, DialogContent } from "../Dialog";

interface Props {
  open: boolean;
  title?: string;
  message: string;
  handleConfirm: MouseEventHandler<HTMLButtonElement>;
  handleExit: MouseEventHandler<HTMLButtonElement>;
  isSubmitting?: boolean;
}

export default ({
  open,
  title = "Confirm",
  message = "Are you sure you want to do this?",
  handleConfirm,
  handleExit,
  isSubmitting
}: Props): JSX.Element => (
  <Dialog open={open} maxWidth="sm" fullWidth className="confirm_modal">
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" id="no" onClick={handleExit}>
        No
      </Button>
      <SpinnerButton
        id="yes"
        size="small"
        color="primary"
        onClick={handleConfirm}
        disabled={isSubmitting}
      >
        Yes
      </SpinnerButton>
    </DialogActions>
  </Dialog>
);

import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../components/Dialog";
import api from "../../../../../api";
import AuditTrail from "../../../../../components/AuditTrail";

function AuditLogDialog({ relationshipId, handleClose, show }) {
  const [auditLog, setAuditLog] = useState([]);
  useEffect(() => {
    const asyncFunction = async () => {
      const res = await api.payments.getPaymentProfileAuditLog(relationshipId);
      setAuditLog(res);
    };
    asyncFunction();
  }, [relationshipId]);
  return (
    <Dialog
      onClose={handleClose}
      open={show}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle onClose={handleClose}>Audit Log</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={16}
          style={{ minHeight: 150 }}
        >
          <Grid item>
            <AuditTrail auditLog={auditLog} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
export default AuditLogDialog;

import getAttachments from "./getAttachments";

const getNoaAttatchmentFile = state => {
  const attatchments = getAttachments(state);

  for (let el of attatchments) {
    if (el.category === "comfreight_noa") {
      return el.download_url;
    }
  }

  return "";
};

export default getNoaAttatchmentFile;

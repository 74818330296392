import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";

interface Props {
  missingBOL: boolean;
  missingRateCon: boolean;
  missingReceipt: boolean;
  blurryBOL: boolean;
  blurryRateCon: boolean;
  blurryReceipt: boolean;
  setFieldValue: Function;
}

const DocumentIssueTable = ({
  missingBOL,
  missingRateCon,
  missingReceipt,
  blurryBOL,
  blurryRateCon,
  blurryReceipt,
  setFieldValue
}: Props): JSX.Element => (
  <Table padding="none">
    <TableHead>
      <TableRow>
        <TableCell align="left">Doc Issue</TableCell>
        <TableCell>BOL</TableCell>
        <TableCell>RATE CON</TableCell>
        <TableCell>RECEIPT</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell align="left">Missing</TableCell>
        <TableCell>
          <Checkbox
            value={missingBOL}
            onChange={(event, checked): void => {
              setFieldValue("missingBOL", checked);
            }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            value={missingRateCon}
            onChange={(event, checked): void => {
              setFieldValue("missingRateCon", checked);
            }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            value={missingReceipt}
            onChange={(event, checked): void => {
              setFieldValue("missingReceipt", checked);
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left">Blurry</TableCell>
        <TableCell>
          <Checkbox
            value={blurryBOL}
            onChange={(event, checked): void => {
              setFieldValue("blurryBOL", checked);
            }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            value={blurryRateCon}
            onChange={(event, checked): void => {
              setFieldValue("blurryRateCon", checked);
            }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            value={blurryReceipt}
            onChange={(event, checked): void => {
              setFieldValue("blurryReceipt", checked);
            }}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default DocumentIssueTable;

import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import { findIndex } from "lodash";
import { Grid } from "@material-ui/core";

import MasterStar from "../../../../components/icons/MasterStar";
import withLocalTable from "../../../../components/withLocalTable";
import {
  deleteDebtor,
  fetchSimilarDebtors,
  getDebtorCompanyName,
  getDebtorMaster,
  getSimilarDebtors,
  queryDebtor,
  transferToMaster
} from "../../../../modules/debtor";
import withSelect from "../../../../components/withSelect";
import {
  closeDialog,
  closeSnackbar,
  getDialog,
  getSnackbar,
  openDialog
} from "../../../../modules/ui";
import PureSimilarDebtors from "./SimilarDebtors";
import tabs from "../tabs";
import MoreLinks from "./components/MoreLinks";
import columns from "./columns";

export const SimilarDebtors = PureSimilarDebtors;

const mapStateToProps = state => ({
  similarDebtors: getSimilarDebtors(state),
  dialog: getDialog(state),
  companyName: getDebtorCompanyName(state),
  snackbar: getSnackbar(state),
  master: getDebtorMaster(state)
});

const mapDispatchToProps = {
  fetchSimilarDebtors,
  openDialog,
  closeDialog,
  deleteDebtor,
  queryDebtor,
  transferToMaster,
  closeSnackbar
};

export default compose(
  withSelect,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      match: {
        params: { id }
      },
      fetchSimilarDebtors,
      similarDebtors,
      openDialog,
      transferToMaster,
      selected,
      clearSelected
    }) => ({
      columns,
      tabs: tabs(id, similarDebtors.length),
      navIndex: findIndex(tabs(id), tab => pathname === tab.route),
      handleRoute: route => push(route),
      id,
      similarDebtors: similarDebtors.map(debtor => ({
        ...debtor,
        phone: () => {},
        company_name: () => (
          <Grid container alignItems="center" spacing={8}>
            <Grid item>{debtor.company_name}</Grid>
            <Grid item>{debtor.master && <MasterStar />}</Grid>
          </Grid>
        ),
        more: () => (
          <MoreLinks
            link={`/admin/debtors/${debtor.id}/profile`}
            handleDelete={() => {
              openDialog(
                "confirm",
                "Confirm Delete",
                "Are you sure you want to delete this Debtor?",
                {
                  id: debtor.id
                }
              );
            }}
          />
        )
      })),
      handleTransferToMaster: async () => {
        const debtors = similarDebtors.filter(d => selected.includes(d.id));
        let message = `Are you sure you want to transfer ${debtors
          .map(d => d.company_name)
          .join(", ")} to this Debtor?`;
        const containsMaster = debtors.map(d => d.master).includes(true);
        if (containsMaster) {
          message =
            "The debtor you're about to transfer to this debtor is a master. Are you sure you want to complete this action?";
        }
        if (selected.length !== 0) {
          openDialog(
            undefined,
            "Transfer Debtor",
            message,
            undefined,
            "transfer-debtor"
          );
        }
      },
      confirmTransferToMaster: async () => {
        try {
          await transferToMaster(id, selected);
          await fetchSimilarDebtors(id);
        } catch (err) {
        } finally {
          clearSelected();
          closeDialog();
        }
      }
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchSimilarDebtors(this.props.id);
      this.props.queryDebtor(this.props.id);
    }
  }),
  withLocalTable("similarDebtors")
)(PureSimilarDebtors);

import { ThunkAction } from "redux-thunk";
import { Action } from "redux-starter-kit";

import { ThunkContext } from "../../types";

function validateEmail(
  email: string
): ThunkAction<Promise<void>, any, ThunkContext, Action<any>> {
  return (dispatch, getState, { api }): Promise<any> =>
    api.user.validateEmail(email);
}

export default validateEmail;

import {
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS
} from "../types";

const getSelectedProfile = (state, { type, payload }) => {
  switch (type) {
    case FETCH_USER_PROFILE_REQUEST:
      return {
        ...state,
        selectedUser: {
          isFetching: true,
          data: {}
        }
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        selectedUser: {
          isFetching: false,
          data: payload
        }
      };
    case FETCH_USER_PROFILE_FAILURE:
      return {
        ...state,
        selectedUser: {
          isFetching: false,
          data: {},
          error: payload
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default getSelectedProfile;

import axios, { AxiosRequestConfig } from "axios";
import compact from "lodash/compact";
import omitEmptyKeys from "../helpers/omitEmptyKeys";
import deleteCommaKeys from "../helpers/deleteCommaKeys";

const confirmInvite = async (
  token: string,
  createRelation: string
): Promise<any> => {
  const response = await axios.get(`/groups/invite/confirm/`, {
    params: { invite_token: token, create_relation: createRelation, send_update_email: true }
  });

  return response.data;
};

const getGroups = async (): Promise<any> => {
  const response = await axios.get(`/groups/`);

  return response.data;
};

const queryUsersGroups = async (
  userId: string,
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/groups/`,
    method: "GET",
    params: {
      user: userId,
      ordering,
      offset,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryAdminGroups = async (
  limit: number,
  ordering: string,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/groups/`,
    method: "GET",
    params: {
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryInvitedCarriers = async (
  limit: number,
  offset: number,
  ordering: string,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/`,
    method: "GET",
    params: {
      ordering,
      offset,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryFactoringCompanies = async (
  limit: number,
  ordering: string,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/`,
    method: "GET",
    params: {
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryConnectedDebtors = async (
  id: string,
  limit: number = 20,
  ordering: string = "-created",
  filters: Record<string, string> = {}
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/debtors/`,
    method: "GET",
    params: {
      group_id: id,
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryAdminGroupMembers = async (
  id: string,
  limit: number,
  ordering: string,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/groups/${id}/users/`,
    method: "GET",
    params: {
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getAdminGroup = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/groups/${id}/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const queryFundingRequests = async (
  id: string,
  limit: number,
  offset: number,
  ordering: string,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `factoring/funding/request/`,
    method: "GET",
    params: {
      group_id: id,
      ordering,
      limit,
      offset,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateGroup = async (
  id: string,
  group: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/groups/${id}/`,
    method: "PATCH",
    data: group
  };
  const response = await axios(options);
  return response.data;
};

const addAttachementToPaymentProfile = ({
  id,
  filename,
  category = ""
}: {
  id: string;
  filename: string;
  category?: string;
}): Promise<any> => {
  const url = `/factoring/payment_profiles/${id}/attachments/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      filename,
      category
    }
  };
  return axios(options);
};

const getPaymentProfileAttachments = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/${id}/attachments/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const updatePaymentProfileRelationship = async (
  factoringId: string,
  relationshipId: string,
  relationship: Record<string, any>,
  params: Record<string, any>,
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/${factoringId}/payment_profiles/${relationshipId}/`,
    method: "PATCH",
    data: relationship,
    params
  };
  const response = await axios(options);
  return response.data;
};

const updateDebtorRelationship = async (
  groupId: string,
  relationship: Record<string, any>
) => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/debtors/`,
    method: "PATCH",
    data: {
      ...relationship,
      group_id: groupId
    }
  };
  const response = await axios(options);
  return response.data;
};

const getVerificationInfo = async (paymentProfileId: string) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/${paymentProfileId}/verification/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};
interface InviteMembers {
  groupId: string;
  emails: string[];
  phones: string[];
}
const inviteMembers = async ({
  groupId,
  emails = [],
  phones = []
}: InviteMembers) => {
  const options: AxiosRequestConfig = {
    url: `/groups/${groupId}/invite/`,
    method: "POST",
    data: {
      emails: compact(emails),
      phone_numbers: compact(phones)
    }
  };
  const response = await axios(options);
  return response.data;
};

const getPaymentProfileRelationships = async (
  paymentProfileId: string,
  limit: number,
  ordering: string,
  filters: Record<string, string>
) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/${paymentProfileId}/relationships/`,
    method: "GET",
    params: {
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getCoBrokeringRelationships = async (
  limit: number,
  ordering: string,
  filters: Record<string, string>
) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/relationships/co_brokering/`,
    method: "GET",
    params: {
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getFundingRequests = async (
  paymentProfileId: string,
  limit: number,
  ordering: string,
  filters: Record<string, string>
) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/`,
    method: "GET",
    params: {
      payment_profile_id: paymentProfileId,
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getFactoringCompany = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/${id}/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getPendingInvite = async () => {
  const options: AxiosRequestConfig = {
    url: `/groups/invited/pending/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getConnectedGroups = async () => {
  const options: AxiosRequestConfig = {
    url: `/groups/invited/connected/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const createFactoringCompany = async (data: Record<string, any>) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const addCarrierToGroup = async (data: Record<string, any>) => {
  const options: AxiosRequestConfig = {
    url: `/groups/invited/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const updateFactoringCompany = async (id: string, data: string) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/${id}/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const changeFactoringCompany = async (data: Record<string, any>) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/change/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const deleteFactoringCompany = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/${id}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const updatePaymentMethod = async (
  paymentProfileId: string,
  relationshipsId: string,
  data: Record<string, any>
) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/${paymentProfileId}/relationships/${relationshipsId}/add_factor/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

export default {
  updatePaymentProfileRelationship,
  queryFundingRequests,
  getAdminGroup,
  queryAdminGroups,
  queryAdminGroupMembers,
  confirmInvite,
  getGroups,
  queryUsersGroups,
  queryInvitedCarriers,
  queryConnectedDebtors,
  updateGroup,
  getPaymentProfileAttachments,
  getPaymentProfileRelationships,
  addAttachementToPaymentProfile,
  updateDebtorRelationship,
  getVerificationInfo,
  inviteMembers,
  queryFactoringCompanies,
  getFundingRequests,
  getFactoringCompany,
  createFactoringCompany,
  updateFactoringCompany,
  deleteFactoringCompany,
  getPendingInvite,
  getConnectedGroups,
  changeFactoringCompany,
  updatePaymentMethod,
  addCarrierToGroup,
  getCoBrokeringRelationships
};

import { createAction } from "redux-starter-kit";
import { fetchLineItemCategories } from "../../factoring";

import {
  DELETE_LINEITEMS_CATEGORIES_FAILURE,
  DELETE_LINEITEMS_CATEGORIES_REQUEST,
  DELETE_LINEITEMS_CATEGORIES_SUCCESS
} from "../types";

export default lineItem => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(DELETE_LINEITEMS_CATEGORIES_REQUEST)());
    await api.finances.deleteLineItemsCategories(lineItem);
    dispatch(await fetchLineItemCategories());
    dispatch(createAction(DELETE_LINEITEMS_CATEGORIES_SUCCESS)());
  } catch (err) {
    dispatch(createAction(DELETE_LINEITEMS_CATEGORIES_FAILURE)(err));
    throw err;
  }
};

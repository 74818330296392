import React from "react";
import { PlaidLink } from "react-plaid-link";

export default function PlaidLinkComponent({
    submitToken,
    handleSubmit,
    children
}) {

    return (
        <PlaidLink
            style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                color: "white",
                fontSize: 14,
                width: "100%"
            }}
            clientName="Haulpay Web"
            env={process.env.REACT_APP_PLAID_ENV}
            product={["auth", "identity"]}
            publicKey={process.env.REACT_APP_PLAID_PUBLIC_KEY}
            onSuccess={(token, { accounts }) => {
                submitToken(
                    token,
                    accounts.map(account => account.id)
                );
                handleSubmit && handleSubmit();
            }}
        >
            {children}
        </PlaidLink>
    )
}

import { assign } from "lodash";
import {
  LOAD_USER_NOTES_ATTACHMENTS_FAILURE,
  LOAD_USER_NOTES_ATTACHMENTS_REQUEST,
  LOAD_USER_NOTES_ATTACHMENTS_SUCCESS
} from "../types";

export default (
  state = {
    attachments: {}
  },
  action
) => {
  switch (action.type) {
    case LOAD_USER_NOTES_ATTACHMENTS_REQUEST:
      return {
        ...state,
        attachments: assign({}, state.attachments, {
          [action.payload.attachmentId]: { isLoading: true }
        })
      };
    case LOAD_USER_NOTES_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        attachments: assign({}, state.attachments, {
          [action.payload.attachmentId]: { isLoading: false, ...action.payload }
        })
      };
    case LOAD_USER_NOTES_ATTACHMENTS_FAILURE:
      return {
        ...state,
        attachments: assign({}, state.attachments, {
          [action.payload.attachmentId]: { isLoading: false, ...action.payload }
        })
      };
    default:
      return state;
  }
};

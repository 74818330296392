import { openDialog } from "../../ui";

const handleDeleteUser = () => async (dispatch, getState, { api }) => {
  const state = getState();
  dispatch(
    openDialog(
      "confirm",
      "Confirm Delete File",
      "Are you sure you want to delete this user?",
      {
        userId: state?.user?.selectedUser?.data?.id
      },
      "delete_user_profile"
    )
  );
};

export default handleDeleteUser;

import { assign, isEqual } from "lodash";
import {
  FETCH_PAYMENTS_FAILURE,
  FETCH_PAYMENTS_REQUEST,
  FETCH_PAYMENTS_SUCCESS
} from "../types";

const sameRequest = (state, payload) =>
  state.limit === payload.limit && isEqual(state.filters, payload.filters);

export default (
  state = {
    loading: false,
    ids: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_PAYMENTS_REQUEST:
      return assign({}, state, {
        ids: [],
        loading: true,
        limit: action.payload.limit,
        filters: action.payload.filters
      });
    case FETCH_PAYMENTS_SUCCESS:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        });
      }
      return state;
    case FETCH_PAYMENTS_FAILURE:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          loading: false
        });
      }
      return state;
    default:
      return state;
  }
};

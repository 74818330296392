import { Grid, IconButton, Typography, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import React, { useState } from "react";
import { ArrayHelpers } from "formik";
import get from "lodash/get";

import { Category, File } from "./types";
import Colors from "../../theme/colors";
import FileCategoriesInput from "./FileCategoriesInput";
import ConfirmDialog from "../dialogs/ConfirmDialog";

const Eye = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon icon={faEye} color={Colors.secondary} size="xs" />;
};

const Trash = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon icon={faTrashAlt} color={Colors.danger} size="xs" />;
};

interface Props {
  multipleCategories: boolean;
  categories: Category[];
  index: number;
  file: File;
  category: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  values: {};
  arrayHelpers: ArrayHelpers;
  files: File[];
  // eslint-disable-next-line react/require-default-props
  filename?: string;
  // eslint-disable-next-line react/require-default-props
  defaultCategoryValue?: string;
}

export const viewAttachment = (file: File) => (): void => {
  const reader = new FileReader();
  reader.onload = (e): void => {
    const image = new Image();
    if (e.target) {
      const { result } = reader;
      if (result) {
        if (typeof result === "string") {
          image.src = result;
        } else {
          image.src = result.toString();
        }
      }
    }
    const w = window.open("", "_blank");
    if (w) {
      w.document.write(image.outerHTML);
    }
  };
  reader.readAsDataURL(file);
};

const FilePreview = ({
  multipleCategories,
  categories,
  defaultCategoryValue,
  ...attachment
}: Props): JSX.Element => {
  const [dialog, setDialog] = useState({
    variant: "",
    show: false,
    message: ""
  });
  return (
    <Grid container style={{ padding: 5 }}>
      {dialog.variant === "confirm" && (
        <ConfirmDialog
          open={dialog.show}
          message={dialog.message}
          handleConfirm={(): void => {
            attachment.arrayHelpers.remove(attachment.index)
          }}
          handleExit={(): void => setDialog({
            variant: "",
            show: false,
            message: ""
          })}
        />
      )}
      <Grid item xs={6}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Tooltip
              title={attachment.filename || get(attachment, "file.name", "")}
            >
              <Typography noWrap variant="caption">
                {attachment.filename || get(attachment, "file.name", "")}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            <IconButton
              onClick={(): void =>
                setDialog({
                  variant: "confirm",
                  show: true,
                  message: `Are you sure you want to delete ${attachment.filename || get(attachment, "file.name", "")}?`
                })}
            >
              <Trash />
            </IconButton>
            <IconButton onClick={viewAttachment(attachment.file)}>
              <Eye />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} justify="center" alignItems="center">
        <FileCategoriesInput
          multipleCategories={multipleCategories}
          categories={categories}
          defaultCategoryValue={defaultCategoryValue}
          {...attachment}
        />
      </Grid>
    </Grid>
  )
};

export default FilePreview;

import React, { CSSProperties } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  createStyles,
  Grid,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";

import Colors from "../theme/colors";
import { CFlogo, HPlogo } from "../theme/assets";
import RoundedButton from "./buttons/RoundedButton";
import { getUserType } from "../modules/auth";

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      color: Colors.white,
      fontSize: 14,
      paddingLeft: 20,
      paddingRight: 20,
      textTransform: "none"
    },
  });

interface Props extends WithStyles<typeof styles> {
  message: string | JSX.Element;
  userType: string;
  isInvite: boolean;
  isBoarding?: boolean;
  showAppBtn?: boolean;
  backButton?: JSX.Element | undefined;
  showOnboardingHaulpayApp: boolean;
  showOnboardingComfreightApp: boolean;
}

interface MapStateToProps {
  userType: string;
}

interface Outter {
  message: string | JSX.Element;
  isInvite: boolean;
}

const Thanks = ({
  message,
  classes,
  userType,
  isInvite,
  isBoarding,
  showAppBtn,
  backButton,
  showOnboardingHaulpayApp,
  showOnboardingComfreightApp,
}: Props): JSX.Element => (
  <Grid
    container
    style={{
      marginTop: 60,
    }}
    alignContent="center"
    alignItems="center"
    direction="column"
  >
    {React.isValidElement(message) ? (
      message
    ) : (
      <Typography
        component="p"
        style={{
          fontSize: "2.387em",
          lineHeight: 1.28,
          fontWeight: "lighter",
          width: "70%",
          marginBottom: 50,
        }}
        align="center"
        gutterBottom
      >
        {message}
      </Typography>
    )}
    {isInvite && showOnboardingHaulpayApp && (
      <React.Fragment>
        <img src={HPlogo} alt="haulpay logo" style={{ width: 80, height: 80 }} />
        <Typography
          component="p"
          style={{
            fontSize: "1.5em",
            lineHeight: 1.28,
            fontWeight: "lighter",
            width: "70%",
            marginBottom: 50,
          }}
          align="center"
          gutterBottom
        >
          You can also use the optional HaulPay mobile app to track and manage
          your payments.
        </Typography>
        <RoundedButton
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_HAULPAY_MOBILE_APP_ANDROID_LINK}
          wrapperStyle={{ width: "auto" }}
        >
          <FontAwesomeIcon icon={faAndroid} size="2x" />
          <Typography className={classes.btn}>Download For Android</Typography>
        </RoundedButton>
        <RoundedButton
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_HAULPAY_MOBILE_APP_IOS_LINK}
          wrapperStyle={{ width: "auto" }}
        >
          <FontAwesomeIcon icon={faApple} size="2x" />
          <Typography className={classes.btn}>Download For iPhone</Typography>
        </RoundedButton>
      </React.Fragment>
    )}
    {isInvite && showOnboardingComfreightApp && (
      <React.Fragment>
        <Grid>
          <img src={CFlogo} alt="Comfreight logo" style={{ padding: 15 }}/>
        </Grid> 
        <Typography
          component="p"
          style={{
            fontSize: "1.5em",
            lineHeight: 1.28,
            fontWeight: "lighter",
            width: "70%",
            marginBottom: 50,
          }}
          align="center"
          gutterBottom
        >
          You can also use the optional Comfreight mobile app to track and
          manage your payments.
        </Typography>
        <RoundedButton
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_COMFREIGHT_MOBILE_APP_ANDROID_LINK}
          wrapperStyle={{ width: "auto" }}
        >
          <FontAwesomeIcon icon={faAndroid} size="2x" />
          <Typography className={classes.btn}>Download For Android</Typography>
        </RoundedButton>
        <RoundedButton
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_COMFREIGHT_MOBILE_APP_IOS_LINK}
          wrapperStyle={{ width: "auto" }}
        >
          <FontAwesomeIcon icon={faApple} size="2x" />
          <Typography className={classes.btn}>Download For iPhone</Typography>
        </RoundedButton>
      </React.Fragment>
    )}
    {isBoarding && showAppBtn && (
      <React.Fragment>
        <RoundedButton
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_HAULPAY_MOBILE_APP_ANDROID_LINK}
          wrapperStyle={{ width: "auto" }}
        >
          <FontAwesomeIcon icon={faAndroid} size="2x" />
          <Typography className={classes.btn}>Download For Android</Typography>
        </RoundedButton>
        <RoundedButton
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_HAULPAY_MOBILE_APP_IOS_LINK}
          wrapperStyle={{ width: "auto" }}
        >
          <FontAwesomeIcon icon={faApple} size="2x" />
          <Typography className={classes.btn}>Download For iPhone</Typography>
        </RoundedButton>
      </React.Fragment>
    )}
    {backButton && backButton}
  </Grid>
);

export default compose<Props, Outter>(
  connect(
    (state: object): MapStateToProps => ({
      userType: getUserType(state),
    })
  ),
  withStyles(styles)
)(Thanks);

import { createReducer } from "redux-starter-kit";

import steps from "./steps";
import * as TYPES from "./types";
import nextStepReducer from "./reducers/nextStep";
import nextStepByNameReducer from "./reducers/moveToStepByName";
import confirmInviteReducer from "./reducers/confirmInvite";
import createFactoringProfileInviteReducer from "./reducers/createFactoringProfileInvite";
import exchangePlaidTokenReducer from "./reducers/exchangePlaidToken";
import resetStepsReducer from "./reducers/resetSteps";
import createFactoringProfileReducer from "./reducers/createFactoringProfile";
import updateFactoringProfileReducer from "./reducers/updateFactoringProfile";
import fetchFactoringProfileReducer from "./reducers/fetchFactoringProfile";
import createUserReducer from "./reducers/createUser";
import currentUserReducer from "./reducers/currentUser";
import paymentTermsReducer from "./reducers/paymentTerms";
import updateInviteStepsReducer from "./reducers/updateInviteSteps";

const initial = {
  step: "welcome",
  steps: steps(true),
  accountAction: "",
  loading: false,
  factoringLoading: false,
  factoringProfileAction: {},
  email: "",
  factoringError: "",
  inviteError: "",
  exchangePlaidLoading: false,
  exchangePlaidSuccess: {},
  exchangePlaidError: "",
  invitePayload: {},
  factoringProfileSuccess: {},
  updateFactoringProfileLoading: false,
  updateFactoringProfileSuccess: {},
  paymentTerms: []
};

const reducer = createReducer(initial, {
  [TYPES.NEXT_STEP]: nextStepReducer,
  [TYPES.NEXT_STEP_BY_NAME]: nextStepByNameReducer,
  [TYPES.RESET_STEPS]: resetStepsReducer,
  [TYPES.CONFIRM_INVITE_REQUEST]: confirmInviteReducer,
  [TYPES.CONFIRM_INVITE_SUCCESS]: confirmInviteReducer,
  [TYPES.CONFIRM_INVITE_FAILURE]: confirmInviteReducer,
  [TYPES.CREATE_FACTORING_PROFILE_INVITE_REQUEST]: createFactoringProfileInviteReducer,
  [TYPES.CREATE_FACTORING_PROFILE_INVITE_SUCCESS]: createFactoringProfileInviteReducer,
  [TYPES.CREATE_FACTORING_PROFILE_INVITE_FAILURE]: createFactoringProfileInviteReducer,
  [TYPES.EXCHANGE_PLAID_TOKEN_FAILURE]: exchangePlaidTokenReducer,
  [TYPES.EXCHANGE_PLAID_TOKEN_REQUEST]: exchangePlaidTokenReducer,
  [TYPES.EXCHANGE_PLAID_TOKEN_SUCCESS]: exchangePlaidTokenReducer,
  [TYPES.CREATE_FACTORING_PROFILE_SUCCESS]: createFactoringProfileReducer,
  [TYPES.CREATE_FACTORING_PROFILE_FAILURE]: createFactoringProfileReducer,
  [TYPES.CREATE_FACTORING_PROFILE_FAILURE]: createFactoringProfileReducer,
  [TYPES.UPDATE_FACTORING_PROFILE_SUCCESS]: updateFactoringProfileReducer,
  [TYPES.UPDATE_FACTORING_PROFILE_REQUEST]: updateFactoringProfileReducer,
  [TYPES.UPDATE_FACTORING_PROFILE_FAILURE]: updateFactoringProfileReducer,
  [TYPES.FETCH_FACTORING_PROFILE_SUCCESS]: fetchFactoringProfileReducer,
  [TYPES.FETCH_FACTORING_PROFILE_REQUEST]: fetchFactoringProfileReducer,
  [TYPES.FETCH_FACTORING_PROFILE_FAILURE]: fetchFactoringProfileReducer,
  [TYPES.CREATE_USER_PROFILE_SUCCESS]: createUserReducer,
  [TYPES.CREATE_USER_PROFILE_FAILURE]: createUserReducer,
  [TYPES.CREATE_USER_PROFILE_REQUEST]: createUserReducer,
  [TYPES.CURRENT_USER_REQUEST]: currentUserReducer,
  [TYPES.CURRENT_USER_SUCCESS]: currentUserReducer,
  [TYPES.CURRENT_USER_FAILURE]: currentUserReducer,
  [TYPES.FETCH_PAYMENTS_TERMS_REQUEST]: paymentTermsReducer,
  [TYPES.FETCH_PAYMENTS_TERMS_SUCCESS]: paymentTermsReducer,
  [TYPES.FETCH_PAYMENTS_TERMS_FAILURE]: paymentTermsReducer,
  [TYPES.UPDATE_INVITE_STEPS]: updateInviteStepsReducer
});

export default reducer;

export { default as confirmInvite } from "./actions/confirmInvite";
export { default as exchangePlaidToken } from "./actions/exchangePlaidToken";
export { default as moveToNextStep } from "./actions/moveToNextStep";
export { default as moveToStepByName } from "./actions/moveToStepByName";
export { default as resetSteps } from "./actions/resetSteps";
export {
  default as createFactoringProfileInvite
} from "./actions/createFactoringProfileInvite";
export { default as createUser } from "./actions/createUser";
export { default as updateInviteSteps } from "./actions/updateInviteSteps";
export { default as fetchPaymentTerms } from "./actions/fetchPaymentTerms";
export { default as updatePaymentTerms } from "./actions/updatePaymentTerms";
export {
  default as updatePaymentProfile
} from "./actions/updatePaymentProfile";

export { default as getLoading } from "./selectors/getLoading";
export { default as getInvitePayload } from "./selectors/getInvitePayload";
export { default as getAccountAction } from "./selectors/getAccountAction";
export { default as getInvitedEmail } from "./selectors/getInvitedEmail";
export { default as getCurrentStep } from "./selectors/getCurrentStep";
export { default as getSteps } from "./selectors/getSteps";
export {
  default as getFactoringLoading
} from "./selectors/getFactoringLoading";
export {
  default as getFactoringProfileAction
} from "./selectors/getFactoringProfileAction";
export {
  default as getFactoringInviteError
} from "./selectors/getFactoringInviteError";
export { default as getInviteError } from "./selectors/getInviteError";
export { default as getExchangeLoading } from "./selectors/getExchangeLoading";
export { default as getExchangePlaid } from "./selectors/getExchangePlaid";
export {
  default as getExchangePlaidError
} from "./selectors/getExchangePlaidError";
export { default as getUsedPlaid } from "./selectors/getUsedPlaid";
export {
  default as fetchFactoringProfile
} from "./actions/fetchFactoringProfile";
export {
  default as createFactoringProfile
} from "./actions/createFactoringProfile";
export {
  default as updateFactoringProfile
} from "./actions/updateFactoringProfile";
export {
  default as getFactoringProfile
} from "./selectors/getFactoringProfile";
export {
  default as getUpdateFactoringProfile
} from "./selectors/getUpdateFactoringProfile";
export {
  default as getUpdateFactoringProfileLoading
} from "./selectors/getUpdateFactoringProfileLoading";
export {
  default as getUserProfileSuccess
} from "./selectors/getUserProfileSuccess";
export { default as getPaymentTerms } from "./selectors/getPaymentTerms";
export { default as getPhoneNumber } from "./selectors/getPhoneNumeber";
export { default as isSmsInvite } from "./selectors/isSmsInvite";

import { createAction } from "redux-starter-kit";
import {
  DELETE_NOTE_REQUEST,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAILURE
} from "../types";

export default (factorCompanyId, noteId) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(DELETE_NOTE_REQUEST)({}));
    const response = await api.factorCompany.deleteNote(
      factorCompanyId,
      noteId
    );
    dispatch(
      createAction(DELETE_NOTE_SUCCESS)({
        factorCompanyId,
        note: noteId
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(DELETE_NOTE_FAILURE)(err));
    throw err;
  }
};

import { DateTime, DateTimeFormatOptions } from "luxon";


const passMethodToDate = (dateString: string, luxonMethod: DateTimeFormatOptions ): string => {
  try {
    if (!dateString || dateString === "--") {
      return "";
    }
    const date = DateTime.fromISO(dateString);
    if (date.isValid && dateString.length >= 20) {
      return date.toLocaleString(luxonMethod);
    }
    return dateString;
  } catch (e) {
    return dateString;
  }
}

const formatDate = (dateString: string): string => {
  return passMethodToDate(dateString, DateTime.DATETIME_MED )
};


export const formatDayMonthYear = (dateString: string) =>{
  return passMethodToDate(dateString, DateTime.DATE_MED )
}

export default formatDate

import React, { createRef } from "react";
import { connect, useDispatch } from "react-redux";
import { compose, lifecycle, withProps, withState } from "recompose";
import { findIndex, get, head, find, isEmpty } from "lodash";

import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { faSave, faTrashAlt } from "@fortawesome/free-regular-svg-icons";

import { openSnackbar } from "../../../../modules/ui";
import {
  getConnectedProfiles,
  queryConnectedProfile,
  updateConnectedFactoringProfile,
  fetchTerms,
  getConnectedCarriersIsLoading,
  getConnectedCarriersCount,
  getConnectedCarriersPage,
  getConnectedCarriersRowsPerPage,
  getConnectedCarriersRowsPerPageOptions,
  getConnectedCarriersSortDirection,
  getConnectedCarriersSortBy,
  getConnectedCarriersFilters,
  handleConnectedCarriersSort,
  handleConnectedCarriersChangePage,
  handleConnectedCarriersChangeRowsPerPage,
  handleConnectedCarriersSearchFilter,
  fetchFactoringProfile,
  getFactorClient
} from "../../../../modules/factoringClient";
import withTabs from "../../../../components/withTabs";
import withSelect from "../../../../components/withSelect";
import formatStatus from "../../../../helpers/format/formatStatus";
import withStatusDropdown from "../../../../components/withStatusDropdown";
import PureConnectedCarriers from "./ConnectedCarriers";
import MoreLinks from "./components/MoreLinks";
import tabs from "../tabs";
import { Input, Select } from "../../../../components/inputs/BoldInput";
import Link from "../../../../components/Link";
import {
  queryConnectedClients,
  updateRelationship
} from "../../../../modules/factorCompany";

import { uploadAttachments } from "../../../../modules/payment";
import openFileDialog from "../../../../helpers/openFileDialog";
import colors from "../../../../theme/colors";
import RemoveFactorDialog from "../../../../components/dialogs/RemoveFactorDialog";
import ConfirmNOADialog from "../../../../components/dialogs/ConfirmNOADialog";
import formatPennies from "../../../../helpers/format/formatPennies";

export const ConnectedBrokersCarriers = PureConnectedCarriers;

const PaperClip = () => {
  return <FontAwesomeIcon icon={faPaperclip} color={colors.secondary} />;
};

const Save = () => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.green_dark} icon={faSave} />;
};

const Trash = () => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />;
};

const columns = [
  {
    key: "name",
    name: "Carrier",
    filter: "text",
    filter_key: "company_name",
    sort: true
  },
  {
    key: "factor",
    name: "Factor"
  },
  {
    key: "status",
    name: "Status",
    filter_key: "status",
    filter: "dropdown",
    filter_values: [
      { label: "Pending", value: "pending" },
      { label: "Approved", value: "approved" },
      { label: "Declined", value: "declined" },
      { label: "Needs Review", value: "needs_review" }
    ]
  },
  {
    key: "email",
    name: "Email",
    filter: "text",
    filter_key: "email",
    sort: true
  },
  {
    key: "carrier_limit",
    name: "Carrier Limit"
  },
  {
    key: "phoneNumber",
    name: "Phone #",
    filter: "text",
    filter_key: "phone",
    sort: true
  },
  {
    key: "mc",
    name: "MC #",
    filter: "text",
    filter_key: "mc",
    sort: true
  },
  {
    key: "dot",
    name: "DOT #",
    filter: "text",
    filter_key: "dot",
    sort: true
  },
  {
    key: "remaining_credit",
    name: "Remaining credit balance",
    format: formatPennies
  },
  {
    key: "brokerPortion",
    name: "Broker Portion"
  },
  {
    key: "paymentSpeed",
    name: "Payment Speed"
  },
  {
    key: "noa",
    name: "NOA Status"
  },
  {
    key: "noaConnection",
    name: "NOA Connection"
  },
  {
    key: "confirmNoa",
    name: "Confirm NOA"
  },
  {
    key: "more",
    name: "Actions"
  }
];

const connectedForm = (
  factoringId,
  updateConnectedFactoringProfile,
  updateRelationship,
  openSnackbar,
  terms
) => ({ discount_rate, broker_portion, ...rest }) => {
  return {
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await updateConnectedFactoringProfile(factoringId, values.id, {
          factoring_fee_split_rate: values.broker_portion,
          noa_status: values.noa_status,
          funding_request_payout_limit: values.carrier_limit,
          payout_days: values.payout_days
        });
        if (values.relation_id) {
          await updateRelationship(values.relation_id, {
            noa_status: values.noa_status
          });
        }
        openSnackbar("success", "Updated Broker Portion & Carrier Limit!");
      } catch (err) {
        openSnackbar("error", head(err));
      } finally {
        setSubmitting(false);
      }
    },
    initialValues: {
      discount_rate,
      broker_portion,
      relation_id: get(rest, ["factoring_company_relation", "id"], ""),
      noa_status: get(
        rest,
        ["factoring_company_relation", "noa_status"],
        "inactive"
      ),
      carrier_limit: get(rest, "funding_request_payout_limit"),
      payout_days: get(rest, "payout_days")
    },
  };
};

const formatProfile = ({
  id,
  discount_rate: discountRate,
  carrier,
  broker,
  factoring_fee_split_rate: brokerPortion,
  funding_request_payout_limit_options: fundingRequestPayoutLimitOptions = [],
  payment_profile: {
    id: paymentProfileId,
    company_name: name,
    owner_email: email,
    company_tax_id: taxId,
    owner_phone_number_cell: phoneNumber,
    company_mc: mc,
    company_dot: dot,
    status
  } = {},
  ...rest
}) => {
  return {
    id,
    carrier_limit: ({ values, handleBlur, handleChange }) => (
      <Select
        fullWidth
        name="carrier_limit"
        value={values.carrier_limit}
        onChange={handleChange}
        onBlur={handleBlur}
        selections={fundingRequestPayoutLimitOptions.map(option => ({
          value: option,
          text: formatPennies(option)
        }))}
      />
    ),
    name: <Link to={`/admin/payment/${paymentProfileId}/profile`}>{name}</Link>,
    factor: (
      <Link
        to={`/admin/companies/${get(
          rest,
          ["factoring_company_relation", "factoring_company"],
          ""
        )}/profile`}
      >
        {get(
          rest,
          ["factoring_company_relation", "factoring_company_name"],
          ""
        )}
      </Link>
    ),
    status,
    email,
    phoneNumber,
    payment_profile_id: paymentProfileId,
    mc,
    dot,
    taxId,
    discountRate,
    discount_rate: discountRate,
    broker_portion: brokerPortion,
    carrier,
    company_name: name,
    broker,
    ...rest
  };
};

const mapStateToProps = state => ({
  connectedProfiles: getConnectedProfiles(state).map(formatProfile),
  loading: getConnectedCarriersIsLoading(state),
  count: getConnectedCarriersCount(state),
  page: getConnectedCarriersPage(state),
  rowsPerPage: getConnectedCarriersRowsPerPage(state),
  rowsPerPageOptions: getConnectedCarriersRowsPerPageOptions(state),
  sortDirection: getConnectedCarriersSortDirection(state),
  sortBy: getConnectedCarriersSortBy(state),
  filters: getConnectedCarriersFilters(state),
  factorClient: getFactorClient(state)
});

const mapDispatchToProps = {
  queryConnectedProfile,
  updateConnectedFactoringProfile,
  updateRelationship,
  openSnackbar,
  fetchTerms,
  handleSort: handleConnectedCarriersSort,
  handleChangePage: handleConnectedCarriersChangePage,
  handleChangeRowsPerPage: handleConnectedCarriersChangeRowsPerPage,
  handleFilterChange: handleConnectedCarriersSearchFilter,
  fetchFactoringProfile
};

export default compose(
  withSelect,
  withStatusDropdown,
  withState("terms", "setTerms", []),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      match: {
        params: { id }
      },
      connectedProfiles,
      selected,
      status,
      updateConnectedFactoringProfile,
      openSnackbar,
      updateRelationship,
      terms,
      factorClient,
      ...rest
    }) => {
      const dispatch = useDispatch();
      const [open, setOpen] = React.useState(false);
      const [send, setSend] = React.useState(false);
      return {
        factorClient,
        tabs: tabs(id, factorClient.allow_self_finance_fr),
        navIndex: findIndex(tabs(id), tab => pathname === tab.route),
        handleRoute: route => push(route),
        id,
        columns,
        connectedProfiles: connectedProfiles.map(profile => {
          const relationId = get(profile, ["factoring_company_relation", "id"]);
          const attachments = get(
            profile,
            ["factoring_company_relation", "attachments"],
            []
          ).filter(a => a.category === "NOA");
          let upload = createRef();
          return {
            ...profile,
            noa: ({ values, handleChange }) => {
              if (!relationId) {
                return <div />;
              }
              return (
                <Select
                  fullWidth
                  name="noa_status"
                  onChange={handleChange}
                  value={values.noa_status}
                  selections={[
                    {
                      text: "In-Active",
                      value: "inactive"
                    },
                    {
                      text: "Active",
                      value: "active"
                    }
                  ]}
                />
              );
            },
            noaConnection: ({ values }) => {
              if (!relationId) {
                return <div />;
              }
              return (
                <Grid container direction="row">
                  <Grid item>
                    <Tooltip title="Remove Member">
                      <IconButton
                        style={{ width: "48px", height: "48px" }}
                        onClick={async () => {
                          setOpen(relationId);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </Tooltip>
                    <RemoveFactorDialog
                      open={open === relationId}
                      id={relationId}
                      handleExit={() => setOpen(false)}
                      name={rest.company_name}
                      baseId={get(profile, "id")}
                    />
                  </Grid>
                  <Grid item>
                    {attachments.length > 0 ? (
                      <Tooltip title="View NOA">
                        <IconButton
                          style={{ width: "48px", height: "48px" }}
                          onClick={async () => {
                            window.open(
                              head(attachments).preview_url,
                              "_blank"
                            );
                          }}
                        >
                          <PaperClip />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <React.Fragment>
                        <Tooltip title="Upload NOA">
                          <IconButton
                            style={{ width: "48px", height: "48px" }}
                            onClick={async () => {
                              openFileDialog(upload);
                            }}
                          >
                            <CloudUploadIcon
                              style={{ color: colors.light_orange }}
                            />
                          </IconButton>
                        </Tooltip>
                        <input
                          type="file"
                          name="attachments"
                          ref={ref => (upload = ref)}
                          style={{ display: "none" }}
                          onChange={async e => {
                            const file = e.currentTarget.files[0];
                            const category = "NOA";

                            const response = await dispatch(
                              uploadAttachments(profile.payment_profile_id, [
                                { file, category }
                              ])
                            );
                            await dispatch(queryConnectedClients(id));
                          }}
                        />
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
              );
            },
            confirmNoa: () => {
              if (!relationId) {
                return <div />;
              }
              return (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setSend(relationId)}
                  >
                    Send
                  </Button>
                  <ConfirmNOADialog
                    open={send === relationId}
                    name={get(profile, [
                      "factoring_company_relation",
                      "factoring_company_name"
                    ])}
                    companyId={get(profile, [
                      "factoring_company_relation",
                      "factoring_company"
                    ])}
                    paymentProfileId={get(profile, [
                      "factoring_company_relation",
                      "payment_profile"
                    ])}
                    handleExit={() => setSend(false)}
                  />
                </React.Fragment>
              );
            },
            status: formatStatus(profile.status),
            brokerPortion: ({ values, handleChange, handleBlur }) => (
              <Select
                value={values.broker_portion}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={get(
                  profile,
                  ["factoring_company_relation", "factoring_company_name"],
                  ""
                )}
                name="broker_portion"
                selections={[
                  {
                    text: "100%",
                    value: "100"
                  },
                  {
                    text: "50%",
                    value: "50"
                  },
                  {
                    text: "0%",
                    value: "0"
                  }
                ]}
              />
            ),
            paymentSpeed: ({
              values,
              handleChange,
              handleBlur,
              setFieldValue
            }) => (
              <Grid item style={{ width: "100%" }}>
                <Select
                  value={values.payout_days}
                  onBlur={handleBlur}
                  fullWidth
                  disabled={get(
                    profile,
                    ["factoring_company_relation", "factoring_company_name"],
                    ""
                  )}
                  onChange={e => {
                    try {
                      handleChange(e);
                      const payoutDays = e.target.value.replace(/[^0-9]/g, "");
                      setFieldValue(
                        "broker_portion",
                        find(terms, {
                          payout_days: parseInt(payoutDays, 0)
                        }).factoring_fee_split_rate
                      );
                    } catch (err) {}
                  }}
                  name="payout_days"
                  selections={terms
                    .sort((a, b) => a.payout_days - b.payout_days)
                    .map(term => {
                      if (term.payout_days == "0") {
                        term.payout_days = 1;
                      }
                      return term;
                    })
                    .map(term => {
                      return {
                        value: `ach_${term.payout_days}_day`,
                        text: `${term.payout_days} Days ${term.discount_rate}%`
                      };
                    })}
                />
              </Grid>
            ),
            discountRate: ({ values, handleChange, handleBlur }) => (
              <Input
                disabled
                name="discount_rate"
                value={values.discount_rate}
              />
            ),
            more: props => {
              return (
                <MoreLinks
                  handleSave={props.handleSubmit}
                  saveSubmtitting={props.isSubmitting}
                />
              );
            }
          };
        }),
        handleSetStatus: async () => {
          try {
            await Promise.all(
              selected.map(selectedId =>
                updateConnectedFactoringProfile(id, selectedId, { status })
              )
            );
            openSnackbar("success", "Status Applied!");
          } catch (err) {
            openSnackbar("error", head(err));
          }
        },
        connectedForm: connectedForm(
          id,
          updateConnectedFactoringProfile,
          updateRelationship,
          openSnackbar,
          terms
        )
      };
    }
  ),
  lifecycle({
    async componentDidMount() {
      const {
        id,
        queryConnectedProfile: fetchConnectedProfile,
        fetchTerms: fetchTermsPaymentSpeed,
        setTerms,
        fetchFactoringProfile,
        factorClient
      } = this.props;
      if (isEmpty(factorClient)) {
        await fetchFactoringProfile(id);
      }
      await fetchConnectedProfile(id);
      const terms = await fetchTermsPaymentSpeed(id);
      setTerms(terms);
    }
  }),
  withTabs
)(PureConnectedCarriers);

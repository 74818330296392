import { createAction } from "redux-starter-kit";
import {
  FETCH_BERBIX_TOKEN_ERROR,
  FETCH_BERBIX_TOKEN_REQUEST,
  FETCH_BERBIX_TOKEN_SUCCESS
} from "../types";

export default () => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_BERBIX_TOKEN_REQUEST)({}));
    const {
      client_token: clientToken,
      ...res
    } = await api.berbix.berbixRequestToken();
    dispatch(
      createAction(FETCH_BERBIX_TOKEN_SUCCESS)({
        clientId: process.env.REACT_APP_BERBIX_CLIENT_ID,
        clientToken,
        ...res
      })
    );
    return res;
  } catch (e) {
    dispatch(createAction(FETCH_BERBIX_TOKEN_ERROR)({}));
  }
};

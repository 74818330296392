import axios from "axios";

/**
 * Function to get carrier data given a DOT number
 * @param {number | string} dot
 * @returns {object} [FMCSA data]{@link https://mobile.fmcsa.dot.gov/developer/qcapidescription.page?cid=268975}
 */
export const getDataFromDOT = async (dot: string): Promise<any> => {
  const req = await axios(`factoring/fmcsa/?dot=${dot}`);
  return req.data;
};

/**
 * Function to get carrier data given a MC number
 * @param {number | string} mc
 * @returns {object} [FMCSA data]{@link https://mobile.fmcsa.dot.gov/developer/qcapidescription.page?cid=268975}
 */
export const getDataFromMC = async (mc: string): Promise<any> => {
  const req = await axios(`/factoring/fmcsa/?mc=${mc}`);
  return req.data;
};

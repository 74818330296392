import axios, { AxiosRequestConfig } from "axios";

const addTMS = async (name: string): Promise<any> => {
  const url = `/tms/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      name
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateTMS = async ({
  name,
  id
}: {
  name: string;
  id: string;
}): Promise<any> => {
  const url = `/tms/${id}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "PATCH",
    data: {
      name
    }
  };
  const response = await axios(options);
  return response.data;
};

const deleteTMS = async (
  id: string): Promise<any> => {
  const url = `/tms/${id}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "DELETE",
  };
  const response = await axios(options);
  return response.data;
};

const fetchTMS = async (): Promise<any> => {
  const url = `/tms/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

export default {
  addTMS,
  fetchTMS,
  updateTMS,
  deleteTMS
};

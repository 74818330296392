import { assign } from "lodash";
import {
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE
} from "../types";

// const sameRequest = (state, payload) =>
//   state.status === payload.status &&
//   state.limit === payload.limit &&
//   state.offset === payload.offset &&
//   state.ordering === payload.ordering;

export default (
  state = {
    groups: {
      loading: false,
      ids: []
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_GROUPS_REQUEST:
      return {
        groups: assign({}, state.groups, {
          ids: [],
          loading: true
          // status: action.payload.status,
          // limit: action.payload.limit,
          // offset: action.payload.offset,
          // ordering: action.payload.ordering,
          // filters: action.payload.filters
        })
      };
    case FETCH_GROUPS_SUCCESS:
      // if (sameRequest(state, action.payload)) {
      return {
        ...state,
        groups: assign({}, state.groups, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        })
      };
    // }
    // return state;
    case FETCH_GROUPS_FAILURE:
      return {
        groups: assign({}, state.groups, {
          loading: false
        })
      };
    // return state;
    default:
      return state;
  }
};

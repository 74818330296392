import { Button } from "@material-ui/core";
import React from "react";
import { ButtonProps } from "@material-ui/core/Button";
import colors from "../../theme/colors";

export default (props: ButtonProps): JSX.Element => (
  <Button
    variant="contained"
    style={{
      background: colors.danger,
      color: colors.white
    }}
    size="medium"
    {...props}
  >
    Delete
  </Button>
);

const sendTransferDocuments = id => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    return await api.factoring.transferDocument(id);
  } catch (err) {
    throw err;
  }
};

export default sendTransferDocuments;

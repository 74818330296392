import { denormalize } from "normalizr";
import { get } from "lodash";
import { debtors } from "../../../api/schema";

const getDebtors = state => {
  try {
    const items = get(state, "factoringClient.connectDebtor.ids");

    const denormalized = denormalize(
      { items },
      { items: [debtors] },
      state.entities
    );

    return denormalized.items;
  } catch (err) {
    return [];
  }
};

export default getDebtors;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_SIMILAR_PAYMENT_PROFILE_FAILURE,
  FETCH_SIMILAR_PAYMENT_PROFILE_SUCCESS,
  FETCH_SIMILAR_PAYMENT_PROFILE_REQUEST
} from "../types";

const fetchSimilarPaymentProfile = id => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(
      createAction(FETCH_SIMILAR_PAYMENT_PROFILE_REQUEST)({
        id
      })
    );
    const response = await api.factoring.paymentProfile.getSimilarPaymentProfile(
      id
    );
    const data = normalize(response.results, [schema.paymentProfile]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_SIMILAR_PAYMENT_PROFILE_SUCCESS)({
        id,
        items: data.result,
        count: response.count,
        next: response.next,
        previous: response.previous
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_SIMILAR_PAYMENT_PROFILE_FAILURE)(err));
    throw err;
  }
};

export default fetchSimilarPaymentProfile;

import { createAction } from "redux-starter-kit";
import {
  CREATE_USER_PROFILE_FAILURE,
  CREATE_USER_PROFILE_REQUEST,
  CREATE_USER_PROFILE_SUCCESS
} from "../types";

const createUser = (user, token, source) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(CREATE_USER_PROFILE_REQUEST)());
    const response = await api.user.createUser(user, token, source);
    window.analytics.track("CompletedRegistration", response);
    dispatch(createAction(CREATE_USER_PROFILE_SUCCESS)(response));
  } catch (err) {
    dispatch(createAction(CREATE_USER_PROFILE_FAILURE)(err));
    throw err;
  }
};

export default createUser;

import { withStateHandlers, StateHandler } from "recompose";

interface State {
  index: number;
}

export default withStateHandlers(
  ({ index = 0 }: State): State => ({
    index
  }),
  {
    handleChange: (): StateHandler<State> => (
      _event,
      index: number
    ): State => ({
      index
    }),
    handleChangeIndex: (): StateHandler<State> => (index: number): State => ({
      index
    })
  }
);

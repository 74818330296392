import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import fetchAll from "../../../helpers/fetchAll";
import { addEntities } from "../../entities";
import {
  FETCH_LINEITEMCATEGORIES_FAILURE,
  FETCH_LINEITEMCATEGORIES_REQUEST,
  FETCH_LINEITEMCATEGORIES_SUCCESS
} from "../types";

const fetchLineItemCategories = () => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_LINEITEMCATEGORIES_REQUEST)({}));
    const similar = await fetchAll(() => api.factoring.getLineItemCategories());
    const data = normalize(similar, [schema.lineItemCategory]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_LINEITEMCATEGORIES_SUCCESS)({
        categories: data.result
      })
    );
  } catch (err) {
    dispatch(createAction(FETCH_LINEITEMCATEGORIES_FAILURE)(err));
    throw err;
  }
};

export default fetchLineItemCategories;

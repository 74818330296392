export default (includeCarrierIDCheck = true, isBoarding = false) => [
  {
    value: "welcome",
    text: "Welcome"
  },
  ...(() =>
    !isBoarding
      ? [
          {
            value: "confirm",
            text: "Carrier Info"
          }
        ]
      : [])(),
  {
    value: "bank",
    text: "Bank Info"
  },
  ...(() =>
    includeCarrierIDCheck
      ? [
          {
            value: "attach",
            text: "ID Verify"
          }
        ]
      : [])(),
  {
    value: "summary",
    text: "Complete"
  },
  ...(() =>
    !isBoarding
      ? [
          {
            value: "thanks",
            text: "Thanks!"
          }
        ]
      : [])()
];

export const FETCH_PAYMENT_PROFILE_REQUEST =
  "PAYMENT_PROFILE/FETCH_PAYMENT_PROFILE_REQUEST";
export const FETCH_PAYMENT_PROFILE_SUCCESS =
  "PAYMENT_PROFILE/FETCH_PAYMENT_PROFILE_SUCCESS";
export const FETCH_PAYMENT_PROFILE_FAILURE =
  "PAYMENT_PROFILE/FETCH_PAYMENT_PROFILE_FAILURE";

export const UPDATE_PAYMENT_PROFILE_FAILURE =
  "PAYMENT_PROFILE/UPDATE_PAYMENT_PROFILE_FAILURE";
export const UPDATE_PAYMENT_PROFILE_REQUEST =
  "PAYMENT_PROFILE/UPDATE_PAYMENT_PROFILE_REQUEST";
export const UPDATE_PAYMENT_PROFILE_SUCCESS =
  "PAYMENT_PROFILE/UPDATE_PAYMENT_PROFILE_SUCCESS";

export const FETCH_USER_SUCCESS = "PAYMENT_PROFILE/FETCH_USER_SUCCESS";
export const FETCH_USER_REQUEST = "PAYMENT_PROFILE/FETCH_USER_REQUEST";
export const FETCH_USER_FAILURE = "PAYMENT_PROFILE/FETCH_USER_FAILURE";

export const QUERY_FUNDING_REQUEST_REQUEST =
  "PAYMENT_PROFILE/QUERY_FUNDING_REQUEST_REQUEST";
export const QUERY_FUNDING_REQUEST_SUCCESS =
  "PAYMENT_PROFILE/QUERY_FUNDING_REQUEST_SUCCESS";
export const QUERY_FUNDING_REQUEST_FAILURE =
  "PAYMENT_PROFILE/QUERY_FUNDING_REQUEST_FAILURE";

export const QUERY_RELATIONSHIPS_REQUEST =
  "PAYMENT_PROFILE/QUERY_RELATIONSHIPS_REQUEST";
export const QUERY_RELATIONSHIPS_SUCCESS =
  "PAYMENT_PROFILE/QUERY_RELATIONSHIPS_SUCCESS";
export const QUERY_RELATIONSHIPS_FAILURE =
  "PAYMENT_PROFILE/QUERY_RELATIONSHIPS_FAILURE";

export const FETCH_ATTACHMENTS_SUCCESS =
  "PAYMENT_PROFILE/FETCH_ATTACHMENTS_SUCCESS";
export const FETCH_ATTACHMENTS_REQUEST =
  "PAYMENT_PROFILE/FETCH_ATTACHMENTS_REQUEST";
export const FETCH_ATTACHMENTS_FAILURE =
  "PAYMENT_PROFILE/FETCH_ATTACHMENTS_FAILURE";

export const UPLOAD_ATTACHMENTS_SUCCESS =
  "PAYMENT_PROFILE/UPLOAD_ATTACHMENTS_SUCCESS";
export const UPLOAD_ATTACHMENTS_REQUEST =
  "PAYMENT_PROFILE/UPLOAD_ATTACHMENTS_REQUEST";
export const UPLOAD_ATTACHMENTS_FAILURE =
  "PAYMENT_PROFILE/UPLOAD_ATTACHMENTS_FAILURE";

export const FETCH_HISTORY_SUCCESS = "PAYMENT_PROFILE/FETCH_HISTORY_SUCCESS";
export const FETCH_HISTORY_REQUEST = "PAYMENT_PROFILE/FETCH_HISTORY_REQUEST";
export const FETCH_HISTORY_FAILURE = "PAYMENT_PROFILE/FETCH_HISTORY_FAILURE";

export const FETCH_NOTES_SUCCESS = "PAYMENT_PROFILE/FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_REQUEST = "PAYMENT_PROFILE/FETCH_NOTES_REQUEST";
export const FETCH_NOTES_FAILURE = "PAYMENT_PROFILE/FETCH_NOTES_FAILURE";

export const UPDATE_NOTES_SUCCESS = "PAYMENT_PROFILE/UPDATE_NOTES_SUCCESS";
export const UPDATE_NOTES_REQUEST = "PAYMENT_PROFILE/UPDATE_NOTES_REQUEST";
export const UPDATE_NOTES_FAILURE = "PAYMENT_PROFILE/UPDATE_NOTES_FAILURE";

export const DELETE_NOTES_SUCCESS = "PAYMENT_PROFILE/DELETE_NOTES_SUCCESS";
export const DELETE_NOTES_REQUEST = "PAYMENT_PROFILE/DELETE_NOTES_REQUEST";
export const DELETE_NOTES_FAILURE = "PAYMENT_PROFILE/DELETE_NOTES_FAILURE";

export const CREATE_NOTES_SUCCESS = "PAYMENT_PROFILE/CREATE_NOTES_SUCCESS";
export const CREATE_NOTES_REQUEST = "PAYMENT_PROFILE/CREATE_NOTES_REQUEST";
export const CREATE_NOTES_FAILURE = "PAYMENT_PROFILE/CREATE_NOTES_FAILURE";

export const FETCH_PAYMENT_PROFILE_PURCHASES_SUCCESS =
  "PAYMENT_PROFILE/FETCH_PURCHASES_SUCCESS";
export const FETCH_PAYMENT_PROFILE_PURCHASES_ERROR =
  "PAYMENT_PROFILE/FETCH_PURCHASES_ERROR";
export const FETCH_PAYMENT_PROFILE_PURCHASES_REQUEST =
  "PAYMENT_PROFILE/FETCH_PURCHASES_REQUEST";

export const FETCH_SIMILAR_PAYMENT_PROFILE_SUCCESS =
  "PAYMENTPROFILE/FETCH_SIMILAR_SUCCESS";
export const FETCH_SIMILAR_PAYMENT_PROFILE_REQUEST =
  "PAYMENTPROFILE/FETCH_SIMILAR_REQUEST";
export const FETCH_SIMILAR_PAYMENT_PROFILE_FAILURE =
  "PAYMENTPROFILE/FETCH_SIMILAR_FAILURE";

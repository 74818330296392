import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  FETCH_DEBTORPROFILE_FAILURE,
  FETCH_DEBTORPROFILE_REQUEST,
  FETCH_DEBTORPROFILE_SUCCESS
} from "../types";

const fetchDebtorProfile = debtorId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_DEBTORPROFILE_REQUEST)());
    const response = await api.debtors.getDebtor(debtorId);
    const data = normalize(response, schema.debtorProfile);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_DEBTORPROFILE_SUCCESS)({
        [debtorId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_DEBTORPROFILE_FAILURE)(err));
    throw err;
  }
};

export default fetchDebtorProfile;

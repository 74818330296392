import React, { ChangeEventHandler } from "react";
import MultiChoiceSelect from "./MultiChoiceSelect";
import equipmentTypes from "../../helpers/equipmentTypes";

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  name?: string;
  onBlur: ChangeEventHandler<HTMLDivElement>;
  withStyle?: boolean;
  error?: string;
  disabled?: boolean;
  values: {
    equipment_types: string[];
  };
}

const EquipmentTypeInput = ({ values, ...props }: Props): JSX.Element => (
  <MultiChoiceSelect
    label="Equipment Types"
    name="equipment_types"
    value={values.equipment_types}
    items={equipmentTypes}
    withStyle
    {...props}
  />
);

export default EquipmentTypeInput;

import { createAction } from "redux-starter-kit";
import get from "lodash/get";

import { fetchUserToken } from "../../auth";
import {
  CREATE_FACTORING_PROFILE_INVITE_FAILURE,
  CREATE_FACTORING_PROFILE_INVITE_REQUEST,
  CREATE_FACTORING_PROFILE_INVITE_SUCCESS
} from "../types";
import confirmInvite from "./confirmInvite";

const createFactoringProfileInvite = (user, token = undefined) => async (
  dispatch,
  getState,
  { api }
) => {
  const state = getState();
  const authToken = get(state, "auth.token", null);
  const isNewUser = get(state, "invited.accountAction", null) === "new";
  try {
    dispatch(createAction(CREATE_FACTORING_PROFILE_INVITE_REQUEST)());
    const response = await api.user.createInvitedUser(
      { ...user, source: "broker-invite" },
      token,
      authToken
    );
    if (isNewUser) {
      await dispatch(fetchUserToken({ ...user, invite_token: token }));
    }
    await dispatch(confirmInvite(token));
    dispatch(createAction(CREATE_FACTORING_PROFILE_INVITE_SUCCESS)(response));
  } catch (err) {
    if (err.response) {
      dispatch(
        createAction(CREATE_FACTORING_PROFILE_INVITE_FAILURE)(err.response.data)
      );
    } else if (err.request) {
      dispatch(
        createAction(CREATE_FACTORING_PROFILE_INVITE_FAILURE)(err.request)
      );
    } else {
      dispatch(
        createAction(CREATE_FACTORING_PROFILE_INVITE_FAILURE)(err.message)
      );
    }
    throw err;
  }
};

export default createFactoringProfileInvite;

import { assign } from "lodash";

import {
  FETCH_FACTORINGATTACHMENTS_REQUEST,
  FETCH_FACTORINGATTACHMENTS_SUCCESS,
  FETCH_FACTORINGATTACHMENTS_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_FACTORINGATTACHMENTS_REQUEST:
      return {
        ...state,
        loadingFactoringAttachments: true
      };
    case FETCH_FACTORINGATTACHMENTS_SUCCESS:
      return {
        ...state,
        loadingFactoringAttachments: false,
        attachments: assign({}, state.attachments, action.payload)
      };
    case FETCH_FACTORINGATTACHMENTS_FAILURE:
      return {
        ...state,
        loadingFactoringAttachments: false
      };
    default:
      return state;
  }
};

const userTypeArray = [
  { label: "All", value: " " },
  {
    value: "debtor",
    label: "Debtor",
  },
  {
    value: "broker",
    label: "Broker",
  },
  {
    value: "carrier",
    label: "Carrier",
  },
  {
    value: "shipper",
    label: "Shippers",
  },
  {
    value: "dispatcher",
    label: "Dispatchers",
  },
];

export default userTypeArray;

import { get } from "lodash";

export default data => async (dispatch, getState, { api }) => {
  try {
    const response = await api.debtors.queryLoadAttachment(data);
    return get(response, "url");
  } catch (err) {
    throw err;
  }
};

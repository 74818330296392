import { assign, isEqual } from "lodash";
import {
  FETCH_PAYMENT_ENTRIES_FAILURE,
  FETCH_PAYMENT_ENTRIES_REQUEST,
  FETCH_PAYMENT_ENTRIES_SUCCESS
} from "../types";

const sameRequest = (state, payload) =>
  state.ordering === payload.ordering &&
  state.limit === payload.limit &&
  isEqual(state.filters, payload.filters) &&
  state.id === payload.id;

export default (
  state = {
    loading: false,
    ids: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_PAYMENT_ENTRIES_REQUEST:
      return assign({}, state, {
        ids: [],
        id: action.payload.id,
        loading: true,
        limit: action.payload.limit,
        filters: action.payload.filters,
        ordering: action.payload.ordering
      });
    case FETCH_PAYMENT_ENTRIES_SUCCESS:
      return assign({}, state, {
        id: action.payload.id,
        loading: false,
        count: action.payload.count,
        ids: action.payload.items,
        next: action.payload.next,
        previous: action.payload.previous
      });
    case FETCH_PAYMENT_ENTRIES_FAILURE:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          loading: false
        });
      }
      return state;
    default:
      return state;
  }
};

import axios, { AxiosRequestConfig } from "axios";

const host = process.env.REACT_APP_VGS_HOST;

const createAccountByCard = async (
  accountNumberRedacted: string,
  cardNumber: string,
  expirationDate: string,
  method: string,
  type: string,
  user: string,
  zipCode: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "POST",
    url: `${host}/accounts/`,
    data: {
      account_number_redacted: accountNumberRedacted,
      card_number: cardNumber,
      expiration_date: expirationDate,
      method,
      type,
      user,
      zip_code: zipCode
    }
  };
  const response = await axios(options);
  return response.data;
};

const createAccountByBank = async (
  accountNumber: string,
  accountNumberRedacted: string,
  isBusiness: boolean,
  routingNumber: string,
  type: string,
  user: string,
  method: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "POST",
    url: `${host}/accounts/`,
    data: {
      account_number: accountNumber,
      account_number_redacted: accountNumberRedacted,
      is_business: isBusiness,
      routing_number: routingNumber,
      type,
      user,
      method
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateBankAccount = async (
  id: string,
  accountNumber: string,
  accountNumberRedacted: string,
  isBusiness: boolean,
  routingNumber: string,
  type: string,
  user: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "PATCH",
    url: `${host}/accounts/${id}/`,
    data: {
      account_number: accountNumber,
      account_number_redacted: accountNumberRedacted,
      is_business: isBusiness,
      routing_number: routingNumber,
      type,
      user
    }
  };
  const response = await axios(options);
  return response.data;
};

const createAccountByWire = async (
  accountName: string,
  accountNumber: string,
  accountNumberRedacted: string,
  method: string,
  routingNumber: string,
  type: string,
  user: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "POST",
    url: `${host}/accounts/`,
    data: {
      account_name: accountName,
      account_number: accountNumber,
      account_number_redacted: accountNumberRedacted,
      method,
      routing_number: routingNumber,
      type,
      user
    }
  };
  const response = await axios(options);
  return response.data;
};

const createAccountByCheck = async (
  mailingAddress: Object,
  user: string,
  name: string,
): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "POST",
    url: `${host}/accounts/`,
    data: {
      // mailing_address: mailingAddress,
      method: 'CHECK',
      user,
      name
    }
  };
  const response = await axios(options);
  return response.data;
};

const createAccountBySwift = async (
  swiftCode: Object,
  user: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "POST",
    url: `${host}/accounts/`,
    data: {
      swift_code: swiftCode,
      method: 'IAC',
      user
    }
  };
  const response = await axios(options);
  return response.data;
};

export default {
  createAccountByCard,
  createAccountByBank,
  updateBankAccount,
  createAccountByWire,
  createAccountByCheck,
  createAccountBySwift
};

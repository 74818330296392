import { connect } from "react-redux";
import { compose, lifecycle, withProps, withState } from "recompose";

import { openModal } from "../../modules/modal";
import { SUCCESS } from "../../helpers/Modals";
import { forgetPassword } from "../../modules/auth";

import ForgetPassword from "./ForgetPassword";
import validationSchema from "./validation";

export const PureForgetPassword = ForgetPassword;

const mapDispatchToProps = {
  forgetPassword,
  openModal
};

export default compose(
  connect(state => ({}), mapDispatchToProps),
  withState("errorServer", "setErrorServer", ""),
  withProps(props => ({
    form: {
      initialValues: {
        email: ""
      },
      validationSchema,
      onSubmit: async (fields, { setSubmitting }) => {
        const {
          setErrorServer,
          forgetPassword: forgetPasswordAction,
          openModal: openModalAction
        } = props;
        setErrorServer("");
        try {
          const res = await forgetPasswordAction(fields.email);
          if (res.status === "failure") {
            setSubmitting(false);
            return setErrorServer(res.message);
          }
          setSubmitting(false);
          props.history.push("/login");
          return openModalAction(
            SUCCESS,
            "We sent you an email with the password reset link. Thank you."
          );
        } catch (err) {
          setErrorServer("Error while sending email to reset your password");
        }
        setSubmitting(false);
      }
    }
  })),
  lifecycle({
    componentDidUpdate(prevProps, prevState) {
      const { errorServer, setErrorServer } = this.props;
      if (errorServer !== prevProps.errorServer) {
        setTimeout(() => setErrorServer(""), 3000);
      }
    }
  })
)(PureForgetPassword);

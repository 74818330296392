import React, { useCallback, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import formatPennies from "../../../../../../helpers/format/formatPennies";
import {
  fetchReceivablesEntries,
  getInvoice,
  getReceivablesEntries,
  getReceivablesEntriesLoading
} from "../../../../../../modules/debtor";
import { closeDialog, getDialog } from "../../../../../../modules/ui";
import columns from "./columns";
import Table from "../../../../../../components/Table";
import {
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../../components/Dialog";

export default function ReceivablesDetails() {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const id = get(dialog, ["data", "id"]);
  const invoiceId = get(dialog, ["data", "fundingRequestId"]);
  const loading = useSelector(getReceivablesEntriesLoading);

  const handleClose = useCallback(() => dispatch(closeDialog()), [dispatch]);
  useEffect(() => {
    if (id) {
      dispatch(fetchReceivablesEntries(id, "-created", 20, {}));
    }
  }, [id]);
  const receivableEntries = useSelector(getReceivablesEntries);
  const invoice = useSelector(getInvoice(invoiceId));

  return (
    <Dialog
      open={dialog.show && dialog.variant === "debtor-payment-history"}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle onClose={handleClose}>Payments History</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={4}>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <Typography>
                  <b>Invoice #: {invoice.invoice_number}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <b>Load #: {invoice.user_load_number}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <Typography>
                  <b>Amount Paid: {formatPennies(invoice.amount_paid)}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <b>Balance: {formatPennies(invoice.amount)}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <Typography>
                  <b>WF Description:
                    {invoice.bank_description}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Table
          columns={columns}
          rows={receivableEntries}
          allowEmpty
          isLoading={loading}
        />
      </DialogContent>
    </Dialog>
  );
}

import React, { ChangeEventHandler } from "react";
import { FormControlLabel, FormGroup, List, MenuItem, Switch } from "@material-ui/core";
import { FormikErrors } from "formik";
import Card from "../../../../../../components/Card";
import ListItem from "../../../../../../components/ListItem";
import MultiChoiceSelect from "../../../../../../components/inputs/MultiChoiceSelect";
import equipmentTypes from "../../../../../../helpers/equipmentTypes";
import CustomTextField from "../../../../../../components/inputs/CustomTextField";
import BoldInput from "../../../../../../components/inputs/BoldInput";
import formatPhone from "../../../../../../helpers/format/formatPhone";
import ProfileSection from "../../../../../../components/ProfileSection";
import MailingAddressInfo from "../../../../../../components/MailingAddressInfo"

interface FormValues {
  ownerFirstName: string;
  ownerLastName: string;
  ownerAddress: string;
  ownerCellPhone: string;
  ownerCity: string;
  ownerState: string;
  ownerZip: string;
  ownerHomePhone: string;
  ownerEmail: string;
  ownerDateOfBirth: string;
  ownerSsn: string;
  ownerLienStatus: string;
  ownerEquipmentType: string[];
  ownerHasContractAuthorityStatus: boolean;
  ownerHasCommonAuthorityStatus: boolean;
  ownerIsAllowedToOperate: boolean;
  ownerHasBrokerAuthorityStatus: boolean;
  ownerUccTransferComplete: boolean;
  creditStatusEmail: string;
  mailingAddressOne: string;
  mailingAddressTwo: string;
  mailingAddressCity: string;
  mailingAddressState: string;
  mailingAddressZip: string;
  mailingAddressCountry: string;
  name: string;
}

interface Props {
  values: FormValues;
  errors: FormikErrors<FormValues>;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  disableAll?: boolean | undefined;
}

export default ({
  setFieldValue,
  values,
  errors,
  handleChange,
  handleBlur,
  disableAll = false
}: Props): JSX.Element => (
  <Card style={{ height: "100%" }}>
    <List dense>
      <ListItem>
        <ProfileSection>Owner Info:</ProfileSection>
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerFirstName"
          label="First Name:"
          value={values.ownerFirstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerFirstName}
          helperText={errors.ownerFirstName}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerLastName"
          label="Last Name:"
          value={values.ownerLastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerLastName}
          helperText={errors.ownerLastName}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerCellPhone"
          label="Phone:"
          value={formatPhone(values.ownerCellPhone)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerCellPhone}
          helperText={errors.ownerCellPhone}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerAddress"
          label="Address:"
          value={values.ownerAddress}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerAddress}
          helperText={errors.ownerAddress}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerCity"
          label="City:"
          value={values.ownerCity}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerCity}
          helperText={errors.ownerCity}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerState"
          label="State:"
          value={values.ownerState}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerState}
          helperText={errors.ownerState}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerZip"
          label="Zip:"
          value={values.ownerZip}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerZip}
          helperText={errors.ownerZip}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerHomePhone"
          label="Owner's Home Phone:"
          value={formatPhone(values.ownerHomePhone)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerHomePhone}
          helperText={errors.ownerHomePhone}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerEmail"
          label="Owner's Email:"
          value={values.ownerEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerEmail}
          helperText={errors.ownerEmail}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="creditStatusEmail"
          label="Credit Status Email:"
          value={values.creditStatusEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.creditStatusEmail}
          helperText={errors.creditStatusEmail}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerDateOfBirth"
          label="Date of Birth:"
          value={values.ownerDateOfBirth}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerDateOfBirth}
          helperText={errors.ownerDateOfBirth}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerSsn"
          label="Last 4 digits of SSN:"
          value={values.ownerSsn}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerSsn}
          helperText={errors.ownerSsn}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <CustomTextField
          fullWidth
          select
          name="ownerLienStatus"
          label="Lien Status:"
          value={values.ownerLienStatus}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerLienStatus}
          helperText={errors.ownerLienStatus}
          disabled={disableAll}
        >
          <MenuItem value="has_tax_lien_payment_plan">
            Has Lien and Has Plan
          </MenuItem>
          <MenuItem value="has_tax_lien">Has Lien and NO Plan</MenuItem>
          <MenuItem value="none">No Lien</MenuItem>
        </CustomTextField>
      </ListItem>
      <ListItem>
        <MultiChoiceSelect
          label="Equipment Types:"
          name="ownerEquipmentType"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.ownerEquipmentType}
          items={equipmentTypes}
          disabled={disableAll}
          withStyle
        />
      </ListItem>
      <ListItem>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={disableAll}
                checked={values.ownerHasContractAuthorityStatus}
                onChange={(): void => {
                  setFieldValue(
                    "ownerHasContractAuthorityStatus",
                    !values.ownerHasContractAuthorityStatus
                  );
                }}
                value="ownerHasContractAuthorityStatus"
              />
            }
            label="Has Contract Authority Status"
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={disableAll}
                checked={values.ownerHasCommonAuthorityStatus}
                onChange={(): void => {
                  setFieldValue(
                    "ownerHasCommonAuthorityStatus",
                    !values.ownerHasCommonAuthorityStatus
                  );
                }}
                value="ownerHasCommonAuthorityStatus"
              />
            }
            label="Has Common Authority Status"
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={disableAll}
                checked={values.ownerHasBrokerAuthorityStatus}
                onChange={(): void => {
                  setFieldValue(
                    "ownerHasBrokerAuthorityStatus",
                    !values.ownerHasBrokerAuthorityStatus
                  );
                }}
                value="ownerHasBrokerAuthorityStatus"
              />
            }
            label="Has Broker Authority Status"
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={disableAll}
                checked={values.ownerIsAllowedToOperate}
                onChange={(): void => {
                  setFieldValue(
                    "ownerIsAllowedToOperate",
                    !values.ownerIsAllowedToOperate
                  );
                }}
                value="ownerIsAllowedToOperate"
              />
            }
            label="Is Allowed to Operate"
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={disableAll}
                checked={values.ownerUccTransferComplete}
                onChange={(): void => {
                  setFieldValue(
                    "ownerUccTransferComplete",
                    !values.ownerUccTransferComplete
                  );
                }}
                value="ownerUccTransferComplete"
              />
            }
            label="UCC Transfer Complete"
          />
        </FormGroup>
      </ListItem>
    </List>
    <MailingAddressInfo handleChange={handleChange} handleBlur={handleBlur} values={values}/>
  </Card>
);

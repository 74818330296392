import { createAction } from "redux-starter-kit";
import {
  DELETE_LINEITEM_FAILURE,
  DELETE_LINEITEM_REQUEST,
  DELETE_LINEITEM_SUCCESS
} from "../types";

const deleteLineItem = (fundingRequestId, lineItemId) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(DELETE_LINEITEM_REQUEST)());
    const response = await api.factoring.deleteLineItem(
      fundingRequestId,
      lineItemId
    );
    dispatch(
      createAction(DELETE_LINEITEM_SUCCESS)({
        fundingRequest: fundingRequestId,
        lineItem: lineItemId
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(DELETE_LINEITEM_FAILURE)(err));
    throw err;
  }
};

export default deleteLineItem;

import { concat } from "lodash";

import {
  CREATE_CARD_ACCOUNT_REQUEST,
  CREATE_CARD_ACCOUNT_SUCCESS,
  CREATE_CARD_ACCOUNT_FAILURE,
  CREATE_BANK_ACCOUNT_REQUEST,
  CREATE_BANK_ACCOUNT_SUCCESS,
  CREATE_BANK_ACCOUNT_FAILURE,
  CREATE_WIRE_ACCOUNT_REQUEST,
  CREATE_WIRE_ACCOUNT_SUCCESS,
  CREATE_WIRE_ACCOUNT_FAILURE,
  CREATE_CHECK_ACCOUNT_REQUEST,
  CREATE_CHECK_ACCOUNT_SUCCESS,
  CREATE_CHECK_ACCOUNT_FAILURE,
  CREATE_SWIFT_ACCOUNT_REQUEST,
  CREATE_SWIFT_ACCOUNT_SUCCESS,
  CREATE_SWIFT_ACCOUNT_FAILURE
} from "../types";

export default (state, action) => {
  const failureRequest = [CREATE_CARD_ACCOUNT_FAILURE, CREATE_BANK_ACCOUNT_FAILURE, CREATE_WIRE_ACCOUNT_FAILURE, CREATE_CHECK_ACCOUNT_FAILURE, CREATE_SWIFT_ACCOUNT_FAILURE];
  const successRequest = [CREATE_CARD_ACCOUNT_SUCCESS, CREATE_BANK_ACCOUNT_SUCCESS, CREATE_WIRE_ACCOUNT_SUCCESS, CREATE_CHECK_ACCOUNT_SUCCESS, CREATE_SWIFT_ACCOUNT_SUCCESS];
  const request = [CREATE_CARD_ACCOUNT_REQUEST, CREATE_BANK_ACCOUNT_REQUEST, CREATE_WIRE_ACCOUNT_REQUEST, CREATE_CHECK_ACCOUNT_REQUEST, CREATE_SWIFT_ACCOUNT_REQUEST];
  if (request.includes(action.type)) {
    return {
      ...state,
      loadingAccounts: true
    };
  }
  if (successRequest.includes(action.type)) {
    return {
      ...state,
      loadingAccounts: false,
      accounts: {
        ...state.accounts,
        [action.payload.user]: concat(
          [],
          state.accounts[action.payload.user],
          [action.payload]
        )
      }
    };
  }
  if (failureRequest.includes(action.type)) {
    return {
      ...state,
      loadingAccounts: false
    };
  }
};

import { denormalize } from "normalizr";
import { obligations } from "../../../api/schema";

export default id => state => {
    try {
        const denormalized = denormalize(
            { item: id },
            { item: obligations },
            state.entities
        );
            
        return denormalized.item || {};
    } catch (err) {
        return {};
    }
};
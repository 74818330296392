import { get } from "lodash";
import { denormalize } from "normalizr";
import { attachment } from "../../../api/schema";

export default relationshipId => state => {
  try {
    const attachments = get(
      state,
      ["payment", "attachments", relationshipId],
      []
    );

    const denormalized = denormalize(
      { attachments },
      { attachments: [attachment] },
      state.entities
    );
    return denormalized.attachments;
  } catch (err) {
    return [];
  }
};

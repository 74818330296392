import {
  FETCH_FACTORINGCLIENT_REQUEST,
  FETCH_FACTORINGCLIENT_SUCCESS,
  FETCH_FACTORINGCLIENT_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_FACTORINGCLIENT_REQUEST:
      return {
        ...state,
        loadingFactoringClient: true
      };
    case FETCH_FACTORINGCLIENT_SUCCESS:
      return {
        ...state,
        loadingFactoringClient: false,
        id: action.payload.id,
        user: action.payload.user
      };
    case FETCH_FACTORINGCLIENT_FAILURE:
      return {
        ...state,
        loadingFactoringClient: false
      };
    default:
      return state;
  }
};

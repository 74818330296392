import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/index';

import {
  getReferralSources,
  fetchReferralSource,
} from '../../../../../../../modules/factoring';
import { Select } from '../../../../../../../components/inputs/BoldInput';
import {
  getDialog,
  openDialog,
  closeDialog,
} from '../../../../../../../modules/ui';
import ReferralModal from './ReferralModal';
import Can from 'apps/haulpay-frontend/src/components/Can';

const ReferralInput = ({ value, onBlur, onChange, setFieldValue, disabled }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const f = async () => {
      dispatch(fetchReferralSource());
    };
    f();
  }, []);
  const referrers = useSelector(getReferralSources);
  const dialog = useSelector(getDialog);
  const onClick = useCallback(
    (args) => dispatch(openDialog('referral-modal')),
    []
  );
  const handleClose = useCallback((args) => dispatch(closeDialog()), []);

  return (
    <Grid container direction="column">
      {dialog.variant === 'referral-modal' && (
        <ReferralModal
          open={dialog.show}
          handleClose={handleClose}
          list={referrers.items}
          setFieldPreviewValue={setFieldValue}
        />
      )}
      <Select
        fullWidth
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        name="referralSource"
        label="Referral Source"
        selections={map(referrers.items, ({ name = '', id }) => ({
          value: id,
          text: name.toUpperCase(),
        }))}
        disabled={disabled}
      />
      <Can
        perform="admin-factor_client-profile:AddNewReferralSource"
        yes={() => (
          <Grid item container justify="flex-end">
            <Typography
              color="secondary"
              variant="caption"
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={onClick}
            >
              Add New?
            </Typography>, 
          </Grid>
        )}
      />
    </Grid>
  );
};
export default ReferralInput;

import React, { useCallback, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import formatPennies from "../../../../../../helpers/format/formatPennies";
import Link from "../../../../../../components/Link";
import {
  fetchReceivable,
  fetchReceivableEntries,
  getReceivable,
  getReceivableEntries,
  getReceivableEntriesLoading
} from "../../../../../../modules/finances";
import { closeDialog, getDialog } from "../../../../../../modules/ui";
import columns from "./columns";
import {
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../../components/Dialog";
import Table from "../../../../../../components/Table";
import AddNewPaymentButton from "./AddNewPaymentButton";

export default function ReceivablesDetails() {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const id = get(dialog, ["data", "id"]);

  const handleClose = useCallback(() => dispatch(closeDialog()), [dispatch]);
  useEffect(() => {
    if (id) {
      dispatch(fetchReceivable(id));
    }
  }, [id]);
  const receivable = useSelector(getReceivable(id));

  useEffect(() => {
    if (id) {
      dispatch(fetchReceivableEntries(id, "-created", 20, {}));
    }
  }, [id]);

  const entries = useSelector(getReceivableEntries);
  const loading = useSelector(getReceivableEntriesLoading);

  return (
    <Dialog
      open={dialog.show && dialog.variant === "receivables-payment-history"}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle onClose={handleClose}>Payments History</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={4}>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <Typography>
                  <b>
                    Invoice #:
                    {receivable.funding_request_invoice_number}
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                {/* <Typography> */}
                {/*  <b>Load #: {receivable.user_load_number}</b> */}
                {/* </Typography> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <Typography>
                  <b>
                    Amount Paid:
                    {formatPennies(receivable.amount_paid)}
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <b>
                    Balance:
                    {formatPennies(receivable.amount_balance)}
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <Typography>
                  <b>
                    WF Description:
                    {receivable.bank_description}
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Table
          columns={columns}
          rows={entries}
          allowEmpty
          isLoading={loading}
        />
        <Grid container direction="row-reverse">
          <Grid item>
            <Link
              to={`/admin/debtors/${receivable.debtor}/receivables`}
              style={{ textDecoration: "none" }}
            >
              <AddNewPaymentButton onClick={handleClose} />
            </Link>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

import { denormalize } from "normalizr";
import get from "lodash/get";
import { paymentProfile } from "../../../api/schema";

export default state => {
  try {
    const items = get(state, ["payment", "similarPaymentProfile", "items"], []);

    const denormalized = denormalize(
      { item: items },
      { item: [paymentProfile] },
      state.entities
    );

    return {
      data: denormalized.item,
      ...get(state, ["payment", "similarPaymentProfile"], {})
    };
  } catch (err) {
    return {};
  }
};

import { head } from "lodash";
import { PayloadAction } from "redux-starter-kit";
import { FETCH_TOKEN_FAILURE, FETCH_TOKEN_REQUEST, FETCH_TOKEN_SUCCESS, State } from "../types";

const fetchUserToken = (
  state: State,
  { type, payload }: PayloadAction
): State => {
  switch (type) {
    case FETCH_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        email: payload.email,
        authError: ""
      };
    case FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        tokenPayload: payload,
        token: payload.access_token
      };
    case FETCH_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        authError: head(payload)
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchUserToken;

import React, { useCallback } from "react";
import { createStyles, Fab, withStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import colors from "../../../../../../../theme/colors";
import {
    closeDialog,
    getDialog,
    openDialog
} from "../../../../../../../modules/ui";
import DepositDialog from "./components/DepositDialog";
import ConfirmedDeposit from "./components/ConfirmedDeposit";
import { useHistory } from "react-router-dom";

const styles = () =>
    createStyles({
        button: {
            boxShadow:
                "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
            background: colors.pink,
            color: colors.white,
            textTransform: "none"
        },
        icon: {
            marginRight: 3
        }
    });

const CreateDepositModal = ({ classes }) => {
    const dispatch = useDispatch();
    const dialog = useSelector(getDialog);
    const history = useHistory();

    const handleExit = useCallback(async () => {
        dispatch(closeDialog());
    }, []);

    return (
        <>
            <Fab
                variant="extended"
                size="medium"
                className={classes.button}
                onClick={() =>
                    dispatch(
                        openDialog(
                            undefined,
                            "",
                            "",
                            {},
                            "create_deposit"
                        )
                    )
                }
            >
                Create Deposit
            </Fab>
            {dialog.action === "create_deposit" && (
                <DepositDialog
                    open={dialog.show}
                    handleExit={handleExit}
                />
            )}
            {dialog.action === "deposit_created_successfully" && (
                <ConfirmedDeposit
                    open={dialog.show}
                    handleExit={handleExit}
                    handleConfirm={() => {
                        handleExit();
                        history.push(`/admin/finances/receivables?invoice_number=${dialog?.data?.invoiceNumber}`);
                    }}
                />
            )}
        </>
    );
};
export default withStyles(styles)(CreateDepositModal);

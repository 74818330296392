import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDollar } from "@fortawesome/free-solid-svg-icons";
import formatPennies from "../../../../../../helpers/format/formatPennies";
import AvatarCard from "../../../../../../components/AvatarCard";
import colors from "../../../../../../theme/colors";

const getColor = (value: number): string => {
  if (value < 0) {
    return colors.green;
  }
  if (value > 0) {
    return colors.danger;
  }
  return colors.link;
};

interface Props {
  pendingNegativeReserve: number;
}

const PendingNegativeReserveCard = ({
  pendingNegativeReserve
}: Props): JSX.Element => (
  <AvatarCard
    title="Pending Negative Reserve"
    color={getColor(pendingNegativeReserve)}
    icon={<FontAwesomeIcon icon={faCommentDollar} size="lg" />}
    subheader={formatPennies(pendingNegativeReserve)}
  />
);

export default PendingNegativeReserveCard;

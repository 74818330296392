export const CONFIRM_INVITE_REQUEST = "CONFIRM_INVITE_REQUEST";
export const CONFIRM_INVITE_SUCCESS = "CONFIRM_INVITE_SUCCESS";
export const CONFIRM_INVITE_FAILURE = "CONFIRM_INVITE_FAILURE";

export const EXCHANGE_PLAID_TOKEN_REQUEST = "EXCHANGE_PLAID_TOKEN_REQUEST";
export const EXCHANGE_PLAID_TOKEN_SUCCESS = "EXCHANGE_PLAID_TOKEN_SUCCESS";
export const EXCHANGE_PLAID_TOKEN_FAILURE = "EXCHANGE_PLAID_TOKEN_FAILURE";

export const NEXT_STEP = "NEXT_STEP";
export const NEXT_STEP_BY_NAME = "NEXT_STEP_BY_NAME";
export const RESET_STEPS = "RESET_STEPS";

export const CREATE_FACTORING_PROFILE_INVITE_REQUEST =
  "CREATE_FACTORING_PROFILE_INVITE_REQUEST";
export const CREATE_FACTORING_PROFILE_INVITE_SUCCESS =
  "CREATE_FACTORING_PROFILE_INVITE_SUCCESS";
export const CREATE_FACTORING_PROFILE_INVITE_FAILURE =
  "CREATE_FACTORING_PROFILE_INVITE_FAILURE";

export const CREATE_FACTORING_PROFILE_REQUEST =
  "CREATE_FACTORING_PROFILE_REQUEST";
export const CREATE_FACTORING_PROFILE_SUCCESS =
  "CREATE_FACTORING_PROFILE_SUCCESS";
export const CREATE_FACTORING_PROFILE_FAILURE =
  "CREATE_FACTORING_PROFILE_FAILURE";
export const UPDATE_FACTORING_PROFILE_REQUEST =
  "UPDATE_FACTORING_PROFILE_REQUEST";
export const UPDATE_FACTORING_PROFILE_SUCCESS =
  "UPDATE_FACTORING_PROFILE_SUCCESS";
export const UPDATE_FACTORING_PROFILE_FAILURE =
  "UPDATE_FACTORING_PROFILE_FAILURE";

export const FETCH_FACTORING_PROFILE_REQUEST =
  "FETCH_FACTORING_PROFILE_REQUEST";
export const FETCH_FACTORING_PROFILE_SUCCESS =
  "FETCH_FACTORING_PROFILE_SUCCESS";
export const FETCH_FACTORING_PROFILE_FAILURE =
  "FETCH_FACTORING_PROFILE_FAILURE";

export const CREATE_USER_PROFILE_REQUEST = "CREATE_USER_PROFILE_REQUEST";
export const CREATE_USER_PROFILE_SUCCESS = "CREATE_USER_PROFILE_SUCCESS";
export const CREATE_USER_PROFILE_FAILURE = "CREATE_USER_PROFILE_FAILURE";

export const CURRENT_USER_REQUEST = "CURRENT_USER_REQUEST";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_FAILURE = "CURRENT_USER_FAILURE";

export const FETCH_PAYMENTS_TERMS_REQUEST = "FETCH_PAYMENTS_TERMS_REQUEST";
export const FETCH_PAYMENTS_TERMS_SUCCESS = "FETCH_PAYMENTS_TERMS_SUCCESS";
export const FETCH_PAYMENTS_TERMS_FAILURE = "FETCH_PAYMENTS_TERMS_FAILURE";

export const UPDATE_PAYMENTS_TERMS_REQUEST = "UPDATE_PAYMENTS_TERMS_REQUEST";
export const UPDATE_PAYMENTS_TERMS_SUCCESS = "UPDATE_PAYMENTS_TERMS_SUCCESS";
export const UPDATE_PAYMENTS_TERMS_FAILURE = "UPDATE_PAYMENTS_TERMS_FAILURE";

export const UPDATE_INVITE_STEPS = "INVITE/UPDATE_STEPS";

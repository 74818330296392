import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  FETCH_FACTORINGPROFILE_FAILURE,
  FETCH_FACTORINGPROFILE_REQUEST,
  FETCH_FACTORINGPROFILE_SUCCESS
} from "../types";

const fetchFactoringProfile = factoringId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_FACTORINGPROFILE_REQUEST)());
    const response = await api.factoring.getProfile(factoringId);
    const data = normalize(response, schema.factoringProfile);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_FACTORINGPROFILE_SUCCESS)({
        [factoringId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_FACTORINGPROFILE_FAILURE)(err));
    throw err;
  }
};

export default fetchFactoringProfile;

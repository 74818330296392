import { Button, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Tooltip, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { delay, filter, find, flow, get, head, sortBy } from "lodash";

import { Dialog, DialogTitle, DialogContent, DialogActions } from "../../../../../../components/Dialog";
import {
  CheckBox,
  Select,
  Input
} from "../../../../../../components/inputs/BoldInput";
import {
  fetchTerms,
  updateTerms,
  getFactorClient
} from "../../../../../../modules/factoringClient";
import formatDays from "../../../../../../helpers/format/formatDays";
import formatPercent from "../../../../../../helpers/format/formatPercent";
import {
  closeDialog,
  getDialog,
  openSnackbar
} from "../../../../../../modules/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "apps/haulpay-frontend/src/theme/colors";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { NumberFormatInput } from "../../../../../../components/inputs/FormatInputs";

interface Props {
  factoringId: string;
}

interface IDiscountDropdownProps {
  index: number;
  discountRate: number;
  profileDiscountRate: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

interface IBrokerPortionProps {
  index: number;
  factoringFeeSplitRate: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const BrokerPortion = (props: IBrokerPortionProps) =>
  <Select
    fullWidth
    value={props.factoringFeeSplitRate}
    name={`terms.${props.index}.factoring_fee_split_rate`}
    onChange={props.handleChange}
    selections={[
      {
        text: "0%",
        value: 0
      },
      {
        text: "50%",
        value: 50
      },
      {
        text: "100%",
        value: 100
      }
    ]}
    {...props}
  />

const DiscountDropDown = (props: IDiscountDropdownProps) =>
  <Select
    fullWidth
    name={`terms.${props.index}.discount_rate`}
    onChange={props.handleChange}
    value={props.discountRate}
    selections={[
      {
        text: "0.75%",
        value: 0.75
      },
      {
        text: "1%",
        value: 1
      },
      {
        text: "1.25%",
        value: 1.25
      },
      {
        text: "1.5%",
        value: 1.5
      },
      {
        text: "1.75%",
        value: 1.75
      },
      {
        text: "2%",
        value: 2
      },
      {
        text: "2.25%",
        value: 2.25
      },
      {
        text: "2.5%",
        value: 2.5
      },
      {
        text: "2.75%",
        value: 2.75
      },
      {
        text: "3%",
        value: 3
      },
      {
        text: "3.25%",
        value: 3.25
      },
      {
        text: "3.5%",
        value: 3.5
      },
      {
        text: "3.75%",
        value: 3.75
      },
      {
        text: "4%",
        value: 4
      },
      {
        text: "4.25%",
        value: 4.25
      },
      {
        text: "4.5%",
        value: 4.5
      },
      {
        text: "4.75%",
        value: 4.75
      },
      {
        text: "5%",
        value: 5
      }
    ].filter(
      item => item.value <= props.profileDiscountRate
    )}
    {...props}
  />;

const EditDefaultSettingsDialog = ({ factoringId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const profile = useSelector(getFactorClient);
  const open = dialog.variant === "edit-terms";
  const [terms, setTerms] = useState([]);
  const defaultPayment = find(terms, ["is_default", true]);

  useEffect(() => {
    const f = async () => {
      const response = await dispatch(fetchTerms(factoringId));
      // @ts-ignore
      setTerms(sortBy(response || [], item => item.payee.toLowerCase()));
    };
    f();
  }, [factoringId, open]);

  const PaymentSpeed = [{
    text: '1 Day',
    value: 0,
  },
  {
    text: '7 Days',
    value: 7,
  },
  {
    text: '15 days',
    value: 15,
  },
  {
    text: '21 Days',
    value: 21,
  },
  {
    text: '30 Days',
    value: 30,
  }];

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <Formik
        enableReinitialize
        initialValues={{
          terms,
          default: get(defaultPayment, "id", ""),
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await Promise.all(
              values.terms.map((term) => {
                return dispatch(
                  updateTerms(
                    factoringId,
                    get(term as any, "id"),
                    term
                  )
                );
              })
            );
            await dispatch(openSnackbar("success", "Updated Terms!"));
          } catch (err) {
            // @ts-ignore
            openSnackbar("error", head(err));
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          setFieldValue
        }): JSX.Element => {
          return (
            <React.Fragment>
              <DialogTitle
                onClose={() => {
                  dispatch(closeDialog());
                }}
              >
                Edit Default Settings
              </DialogTitle>
              <DialogContent>
                <Typography>
                  These are the presets default options (if enabled) that this
                  broker will get to select from to give their invited carriers
                  the options to choose from.
                </Typography>
                <Grid
                  container
                  // spacing={8}
                  direction="column"
                  style={{
                    marginTop: 16,
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: 20
                  }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={8}
                      style={{ marginBottom: -20 }}
                    >
                      <Grid item xs={1}>
                        <Typography>
                          <b>Enable Option?</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>
                          <b>Pay Day</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>
                          <b>HP Discount</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>
                          <b>Broker Portion</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>
                          <b>Carrier Rate</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          <b>Self Finance Fee & Share</b>
                        </Typography>
                        <Typography>
                          <b>With Invoice        No Invoice</b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {values.terms.map((term: any, index) => {
                    let carrierRate = 0;
                    if (term.factoring_fee_split_rate === 0) {
                      carrierRate = term.discount_rate;
                    } else if (term.factoring_fee_split_rate === 50) {
                      carrierRate = term.discount_rate / 2;
                    }
                    if (term.payee === "factoring_company") {
                      return (
                        <Grid item container direction="row" justify="center" alignItems="center">
                          <Typography>
                            <Tooltip
                              placement="right"
                              title={
                                <Typography variant="body1" style={{ color: colors.white }}>
                                  This shows and sets the default payment speed for invited carriers that board with a factoring company.
                                </Typography>
                              }
                            >
                              <div>
                                <b>Choose the default payment speed for carriers boarding with a factoring company. {' '}</b>
                                <FontAwesomeIcon icon={faInfoCircle} color={colors.link} size="sm" />
                              </div>
                            </Tooltip>
                          </Typography>
                          <Grid container direction="row" spacing={8}>
                            <Grid item xs={1}>
                              <CheckBox
                                value={term.enable_factoring}
                                name={`terms.${index}.enable_factoring`}
                                setFieldValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Select
                                fullWidth
                                value={term.payout_days}
                                name={`terms.${index}.payout_days`}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const paymentSpeed: any = find(terms, (term: { payout_days: string }) => term.payout_days === e.target.value)
                                  setFieldValue(`terms.${index}.payout_days`, e.target.value, true)
                                  setFieldValue(`terms.${index}.discount_rate`, paymentSpeed?.discount_rate, true);
                                }}
                                selections={PaymentSpeed}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <DiscountDropDown
                                index={index}
                                handleChange={handleChange}
                                discountRate={term.discount_rate}
                                profileDiscountRate={profile.discount_rate}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <BrokerPortion
                                factoringFeeSplitRate={term.factoring_fee_split_rate}
                                index={index}
                                handleChange={handleChange}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Input
                                fullWidth
                                disabled
                                value={formatPercent(String(carrierRate))}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    }
                    return (
                      <Grid item>
                        <Grid container direction="row" spacing={8}>
                          <Grid item xs={1}>
                            <CheckBox
                              value={term.enable_factoring}
                              name={`terms.${index}.enable_factoring`}
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Input
                              fullWidth
                              value={
                                term.payee === "factoring_company"
                                  ? `${formatDays(term.payout_days)} - Factors`
                                  : formatDays(
                                    term.payout_days === 0
                                      ? 1
                                      : term.payout_days
                                  )
                              }
                              disabled
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <DiscountDropDown
                              index={index}
                              handleChange={handleChange}
                              discountRate={term.discount_rate}
                              profileDiscountRate={profile.discount_rate}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <BrokerPortion
                              factoringFeeSplitRate={term.factoring_fee_split_rate}
                              index={index}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Input
                              fullWidth
                              disabled
                              value={formatPercent(String(carrierRate))}
                            />
                          </Grid>
                          <Grid container item xs={3} spacing={8}>
                            <Grid item xs={4}>
                              <SelfFinanceInput
                                value={term?.self_finance_fee_with_invoice?.value}
                                name={`terms.${index}.self_finance_fee_with_invoice.value`}
                                fullWidth
                                setFieldValue={setFieldValue}
                                share={term?.self_finance_fee_with_invoice?.share}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <SelfFinanceFeeShareButton
                                value={term?.self_finance_fee_with_invoice?.share}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                  setFieldValue(`terms.${index}.self_finance_fee_with_invoice.share`, term?.self_finance_fee_with_invoice?.share === SHARE_OPTIONS.PERCENTAGE ? SHARE_OPTIONS.FLAT : SHARE_OPTIONS.PERCENTAGE, true);
                                  event.preventDefault();
                                }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <SelfFinanceInput
                                value={term?.self_finance_fee?.value}
                                name={`terms.${index}.self_finance_fee.value`}
                                fullWidth
                                setFieldValue={setFieldValue}
                                share={term?.self_finance_fee?.share}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <SelfFinanceFeeShareButton
                                value={term?.self_finance_fee?.share}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                  setFieldValue(`terms.${index}.self_finance_fee.share`, term?.self_finance_fee?.share === SHARE_OPTIONS.PERCENTAGE ? SHARE_OPTIONS.FLAT : SHARE_OPTIONS.PERCENTAGE, true);
                                  event.preventDefault();
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  Confirm
                </Button>
              </DialogActions>
            </React.Fragment>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const SHARE_OPTIONS = {
  FLAT: 'FLAT',
  PERCENTAGE: 'PERCENTAGE'
}

const SelfFinanceFeeShareButton = ({ value = SHARE_OPTIONS.FLAT, onClick }: any) => {
  return <Button
    style={{
      minWidth: 40
    }}
    size="small"
    variant="outlined"
    onClick={onClick}
  >
    <Typography variant="body1">{value === SHARE_OPTIONS.PERCENTAGE ? "%" : "$"}</Typography>
  </Button>
}

const SelfFinanceInput = ({ share = SHARE_OPTIONS.FLAT, setFieldValue, name, value, ...props }: any) => {
  const selfFinanceViews = {
    [SHARE_OPTIONS.FLAT]: (
      <Input
        {...props}
        inputProps={{
          defaultValue: Number(value) / 100,
          thousandSeparator: ',',
          prefix: "$",
          fixedDecimalScale: true,
          isNumericString: true,
          decimalSeparator: ".",
          decimalScale: 2,
        }}
        onChange={(value: string) =>
          setFieldValue(name, String(parseFloat(value) * 100), true)}
        inputComponent={NumberFormatInput}
      />
    ),
    [SHARE_OPTIONS.PERCENTAGE]: (
      <Input
        {...props}
        inputProps={{
          suffix:'%',
          isNumericString: true,
          isAllowed: (values: any) => {
            const { value } = values;
            return value < 100;
          },
          defaultValue: value
        }}
        onChange={value =>
          setFieldValue(name, value, true)}
        inputComponent={NumberFormatInput}
      />
    ),
  }
  return selfFinanceViews[share]
}

export default EditDefaultSettingsDialog;

import { compose, withStateHandlers } from "recompose";
import React, { MouseEvent } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Popover
} from "@material-ui/core";
import {
  AttachFile as AttachIcon,
  CloudDownload as DownloadIcon,
  Visibility as ViewIcon,
  Loop
} from "@material-ui/icons";
import get from "lodash/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import colors from "../../theme/colors";

interface Attachment {
  id?: string;
  filename?: string;
  modified?: string | undefined;
  created?: string;
  preview_url?: string;
  download_url?: string;
}

interface State {
  anchorEl: HTMLElement | null;
}

interface Props {
  anchorEl: HTMLElement | null;
  handleClick: (arg0: MouseEvent) => void;
  handleClose: () => void;
  attachments: Attachment[];
  error: boolean;
}

type ClickEvent = MouseEvent<HTMLElement, MouseEvent>;

interface InputProps {
  attachments?: Attachment[];
}

const AttachmentButton = compose<Props, InputProps>(
  withStateHandlers((): State => ({ anchorEl: null }), {
    handleClick: (): ((arg0: ClickEvent) => State) => (
      event: ClickEvent
    ): State => ({ anchorEl: event.target as HTMLElement }),
    handleClose: (): (() => State) => (): State => ({ anchorEl: null })
  })
)(
  ({
    anchorEl,
    handleClick,
    handleClose,
    attachments = [],
    error = false
  }: Props): JSX.Element => {
    const open = Boolean(anchorEl);
    if (error) {
      return (
        <IconButton aria-label="Attachments" aria-haspopup="true">
          <FontAwesomeIcon color={colors.danger} icon={faTimes} />
        </IconButton>
      );
    }
    if(!get(attachments, "[0].download_url", undefined)) {
      return (
        <IconButton aria-label="Attachments" aria-haspopup="true">
          <FontAwesomeIcon color={colors.green} icon={faSyncAlt} spin />
        </IconButton>
      );
    }
    return (
      <React.Fragment>
        <IconButton
          aria-label="Attachments"
          aria-owns={open ? "simple-popper" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <AttachIcon />
        </IconButton>
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <List>
            {attachments.map(
              (attachment): JSX.Element => (
                <ListItem key={attachment.id || attachment.download_url}>
                  <div style={{ paddingRight: "64px" }}>
                    <ListItemText
                      primary={attachment.filename}
                      secondary={
                        attachment.modified
                          ? attachment.modified
                          : attachment.created
                      }
                    />
                  </div>
                  <ListItemSecondaryAction>
                    <a href={attachment.preview_url} target="_blank">
                      <IconButton aria-label="View">
                        <ViewIcon />
                      </IconButton>
                    </a>
                    <a
                      href={attachment.download_url}
                      download={attachment.filename}
                    >
                      <IconButton aria-label="Download">
                        <DownloadIcon />
                      </IconButton>
                    </a>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            )}
          </List>
        </Popover>
      </React.Fragment>
    );
  }
);

export default AttachmentButton;

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import find from "lodash/find";
import {
  anthony,
  aymen,
  bianka,
  bilal,
  david,
  gonzo,
  imen,
  joanna,
  matt,
  raisa,
  smitesh,
  steve,
  trevor
} from "../theme/assets";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit
  },
  label: {
    textTransform: "capitalize"
  },
  avatar: {
    width: 30,
    height: 30
  }
});

const adminAvatarArray = [
  {
    name: "anthony recharte",
    picture: anthony
  },
  {
    name: "aymen talbi",
    picture: aymen
  },
  {
    name: "bianka anguiano",
    picture: bianka
  },
  {
    name: "bilal",
    picture: bilal
  },
  {
    name: "david stone",
    picture: david
  },
  {
    name: "gonzalo",
    picture: gonzo
  },
  {
    name: "imen mahjoubi",
    picture: imen
  },
  {
    name: "joanna",
    picture: joanna
  },
  {
    name: "matt",
    picture: matt
  },
  {
    name: "raisa",
    picture: raisa
  },
  {
    name: "smitesh",
    picture: smitesh
  },
  {
    name: "steve admin",
    picture: steve
  },
  {
    name: "trevor",
    picture: trevor
  }
];

const AdminAvatar = ({ name, classes }) => {
  const avatarObject = find(adminAvatarArray, { name: name.toLowerCase() });
  return avatarObject ? (
    <Avatar className={classes.avatar} alt={name} src={avatarObject.picture} />
  ) : (
    <Avatar className={classes.avatar}>
      {`${name
        .split(" ")
        .slice(0)
        .join(" ")
        .charAt(0)} ${name
        .split(" ")
        .slice(1)
        .join(" ")
        .charAt(0)}`}
    </Avatar>
  );
};
AdminAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

const AdminAvatarChip = ({ name, classes }) => (
  <div className={classes.root}>
    {name ? (
      <Chip
        avatar={<AdminAvatar name={name} classes={classes} />}
        label={name}
        variant="outlined"
        className={classes.chip}
        classes={{
          label: classes.label
        }}
      />
    ) : (
      <span>-</span>
    )}
  </div>
);

AdminAvatarChip.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default withStyles(styles)(AdminAvatarChip);

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { MenuItem, TextField } from "@material-ui/core";
import AvatarCard from "../../../../../components/AvatarCard";
import colors from "../../../../../theme/colors";
import CustomTextField from "../../../../../components/inputs/CustomTextField";

const AUTHORITY_CHOICES = [{
      value: "BROKER_AUTH",
      text:  "Broker Auth Only"
  }, {
      value: "DUAL_AUTH",
      text:  "Dual Authority"
  },{
      value: "CARRIER_AUTH",
      text:  "Carrier Auth Only"
  }]

const getColor = status => {
  switch (status) {
    case "BROKER_AUTH":
      return colors.purple;
    case "DUAL_AUTH":
      return colors.secondary;
    case "CARRIER_AUTH":
      return colors.pink;
    default:
      return colors.borderInputDisableColor;
  }
};

const AuthorityCoBrokeringStatusCard = ({
  title,
  value,
  name,
  handleChange,
  handleBlur
}) => (
  <AvatarCard
    title={title}
    color={getColor(value)}
    icon={<FontAwesomeIcon size="lg" icon={faTruck} />}
    subheader={
      <TextField
        fullWidth
        select
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        InputProps={{
          disableUnderline: true,
          style: { color: "white" }
        }}
      >
        {AUTHORITY_CHOICES.map(choice => (
          <MenuItem key={choice.value} value={choice.value}>
            {choice.text}
          </MenuItem>
        ))}
      </TextField>
    }
  />
);

export default AuthorityCoBrokeringStatusCard;

import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

import colors from "../../../../../../theme/colors";

const Save = () => {
    // @ts-ignore
    return <FontAwesomeIcon color={colors.green_dark} icon={faSave} />;
  };
export default Save;

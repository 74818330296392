import React from "react";
import FormatBool from "../../../../components/FormatBool";
import splitAndCase from "../../../../helpers/format/splitAndCase";

const columns = [
  {
    key: "debtor_name",
    name: "Debtor",
    filter: "text",
    filter_key: "debtor_name",
    sort: true
  },
  {
    key: "debtor_noa_contact_email",
    name: "NOA Contact Email"
  },
  {
    key: "client_name",
    name: "Client",
    filter: "text",
    filter_key: "client_name"
  },
  {
    key: "factoring.owner_profile.email",
    name: "Client Contact Email"
  },
  {
    key: "noa_summary",
    name: "NOA Summary",
    filter_key: "days_past",
    filter: "dropdown",
    filter_values: [
      {
        label: "--",
        value: null
      },
      { label: "Last 5 Days", value: "5" },
      { label: "Last 10 Days", value: "10" },
      { label: "Last 15 Days", value: "15" },
      { label: "Last 30 Days", value: "30" },
      { label: "Last 45 Days", value: "45" },
      { label: "Last 60+ Days", value: "60" }
    ]
  },
  {
    key: "user_type",
    name: "Client Type",
    default: "-",
    format: splitAndCase,
    filter_key: "user_type",
    filter: "dropdown",
    filter_values: [
      { label: "All", value: "" },
      { label: "Broker", value: "broker" },
      { label: "Carrier", value: "carrier" }
    ]
  },
  {
    key: "sent_from_fr",
    name: "Has a Funding Request?",
    format: (bool: boolean): JSX.Element => <FormatBool bool={bool} />,
    filter_key: "sent_from_fr",
    filter: "dropdown",
    filter_values: [
      { label: "All", value: "" },
      { label: "No", value: "false" },
      { label: "Yes", value: "true" }
    ]
  },
  {
    key: "more",
    name: "NOA Status",
    sort: true,
    filter_key: "noa_status",
    filter: "dropdown",
    filter_values: [
      {
        label: "--",
        value: null
      },
      { label: "NOA Not Requested", value: "noa_not_requested" },
      { label: "NOA Requested", value: "noa_requested" },
      { label: "NOA Sent", value: "noa_sent" },
    ]
  }
];

export default columns;

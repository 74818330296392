import { filter, assign, get } from "lodash";
import {
  DELETE_FACTORINGNOTE_REQUEST,
  DELETE_FACTORINGNOTE_SUCCESS,
  DELETE_FACTORINGNOTE_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case DELETE_FACTORINGNOTE_REQUEST:
      return {
        ...state,
        loadingNotes: true
      };
    case DELETE_FACTORINGNOTE_SUCCESS:
      return {
        ...state,
        loadingNotes: false,
        notes: assign({}, state.notes, {
          [action.payload.factoringId]: filter(
            get(state.notes, `${action.payload.factoringId}`, []),
            noteId => noteId !== action.payload.noteId
          )
        })
      };
    case DELETE_FACTORINGNOTE_FAILURE:
      return {
        ...state,
        loadingNotes: false
      };
    default:
      return state;
  }
};

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_CARRIER_INVOICE_FAILURE,
  FETCH_CARRIER_INVOICE_REQUEST,
  FETCH_CARRIER_INVOICE_SUCCESS
} from "../types";

const fetchCollated = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_CARRIER_INVOICE_REQUEST)());
    const response = await api.factoring.getCarrierInvoice(id);
    const data = normalize(
      { id: `${id}-carrier-invoice`, ...response },
      schema.factoring_document
    );
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_CARRIER_INVOICE_SUCCESS)({
        [id]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_CARRIER_INVOICE_FAILURE)(err));
  }
};

export default fetchCollated;

import factoring from "./factoring";
import groups from "./groups";
import plaid from "./plaid";
import reserve from "./reserve";
import user from "./user";
import debtors from "./debtors";
import admin from "./admin";
import payments from "./payments";
import documents from "./documents";
import finances from "./finances";
import account from "./account";
import vgs from "./vgs";
import berbix from "./berbix";
import factorCompany from "./factorCompany";

export default {
  account,
  admin,
  factoring,
  groups,
  plaid,
  reserve,
  user,
  debtors,
  payments,
  documents,
  finances,
  factorCompany,
  vgs,
  berbix
};

import formatStatus from "../../../../../helpers/format/formatStatus";

interface Status {
  value: string;
  name: string;
}

const statuses = [
  { value: "new_noa" },
  { value: "new" },
  { value: "fuel_advance" },
  { value: "pending" },
  { value: "pending_noa" },
  { value: "pending_originals" },
  { value: "special_pending" },
  { value: "pending_delivery" },
  { value: "document_issue" },
  { value: "document_review" },
  { value: "haulpay_review" },
  { value: "non_factored" },
  { value: "approved" },
  { value: "remote_approved" },
  { value: "declined" },
  { value: "paid" },
  { value: "deleted" },
  { value: "incomplete" },
  { value: "closed" },
  { value: "hold" },
].map(
  (s): Status => ({
    ...s,
    name: formatStatus(s.value)
  })
);

export default statuses;

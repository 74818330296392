import React from "react";
import { Grid, Typography } from "@material-ui/core";

import SpinnerButton from "../../../../../../../../components/buttons/SpinnerButton";
import { DialogActions, DialogTitle, Dialog, DialogContent } from "../../../../../../../../components/Dialog";

export default ({
    open,
    handleConfirm,
    handleExit,
}) => (
    <Dialog open={open} maxWidth="sm" fullWidth className="confirm_modal">
        <DialogTitle>Deposit Created</DialogTitle>
        <DialogContent style={{ margin: 20 }}>
            <Grid container direction="column" spacing="32">
                <Typography variant="body1">
                    The Deposit has successfully been created. Would you like to navigate to it?
                </Typography>
            </Grid>
        </DialogContent>
        <DialogActions>
            <SpinnerButton
                id="yes"
                size="small"
                color="primary"
                onClick={handleConfirm}
            >
                View Deposit
            </SpinnerButton>
            <SpinnerButton
                id="yes"
                size="small"
                color="primary"
                onClick={handleExit}
            >
                Return To Profile
            </SpinnerButton>
        </DialogActions>
    </Dialog>
);

import {
  EXCHANGE_PLAID_TOKEN_REQUEST,
  EXCHANGE_PLAID_TOKEN_SUCCESS,
  EXCHANGE_PLAID_TOKEN_FAILURE
} from "../types";

const exchangePlaidToken = (state, { type, payload }) => {
  switch (type) {
    case EXCHANGE_PLAID_TOKEN_REQUEST:
      return {
        ...state,
        exchangePlaidLoading: true
      };
    case EXCHANGE_PLAID_TOKEN_SUCCESS:
      return {
        ...state,
        exchangePlaidLoading: false,
        exchangePlaidSuccess: payload
      };
    case EXCHANGE_PLAID_TOKEN_FAILURE:
      return {
        ...state,
        exchangePlaidError: payload,
        exchangePlaidLoading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default exchangePlaidToken;

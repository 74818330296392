import {
  UPDATE_CLIENTNOTE_REQUEST,
  UPDATE_CLIENTNOTE_SUCCESS,
  UPDATE_CLIENTNOTE_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case UPDATE_CLIENTNOTE_REQUEST:
      return {
        ...state,
        loadingClientNotes: true
      };
    case UPDATE_CLIENTNOTE_SUCCESS:
      return {
        ...state,
        loadingClientNotes: false
      };
    case UPDATE_CLIENTNOTE_FAILURE:
      return {
        ...state,
        loadingClientNotes: false
      };
    default:
      return state;
  }
};

import { Grid, Tooltip } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import MoreButton from "../../../../components/buttons/MoreButton";

interface Props {
  link: string;
}

export default ({ link }: Props): JSX.Element => (
  <Grid container>
    <Grid item>
      <Tooltip title="View Profile">
        <Link to={link}>
          <MoreButton />
        </Link>
      </Tooltip>
    </Grid>
  </Grid>
);

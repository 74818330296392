import { createAction } from "redux-starter-kit";
import {
  CREATE_LINEITEM_FAILURE,
  CREATE_LINEITEM_REQUEST,
  CREATE_LINEITEM_SUCCESS
} from "../types";

const createLineItem = (
  fundingRequestId,
  amount,
  discountRate,
  category,
  description,
  approved
) => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(CREATE_LINEITEM_REQUEST)({}));
    await api.factoring.createLineItem(fundingRequestId, {
      amount_requested: amount,
      discount_rate: discountRate,
      category,
      description,
      approval_status: approved
    });
    dispatch(createAction(CREATE_LINEITEM_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(CREATE_LINEITEM_FAILURE)(err));
    throw err;
  }
};

export default createLineItem;

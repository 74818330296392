import React from "react";
import { List } from "@material-ui/core";
import { useFormikContext } from 'formik';
import { useDispatch } from "react-redux";

import ListItem from "../../../../../components/ListItem";
import Card from "../../../../../components/Card";
import BoldInput, { CheckBox, Pennies } from "../../../../../components/inputs/BoldInput";
import FactoringStatusInput from "./FactoringStatusInput";
import { Attachments, Category, File } from "../../../../../components/AttachmentsCard";
import ProfileSection from "../../../../../components/ProfileSection";
import { handleDeleteAttachment } from "../../../../../modules/user";

interface Props {
  files: File[];
  categories: Category[];
  filterCategories?: string[];
}

function CreditDetails({
  files,
  categories,
  filterCategories
}: Props): JSX.Element {
  const { values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched } = useFormikContext<any>();
  const dispatch = useDispatch()
  return (
    <Card style={{ height: "100%" }}>
      <List dense>
        <ListItem>
          <ProfileSection>Credit Details</ProfileSection>
        </ListItem>
        <ListItem>
          <FactoringStatusInput
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.factoring_approved}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="avgpay"
            error={!!errors.avgpay}
            helperText={errors.avgpay}
            label="Average days to pay"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.avgpay}
          />
        </ListItem>
        <ListItem>
          <Pennies
            fullWidth
            name="cargo_insurance_value"
            label="Cargo Insurance Value"
            error={!!errors.cargo_insurance_value}
            helperText={errors.cargo_insurance_value}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            value={values.cargo_insurance_value}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            label="Cargo Insurance Provider"
            fullWidth
            error={!!errors.cargo_insurance_provider}
            helperText={errors.cargo_insurance_provider}
            value={values.cargo_insurance_provider}
            name="cargo_insurance_provider"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ListItem>
        <ListItem>
          <CheckBox
            disabled
            value={values.hasAppliedFactoring}
            name="hasAppliedFactoring"
            setFieldValue={setFieldValue}
            label="Has Applied For Factoring"
          />
        </ListItem>
        <ListItem>
          <CheckBox
            disabled
            value={values.credit_approved}
            name="credit_approved"
            setFieldValue={setFieldValue}
            label="Credit Approved Emblem"
          />
        </ListItem>
        <ListItem>
          <CheckBox
            disabled
            value={values.credit_approved}
            name="haulpay_interest"
            setFieldValue={setFieldValue}
            label="HaulPay Interest"
          />
        </ListItem>
        <ListItem>
          <ProfileSection>Attachments</ProfileSection>
        </ListItem>
      </List>
      <div style={{ margin: 8 }}>
        <Attachments
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          values={values}
          files={files}
          handleDelete={(id: string) => {dispatch(handleDeleteAttachment(id))}}
          categories={categories}
          filterCategories={filterCategories}
          multipleCategories
        />
      </div>
    </Card>
  );
}

export default CreditDetails;

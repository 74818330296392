import React, { MouseEventHandler } from 'react';
import { Formik } from 'formik';
import { Button, FormControl, List, ListItem } from '@material-ui/core';

import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
} from '../../../../../components/Dialog';
import PenniesInput from '../../../../../components/inputs/PenniesInput';
import CustomTextField from '../../../../../components/inputs/CustomTextField';
import AgainstWhoInput from './AgainstWhoInput';
import CategoryInput from './CategoryInput';
import BillWithFundingRequest from './BillWithFundingRequest';
import AdminDropDown from "../../../../../components/AdminDropDown";
import ApproveInput from './ApproveInput';
import { lineItemEditForm } from '../../validation';

interface Category {
  id: string;
  name: string;
  appliedAgainst: string;
}

interface Props {
  open: boolean;
  lineItemForm: any;
  categories: Category[];
  handleClose?: MouseEventHandler<HTMLButtonElement>;
  type?: string;
  status?: string;
}

export default ({
  open,
  lineItemForm,
  categories,
  handleClose,
  type = '',
  status = '',
}: Props): JSX.Element => {
  const disabled = ['view', 'edit'].includes(type);
  const canEditDescription = !['approved', 'paid', 'deleted', 'non-factored'].includes(status);
  const disableField = type === 'view' || !canEditDescription;
  let title = '';
  switch (type) {
    case 'view':
      title = "View Line Item";
      break;
    case 'edit':
      title = "Edit Line Item";
      break;
    default:
      title = "Add New Line Item";
      break;
  }
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      <Formik {...lineItemForm} validationSchema={lineItemEditForm}>
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
          errors
        }): JSX.Element => {
          const applicableCategories = categories.filter(
            (category) => category.appliedAgainst === values.againstWho
          );
          return (
            <React.Fragment>
              <DialogContent>
                <List>
                  {
                    disabled &&
                    <ListItem>
                      <FormControl fullWidth>
                        <AdminDropDown
                          name="transactionRep"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Created By"
                          value={values.createdBy}
                          disabled
                          selectType="classic"
                        />
                      </FormControl>
                    </ListItem>
                  }
                  <ListItem>
                    <FormControl fullWidth>
                      <PenniesInput
                        label="Amount Requested"
                        name="amountRequested"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.amountRequested}
                        withStyle
                        disabled={type !== 'Add' ? disableField : false}
                      />
                    </FormControl>
                  </ListItem>
                  {/* <ListItem>
                    <CustomTextField
                      fullWidth
                      label="Discount Rate"
                      name="discountRate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.discountRate}
                      disabled={disabled}
                    />
                  </ListItem> */}
                  <ListItem>
                    <FormControl fullWidth>
                      <AgainstWhoInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.againstWho}
                        disabled={type !== 'Add' ? disableField : false}
                      />
                    </FormControl>
                  </ListItem>
                  <ListItem>
                    <FormControl
                      fullWidth
                      disabled={applicableCategories.length === 0}
                    >
                      <CategoryInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.category}
                        categories={applicableCategories}
                        disabled={type !== 'Add' ? disableField : false}
                      />
                    </FormControl>
                  </ListItem>
                  <ListItem>
                    <CustomTextField
                      fullWidth
                      label="Description"
                      name="description"
                      multiline
                      rows={3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      error={!!errors.description}
                      helperText={errors.description}
                      disabled={type === 'view'}
                    />
                  </ListItem>
                  {/* <ListItem>
                    <FormControl fullWidth>
                      <ApproveInput
                        value={values.approve}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disabled}
                      />
                    </FormControl>
                  </ListItem> */}
                  {values.againstWho === 'debtor' && (
                    <ListItem>
                      <BillWithFundingRequest
                        setFieldValue={setFieldValue}
                        value={values.billWithFundingRequest}
                        disabled={type !== 'Add' ? disableField : false}
                      />
                    </ListItem>
                  )}
                </List>
              </DialogContent>
              {type !== 'view' &&
                <DialogActions>
                  <Button
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={(): void => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </DialogActions>
              }
            </React.Fragment>
          );
        }}
      </Formik>
    </Dialog>
  );
};

import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { attachment } from "../../../api/schema";

export default memoize(state => factoringId => {
  try {
    const attachments = get(
      state,
      [`factoring`, `attachments`, factoringId],
      []
    );

    const denormalized = denormalize(
      { attachments },
      { attachments: [attachment] },
      state.entities
    );

    return denormalized.attachments;
  } catch (err) {
    return [];
  }
});

import {
  DialogContent as MuiDialogContent,
  withStyles,
  createStyles
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const DialogContent = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing.unit * 2
    }
  })
)(MuiDialogContent);

export default DialogContent;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import colors from "../../theme/colors";

interface IconProps {
  icon: IconDefinition;
}
const Icon = ({ icon }: IconProps): JSX.Element => {
  return (
    <FontAwesomeIcon
      // @ts-ignore
      icon={icon}
      size="1x"
      style={{
        color: colors.secondary,
        paddingLeft: 2
      }}
    />
  );
};

export default Icon;

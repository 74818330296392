import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_NOTES_FAILURE,
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_SUCCESS
} from "../types";

const fetchFactorCompanyNotes = factorCompanyId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_NOTES_REQUEST)());
    const response = await api.factorCompany.getFactorCompanyNotes(
      factorCompanyId
    );
    const data = normalize(response, [schema.notes]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_NOTES_SUCCESS)({
        [factorCompanyId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_NOTES_FAILURE)(err));
    throw err;
  }
};

export default fetchFactorCompanyNotes;

import React, { ChangeEventHandler } from "react";
import { Grid, List } from "@material-ui/core";
import { useFormikContext, Formik, Form, Field } from 'formik';

import ListItem from "../../../../../components/ListItem";
import Card from "../../../../../components/Card";
import AutoCompleteAddress from "../../../../../components/inputs/AutoCompleteAddress";
import BoldInput from "../../../../../components/inputs/BoldInput";
import formatPhone from "../../../../../helpers/format/formatPhone";
import onlyNumbers from "../../../../../helpers/format/onlyNumbers";
import ProfileSection from "../../../../../components/ProfileSection";

interface IProps {
  userType: string;
};

function ContactInfo({ userType }: IProps): JSX.Element {
  const { values, handleChange, handleBlur, setFieldValue, setFieldTouched } = useFormikContext<any>();
  return (
    <Card style={{ height: "100%" }}>
      <List dense>
        <ListItem>
          <ProfileSection>Contact Info</ProfileSection>
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="contact_email"
            disabled
            label="Contact Email (PE Only)"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contact_email}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="email"
            label="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            disabled={userType !== "admin"}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="contact_name"
            label="Contact Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contact_name}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="dba"
            label="Company"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.dba}
          />
        </ListItem>
        <ListItem>
          <AutoCompleteAddress
            name="address"
            value={values.address}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            withStyle
          />
        </ListItem>
        <ListItem>
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                value={formatPhone(onlyNumbers(values.contact_phone))}
                name="contact_phone"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Phone"
                id="phone"
              />
            </Grid>
            <Grid item xs={6}>
              <BoldInput
                label="Ext."
                fullWidth
                value={values.phone_ext}
                name="phone_ext"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                value={formatPhone(onlyNumbers(values.fax_number))}
                name="fax_number"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Fax"
                id="fax"
              />
            </Grid>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                label="Ext."
                value={values.fax_ext}
                name="fax_ext"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="MC"
            value={onlyNumbers(values.mc)}
            name="mc"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="DOT"
            value={onlyNumbers(values.dot)}
            name="dot"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            label="Stripe Email"
            fullWidth
            value={values.stripe_email}
            name="stripe_email"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ListItem>
      </List>
    </Card>
  );
}

export default ContactInfo;
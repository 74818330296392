import React from "react";
import { Redirect } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { get } from "lodash";

import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import Table from "../../../../components/Table";
import NavigationTabs from "../../../../components/NavigationTabs";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import AuditLogDialog from "../../Debtor/ConnectedClients/components/AuditLogDialog";
import FuelAdvanceDialog from "./components/FuelAdvanceDialog";
import InvoicingSettingsDialog from "./components/InvoicingSettingsDialog";
import UploadAttachmentDialog from '../../../../components/dialogs/UploadAttachmentDialog';

const ConnectedDebtors = ({
  navIndex,
  handleRoute,
  tabs,
  columns,
  isSelected,
  allSelected,
  handleSelect,
  handleSelectAllClick,
  loading,
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  sortDirection,
  sortBy,
  filters,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleFilterChange,
  connectedDebtors,
  connectedDebtorForm,
  dialog,
  closeDialog,
  handleDeleteConfirm,
  fuelAdvanceForm,
  sendNOADialog,
  closeSendNOA,
  onSubmitInvoincingSettings,
  userType
}) => (
  <Can
    perform="admin-factorclients:view"
    yes={() => (
      <InternalLayout title="Factor Client">
        <NavigationTabs
          tabs={tabs}
          value={navIndex}
          handleRoute={handleRoute}
        />
        <Paper style={{ marginTop: "32px" }}>
          <Table
            columns={columns(userType)}
            rows={connectedDebtors}
            select
            filter
            sort
            isSelected={isSelected}
            allSelected={allSelected}
            handleSelect={handleSelect}
            handleSelectAllClick={handleSelectAllClick}
            isLoading={loading}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            sortDirection={sortDirection}
            sortBy={sortBy}
            filters={filters}
            handleSort={handleSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleFilterChange={handleFilterChange}
            formikRow={connectedDebtorForm}
            allowEmpty
          />
          {dialog.variant === "auditlog" && (
            <AuditLogDialog
              open={dialog.show}
              handleClose={closeDialog}
              relationshipId={get(dialog, ["data", "relationshipId"])}
            />
          )}
          {dialog.variant === "confirm" && (
            <ConfirmDialog
              open={dialog.show}
              message={dialog.message}
              handleConfirm={handleDeleteConfirm}
              handleExit={() => closeDialog()}
            />
          )}
          {dialog.variant === "fuel-advance-settings" && (
            <FuelAdvanceDialog
              open={dialog.show}
              handleClose={closeDialog}
              fuelAdvanceForm={fuelAdvanceForm}
            />
          )}
            {dialog.variant === "co-brokering-documents" && (
              <UploadAttachmentDialog
                uploadFileProps={{
                    type: "CO_BROKER_DEBTOR_AGREEMENT_DOCS",
                    requestId: dialog.data.relationshipId,
                    categoriesProps: [
                        "Shipper Acknowledgement",
                        "Other"
                    ],
                    defaultCategoryValue: "Other"
                }}
                attachments={dialog.data.attachments}
                handleExit={closeDialog}
                open={dialog.show}
              />
            )}
          {dialog.variant === "invoincing-settings" && (
            <InvoicingSettingsDialog
              open={dialog.show}
              handleClose={closeDialog}
              onSubmitHandler={onSubmitInvoincingSettings}
              {...dialog.data}
            />
          )}
        </Paper>
      </InternalLayout>
    )}
    no={() => <Redirect to="/" />}
  />
);

export default ConnectedDebtors;

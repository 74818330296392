import axios from "axios";
import { get, merge } from "lodash";

axios.interceptors.request.use(
  config => {
    const newConfig = { params: {} };

    ["amount"].forEach(path => {
      const value = get(config.params, path);
      if (value) {
        newConfig.params[path] = value.replace(".", "");
      }
    });

    return merge({}, config, newConfig);
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

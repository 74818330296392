import React, { useState, useCallback } from "react";
import { Formik } from "formik";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import map from "lodash/map";

import {
  addReasonForLeaving,
  updateReasonForLeaving,
  deleteReasonForLeaving
} from "../../../../../../../modules/factoring";
import BoldInput from "../../../../../../../components/inputs/BoldInput";
import SpinnerButton from "../../../../../../../components/buttons/SpinnerButton";
import { openSnackbar } from "../../../../../../../modules/ui";
import {
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../../../components/Dialog";

const ReasonForLeavingModal = ({
  handleClose,
  open,
  list = [],
  setFieldPreviewValue
}) => {
  const dispatch = useDispatch();
  const [formAction, setFormAction] = useState("Add");
  const onSubmit = useCallback(
    async (fields, formikActions, formAction) => {
      try {
        formikActions.setSubmitting(true);
        let res;
        if (formAction === "Add") {
          res = await dispatch(addReasonForLeaving(fields.name));
        } else {
          res = await dispatch(updateReasonForLeaving(fields));
        }

        setFieldPreviewValue("reasonForLeaving", res.id);
        dispatch(
          openSnackbar(
            "success",
            `Reason for Leaving has been ${
              formAction === "Add" ? "added" : "updated"
            }.`
          )
        );
      } catch (e) {
        dispatch(
          openSnackbar(
            "error",
            `Error while ${
              formAction === "Add" ? "adding" : "updating"
            } Reason for Leaving.`
          )
        );
      }
      formikActions.setSubmitting(false);
      formikActions.resetForm();
    },
    [dispatch]
  );

  const editAction = useCallback(({ setFieldValue, name, id }) => {
    setFieldValue("name", name);
    setFieldValue("id", id);
    setFormAction("Edit");
  }, []);
  const deleteAction = useCallback(
    (id, name, handleReset) => {
      try {
        handleReset();
        dispatch(deleteReasonForLeaving(id));
        dispatch(
          openSnackbar("success", `${name.toUpperCase()} has been deleted.`)
        );
        setFormAction("Add");
      } catch (e) {
        dispatch(
          openSnackbar("error", `Error while deleting ${name.toUpperCase()}.`)
        );
      }
    },
    [dispatch]
  );
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle onClose={handleClose}>Reason for Leaving</DialogTitle>
      <Formik
        onSubmit={(fields, formikActions) =>
          onSubmit(fields, formikActions, formAction)
        }
        initialValues={{
          name: "",
          id: ""
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          errors,
          handleReset
        }) => (
          <DialogContent>
            <Grid container direction="column" spacing={24}>
              <Grid item>
                <BoldInput
                  fullWidth
                  name="name"
                  label="Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid
                item
                container
                justify={"flex-end"}
                alignItems={"flex-end"}
                xs
              >
                <SpinnerButton
                  disabled={isSubmitting}
                  style={{ justifySelf: "flex-end" }}
                  variant="raised"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {formAction === "Add" ? `Save` : `Update`}
                </SpinnerButton>
              </Grid>
              <Grid item>
                <List>
                  {map(list, ({ name = "", id }) => (
                    <ListItem key={id}>
                      <ListItemText primary={name.toUpperCase()} />
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Edit"
                          onClick={() =>
                            editAction({ setFieldValue, name, id })
                          }
                        >
                          <EditIcon color={"secondary"} />
                        </IconButton>
                        <IconButton
                          aria-label="Delete"
                          onClick={() => deleteAction(id, name, handleReset)}
                        >
                          <DeleteIcon color={"error"} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Formik>
    </Dialog>
  );
};

export default ReasonForLeavingModal;

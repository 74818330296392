import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, IconButton } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { Formik } from "formik";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FactorCompanyAutoComplete from "../../../components/inputs/FactorCompanyAutocomplete";
import RoundedButton from "../../../components/buttons/RoundedButton";
import formatLocation from "../../../helpers/format/formatAddress";
import UploadFile from "../../../components/UploadFile";
import factorCompanyAPI from "../../../api/factorCompany";
import CreateFactorCompanyForm from "./CreateFactorComapnyForm";
import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../modules/ui";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import SnackbarContentWrapper from "../../../components/Snackbar";
import CreateFactoringSchema from "../../Dashboard/ICPortal/components/CreateFactoringSchema";

const DetailsFactorCompany = ({ value }) => {
  if (!value) return <div />;
  return <Typography variant="caption">{value}</Typography>;
};

const FactorCompany = ({
  brokerFactoringId,
  setFactor,
  moveToNextStep,
  paymentProfileId,
  backAction,
  boarding,
  createRelation = true
}) => {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const closeConfirmDialog = useCallback(args => dispatch(closeDialog()), []);
  const [selectedFactorCompany, setSelectedFactorCompany] = useState(undefined);
  const [uploadNOA, setUploadNOA] = useState(false);
  const [relationship, setRelationship] = useState({});
  const [displayCreateForm, setDisplayCreateForm] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [relationError, setRelationError] = useState("");

  const openConfirmInviteModal = useCallback(
    args =>
      dispatch(
        openDialog(
          "confirm_send_invite",
          "Confirm Send Invite",
          "Are you sure you would like to use this factoring company?"
        )
      ),
    []
  );

  const handleConfirm = useCallback(
    async args => {
      setLoading(true);
      closeConfirmDialog();
      moveToNextStep(selectedFactorCompany);
      if (boarding) {
        return setLoading(false);
      }
      try {
        const relation = await factorCompanyAPI.getRelationByFactorCompanyId(
          args
        );
        if (isEmpty(find(relation.results, ["factoring_company", args]))) {
          await factorCompanyAPI.createFactorCompanyRelation(
            args,
            brokerFactoringId
          );
        }
        await factorCompanyAPI.sendFactorCompanyInvite(args, paymentProfileId);
      } catch (e) {}
      setLoading(false);
    },
    [selectedFactorCompany]
  );

  const onSubmit = useCallback(async fields => {
    try {
      const res = await factorCompanyAPI.createFactorCompany({
        ...fields,
        ...fields.address
      });
      setSelectedFactorCompany({
        ...res,
        label: res.name
      });
    } catch (e) {
      dispatch(openSnackbar("error", e));
    }
    setDisplayCreateForm(false);
  }, []);

  const IconBack = ({ onClick }) => (
    <Grid item container justify="flex-start">
      <IconButton aria-label="back" onClick={onClick}>
        <ArrowBackIcon />
      </IconButton>
    </Grid>
  );

  if (displayCreateForm)
    return (
      <Grid container direction="column" xs={10} sm={9} md={10}>
        <IconBack onClick={() => setDisplayCreateForm(false)} />
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone_number: "",
            address: ""
          }}
          onSubmit={onSubmit}
          component={CreateFactorCompanyForm}
          validationSchema={CreateFactoringSchema}
          ß
        />
      </Grid>
    );
  return (
    <Grid
      container
      direction="column"
      xs={12}
      md={10}
      justify="center"
      alignItems="center"
      alignContent="center"
      style={{ margin: "auto", width: "80%" }}
      spacing={24}
    >
      <IconBack
        onClick={() => {
          setFactor(false);
          backAction && backAction();
        }}
      />
      <Grid item>
        <Typography variant="body2" align="center">
          {selectedFactorCompany
            ? `You can send an invite to factoring company you are working with by adding their email, or
if you already have the notice of assignment you can upload it by clicking on the blue button.`
            : `Search for the factoring company that you're using below.`}
        </Typography>
      </Grid>
      <Grid item>
        {error && (
          <SnackbarContentWrapper
            variant="error"
            message="Please select a factoring company"
            onClick={() => setError(false)}
          />
        )}
        {relationError && (
          <SnackbarContentWrapper
            variant="error"
            message={relationError}
            onClick={() => setRelationError("")}
          />
        )}
      </Grid>
      <Grid
        item
        container
        style={{ margin: "auto", width: "100%", height: 'auto', minHeight: 300 }}
        xs={12}
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} md={6} style={{ height: 'auto' }}>
          <FactorCompanyAutoComplete
            label="Company Name"
            placeholder="Company Name"
            onNoOptionClick={() => setDisplayCreateForm(true)}
            value={selectedFactorCompany}
            onChange={value => {
              setError(false);
              setSelectedFactorCompany(value);
            }}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={4}
          justify="flex-end"
          alignItems="flex-end"
          alignContent="flex-end"
        >
          <RoundedButton
            style={{ width: "100%", paddingLeft: 20 }}
            variant="contained"
            color="primary"
            size="large"
            onClick={async () => {
              if (isEmpty(selectedFactorCompany)) return setError(true);
              await openConfirmInviteModal();
            }}
            loading={loading}
            disabled={isEmpty(selectedFactorCompany)}
          >
            Use This Factor
          </RoundedButton>
        </Grid>
        {!isEmpty(selectedFactorCompany) && (
          <>
            <Grid
              item
              container
              style={{ margin: "auto", width: "100%" }}
              xs={12}
              justify="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={6}>
                <DetailsFactorCompany value={selectedFactorCompany.email} />
                <DetailsFactorCompany
                  value={selectedFactorCompany.phone_number}
                />
                <DetailsFactorCompany
                  value={formatLocation(selectedFactorCompany)}
                />
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </>
        )}
      </Grid>
      {!isEmpty(selectedFactorCompany) && (
        <Grid item style={{ margin: "auto", width: "100%" }} xs={12}>
          {!uploadNOA ? (
            <RoundedButton
              style={{ width: "100%" }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={async () => {
                try {
                  setRelationship(
                    await factorCompanyAPI.createFactorCompanyRelation(
                      selectedFactorCompany.id,
                      brokerFactoringId,
                      undefined,
                      {
                        create_relation: createRelation
                      }
                    )
                  );
                  setUploadNOA(true);
                } catch (e) {
                  setRelationError(e);
                }
              }}
            >
              I already have the NOA
            </RoundedButton>
          ) : (
            <UploadFile
              single
              noCategories
              type="NOA"
              requestId={relationship.id}
            />
          )}
        </Grid>
      )}
      {dialog.variant === "confirm_send_invite" && (
        <ConfirmDialog
          open={dialog.show}
          message={dialog.message}
          handleConfirm={() => handleConfirm(selectedFactorCompany.id)}
          handleExit={closeConfirmDialog}
        />
      )}
    </Grid>
  );
};

export default FactorCompany;

import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";

import AvatarCard from "../../../../../../components/AvatarCard";
import colors from "../../../../../../theme/colors";

const statuses = [
  {
    value: "new",
    name: "New"
  },
  {
    value: "document_review",
    name: "Document Review"
  },
  {
    value: "document_issue",
    name: "Document Issue"
  },
  {
    value: "pending",
    name: "Pending"
  },
  {
    value: "contract_out",
    name: "Contract Out"
  },
  {
    value: "ucc_hold",
    name: "UCC Hold"
  },
  {
    value: "not_authorized",
    name: "Not Authorized"
  },
  {
    value: "approved",
    name: "Approved"
  },
  {
    value: "declined",
    name: "Declined"
  },
  {
    value: "dead",
    name: "Dead"
  },
  {
    value: "bad_lead",
    name: "Bad Lead"
  },
  {
    value: "lead_in",
    name: "Lead In"
  },
  {
    value: "broker_invited",
    name: "Broker Invited"
  }
];

const getColor = status => {
  switch (status) {
    case "new":
      return colors.green;
    case "document_review":
      return colors.warning;
    case "document_issue":
      return colors.warning;
    case "pending":
      return colors.warning;
    case "contract_out":
      return colors.warning;
    case "broker_invited":
      return colors.warning;
    case "ucc_hold":
      return colors.danger;
    case "not_authorized":
      return colors.danger;
    case "approved":
      return colors.green;
    case "declined":
      return colors.danger;
    case "dead":
      return colors.danger;
    case "bad_lead":
      return colors.danger;
    default:
      return colors.warning;
  }
};

const ClientApprovalStateCard = ({ values, handleChange, handleBlur, disabled }) => (
  <AvatarCard
    title="Client Approval Status"
    color={getColor(values.creditApprovalStatus)}
    icon={<FontAwesomeIcon size="lg" icon={faUserClock} />}
    subheader={
      <TextField
        fullWidth
        select
        name="creditApprovalStatus"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.creditApprovalStatus}
        InputProps={{
          disableUnderline: true,
          style: { color: "white" }
        }}
        disabled={disabled}
      >
        {statuses.map(status => (
          <MenuItem key={status.value} value={status.value}>
            {status.name}
          </MenuItem>
        ))}
      </TextField>
    }
  />
);

export default ClientApprovalStateCard;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchObligations, getObligationById, getObligations, fetchObligationById } from "../../../../../modules/obligations";
import columns from "./columns";
import Table from "../../../../../components/Table";
import { CardContent, Grid, IconButton, Typography } from '@material-ui/core';
import ObligationStatus from "./Components/ObligationStatus";
import formatDollars from "../../../../../helpers/format/formatDollars";
import formatDate from "../../../../../helpers/format/formatDate";
import { openSnackbar } from "../../../../../modules/ui";
import api from "../../../../../api";
import nestedPaymentColumn from "./nestedPaymentColumns";
import lineItemsColumns from "./LineItemsColumns";
import colors from "../../../../../theme/colors";
import moment from "moment";
import Card from "../../../../../components/Card";
import ObligationsTypeComponents from "../../../../../components/ObligationsTypeComponents";
import LineItemBuilder from "./LineItemBuilder";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import Colors from '../../../../../theme/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, map } from 'lodash';
import { deleteObligationLineItem } from '../../../../../api/factoring/contracts';
import isEmpty from 'lodash/isEmpty';


interface IProps {
    contractId: string;
    useNewBrokerFR?: boolean;
}

interface IExpandableView {
    obligationId: string;
    contractId: string;
}

interface ILineItems {
    id: string;
    description: string;
    rate: number;
    rate_type: string;
    quantity: number;
    total_amount: number;
    is_deletable: boolean;
}

interface IParties {
    type: string;
    user: string;
    company: string;
    account: string;
    role: string;
    name: string;
}

interface IObligation {
    id: string;
    created: string;
    to_party: IParties;
    from_party: IParties;
    amount: number;
    status: string;
    type: string;
    description: string;
    line_items: Array<ILineItems>;
    payments_due_date: string;
}

interface IPayment {
    amount: number;
    status: string;
    paid_at: string;
    payment_reference: string;
    due_date: string;
    account_type: string;
    account_method: string;
    account_number: string;
    created: string;
    payments_due_date: string;
};

const typeMapping: { [key: string]: string; } = {
    INVOICE_RECEIVABLE: "Invoice",
    NON_INVOICE_RECEIVABLE: "Non Invoice",
    DEBIT_RECEIVABLE: "Debit",
    PURCHASE: "Purchase",
    EARNINGS: "Earnings",
    PAYABLE: "Payable",
    LEDGER_WALLET_WITHDRAWAL: "Ledger Wallet Withdrawal"
}

function PaymentExpandableView({ obligationId, contractId }: IExpandableView): JSX.Element {
    const dispatch = useDispatch();
    const [payments, setPayments] = useState<Array<IPayment>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const obligation = useSelector(getObligationById(obligationId)) as unknown as IObligation;
    const [lineItems, setLineItems] = useState([] as ILineItems[]);

    useEffect(() => {
        setLineItems(obligation.line_items);
    }, [obligation]);

    useEffect(() => {
        const f = async () => {
            setIsLoading(true);
            await dispatch(fetchObligationById(contractId, obligationId));
            const { results } = await api.factoring.getPayments(contractId, obligationId);
            setPayments(results);
            setIsLoading(false);
        };
        f();
    }, [obligationId]);

    const handleDelete = useCallback(async id => {
        try {
            await api.factoring.deleteObligationLineItem(id);
            const updatedLineItems = lineItems.filter(item => item.id !== id);
            setLineItems(updatedLineItems);
            dispatch(openSnackbar("success", "Line Item Deleted!"));
        } catch(e) {
            dispatch(openSnackbar("error", e.message || e));
        }
    }, []);

    const ObligationStatusText = ({ status }: { status: string; }): JSX.Element => {
        return (
            <Table
                columnStyle={{ color: colors.primary }}
                columns={nestedPaymentColumn}
                rows={payments.map(payment => ({
                    ...payment,
                    status: (
                        <ObligationStatus status={payment.status} />
                    ),
                    to: obligation.to_party.name,
                    from: obligation.from_party.name,
                    account: `${payment.account_method} (${payment.account_type})`,
                    created: moment(payment.created).format('l'),
                }))}
                isLoading={isLoading}
                allowEmpty
            />
        )
    }

    const lineItemRows = map(lineItems, lineItem => ({
        ...lineItem,
        action: () => {
            if(lineItem.is_deletable) {
                return (
                    <IconButton onClick={async (): Promise<void> => await handleDelete(lineItem.id)}>
                        <FontAwesomeIcon icon={faTrashAlt} color={Colors.danger} size="xs" />
                    </IconButton>
                )
            }
        }
    }))

    const LineItemsTable = (): JSX.Element =>
        <Table
            columnStyle={{ color: colors.primary }}
            columns={lineItemsColumns}
            rows={lineItemRows}
            isLoading={isLoading}
            allowEmpty
        />

    return (
        <Grid container direction="column" style={{ textAlign: "left" }}>
            <Grid item style={{ paddingTop: 10, paddingLeft: 10 }}>
                {payments.map(payment =>
                    <Grid>
                        <Typography variant="body2" style={{ wordSpacing: 10 }}>
                            <Typography variant="body2" inline style={{ wordSpacing: 0 }}>{typeMapping[obligation.type]}</Typography><ObligationStatus status={payment.status} />
                            {`${formatDollars(payment.amount)} on ${moment(payment.created).format('l')}`}
                            <Typography variant="body1" inline color="primary" style={{ marginLeft: 10, marginRight: 10 }}>From</Typography>
                            {`${obligation.from_party.name}`}
                            <Typography variant="body1" inline color="primary" style={{ marginLeft: 10, marginRight: 10 }}>To→</Typography>
                            {`${obligation.to_party.name}`}
                            <Typography variant="body1" inline style={{ marginLeft: 10, marginRight: 10 }}>account</Typography>
                            {`${payment.account_method}(${payment.account_type})`}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid item container direction="row" style={{ paddingTop: 10, paddingLeft: 10 }}>
                <LineItemsTable />
            </Grid>
        </Grid>
    )
}
export default function ObligationsTab({
    contractId,
    useNewBrokerFR,
}: IProps): JSX.Element {
    const dispatch = useDispatch();
    const obligations = useSelector(getObligations);

    const runFailedLedgerWalletObligationHandler = useCallback(async obligationId => {
        try {
            await api.factoring.runFailedLedgerWalletObligation(obligationId);
            dispatch(openSnackbar("success", "Obligation has been reprocessed."))
        } catch (error) {
            dispatch(openSnackbar("error", error as string))
        }
    }, [obligations]);

    const handleResubmitButton = useCallback(async obligationId => {
        try {
            await api.factoring.resubmitObligation(obligationId);
            dispatch(openSnackbar("success", "Obligation has been resubmitted."))
        } catch (error) {
            dispatch(openSnackbar("error", error as string))
        }
    }, []);

    useEffect(() => {
        const f = async () => {
            try{
                await dispatch(fetchObligations(contractId));
            } catch (error) {
            }
        };
        if(contractId) {
            f().then();
        }
    }, [contractId]);

    const hasPurchase = !isEmpty(find(obligations, obligation => obligation.status === "COMPLETED" && obligation.description === "Delivery advance carrier"));
    console.log("HAS PURCHASE", hasPurchase);

    return (
        <div>
            {useNewBrokerFR &&
                <LineItemBuilder contractId={contractId} hasPurchase={hasPurchase} />
            }
            <Table
                columns={columns(runFailedLedgerWalletObligationHandler, handleResubmitButton)}
                rows={obligations}
                allowEmpty
                collapse
                expandableView={(id: string) => (
                    <PaymentExpandableView contractId={contractId} obligationId={id} />
                )}
            />
        </div>
    );
}

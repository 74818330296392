import React from "react";
import { Grid, CircularProgress, Button, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { includes, reduce, concat, get, isNil, omit } from "lodash";
import { withState, compose, lifecycle } from "recompose";
import BerbixVerify from "berbix-react";
import { Helmet } from "react-helmet";

import Steps from "../../components/Steps";
import Thanks from "../../components/Thanks";
import Bank from "../../components/Bank";
import Layout from "./components/Layout";
import Welcome from "./components/Welcome";
import Confirm from "./components/Confirm";
import Attach from "./components/Attach";
import Summary from "./components/Summary";
import Berbix from "./components/Berbix";
import InviteBank from "./components/InviteBank";
import colors from "../../theme/colors";
import { useHubspotTracking } from "@haulpay/hooks";
import segmentsEvents from "../../helpers/segmentsEvents";

const getSplitIndexName = (string, index) => {
  try {
    const stringArray = string.split(" ");
    return stringArray[index];
  } catch (e) {
    return "";
  }
};

const getInitialValuesConfirm = values => {
  const address = get(values, "address_street_one", "")
    ? `${get(values, "address_street_one", "")} ${get(
        values,
        "address_street_two",
        ""
      )}, ${get(values, "address_city", "")}
        ${get(values, "address_state", "")}
          ${get(values, "address_zip", "")} ${get(
        values,
        "address_country",
        ""
      )}`
    : "";
  return {
    referrer: "",
    company_name: get(values, "company_name", ""),
    owner_first_name: getSplitIndexName(get(values, "contact_name", ""), 0),
    owner_last_name: getSplitIndexName(get(values, "contact_name", ""), 1),
    email: get(values, "email", undefined),
    password: "",
    confirm_password: "",
    address: {
      label: address
    },
    owner_phone_number:
      get(
        values,
        "contact_phone_number",
        get(values, "phone_number", undefined)
      ) || "",
    dot: get(values, "dot", ""),
    mc: get(values, "mc", ""),
    terms: false
  };
};

const handleConfirmSubmit = (
  factoringProfileId,
  factoringId,
  accountAction,
  token,
  createFactoringProfileInvite,
  moveToNextStep,
  setErrorMsg,
  setTrackEvent,
  customToken
) => async fields => {
  // Values payload
  // {
  //   "contact_name": "string",
  //   "contact_phone_number": "string",
  //   "address_country": "string",
  //   "address_street_one": "string",
  //   "address_state": "string",
  //   "dot": "string",
  //   "about": "string",
  //   "contact_fax_number": "string",
  //   "url": "string",
  //   "contact_email": "user@example.com",
  //   "address_city": "string",
  //   "user_type": "carrier",
  //   "mc": "string",
  //   "company_name": "string",
  //   "address_zip": "string",
  //   "email": "user@example.com"
  // }
  const { address, ...values } = fields;
  const password = values.password !== "" ? { password: values.password } : {};
  const user = {
    contact_name: `${values.owner_first_name} ${values.owner_last_name}`,
    contact_phone_number: values.owner_phone_number,
    address_country: address.country,
    address_street_one: address.street_one,
    address_state: address.state,
    address_city: address.city,
    mc: values.mc,
    dot: values.dot,
    contact_email: values.email,
    user_type: "carrier",
    company_name: values.company_name,
    address_zip: address.zip,
    email: values.email,
    enable_sms_alerts: values.enable_sms_alerts,
    mailing_address: values.mailing_address,
    signup_url: window.location.href,
    referrer_url: document.referrer,
    ...password
  };
  try {
    await createFactoringProfileInvite(user, token);
    setTrackEvent && setTrackEvent('haulPay invited carrier sign-up', omit(user, ['password']));
    customToken ? window.analytics.track(segmentsEvents.HAULPAY_PAYMENT_PROFILE_INVITED_CARRIER_STATIC_LINK_BROKER_GROUP)
      : window.analytics.track(segmentsEvents.HAULPAY_PAYMENT_PROFILE_INVITED_CARRIER_STATIC_LINK_EMAIL, omit(user, ['password']));
    moveToNextStep();
  } catch (e) {
    setErrorMsg({
      display: true,
      message: get(e, "response.data", "")
    });
  }
};

const InvitedForm = ({
  loading,
  step,
  steps,
  accountAction,
  moveToNextStep,
  exchangePlaidToken,
  createFactoringProfileInvite,
  factoringLoading,
  email,
  factoringProfileSuccess,
  inviteError,
  match: {
    params: { token }
  },
  exchangePlaidLoading,
  exchangePlaid,
  exchangePlaidError,
  updateFactoringProfile,
  updateFactoringLoading,
  attachments,
  user,
  factoringProfile,
  invitePayload = {
    email
  },
  usedPlaid,
  attachmentError,
  setAttachmentError,
  errorMsg,
  setErrorMsg,
  berbixValues,
  SaveBerbixRequest,
  requestBerbixToken,
  isSmsInvite,
  updateUser,
  confirmInvite,
  ...props
}) => {
  const currentStepValue = step;
  const customToken = get(invitePayload, "custom_token", undefined);
  const groupId = get(invitePayload, "group_id", undefined);
  const factoringProfileId = customToken
    ? get(factoringProfileSuccess, "profiles.payment.id", undefined)
    : get(invitePayload, "profiles.payment.id", undefined);
  const userId = get(invitePayload, "profiles.payment.user", undefined);
  const welcomeContinue = () => {
    moveToNextStep();
  };
  const groupName = get(invitePayload, "group_name", undefined);
  const brokerFactoringId = get(invitePayload, "factoring_id", undefined);
  const rmisUrl = get(invitePayload, "rmis_url", undefined);
  const rmisText = get(invitePayload, "rmis_text", undefined);

  const showRmisLinkOnboarding = get(
    invitePayload,
    "show_rmis_link_onboarding",
    false
  );
  const showOnboardingHaulpayApp = get(
    invitePayload,
    "show_onboarding_haulpay_app",
    false
  );
  const showOnboardingComfreightApp = get(
    invitePayload,
    "show_onboarding_comfreight_app",
    false
  );
  const { setTrackEvent } = useHubspotTracking();
  const stepMap = {
    welcome: (
      <Welcome
        loading={loading}
        nextStep={welcomeContinue}
        disabled={loading || !!inviteError}
        inviteError={inviteError}
        groupName={groupName}
      />
    ),
    confirm: (
      <Confirm
        factoringLoading={factoringLoading || updateFactoringLoading}
        factoringProfileSuccess={factoringProfileSuccess}
        accountAction={accountAction}
        invitePayload={invitePayload}
        initialValues={getInitialValuesConfirm(invitePayload)}
        setPassword={accountAction === "new"}
        isSmsInvite={isSmsInvite}
        onSubmit={handleConfirmSubmit(
          factoringProfileId,
          factoringProfileId,
          accountAction,
          token,
          createFactoringProfileInvite,
          moveToNextStep,
          setErrorMsg,
          setTrackEvent,
          customToken
        )}
        token={token}
        errorMsg={errorMsg}
        clearError={() => setErrorMsg({ display: false, message: "" })}
        email={email}
        customToken={customToken}
        groupId={groupId}
        {...props}
      />
    ),
    bank: (
      <InviteBank
        moveToNextStep={() => {
          confirmInvite(token, true);
          moveToNextStep();
        }}
        exchangePlaidToken={exchangePlaidToken}
        exchangePlaidLoading={exchangePlaidLoading}
        exchangePlaidError={exchangePlaidError}
        attachments={attachments}
        setAttachmentError={setAttachmentError}
        attachmentError={attachmentError}
        factoringProfileId={factoringProfileId}
        usedPlaid={usedPlaid}
        brokerFactoringId={brokerFactoringId}
        customToken={customToken}
        confirmInvite={() => confirmInvite(token, true)}
      />
    ),
    attach: (
      <Berbix
        berbixValues={berbixValues}
        SaveBerbixRequest={SaveBerbixRequest}
        moveToNextStep={moveToNextStep}
        requestBerbixToken={requestBerbixToken}
      />
    ),
    thanks: (
      <Summary
        initialValues={getInitialValuesConfirm(
          customToken ? factoringProfileSuccess : invitePayload
        )}
        usedPlaid={usedPlaid}
        factoringProfileId={factoringProfileId}
        factoringProfileData={factoringProfileSuccess || factoringProfile}
        plaidData={exchangePlaid}
        updateFactoringProfile={updateFactoringProfile}
        updateFactoringLoading={updateFactoringLoading}
        attachments={attachments}
        onSubmit={async fields => {
          try {
            // await updateUser({
            //   userId,
            //   email: fields.email,
            //   referrer: fields.referrer
            // });
            moveToNextStep();
          } catch (e) {}
        }}
      />
    ),
    summary: (
      <Thanks
        message={
          <>
            <Typography
              component="p"
              style={{
                fontSize: "1.5em",
                lineHeight: 1.28,
                fontWeight: "lighter",
                width: "70%",
                marginBottom: 50
              }}
              align="center"
              gutterBottom
            >
              Thank you for boarding for payments via HaulPay.
            </Typography>
            {showRmisLinkOnboarding && (
              <>
                <Typography
                  component="p"
                  style={{
                    fontSize: "1.5em",
                    lineHeight: 1.28,
                    fontWeight: "lighter",
                    width: "50%",
                    marginBottom: 50,
                    paddingRight: 30,
                    paddingLeft: 30
                  }}
                  align="center"
                  gutterBottom
                >
                  Please click below to finish the broker's enrollment process if you haven't already.
                </Typography>
                <Button
                  style={{
                    backgroundColor: colors.green_light,
                    color: colors.white
                  }}
                  onClick={() =>
                    rmisUrl &&
                    window.open(
                      rmisUrl.match(/^http[s]?:\/\//)
                        ? rmisUrl
                        : `//${rmisUrl}`,
                      "_blank"
                    )
                  }
                  variant="contained"
                >
                  {rmisText || 'Complete Broker Agreement via RMIS'}
                </Button>
                <hr
                  style={{
                    borderTop: `1px solid ${colors.grayBg}`,
                    width: "100%",
                    marginTop: 40,
                    marginBottom: 40
                  }}
                />
              </>
            )}
          </>
        }
        isInvite
        showOnboardingHaulpayApp={showOnboardingHaulpayApp}
        showOnboardingComfreightApp={showOnboardingComfreightApp}
      />
    )
  };

  const showSteps =
    currentStepValue !== "welcome" && currentStepValue !== "thanks";

  return (
    <>
    <Helmet>
        <title>{`${groupName} | HaulPay`}</title>
        <meta name="robots" content="noindex"></meta>
    </Helmet>
    <Layout
      isInvited
      noTitle
      extraHeader={
        groupName && (
          <Grid item xs={12} justify="center" alignItems="center">
            <Typography variant="h6" align="center">
              {`For ${groupName}`}
            </Typography>
          </Grid>
        )
      }
    >
      {showSteps && (
        <Steps
          activeStep={currentStepValue}
          steps={steps.slice(1, steps.length - 1)}
        />
      )}
      {stepMap[currentStepValue]}
    </Layout>
    </>
  );
};

InvitedForm.defaultProps = {
  step: "welcome"
};

InvitedForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  factoringLoading: PropTypes.bool.isRequired,
  step: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  moveToNextStep: PropTypes.func.isRequired,
  exchangePlaidToken: PropTypes.func.isRequired,
  createFactoringProfileInvite: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  factoringError: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  inviteError: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  factoringProfileSuccess: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  exchangePlaidLoading: PropTypes.bool.isRequired,
  exchangePlaid: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  exchangePlaidError: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  updateFactoringProfile: PropTypes.func.isRequired,
  updateFactoringLoading: PropTypes.bool.isRequired,
  attachments: PropTypes.array.isRequired
};

export default compose(
  withState("attachmentError", "setAttachmentError", ""),
  withState("errorMsg", "setErrorMsg", { display: false, message: "" }),
  lifecycle({
    componentDidUpdate(PrevProps) {
      const { setErrorMsg, factoringError } = this.props;
      if (PrevProps.factoringError !== factoringError) {
        setErrorMsg({ display: !!factoringError, message: factoringError });
      }
    }
  })
)(InvitedForm);

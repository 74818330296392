import { createReducer } from "redux-starter-kit";
import { compose } from "recompose";
import { get } from "lodash";

import * as TYPES from "./types";
import fetchGroupsReducer from "./reducers/fetchGroups";
import fetchMembersReducer from "./reducers/fetchMembers";
import queryInvitedCarriersReducer from "./reducers/queryInvitedCarriers";
import queryConnectedDebtorsReducer from "./reducers/queryConnectedDebtors";
import withTable from "../withTable";
import fetchGroups, { next as nextFetchGroups } from "./actions/fetchGroups";
import queryFundingRequests from "./actions/queryFundingRequests";
import queryConnectedDebtors from "./actions/queryConnectedDebtors";
import fetchMembers, { next as nextFetchMembers } from "./actions/fetchMembers";
import queryInvitedCarriers, {
  next as nextQueryInvitedCarriers
} from "./actions/queryInvitedCarriers";
import queryFundingRequestsReducer from "./reducers/queryFundingRequests";
import queryFactoringCompaniesReducer from "./reducers/queryFactoringCompanies";
import queryFactoringCompanies from "./actions/queryFactoringCompanies";
import fetchConnectedGroupsReducer from "./reducers/fetchConnectedGroups";
import fetchPendingInviteReducer from "./reducers/fetchPendingInvite";

const groupsTable = withTable({
  stateKey: "groups.groups",
  typeKey: "groups/fetch_groups"
});

const membersTable = withTable({
  stateKey: "groups.members",
  typeKey: "groups/fetch_members"
});

const invitedCarriersTable = withTable({
  stateKey: "groups.invitedCarriers",
  typeKey: "groups/query_invited_carriers"
});

const factoringCompaniesTable = withTable({
  stateKey: "groups.factoringCompanies",
  typeKey: "groups/query_factoring_companies"
});

const connectedDebtorsTable = withTable({
  stateKey: "groups.connectedDebtors",
  typeKey: "groups/query_connected_debtors"
});

const fundingRequestsTable = withTable({
  stateKey: "groups.fundingRequests",
  typeKey: "groups/query_fundingrequests"
});

const reducer = createReducer(
  {},
  {
    [TYPES.FETCH_GROUPS_REQUEST]: fetchGroupsReducer,
    [TYPES.FETCH_GROUPS_SUCCESS]: fetchGroupsReducer,
    [TYPES.FETCH_GROUPS_FAILURE]: fetchGroupsReducer,

    [TYPES.FETCH_MEMBERS_REQUEST]: fetchMembersReducer,
    [TYPES.FETCH_MEMBERS_SUCCESS]: fetchMembersReducer,
    [TYPES.FETCH_MEMBERS_FAILURE]: fetchMembersReducer,

    [TYPES.QUERY_INVITED_CARRIERS_REQUEST]: queryInvitedCarriersReducer,
    [TYPES.QUERY_INVITED_CARRIERS_SUCCESS]: queryInvitedCarriersReducer,
    [TYPES.QUERY_INVITED_CARRIERS_FAILURE]: queryInvitedCarriersReducer,

    [TYPES.QUERY_CONNECTED_DEBTORS_REQUEST]: queryConnectedDebtorsReducer,
    [TYPES.QUERY_CONNECTED_DEBTORS_SUCCESS]: queryConnectedDebtorsReducer,
    [TYPES.QUERY_CONNECTED_DEBTORS_FAILURE]: queryConnectedDebtorsReducer,

    [TYPES.QUERY_FUNDINGREQUESTS_REQUEST]: queryFundingRequestsReducer,
    [TYPES.QUERY_FUNDINGREQUESTS_SUCCESS]: queryFundingRequestsReducer,
    [TYPES.QUERY_FUNDINGREQUESTS_FAILURE]: queryFundingRequestsReducer,

    [TYPES.QUERY_FACTORING_COMPANIES_REQUEST]: queryFactoringCompaniesReducer,
    [TYPES.QUERY_FACTORING_COMPANIES_SUCCESS]: queryFactoringCompaniesReducer,
    [TYPES.QUERY_FACTORING_COMPANIES_FAILURE]: queryFactoringCompaniesReducer,

    [TYPES.FETCH_CONNECTED_GROUPS_REQUEST]: fetchConnectedGroupsReducer,
    [TYPES.FETCH_CONNECTED_GROUPS_SUCCESS]: fetchConnectedGroupsReducer,
    [TYPES.FETCH_CONNECTED_GROUPS_FAILURE]: fetchConnectedGroupsReducer,

    [TYPES.FETCH_PENDING_INVITE_FAILURE]: fetchPendingInviteReducer,
    [TYPES.FETCH_PENDING_INVITE_REQUEST]: fetchPendingInviteReducer,
    [TYPES.FETCH_PENDING_INVITE_SUCCESS]: fetchPendingInviteReducer
  }
);

export default compose(
  groupsTable.reducer,
  membersTable.reducer,
  invitedCarriersTable.reducer,
  factoringCompaniesTable.reducer,
  connectedDebtorsTable.reducer,
  fundingRequestsTable.reducer
)(reducer);

export const middleware = [
  groupsTable.middleware(selectors => store => action => {
    const state = store.getState();
    const sortBy = selectors.getSortBy(state);
    const sortDirection = selectors.getSortDirection(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const filters = selectors.getFilters(state);
    const links = selectors.getLinks(state);
    const prevPage = selectors.getPrevPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;

    if (action.type.includes("UPDATE_PAGE")) {
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextFetchGroups(links.next, rowsPerPage, ordering, filters)
        );
      } else {
        store.dispatch(
          nextFetchGroups(links.previous, rowsPerPage, ordering, filters)
        );
      }
    } else {
      store.dispatch(
        fetchGroups(
          rowsPerPage,
          sortDirection === "asc" ? sortBy : `-${sortBy}`,
          filters
        )
      );
    }
  }),
  membersTable.middleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const sortDirection = selectors.getSortDirection(state);
    const sortBy = selectors.getSortBy(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const prevPage = selectors.getPrevPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
    const links = selectors.getLinks(state);
    const id = get(state, ["groups", "members", "id"]);

    if (action.type.includes("UPDATE_PAGE")) {
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextFetchMembers(id, links.next, rowsPerPage, ordering, filters)
        );
      } else {
        store.dispatch(
          nextFetchMembers(id, links.previous, rowsPerPage, ordering, filters)
        );
      }
    } else {
      store.dispatch(fetchMembers(id, rowsPerPage, ordering, filters));
    }
  }),
  factoringCompaniesTable.middleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const sortDirection = selectors.getSortDirection(state);
    const sortBy = selectors.getSortBy(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    // const prevPage = selectors.getPrevPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
    // const links = selectors.getLinks(state);

    // if (action.type.includes("UPDATE_PAGE")) {
    //   if (action.payload.page > prevPage) {
    //     store.dispatch(
    //       nextQueryInvitedCarriers(links.next, rowsPerPage, ordering, filters)
    //     );
    //   } else {
    //     store.dispatch(
    //       nextQueryInvitedCarriers(
    //         links.previous,
    //         rowsPerPage,
    //         ordering,
    //         filters
    //       )
    //     );
    //   }
    // } else {
    store.dispatch(queryFactoringCompanies(rowsPerPage, ordering, filters));
    // }
  }),
  invitedCarriersTable.middleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const sortDirection = selectors.getSortDirection(state);
    const sortBy = selectors.getSortBy(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const offset = selectors.getOffset(state);
    const prevPage = selectors.getPrevPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
    const links = selectors.getLinks(state);

    if (action.type.includes("UPDATE_PAGE")) {
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextQueryInvitedCarriers(
            links.next,
            rowsPerPage,
            offset,
            ordering,
            filters
          )
        );
      } else {
        store.dispatch(
          nextQueryInvitedCarriers(
            links.previous,
            rowsPerPage,
            offset,
            ordering,
            filters
          )
        );
      }
    } else {
      store.dispatch(
        queryInvitedCarriers(rowsPerPage, offset, ordering, filters)
      );
    }
  }),
  connectedDebtorsTable.middleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const sortDirection = selectors.getSortDirection(state);
    const sortBy = selectors.getSortBy(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    // const prevPage = selectors.getPrevPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
    // const links = selectors.getLinks(state);
    const id = get(state, ["groups", "connectedDebtors", "id"]);

    if (action.type.includes("UPDATE_PAGE")) {
      // if (action.payload.page > prevPage) {
      //   store.dispatch(
      //     nextFetchMembers(id, links.next, rowsPerPage, ordering, filters)
      //   );
      // } else {
      //   store.dispatch(
      //     nextFetchMembers(id, links.previous, rowsPerPage, ordering, filters)
      //   );
      // }
    } else {
      store.dispatch(queryConnectedDebtors(id, rowsPerPage, ordering, filters));
    }
  }),
  fundingRequestsTable.middleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const sortDirection = selectors.getSortDirection(state);
    const sortBy = selectors.getSortBy(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
    const offset = selectors.getOffset(state);

    const id = get(state, ["groups", "fundingRequests", "id"]);

    store.dispatch(
      queryFundingRequests(id, rowsPerPage, offset, ordering, filters)
    );
  })
];

export {
  default as fetchFactoringCompany
} from "./actions/fetchFactoringCompany";
export {
  default as getFactoringCompany
} from "./selectors/getFactoringCompany";
export const groupsTableSelectors = groupsTable.selectors;
export const groupsTableActions = groupsTable.actions;

export const membersTableSelectors = membersTable.selectors;
export const membersTableActions = membersTable.actions;

export const invitedCarriersTableSelectors = invitedCarriersTable.selectors;
export const invitedCarriersTableActions = invitedCarriersTable.actions;

export const factoringCompaniesTableSelectors =
  factoringCompaniesTable.selectors;
export const factoringCompaniesTableActions = factoringCompaniesTable.actions;

export const fundingRequestTableSelectors = fundingRequestsTable.selectors;
export const fundingRequestTableActions = fundingRequestsTable.actions;

export const connectedDebtorsSelectors = connectedDebtorsTable.selectors;
export const connectedDebtorsActions = connectedDebtorsTable.actions;

export { default as getGroups } from "./selectors/getGroups";
export { default as fetchGroups } from "./actions/fetchGroups";

export { default as getGroup } from "./selectors/getGroup";
export { default as fetchGroup } from "./actions/fetchGroup";
export { default as updateGroup } from "./actions/updateGroup";

export { default as getMembers } from "./selectors/getMembers";
export { default as fetchMembers } from "./actions/fetchMembers";

export { default as getFundingRequests } from "./selectors/getFundingRequests";
export {
  default as queryFundingRequests
} from "./actions/queryFundingRequests";

export {
  default as queryInvitedCarriers
} from "./actions/queryInvitedCarriers";
export { default as getInvitedCarriers } from "./selectors/getInvitedCarriers";

export { default as needsReviewCount } from "./actions/needsReviewCount";
export {
  default as needsReviewCountFactoringCompanies
} from "./actions/needsReviewCountFactoringCompanies";

export {
  default as queryConnectedDebtors
} from "./actions/queryConnectedDebtors";
export {
  default as getConnectedDebtors
} from "./selectors/getConnectedDebtors";

export {
  default as updatePaymentProfileRelationship
} from "./actions/updatePaymentProfileRelationship";

export {
  default as updateDebtorRelationship
} from "./actions/updateDebtorRelationship";

export {
  default as createFactoringCompany
} from "./actions/createFactoringCompany";
export { default as inviteMembers } from "./actions/inviteMembers";
export {
  default as queryFactoringCompanies
} from "./actions/queryFactoringCompanies";
export {
  default as getFactoringCompanies
} from "./selectors/getFactoringCompanies";
export {
  default as updateFactoringCompany
} from "./actions/updateFactoringCompany";
export {
  default as deleteFactoringCompany
} from "./actions/deleteFactoringCompany";

export {
  default as fetchConnectedGroups
} from "./actions/fetchConnectedGroups";

export { default as fetchPendingInvite } from "./actions/fetchPendingInvite";

export { default as getConnectedGroups } from "./selectors/getConnectedGroups";

export { default as getPendingInvite } from "./selectors/getPendingInvite";

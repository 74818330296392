import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";

import { get } from "lodash";
import {
  CREATE_PAYMENT_FORWARD_REQUEST,
  CREATE_PAYMENT_FORWARD_SUCCESS,
  CREATE_PAYMENT_FORWARD_FAILURE
} from "../types";
import { addEntities } from "../../entities";
import { ADD_FUNDING_REQUEST_RECEIVABLES_SUCCESS } from "../../finances/types";

const createPaymentForward = object => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(CREATE_PAYMENT_FORWARD_REQUEST)({}));
    const response = await api.factoring.createInvoiceDebtor(object);
    const schemaEntities =
      get(object, "filters.response_type") === "debtor_receivables"
        ? schema.fundingRequest
        : schema.receivable;
    const reduxEvent =
      get(object, "filters.response_type") === "debtor_receivables"
        ? CREATE_PAYMENT_FORWARD_SUCCESS
        : ADD_FUNDING_REQUEST_RECEIVABLES_SUCCESS;
    const data = normalize(response, schemaEntities);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(reduxEvent)({
        items: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(CREATE_PAYMENT_FORWARD_FAILURE)(err));
    throw err;
  }
};

export default createPaymentForward;

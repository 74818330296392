import {
  FETCH_LINEITEMCATEGORIES_REQUEST,
  FETCH_LINEITEMCATEGORIES_SUCCESS,
  FETCH_LINEITEMCATEGORIES_FAILURE
} from "../types";

const fetchLineItemCategories = (state, { type, payload }) => {
  switch (type) {
    case FETCH_LINEITEMCATEGORIES_REQUEST:
      return {
        ...state,
        loadingLineItemCategories: true
      };
    case FETCH_LINEITEMCATEGORIES_SUCCESS:
      return {
        ...state,
        lineItemCategories: payload,
        loadingLineItemCategories: false
      };
    case FETCH_LINEITEMCATEGORIES_FAILURE:
      return {
        ...state,
        loadingLineItemCategories: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchLineItemCategories;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSimilarPaymentProfile,
  getSimilarPaymentProfile
} from "../../../modules/payment";

const WithSimilarPaymentProfile = id => {
  const dispatch = useDispatch();
  const similarPaymentProfiles = useSelector(getSimilarPaymentProfile);
  useEffect(() => {
    async function fetchData() {
      await dispatch(fetchSimilarPaymentProfile(id));
    }
    fetchData();
  }, [id]);
  return [
    similarPaymentProfiles.count,
    similarPaymentProfiles.data,
    similarPaymentProfiles.loading,
    similarPaymentProfiles.next,
    similarPaymentProfiles.previous
  ];
};
export default WithSimilarPaymentProfile;

let instance;
class stripeInstance {
    constructor(stripeAccount) {
        this.stripe = Stripe(process.env.REACT_APP_STRIPE_KEY, { stripeAccount, apiVersion: process.env.REACT_APP_STRIPE_API_VERSION });
    }

    collectBankAccountForSetup(clientSecret, params, options) {
        return this.stripe.collectBankAccountForSetup({ clientSecret, params, ...options });
    }

    confirmUsBankAccountSetup(clientSecret, params) {
        return this.stripe.confirmUsBankAccountSetup(clientSecret, params);
    }

}
export default {
    getInstance(stripeAccount) {
        if (!instance) {
            instance = new stripeInstance(stripeAccount);
        }
        return instance;
    }
};
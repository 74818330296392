import { assign } from "lodash";
import {
  FETCH_LINEITEMS_FAILURE,
  FETCH_LINEITEMS_REQUEST,
  FETCH_LINEITEMS_SUCCESS
} from "../types";

const fetchLineItems = (state, { type, payload }) => {
  switch (type) {
    case FETCH_LINEITEMS_REQUEST:
      return {
        ...state,
        loadingLineItems: true
      };
    case FETCH_LINEITEMS_SUCCESS:
      return {
        ...state,
        lineItems: assign({}, state.lineItems, payload),
        loadingLineItems: false
      };
    case FETCH_LINEITEMS_FAILURE:
      return {
        ...state,
        loadingLineItems: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchLineItems;

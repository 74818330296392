import React, { useCallback } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Input,
  FormHelperText,
  Tooltip,
  Typography,
  MenuItem
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Form, Field } from "formik";
import { isUndefined, throttle, isEmpty, get } from "lodash";
import MultiButtonToggleInputs from "../../../components/inputs/MultiButtonToggleInputs";
import PhoneInput from "../../../components/inputs/PhoneInput";
import AutoCompleteAddress from "../../../components/inputs/AutoCompleteAddress";
import { getDataFromDOT, getDataFromMC } from "../../../api/fmcsa";
import formatPhone from "../../../helpers/format/formatPhone";
import { useDispatch, useSelector } from "react-redux";
import { getDialog, openDialog, closeDialog } from "../../../modules/ui";
import SMSAlertPolicyDialog from "./SMSAlertPolicyDialog";

const whereToFindUsOptions = [
  { label: "Organic Search/Google", value: "organic_search" },
  { label: "Google Ad", value: "google_ad" },
  { label: "Facebook Ad", value: "facebook_ad" },
  { label: "Instagram Ad", value: "instagram_ad" },
  { label: "Industry Publication/Press", value: "industry_publication_press" },
  { label: "Other", value: "other" }
];

export const assignValuesToFields = (
  data,
  setFieldValue,
  setFieldTouched,
  isSmsInvite
) => {
  setFieldValue(
    "company_name",
    get(data, "name", null) || get(data, "dba", null)
  );
  setFieldTouched("company_name", true);
  if (!isSmsInvite) {
    setFieldValue("owner_phone_number", formatPhone(get(data, "phone", null)));
    setFieldTouched("owner_phone_number", true);
  }
  const address = `${get(data, "street_one", "")} ${get(
    data,
    "street_two",
    ""
  )},
  ${get(data, "city", "")}
  ${get(data, "state", "")}
  ${get(data, "zip", "")}
  ${get(data, "country", null) || "USA"}`;
  const values = {
    street_one: get(data, "street_one", ""),
    city: get(data, "city", ""),
    state: get(data, "state", ""),
    zip: get(data, "zip", ""),
    country: get(data, "country", "USA")
  };
  setFieldValue("address", {
    label: address,
    ...values
  });
  setFieldTouched("address", true);
  setFieldValue(
    "mailing_address",
    {
      street_one: get(data, "mailing_address_street", ''),
      city: get(data, "mailing_address_city", ''),
      state: get(data, "mailing_address_state", ''),
      zip: get(data, "mailing_address_zip", '')
    }
  );
};

export const initFormValues = setFieldValue => {
  setFieldValue("company_name", "");
  setFieldValue("owner_phone_number", "");
  setFieldValue("address", "");
};

const CarrierInfo = ({
  values,
  touched,
  usedPlaid,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  isSummary,
  setPassword,
  isSmsInvite,
  email
}) => {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const openSmsPolicy = useCallback(
    args =>
      dispatch(
        openDialog(
          "read_sms_alert_policy",
        )
      ),
    []
  );
  const handleExit = useCallback(args => dispatch(closeDialog()), []);
  return (
    <Form>
      <Grid container direction="column" spacing={32}>
        <Grid item>
          <React.Fragment>
            <MultiButtonToggleInputs
              withField
              disabled={isSummary}
              names={["mc", "dot"]}
              placeholder={{ mc: "MC#", dot: "DOT#" }}
              selectedName="mc"
              label="MC / DOT"
              hasError={
                !!((errors.mc && touched.mc) || (errors.dot && touched.dot))
              }
              xs={12}
              sm={12}
              md={12}
              lg={12}
              inputSize={9}
              buttonSize={3}
              onBlur={async () => {
                !isSmsInvite && initFormValues(setFieldValue);
                if (values.mc) {
                  try {
                    const data = await throttle(getDataFromMC, 500, {
                      trailing: true
                    })(values.mc);
                    if (!isEmpty(data)) {
                      assignValuesToFields(
                        data,
                        setFieldValue,
                        setFieldTouched,
                        isSmsInvite
                      );
                    }
                  } catch (e) { }
                }
                if (values.dot) {
                  try {
                    const data = await throttle(getDataFromDOT, 500, {
                      trailing: true
                    })(values.dot);
                    if (!isEmpty(data)) {
                      assignValuesToFields(
                        data,
                        setFieldValue,
                        setFieldTouched,
                        isSmsInvite
                      );
                    }
                  } catch (e) { }
                }
              }}
            />
            {!!((errors.mc && touched.mc) || (errors.dot && touched.dot)) && (
              <FormHelperText error>
                {[errors.mc, errors.dot]
                  .filter(val => !isUndefined(val))
                  .join(", ")}
              </FormHelperText>
            )}
          </React.Fragment>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Field name="email">
            {({
              field: { value, ...field },
              form,
              hasError = !!(form.errors.email && form.touched.email)
            }) =>
              !isEmpty(email) ? (
                <Tooltip placement="left" title={value} aria-label="Email">
                  <TextField
                    label="Email"
                    placeholder="Email"
                    value={value}
                    fullWidth
                    disabled={!isEmpty(email)}
                    helperText={
                      hasError ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: form.errors.email }}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          component="span"
                          color="secondary"
                        >
                          Save this login info so you can complete future
                          boardings easily.
                        </Typography>
                      )
                    }
                    error={hasError}
                    name={field.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Tooltip>
              ) : (
                <TextField
                  label="Email"
                  placeholder="Email"
                  value={value}
                  fullWidth
                  disabled={!isEmpty(email)}
                  helperText={
                    hasError ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: form.errors.email }}
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        component="span"
                        color="secondary"
                      >
                        Save this login info so you can complete future boardings
                        easily.
                      </Typography>
                    )
                  }
                  error={hasError}
                  name={field.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )
            }
          </Field>
        </Grid>
        {setPassword && (
          <Grid
            container
            item
            spacing={8}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch"
            }}
          >
            <Grid item style={{ flex: 1 }}>
              <Field name="password">
                {({
                  field: { value, ...field },
                  form,
                  hasError = !!(form.errors.password && form.touched.password)
                }) => (
                  <div style={{ flex: 1, flexDirection: "column" }}>
                    <TextField
                      label="Password"
                      value={value}
                      placeholder="Password"
                      type="password"
                      fullWidth
                      helperText={hasError ? form.errors.password : null}
                      error={hasError}
                      name={field.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                )}
              </Field>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Field name="confirm_password">
                {({
                  field: { value, ...field },
                  form,
                  hasError = !!(
                    form.errors.confirm_password && form.touched.confirm_password
                  )
                }) => (
                  <div style={{ flex: 1, flexDirection: "column" }}>
                    <TextField
                      label="Confirm Password"
                      value={value}
                      placeholder="Password"
                      type="password"
                      fullWidth
                      helperText={hasError ? form.errors.confirm_password : null}
                      error={hasError}
                      name={field.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                )}
              </Field>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Field name="owner_first_name">
            {({
              field: { value, ...field },
              form,
              hasError = !!(
                form.errors.owner_first_name && form.touched.owner_first_name
              )
            }) => (
              <TextField
                label="Owner's First Name"
                placeholder="First Name"
                value={value}
                disabled={isSummary}
                fullWidth
                FormHelperTextProps={{
                  error: hasError
                }}
                helperText={hasError ? form.errors.owner_last_name : null}
                error={hasError}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Field>
        </Grid>
        <Grid item>
          <Field name="owner_last_name">
            {({
              field: { value, ...field },
              form,
              hasError = !!(
                form.errors.owner_last_name && form.touched.owner_last_name
              )
            }) => (
              <TextField
                label="Owner's Last Name"
                placeholder="Last Name"
                fullWidth
                disabled={isSummary}
                value={value}
                FormHelperTextProps={{
                  error: hasError
                }}
                helperText={hasError ? form.errors.owner_last_name : null}
                error={hasError}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Field>
        </Grid>
        <Grid item>
          <Field name="owner_phone_number">
            {({
              field: { value, ...field },
              form,
              hasError = !!(
                form.errors.owner_phone_number && form.touched.owner_phone_number
              )
            }) => (
              <TextField
                error={hasError}
                disabled={isSummary || isSmsInvite}
                label="Owner's Phone #"
                fullWidth
                value={value}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  inputComponent: PhoneInput
                }}
                helperText={hasError ? errors.owner_phone_number : null}
              />
            )}
          </Field>
        </Grid>
        <Grid item>
          <Field name="company_name">
            {({
              field: { value, ...field },
              form,
              hasError = !!(form.errors.company_name && form.touched.company_name)
            }) => (
              <TextField
                label="Company Name"
                placeholder="Company Name"
                fullWidth
                disabled={isSummary}
                value={value}
                FormHelperTextProps={{
                  error: hasError
                }}
                helperText={hasError ? form.errors.company_name : null}
                error={hasError}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Field>
        </Grid>
        <Grid item style={isSummary ? { display: "none" } : null}>
          <Field name="address">
            {({
              field,
              form,
              hasError = !!(errors?.address?.label && form.touched.address)
            }) => (
              <React.Fragment>
                <AutoCompleteAddress
                  name={field.name}
                  value={field.value}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  hasError={hasError}
                />
                {hasError && (
                  <FormHelperText error id="component-error-text">
                    {errors?.address?.label}
                  </FormHelperText>
                )}
              </React.Fragment>
            )}
          </Field>
        </Grid>
        <Grid
          item
          style={
            isSummary && usedPlaid ? { display: "flex" } : { display: "none" }
          }
        >
          <Field name="bank_info">
            {({
              field: { value, ...field },
              form,
              hasError = !!(form.errors.terms && form.touched.terms)
            }) => (
              <TextField
                label="Bank Info"
                placeholder="Adv checking Banking ****0000"
                fullWidth
                disabled
                value={value}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Field>
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems="flex-start"
            style={isSummary ? { display: "none" } : null}
          >
            <Field name="terms">
              {({
                field: { value, ...field },
                form,
                hasError = !!(form.errors.terms && form.touched.terms)
              }) => (
                <FormGroup>
                  <FormControlLabel
                    label={
                      <Typography>
                        I agree to{" "}
                        <a target="_blank" href="https://comfreight.com/tos">
                          the HaulPay Terms of Service
                        </a>
                      </Typography>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name={field.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                  />
                  {hasError && (
                    <FormHelperText error>{form.errors.terms}</FormHelperText>
                  )}
                </FormGroup>
              )}
            </Field>
          </Grid>
          <Grid
            container
            alignItems="flex-start"
            style={isSummary ? { display: "none" } : null}
          >
            <Field name="enable_sms_alerts">
              {({
                field: { value, ...field },
                form,
                hasError = !!(form.errors.enable_sms_alerts && form.touched.enable_sms_alerts)
              }) => (
                <FormGroup>
                  <FormControlLabel
                    label={
                      <Typography>
                        I would like to receive{" "}
                        <a href={void(0)} onClick={openSmsPolicy}>
                          important text messages regarding my operations
                        </a>
                      </Typography>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name={field.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                  />
                  {hasError && (
                    <FormHelperText error>{form.errors.enable_sms_alerts}</FormHelperText>
                  )}
                </FormGroup>
              )}
            </Field>
          </Grid>
        </Grid>
      </Grid>
      {dialog.variant === "read_sms_alert_policy" && (
        <SMSAlertPolicyDialog
          open={dialog.show}
          handleExit={handleExit}
        />
      )}
    </Form>
  )
};

CarrierInfo.defaultProps = {
  isSummary: false,
  usedPlaid: false
};

CarrierInfo.propTypes = {
  isSummary: PropTypes.bool,
  usedPlaid: PropTypes.bool
};

export default CarrierInfo;

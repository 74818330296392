import React from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, Paper, Tab, Tabs, Tooltip } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from "yup";
import { get, includes, find, compact } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';

import ProfileHeader from '../../../../components/ProfileHeader';
import InternalLayout from '../../../../components/layouts/InternalLayout';
import Can from '../../../../components/Can';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import WaitView from '../../../../components/WaitView';
import Link from '../../../../components/Link';
import splitAndCase from '../../../../helpers/format/splitAndCase';
import FMCSALink from '../../../../components/FMCSA';
import NavigationTabs from '../../../../components/NavigationTabs';
import ExportButton from '../../../../components/ExportButton';
import Notes from '../../../../components/Notes';
import AuditTrail from '../../../../components/AuditTrail';
import PlaidInfo from '../../../../components/PlaidInfo';
import SendBankLinkButton from '../../../../components/SendBankLinkButton';
import colors from '../../../../theme/colors';

import formatDate from '../../../../helpers/format/formatDate';
import formatPhone from '../../../../helpers/format/formatPhone';

import FactoringProfileDetailsCard from './components/cards/FactoringProfileDetailsCard';
import ClientStats from './components/ClientStats';
import BusinessInfoCard from './components/cards/BusinessInfoCard';
import OwnerInfoCard from './components/cards/OwnerInfoCard';
import ReportCard from './components/cards/ReportCard';
import ActiveReserveCard from './components/cards/ActiveReserveCard';
import PendingNegativeReserveCard from './components/cards/PendingNegativeReserveCard';
import ClientApprovalState from './components/cards/ClientApprovalStateCard';
import GeneralInfoCard from './components/cards/GeneralInfoCard';
import EditPaymentMethodDialog from './components/dialogs/EditPaymentMethodDialog';
import FundingRequestDialog from './components/dialogs/FundingRequestDialog';
import FundingRequestBrokerDialog from './components/dialogs/FundingRequestBrokerDialog';
import FundingRequestButton from './components/buttons/FundingRequestButton';
import EditPaymentButton from './components/buttons/EditPaymentButton';
import DeleteButton from './components/buttons/DeleteButton';
import SaveButton from './components/buttons/SaveButton';
import ChargeBackGiveBackHistory from './components/cards/ChargeBackGiveBackHistory';
import EditDefaultSettingsDialog from './components/dialogs/EditDefaultSettingsDialog';
import DefaultsButton from './components/buttons/DefaultsButton';
import SendNoaBlastButton from './components/buttons/SendNoaBlastButton';
import PaymentProfileButton from './components/buttons/PaymentProfileButton';
import PaymentProfileDialog from './components/dialogs/PaymentProfileDialog';
import PaymentProfileLinkDialog from './components/dialogs/PaymentProfileLinkDialog';
import CreateDepositDialog from './components/dialogs/CreateDepositDialog';

const fileCategories = (userType) =>
  userType === 'factoring_remote_admin'
    ? [
      {
        key: 'comfreight_noa',
        name: 'HaulPay NOA',
      },
      {
        key: 'transfer_document',
        name: 'Transfer Documents',
      },
    ]
    : [
      {
        key: 'driver_license',
        name: 'Driver License',
      },
      {
        key: 'insurance',
        name: 'Insurance',
      },
      {
        key: 'voided_check',
        name: 'Voided Check',
      },
      {
        key: 'comfreight_noa',
        name: 'ComFreight NOA',
      },
      {
        key: 'transfer_document',
        name: 'Transfer Documents',
      },
      {
        key: ' ',
        name: 'Other',
      },
    ];

const Profile = ({
  attachments,
  activeReserve,
  pendingNegativeReserve,
  tabs,
  factoringClient,
  user = {},
  factorClientForm,
  dialog,
  closeDialog,
  handleDeleteProfile,
  handleDeleteProfileConfirm,
  handleEditPayments,
  userId,
  notes,
  reserve,
  handleDeleteAttachmentConfirm,
  handleAttachmentDelete,
  noteForm,
  handleDeleteNote,
  handleCreateFundingRequest,
  createFundingRequestForm,
  id,
  auditLog,
  clientStats,
  collatedAttachments,
  handleSendTransferDocuments,
  fetchExport,
  isLoading,
  openSnackbar,
  updateFactoringNote,
  index,
  handleChange: handleTabChange,
  handleEditReceivableNotes,
  openEditLineItem,
  closeEditLineItem,
  updateLineItem,
  lineItemCategories,
  paymentProfileOptions,
  handlePaymentProfilesLink,
  fetchNotesTimelineNext,
  moreNotes,
  noaAttatchmentFile,
  disableAll,
  userType,
  updateFactoringProfile,
}) => {
  const group = find(user.groups, { roles: [{ role: 'broker' }] }) || {};
  const paymentProfileIDS = get(paymentProfileOptions, 'payment_profiles', []);
  const isBroker = user.type === 'broker';
  const isCarrier = user.type === 'carrier';
  return (
    <Can
      perform="admin-factorclients:view"
      yes={() => (
        <InternalLayout
          color={user.user_type === 'broker' ? '#FFE1C6' : undefined}
          title="Factor Client"
        >
          {isLoading ? (
            <WaitView />
          ) : (
            <Formik
              {...factorClientForm}
                validationSchema={() => Yup.lazy((values) =>
                  Yup.object().shape({
                    defaultFrTypeApi: values.defaultFrTypeApi ? Yup.mixed().oneOf(values.enabledContractTypes, 'Funding request type is not enabled to set as default')
                      : Yup.string().notRequired()
                }))}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
              }) => (
                <React.Fragment>
                  <React.Fragment>
                    <Grid container direction="column" spacing={24}>
                      <ProfileHeader
                        title={`Factor Client Profile - ${splitAndCase(
                          user.type
                        )}`}
                        icon={
                          isBroker ? (
                            <Tooltip title="Broker">
                              <FontAwesomeIcon
                                color={colors.green}
                                icon={faHandshake}
                              />
                            </Tooltip>
                          ) : undefined
                        }
                        links={compact([
                          <Can
                            perform="admin-actor_client-profile:userProfileLink"
                            yes={() => (
                              <Link to={`/admin/users/${userId}/profile`}>
                                User Profile
                              </Link>
                            )}
                          />,
                          <FMCSALink
                            mc={get(factoringClient, 'company_profile.mc', '')}
                            dot={get(
                              factoringClient,
                              'company_profile.dot',
                              ''
                            )}
                          />,
                          isBroker && (
                            <Can
                              perform="admin-actor_client-profile:groupLink"
                              yes={() => (
                                <Link
                                  to={`/admin/groups/${group.id}/ownership`}
                                >
                                  Group Page
                                </Link>
                              )}
                            />
                          ),
                          isCarrier && paymentProfileIDS.length > 0 && (
                            <Can
                              perform="admin-factor_client-profile:paymentProfileMatch"
                              yes={() => (
                                <a
                                  href={
                                    paymentProfileIDS.length === 1
                                      ? `/admin/payment/${paymentProfileIDS[0]}/profile`
                                      : '#'
                                  }
                                  onClick={
                                    paymentProfileIDS.length === 1
                                      ? () => ({})
                                      : handlePaymentProfilesLink
                                  }
                                >
                                  Payment Profile MC/DOT Match
                                </a>
                              )}
                            />
                          ),
                        ])}
                        actions={compact([
                          noaAttatchmentFile && (
                            <Can
                              perform="admin-factor_client-profile:BlastNOA"
                              yes={() => <SendNoaBlastButton />}
                            />
                          ),
                          <SendBankLinkButton
                            userId={userId}
                            source="factoring_client"
                          />,
                          isCarrier && !paymentProfileOptions.exists && (
                            <PaymentProfileButton />
                          ),
                          isBroker && (
                            <Can
                              perform="admin-factor_client-profile:BlastNOA"
                              yes={() => <DefaultsButton />}
                            />
                          ),
                          isBroker && (
                            <Can
                              perform="admin-factor_client-profile:BlastNOA"
                              yes={() => <CreateDepositDialog />}
                            />
                          ),
                          includes(
                            ['approved', 'dead'],
                            get(factoringClient, 'factoring_approved')
                          ) && (
                            <FundingRequestButton
                              onClick={handleCreateFundingRequest}
                            />
                          ),
                          <Can
                            perform="admin-factorclient-profile:edit-payment-methods"
                            yes={() => (
                              <EditPaymentButton onClick={handleEditPayments} />
                            )}
                          />,
                          <Can
                            perform="admin-factorclient-profile:delete"
                            yes={() => (
                              <DeleteButton onClick={handleDeleteProfile} />
                            )}
                          />,
                          <Can
                            perform="admin-factorclient-profile:save"
                            yes={() => (
                              <SaveButton
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                              />
                            )}
                          />,
                        ])}
                      />
                      <Grid item style={{ width: '100%' }}>
                        <NavigationTabs
                          tabs={tabs}
                          value={0}
                          additional={
                            <Can
                              perform="admin-factorclient-profile:reports"
                              yes={() => (
                                <ExportButton>
                                  <ReportCard
                                    factoringId={id}
                                    submit={fetchExport}
                                    openSnackbar={openSnackbar}
                                    isBroker={user.type === 'broker'}
                                  />
                                </ExportButton>
                              )}
                            />
                          }
                        />
                      </Grid>
                      <Grid item style={{ width: '100%' }}>
                        <Grid container spacing={16}>
                          <Grid item sm={4}>
                            <ClientApprovalState
                              values={values}
                              errors={errors}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              disabled={disableAll}
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <ActiveReserveCard activeReserve={activeReserve} />
                          </Grid>
                          <Grid item sm={4}>
                            <PendingNegativeReserveCard
                              pendingNegativeReserve={pendingNegativeReserve}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ width: '100%' }}>
                        <Grid
                          container
                          spacing={16}
                          style={{ width: '100%' }}
                          justify="space-evenly"
                        >
                          <Grid item xs>
                            <GeneralInfoCard
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              values={values}
                              handleChange={handleChange}
                              agreementNumber={factoringClient.agreement_number}
                              userType={user.type}
                              createdAt={formatDate(user.created)}
                              lastLogin={formatDate(user.last_login)}
                              source={user.source}
                              email={user.email}
                              contactName={user.contact_name}
                              phone={formatPhone(user.contact_phone)}
                              fax={user.fax_number}
                              language={user.language}
                              averageDaysToPay={user.avgpay}
                              creditApprovedEmblem={user.credit_approved}
                              cargoInsuranceValue={
                                user.cargo_insurance_provider
                              }
                              cargoInsuranceProvider={
                                user.cargo_insurance_value
                              }
                              disableAll={disableAll}
                            />
                          </Grid>
                          <Grid item xs>
                            <BusinessInfoCard
                              values={values}
                              errors={errors}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              files={attachments}
                              categories={fileCategories}
                              handleDelete={handleAttachmentDelete}
                              disableAll={disableAll}
                            />
                          </Grid>
                          <Can
                            perform="admin-factorclient-owner_info:view"
                            yes={() => (
                              <Grid item xs>
                                <OwnerInfoCard
                                  values={values}
                                  errors={errors}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                  disableAll={disableAll}
                                />
                              </Grid>
                            )}
                          />
                          <Grid item xs>
                            <FactoringProfileDetailsCard
                              userType={user.type}
                              values={values}
                              errors={errors}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                              files={attachments}
                              handleDelete={handleAttachmentDelete}
                              categories={fileCategories(userType)}
                              collatedAttachments={collatedAttachments}
                              handleSendTransferDocuments={
                                handleSendTransferDocuments
                              }
                              disableAll={disableAll}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Can
                        perform="admin-factorclient-tabs:view"
                        yes={() => (
                          <Grid item style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                              <Tabs
                                value={index}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                              >
                                <Tab label="Internal Client Notes" />
                                <Tab label="Audit Trail" />
                                <Tab label="Client Stats" />
                                <Tab label="Givebacks / Chargebacks" />
                                <Tab label="Plaid Info" />
                              </Tabs>
                              <div style={{ padding: 32, width: '100%' }}>
                                {index === 0 && (
                                  <Formik {...noteForm}>
                                    {({
                                      handleChange,
                                      handleSubmit,
                                      handleBlur,
                                      values,
                                      errors,
                                      touched,
                                      setFieldValue,
                                      setFieldTouched,
                                      status,
                                      setStatus,
                                      isSubmitting,
                                    }) => {
                                      return (
                                        <Notes
                                          values={values}
                                          errors={errors}
                                          touched={touched}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          setFieldValue={setFieldValue}
                                          setFieldTouched={setFieldTouched}
                                          handleSubmit={handleSubmit}
                                          isCreatingNote={isSubmitting}
                                          notes={notes}
                                          isFetching={false}
                                          notesAttachments={{}}
                                          isUpdatingNote={isSubmitting}
                                          handleDeleteNote={handleDeleteNote}
                                          status={status}
                                          setStatus={setStatus}
                                          hasMore={moreNotes}
                                          loadMore={() =>
                                            fetchNotesTimelineNext()
                                          }
                                        />
                                      );
                                    }}
                                  </Formik>
                                )}
                                {index === 1 && (
                                  <AuditTrail
                                    auditLog={auditLog}
                                    prefix="factoring_"
                                  />
                                )}
                                {index === 2 && (
                                  <ClientStats
                                    data={get(clientStats, 'factoring_stats')}
                                  />
                                )}
                                {index === 3 && (
                                  <ChargeBackGiveBackHistory
                                    dialog={dialog}
                                    handleEditReceivableNotes={
                                      handleEditReceivableNotes
                                    }
                                    reserve={reserve}
                                    openEditLineItem={openEditLineItem}
                                    closeEditLineItem={closeEditLineItem}
                                    updateLineItem={updateLineItem}
                                    openSnackbar={openSnackbar}
                                    lineItemCategories={lineItemCategories}
                                  />
                                )}
                                {index === 4 && <PlaidInfo userId={userId} />}
                              </div>
                            </Paper>
                          </Grid>
                        )}
                      />
                    </Grid>
                  </React.Fragment>
                  {dialog.variant === 'confirm' && (
                    <ConfirmDialog
                      open={dialog.show}
                      message={dialog.message}
                      handleConfirm={handleDeleteProfileConfirm}
                      handleExit={() => closeDialog()}
                    />
                  )}
                  {dialog.variant === 'delete-factor-client-attachment' && (
                    <ConfirmDialog
                      open={dialog.show}
                      message={dialog.message}
                      handleConfirm={handleDeleteAttachmentConfirm}
                      handleExit={() => closeDialog()}
                    />
                  )}
                  {dialog.variant === 'edit-payment' && (
                    <EditPaymentMethodDialog userId={userId} user={user} updateProfile={updateFactoringProfile} />
                  )}
                  {dialog.variant === 'create-payment-profile' && (
                    <PaymentProfileDialog user={id} />
                  )}
                  {dialog.variant === 'payment-profile-link-dialog' && (
                    <PaymentProfileLinkDialog
                      ids={paymentProfileOptions.payment_profiles}
                    />
                  )}
                  {dialog.variant ===
                    'factor-client-create-funding-request' && (
                      <React.Fragment>
                        {user.user_type === 'broker' ? (
                          <FundingRequestBrokerDialog factoringId={id} />
                        ) : (
                          <FundingRequestDialog
                            open={dialog.show}
                            handleExit={() => closeDialog()}
                            form={createFundingRequestForm}
                            factoringId={id}
                          />
                        )}
                      </React.Fragment>
                    )}
                  <EditDefaultSettingsDialog factoringId={id} />
                </React.Fragment>
              )}
            </Formik>
          )}
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
};

export default Profile;

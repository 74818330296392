import { Action, createAction } from "redux-starter-kit";
import { ThunkAction } from "redux-thunk";
import { ThunkContext } from "../../types";

function forgetPassword(
  email: string
): ThunkAction<Promise<void>, any, ThunkContext, Action<any>> {
  return async (dispatch, getState, { api }): Promise<void> => {
    try {
      return await api.user.resetPassword(email);
    } catch (err) {
      throw err;
    }
  };
}

export default forgetPassword;

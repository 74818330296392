import React from 'react';
import { Link } from "react-router-dom";
import formatDate from "../../../../../../helpers/format/formatDate";
import formatDollars from '../../../../../../helpers/format/formatDollars';

const columns = [
    {
        key: "created",
        name: "Date",
        format: formatDate
    },
    {
        key: "description",
        name: "Description",
    },
    {
        key: ["transaction_category", "invoice_number", "funding_request_id"],
        name: "Invoice",
        format: (transaction_category: string, invoice_number: string, funding_request_id: string): JSX.Element => 
            <div>
            {transaction_category === 'STRIPE_WALLET_DEPOSIT' ? 
                invoice_number 
                    : <Link to={`/admin/fundingrequest/${funding_request_id}`} key={funding_request_id}> 
                {invoice_number} 
                </Link>
            }
            </div>
    },
    {
        key: "credit_amount",
        name: "credit",
        format: formatDollars,
    },
    {
        key: "debit_amount",
        name: "Debit",
        format: formatDollars,
    },
    {
        key: "wallet_balance",
        name: "Balance",
        format: formatDollars,
    },
];

export default columns;

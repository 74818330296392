import { createAction } from "redux-starter-kit";
import {
  GET_COLLATED_PDF_FAILURE,
  GET_COLLATED_PDF_REQUEST,
  GET_COLLATED_PDF_SUCCESS
} from "../types";

const getCollatedPdf = ({ token = null }) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(GET_COLLATED_PDF_REQUEST)());
    const response = await api.factoring.getCollatedPdf({ token });
    dispatch(createAction(GET_COLLATED_PDF_SUCCESS)(response));
    return response;
  } catch (err) {
    dispatch(createAction(GET_COLLATED_PDF_FAILURE)(err));
    throw err;
  }
};

export default getCollatedPdf;

interface TabOption {
  label: string;
  route: string;
}

function tabs(id: string): TabOption[] {
  return [
    {
      label: "Ownership",
      route: `/admin/groups/${id}/ownership`
    },
    {
      label: "Members",
      route: `/admin/groups/${id}/members`
    },
    {
      label: "Funding Requests",
      route: `/admin/groups/${id}/funding_requests`
    },
    {
      label: "Connected Debtors",
      route: `/admin/groups/${id}/connected_debtor`
    }
  ];
}

export default tabs;

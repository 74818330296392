import React, { useCallback, useState } from "react";
import { IconButton, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpenText,
  faEnvelope,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import head from "lodash/head";

import { openSnackbar } from "../../../../modules/ui";
import API from "../../../../api";
import colors from "../../../../theme/colors";

const SendReminder = ({ id, status }) => {
  const dispatch = useDispatch();
  const [successIcon, setSuccessIcon] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async args => {
    try {
      setLoading(true);
      await API.factoring.sendDocumentIssueReminder(args);
      dispatch(openSnackbar("success", "Reminder has been sent"));
      setSuccessIcon(true);
    } catch (err) {
      dispatch(openSnackbar("error", head(err)));
    }
    setLoading(false);
  }, []);
  if (status !== "document_issue") {
    return <div />
  }
  if (loading) {
    return (
      <IconButton>
        <FontAwesomeIcon
          icon={faSpinner}
          size="sm"
          spin
          color={colors.green}
          style={{ marginLeft: 5 }}
        />
      </IconButton>
    );
  }
  return (
    <Typography>
      <IconButton id={id} onClick={() => onClick(id)}>
        {successIcon ? (
          <FontAwesomeIcon color={colors.green} icon={faEnvelope} />
        ) : (
          <FontAwesomeIcon color={colors.danger} icon={faEnvelopeOpenText} />
        )}
      </IconButton>
        Reminder Email
    </Typography>
  );
};

export default SendReminder;

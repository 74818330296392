import React, { useCallback, useEffect } from "react";
import { withStateHandlers, compose } from "recompose";
import { useDispatch, useSelector } from "react-redux";
import { head } from "lodash";

import SelectMethod from "./SelectMethod";
import DebitCredit from "./DebitCredit";
import ACH from "./ACH";
import Wire from "./Wire";
import {
  getAccounts,
  deleteAccount,
  fetchAccounts,
  createWireAccount,
  createCardAccount,
  createCheckAccount,
  createSwiftAccount,
  defaultBankAccount,
  createBankAccount,
  updateFactoringProfile
} from "../../../../../../../modules/factoringClient";
import {
  closeDialog,
  getDialog,
  openSnackbar
} from "../../../../../../../modules/ui";
import CHECK from "./CHECK";
import SWIFT from "./SWIFT";

const redact = str => {
  if (str.length < 4) {
    return str;
  }
  return new Array(str.length - 3)
    .join("x")
    .concat(str.substring(str.length - 4));
};

const EditPaymentMethodDialog = ({
  user,
  userId,
  methodSelected,
  selectDebitCredit,
  selectAch,
  selectWire,
  selectCheck,
  selectSwift,
  selectNone,
  updateProfile,
  selectDebit,
}) => {
  const accounts = useSelector(getAccounts(userId));
  const dialog = useSelector(getDialog);
  const dispatch = useDispatch();
  const handleExit = useCallback(() => dispatch(closeDialog()), [dispatch]);
  const handleDeleteAccount = async (userId, account) => {
    try {
      await dispatch(deleteAccount(userId, account));
      await dispatch(fetchAccounts(userId));
      dispatch(openSnackbar("success", "Deleted Payment Method!"));
    } catch (err) {
      dispatch(openSnackbar("error", head(err)));
    }
  };
  useEffect(() => {
    const f = async () => {
      if (userId) {
        dispatch(fetchAccounts(userId));
      }
    };
    f();
  }, [userId]);
  const handleCreateCardAccount = async (cardNumber, expiration, zipcode) => {
    try {
      const redacted = redact(cardNumber);

      await dispatch(
        createCardAccount(
          redacted,
          cardNumber,
          expiration,
          "DEBIT",
          "DEBIT",
          userId,
          zipcode
        )
      );
      dispatch(openSnackbar("success", "Created Payment Method!"));
    } catch (err) {
      dispatch(openSnackbar("error", head(err)));
    }
  };
  const handleCreateBankAccount = async (
    accountType,
    accountNumber,
    routingNumber,
    isThisABusinessAccount
  ) => {
    try {
      const redacted = redact(accountNumber);

      await dispatch(
        createBankAccount(
          accountNumber,
          redacted,
          isThisABusinessAccount,
          routingNumber,
          accountType,
          userId
        )
      );
      dispatch(openSnackbar("success", "Created Payment Method!"));
    } catch (err) {
      dispatch(openSnackbar("error", head(err)));
    }
  };
  const handleCreateDebitBankAccount = async (
    accountType,
    accountNumber,
    routingNumber,
    isThisABusinessAccount
  ) => {
    try {
      const redacted = redact(accountNumber);

      await dispatch(
        createBankAccount(
          accountNumber,
          redacted,
          isThisABusinessAccount,
          routingNumber,
          accountType,
          userId,
          "DEBIT"
        )
      );
      dispatch(openSnackbar("success", "Created Payment Method!"));
    } catch (err) {
      dispatch(openSnackbar("error", head(err)));
    }
  };
  const handleCreateWireAccount = async (
    nickName,
    accountType,
    accountNumber,
    routingNumber
  ) => {
    try {
      const redacted = redact(accountNumber);

      await dispatch(
        createWireAccount(
          nickName,
          accountNumber,
          redacted,
          "WIRE",
          routingNumber,
          accountType,
          userId
        )
      );
      dispatch(openSnackbar("success", "Created Payment Method!"));
    } catch (err) {
      dispatch(openSnackbar("error", head(err)));
    }
  };

  const handleCreateCheckAccount = async (
    mailingAddress,
    name,
  ) => {
    try {
      await dispatch(
        createCheckAccount(
          mailingAddress,
          userId, 
          name,
        )
      );
      dispatch(openSnackbar("success", "Created Payment Method!"));
    } catch (err) {
      dispatch(openSnackbar("error", head(err)));
    }
  };

  const handleCreateSwiftAccount = async (swiftCode) => {
  try {
    await dispatch(
      createSwiftAccount(
        swiftCode,
        userId
      )
    );
    dispatch(openSnackbar("success", "Created Payment Method!"));
  } catch (err) {
    dispatch(openSnackbar("error", head(err)));
  }
}

  const handleDefaultBankAccount = async (values, data) => {
    try {
      if (values.debit_account_id) {
        await dispatch(defaultBankAccount(userId, values.debit_account_id, data));
      }
      if (values.ach_account_id) {
        await dispatch(defaultBankAccount(userId, values.ach_account_id, data));
      }
      if (values.wire_account_id) {
        await dispatch(defaultBankAccount(userId, values.wire_account_id, data));
      }
      if (values.factoring_company_account_id) {
        await dispatch(
          defaultBankAccount(userId, values.factoring_company_account_id, data)
        );
      }
      dispatch(openSnackbar("success", "Payment Methods set to default!"));
    } catch (err) {
      dispatch(openSnackbar("error", head(err)));
    }
  };

  const handleUpdateFactoringMailingAdress = async mailingAddress => updateProfile(mailingAddress);

  if (methodSelected === "debit/credit") {
    return (
      <DebitCredit
        open={dialog.show}
        selectNone={selectNone}
        handleExit={handleExit}
        createAccount={handleCreateCardAccount}
      />
    );
  }
  if (methodSelected === "ach") {
    return (
      <ACH
        open={dialog.show}
        selectNone={selectNone}
        handleExit={handleExit}
        createAccount={handleCreateBankAccount}
      />
    );
  }
  if (methodSelected === "wire") {
    return (
      <Wire
        open={dialog.show}
        selectNone={selectNone}
        handleExit={handleExit}
        createAccount={handleCreateWireAccount}
      />
    );
  }
  if (methodSelected === "check") {
    return (
      <CHECK
        open={dialog.show}
        selectNone={selectNone}
        handleExit={handleExit}
        createAccount={handleCreateCheckAccount}
        address={user.mailing_address}
        handleUpdateFactoringMailingAdress={handleUpdateFactoringMailingAdress}
      />
    );
  }
  if (methodSelected === "swift") {
    return (
      <SWIFT
        open={dialog.show}
        selectNone={selectNone}
        handleExit={handleExit}
        createAccount={handleCreateSwiftAccount}
      />
    );
  }
  if(methodSelected === "debit") {
    return (
      <ACH
        open={dialog.show}
        selectNone={selectNone}
        handleExit={handleExit}
        createAccount={handleCreateDebitBankAccount}
        title="Self-Finance Debit Account"
      />
    );
  }
  return (
    <SelectMethod
      open={dialog.show}
      handleExit={handleExit}
      selectDebitCredit={selectDebitCredit}
      selectAch={selectAch}
      selectWire={selectWire}
      selectCheck={selectCheck}
      selectSwift={selectSwift}
      selectDebit={selectDebit}
      accounts={accounts}
      handleDefaultBankAccount={handleDefaultBankAccount}
      handleDeleteAccount={handleDeleteAccount}
      user={user}
    />
  );
};

export default compose(
  withStateHandlers(
    ({ methodSelected }) => ({
      methodSelected: methodSelected || "none"
    }),
    {
      selectDebitCredit: () => () => ({ methodSelected: "debit/credit" }),
      selectAch: () => () => ({ methodSelected: "ach" }),
      selectWire: () => () => ({ methodSelected: "wire" }),
      selectCheck: () => () => ({ methodSelected: "check" }),
      selectSwift: () => () => ({ methodSelected: "swift" }),
      selectDebit: () => () => ({ methodSelected: "debit" }),
      selectNone: () => () => ({ methodSelected: "none" })
    }
  )
)(EditPaymentMethodDialog);

import { findIndex } from "lodash";

const moveToStep = (state, { type, payload }) => {
  const steps = state.steps.map(step => step.value);
  const next = steps[findIndex(steps, step => step === payload)];
  return {
    ...state,
    step: next
  };
};

export default moveToStep;

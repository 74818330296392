import splitAndCase from "../../../../helpers/format/splitAndCase";
import formatPennies from "../../../../helpers/format/formatPennies";

const columns = [
  {
    key: "company_name",
    name: "Company",
    filter: "text",
    filter_key: "company_name",
    primary: true,
    sort: "company_name"
  },
  {
    key: "email",
    name: "Email",
    format: (value: string): string => value.toLowerCase().replace(",", "\n"),
    filter: "text",
    filter_key: "email",
    sort: true
  },
  {
    key: "user.user_type",
    name: "User Type",
    default: "-",
    format: splitAndCase,
    filter_key: "user_type",
    filter: "dropdown",
    filter_values: [
      { label: "All", value: "" },
      { label: "Debtor", value: "debtor" },
      { label: "Broker", value: "broker" },
      { label: "Carrier", value: "carrier" }
    ]
  },
  {
    key: "outstanding_balance",
    name: "Outstanding Balance",
    format: formatPennies,
    sort: true
  },
  {
    key: "discount_rate",
    name: "Discount Rate",
    default: "-"
  },
  {
    key: "phone_number",
    name: "Phone",
    default: "-",
    filter: "text",
    filter_key: "phone_number"
  },
  {
    key: "mc",
    default: "-",
    name: "MC#",
    filter: "text",
    filter_key: "mc"
  },
  {
    key: "dot",
    default: "-",
    name: "DOT#",
    filter: "text",
    filter_key: "dot"
  },
  {
    key: "tax_id",
    default: "-",
    name: "Tax ID",
    filter: "text",
    filter_key: "tax_id"
  },
  {
    key: "more",
    name: "More"
  }
];

export default columns;

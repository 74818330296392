import React from "react";
import { Button, List, ListItem } from "@material-ui/core";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { head } from "lodash";

import BoldInput from "../../../../../components/inputs/BoldInput";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../components/Dialog";

import {
  closeDialog,
  getDialog,
  openSnackbar
} from "../../../../../modules/ui";
import { createFactoringCompany } from "../../../../../modules/groups";

export default () => {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);

  return (
    <Dialog
      open={dialog.show && dialog.action === "add-factor-company"}
      maxWidth="sm"
      fullWidth
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: "",
          email: "",
          contact_name: "",
          phone_number: "",
          street_one: "",
          street_two: "",
          state: "",
          zip_code: ""
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await dispatch(createFactoringCompany(values));
            dispatch(openSnackbar("success", "Created a new Factor Company!"));
            dispatch(closeDialog());
          } catch (e) {
            dispatch(openSnackbar("error", head(e)));
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values }) => (
          <React.Fragment>
            <DialogTitle
              onClose={() => {
                dispatch(closeDialog());
              }}
            >
              Add Factor Company
            </DialogTitle>
            <DialogContent>
              <List>
                <ListItem>
                  <BoldInput
                    name="name"
                    label="DBA"
                    value={values.name}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItem>
                <ListItem>
                  <BoldInput
                    name="contact_name"
                    label="Contact Name"
                    value={values.contact_name}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItem>
                <ListItem>
                  <BoldInput
                    name="email"
                    label="Contact Email"
                    value={values.email}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItem>
                <ListItem>
                  <BoldInput
                    name="street_one"
                    label="Street One"
                    value={values.street_one}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItem>
                <ListItem>
                  <BoldInput
                    name="street_two"
                    label="Street Two"
                    value={values.street_two}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItem>
                <ListItem>
                  <BoldInput
                    name="state"
                    label="State"
                    value={values.state}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItem>
                <ListItem>
                  <BoldInput
                    name="zip_code"
                    label="Zip Code"
                    value={values.zip_code}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItem>
                <ListItem>
                  <BoldInput
                    name="phone_number"
                    label="Contact Phone"
                    value={values.phone}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItem>
              </List>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => handleSubmit()}>
                Submit
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Formik>
    </Dialog>
  );
};

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import awsImageUpload from "../../../helpers/awsImageUpload";
import {
  UPLOAD_ATTACHMENTS_FAILURE,
  UPLOAD_ATTACHMENTS_REQUEST,
  UPLOAD_ATTACHMENTS_SUCCESS
} from "../types";
import { addEntities } from "../../entities";
import fetchAttachments from "./fetchAttachments";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const uploadAttachments = (id, attachments) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPLOAD_ATTACHMENTS_REQUEST)({}));
    const responses = await Promise.all(
      attachments.map(async ({ file: { name }, category }) =>
        api.factoring.addAttachementToProfile({
          id,
          filename: name,
          category
        })
      )
    );
    const data = responses.map(r => r.data);

    const all = data.map((d, i) => ({
      file: attachments[i].file,
      upload_url: d.upload_url
    }));

    if (all.length !== 0) {
      awsImageUpload(all).then(async () => {
        await sleep(5000); // Wait for API to catch up
        await dispatch(fetchAttachments(id));
      });
    }

    const normalized = normalize(data, [schema.attachment]);
    dispatch(addEntities(normalized.entities));
    dispatch(
      createAction(UPLOAD_ATTACHMENTS_SUCCESS)({ [id]: normalized.result })
    );
  } catch (err) {
    dispatch(createAction(UPLOAD_ATTACHMENTS_FAILURE)(err));
    throw err;
  }
};

export default uploadAttachments;

import {
  FETCH_BERBIX_TOKEN_ERROR,
  FETCH_BERBIX_TOKEN_REQUEST,
  FETCH_BERBIX_TOKEN_SUCCESS
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_BERBIX_TOKEN_REQUEST:
      return { ...state, ...action.payload };
    case FETCH_BERBIX_TOKEN_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_BERBIX_TOKEN_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

import { get } from "lodash";
import awsImageUpload from "../../../helpers/awsImageUpload";

const brokerCreateFR = (load_id, funding_request, factoring) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  // const load = await api.factoring.postBrokerLoad(load_data);
  const fr = await api.factoring.postBrokerFR(
    {
      ...funding_request,
      load_id,
      factoring
    },
    funding_request.non_factored
  );

  const files = await Promise.all(
    get(fr, ["attachments"], []).map(async (attachment, i) => {
      const base64 = get(funding_request, "attachments[i].file.base64", funding_request.attachments[i].base64);
      const blob = await fetch(base64).then(
        res => res.blob()
      );
      return {
        ...attachment,
        file: blob
      };
    })
  );

  await awsImageUpload(files);
};

export default brokerCreateFR;

import { Button, Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import colors from "../../../../theme/colors";
import HaulPayLogo from "../../../../components/HaulPayLogo";
import { logout } from "../../../../modules/auth";

export default function() {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = useCallback(() => {
    history.push("/login");
    dispatch(logout());
  }, [dispatch, history]);
  return (
    <div className="ic_container_header">
      <div>
        <HaulPayLogo
          style={{
            width: "100%"
          }}
        />
      </div>
      <div>
        <Button
          style={{ color: colors.secondary, fontSize: 20 }}
          onClick={handleLogout}
        >
          Log Out
        </Button>
      </div>
    </div>
  );
}

import {
  UPDATE_USER_NOTE_FAILURE,
  UPDATE_USER_NOTE_REQUEST,
  UPDATE_USER_NOTE_SUCCESS
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case UPDATE_USER_NOTE_REQUEST:
      return {
        ...state,
        loadingUserNotes: true
      };
    case UPDATE_USER_NOTE_SUCCESS:
      return {
        ...state,
        loadingUserNotes: false
      };
    case UPDATE_USER_NOTE_FAILURE:
      return {
        ...state,
        loadingUserNotes: false
      };
    default:
      return state;
  }
};

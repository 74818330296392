const fetchCommodities = () => async (_, __, { api }) => {
  const response = await api.factoring.getCommodities();

  const keys = Object.keys(response);
  const values = Object.values(response);

  let commodities = [];

  for (let i = 0; i < keys.length; i++) {
    commodities.push({
      ...values[i],
      id: keys[i]
    });
  }
  return commodities;
};

export default fetchCommodities;

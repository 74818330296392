import { denormalize } from "normalizr";
import { get } from "lodash";

import { fundingRequest } from "../../../api/schema";

const getOpenFundingRequests = state => {
  try {
    const items = get(state, "debtor.payments.ids", []);

    const denormalized = denormalize(
      { items },
      { items: [fundingRequest] },
      state.entities
    );

    return denormalized.items;
  } catch (err) {
    return [];
  }
};

export default getOpenFundingRequests;

import React from "react";
import { CreditCard } from "@material-ui/icons";

import formatPennies from "../../../../../helpers/format/formatPennies";
import AvatarCard from "../../../../../components/AvatarCard";

interface Props {
  available: number;
}

const AvailableCreditCard = ({ available }: Props): JSX.Element => (
  <AvatarCard
    title="Available Credit"
    color="#4a90e2"
    icon={<CreditCard style={{ fontSize: "32px" }} />}
    subheader={formatPennies(available)}
  />
);

export default AvailableCreditCard;

import { Button, Grid, MenuItem } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions
} from "../../../../../../../components/Dialog";
import CustomTextField from "../../../../../../../components/inputs/CustomTextField";
import Yup from "../../../../../../../YupValidation";

const validatorSchema = () => {
  const schema = {
    routingNumber: Yup.string().required("Please fill out this field").routingNumberIsValid(),
  }
  return Yup.object().shape(schema);
}

const Wire = ({ open, selectNone, handleExit, createAccount }) => (
  <Dialog open={open} maxWidth="sm" fullWidth>
    <Formik
      initialValues={{
        nickName: "",
        accountType: "",
        accountNumber: "",
        routingNumber: ""
      }}
      validationSchema={validatorSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await createAccount(
            values.nickName,
            values.accountType,
            values.accountNumber,
            values.routingNumber
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, values, handleChange, handleBlur, errors }) => (
        <React.Fragment>
          <DialogTitle onClose={handleExit}>Add Wire</DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <CustomTextField
                  fullWidth
                  name="nickName"
                  label="Bank Nick Name"
                  value={values.nickName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.nickName}
                  error={!!errors.nickName}
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  fullWidth
                  select
                  id="accountType"
                  name="accountType"
                  label="Account Type"
                  value={values.accountType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="BUSINESS">Business</MenuItem>
                  <MenuItem value="CHECKING">Checking</MenuItem>
                  <MenuItem value="SAVINGS">Savings</MenuItem>
                </CustomTextField>
              </Grid>
              <Grid item>
                <CustomTextField
                  fullWidth
                  name="accountNumber"
                  label="Account Number"
                  value={values.accountNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.accountNumber}
                  error={!!errors.accountNumber}
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  fullWidth
                  name="routingNumber"
                  label="Routing Number"
                  value={values.routingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.routingNumber}
                  error={!!errors.routingNumber}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => selectNone()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Add Wire
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Formik>
  </Dialog>
);

export default Wire;

import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, withProps, withState } from "recompose";
import { findIndex, head, merge } from "lodash";
import { DateTime } from "luxon";

import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../modules/ui";
import withSelect from "../../../../components/withSelect";
import {
  createDebtorPayment,
  createFollowupNote,
  createFundingRequestCSV,
  createLineItem,
  fetchLineItemCategories,
  fetchLineItems,
  fetchReceivableNotes,
  getDebtorCompanyName,
  getDebtorMaster,
  getIsLoading,
  getLineItemCategories,
  getLineItemsByFundingRequest,
  getOpenFundingRequests,
  getPaymentsCount,
  getPaymentsFilters,
  getPaymentsPage,
  getPaymentsRowsPerPage,
  getPaymentsRowsPerPageOptions,
  getPaymentsSortBy,
  getPaymentsSortDirection,
  getSelectedFundingRequests,
  handlePaymentsChangePage,
  handlePaymentsChangeRowsPerPage,
  handlePaymentsSearchFilter,
  handlePaymentsSort,
  queryDebtor,
  queryOpenFundingRequests,
  uploadFundingRequestCSV,
  fetchSimilarDebtors,
  getSimilarDebtors
} from "../../../../modules/debtor";
import {
  fetchEmailTemplates,
  getEmailTemplates
} from "../../../../modules/factoring";
import formatPennies from "../../../../helpers/format/formatPennies";
import formatStatus from "../../../../helpers/format/formatStatus";
import formatDate from "../../../../helpers/format/formatDate";
import PenniesInput from "../../../../components/inputs/PenniesInput";
import PurePayment from "./Payment";
import tabs from "../tabs";
import { payment as paymentSchema } from "./validation";
import MoreLinks from "./components/MoreLinks";
import columns from "./columns";
import { getUserType } from "apps/haulpay-frontend/src/modules/auth";
import { Tooltip } from "@material-ui/core";

export const Payment = PurePayment;

const adjustForm = (createLineItem, openSnackbar, refresh) => ({
  enableReinitialize: true,
  initialValues: {
    amount: 0,
    discountRate: "3.5",
    againstWho: "",
    category: "",
    description: ""
  },
  onSubmit: async (
    { fundingRequest, amount, discountRate, category, description },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await createLineItem(
        fundingRequest,
        Math.round(amount * 100),
        discountRate,
        category,
        description,
        "approved"
      );
      await refresh();
      openSnackbar("success", "Created Line Item!");
      resetForm();
      setFieldValue("approved", "pending");
    } catch (err) {
      openSnackbar("error", err);
    } finally {
      setSubmitting(false);
    }
  }
});

const mapStateToProps = (
  state,
  {
    match: {
      params: { id }
    }
  }
) => ({
  similarDebtors: getSimilarDebtors(state),
  fundingRequests: getOpenFundingRequests(state),
  isLoading: getIsLoading(state),
  companyName: getDebtorCompanyName(state),
  master: getDebtorMaster(state),
  dialog: getDialog(state),
  getSelectedFundingRequests: getSelectedFundingRequests(state),
  lineItemCategories: getLineItemCategories(state),
  getLineItemsByFundingRequest: getLineItemsByFundingRequest(state),
  count: getPaymentsCount(state),
  page: getPaymentsPage(state),
  rowsPerPage: getPaymentsRowsPerPage(state),
  rowsPerPageOptions: getPaymentsRowsPerPageOptions(state),
  sortDirection: getPaymentsSortDirection(state),
  sortBy: getPaymentsSortBy(state),
  filters: getPaymentsFilters(state),
  emailTemplates: getEmailTemplates(id)(state),
  userType: getUserType(state),
});

const mapDispatchToProps = {
  fetchSimilarDebtors,
  queryDebtor,
  queryOpenFundingRequests,
  createDebtorPayment,
  openSnackbar,
  createFundingRequestCSV,
  uploadFundingRequestCSV,
  openDialog,
  closeDialog,
  fetchReceivableNotes,
  createFollowupNote,
  fetchLineItemCategories,
  createLineItem,
  fetchLineItems,
  handlePaymentsSort,
  handlePaymentsChangePage,
  handlePaymentsChangeRowsPerPage,
  handlePaymentsSearchFilter,
  fetchEmailTemplates
};

export default compose(
  withSelect,
  connect(mapStateToProps, mapDispatchToProps),
  withState("status", "setStatus", []),
  withProps(
    ({
      history: {
        location: { pathname }
      },
      match: {
        params: { id }
      },
      status,
      setStatus,
      fundingRequests,
      selected,
      createDebtorPayment,
      queryOpenFundingRequests,
      clearSelected,
      openSnackbar,
      openDialog,
      createFollowupNote,
      fetchReceivableNotes,
      getSelectedFundingRequests,
      createLineItem,
      fetchLineItems,
      filters,
      page,
      rowsPerPage,
      sortDirection,
      sortBy,
      similarDebtors,
      userType
    }) => ({
      columns: columns(status, setStatus, userType),
      tabs: tabs(id, similarDebtors.length),
      navIndex: findIndex(tabs(id), tab => pathname === tab.route),
      fundingRequestsArray: fundingRequests,
      fundingRequests: fundingRequests.map(request => ({
        ...request,
        outstanding_balance: ({ isSelected, setFieldValue, values }) =>
          isSelected ? (
            <PenniesInput
              name={request.id}
              value={values[request.id] || 0}
              setFieldValue={setFieldValue}
              withStyle
            />
          ) : (
            formatPennies(request.outstanding_balance)
          ),
        receivable_status: formatStatus(request.receivable_status),
        response_category: formatStatus(request.response_category),
        followup_date: formatDate(request.followup_date),
        more: () => (
          <MoreLinks
            link={`/admin/fundingrequest/${request.id}`}
            handleViewPayments={() => {
              openDialog("debtor-payment-history", "", "", {
                id: request.receivable_id,
                fundingRequestId: request.id
              });
            }}
            handleNote={() => {
              fetchReceivableNotes(request.receivable_id);
              openDialog("debtor-payment-notes", "", "", {
                fundingRequestId: request.id,
                receivableId: request.receivable_id
              });
            }}
          />
        )
      })),
      id,
      handleAddFollowup: () => {
        openDialog("debtor-payment-notes");
      },
      handleSettle: () => {
        openDialog("debtor-payment-adjustment");
      },
      refresh: () => {
        queryOpenFundingRequests(
          id,
          sortDirection === "asc" ? sortBy : `-${sortBy}`,
          rowsPerPage,
          page,
          filters
        );
      },
      selectedFundingRequests: getSelectedFundingRequests(selected),
      adjustForm: adjustForm(createLineItem, openSnackbar, async () => {
        queryOpenFundingRequests(
          id,
          sortDirection === "asc" ? sortBy : `-${sortBy}`,
          rowsPerPage,
          page,
          filters
        );
        await Promise.all(fundingRequests.map(fr => fetchLineItems(fr.id)));
      })
    })
  ),
  lifecycle({
    async componentDidMount() {
      const { id } = this.props;
      await Promise.all([
        this.props.fetchLineItemCategories(),
        this.props.fetchSimilarDebtors(id),
        this.props.queryDebtor(id),
        this.props.queryOpenFundingRequests(
          id,
          "-time_posted",
          this.props.rowsPerPage,
          0,
          {}
        )
      ]);
    },
    async componentDidUpdate(prevProps) {
      if (
        this.props.dialog.variant !== prevProps.dialog.variant &&
        this.props.dialog.variant === "debtor-payment-adjustment"
      ) {
        Promise.all(
          this.props.selectedFundingRequests.map(fr =>
            this.props.fetchLineItems(fr.id)
          )
        );
      }
    }
  }),
  withProps(
    ({ id, createFundingRequestCSV, filters, uploadFundingRequestCSV }) => ({
      handleCSV: () =>
        createFundingRequestCSV(
          id,
          filters.receivable_status,
          filters.response_category,
          filters.factoring_company_profile_name,
          filters.invoice_number,
          filters.user_load_number,
          filters.receivable_age_gte,
          filters.receivable_age_lte,
          filters.reference_number
        ),
      uploadCSV: file => uploadFundingRequestCSV(id, file)
    })
  )
)(PurePayment);

import React, { useEffect, useState } from "react";
import { ClickAwayListener, MenuList, Paper, Popper } from "@material-ui/core";
import { get } from "lodash";
import BoldInput from "../BoldInput";
import DebtorItem from "./DebtorItem";
import getDebtors from "./getDebtors";
import CustomInputField from "../CustomInputField";

interface Debtor {
  id: string;
  company_name: string;
  dba: string;
  mc: string;
  dot: string;
  credit_approved: string;
  email: string;
}

interface Props {
  factoringId: string;
  label: string;
  setFieldValue: Function;
  setFieldTouched: Function;
  name: string;
  debtorName?: string;
  customInput?: boolean;
}

function DebtorDropDown({
  factoringId,
  label,
  setFieldValue,
  setFieldTouched,
  name,
  debtorName,
  customInput = false,
  ...props
}: Props & any): JSX.Element {
  const [debtors, setDebtors] = useState([]);
  const [closed, setClosed] = useState(true);
  const [query, setQuery] = useState(debtorName || "");

  useEffect((): void => {
    if (debtorName !== query && (query === undefined || query === "")) {
      setQuery(debtorName || get(props, ["value", "company_name"], ""));
    }
  }, [debtorName]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect((): void => {
    const seachForDebtors = async (): Promise<void> => {
      const search = await getDebtors({
        factoring: factoringId,
        dba: query,
        mc: query
      });
      setDebtors(search.slice(0, 5));
    };
    seachForDebtors();
  }, [query]);

  const InputComponent = !customInput ? BoldInput : CustomInputField;

  return (
    <>
      <InputComponent
        {...props}
        fullWidth
        label={label}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          if (!anchorEl) {
            setAnchorEl(event.currentTarget);
          }
          setQuery(event.target.value);
          setClosed(false);
          setFieldTouched(name, true);
        }}
        onBlur={(): void => {
          setFieldTouched(name, true);
        }}
        value={query}
      />
      {anchorEl && (
        <ClickAwayListener
          onClickAway={(): void => {
            setClosed(true);
            setAnchorEl(null);
          }}
        >
          <Popper
            open={!closed}
            anchorEl={anchorEl}
            style={{ zIndex: 2000 }}
            placement="bottom-start"
          >
            <Paper>
              <MenuList>
                {debtors.map(
                  (debtor: Debtor): JSX.Element => {
                    return (
                      <DebtorItem
                        id={debtor.id}
                        key={debtor.id}
                        email={debtor.email}
                        companyName={debtor.company_name}
                        dba={debtor.dba}
                        mc={debtor.mc}
                        dot={debtor.dot}
                        approved={debtor.credit_approved === "approved"}
                        onClick={(): void => {
                          setFieldValue(name, debtor);
                          setQuery(debtor.company_name || debtor.dba);
                          setClosed(true);
                        }}
                      />
                    );
                  }
                )}
              </MenuList>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
}

export default DebtorDropDown;

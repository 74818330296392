import { isNumber, isNaN } from "lodash";

const validNumber = (value: any): boolean => isNumber(value) && !isNaN(value);

const validDecimalNumber = (value: string): boolean => {
  if (value.includes(".")) {
    const split = value.split(".");
    if (split.length !== 2) {
      return false;
    }
    return validNumber(split[0]) && validNumber(split[1])
  }
  return false;
};

const pennyPadding = (value: string | number): string | number => {
  if (validNumber(value) || (value && validDecimalNumber(String(value)))) {
    let str = String(value);
    if (!str.includes(".")) {
      str += ".00";
    }

    return Number(str).toFixed(2);
  }
  return value;
};

export default pennyPadding;

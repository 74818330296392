import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import columns from "./columns";
import Table from "../../../../../../components/Table";
import { Paper, CardContent, Grid, Typography } from "@material-ui/core";

import {
    fetchWalletHistory,
    getWalletTransactions,
    walletTransactionsTableSelectors,
    walletTransactionsTableActions
} from "../../../../../../modules/ledger";

export default function ({ userId, factoringId, isStripeUser }) {
    const dispatch = useDispatch();
    const walletTransactions = useSelector(getWalletTransactions);

    useEffect(() => {
        const f = async () => {
            try {
                await dispatch(fetchWalletHistory(userId, factoringId, isStripeUser, 20, "-created", {}));
            } catch (error) {
                console.log("An error", error);
            }
        };
        f();
    }, [factoringId]);


    return (
        <Paper style={{ paddingLeft: 16, marginTop: 32, backgroundColor: "white" }}>
            <Table 
                columns={columns} 
                rows={walletTransactions}
                isLoading={useSelector(walletTransactionsTableSelectors.getIsLoading)}
                count={useSelector(walletTransactionsTableSelectors.getCount)}
                page={useSelector(walletTransactionsTableSelectors.getPage)}
                rowsPerPage={useSelector(walletTransactionsTableSelectors.getRowsPerPage)}
                handleChangePage={useCallback(
                    (...args) =>
                        dispatch(walletTransactionsTableActions.handleChangePage(...args)),
                    [dispatch]
                )}
                rowsPerPageOptions={useSelector(
                    walletTransactionsTableSelectors.getRowsPerPageOptions
                )}
                handleChangeRowsPerPage={useCallback(
                    (...args) =>
                        dispatch(
                            walletTransactionsTableActions.handleChangeRowsPerPage(...args)
                        ),
                    [dispatch]
                )}
                allowEmpty
            />
        </Paper>
    );
}

import { createReducer } from "redux-starter-kit";

import * as TYPES from "./types";
import createUserReducer from "./reducers/createUser";
import currentUserReducer from "./reducers/currentUser";
import getSelectedUserReducer from "./reducers/getUserById";
import lineUserFormReducer from "./reducers/linesUserForm";
import uploadFileReducer from "./reducers/uploadFile";
import updateUserReducer from "./reducers/updateUser";
import deleteUserReducer from "./reducers/deleteUser";

const initial = {
  userProfileSuccess: {},
  loading: false,
  selectedUser: {
    data: {},
    isFetching: false,
    isUpdating: false,
    error: null
  },
  documents: {
    data: [],
    isUploading: false,
    error: null
  },
  lineUserForm: {}
};

const reducer = createReducer(initial, {
  [TYPES.CREATE_USER_PROFILE_SUCCESS]: createUserReducer,
  [TYPES.CREATE_USER_PROFILE_FAILURE]: createUserReducer,
  [TYPES.CREATE_USER_PROFILE_REQUEST]: createUserReducer,
  [TYPES.CURRENT_USER_REQUEST]: currentUserReducer,
  [TYPES.CURRENT_USER_SUCCESS]: currentUserReducer,
  [TYPES.CURRENT_USER_FAILURE]: currentUserReducer,
  [TYPES.FETCH_USER_PROFILE_FAILURE]: getSelectedUserReducer,
  [TYPES.FETCH_USER_PROFILE_REQUEST]: getSelectedUserReducer,
  [TYPES.FETCH_USER_PROFILE_SUCCESS]: getSelectedUserReducer,
  [TYPES.LINES_USER_FORM]: lineUserFormReducer,
  [TYPES.UPLOAD_USER_FILE_SUCCESS]: uploadFileReducer,
  [TYPES.UPLOAD_USER_FILE_REQUEST]: uploadFileReducer,
  [TYPES.UPLOAD_USER_FILE_FAILURE]: uploadFileReducer,
  [TYPES.UPDATE_USER_PROFILE_SUCCESS]: updateUserReducer,
  [TYPES.UPDATE_USER_PROFILE_REQUEST]: updateUserReducer,
  [TYPES.UPDATE_USER_PROFILE_FAILURE]: updateUserReducer,
  [TYPES.DELETE_USER_FILE_FAILURE]: deleteUserReducer,
  [TYPES.DELETE_USER_FILE_REQUEST]: deleteUserReducer,
  [TYPES.DELETE_USER_FILE_SUCCESS]: deleteUserReducer
});

export default reducer;

export { default as clientUserUpdate } from "./actions/clientUserUpdate";
export { default as createUser } from "./actions/createUser";
export { default as currentUser } from "./actions/currentUser";
export { default as selectedUser } from "./actions/getUserById";
export { default as lineUserForm } from "./actions/linesUserForm";
export { default as updateUser } from "./actions/updateUser";
export { default as deleteFile } from "./actions/deleteFile";
export { default as uploadFile } from "./actions/uploadFiles";
export { default as deleteUser } from "./actions/deleteUser";
export { default as handleDeleteUser } from "./actions/handleDeleteUser";
export { default as handleLoginAsUser } from "./actions/handleLoginAsUser";
export { default as handleDeleteFile } from "./actions/handleDeleteFile";
export { default as handleDeleteAttachment } from "./actions/handleDeleteAttachment";
export { default as updateUserProfile } from "./actions/updateUserProfile";
export { default as handleConfirmDeleteUserFile } from "./actions/handleConfirmDeleteUserFile";
export { default as handleConfirmDeleteUser } from "./actions/handleConfirmDeleteUser";

export { default as getLoading } from "./selectors/getLoading";
export { default as getUserProfileSuccess } from "./selectors/getUserProfileSuccess";
export { default as getSelectedUser } from "./selectors/getSelectedUser";
export { default as getLineUserForm } from "./selectors/getLineUserForm";
export { default as getDocuments } from "./selectors/getDocuments";
export { default as getSelectedUserProfile } from "./selectors/getSelectedUserProfile";

import React, { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";

import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../components/Dialog";
import CustomTextField from "../../../../components/inputs/CustomTextField";

export default function ConfirmRecipientEmail({
  open,
  emailTemplate = "",
  email,
  handleConfirm,
  handleExit
}) {
  const [recipientEmail, setEmailRecipient] = useState(email);
  const [cc, setCc] = useState("");

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleExit}>
        {`${emailTemplate.label} - Confirm`}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <CustomTextField
            fullWidth
            label="Confirm Recipient Email"
            name="recipient_email"
            onChange={event => setEmailRecipient(event.target.value)}
            value={recipientEmail}
          />
          <CustomTextField
            fullWidth
            label="CC"
            name="email_template"
            onChange={event => setCc(event.target.value)}
            value={cc}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button color="secondary" onClick={handleExit}>
            Cancel
          </Button>
        </Grid>
        <Button
          color="primary"
          onClick={() => handleConfirm(recipientEmail, (cc || "").split(","))}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

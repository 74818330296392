import { merge } from "lodash";
import {
  FETCH_USER_NOTES_FAILURE,
  FETCH_USER_NOTES_REQUEST,
  FETCH_USER_NOTES_SUCCESS
} from "../types";

const fetchUserNotes = (state, { type, payload }) => {
  switch (type) {
    case FETCH_USER_NOTES_REQUEST:
      return {
        ...state,
        loadingUserNotes: true
      };
    case FETCH_USER_NOTES_SUCCESS:
      return {
        ...state,
        userNotes: merge({}, state.userNotes, payload),
        loadingUserNotes: false
      };
    case FETCH_USER_NOTES_FAILURE:
      return {
        ...state,
        loadingUserNotes: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchUserNotes;

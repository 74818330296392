import { Grid, MenuItem, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  id: string;
  email: string;
  companyName: string;
  dba: string;
  mc: string;
  dot: string;
  onClick: () => void;
  approved: boolean;
}

const DebtorItem = ({
  id,
  email,
  companyName,
  dba,
  mc,
  dot,
  onClick,
  approved
}: Props): JSX.Element => (
  <MenuItem
    key={id}
    value={id}
    onClick={() => onClick()}
    style={{ padding: 10, height: "unset" }}
  >
    <Grid container direction="column">
      <Grid item>
        <Typography>
          <b>{companyName || dba}</b>
        </Typography>
      </Grid>
      <Grid item>
        {email && (
          <Typography>
            {email}
          </Typography>
        )}
        <Typography>
          {mc && `MC #: ${mc} `}
          {dot && `DOT #: ${dot}`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <div style={{ color: "green" }}>{approved && "HAULPAY APPROVED"}</div>
        </Typography>
      </Grid>
    </Grid>
  </MenuItem>
);

export default DebtorItem;

import React from "react";
import Select from "react-select/lib/Async";
import { withStyles } from "@material-ui/core/styles";
import NoSsr from "@material-ui/core/NoSsr";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import components from "./components";

const useStyles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    padding: "3px 0 7px"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.38)"
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

const Address = ({
  classes,
  theme,
  name,
  setFieldValue,
  setFieldTouched,
  hasError,
  value,
  saveAddress,
  getSavedAddress,
  getSuggestionDetails,
  FilterPlaces,
  updateGoogleSessionToken,
  onlyState = false,
  placeholder = "Start typing your address",
  label = "Company Address",
  googleSessionToken,
  withStyle = false,
  disabled = false,
  id
}) => {
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  };
  const addressesList = getSavedAddress(onlyState ? "state" : "address");

  const handleChange = async data => {
    if (isEmpty(data)) return setFieldValue(name, {});
    const { label, ...values } = data;

    saveAddress(addressesList, { ...values, label }, onlyState);
    const address = await getSuggestionDetails(values);
    setFieldValue(name, { ...address, label });
    updateGoogleSessionToken();
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const withLabel = Object.assign({}, value);

  if (!withLabel.label) {
    if (withLabel.street_one) {
      withLabel.label = withLabel.street_one;
    }

    if (withLabel.city) {
      withLabel.label += `, ${withLabel.city}`;
    }

    if (withLabel.state) {
      withLabel.label += `, ${withLabel.state}`;
    }

    if (withLabel.zip) {
      withLabel.label += `, ${withLabel.zip}`;
    }
  }

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          name={name}
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            label,
            withStyle
          }}
          id={id}
          isDisabled={disabled}
          value={withLabel}
          cacheOptions
          defaultOptions={addressesList}
          error={hasError}
          label="Company Address"
          onChange={handleChange}
          onBlur={handleBlur}
          components={components}
          loadOptions={values => FilterPlaces(values, onlyState)}
          placeholder={placeholder}
          isClearable
        />
      </NoSsr>
    </div>
  );
};

Address.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired
  // value
};

export default withStyles(useStyles, { withTheme: true })(Address);

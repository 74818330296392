import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Fab,
  Grid,
  Typography,
  List,
  ListItemText,
  ListItem,
  IconButton
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import UploadFile from "../../../components/UploadFile";
import PaperSheet from "../../../components/PaperSheet";
import colors from "../../../theme/colors";

const Attach = ({
  error,
  usedPlaid,
  nextStep,
  factoringProfileId,
  setShowUploadAttachments
}) => {
  const IconBack = ({ onClick }) => (
    <Grid item container justify="flex-start">
      <IconButton aria-label="back" onClick={onClick}>
        <ArrowBackIcon />
      </IconButton>
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      xs={10}
      sm={9}
      md={10}
      style={{ margin: "auto", width: "70%" }}
      spacing={24}
    >
      <Grid item container direction="row" spacing={24}>
        <Grid item xs="auto">
          <IconBack onClick={() => setShowUploadAttachments(false)} />
        </Grid>
        <Grid item xs="auto">
          <Typography variant="body2" align="center">
            Please select your default payment option. You can ask the broker to
            change this later if needed.
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          align="left"
          variant="body1"
          style={{
            marginTop: 30,
            fontWeight: 400,
            color: colors.gray
          }}
        >
          Please Attach:
        </Typography>
      </Grid>
      <Grid item>
        <List dense>
          {!usedPlaid && (
            <ListItem>
              <ListItemText>
                1- Voided check or Direct Deposit Slip
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Grid>
      {error && (
        <Grid item>
          <PaperSheet message={error} variant="error" />
        </Grid>
      )}
      <Grid
        item
        xs={10}
        sm={9}
        md={8}
        style={{ alignSelf: "center", width: "100%" }}
      >
        <UploadFile
          type="payment"
          requestId={factoringProfileId}
          categoriesProps={["Void Check"]}
        />
      </Grid>
      <Grid item justify="flex-end">
        <Grid container direction="row-reverse">
          <Fab
            aria-label="Continue"
            color="primary"
            onClick={nextStep}
            style={{ marginTop: 30, justifySelf: "flex-end" }}
          >
            <ArrowForwardIos style={{ color: colors.white }} />
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
};

Attach.defaultProps = {
  error: ""
};

Attach.propTypes = {
  error: PropTypes.string,
  usedPlaid: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  factoringProfileId: PropTypes.string.isRequired
};

export default Attach;

import { List } from '@material-ui/core';
import React from 'react';
import get from 'lodash/get';
import { getEmojiDataFromNative } from 'emoji-mart';
import data from 'emoji-mart/data/all.json';

import Card from '../../../../../../components/Card';
import splitAndCase from '../../../../../../helpers/format/splitAndCase';
import AdminDropDown from '../../../../../../components/AdminDropDown';
import BoldInput, {
  Select
} from '../../../../../../components/inputs/BoldInput';
import ListItem from '../../../../../../components/ListItem';
import ProfileSection from '../../../../../../components/ProfileSection';
import formatPennies from '../../../../../../helpers/format/formatPennies';
import ReasonForLeavingInput from '../inputs/ReasonForLeavingInput';
import ReferralInput from '../inputs/ReferralInput';

const whereToFindUsOptions = [
  { text: 'Organic Search/Google', value: 'organic_search' },
  { text: 'Google Ad', value: 'google_ad' },
  { text: 'Facebook Ad', value: 'facebook_ad' },
  { text: 'Instagram Ad', value: 'instagram_ad' },
  { text: 'Industry Publication/Press', value: 'industry_publication_press' },
  { text: 'Other', value: 'other' }
];

const GeneralInfoCard = ({
  values,
  agreementNumber,
  userType,
  createdAt,
  lastLogin,
  source,
  email,
  contactName,
  phone,
  fax,
  averageDaysToPay,
  creditApprovedEmblem,
  cargoInsuranceValue,
  cargoInsuranceProvider,
  language,
  handleChange,
  handleBlur,
  setFieldValue,
  disableAll
}) => {
  const mexicanEmoji = getEmojiDataFromNative('🇲🇽', 'flag-mx', data);
  const usaEmoji = getEmojiDataFromNative('🇺🇸', 'flag-us', data);
  const rows = [
    { label: 'Contact Name', field: contactName, name: 'contact_name' },
    { label: 'Email', field: email, name: 'email' },
    { label: 'Phone', field: phone, name: 'phone' },
    {
      label: 'CMFT Agreement #',
      field: agreementNumber,
      name: 'cmft_agreement_number'
    },
    { label: 'User Type', field: splitAndCase(userType), name: 'user_type' },
    { label: 'Last Login', field: lastLogin, name: 'last_login' },
    { label: 'Created At', field: createdAt, name: 'created_at' },
    { label: 'Fax', field: fax, name: 'fax' },
    {
      label: 'Average Days To Pay',
      field: averageDaysToPay,
      name: 'average_days_to_pay'
    },
    {
      label: 'Credit Approved Emblem',
      field: creditApprovedEmblem,
      name: 'credit_approved_emblem'
    },
    {
      label: 'Cargo Insurance Value',
      field: cargoInsuranceValue,
      name: 'cargo_insurance_value'
    },
    {
      label: 'Cargo Insurance Provider',
      field: cargoInsuranceProvider,
      name: 'cargo_insurance_provider'
    },
    {
      label: 'Language',
      field:
        language === 'en'
          ? `English ${usaEmoji.native}`
          : `Español ${mexicanEmoji.native}`,
      name: 'language'
    }
  ];
  return (
    <Card style={{ height: '100%' }}>
      <List dense>
        <ListItem>
          <ProfileSection>{splitAndCase(userType)} Info:</ProfileSection>
        </ListItem>
        <ListItem>
          <AdminDropDown
            fullWidth
            label="Sales Rep"
            name="assignedAdmin"
            value={values.assignedAdmin}
            onChange={handleChange}
            disabled={disableAll}
          />
        </ListItem>
        <ListItem>
          <AdminDropDown
            fullWidth
            label="Account Manager"
            name="accountManager"
            value={values.accountManager}
            onChange={handleChange}
            disabled={disableAll}
          />
        </ListItem>
        <ListItem>
          <ReferralInput
            value={values.referralSource}
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            disabled={disableAll}
          />
        </ListItem>
        {/* <ListItem>
          <Select
            fullWidth
            label="Referral Source"
            name="referralSource"
            onChange={handleChange}
            value={values.referralSource}
            selections={[
              {
                text: "None",
                value: " "
              },
              {
                text: "RMIS",
                value: "rmis"
              },
              {
                text: "Greg Hudgens",
                value: "gh"
              },
              {
                text: "Don Huffman",
                value: "dh"
              },
              {
                text: "Tom Ghorley",
                value: "tg"
              },
              {
                text: "Aladdin Financial",
                value: "af"
              },
              {
                text: "West Coast Insurance",
                value: "wci"
              },
              {
                text: "Top Dispatching",
                value: "td"
              },
              {
                text: "CLD Transporation LLC",
                value: "cld"
              },
              {
                text: "Justin Martin",
                value: "jm"
              },
              {
                text: "Tim Dillon",
                value: "tim_dillon"
              }
            ]}
          />
        </ListItem> */}
        <ListItem>
          <Select
            fullWidth
            label="Referral Partner Share %"
            name="referralPartnerShare"
            onChange={handleChange}
            value={values.referralPartnerShare}
            selections={[
              {
                text: 'N/A',
                value: 0
              },
              {
                text: '5%',
                value: 5
              },
              {
                text: '7.5%',
                value: 7.5
              },
              {
                text: '10%',
                value: 10
              },
              {
                text: '12.5%',
                value: 12.5
              },
              {
                text: '15%',
                value: 15
              }
            ]}
            disabled={disableAll}
          />
        </ListItem>
        <ListItem>
          <Select
            label="Referral Margin Type"
            name="referralMarginType"
            onChange={handleChange}
            value={values.referralMarginType}
            fullWidth
            selections={[
              {
                text: 'None',
                value: 0
              },
              {
                text: 'On Gross Fees',
                value: 1
              },
              {
                text: 'On Net Fees',
                value: 2
              }
            ]}
            disabled={disableAll}
          />
        </ListItem>
        {rows.slice(0, 3).map(row => (
          <ListItem>
            <BoldInput
              fullWidth
              disabled
              label={row.label}
              value={row.field}
              id={row.name}
            />
          </ListItem>
        ))}
        <ListItem>
          <BoldInput
            fullWidth
            label="Contact Notes"
            name="contactNotes"
            onChange={handleChange}
            value={values.contactNotes}
            disabled={disableAll}
          />
        </ListItem>
        {rows.slice(3).map(row => (
          <ListItem>
            <BoldInput
              fullWidth
              disabled
              label={row.label}
              value={row.field}
              id={row.name}
            />
          </ListItem>
        ))}
        {userType === 'broker' && (
          <ListItem>
            <Select
              name="defaultCarrierLimit"
              label="Default Limit Per Carrier"
              fullWidth
              onChange={handleChange}
              value={values.defaultCarrierLimit}
              selections={values.defaultCarrierLimitOptions.map(option => ({
                text: formatPennies(option),
                value: option
              }))}
              disabled={disableAll}
            />
          </ListItem>
        )}
        <ListItem>
          <Select
            disabled
            value={get(values, 'user.referrer')}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            name="referrer"
            label="Referral"
            selections={whereToFindUsOptions}
          />
        </ListItem>
        <ListItem>
          <ReasonForLeavingInput
            value={values.reasonForLeaving}
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            disabled={disableAll}
          />
        </ListItem>
      </List>
    </Card>
  );
};

export default GeneralInfoCard;

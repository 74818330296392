const needsReviewCountFactoringCompanies = status => async (
  dispatch,
  getState,
  { api }
) => {
  const response = await api.groups.queryFactoringCompanies(
    undefined,
    undefined,
    {
      status: status || "needs_review"
    }
  );
  return response.count;
};

export default needsReviewCountFactoringCompanies;

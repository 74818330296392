import { merge } from "lodash";
import {
  FETCH_CLIENTNOTES_REQUEST,
  FETCH_CLIENTNOTES_SUCCESS,
  FETCH_CLIENTNOTES_FAILURE
} from "../types";

const fetchClientNotes = (state, { type, payload }) => {
  switch (type) {
    case FETCH_CLIENTNOTES_REQUEST:
      return {
        ...state,
        loadingClientNotes: true
      };
    case FETCH_CLIENTNOTES_SUCCESS:
      return {
        ...state,
        clientNotes: merge({}, state.clientNotes, payload),
        loadingClientNotes: false
      };
    case FETCH_CLIENTNOTES_FAILURE:
      return {
        ...state,
        loadingClientNotes: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchClientNotes;

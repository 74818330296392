import formatDate from "../../../../helpers/format/formatDate";
import splitAndCase from "../../../../helpers/format/splitAndCase";

export const columns = [
  {
    key: "dba",
    name: "DBA",
    filter: "text",
    filter_key: "company_name"
  },
  {
    key: "user_type",
    name: "Type",
    filter_key: "user_type",
    format: splitAndCase,
    filter: "dropdown",
    filter_values: [
      { label: "All", value: "" },
      { label: "Carrier", value: "carrier" },
      { label: "Broker", value: "broker" }
    ]
  },
  {
    name: "MC",
    key: "mc",
    filter: "text",
    filter_key: "mc"
  },
  {
    name: "DOT",
    key: "dot",
    filter: "text",
    filter_key: "dot"
  },
  {
    key: "group_role",
    name: "Role",
    format: splitAndCase
  },
  {
    key: "created",
    name: "Created At",
    sort: true,
    format: formatDate
  },
  {
    key: "email",
    name: "Email",
    filter: "text",
    filter_key: "email"
  }
];

export const paymentProfileColumns = [
  {
    key: "dba",
    name: "DBA",
    filter: "text",
    filter_key: "company_name"
  },
  {
    key: "user_type",
    name: "Type",
    filter_key: "user_type",
    format: splitAndCase,
    filter: "dropdown",
    filter_values: [
      { label: "All", value: "" },
      { label: "Carrier", value: "carrier" },
      { label: "Broker", value: "broker" }
    ]
  },
  {
    key: "carrier_limit",
    name: "Carrier Limit"
  },
  {
    name: "MC",
    key: "mc",
    filter: "text",
    filter_key: "mc"
  },
  {
    name: "DOT",
    key: "dot",
    filter: "text",
    filter_key: "dot"
  },
  {
    key: "broker_percent",
    name: "Broker's Portion %"
  },
  {
    key: "carriers_rate",
    name: "Carrier's Rate"
  },
  {
    key: "payout_days",
    name: "Payment Speed"
  },
  {
    key: "group_role",
    name: "Role",
    format: splitAndCase
  },
  {
    key: "created",
    name: "Created At",
    sort: true,
    format: formatDate
  },
  {
    key: "contact_email",
    name: "Email",
    filter: "text",
    filter_key: "email"
  },
  {
    key: "action",
    name: "Actions"
  }
];

import React, { ChangeEventHandler } from "react";
import { List } from "@material-ui/core";
import ListItem from "./ListItem";
import ProfileSection from "./ProfileSection";
import BoldInput from "./inputs/BoldInput";
import { FormikErrors } from "formik";
import isUndefined from "lodash/isUndefined";

interface IValues {
    mailingAddressOne: string;
    mailingAddressTwo: string;
    mailingAddressCity: string;
    mailingAddressState: string;
    mailingAddressZip: string
    mailingAddressCountry: string;
    legalAccountName?: string;
}
interface IProps {
    values: IValues;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleBlur: ChangeEventHandler<HTMLInputElement>;
    errors?: FormikErrors<IValues>;
    displayLegalName?: boolean;
}

const MailingAddressInfo = ({ handleChange, handleBlur, values, errors, displayLegalName }: IProps): JSX.Element => {
    const errorField = (fieldName: keyof IValues ) => {
        if (!isUndefined(errors)) {
            return {
                error: !!errors[fieldName],
                helperText: errors[fieldName] ? errors[fieldName] : ""
            };
        }
        return {};
    }
    return (
        <List dense>
            <ListItem>
                <ProfileSection>
                    Mailing Address
                </ProfileSection>
            </ListItem>
            {displayLegalName &&
                <ListItem>
                    <BoldInput fullWidth
                        name="legalAccountName"
                        value={values.legalAccountName}
                        label="Legal Account Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        {...errorField("legalAccountName")}
                    />
                </ListItem>
            }
            <ListItem>
                <BoldInput fullWidth
                    name="mailingAddressOne"
                    value={values.mailingAddressOne}
                    label="Address 1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...errorField("mailingAddressOne")}
                />
            </ListItem>
            <ListItem>
                <BoldInput
                    fullWidth
                    name="mailingAddressTwo"
                    value={values.mailingAddressTwo}
                    label="Address 2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </ListItem>
            <ListItem>
                <BoldInput
                    fullWidth
                    name="mailingAddressCity"
                    value={values.mailingAddressCity}
                    label="City"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...errorField("mailingAddressCity")}
                />
            </ListItem>
            <ListItem>
                <BoldInput
                    fullWidth
                    name="mailingAddressState"
                    value={values.mailingAddressState}
                    label="State"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...errorField("mailingAddressState")}
                />
            </ListItem>
            <ListItem>
                <BoldInput
                    fullWidth
                    name="mailingAddressZip"
                    value={values.mailingAddressZip}
                    label="Zip"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...errorField("mailingAddressZip")}
                />
            </ListItem>
            <ListItem>
                <BoldInput
                    fullWidth
                    name="mailingAddressCountry"
                    value={values.mailingAddressCountry}
                    label="Country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </ListItem>
        </List>
    );
};

export default MailingAddressInfo;

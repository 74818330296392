import { denormalize } from "normalizr";
import { walletTransactions } from "../../../api/schema";

export default state => {
    try {
        const items = state.ledger.walletTransactions.ids;

        const denormalized = denormalize(
            { items },
            { items: [walletTransactions] },
            state.entities
        );
        return denormalized.items || [];
    } catch (err) {
        return [];
    }
};

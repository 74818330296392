import React from "react";
import { IconButton, ListItemIcon } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";
import { faFileDownload, faMinus, faPaperclip, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../theme/colors";

interface Attachments {
  [index: string]: {
    isLoading: boolean;
    url: string;
  };
}

interface FileAttachment {
  created: string;
  deleted_by: string | null;
  deleted_reason: string | null;
  download_url: string;
  filename: string;
  funding_request_note: string;
  id: string;
  modified: string;
  preview_url: string;
  upload_url: string;
  uploaded: string;
}

interface Note {
  id: string;
  attachments: string[] | FileAttachment[];
  url?: string;
}

interface LoadAttachmentPayload {
  attachmentId: string;
  attachment: string;
}

interface Props {
  note: Note;
  notesAttachments: Attachments;
  loadNotesAttachments?: ({
    attachmentId,
    attachment
  }: LoadAttachmentPayload) => Promise<string>;
}

function AttachmentsView({
  note,
  notesAttachments,
  loadNotesAttachments
}: Props): JSX.Element {
  const attachments = get(note, "attachments", []);
  const isLoading = get(notesAttachments, [note.id, "isLoading"], false);
  const url = get(notesAttachments, [note.id, "url"]);
  const attachmentUrl = get(note, "url");
  const downloadUrl = get(note, ["attachments", "0", "download_url"]);
  if (!attachments.length) {
    return (
      <ListItemIcon>
        <IconButton aria-label="minus" className="minus">
          <FontAwesomeIcon icon={faMinus} size="xs" color={Colors.secondary} />
        </IconButton>
      </ListItemIcon>
    );
  }

  let child = <div />;

  if (isLoading) {
    child = (
      <FontAwesomeIcon
        icon={faSpinner}
        size="xs"
        spin
        color={Colors.secondary}
      />
    );
  } else if (url) {
    child = (
      <FontAwesomeIcon
        icon={faFileDownload}
        size="xs"
        className="download"
        color={Colors.secondary}
      />
    );
  } else {
    child = (
      <FontAwesomeIcon className="paperclip" icon={faPaperclip} size="xs" color={Colors.secondary} />
    );
  }
  return (
    <ListItemIcon>
      <IconButton
        aria-label="attachments"
        disabled={isLoading}
        onClick={async (): Promise<void> => {
          if (!url && !attachmentUrl && !downloadUrl) {
            if (
              loadNotesAttachments &&
              typeof note.attachments[0] === "string"
            ) {
              const attachmentLink = await loadNotesAttachments({
                attachmentId: note.id,
                attachment: note.attachments[0]
              });
              window.open(attachmentLink, "_blank");
            }
          } else {
            window.open(url || attachmentUrl || downloadUrl, "_blank");
          }
        }}
      >
        {child}
      </IconButton>
    </ListItemIcon>
  );
}

export default AttachmentsView;

import React, { useCallback, useState } from "react";
import {
  createStyles,
  Fab,
  Grid,
  List,
  Typography,
  withStyles
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import ListItem from "../../../../../components/ListItem";
import FactorCompanyAutoComplete from "../../../../../components/inputs/FactorCompanyAutocomplete";
import colors from "../../../../../theme/colors";
import NOAFactor from "./NOAFactor";
import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../../modules/ui";
import factorCompanyAPI from "../../../../../api/factorCompany";
import formatLocation from "../../../../../helpers/format/formatAddress";
import UploadFile from "../../../../../components/UploadFile";
import AddFactoringCompanyModal from "./AddFactorCompanyModal";
import ConnectedBrokerAutoComplete from "../../../../../components/inputs/ConnectedBrokerAutoComplete";
import { fetchPaymentProfile } from "../../../../../modules/payment";
import ProfileSection from "../../../../../components/ProfileSection";

const styles = () =>
  createStyles({
    button: {
      boxShadow:
        "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      background: colors.green_dark,
      color: colors.white,
      textTransform: "none"
    }
  });

const DetailsFactorCompany = ({ value }) => {
  if (!value) return <div />;
  return <Typography variant="body2">{value}</Typography>;
};

export default withStyles(styles)(
  ({
    classes,
    values,
    errors,
    setFieldValue,
    handleBlur,
    paymentProfile,
    paymentProfileId
  }) => {
    const [loading, setLoading] = useState(false);
    const [broker, setBroker] = useState(undefined);
    const dispatch = useDispatch();
    const [selectedFactorCompany, setSelectedFactorCompany] = useState(
      undefined
    );
    const [relationship, setRelationship] = useState({});

    const openCreateDialog = useCallback(
      () => dispatch(openDialog("create_factor_modal")),
      [dispatch]
    );

    const dialog = useSelector(getDialog);

    const openConfirmInviteModal = useCallback(
      args =>
        dispatch(
          openDialog(
            "confirm_send_invite",
            "Confirm Send Invite",
            "Are you sure you would like to send invite to this factor company ?"
          )
        ),
      []
    );
    const NOALink =
      get(
        paymentProfile,
        "factoring_company.noa_attachment.preview_url",
        undefined
      ) || get(relationship, "attachments[0].preview_url", undefined);

    const relationShipId =
      get(paymentProfile, "factoring_company.relationship_id", undefined) ||
      get(relationship, "id", undefined);

    const handleExit = useCallback(() => dispatch(closeDialog()), [dispatch]);

    const connectFactorOnClick = useCallback(async (args, broker) => {
      setLoading(true);
      handleExit();
      let relation = {};
      try {
        relation = await factorCompanyAPI.createFactorCompanyRelation(
          args.id,
          get(broker, ["factoring", "id"]),
          paymentProfileId
        );
        await factorCompanyAPI.sendFactorCompanyInvite(
          args.id,
          paymentProfileId
        );
        setRelationship(relation);
        setFieldValue("businessCurrentFactoringCompany", args.name);
        setFieldValue("businessCurrentFactoringCompanyId", args.id);
        dispatch(
          openSnackbar(
            "success",
            `Factoring company ${args.name} has been linked to ${broker.factoring.company_profile.name}.`
          )
        );
      } catch (e) {
        dispatch(openSnackbar("error", e));
      }
      setLoading(false);
      setBroker({});
      setSelectedFactorCompany({});
    }, []);

    return (
      <List dense>
        <ListItem>
          <ProfileSection>Settings</ProfileSection>
        </ListItem>
        {!values.businessCurrentFactoringCompany && (
          <ListItem>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <ConnectedBrokerAutoComplete
                  label="Broker Name"
                  placeholder="Select a Connected Broker"
                  value={broker}
                  onChange={value => setBroker(value)}
                  paymentProfileId={paymentProfile.id}
                />
              </Grid>
              <Grid container item direction="row">
                <Grid item xs={8}>
                  <FactorCompanyAutoComplete
                    label="Company Name"
                    placeholder="Company Name"
                    value={selectedFactorCompany}
                    onChange={value => setSelectedFactorCompany(value)}
                    onNoOptionClick={openCreateDialog}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  alignContent="center"
                  justify="center"
                >
                  <Fab
                    disabled={isEmpty(selectedFactorCompany) || isEmpty(broker)}
                    variant="extended"
                    size="medium"
                    onClick={() =>
                      connectFactorOnClick(selectedFactorCompany, broker)
                    }
                    className={classes.button}
                  >
                    Connect Factor
                  </Fab>
                </Grid>
              </Grid>
              {!isEmpty(selectedFactorCompany) && (
                <>
                  <Grid
                    item
                    container
                    style={{ margin: "auto", width: "100%" }}
                    xs={12}
                  >
                    <Grid item xs={6}>
                      <DetailsFactorCompany
                        value={selectedFactorCompany.email}
                      />
                      <DetailsFactorCompany
                        value={selectedFactorCompany.phone_number}
                      />
                      <DetailsFactorCompany
                        value={formatLocation(selectedFactorCompany)}
                      />
                    </Grid>
                    <Grid item xs={4} />
                  </Grid>
                </>
              )}
            </Grid>
          </ListItem>
        )}
        {values.businessCurrentFactoringCompany && !NOALink && relationShipId && (
          <ListItem>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  style={{
                    fontSize: 16,
                    color: "black",
                    fontWeight: "bold"
                  }}
                >
                  Upload NOA
                </Typography>
              </Grid>
              <Grid item>
                <UploadFile
                  single
                  noCategories
                  type="NOA"
                  requestId={relationShipId}
                  isClearFiles
                  buttonClearText="Save"
                  clearFilesButtonAction={async () =>
                    dispatch(fetchPaymentProfile(paymentProfile.id))
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        )}
        {values.businessCurrentFactoringCompany && NOALink && relationShipId && (
          <ListItem>
            <NOAFactor
              relationId={relationShipId}
              NOALink={NOALink}
              paymentProfileId={paymentProfile.id}
              setFieldValue={setFieldValue}
            />
          </ListItem>
        )}
        {dialog.variant === "create_factor_modal" && (
          <AddFactoringCompanyModal
            open={dialog.show}
            handleExit={handleExit}
            setSelectedFactorCompany={setSelectedFactorCompany}
          />
        )}
      </List>
    );
  }
);

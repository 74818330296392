import { LINES_USER_FORM } from "../types";

export default (
  state = {
    lineUserForm: {}
  },
  action
) => {
  switch (action.type) {
    case LINES_USER_FORM:
      return { ...state, lineUserForm: action.payload };
    default:
      return state;
  }
};

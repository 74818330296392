import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import { Redirect } from 'react-router-dom';
import {
  Grid,
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import {
  get,
  merge,
  sum,
  head,
  isInteger,
  filter,
  isEmpty,
  find,
  indexOf
} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHdd,
  faTrashAlt,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';

import InternalLayout from '../../../../components/layouts/InternalLayout';
import Can from '../../../../components/Can';
import NavigationTabs from '../../../../components/NavigationTabs';
import Table from '../../../../components/Table';
import formatPennies from '../../../../helpers/format/formatPennies';
import Fields from './components/Fields';
import TitleButtons from './components/TitleButtons';
import NotesDialog from '../../../../components/dialogs/NotesDialog';
import AdjustmentDialog from './components/AdjustmentDialog';
import PaymentsHistory from './components/PaymentsHistory';
import MasterStar from '../../../../components/icons/MasterStar';
import Filters from './Filters';
import NegativeMarginIcon from '../../../../components/icons/NegativeMarginIcon';
import CustomTextField, {
  CustomPenniesField
} from '../../../../components/inputs/CustomTextField';
import ClientAutoComplete from '../../../../components/inputs/ClientAutoComplete';
import colors from '../../../../theme/colors';
import { openDialog, openSnackbar } from '../../../../modules/ui';
import Yup from '../../../../YupValidation';
import { createPaymentForward } from '../../../../modules/debtor';
import { payment as paymentSchema } from './validation';
import UploadAttachmentsModal from './components/UploadAttachmentsModal';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import UploadBulkAttachments from './components/UploadBulkAttachments';
import SelfFinanceIcon from '../../../../components/icons/SelfFinanceIcon';

export default ({
  id,
  tabs,
  navIndex,
  columns,
  isSelected,
  allSelected,
  handleSelect,
  handleSelectAllClick,
  companyName,
  master,
  handleCSV,
  noteForm,
  uploadCSV,
  dialog,
  handleAddFollowup,
  closeDialog,
  receivableNotes,
  handleSettle,
  selectedFundingRequests,
  selected,
  lineItemCategories,
  adjustForm,
  getLineItemsByFundingRequest,
  fundingRequests,
  isLoading,
  refresh,
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  sortDirection,
  sortBy,
  handlePaymentsSort,
  handlePaymentsChangePage,
  handlePaymentsChangeRowsPerPage,
  handlePaymentsSearchFilter,
  filters,
  clearSelected,
  createDebtorPayment,
  queryOpenFundingRequests,
  fundingRequestsArray,
  userType
}) => {
  const initialState = {
    rowsForm: [],
    amountAdded: [],
    totalAmount: 0,
    displayBulkAttachmentsModal: false,
    displayAttachmentsModal: false,
    fundingRequestId: '',
    selectAllAction: false
  };
  const [updated, setUpdated] = useState(false);
  const [confirmOnSubmitPayment, setConfirmOnSubmitPayment] = useState(false);

  const formRef = useRef();
  const dispatch = useDispatch();

  const onSubmitPaymentForm = useCallback(
    async (debtorId, values) => {
      formRef.current.setSubmitting(true);
      try {
        const data = {
          amount: Math.round(Number(values.amount) * 100),
          category: values.type,
          sch: values.sch,
          paid_date: values.paid_date,
          notes: values.notes.length > 0 ? values.notes : undefined,
          bank_description: values.bank_description || undefined,
          funding_requests: selected.map(id => ({
            id,
            amount_applied: Math.round(Number(values[id]) * 100)
          }))
        };
        await createDebtorPayment(debtorId, data);
        await refresh();
        clearSelected();
        dispatch(openSnackbar('success', 'Applied Debtor Payment!'));
        formRef.current.resetForm();
      } catch (err) {
        dispatch(openSnackbar('error', head(err)));
      } finally {
        formRef.current.setSubmitting(false);
        setConfirmOnSubmitPayment(false);
        dispatch(closeDialog());
      }
    },
    [selected]
  );

  const paymentForm = (
    selected,
    fundingRequests = [],
    debtorId,
    createDebtorPayment,
    clearSelected,
    openSnackbar,
    refresh
  ) => ({
    enableReinitialize: true,
    validationSchema: paymentSchema(selected),
    initialValues: {
      paid_date: '',
      type: '',
      sch: '',
      amount: 0,
      notes: '',
      amountAdded: 0,
      bank_description: '',
      ...fundingRequests.reduce(
        (acc, curr) =>
          merge({}, acc, {
            [curr.id]: Number(curr.outstanding_balance) / 100 || 0
          }),
        {}
      )
    },
    onSubmit: async (values, { isSubmitting }) => {
      const hasNegativeReserve =
        filter(
          fundingRequestsArray,
          fundingRequest =>
            selected.includes(fundingRequest.id) &&
            get(fundingRequest, 'reserve_info.has_negative_reserve') &&
            get(fundingRequest, 'receivable_status', []).includes([
              'non_factored_open',
              'non_factored_over_paid',
              'non_factored_short_paid',
              'non_factored'
            ])
        ).length > 0;
      return hasNegativeReserve && !confirmOnSubmitPayment
        ? dispatch(
            openDialog('invoice-confirm-negative-margin', 'Confirm', '', {
              isSubmitting
            })
          )
        : onSubmitPaymentForm(debtorId, values);
    }
  });

  const formColumn = {
    id: Math.floor(Math.random() * 999 + 1),
    invoice_number: {
      default: '#####'
    },
    carrier: {
      initialValue: '',
      component: ({
        field,
        form: { touched, setFieldValue, handleBlur, errors },
        ...inputProps
      }) => (
        <div>
          <ClientAutoComplete
            id="client"
            {...field}
            {...inputProps}
            DropdownIndicator={false}
            onChange={carrier => {
              setFieldValue('carrier', carrier);
            }}
            hasError={touched[field.name] && errors[field.name]}
          />
        </div>
      )
    },
    amount: {
      initialValue: 0.0,
      component: ({
        field,
        form: { touched, errors, setFieldValue, handleBlur, ...form },
        ...inputProps
      }) => (
        <div style={{ paddingTop: 5 }}>
          <CustomPenniesField
            id="amount"
            {...field}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            {...inputProps}
            error={touched[field.name] && errors[field.name]}
          />
        </div>
      )
    },
    amount_due: {
      initialValue: 0.0,
      component: ({
        field,
        form: { touched, errors, setFieldValue, handleBlur, values, ...form },
        ...inputProps
      }) => (
        <div style={{ paddingTop: 5 }}>
          <CustomPenniesField
            id="amount_due"
            {...field}
            setFieldValue={(...args) => {
              setFieldValue(...args);
            }}
            onBlur={value => {
              setAmountAdded({ id: values.id, amount: value });
              setTimeout(() => setUpdated(true), 500);
            }}
            {...inputProps}
            error={touched[field.name] && errors[field.name]}
          />
        </div>
      )
    },
    user_load_number: {
      initialValue: '',
      component: ({ field, form: { touched, errors }, ...inputProps }) => (
        <div style={{ paddingTop: 5 }}>
          <CustomTextField
            id="user_load_number"
            {...field}
            {...inputProps}
            error={touched[field.name] && errors[field.name]}
          />
        </div>
      )
    },
    actions: ({ handleSubmit, setFieldValue, index, isSubmitting }) => {
      return (
        <Grid container style={{ minWidth: '200px' }}>
          <Grid item>
            <Tooltip title="Save">
              <IconButton
                id="save"
                style={{ width: '48px', height: '48px' }}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <FontAwesomeIcon
                    color={colors.green_dark}
                    spin
                    icon={faSpinner}
                  />
                ) : (
                  <FontAwesomeIcon color={colors.green_dark} icon={faHdd} />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Switch
              name="charge_non_factored_fee"
              onChange={(event, checked) =>
                setFieldValue('charge_non_factored_fee', checked)
              }
              defaultChecked
            />
            <Typography style={{ paddingLeft: 10 }} variant="caption">
              NF Fee
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title="delete">
              <IconButton
                style={{ width: '48px', height: '48px' }}
                onClick={() => deleteRow(index)}
              >
                <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    }
  };

  function reducer(state, action) {
    let form = [];
    switch (action.type) {
      case 'ADD_ROW':
        return { ...state, rowsForm: [formColumn, ...state.rowsForm] };
      case 'OPEN_BULK_ATTACHMENT_MODAL':
        return { ...state, displayBulkAttachmentsModal: true };
      case 'CLOSE_BULK_ATTACHMENT_MODAL':
        return { ...state, displayBulkAttachmentsModal: false };
      case 'OPEN_ATTACHMENT_MODAL':
        return {
          ...state,
          displayAttachmentsModal: true,
          fundingRequestId: action.payload
        };
      case 'CLOSE_ATTACHMENT_MODAL':
        return {
          ...state,
          displayAttachmentsModal: false,
          fundingRequestId: ''
        };
      case 'DELETE_ROW':
        form = filter(state.rowsForm, row => row.id !== action.payload);
        return { ...state, rowsForm: form };
      case 'ADD_AMOUNT':
        form = filter(
          state.amountAdded,
          object => object.id !== action.payload.id
        );
        return {
          ...state,
          amountAdded: [...form, action.payload]
        };
      case 'TOTAL_AMOUNT':
        return {
          ...state,
          totalAmount: action.payload
        };
      case 'DELETE_SELECTED_INDEX':
        if (indexOf(selected, action.payload) !== -1) {
          handleSelect(action.payload);
        }
        return state;
      case 'SELECT_ALL_ACTION':
        return {
          ...state,
          selectAllAction: !state.selectAllAction
        };
      default:
        throw new Error();
    }
  }
  const [state, localDispatch] = useReducer(reducer, initialState);

  const handleAddInvoice = () => {
    localDispatch({ type: 'ADD_ROW' });
  };

  const handleAddAttachments = () =>
    localDispatch({ type: 'OPEN_BULK_ATTACHMENT_MODAL' });
  const handleAddAttachment = payload =>
    localDispatch({ type: 'OPEN_ATTACHMENT_MODAL', payload });

  const deleteRow = index => {
    localDispatch({ type: 'DELETE_ROW', payload: index });
    localDispatch({ type: 'DELETE_SELECTED_INDEX', payload: index });
  };

  const setAmountAdded = payload => {
    localDispatch({ type: 'ADD_AMOUNT', payload });
  };

  const setTotalAmount = payload => {
    localDispatch({ type: 'TOTAL_AMOUNT', payload });
  };

  const formValidation = Yup.object().shape({
    amount: Yup.number().required('Please fill out this field'),
    carrier: Yup.object().shape({
      id: Yup.string().required('Please fill out this field')
    }),
    user_load_number: Yup.string().required('Please fill out this field')
  });

  const onSubmit = useCallback(
    async (index, fields, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const res = await dispatch(
          createPaymentForward({
            debtor_id: id,
            factoring_id: fields.carrier.id,
            amount: parseFloat(fields.amount) * 100,
            user_load_number: fields.user_load_number,
            charge_non_factored_fee: fields.charge_non_factored_fee,
            filters: { response_type: 'debtor_receivables' }
          })
        );
        deleteRow(index);
        if (indexOf(selected, index) !== -1) {
          const amount = get(formRef, `current.state.amountAdded`, 0);
          if (amount > 0) {
            formRef.current.setFieldValue(
              'amountAdded',
              amount - Number(fields.amount)
            );
          }
          handleSelect(res.id);
        }
        dispatch(openSnackbar('success', 'Invoice successfully added.'));
        handleAddAttachment(res.id);
      } catch (err) {
        dispatch(openSnackbar('error', head(err)));
      }
      setSubmitting(false);
    },
    [id, selected]
  );

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevSelected = usePrevious(selected);

  useEffect(() => {
    const asyncFunction = async () => {
      try {
        if (
          selected.length &&
          get(
            formRef,
            `current.props.initialValues.${selected[selected.length - 1]}`
          ) &&
          (prevSelected || []).length < (selected || []).length &&
          !state.selectAllAction
        ) {
          const id = selected[selected.length - 1];
          const amountDue = Number(
            (find(fundingRequestsArray, ['id', id]) || {})
              .outstanding_balance || 0
          );
          formRef.current.validateForm();
          return formRef.current.setFieldValue(
            id,
            amountDue > 0 ? amountDue / 100 : amountDue
          );
        }
        if (
          selected.length &&
          (prevSelected || []).length < (selected || []).length &&
          state.selectAllAction
        ) {
          selected.map(id => {
            const amountDue = Number(
              (find(fundingRequestsArray, ['id', id]) || {})
                .outstanding_balance || 0
            );
            formRef.current.validateForm();
            return formRef.current.setFieldValue(
              id,
              amountDue > 0 ? amountDue / 100 : amountDue
            );
          });
          return localDispatch({ type: 'SELECT_ALL_ACTION' });
        }
        if (isEmpty(selected)) {
          formRef.current.validateForm();
          return formRef.current.setFieldValue('amountAdded', 0);
        }
        const totalAmount = sum(
          selected.map(id => {
            let amount = 0;
            if (isInteger(id)) {
              amount = (find(state.amountAdded, ['id', id]) || {}).amount || 0;
              return Number(amount);
            }
            return Number(amount) / 100;
          })
        );
        setTotalAmount(totalAmount);
        formRef.current.validateForm();
        formRef.current.setFieldValue('amountAdded', totalAmount);
        setUpdated(false);
      } catch (e) {}
    };
    asyncFunction();
    return () => ({});
  }, [selected, updated, state.selectAllAction]);

  return (
    <Can
      perform="admin-debtor-payment:view"
      yes={() => (
        <InternalLayout title="Debtor Receivables">
          <Formik
            innerRef={formRef}
            {...paymentForm(
              selected,
              fundingRequestsArray,
              id,
              createDebtorPayment,
              clearSelected,
              openSnackbar,
              async () => {
                await queryOpenFundingRequests(
                  id,
                  sortDirection === 'asc' ? sortBy : `-${sortBy}`,
                  rowsPerPage,
                  page,
                  filters
                );
              }
            )}
          >
            {({
              handleSubmit,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue
            }) => (
              <React.Fragment>
                <Grid container style={{ paddingBottom: '24px' }}>
                  <Grid item>
                    <Grid container spacing={16}>
                      <Grid item>{master === true && <MasterStar />}</Grid>
                      <Grid item>
                        <Typography variant="h6">{companyName}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <NavigationTabs tabs={tabs} value={navIndex} />
                <Paper style={{ marginTop: '32px' }}>
                  <div style={{ padding: '32px' }}>
                    <Fields
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleCSV={handleCSV}
                      setFieldValue={setFieldValue}
                      handleUpload={uploadCSV}
                    />
                    <TitleButtons
                      id={id}
                      isSubmitting={isSubmitting}
                      handleSubmit={handleSubmit}
                      handleAddFollowup={handleAddFollowup}
                      handleSettle={handleSettle}
                      selected={selected}
                      fundingRequests={fundingRequests}
                      handleAddInvoice={handleAddInvoice}
                      handleAddAttachments={handleAddAttachments}
                    />
                  </div>
                  <Filters
                    handleSearchFilter={event => {
                      clearSelected();
                      handlePaymentsSearchFilter(event);
                    }}
                  />
                  <Table
                    columns={columns}
                    rows={fundingRequests.map(row => ({
                      ...row,
                      invoice_number: () => (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}>
                          <div>
                            {get(row, 'invoice_number', '')}
                          </div>
                          <SelfFinanceIcon contractType={get(row, 'contract_type')} />
                        </div>
                      ),
                      invoice_amount: (
                        <Grid container direction="row" alignItems="center">
                          {formatPennies(row.amount)}
                          <Grid item>
                            <NegativeMarginIcon
                              factoringAmount={row.factoring_amount}
                            />
                          </Grid>
                        </Grid>
                      )
                    }))}
                    isLoading={isLoading}
                    select
                    isSelected={isSelected}
                    allSelected={allSelected}
                    handleSelect={handleSelect}
                    handleSelectAllClick={values => {
                      handleSelectAllClick(values);
                      localDispatch({ type: 'SELECT_ALL_ACTION' });
                    }}
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handlePaymentsChangePage}
                    handleChangeRowsPerPage={handlePaymentsChangeRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    sort
                    handleSort={handlePaymentsSort}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    filter
                    filters={filters}
                    handleFilterChange={event => {
                      clearSelected();
                      handlePaymentsSearchFilter(event);
                    }}
                    form
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    formKeys={[
                      'carrier',
                      'amount',
                      'amount_due',
                      'user_load_number'
                    ]}
                    rowsForm={state.rowsForm}
                    allowEmpty
                    formTableProps={{
                      onSubmit,
                      validationSchema: formValidation
                    }}
                  />
                </Paper>
                <UploadBulkAttachments
                  open={state.displayBulkAttachmentsModal}
                  handleClose={() =>
                    localDispatch({ type: 'CLOSE_BULK_ATTACHMENT_MODAL' })
                  }
                  selected={selected}
                />
                <UploadBulkAttachments
                  open={state.displayAttachmentsModal}
                  handleClose={() =>
                    localDispatch({ type: 'CLOSE_ATTACHMENT_MODAL' })
                  }
                  selected={[state.fundingRequestId]}
                />
                {dialog.variant === 'debtor-payment-notes' && (
                  <NotesDialog
                    receivableId={get(dialog, ['data', 'receivableId'])}
                    debtorId={id}
                    selected={selected}
                    refresh={refresh}
                    userType={userType}
                  />
                )}
                {dialog.variant === 'debtor-payment-adjustment' && (
                  <AdjustmentDialog
                    open={dialog.show}
                    fundingRequests={selectedFundingRequests}
                    lineItemCategories={lineItemCategories}
                    handleExit={closeDialog}
                    adjustForm={adjustForm}
                    getLineItemsByFundingRequest={getLineItemsByFundingRequest}
                  />
                )}
                {dialog.variant === 'funding-request-upload-attachments' && (
                  <UploadAttachmentsModal
                    open={dialog.show}
                    fundingRequestId={get(dialog, 'data.id')}
                  />
                )}
                {dialog.variant === 'invoice-confirm-negative-margin' && (
                  <ConfirmDialog
                    open={dialog.show}
                    message={
                      <Grid container spacing={16}>
                        <Grid item>
                          <Typography variant="body1">
                            This client has a negative reserve.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            Are you sure you want to apply payment to the
                            selected invoices?
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                    isSubmitting={get(dialog, 'data.isSubmitting', false)}
                    handleConfirm={() => {
                      setConfirmOnSubmitPayment(true);
                      formRef.current.handleSubmit();
                    }}
                    handleExit={() => {
                      dispatch(closeDialog());
                      formRef.current.setSubmitting(false);
                    }}
                  />
                )}
                <PaymentsHistory />
              </React.Fragment>
            )}
          </Formik>
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
};

import { assign, concat } from "lodash";
import {
  FETCH_PURCHASES_FAILURE,
  FETCH_PURCHASES_REQUEST,
  FETCH_PURCHASES_SUCCESS,
  ADD_PURCHASES_SUCCESS
} from "../types";

const fetchReceivables = (state, { type, payload }) => {
  switch (type) {
    case FETCH_PURCHASES_REQUEST:
      return {
        ...state,
        loadingPurchases: true
      };
    case FETCH_PURCHASES_SUCCESS:
      return {
        ...state,
        purchases: assign({}, state.purchases, payload),
        loadingPurchases: false
      };
    case FETCH_PURCHASES_FAILURE:
      return {
        ...state,
        loadingPurchases: false
      };
    case ADD_PURCHASES_SUCCESS:
      return {
        ...state,
        purchases: assign({}, state.purchases, {
          [payload.id]: concat(payload.data, state.purchases[payload.id])
        }),
        loadingPurchases: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchReceivables;

import { Grid, TableSortLabel, Typography } from "@material-ui/core";
import React, { CSSProperties, MouseEventHandler } from "react";

interface Column {
  key: string;
  name: string;
  sort?: boolean | string;
}

interface Props {
  column: Column;
  sortBy: string;
  sortDirection: "desc" | "asc" | undefined;
  columnStyle: CSSProperties;
  handleSort: MouseEventHandler<HTMLDivElement>;
}

const HeaderCell = ({
  column,
  handleSort,
  sortDirection,
  sortBy,
  columnStyle
}: Props): JSX.Element => (
  <Grid container alignItems="center">
    <Grid item>
      {column.sort && handleSort ? (
        <TableSortLabel
          onClick={handleSort}
          direction={sortDirection}
          active={sortBy === column.key || sortBy === column.sort}
        >
          <Typography style={{ color: "#2196f3", ...columnStyle }}>
            {column.name}
          </Typography>
        </TableSortLabel>
      ) : (
        <Typography style={{ color: "#2196f3", ...columnStyle }}>
          {column.name}
        </Typography>
      )}
    </Grid>
  </Grid>
);

export default HeaderCell;

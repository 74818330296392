import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import Can from "../../../../components/Can";
import Steps from "../../../../components/Steps";
import Thanks from "../../../../components/Thanks";
import {
  getBerbixValues,
  requestBerbixToken,
  SaveBerbixRequest
} from "../../../../modules/berbix";
import { fetchAccounts } from "../../../../modules/factoringClient";
import {
  confirmInvite,
  getCurrentStep,
  getInvitePayload,
  getSteps,
  updateInviteSteps,
  getInviteError,
  moveToNextStep
} from "../../../../modules/invited";
import colors from "../../../../theme/colors";
import Berbix from "../../../Invited/components/Berbix";
import Layout from "../../../Invited/components/Layout";
import Welcome from "../../../Invited/components/Welcome";
import BackHomeBtn from "../components/BackHomeBtn";
import Bank from "./Bank";

export default function(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const token = get(params, "token");

  const invitePayload = useSelector(getInvitePayload);
  const inviteError = useSelector(getInviteError);
  const steps = useSelector(getSteps);
  const currentStepValue = useSelector(getCurrentStep);

  const groupName = get(invitePayload, "group_name");
  const showSteps = currentStepValue !== "welcome";
  const isApproved =
    get(invitePayload, "profiles.payment.status") === "approved";
  const factoringCompanies = get(invitePayload, "factoring_companies", []);
  const relationshipId = get(invitePayload, "relationship_id");
  const paymentProfileId = get(invitePayload, "profiles.payment.id");
  const showRmisLinkOnboarding = get(
    invitePayload,
    "show_rmis_link_onboarding",
    false
  );
  const rmisUrl = get(invitePayload, "rmis_url", undefined);
  const rmisText = get(invitePayload, "rmis_text", undefined);
  const hideOnboardingHaulPayApp = get(
    invitePayload,
    "hide_onboarding_haulpay_app",
    false
  );
  const useFactoringCompany = get(
    invitePayload,
    "using_factoring_company",
    false
  );

  const nextStep = useCallback(() => dispatch(moveToNextStep()));
  const saveBerbixRequest = useCallback((...args) =>
    dispatch(SaveBerbixRequest(...args))
  );
  const requestBerbixTokenCallback = useCallback(async () =>
    dispatch(requestBerbixToken())
  );

  const confirmInviteCallback = useCallback(
    () => dispatch(confirmInvite(token, true)),
    []
  );

  useEffect(() => {
    try {
      setLoading(true);
      const asyncFunction = async () => {
        const res = await dispatch(confirmInvite(token, false));
        dispatch(updateInviteSteps(get(res, "include_carrier_id_check"), true));
        await dispatch(fetchAccounts(res.id));
      };
      asyncFunction();
    } catch (e) {}
    setTimeout(() => setLoading(false), 2000);
  }, [token]);

  const stepMap = {
    welcome: (
      <Welcome
        inviteError={inviteError}
        nextStep={nextStep}
        disabled={loading}
        groupName={groupName}
      />
    ),
    bank: (
      <Bank
        factoringProfileId={get(invitePayload, "profiles.factoring.id")}
        brokerFactoringId={invitePayload.factoring_id}
        isApproved={isApproved}
        factoringCompanies={factoringCompanies}
        paymentProfileId={paymentProfileId}
        useFactoringCompany={useFactoringCompany}
        relationshipId={relationshipId}
        moveToNextStep={nextStep}
        confirmInviteCallback={confirmInviteCallback}
      />
    ),
    attach: (
      <Berbix
        requestBerbixToken={requestBerbixTokenCallback}
        SaveBerbixRequest={saveBerbixRequest}
        moveToNextStep={nextStep}
        backButton
      />
    ),
    summary: (
      <Thanks
        message={
          <>
            <Typography
              component="p"
              style={{
                fontSize: "1.5em",
                lineHeight: 1.28,
                fontWeight: "lighter",
                width: "70%",
                marginBottom: 50
              }}
              align="center"
              gutterBottom
            >
              Thank you for boarding for payments via HaulPay.
            </Typography>
            {showRmisLinkOnboarding && (
              <>
                <Typography
                  component="p"
                  style={{
                    fontSize: "1.5em",
                    lineHeight: 1.28,
                    fontWeight: "lighter",
                    width: "50%",
                    marginBottom: 50,
                    paddingRight: 30,
                    paddingLeft: 30
                  }}
                  align="center"
                  gutterBottom
                >
                  Please click below to finish the broker's enrollment process if you haven't already.
                </Typography>
                <Button
                  style={{
                    backgroundColor: colors.green_light,
                    color: colors.white
                  }}
                  onClick={() =>
                    rmisUrl &&
                    window.open(
                      rmisUrl.match(/^http[s]?:\/\//)
                        ? rmisUrl
                        : `//${rmisUrl}`,
                      "_blank"
                    )
                  }
                  variant="contained"
                >
                  {rmisText || 'Complete Broker Agreement via RMIS'}
                </Button>
                <hr
                  style={{
                    borderTop: `1px solid ${colors.grayBg}`,
                    width: "100%",
                    marginTop: 40,
                    marginBottom: 40
                  }}
                />
              </>
            )}
            {!hideOnboardingHaulPayApp && (
              <Typography
                component="p"
                style={{
                  fontSize: "1.5em",
                  lineHeight: 1.28,
                  fontWeight: "lighter",
                  width: "70%",
                  marginBottom: 50
                }}
                align="center"
                gutterBottom
              >
                You can also use the optional HaulPay mobile app to track and
                manage your payments.
              </Typography>
            )}
          </>
        }
        isInvite={false}
        isBoarding
        showAppBtn={!hideOnboardingHaulPayApp}
        backButton={
          <Grid item style={{ padding: 10 }} container justify="center">
            <BackHomeBtn />
          </Grid>
        }
      />
    )
  };
  const View = () => {
    return loading ? (
      <Grid container alignItems="center" justify="center">
        <CircularProgress color="primary" />
      </Grid>
    ) : (
      <Layout
        isInvited
        noTitle
        extraHeader={
          groupName && (
            <Grid item xs={12} justify="center" alignItems="center">
              <Typography variant="h6" align="center">
                {`For ${groupName}`}
              </Typography>
            </Grid>
          )
        }
      >
        {showSteps && (
          <Steps
            activeStep={currentStepValue}
            steps={steps.slice(1, steps.length)}
          />
        )}
        {stepMap[currentStepValue]}
      </Layout>
    );
  };

  return (
    <Can
      perform="ic-panel-view"
      yes={() => <View {...props} />}
      no={() => <Redirect to="/login" />}
    />
  );
}

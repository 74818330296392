import { createAction } from "redux-starter-kit";
import { get, isEmpty } from "lodash";
import { normalize } from "normalizr";
import {
  CREATE_FOLLOWUPNOTE_FAILURE,
  CREATE_FOLLOWUPNOTE_REQUEST,
  CREATE_FOLLOWUPNOTE_SUCCESS,
  UPDATE_RECEIVABLENOTES_SUCCESS
} from "../types";
import getReceivableIdsFromFundingRequests from "../selectors/getReceivableIdsFromFundingRequests";
import { addEntities } from "../../entities";

const createFollowupNote = (
  debtorId,
  fundingRequestIds,
  note,
  followupDate,
  responseCategory,
  receivableId
) => async (dispatch, getState, { api, schema }) => {
  try {
    const state = getState();
    let ids = fundingRequestIds;
    if (ids.length === 0) {
      ids = [get(state, "ui.dialog.data.fundingRequestId")];
    }
    dispatch(createAction(CREATE_FOLLOWUPNOTE_REQUEST)({}));
    // await api.factoring.createReceivableFollowup(
    //   debtorId,
    //   ids,
    //   followupDate,
    //   responseCategory
    // );
    const receivableIds = getReceivableIdsFromFundingRequests(state)(ids);
    let p = [];
    if (!receivableId) {
      p = receivableIds.map(id => {
        return api.factoring.createReceivableNote(
          id,
          note,
          followupDate,
          responseCategory
        );
      });
    } else {
      p.push(
        api.factoring.createReceivableNote(
          receivableId,
          note,
          followupDate,
          responseCategory
        )
      );
    }

    await Promise.all(p).then(response => {
      if (isEmpty(response)) return;
      const data = normalize(response[0].receivable_data, schema.receivable);
      const dataNotes = normalize([response[0]], [schema.notes]);
      dispatch(addEntities(data.entities));
      dispatch(addEntities(dataNotes.entities));
      dispatch(
        createAction(UPDATE_RECEIVABLENOTES_SUCCESS)({
          id: response[0].receivable,
          data: dataNotes.result
        })
      );
    });
    dispatch(createAction(CREATE_FOLLOWUPNOTE_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(CREATE_FOLLOWUPNOTE_FAILURE)(err));
    throw err;
  }
};

export default createFollowupNote;

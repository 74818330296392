import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { transactionNote } from "../../../api/schema";

export default fundingRequestId =>
  memoize(state => {
    try {
      const transactionNotes = get(
        state,
        `factoring.transactionNotes.${fundingRequestId}`,
        []
      );

      const denormalized = denormalize(
        { transactionNotes },
        { transactionNotes: [transactionNote] },
        state.entities
      );

      return denormalized.transactionNotes.map(note => ({
        ...note,
        posted_by_id: get(note, "posted_by.id", ""),
        posted_by_name: get(note, "posted_by.name", "")
      }));
    } catch (err) {
      return [];
    }
  });

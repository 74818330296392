import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import { get, head, reverse } from "lodash";
import Table from "../../../../components/Table";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import tabs from "../tabs";
import columns from "./columns";
import {
  queryFundingRequests,
  fundingRequestTableSelectors,
  fundingRequestTableActions,
  getFundingRequests
} from "../../../../modules/groups";
import TransactionStatus from "../../../../components/TransactionStatus";
import InvoiceRow from "../../../../components/InvoiceRow";
import Link from "../../../../components/Link";
import MoreButton from "./components/EditButton";
import formatPennies from "../../../../helpers/format/formatPennies";
import SelfFinanceIcon from "../../../../components/icons/SelfFinanceIcon";

const FundingRequests = (props: any): JSX.Element => {
  const id = get(props, ["match", "params", "id"], "");
  const dispatch = useDispatch();
  useEffect((): void => {
    dispatch(queryFundingRequests(id, 20, 0, "-created", {}));
  }, [id]);
  const fundingRequests = useSelector(getFundingRequests).map(
    (fundingRequest: any) => ({
      ...fundingRequest,
      amount: (
        <Grid container direction="row" alignItems="center">
          {formatPennies(fundingRequest.amount)}
          <SelfFinanceIcon contractType={fundingRequest.contract_type} />
        </Grid>
      ),
      client_business: (
        <Link
          to={`/admin/factorclients/${get(fundingRequest, [
            "factoring",
            "id"
          ])}/profile`}
        >
          {get(fundingRequest, ["factoring", "company_profile", "name"])}
        </Link>
      ),
      debtor: (
        <Link
          to={`/admin/debtors/${get(fundingRequest, ["debtor", "id"])}/profile`}
        >
          {get(fundingRequest, ["debtor", "dba"])}
        </Link>
      ),
      status: (
        <TransactionStatus
          status={fundingRequest.status}
          modified={fundingRequest.modified}
          statusTimeline={fundingRequest.status_timeline}
          newAttachments={get(
            head(reverse(fundingRequest.attachments)),
            "after_issue"
          )}
        />
      ),
      invoice_number: (
        <InvoiceRow
          discountRate={fundingRequest.discount_rate}
          invoiceNumber={fundingRequest.invoice_number}
          paymentMethod={fundingRequest.payment_method}
        />
      ),
      actions: <MoreButton id={fundingRequest.id} />
    })
  );
  return (
    <Can
      perform="admin-groups:view"
      yes={(): JSX.Element => (
        <InternalLayout title="Groups">
          <Grid container direction="column" spacing={16}>
            <Grid item style={{ width: "100%" }}></Grid>
            <Grid item style={{ width: "100%" }}>
              <NavigationTabs tabs={tabs(id)} value={2} />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Paper style={{ padding: "16px" }}>
                <Table
                  columns={columns}
                  rows={fundingRequests}
                  isLoading={useSelector(
                    fundingRequestTableSelectors.getIsLoading
                  )}
                  handleSort={useCallback(
                    (...args: any[]) =>
                      dispatch(fundingRequestTableActions.handleSort(...args)),
                    [dispatch]
                  )}
                  sortDirection={useSelector(
                    fundingRequestTableSelectors.getSortDirection
                  )}
                  sortBy={useSelector(fundingRequestTableSelectors.getSortBy)}
                  count={useSelector(fundingRequestTableSelectors.getCount)}
                  page={useSelector(fundingRequestTableSelectors.getPage)}
                  rowsPerPage={useSelector(
                    fundingRequestTableSelectors.getRowsPerPage
                  )}
                  handleChangePage={useCallback(
                    (...args: any[]) =>
                      dispatch(
                        fundingRequestTableActions.handleChangePage(...args)
                      ),
                    [dispatch]
                  )}
                  rowsPerPageOptions={useSelector(
                    fundingRequestTableSelectors.getRowsPerPageOptions
                  )}
                  handleChangeRowsPerPage={useCallback(
                    (...args: any[]) =>
                      dispatch(
                        fundingRequestTableActions.handleChangeRowsPerPage(...args)
                      ),
                    [dispatch]
                  )}
                  filter
                  filters={useSelector(fundingRequestTableSelectors.getFilters)}
                  handleFilterChange={useCallback(
                    (...args: any[]) =>
                      dispatch(
                        fundingRequestTableActions.handleSearchFilter(...args)
                      ),
                    [dispatch]
                  )}
                  allowEmpty
                />
              </Paper>
            </Grid>
          </Grid>
        </InternalLayout>
      )}
      no={(): JSX.Element => <Redirect to="/" />}
    />
  );
};

export default FundingRequests;

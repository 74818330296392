import React, { ReactNode } from "react";
import { withStyles } from "@material-ui/styles";
import { createStyles, WithStyles } from "@material-ui/core";

const styles = createStyles({
  overlay: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    zIndex: 9999999,
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0, 0.4)",
    overflowX: "hidden",
    transition: "0.5s"
  },
  overlayContent: {
    position: "relative",
    top: "50%",
    width: "100%",
    textAlign: "center"
  }
});

interface Props extends WithStyles<typeof styles> {
  children: ReactNode;
}

const OverlayScreen = ({ children, classes }: Props): JSX.Element => (
  <div className={classes.overlay}>
    <div className={classes.overlayContent}>{children}</div>
  </div>
);

export default withStyles(styles)(OverlayScreen);

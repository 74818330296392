import { get } from "lodash";

const getFiles = (state, type) => {
  let files = get(state, ["upload", "files"], []);
  try {
    if (type === "NOA" || type === "LOR") {
      files = files.filter(file => get(file, "category") === type);
    }
  } catch (e) {
    files = get(state, ["upload", "files"], []);
  }
  return files;
};

export default getFiles;

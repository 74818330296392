import { denormalize } from "normalizr";
import { factorClientStats } from "../../../api/schema";

const getStats = state => id => {
  try {
    const denormalized = denormalize(
      { id },
      { id: factorClientStats },
      state.entities
    );
    return denormalized.id;
  } catch (err) {
    return {};
  }
};

export default getStats;

import React, { useCallback } from "react";
import {
  Button,
  colors,
  DialogActions,
  DialogContent,
  Grid,
  Typography
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Bank from "../../../../components/Bank";
import { DialogTitle, Dialog } from "../../../../components/Dialog";
import UploadFile from "../../../../components/UploadFile";
import {
  getExchangeLoading,
  getExchangePlaidError,
  exchangePlaidToken
} from "../../../../modules/invited";
import { closeDialog } from "../../../../modules/ui";
import { getFiles } from "../../../../modules/upload";

export default function({
  open,
  fetchAccounts,
  handleSubmit,
  paymentProfileId,
  handleConfirm
}) {
  const dispatch = useDispatch();
  const files = useSelector(state => getFiles(state, "payment"));
  const plaidError = useSelector(getExchangePlaidError);

  const handleClose = useCallback(async args => dispatch(closeDialog()));

  const exchangePlaidTokenCallBack = useCallback(async (...args) => {
    await dispatch(exchangePlaidToken(...args));
    handleClose();
    await dispatch(fetchAccounts());
    handleSubmit();
  }, []);

  const plaidIsLoading = useSelector(getExchangeLoading);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle onClose={handleClose}>Add Bank account</DialogTitle>
      <DialogContent>
        <Grid container spacing={16} style={{ padding: 20 }}>
          <Grid item xs={12}>
            <Typography
              component="p"
              align="center"
              style={{
                flexDirection: "row",
                color: colors.danger
              }}
            >
              Please enter your bank information.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Bank
              nextStep={() => window.open("tel:+1886335558")}
              bankNotFoundText="Bank not listed. Please click to call us."
              submitToken={exchangePlaidTokenCallBack}
              loading={plaidIsLoading}
              error={plaidError}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              align="center"
              style={{
                flexDirection: "row",
                color: colors.danger
              }}
            >
              Or add a void check
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <UploadFile
              type="payment"
              requestId={paymentProfileId}
              categoriesProps={["Void Check"]}
              single
            />
          </Grid>
        </Grid>
      </DialogContent>
      {files.length > 0 && (
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleClose();
              handleConfirm();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

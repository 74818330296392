import { assign } from "lodash";
import {
  QUERY_INVITED_CARRIERS_REQUEST,
  QUERY_INVITED_CARRIERS_SUCCESS,
  QUERY_INVITED_CARRIERS_FAILURE
} from "../types";

const sameRequest = (state, payload) => {
  return (
    // state.invitedCarriers.filters === payload.filters &&
    state.invitedCarriers.ordering === payload.ordering
  );
};

export default (
  state = {
    members: {
      loading: false,
      ids: []
    }
  },
  action
) => {
  switch (action.type) {
    case QUERY_INVITED_CARRIERS_REQUEST:
      return {
        ...state,
        invitedCarriers: assign({}, state.invitedCarriers, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          ordering: action.payload.ordering,
          filters: action.payload.filters
        })
      };
    case QUERY_INVITED_CARRIERS_SUCCESS:
      if (sameRequest(state, action.payload)) {
        return {
          invitedCarriers: assign({}, state.invitedCarriers, {
            loading: false,
            ids: action.payload.items,
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous
          })
        };
      }
      return state;
    case QUERY_INVITED_CARRIERS_FAILURE:
      return {
        invitedCarriers: assign({}, state.invitedCarriers, {
          loading: false
        })
      };
    default:
      return state;
  }
};

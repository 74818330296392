import React from 'react'
import tw, { styled } from 'twin.macro'

export interface HeaderProps {
    children: React.ReactNode;
    variant?: 'primary' | 'secondary' | 'default';
    size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
    transform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
    inline?: boolean;
    weight?: 'thin' | 'extralight' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black';
}
const H = styled.p<HeaderProps>`
    ${tw`text-base font-bold capitalize`}
    ${(props: HeaderProps) => props.variant === 'primary' && tw`text-primary`}
    ${(props: HeaderProps) => props.variant === 'secondary' && tw`text-blue-400`}
    ${(props: HeaderProps) => props.variant === 'default' && tw`text-gray-400`}
    ${(props: HeaderProps) => props.size === 'small' && tw`text-sm`}
    ${(props: HeaderProps) => props.size === 'medium' && tw`text-base`}
    ${(props: HeaderProps) => props.size === 'large' && tw`text-lg`}
    ${(props: HeaderProps) => props.size === 'xlarge' && tw`text-xl`}
    ${(props: HeaderProps) => props.size === 'xxlarge' && tw`text-2xl`}
    ${(props: HeaderProps) => props.transform === 'uppercase' && tw`uppercase`}
    ${(props: HeaderProps) => props.transform === 'lowercase' && tw`lowercase`}
    ${(props: HeaderProps) => props.transform === 'capitalize' && tw`capitalize`}
    ${(props: HeaderProps) => props.transform === 'none' && tw`normal-case`}
    ${(props: HeaderProps) => props.inline ? tw`inline` : tw`block`}
    ${(props: HeaderProps) => props.weight === 'thin' && tw`font-thin`}
    ${(props: HeaderProps) => props.weight === 'extralight' && tw`font-extralight`}
    ${(props: HeaderProps) => props.weight === 'light' && tw`font-light`}
    ${(props: HeaderProps) => props.weight === 'normal' && tw`font-normal`}
    ${(props: HeaderProps) => props.weight === 'medium' && tw`font-medium`}
    ${(props: HeaderProps) => props.weight === 'semibold' && tw`font-semibold`}
    ${(props: HeaderProps) => props.weight === 'bold' && tw`font-bold`}
    ${(props: HeaderProps) => props.weight === 'extrabold' && tw`font-extrabold`}
    ${(props: HeaderProps) => props.weight === 'black' && tw`font-black`}
`;
function Header(props: HeaderProps) {
    return (
        <H {...props}>
            {props.children}
        </H>
    )
}

export default Header;
interface Tab {
  label: string;
  route: string;
  value: string;
  badge?: string;
  permission?: string;
}

const tabs = (id: string, numberOfSimilarDebtors: number = 0): Tab[] => [
  {
    label: "Profile",
    route: `/admin/debtors/${id}/profile`,
    value: "profile"
  },
  {
    label: "Receivables",
    route: `/admin/debtors/${id}/receivables`,
    value: "receivables"
  },
  {
    label: "Funding Requests",
    route: `/admin/debtors/${id}/funding_requests`,
    value: "funding_requests",
    permission: "admin-debtor-funding_requests:view"
  },
  {
    label: "Connected Clients",
    route: `/admin/debtors/${id}/connected_clients`,
    value: "connected_clients"
  },
  {
    label: "Similar Debtors",
    route: `/admin/debtors/${id}/similar_debtors`,
    value: "similar_debtors",
    badge:
      numberOfSimilarDebtors !== 0 ? String(numberOfSimilarDebtors) : undefined,
    permission: "admin-debtor-similar_debtors:view"
  }
];

export default tabs;

import { Typography } from "@material-ui/core";
import React from "react";
import colors from "../theme/colors";

interface Props {
  bool: boolean;
}

const FormatBool = ({ bool }: Props): JSX.Element =>
  bool ? (
    <Typography variant="caption" style={{ color: colors.green }}>
      Yes
    </Typography>
  ) : (
    <Typography variant="caption" color="error">
      No
    </Typography>
  );

export default FormatBool;

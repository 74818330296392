import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { debtorRelation } from "../../../api/schema";

export default fundingRequestId =>
  memoize(state => {
    try {
      const relation = get(
        state,
        `factoring.debtorRelations.${fundingRequestId}`,
        {}
      );
      const denormalized = denormalize(
        { relation },
        { relation: debtorRelation },
        state.entities
      );

      return denormalized.relation;
    } catch (err) {
      return {};
    }
  });

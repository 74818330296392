import * as React from "react";
import { Button, ButtonProps } from "antd";

function AntButton(props: ButtonProps) {
    return (
        <Button
            {...props}
            />
    )
}
export default AntButton;
import { createAction } from "redux-starter-kit";
import {
  FETCH_PAYMENTS_TERMS_REQUEST,
  FETCH_PAYMENTS_TERMS_SUCCESS,
  FETCH_PAYMENTS_TERMS_FAILURE
} from "../types";

const fetchPaymentTerms = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_PAYMENTS_TERMS_REQUEST)());
    const response = await api.factoring.getPaymentTerms(id);
    dispatch(createAction(FETCH_PAYMENTS_TERMS_SUCCESS)(response));
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_PAYMENTS_TERMS_FAILURE)(err));
    throw err;
  }
};

export default fetchPaymentTerms;

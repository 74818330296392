import {
  GET_COLLATED_PDF_SUCCESS,
  GET_COLLATED_PDF_REQUEST,
  GET_COLLATED_PDF_FAILURE
} from "../types";

const getCollatedPdf = (state, { type, payload }) => {
  switch (type) {
    case GET_COLLATED_PDF_REQUEST:
      return {
        ...state,
        collatedPdf: {
          loading: true,
          data: {},
          error: null
        }
      };
    case GET_COLLATED_PDF_SUCCESS:
      return {
        ...state,
        collatedPdf: {
          loading: false,
          data: payload,
          error: null
        }
      };
    case GET_COLLATED_PDF_FAILURE:
      return {
        ...state,
        collatedPdf: {
          loading: false,
          data: {},
          error: payload
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default getCollatedPdf;

import React from "react";
import {
  Grid,
  Typography,
  withStyles,
  createStyles,
  WithStyles,
  Theme
} from "@material-ui/core";

import colors from "../theme/colors";
import carrierCategories from "../helpers/categories";
import brokerCategories from "../helpers/brokerCategories";
import UploadFile from "./UploadFile";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    welcomeBtn: {
      borderRadius: 25,
      width: "100vw",
      maxWidth: "200px"
    },
    subHeading: {
      lineHeight: 1.5,
      marginBottom: 30,
      fontWeight: 400,
      color: colors.gray
    },
    margin: {
      margin: theme.spacing.unit
    }
  });

interface Props extends WithStyles<typeof styles> {
  factoringProfileId: string;
  userType: string;
}

const FactoringPendingView = ({
  factoringProfileId,
  classes,
  userType
}: Props): JSX.Element => (
  <Grid
    container
    direction="column"
    alignItems="center"
    alignContent="center"
    style={{
      marginTop: 100
    }}
  >
    <Grid item>
      <Typography align="center" variant="h4" className={classes.subHeading}>
        {"Haul Pay Application Status"}
      </Typography>
    </Grid>
    <Grid item>
      <Typography align="center" variant="h6" className={classes.subHeading}>
        {"Thank you for your application for our Haul Pay Factoring services. We will update your here as well as via email once your application is processed.\n" +
          "\n" +
          "Please allow 1-2 business days to process your application.\n" +
          "\n" +
          "Once you're approved, you'll be able to get paid on your invoices for all loads you move from credit qualified shippers and brokers within only a few hours of confirmed deliveries!"}
      </Typography>
    </Grid>
    <Grid item direction="row">
      <Typography align="center" variant="h4" className={classes.subHeading}>
        {"STATUS:"}
        <Typography
          align="center"
          variant="h4"
          className={classes.subHeading}
          style={{ color: colors.orange }}
        >
          {"PENDING APPROVAL"}
        </Typography>
      </Typography>
    </Grid>
    <Grid item>
      <Typography align="center" variant="h6" className={classes.subHeading}>
        {
          "Once you're approved you will have free access to our load board and our invoice management tools here!"
        }
      </Typography>
    </Grid>
    <Grid item style={{ width: "120%", padding: 40 }}>
      <UploadFile
        isClearFiles
        requestId={factoringProfileId}
        categoriesProps={
          userType === "carrier" ? carrierCategories : brokerCategories
        }
      />
    </Grid>
  </Grid>
);

// @ts-ignore
export default withStyles(styles)(FactoringPendingView);

import { merge } from "lodash";
import {
  FETCH_NOTES_FAILURE,
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_SUCCESS
} from "../types";

const fetchFactorCompanyNotes = (state, { type, payload }) => {
  switch (type) {
    case FETCH_NOTES_REQUEST:
      return {
        ...state,
        loadingFactorCompanyNotes: true
      };
    case FETCH_NOTES_SUCCESS:
      return {
        ...state,
        factorCompanyNotes: merge({}, state.factorCompanyNotes, payload),
        loadingFactorCompanyNotes: false
      };
    case FETCH_NOTES_FAILURE:
      return {
        ...state,
        loadingFactorCompanyNotes: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchFactorCompanyNotes;

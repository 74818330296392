import { compose } from "recompose";
import { connect } from "react-redux";

import { closeModal, openModal } from "../modules/modal";
import ModalContainer from "./ModalContainer";
import { closeDialog } from "../modules/ui";
import { getCurrentUserId } from "../modules/auth";
import { updateUser } from "../modules/user";

const mapStateToProps = state => ({
  modals: state.ui.modal,
  userId: getCurrentUserId(state)
});

const mapDispatchToProps = {
  handleClose: closeModal,
  closeDialog,
  updateUser,
  openModal
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ModalContainer);

import React, { useCallback } from "react";
import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { get, head } from "lodash";
import API from "../../../../../api";
import attachmentCategories from "../../../FundingRequest/attachmentCategories";
import { Attachments } from "../../../../../components/AttachmentsCard";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../components/Dialog";
import { closeDialog, openSnackbar } from "../../../../../modules/ui";
import SpinnerButton from "../../../../../components/buttons/SpinnerButton";

function UploadBulkAttachments({ open, handleClose, selected }) {
  const dispatch = useDispatch();
  return (
    <Dialog open={open} maxWidth="sm" fullWidth id="upload-bulk-attachment">
      <Formik
        initialValues={{
          attachments: []
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            const attachments = get(values, "attachments", []);
            const promise = attachments.map(attachment =>
              API.factoring.bulkAttachmentsSubmit(
                selected,
                attachment.file,
                attachment.category
              )
            );
            await Promise.all(promise);
            dispatch(openSnackbar("success", "Attachments has been uploaded."));
            handleClose();
          } catch (e) {
            dispatch(openSnackbar("error", head(e)));
          }
          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          setFieldTouched
        }) => (
          <>
            <DialogTitle onClose={handleClose} id="dialog-attachment">
              Add Attachments
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Attachments
                  files={[]}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  values={values}
                  multipleCategories
                  categories={attachmentCategories}
                  handleDelete={() => ({})}
                  defaultCategoryValue="Other"
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <SpinnerButton
                variant="outlined"
                size="small"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Submit
              </SpinnerButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
export default UploadBulkAttachments;

import React from 'react';
import { Button, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
} from '../../../../../../../components/Dialog';
import { Formik } from 'formik';
import CustomTextField, { CustomPenniesField } from '../../../../../../../components/inputs/CustomTextField';
import Yup from "../../../../../../../YupValidation";
import formatPennies from "../../../../../../../helpers/format/formatPennies";

interface ReversalDialogProps {
  open: boolean;
  handleClose: () => void;
  ReversalLineItems: Function;
  itemId?: string;
  fundingRequestId?: string;
  openSnackbar: Function;
  amount?: string;
  adjustmentItem?: {
    description?: string;
    amount?: number;
    adjustedBy?: string;
  }
}

function ReversalDialog({
  open,
  handleClose,
  itemId,
  ReversalLineItems,
  fundingRequestId,
  openSnackbar,
  amount,
  adjustmentItem
}: ReversalDialogProps) {
  const viewDialog = !isEmpty(adjustmentItem);
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>{`${viewDialog ? 'Line Item Reversal' :'Confirm Reversal'}`}</DialogTitle>
      <Formik
        validationSchema={() => {
          const schema = {
            amount: Yup.number()
              .typeError('You must enter an amount')
              .max(Number(amount) * 100, 'Amount should not exceed Line Item amount'),
            description: Yup.string()
              .required("This field is required")
          };
          return Yup.object().shape(schema);
        }}
        initialValues={{
          description: adjustmentItem?.description || '',
          amount: Number(adjustmentItem?.amount) /100 || 0,
          adjustedBy: adjustmentItem?.adjustedBy || '',
        }}
        onSubmit={async (fields, { setSubmitting }) => {
          try {
            setSubmitting(true);
            await ReversalLineItems(
              itemId,
              fundingRequestId,
              fields.description,
              fields.amount * 100
            );
            openSnackbar('success', 'Line item successfully reversed.');
          } catch (e) {
            openSnackbar('error', 'Error While Reversing Line Item.');
          }
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          errors,
          touched
        }): JSX.Element => (
          <>
            <DialogContent>
              {
                viewDialog &&
                <CustomTextField
                  fullWidth
                  label="Adjusted by"
                  name="adjustedBy"
                  value={values.adjustedBy}
                  disabled={viewDialog}
                />
              }
              <CustomPenniesField
                fullWidth
                label="Total Amount"
                name="amount"
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                value={values.amount}
                helperText={!viewDialog ? `${formatPennies(Number(amount))} - ${formatPennies(
                  values.amount
                )} = ${formatPennies(Number(amount) - Number(values.amount * 100))},
                Total Amount is the difference between the Amount of the line item and the adjustment amount.` : ''}
                error={Boolean(touched.amount && errors.amount)}
                disabled={viewDialog}
              />
              <CustomTextField
                fullWidth
                label="Reason for reversing (required)"
                name="description"
                multiline
                rows={3}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                error={Boolean(touched.description && errors.description)}
                disabled={viewDialog}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={(): void => handleClose()}>{`${viewDialog ? 'Close' : 'Cancel'}`}</Button>
              {
                !viewDialog && 
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(): void => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
              }
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

export default ReversalDialog;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_FACTORINGNOTES_FAILURE,
  FETCH_FACTORINGNOTES_REQUEST,
  FETCH_FACTORINGNOTES_SUCCESS
} from "../types";

const fetchNotesTimeline = factoringId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_FACTORINGNOTES_REQUEST)());
    const response = await api.factoring.getNotesTimeline(factoringId);

    const notes = await Promise.all(
      response.results.map(async note => {
        if (note.attachments.length > 0) {
          try {
            const att = await api.factoring.getFactoringClientNoteAttachment(
              note.id,
              note.attachments[0]
            );
            return {
              ...note,
              ...att
            };
          } catch (err) {
            return note;
          }
        }
        return note;
      })
    );

    const data = normalize(notes, [schema.notes]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_FACTORINGNOTES_SUCCESS)({
        [factoringId]: {
          results: data.result,
          next: response.next
        }
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_FACTORINGNOTES_FAILURE)(err));
    throw err;
  }
};

export default fetchNotesTimeline;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";

import {
  UPDATE_FACTORINGPROFILE_FAILURE,
  UPDATE_FACTORINGPROFILE_REQUEST,
  UPDATE_FACTORINGPROFILE_SUCCESS
} from "../types";
import { addEntities } from "../../entities";

const updateFactoringProfile = factoringId => (
  dispatch,
  getState,
  { api, schema }
) => async update => {
  try {
    dispatch(createAction(UPDATE_FACTORINGPROFILE_REQUEST)({}));
    const response = await api.factoring.updateProfile(factoringId, update);
    const data = normalize(response, schema.factoringProfile);
    dispatch(addEntities(data.entities));
    dispatch(createAction(UPDATE_FACTORINGPROFILE_SUCCESS)({}));
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_FACTORINGPROFILE_FAILURE)(err));
    throw err;
  }
};

export default updateFactoringProfile;

import { assign } from "lodash";
import {
  FETCH_RECEIVABLES_BY_DEBTORS_FAILURE,
  FETCH_RECEIVABLES_BY_DEBTORS_REQUEST,
  FETCH_RECEIVABLES_BY_DEBTORS_SUCCESS
} from "../types";

export default (
  state = {
    debtors: {
      loading: false,
      ids: [],
      count: 0,
      offset: 0,
      limit: 20,
      ordering: undefined,
      rowsPerPageOptions: [5, 10, 20, 50, 100, 200]
    }
  },
  action
) => {
  const { debtors } = state;
  switch (action.type) {
    case FETCH_RECEIVABLES_BY_DEBTORS_REQUEST:
      return {
        ...state,
        debtors: assign({}, debtors, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          offset: action.payload.offset,
          ordering: action.payload.ordering,
          filters: action.payload.filters
        })
      };
    case FETCH_RECEIVABLES_BY_DEBTORS_SUCCESS:
      return {
        ...state,
        debtors: assign({}, debtors, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count
        })
      };
    case FETCH_RECEIVABLES_BY_DEBTORS_FAILURE:
      return {
        ...state,
        debtors: assign({}, debtors, {
          loading: false
        })
      };
    default:
      return state;
  }
};

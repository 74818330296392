import { createAction } from "redux-starter-kit";
import { deleteRow } from "../../entities";
import { DELETE_TMS_REQUEST } from "../types";

const deleteTMS = id => async (dispatch, getState, { api, schema }) => {
  const response = await api.factoring.tms.deleteTMS(id);
  dispatch(
    createAction(DELETE_TMS_REQUEST)({
      id
    })
  );
  dispatch(deleteRow({ entity: "tms", id }));
  return response;
};

export default deleteTMS;

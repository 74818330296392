import { IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Link } from "react-router-dom";

import colors from "../../../../../theme/colors";

export default ({ id }) => (
  <Tooltip title="View Funding Request">
    <Link to={`/admin/fundingrequest/${id}`}>
      <IconButton style={{ width: "48px", height: "48px" }}>
        <FontAwesomeIcon color={colors.link} icon={faEdit} />
      </IconButton>
    </Link>
  </Tooltip>
);

import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import API from "../../../../api";
import { Dialog, DialogContent } from "../../../../components/Dialog";
import { getEmail } from "../../../../modules/auth";
import { closeDialog } from "../../../../modules/ui";
import Colors from "../../../../theme/colors";

export default function({ open, groupId, handleExit, handleSubmit }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [group, setGroup] = useState({});
  const email = useSelector(getEmail);

  const handleClose = useCallback(async args => {
    dispatch(closeDialog());
    handleExit();
  });

  const handleConfirm = useCallback(
    async args => {
      handleClose();
      handleSubmit(group.token);
    },
    [group]
  );

  useEffect(() => {
    async function asyncFunction() {
      try {
        setLoading(true);
        if (groupId && email) {
          const res = await API.groups.addCarrierToGroup({
            email,
            group_id: groupId
          });
          setGroup(res);
        }
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    }
    asyncFunction();
  }, [groupId, email]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogContent>
        <Grid
          container
          direction="column"
          justify="center"
          alignContent="center"
          alignItems="center"
          spacing="24"
        >
          {loading ? (
            <CircularProgress color="primary" />
          ) : !isEmpty(error) ? (
            <>
              <Grid item>
                <FontAwesomeIcon
                  color={Colors.danger}
                  size="3x"
                  icon={faTimesCircle}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">Invite Not Found!</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleClose();
                  }}
                  size="medium"
                >
                  Close
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <Typography variant="body1">
                  {`${group.group_name} has invited you to join their Haul Pay group. Would you like
                  to go through the boarding process now?`}
                </Typography>
              </Grid>
              <Grid item container justify="center" spacing="24">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      handleClose();
                    }}
                    size="medium"
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => handleConfirm()}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

import React, { useCallback } from "react";
import { FilterList as FilterIcon } from "@material-ui/icons";
import { Link, Redirect } from "react-router-dom";
import { get } from "lodash";
import {
  ExpansionPanelDetails,
  Grid,
  IconButton,
  Paper,
  Typography,
  Tooltip
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {
  faArrowAltCircleDown,
  faHandHoldingUsd
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import ExpansionPanelSummary from "../../../../components/ExpansionPanelSummary";
import Can from "../../../../components/Can";
import ExpandableView from "../../../../components/ExpandableView";
import nestedPurchasesTableColumns from "./nestedColumns";
import columns from "./columns";
import HaulPayStatus from "../../../../components/StatusFormat";
import Table from "../../../../components/Table";
import NavigationTabs from "../../../../components/NavigationTabs";
import CustomExpansionPanel from "../../../../components/ExpansionPanel";
import CustomTextField from "../../../../components/inputs/CustomTextField";
import colors from "../../../../theme/colors";
import API from "../../../../api";
import { openSnackbar } from "../../../../modules/ui";
import SelfFinanceIcon from "../../../../components/icons/SelfFinanceIcon";
import formatPennies from "../../../../helpers/format/formatPennies";

const Filters = ({ handleSearchFilter, filters }) => (
  <CustomExpansionPanel>
    <ExpansionPanelSummary expandIcon={<FilterIcon />} />
    <ExpansionPanelDetails>
      <Grid container spacing={32} style={{ marginRight: 30, marginLeft: 30 }}>
        <Grid item>
          <CustomTextField
            label="Invoice Number"
            name="invoice_number"
            value={filters.invoice_number}
            onChange={handleSearchFilter}
          />
        </Grid>
        <Grid item>
          <CustomTextField
            label="Load Number"
            name="load_number"
            value={filters.user_load_number}
            onChange={handleSearchFilter}
          />
        </Grid>
      </Grid>
    </ExpansionPanelDetails>
  </CustomExpansionPanel>
);

const NestedMoreButton = ({ id }) => (
  <Link to={`/admin/fundingrequest/${id}`}>
    <IconButton
      key="close"
      aria-label="Close"
      style={{ color: colors.green_light }}
    >
      <FontAwesomeIcon size="xs" icon={faHandHoldingUsd} />
    </IconButton>
  </Link>
);

const Purchases = ({
  purchasesTable,
  purchasesActionsTable,
  purchases = [],
  fetchPurchaseItems,
  purchaseItems = [],
  clearPurchaseItems
}) => {
  const dispatch = useDispatch();

  const handleExportReports = useCallback(async purchaseId => {
    try {
      const response = await API.factorCompany.downloadPurchaseReport(
        purchaseId
      );
      const element = document.createElement("a");
      element.href = response.download_url;
      element.download = `export-${purchaseId}.csv`;
      document.body.appendChild(element);
      element.click();
    } catch (e) {
      dispatch(openSnackbar("error", "Error while download"));
    }
  }, []);

  const formattedRows = purchases.map(row => {
    return {
      ...row,
      status: <HaulPayStatus style={{ fontSize: 14 }} status={row.status} />,
      account: (
        <>
          <Typography variant="body1">
            {row.account_number_redacted
              ? `${row.account_payment_method} (${row.account_number_redacted})`
              : ""}
          </Typography>
        </>
      ),
      action: (
        <Tooltip title="Export CSV">
          <IconButton onClick={() => handleExportReports(row.id)}>
            <FontAwesomeIcon
              icon={faArrowAltCircleDown}
              color={colors.green}
              size="sm"
            />
          </IconButton>
        </Tooltip>
      )
    };
  });
  const formattedNestedRows = purchaseItems.map(row => ({
    ...row,
    amount: () => (
      <Grid container direction="row" alignItems="center">
        {formatPennies(row?.amount)}
        <SelfFinanceIcon contractType={row?.contract_type} />
      </Grid>
    ),
    more: <NestedMoreButton id={get(row, "funding_request")} />
  }));
  return (
    <Table
      columns={columns}
      rows={formattedRows}
      isLoading={purchasesTable.isLoading}
      handleSort={purchasesActionsTable().handleSort}
      sortDirection={purchasesTable.sortDirection}
      sortBy={purchasesTable.sortBy}
      count={purchasesTable.count}
      page={purchasesTable.page}
      rowsPerPage={purchasesTable.rowsPerPage}
      handleChangePage={purchasesActionsTable().handleChangePage}
      rowsPerPageOptions={purchasesTable.rowsPerPageOptions}
      handleChangeRowsPerPage={purchasesActionsTable().handleChangeRowsPerPage}
      filter
      filters={purchasesTable.filters}
      handleFilterChange={purchasesActionsTable().handleSearchFilter}
      collapse
      colSpan={11}
      expandableView={id => (
        <ExpandableView
          columns={nestedPurchasesTableColumns}
          fetch={id => fetchPurchaseItems(id, 30)}
          id={id}
          tableKey="purchaseItems"
          clear={clearPurchaseItems}
          data={formattedNestedRows}
          allowEmpty
        />
      )}
    />
  );
};

const styles = {
  root: {
    background: "linear-gradient(45deg, #2EBD00 30%, #4dc527 90%)"
  },
  label: {
    fontFamily: "Avenir-Medium",
    textTransform: "capitalize",
    color: colors.white
  }
};

export default withStyles(styles)(({ tabs, statusIndex, ...props }) => {
  const index = statusIndex === -1 ? 0 : statusIndex;
  return (
    <Can
      perform="admin-finances-purchases:view"
      yes={() => (
        <InternalLayout title="Finances">
          <React.Fragment>
            <div style={{ margin: 10 }}>
              <Typography variant="h5">All Purchases</Typography>
            </div>
            <NavigationTabs tabs={tabs} value={index} />
            <Paper style={{ marginTop: 32 }}>
              <Filters
                filters={props.purchasesTable.filters}
                handleSearchFilter={
                  props.purchasesActionsTable().handleSearchFilter
                }
              />
              <Purchases {...props} />
            </Paper>
          </React.Fragment>
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
});

import {
  UPLOAD_USER_FILE_FAILURE,
  UPLOAD_USER_FILE_REQUEST,
  UPLOAD_USER_FILE_SUCCESS
} from "../types";

const uploadFile = (state, { type, payload }) => {
  switch (type) {
    case UPLOAD_USER_FILE_REQUEST:
      return {
        ...state,
        documents: {
          isUploading: true,
          data: {}
        }
      };
    case UPLOAD_USER_FILE_SUCCESS:
      return {
        ...state,
        documents: {
          isUploading: false,
          data: payload
        }
      };
    case UPLOAD_USER_FILE_FAILURE:
      return {
        ...state,
        documents: {
          isUploading: false,
          data: {},
          error: payload
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default uploadFile;

import { createAction } from "redux-starter-kit";
import {
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS
} from "../types";

const deleteAccount = (userId, accountId) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(DELETE_ACCOUNT_REQUEST)());
    const response = await api.account.deleteAccount(userId, accountId);
    dispatch(
      createAction(DELETE_ACCOUNT_SUCCESS)({
        userId,
        accountId
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(DELETE_ACCOUNT_FAILURE)(err));
    throw err;
  }
};

export default deleteAccount;

import { createAction } from "redux-starter-kit";
import {
  DELETE_FACTORINGNOTE_FAILURE,
  DELETE_FACTORINGNOTE_REQUEST,
  DELETE_FACTORINGNOTE_SUCCESS
} from "../types";

const deleteFactoringNote = (factoringId, noteId) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(DELETE_FACTORINGNOTE_REQUEST)());
    const response = await api.factoring.deleteFactoringClientNote(noteId);
    dispatch(
      createAction(DELETE_FACTORINGNOTE_SUCCESS)({
        factoringId,
        noteId
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(DELETE_FACTORINGNOTE_FAILURE)(err));
    throw err;
  }
};

export default deleteFactoringNote;

import React, { useEffect } from 'react';
import { getIn, FieldArray } from 'formik';
import {
    Pennies,
    Select
} from "../../../../../../../components/inputs/BoldInput";
import { Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import colors from '../../../../../../../theme/colors';
import { map } from 'lodash';

const LineItemCategories = [{
    text: 'Freight Charges',
    value: 'Freight Charges',
}, {
    text: 'Line Haul',
    value: 'Line Haul',
},
{
    text: 'Fuel Surcharge',
    value: 'Fuel Surcharge',
}, {
    text: 'Lumper Fee',
    value: 'Lumper Fee (Paid by Client)',
}, {
    text: 'Gate Charge',
    value: 'Gate Charge',
}, {
    text: 'Detention',
    value: 'Detention Charge',
}, {
    text: 'Layover',
    value: 'Layover',
}, {
    text: 'Storage',
    value: 'Storage',
}, {
    text: 'TONU',
    value: 'TONU',
}, {
    text: 'Other',
    value: 'Other',
}];

function LineItemsForm({ values, setFieldValue, handleBlur, handleChange, touched, errors, disabled }) {

    useEffect(() => {
        if (
            values.line_items.length &&
            touched.line_items
        ) {
            let amount = 0;
            map(values.line_items, lineItem => lineItem.amount ? amount += parseInt(lineItem.amount) : 0)
            setFieldValue('shipper_amount', amount);
        }
    }, [values.line_items, touched.line_items, setFieldValue])
    return (<FieldArray
        name="line_items"
        render={arrayHelpers => (
            <Grid item container direction='column' style={{ padding: 10 }} spacing={8}>
                {values.line_items && values.line_items.length > 0 ? (
                    <>
                        <Grid item>
                            {values.line_items.map((line_item, index) => (
                                <Grid item container spacing={8} direction="row" alignItems="center">
                                    <Grid item key={index} xs={8}>
                                        <Pennies
                                            label="Amount"
                                            name={`line_items[${index}].amount`}
                                            value={getIn(values, `line_items[${index}].amount`)}
                                            setFieldValue={setFieldValue}
                                            withStyle
                                            fullWidth
                                            error={getIn(touched, `line_items[${index}].amount`) && !!getIn(errors, `line_items[${index}].amount`)}
                                            helperText={
                                                getIn(touched, `line_items[${index}].amount`) ? getIn(errors, `line_items[${index}].amount`) : ""
                                            }
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select
                                            label="Type"
                                            value={getIn(values, `line_items[${index}].category`)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            name={`line_items[${index}].category`}
                                            selections={LineItemCategories}
                                            error={getIn(touched, `line_items[${index}].category`) && !!getIn(errors, `line_items[${index}].category`)}
                                            helperText={
                                                getIn(touched, `line_items[${index}].category`) ? getIn(errors, `line_items[${index}].category`) : ""
                                            }
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={1} onClick={() => arrayHelpers.remove(index)} style={{ paddingTop: 30 }}>
                                        {!disabled && <FontAwesomeIcon icon={faMinusCircle} size="1x" color={colors.danger} /> }
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item onClick={() => arrayHelpers.push('')}>
                            {!disabled && <Typography variant='body2'><FontAwesomeIcon icon={faPlusCircle} size="1x" color={colors.link} /> Add Line Items</Typography> }
                        </Grid>
                    </>
                ) : (
                        <div onClick={() => arrayHelpers.push({ amount: 0, category: "Line Haul" })}>
                        <Typography variant='body2' ><FontAwesomeIcon icon={faPlusCircle} size="1x" color={colors.link} /> Add Line Items</Typography>
                    </div>
                )}
            </Grid>
        )}
    />)
}

export default LineItemsForm;
import React from "react";
import MaskedInput from "react-text-mask";
import * as PropTypes from "prop-types";

const PhoneInput = ({ inputRef, ...props }) => (
  <MaskedInput
    {...props}
    ref={ref => {
      inputRef(ref ? ref.inputElement : null);
    }}
    guide={false}
    mask={[
      /[1-9]/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ]}
    showMask
  />
);

PhoneInput.propTypes = {
  inputRef: PropTypes.func.isRequired
};

export default PhoneInput;

import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import AutoComplete from "./AutoComplete";
import { getFactorCompany } from "../../modules/factorCompany";
import formatLocation from "../../helpers/format/formatAddress";
import API from "../../api";

const DetailsFactorCompany = ({ value }) => {
  if (!value) return <div />;
  return <Typography variant="caption">{value}</Typography>;
};

const ConnectedBrokerAutoComplete = ({ onNoOptionClick, ...props }) => {
  const loadOptions = useCallback(async name => {
    const res = await API.factoring.getFactoringClients("approved", "", 20, 0, {
      user_type: "broker",
      company_profile__name: name,
      for_payment_profile: props.paymentProfileId
    });
    if (!isEmpty(res.results)) {
      return map(res.results, object => ({
        label: object.company_profile.name,
        ...object
      }));
    }
    return [];
  }, []);

  const Option = ({
    innerRef,
    isFocused,
    isSelected,
    innerProps,
    children,
    data,
    ...props
  }) => (
    <ListItem dense divider selected={isFocused} button buttonRef={innerRef}>
      <ListItemText
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
        {...innerProps}
        primary={data.name}
        secondary={
          <React.Fragment>
            <Typography component="span" color="textSecondary">
              {data.company_profile.name}
            </Typography>
            {data.company_profile.mc && `MC: ${data.company_profile.mc}`}
            {data.company_profile.dot && `DOT: ${data.company_profile.dot}`}
          </React.Fragment>
        }
      />
    </ListItem>
  );

  const Menu = ({ selectProps, innerProps, children }) => (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      <List dense="dense">{children}</List>
    </Paper>
  );

  return (
    <AutoComplete
      loadOptions={name => loadOptions(name)}
      autoCompleteComponents={{ Option, Menu }}
      {...props}
    />
  );
};
export default ConnectedBrokerAutoComplete;

import { get } from 'lodash';

export default value => {
  const applied = {
    client: 'Client',
    organization: 'ComFreight',
    proof: 'Proof',
    debtor: 'debtor',
    client_reserve_negative: "Take out of Client's Reserve",
    client_reserve_positive: "Add to Client's Reserve",
    invited_carrier: 'Invited Carrier'
  };
  return get(applied, [value], '');
};

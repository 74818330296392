import React, { useEffect, useCallback, useState, useRef } from "react";
import reduce from "lodash/reduce";
import concat from "lodash/concat";
import includes from "lodash/includes";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/styles/makeStyles";
import { Formik } from "formik";
import { filter } from "lodash";
import Bank from "../../../../components/Bank";
import {
  getPaymentTerms,
  fetchPaymentTerms,
  updatePaymentTerms,
  getExchangePlaidError,
  exchangePlaidToken,
  getExchangeLoading,
  getUsedPlaid
} from "../../../../modules/invited";
import API from "../../../../api";
import Attach from "../../../Invited/components/Attach";
import groupsAPI from "../../../../api/groups";
import FactorCompany from "../../../Invited/components/FactorCompany";
import { getFiles } from "../../../../modules/upload";
import {
  getAccounts,
  fetchAccounts
} from "../../../../modules/factoringClient";
import { getDialog, openDialog, openSnackbar } from "../../../../modules/ui";
import CreateFactoringCompanyModal from "../components/CreateFactoringCompanyModal";

import BankAccountModal from "../components/BankAccountModal";
import PaymentSpeedModal from "../components/PaymentSpeedModal";
import { getCurrentUserId } from "../../../../modules/auth";
import BackHomeBtn from "../components/BackHomeBtn";
import BankAccountDropDown from "../components/BankAccountDropDown"

const useStyles = makeStyles(() => ({
  plaidBtn: {
    borderRadius: 25,
    width: 250
  }
}));

function ICBank({
  moveToNextStep,
  factoringProfileId,
  brokerFactoringId,
  isApproved,
  factoringCompanies,
  useFactoringCompany,
  paymentProfileId,
  relationshipId,
  confirmInviteCallback
}) {
  const dispatch = useDispatch();
  const isGone = useRef(false);
  const classes = useStyles();
  const [factor, setFactor] = useState(false);
  const [showUploadAttachments, setShowUploadAttachments] = useState(false);
  const [newFactorCompany, setNewFactorCompany] = useState({});
  const [attachmentError, setAttachmentError] = useState("");
  const [paymentSpeedModaldisplayed, setPaymentSpeedModalDisplayed] = useState(
    false
  );
  const [displayNewFactorForm, setDisplayNewFactorForm] = useState();
  const [displayBank, setDisplayBank] = useState(false);

  const exchangePlaidError = useSelector(getExchangePlaidError);
  const exchangePlaidLoading = useSelector(getExchangeLoading);
  const usedPlaid = useSelector(getUsedPlaid);
  const attachments = useSelector(getFiles);
  const dialog = useSelector(getDialog);
  const userId = useSelector(getCurrentUserId);
  const activeAccounts = filter(
    useSelector(getAccounts(userId)),
    o =>
      !get(o, "deleted_by") &&
      !get(o, "deleted_reason") &&
      get(o, "account_user_type") !== "factoring_company"
  );

  const exchangePlaidTokenCallBack = useCallback(
    async (...args) => dispatch(exchangePlaidToken(...args)),
    []
  );

  useEffect(() => {
    if (isGone.current) return;
    async function asyncFetchPaymentTerms() {
      await dispatch(fetchPaymentTerms(brokerFactoringId));
    }
    asyncFetchPaymentTerms();
    return () => {
      isGone.current = true;
    };
  }, [brokerFactoringId]);

  const updatePaymentTermsCallback = useCallback((...args) =>
    dispatch(updatePaymentTerms(...args), [])
  );

  const openPaymentSpeedModal = useCallback(
    args =>
      dispatch(
        openDialog("payment_speed_case", "", "", {
          factoringId: brokerFactoringId
        })
      ),
    []
  );

  const updateFactoringCompanyCallback = useCallback(
    (...args) => groupsAPI.updatePaymentMethod(...args),
    []
  );

  const snackBarMessage = useCallback(
    (...args) => dispatch(openSnackbar(...args)),
    []
  );

  const openBankAccountModal = useCallback(
    args => dispatch(openDialog("add_bank_account", "", "", args)),
    []
  );

  const paymentTerms = useSelector(getPaymentTerms); // [0, 7, 15, 21]

  const payoutTermsOption = [];

  map(
    paymentTerms,
    ({ id, is_default, payout_days, carrier_rate, name, payee }) => {
      const label = carrier_rate
        ? `${name} (${carrier_rate}% Quick Pay Fee)`
        : `${name} (Free)`;
      return payoutTermsOption.push({
        value: id,
        label,
        is_default
      });
    }
  );

  const [paymentSpeed, setPaymentSpeed] = useState(null);

  if (isEmpty(paymentTerms)) {
    return (
      <CircularProgress
        style={{ alignSelf: "center", justifySelf: "center", margin: 30 }}
        color="secondary"
        size={50}
      />
    );
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        paymentSpeed: !useFactoringCompany
          ? (find(paymentTerms, ["payout_days", 0]) || {}).id
          : (find(factoringCompanies, "current") || {}).id
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setDisplayNewFactorForm(false);
          setSubmitting(true);
          if (
            !isEmpty(find(factoringCompanies, ["id", values.paymentSpeed])) ||
            (!isEmpty(newFactorCompany) &&
              newFactorCompany.id === values.paymentSpeed)
          ) {
            if (
              !isEmpty(newFactorCompany) &&
              newFactorCompany.id === values.paymentSpeed
            ) {
              await API.groups.changeFactoringCompany({
                factoring_id: brokerFactoringId,
                factoring_company_id: newFactorCompany.id,
                note: values.notes
              });
            }
            await updateFactoringCompanyCallback(
              values.paymentSpeed,
              relationshipId,
              { factoring_company: values.paymentSpeed }
            );
            moveToNextStep();
            snackBarMessage("success", "Payment Speed has been updated.");
            setSubmitting(false);
            return confirmInviteCallback();
          }
          if (useFactoringCompany && !paymentSpeedModaldisplayed) {
            useFactoringCompany = false;
            return openPaymentSpeedModal();
          }
          if (activeAccounts.length === 0 && isApproved) {
            return openBankAccountModal({
              paymentProfileId,
              paymentSpeed: values.paymentSpeed,
              brokerFactoringId
            });
          }
          await updatePaymentTermsCallback(
            brokerFactoringId,
            values.paymentSpeed,
            {
              is_default: true,
              remove_factoring_company: !!useFactoringCompany
            },
            {
              send_update_email: true
            }
          );
          snackBarMessage("success", "Payment Speed has been updated.");
          setSubmitting(false);
          confirmInviteCallback();
          moveToNextStep();
        } catch (e) {
          snackBarMessage("error", e);
        }
      }}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        handleChange
      }) => {
        return (
          <Grid
            container
            xs={10}
            sm={9}
            md={8}
            direction="column"
            justify="center"
            alignItems="center"
            alignContent="center"
            style={{ margin: "auto", width: "100%" }}
            spacing={16}
          >
            {(() => {
              if (factor) {
                return (
                  <FactorCompany
                    brokerFactoringId={brokerFactoringId}
                    setFactor={setFactor}
                    boarding
                    moveToNextStep={args => {
                      setNewFactorCompany(args);
                      setDisplayNewFactorForm(true);
                      setFactor(false);
                      setFieldValue("paymentSpeed", args.id);
                    }}
                    paymentProfileId={paymentProfileId}
                    backAction={() => setDisplayNewFactorForm(false)}
                    createRelation={false}
                  />
                );
              }
              if (showUploadAttachments) {
                return (
                  <Attach
                    nextStep={() => {
                      const categories = reduce(
                        attachments,
                        (acc, cur) => concat(acc, get(cur, "categories")),
                        []
                      );
                      if (!usedPlaid && !includes(categories, "Void Check")) {
                        setAttachmentError("Please attach Voided Check.");
                      } else {
                        setAttachmentError("");
                        moveToNextStep();
                      }
                    }}
                    error={attachmentError}
                    factoringProfileId={paymentProfileId}
                    usedPlaid={usedPlaid}
                    setShowUploadAttachments={setShowUploadAttachments}
                  />
                );
              }
              return (
                <>
                  <Typography variant="body2" align="center">
                    Please select your default payment option. You can ask the
                    broker to change this later if needed.
                  </Typography>
                  <FormControl style={{ minWidth: 250, marginBottom: 20 }}>
                    <InputLabel id="default_payment">
                      Default Payment
                    </InputLabel>
                    <Select
                      name="paymentSpeed"
                      value={values.paymentSpeed}
                      onChange={event => {
                        if (event.target.value === "newFactoring") {
                          setDisplayNewFactorForm(true);
                          return setFactor(true);
                        }
                        if (event.target.value === "factoring") {
                          return setFactor(true);
                        }
                        setFieldValue("paymentSpeed", event.target.value);
                      }}
                    >
                      {!isEmpty(newFactorCompany) ? (
                        <MenuItem
                          key={newFactorCompany.id}
                          value={newFactorCompany.id}
                          id={newFactorCompany.id}
                        >
                          <em style={{ fontSize: 15, color: "#2196f3" }}>
                            {`${newFactorCompany.name}`}
                          </em>
                        </MenuItem>
                      ) : (
                        ""
                      )}
                      {payoutTermsOption.map(
                        ({ value, label, is_default }, index) => (
                          <MenuItem key={index} value={value} id={index}>
                            <em defaultChecked={is_default}>{label}</em>
                          </MenuItem>
                        )
                      )}
                      {factoringCompanies.map(
                        ({ current, id, name }, index) => (
                          <MenuItem key={index} value={id} id={index}>
                            <em style={{ fontSize: 15, color: "#2196f3" }}>
                              {`${name} ${current ? "- My Factor" : ""}`}
                            </em>
                          </MenuItem>
                        )
                      )}
                      <MenuItem
                        value={
                          factoringCompanies.length > 0
                            ? "factoring"
                            : "newFactoring"
                        }
                        id="factoring"
                      >
                        <p style={{ fontSize: 15, color: "#2196f3" }}>
                          {factoringCompanies.length > 0
                            ? "Change to a new Factor"
                            : "Use my Factoring Company"}
                        </p>
                      </MenuItem>
                    </Select>
                    {displayNewFactorForm && factoringCompanies.length > 0 && (
                      <>
                        <Typography variant="body1" style={{ marginTop: 10 }}>
                          Enter message about new Factor
                        </Typography>
                        <TextField
                          name="notes"
                          variant="outlined"
                          onChange={handleChange}
                          multiline
                          rows={6}
                          style={{ marginTop: 10 }}
                        />
                      </>
                    )}
                  </FormControl>
                  {!isEmpty(
                    find(factoringCompanies, ["id", values.paymentSpeed])
                  ) ||
                  (!isEmpty(newFactorCompany) &&
                    newFactorCompany.id === values.paymentSpeed) ||
                  isApproved ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="medium"
                      className={classes.plaidBtn}
                      onClick={handleSubmit}
                    >
                      Next
                    </Button>
                  ) : (
                    <BankAccountDropDown
                      userId={userId}
                      showUploadAttachments={() =>
                        setShowUploadAttachments(true)
                      }
                      handleSubmit={handleSubmit}
                      nextStep={moveToNextStep}
                      submitToken={exchangePlaidTokenCallBack}
                      loading={exchangePlaidLoading}
                      error={exchangePlaidError}
                    />
                  )}
                </>
              );
            })()}
            <Grid item style={{ paddingTop: 30 }}>
              <BackHomeBtn />
            </Grid>
            {dialog.variant === "create_factoring_company" && (
              <CreateFactoringCompanyModal
                brokerFactoringId={get(dialog, "data.brokerFactoringId")}
                open={dialog.show}
              />
            )}
            {dialog.variant === "add_bank_account" && (
              <BankAccountModal
                open={dialog.show}
                fetchAccounts={() => dispatch(fetchAccounts(userId))}
                handleSubmit={handleSubmit}
                paymentProfileId={get(dialog, "data.paymentProfileId")}
                handleConfirm={() => {
                  updatePaymentTermsCallback(
                    get(dialog, "data.brokerFactoringId"),
                    get(dialog, "data.paymentSpeed"),
                    {
                      is_default: true,
                      remove_factoring_company: !!useFactoringCompany
                    },
                    {
                      send_update_email: true
                    }
                  );
                  confirmInviteCallback();
                }}
              />
            )}
            {dialog.variant === "payment_speed_case" && (
              <PaymentSpeedModal
                open={dialog.show}
                {...get(dialog, "data")}
                handleSubmit={() => {
                  setPaymentSpeedModalDisplayed(true);
                  handleSubmit();
                }}
              />
            )}
          </Grid>
        );
      }}
    </Formik>
  );
}

export default ICBank;

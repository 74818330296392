import { merge } from "lodash";
import {
  UPDATE_NOADATES_REQUEST,
  UPDATE_NOADATES_SUCCESS,
  UPDATE_NOADATES_FAILURE
} from "../types";

const updateNOADates = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_NOADATES_REQUEST:
      return {
        ...state,
        loadingDebtorRelations: true
      };
    case UPDATE_NOADATES_SUCCESS:
      return {
        ...state,
        debtorRelations: merge({}, state.debtorRelations, payload),
        loadingDebtorRelations: false
      };
    case UPDATE_NOADATES_FAILURE:
      return {
        ...state,
        loadingDebtorRelations: false
      };
    default:
      return {
        ...state
      };
  }
};

export default updateNOADates;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  UPDATE_PAYMENT_PROFILE_RELATIONSHIP_REQUEST,
  UPDATE_PAYMENT_PROFILE_RELATIONSHIP_SUCCESS,
  UPDATE_PAYMENT_PROFILE_RELATIONSHIP_FAILURE,
  UPDATE_DEBTOR_RELATIONSHIP_REQUEST,
  UPDATE_DEBTOR_RELATIONSHIP_SUCCESS,
  UPDATE_DEBTOR_RELATIONSHIP_FAILURE
} from "../types";

const updatePaymentProfileRelationship = (groupId, relationship) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(
      createAction(UPDATE_DEBTOR_RELATIONSHIP_REQUEST)({
        groupId
      })
    );
    const response = await api.groups.updateDebtorRelationship(
      groupId,
      relationship
    );
    const data = normalize(response, schema.debtorProfile);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(UPDATE_DEBTOR_RELATIONSHIP_SUCCESS)({
        groupId
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_DEBTOR_RELATIONSHIP_FAILURE)(err));
    throw err;
  }
};

export default updatePaymentProfileRelationship;

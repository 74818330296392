import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, IconButton, MenuItem } from "@material-ui/core";
import map from "lodash/map";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEnvelopeOpenText,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { sendFREmailTemplate } from "../../../../api/factoring";
import {
  fetchFREmailTemplates,
  getEmailTemplates
} from "../../../../modules/factoring";
import CustomTextField from "../../../../components/inputs/CustomTextField";
import colors from "../../../../theme/colors";
import {
  closeDialog,
  openSnackbar,
  getDialog,
  openDialog
} from "../../../../modules/ui";
import ConfirmRecipientEmail from "./ConfirmRecipientEmail";

export default function EmailFRTemplates({ id }) {
  const emailTemplatesMap = {
    close_out_load: "Close Out Load",
    rate_pod_approval: "Rate and POD Approval",
    contact_list_update: "Contact List Update",
    pod_approval: "POD Approval"
  };
  const [emailTemplate, setEmailTemplate] = useState("");
  const [loading, setLoading] = useState(false);
  const [successIcon, setSuccessIcon] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => dispatch(fetchFREmailTemplates(id)), [id]);

  const dialog = useSelector(getDialog);
  const emailTemplates = map(useSelector(getEmailTemplates(id)), template => ({
    label: emailTemplatesMap[template.name],
    value: template.id,
    ...template
  }));

  if (loading) {
    return (
      <IconButton>
        <FontAwesomeIcon
          icon={faSpinner}
          size="sm"
          spin
          color={colors.green}
          style={{ marginLeft: 5 }}
        />
      </IconButton>
    );
  }

  const handleEmailTemplates = useCallback(
    async (emailTemplateId, email, ccEmails) => {
      try {
        await sendFREmailTemplate(emailTemplateId, id, email, ccEmails);
        dispatch(openSnackbar("success", "Email has been sent."));
        dispatch(closeDialog());
      } catch (e) {
        dispatch(openSnackbar("error", "Error while sending email."));
      }
    },
    [dispatch]
  );
  const handleCloseDialog = useCallback(args => dispatch(closeDialog()), []);
  const openConfirmDialog = useCallback(
    (emailTemplatesArray, emailTemplateId) =>
      dispatch(
        openDialog(
          "",
          "",
          "",
          {
            email: "",
            emailTemplate: find(emailTemplatesArray, ["id", emailTemplateId])
          },
          "confirm_fr_email_template"
        )
      ),
    []
  );

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      alignContent="center"
      spacing={16}
    >
      <Grid item xs={9}>
        <CustomTextField
          fullWidth
          select
          label="Emails"
          name="email_template"
          onChange={event => setEmailTemplate(event.target.value)}
          value={emailTemplate}
        >
          {emailTemplates.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </CustomTextField>
      </Grid>
      <Grid item>
        <IconButton
          disabled={isEmpty(emailTemplate)}
          id={id}
          onClick={() => openConfirmDialog(emailTemplates, emailTemplate)}
        >
          {successIcon ? (
            <FontAwesomeIcon color={colors.green} icon={faEnvelope} />
          ) : (
            <FontAwesomeIcon color={colors.danger} icon={faEnvelopeOpenText} />
          )}
        </IconButton>
      </Grid>
      {dialog.action === "confirm_fr_email_template" && (
        <ConfirmRecipientEmail
          {...dialog.data}
          open={dialog.show}
          handleConfirm={(...args) =>
            handleEmailTemplates(emailTemplate, ...args)
          }
          handleExit={handleCloseDialog}
        />
      )}
    </Grid>
  );
}

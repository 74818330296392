import React from "react";
import { Formik } from "formik";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography
} from "@material-ui/core";
import { DateTime } from "luxon";

import Card from "../../../../../../components/Card";
import DateRange from "../../../../../../components/inputs/DateRangePicker";

interface Props {
  submit: (
    id: string,
    body: {
      datetime_start: string;
      datetime_end: string;
      include_chargebacks: boolean;
      file_format: string;
    },
    isBroker: boolean
  ) => {
    download_url: string;
  };
  factoringId: string;
  openSnackbar: (status: string, message: string) => void;
  isBroker?: boolean; 
}

export default ({
  factoringId,
  submit,
  openSnackbar,
  isBroker
}: Props): JSX.Element => (
  <Formik
    initialValues={{
      report_type: "funding_request_report",
      datetime_start: DateTime.local()
        .minus({ months: 6 })
        .startOf("day")
        .toISO(),
      datetime_end: DateTime.local()
        .endOf("day")
        .toISO(),
      include_chargebacks: false,
      file_format: "pdf"
    }}
    onSubmit={async (values, { setSubmitting }): Promise<void> => {
      try {
        await submit(factoringId, values, isBroker || false);
      } catch (err) {
        openSnackbar("error", "There was an error exporting this report");
      } finally {
        setSubmitting(false);
      }
    }}
  >
    {({
      values,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    }): JSX.Element => (
      <Card style={{ overflow: "unset", padding: 8 }}>
        <Grid container direction="column" spacing={16}>
          <Grid item style={{ width: "100%" }}>
            <Typography>Report</Typography>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel>Report Type</InputLabel>
              {!isBroker ? (
                <Select
                  name="report_type"
                  value={values.report_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="funding_request_report">
                    Funding Request Report
                  </MenuItem>
                  <MenuItem value="aging_report">Aging Report</MenuItem>
                  <MenuItem value="buyout_report">Buyout Report</MenuItem>
                  <MenuItem value="reserve_report">Reserve Report</MenuItem>
                </Select>
              ) : (
                <Select
                  name="report_type"
                  value={values.report_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="funding_report">
                    Funding Request Report
                  </MenuItem>
                  <MenuItem value="carrier_funding_report">
                    Carrier Funding Report
                  </MenuItem>
                  <MenuItem value="pending_reserve_report">
                    Pending Chargeback/Giveback
                  </MenuItem>
                  <MenuItem value="broker_funding_report">
                    Broker Funding Report
                  </MenuItem>
                  <MenuItem value="ar_report">
                      AR Report
                  </MenuItem>
                  <MenuItem value="open_ar_report">
                      Open AR Report
                  </MenuItem>
                  <MenuItem value="debtor_aging_by_bucket">
                    Debtor Aging
                  </MenuItem>
                  <MenuItem value="chargeback_giveback_report">
                      Chargeback/Giveback Report
                  </MenuItem>
                  <MenuItem value="self_finance_report">
                      Self-Finance Funding Report
                  </MenuItem>
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Typography variant="caption">Start Date -- End Date</Typography>
            <div style={{ zIndex: 5000 }}>
              <DateRange
                value={[values.datetime_start, values.datetime_end]}
                onChange={(dates: string[]): void => {
                  setFieldValue("datetime_start", dates[0]);
                  setFieldValue("datetime_end", dates[1]);
                }}
              />
            </div>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="caption">
                  Include Previous CB/GB
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.include_chargebacks}
                  onChange={(): void => {
                    setFieldValue(
                      "include_chargebacks",
                      !values.include_chargebacks
                    );
                  }}
                  value="include_chargebacks"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel>File Format</InputLabel>
              <Select
                name="file_format"
                value={values.file_format}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="pdf">PDF</MenuItem>
                <MenuItem value="excel">Excel</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Grid container direction="row-reverse">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(): void => handleSubmit()}
                >
                  Export
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    )}
  </Formik>
);

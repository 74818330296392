import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import { SEND_NOA_FAILURE, SEND_NOA_REQUEST, SEND_NOA_SUCCESS } from "../types";

const sendNOA = (
  debtorId,
  factoringId,
  fundingRequest,
  attachmentFileName
) => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(SEND_NOA_REQUEST)());
    const response = await api.factoring.sendNOA(
      debtorId,
      factoringId,
      fundingRequest,
      attachmentFileName
    );
    const data = normalize(response, schema.debtorRelation);
    dispatch(addEntities(data.entities));
    dispatch(createAction(SEND_NOA_SUCCESS)(response));
    return response;
  } catch (err) {
    dispatch(createAction(SEND_NOA_FAILURE)(err));
    throw err;
  }
};

export default sendNOA;

const fetchFreightClasses = () => async (_, __, { api }) => {
  const response = await api.factoring.getFreightClasses();
  const keys = Object.keys(response);
  const values = Object.values(response);

  let classes = [];

  for (let i = 0; i < keys.length; i++) {
    classes.push({
      ...values[i],
      id: keys[i]
    });
  }

  return classes;
};

export default fetchFreightClasses;

import { normalize } from "normalizr";
import { addEntities } from "../../entities";

const addTMS = tms => async (dispatch, _, { api, schema }) => {
  const response = await api.factoring.tms.updateTMS(tms);
  const data = normalize([response], [schema.tms]);
  dispatch(addEntities(data.entities));
  return response;
};

export default addTMS;

import React from "react";
import { FormControl, Grid, Typography } from "@material-ui/core";
import { Formik } from "formik";

import Yup from "../../../../../../YupValidation";
import SpinnerButton from "../../../../../../components/buttons/SpinnerButton";
import BoldInput, {
    Pennies,
    Select
} from '../../../../../../components/inputs/BoldInput';
import { openDialog, openSnackbar, getDialog, closeDialog } from '../../../../../../modules/ui';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../../../../api/factoring/contracts';
import LineItemsCategoryInput from '../../../../../../components/inputs/LineItemsCategoryInput';
import AdditionalPurchaseFeeModal from '../Components/AdditionalPurchaseFeeModal';

interface ILineItemBuilderForm {
    contractId: string;
    hasPurchase: boolean;
}

const fromToOptions = [
    {
        value: "broker",
        text: "Broker"
    },
    {
        value: "carrier",
        text: "Carrier"
    },
    // {
    //     value: "debtor",
    //     text: "Debtor"
    // },
    {
        value: "haulpay",
        text: "Haulpay"
    }
];

function LineItemBuilderForm({ contractId, hasPurchase }: ILineItemBuilderForm) {

    const dispatch = useDispatch();
    const openPurchaseFeeModal = () => dispatch(openDialog("additional-purchase-fee-modal", "Additional Purchase", ""))
    const dialog = useSelector(getDialog)
    return (
        <Formik
            validationSchema={() =>
                Yup.object().shape({
                    rate: Yup.number()
                        .moreThan(0, "Amount must be greater than $0")
                        .required("Amount is a required field"),
                    from_party: Yup.string()
                        .required("This field is required"),
                    to_party: Yup.string()
                        .required("This field is required")
                        .notOneOf([Yup.ref('from_party')], 'From and To must not be the same'),
                    name: Yup.string()
                        .required("This field is required")
                })
            }
            initialValues={{
                from_party: "",
                to_party: "",
                name: "",
                rate: 0,
                notes: "",
                charge_fee_to: "haulpay",
            }}
            onSubmit={async (fields, { setSubmitting, resetForm }) => {
                try {
                    await API.createContractLineItems(contractId, fields)
                    dispatch(openSnackbar("success", "Line Item created!"));
                } catch(err) {
                    dispatch(openSnackbar("error", err.message || err));
                } finally {
                    setSubmitting(false);
                    resetForm();
                }
            }}
        >
            {({ errors, values, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting, setTouched }): JSX.Element => (
                <Grid container direction="row" style={{ width: "100%" }} spacing={16}>
                    <Grid item style={{ width: "100%" }}>
                        <Typography variant="h6">Line Item Builder</Typography>
                    </Grid>
                    <Grid item style={{ width: "25%" }}>
                        <FormControl fullWidth>
                            <Select
                                name="from_party"
                                label="From"
                                value={values.from_party}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                selections={fromToOptions}
                                displayEmpty
                                error={!!errors.from_party}
                                helperText={errors.from_party}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item style={{ width: "25%" }}>
                        <FormControl fullWidth>
                            <Select
                                name="to_party"
                                label="To"
                                value={values.to_party}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                displayEmpty
                                selections={fromToOptions}
                                error={!!errors.to_party}
                                helperText={errors.to_party}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item style={{ width: "25%" }}>
                        <FormControl fullWidth>
                            <LineItemsCategoryInput
                                name="name"
                                label="Category"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                displayEmpty
                                error={!!errors.name}
                                helperText={errors.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item style={{ width: "25%" }}>
                        <Pennies
                            label="Amount"
                            fullWidth
                            name="rate"
                            setFieldValue={setFieldValue}
                            value={values.rate}
                            onBlur={handleBlur}
                            error={!!errors.rate}
                            helperText={errors.rate}
                        />
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                        <BoldInput
                            name="notes"
                            label="Description"
                            multiline
                            rows={3}
                            value={values.notes}
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item container style={{ width: "100%" }} alignItems="flex-end" justify="flex-end">
                        <SpinnerButton
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={(): ReturnType<(<T>() => T)>=> {
                                if (hasPurchase) {
                                    return openPurchaseFeeModal();
                                }
                                return handleSubmit();
                            }}
                            disabled={isSubmitting}
                        >
                            Submit
                        </SpinnerButton>
                    </Grid>
                    {dialog.variant === "additional-purchase-fee-modal" && (
                        <AdditionalPurchaseFeeModal
                          show={dialog.show}
                          handleClose={(): void => {
                            dispatch(closeDialog());
                          }}
                          error={errors.charge_fee_to}
                          value={values.charge_fee_to}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          handleSubmit={(): void => {
                            dispatch(closeDialog());
                            handleSubmit();
                          }}
                        />
                     )}
              </Grid>
            )}
        </Formik>
    )
}

export default LineItemBuilderForm;

import { merge } from "lodash";
import {
  FETCH_INVOICE_REQUEST,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_FAILURE
} from "../types";

const fetchInvoice = (state, { type, payload }) => {
  switch (type) {
    case FETCH_INVOICE_REQUEST:
      return {
        ...state,
        loadingInvoice: true
      };
    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceDocs: merge({}, state.invoiceDocs, payload),
        loadingInvoice: false
      };
    case FETCH_INVOICE_FAILURE:
      return {
        ...state,
        loadingInvoice: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchInvoice;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faColumns, faLink } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Colors from "../../../theme/colors";
import { comftAndroid, comftIos, comftWeb, FbAds, googleAds, HpAndroid, HpIos, HpWeb } from "../../../theme/assets";

interface Props {
  source: string;
  width: number;
  height: number;
}

interface ImgProps {
  src: string;
  alt: string;
  width: number;
  height: number;
}

const Img = ({ src = "", alt = "", width, height }: ImgProps): JSX.Element => (
  <img alt={alt} src={src} width={width} height={height} />
);

const SourceIcon = ({ source = "", width, height }: Props): JSX.Element => {
  const sourceString = String(source);
  // eslint-disable-next-line default-case
  switch (sourceString) {
    case "mobile-haulpay-android":
      return (
        <Img alt="android HP" src={HpAndroid} width={width} height={height} />
      );
    case "mobile-android":
      return (
        <Img
          alt="android COMFT"
          src={comftAndroid}
          width={width}
          height={height}
        />
      );
    case "mobile-haulpay-ios":
      return <Img alt="ios HP" src={HpIos} width={width} height={height} />;
    case "mobile-ios":
      return (
        <Img alt="ios COMFT" src={comftIos} width={width} height={height} />
      );
  }
  if (
    sourceString.toLowerCase().includes("fbtrafficad") ||
    sourceString.toLowerCase().includes("hp_fb_video_ad_landing") ||
    sourceString.toLowerCase().includes("facebook")
  )
    return <Img alt="Fb Ads" src={FbAds} width={width} height={height} />;
  if (sourceString.toLowerCase().includes("google"))
    return (
      <Img alt="web COMFT" src={googleAds} width={width} height={height} />
    );
  if (sourceString.toLowerCase().includes("haulpay.co"))
    return <Img alt="web COMFT" src={HpWeb} width={width} height={height} />;
  if (
    sourceString.toLowerCase().includes("comfreight.com") ||
    sourceString.toLowerCase().includes("direct")
  )
    return <Img alt="web COMFT" src={comftWeb} width={width} height={height} />;
  if (sourceString === "GAloadboard")
    return <FontAwesomeIcon size="1x" icon={faColumns} />;
  return <FontAwesomeIcon size="1x" color={Colors.secondary} icon={faLink} />;
};

export default SourceIcon;

import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import BoldInput from "./inputs/BoldInput";
import { getPlaidUser, fetchPlaidUser } from "../modules/factoringClient";

const PlaidInfo = ({ userId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (userId) {
      dispatch(fetchPlaidUser(userId));
    }
  }, [userId]);
  const {
    name,
    address,
    city,
    state,
    zip,
    phone,
    email,
    bankAccount,
    routingNumber,
    bankName
  } = useSelector(getPlaidUser);
  return (
    <Grid container direction="row" spacing={32}>
      <Grid item xs={4}>
        <BoldInput disabled fullWidth label="Plaid Name" value={name} />
        <BoldInput disabled fullWidth label="Plaid Address" value={address} />
        <BoldInput disabled fullWidth label="Plaid City" value={city} />
      </Grid>
      <Grid item xs={4}>
        <BoldInput disabled fullWidth label="Plaid State" value={state} />
        <BoldInput disabled fullWidth label="Plaid Zip" value={zip} />
        <BoldInput disabled fullWidth label="Plaid Phone" value={phone} />
        <BoldInput disabled fullWidth label="Plaid Email" value={email} />
      </Grid>
      <Grid item xs={4}>
        <BoldInput
          disabled
          fullWidth
          label="Plaid Account"
          value={bankAccount}
        />
        <BoldInput
          disabled
          fullWidth
          label="Plaid Routing Number"
          value={routingNumber}
        />
        <BoldInput
          disabled
          fullWidth
          label="Plaid Bank Name"
          value={bankName}
        />
      </Grid>
    </Grid>
  );
};

export default PlaidInfo;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";

import { addEntities } from "../../entities";

import {
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_SUCCESS
} from "../types";

const fetchAccounts = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_ACCOUNTS_REQUEST)());
    const accounts = await api.user.getAccounts(id);

    const data = normalize(accounts.results, [schema.accounts]);

    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_ACCOUNTS_SUCCESS)({
        [id]: data.result
      })
    );
    return accounts;
  } catch (err) {
    dispatch(createAction(FETCH_ACCOUNTS_FAILURE)(err));
    throw err;
  }
};

export default fetchAccounts;

import {
  CREATE_FACTORING_PROFILE_INVITE_REQUEST,
  CREATE_FACTORING_PROFILE_INVITE_SUCCESS,
  CREATE_FACTORING_PROFILE_INVITE_FAILURE
} from "../types";

const createFactoringProfileInvite = (state, { type, payload }) => {
  switch (type) {
    case CREATE_FACTORING_PROFILE_INVITE_REQUEST:
      return {
        ...state,
        factoringLoading: true
      };
    case CREATE_FACTORING_PROFILE_INVITE_SUCCESS:
      return {
        ...state,
        factoringLoading: false,
        factoringProfileAction: payload,
        email: payload.email
      };
    case CREATE_FACTORING_PROFILE_INVITE_FAILURE:
      return {
        ...state,
        factoringError: payload,
        factoringLoading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default createFactoringProfileInvite;

import { connect } from "react-redux";
import { compose } from "recompose";

import mapState from "../../helpers/mapState";
import { getCollatedPdf, collatedPdf } from "../../modules/factoring";
import Invoice from "./Invoice";

const mapStateToProps = mapState({
  collatedPdf
});

const mapDispatchToProps = {
  getCollatedPdf
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Invoice);

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_STATS_FAILURE,
  FETCH_STATS_REQUEST,
  FETCH_STATS_SUCCESS
} from "../types";

const fetchStats = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_STATS_REQUEST)());
    const response = await api.factoring.getClientStats(id);
    const withId = { id, ...response };
    const data = normalize(withId, schema.factorClientStats);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_STATS_SUCCESS)({
        [id]: data.entities
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_STATS_FAILURE)(err));
    throw err;
  }
};

export default fetchStats;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";

import { addEntities } from "../../entities";
import {
  QUERY_FACTORING_COMPANIES_FAILURE,
  QUERY_FACTORING_COMPANIES_SUCCESS,
  QUERY_FACTORING_COMPANIES_REQUEST
} from "../types";

const handleResponse = (
  response,
  dispatch,
  factoringCompany,
  limit,
  ordering,
  filters
) => {
  const data = normalize(response.results, [factoringCompany]);
  dispatch(addEntities(data.entities));
  dispatch(
    createAction(QUERY_FACTORING_COMPANIES_SUCCESS)({
      items: data.result,
      count: response.count,
      next: response.next,
      previous: response.previous,
      ordering,
      limit,
      filters
    })
  );
  return response;
};

const debounced = debounce(
  async (dispatch, schema, api, limit, ordering, filters) => {
    dispatch(
      createAction(QUERY_FACTORING_COMPANIES_REQUEST)({
        limit,
        filters,
        ordering
      })
    );
    const response = await api.groups.queryFactoringCompanies(
      limit,
      ordering,
      filters
    );
    handleResponse(
      response,
      dispatch,
      schema.factoringCompany,
      limit,
      ordering,
      filters
    );
  },
  1000,
  { trailing: true }
);

// export const next = (link, limit, ordering, filters) => async (
//   dispatch,
//   getState,
//   { schema }
// ) => {
//   try {
//     dispatch(
//       createAction(QUERY_INVITED_CARRIERS_REQUEST)({
//         limit,
//         ordering,
//         filters
//       })
//     );
//
//     const response = await axios.get(link);
//     handleResponse(
//       response.data,
//       dispatch,
//       schema.paymentProfile,
//       limit,
//       ordering,
//       filters
//     );
//   } catch (err) {
//     dispatch(createAction(QUERY_INVITED_CARRIERS_FAILURE)(err));
//     throw err;
//   }
// };

const queryFactoringCompanies = (limit, ordering, filters = {}) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    await debounced(dispatch, schema, api, limit, ordering, filters);
  } catch (err) {
    dispatch(createAction(QUERY_FACTORING_COMPANIES_FAILURE)(err));
    throw err;
  }
};

export default queryFactoringCompanies;

import { withStateHandlers } from "recompose";

const withStatusDropdown = withStateHandlers(
  {
    status: ""
  },
  {
    handleStatusChange: () => event => ({ status: event.target.value })
  }
);

export default withStatusDropdown;

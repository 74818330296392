import Yup from "../../../../YupValidation";

const CreateFactoringSchema = () => {
  const schema = {
    email: Yup.string()
      .email("Please Enter a Valid Email.")
      .required("Please fill out this field"),
    address: Yup.string().required("Please fill out this field"),
    name: Yup.string()
      .min(2, "Too Short First Name.")
      .max(50, "Too Long First Name.")
      .required("Please fill out this field"),
    phone_number: Yup.string()
      .required("Please fill out this field")
      .nullable()
      .phone()
      .checkPhone()
  };

  return Yup.object().shape(schema);
};

export default CreateFactoringSchema;

import {
  FETCH_HISTORY_FAILURE,
  FETCH_HISTORY_REQUEST,
  FETCH_HISTORY_SUCCESS
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_HISTORY_REQUEST:
      return {
        ...state,
        loadingHistory: true
      };
    case FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        loadingHistory: false,
        history: action.payload
      };
    case FETCH_HISTORY_FAILURE:
      return {
        ...state,
        loadingHistory: false
      };
    default:
      return state;
  }
};

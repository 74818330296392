import React from "react";
import { Button, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";

import openFileDialog from "../helpers/openFileDialog";

const UploadCSV = ({ uploadBtnRef = React.createRef(), handleUpload }) => (
  <Grid container direction="row">
    <Button
      variant="outlined"
      color="secondary"
      mini
      onClick={() => {
        openFileDialog(uploadBtnRef);
      }}
    >
      <FontAwesomeIcon icon={faArrowAltCircleUp} style={{ margin: 5 }} />
      CSV
    </Button>
    <input
      type="file"
      name="attachments"
      ref={ref => (uploadBtnRef = ref)}
      style={{ display: "none" }}
      onChange={e => {
        const file = e.currentTarget.files[0];
        handleUpload(file);
      }}
    />
  </Grid>
);

export default UploadCSV;

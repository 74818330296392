import { assign, isEqual } from "lodash";
import {
  ADD_FUNDING_REQUEST_RECEIVABLES_SUCCESS,
  FETCH_FUNDING_REQUEST_RECEIVABLES_FAILURE,
  FETCH_FUNDING_REQUEST_RECEIVABLES_REQUEST,
  FETCH_FUNDING_REQUEST_RECEIVABLES_SUCCESS
} from "../types";

const sameRequest = (state, payload) =>
  state.ordering === payload.ordering &&
  isEqual(state.filters, payload.filters);

export default (
  state = {
    loading: false,
    ids: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_FUNDING_REQUEST_RECEIVABLES_REQUEST:
      return assign({}, state, {
        ids: [],
        loading: true,
        ordering: action.payload.ordering,
        filters: action.payload.filters
      });
    case FETCH_FUNDING_REQUEST_RECEIVABLES_SUCCESS:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        });
      }
      return state;
    case ADD_FUNDING_REQUEST_RECEIVABLES_SUCCESS:
      return {
        ...state,
        ids: [action.payload.items, ...state.ids]
      };
    case FETCH_FUNDING_REQUEST_RECEIVABLES_FAILURE:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          loading: false
        });
      }
      return state;
    default:
      return state;
  }
};

import { createReducer } from "redux-starter-kit";
import * as TYPES from "./types";
import fetchDebtorsReducers from "./reducers/fetchDebtors";
import createDebtorDialogReducers from "./reducers/ui/createDebtorDialog";
import withTable from "../withTable";
import queryfetchDebtors from "./actions/fetchDebtors";

const INITIAL_STATE = {
  loading: false,
  ids: [],
  count: 0,
  status: undefined,
  createDebtorDialog: {
    show: false
  }
};

const {
  selectors,
  actions,
  reducer: tableReducer,
  middleware: tableMiddleware
} = withTable({
  stateKey: "debtors",
  typeKey: "debtors"
});

const reducer = createReducer(INITIAL_STATE, {
  [TYPES.FETCH_QUERY_DEBTORS_REQUEST]: fetchDebtorsReducers,
  [TYPES.FETCH_QUERY_DEBTORS_SUCCESS]: fetchDebtorsReducers,
  [TYPES.FETCH_QUERY_DEBTORS_FAILURE]: fetchDebtorsReducers,
  [TYPES.OPEN_CREATE_DEBTOR]: createDebtorDialogReducers,
  [TYPES.CLOSE_CREATE_DEBTOR]: createDebtorDialogReducers
});

export default tableReducer(reducer);

export const middleware = tableMiddleware(tableSelectors => store => action => {
  const state = store.getState();
  const sortBy = tableSelectors.getSortBy(state);
  const sortDirection = tableSelectors.getSortDirection(state);
  const offset = tableSelectors.getOffset(state);
  const rowsPerPage = tableSelectors.getRowsPerPage(state);
  const filters = tableSelectors.getFilters(state);

  store.dispatch(
    queryfetchDebtors(
      state.debtors.status,
      sortDirection === "asc" ? sortBy : `-${sortBy}`,
      rowsPerPage,
      offset,
      filters
    )
  );
});

export const {
  getOffset,
  getRowsPerPageOptions,
  getRowsPerPage,
  getCount,
  getSortDirection,
  getSortBy,
  getPage,
  getFilters,
  getIsLoading
} = selectors;

export const {
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter
} = actions;

export { default as fetchDebtors } from "./actions/fetchDebtors";
export { default as getDebtors } from "./selectors/getDebtors";
export { default as createDebtorsCSV } from "./actions/createDebtorsCSV";

export { default as closeCreateDebtor } from "./actions/ui/closeCreateDebtor";
export { default as getCreateDebtor } from "./selectors/ui/getCreateDebtor";
export { default as openCreateDebtor } from "./actions/ui/openCreateDebtor";
export { default as createDebtor } from "./actions/createDebtor";
export { default as needsReviewCount } from "./actions/needsReviewCount";

import axios, { AxiosRequestConfig } from "axios";
import omitEmptyKeys from "../helpers/omitEmptyKeys";
import deleteCommaKeys from "../helpers/deleteCommaKeys";

const getLoginToken = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/user/${id}/access_token/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getAdmins = async (): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/search/admin/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getUserById = async (id: string): Promise<any> => {
  if (!id) {
    throw new TypeError("`getUserById`: No `id` passed");
  }
  const response = await axios(`/admin/user/${id}/`);
  return response.data;
};

const fetchCapitalManagement = async (
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/capital/draws/`,
    method: "GET",
    params: {
      ordering,
      offset,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const fetchStats = async (data: Record<string, any>): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/stats/`,
    method: "GET",
    params: {
      ...data
    }
  };
  const response = await axios(options);
  return response.data;
};

export default {
  getLoginToken,
  getAdmins,
  getUserById,
  fetchCapitalManagement,
  fetchStats
};

import {
  UPDATE_TRANSACTIONNOTE_REQUEST,
  UPDATE_TRANSACTIONNOTE_SUCCESS,
  UPDATE_TRANSACTIONNOTE_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case UPDATE_TRANSACTIONNOTE_REQUEST:
      return {
        ...state,
        loadingTransactionNotes: true
      };
    case UPDATE_TRANSACTIONNOTE_SUCCESS:
      return {
        ...state,
        loadingTransactionNotes: false
      };
    case UPDATE_TRANSACTIONNOTE_FAILURE:
      return {
        ...state,
        loadingTransactionNotes: false
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography
} from "@material-ui/core";
import { filter, get, isEmpty } from "lodash";
import { useDispatch } from "react-redux";

import Steps from "../../../../../../../components/Steps";
import DebtorInput from "../../../../../../../components/inputs/DebtorInput";
import ConnectedCarrierInput from "../../../../../../../components/inputs/ConnectedCarrierInput";
import BoldInput, {
  Pennies,
  Select
} from "../../../../../../../components/inputs/BoldInput";
import { fetchTerms } from "../../../../../../../modules/factoringClient";
import LineItemsForm from "./LineItemsForm";
import formatPennies from "../../../../../../../helpers/format/formatPennies";
import FundingRequestTypeSelect from "../../../../../../../components/inputs/FundingRequestTypeSelect";

interface Props {
  disabled?: boolean;
  steps: any[];
  status: string;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: Function;
  handleBlur: Function;
  setFieldTouched: Function;
  errors: any;
  touched: any;
  factoringId: string;
  generateLoadNumbers: boolean;
  allowLineItemizedItem: boolean;
  selfFinanceFrLimit: number;
  selfFinanceFrRemainingBalance: number;
}

const getPaymentMethodType = (paymentProfile:any) => {
  const isCheckEnabled = paymentProfile.enable_pay_via_check && !isEmpty(filter(paymentProfile.accounts, ['method', 'CHECK']));
  if(isCheckEnabled) {
    return 'check'
  }
  if (!isEmpty(filter(paymentProfile.accounts, ['method', 'ACH']))) {
    return 'ach';
  }
  return 'wire';
}

function FundingRequest({
  disabled,
  steps,
  status,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  setFieldTouched,
  errors,
  touched,
  factoringId,
  allowLineItemizedItem,
  selfFinanceFrLimit,
  selfFinanceFrRemainingBalance
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const debtorDeclined =
    get(values, ["debtor", "credit_approved"], "") === "declined";
  const [terms, setTerms] = useState([]);
  const [accounts, setAccounts] = useState<any[]>(get(values, 'carrier.payment_profile.accounts', []));
  const factoringCompany = get(values, 'carrier.factoring_company_relation.factoring_company_name', '');
  const factoringCompanyId = get(values, 'carrier.factoring_company_relation.factoring_company', '');
  useEffect(() => {
    const f = async () => {
      const response = await dispatch(fetchTerms(factoringId));
      // @ts-ignore
      setTerms(response);
    };
    f();
  }, [factoringId, open]);

  return (
    <React.Fragment>
      <div style={{ paddingTop: "16px" }}>
        <Steps steps={steps} activeStep={status} />
      </div>
      <Grid
        container
        direction="column"
        style={{ margin: "auto", padding: 16 }}
        spacing={16}
      >
        <Grid
          item
          container
          justify="flex-end"
          alignContent="flex-end"
          direction="column"
        >
          <FundingRequestTypeSelect
            fullWidth
            value={values.contract_type}
            setFieldValue={setFieldValue}
            name="contract_type"
            label="Funding Request Type"
            withHeader
          />
        </Grid>
        <Grid item>
          <Typography variant="body1" color="error">
            {values.nonFactored &&
              !values.billOutOnly &&
              `Warning: The carrier will be paid when broker is paid when the shipper settles the invoice in the future. `}
            {values.nonFactored &&
              values.billOutOnly &&
              `You will be paid when the shipper pays their invoice. We will not pay the carrier with this option.`}
            {values.self_finance_requested &&
              `You've chosen to pay the carrier using your own funds. When self-financing QuickPay the limit for each funding request is ${formatPennies(selfFinanceFrLimit)}. You can Fund ${formatPennies(selfFinanceFrRemainingBalance)} more worth of funding requests.`}
          </Typography>
        </Grid>
        <Grid item>
          <Pennies
            label="Shipper Invoice Amount"
            name="shipper_amount"
            value={values.shipper_amount}
            setFieldValue={setFieldValue}
            withStyle
            fullWidth
            disabled={allowLineItemizedItem}
            error={touched.shipper_amount && !!errors.shipper_amount}
            helperText={touched.shipper_amount ? errors.shipper_amount : ""}
          />
        </Grid>
        <Grid item>
          {
            allowLineItemizedItem &&
              <LineItemsForm
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                disabled={disabled}
              />
          }
        </Grid>
        {(!values.billOutOnly || !values.nonFactored) && (
          <Grid item>
            <Pennies
              label="Carrier Invoice Amount"
              name="carrier_amount"
              value={values.carrier_amount}
              setFieldValue={setFieldValue}
              withStyle
              fullWidth
              disabled={disabled}
              error={touched.carrier_amount && !!errors.carrier_amount}
              helperText={touched.carrier_amount ? errors.carrier_amount : ""}
            />
          </Grid>
        )}
        <Grid item style={{ width: "100%" }}>
          <DebtorInput
            label="Debtor"
            fullWidth
            name="debtor"
            value={values.debtor}
            setFieldValue={(name: string, value: any): void => {
              setFieldValue("debtor", value);
            }}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            disabled={disabled}
            factoringId={factoringId}
            helperText={
              debtorDeclined &&
              "This debtor is declined you can only submit this as Non-Factored."
            }
            error={debtorDeclined}
          />
        </Grid>
        {(!values.billOutOnly || !values.nonFactored) && (
          <Grid item style={{ width: "100%" }}>
            <ConnectedCarrierInput
              label="Carrier"
              fullWidth
              name="carrier"
              value={values.carrier}
              setFieldValue={(name: string, value: any): void => {
                setFieldValue("carrier", value);
                if(value.payout_days) {
                  setFieldValue("payment_speed", value.payout_days.replace(/[^0-9]/g, ''));
                }
                setFieldValue("broker_portion", value.factoring_fee_split_rate || 0);
                setAccounts(get(value, 'payment_profile.accounts', []));
                setFieldValue("payment_method", get(value, 'factoring_company_relation.factoring_company', '') ? get(value, 'factoring_company_relation.factoring_company', '') : getPaymentMethodType(value.payment_profile));
              }}
              setFieldTouched={setFieldTouched}
              handleBlur={handleBlur}
              disabled={disabled}
              factoringId={factoringId}
            />
          </Grid>
        )}
        <Grid
          container
          justify="space-between"
          item
          style={{ width: "100%" }}
          direction="row"
          spacing={8}
        >
          <Grid item style={{ width: "50%" }}>
            <BoldInput
              label="Load #"
              name="loadNumber"
              value={values.loadNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              disabled
              error={touched.loadNumber && !!errors.loadNumber}
              helperText={touched.loadNumber ? errors.loadNumber : ""}
            />
          </Grid>
          <Grid item style={{ width: "50%" }}>
            <BoldInput
              label="Carrier Pro #"
              name="carrierProNumber"
              value={values.carrierProNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              disabled={disabled}
              error={touched.carrierProNumber && !!errors.carrierProNumber}
              helperText={
                touched.carrierProNumber ? errors.carrierProNumber : ""
              }
            />
          </Grid>
        </Grid>
        <Grid item style={{ width: "100%" }}>
            <Select
              disabled={disabled || !!factoringCompany}
              value={values.payment_method}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              label="Payment Method"
              name="payment_method"
              selections={(() => {
                const paymentProfileAccounts: any[] = (accounts || [])
                .map(({ method, account_number_redacted }) => ({
                  value: method.toLowerCase(),
                  text: `${method} (****${account_number_redacted})`
                }));
                if (factoringCompany) {
                  paymentProfileAccounts.push({
                    value: factoringCompanyId,
                    text: factoringCompany.toUpperCase()
                  });
                }
                return paymentProfileAccounts;
                })()}
            />
          </Grid>
        {!values.nonFactored && (
          <Grid item style={{ width: "100%" }}>
            <Select
              disabled={disabled}
              value={values.payment_speed}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              label="Payment Speed"
              name="payment_speed"
              selections={terms
                .map((term: any) => {
                  if (term.payout_days == "0") {
                    term.payout_days = "1";
                  }
                  return term;
                })
                .map((term: any) => {
                  return {
                    value: term.payout_days,
                    text: `${term.payout_days} Days ${term.discount_rate}%`
                  };
                })}
            />
          </Grid>
        )}
        {!values.nonFactored && (
          <Grid item style={{ width: "100%" }}>
            <Select
              disabled={disabled || !!factoringCompany}
              value={values.broker_portion}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              label="Broker's Portion"
              name="broker_portion"
              selections={[
                {
                  value: 0,
                  text: "0%"
                },
                {
                  value: 50,
                  text: "50%"
                },
                {
                  value: 100,
                  text: "100%"
                }
              ]}
            />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default FundingRequest;

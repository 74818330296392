import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";
import { addEntities } from "../../entities";
import {
  FETCH_QUERY_ADMINS_FAILURE,
  FETCH_QUERY_ADMINS_REQUEST,
  FETCH_QUERY_ADMINS_SUCCESS
} from "../types";

const debounced = debounce(
  async (dispatch, schema, api, status, limit, offset, ordering, filters) => {
    dispatch(
      createAction(FETCH_QUERY_ADMINS_REQUEST)({
        limit,
        offset,
        ordering,
        filters
      })
    );
    const response = await api.admin.getAdmins();
    const data = normalize(response, [schema.admin]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_QUERY_ADMINS_SUCCESS)({
        items: data.result,
        count: response.length,
        limit,
        offset,
        ordering,
        filters
      })
    );
    return response;
  },
  500,
  { trailing: true }
);

export default (ordering, limit, offset, filters = {}) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    await debounced(dispatch, schema, api, limit, offset, ordering, filters);
  } catch (err) {
    dispatch(createAction(FETCH_QUERY_ADMINS_FAILURE)(err));
    throw err;
  }
};

import { assign, isEqual } from "lodash";
import {
  FETCH_PAYMENT_PROFILE_PURCHASES_ERROR,
  FETCH_PAYMENT_PROFILE_PURCHASES_REQUEST,
  FETCH_PAYMENT_PROFILE_PURCHASES_SUCCESS
} from "../types";

const sameRequest = (state, payload) =>
  state.limit === payload.limit && isEqual(state.filters, payload.filters);

export default (
  state = {
    purchases: {
      loading: false,
      ids: []
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_PAYMENT_PROFILE_PURCHASES_REQUEST:
      return {
        ...state,
        purchases: assign({}, state.purchases, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          filters: action.payload.filters,
          id: action.payload.id
        })
      };
    case FETCH_PAYMENT_PROFILE_PURCHASES_SUCCESS:
      if (sameRequest(state.purchases, action.payload)) {
        return {
          ...state,
          purchases: assign({}, state.purchases, {
            loading: false,
            ids: action.payload.items,
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous
          })
        };
      }
      return state;

    case FETCH_PAYMENT_PROFILE_PURCHASES_ERROR:
      if (sameRequest(state.purchases, action.payload)) {
        return {
          ...state,
          purchases: assign({}, state.purchases, {
            loading: false
          })
        };
      }
      return state;
    default:
      return state;
  }
};

import React from "react";
import { List } from "@material-ui/core";
import ListItem from "../../../../../components/ListItem";
import BoldInput from "../../../../../components/inputs/BoldInput";
import Card from "../../../../../components/Card";
import ProfileSection from "../../../../../components/ProfileSection";

export default ({
  contactName,
  email,
  phoneNumber,
  businessName,
  city,
  state,
  zip,
  street_two,
  street_one,
  handleChange
}) => (
  <Card>
    <List dense style={{ width: "100%" }}>
      <ListItem>
        <ProfileSection>Company Info</ProfileSection>
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Contact Name"
          name="contactName"
          value={contactName}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Email"
          name="email"
          value={email}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Phone"
          name="phoneNumber"
          value={phoneNumber}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Legal Business Name"
          name="businessName"
          value={businessName}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Street One"
          name="street_one"
          value={street_one}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Street Two"
          name="street_two"
          value={street_two}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="City"
          name="city"
          value={city}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="State"
          name="state"
          value={state}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Zip"
          name="zip"
          value={zip}
          onChange={handleChange}
        />
      </ListItem>
    </List>
  </Card>
);

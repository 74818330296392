import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import colors from '../theme/colors';

const BackgroundSpinner = (): JSX.Element =>
  <div
    className='cover-spin'
  >
    <div
      style={{
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <FontAwesomeIcon
          icon={faSpinner}
          size="6x"
          spin
          color={colors.grayBg}
          style={{ marginLeft: 5 }}
        />
    </div>
  </div>;

export default BackgroundSpinner;

import { assign } from "lodash";
import {
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_ATTACHMENTS_REQUEST,
  FETCH_ATTACHMENTS_FAILURE
} from "../types";

const fetchAttachments = (state, { type, payload }) => {
  switch (type) {
    case FETCH_ATTACHMENTS_REQUEST:
      return {
        ...state,
        loadingAttachments: true
      };
    case FETCH_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        attachments: assign({}, state.attachments, payload),
        loadingAttachments: false
      };
    case FETCH_ATTACHMENTS_FAILURE:
      return {
        ...state,
        loadingAttachments: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchAttachments;

const categories = [
  {
    text: "Licence",
    value: "driver_license"
  },
  {
    text: "Insurance",
    value: "insurance"
  },
  {
    text: "Void Check",
    value: "voided_check"
  },
  {
    text: "Other",
    value: ""
  }
];

export default categories;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Select, FormControl, InputLabel, Grid, Button } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";
import { get, filter } from "lodash";

import {
    getAccounts,
    fetchAccounts,
    CarrierDefaultBankAccount
} from "../../../../modules/factoringClient";
import Bank from "../../../../components/Bank";
import PlaidLinkComponent from "apps/haulpay-frontend/src/components/PlaidLinkComponent";

const useStyles = makeStyles(() => ({
    plaidBtn: {
        borderRadius: 25,
        width: 250
    }
}));

export default function BankAccountDropDown({ userId, setShowUploadAttachments, handleSubmit, moveToNextStep, exchangePlaidTokenCallBack, exchangePlaidLoading, exchangePlaidError }) {
    const dispatch = useDispatch();
    const activeBankAccounts = filter(
        useSelector(getAccounts(userId)),
        o =>
            !get(o, "deleted_by") &&
            !get(o, "deleted_reason") &&
            get(o, "account_user_type") !== "factoring_company"
    );

    useEffect(() => {
        async function asyncFunction() {
            await dispatch(fetchAccounts(userId));
        }
        if (userId) asyncFunction();
    }, [userId]);

    const [bankAccountId, setBankAccountId] = useState();
    const classes = useStyles();

    return activeBankAccounts.length === 0 ?
        <Bank
            showUploadAttachments={() =>
                setShowUploadAttachments(true)
            }
            handleSubmit={handleSubmit}
            nextStep={moveToNextStep}
            submitToken={exchangePlaidTokenCallBack}
            loading={exchangePlaidLoading}
            error={exchangePlaidError}
        />
        :
        <FormControl style={{ minWidth: 250, marginBottom: 20 }}>
            <InputLabel id="bank_account">
                Bank Accounts
            </InputLabel>
            <Select
                id="bank_account"
                name="bank_accounts"
                value={bankAccountId || activeBankAccounts[0].id}
                style={{ minWidth: 250, maxWidth: 250 }}
                onChange={event => {
                    if (event.target.value) {
                        setBankAccountId(event.target.value);
                    }
                }}
            >
                {activeBankAccounts.map(
                    (
                        {
                            account_number_redacted,
                            method,
                            type,
                            bank_name,
                            id
                        },
                        index
                    ) => (
                        <MenuItem key={index} value={id} id={index}>
                            <em>
                                {`${type} - (XXXX - ${account_number_redacted}) - ${method}`}
                            </em>
                        </MenuItem>
                    ))
                }
                <PlaidLinkComponent submitToken={exchangePlaidTokenCallBack} handleSubmit={handleSubmit}>
                    <MenuItem>
                        <p style={{ fontSize: 17, color: "#2196f3" }}>
                            Add Bank Info
                        </p>
                    </MenuItem>
                </PlaidLinkComponent>
            </Select >
            {
                bankAccountId && (
                    <Grid style={{ paddingTop: 30 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="medium"
                            className={classes.plaidBtn}
                            onClick={() => {
                                dispatch(CarrierDefaultBankAccount(userId, bankAccountId, { default: true }));
                                handleSubmit();
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                )
            }
        </FormControl>
}
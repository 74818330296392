export const EXCHANGE_SIGNUP_PLAID_TOKEN_REQUEST =
  "EXCHANGE_SIGNUP_PLAID_TOKEN_REQUEST";
export const EXCHANGE_SIGNUP_PLAID_TOKEN_SUCCESS =
  "EXCHANGE_SIGNUP_PLAID_TOKEN_SUCCESS";
export const EXCHANGE_SIGNUP_PLAID_TOKEN_FAILURE =
  "EXCHANGE_SIGNUP_PLAID_TOKEN_FAILURE";

export const NEXT_SIGNUP_STEP = "NEXT_SIGNUP_STEP";
export const RESET_SIGNUP_STEPS = "RESET_SIGNUP_STEPS";

export const CREATE_SIGNUP_FACTORING_PROFILE_REQUEST =
  "CREATE_SIGNUP_FACTORING_PROFILE_REQUEST";
export const CREATE_SIGNUP_FACTORING_PROFILE_SUCCESS =
  "CREATE_SIGNUP_FACTORING_PROFILE_SUCCESS";
export const CREATE_SIGNUP_FACTORING_PROFILE_FAILURE =
  "CREATE_SIGNUP_FACTORING_PROFILE_FAILURE";
export const NEXT_STEP_BY_NAME = "NEXT_STEP_BY_NAME";

export const FETCH_FACTORING_PROFILE_REQUEST =
  "FETCH_FACTORING_PROFILE_REQUEST";
export const FETCH_FACTORING_PROFILE_SUCCESS =
  "FETCH_FACTORING_PROFILE_SUCCESS";
export const FETCH_FACTORING_PROFILE_FAILURE =
  "FETCH_FACTORING_PROFILE_FAILURE";

import {
  CREATE_SIGNUP_FACTORING_PROFILE_REQUEST,
  CREATE_SIGNUP_FACTORING_PROFILE_SUCCESS,
  CREATE_SIGNUP_FACTORING_PROFILE_FAILURE
} from "../types";

const signUpFactoringProfile = (state, { type, payload }) => {
  switch (type) {
    case CREATE_SIGNUP_FACTORING_PROFILE_REQUEST:
      return {
        ...state,
        factoringLoading: true
      };
    case CREATE_SIGNUP_FACTORING_PROFILE_SUCCESS:
      return {
        ...state,
        factoringLoading: false,
        factoringProfile: payload
      };
    case CREATE_SIGNUP_FACTORING_PROFILE_FAILURE:
      return {
        ...state,
        factoringError: payload,
        factoringLoading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default signUpFactoringProfile;

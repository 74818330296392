import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  CREATE_WIRE_ACCOUNT_FAILURE,
  CREATE_WIRE_ACCOUNT_REQUEST,
  CREATE_WIRE_ACCOUNT_SUCCESS
} from "../types";

const createWireAccount = (
  accountName,
  accountNumber,
  accountNumberRedacted,
  method,
  routingNumber,
  type,
  user
) => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(CREATE_WIRE_ACCOUNT_REQUEST)());
    const account = await api.vgs.createAccountByWire(
      accountName,
      accountNumber,
      accountNumberRedacted,
      method,
      routingNumber,
      type,
      user
    );

    const data = normalize(account, schema.accounts);
    dispatch(addEntities(data.entities));
    dispatch(createAction(CREATE_WIRE_ACCOUNT_SUCCESS)(account));
    return account;
  } catch (err) {
    dispatch(createAction(CREATE_WIRE_ACCOUNT_FAILURE)(err));
    throw err;
  }
};

export default createWireAccount;

import React from 'react'
import RcTabs, { TabPane, TabsProps as RcTabsProps } from 'rc-tabs';
import tw, { styled } from 'twin.macro'

export interface TabsProps extends RcTabsProps {

}

const StyledTabs = styled(RcTabs)`
  ${tw`
    bg-white
  `}
  .cf-tabs-nav-list {
    ${tw`
      border-b
      border-gray-200
    `}
  }
  .cf-tabs-nav-list {
    ${tw`
      flex 
      flex-row 
      flex-wrap
      select-none
    `}
  }

  .cf-tabs-tab {
    ${tw`
      
      hover:bg-gray-100
      cursor-pointer
      p-2
      -mb-px
      rounded-t-md
    `}
  }

  .cf-tabs-tab-active {
    ${tw`
      bg-white
      hover:bg-white
      border
      border-b-0
      border-b-transparent
      border-gray-200
      cursor-default
      font-semibold
      text-black
    `}
  }

  .cf-tabs-ink-bar {
    display: none;
  }
`;

export const Tabs = (props: TabsProps) => {
  return (
    <StyledTabs
      prefixCls="cf-tabs"
      moreIcon={() => <div></div>}
      {...props}
    />
  );
}

Tabs.TabPane = TabPane;

export default Tabs;

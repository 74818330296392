import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import fetchAll from "../../../helpers/fetchAll";
import { addEntities } from "../../entities";
import {
  FETCH_SIMILARDEBTORS_FAILURE,
  FETCH_SIMILARDEBTORS_REQUEST,
  FETCH_SIMILARDEBTORS_SUCCESS
} from "../types";

const fetchSimilarDebtors = id => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_SIMILARDEBTORS_REQUEST)({}));
    const similar = await fetchAll(() => api.debtors.getSimilarDebtors(id));
    const data = normalize(similar, [schema.debtorProfile]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_SIMILARDEBTORS_SUCCESS)({
        ids: data.result
      })
    );
  } catch (err) {
    dispatch(createAction(FETCH_SIMILARDEBTORS_FAILURE)(err));
    throw err;
  }
};

export default fetchSimilarDebtors;

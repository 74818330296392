import { createAction } from "redux-starter-kit";
import * as TYPES from "../types";

const updateCategories = ({ type = "factoring", ...payload }) => async (
  dispatch,
  getState,
  { api }
) => {
  const { files } = getState().upload;
  const newFiles = files.slice(0);
  files[payload.position].categories = payload.categories;

  const file = files[payload.position];
  let response = {};
  if (type === "factoring") {
    response = await api.factoring.updateAttachmentCategory(
      file.requestId,
      file.file_id,
      payload.categories
    );
  } else if (type === "funding") {
    response = await api.factoring.updateAttachment(
      file.requestId,
      file.file_id,
      { category: payload.categories }
    );
  } else if (type === "CO_BROKER_AGREEMENT_DOCS") {
    response = await api.factoring.paymentProfile.updateFactoringPaymentProfileAttachment(
      file.requestId,
      file.file_id,
      payload.categories
    );
  } else if (type === "CO_BROKER_DEBTOR_AGREEMENT_DOCS") {
    response = await api.factoring.paymentProfile.updateFactoringDebtorAttachment({
        id: file.requestId,
        attachmentId: file.file_id,
        category: payload.categories
    });
  } else {
    response = await api.factoring.updateProfileAttachmentCategory(
      file.requestId,
      file.file_id,
      payload.categories
    );
  }
  dispatch(
    createAction(TYPES.UPDATE_CATEGORIES)({
      ...payload,
      files: newFiles
    })
  );
  return response;
};

export default updateCategories;

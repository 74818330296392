import {
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS
} from "../types";

const updateUser = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        selectedUser: {
          isUpdating: true,
          ...state.selectedUser
        }
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        selectedUser: {
          isUpdating: false,
          ...state.selectedUser
        }
      };
    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        selectedUser: {
          isUpdating: false,
          ...state.selectedUser
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default updateUser;

import React, { MouseEventHandler } from "react";
import { Button, Typography } from "@material-ui/core";

import { DialogActions, DialogTitle, Dialog, DialogContent } from "../../../components/Dialog";

export default ({
    open,
    handleExit,
}) => (
    <Dialog open={open} maxWidth="sm" fullWidth className="confirm_modal">
        <DialogTitle withBorder>Comfreight Text Messaging</DialogTitle>
        <DialogContent>
            <Typography>If enabled, we'll send you a text(SMS) when there are urgent matters that need your attention, such as:</Typography>
            <div style={{ padding: 20 }}>
                <Typography>- There are document issues with a funding request</Typography>
                <Typography>- You are underbid on a bid</Typography>
                <Typography>- And others</Typography>
            </div>
            <Typography>Depending on your activity on our platform, you can expect between 1 text every month to 1 text per day.</Typography>
            <Typography style={{ paddingTop: 20 }}>Charges may apply.</Typography>
        </DialogContent>
        <DialogActions>
            <Button fullWidth variant="contained" color="primary" id="no" onClick={handleExit}>
                ok
            </Button>
        </DialogActions>
    </Dialog>
);

import React, { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Paper, Tab, Tabs, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Link from "../../../../components/Link";

import Badge from "../../../../components/Badge";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import Table from "../../../../components/Table";
import columns from "./columns";
import NavigationTabs from "../../../../components/NavigationTabs";
import tabs from "../tabs";
import AddButton from "../../../../components/buttons/AddButton";

import {
  factoringCompaniesTableSelectors,
  factoringCompaniesTableActions,
  needsReviewCountFactoringCompanies,
  queryFactoringCompanies,
  getFactoringCompanies
} from "../../../../modules/groups";
import AddFactoringCompanyDialog from "./components/AddFactoringCompanyDialog";
import { openDialog } from "../../../../modules/ui";
import MoreButton from "../../../../components/buttons/MoreButton";

function FactoringCompanies() {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [status, setStatus] = useState("approved");
  useEffect(() => {
    dispatch(queryFactoringCompanies(20, "-created", { status }));
  }, [status]);
  const factoringCompanies = useSelector(getFactoringCompanies).map(
    company => ({
      ...company,
      name: (
        <Link to={`/admin/companies/${company.id}/profile`}>
          {company.name}
        </Link>
      ),
      more: (
        <Grid container>
          <Grid item>
            <Tooltip title="View Profile">
              <Link to={`/admin/companies/${company.id}/profile`}>
                <MoreButton />
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      )
    })
  );

  const [badge, setBadge] = useState(10);
  useEffect(() => {
    async function f() {
      const badgeCount = await dispatch(
        needsReviewCountFactoringCompanies("pending")
      );
      // @ts-ignore
      setBadge(badgeCount);
    }
    f();
  }, [status]);
  return (
    <Can
      perform="admin-groups:view"
      yes={() => (
        <InternalLayout title="Groups">
          <NavigationTabs tabs={tabs} value={2} />
          <Paper style={{ marginTop: "32px" }}>
            <Grid container direction="row-reverse">
              <Grid item>
                <AddButton
                  text="Add Factor Company"
                  onClick={() => {
                    dispatch(openDialog("", "", "", {}, "add-factor-company"));
                  }}
                />
              </Grid>
            </Grid>
            <Tabs
              value={tab}
              variant="fullWidth"
              indicatorColor="primary"
              onChange={(event, value) => {
                setTab(value);
                switch (value) {
                  case 0:
                    setStatus("approved");
                    break;
                  case 1:
                    setStatus("pending");
                    break;
                  case 2:
                    setStatus("declined");
                    break;
                  default:
                    setStatus("");
                }
              }}
            >
              <Tab label="Approved" />
              <Tab
                label={
                  badge ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ paddingRight: "6px", flex: "auto" }}>
                        Pending
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: "auto",
                          justifyContent: "flex-end"
                        }}
                      >
                        <Badge count={String(badge)} />
                      </div>
                    </div>
                  ) : (
                    "Pending"
                  )
                }
              />
              <Tab label="Declined" />
              <Tab label="All" />
            </Tabs>
            <div style={{ paddingLeft: 16 }}>
              <Table
                columns={columns}
                rows={factoringCompanies}
                isLoading={useSelector(
                  factoringCompaniesTableSelectors.getIsLoading
                )}
                handleSort={useCallback(
                  (...args) =>
                    dispatch(
                      factoringCompaniesTableActions.handleSort(...args)
                    ),
                  [dispatch]
                )}
                sortDirection={useSelector(
                  factoringCompaniesTableSelectors.getSortDirection
                )}
                sortBy={useSelector(factoringCompaniesTableSelectors.getSortBy)}
                count={useSelector(factoringCompaniesTableSelectors.getCount)}
                page={useSelector(factoringCompaniesTableSelectors.getPage)}
                rowsPerPage={useSelector(
                  factoringCompaniesTableSelectors.getRowsPerPage
                )}
                handleChangePage={useCallback(
                  (...args) =>
                    dispatch(
                      factoringCompaniesTableActions.handleChangePage(...args)
                    ),
                  [dispatch]
                )}
                rowsPerPageOptions={useSelector(
                  factoringCompaniesTableSelectors.getRowsPerPageOptions
                )}
                handleChangeRowsPerPage={useCallback(
                  (...args) =>
                    dispatch(
                      factoringCompaniesTableActions.handleChangeRowsPerPage(
                        ...args
                      )
                    ),
                  [dispatch]
                )}
                filter
                filters={useSelector(
                  factoringCompaniesTableSelectors.getFilters
                )}
                handleFilterChange={useCallback(
                  (...args) =>
                    dispatch(
                      factoringCompaniesTableActions.handleSearchFilter(...args)
                    ),
                  [dispatch]
                )}
                allowEmpty
              />
            </div>
          </Paper>
          <AddFactoringCompanyDialog />
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
}

export default FactoringCompanies;

import { createSelector } from 'redux-starter-kit';
import { get, split } from 'lodash';

import getAddress from '../../../helpers/getAddress';
import formatDate from '../../../helpers/format/formatDate';

export default createSelector(['user.selectedUser.data'], user => {
  return {
    id: user.id,
    password: '',
    contact_status: user.contact_status || '',
    contact_email: user.contact_email || '',
    contact_name: user.contact_name || '',
    email: user.email || '',
    dba: user.dba || '',
    referrer: user.referrer || '',
    address: getAddress({
      street_one: user.address,
      city: user.city,
      state: user.state,
      zip: user.zip,
      country: user.country
    }),
    account_manager: user.account_manager,
    fax_number: get(split(user.fax_number, ';'), '0', ''),
    fax_ext: get(split(user.fax_number, ';'), '1', ''),
    contact_phone: get(split(user.contact_phone, ';'), '0', ''),
    phone_ext: get(split(user.contact_phone, ';'), '1', ''),
    mc: user.mc || '',
    dot: user.dot || '',
    stripe_email: user.stripe_email || '',
    groups: user.groups,
    assigned_admin: user.assigned_admin,
    user_type: user.user_type || '',
    dispatching_interest: user.groups
      ? Boolean(user.groups.find(group => group.name === 'Admin Dispatch'))
      : false,
    created: formatDate(user.created) || '',
    last_login: formatDate(user.last_login) || '',
    is_trialing: user.is_trialing || false,
    is_subscribed: user.is_subscribed || false,
    equipment_types: user.equipment_types || [],
    commodities_shipped: user.commodities_shipped || '',
    source: user.source || '',
    factoring_approved: get(user.factoring, 'factoring_approved', ''),
    cargo_insurance_provider: user.cargo_insurance_provider || '',
    cargo_insurance_value: (user.cargo_insurance_value || 0) / 100,
    avgpay: user.avgpay || 0,
    haulpay_interest: user.haulpay_interest || false,
    credit_approved: user.credit_approved || false,
    hasAppliedFactoring: get(user.factoring, 'id', false),
    attachments: [],
    language: get(user, 'language', ''),
    payment_profile_id: user?.payment_profile_id,
    factoring_id: user?.factoring_id,
    files: user?.files,
    enable_sms_alerts: user?.enable_sms_alerts
  };
});

import { filter, flatMap, identity, join, split, uniq } from "lodash";

export default (str: string): string =>
  join(
    filter(
      uniq(
        flatMap(
          flatMap([str], (s: string): string[] => split(s, " ")),
          (s: string): string[] => split(s, ",")
        )
      ),
      identity
    ),
    ","
  );

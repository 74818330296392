import {
  get,
  head,
  join,
  merge,
  reverse,
  set,
  split,
  tail,
  reduce,
  map,
  isUndefined
} from "lodash";

const refreshMiddleware = (actionTypes, refresh) => store => next => action => {
  const result = next(action);
  if (actionTypes.includes(action.type)) {
    refresh(store)(action);
  }
  return result;
};

const withTable = ({ typeKey, stateKey }) => {
  const UPDATE_SORT = `${typeKey}/UPDATE_SORT`.toUpperCase();
  const UPDATE_LIMIT = `${typeKey}/UPDATE_LIMIT`.toUpperCase();
  const UPDATE_PAGE = `${typeKey}/UPDATE_PAGE`.toUpperCase();
  const UPDATE_SEARCHFILTER = `${typeKey}/UPDATE_SEARCHFILTER`.toUpperCase();

  const types = {
    UPDATE_SORT,
    UPDATE_LIMIT,
    UPDATE_PAGE,
    UPDATE_SEARCHFILTER
  };

  const getOffset = state =>
    get(state, stateKey).page * get(state, stateKey).limit;
  const getRowsPerPageOptions = state =>
    get(state, stateKey).rowsPerPageOptions;
  const getRowsPerPage = state => get(state, stateKey).limit;
  const getCount = state => get(state, stateKey).count;
  const getIsLoading = state => get(state, stateKey).loading;
  const getSortDirection = state =>
    head(split(get(state, `${stateKey}.ordering`, ""), "")) === "-"
      ? "desc"
      : "asc";
  const getSortBy = state => {
    const ordering = get(state, `${stateKey}.ordering`);
    const direction = getSortDirection(state);

    if (direction === "desc") {
      return ordering.substring(1);
    }
    return ordering;
  };
  const getPage = state => get(state, `${stateKey}.page`);
  const getPrevPage = state => get(state, `${stateKey}.prevPage`);
  const getFilters = state => get(state, `${stateKey}.filters`);
  const getLinks = state => ({
    previous: head(
      reverse((get(state, `${stateKey}.previous`, "") || "").split("v1"))
    ),
    next: head(reverse((get(state, `${stateKey}.next`, "") || "").split("v1")))
  });

  const selectors = {
    getOffset,
    getRowsPerPageOptions,
    getRowsPerPage,
    getCount,
    getSortDirection,
    getSortBy,
    getPage,
    getPrevPage,
    getFilters,
    getIsLoading,
    getLinks
  };

  const tableReducer = reducer => {
    let initialState = {};
    const xs = join(tail(split(stateKey, ".")), ".");
    if (xs) {
      initialState[xs] = {
        count: 0,
        offset: 0,
        page: 0,
        prevPage: 0,
        limit: 20,
        next: undefined,
        previous: undefined,
        ordering: undefined,
        rowsPerPageOptions: [5, 10, 20, 50, 100, 200],
        filters: {}
      };
    } else {
      initialState = {
        count: 0,
        offset: 0,
        page: 0,
        prevPage: 0,
        limit: 20,
        next: undefined,
        previous: undefined,
        ordering: undefined,
        rowsPerPageOptions: [5, 10, 20, 50, 100, 200],
        filters: {}
      };
    }

    let path = "";

    if (xs.length !== 0) {
      path += `${xs}.`;
    }

    return (state, action) => {
      switch (action.type) {
        case UPDATE_PAGE:
          const prevPage = get(state, `${path}page`);
          return merge(
            {},
            state,
            merge(
              {},
              set({}, `${path}page`, action.payload.page),
              set({}, `${path}prevPage`, prevPage)
            )
          );
        case UPDATE_SEARCHFILTER:
          if (Array.isArray(action.payload)) {
            const filters = reduce(
              action.payload,
              (obj, param) => {
                obj[param.name] = param.value;
                return obj;
              },
              {}
            );
            return merge({}, state, set({}, `${path}filters`, filters));
          }
          return merge(
            {},
            state,
            set(
              {},
              `${path}filters`,
              set({}, action.payload.name, action.payload.value)
            )
          );
        case UPDATE_SORT:
          return merge({}, state, set({}, `${path}ordering`, action.payload));
        case UPDATE_LIMIT:
          return merge({}, state, set({}, `${path}limit`, action.payload));
        default:
          return merge({}, initialState, reducer(state, action));
      }
    };
  };

  const handleSort = property => (dispatch, getState) => () => {
    const state = getState();
    const sortBy = getSortBy(state);
    const direction = getSortDirection(state);

    if (sortBy === property) {
      const ordering = direction === "asc" ? `-${sortBy}` : sortBy;
      dispatch({
        type: UPDATE_SORT,
        payload: ordering
      });
    } else {
      dispatch({
        type: UPDATE_SORT,
        payload: property
      });
    }
  };

  const handleChangePage = (event, page) => {
    return {
      type: UPDATE_PAGE,
      payload: {
        page
      }
    };
  };

  const handleChangeRowsPerPage = event => ({
    type: UPDATE_LIMIT,
    payload: event.target.value
  });

  const handleSearchFilter = (event, name) => {
    if (Array.isArray(event)) {
      const payload = map(event, object => ({
        name: object.target.name,
        value: object.target.value
      }));
      return {
        type: UPDATE_SEARCHFILTER,
        payload
      };
    }
    if (Array.isArray(get(event, "target.value", ""))) {
      const value = event.target.value.join(",");
      return {
        type: UPDATE_SEARCHFILTER,
        payload: {
          name: name || event.target.name,
          value
        }
      };
    }
    return {
      type: UPDATE_SEARCHFILTER,
      payload: {
        name: event.target.name,
        value: event.target.value
      }
    };
  };

  const actions = {
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearchFilter
  };

  return {
    types,
    reducer: tableReducer,
    selectors,
    actions,
    middleware: refreshTable =>
      refreshMiddleware(
        [UPDATE_SEARCHFILTER, UPDATE_LIMIT, UPDATE_PAGE, UPDATE_SORT],
        refreshTable(selectors)
      )
  };
};

export default withTable;

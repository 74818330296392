import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

const WaitView = (): JSX.Element => (
  <Grid
    container
    xs={12}
    style={{ width: "100%", height: "100vh" }}
    justify="center"
    alignItems="center"
  >
    <CircularProgress />
  </Grid>
);

export default WaitView;

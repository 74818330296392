import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_FACTORINGPROFILEHISTORY_FAILURE,
  FETCH_FACTORINGPROFILEHISTORY_REQUEST,
  FETCH_FACTORINGPROFILEHISTORY_SUCCESS
} from "../types";

const fetchFactoringProfileHistory = factoringId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_FACTORINGPROFILEHISTORY_REQUEST)({}));
    const response = await api.factoring.getProfileHistory(factoringId);
    const data = normalize(
      { id: factoringId, history: response },
      schema.factoringProfileHistory
    );
    dispatch(addEntities(data.entities));
    dispatch(createAction(FETCH_FACTORINGPROFILEHISTORY_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(FETCH_FACTORINGPROFILEHISTORY_FAILURE)(err));
    throw err;
  }
};

export default fetchFactoringProfileHistory;

import Yup from "../../../../../../YupValidation";

const SUPPORTED_FORMATS = [
  "application/csv",
  "application/x-csv",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel"
];
const schema = {
  file: Yup.mixed().test("fileType", "Unsupported File Format", value =>
    SUPPORTED_FORMATS.includes(value.type)
  )
};
export default Yup.object().shape(schema);

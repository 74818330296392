import React, { ReactNode } from "react";
import { InputAdornment, TextField, WithStyles } from "@material-ui/core";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField";
import styles from "./styles";
import formatCurrency from "../../../helpers/format/formatCurrency";
import validNumber from "../../../helpers/validNumber";
import validDecimalNumber from "../../../helpers/validDecimalNumber";

interface Props extends WithStyles<typeof styles> {
  children?: ReactNode;
  componentType?: ReactNode;
}

interface PenniesProps extends WithStyles<typeof styles> {
  children?: ReactNode;
  componentType?: ReactNode;
  setFieldValue: (name: string, value: any) => void;
  name: string;
  disableUnderline?: boolean;
  color?: string;
}

const CustomTextField = (props: Props & TextFieldProps): JSX.Element => {
  const {
    classes,
    children,
    InputProps,
    InputLabelProps,
    ...restProps
  } = props;
  const underlineClasses = classNames({
    [classes.underline]: true
  });
  return (
    // @ts-ignore
    <TextField
      InputLabelProps={{
        classes: {
          focused: classes.cssFocused
          // error: classes.errorLabel
        },
        ...InputLabelProps
      }}
      InputProps={{
        classes: {
          underline: underlineClasses,
          input: classes.input,
          error: classes.errorInput
        },
        ...InputProps
      }}
      {...restProps}
    >
      {children}
    </TextField>
  );
};

export const CustomPenniesField = withStyles(styles)(
  (props: PenniesProps & TextFieldProps): JSX.Element => {
    const {
      classes,
      children,
      InputProps,
      InputLabelProps,
      setFieldValue,
      name,
      color,
      onBlur,
      ...restProps
    } = props;
    const underlineClasses = classNames({
      [classes.underline]: true
    });
    return (
      // @ts-ignore
      <TextField
        InputLabelProps={{
          classes: {
            focused: classes.cssFocused
            // error: classes.errorLabel
          },
          ...InputLabelProps
        }}
        InputProps={{
          classes: {
            underline: underlineClasses,
            input: classes.input,
            error: classes.errorInput
          },
          onBlur: (): void => {
            const value = Number(props.value);
            setFieldValue(name, formatCurrency(value));
            onBlur && onBlur(formatCurrency(value));
          },
          startAdornment: (
            <InputAdornment position="start">
              <div style={{ color }}>$</div>
            </InputAdornment>
          ),
          ...InputProps
        }}
        {...restProps}
        onChange={(event): void => {
          const value = Number(event.target.value);
          if (validNumber(value) || validDecimalNumber(event.target.value)) {
            const str = String(event.target.value);
            if (str.includes(".") && str.split(".")[1].length >= 2) {
              const formattedValue = Number(str).toFixed(2);
              setFieldValue(name, formattedValue);
            } else {
              setFieldValue(name, str);
            }
          }
        }}
      >
        {children}
      </TextField>
    );
  }
);

export default withStyles(styles)(CustomTextField);

import React, { ReactNode, ChangeEvent } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  withStateHandlers,
  compose,
  StateHandlerMap,
  StateHandler
} from "recompose";
import { withStyles } from "@material-ui/core/styles";

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  createStyles,
  WithStyles,
  Typography
} from "@material-ui/core";

import Card from "./Card";

const styles = createStyles({
  expanded: {
    margin: "auto"
  }
});

interface Props extends WithStyles<typeof styles> {
  title?: string;
  children?: ReactNode;
  expanded: boolean;
  actions?: JSX.Element[];
  handleChange: (event: ChangeEvent<{}>, expanded: boolean) => void;
}

const DropdownDrawer = ({
  classes,
  title,
  children,
  expanded,
  handleChange,
  actions = []
}: Props): JSX.Element => (
  <Card>
    <ExpansionPanel
      className={classes.expanded}
      expanded={expanded}
      onChange={handleChange}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div style={{ width: "100%" }}>{children}</div>
      </ExpansionPanelDetails>
      {actions.length > 0 && (
        <div>
          <ExpansionPanelActions>
            {actions.map((action): JSX.Element => action)}
          </ExpansionPanelActions>
        </div>
      )}
    </ExpansionPanel>
  </Card>
);

interface AllProps {
  expanded?: boolean;
  children?: ReactNode;
}

interface State {
  expanded: boolean;
}

interface Handlers extends StateHandlerMap<State> {
  handleChange: StateHandler<State>;
}

interface Outter {
  expanded?: boolean;
  title?: string | JSX.Element;
  children?: ReactNode;
  actions?: JSX.Element[];
}

export default compose<Props, Outter>(
  withStateHandlers<State, Handlers, AllProps>(
    ({ expanded = true }): State => ({
      expanded
    }),
    {
      handleChange: (): ((
        event: ChangeEvent<{}>,
        expanded: boolean
      ) => State) => (event, expanded): State => ({ expanded })
    }
  ),
  withStyles(styles)
  // @ts-ignore
)(DropdownDrawer);

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";
import axios from "axios";
import { addEntities } from "../../entities";
import {
  FETCH_PURCHASES_FAILURE,
  FETCH_PURCHASES_REQUEST,
  FETCH_PURCHASES_SUCCESS
} from "../types";

const debounced = debounce(
  async (dispatch, schema, api, limit, filters) => {
    dispatch(
      createAction(FETCH_PURCHASES_REQUEST)({
        limit,
        filters
      })
    );
    const response = await api.finances.queryFetchPurchases(limit, filters);
    const data = normalize(response.results, [schema.purchasesList]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_PURCHASES_SUCCESS)({
        items: data.result,
        count: response.count,
        next: response.next,
        previous: response.previous,
        limit,
        filters
      })
    );
    return response;
  },
  1000,
  { trailing: true }
);

const fetchPurchases = (limit, filters = {}) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    await debounced(dispatch, schema, api, limit, filters);
  } catch (err) {
    dispatch(createAction(FETCH_PURCHASES_FAILURE)(err));
    throw err;
  }
};

export const next = (link, limit, filters) => async (
  dispatch,
  getState,
  { schema }
) => {
  try {
    dispatch(
      createAction(FETCH_PURCHASES_REQUEST)({
        limit,
        filters
      })
    );

    const response = await axios.get(link);
    const data = normalize(response.data.results, [schema.purchasesList]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_PURCHASES_SUCCESS)({
        items: data.result,
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous,
        limit,
        filters
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_PURCHASES_FAILURE)(err));
    throw err;
  }
};

export default fetchPurchases;

import React, { useCallback, useState } from "react";
import { Button, FormControl, Grid, Typography } from '@material-ui/core';
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";

import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../../components/Dialog";
import { Select } from '../../../../../../components/inputs/BoldInput';

interface IProp{
    show: boolean;
    handleClose: React.ReactEventHandler<{}>;
    handleChange: React.ReactEventHandler<{}>;
    handleBlur: React.ReactEventHandler<{}>;
    handleSubmit: React.ReactEventHandler<{}>;
    value: string;
    error: string | undefined;
}

const fromToOptions = [
    {
        value: "broker",
        text: "Broker"
    },
    {
        value: "carrier",
        text: "Carrier"
    },
    {
        value: "haulpay",
        text: "Haulpay"
    }
];

function AdditionalPurchaseFeeModal({ show, handleClose, handleChange, handleBlur, value, error, handleSubmit }:IProp) {

  return (
    <Dialog
      onClose={handleClose}
      open={show}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle onClose={handleClose}>Purchase</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={16}
          style={{ minHeight: 150 }}
        >
          <Grid item>
            <Typography variant="body1" style={{ paddingBottom: 20 }}>
               This line item will incur an additional charge from the carrier. Which party should be responsible for this fee?
            </Typography>
            <FormControl fullWidth>
                <Select
                    name="charge_fee_to"
                    label="Change Fee To"
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    selections={fromToOptions}
                    displayEmpty
                    error={!!error}
                    helperText={error}
                />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdditionalPurchaseFeeModal;

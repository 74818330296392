import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { clientNote } from "../../../api/schema";

export default factoringId =>
  memoize(state => {
    try {
      const clientNotes = get(
        state,
        `factoring.clientNotes.${factoringId}`,
        []
      );

      const denormalized = denormalize(
        { clientNotes },
        { clientNotes: [clientNote] },
        state.entities
      );

      return denormalized.clientNotes;
    } catch (err) {
      return [];
    }
  });

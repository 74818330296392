import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { receivable as receivableSchema } from "../../../api/schema";

export default fundingRequestId =>
  memoize(state => {
    try {
      const receivable = get(
        state,
        ["factoring", "receivables", fundingRequestId],
        ""
      );

      const denormalized = denormalize(
        { receivable },
        { receivable: receivableSchema },
        state.entities
      );

      return denormalized.receivable;
    } catch (err) {
      return {};
    }
  });

import { OPEN_CREATE_DEBTOR, CLOSE_CREATE_DEBTOR } from "../../types";

export default (state, { type, payload }) => {
  switch (type) {
    case OPEN_CREATE_DEBTOR:
      return {
        ...state,
        createDebtorDialog: {
          show: true
        }
      };
    case CLOSE_CREATE_DEBTOR:
      return {
        ...state,
        createDebtorDialog: {
          show: false
        }
      };
    default:
      return {
        ...state
      };
  }
};

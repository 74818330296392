import { createAction } from "redux-starter-kit";
import {
  DELETE_DEBTORRELATIONHISTORY_FAILURE,
  DELETE_DEBTORRELATIONHISTORY_REQUEST,
  DELETE_DEBTORRELATIONHISTORY_SUCCESS
} from "../types";

const deleteDebtorRelation = (factoringId, debtorId) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(DELETE_DEBTORRELATIONHISTORY_REQUEST)({}));
    await api.debtors.deleteFactoringDebtorRelationship(factoringId, debtorId);
    dispatch(createAction(DELETE_DEBTORRELATIONHISTORY_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(DELETE_DEBTORRELATIONHISTORY_FAILURE)(err));
    throw err;
  }
};

export default deleteDebtorRelation;

import React, { ReactNode } from "react";
import { createStyles, Grid, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Header from "../Header";

const styles = createStyles({
  root: {
    flexGrow: 1
  }
});

interface Props extends WithStyles<typeof styles> {
  children: ReactNode;
  headerProps: HeaderProps;
}
interface HeaderProps {
  noTitle: boolean;
}
const Layout = ({ children, classes, headerProps }: Props): JSX.Element => (
  <Grid
    container
    className={classes.root}
    direction="column"
    alignItems="center"
  >
    <Grid item style={{ height: 150, padding: "2%" }}>
      <Header {...headerProps} />
    </Grid>
    <Grid
      item
      style={{ paddingLeft: "32px", paddingRight: "32px", height: "100%" }}
    >
      {children}
    </Grid>
  </Grid>
);

export default withStyles(styles)(Layout);

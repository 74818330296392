import { createAction } from "redux-starter-kit";
import has from "lodash/has";
import get from "lodash/get";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  CREATE_NOTES_FAILURE,
  CREATE_NOTES_REQUEST,
  CREATE_NOTES_SUCCESS
} from "../types";
import awsImageUpload from "../../../helpers/awsImageUpload";

const createNotes = (paymentProfileId, object) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(
      createAction(CREATE_NOTES_REQUEST)({
        paymentProfileId
      })
    );
    const response = await api.payments.createPaymentProfileNotes(
      paymentProfileId,
      object
    );
    if (object.attachments && object.attachments.name) {
      await awsImageUpload(
        [object.attachments].map(attachment => ({
          file: attachment,
          upload_url: get(response, "attachments[0].upload_url")
        }))
      );
    }
    const data = normalize([response], [schema.paymentBillingNotes]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(CREATE_NOTES_SUCCESS)({
        [paymentProfileId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(CREATE_NOTES_FAILURE)(err));
    throw err;
  }
};

export default createNotes;

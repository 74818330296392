import React, { useCallback, useState, memo } from "react";
import { DialogActions, Grid, IconButton, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpenText,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import { get } from "lodash";
import head from "lodash/head";

import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../modules/ui";
import colors from "../../../../theme/colors";
import {
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../components/Dialog";
import CustomInputField from "../../../../components/inputs/CustomInputField";
import PhoneInput from "../../../../components/inputs/PhoneInput";
import SubmitButton from "../../Group/Members/components/Submit";
import { getFactoringProfileById } from "../../../../modules/factoring";
import getPhone from "../../../../helpers/getPhone";
import API from "../../../../api";
import { compose, shouldUpdate, shallowEqual } from "recompose";

const SendTextReminder = ({ id, factoringID, status }) => {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const client = useSelector(getFactoringProfileById(factoringID));

  const handleExit = useCallback(() => dispatch(closeDialog()), [dispatch]);

  const [loading, setLoading] = useState(false);

  const onClick = useCallback(
    () => dispatch(openDialog("send-reminder-text-modal")),
    [dispatch]
  );

  const onSubmit = useCallback(async args => {
    try {
      setLoading(true);
      await API.factoring.sendReminderText({ ...args, id });
      handleExit();
      dispatch(openSnackbar("success", "Reminder has been sent"));
    } catch (err) {
      dispatch(openSnackbar("error", head(err)));
    }
    setLoading(false);
  });

  const SendTextReminderModal = memo(({ open, handleExit, phone }) => {
    return (
      <Formik
        render={({ handleSubmit, values, handleChange }) => {
          return (
            <Dialog open={open} maxWidth="sm" fullWidth>
              <DialogTitle onClose={() => handleExit()}>
                Send Text Reminder
              </DialogTitle>
              <DialogContent>
                <Grid container item xs={12}>
                  <CustomInputField
                    fullWidth
                    value={values.phone_number}
                    label="Phone"
                    name="phone_number"
                    inputComponent={PhoneInput}
                    onChange={handleChange}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <SubmitButton onClick={handleSubmit} />
              </DialogActions>
            </Dialog>
          );
        }}
        initialValues={{
          phone_number: phone
        }}
        onSubmit={onSubmit}
      />
    );
  }, (prevProps, nextProps) => shallowEqual(prevProps, nextProps))

  return (
    <>
      {
        status === "document_issue" &&
        <Typography>
          {loading ? (
            <IconButton>
              <FontAwesomeIcon
                icon={faSpinner}
                size="sm"
                spin
                color={colors.green}
                style={{ marginLeft: 5 }}
              />
            </IconButton>
          ) : (
            <IconButton onClick={onClick}>
              <FontAwesomeIcon color={colors.danger} icon={faEnvelopeOpenText} />
            </IconButton>
          )}
            Reminder Text
        </Typography>
      }
      {dialog.variant === "send-reminder-text-modal" && (
        <SendTextReminderModal
          open={dialog.show}
          handleExit={handleExit}
          phone={getPhone(get(client, ["owner_profile", "phone_number_cell"]))}
        />
      )}
    </>
  );
};

export default compose(shouldUpdate((props, nextProps) => !shallowEqual(props, nextProps)))(SendTextReminder);

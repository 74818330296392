import React from 'react';
import { NumericFormat } from 'react-number-format';

export const NumberFormatInput = props => {
    const { inputRef, onChange, setFieldValue, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => onChange(values.value)}
        />
    );
};

import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import { findIndex, get, head, isEmpty, merge } from "lodash";

import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../modules/ui";
import {
  createFactoringDebtorRelationship,
  deleteDebtorRelation,
  fetchFactoringProfile,
  getConnectDebtorCount,
  getConnectDebtorFilters,
  getConnectDebtorIsLoading,
  getConnectDebtorPage,
  getConnectDebtorRowsPerPage,
  getConnectDebtorRowsPerPageOptions,
  getConnectDebtorSortBy,
  getConnectDebtorSortDirection,
  getDebtors,
  getFactorClient,
  handleConnectDebtorChangePage,
  handleConnectDebtorRowsPerPage,
  handleConnectDebtorSearchFilter,
  handleConnectDebtorSort,
  queryDebtors
} from "../../../../modules/factoringClient";
import PureConnectDebtor from "./ConnectDebtor";
import MoreLinks from "./components/MoreLinks";
import tabs from "../tabs";
import columns from "./columns";

export const ConnectDebtor = PureConnectDebtor;

const mapStateToProps = state => ({
  debtors: getDebtors(state),
  loading: getConnectDebtorIsLoading(state),
  count: getConnectDebtorCount(state),
  page: getConnectDebtorPage(state),
  rowsPerPage: getConnectDebtorRowsPerPage(state),
  rowsPerPageOptions: getConnectDebtorRowsPerPageOptions(state),
  sortDirection: getConnectDebtorSortDirection(state),
  sortBy: getConnectDebtorSortBy(state),
  filters: getConnectDebtorFilters(state),
  dialog: getDialog(state),
  factorClient: getFactorClient(state)
});

const mapDispatchToProps = {
  queryDebtors,
  openDialog,
  closeDialog,
  openSnackbar,
  createFactoringDebtorRelationship,
  deleteDebtorRelation,
  handleSort: handleConnectDebtorSort,
  handleChangePage: handleConnectDebtorChangePage,
  handleChangeRowsPerPage: handleConnectDebtorRowsPerPage,
  handleFilterChange: handleConnectDebtorSearchFilter,
  fetchFactoringProfile
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      match: {
        params: { id }
      },
      debtors,
      openDialog,
      closeDialog,
      createFactoringDebtorRelationship,
      openSnackbar,
      dialog,
      deleteDebtorRelation,
      queryDebtors,
      factorClient,
      filters
    }) => ({
      factorClient,
      tabs: tabs(id, factorClient.allow_self_finance_fr),
      navIndex: findIndex(tabs(id), tab => pathname === tab.route),
      handleRoute: route => push(route),
      id,
      columns,
      debtors: debtors.map(debtor => ({
        ...debtor,
        more: () => (
          <MoreLinks
            link={`/admin/debtors/${debtor.id}/profile`}
            handleConnectDebtor={() =>
              openDialog(
                "confirm-create-relation",
                "Are you sure?",
                "Are you sure you want to associate this debtor with this client?",
                {
                  debtor: debtor.id
                }
              )
            }
            handleDeleteRelationship={() =>
              openDialog(
                "confirm-delete-relation",
                "Are you sure?",
                "Are you sure you want to delete the association to this debtor with this client?",
                {
                  debtor: debtor.id
                }
              )
            }
          />
        )
      })),
      handleConfirmConnectDebtor: async () => {
        try {
          await createFactoringDebtorRelationship(
            id,
            get(dialog, "data.debtor", "")
          );
          queryDebtors(
            "-created",
            5,
            0,
            merge({}, filters, {
              exclude_client_relation: id
            })
          );
          openSnackbar("success", "Connected Debtor!");
        } catch (err) {
          openSnackbar("error", head(err));
        } finally {
          closeDialog();
        }
      },
      handleDeleteDebtorRelation: async () => {
        try {
          await deleteDebtorRelation(id, get(dialog, "data.debtor", ""));
          openSnackbar("success", "Deleted Debtor Relation!");
        } catch (err) {
          openSnackbar("error", err);
        } finally {
          closeDialog();
        }
      }
    })
  ),
  lifecycle({
    componentDidMount() {
      if (isEmpty(this.props.factorClient)) {
        this.props.fetchFactoringProfile(this.props.id);
      }
      this.props.handleFilterChange({
        target: {
          name: "exclude_client_relation",
          value: this.props.id
        }
      });
      this.props.queryDebtors(
        "-created",
        5,
        0,
        merge({}, this.props.filters, {
          exclude_client_relation: this.props.id
        })
      );
    }
  })
)(PureConnectDebtor);

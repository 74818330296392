import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { get, isEmpty, compact } from "lodash";
import { Formik } from "formik";

import { 
  getSelectedUserProfile, 
  updateUserProfile, 
  getSelectedUser, 
  getDocuments, 
  handleConfirmDeleteUserFile, 
  handleConfirmDeleteUser,
  selectedUser,
  handleLoginAsUser,
  handleDeleteUser
} from "../../../../modules/user"
import { getUserType } from "../../../../modules/auth";
import { closeDialog, getDialog } from "../../../../modules/ui";
import WaitView from "../../../../components/WaitView";
import ProfileHeader from "../../../../components/ProfileHeader";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import FMCSALink from "../../../../components/FMCSA";
import ClientProfileButton from "./components/ClientProfileButton";
import LoginAsUserButton from "./components/LoginAsUserButton";
import DeleteButton from "./components/DeleteButton";
import SaveButton from "./components/SaveButton";
import ContactInfo from "./components/ContactInfo";
import CreditInfo from "./components/CreditInfo";
import GeneralInfo from "./components/GeneralInfo";
import InternalUserNotes from "../../../../components/InternalUserNotes";

export default function Profile(): JSX.Element {
  const dispatch = useDispatch()
  const { userId } = useParams<{ userId: string }>();
  useEffect(() => {dispatch(selectedUser(userId))}, [userId]);
  const dialog = useSelector(getDialog);
  const profile = useSelector(getSelectedUserProfile);
  const isFetchingUser = useSelector(getSelectedUser).isFetching;
  const isUpdatingUser = useSelector(getSelectedUser).isUpdating;
  const isUploadingDocuments = useSelector(getDocuments).isUploading;
  const userType = useSelector(getUserType);
  
  return (
    <Can
      perform="admin-user-profile:view"
      yes={(): JSX.Element => (
        <InternalLayout title="User Profile">
          {isFetchingUser ? (
            <WaitView />
          ) : (
            <React.Fragment>
              {!isEmpty(profile) && (
                <Formik
                  enableReinitialize={true}
                  initialValues={profile}
                  onSubmit={async (fields, { setSubmitting, resetForm }) => {
                    await dispatch(updateUserProfile(fields));
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {({ handleSubmit }): JSX.Element => (
                    <Grid container direction="column" spacing={16}>
                      <ProfileHeader 
                        title={profile.dba}
                        links={compact([
                          <FMCSALink mc={profile.mc} dot={profile.dot} />,
                          profile.payment_profile_id && (<a href={`/admin/payment/${profile.payment_profile_id}/profile`}>Payment Profile</a>)
                        ])}
                        actions={compact([
                          profile?.factoring_id && (
                              <ClientProfileButton id={profile.factoring_id} />
                          ),
                          <LoginAsUserButton
                            onClick={(): void => {dispatch(handleLoginAsUser())}}
                          />,
                          <DeleteButton
                            onClick={(): void => {dispatch(handleDeleteUser())}}
                          />,
                          <SaveButton
                            onClick={(): void => handleSubmit()}
                            disabled={isUploadingDocuments || isUpdatingUser}
                          />
                        ])}
                      />
                      <Grid item>
                        <Grid container spacing={16}>
                          <Grid item xs={4}>
                              <ContactInfo userType={userType} />
                          </Grid>
                          <Grid container spacing={8} item xs={4}>
                            <Grid item xs={12}>
                              <CreditInfo
                                files={get(profile, "files")}
                                categories={[
                                  {
                                    key: "documents_insurance",
                                    name: "Insurance"
                                  },
                                  {
                                    key: "documents_authority",
                                    name: "Authority"
                                  }
                                ]}
                                filterCategories={[
                                  "documents_insurance",
                                  "documents_authority"
                                ]}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <GeneralInfo
                              source={profile.source || ""}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <Paper style={{ padding: 16 }}>
                          <Tabs
                            value={0} 
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                          >
                            <Tab label="Internal User Notes" />
                          </Tabs>
                          <InternalUserNotes userId={userId} />
                        </Paper>
                      </Grid>
                    </Grid>
                  )}
                </Formik>
              )}
            </React.Fragment>
          )}
          {dialog.variant === "confirm" &&
            dialog.action === "delete_user_profile" && (
              <ConfirmDialog
                open={dialog.show}
                message={dialog.message}
                handleConfirm={async (): Promise<void> => { dispatch(handleConfirmDeleteUser()); }}
                handleExit={(): void => {dispatch(closeDialog())}}
              />
            )}
          {dialog.variant === "confirm" &&
            dialog.action === "delete_user_file" && (
              <ConfirmDialog
                open={dialog.show}
                message={dialog.message}
                handleConfirm={async (): Promise<void> => { dispatch(handleConfirmDeleteUserFile()); }}
                handleExit={(): void => {dispatch(closeDialog())}}
              />
            )}
        </InternalLayout>
      )}
      no={(): JSX.Element => <Redirect to="/" />}
    />
  );
}
import {
  Card,
  CardHeader,
  Avatar,
  createStyles,
  WithStyles
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const styles = createStyles({
  title: {
    color: "white"
  },
  header: {
    padding: 0
  },
  avatar: {
    backgroundColor: "white",
    borderRadius: 0,
    width: 60,
    height: 65
  }
});

interface Props extends WithStyles<typeof styles> {
  color: string;
  title: string;
  subheader: string | JSX.Element;
  icon: JSX.Element;
}

const AvatarCard = ({
  classes,
  color,
  icon,
  title,
  subheader
}: Props): JSX.Element => (
  <Card style={{ backgroundColor: color }}>
    <CardHeader
      classes={{
        title: classes.title,
        subheader: classes.title,
        root: classes.header
      }}
      avatar={
        <Avatar className={classes.avatar} style={{ color }}>
          {icon}
        </Avatar>
      }
      title={title}
      subheader={subheader}
    />
  </Card>
);

const DecoratedAvatarCard = withStyles(styles)(AvatarCard);

// @ts-ignore
export default DecoratedAvatarCard;

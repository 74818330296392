import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { lineItem } from "../../../api/schema";

export default fundingRequestId =>
  memoize(state => {
    try {
      const items = get(state, `factoring.lineItems.${fundingRequestId}`, []);
      const denormalized = denormalize(
        { items },
        { items: [lineItem] },
        state.entities
      );
      return denormalized.items;
    } catch (err) {
      return [];
    }
  });

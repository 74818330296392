import { createAction } from "redux-starter-kit";
import {
  DELETE_ATTACHMENT_FAILURE,
  DELETE_ATTACHMENT_REQUEST,
  DELETE_ATTACHMENT_SUCCESS
} from "../types";

const deleteAttachment = (frId, attachmentId) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(DELETE_ATTACHMENT_REQUEST)({}));
    await api.factoring.deleteAttachmentFromProfile(frId, attachmentId);
    dispatch(
      createAction(DELETE_ATTACHMENT_SUCCESS)({
        fundingRequestId: frId,
        attachmentId
      })
    );
  } catch (err) {
    dispatch(createAction(DELETE_ATTACHMENT_FAILURE)(err));
    throw err;
  }
};

export default deleteAttachment;

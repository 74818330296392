import React from "react";
import { Formik } from "formik";
import {
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Switch,
  Grid,
  Button
} from "@material-ui/core";

import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../components/Dialog";

export default function InvoicingSettingsDialog({
  open,
  handleClose,
  sendDebtorInvoice,
  sendARAgingFollowup,
  debtorId,
  onSubmitHandler
}) {
  return (
    <Formik
      initialValues={{
        sendDebtorInvoice,
        sendARAgingFollowup,
        debtorId
      }}
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleChange
      }) => (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
          <DialogTitle onClose={handleClose}>
            Invoicing Debtor Settings
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <List>
                  <ListItem>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.sendDebtorInvoice}
                            onChange={() => {
                              setFieldValue(
                                "sendDebtorInvoice",
                                !values.sendDebtorInvoice
                              );
                            }}
                            value="sendDebtorInvoice"
                          />
                        }
                        label="Send Debtor Invoice"
                      />
                    </FormGroup>
                  </ListItem>
                  <ListItem>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.sendARAgingFollowup}
                            onChange={() => {
                              setFieldValue(
                                "sendARAgingFollowup",
                                !values.sendARAgingFollowup
                              );
                            }}
                            value="sendARAgingFollowup"
                          />
                        }
                        label="Send AR Aging Follow-Up Emails"
                      />
                    </FormGroup>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

const tabs = [
  {
    label: "Debtor Balances",
    route: "/admin/finances/debtor_balances",
    value: "debtor_balances",
    permission: "admin-finances-debtor-balances:view"
  },
  {
    label: "Receivables",
    route: "/admin/finances/receivables",
    value: "receivables",
    permission: "admin-finances:view"
  },
  {
    label: "Purchases",
    route: "/admin/finances/purchases",
    value: "purchases",
    permission: "admin-finances-purchases:view"
  },
  {
    label: "Payments",
    route: "/admin/finances/payments",
    value: "payments",
    permission: "admin-finances-payments:view"
  },
  {
    label: "Line Items",
    route: "/admin/finances/line_items",
    value: "line_items",
    permission: "admin-finances-line-items:view"
  }
];
export default tabs;

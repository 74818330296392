import { assign } from "lodash";
import {
  FETCH_RECEIVABLES_FAILURE,
  FETCH_RECEIVABLES_REQUEST,
  FETCH_RECEIVABLES_SUCCESS
} from "../types";

const fetchReceivables = (state, { type, payload }) => {
  switch (type) {
    case FETCH_RECEIVABLES_REQUEST:
      return {
        ...state,
        loadingReceivables: true
      };
    case FETCH_RECEIVABLES_SUCCESS:
      return {
        ...state,
        receivables: assign({}, state.receivables, payload),
        loadingReceivables: false
      };
    case FETCH_RECEIVABLES_FAILURE:
      return {
        ...state,
        loadingReceivables: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchReceivables;

import { Button, Grid, MenuItem } from "@material-ui/core";
import React, { MouseEvent, ChangeEventHandler } from "react";
import {useSelector} from "react-redux";

import CustomTextField from "../../../../components/inputs/CustomTextField";
import {getUserType} from "../../../../modules/auth";

interface StatusOption {
  value: string;
  label: string;
}

interface Props {
  status: string;
  statuses: StatusOption[];
  handleSetStatus: (event: MouseEvent) => void;
  handleStatusChange: ChangeEventHandler<HTMLInputElement>;
}

const SetStatus = ({
  status,
  statuses,
  handleSetStatus,
  handleStatusChange
}: Props): JSX.Element => {
  const type = useSelector(getUserType);
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      spacing={32}
      style={{ paddingRight: "32px" }}
    >
      <Grid item xs={2}>
        <CustomTextField
          fullWidth
          select
          name="status"
          label="Status"
          value={status}
          onChange={handleStatusChange}
        >
          {statuses.filter(status => {
            if (type === "factoring_remote_admin") {
              if (
                status.value === "special_pending" ||
                status.value === "pending_originals" ||
                status.value === "pending_delivery" ||
                status.value === "approved" ||
                status.value === "remote_approved" ||
                status.value === "declined" ||
                status.value === "paid"
              ) {
                return false;
              }
            }
            return true;
          }).map(
            ({ value, label }): JSX.Element => (
              <MenuItem value={value}>{label}</MenuItem>
            )
          )}
        </CustomTextField>
      </Grid>
      <div style={{ padding: 20 }}>
        <Button
          variant="contained"
          color="primary"
          mini
          onClick={handleSetStatus}
        >
          Set Status
        </Button>
      </div>
    </Grid>
  );
}

export default SetStatus;

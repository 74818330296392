import PropTypes from "prop-types";
import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import UploadFile from "../../../components/UploadFile";
import PaperSheet from "../../../components/PaperSheet";
import colors from "../../../theme/colors";
import carrierCategories from "../../../helpers/categories";
import ContinueButton from "../../../components/buttons/ContinueButton";

const Attach = ({
  error,
  usedPlaid,
  attachmentsValidation,
  factoringProfileId,
  userType,
  updateFactoringProfileLoading,
  attachments,
  setAttachmentError,
  moveToNextStep
}) => (
  <Grid container direction="column" spacing={8}>
    <Grid item>
      <Typography
        align="left"
        variant="body1"
        style={{
          marginTop: 30,
          fontWeight: 400,
          color: colors.gray
        }}
      >
        Please Attach:
      </Typography>
    </Grid>
    <Grid item>
      {userType === "carrier" ? (
        <List dense>
          <ListItem>
            <ListItemText>1- Driver Licence</ListItemText>
          </ListItem>
          {!usedPlaid && (
            <ListItem>
              <ListItemText>
                2- Voided check or Direct Deposit Slip
              </ListItemText>
            </ListItem>
          )}
        </List>
      ) : (
        <List dense>
          <ListItem>
            <ListItemText>1- Owner's Driver Licence</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>2- Articles of Incorporation</ListItemText>
          </ListItem>
        </List>
      )}
    </Grid>
    {error && (
      <Grid item>
        <PaperSheet message={error} variant="error" />
      </Grid>
    )}
    <Grid
      item
      xs={10}
      md={12}
      lg={12}
      alignContent="center"
      alignItems="center"
    >
      <UploadFile
        requestId={factoringProfileId}
        categoriesProps={userType === "carrier" ? carrierCategories : []}
      />
    </Grid>
    <Grid item>
      <Grid container direction="row-reverse">
        <ContinueButton
          disabled={updateFactoringProfileLoading}
          onClick={() => {
            try {
              attachmentsValidation({
                setAttachmentError,
                usedPlaid,
                attachments
              });
              moveToNextStep();
            } catch (e) {}
          }}
          style={{ marginTop: 30 }}
        >
          <Icon style={{ color: colors.white }}>check</Icon>
        </ContinueButton>
      </Grid>
    </Grid>
  </Grid>
);

Attach.defaultProps = {
  error: ""
};

Attach.propTypes = {
  error: PropTypes.string,
  usedPlaid: PropTypes.bool.isRequired,
  attachmentsValidation: PropTypes.func.isRequired,
  factoringProfileId: PropTypes.string.isRequired
};

export default Attach;

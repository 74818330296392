import React, { useCallback } from "react";
import { DialogActions, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../components/Dialog";
import SubmitButton from "../../../Group/Members/components/Submit";
import UploadFile from "../../../../../components/UploadFile";
import { clearFiles, getFiles } from "../../../../../modules/upload";
import { fetchPaymentProfile } from "../../../../../modules/payment";
import { openSnackbar } from "../../../../../modules/ui";

const styles = {
  linePadding: {
    marginTop: 20
  },
  captionDiv: {
    marginTop: 50,
    flex: 1
  },
  upload: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  marginText: {
    marginTop: 20
  }
};

export default function RemoveFactorModal({
  open,
  handleExit,
  id,
  paymentProfileId,
  setFieldValue,
  content
}) {
  const dispatch = useDispatch();
  const submitButton = useCallback(() => {
    try {
      dispatch(clearFiles());
      dispatch(fetchPaymentProfile(paymentProfileId));
      setFieldValue("businessCurrentFactoringCompany", "");
      handleExit();
      dispatch(openSnackbar("success", `Unlinked.`));
    } catch (e) {
      dispatch(openSnackbar("error", e));
    }
  }, [dispatch]);
  const files = useSelector(getFiles);
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={() => handleExit()}>
        Remove Factor Connection Entirely
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Please note this will remove this factor as a payment method for all
          brokers with an NOA active tied to this payment profile.
        </Typography>
        <Typography variant={"h6"} style={styles.marginText}>
          Please Attach Letter of Release Below (LOR)
        </Typography>
        <hr />
        <div style={styles.upload}>
          <UploadFile single noCategories type="LOR" requestId={id} />
        </div>
        <hr style={styles.linePadding} />
        <div style={styles.captionDiv}>
          {content || (
            <Typography variant="caption">
              <b>Please</b> connect a new replacement factor or if this payment
              profile is no longer using a factor please ensure that a new
              default payment method is selected after completing action.
            </Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <SubmitButton onClick={submitButton} />
      </DialogActions>
    </Dialog>
  );
}

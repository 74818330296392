import { isEmpty } from "lodash";

const getAddress = (address = {}) => {
  if (isEmpty(address)) {
    return "";
  }
  const components = Object.values(address).filter(Boolean);
  if (components.length) {
    return {
      label: components.filter(component => component).join(", "),
      values: address
    };
  }
  return "";
};

export default getAddress;

import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";

import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions
} from "../../../../../../../components/Dialog";
import CustomTextField from "../../../../../../../components/inputs/CustomTextField";

const DebitCredit = ({ open, selectNone, handleExit, createAccount }) => (
  <Dialog open={open} maxWidth="sm" fullWidth>
    <Formik
      initialValues={{
        cardNumber: "",
        expiration: "",
        zipcode: ""
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await createAccount(
            values.cardNumber,
            values.expiration,
            values.zipcode
          );
          selectNone();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, values, errors, handleChange, handleBlur }) => (
        <React.Fragment>
          <DialogTitle onClose={handleExit}>
            Add Debit / Credit Card
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <CustomTextField
                  fullWidth
                  name="cardNumber"
                  label="Card Number"
                  value={values.cardNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.cardNumber}
                  error={!!errors.cardNumber}
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  fullWidth
                  name="expiration"
                  label="Expiration"
                  value={values.expiration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.expiration}
                  error={!!errors.expiration}
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  fullWidth
                  name="zipcode"
                  label="Zip Code"
                  value={values.zipcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.zipcode}
                  error={!!errors.zipcode}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => selectNone()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Add Card
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Formik>
  </Dialog>
);

export default DebitCredit;

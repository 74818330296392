import { assign } from "lodash";
import {
  FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_REQUEST,
  FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_SUCCESS,
  FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_REQUEST:
      return assign({}, state, {
        id: action.payload.id,
        connectedClients: assign({}, state.connectedClients, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          ordering: action.payload.ordering
        })
      });
    case FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_SUCCESS:
      return assign({}, state, {
        connectedClients: assign({}, state.connectedClients, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count
        })
      });
    case FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_FAILURE:
      return {
        ...state,
        connectedClients: assign({}, state.connectedClients, {
          loading: false
        })
      };
    default:
      return state;
  }
};

import { Grid, MenuItem, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  id: string;
  email: string;
  companyName: string;
  dba: string;
  mc: string;
  dot: string;
  onClick: () => void;
  approved: boolean;
  status: string;
}

const ConnectedCarrierItem = ({
  id,
  email,
  companyName,
  dba,
  mc,
  dot,
  onClick,
  approved,
  status
}: Props): JSX.Element => (
  <MenuItem
    key={id}
    value={id}
    onClick={() => onClick()}
    style={{ padding: 10, height: "unset" }}
  >
    <Grid container direction="column">
      <Grid item>
        <Typography>
          <b>{companyName || dba}</b>
        </Typography>
      </Grid>
      <Grid item>
        {email && (
          <Typography>
            {email}
          </Typography>
        )}
        <Typography>
          {mc && `MC #: ${mc} `}
        </Typography>
          <Typography>
              {dot && `DOT #: ${dot}`}
          </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <div style={{ textTransform: 'capitalize'}}>{(() => {
              if (['approved', 'paid', 'remote_approved', 'invoice_paid', 'carrier_paid'].includes(status)) {
                  return <div style={{color: "green"}}>{status}</div>
              } else if (['pending', 'special_pending', 'fuel_advance', 'needs_review'].includes(status)) {
                  return <div style={{color: "orange"}}>{status}</div>
              } else if (['declined', 'incomplete', 'pending_delivery', 'document_issue'].includes(status)) {
                  return <div style={{color: "red"}}>{status}</div>
              } else {
                  return <div style={{color: "blue"}}>{status}</div>
              }
          })()}</div>
        </Typography>
      </Grid>
    </Grid>
  </MenuItem>
);

export default ConnectedCarrierItem;

import * as React from "react";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker/index"

const { RangePicker  }: any = DatePicker;

function AntRangeDatePicker(props: RangePickerProps) {
    return (
        <RangePicker
            {...props}
        />
    )
}
export default AntRangeDatePicker;
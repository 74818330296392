import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";

import {
  UPDATE_FOLLOWUPNOTE_REQUEST,
  UPDATE_FOLLOWUPNOTE_SUCCESS,
  UPDATE_FOLLOWUPNOTE_FAILURE
} from "../types";
import { addEntities } from "../../entities";

const createFollowupNote = (receivableId, noteId, note) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPDATE_FOLLOWUPNOTE_REQUEST)({}));
    const response = await api.factoring.updateReceivableNote(
      receivableId,
      noteId,
      note
    );
    const data = normalize(response, schema.notes);
    dispatch(addEntities(data.entities));
    dispatch(createAction(UPDATE_FOLLOWUPNOTE_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(UPDATE_FOLLOWUPNOTE_FAILURE)(err));
    throw err;
  }
};

export default createFollowupNote;

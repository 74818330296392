import { Grid } from "@material-ui/core";
import React from "react";
import { SelfFinanceNoInvoice, SelfFinaceInvoice, SelfFinaceReceivables, SelfFinanceLedgerDeposit } from "../../theme/assets";

interface Props {
    contractType: string;
    width?: number;
    height?: number;
}

const SelfFinanceIcon = ({ contractType = '', width, height }: Props) => {
    const src = () => {
        switch (contractType) {
            case 'SELF_FINANCE_NO_INVOICE':
                return SelfFinanceNoInvoice;
            case 'SELF_FINANCE_WITH_INVOICE':
                return SelfFinaceInvoice;
            case 'SELF_FINANCE_LEDGER_DEPOSIT':
                return SelfFinanceLedgerDeposit;
            case 'SELF_FINANCE_RECEIVABLE':
                return SelfFinaceReceivables;
        }}
    return ['SELF_FINANCE_NO_INVOICE', 'SELF_FINANCE_WITH_INVOICE', 'SELF_FINANCE_LEDGER_DEPOSIT', 'SELF_FINANCE_RECEIVABLE'].includes(contractType) ?
        <div>
            <img
                src={src()}
                style={{ width: width || 30, height: height || 30, filter: "invert(42%) sepia(29%) saturate(904%) hue-rotate(71deg) brightness(93%) contrast(95%)" }}
            />
        </div> : null;
};
export default SelfFinanceIcon;

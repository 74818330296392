import { denormalize } from "normalizr";
import { get } from "lodash";
import { reserveItem } from "../../../api/schema";

const getReserve = state => {
  try {
    const id = get(state, "factoringClient.user", "");
    const reserveIds = get(state, `factoringClient.reserves.${id}`, []);

    const denormalized = denormalize(
      { reserves: reserveIds },
      { reserves: [reserveItem] },
      state.entities
    );

    return denormalized.reserves;
  } catch (err) {
    return [];
  }
};

export default getReserve;

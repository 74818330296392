import { createAction } from "redux-starter-kit";
import {
  UPDATE_FACTORING_PROFILE_FAILURE,
  UPDATE_FACTORING_PROFILE_REQUEST,
  UPDATE_FACTORING_PROFILE_SUCCESS
} from "../types";

const updateFactoringProfile = user => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(UPDATE_FACTORING_PROFILE_REQUEST)());
    const response = await api.factoring.updateFactoringClient(user);
    dispatch(createAction(UPDATE_FACTORING_PROFILE_SUCCESS)(response));
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_FACTORING_PROFILE_FAILURE)(err));
    throw err;
  }
};

export default updateFactoringProfile;

import React, { CSSProperties, ReactNode, ComponentType } from "react";
import { StateHandlerMap, StateHandler, withStateHandlers } from "recompose";
import { CardHeader, Collapse, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { CollapseProps } from "@material-ui/core/Collapse";

import Card from "./Card";

interface RenderProps {
  expanded: boolean;
  Collapse: ComponentType<CollapseProps>;
}

interface CollapseCardProps {
  title: JSX.Element;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  iconStyle: {
    expandedStyle: CSSProperties;
    collapsedStyle?: CSSProperties;
  };
  render: (props: RenderProps) => ReactNode;
}

const CollapseCard = ({
  title,
  expanded,
  setExpanded,
  render,
  iconStyle: { expandedStyle = {}, collapsedStyle = {} }
}: CollapseCardProps): JSX.Element => (
  <Card>
    <CardHeader
      title={title}
      action={
        <IconButton onClick={(): void => setExpanded(!expanded)}>
          <FontAwesomeIcon
            icon={faChevronDown}
            size="xs"
            rotation={expanded ? 180 : undefined}
            style={expanded ? expandedStyle : collapsedStyle}
          />
        </IconButton>
      }
    />
    {render({ expanded, Collapse })}
  </Card>
);

interface State {
  expanded: boolean;
}

interface Handlers extends StateHandlerMap<State> {
  setExpanded: StateHandler<State>;
}

interface Props {
  expanded?: boolean;
  title: JSX.Element;
  iconStyle: {
    expandedStyle: CSSProperties;
    collapsedStyle?: CSSProperties;
  };
  render: (props: RenderProps) => ReactNode;
}

export default withStateHandlers<State, Handlers, Props>(
  ({ expanded = false }): State => ({ expanded }),
  {
    setExpanded: (): ((expanded: boolean) => State) => (expanded): State => ({
      expanded
    })
  }
)(CollapseCard);

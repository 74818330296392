import React from "react";
import { compose, lifecycle, withState } from "recompose";
import axios, { AxiosRequestConfig } from "axios";
import { memoize, sortBy } from "lodash";
import { MenuItem } from '@material-ui/core';
import { TextFieldProps } from "@material-ui/core/TextField";
import { Select } from "./inputs/BoldInput";
import CustomTextField from './inputs/CustomTextField';

interface Admin {
  id: string;
  name: string;
}

export const noneSelect = {
  id: " ",
  name: "Everyone"
};

const getAdmins = memoize(
  async (): Promise<Admin[]> => {
    const options: AxiosRequestConfig = {
      url: `/admin/search/admin/`,
      method: "GET"
    };
    try {
      const response = await axios(options);
      return [noneSelect, ...sortBy(response.data, ['name'])];
    } catch (e) {
      return [];
    }
  }
);

interface DropDownProps {
  admins: Admin[];
  selectType?: "admin" | "classic";
}

export const AdminDropDown = ({
  admins,
  ...props
}: DropDownProps & TextFieldProps): JSX.Element => {
  if(props.selectType === "admin") {
    return <Select
    {...props}
    fullWidth
    selections={admins.map(admin => ({
      text: admin.name,
      value: admin.id
    }))}
  />
  }
  return (
  <CustomTextField
    select
    fullWidth
    name={props.name}
    label={props.label}
    onChange={props.onChange}
    onBlur={props.onBlur}
    value={props.value}
    disabled={props.disabled}
  >
    {admins.map(admin => (
      <MenuItem value={admin.id}>{admin.name}</MenuItem>
      ))}
  </CustomTextField>
)
};

interface Props {
  setAdmins: (arg0: Admin[]) => void;
  admins: Admin[];
  value: string;
  currentUserId?: string;
  selectType?: "admin" | "classic";
}

export default compose<Props, TextFieldProps | { currentUserId?: string, selectType?: "admin" | "classic"; }>(
  withState("admins", "setAdmins", []),
  lifecycle<Props, {}>({
    async componentDidMount(): Promise<void> {
      const { setAdmins, currentUserId, value } = this.props;
      // if (currentUserId) {
      //   let admins = await getAdmins();
      //   const filtered = admins.filter(admin => {
      //     return admin.id === currentUserId || admin.id === value
      //   })
      //   setAdmins(filtered);
      // } else {
      //   const admins = await getAdmins();
      //   setAdmins(admins);
      // }
      const admins = await getAdmins();
      setAdmins(admins);
    }
    // async componentDidUpdate(prevProps, prevState): Promise<void> {
    //   if (
    //     this.props.currentUserId !== prevProps.currentUserId ||
    //     this.props.value !== prevProps.value
    //   ) {
    //     const admins = await getAdmins();
    //     const filtered = admins.filter(admin => {
    //       return (
    //         admin.id === this.props.currentUserId ||
    //         admin.id === this.props.value
    //       );
    //     });
    //     this.props.setAdmins(filtered);
    //   }
    // }
  })
)(AdminDropDown);

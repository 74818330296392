import { createAction } from "redux-starter-kit";
import { denormalize, normalize } from "normalizr";
import { debounce } from "lodash";

import withTable from "./withTable";

import { addEntities } from "./entities";
import { factorClient } from "../api/schema";
import triggerDownload from "../helpers/triggerDownload";

export const FETCH_FACTORINGCLIENTS_REQUEST =
  "FACTORINGCLIENTS/FETCH_FACTORINGCLIENTS_REQUEST";
export const FETCH_FACTORINGCLIENTS_SUCCESS =
  "FACTORINGCLIENTS/FETCH_FACTORINGCLIENTS_SUCCESS";
export const FETCH_FACTORINGCLIENTS_FAILURE =
  "FACTORINGCLIENTS/FETCH_FACTORINGCLIENTS_FAILURE";

const {
  selectors,
  actions,
  reducer: tableReducer,
  middleware: tableMiddleware
} = withTable({
  stateKey: "factoringClients",
  typeKey: "factoringClients"
});

const debounced = debounce(
  async (
    dispatch,
    getState,
    api,
    schema,
    status,
    ordering,
    limit,
    offset,
    filters
  ) => {
    dispatch(
      createAction(FETCH_FACTORINGCLIENTS_REQUEST)({
        status,
        filters
      })
    );
    const response = await api.factoring.getFactoringClients(
      status,
      ordering,
      limit,
      offset,
      filters
    );
    const data = normalize(response.results, [schema.factorClient]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_FACTORINGCLIENTS_SUCCESS)({
        items: data.result,
        count: response.count
      })
    );
  },
  1000,
  { trailing: true }
);

export const queryFactoringClients = (
  status,
  ordering,
  limit,
  offset,
  filters
) => async (dispatch, getState, { api, schema }) => {
  try {
    await debounced(
      dispatch,
      getState,
      api,
      schema,
      status,
      ordering,
      limit,
      offset,
      filters
    );
  } catch (err) {
    dispatch(createAction(FETCH_FACTORINGCLIENTS_FAILURE)(err));
  }
};

export const docReviewCount = () => async (dispatch, getState, { api }) => {
  const response = await api.factoring.getFactoringClients("document_review");

  return response.count;
};

export const createCSV = filters => async (dispatch, getState, { api }) => {
  const response = await api.factoring.getFactoringClientsExport(filters);
  triggerDownload(response.download_url);
};

export const getFactoringClients = state => {
  const items = state.factoringClients.ids;

  const denormalized = denormalize(
    { items },
    { items: [factorClient] },
    state.entities
  );

  return denormalized.items;
};

export const getLoading = state => state.factoringClients.loading;

const reducer = (
  state = {
    loading: false,
    ids: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_FACTORINGCLIENTS_REQUEST:
      return {
        ...state,
        loading: true,
        status: action.payload.status,
        filters: action.payload.filters
      };
    case FETCH_FACTORINGCLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ids: action.payload.items,
        count: action.payload.count
      };
    case FETCH_FACTORINGCLIENTS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default tableReducer(reducer);

export const {
  getOffset,
  getRowsPerPageOptions,
  getRowsPerPage,
  getCount,
  getSortDirection,
  getSortBy,
  getPage,
  getFilters
} = selectors;

export const {
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter
} = actions;

export const middleware = tableMiddleware(selectors => store => action => {
  const state = store.getState();
  const sortBy = selectors.getSortBy(state);
  const sortDirection = selectors.getSortDirection(state);
  const offset = selectors.getOffset(state);
  const rowsPerPage = selectors.getRowsPerPage(state);
  const filters = selectors.getFilters(state);

  store.dispatch(
    queryFactoringClients(
      state.factoringClients.status,
      sortDirection === "asc" ? sortBy : `-${sortBy}`,
      rowsPerPage,
      offset,
      filters
    )
  );
});

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";

import {
  CREATE_LINEITEMS_CATEGORIES_FAILURE,
  CREATE_LINEITEMS_CATEGORIES_REQUEST,
  CREATE_LINEITEMS_CATEGORIES_SUCCESS
} from "../types";
import { addEntities } from "../../entities";

export default lineItem => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(CREATE_LINEITEMS_CATEGORIES_REQUEST)());
    const response = await api.finances.createLineItemsCategories(lineItem);
    const data = normalize([response], [schema.lineItemCategory]);
    dispatch(addEntities(data.entities));
    dispatch(createAction(CREATE_LINEITEMS_CATEGORIES_SUCCESS)());
  } catch (err) {
    dispatch(createAction(CREATE_LINEITEMS_CATEGORIES_FAILURE)(err));
    throw err;
  }
};

import { createReducer } from "redux-starter-kit";

import steps from "./steps";
import * as TYPES from "./types";
import nextStepReducer from "./reducers/nextStep";
import exchangePlaidTokenReducer from "./reducers/exchangePlaidToken";
import signUpFactoringProfile from "./reducers/signUpFactoringProfile";
import resetStepsReducer from "./reducers/resetSteps";
import nextStepByNameReducer from "./reducers/moveToStepByName";
import fetchFactoringProfileReducer from "./reducers/fetchFactoringProfile";

const initial = {
  step: "confirm",
  steps,
  factoringLoading: false,
  factoringError: "",
  exchangePlaidLoading: false,
  exchangePlaidSuccess: {},
  exchangePlaidError: "",
  factoringProfile: {}
};

const reducer = createReducer(initial, {
  [TYPES.NEXT_SIGNUP_STEP]: nextStepReducer,
  [TYPES.RESET_SIGNUP_STEPS]: resetStepsReducer,
  [TYPES.NEXT_STEP_BY_NAME]: nextStepByNameReducer,
  [TYPES.CREATE_SIGNUP_FACTORING_PROFILE_SUCCESS]: signUpFactoringProfile,
  [TYPES.CREATE_SIGNUP_FACTORING_PROFILE_FAILURE]: signUpFactoringProfile,
  [TYPES.CREATE_SIGNUP_FACTORING_PROFILE_REQUEST]: signUpFactoringProfile,
  [TYPES.EXCHANGE_SIGNUP_PLAID_TOKEN_FAILURE]: exchangePlaidTokenReducer,
  [TYPES.EXCHANGE_SIGNUP_PLAID_TOKEN_REQUEST]: exchangePlaidTokenReducer,
  [TYPES.EXCHANGE_SIGNUP_PLAID_TOKEN_SUCCESS]: exchangePlaidTokenReducer,
  [TYPES.FETCH_FACTORING_PROFILE_SUCCESS]: fetchFactoringProfileReducer,
  [TYPES.FETCH_FACTORING_PROFILE_REQUEST]: fetchFactoringProfileReducer,
  [TYPES.FETCH_FACTORING_PROFILE_FAILURE]: fetchFactoringProfileReducer
});

export default reducer;

export { default as exchangePlaidToken } from "./actions/exchangePlaidToken";
export { default as moveToNextStep } from "./actions/moveToNextStep";
export { default as resetSteps } from "./actions/resetSteps";

export { default as getCurrentStep } from "./selectors/steps/getCurrentStep";
export { default as getSteps } from "./selectors/steps/getSteps";
export {
  default as signUpFactoringProfile
} from "./actions/signUpFactoringProfile";
export {
  default as getExchangeLoading
} from "./selectors/exchangePlaidToken/getExchangeLoading";
export {
  default as getExchangePlaid
} from "./selectors/exchangePlaidToken/getExchangePlaid";
export {
  default as getExchangePlaidError
} from "./selectors/exchangePlaidToken/getExchangePlaidError";
export {
  default as getUsedPlaid
} from "./selectors/exchangePlaidToken/getUsedPlaid";
export {
  default as getFactoringProfile
} from "./selectors/signUpFactoringProfile/getFactoringProfile";
export {
  default as getFactoringLoading
} from "./selectors/signUpFactoringProfile/getFactoringLoading";
export {
  default as getFactoringError
} from "./selectors/signUpFactoringProfile/getFactoringError";
export { default as moveToStepByName } from "./actions/moveToStepByName";

import React, { useState, useCallback, useEffect } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faTrashAlt,
  faSave
} from "@fortawesome/free-solid-svg-icons";

import { Select } from "../../../../../components/inputs/BoldInput";
import Colors from "../../../../../theme/colors";
import {
  getDialog,
  closeDialog,
  openDialog,
  openSnackbar
} from "../../../../../modules/ui";
import RemoveFactorModal from "./RemoveFactorModal";
import API from "../../../../../api";
import NOAAttachmentList from "./NOAAttachamentList";

export default function NOAFactor({
  relationId,
  NOALink,
  paymentProfileId,
  setFieldValue
}) {
  const [status, setStatus] = useState("active");
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);

  const handleExit = useCallback(() => dispatch(closeDialog()), [dispatch]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await API.factorCompany.getFactorCompanyRelationship(
          relationId
        );
        setStatus(res.noa_status);
        setAttachments(res.attachments);
      } catch (e) {}
    }
    fetchData();
  }, [relationId]);

  const openDialogAction = useCallback(
    () => dispatch(openDialog("remove_factor_modal")),
    [dispatch]
  );

  const openAttachment = useCallback(() => window.open(NOALink, "_blank"), []);
  const saveStatus = useCallback(
    async (id, data) => {
      try {
        await API.factorCompany.updateFactorCompanyRelationship(id, data);
        dispatch(openSnackbar("success", "NOA status has been updated!"));
      } catch (e) {
        dispatch(openSnackbar("error", "Error while updating NOA status."));
      }
    },
    [dispatch]
  );
  return (
    <Grid container style={{ marginTop: 10, width: "100%" }} spacing={8}>
      <Grid container item>
        <Typography
          style={{
            fontSize: 16,
            color: "black",
            fontWeight: "bold"
          }}
        >
          NOA Status for Factor
        </Typography>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={7} container alignContent="center">
          <Select
            selections={[
              {
                text: "Active",
                value: "active"
              },
              {
                text: "Inactive",
                value: "inactive"
              }
            ]}
            value={status}
            onChange={value => {
              setStatus(value.target.value);
            }}
          />
        </Grid>
        <Grid item xs={5} container alignContent="center">
          <IconButton onClick={openDialogAction}>
            <FontAwesomeIcon
              icon={faTrashAlt}
              color={Colors.danger}
              size="xs"
            />
          </IconButton>
          <IconButton onClick={openAttachment}>
            <FontAwesomeIcon icon={faPaperclip} color={Colors.info} size="sm" />
          </IconButton>
          <IconButton
            onClick={() => saveStatus(relationId, { noa_status: status })}
          >
            <FontAwesomeIcon icon={faSave} color={Colors.green} size="sm" />
          </IconButton>
        </Grid>
        {attachments.length > 0 ?
          <Grid container direction="row" item>
            <Typography
              style={{
                fontSize: 16,
                color: "black",
                fontWeight: "bold"
              }}
            >
              NOA List
            </Typography>
            <NOAAttachmentList attachments={attachments} />
          </Grid>
        : null}
      </Grid>
      {dialog.variant === "remove_factor_modal" && (
        <RemoveFactorModal
          open={dialog.show}
          handleExit={handleExit}
          id={relationId}
          paymentProfileId={paymentProfileId}
          setFieldValue={setFieldValue}
        />
      )}
    </Grid>
  );
}

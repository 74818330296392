import { denormalize } from "normalizr";

import { obligations } from "../../../api/schema";

export default state => {
    try {
        const items = state.obligations.ids;
        const denormalized = denormalize(
            { items },
            { items: [obligations] },
            state.entities
        );
        return denormalized.items;
    } catch (err) {
        return [];
    }
};

import { Action, createAction } from "redux-starter-kit";
import * as Sentry from "@sentry/react";
import { ThunkAction } from "redux-thunk";
import { ThunkContext } from "../../types";
import fetchUserToken from "./fetchUserToken";
import getGroups from "./getGroups";
import currentUser from "./currentUser";

import {
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS
} from "../types";

function login(
  email: string,
  password: string
): ThunkAction<Promise<void>, any, ThunkContext, Action<any>> {
  return async (dispatch, getState, { api }) => {
    try {
      dispatch(createAction(USER_LOGIN_REQUEST)());
      await dispatch(fetchUserToken({ email, password }));
      const user = ((await dispatch(currentUser())) as unknown) as any;
      const currentUserResponse = (await (api.user.getUserById(
        user.id
      ) as unknown)) as any;
      dispatch(getGroups());
      (window as any).analytics.identify(user.id, {
        email: user.email,
        type: user.type
      });
      Sentry.setUser({
        id: user.id,
        type: user.type,
        email: user.email
      });
      dispatch(createAction(USER_LOGIN_SUCCESS)(currentUserResponse));
    } catch (err) {
      dispatch(createAction(USER_LOGIN_FAILURE)(err));
      throw (err);
    }
  };
}

export default login;

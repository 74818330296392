import { createAction } from "redux-starter-kit";
import {
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS
} from "../types";

const getSelectedProfile = userId => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(FETCH_USER_PROFILE_REQUEST)());
    const user = await api.admin.getUserById(userId);
    dispatch(createAction(FETCH_USER_PROFILE_SUCCESS)(user));
  } catch (err) {
    dispatch(createAction(FETCH_USER_PROFILE_FAILURE)(err));
    throw err;
  }
};

export default getSelectedProfile;

import { denormalize } from "normalizr";

import { receivable } from "../../../api/schema";

export default state => {
  try {
    const items = state.receivables.ids;
    const denormalized = denormalize(
      { items },
      { items: [receivable] },
      state.entities
    );
    return denormalized.items;
  } catch (err) {
    return [];
  }
};

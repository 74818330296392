import React from "react";
import { getEmojiDataFromNative } from "emoji-mart";
import data from "emoji-mart/data/all.json";
import { Typography } from "@material-ui/core";

import Colors from "../theme/colors";

interface Props {
  name: string;
  language: string;
}

const languageObject: { [language: string]: string } = {
  en: "English",
  es: "Español"
};

const languageText = (language: string): string =>
  language === "es"
    ? `${languageObject[language]} ${
        getEmojiDataFromNative("🇲🇽", "apple", data).native
      }`
    : `${languageObject[language]}`;

const NameFormat = ({ name, language }: Props): JSX.Element => (
  <div>
    <Typography variant="body2">{name}</Typography>
    {language === "es" && (
      <Typography variant="caption" style={{ color: Colors.green }}>
        {`(${languageText(language)})`}
      </Typography>
    )}
  </div>
);

export default NameFormat;

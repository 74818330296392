import React from "react";
import { SelectProps } from "@material-ui/core/Select";
import { Select } from "../BoldInput";
import { fundingRequestType } from "../../../helpers/fundingRequestType";
import filter from "lodash/filter";

interface FundingRequestTypeSelectProps extends SelectProps {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    withHeader: boolean;
    label: string;
    value: string;
}

const FundingRequestTypeSelect = (props: FundingRequestTypeSelectProps): JSX.Element =>
        <Select
            {...props}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                e.preventDefault();
                switch (e.target.value) {
                    case 'NON_FACTORED_STANDARD':
                        props.setFieldValue("nonFactored", true);
                        props.setFieldValue("billOutOnly", false);
                        props.setFieldValue('self_finance_requested', false);
                        props.setFieldValue('selfFinanceRequested', false);
                        break;
                    case 'NON_FACTORED_BILL_OUT':
                        props.setFieldValue('nonFactored', true);
                        props.setFieldValue('billOutOnly', true);
                        props.setFieldValue('self_finance_requested', false);
                        props.setFieldValue('selfFinanceRequested', false);
                        break;
                    case 'SELF_FINANCE_NO_INVOICE':
                        props.setFieldValue('nonFactored', false);
                        props.setFieldValue('billOutOnly', false);
                        props.setFieldValue('self_finance_requested', true);
                        props.setFieldValue('selfFinanceRequested', true);
                        break;
                    case 'SELF_FINANCE_WITH_INVOICE':
                        props.setFieldValue('nonFactored', false);
                        props.setFieldValue('billOutOnly', false);
                        props.setFieldValue('self_finance_requested', true);
                        props.setFieldValue('selfFinanceRequested', true);
                        break;
                    default:
                        props.setFieldValue('nonFactored', false);
                        props.setFieldValue('billOutOnly', false);
                        props.setFieldValue('self_finance_requested', false);
                        props.setFieldValue('selfFinanceRequested', false);
                        break;
                }
                props.setFieldValue(props.name || 'contact_type', e.target.value);
            }}
        selections={fundingRequestType}
        hiddenValues={['SELF_FINANCE_LEDGER_DEPOSIT', 'SELF_FINANCE_RECEIVABLES', 'NON_FACTORED_PAYMENT_FORWARD']}
        />

export default FundingRequestTypeSelect;

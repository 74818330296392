import { denormalize } from "normalizr";

import { group } from "../../../api/schema";

export default state => {
  try {
    const items = state.groups.groups.ids;

    const denormalized = denormalize(
      { items },
      { items: [group] },
      state.entities
    );
    return denormalized.items || [];
  } catch (err) {
    return [];
  }
};

import { get } from "lodash";
import { denormalize } from "normalizr";
import { tms as tmsSchema } from "../../../api/schema";

export default state => {
  try {
    const tms = get(state, `factoring.tms.ids`, []);

    const denormalized = denormalize(
      { tms },
      { tms: [tmsSchema] },
      state.entities
    );

    return {
      items: denormalized.tms,
      ...get(state, `factoring.tms`, {})
    };
  } catch (err) {
    return [];
  }
};

import axios, { AxiosRequestConfig } from "axios";


const getObligations = async (contractId: string): Promise<any> => {
    const url = `/contracts/${contractId}/obligations`;
    const options: AxiosRequestConfig = {
        url,
        method: "GET"
    };
    const response = await axios(options);
    return response.data;
};

const getObligationsById = async (contractId: string, obligationId:string): Promise<any> => {
    const url = `/contracts/${contractId}/obligations/${obligationId}`;
    const options: AxiosRequestConfig = {
        url,
        method: "GET"
    };
    const response = await axios(options);
    return response.data;
};

const resubmitObligation = async (obligationId:string): Promise<any> => {
    const url = `/obligations/${obligationId}/resubmit`;
    const options: AxiosRequestConfig = {
        url,
        method: "POST"
    };
    const response = await axios(options);
    return response.data;
};

const getPayments = async (contractId: string, obligationId: string): Promise<any> => {
    const url = `/contracts/${contractId}/obligations/${obligationId}/payments`;
    const options: AxiosRequestConfig = {
        url,
        method: "GET"
    };
    const response = await axios(options);
    return response.data;
};

const runFailedLedgerWalletObligation = async (obligationId: string): Promise<any> => {
    const url = `/obligations/run_failed_ledger_wallet_obligation`;
    const options: AxiosRequestConfig = {
        url,
        method: "POST",
        data: {
            obligation_id: obligationId
        }
    };
    const response = await axios(options);
    return response.data;
};

const createContractLineItems = async (contractId: string, data: Record<string, any>): Promise<any> => {
    const url = `/obligations/${contractId}/line_item`;
    const options: AxiosRequestConfig = {
        url,
        method: "POST",
        data
    };
    const response = await axios(options);
    if(response.data)
        return response.data;
    return response;
}

export const fetchLineItemCategories = async (): Promise<any> => {
    const url = `/funding_requests/line_items/categories/list/`;
    const options: AxiosRequestConfig = {
        url,
        method: "GET"
    };
    const response = await axios(options);
    return response.data;
}

export const deleteObligationLineItem = async (id: string): Promise<any> => {
    const url = `obligations/line_item/${id}/`;
    const options: AxiosRequestConfig = {
        url,
        method: "DELETE"
    };
    const response = await axios(options);
    return response.data;
}

export default {
    getObligations,
    getObligationsById,
    getPayments,
    runFailedLedgerWalletObligation,
    resubmitObligation,
    createContractLineItems,
    fetchLineItemCategories,
    deleteObligationLineItem,
}

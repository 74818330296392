import { denormalize } from "normalizr";
import { get, filter } from "lodash";
import { attachment } from "../../../api/schema";
import getFactorClient from "./getFactorClient";
import { getUserType } from "../../auth";

const getAttachments = state => {
  try {
    const userType = getUserType(state);
    const id = get(getFactorClient(state), "id");
    const items = get(state, `factoringClient.attachments.${id}`, []);

    const denormalized = denormalize(
      { items },
      { items: [attachment] },
      state.entities
    );
    return userType !== "factoring_remote_admin" ? denormalized.items : filter(denormalized.items, o => 
      ["comfreight_noa", "transfer_document"].includes(o.category));
  } catch (err) {
    return [];
  }
};

export default getAttachments;

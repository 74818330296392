import formatStatus from '../../../../helpers/format/formatStatus';

const columns = (userType) => [
  {
    key: 'name',
    name: 'Company',
    filter: 'text',
    filter_key: 'company_name',
  },
  {
    key: 'noa_contact_email',
    name: 'NOA Contact Email',
  },
  {
    key: 'user_type',
    name: 'User Type',
  },
  {
    key: 'created',
    name: 'Created At',
  },
  {
    key: 'state_zip',
    name: 'State/Zip',
  },
  ...(() =>
    userType !== 'factoring_remote_admin'
      ? [
          {
            key: 'discount_rate',
            name: 'Rate',
          },
          {
            key: 'minimum_fee',
            name: 'Minimum Fee',
          },
        ]
      : [])(),
    {
       key: 'coBrokerAllowed',
       name: 'Co Broker Allowed',
    },
  {
    key: 'summary',
    name: 'NOA Summary',
  },
  {
    key: 'credit_approved',
    name: 'Status',
    format: formatStatus,
  },
  {
    key: 'more',
    name: 'More',
  },
];

export default columns;

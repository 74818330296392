import { isInteger } from "formik";
import Yup from "../../../YupValidation";
import { throttleEmailValidation } from "../../SignUp/components/form/formValidation";

const InvitedValidationSchema = (
  action,
  isSmsInvite,
  token = "",
  groupId,
  isStaticLink
) => {
  const schema = {
    email: Yup.string()
      .email("Please Enter a Valid Email.")
      .required("Please fill out this field")
      .test(
        "unique-email",
        object =>
          `This email already exists. Please <a href="/login?${object.value ? `email=${object.value}` : ""
          }&group_id=${groupId}">Click Here</a> to login and complete`,
        value => {
          if (isSmsInvite) {
            return throttleEmailValidation(value);
          }
          return true;
        }
      ),
    address: Yup.object().shape({
      label: Yup.string().required("Please fill out this field"),
    }),
    terms: Yup.boolean()
      .oneOf([true], "Must Accept Terms and Conditions")
      .required("Must Accept Terms and Conditions"),
    owner_first_name: Yup.string()
      .min(2, "Too Short First Name.")
      .max(50, "Too Long First Name.")
      .required("Please fill out this field"),
    owner_last_name: Yup.string()
      .min(2, "Too Short First Name.")
      .max(50, "Too Long First Name.")
      .required("Please fill out this field"),
    owner_phone_number: Yup.string()
      .required("Please fill out this field")
      .nullable()
      .phone()
      .checkPhone(),
    mc: Yup.string()
      .min(1, "too short MC")
      .max(8, "too long MC")
      .test(
        "mcIsNumber",
        "MC must be a number",
        val => !val || isInteger(String(Number(val)))
      )
      .test(
        "containsDashes",
        "TAX ID must not contains dashes.",
        val => !val || !val.includes("-")
      ),
    dot: Yup.string()
      .min(1, "too short DOT")
      .test(
        "dotIsNumber",
        "DOT must be a number",
        val => !val || isInteger(String(Number(val)))
      )
      .test(
        "containsDashes",
        "DOT must not contains dashes.",
        val => !val || !val.includes("-")
      ),
    company_name: Yup.string().required("Please fill out this field"),
    ...(() =>
      !action
        ? {
          password: Yup.string().required("Please fill out this field"),
          confirm_password: Yup.string()
            .equalTo(Yup.ref("password"), "Passwords must match")
            .required("Please fill out this field")
        }
        : {})()
  };

  return Yup.object().shape(schema);
};

export default InvitedValidationSchema;

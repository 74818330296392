import { denormalize } from "normalizr";
import { get } from "lodash";
import { fundingRequest } from "../../../api/schema";

const getFundingRequests = state => {
  try {
    const items = get(state, "factoringClient.fundingRequests.ids", []);

    const denormalized = denormalize(
      { items },
      { items: [fundingRequest] },
      state.entities
    );

    return denormalized.items;
  } catch (err) {
    return [];
  }
};

export default getFundingRequests;

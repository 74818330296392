import { assign } from "lodash";
import {
  DELETE_USER_NOTE_FAILURE,
  DELETE_USER_NOTE_REQUEST,
  DELETE_USER_NOTE_SUCCESS
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case DELETE_USER_NOTE_REQUEST:
      return {
        ...state,
        loadingUserNotes: true
      };
    case DELETE_USER_NOTE_SUCCESS:
      return {
        ...state,
        loadingUserNotes: false,
        userNotes: assign({}, state.userNotes, {
          [action.payload.userId]: state.userNotes[
            action.payload.userId
          ].filter(note => {
            return note !== action.payload.note;
          })
        })
      };
    case DELETE_USER_NOTE_FAILURE:
      return {
        ...state,
        loadingUserNotes: false
      };
    default:
      return state;
  }
};

import { Formik } from "formik";
import React from "react";
import Notes from "../../../../components/Notes";

export interface Note {
  posted_by_name: string;
  note: string;
  id: string;
  attachments: string[];
  url: string;
  created: string;
}

interface Props {
  updateClientNote: (noteId: string, note: string, attachments?: File) => void;
  openSnackbar: Function;
  createClientNote: (note: string, attachments?: File) => void;
  clientNotes: Note[];
  deleteClientNote: (id: string) => Promise<void>;
}

export default ({
  updateClientNote,
  openSnackbar,
  createClientNote,
  clientNotes,
  deleteClientNote
}: Props): JSX.Element => (
  <Formik
    enableReinitialize
    initialStatus="CREATE"
    initialValues={{
      note: "",
      attachments: undefined,
      notesID: ""
    }}
    onSubmit={async (values, { setSubmitting, resetForm }): Promise<void> => {
      try {
        if (values.notesID !== "") {
          await updateClientNote(
            values.notesID,
            values.note,
            values.attachments
          );
          openSnackbar("success", "Updated Note!");
        } else {
          await createClientNote(values.note, values.attachments);
          openSnackbar("success", "Created Note!");
        }
      } catch (err) {
        openSnackbar("error", err.message || err);
      } finally {
        setSubmitting(false);
        resetForm();
      }
    }}
  >
    {({
      handleChange,
      handleSubmit,
      handleBlur,
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      status,
      setStatus,
      isSubmitting
    }): JSX.Element => {
      return (
        <Notes
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          handleSubmit={handleSubmit}
          isCreatingNote={isSubmitting}
          notes={clientNotes}
          isFetching={false}
          notesAttachments={{}}
          isUpdatingNote={isSubmitting}
          handleDeleteNote={deleteClientNote}
          status={status}
          setStatus={setStatus}
        />
      );
    }}
  </Formik>
);

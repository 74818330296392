import React from "react";

import AdminDropDown from "../../../components/AdminDropDown";
import formatPhone from "../../../helpers/format/formatPhone";
import Source from "../../../components/icons/SourceIcon";
import AdminAvatarChip from "../../../components/AdminAvatarChip";
import NameFormat from "../../../components/LanguageFormat";
import formatDate from "../../../helpers/format/formatDate";

const columns = (inactive = false, status = "") => {
  return [
    {
      key: "company_name",
      name: "DBA",
      filter: "text",
      filter_key: "company_profile__name"
    },
    {
      key: "assigned_admin_name",
      name: "Assigned Admin",
      format: (name: string): JSX.Element => <AdminAvatarChip name={name} />,
      filter: "component",
      filter_value: "",
      filter_component: AdminDropDown,
      filter_key: "assigned_admin",
      sort: true
    },
    {
      key: ["owner_name", "user.language"],
      name: "Contact Name",
      format: (name: string, language: string): JSX.Element => (
        <NameFormat name={name} language={language} />
      ),
      filter: "text",
      filter_key: "owner_profile__name"
    },
    {
      key: "owner_profile.phone_number_cell",
      name: "Phone",
      filter: "text",
      filter_key: "phone_number",
      format: formatPhone
    },
    {
      key: "time_posted",
      name: "Created At",
      sort: true
    },
    {
      key: "owner_profile.email",
      name: "Contact Email",
      filter: "text",
      filter_key: "owner_profile__email"
    },
    {
      key: "company_profile.address.state",
      name: "State",
      filter: "text",
      filter_key: "state"
    },
    {
      key: "company_profile.address.zip",
      name: "Zip",
      filter: "text",
      filter_key: "zip_code"
    },
    {
      key: "source",
      name: "Source",
      format: (source: string): JSX.Element => <Source source={source} />
    },
    ...(() =>
      status == "ucc_hold"
        ? [
            {
              key: "ucc_followup_date",
              name: "Ucc Followup",
              format: formatDate,
              colSpan: 3
            }
          ]
        : [])(),
    {
      key: "stats",
      name: "Stats",
      sort: true
    },
    {
      key: "status",
      name: "Status",
      colSpan: 3,
      sort: "approved_datetime"
    },
    {
      key: "user_type",
      name: "Type",
      filter_key: "user_type",
      filter: "dropdown",
      filter_value: " ",
      filter_values: [
        { label: "All", value: " " },
        { label: "Broker", value: "broker" },
        { label: "Carrier", value: "carrier" }
      ]
    },
    ...(() =>
      inactive
        ? [
            {
              key: "last_submitted_funding_request",
              name: "Last Invoice",
              format: formatDate,
              colSpan: 3
            },
            {
              key: "user.last_login",
              name: "Last Login",
              format: formatDate,
              colSpan: 3
            }
          ]
        : [])(),
    {
      key: "more",
      name: "More"
    }
  ];
};

// @ts-ignore
export default columns;

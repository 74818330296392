import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { DialogTitle, Dialog } from "../../../../../components/Dialog";
import UploadFile from "../../../../../components/UploadFile";
import { closeDialog } from "../../../../../modules/ui";

export default function({ open, fundingRequestId }) {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => dispatch(closeDialog()));
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>Add Attachments</DialogTitle>
      <DialogContent>
        <UploadFile
          type="funding"
          requestId={fundingRequestId}
          categoriesProps={[
            "Signed Bill of Lading (Delivered)",
            "Signed Bill of Lading (Pick-Up)",
            "Rate Confirmation",
            "Truck Order Not Used",
            "Invoice",
            "Freight Bill",
            "Lumper Receipt",
            "Detention Receipt",
            "Late Fee Receipt",
            "Other"
          ]}
          defaultCategoryValue="Other"
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { createAction } from "redux-starter-kit";
import {
  DELETE_TRANSACTIONNOTE_FAILURE,
  DELETE_TRANSACTIONNOTE_REQUEST,
  DELETE_TRANSACTIONNOTE_SUCCESS
} from "../types";

const deleteTransactionNote = (fundingRequestId, noteId) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(DELETE_TRANSACTIONNOTE_REQUEST)());
    const response = await api.factoring.deleteTransactionNote(
      fundingRequestId,
      noteId
    );
    dispatch(
      createAction(DELETE_TRANSACTIONNOTE_SUCCESS)({
        fundingRequest: fundingRequestId,
        note: noteId
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(DELETE_TRANSACTIONNOTE_FAILURE)(err));
    throw err;
  }
};

export default deleteTransactionNote;

import React from "react";
import { Button, DialogActions, Typography, Grid } from "@material-ui/core";
import { head } from "lodash";
import { useDispatch } from "react-redux";

import { DialogTitle, Dialog, DialogContent } from "../Dialog";
import factorCompanyAPI from "../../api/factorCompany";
import { openSnackbar } from "../../modules/ui";

export default function ConfirmNOADialog({
  open,
  handleExit,
  name,
  companyId,
  paymentProfileId
}) {
  const dispatch = useDispatch();
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={() => handleExit()}>Warning</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Are you sure you'd like to send the Confirm NOA email with the
          attached NOA for this Payment Profile to {name}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Button color="secondary" onClick={handleExit}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                try {
                  await factorCompanyAPI.sendFactorCompanyInvite(
                    companyId,
                    paymentProfileId
                  );
                  dispatch(openSnackbar("success", "NOA Sent!"));
                  handleExit();
                } catch (err) {
                  dispatch(openSnackbar("error", head(err)));
                }
              }}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

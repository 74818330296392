import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { notes } from "../../../api/schema";

export default userId =>
  memoize(state => {
    try {
      const userNotes = get(state, `userNotes.userNotes.${userId}`, []);

      const denormalized = denormalize(
        { userNotes },
        { userNotes: [notes] },
        state.entities
      );

      return denormalized.userNotes;
    } catch (err) {
      return [];
    }
  });

import { normalize } from "normalizr";
import { addEntities } from "../../entities";

const updateReasonForLeaving = reason => async (
  dispatch,
  _,
  { api, schema }
) => {
  const response = await api.factoring.updateReasonForLeaving(reason);
  const data = normalize([response], [schema.reasonForLeaving]);
  dispatch(addEntities(data.entities));
  return response;
};

export default updateReasonForLeaving;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { get } from "lodash";
import awsImageUpload from "../../../helpers/awsImageUpload";
import {
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_REQUEST,
  CREATE_NOTE_SUCCESS
} from "../types";
import { addEntities } from "../../entities";

export default (factorCompanyId, note, attachments) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(CREATE_NOTE_REQUEST)({}));
    const response = await api.factorCompany.createNote(factorCompanyId, {
      note,
      attachments
    });

    const upload = get(response, "attachments.0.upload_url");
    if (upload) {
      await awsImageUpload([
        {
          file: attachments,
          upload_url: upload
        }
      ]);
    }

    const data = normalize(response, schema.notes);

    dispatch(addEntities(data.entities));
    dispatch(
      createAction(CREATE_NOTE_SUCCESS)({
        [factorCompanyId]: [data.result]
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(CREATE_NOTE_FAILURE)(err));
    throw err;
  }
};

import { denormalize } from "normalizr";
import { get } from "lodash";

import { debtors } from "../../../api/schema";

export default state => {
  try {
    const items = get(state, "receivables.debtors.ids", []);
    const denormalized = denormalize(
      { items },
      { items: [debtors] },
      state.entities
    );
    return denormalized.items;
  } catch (err) {
    return [];
  }
};

const tabs = [
  {
    label: "All",
    route: "/admin/debtors/",
    value: ""
  },
  {
    label: "Pending",
    route: "/admin/debtors/pending",
    value: "pending"
  },
  {
    label: "Verify Duplicate",
    route: "/admin/debtors/verify_duplicate",
    value: "verify_duplicate"
  },
  {
    label: "Approved",
    route: "/admin/debtors/approved",
    value: "approved"
  },
  {
    label: "Declined",
    route: "/admin/debtors/declined",
    value: "declined"
  },
  {
    label: "Do Not Contact",
    route: "/admin/debtors/do_not_contact",
    value: "do_not_contact"
  },
  {
    label: "Needs Review",
    route: "/admin/debtors/needs_review",
    value: "needs_review",
    color: "red"
  },
];

export default tabs;

import { createAction } from "redux-starter-kit";
import { deleteRow } from "../../entities";
import { DELETE_REFERRAL_SOURCE_REQUEST } from "../types";

const deleteReferralSource = id => async (
  dispatch,
  getState,
  { api, schema }
) => {
  const response = await api.factoring.deleteReferralSource(id);
  dispatch(
    createAction(DELETE_REFERRAL_SOURCE_REQUEST)({
      id
    })
  );
  dispatch(deleteRow({ entity: "referralSource", id }));
  return response;
};

export default deleteReferralSource;

import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  filter,
  get,
  indexOf,
  isEmpty,
  head,
  sum,
  isInteger,
  find,
  merge
} from 'lodash';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHdd,
  faSpinner,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

import Table from '../../../../components/Table';
import InternalLayout from '../../../../components/layouts/InternalLayout';
import Can from '../../../../components/Can';
import CSVButton from '../../../../components/buttons/CSVButton';
import AdjustmentsDialog from './components/AdjustmentsDialog';
import FollowUp from './components/FollowUp';
import MoreButton from './components/MoreButton';
import fundingRequestReceivables from './columns';
import tabs from '../tabs';
import NavigationTabs from '../../../../components/NavigationTabs';
import Link from '../../../../components/Link';
import ReceivablesAttachment from './components/ReceivablesAttachment';
import PaymentsHistory from './components/PaymentsHistory';
import NotesDialog from '../../../../components/dialogs/NotesDialog';
import TimeZone from '../../../../components/TimeZone';
import Filters from './components/Filters';
import formatPennies from '../../../../helpers/format/formatPennies';

import CustomTextField, {
  CustomPenniesField
} from '../../../../components/inputs/CustomTextField';
import DebtorInput from '../../../../components/inputs/DebtorInput';
import PenniesInput from '../../../../components/inputs/PenniesInput';
import DatePicker from '../../../../components/inputs/DatePicker';
import TypeInput from './components/TypeInput';
import EmailTemplates from '../../Debtor/Payment/components/EmailTemplates';
import { createPaymentForward } from '../../../../modules/debtor';
import ClientAutoComplete from '../../../../components/inputs/ClientAutoComplete';
import colors from '../../../../theme/colors';
import Yup from '../../../../YupValidation';
import UploadBulkAttachments from '../../Debtor/Payment/components/UploadBulkAttachments';
import WithSimilarPaymentProfile from '../WithSimilarPaymentProfile';
import { payment as paymentSchema } from './validation';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import SelfFinanceIcon from '../../../../components/icons/SelfFinanceIcon';

const styles = {
  root: {
    background: 'linear-gradient(45deg, #2EBD00 30%, #4dc527 90%)'
  },
  label: {
    fontFamily: 'Avenir-Medium',
    textTransform: 'capitalize',
    color: colors.white
  }
};

export default withStyles(styles)(
  ({
    statusIndex,
    handleCSV,
    selectedReceivables,
    fetchLineItems,
    receivableLineItems,
    selectedReceivable,
    setSelectedReceivable,
    createLineItem,
    open,
    email,
    handleLogout,
    handleDrawerOpen,
    handleDrawerClose,
    openUserSetting,
    receivables = [],
    isSelected,
    allSelected,
    handleSelect,
    handleSelectAllClick,
    dialog,
    closeDialog,
    openDialog,
    openModal,
    LineItemCategories,
    selected,
    createDebtorPayment,
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearchFilter,
    isLoading,
    sortDirection,
    sortBy,
    count,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    filters,
    openSnackbar,
    createPaymentsFile,
    refresh,
    userType,
    clearSelected,
    singleDebtorSelected,
    byId,
    match,
    fetchFundingRequestReceivables,
    debtorId,
    ...props
  }) => {
    const idPaymentProfile = get(match, ['params', 'id'], '');
    const initialState = {
      rowsForm: [],
      amountAdded: [],
      totalAmount: 0,
      displayBulkAttachmentsModal: false,
      displayAttachmentsModal: false,
      fundingRequestId: '',
      selectAllAction: false
    };

    const [updated, setUpdated] = useState(false);
    const [confirmOnSubmitPayment, setConfirmOnSubmitPayment] = useState(false);
    const formRef = useRef();
    const dispatch = useDispatch();
    const [status, setStatus] = useState([]);

    const paymentForm = (
      selected,
      receivables,
      openSnackbar,
      debtorId,
      createDebtorPayment,
      clearSelected,
      receivablesById,
      refresh
    ) => {
      const onSubmitPaymentForm = async values => {
        try {
          formRef.current.setSubmitting(true);
          const data = {
            amount: Math.round(Number(values.amount) * 100),
            category: values.type,
            sch: values.sch,
            paid_date: values.paid_date,
            notes: values.notes.length > 0 ? values.notes : undefined,
            funding_requests: selected.map(id => ({
              id: get(receivablesById, [id, 'funding_request', 'id']),
              amount_applied: Math.round(Number(values[id]) * 100)
            }))
          };
          await createDebtorPayment.apply(
            this,
            singleDebtorSelected
              ? [debtorId, data]
              : [debtorId, data, { diff_debtor: true }]
          );
          await refresh();
          clearSelected();
          dispatch(openSnackbar('success', 'Applied Debtor Payment!'));
          formRef.current.resetForm();
        } catch (err) {
          dispatch(openSnackbar('error', head(err)));
        } finally {
          formRef.current.setSubmitting(false);
          setConfirmOnSubmitPayment(false);
          dispatch(closeDialog());
        }
      };
      return {
        enableReinitialize: true,
        validationSchema: paymentSchema(selected),
        initialValues: {
          paid_date: '',
          type: '',
          sch: '',
          amount: 0,
          notes: '',
          ...receivables.reduce(
            (acc, curr) =>
              merge({}, acc, { [curr.id]: Number(curr.amount_balance) / 100 }),
            {}
          )
        },
        onSubmit: async (values, { setSubmitting, isSubmitting }) => {
          const hasNegativeReserve =
            filter(
              receivables,
              fundingRequest =>
                selected.includes(fundingRequest.id) &&
                get(fundingRequest, 'reserve_info.has_negative_reserve') &&
                get(fundingRequest, 'status', []).includes([
                  'non_factored_open',
                  'non_factored_over_paid',
                  'non_factored_short_paid',
                  'non_factored'
                ])
            ).length > 0;
          return hasNegativeReserve && !confirmOnSubmitPayment
            ? dispatch(
              openDialog('invoice-confirm-negative-margin', 'Confirm', '', {
                isSubmitting
              })
            )
            : onSubmitPaymentForm(values);
        }
      };
    };

    const formattedRows = receivables.map(row => {
      return {
        ...row,
        debtor_name: () => (
          <Grid container direction="row" alignItems="center" spacing={8}>
            <Grid item>
              <Link to={`/admin/debtors/${get(row, 'debtor', '')}/profile`}>
                {get(row, 'debtor_name', '')}
              </Link>
            </Grid>
            <Grid item>
              <TimeZone zone={get(row, 'debtor_operating_timezone', '')} />
            </Grid>
          </Grid>
        ),
        company_name: () => (
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Link
                to={`/admin/factorclients/${get(
                  row,
                  ['factoring_id'],
                  ''
                )}/profile`}
              >
                {get(row, ['factoring_company_profile', 'name'], '')}
              </Link>
            </Grid>
          </Grid>
        ),
        funding_request_invoice_number: () => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
            <div>
              {get(row, 'funding_request_invoice_number', '')}
            </div>
            <SelfFinanceIcon contractType={get(row, 'contract_type')} />
          </div>
        ),
        payment_profile_name: () => (
          <Link
            to={`/admin/payment/${get(row, 'payment_profile_id', '')}/profile`}
          >
            {get(row, 'payment_profile_name', '')}
          </Link>
        ),
        amount_balance: ({ isSelected, setFieldValue, values }) =>
          isSelected ? (
            <PenniesInput
              name={row.id}
              value={values[row.id]}
              setFieldValue={setFieldValue}
              withStyle
            />
          ) : (
            formatPennies(row.amount_balance)
          ),
        more: (
          <MoreButton
            receivableId={get(row, 'id', '')}
            id={get(row, 'debtor', '')}
            frid={get(row, ['funding_request', 'id'], '')}
            status={get(row, 'status')}
          />
        )
      };
    });

    const formColumn = {
      id: Math.floor(Math.random() * 999 + 1),
      invoice_number: {
        default: '#####'
      },
      charge_non_factored_fee: {
        initialValue: true
      },
      carrier: {
        initialValue: '',
        component: ({
          field,
          form: { touched, setFieldValue, handleBlur, errors },
          ...inputProps
        }) => (
          <div>
            <ClientAutoComplete
              id="client"
              {...field}
              {...inputProps}
              DropdownIndicator={false}
              onChange={carrier => {
                setFieldValue('carrier', carrier);
              }}
              hasError={touched[field.name] && errors[field.name]}
            />
          </div>
        )
      },
      amount: {
        initialValue: 0.0,
        component: ({
          field,
          form: { touched, errors, setFieldValue, handleBlur, ...form },
          ...inputProps
        }) => (
          <div style={{ paddingTop: 5 }}>
            <CustomPenniesField
              id="amount"
              {...field}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              {...inputProps}
              error={touched[field.name] && errors[field.name]}
            />
          </div>
        )
      },
      debtor_name: {
        initialValue: '',
        component: ({
          field,
          form: { touched, errors, setFieldValue, handleBlur, ...form },
          ...inputProps
        }) => (
          <div style={{ paddingBottom: 12 }}>
            <DebtorInput
              fullWidth
              customInput
              id="debtor"
              {...field}
              setFieldValue={setFieldValue}
              {...inputProps}
              {...form}
              error={touched[field.name] && errors[field.name]}
            />
          </div>
        )
      },
      amount_due: {
        initialValue: 0.0,
        component: ({
          field,
          form: { touched, errors, setFieldValue, handleBlur, values, ...form },
          ...inputProps
        }) => (
          <div style={{ paddingTop: 5 }}>
            <CustomPenniesField
              id="amount_due"
              {...field}
              setFieldValue={(...args) => {
                setFieldValue(...args);
              }}
              onBlur={value => {
                setAmountAdded({ id: values.id, amount: value });
                setTimeout(() => setUpdated(true), 500);
              }}
              {...inputProps}
              error={touched[field.name] && errors[field.name]}
            />
          </div>
        )
      },
      user_load_number: {
        initialValue: '',
        component: ({ field, form: { touched, errors }, ...inputProps }) => (
          <div style={{ paddingTop: 5 }}>
            <CustomTextField
              id="user_load_number"
              {...field}
              {...inputProps}
              error={touched[field.name] && errors[field.name]}
            />
          </div>
        )
      },
      actions: ({ handleSubmit, setFieldValue, index, isSubmitting }) => {
        return (
          <Grid container style={{ minWidth: '200px' }}>
            <Grid item>
              <Tooltip title="Save">
                <IconButton
                  id="save"
                  style={{ width: '48px', height: '48px' }}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FontAwesomeIcon
                      color={colors.green_dark}
                      spin
                      icon={faSpinner}
                    />
                  ) : (
                    <FontAwesomeIcon color={colors.green_dark} icon={faHdd} />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Switch
                name="charge_non_factored_fee"
                onChange={(event, checked) =>
                  setFieldValue('charge_non_factored_fee', checked)
                }
                defaultChecked
              />
              <Typography style={{ paddingLeft: 10 }} variant="caption">
                NF Fee
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="delete">
                <IconButton
                  style={{ width: '48px', height: '48px' }}
                  onClick={() => deleteRow(index)}
                >
                  <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      }
    };

    function reducer(state, action) {
      let form = [];
      switch (action.type) {
        case 'ADD_ROW':
          return { ...state, rowsForm: [formColumn, ...state.rowsForm] };
        case 'OPEN_BULK_ATTACHMENT_MODAL':
          return { ...state, displayBulkAttachmentsModal: true };
        case 'CLOSE_BULK_ATTACHMENT_MODAL':
          return { ...state, displayBulkAttachmentsModal: false };
        case 'OPEN_ATTACHMENT_MODAL':
          return {
            ...state,
            displayAttachmentsModal: true,
            fundingRequestId: action.payload
          };
        case 'CLOSE_ATTACHMENT_MODAL':
          return {
            ...state,
            displayAttachmentsModal: false,
            fundingRequestId: ''
          };
        case 'DELETE_ROW':
          form = filter(state.rowsForm, row => row.id !== action.payload);
          return { ...state, rowsForm: form };
        case 'ADD_AMOUNT':
          form = filter(
            state.amountAdded,
            object => object.id !== action.payload.id
          );
          return {
            ...state,
            amountAdded: [...form, action.payload]
          };
        case 'TOTAL_AMOUNT':
          return {
            ...state,
            totalAmount: action.payload
          };
        case 'DELETE_SELECTED_INDEX':
          if (indexOf(selected, action.payload) !== -1) {
            handleSelect(action.payload);
          }
          return state;
        case 'SELECT_ALL_ACTION':
          return {
            ...state,
            selectAllAction: !state.selectAllAction
          };
        default:
          throw new Error();
      }
    }

    const [state, localDispatch] = useReducer(reducer, initialState);

    const handleAddInvoice = () => {
      localDispatch({ type: 'ADD_ROW' });
    };

    const handleAddAttachments = () =>
      localDispatch({ type: 'OPEN_BULK_ATTACHMENT_MODAL' });
    const handleAddAttachment = payload =>
      localDispatch({ type: 'OPEN_ATTACHMENT_MODAL', payload });

    const deleteRow = index => {
      localDispatch({ type: 'DELETE_ROW', payload: index });
      localDispatch({ type: 'DELETE_SELECTED_INDEX', payload: index });
    };

    const setAmountAdded = payload => {
      localDispatch({ type: 'ADD_AMOUNT', payload });
    };

    const setTotalAmount = payload => {
      localDispatch({ type: 'TOTAL_AMOUNT', payload });
    };

    const formValidation = Yup.object().shape({
      amount: Yup.number().required('Please fill out this field'),
      carrier: Yup.object().shape({
        id: Yup.string().required('Please fill out this field')
      }),
      debtor_name: Yup.object().shape({
        id: Yup.string().required('Please fill out this field')
      }),
      user_load_number: Yup.string().required('Please fill out this field')
    });

    const onSubmit = useCallback(
      async (index, fields, { setSubmitting }) => {
        try {
          setSubmitting(true);
          const res = await dispatch(
            createPaymentForward({
              debtor_id: get(fields, 'debtor_name.id'),
              factoring_id: fields.carrier.id,
              amount: fields.amount * 100,
              user_load_number: fields.user_load_number,
              charge_non_factored_fee: fields.charge_non_factored_fee,
              payment_profile_id: idPaymentProfile,
              filters: { response_type: 'finance_receivables' }
            })
          );
          deleteRow(index);
          if (indexOf(selected, index) !== -1) {
            const amount = get(formRef, `current.state.amountAdded`, 0);
            if (amount > 0) {
              formRef.current.setFieldValue(
                'amountAdded',
                amount - Number(fields.amount)
              );
            }
            handleSelect(res.id);
          }
          dispatch(openSnackbar('success', 'Invoice successfully added.'));
          handleAddAttachment(res.id);
        } catch (err) {
          dispatch(openSnackbar('error', head(err)));
        }
        setSubmitting(false);
      },
      [selected]
    );

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }
    const prevSelected = usePrevious(selected);

    useEffect(() => {
      const asyncFunction = async () => {
        try {
          if (
            selected.length &&
            get(
              formRef,
              `current.props.initialValues.${selected[selected.length - 1]}`
            ) &&
            (prevSelected || []).length < (selected || []).length &&
            !state.selectAllAction
          ) {
            const id = selected[selected.length - 1];
            const amountDue = Number(
              (find(receivables, ['id', id]) || {}).amount_balance || 0
            );
            formRef.current.validateForm();
            return formRef.current.setFieldValue(
              id,
              amountDue > 0 ? amountDue / 100 : amountDue
            );
          }
          if (
            selected.length &&
            (prevSelected || []).length < (selected || []).length &&
            state.selectAllAction
          ) {
            selected.map(id => {
              const amountDue = Number(
                (find(receivables, ['id', id]) || {}).amount_balance || 0
              );
              formRef.current.validateForm();
              return formRef.current.setFieldValue(
                id,
                amountDue > 0 ? amountDue / 100 : amountDue
              );
            });
            return localDispatch({ type: 'SELECT_ALL_ACTION' });
          }
          if (isEmpty(selected)) {
            formRef.current.validateForm();
            return formRef.current.setFieldValue('amountAdded', 0);
          }
          const totalAmount = sum(
            selected.map(id => {
              let amount = 0;
              if (isInteger(id)) {
                amount =
                  (find(state.amountAdded, ['id', id]) || {}).amount || 0;
                return Number(amount);
              }
              return Number(amount) / 100;
            })
          );
          setTotalAmount(totalAmount);
          formRef.current.validateForm();
          formRef.current.setFieldValue('amountAdded', totalAmount);
          setUpdated(false);
        } catch (e) { }
      };
      asyncFunction();
      return () => ({});
    }, [selected, updated, state.selectAllAction]);

    const disabledSettleButton = !!filter(
      selected,
      num => typeof num === 'number'
    ).length;

    const id = get(props, ['match', 'params', 'id'], '');
    const [countPaymentProfile] = WithSimilarPaymentProfile(idPaymentProfile);

    return (
      <Can
        perform="admin-finances:view"
        yes={() => (
          <InternalLayout
            open={open}
            title="Payment Profile"
            email={email}
            handleLogout={handleLogout}
            openUserSetting={openUserSetting}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          >
            <Formik
              {...paymentForm(
                selected,
                receivables,
                openSnackbar,
                debtorId,
                createDebtorPayment,
                clearSelected,
                byId,
                refresh
              )}
              innerRef={formRef}
            >
              {({
                handleSubmit,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue
              }) => (
                <>
                  <div style={{ margin: 10 }}>
                    <Typography variant="h5">All Receivables</Typography>
                  </div>
                  <NavigationTabs
                    tabs={tabs(idPaymentProfile, countPaymentProfile)}
                    value={2}
                  />
                  <Paper style={{ marginTop: 32, padding: 16 }}>
                    <Grid container justify="space-between">
                      <Grid item container md={12} lg={8}>
                        <Can
                          perform="admin-finances-receivables:post-payment"
                          yes={() => (
                            <Grid container spacing={32}>
                              <Grid item lg={4}>
                                <Grid container direction="column" spacing={16}>
                                  <Grid item lg={12}>
                                    <TypeInput
                                      values={values}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </Grid>
                                  <Grid item lg={12}>
                                    <CustomTextField
                                      fullWidth
                                      label="Reference Number#"
                                      name="sch"
                                      value={values.sch}
                                      onChange={handleChange}
                                      error={!!errors.sch && touched.sch}
                                    />
                                  </Grid>
                                  <Grid item lg={12}>
                                    <PenniesInput
                                      fullWidth
                                      label="Amount"
                                      name="amount"
                                      value={values.amount}
                                      setFieldValue={setFieldValue}
                                      error={!!errors.amount}
                                      helperText={
                                        errors.amount ? errors.amount : ''
                                      }
                                      withStyle
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item lg={4}>
                                <Grid container direction="column" spacing={16}>
                                  <Grid item lg={12}>
                                    <CustomTextField
                                      fullWidth
                                      label="Notes"
                                      name="notes"
                                      value={values.notes}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={!!errors.notes && touched.notes}
                                      helperText={
                                        !!errors.notes && touched.notes
                                          ? errors.notes
                                          : ''
                                      }
                                    />
                                  </Grid>
                                  <Grid item lg={12}>
                                    <DatePicker
                                      fullWidth
                                      name="paid_date"
                                      label="Payment Posting Date"
                                      onBlur={handleBlur}
                                      value={
                                        values.paid_date === ''
                                          ? null
                                          : values.paid_date
                                      }
                                      onChange={date => {
                                        setFieldValue(
                                          'paid_date',
                                          date.toISO()
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        direction="column"
                        md={12}
                        lg={4}
                        spacing={8}
                      >
                        <Grid container direction="row-reverse" spacing={16}>
                          <Grid item>
                            <ReceivablesAttachment
                              isUploading={false}
                              openSnackbar={openSnackbar}
                              createPaymentsFile={createPaymentsFile}
                            />
                          </Grid>
                          <Can
                            perform="admin-finances-receivables:edit"
                            yes={() => (
                              <Grid item>
                                <CSVButton handleCSV={handleCSV} />
                              </Grid>
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <EmailTemplates
                            selected={selected}
                            textFieldWidth={6}
                          />
                        </Grid>
                        <Can
                          perform="admin-finances-receivables:edit"
                          yes={() => (
                            <Grid item container spacing={16}>
                              <Grid item container justify="flex-end">
                                <Button
                                  style={{
                                    backgroundColor: colors.link,
                                    color: colors.white
                                  }}
                                  mini
                                  variant="contained"
                                  onClick={handleAddAttachments}
                                  disabled={
                                    disabledSettleButton || !selected.length
                                  }
                                >
                                  Add Attachments
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        />
                        <Grid
                          container
                          direction="row-reverse"
                          spacing={16}
                          style={{ paddingBottom: '10px' }}
                        >
                          <>
                            <Can
                              perform="admin-finances-receivables:edit"
                              yes={() => (
                                <Grid item>
                                  <Button
                                    disabled={isEmpty(selected)}
                                    variant="contained"
                                    color="primary"
                                    mini
                                    style={{
                                      backgroundColor: colors.green,
                                      color: colors.white
                                    }}
                                    onClick={() =>
                                      openDialog('', '', '', {}, 'Adjustments')
                                    }
                                  >
                                    Settle
                                  </Button>
                                </Grid>
                              )}
                            />
                            <Can
                              perform="admin-finances-receivables:post-payment"
                              yes={() => (
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSubmit()}
                                    disabled={isSubmitting}
                                    mini
                                  >
                                    Submit
                                  </Button>
                                </Grid>
                              )}
                            />
                          </>
                          <Grid item>
                            <Button
                              variant="contained"
                              disabled={isEmpty(selected)}
                              style={{
                                backgroundColor: colors.light_orange,
                                color: colors.white
                              }}
                              onClick={() => {
                                dispatch(
                                  openDialog('receivables-notes', '', '')
                                );
                              }}
                              mini
                            >
                              + Add FollowUp
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: 20 }}>
                      <Filters
                        handleSearchFilter={handleSearchFilter}
                        {...props}
                      />
                      <Table
                        columns={fundingRequestReceivables(
                          status,
                          setStatus,
                          userType
                        )}
                        rows={formattedRows}
                        isLoading={isLoading}
                        handleSort={handleSort}
                        sortDirection={sortDirection}
                        sortBy={sortBy}
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        filter
                        handleFilterChange={event => {
                          clearSelected();
                          handleSearchFilter(event);
                        }}
                        form
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        values={values}
                        allowEmpty
                        select
                        isSelected={isSelected}
                        handleSelect={handleSelect}
                        handleSelectAllClick={values => {
                          handleSelectAllClick(values);
                          localDispatch({ type: 'SELECT_ALL_ACTION' });
                        }}
                        allSelected={allSelected}
                        formKeys={[
                          'amount',
                          'carrier',
                          'amount_due',
                          'user_load_number',
                          'debtor_name',
                          'payment_profile_name',
                          'charge_non_factored_fee'
                        ]}
                        filters={filters}
                        rowsForm={state.rowsForm}
                        formTableProps={{
                          onSubmit,
                          validationSchema: formValidation
                        }}
                      />
                    </Grid>
                  </Paper>
                  <UploadBulkAttachments
                    open={state.displayBulkAttachmentsModal}
                    handleClose={() =>
                      localDispatch({ type: 'CLOSE_BULK_ATTACHMENT_MODAL' })
                    }
                    selected={selected.map(id =>
                      get(byId, [id, 'funding_request', 'id'])
                    )}
                  />
                  <UploadBulkAttachments
                    open={state.displayAttachmentsModal}
                    handleClose={() =>
                      localDispatch({ type: 'CLOSE_ATTACHMENT_MODAL' })
                    }
                    selected={[state.fundingRequestId]}
                  />
                  {dialog.action === 'Adjustments' && (
                    <AdjustmentsDialog
                      handleClose={closeDialog}
                      open={dialog.show}
                      openModal={openModal}
                      receivables={selectedReceivables}
                      fetchLineItems={fetchLineItems}
                      lineItems={receivableLineItems}
                      selectedReceivable={selectedReceivable}
                      setSelectedReceivable={setSelectedReceivable}
                      LineItemCategories={LineItemCategories}
                      createLineItem={createLineItem}
                      {...dialog.data}
                    />
                  )}
                  {dialog.action === 'followup' && (
                    <FollowUp
                      userType={userType}
                      handleClose={closeDialog}
                      open={dialog.show}
                      openModal={openModal}
                      receivables={selectedReceivables}
                      createDebtorPayment={createDebtorPayment}
                      {...dialog.data}
                    />
                  )}
                  {dialog.variant === 'receivables-notes' && (
                    <NotesDialog
                      receivableId={get(dialog, ['data', 'receivableId'])}
                      selected={selected}
                      refresh={refresh}
                      userType={userType}
                      status={get(dialog, ['data', 'status'])}
                    />
                  )}
                  {dialog.variant === 'invoice-confirm-negative-margin' && (
                    <ConfirmDialog
                      open={dialog.show}
                      message={
                        <Grid container spacing={16}>
                          <Grid item>
                            <Typography variant="body1">
                              This client has a negative reserve.
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1">
                              Are you sure you want to apply payment to the
                              selected invoices?
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      isSubmitting={get(dialog, 'data.isSubmitting', false)}
                      handleConfirm={() => {
                        setConfirmOnSubmitPayment(true);
                        formRef.current.handleSubmit();
                      }}
                      handleExit={() => {
                        dispatch(closeDialog());
                        formRef.current.setSubmitting(false);
                      }}
                    />
                  )}
                  <PaymentsHistory />
                </>
              )}
            </Formik>
          </InternalLayout>
        )}
        no={() => <Redirect to="/" />}
      />
    );
  }
);

import { assign } from "lodash";
import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loadingUser: true
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        users: assign({}, state.users, action.payload)
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loadingUser: false
      };
    default:
      return state;
  }
};

import React from 'react';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import { Grid, Paper, Typography } from '@material-ui/core';

import InternalLayout from '../../../../components/layouts/InternalLayout';
import Can from '../../../../components/Can';
import NavigationTabs from '../../../../components/NavigationTabs';
import Table from '../../../../components/Table';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import AuditLogDialog from './components/AuditLogDialog';
import MasterStar from '../../../../components/icons/MasterStar';
import CSVButton from '../../../../components/buttons/CSVButton';
import ClientAutoComplete from '../../../../components/inputs/ClientAutoComplete';

export default ({
  tabs,
  navIndex,
  handleRoute,
  columns,
  companyName,
  connectedClients,
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  isLoading,
  sortDirection,
  sortBy,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter,
  connectedClientsForm,
  dialog,
  closeDialog,
  filters,
  master,
  createConnectedClientsCSV,
  handleConfirmDeleteRelationship,
  userType
}) => {
  return (
    <Can
      perform="admin-debtor-payment:view"
      yes={() => (
        <InternalLayout title="Debtor Connected Clients">
          <Grid container style={{ paddingBottom: '24px' }}>
            <Grid item>
              <Grid container spacing={16}>
                <Grid item>{master === true && <MasterStar />}</Grid>
                <Grid item>
                  <Typography variant="h6">{companyName}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <NavigationTabs
            tabs={tabs}
            value={navIndex}
            handleRoute={handleRoute}
          />
          <Paper style={{ paddingLeft: 16, marginTop: 32 }}>
            <Grid container direction="column">
              <Grid
                item
                container
                direction="row"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Grid item xs="4">
                  <ClientAutoComplete
                    style={{
                      margin: 16,
                    }}
                    label="Client Search"
                    id="client"
                    name="client"
                    DropdownIndicator={false}
                    onChange={(carrier) =>
                      handleSearchFilter({
                        target: {
                          name: 'client_name',
                          value: get(carrier, 'company_profile.name', ''),
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs="2" />
                <Can
                  perform="admin-debtor-profile-connected_clients:downloadReport"
                  yes={() => (
                    <Grid
                      item
                      xs="6"
                      container
                      alignContent="flex-end"
                      justify="flex-end"
                      alignItems="flex-end"
                    >
                      <div
                        style={{
                          margin: 16,
                        }}
                      >
                        <CSVButton handleCSV={createConnectedClientsCSV} />
                      </div>
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item>
                <Table
                  isLoading={isLoading}
                  columns={columns(userType)}
                  rows={connectedClients}
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  sort
                  sortDirection={sortDirection}
                  sortBy={sortBy}
                  handleSort={handleSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleSearchFilter={handleSearchFilter}
                  formikRow={connectedClientsForm}
                  allowEmpty
                  filters={filters}
                />
              </Grid>
            </Grid>
          </Paper>
          {dialog.variant === 'auditlog' && (
            <AuditLogDialog
              open={dialog.show}
              handleClose={closeDialog}
              relationshipId={get(dialog, ['data', 'relationshipId'])}
            />
          )}
          {dialog.variant === 'delete-relation' && (
            <ConfirmDialog
              open={dialog.show}
              title={dialog.title}
              message={dialog.message}
              handleConfirm={handleConfirmDeleteRelationship}
              handleExit={() => closeDialog()}
            />
          )}
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
};

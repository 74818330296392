import { assign } from "lodash";
import {
  FETCH_PENDING_INVITE_REQUEST,
  FETCH_PENDING_INVITE_SUCCESS,
  FETCH_PENDING_INVITE_FAILURE
} from "../types";

export default (
  state = {
    pendingInvites: {
      loading: false,
      ids: []
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_PENDING_INVITE_REQUEST:
      return {
        pendingInvites: assign({}, state.pendingInvites, {
          ids: [],
          loading: true
        })
      };
    case FETCH_PENDING_INVITE_SUCCESS:
      return {
        ...state,
        pendingInvites: assign({}, state.pendingInvites, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        })
      };
    case FETCH_PENDING_INVITE_FAILURE:
      return {
        pendingInvites: assign({}, state.pendingInvites, {
          loading: false
        })
      };
    default:
      return state;
  }
};

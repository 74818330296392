import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

export default ({ title, description, chart }) => (
  <Card>
    <CardContent>
      <Grid container spacing={16}>
        <Grid container item xs={6} spacing={32}>
          <Grid container item xs={12}>
            <Typography variant="subtitle1">{title}</Typography>
          </Grid>
          <Grid container item xs={12} justify="flex-end" alignItems="center">
            {description}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={6}
          justify="center"
          alignItems="center"
          style={{ width: "100%" }}
        >
          {chart}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export const SUCCESS = "success";
export const WARNING = "warning";
export const ERROR = "error";
export const INFO = "info";

export default {
  SUCCESS,
  WARNING,
  INFO,
  ERROR
};

import { denormalize } from "normalizr";
import { get } from "lodash";
import { compose } from "recompose";
import { createReducer } from "redux-starter-kit";

import createCSV from "../../helpers/createCSV";
import { fundingRequest } from "../../api/schema";
import withTable from "../withTable";

import queryFundingRequests from "./actions/queryFundingRequests";
import queryConnectedDebtors, {
  next as nextQueryConnectedDebtors
} from "./actions/queryConnectedDebtors";
import queryDebtors from "./actions/queryDebtors";
import queryConnectedClients, {
  next as nextQueryConnectedClients
} from "./actions/queryConnectedProfile";

import fetchFactoringProfileReducer from "./reducers/fetchFactoringProfile";
import fetchAttachmentsReducer from "./reducers/fetchAttachments";
import fetchUserReducer from "./reducers/fetchUser";
import fetchReserveAggregateReducer from "./reducers/fetchReserveAggregate";
import fetchPlaidUserReducer from "./reducers/fetchPlaidUser";
import queryFundingRequestsReducer from "./reducers/queryFundingRequests";
import queryConnectedDebtorsReducer from "./reducers/queryConnectedDebtors";
import queryConnectedProfileReducer from "./reducers/queryConnectedProfile";
import queryDebtorsReducer from "./reducers/queryDebtors";
import fetchAccountsReducer from "./reducers/fetchAccounts";
import createAccountReducer from "./reducers/createAccount";
import fetchNotesTimelineReducer from "./reducers/fetchNotesTimeline";
import fetchReserveReducer from "./reducers/fetchReserve";
import uploadAttachmentsReducer from "./reducers/uploadAttachments";
import deleteAttachmentReducer from "./reducers/deleteAttachment";
import createFactoringNoteReducer from "./reducers/createFactoringNote";
import deleteFactoringNoteReducer from "./reducers/deleteFactoringNote";
import fetchFactoringCollatedFilesReducer from "./reducers/fetchFactoringCollatedFiles";

import * as TYPES from "./types";
import formatPennies from "../../helpers/format/formatPennies";

const {
  selectors: fundingRequestsSelectors,
  actions: fundingRequestsActions,
  reducer: fundingRequestsTableReducer,
  middleware: fundingRequestsMiddleware
} = withTable({
  stateKey: "factoringClient.fundingRequests",
  typeKey: "factoringclient/fundingRequests"
});

const {
  selectors: connectedDebtorsSelectors,
  actions: connectedDebtorsActions,
  reducer: connectedDebtorsTableReducer,
  middleware: connectedDebtorsMiddleware
} = withTable({
  stateKey: "factoringClient.connectedDebtors",
  typeKey: "factoringclient/fetch_connecteddebtors"
});

const {
  selectors: connectedCarriersSelectors,
  actions: connectedCarriersActions,
  reducer: connectedCarriersTableReducer,
  middleware: connectedCarriersMiddleware
} = withTable({
  stateKey: "factoringClient.connectedBrokersCarriers",
  typeKey: "factoringclient/fetch_connectedcarriers"
});

const {
  selectors: connectDebtorSelectors,
  actions: connectDebtorActions,
  reducer: connectDebtorTableReducer,
  middleware: connectDebtorMiddleware
} = withTable({
  stateKey: "factoringClient.connectDebtor",
  typeKey: "factoringclient/connectDebtor"
});

export { default as getDebtorRelationHistory } from "./selectors/getDebtorRelationHistory";
export { default as getActiveReserve } from "./selectors/getActiveReserve";
export { default as getStats } from "./selectors/getStats";
export { default as getDebtors } from "./selectors/getDebtors";
export { default as getUserId } from "./selectors/getUserId";
export { default as getAccounts } from "./selectors/getAccounts";
export { default as getReserve } from "./selectors/getReserve";
export { default as getAttachments } from "./selectors/getAttachments";
export { default as getNotesTimelineMore } from "./selectors/getNotesTimelineMore";
export { default as getCollatedAttachments } from "./selectors/getCollatedAttachments";
export { default as getPendingNegativeReserve } from "./selectors/getPendingNegativeReserve";
export { default as getFactorClient } from "./selectors/getFactorClient";
export { default as getPlaidUser } from "./selectors/getPlaidUser";
export { default as getUser } from "./selectors/getUser";
export { default as getFundingRequests } from "./selectors/getFundingRequests";
export { default as getConnectedDebtors } from "./selectors/getConnectedDebtors";
export { default as getConnectedProfiles } from "./selectors/getConnectedProfiles";
export { default as getNotesTimeline } from "./selectors/getNotesTimeline";
export { default as getFactoringProfileHistory } from "./selectors/getFactoringProfileHistory";
export { default as getNoaAttatchmentFile } from "./selectors/getNoaAttatchmentFile";

export { default as fetchStats } from "./actions/fetchStats";
export { default as deleteFactoringNote } from "./actions/deleteFactoringNote";
export { default as updateUser } from "./actions/updateUser";
export { default as updateFactoringProfile } from "./actions/updateFactoringProfile";
export { default as fetchReserve } from "./actions/fetchReserve";
export { default as uploadAttachments } from "./actions/uploadAttachments";
export { default as createBankAccount } from "./actions/createBankAccount";
export { default as createCardAccount } from "./actions/createCardAccount";
export { default as createWireAccount } from "./actions/createWireAccount";
export { default as fetchAccounts } from "./actions/fetchAccounts";
export { default as deleteClientProfile } from "./actions/deleteClientProfile";
export { default as createFactoringNote } from "./actions/createFactoringNote";
export { default as updateFactoringNote } from "./actions/updateFactoringNote";
export { default as fetchTerms } from "./actions/fetchTerms";
export { default as brokerCreateFR } from "./actions/brokerCreateFR";
export { default as updateTerms } from "./actions/updateTerms";
export { default as createFactoringDebtorRelationship } from "./actions/createFactoringDebtorRelationship";
export { default as createFundingRequest } from "./actions/createFundingRequest";
export { default as queryConnectedProfile } from "./actions/queryConnectedProfile";
export { default as deleteDebtorRelation } from "./actions/deleteDebtorRelation";
export { default as fetchDebtorRelationHistory } from "./actions/fetchDebtorRelationHistory";
export { default as deleteAttachment } from "./actions/deleteAttachment";
export { default as updateFactoringDebtor } from "./actions/updateFactoringDebtor";
export { default as fetchFactoringProfile } from "./actions/fetchFactoringProfile";
export { default as fetchAttachments } from "./actions/fetchAttachments";
export { default as defaultBankAccount } from "./actions/defaultBankAccount";
export { default as CarrierDefaultBankAccount } from "./actions/CarrierDefaultAccount";
export { default as fetchExport } from "./actions/fetchExport";
export { default as fetchFactoringCollatedFiles } from "./actions/fetchFactoringCollatedFiles";
export { default as fetchUser } from "./actions/fetchUser";
export { default as sendTransferDocuments } from "./actions/sendTransferDocuments";
export { default as fetchReserveAggregate } from "./actions/fetchReserveAggregate";
export { default as fetchPlaidUser } from "./actions/fetchPlaidUser";
export { default as queryConnectedDebtors } from "./actions/queryConnectedDebtors";
export { default as queryFundingRequests } from "./actions/queryFundingRequests";
export { default as updateConnectedFactoringProfile } from "./actions/updateConnectedFactoringProfile";
export { default as queryDebtors } from "./actions/queryDebtors";
export { default as deleteAccount } from "./actions/deleteAccount";
export { default as fetchNotesTimeline } from "./actions/fetchNotesTimeline";
export { default as fetchNotesTimelineNext } from "./actions/fetchNotesTimelineNext";
export { default as fetchFactoringProfileHistory } from "./actions/fetchFactoringProfileHistory";
export { default as updateBankAccount } from "./actions/updateBankAccount";
export { default as createCheckAccount } from "./actions/createCheckAccount";
export { default as createSwiftAccount } from "./actions/createSwiftAccount";

export const getConnectDebtorIsLoading = state =>
  get(state, "factoringClient.connectDebtor.loading", false);
export const getConnectDebtorCount = connectDebtorSelectors.getCount;
export const getConnectDebtorPage = connectDebtorSelectors.getPage;
export const getConnectDebtorRowsPerPage =
  connectDebtorSelectors.getRowsPerPage;
export const getConnectDebtorRowsPerPageOptions =
  connectDebtorSelectors.getRowsPerPageOptions;
export const getConnectDebtorSortDirection =
  connectDebtorSelectors.getSortDirection;
export const getConnectDebtorSortBy = connectDebtorSelectors.getSortBy;
export const getConnectDebtorFilters = connectDebtorSelectors.getFilters;

export const handleConnectDebtorSort = connectDebtorActions.handleSort;
export const handleConnectDebtorChangePage =
  connectDebtorActions.handleChangePage;
export const handleConnectDebtorRowsPerPage =
  connectDebtorActions.handleChangeRowsPerPage;
export const handleConnectDebtorSearchFilter =
  connectDebtorActions.handleSearchFilter;

export const getFundingRequestsIsLoading = state =>
  get(state, "factoringClient.fundingRequests.loading", false);
export const getFundingRequestsCount = fundingRequestsSelectors.getCount;
export const getFundingRequestsPage = fundingRequestsSelectors.getPage;
export const getFundingRequestsRowsPerPage =
  fundingRequestsSelectors.getRowsPerPage;
export const getFundingRequestsRowsPerPageOptions =
  fundingRequestsSelectors.getRowsPerPageOptions;
export const getFundingRequestsSortDirection =
  fundingRequestsSelectors.getSortDirection;
export const getFundingRequestsSortBy = fundingRequestsSelectors.getSortBy;
export const getFundingRequestsFilters = fundingRequestsSelectors.getFilters;

export const handleFundingRequestsSort = fundingRequestsActions.handleSort;
export const handleFundingRequestsChangePage =
  fundingRequestsActions.handleChangePage;
export const handleFundingRequestsRowsPerPage =
  fundingRequestsActions.handleChangeRowsPerPage;
export const handleFundingRequestsSearchFilter =
  fundingRequestsActions.handleSearchFilter;

export const getConnectedDebtorsIsLoading = state =>
  get(state, "factoringClient.connectedDebtors.loading", false);
export const getConnectedDebtorsCount = connectedDebtorsSelectors.getCount;
export const getConnectedDebtorsPage = connectedDebtorsSelectors.getPage;
export const getConnectedDebtorsRowsPerPage =
  connectedDebtorsSelectors.getRowsPerPage;
export const getConnectedDebtorsRowsPerPageOptions =
  connectedDebtorsSelectors.getRowsPerPageOptions;
export const getConnectedDebtorsSortDirection =
  connectedDebtorsSelectors.getSortDirection;
export const getConnectedDebtorsSortBy = connectedDebtorsSelectors.getSortBy;
export const getConnectedDebtorsFilters = connectedDebtorsSelectors.getFilters;

export const getConnectedCarriersIsLoading = state =>
  get(state, "factoringClient.connectedCarriers.loading", false);
export const getConnectedCarriersCount = connectedCarriersSelectors.getCount;
export const getConnectedCarriersPage = connectedCarriersSelectors.getPage;
export const getConnectedCarriersRowsPerPage =
  connectedCarriersSelectors.getRowsPerPage;
export const getConnectedCarriersRowsPerPageOptions =
  connectedCarriersSelectors.getRowsPerPageOptions;
export const getConnectedCarriersSortDirection =
  connectedCarriersSelectors.getSortDirection;
export const getConnectedCarriersSortBy = connectedCarriersSelectors.getSortBy;
export const getConnectedCarriersFilters =
  connectedCarriersSelectors.getFilters;

export const handleConnectedDebtorsSort = connectedDebtorsActions.handleSort;
export const handleConnectedDebtorsChangePage =
  connectedDebtorsActions.handleChangePage;
export const handleConnectedDebtorsChangeRowsPerPage =
  connectedDebtorsActions.handleChangeRowsPerPage;
export const handleConnectedDebtorsSearchFilter =
  connectedDebtorsActions.handleSearchFilter;

export const handleConnectedCarriersSort = connectedCarriersActions.handleSort;
export const handleConnectedCarriersChangePage =
  connectedCarriersActions.handleChangePage;
export const handleConnectedCarriersChangeRowsPerPage =
  connectedCarriersActions.handleChangeRowsPerPage;
export const handleConnectedCarriersSearchFilter =
  connectedCarriersActions.handleSearchFilter;

export const createFundingRequestCSV = ids => async (dispatch, getState) => {
  const state = getState();
  const denormalized = denormalize(
    { ids },
    { ids: [fundingRequest] },
    state.entities
  );

  const fundingRequests = denormalized.ids;

  await createCSV(
    fundingRequests.map(fr => ({
      client: get(fr, ["factoring", "company_profile", "name"], ""),
      debtor: get(fr, ["debtor", "dba"], ""),
      amount: formatPennies(get(fr, "amount", 0)),
      invoice_number: get(fr, "invoice_number"),
      load_number: get(fr, "user_load_number", ""),
      transaction_rep: get(fr, ["assigned_admin", "name"], ""),
      amount_funded: formatPennies(get(fr, "amount_funded", 0)),
      status: get(fr, "status", "")
    }))
  );
};

const initial = {
  loadingFactoringClient: false,
  loadingFactoringAttachments: false,
  loadingUser: false,
  loadingReserveAggregate: false,
  loadingPlaidUser: false,
  loadingAccounts: false,
  loadingNotes: false,
  loadingReserve: false,
  id: "",
  user: "",
  attachments: {},
  collatedFiles: {
    isLoading: false,
    item: undefined,
    error: ""
  },
  accounts: {},
  users: {},
  notes: {},
  nextNote: {},
  reserveAggregates: {},
  reserve: {},
  plaidUsers: {},
  fundingRequests: {
    loading: false,
    ids: [],
    count: 0
  },
  connectedDebtors: {
    loading: false,
    ids: [],
    count: 0
  },
  connectedBrokersCarriers: {
    loading: false,
    ids: []
  },
  connectDebtor: {
    loading: false,
    ids: [],
    count: 0
  }
};

const reducer = createReducer(initial, {
  [TYPES.FETCH_FACTORINGATTACHMENTS_REQUEST]: fetchAttachmentsReducer,
  [TYPES.FETCH_FACTORINGATTACHMENTS_SUCCESS]: fetchAttachmentsReducer,
  [TYPES.FETCH_FACTORINGATTACHMENTS_FAILURE]: fetchAttachmentsReducer,
  [TYPES.FETCH_USER_REQUEST]: fetchUserReducer,
  [TYPES.FETCH_USER_SUCCESS]: fetchUserReducer,
  [TYPES.FETCH_USER_FAILURE]: fetchUserReducer,
  [TYPES.FETCH_RESERVEAGGREGATE_REQUEST]: fetchReserveAggregateReducer,
  [TYPES.FETCH_RESERVEAGGREGATE_SUCCESS]: fetchReserveAggregateReducer,
  [TYPES.FETCH_RESERVEAGGREGATE_FAILURE]: fetchReserveAggregateReducer,
  [TYPES.FETCH_PLAIDUSER_REQUEST]: fetchPlaidUserReducer,
  [TYPES.FETCH_PLAIDUSER_SUCCESS]: fetchPlaidUserReducer,
  [TYPES.FETCH_PLAIDUSER_FAILURE]: fetchPlaidUserReducer,
  [TYPES.FETCH_QUERYFUNDINGREQUESTS_REQUEST]: queryFundingRequestsReducer,
  [TYPES.FETCH_QUERYFUNDINGREQUESTS_SUCCESS]: queryFundingRequestsReducer,
  [TYPES.FETCH_QUERYFUNDINGREQUESTS_FAILURE]: queryFundingRequestsReducer,
  [TYPES.FETCH_CONNECTEDDEBTORS_REQUEST]: queryConnectedDebtorsReducer,
  [TYPES.FETCH_CONNECTEDDEBTORS_SUCCESS]: queryConnectedDebtorsReducer,
  [TYPES.FETCH_CONNECTEDDEBTORS_FAILURE]: queryConnectedDebtorsReducer,
  [TYPES.FETCH_CONNECTEDPROFILE_REQUEST]: queryConnectedProfileReducer,
  [TYPES.FETCH_CONNECTEDPROFILE_SUCCESS]: queryConnectedProfileReducer,
  [TYPES.FETCH_CONNECTEDPROFILE_FAILURE]: queryConnectedProfileReducer,
  [TYPES.FETCH_QUERY_DEBTORS_REQUEST]: queryDebtorsReducer,
  [TYPES.FETCH_QUERY_DEBTORS_SUCCESS]: queryDebtorsReducer,
  [TYPES.FETCH_QUERY_DEBTORS_FAILURE]: queryDebtorsReducer,
  [TYPES.FETCH_FACTORINGCLIENT_REQUEST]: fetchFactoringProfileReducer,
  [TYPES.FETCH_FACTORINGCLIENT_SUCCESS]: fetchFactoringProfileReducer,
  [TYPES.FETCH_FACTORINGCLIENT_FAILURE]: fetchFactoringProfileReducer,
  [TYPES.FETCH_ACCOUNTS_REQUEST]: fetchAccountsReducer,
  [TYPES.FETCH_ACCOUNTS_SUCCESS]: fetchAccountsReducer,
  [TYPES.FETCH_ACCOUNTS_FAILURE]: fetchAccountsReducer,
  [TYPES.CREATE_CARD_ACCOUNT_REQUEST]: createAccountReducer,
  [TYPES.CREATE_CARD_ACCOUNT_SUCCESS]: createAccountReducer,
  [TYPES.CREATE_CARD_ACCOUNT_FAILURE]: createAccountReducer,
  [TYPES.CREATE_BANK_ACCOUNT_REQUEST]: createAccountReducer,
  [TYPES.CREATE_BANK_ACCOUNT_SUCCESS]: createAccountReducer,
  [TYPES.CREATE_BANK_ACCOUNT_FAILURE]: createAccountReducer,
  [TYPES.CREATE_WIRE_ACCOUNT_REQUEST]: createAccountReducer,
  [TYPES.CREATE_WIRE_ACCOUNT_SUCCESS]: createAccountReducer,
  [TYPES.CREATE_WIRE_ACCOUNT_FAILURE]: createAccountReducer,
  [TYPES.CREATE_CHECK_ACCOUNT_REQUEST]: createAccountReducer,
  [TYPES.CREATE_CHECK_ACCOUNT_SUCCESS]: createAccountReducer,
  [TYPES.CREATE_CHECK_ACCOUNT_FAILURE]: createAccountReducer,
  [TYPES.CREATE_SWIFT_ACCOUNT_FAILURE]: createAccountReducer,
  [TYPES.CREATE_SWIFT_ACCOUNT_REQUEST]: createAccountReducer,
  [TYPES.CREATE_SWIFT_ACCOUNT_SUCCESS]: createAccountReducer,
  [TYPES.FETCH_FACTORINGNOTES_REQUEST]: fetchNotesTimelineReducer,
  [TYPES.FETCH_FACTORINGNOTES_SUCCESS]: fetchNotesTimelineReducer,
  [TYPES.FETCH_FACTORINGNOTES_FAILURE]: fetchNotesTimelineReducer,
  [TYPES.FETCH_RESERVE_REQUEST]: fetchReserveReducer,
  [TYPES.FETCH_RESERVE_SUCCESS]: fetchReserveReducer,
  [TYPES.FETCH_RESERVE_FAILURE]: fetchReserveReducer,
  [TYPES.UPLOAD_ATTACHMENTS_REQUEST]: uploadAttachmentsReducer,
  [TYPES.UPLOAD_ATTACHMENTS_SUCCESS]: uploadAttachmentsReducer,
  [TYPES.UPLOAD_ATTACHMENTS_FAILURE]: uploadAttachmentsReducer,
  [TYPES.DELETE_ATTACHMENT_REQUEST]: deleteAttachmentReducer,
  [TYPES.DELETE_ATTACHMENT_SUCCESS]: deleteAttachmentReducer,
  [TYPES.DELETE_ATTACHMENT_FAILURE]: deleteAttachmentReducer,
  [TYPES.CREATE_FACTORINGNOTE_REQUEST]: createFactoringNoteReducer,
  [TYPES.CREATE_FACTORINGNOTE_SUCCESS]: createFactoringNoteReducer,
  [TYPES.CREATE_FACTORINGNOTE_FAILURE]: createFactoringNoteReducer,
  [TYPES.DELETE_FACTORINGNOTE_REQUEST]: deleteFactoringNoteReducer,
  [TYPES.DELETE_FACTORINGNOTE_SUCCESS]: deleteFactoringNoteReducer,
  [TYPES.DELETE_FACTORINGNOTE_FAILURE]: deleteFactoringNoteReducer,
  [TYPES.FACTORINGCLIENT_ATTACHMENT_COLLATED_ERROR]: fetchFactoringCollatedFilesReducer,
  [TYPES.FACTORINGCLIENT_ATTACHMENT_COLLATED_FETCH]: fetchFactoringCollatedFilesReducer,
  [TYPES.FACTORINGCLIENT_ATTACHMENT_COLLATED_SUCCESS]: fetchFactoringCollatedFilesReducer
});

export default compose(
  fundingRequestsTableReducer,
  connectedDebtorsTableReducer,
  connectedCarriersTableReducer,
  connectDebtorTableReducer
)(reducer);

export const middleware = [
  fundingRequestsMiddleware(selectors => store => action => {
    const state = store.getState();
    const sortBy = selectors.getSortBy(state);
    const sortDirection = selectors.getSortDirection(state);
    const offset = selectors.getOffset(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const filters = selectors.getFilters(state);

    store.dispatch(
      queryFundingRequests(
        state.factoringClient.id,
        sortDirection === "asc" ? sortBy : `-${sortBy}`,
        rowsPerPage,
        offset,
        filters
      )
    );
  }),
  connectedDebtorsMiddleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const sortDirection = selectors.getSortDirection(state);
    const sortBy = selectors.getSortBy(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const prevPage = selectors.getPrevPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
    const links = selectors.getLinks(state);
    const id = get(state, ["factoringClient", "id"]);

    if (action.type.includes("UPDATE_PAGE")) {
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextQueryConnectedDebtors(
            id,
            links.next,
            rowsPerPage,
            ordering,
            filters
          )
        );
      } else {
        store.dispatch(
          nextQueryConnectedDebtors(
            id,
            links.previous,
            rowsPerPage,
            ordering,
            filters
          )
        );
      }
    } else {
      store.dispatch(
        queryConnectedDebtors(
          id,
          sortDirection === "asc" ? sortBy : `-${sortBy}`,
          rowsPerPage,
          filters
        )
      );
    }
  }),
  connectDebtorMiddleware(selectors => store => action => {
    const state = store.getState();
    const sortBy = selectors.getSortBy(state);
    const sortDirection = selectors.getSortDirection(state);
    const offset = selectors.getOffset(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const filters = selectors.getFilters(state);

    store.dispatch(
      queryDebtors(
        sortDirection === "asc" ? sortBy : `-${sortBy}`,
        rowsPerPage,
        offset,
        filters
      )
    );
  }),
  connectedCarriersMiddleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const sortDirection = selectors.getSortDirection(state);
    const sortBy = selectors.getSortBy(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const prevPage = selectors.getPrevPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
    const links = selectors.getLinks(state);
    const id = get(state, ["factoringClient", "id"]);

    if (action.type.includes("UPDATE_PAGE")) {
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextQueryConnectedClients(
            id,
            links.next,
            rowsPerPage,
            ordering,
            filters
          )
        );
      } else {
        store.dispatch(
          nextQueryConnectedClients(
            id,
            links.previous,
            rowsPerPage,
            ordering,
            filters
          )
        );
      }
    } else {
      store.dispatch(
        queryConnectedClients(
          id,
          sortDirection === "asc" ? sortBy : `-${sortBy}`,
          rowsPerPage,
          filters
        )
      );
    }
  })
];

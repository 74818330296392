export const CREATE_USER_NOTE_REQUEST = "USER_NOTE/CREATE_REQUEST";
export const CREATE_USER_NOTE_SUCCESS = "USER_NOTE/CREATE_SUCCESS";
export const CREATE_USER_NOTE_FAILURE = "USER_NOTE/CREATE_FAILURE";

export const FETCH_USER_NOTES_REQUEST = "USER_NOTE/FETCH_REQUEST";
export const FETCH_USER_NOTES_SUCCESS = "USER_NOTE/FETCH_SUCCESS";
export const FETCH_USER_NOTES_FAILURE = "USER_NOTE/FETCH_FAILURE";

export const LOAD_USER_NOTES_ATTACHMENTS_SUCCESS =
  "USER_NOTE/LOAD_ATTACHMENTS_SUCCESS";
export const LOAD_USER_NOTES_ATTACHMENTS_FAILURE =
  "USER_NOTE/LOAD_ATTACHMENTS_FAILURE";
export const LOAD_USER_NOTES_ATTACHMENTS_REQUEST =
  "USER_NOTE/LOAD_ATTACHMENTS_REQUEST";

export const DELETE_USER_NOTE_REQUEST = "USER_NOTE/DELETE_REQUEST";
export const DELETE_USER_NOTE_SUCCESS = "USER_NOTE/DELETE_SUCCESS";
export const DELETE_USER_NOTE_FAILURE = "USER_NOTE/DELETE_FAILURE";

export const USER_NOTE_FORM = "USER_NOTE/FORM";

export const UPDATE_USER_NOTE_REQUEST = "USER_NOTE/UPDATE_REQUEST";
export const UPDATE_USER_NOTE_SUCCESS = "USER_NOTE/UPDATE_SUCCESS";
export const UPDATE_USER_NOTE_FAILURE = "USER_NOTE/UPDATE_FAILURE";

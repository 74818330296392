import React, { useCallback, useState } from "react";
import LineItemBuilderForm from "./LineItemForm";
import { Button, Collapse, Grid } from "@material-ui/core";

interface ILineItemBuilder {
    contractId: string;
    hasPurchase: boolean;
}

export default function LineItemBuilder({ contractId, hasPurchase }: ILineItemBuilder) {
    const [showLineItemBuilder, setShowLineItemBuilder] = useState(false);

    const showHideLineItemBuilderHandler = useCallback(() => setShowLineItemBuilder(!showLineItemBuilder), [showLineItemBuilder])
    return (
        <Grid container direction="column">
            <Grid item container justify="flex-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={showHideLineItemBuilderHandler}
                >
                    Line Item Builder
                </Button>
            </Grid>
            <Grid item style={{ width: "100%" }}>
                <Collapse in={showLineItemBuilder}>
                    <LineItemBuilderForm contractId={contractId} hasPurchase={hasPurchase} />
                </Collapse>
            </Grid>
        </Grid>
    )
}

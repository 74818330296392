import { Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

export default function() {
  const history = useHistory();
  return (
    <a onClick={() => history.push("/dashboard/ic_portal/")}>
      <Typography
        style={{
          textDecoration: "underline",
          fontSize: 14,
          padding: 5,
          cursor: "pointer"
        }}
        color="primary"
      >
        Back to Home
      </Typography>
    </a>
  );
}

import formatDate from "../../../../../../helpers/format/formatDate";
import splitAndCase from "../../../../../../helpers/format/splitAndCase";
import formatPennies from "../../../../../../helpers/format/formatPennies";

const columns = [
  {
    key: "debit",
    name: "Amount",
    format: formatPennies
  },
  {
    key: "payment.category",
    name: "Payment Type"
  },
  {
    key: "payment.sch",
    name: "Check #"
  },
  {
    key: "created",
    name: "Date",
    format: formatDate
  },
  {
    key: "added_by",
    name: "Added By"
  },
  {
    key: "payment.amount",
    name: "Total Check Amnt.",
    format: formatPennies
  },
  {
    key: "status",
    name: "Status",
    format: splitAndCase
  }
];

export default columns;

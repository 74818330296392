import { connect } from 'react-redux';
import { compose, lifecycle, withProps, withState } from 'recompose';
import qs from 'qs';

import mapState from '../../helpers/mapState';
import { getEmail, getAuthError, login } from '../../modules/auth';

import Login from './Login';
import { login as validationSchema } from './validation';
import API from '../../api';

export const PureLoginScreen = Login;

const mapStateToProps = mapState({
    email: getEmail
});

const mapDispatchToProps = {
    login
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withState('loginError', 'setLoginError', ''),
    withProps(props => ({
        form: {
            initialValues: {
                email:
                    props.email ||
                    qs.parse(props.location.search)['?email'] ||
                    '',
                password: ''
            },
            validationSchema,
            onSubmit: async (fields, { setSubmitting }) => {
                try {
                    const link =
                        qs.parse(props.location.search)['?redirect'] || '/';
                    // if user type != admin and email ends in comfreight -> throw error
                    if (
                        props.userType !== 'admin' &&
                        fields.email.includes('@comfreight.com')
                    ) {
                        throw 'Invalid credentials given.';
                    }
                    await props.login(fields.email, fields.password);
                    props.history.push(link);
                } catch (err) {
                    props.setLoginError(err);
                }
                setSubmitting(false);
            }
        }
    })),
    lifecycle({
        componentDidMount() {
            const groupId = qs.parse(this.props.location.search).group_id || '';
            const email = qs.parse(this.props.location.search)['?email'] || '';
            if (groupId && groupId !== 'undefined' && email) {
                API.groups.addCarrierToGroup({ email, group_id: groupId });
            }
        }
    })
)(PureLoginScreen);

import { createReducer } from "redux-starter-kit";
import PickFilesReducer from "./reducers/pickFileReducer";
import UpdateCategoriesReducer from "./reducers/updateCategoriesReducer";
import * as TYPES from "./types";
import UploadFilesReducer from "./reducers/uploadFile";
import UploadProgressReducer from "./reducers/uploadProgressReducer";
import uploadStatusReducer from "./reducers/uploadStatusReducer";
import clearFilesReducer from "./reducers/clearFileReducer";
import updateVisibleToReducer from "./reducers/updateVisibleToReducer";

const initial = {
  files: []
};

const reducer = createReducer(initial, {
  [TYPES.PICK_FILES]: PickFilesReducer,
  [TYPES.UPDATE_CATEGORIES]: UpdateCategoriesReducer,
  [TYPES.UPLOAD_FILES]: UploadFilesReducer,
  [TYPES.UPLOAD_PROGRESS]: UploadProgressReducer,
  [TYPES.CANCEL_FILE_UPLOAD]: uploadStatusReducer,
  [TYPES.FILE_UPLOADED]: uploadStatusReducer,
  [TYPES.CLEAR_FILES]: clearFilesReducer,
  [TYPES.UPDATE_VISIBLE_TO]: updateVisibleToReducer
});

export default reducer;
export { default as pickFiles } from "./actions/pickFiles";
export { default as clearFiles } from "./actions/clearFiles";
export { default as updateCategories } from "./actions/updateCategories";
export { default as updateVisibleTo } from "./actions/updateVisibleTo";
export { default as uploadFiles } from "./actions/uploadFiles";
export { default as getFiles } from "./selectors/getFiles";
export { default as getNewFiles } from "./selectors/getNewFiles";

import { compose, createReducer } from "redux-starter-kit";
import { get } from "lodash";
import * as TYPES from "./types";
import fetchReceivablesByDebtorsReducer from "./reducers/fetchReceivablesByDebtors";
import fetchOutstandingTotalsReducer from "./reducers/fetchOutstandingTotals";
import fetchPaymentEntriesReducer from "./reducers/fetchPaymentsEntries";
// import createPaymentsFileReducer from "./reducers/createPaymentsFile";
import fetchPurchasesReducer from "./reducers/fetchPurchases";
import fetchPaymentsReducer from "./reducers/fetchPayments";
import fetchPurchaseItemsReducer from "./reducers/fetchPurchaseItems";
import withTable from "../withTable";
import fetchReceivablesByDebtors from "./actions/fetchReceivablesByDebtors";
import fetchFundingRequestReceivables, {
  next as nextFundingRequestReceivables
} from "./actions/fetchFundingRequestReceivables";
import fetchPayments, {
  next as nextFetchPayments
} from "./actions/fetchPayments";
import fetchPaymentEntries, {
  next as nextFetchPaymentEntries
} from "./actions/fetchPaymentEntries";
import fetchFundingRequestReceivablesReducer from "./reducers/fetchFundingRequestReceivables";
import fetchReceivableEntriesReducer from "./reducers/fetchReceivableEntries";
import queryFetchPurchases, { next as fetchNextPurchase } from "./actions/fetchPurchases";

// const INITIAL_STATE = {};
const INITIAL_STATE_RECEIVABLES = {
  outstandingTotals: {
    IsLoading: false,
    totalBalance: 0,
    totalReceivables: 0
  },
  debtors: {
    loading: false,
    ids: [],
    count: 0,
    offset: 0,
    limit: 20,
    ordering: undefined,
    rowsPerPageOptions: [5, 10, 20, 50, 100, 200]
  }
};

const INITIAL_STATE_PURCHASES = {
  items: {
    loading: false,
    ids: [],
    count: 0,
    offset: 0,
    limit: 20,
    ordering: undefined,
    rowsPerPageOptions: [5, 10, 20, 50, 100, 200]
  }
};

const INITIAL_STATE_PAYMENTS = {
  items: {
    loading: false,
    ids: []
  }
};

const INITIAL_STATE_RECEIVABLE_ENTRIES = {
  items: {
    loading: false,
    ids: []
  }
};

const INITIAL_STATE_PAYMENT_ENTRIES = {
  items: {
    loading: false,
    ids: []
  }
};

const receivableWithTable = withTable({
  stateKey: "receivables",
  typeKey: "receivables"
});

const purchasesWithTable = withTable({
  stateKey: "purchases",
  typeKey: "purchases"
});

const receivableDebtorsWithTable = withTable({
  stateKey: "receivables.debtors",
  typeKey: "receivablesdebtors"
});

const paymentsWithTable = withTable({
  stateKey: "payments",
  typeKey: "payments"
});

const paymentEntriesWithTable = withTable({
  stateKey: "paymentEntries",
  typeKey: "paymentEntries"
});

const receivablesEntries = createReducer(INITIAL_STATE_RECEIVABLE_ENTRIES, {
  [TYPES.FETCH_RECEIVABLE_ENTRIES_REQUEST]: fetchReceivableEntriesReducer,
  [TYPES.FETCH_RECEIVABLE_ENTRIES_SUCCESS]: fetchReceivableEntriesReducer,
  [TYPES.FETCH_RECEIVABLE_ENTRIES_FAILURE]: fetchReceivableEntriesReducer
});

const purchasesReducer = createReducer(INITIAL_STATE_PURCHASES, {
  [TYPES.FETCH_PURCHASES_FAILURE]: fetchPurchasesReducer,
  [TYPES.FETCH_PURCHASES_REQUEST]: fetchPurchasesReducer,
  [TYPES.FETCH_PURCHASES_SUCCESS]: fetchPurchasesReducer,
  [TYPES.FETCH_PURCHASES_ITEMS_SUCCESS]: fetchPurchaseItemsReducer,
  [TYPES.FETCH_PURCHASES_ITEMS_REQUEST]: fetchPurchaseItemsReducer,
  [TYPES.FETCH_PURCHASES_ITEMS_FAILURE]: fetchPurchaseItemsReducer
});

const paymentsReducer = createReducer(INITIAL_STATE_PAYMENTS, {
  [TYPES.FETCH_PAYMENTS_FAILURE]: fetchPaymentsReducer,
  [TYPES.FETCH_PAYMENTS_REQUEST]: fetchPaymentsReducer,
  [TYPES.FETCH_PAYMENTS_SUCCESS]: fetchPaymentsReducer
});

const receivableReducer = createReducer(INITIAL_STATE_RECEIVABLES, {
  [TYPES.FETCH_RECEIVABLES_BY_DEBTORS_FAILURE]: receivableDebtorsWithTable.reducer(
    fetchReceivablesByDebtorsReducer
  ),
  [TYPES.FETCH_RECEIVABLES_BY_DEBTORS_REQUEST]: receivableDebtorsWithTable.reducer(
    fetchReceivablesByDebtorsReducer
  ),
  [TYPES.FETCH_RECEIVABLES_BY_DEBTORS_SUCCESS]: receivableDebtorsWithTable.reducer(
    fetchReceivablesByDebtorsReducer
  ),
  [TYPES.FETCH_OUTSTANDING_TOTALS_FAILURE]: fetchOutstandingTotalsReducer,
  [TYPES.FETCH_OUTSTANDING_TOTALS_REQUEST]: fetchOutstandingTotalsReducer,
  [TYPES.FETCH_OUTSTANDING_TOTALS_SUCCESS]: fetchOutstandingTotalsReducer,
  [TYPES.FETCH_FUNDING_REQUEST_RECEIVABLES_REQUEST]: fetchFundingRequestReceivablesReducer,
  [TYPES.FETCH_FUNDING_REQUEST_RECEIVABLES_SUCCESS]: fetchFundingRequestReceivablesReducer,
  [TYPES.FETCH_FUNDING_REQUEST_RECEIVABLES_FAILURE]: fetchFundingRequestReceivablesReducer,
  [TYPES.ADD_FUNDING_REQUEST_RECEIVABLES_SUCCESS]: fetchFundingRequestReceivablesReducer
});

const paymentEntitiesReducer = createReducer(INITIAL_STATE_PAYMENT_ENTRIES, {
  [TYPES.FETCH_PAYMENT_ENTRIES_REQUEST]: fetchPaymentEntriesReducer,
  [TYPES.FETCH_PAYMENT_ENTRIES_SUCCESS]: fetchPaymentEntriesReducer,
  [TYPES.FETCH_PAYMENT_ENTRIES_FAILURE]: fetchPaymentEntriesReducer
});

const reducer = {
  receivables: compose(
    receivableWithTable.reducer,
    receivableDebtorsWithTable.reducer
  )(receivableReducer),
  payments: paymentsWithTable.reducer(paymentsReducer),
  purchases: purchasesWithTable.reducer(purchasesReducer),
  paymentEntries: paymentEntriesWithTable.reducer(paymentEntitiesReducer),
  receivablesEntries
};

export default reducer;

export const paymentEntriesMiddleware = paymentEntriesWithTable.middleware(
  tableSelectors => store => action => {
    const state = store.getState();

    const filters = tableSelectors.getFilters(state);
    const sortDirection = tableSelectors.getSortDirection(state);
    const sortBy = tableSelectors.getSortBy(state);
    const rowsPerPage = tableSelectors.getRowsPerPage(state);
    const prevPage = tableSelectors.getPrevPage(state);

    const links = tableSelectors.getLinks(state);
    const id = get(state, "paymentEntries.id", "");
    if (action.type.includes("UPDATE_PAGE")) {
      const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextFetchPaymentEntries(
            links.next,
            id,
            rowsPerPage,
            ordering,
            filters
          )
        );
      } else {
        store.dispatch(
          nextFetchPaymentEntries(
            links.previous,
            id,
            rowsPerPage,
            ordering,
            filters
          )
        );
      }
    } else {
      store.dispatch(
        fetchPaymentEntries(
          id,
          sortDirection === "asc" ? sortBy : `-${sortBy}`,
          rowsPerPage,
          filters
        )
      );
    }
  }
);

export const purchaseMiddleware = purchasesWithTable.middleware(
  tableSelectors => store => action => {
    const state = store.getState();
    const rowsPerPage = tableSelectors.getRowsPerPage(state);
    const filters = tableSelectors.getFilters(state);
    const prevPage = tableSelectors.getPrevPage(state);
    const links = tableSelectors.getLinks(state);
    if (action.type.includes("UPDATE_PAGE")) {
      if (action.payload.page > prevPage) {
        return store.dispatch(
          fetchNextPurchase(
            links.next,
            rowsPerPage,
            filters
          )
        );
      }
      return store.dispatch(
        fetchNextPurchase(
          links.previous,
          rowsPerPage,
          filters
        )
      );
    }
    store.dispatch(queryFetchPurchases(rowsPerPage, filters));
  }
);

export const receivableMiddleware = receivableWithTable.middleware(
  tableSelectors => store => action => {
    const state = store.getState();
    const filters = tableSelectors.getFilters(state);
    const sortDirection = tableSelectors.getSortDirection(state);
    const sortBy = tableSelectors.getSortBy(state);
    const rowsPerPage = tableSelectors.getRowsPerPage(state);
    const prevPage = tableSelectors.getPrevPage(state);

    const links = tableSelectors.getLinks(state);
    if (action.type.includes("UPDATE_PAGE")) {
      const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextFundingRequestReceivables(
            links.next,
            rowsPerPage,
            ordering,
            filters
          )
        );
      } else {
        store.dispatch(
          nextFundingRequestReceivables(
            links.previous,
            rowsPerPage,
            ordering,
            filters
          )
        );
      }
    } else {
      store.dispatch(
        fetchFundingRequestReceivables(
          sortDirection === "asc" ? sortBy : `-${sortBy}`,
          rowsPerPage,
          filters
        )
      );
    }
  }
);

export const receivableDebtorMiddleware = receivableDebtorsWithTable.middleware(
  tableSelectors => store => action => {
    const state = store.getState();
    const sortBy = tableSelectors.getSortBy(state);
    const sortDirection = tableSelectors.getSortDirection(state);
    const offset = tableSelectors.getOffset(state);
    const rowsPerPage = tableSelectors.getRowsPerPage(state);
    const filters = tableSelectors.getFilters(state);

    store.dispatch(
      fetchReceivablesByDebtors(
        sortDirection === "asc" ? sortBy : `-${sortBy}`,
        rowsPerPage,
        offset,
        filters
      )
    );
  }
);

export const paymentsMiddleware = paymentsWithTable.middleware(
  tableSelectors => store => action => {
    const state = store.getState();

    const filters = tableSelectors.getFilters(state);
    const sortDirection = tableSelectors.getSortDirection(state);
    const sortBy = tableSelectors.getSortBy(state);
    const rowsPerPage = tableSelectors.getRowsPerPage(state);
    const prevPage = tableSelectors.getPrevPage(state);

    const links = tableSelectors.getLinks(state);

    if (action.type.includes("UPDATE_PAGE")) {
      const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextFetchPayments(links.next, rowsPerPage, ordering, filters)
        );
      } else {
        store.dispatch(
          nextFetchPayments(links.previous, rowsPerPage, ordering, filters)
        );
      }
    } else {
      store.dispatch(
        fetchPayments(
          rowsPerPage,
          sortDirection === "asc" ? sortBy : `-${sortBy}`,
          filters
        )
      );
    }
  }
);

export const selectorsPaymentEntriesTable = paymentEntriesWithTable.selectors;
export const actionsPaymentEntriesTable = paymentEntriesWithTable.actions;

export const selectorsPaymentsTable = paymentsWithTable.selectors;
export const actionsPaymentTable = paymentsWithTable.actions;
export const selectorsRecivablesTable = receivableWithTable.selectors;
export const selectorsReceivablesDebtorsTable =
  receivableDebtorsWithTable.selectors;
export const receivablesActionsTable = receivableWithTable.actions;
export const receivablesDebtorsActionsTable =
  receivableDebtorsWithTable.actions;
export const selectorsPurchasesTable = purchasesWithTable.selectors;
export const purchasesActionsTable = purchasesWithTable.actions;
export { default as fetchReceivables } from "./actions/fetchReceivablesByDebtors";
export { default as fetchFundingRequestReceivables } from "./actions/fetchFundingRequestReceivables";
export { default as fetchPurchases } from "./actions/fetchPurchases";
export { default as fetchReceivable } from "./actions/fetchReceivable";
export { default as fetchPayment } from "./actions/fetchPayment";
export { default as fetchPaymentEntries } from "./actions/fetchPaymentEntries";
export { default as clearPurchaseItems } from "./actions/clearPurchaseItems";
export { default as fetchPurchaseItems } from "./actions/fetcPurchaseItems";
export { default as createPaymentsFile } from "./actions/createPaymentsFile";
export { default as fetchPayments } from "./actions/fetchPayments";
export { default as fetchReceivableEntries } from "./actions/fetchReceivableEntries";
export { default as fetchOutstandingTotals } from "./actions/fetchOutstandingTotals";
export { default as createLineItemsCategories } from "./actions/createLineItemsCategories";
export { default as updateLineItemsCategories } from "./actions/updateLineItemsCategories";
export { default as deleteLineItemsCategories } from "./actions/deleteLineItemsCategories";
export { default as getReceivables } from "./selectors/getReceivables";
export { default as getReceivablesById } from "./selectors/getReceivablesById";
export { default as getDebtors } from "./selectors/getDebtors";
export { default as getIsUploading } from "./selectors/getIsUploading";
export { default as getPurchases } from "./selectors/getPurchases";
export { default as getPurchaseItems } from "./selectors/getPurchaseItems";
export { default as getPayments } from "./selectors/getPayments";
export { default as getPaymentEntries } from "./selectors/getPaymentEntries";
export { default as getPaymentEntriesLoading } from "./selectors/getPaymentEntriesLoading";
export { default as getPayment } from "./selectors/getPayment";
export { default as getReceivable } from "./selectors/getReceivable";
export { default as getReceivableEntriesLoading } from "./selectors/getReceivableEntriesLoading";
export { default as getReceivableEntries } from "./selectors/getReceivableEntries";
export { default as getOutstandingTotals } from "./selectors/getOutstandingTotals";
export { default as createReceivableCSV } from "./createReceivableCSV";
export { default as createPurchasesCSV } from "./createPurchasesCSV";
export { default as createPaymentsCSV } from "./createPaymentsCSV";
export { default as deletePayment } from "./actions/deletePayment";

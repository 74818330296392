import { createReducer } from "redux-starter-kit";

import * as TYPES from "./types";
import addEntitiesReducer from "./addEntities";

const initial = {};

const reducer = createReducer(initial, {
  [TYPES.ADD_ENTITIES]: addEntitiesReducer,
  [TYPES.DELETE_ROW]: addEntitiesReducer
});

export default reducer;

export { default as addEntities } from "./actions";
export { default as deleteRow } from "./deleteRow";

import { createAction } from "redux-starter-kit";

import {
  FETCH_DEBTORHISTORY_FAILURE,
  FETCH_DEBTORHISTORY_REQUEST,
  FETCH_DEBTORHISTORY_SUCCESS
} from "../types";

const fetchHistory = id => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(FETCH_DEBTORHISTORY_REQUEST)({}));
    const response = await api.debtors.getDebtorHistory(id);
    dispatch(createAction(FETCH_DEBTORHISTORY_SUCCESS)(response));
  } catch (err) {
    dispatch(createAction(FETCH_DEBTORHISTORY_FAILURE)(err));
    throw err;
  }
};

export default fetchHistory;

import React, { useCallback, useEffect, useState } from "react";
import { Typography, Tooltip, Grid, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { get, sumBy, isString } from "lodash";

import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import {
  fetchPaymentEntries,
  getPaymentEntries,
  getPayment,
  selectorsPaymentEntriesTable,
  actionsPaymentEntriesTable
} from "../../../../../../modules/finances";
import {
  closeDialog,
  getDialog,
  openSnackbar
} from "../../../../../../modules/ui";
import columns from "./columns";
import Table from "../../../../../../components/Table";
import Link from "../../../../../../components/Link";
import formatPennies from "../../../../../../helpers/format/formatPennies";
import formatDate from "../../../../../../helpers/format/formatDate";
import RoundedButton from "../../../../../../components/buttons/RoundedButton";
import {
  Pennies,
  Input,
  Select
} from "../../../../../../components/inputs/BoldInput";
import DatePicker from "../../../../../../components/inputs/DatePicker";
import colors from "../../../../../../theme/colors";
import { updatePayment } from "../../../../../../api/payments";
import formatCurrency from "../../../../../../helpers/format/formatCurrency";
import {
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../../components/Dialog";
import SelfFinanceIcon from "../../../../../../components/icons/SelfFinanceIcon";

const styles = {
  typography: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.black,
    margin: 5
  }
};

export default function ReceivablesDetails() {
  const dispatch = useDispatch();
  const [display, setDisplay] = useState(false);
  const dialog = useSelector(getDialog);
  const id = get(dialog, ["data", "id"]);

  const handleClose = useCallback(() => dispatch(closeDialog()), [dispatch]);
  useEffect(() => {
    const asyncFetch = async () => {
      if (id) {
        await dispatch(fetchPaymentEntries(id, "-created", 20, {}));
      }
    }
    asyncFetch();

  }, [id]);
  const paymentEntries = useSelector(getPaymentEntries);
  const payment = useSelector(getPayment(id));

  const loading = useSelector(selectorsPaymentEntriesTable.getIsLoading);
  const count = useSelector(selectorsPaymentEntriesTable.getCount);
  const sortBy = useSelector(selectorsPaymentEntriesTable.getSortBy);
  const page = useSelector(selectorsPaymentEntriesTable.getPage);
  const rowsPerPage = useSelector(selectorsPaymentEntriesTable.getRowsPerPage);
  const sortDirection = useSelector(
    selectorsPaymentEntriesTable.getSortDirection
  );
  const filters = useSelector(selectorsPaymentEntriesTable.getFilters);
  const rowsPerPageOptions = useSelector(
    selectorsPaymentEntriesTable.getRowsPerPageOptions
  );

  const handleSort = useCallback(
    (...args) => dispatch(actionsPaymentEntriesTable.handleSort(...args)),
    [dispatch]
  );

  const handleChangePage = useCallback(
    (...args) => dispatch(actionsPaymentEntriesTable.handleChangePage(...args)),
    [dispatch]
  );

  const handleChangeRowsPerPage = useCallback(
    (...args) =>
      dispatch(actionsPaymentEntriesTable.handleChangeRowsPerPage(...args)),
    [dispatch]
  );

  const handleSearchFilter = useCallback(
    (...args) =>
      dispatch(actionsPaymentEntriesTable.handleSearchFilter(...args)),
    [dispatch]
  );
  const TextToInput = ({
    display,
    value,
    type = "text",
    formatValue,
    handleChange,
    handleBlur,
    renderText,
    onBlur,
    ...props
  }) => {
    if (!display && renderText) return renderText();
    if (!display)
      return (
        <Typography inline variant="body1" style={styles.typography}>
          {" "}
          {formatValue ? formatValue(Math.round(value * 100)) : value}
        </Typography>
      );
    switch (type) {
      case "pennies":
        return <Pennies value={value} {...props} onBlur={onBlur} />;
      case "text":
        return (
          <Input
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            {...props}
          />
        );
      case "select":
        return (
          <Select
            value={value}
            style={{ minWidth: 150 }}
            onChange={handleChange}
            onBlur={handleBlur}
            {...props}
          />
        );
      case "date":
        return (
          <DatePicker
            value={value}
            onChange={date => {
              props.setFieldValue(props.name, date.toISO());
            }}
            onBlur={handleBlur}
            {...props}
          />
        );
    }
  };

  return (
    <Dialog
      open={dialog.show && dialog.variant === "finance-payment-details"}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle
        onClose={() => {
          handleClose();
          setDisplay(false);
        }}
      >
        Receivables Details
      </DialogTitle>
      {!loading ? (
        <Formik
          initialValues={{
            sch: payment.sch,
            amount: (payment.amount || 0) / 100,
            posting_date: payment.posting_date,
            notes: payment.notes,
            category: payment.category,
            entries: paymentEntries.map(entry => ({
              debit: (entry.debit || 0) / 100,
              id: entry.id
            })),
            bank_description: payment.bank_description
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              const entries = values.entries.map(entry => ({
                debit: Math.round(entry.debit * 100),
                id: entry.id
              }));
              await updatePayment(payment.id, {
                ...values,
                amount: Math.round(values.amount * 100),
                entries
              });
              setDisplay(false);
              dispatch(openSnackbar("success", "payments has been updated."));
            } catch (e) {
              dispatch(openSnackbar("error", "Error updating payments."));
            }
          }}
        >
          {({ handleSubmit, values, ...props }) => {
            const formatted = paymentEntries.map((entry, i) => {
              return {
                ...entry,
                debit: () => (
                  <TextToInput
                    name={`entries[${i}].debit`}
                    value={get(values, `entries[${i}].debit`, 0)}
                    setFieldValue={(...args) => props.setFieldValue(...args)}
                    display={display}
                    onBlur={() => {
                      const amount = sumBy(values.entries, o =>
                        Number.parseFloat(
                          isString(o.debit)
                            ? o.debit.replace(/[^\d\.]/g, "")
                            : o.debit,
                          10
                        )
                      );
                      props.setFieldValue("amount", formatCurrency(amount));
                    }}
                    type="pennies"
                    formatValue={formatPennies}
                    renderText={() =>
                      formatPennies(
                        Math.round(get(values, `entries[${i}].debit`, 0) * 100)
                      )
                    }
                  />
                ),
                invoice_number: (
                  <Link
                    to={`/admin/fundingrequest/${get(
                      entry,
                      ["receivable", "funding_request", "id"],
                      ""
                    )}`}
                  >
                    {get(
                      entry,
                      ["receivable", "funding_request_invoice_number"],
                      ""
                    )}
                  </Link>
                ),
                client: (
                  <Link
                    to={`/admin/factorclients/${get(
                      entry,
                      ["receivable", "funding_request", "factoring", "id"],
                      ""
                    )}/profile`}
                  >
                    {get(
                      entry,
                      [
                        "receivable",
                        "funding_request",
                        "factoring",
                        "company_profile",
                        "name"
                      ],
                      ""
                    )}
                  </Link>
                ),
                "receivable.funding_request.amount": () => (
                  <Grid container direction="row" alignItems="center">
                    {formatPennies(get(
                      entry,
                      "receivable.funding_request.amount",
                      ""
                    ))}
                    <SelfFinanceIcon contractType={get(
                      entry,
                      "receivable.contract_type",
                      ""
                    )} />
                  </Grid>
                ),
                more: (
                  <Tooltip title="View Funding Request">
                    <Link
                      to={`/admin/fundingrequest/${get(
                        entry,
                        ["receivable", "funding_request", "id"],
                        ""
                      )}`}
                    >
                      <FontAwesomeIcon
                        size="1x"
                        color={colors.green_light}
                        icon={faHandHoldingUsd}
                      />
                    </Link>
                  </Tooltip>
                )
              };
            });
            return (
              <DialogContent>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ width: "100%", margin: "auto" }}
                >
                  <Grid item xs={4}>
                    <Typography
                      inline
                      variant="body1"
                      style={styles.typography}
                    >
                      ACH/Check#:
                    </Typography>
                    <TextToInput
                      value={values.sch}
                      name="sch"
                      display={display}
                      type="text"
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      inline
                      variant="body1"
                      style={styles.typography}
                    >
                      Debtor:{" "}
                    </Typography>
                    <Typography
                      inline
                      variant="body1"
                      style={styles.typography}
                    >
                      <Link
                        to={`/admin/debtors/${get(
                          payment,
                          ["source", "id"],
                          ""
                        )}/profile`}
                      >
                        {get(payment, ["source", "dba"], "")}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      inline
                      variant="body1"
                      style={styles.typography}
                    >
                      Check Amount:{" "}
                    </Typography>
                    <TextToInput
                      disabled
                      value={values.amount}
                      name="amount"
                      display={display}
                      type="pennies"
                      formatValue={formatPennies}
                      {...props}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ width: "100%", margin: "auto" }}
                >
                  <Grid item xs={4}>
                    <Typography
                      inline
                      variant="body1"
                      style={styles.typography}
                    >
                      Payment Date:{" "}
                    </Typography>
                    <TextToInput
                      value={values.posting_date}
                      display={display}
                      type="date"
                      name="posting_date"
                      formatValue={formatDate}
                      renderText={() => (
                        <Typography
                          component="span"
                          variant="body1"
                          inline
                          style={{ color: colors.black }}
                        >
                          {formatDate(values.posting_date)}
                        </Typography>
                      )}
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      inline
                      variant="body1"
                      style={styles.typography}
                    >
                      Payment Notes:{" "}
                    </Typography>
                    <TextToInput
                      value={values.notes}
                      display={display}
                      type="text"
                      name="notes"
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      inline
                      variant="body1"
                      style={styles.typography}
                    >
                      Payment Type:
                    </Typography>
                    <TextToInput
                      value={values.category}
                      display={display}
                      type="select"
                      name="category"
                      selections={[
                        { text: "ACH", value: "ach" },
                        { text: "CHECK", value: "check" }
                      ]}
                      {...props}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ width: "100%", margin: "auto" }}
                >
                  <Grid item xs={4} style={{ margingTop: 5 }}>
                    <Typography
                      inline
                      variant="body1"
                      type="text"
                      style={styles.typography}
                    >
                      WF Description:
                    </Typography>
                    <TextToInput
                      value={values.bank_description}
                      display={display}
                      name="bank_description"
                      {...props}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="flex-end">
                  {display ? (
                    <RoundedButton
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Save
                    </RoundedButton>
                  ) : (
                    <RoundedButton
                      variant="contained"
                      color="primary"
                      onClick={() => setDisplay(true)}
                    >
                      Edit Payment
                    </RoundedButton>
                  )}
                </Grid>
                <Grid style={{ width: "100%", margin: "auto" }}>
                  <Table
                    columns={columns}
                    rows={formatted}
                    isLoading={loading}
                    handleSort={handleSort}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    filter
                    filters={filters}
                    handleFilterChange={handleSearchFilter}
                    allowEmpty
                  />
                </Grid>
              </DialogContent>
            );
          }}
        </Formik>
      ) : (
        <Grid
          style={{ minHeight: 200 }}
          container
          justify="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      )}
    </Dialog>
  );
}

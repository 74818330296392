import { CANCEL_FILE_UPLOAD, FILE_UPLOADED } from "../types";

const updateFileWithstatus = (state, payload) => {
  const { files } = { ...state };
  const newFiles = files.slice(0);
  files[payload.id].status = payload.status;
  return newFiles;
};

const uploadStatusReducer = (state, { type, payload }) => {
  switch (type) {
    case CANCEL_FILE_UPLOAD:
      return {
        ...state,
        files: updateFileWithstatus(state, payload)
      };
    case FILE_UPLOADED:
      return {
        ...state,
        files: updateFileWithstatus(state, payload)
      };
    default:
      return {
        ...state
      };
  }
};

export default uploadStatusReducer;

import axios, { AxiosRequestConfig } from "axios";

const deleteAccount = async (
  userId: string,
  accountId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/accounts/${userId}/${accountId}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const stripeAddBankAccount = async (
  companyId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/cmftstripe/add_bank_account/`,
    method: "POST",
    data: {
      company_id: companyId
    }
  };
  const response = await axios(options);
  return response.data;
};

const fetchStripeAccount = async (): Promise<any> => {
  const url = `/cmftstripe/account/`;
  const options: AxiosRequestConfig = {
    url,
    method: 'GET',
  };
  const response = await axios(options);
  return response.data;
};

export default {
  deleteAccount,
  stripeAddBankAccount,
  fetchStripeAccount
};

import Yup from "../../../../../YupValidation";
import { searchFactoringDebtors } from "../../../../../api/factoring";
import Colors from "../../../../../theme/colors";

const getDebtorByField = async (value, path, createError) => {
  if (!value) return false;
  const { results = [] } = await searchFactoringDebtors({
    [path]: value,
    limit: 1
  });
  if (results.length)
    return createError({
      message: `This ${path}# ${value} already used with <a href="/admin/debtors/${results[0].id}/profile" style="text-decoration: none; color: ${Colors.secondary}"> debtor: ${results[0].dba}</a>.`,
      path
    });
  return true;
};

const addDebtorFormValidation = () => {
  const schema = {
    mc: Yup.string().test("unique-mc", "", async function(value) {
      const { path, createError } = this;
      return getDebtorByField(value, path, createError);
    }),
    dot: Yup.string().test("unique-dot", "", async function(value) {
      const { path, createError } = this;
      return getDebtorByField(value, path, createError);
    }),
    tax_id: Yup.string().test("unique-tax_id", "", async function(value) {
      const { path, createError } = this;
      return getDebtorByField(value, path, createError);
    })
  };
  return Yup.object().shape(schema);
};

export default addDebtorFormValidation;

import React from "react";
import { compose, lifecycle } from "recompose";

import Thanks from "../../../components/Thanks";

const SignUpThanks = props => <Thanks {...props} />;

export default compose(
  lifecycle({
    componentDidMount() {
      const { isIframe } = this.props;
      if (isIframe) {
        setTimeout(
          () =>
            window.parent.postMessage(
              "reload",
              `${process.env.REACT_APP_COMFREIGHT_URL}/haul-pay/join`
            ),
          4000
        );
      }
    }
  })
)(SignUpThanks);

import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, withProps, withStateHandlers } from "recompose";
import { find, findIndex, get, head, merge, omitBy, reverse } from "lodash";
import { Grid, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";

import { DateTime } from "luxon";
import withStatusDropdown from "../../../components/withStatusDropdown";
import {
  createCSV,
  getCount,
  getFactoringClients,
  getFilters,
  getLoading,
  getPage,
  getRowsPerPage,
  getRowsPerPageOptions,
  getSortBy,
  getSortDirection,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter,
  handleSort,
  queryFactoringClients
} from "../../../modules/factoringClients";
import formatDate from "../../../helpers/format/formatDate";
import splitAndCase from "../../../helpers/format/splitAndCase";
import Link from "../../../components/Link";
import PureFactorClients from "./FactorClients";
import Stats from "./components/Stats";
import MoreLinks from "./components/MoreLinks";
import tabs from "./tabs";
import columns from "./columns";
import TransactionStatus from "../../../components/TransactionStatus";
import colors from "../../../theme/colors";

const mapStateToProps = state => ({
  factorClients: getFactoringClients(state),
  rowsPerPageOptions: getRowsPerPageOptions(state),
  rowsPerPage: getRowsPerPage(state),
  count: getCount(state),
  sortDirection: getSortDirection(state),
  sortBy: getSortBy(state),
  page: getPage(state),
  loading: getLoading(state),
  filters: getFilters(state)
});

const mapDispatchToProps = {
  queryFactoringClients,
  handleChangeRowsPerPage,
  handleChangePage,
  handleSort,
  handleSearchFilter,
  createCSV
};

export default compose(
  withStatusDropdown,
  withStateHandlers(
    ({
      datetime_end = DateTime.local(),
      datetime_start = DateTime.local()
    }) => ({ datetime_end, datetime_start }),
    {
      onDateStartChange: () => datetime_start => ({ datetime_start }),
      onDateEndChange: () => datetime_end => ({ datetime_end })
    }
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      createCSV,
      history: {
        location: { pathname },
        push
      },
      filters,
      factorClients
    }) => ({
      tabs,
      columns,
      statusIndex: findIndex(tabs, tab => pathname === tab.route),
      filterStatus: (find(tabs, tab => pathname === tab.route) || {}).value,
      handleRoute: route => push(route),
      handleCSV: () => {
        const csvFilters = omitBy(
          {
            status: (find(tabs, tab => pathname === tab.route) || {}).value,
            ...filters
          },
          (value, key) => value === "all" && key === "status"
        );
        createCSV(csvFilters);
      },
      factorClients: factorClients.map(client => {
        return {
          ...client,
          company_name: (
            <Grid container alignItems="center" spacing={8}>
              <Grid item>
                <Link to={`/admin/factorclients/${client.id}/profile`}>
                  {get(client, "company_profile.name", "")}
                </Link>
              </Grid>
              {client.user_type === "broker" && (
                <Grid item>
                  <Tooltip title="Broker">
                    <FontAwesomeIcon color={colors.green} icon={faHandshake} />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          ),
          time_posted: formatDate(client.time_posted),
          owner_name: `${get(client, "owner_profile.name_first", "")} ${get(
            client,
            "owner_profile.name_last",
            ""
          )}`,
          user_type: splitAndCase(client.user_type),
          status_time: formatDate(
            get(client.status_timeline, [
              client.status_timeline.length - 1,
              "status_set_datetime"
            ])
          ),
          status: (
            <TransactionStatus
              status={client.factoring_approved}
              modified={client.modified}
              statusTimeline={client.status_timeline}
              newAttachments={get(
                head(reverse(client.attachments)),
                "after_issue"
              )}
            />
          ),
          source: get(client, "user.source", ""),
          stats: (
            <Stats
              thisPeriod={client.this_period_fr}
              difference={client.this_period_fr - client.last_period_fr}
            />
          ),
          more: <MoreLinks link={`/admin/factorclients/${client.id}/profile`} />
        };
      })
    })
  ),
  lifecycle({
    componentDidMount() {
      const status =
        this.props.filterStatus === "all" ? undefined : this.props.filterStatus;

      this.props.queryFactoringClients(
        status,
        "-time_posted",
        this.props.rowsPerPage,
        0,
        merge(
          {},
          {
            inactive: false
          }
        )
      );
    },
    componentDidUpdate(prevProps) {
      if (this.props.filterStatus !== prevProps.filterStatus) {
        const status =
          this.props.filterStatus === "all"
            ? undefined
            : this.props.filterStatus;
        this.props.queryFactoringClients(
          status,
          "-time_posted",
          this.props.rowsPerPage,
          0,
          merge({}, this.props.filters, {
            inactive: false
          })
        );
      }
    }
  })
)(PureFactorClients);

import { combineReducers, configureStore } from "redux-starter-kit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";
import { get } from "lodash";

import api from "./api";
import * as schema from "./api/schema";
import ui from "./modules/ui";
import entities from "./modules/entities";
import invited from "./modules/invited";
import upload from "./modules/upload";
import auth from "./modules/auth";
import user from "./modules/user";
import signup from "./modules/signUp";
import factoring from "./modules/factoring";
import reserve from "./modules/reserve";
import debtors, { middleware as debtorsMiddleware } from "./modules/debtors";
import debtor, { middleware as debtorMiddleware } from "./modules/debtor";
import userNotes from "./modules/userNotes";
import users, { middleware as usersMiddleware } from "./modules/users";
import factoringClients, {
  middleware as factoringClientsMiddleware
} from "./modules/factoringClients";
import admins, { middleware as adminsMiddleware } from "./modules/admins";
import factoringClient, {
  middleware as factoringClientMiddleware
} from "./modules/factoringClient";
import groups, { middleware as groupsMiddleware } from "./modules/groups";
import payment, { middleware as paymentMiddleware } from "./modules/payment";
import factorCompany, {
  middleware as factorCompanyMiddleware
} from "./modules/factorCompany";
import transactions, {
  middleware as transactionMiddleware
} from "./modules/transactions";
import finances, {
  paymentsMiddleware,
  purchaseMiddleware,
  receivableDebtorMiddleware,
  receivableMiddleware,
  paymentEntriesMiddleware
} from "./modules/finances";
import authoriaztionProvider from "./middleware/authorizationProvider";
import dashboard from "./modules/dashboard";
import berbix from "./modules/berbix";
import ledger, { middleware as ledgerMiddleware } from "./modules/ledger";
import noa, { middleware as noaMiddleware } from "./modules/NOA";
import obligations, { middleware as obligationsMiddleware } from "./modules/obligations";
const isProduction = process.env.NODE_ENV === "production";

const reducer = combineReducers({
  entities,
  invited,
  upload,
  auth,
  signup,
  factoring,
  user,
  reserve,
  transactions,
  ui,
  debtors,
  debtor,
  users,
  userNotes,
  ...finances,
  factoringClients,
  factoringClient,
  ...dashboard,
  admins,
  berbix,
  groups,
  payment,
  factorCompany,
  noa,
  obligations,
  ledger
});

const rootReducer = (state, action) => {
  if (action.type === "persist/REHYDRATE") {
    Sentry.setUser({
      id: get(action, ["payload", "auth", "currentUserId"]),
      type: get(action, ["payload", "auth", "type"]),
      email: get(action, ["payload", "auth", "email"])
    });
  }

  if (action.type === "AUTH/LOGOUT") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    state = undefined;
  }
  return reducer(state, action);
};

const devMiddleware = [logger];

const middleware = [
  authoriaztionProvider,
  thunk.withExtraArgument({ api, schema }),
  transactionMiddleware,
  debtorsMiddleware,
  usersMiddleware,
  receivableMiddleware,
  ...debtorMiddleware,
  factoringClientsMiddleware,
  ...factoringClientMiddleware,
  receivableDebtorMiddleware,
  adminsMiddleware,
  purchaseMiddleware,
  paymentsMiddleware,
  paymentEntriesMiddleware,
  ...groupsMiddleware,
  ...paymentMiddleware,
  ...factorCompanyMiddleware,
  noaMiddleware,
  obligationsMiddleware,
  ledgerMiddleware
];

if (!isProduction) {
  middleware.push(...devMiddleware);
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: !isProduction
});

const persistor = persistStore(store);

export default () => ({ store, persistor });

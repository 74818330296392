import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";

import { addEntities } from "../../entities";
import {
  FETCH_QUERYFUNDINGREQUESTS_FAILURE,
  FETCH_QUERYFUNDINGREQUESTS_REQUEST,
  FETCH_QUERYFUNDINGREQUESTS_SUCCESS
} from "../types";

const queryFundingRequestsDebounced = debounce(
  async (
    dispatch,
    schema,
    api,
    factoringId,
    limit,
    offset,
    ordering,
    filters
  ) => {
    dispatch(
      createAction(FETCH_QUERYFUNDINGREQUESTS_REQUEST)({
        id: factoringId,
        limit,
        offset,
        ordering,
        filters
      })
    );
    const response = await api.factoring.queryFundingRequestsByFactoringId(
      factoringId,
      ordering,
      limit,
      offset,
      filters
    );
    const data = normalize(response.results, [schema.fundingRequest]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_QUERYFUNDINGREQUESTS_SUCCESS)({
        items: data.result,
        count: response.count,
        limit,
        offset,
        ordering,
        filters
      })
    );
    return response;
  },
  1000,
  { trailing: true }
);

const queryFundingRequests = (
  factoringId,
  ordering,
  limit,
  offset,
  filters = {}
) => async (dispatch, getState, { api, schema }) => {
  try {
    await queryFundingRequestsDebounced(
      dispatch,
      schema,
      api,
      factoringId,
      limit,
      offset,
      ordering,
      filters
    );
  } catch (err) {
    dispatch(createAction(FETCH_QUERYFUNDINGREQUESTS_FAILURE)(err));
    throw err;
  }
};

export default queryFundingRequests;

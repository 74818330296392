import React from "react";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

import MoreButton from "../../../../../components/buttons/MoreButton";
import columns from "./columns";
import Table from "../../../../../components/Table";

interface Props {
  receivables: Record<string, any>;
}

export default function ReceivablesTable({ receivables }: Props): JSX.Element {
  let row: any[] = [];
  if (receivables) {
    row = [receivables];
  }
  const withButton = row.map(row => ({
    ...row,
    action: (): JSX.Element => (
      <Tooltip title="Add Payment">
        <Link
          to={`/admin/finances/receivables?invoice_number=${row.funding_request_invoice_number}`}
          style={{
            textDecoration: "none"
          }}
        >
          <MoreButton />
        </Link>
      </Tooltip>
    )
  }));
  return <Table columns={columns} rows={withButton} allowEmpty />;
}

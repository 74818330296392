import React, { useCallback, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";

import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../components/Dialog";
import BrokerAutoComplete from "../../../../../components/inputs/BrokerAutoComplete";
import { openSnackbar } from "../../../../../modules/ui";
import API from "../../../../../api/factoring/paymentProfile";

function ConnectBrokerDialog({ paymentProfileId, show, handleClose, refresh }) {
  const [loading, setLoading] = useState(false);
  const [broker, setBroker] = useState(undefined);
  const dispatch = useDispatch();
  const [selectedFactorCompany, setSelectedFactorCompany] = useState(undefined);

  const connectFactorOnClick = useCallback(async (paymentProfileId, broker) => {
    setLoading(true);
    handleClose();
    try {
      await API.createPaymentProfileRelationship({
        factoring_id: broker.id,
        payment_profile_id: paymentProfileId
      });
      dispatch(
        openSnackbar(
          "success",
          `${broker.company_profile.name} has been linked.`
        )
      );
    } catch (e) {
      dispatch(openSnackbar("error", e));
    } finally {
      await refresh();
    }
    setLoading(false);
    setBroker({});
    setSelectedFactorCompany({});
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      open={show}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle onClose={handleClose}>Connect Broker</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={16}
          style={{ minHeight: 150 }}
        >
          <Grid item>
            <BrokerAutoComplete
              label="Broker Name"
              placeholder="Select a Broker"
              value={broker}
              paymentProfileId={paymentProfileId}
              onChange={value => setBroker(value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={isEmpty(broker)}
          onClick={() => connectFactorOnClick(paymentProfileId, broker)}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConnectBrokerDialog;

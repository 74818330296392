import React, { useCallback } from "react";
import { TextField, Grid, FormHelperText, MenuItem, FormGroup, FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Form, Field } from "formik";
import { isUndefined, throttle, isEmpty, get } from "lodash";
import MultiButtonToggleInputs from "../../../../components/inputs/MultiButtonToggleInputs";
import PhoneInput from "../../../../components/inputs/PhoneInput";
import AutoCompleteAddress from "../../../../components/inputs/AutoCompleteAddress";
import PasswordInput from "../../../../components/inputs/PasswordInput";
import {
  assignValuesToFields,
  initFormValues
} from "../../../Invited/components/CarrierInfo";
import { getDataFromDOT, getDataFromMC } from "../../../../api/fmcsa";
import SMSAlertPolicyDialog from "../../../Invited/components/SMSAlertPolicyDialog";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog, getDialog, openDialog } from "../../../..//modules/ui";

const whereToFindUsOptions = [
  { label: "Organic Search/Google", value: "organic_search" },
  { label: "Google Ad", value: "google_ad" },
  { label: "Facebook Ad", value: "facebook_ad" },
  { label: "Instagram Ad", value: "instagram_ad" },
  { label: "Industry Publication/Press", value: "industry_publication_press" },
  { label: "Other", value: "other" }
];

const CarrierInfo = ({
  values,
  touched,
  usedPlaid,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isSummary,
  setPassword,
  token,
  landing = false,
}) => {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const openSmsPolicy = useCallback(
    args =>
      dispatch(
        openDialog(
          "read_sms_alert_policy",
        )
      ),
    []
  );
  const handleExit = useCallback(args => dispatch(closeDialog()), []);
  return (
    <Form>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={32}
      >
        <Grid item style={isSummary ? { display: "flex" } : { display: "none" }}>
          <Field name="referrer">
            {({
              field: { value, ...field },
              form,
              hasError = !!(form.errors.referrer && form.touched.referrer)
            }) => (
              <TextField
                label="Where did you find us"
                select
                value={value || ""}
                fullWidth
                helperText={
                  hasError ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: form.errors.referrer }}
                    />
                  ) : null
                }
                error={hasError}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {whereToFindUsOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Field>
        </Grid>
        <Grid item>
          <MultiButtonToggleInputs
            names={["mc", "dot"]}
            placeholder={{ mc: "MC#", dot: "DOT#" }}
            selectedName="mc"
            label="MC / DOT"
            hasError={
              !!((errors.mc && touched.mc) || (errors.dot && touched.dot))
            }
            xs={12}
            sm={12}
            md={12}
            lg={12}
            inputSize={8}
            buttonSize={4}
            onBlur={async () => {
              initFormValues(setFieldValue);
              if (values.mc) {
                try {
                  const data = await throttle(getDataFromMC, 500, {
                    trailing: true
                  })(values.mc);
                  if (!isEmpty(data)) {
                    assignValuesToFields(data, setFieldValue, setFieldTouched);
                  }
                } catch (e) { }
              }
              if (values.dot) {
                try {
                  const data = await throttle(getDataFromDOT, 500, {
                    trailing: true
                  })(values.dot);
                  if (!isEmpty(data)) {
                    assignValuesToFields(data, setFieldValue, setFieldTouched);
                  }
                } catch (e) { }
              }
            }}
          />
          {!!((errors.mc && touched.mc) || (errors.dot && touched.dot)) && (
            <FormHelperText error>
              {[errors.mc, errors.dot]
                .filter(val => !isUndefined(val))
                .join(", ")}
            </FormHelperText>
          )}
        </Grid>
        <Grid
          container
          item
          spacing={8}
          style={
            isSummary
              ? { display: "none" }
              : {
                display: "flex",
                flexDirection: setPassword ? "row" : "column",
                alignItems: "stretch"
              }
          }
        >
          <Grid item style={{ flex: 1 }}>
            <Field name="email">
              {({
                field: { value, ...field },
                form,
                hasError = !!(form.errors.email && form.touched.email)
              }) => (
                <TextField
                  label="Email"
                  placeholder="Email"
                  value={value || ""}
                  fullWidth
                  helperText={
                    hasError ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: form.errors.email }}
                      />
                    ) : null
                  }
                  error={hasError}
                  name={field.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
            </Field>
          </Grid>
          {setPassword && (
            <Grid item style={{ flex: 1 }}>
              <Field name="password">
                {({
                  field: { value, ...field },
                  form,
                  hasError = !!(form.errors.password && form.touched.password)
                }) => (
                  <PasswordInput
                    value={value || ""}
                    fullWidth
                    helperText={hasError ? form.errors.password : null}
                    error={hasError}
                    name={field.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </Field>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Field name="owner_first_name">
            {({
              field: { value, ...field },
              form,
              hasError = !!(
                form.errors.owner_first_name && form.touched.owner_first_name
              )
            }) => (
              <TextField
                label="Owner's First Name"
                placeholder="First Name"
                value={value || ""}
                fullWidth
                FormHelperTextProps={{
                  error: hasError
                }}
                helperText={hasError ? form.errors.owner_first_name : null}
                error={hasError}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Field>
        </Grid>
        <Grid item>
          <Field name="owner_last_name">
            {({
              field: { value, ...field },
              form,
              hasError = !!(
                form.errors.owner_last_name && form.touched.owner_last_name
              )
            }) => (
              <TextField
                label="Owner's Last Name"
                placeholder="Last Name"
                fullWidth
                value={value || ""}
                FormHelperTextProps={{
                  error: hasError
                }}
                helperText={hasError ? form.errors.owner_last_name : null}
                error={hasError}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Field>
        </Grid>
        <Grid item>
          <Field name="owner_phone_number">
            {({
              field: { value, ...field },
              form,
              hasError = !!(
                form.errors.owner_phone_number && form.touched.owner_phone_number
              )
            }) => (
              <TextField
                error={hasError}
                label="Owner's Phone #"
                fullWidth
                value={value || ""}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  inputComponent: PhoneInput
                }}
                helperText={hasError ? errors.owner_phone_number : null}
              />
            )}
          </Field>
        </Grid>
        <Grid item>
          <Field name="company_name">
            {({
              field: { value, ...field },
              form,
              hasError = !!(form.errors.company_name && form.touched.company_name)
            }) => (
              <TextField
                label="Company Name"
                placeholder="Company Name"
                fullWidth
                value={value || ""}
                FormHelperTextProps={{
                  error: hasError
                }}
                helperText={hasError ? form.errors.company_name : null}
                error={hasError}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Field>
        </Grid>
        <Grid
          item
          container
          spacing={8}
          direction="row"
          style={isSummary ? { display: "none" } : null}
        >
          <Grid item xs={8}>
            <Field name="address">
              {({
                field,
                form,
                hasError = !!(form.errors.address && form.touched.address)
              }) => (
                <React.Fragment>
                  <AutoCompleteAddress
                    name={field.name}
                    value={field.value || ""}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    hasError={hasError}
                  />
                  {hasError && (
                    <FormHelperText error id="component-error-text">
                      {errors.address}
                    </FormHelperText>
                  )}
                </React.Fragment>
              )}
            </Field>
          </Grid>
          <Grid
            item
            xs={4}
            alignContent="flex-end"
            alignItems="flex-end"
            style={{ marginTop: 20 }}
          >
            <Field name="street_two">
              {({
                field: { value, ...field },
                form,
                hasError = !!(form.errors.street_two && form.touched.street_two)
              }) => (
                <TextField
                  // label="Street #2 apt#"
                  placeholder="Street 2"
                  fullWidth
                  value={value || ""}
                  FormHelperTextProps={{
                    error: hasError
                  }}
                  helperText={hasError ? form.errors.street_two : null}
                  error={hasError}
                  name={field.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
            </Field>
          </Grid>
        </Grid>
        <Grid
          item
          style={
            isSummary && usedPlaid ? { display: "flex" } : { display: "none" }
          }
        >
          <Field name="bank_info">
            {({
              field: { value, ...field },
              form,
              hasError = !!(form.errors.terms && form.touched.terms)
            }) => (
              <TextField
                label="Bank Info"
                placeholder="Adv checking Banking ****0000"
                fullWidth
                disabled
                value={value || ""}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Field>
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems="flex-start"
            style={isSummary ? { display: "none" } : null}
          >
            <Field name="enable_sms_alerts">
              {({
                field: { value, ...field },
                form,
                hasError = !!(form.errors.enable_sms_alerts && form.touched.enable_sms_alerts)
              }) => (
                <FormGroup>
                  <FormControlLabel
                    label={
                      <Typography>
                        I would like to receive{" "}
                        <a href={void (0)} onClick={openSmsPolicy}>
                          important text messages regarding my operations
                        </a>
                      </Typography>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name={field.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                  />
                  {hasError && (
                    <FormHelperText error>{form.errors.enable_sms_alerts}</FormHelperText>
                  )}
                </FormGroup>
              )}
            </Field>
          </Grid>
        </Grid>
        {/* {!token && !landing && ( */}
        {/*  <Grid item> */}
        {/*    <Grid */}
        {/*      container */}
        {/*      alignItems="flex-start" */}
        {/*      style={isSummary ? { display: "none" } : null} */}
        {/*    > */}
        {/*      <Field name="terms"> */}
        {/*        {({ */}
        {/*          field: { value, ...field }, */}
        {/*          form, */}
        {/*          hasError = !!(form.errors.terms && form.touched.terms) */}
        {/*        }) => ( */}
        {/*          <FormGroup> */}
        {/*            <FormControlLabel */}
        {/*              label={ */}
        {/*                <Typography> */}
        {/*                  I agree to{" "} */}
        {/*                  <a target="_blank" href="https://comfreight.com/tos"> */}
        {/*                    the ComFreight Terms of Service */}
        {/*                  </a> */}
        {/*                </Typography> */}
        {/*              } */}
        {/*              control={ */}
        {/*                <Checkbox */}
        {/*                  color="primary" */}
        {/*                  name={field.name} */}
        {/*                  onChange={handleChange} */}
        {/*                  onBlur={handleBlur} */}
        {/*                /> */}
        {/*              } */}
        {/*            /> */}
        {/*            {hasError && ( */}
        {/*              <FormHelperText error>{form.errors.terms}</FormHelperText> */}
        {/*            )} */}
        {/*          </FormGroup> */}
        {/*        )} */}
        {/*      </Field> */}
        {/*    </Grid> */}
        {/*  </Grid> */}
        {/* )} */}
      </Grid>
      {dialog.variant === "read_sms_alert_policy" && (
        <SMSAlertPolicyDialog
          open={dialog.show}
          handleExit={handleExit}
        />
      )}
    </Form>
  )
};

CarrierInfo.defaultProps = {
  isSummary: false,
  usedPlaid: false
};

CarrierInfo.propTypes = {
  isSummary: PropTypes.bool,
  usedPlaid: PropTypes.bool
};

export default CarrierInfo;

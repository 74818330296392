import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

import colors from "../../../../../../theme/colors";

const Trash = () => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />;
};

export default Trash;

import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";

import { openDialog, getDialog, closeDialog } from "../../../../../modules/ui";
import Table from "../../../../../components/Table";
import { getCreditReport } from "../../../../../modules/debtor";
import formatDate from "../../../../../helpers/format/formatDate";
import AddCreditCheckDataModal from "./AddCreditCheckDataModal";

const columns: any[] = [
  {
    key: "created",
    name: "Pull Time",
    format: formatDate
  },
  {
    key: "risk_score",
    name: "Risk Score"
  },
  {
    key: "days_to_pay",
    name: "Average DTP"
  },
  {
    key: "alerts",
    name: "Alerts"
  },
  {
    key: "average_monthly_balance",
    name: "Average Monthly Balance"
  },
  {
    key: "total_companies_reporting",
    name: "Total Companies Reporting"
  },
  {
    key: "final_risk_score",
    name: "CF Safety Score"
  }
];
interface Props {
  id: string;
}
export default function({ id }: Props) {
  const dispatch = useDispatch();
  const report = useSelector(getCreditReport);
  const dialog = useSelector(getDialog);
  const openAddCreditCheckDataModal = useCallback(
    args => dispatch(openDialog(args)),
    []
  );
  const closeModal = useCallback(args => dispatch(closeDialog()), []);
  return (
    <Grid container direction="column" style={{ padding: 16 }}>
      <Button
        variant="raised"
        color="primary"
        style={{ alignSelf: "flex-end", padding: 10 }}
        onClick={() => openAddCreditCheckDataModal("add_credit_check_data")}
      >
        Manual Data Add
      </Button>
      <Grid item xs={12}>
        <Table columns={columns} rows={report} allowEmpty />
      </Grid>
      {dialog.variant === "add_credit_check_data" && (
        <AddCreditCheckDataModal id={id} handleClose={closeModal} />
      )}
    </Grid>
  );
}

import { PayloadAction } from "redux-starter-kit";
import { FETCH_GROUPS_FAILURE, FETCH_GROUPS_REQUEST, FETCH_GROUPS_SUCCESS, State } from "../types";

const fetchUserToken = (
  state: State,
  { type, payload }: PayloadAction
): State => {
  switch (type) {
    case FETCH_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
        authError: ""
      };
    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: payload
      };
    case FETCH_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        authError: payload
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchUserToken;

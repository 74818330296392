import { createAction, PayloadAction } from "redux-starter-kit";

import { SET_AUTH_EMAIL } from "../types";

const setEmail = (email: string): PayloadAction<any, typeof SET_AUTH_EMAIL> =>
  createAction(SET_AUTH_EMAIL)({
    email
  });

export default setEmail;

const formatPhone = (phone: string | null | undefined): string => {
  if (!phone) {
    return "";
  }
  // normalize string and remove all unnecessary characters
  const normal = phone.replace(/[^\d]/g, "");

  // check if number length equals to 10
  if (normal.length === 10) {
    // reformat and return phone number
    return normal.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }

  if (normal.length === 11) {
    // reformat and return phone number
    return normal.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
  }

  return phone;
};

export default formatPhone;

const states = [
  {
    key: "AB",
    value: "Alberta"
  },
  {
    key: "AK",
    value: "Alaska"
  },
  {
    key: "AL",
    value: "Alabama"
  },
  {
    key: "AR",
    value: "Arkansas"
  },
  {
    key: "AZ",
    value: "Arizona"
  },
  {
    key: "BC",
    value: "British Columbia"
  },
  {
    key: "CA",
    value: "California"
  },
  {
    key: "CO",
    value: "Colorado"
  },
  {
    key: "CT",
    value: "Connecticut"
  },
  {
    key: "DC",
    value: "District of Columbia"
  },
  {
    key: "DE",
    value: "Delaware"
  },
  {
    key: "FL",
    value: "Florida"
  },
  {
    key: "GA",
    value: "Georgia"
  },
  {
    key: "HI",
    value: "Hawaii"
  },
  {
    key: "IA",
    value: "Iowa"
  },
  {
    key: "ID",
    value: "Idaho"
  },
  {
    key: "IL",
    value: "Illinois"
  },
  {
    key: "IN",
    value: "Indiana"
  },
  {
    key: "KS",
    value: "Kansas"
  },
  {
    key: "KY",
    value: "Kentucky"
  },
  {
    key: "LA",
    value: "Louisiana"
  },
  {
    key: "MA",
    value: "Massachusetts"
  },
  {
    key: "MB",
    value: "Manitoba"
  },
  {
    key: "MD",
    value: "Maryland"
  },
  {
    key: "ME",
    value: "Maine"
  },
  {
    key: "MI",
    value: "Michigan"
  },
  {
    key: "MN",
    value: "Minnesota"
  },
  {
    key: "MO",
    value: "Missouri"
  },
  {
    key: "MS",
    value: "Mississippi"
  },
  {
    key: "MT",
    value: "Montana"
  },
  {
    key: "NB",
    value: "New Brunswick"
  },
  {
    key: "NC",
    value: "North Carolina"
  },
  {
    key: "ND",
    value: "North Dakota"
  },
  {
    key: "NE",
    value: "Nebraska"
  },
  {
    key: "NH",
    value: "New Hampshire"
  },
  {
    key: "NJ",
    value: "New Jersey"
  },
  {
    key: "NL",
    value: "Newfoundland"
  },
  {
    key: "NM",
    value: "New Mexico"
  },
  {
    key: "NS",
    value: "Nova Scotia"
  },
  {
    key: "NT",
    value: "Northwest Territories"
  },
  {
    key: "NU",
    value: "Nunavut"
  },
  {
    key: "NV",
    value: "Nevada"
  },
  {
    key: "NY",
    value: "New York"
  },
  {
    key: "OH",
    value: "Ohio"
  },
  {
    key: "OK",
    value: "Oklahoma"
  },
  {
    key: "ON",
    value: "Ontario"
  },
  {
    key: "OR",
    value: "Oregon"
  },
  {
    key: "PA",
    value: "Pennsylvania"
  },
  {
    key: "PE",
    value: "Prince Edward Island"
  },
  {
    key: "QC",
    value: "Quebec"
  },
  {
    key: "RI",
    value: "Rhode Island"
  },
  {
    key: "SC",
    value: "South Carolina"
  },
  {
    key: "SD",
    value: "South Dakota"
  },
  {
    key: "SK",
    value: "Saskatchewan"
  },
  {
    key: "TN",
    value: "Tennessee"
  },
  {
    key: "TX",
    value: "Texas"
  },
  {
    key: "UT",
    value: "Utah"
  },
  {
    key: "VA",
    value: "Virginia"
  },
  {
    key: "VT",
    value: "Vermont"
  },
  {
    key: "WA",
    value: "Washington"
  },
  {
    key: "WI",
    value: "Wisconsin"
  },
  {
    key: "WV",
    value: "West Virginia"
  },
  {
    key: "WY",
    value: "Wyoming"
  },
  {
    key: "YT",
    value: "Yukon Territory"
  }
];

export default states;

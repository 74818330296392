import React from "react";
import {
  List,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { getEmojiDataFromNative } from "emoji-mart";
import data from "emoji-mart/data/all.json";
import { useFormikContext } from 'formik';

import BoldInput, {
  CheckBox,
  Select
} from "../../../../../components/inputs/BoldInput";
import AdminDropDown from "../../../../../components/AdminDropDown";
import Card from "../../../../../components/Card";
import EquipmentTypeInput from "../../../../../components/inputs/EquipmentTypeInput";
import ListItem from "../../../../../components/ListItem";
import SourceIcon from "../../../../../components/icons/SourceIcon";
import ProfileSection from "../../../../../components/ProfileSection";

const ContactStatus = [
  { text: "No Contact", value: "no_contact" },
  { text: "First Contact", value: "first" },
  { text: "Second Contact", value: "second" },
  { text: "Third Contact", value: "third" }
];

const whereToFindUsOptions = [
  { text: "Organic Search/Google", value: "organic_search" },
  { text: "Google Ad", value: "google_ad" },
  { text: "Facebook Ad", value: "facebook_ad" },
  { text: "Instagram Ad", value: "instagram_ad" },
  { text: "Industry Publication/Press", value: "industry_publication_press" },
  { text: "Other", value: "other" }
];

interface Props {
  source: string;
}

function GeneralInfo({
  source
}: Props): JSX.Element {
  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext<any>();
  return (
    <Card style={{ height: "100%" }}>
      <List dense>
        <ListItem>
          <ProfileSection>General Info</ProfileSection>
        </ListItem>
        <ListItem>
          <AdminDropDown
            fullWidth
            name="assigned_admin"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.assigned_admin}
            label="Sales Rep"
          />
        </ListItem>
        <ListItem>
          <AdminDropDown
            fullWidth
            name="account_manager"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.account_manager}
            label="Account Manager"
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="user_type"
            label="User Type"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.user_type}
            disabled
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="created"
            label="Created At"
            disabled
            value={values.created}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            disabled
            name="last_login"
            label="Last Login"
            value={values.last_login}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="commodities_shipped"
            label="Common Commodity"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.commodities_shipped}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="password"
            label="Password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
        </ListItem>
        <ListItem>
          <FormControl fullWidth>
            <FormLabel
              style={{
                color: "black",
                fontSize: 15,
                fontWeight: 900
              }}
            >
              Language
            </FormLabel>
            <RadioGroup
              aria-label="Language"
              name="language"
              value={values.language}
              onChange={(event: React.ChangeEvent<{}>): void => handleChange(event)}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                value="en"
                control={<Radio />}
                label={`EN ${
                  getEmojiDataFromNative("🇺🇸", "apple", data).native
                }`}
              />
              <FormControlLabel
                value="es"
                control={<Radio />}
                label={`ES ${
                  getEmojiDataFromNative("🇲🇽", "apple", data).native
                }`}
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
        <ListItem>
          <CheckBox
            value={values.enable_sms_alerts}
            name="enable_sms_alerts"
            setFieldValue={setFieldValue}
            label="Enabled SMS Alerts"
          />
        </ListItem>
        <ListItem>
          <EquipmentTypeInput
            onBlur={handleBlur}
            onChange={handleChange}
            values={values}
          />
        </ListItem>
        <ListItem>
          <Select
            value={values.contact_status}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            name="contact_status"
            label="Contact Status"
            selections={ContactStatus}
          />
        </ListItem>
        <ListItem>
          <CheckBox
            disabled
            value={values.dispatching_interest}
            name="dispatching_interest"
            setFieldValue={setFieldValue}
            label="Dispatch Client"
          />
        </ListItem>
        <ListItem>
          <CheckBox
            disabled
            value={values.is_trialing}
            name="is_trialing"
            setFieldValue={setFieldValue}
            label="Is Trialing"
          />
        </ListItem>
        <ListItem>
          <CheckBox
            disabled
            value={values.is_subscribed}
            name="is_subscribed"
            setFieldValue={setFieldValue}
            label="Is Subscribed"
          />
        </ListItem>
        <ListItem>
          <Select
            value={values.referrer}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            name="referrer"
            label="Referral"
            selections={whereToFindUsOptions}
          />
        </ListItem>
        <ListItem>
          <SourceIcon width={30} height={30} source={source} />
        </ListItem>
      </List>
    </Card>
  );
}

export default GeneralInfo;
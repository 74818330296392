import React from "react";
import { Chip } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";

import ReserveAmount from "../../../../components/ReserveAmount";
import formatPennies from "../../../../helpers/format/formatPennies";
import formatDate from "../../../../helpers/format/formatDate";
import HaulPayStatus from "../../../../components/StatusFormat";
import formatStatus from "../../../../helpers/format/formatStatus";

const fundingRequestReceivables = (
  status: string[],
  setStatus: Function,
  userType: string
) => [
  {
    key: "funding_request_invoice_number",
    name: "Invoice #",
    default: "-",
    filter: "text",
    filter_key: "invoice_number"
  },
  {
    key: "funding_request.user_load_number",
    name: "Load #",
    default: "-",
    filter: "text",
    filter_key: "user_load_number",
    formKey: "user_load_number"
  },
  {
    key: "purchase_order_number",
    name: "PO #",
    filter: "text",
    filter_key: "purchase_order_number"
  },
  {
    key: "payment_profile_name",
    name: "Pmnt. Profile",
    filter: "text",
    filter_key: "payment_profile_name",
    formKey: "payment_profile_name"
  },
  {
    key: "debtor_name",
    name: "Debtor",
    filter: "text",
    filter_key: "debtor_name",
    formKey: "debtor_name"
  },
  {
    key: "funding_request.amount",
    name: "Invoice Amount",
    format: formatPennies,
    formKey: "amount"
  },
  {
    key: "amount_balance",
    name: "Amount Due",
    format: formatPennies,
    formKey: "amount_due"
  },
  {
    key: 'reserve_info',
    name: 'Reserve',
    format: (value: any) => (
      <ReserveAmount
        amount={value.reserve_amount}
        negative={value.has_negative_reserve}
      />
    )
  },
  {
    key: "invoice_date",
    name: "Invoice Date",
    format: formatDate
  },
  {
    key: "followup_date",
    name: "Next Followup",
    format: formatDate,
    sort: true
  },
  {
    key: "response_category",
    name: "Response Category",
    filter_key: "response_category",
    filter: "dropdown",
    filter_values: [
      {
        label: "None",
        value: "none"
      },
      {
        label: "No Response",
        value: "no_response"
      },
      { label: "Resubmit Invoice", value: "resubmit_invoice" },
      { label: "Promise To Pay", value: "promise_to_pay" },
      { label: "Follow Up", value: "follow_up" },
      { label: "Debtor Issue", value: "debtor_issue" },
      { label: "Document Issue A", value: "document_issue_a" },
      { label: "Document Issue B", value: "document_issue_b" },
      { label: "Document Issue C", value: "document_issue_c" },
      { label: "Pending Claim", value: "pending_claim" },
      { label: "Payment Issue", value: "payment_issue" }, 
      { label: "Internal CF", value: "internal_cf" },
      { label: "Issue", value: "issue" }
    ],
    format: formatStatus
  },
  {
    key: "funding_request.debtor.dtp_allowance",
    name: "Net Terms",
    default: 30,
    sort: true
  },
  {
    key: "status",
    name: "Status",
    filter: "dropdown",
    selectProps: {
      value: status, // override the value with our new state value
      multiple: true,
      displayEmpty: true,
      renderValue: (selected: string[]): JSX.Element => (
        <div>
          {!isEmpty(selected) &&
            selected.map((selection: string) => (
              <Chip key={selection} label={selection} />
            ))}
        </div>
      )
    },
    filter_key: "status",
    filter_value: status,
    filter_update_value: setStatus,
    filter_values: [
      {
        label: "Open",
        value: "open"
      },
      {
        label: "Paid",
        value: "paid"
      },
      {
        label: "Short Paid",
        value: "short_paid"
      },
      {
        label: "Write Off",
        value: "write_off"
      },
      {
        label: "Double Paid",
        value: "double_paid"
      },
      {
        label: "Client Settled",
        value: "client_settled"
      },
      {
        label: "Over Paid",
        value: "over_paid"
      },
      {
        label: "Non Factored Open",
        value: "non_factored_open"
      },
      {
        label: "Non Factored Short Paid",
        value: "non_factored_short_paid"
      },
      {
        label: "Non Factored Over Paid",
        value: "non_factored_over_paid"
      },
    ],
    format: (value: string): JSX.Element => (
      <HaulPayStatus status={formatStatus(value)} />
    )
  },
  {
    key: "aging",
    name: "Aging",
    format: (value: string): string =>
      value && Number.isFinite(Number(value))
        ? `${value} day${Number(value) > 1 ? "s" : ""}`
        : "-",
    sort: true,
    filter_key: "receivable_age"
  },
  {
    key: "more",
    name: "More",
    formKey: "actions"
  }
];

export default fundingRequestReceivables;

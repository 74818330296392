import React from "react";
import { createStyles, Fab, withStyles, WithStyles } from "@material-ui/core";
import colors from "../../../../../theme/colors";
import Link from "../../../../../components/Link";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = () =>
  createStyles({
    button: {
      boxShadow:
        "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      background: colors.green,
      color: colors.white,
      textTransform: "none"
    }
  });

interface Props extends WithStyles<typeof styles> {
  id: string;
}

export default withStyles(styles)(
  ({ id, classes }: Props): JSX.Element => (
    <Link to={`/admin/factorclients/${id}/profile`} underline={false}>
      <Fab variant="extended" size="medium" className={classes.button}>
        Client Profile
      </Fab>
    </Link>
  )
);
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import React from "react";

interface Props {
  id: string;
}

const MoreButton = ({ id }: Props): JSX.Element => (
  <Link to={`/admin/fundingrequest/${id}`}>
    <IconButton style={{ color: "#2196f3" }}>
      <EditIcon />
    </IconButton>
  </Link>
);

export default MoreButton;

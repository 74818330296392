import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import MenuList from "@material-ui/core/MenuList";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import styles from "../CustomTextField/styles";

const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.noOptionsMessage}
    {...innerProps}
  >
    {children}
  </Typography>
);

const LoadingMessage = ({ selectProps, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.noOptionsMessage}
    {...innerProps}
  >
    {children}
  </Typography>
);

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

const Control = withStyles(styles)(
  ({ classes, selectProps, innerRef, children, innerProps }) => {
    const {
      textFieldProps: { withStyle }
    } = selectProps;
    const underlineClasses = classNames({
      [classes.underlineAddress]: true
    });
    const inputClasses = withStyle
      ? {
          classes: {
            underline: underlineClasses,
            input: classes.input,
            error: classes.errorInput
          }
        }
      : {};
    const labelClasses = withStyle
      ? {
          classes: {
            focused: classes.cssFocused,
            error: classes.errorLabel
          }
        }
      : {};
    return (
      <TextField
        fullWidth
        error={selectProps.error}
        InputProps={{
          inputComponent,
          ...inputClasses,
          inputProps: {
            className: selectProps.classes.input,
            inputRef: innerRef,
            children,
            ...innerProps
          }
        }}
        {...selectProps.textFieldProps}
        InputLabelProps={{
          ...labelClasses,
          shrink: true
        }}
      />
    );
  }
);

const Option = ({ innerRef, isFocused, isSelected, innerProps, children }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400
    }}
    {...innerProps}
  >
    {children}
  </MenuItem>
);

const Placeholder = ({ selectProps, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.placeholder}
    {...innerProps}
  >
    {children}
  </Typography>
);

const SingleValue = ({ selectProps, innerProps, children }) => (
  <Typography className={selectProps.classes.singleValue} {...innerProps}>
    {children}
  </Typography>
);

const ValueContainer = ({ selectProps, children }) => (
  <div className={selectProps.classes.valueContainer}>{children}</div>
);

const MultiValue = ({ children, selectProps, isFocused, removeProps }) => (
  <Chip
    tabIndex={-1}
    label={children}
    className={classNames(selectProps.classes.chip, {
      [selectProps.classes.chipFocused]: isFocused
    })}
    onDelete={removeProps.onClick}
    deleteIcon={<CancelIcon {...removeProps} />}
  />
);

const Menu = ({ selectProps, innerProps, children }) => (
  <Paper square className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

export default {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  LoadingMessage
};

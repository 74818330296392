import { createReducer } from "redux-starter-kit";
import * as TYPES from "./types";
import fetchAdminsReducers from "./reducers/fetchAdmins";
import withTable from "../withTable";
import queryFetchAdmins from "./actions/fetchAdmins";

const INITIAL_STATE = {
  ids: []
};

const {
  selectors,
  actions,
  reducer: tableReducer,
  middleware: tableMiddleware
} = withTable({
  stateKey: "admins",
  typeKey: "admins"
});

const reducer = createReducer(INITIAL_STATE, {
  [TYPES.FETCH_QUERY_ADMINS_FAILURE]: fetchAdminsReducers,
  [TYPES.FETCH_QUERY_ADMINS_REQUEST]: fetchAdminsReducers,
  [TYPES.FETCH_QUERY_ADMINS_SUCCESS]: fetchAdminsReducers
});

export default tableReducer(reducer);

export const middleware = tableMiddleware(tableSelectors => store => action => {
  const state = store.getState();
  const sortBy = tableSelectors.getSortBy(state);
  const sortDirection = tableSelectors.getSortDirection(state);
  const offset = tableSelectors.getOffset(state);
  const rowsPerPage = tableSelectors.getRowsPerPage(state);
  const filters = tableSelectors.getFilters(state);

  store.dispatch(
    queryFetchAdmins(
      sortDirection === "asc" ? sortBy : `-${sortBy}`,
      rowsPerPage,
      offset,
      filters
    )
  );
});

export const {
  getOffset,
  getRowsPerPageOptions,
  getRowsPerPage,
  getCount,
  getSortDirection,
  getSortBy,
  getPage,
  getFilters,
  getIsLoading
} = selectors;

export const {
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter
} = actions;

export { default as fetchAdmins } from "./actions/fetchAdmins";
export { default as getAdmins } from "./selectors/getAdmins";

import { Button, Grid, MenuItem, Select } from "@material-ui/core";
import { find, get, isEmpty, filter, findIndex, map } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularButton from "../../../../components/buttons/CircularButton";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import Table from "../../../../components/Table";
import {
  getConnectedGroups,
  fetchConnectedGroups
} from "../../../../modules/groups";
import { updatePaymentTerms } from "../../../../modules/invited";
import {
  openDialog,
  getDialog,
  closeDialog,
  openSnackbar
} from "../../../../modules/ui";
import colors from "../../../../theme/colors";
import CreateFactoringCompanyModal from "../components/CreateFactoringCompanyModal";
import PaymentSpeedModal from "../components/PaymentSpeedModal";
import groupsAPI from "../../../../api/groups";
import columns from "./columns";
import {
  getAccounts,
  fetchAccounts
} from "../../../../modules/factoringClient";

import { getCurrentUserId } from "../../../../modules/auth";
import BankAccountModal from "../components/BankAccountModal";
import segmentsEvents from "../../../../helpers/segmentsEvents";

export default function() {
  const [newFactorCompany, setNewFactorCompany] = useState({});
  const [formikRefArray, setFormikRefArray] = useState([]);
  const [useFactoringCompany, setUseFactoringCompany] = useState([]);
  const [paymentSpeedModaldisplayed, setPaymentSpeedModalDisplayed] = useState(
    []
  );
  const [notes, setNotes] = useState(false);
  const [paymentSpeedLabel, setPaymentSpeedLabel] = useState({});
  const dispatch = useDispatch();
  const connectedGroups = useSelector(getConnectedGroups);
  const dialog = useSelector(getDialog);
  const userId = useSelector(getCurrentUserId);
  const activeAccounts = filter(
    useSelector(getAccounts(userId)),
    o =>
      !get(o, "deleted_by") &&
      !get(o, "deleted_reason") &&
      get(o, "account_user_type") !== "factoring_company"
  );

  useEffect(() => {
    async function asyncFunction() {
      await dispatch(fetchAccounts(userId));
    }
    if (userId) asyncFunction();
  }, [userId]);

  const openCreateCompanyModal = useCallback(
    args =>
      dispatch(
        openDialog("create_factoring_company", "", "", {
          brokerFactoringId:
            get(args, "group_data.factoring_id") || get(args, "factoringId"),
          formIndex: get(args, "formIndex")
        })
      ),
    []
  );

  const openPaymentSpeedModal = useCallback(
    args =>
      dispatch(
        openDialog("payment_speed_case", "", "", {
          factoringId: get(args, "group_data.factoring_id"),
          index: args.index,
          formIndex: args.formIndex,
          payment_speed_options: get(args, "payment_speed_options"),
          paymentSpeed: get(args, "paymentSpeed")
        })
      ),
    []
  );

  const openBankAccountModal = useCallback(
    args => dispatch(openDialog("add_bank_account", "", "", args)),
    []
  );

  const handleClose = useCallback(async args => dispatch(closeDialog()));

  const updatePaymentTermsCallback = useCallback(
    (...args) => dispatch(updatePaymentTerms(...args)),
    []
  );

  const updateFactoringCompanyCallback = useCallback(
    (...args) => groupsAPI.updatePaymentMethod(...args),
    []
  );

  const snackBarMessage = useCallback(
    (...args) => dispatch(openSnackbar(...args)),
    []
  );

  const handleConfirm = useCallback(
    async ({ factorCompany, brokerFactoringId, formIndex }) => {
      handleClose();
      try {
        // const relation = await factorCompanyAPI.getRelationByFactorCompanyId(
        //   factorCompany.id
        // );
        // if (
        //   isEmpty(
        //     find(relation.results, ["factoring_company", factorCompany.id])
        //   )
        // ) {
        //   await factorCompanyAPI.createFactorCompanyRelation(
        //     factorCompany.id,
        //     brokerFactoringId
        //   );
        // }
        // await factorCompanyAPI.sendFactorCompanyInvite(factorCompany.id);
        await groupsAPI.changeFactoringCompany({
          factoring_id: brokerFactoringId,
          note: notes,
          factoring_company_id: factorCompany.id
        });
        if (
          !get(
            formikRefArray[formIndex],
            "initialValues.factoringCompanies",
            []
          ).length
        ) {
          await updateFactoringCompanyCallback(
            factorCompany.id,
            get(
              formikRefArray[formIndex],
              "initialValues.factoringPaymentProfileRelationId"
            ),
            { factoring_company: factorCompany.id }
          );
        }
        setNewFactorCompany(factorCompany);
        formikRefArray[formIndex].setFieldValue(
          "paymentSpeed",
          factorCompany.id
        );
        openPaymentSpeedModal({
          index: 4
        });
      } catch (e) {
        dispatch(openSnackbar("error", e));
      }
    },
    [formikRefArray, notes]
  );

  const rows = (connectedGroups.data || []).map((o, i) => ({
    ...o,
    payment_speed: ({ values, setFieldValue, handleSubmit, submitting }) => (
      <Grid container xs={12}>
        <Select
          id="default_payment"
          name="paymentSpeed"
          value={values.paymentSpeed}
          style={{ minWidth: 250, maxWidth: 250 }}
          onChange={event => {
            if (event.target.value === "factoring") {
              return get(o, "factoring_companies", []).length > 0
                ? openCreateCompanyModal({ ...o, formIndex: i })
                : openCreateCompanyModal({ ...o, formIndex: i });
            }
            setFieldValue("paymentSpeed", event.target.value);
          }}
        >
          {!isEmpty(newFactorCompany) ? (
            <MenuItem
              key={newFactorCompany.id}
              value={newFactorCompany.id}
              id={newFactorCompany.id}
            >
              <em style={{ fontSize: 15, color: "#2196f3" }}>
                {`Processing request for ${newFactorCompany.name}`}
              </em>
            </MenuItem>
          ) : (
            ""
          )}
          {get(o, "payment_speed_options", [])
            .sort((a, b) => a.payout_days - b.payout_days)
            .map(
              (
                {
                  payout_days,
                  name,
                  carrier_rate: discountRate,
                  is_default: isDefault,
                  id,
                  in_process: inProcess,
                },
                index
              ) => (
                <MenuItem key={index} value={id} id={index}>
                  <em>
                    {!isEmpty(paymentSpeedLabel) && paymentSpeedLabel.id === id
                      ? `${inProcess ? 'Processing request for' : ''} ${paymentSpeedLabel.label}`
                      : `${inProcess ? 'Processing request for' : ''} ${name} (${discountRate}% Quick Pay Fee)`}
                  </em>
                </MenuItem>
              )
            )}
          {get(o, "factoring_companies", []).map(
            ({ current, id, name }, index) => {
              const newName = o.factoring_companies.length > 1 && current && isEmpty(newFactorCompany)
                ? `Processing request for ${name}`
                : `${name} ${current ? "- My Factor" : ""}`
              return (
                <MenuItem key={index} value={id} id={index}>
                  <em style={{ fontSize: 15, color: "#2196f3" }}>
                    {newName}
                  </em>
                </MenuItem>
              )
            })}
          <MenuItem value="factoring" id="factoring">
            <p style={{ fontSize: 15, color: "#2196f3" }}>
              {get(o, "factoring_companies", []).length > 0
                ? "Change to a new Factor"
                : "Use my Factoring Company"}
            </p>
          </MenuItem>
        </Select>
        <CircularButton
          loading={submitting}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Update
        </CircularButton>
      </Grid>
    ),
    boarding: get(o, "group_data.tms_url", "") ? (
      <Button
        style={{ background: colors.green, color: colors.white }}
        variant="contained"
        onClick={() => {
          window.open(
            o.group_data.tms_url.match(/^http[s]?:\/\//)
              ? o.group_data.tms_url
              : `//${o.group_data.tms_url}`,
            "_blank"
          );
        }}
      >
        Go Here
      </Button>
    ) : (
      <div />
    )
  }));
  useEffect(() => {
    const asyncFunction = async () => {
      await dispatch(fetchConnectedGroups());
    };
    asyncFunction();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Table
        formikRow={(group, index) => {
          useFactoringCompany[index] = get(
            group,
            "group_data.using_factoring_company",
            false
          );
          const inProcessPaymentTerms = find(group.payment_speed_options, ["in_process", true]);
          return {
            enableReinitialize: true,
            innerRef: ref => (formikRefArray[index] = ref),
            initialValues: {
              paymentSpeed: (!get(
                group,
                "group_data.using_factoring_company",
                false
              ) || !isEmpty(inProcessPaymentTerms))
                ? isEmpty(inProcessPaymentTerms) ? (
                     find(group.payment_speed_options, [
                      "payout_days",
                      parseInt(
                        ((group.payout_days || "").match(/\d+/) || [])[0],
                        0
                      ) === 1
                        ? 0
                        : parseInt(
                            ((group.payout_days || "").match(/\d+/) || [])[0],
                            0
                          )
                    ]) || {}
                ).id : inProcessPaymentTerms.id
                : (find(group.factoring_companies, "current") || {}).id,
              brokerFactoringId: get(group, "group_data.factoring_id"),
              paymentSpeedTerms: get(group, "payment_speed_options"),
              factoringCompanies: get(group, "factoring_companies"),
              paymentProfileId: get(group, "group_data.payment_profile_id"),
              factoringPaymentProfileRelationId: get(
                group,
                "group_data.factoring_payment_profile_relation_id"
              )
            },
            onSubmit: async (values, { setSubmitting }) => {
              try {
                setSubmitting(true);
                if (
                  !isEmpty(
                    find(values.factoringCompanies, ["id", values.paymentSpeed])
                  ) ||
                  (!isEmpty(newFactorCompany) &&
                    newFactorCompany.id === values.paymentSpeed)
                ) {
                  await updateFactoringCompanyCallback(
                    values.paymentSpeed,
                    values.factoringPaymentProfileRelationId,
                    { factoring_company: values.paymentSpeed }
                  );
                  window.analytics.track(segmentsEvents.HAULPAY_PAYMENT_PROFILE_INVITED_IC_PORTAL_CONNECTED_PAYMENT_SPEED_UPDATE)
                  snackBarMessage("success", "Payment Speed has been updated.");
                  return setSubmitting(false);
                }
                if (
                  useFactoringCompany[index] &&
                  !paymentSpeedModaldisplayed[index]
                ) {
                  useFactoringCompany[index] = false;
                  return openPaymentSpeedModal({
                    formIndex: index,
                    ...group,
                    index: 1,
                    displayFactoringCompanyConfirmModal: true,
                    paymentSpeed: values.paymentSpeed
                  });
                }
                if (activeAccounts.length === 0) {
                  return openBankAccountModal({
                    formIndex: index,
                    paymentProfileId: get(
                      group,
                      "group_data.payment_profile_id"
                    ),
                    paymentSpeed: values.paymentSpeed,
                    brokerFactoringId: values.brokerFactoringId
                  });
                }
                if (
                  !useFactoringCompany[index] &&
                  !paymentSpeedModaldisplayed[index]
                ) {
                  return openPaymentSpeedModal({
                    formIndex: index,
                    ...group,
                    index: 5,
                    paymentSpeed: values.paymentSpeed
                  });
                }
                paymentSpeedModaldisplayed[index] = false;
                await updatePaymentTermsCallback(
                  values.brokerFactoringId,
                  values.paymentSpeed,
                  {
                    is_default: true,
                    remove_factoring_company: !!useFactoringCompany[index]
                  },
                  {
                    send_update_email: true
                  }
                );
                window.analytics.track(segmentsEvents.HAULPAY_PAYMENT_PROFILE_INVITED_IC_PORTAL_CONNECTED_PAYMENT_SPEED_UPDATE)
                snackBarMessage("success", "Payment Speed has been updated.");
                setSubmitting(false);
              } catch (e) {
                snackBarMessage("error", e);
              }
            }
          };
        }}
        columns={columns}
        rows={rows}
        allowEmpty
        isLoading={connectedGroups.loading}
        expanded
      />
      {dialog.variant === "create_factoring_company" && (
        <CreateFactoringCompanyModal
          brokerFactoringId={get(dialog, "data.brokerFactoringId")}
          open={dialog.show}
          formIndex={get(dialog, "data.formIndex")}
        />
      )}
      {dialog.variant === "add_bank_account" && (
        <BankAccountModal
          open={dialog.show}
          fetchAccounts={() => fetchAccounts(userId)}
          handleSubmit={() => {
            if (useFactoringCompany[get(dialog, "data.formIndex")]) {
              useFactoringCompany[get(dialog, "data.formIndex")] = false;
              openPaymentSpeedModal({
                index: 3
              });
            }
            formikRefArray[get(dialog, "data.formIndex")].handleSubmit();
          }}
          paymentProfileId={get(dialog, "data.paymentProfileId")}
          handleConfirm={async () => {
            try {
              if (useFactoringCompany[get(dialog, "data.formIndex")]) {
                useFactoringCompany[get(dialog, "data.formIndex")] = false;
                openPaymentSpeedModal({
                  index: 3
                });
              }
              await updatePaymentTermsCallback(
                get(dialog, "data.brokerFactoringId"),
                get(dialog, "data.paymentSpeed"),
                {
                  is_default: true,
                  remove_factoring_company: !!useFactoringCompany[
                    get(dialog, "data.formIndex")
                  ]
                },
                {
                    send_update_email: true
                }
              );
              snackBarMessage("success", "Payment Speed has been updated.");
            } catch (e) {
              snackBarMessage("error", e);
            }
          }}
        />
      )}
      {dialog.variant === "payment_speed_case" && (
        <PaymentSpeedModal
          open={dialog.show}
          {...get(dialog, "data")}
          openCreateCompanyModal={openCreateCompanyModal}
          setNotesForm={setNotes}
          handleSubmit={() => {
            useFactoringCompany[get(dialog, "data.formIndex")] = get(
              dialog,
              "data.displayFactoringCompanyConfirmModal",
              false
            );
            paymentSpeedModaldisplayed[get(dialog, "data.formIndex")] = true;
            const paymentSpeedIndex = findIndex(
              get(dialog, "data.payment_speed_options"),
              ["id", get(dialog, "data.paymentSpeed")]
            );
            const paymentSpeedOptions = get(
              dialog,
              "data.payment_speed_options"
            )[paymentSpeedIndex];
            if (get(dialog, "data.index") !== 5)
              setPaymentSpeedLabel({
                ...paymentSpeedOptions,
                label: `Processing request for ${
                  get(dialog, "data.payment_speed_options")[paymentSpeedIndex]
                    .name
                } (${
                  get(dialog, "data.payment_speed_options")[paymentSpeedIndex]
                    .carrier_rate
                }% Quick Pay Fee)`
              });
            formikRefArray[get(dialog, "data.formIndex")].handleSubmit();
          }}
          handleExit={() => {
            formikRefArray[get(dialog, "data.formIndex")].resetForm();
          }}
          formsRef={formikRefArray}
        />
      )}
      {dialog.variant === "confirm_send_invite" && (
        <ConfirmDialog
          open={dialog.show}
          message={dialog.message}
          handleConfirm={() =>
            handleConfirm({
              factorCompany: get(dialog, "data.factorCompany"),
              brokerFactoringId: get(dialog, "data.brokerFactoringId"),
              formIndex: get(dialog, "data.formIndex")
            })
          }
          handleExit={handleClose}
        />
      )}
    </div>
  );
}

import { assign } from "lodash";
import {
  QUERY_FACTORING_COMPANIES_REQUEST,
  QUERY_FACTORING_COMPANIES_SUCCESS,
  QUERY_FACTORING_COMPANIES_FAILURE
} from "../types";

const sameRequest = (state, payload) => {
  return (
    state.factoringCompanies.limit === payload.limit &&
    // state.invitedCarriers.filters === payload.filters &&
    state.factoringCompanies.ordering === payload.ordering
  );
};

export default (
  state = {
    loading: false,
    ids: []
  },
  action
) => {
  switch (action.type) {
    case QUERY_FACTORING_COMPANIES_REQUEST:
      return {
        ...state,
        factoringCompanies: assign({}, state.factoringCompanies, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          ordering: action.payload.ordering,
          filters: action.payload.filters
        })
      };
    case QUERY_FACTORING_COMPANIES_SUCCESS:
      if (sameRequest(state, action.payload)) {
        return {
          factoringCompanies: assign({}, state.factoringCompanies, {
            loading: false,
            ids: action.payload.items,
            count: action.payload.count
          })
        };
      }
      return state;
    case QUERY_FACTORING_COMPANIES_FAILURE:
      return {
        factoringCompanies: assign({}, state.factoringCompanies, {
          loading: false
        })
      };
    default:
      return state;
  }
};

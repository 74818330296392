import { PayloadAction } from "redux-starter-kit";
import { State, UPDATE_DEBTOR_FAILURE, UPDATE_DEBTOR_REQUEST, UPDATE_DEBTOR_SUCCESS } from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case UPDATE_DEBTOR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_DEBTOR_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case UPDATE_DEBTOR_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

import React from "react";
import colors from "../theme/colors";

interface Props {
  children: React.ReactNode;
  amount?: number;
}

export default ({ amount, children }: Props): JSX.Element => {
  if (amount) {
    if (amount < 0) {
      return <div style={{ color: colors.danger }}>{children}</div>;
    }
    if (amount > 0) {
      return <div style={{ color: colors.green }}>{children}</div>;
    }
  }
  return <div>{children}</div>;
};

import * as Yup from "yup";
import libphonenumber from "google-libphonenumber";
import { isUndefined } from "lodash";
import { searchFactoringDebtors } from "./api/factoring";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

Yup.addMethod(Yup.string, "checkPhone", function(message) {
  return this.test({
    name: "checkPhone",
    exclusive: true,
    message: message || "Must be a phone number",
    test: value => {
      try {
        if (value === "" || isUndefined(value)) {
          return true;
        }
        const regions = ["US", "MX", "CA"];
        const isValid = regions.some(region => {
          const number = phoneUtil.parse(value, region);
          return phoneUtil.isValidNumber(number);
        });
        return isValid;
      } catch (e) {
        return false;
      }
    }
  });
});

Yup.addMethod(Yup.string, "phone", function(message) {
  return this.test({
    name: "phone",
    exclusive: true,
    message: message || "Phone number is not valid",
    test: value => {
      try {
        if (value === "" || isUndefined(value)) {
          return true;
        }
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
        return value.match(phoneRegExp) !== null;
      } catch (e) {
        return false;
      }
    }
  });
});

Yup.addMethod(Yup.string, "swiftNumberIsValid", function (message) {
  return this.test({
    name: "swiftNumberIsValid",
    exclusive: true,
    message: message || "Swift number is not valid",
    test: value => {
      try {
        if (value === "" || isUndefined(value)) {
          return true;
        }
        const swiftCode = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
        return value.match(swiftCode) !== null;
      } catch (e) {
        return false;
      }
    }
  });
});

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg || "${path} must be the same as ${reference}",
    params: {
      reference: ref.path
    },
    test(value) {
      return value === this.resolve(ref);
    }
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);

function routingNumberIsValid(routing) {
  if (!routing) { 
    return false;
  }

  if (routing.length !== 9) {
        return false;
  }
    
    // http://en.wikipedia.org/wiki/Routing_transit_number#MICR_Routing_number_format
  const checksumTotal = (7 * (parseInt(routing.charAt(0),10) + parseInt(routing.charAt(3),10) + parseInt(routing.charAt(6),10))) +
                      (3 * (parseInt(routing.charAt(1),10) + parseInt(routing.charAt(4),10) + parseInt(routing.charAt(7),10))) +
                      (9 * (parseInt(routing.charAt(2),10) + parseInt(routing.charAt(5),10) + parseInt(routing.charAt(8),10)));
  
  const checksumMod = checksumTotal % 10;
  if (checksumMod !== 0) {
      return false;
  } else {
      return true;
  }
}
Yup.addMethod(Yup.string, "routingNumberIsValid", function(message) {
  return this.test({
    name: "routingNumberIsValid",
    exclusive: true,
    message: message || "Routing Number is not valid",
    test: routingNumberIsValid
  });
});
export default Yup;

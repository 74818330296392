import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_LINEITEM_HISTORY_REQUEST,
  FETCH_LINEITEM_HISTORY_SUCCESS,
  FETCH_LINEITEM_HISTORY_FAILURE
} from "../types";

const fetchLineItemHistory = (fundingRequestId, lineItemId) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_LINEITEM_HISTORY_REQUEST)());
    const response = await api.factoring.getLineItemHistory(
      fundingRequestId,
      lineItemId
    );
    const data = normalize(response, [schema.lineItemHistory]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_LINEITEM_HISTORY_SUCCESS)({
        [lineItemId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_LINEITEM_HISTORY_FAILURE)(err));
    throw err;
  }
};

export default fetchLineItemHistory;

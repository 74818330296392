import React from "react";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { StateHandler, withStateHandlers } from "recompose";
import { find, get, merge } from "lodash";
import { Formik, FormikConfig } from "formik";

import { DialogTitle, Dialog, DialogContent, DialogActions } from "../../../../../components/Dialog";
import formatPennies from "../../../../../helpers/format/formatPennies";
import PenniesInput from "../../../../../components/inputs/PenniesInput";
import Table from "../../../../../components/Table";
import formatPercent from "../../../../../helpers/format/formatPercent";
import formatStatus from "../../../../../helpers/format/formatStatus";

interface LineItemCategory {
  applied_against: string;
  applied_by: string;
  apply_discount: boolean;
  created: string;
  deleted_by?: string;
  deleted_reason?: string;
  description: string;
  discount_rate?: string;
  id: string;
  invoice_name: string;
  is_client_invoiced: boolean;
  is_debtor_invoiced: boolean;
  is_default: boolean;
  modified: string;
  name: string;
  should_collate: boolean;
}

interface FundingRequest {
  invoice_number: string;
  outstanding_balance: string;
  id: string;
  receivable_status: string;
  amount: number;
}

interface LineItem {
  id: string;
  amount_requested: string;
  discount_rate: string;
  category_name: string;
  description: string;
  approval_status: string;
}

const formatOutstandingBalance = (
  status: string,
  outstanding: string,
  invoiceAmount: number
): string => {
  const amount = formatPennies(Math.abs(Number(outstanding)));

  if (["over_paid", "non_factored_over_paid"].includes(status)) {
    return `Over Paid: ${amount}`;
  }
  if (["short_paid", "non_factored_short_paid"].includes(status)) {
    return `Short Paid: ${amount}`;
  }
  return `Correct Balance ✅ ${formatPennies(invoiceAmount)}`;
};

interface SelectAdjustingProps {
  setAdjusting: (id: string) => void;
  fundingRequests: FundingRequest[];
}

const SelectAdjusting = ({
  fundingRequests,
  setAdjusting
}: SelectAdjustingProps): JSX.Element => (
  <DialogContent>
    <List>
      {fundingRequests.map(
        (fr): JSX.Element => {
          return (
            <ListItem key={fr.id}>
              <ListItemText
                primary={`Invoice Number ${fr.invoice_number}`}
                secondary={formatOutstandingBalance(
                  fr.receivable_status,
                  fr.outstanding_balance,
                  fr.amount
                )}
              />
              {fr.outstanding_balance !== "0" && (
                <ListItemSecondaryAction>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(): void => setAdjusting(fr.id)}
                  >
                    Adjust
                  </Button>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        }
      )}
    </List>
  </DialogContent>
);

interface FormFields {
  amount: string;
  discountRate: string;
  againstWho: string;
  category: string;
  description: string;
  fundingRequest: string;
}

interface AdjustProps {
  lineItemCategories: LineItemCategory[];
  adjustForm: FormikConfig<FormFields>;
  adjusting: string;
  lineItems: LineItem[];
}

const columns = [
  {
    key: "amount_requested",
    name: "Amount",
    format: formatPennies
  },
  {
    key: "discount_rate",
    name: "Discount Rate",
    format: formatPercent
  },
  {
    key: "category_name",
    name: "Category"
  },
  {
    key: "description",
    name: "Description"
  },
  {
    key: "approval_status",
    name: "Approved",
    format: formatStatus
  }
];

const Adjust = ({
  lineItemCategories,
  adjustForm,
  adjusting,
  lineItems
}: AdjustProps): JSX.Element => (
  <DialogContent>
    <Grid container direction="column" spacing={16}>
      <Formik
        {...merge({ initialValues: { fundingRequest: adjusting } }, adjustForm)}
      >
        {({
          handleSubmit,
          setFieldValue,
          values,
          handleChange,
          handleBlur,
          isSubmitting
        }): JSX.Element => (
          <React.Fragment>
            <Grid item style={{ width: "100%" }}>
              <PenniesInput
                label="Amount"
                fullWidth
                name="amount"
                setFieldValue={setFieldValue}
                value={values.amount}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <TextField
                label="Discount Rate"
                fullWidth
                name="discountRate"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.discountRate}
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Against Who?</InputLabel>
                <Select
                  name="againstWho"
                  value={values.againstWho}
                  onChange={(event): void => {
                    handleChange(event);
                    setFieldValue("category", "");
                  }}
                  onBlur={handleBlur}
                  displayEmpty
                >
                  <MenuItem value="client">Client</MenuItem>
                  <MenuItem value="debtor">Debtor</MenuItem>
                  <MenuItem value="organization">ComFreight</MenuItem>
                  <MenuItem value="proof">Proof</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {values.againstWho !== "" && (
              <Grid item style={{ width: "100%" }}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    inputProps={{
                      id: "category",
                      name: "category"
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.category}
                    displayEmpty
                  >
                    {lineItemCategories
                      .filter(
                        (category): boolean =>
                          category.applied_against === values.againstWho
                      )
                      .map(
                        (category): JSX.Element => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item style={{ width: "100%" }}>
              <TextField
                label="Description"
                fullWidth
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={(): void => handleSubmit()}
                  >
                    Add Line Item
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Formik>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        <Table columns={columns} rows={lineItems} />
      </Grid>
    </Grid>
  </DialogContent>
);

interface Props {
  open: boolean;
  fundingRequests: FundingRequest[];
  lineItemCategories: LineItemCategory[];
  setAdjusting: (id: string) => void;
  adjusting: string;
  handleExit: () => void;
  adjustForm: FormikConfig<FormFields>;
  getLineItemsByFundingRequest: (id: string) => LineItem[];
}

const AdjustmentDialog = ({
  setAdjusting,
  open,
  fundingRequests,
  adjusting,
  lineItemCategories,
  handleExit,
  adjustForm,
  getLineItemsByFundingRequest
}: Props): JSX.Element => (
  <Dialog open={open} maxWidth="md" fullWidth>
    <React.Fragment>
      <DialogTitle onClose={(): void => handleExit()}>
        {adjusting === ""
          ? "Adjustments"
          : formatOutstandingBalance(
              get(
                find(fundingRequests, (fr): boolean => fr.id === adjusting),
                "receivable_status",
                ""
              ),
              get(
                find(fundingRequests, (fr): boolean => fr.id === adjusting),
                "outstanding_balance",
                ""
              ),
              get(
                find(fundingRequests, (fr): boolean => fr.id === adjusting),
                "amount",
                0
              )
            )}
      </DialogTitle>
      {adjusting === "" ? (
        <SelectAdjusting
          fundingRequests={fundingRequests}
          setAdjusting={setAdjusting}
        />
      ) : (
        <Adjust
          adjustForm={adjustForm}
          lineItemCategories={lineItemCategories}
          adjusting={adjusting}
          lineItems={getLineItemsByFundingRequest(adjusting)}
        />
      )}
      <DialogActions>
        {adjusting === "" ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={(): void => handleExit()}
          >
            Done
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            onClick={(): void => setAdjusting("")}
          >
            Next
          </Button>
        )}
      </DialogActions>
    </React.Fragment>
  </Dialog>
);

interface State {
  adjusting: string;
}

interface Updaters {
  [index: string]: StateHandler<State>;
  setAdjusting: (id: string) => Partial<State>;
}

interface Outter {
  open: boolean;
  fundingRequests: FundingRequest[];
  lineItemCategories: LineItemCategory[];
  handleExit: () => void;
  adjustForm: FormikConfig<FormFields>;
  getLineItemsByFundingRequest: (id: string) => LineItem[];
}

export default withStateHandlers<State, Updaters, Outter>(
  {
    adjusting: ""
  },
  {
    setAdjusting: (): ((id: string) => State) => (id): State => ({
      adjusting: id
    })
  }
)(AdjustmentDialog);

/* global google */
import memoize from "lodash/memoize";
import concat from "lodash/concat";

const parsePlace = (() => {
  class Priority {
    constructor(property, p) {
      this.property = property;
      this.p = p;
      this.value = null;
    }

    toString() {
      return this.property;
    }

    valueOf() {
      return this.p;
    }

    hold(value) {
      const p = new Priority(this.property, this.p);
      p.value = value;
      return p;
    }
  }

  const typingMap = {
    street_number: "street_number",
    route: new Priority("street", Infinity),
    premise: new Priority("street", 50),
    bus_station: new Priority("street", 50),
    establishment: new Priority("street", 50),
    point_of_interest: new Priority("street", 50),
    transit_station: new Priority("street", 50),
    political: new Priority("city", 30),
    neighborhood: new Priority("city", 10), // some neighborhoods are considered cities
    postal_code: "zip",
    postal_code_suffix: "zip_suffix",
    locality: new Priority("city", Infinity),
    sublocality_level_1: new Priority("city", 20), // some cities are considered sublocality_level_1
    sublocality: new Priority("city", 30), // some cities are considered sublocality
    administrative_area_level_3: new Priority("city", 5),
    administrative_area_level_2: "county",
    administrative_area_level_1: "state",
    country: "country",
    subpremise: "subpremise",
    intersection: "intersection"
  };

  return place => {
    const parsedPlace = place.address_components.reduce(
      (acc, curr) => {
        const curr_type = curr.types[0];
        let curr_value = curr.short_name;
        let mapped_type = typingMap[curr_type];
        // if we don't have a mapping for this type, check the rest of the `types` array for one we do recognize
        if (!mapped_type) {
          curr.types.some(type => {
            mapped_type = typingMap[type];
            if (mapped_type) {
              return true;
            }
          });
          // should 100% have a type mapping for this now
          if (!mapped_type) {
            throw new Error(
              `Failed to parse google place: Missing type for ${JSON.stringify(
                curr
              )}: ${JSON.stringify(curr.types)}`
            );
          }
        }
        const prev_value = acc[mapped_type];
        if (mapped_type instanceof Priority) {
          curr_value = mapped_type.hold(curr_value);
          if (!prev_value || (prev_value && mapped_type > prev_value)) {
            acc[mapped_type] = curr_value;
          }
        } else {
          acc[mapped_type] = curr_value;
        }
        return acc;
      },
      {
        place_id: place.place_id,
        formatted_address: place.formatted_address
      }
    );
    for (const key in parsedPlace) {
      const value = parsedPlace[key];
      if (value instanceof Priority) {
        parsedPlace[key] = value.value;
      }
    }

    if (!parsedPlace.city) {
      parsedPlace.city = "";
    }

    parsedPlace.address = parsedPlace.street_one = [
      parsedPlace.street_number,
      parsedPlace.street
    ]
      .filter(Boolean)
      .join(" ");

    if (parsedPlace.street_one === "" && parsedPlace.intersection) {
      parsedPlace.street_one = parsedPlace.intersection;
    }

    return parsedPlace;
  };
})();

export default class GoogleApi {
  constructor() {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.services = new google.maps.Geocoder();
    this.OK = window.google.maps.GeocoderStatus.OK;
    this.getPlaceDetails = memoize(this.getPlaceDetails);
    this.getPlacePredictions = memoize(this.getPlacePredictions, concat);
  }

  autoCompletePlacePredictions(request) {
    return new Promise((resolve, reject) => {
      this.autocompleteService.getPlacePredictions(
        request,
        (results, status) => {
          if (status !== this.OK) {
            reject(status);
          }
          resolve(results);
        }
      );
    });
  }

  async getPlacePredictions(value, specificity, sessionToken) {
    const request = {
      input: value,
      types: specificity ? [specificity] : specificity,
      location: new google.maps.LatLng(46.073231, -103.007813),
      radius: 3586000,
      components: "country:US|country:CA|country:MX",
      sessionToken
    };
    try {
      const res = await this.autoCompletePlacePredictions(request);
      return res;
    } catch (e) {
      throw e;
    }
  }

  geocodeByPlaceId(placeId) {
    return new Promise((resolve, reject) => {
      this.services.geocode({ placeId }, (results, status) => {
        if (status !== this.OK) {
          reject(status);
        }
        resolve(results);
      });
    });
  }

  /**
   * @param {string} place_id
   */
  async getPlaceDetails(placeid) {
    try {
      const res = await this.geocodeByPlaceId(placeid);
      if (res.length) return parsePlace(res[0]);
      throw res;
    } catch (e) {
      throw e;
    }
  }
}

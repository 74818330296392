import { assign } from "lodash";

import {
  FACTORINGCLIENT_ATTACHMENT_COLLATED_SUCCESS,
  FACTORINGCLIENT_ATTACHMENT_COLLATED_FETCH,
  FACTORINGCLIENT_ATTACHMENT_COLLATED_ERROR
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FACTORINGCLIENT_ATTACHMENT_COLLATED_FETCH:
      return {
        ...state,
        collatedFiles: assign({}, state.collatedFiles, {
          isLoading: true
        })
      };
    case FACTORINGCLIENT_ATTACHMENT_COLLATED_SUCCESS:
      return {
        ...state,
        collatedFiles: assign({}, state.collatedFiles, {
          isLoading: false,
          ...action.payload
        })
      };
    case FACTORINGCLIENT_ATTACHMENT_COLLATED_ERROR:
      return {
        ...state,
        collatedFiles: assign({}, state.collatedFiles, {
          isLoading: false,
          error: action.payload
        })
      };
    default:
      return state;
  }
};

import { denormalize } from "normalizr";
import { get, memoize } from "lodash";

import { fundingRequest } from "../../../api/schema";

export default id =>
  memoize(state => {
    try {
      const denormalized = denormalize(
        { fundingRequest: id },
        { fundingRequest },
        state.entities
      );
      let userId = get(denormalized.fundingRequest, "factoring.user.id");
      if (!userId) {
        // API returning with and without user object
        userId = get(denormalized.fundingRequest, "factoring.user");
      }
      return userId;
    } catch (err) {
      return "";
    }
  });

import { Button, Grid } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import SpinnerButton from "../../../../components/buttons/SpinnerButton";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../components/Dialog";
import API from "../../../../api";
import { openSnackbar } from "../../../../modules/ui";
import BoldInput from "../../../../components/inputs/BoldInput";

function AddBulkFRNotes({ handleClose, show, selected }) {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values, actions) => {
      try {
        actions.setSubmitting(true);
        await API.factoring.BulkFundingRequestNotes(
          selected,
          values.internalTransactionNotes
        );
        dispatch(openSnackbar("success", "Note has been added."));
      } catch (e) {
        dispatch(openSnackbar("error", "Error while adding note."));
      }
      actions.setSubmitting(false);
    },
    [selected]
  );

  return (
    <Formik
      initialValues={{
        internalTransactionNotes: ""
      }}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors
      }) => (
        <Dialog
          onClose={handleClose}
          open={show}
          fullWidth
          maxWidth="sm"
          scroll="body"
        >
          <DialogTitle onClose={handleClose}>
            New Funding Request Note
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="column"
              spacing={16}
              style={{ minHeight: 150 }}
            >
              <Grid item>
                <BoldInput
                  fullWidth
                  name="internalTransactionNotes"
                  label="Internal Transaction Notes"
                  rows="4"
                  multiline
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.internalTransactionNotes}
                  error={
                    touched.internalTransactionNotes &&
                    !!errors.internalTransactionNotes
                  }
                  helperText={
                    touched.internalTransactionNotes
                      ? errors.internalTransactionNotes
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SpinnerButton
              variant="outlined"
              size="small"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Submit
            </SpinnerButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
export default AddBulkFRNotes;

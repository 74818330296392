import { Action, createAction, createReducer } from "redux-starter-kit";
import { get, merge } from "lodash";
import { HIDE_MODAL, modalReducer, SHOW_MODAL } from "./modal";

export enum TypeKeys {
  SHOW_SNACKBAR = "SHOW_SNACKBAR",
  HIDE_SNACKBAR = "HIDE_SNACKBAR",
  SHOW_DIALOG = "SHOW_DIALOG",
  HIDE_DIALOG = "HIDE_DIALOG",
  OPEN_DRAWER = "OPEN_DRAWER",
  CLOSE_DRAWER = "CLOSE_DRAWER",
  OTHER_ACTION = "__any_other_action_type__"
}

export interface OpenDrawerAction {
  type: TypeKeys.OPEN_DRAWER;
}

export interface CloseDrawerAction {
  type: TypeKeys.CLOSE_DRAWER;
}

export interface OpenSnackbarAction {
  type: TypeKeys.SHOW_SNACKBAR;
  payload: {
    variant: string;
    message: string;
  };
}

export interface CloseSnackbarAction {
  type: TypeKeys.HIDE_SNACKBAR;
}

export interface OpenDialogAction {
  type: TypeKeys.SHOW_DIALOG;
  payload: {
    variant: string;
    title: string;
    message: string;
    data: any;
    action: string;
  };
}

export interface HideDialogAction {
  type: TypeKeys.HIDE_DIALOG;
}

export interface OtherActionAction {
  type: TypeKeys.OTHER_ACTION;
}

export type ActionTypes =
  | OpenDrawerAction
  | CloseDrawerAction
  | CloseSnackbarAction
  | OpenSnackbarAction
  | OpenDialogAction
  | HideDialogAction
  | OtherActionAction;

export const openDrawer = (): OpenDrawerAction =>
  createAction(TypeKeys.OPEN_DRAWER)();

export const closeDrawer = (): CloseDrawerAction =>
  createAction(TypeKeys.CLOSE_DRAWER)();

export const openSnackbar = (
  variant: string,
  message: string
): OpenSnackbarAction =>
  createAction(TypeKeys.SHOW_SNACKBAR)({
    variant,
    message
  });

export const closeSnackbar = (): CloseSnackbarAction =>
  createAction(TypeKeys.HIDE_SNACKBAR)();

export const openUserSetting = (): Action =>
  createAction(TypeKeys.SHOW_DIALOG)({ variant: "user_settings" });

export function openDialog(
  variant: string,
  title?: string,
  message?: string,
  data?: any,
  action?: string
): OpenDialogAction {
  return createAction(TypeKeys.SHOW_DIALOG)({
    variant,
    title,
    message,
    data,
    action
  });
}

export const closeDialog = (): HideDialogAction =>
  createAction(TypeKeys.HIDE_DIALOG)();

interface State {
  drawerOpen: boolean;
  snackbar: {
    variant: string;
    message: string;
    show: boolean;
  };
  modal: {
    variant: null | string;
    message: null | string;
    types: [
      {
        status: "success";
        show: boolean;
      },
      {
        status: "warning";
        show: boolean;
      },
      {
        status: "info";
        show: boolean;
      },
      {
        status: "error";
        show: boolean;
      }
    ];
  };
  dialog: {
    variant: string;
    message: string;
    show: boolean;
    title: string;
    data: any;
    action: string;
  };
}

const initial: State = {
  drawerOpen: false,
  snackbar: {
    variant: "info",
    message: "",
    show: false
  },
  modal: {
    variant: null,
    message: null,
    types: [
      {
        status: "success",
        show: false
      },
      {
        status: "warning",
        show: false
      },
      {
        status: "info",
        show: false
      },
      {
        status: "error",
        show: false
      }
    ]
  },
  dialog: {
    variant: "",
    message: "",
    show: false,
    title: "",
    data: {},
    action: ""
  }
};

const snackbarReducer = (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case TypeKeys.SHOW_SNACKBAR:
      return {
        ...state,
        ...merge({}, state, {
          snackbar: {
            variant: action.payload.variant,
            message: action.payload.message,
            show: true
          }
        })
      };
    case TypeKeys.HIDE_SNACKBAR:
      return {
        ...state,
        ...merge({}, state, {
          snackbar: {
            show: false
          }
        })
      };
    default:
      return {
        ...state
      };
  }
};

const dialogReducer = (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case TypeKeys.SHOW_DIALOG:
      return {
        ...state,
        ...merge({}, state, {
          dialog: {
            variant: action.payload.variant,
            message: action.payload.message,
            show: true,
            title: action.payload.title,
            data: action.payload.data,
            action: action.payload.action
          }
        })
      };
    case TypeKeys.HIDE_DIALOG:
      return {
        ...state,
        dialog: {
          variant: "",
          message: "",
          show: false,
          title: "",
          data: {},
          action: ""
        }
      };
    default:
      return {
        ...state
      };
  }
};

const drawerReducer = (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case TypeKeys.OPEN_DRAWER:
      return {
        ...state,
        drawerOpen: true
      };
    case TypeKeys.CLOSE_DRAWER:
      return {
        ...state,
        drawerOpen: false
      };
    default:
      return {
        ...state
      };
  }
};

const reducer = createReducer(initial, {
  [TypeKeys.SHOW_SNACKBAR]: snackbarReducer,
  [TypeKeys.HIDE_SNACKBAR]: snackbarReducer,
  [TypeKeys.SHOW_DIALOG]: dialogReducer,
  [TypeKeys.HIDE_DIALOG]: dialogReducer,
  [SHOW_MODAL]: modalReducer,
  [HIDE_MODAL]: modalReducer,
  [TypeKeys.OPEN_DRAWER]: drawerReducer,
  [TypeKeys.CLOSE_DRAWER]: drawerReducer
});

export default reducer;

interface Snackbar {
  variant: "success" | "warning" | "error" | "info";
  message: string;
  show: boolean;
}
export const getSnackbar = (state: any): Snackbar =>
  get(state, ["ui", "snackbar"], {});
export const getDialog = (state: any): any => get(state, ["ui", "dialog"], {});
export const isDrawerOpen = (state: any): boolean =>
  get(state, "ui.drawerOpen", false);

import { assign } from "lodash";
import {
  FETCH_RESERVE_REQUEST,
  FETCH_RESERVE_SUCCESS,
  FETCH_RESERVE_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_RESERVE_REQUEST:
      return {
        ...state,
        loadingReserve: true
      };
    case FETCH_RESERVE_SUCCESS:
      return {
        ...state,
        loadingReserve: false,
        reserves: assign({}, state.reserves, action.payload)
      };
    case FETCH_RESERVE_FAILURE:
      return {
        ...state,
        loadingReserve: false
      };
    default:
      return state;
  }
};

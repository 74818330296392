import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_EMAIL_TEMPLATES_FAILURE,
  FETCH_EMAIL_TEMPLATES_REQUEST,
  FETCH_EMAIL_TEMPLATES_SUCCESS
} from "../types";

const fetchEmailTemplates = fundingRequestId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_EMAIL_TEMPLATES_REQUEST)());
    const response = await api.factoring.getEmailTemplates(fundingRequestId);
    const data = normalize(response, [schema.emailTemplates]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_EMAIL_TEMPLATES_SUCCESS)({
        [fundingRequestId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_EMAIL_TEMPLATES_FAILURE)(err));
    throw err;
  }
};

export default fetchEmailTemplates;

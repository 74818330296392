import { filter, get, isUndefined, memoize } from "lodash";
import { denormalize } from "normalizr";
import { notes } from "../../../api/schema";

export default debtorId =>
  memoize(state => {
    try {
      const debtorNotes = get(state, ["debtor", "debtorNotes", debtorId], []);

      const denormalized = denormalize(
        { debtorNotes },
        { debtorNotes: [notes] },
        state.entities
      );

      return filter(denormalized.debtorNotes, note => !isUndefined(note));
    } catch (err) {
      return [];
    }
  });

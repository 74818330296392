import { merge } from "lodash";
import {
  FETCH_FACTORINGPROFILE_REQUEST,
  FETCH_FACTORINGPROFILE_SUCCESS,
  FETCH_FACTORINGPROFILE_FAILURE
} from "../types";

const fetchReceivables = (state, { type, payload }) => {
  switch (type) {
    case FETCH_FACTORINGPROFILE_REQUEST:
      return {
        ...state,
        loadingFactoringProfile: true
      };
    case FETCH_FACTORINGPROFILE_SUCCESS:
      return {
        ...state,
        factoringProfile: merge({}, state.factoringProfile, payload),
        loadingFactoringProfile: false
      };
    case FETCH_FACTORINGPROFILE_FAILURE:
      return {
        ...state,
        loadingFactoringProfile: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchReceivables;

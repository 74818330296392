import { denormalize } from "normalizr";
import { memoize } from "lodash";

import { fundingRequest } from "../../../api/schema";

export default id =>
  memoize(state => {
    try {
      const denormalized = denormalize(
        { fundingRequest: id },
        { fundingRequest },
        state.entities
      );
      return denormalized.fundingRequest;
    } catch (err) {
      return {};
    }
  });

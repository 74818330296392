import { PayloadAction } from "redux-starter-kit";
import { FETCH_DEBTOR_FAILURE, FETCH_DEBTOR_REQUEST, FETCH_DEBTOR_SUCCESS, State } from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case FETCH_DEBTOR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_DEBTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.id
      };
    case FETCH_DEBTOR_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  UPDATE_DEBTOR_FAILURE,
  UPDATE_DEBTOR_REQUEST,
  UPDATE_DEBTOR_SUCCESS
} from "../types";

const updateDebtor = (id, debtor) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPDATE_DEBTOR_REQUEST)({}));
    const response = await api.debtors.updateDebtor(id, debtor);
    const data = normalize(response, schema.debtorProfile);
    dispatch(addEntities(data.entities));
    dispatch(createAction(UPDATE_DEBTOR_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(UPDATE_DEBTOR_FAILURE)(err));
    throw err;
  }
};

export default updateDebtor;

import React from "react";
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
  faGripLines
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import colors from "../../../../theme/colors";

export default ({ thisPeriod, difference }) => {
  let color = colors.link;
  let icon = faGripLines;

  if (difference > 0) {
    color = colors.green;
    icon = faLongArrowAltUp;
  }

  if (difference < 0) {
    color = colors.danger;
    icon = faLongArrowAltDown;
  }

  return (
    <div style={{ color }}>
      {thisPeriod} ({difference}) <FontAwesomeIcon icon={icon} />
    </div>
  );
};

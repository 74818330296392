import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import Yup from "../../../../../../../YupValidation";
import {
    DialogTitle,
    Dialog,
    DialogContent,
    DialogActions
} from "../../../../../../../components/Dialog";
import CustomTextField from "../../../../../../../components/inputs/CustomTextField";

const validatorSchema = () => {
    const schema = {
        swiftCode: Yup.string().required("Please fill out this field").swiftNumberIsValid(),
    }
    return Yup.object().shape(schema);
}

const SWIFT = ({ open, handleExit, createAccount, selectNone }) => (
    <Dialog open={open} maxWidth="sm" fullWidth>
        <Formik
            enableReinitialize
            initialValues={{
                swiftCode: undefined,
            }}
            validationSchema={validatorSchema}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    await createAccount(values.swiftCode);
                    selectNone();
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({
                handleSubmit,
                values,
                errors,
                handleChange,
                handleBlur,
                isSubmitting
            }) => (
                <React.Fragment>
                    <DialogTitle onClose={handleExit} />
                    <DialogContent>
                        <Grid container direction="column" spacing={16}>
                            <Grid item>
                                <CustomTextField
                                    fullWidth
                                    name="swiftCode"
                                    label="Swift Number"
                                    value={values.swiftCode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.swiftCode}
                                    error={!!errors.swiftCode}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => selectNone()}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={() => handleSubmit()}
                        >
                            Add Swift
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )}
        </Formik>
    </Dialog>
);

export default SWIFT;

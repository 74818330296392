const tabs = [
  {
    label: "Groups",
    route: "/admin/groups",
    value: "groups"
  },
  {
    label: "Invited Carriers",
    route: "/admin/groups/invited_carriers",
    value: "invited_carriers"
  },
  {
    label: "Factoring Companies",
    route: "/admin/companies/approved",
    value: "companies"
  },
  {
    label: "Co-Brokering",
    route: "/admin/groups/co_brokering",
    value: "co_brokering"
  }
];

export default tabs;

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import logo from "./HPLogo.png";

const NotFound = (): JSX.Element => (
  <Grid
    container
    style={{
      marginTop: 60
    }}
    alignContent="center"
    alignItems="center"
    direction="column"
  >
    <div
      style={{
        flex: 1,
        display: "flex",
        alignContent: "center",
        alignItems: "center"
      }}
    >
      <Typography
        component="h5"
        color="primary"
        variant="display3"
        align="center"
      >
        4
      </Typography>
      <Typography
        component="h5"
        color="secondary"
        variant="display3"
        align="center"
      >
        0
      </Typography>
      <Typography
        component="h5"
        color="primary"
        variant="display3"
        align="center"
      >
        4
      </Typography>
      <Typography
        style={{
          marginLeft: 10
        }}
        component="h5"
        color="secondary"
        variant="display3"
        align="center"
      >
        NOT
      </Typography>
      <Typography
        component="h5"
        style={{
          marginLeft: 10
        }}
        color="primary"
        variant="display3"
        align="center"
      >
        FOUND
      </Typography>
    </div>
    <img src={logo} width={500} height={500} alt="" />
  </Grid>
);

export default NotFound;

import React from "react";
import { List } from "@material-ui/core";
import filter from "lodash/filter";
import get from "lodash/get";

import Can from "../../../../../../components/Can";
import onlyNumbers from "../../../../../../helpers/format/onlyNumbers";
import businessTypes from "../../../../../../helpers/businessType";
import states from "../../../../../../helpers/states";
import Card from "../../../../../../components/Card";
import BoldInput, {
  Select
} from "../../../../../../components/inputs/BoldInput";
import ListItem from "../../../../../../components/ListItem";
import Settings from "../Settings";
import BrokerLogo from "../inputs/BrokerLogo";
import ProfileSection from "../../../../../../components/ProfileSection";

export default ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  files,
  categories,
  handleDelete,
  disableAll
}) => (
  <Card style={{ height: "100%" }}>
    <List dense>
      <ListItem>
        <ProfileSection>Business Info:</ProfileSection>
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessLegalName"
          label="Legal Business Name:"
          value={values.businessLegalName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessLegalName}
          helperText={errors.businessLegalName}
          disabled={disableAll}
        />
      </ListItem>
      <Can
        perform="admin-factorclient-profile-phonenumber:view"
        yes={() => (
          <ListItem>
            <BoldInput
              fullWidth
              name="businessPhone"
              label="Phone:"
              value={values.businessPhone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.businessPhone}
              helperText={errors.businessPhone}
              disabled={disableAll}
            />
          </ListItem>
        )}
      />
      <Can
        perform="admin-factorclient-profile-address:view"
        yes={() => (
          <React.Fragment>
            <ListItem>
              <BoldInput
                fullWidth
                name="businessAddress"
                label="Address:"
                value={values.businessAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.businessAddress}
                helperText={errors.businessAddress}
                disabled={disableAll}
              />
            </ListItem>
            <ListItem>
              <BoldInput
                fullWidth
                name="businessCity"
                label="City:"
                value={values.businessCity}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.businessCity}
                helperText={errors.businessCity}
                disabled={disableAll}
              />
            </ListItem>
            <ListItem>
              <BoldInput
                fullWidth
                name="businessState"
                label="State:"
                value={values.businessState}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.businessState}
                helperText={errors.businessState}
                disabled={disableAll}
              />
            </ListItem>
            <ListItem>
              <BoldInput
                fullWidth
                name="businessZip"
                label="Zip:"
                value={values.businessZip}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.businessZip}
                helperText={errors.businessZip}
                disabled={disableAll}
              />
            </ListItem>
          </React.Fragment>
        )}
      />
      <ListItem>
        <Select
          value={values.businessType}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          name="businessType"
          label="Business Type:"
          selections={businessTypes.map(value => ({
            text: value,
            value
          }))}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessMc"
          label="MC:"
          value={onlyNumbers(values.businessMc)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessMc}
          helperText={errors.businessMc}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessDot"
          label="DOT:"
          value={onlyNumbers(values.businessDot)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessDot}
          helperText={errors.businessDot}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="autoFRCreateEmail"
          label="Auto-FR Create Email:"
          value={values.autoFRCreateEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.autoFRCreateEmail}
          helperText={errors.autoFRCreateEmail}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ccBrokerRateConfirmationEmail"
          label="CC Rate Confirmation Email:"
          value={values.ccBrokerRateConfirmationEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ccBrokerRateConfirmationEmail}
          helperText={errors.ccBrokerRateConfirmationEmail}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessNumberOfTrucks"
          label="# of Trucks:"
          value={values.businessNumberOfTrucks}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessNumberOfTrucks}
          helperText={errors.businessNumberOfTrucks}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessTypeOfTrailers"
          label="Type of Trailers:"
          value={values.businessTypeOfTrailers}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessTypeOfTrailers}
          helperText={errors.businessTypeOfTrailers}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessNumberOfOwnerOperators"
          label="# of Owner Operators:"
          value={values.businessNumberOfOwnerOperators}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessNumberOfOwnerOperators}
          helperText={errors.businessNumberOfOwnerOperators}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <Select
          value={values.businessStateIncorporated}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          name="businessStateIncorporated"
          label="State Incorporated:"
          selections={states.map(({ key, value }) => ({
            text: value,
            value: key
          }))}
          disabled={disableAll}
        />
      </ListItem>
      <Can
        perform="taxid:view"
        yes={() => (
          <ListItem>
            <BoldInput
              fullWidth
              name="businessEin"
              label="EIN:"
              value={values.businessEin}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.businessEin}
              helperText={errors.businessEin}
              disabled={disableAll}
            />
          </ListItem>
        )}
      />
      <ListItem>
        <BoldInput
          fullWidth
          name="businessCurrentFactoringCompany"
          label="Current Factoring Company:"
          value={values.businessCurrentFactoringCompany}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessCurrentFactoringCompany}
          helperText={errors.businessCurrentFactoringCompany}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Remittance Email"
          name="remittanceEmail"
          value={values.remittanceEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.remittanceEmail}
          helperText={errors.remittanceEmail}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Debtor Invoice CC Email"
          name="debtorInvoiceCCEmail"
          value={values.debtorInvoiceCCEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.debtorInvoiceCCEmail}
          helperText={errors.debtorInvoiceCCEmail}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Pre & Post Doc Issue Email"
          name="documentIssueEmail"
          value={values.documentIssueEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.documentIssueEmail}
          helperText={errors.documentIssueEmail}
          disabled={disableAll}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Pre & Post Doc Issue Mobile"
          name="documentIssuePhone"
          value={values.documentIssuePhone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.documentIssuePhone}
          helperText={errors.documentIssuePhone}
          disabled={disableAll}
        />
      </ListItem>
    </List>
    <Settings
      style={{ height: "100%" }}
      values={values}
      errors={errors}
      setFieldValue={setFieldValue}
      onBlur={handleBlur}
      disabled={disableAll}
    />
    <BrokerLogo
      setFieldTouched={setFieldTouched}
      values={values}
      setFieldValue={setFieldValue}
      files={filter(files, ["category", "logo"])}
      index={get(values, "attachments", []).length}
      handleDelete={handleDelete}
    />
  </Card>
);

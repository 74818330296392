interface Tab {
  label: string;
  route: string;
  value: string;
  permission?: string;
}

const tabs = (id: string, hasWallet: boolean): Tab[] => {
  const defaultTabs = [
    {
      label: "Profile",
      route: `/admin/factorclients/${id}/profile`,
      value: "profile",
      permission: "admin-factorclient-profile:view"
    },
    {
      label: "Funding Requests",
      route: `/admin/factorclients/${id}/funding_requests`,
      value: "funding_requests",
      permission: "admin-factorclient-funding_requests:view"
    },
    {
      label: "Receivables",
      route: `/admin/factorclients/${id}/receivables`,
      value: "receivables",
      permission: "admin-factorclient-funding_requests:view"
    },
    {
      label: "Connected Debtors",
      route: `/admin/factorclients/${id}/connected_debtors`,
      value: "connected_debtors",
      permission: "admin-factorclient-connected_debtors:view"
    },
    {
      label: "Connected Carriers",
      route: `/admin/factorclients/${id}/connected_carriers`,
      value: "connected_carriers",
      permission: "admin-factorclient-connected_carriers:view"
    },
    {
      label: "Connect Debtor",
      route: `/admin/factorclients/${id}/connect_debtor`,
      value: "connect_debtor",
      permission: "admin-factorclient-connect_debtor:view"
    },
  ];

  if (hasWallet) {
    defaultTabs.push({
      label: "Wallet",
      route: `/admin/factorclients/${id}/wallet`,
      value: "wallet",
      permission: "admin-factorclient-wallet:view"
    });
  }

  return defaultTabs;
};

export default tabs;

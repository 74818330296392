import { denormalize } from "normalizr";
import { factoringProfileHistory } from "../../../api/schema";

const getFactoringProfileHistory = state => id => {
  try {
    const denormalized = denormalize(
      { id },
      { id: factoringProfileHistory },
      state.entities
    );

    return denormalized.id.history;
  } catch (err) {
    return [];
  }
};

export default getFactoringProfileHistory;

import { findIndex } from "lodash";

const nextStep = state => {
  const steps = state.steps.map(step => step.value);
  const currentStep = state.step;

  const next = steps[findIndex(steps, step => step === currentStep) + 1];

  return {
    ...state,
    step: next
  };
};

export default nextStep;

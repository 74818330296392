import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
    CREATE_SWIFT_ACCOUNT_FAILURE,
    CREATE_SWIFT_ACCOUNT_REQUEST,
    CREATE_SWIFT_ACCOUNT_SUCCESS
} from "../types";

const createSwiftAccount = (
    swiftCode,
    user
) => async (dispatch, getState, { api, schema }) => {
    try {
        dispatch(createAction(CREATE_SWIFT_ACCOUNT_REQUEST)());
        const account = await api.vgs.createAccountBySwift(
            swiftCode,
            user
        );

        const data = normalize(account, schema.accounts);
        dispatch(addEntities(data.entities));
        dispatch(createAction(CREATE_SWIFT_ACCOUNT_SUCCESS)(account));
        return account;
    } catch (err) {
        dispatch(createAction(CREATE_SWIFT_ACCOUNT_FAILURE)(err));
        throw err;
    }
};

export default createSwiftAccount;

import { Grid } from "@material-ui/core";
import React from "react";

const GridColumn = ({ children, numbers, ...props }) => (
  <Grid direction="column" item xs={numbers} {...props}>
    {children}
  </Grid>
);

export default GridColumn;

import React, { useCallback } from "react";
import { createStyles, Fab, withStyles } from "@material-ui/core";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import colors from "../../../../../../theme/colors";
import {
  closeDialog,
  getDialog,
  openDialog
} from "../../../../../../modules/ui";
import sendNoaBlast from "../../../../../../modules/factoringClient/actions/sendNoaBlast";
import ConfirmDialog from "../../../../../../components/dialogs/ConfirmDialog";

const styles = () =>
  createStyles({
    button: {
      boxShadow:
        "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      background: colors.info,
      color: colors.white,
      textTransform: "none"
    },
    icon: {
      marginRight: 3
    }
  });

const SendNoaBlastButton = ({ classes }) => {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);

  const sendLink = async () => {
    dispatch(sendNoaBlast());
  };

  const handleExit = useCallback(async () => {
    dispatch(closeDialog());
  }, []);

  return (
    <>
      <Fab
        variant="extended"
        size="medium"
        className={classes.button}
        onClick={() =>
          dispatch(
            openDialog(
              undefined,
              "Confirm Send NOA Blast",
              "Are you sure you want to send the NOA Blast?",
              {},
              "confirm_send_noa_blast"
            )
          )
        }
      >
        <FontAwesomeIcon
          icon={faFile}
          size="sm"
          color={colors.white}
          className={classes.icon}
        />
        Send NOA Blast
      </Fab>
      {dialog.action === "confirm_send_noa_blast" && (
        <ConfirmDialog
          open={dialog.show}
          message={dialog.message}
          handleConfirm={sendLink}
          handleExit={handleExit}
        />
      )}
    </>
  );
};
export default withStyles(styles)(SendNoaBlastButton);

import { get } from "lodash";
import deleteUser from "./deleteUser";
import getUserById from "./getUserById";
import { closeDialog } from "../../ui";

const handleConfirmDeleteUser = () => async (dispatch, getState, { api }) => {
  const state = getState();
  const dialog = state.ui.dialog;
  await dispatch(deleteUser(get(dialog, ["data", "userId"])));
  dispatch(closeDialog());
};

export default handleConfirmDeleteUser;

import axios, { AxiosRequestConfig } from "axios";

const berbixRequestToken = async (): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `berbix/start/`,
    method: "POST"
  };
  const response = await axios(options);
  return response.data;
};

const berbixSaveTransaction = async (
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `berbix/save/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

export default {
  berbixRequestToken,
  berbixSaveTransaction
};

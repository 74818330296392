import React from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import { Formik } from "formik";

import Attachment from "../../../components/Attachment";
import ContinueButton from "../../../components/buttons/ContinueButton";
import InvitedValidationSchema from "./form/formValidation";
import CarrierInfo from "./form/CarrierInfo";

const getBankInfoAccount = accounts => {
  const account = _.find(accounts, object => object.default === true);
  if (!account) {
    return accounts[0];
  }
  return account;
};

const Summary = ({
  usedPlaid,
  carrierForRef = {},
  factoringProfileId,
  factoringProfileData: {
    company_profile = {},
    owner_profile = {},
    ...factoringProfileData
  },
  plaidData: { accounts = [], ...plaidData },
  defaultBankAccount = getBankInfoAccount(accounts),
  formInitialValues = {
    referrer: "",
    mc: company_profile.mc,
    dot: company_profile.dot,
    tax_id: company_profile.tax_id,
    owner_first_name: owner_profile.name_first,
    owner_last_name: owner_profile.name_last,
    owner_phone_number: owner_profile.phone_number_cell,
    company_name: company_profile.name,
    email: owner_profile.email,
    bank_info: usedPlaid
      ? `${defaultBankAccount.type}******${defaultBankAccount.account_number_redacted}`
      : null,
  },
  updateFactoringProfile,
  updateFactoringLoading,
  userType,
  attachments,
  updateUser,
  userId
}) => (
  <Grid
    container
    direction="column"
    justify="space-between"
    alignItems="stretch"
    spacing={32}
  >
    <Formik
      innerRef={ref => {
        carrierForRef = ref;
      }}
      validationSchema={InvitedValidationSchema(true, true)}
      initialValues={formInitialValues}
      render={props => <CarrierInfo isSummary {...props} userType={userType} />}
      onSubmit={async (values, actions) => {
        const user = {
          factoring_id: factoringProfileId,
          username: values.email,
          [userType]: {
            contact_name: `${values.owner_first_name} ${values.owner_last_name}`,
            address: owner_profile.address
          },
          owner_profile: {
            name_first: values.owner_first_name,
            name_last: values.owner_last_name,
            email: owner_profile.email,
            phone_number_cell: values.owner_phone_number,
            address: owner_profile.address
          },
          company_profile: {
            name: values.company_name,
            address: undefined,
            phone: values.owner_phone_number,
            number_of_trucks: undefined,
            state_incorporated: undefined,
            current_factoring_company: undefined,
            type: undefined,
            address: owner_profile.address,
          },
          has_contract_authority_status: undefined,
          has_common_authority_status: undefined,
          has_broker_authority_status: undefined,
          is_allowed_to_operate: undefined,
          type: userType,
          ...values
        };
        user[userType] = {
          ...user[userType],
          mc: values.mc,
          dot: values.dot,
          tax_id: values.tax_id
        };
        user.company_profile = {
          ...user.company_profile,
          mc: values.mc,
          dot: values.dot,
          tax_id: values.tax_id
        };
        await updateFactoringProfile(user);
        await updateUser({
          userId,
          email: values.email,
          referrer: values.referrer
        });
      }}
    />
    {attachments.length
      ? attachments.map(({ file_id: id, path }) => (
          <Attachment id={id} label={path} />
        ))
      : null}
    <Grid container direction="row-reverse" alignItems="flex-start">
      <ContinueButton
        disabled={updateFactoringLoading}
        onClick={() => carrierForRef.submitForm()}
      />
    </Grid>
  </Grid>
);

export default Summary;

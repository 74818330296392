import { assign } from "lodash";
import {
  FETCH_FR_EMAIL_TEMPLATES_FAILURE,
  FETCH_FR_EMAIL_TEMPLATES_REQUEST,
  FETCH_FR_EMAIL_TEMPLATES_SUCCESS
} from "../types";

const fetchFREmailTemplates = (state, { type, payload }) => {
  switch (type) {
    case FETCH_FR_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        loadingFREmailTemplates: true
      };
    case FETCH_FR_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: assign({}, state.emailTemplates, payload),
        loadingFREmailTemplates: false
      };
    case FETCH_FR_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        loadingFREmailTemplates: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchFREmailTemplates;

import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getFactorClient, fetchFactoringProfile, getUserId } from "../../../../modules/factoringClient"
import getTabs from '../tabs';
import NavigationTabs from '../../../../components/NavigationTabs';
import BrokerWallet from './BrokerWallet';
import StripeWallet from './StripeWallet';
import Can from '../../../../components/Can';
import InternalLayout from '../../../../components/layouts/InternalLayout';
import WalletHistory from './components/WalletHistory';

export default function (props) {
    const dispatch = useDispatch();
    const {
        allow_self_finance_fr: allowSelFFinanceFr,
        stripe_enabled: stripeEnabled,
        ...factoringClient
    } = useSelector(getFactorClient);
    const userId = useSelector(getUserId);
    const id = get(props, ["match", "params", "id"], "");
    const tabs = getTabs(id, allowSelFFinanceFr);

    useEffect(() => {
        async function f() {
            await dispatch(fetchFactoringProfile(id));
        }
        if (isEmpty(factoringClient)) {
            f();
        }
    }, []);

    return !isEmpty(userId) ? <Can
        perform="admin-factorclients:view"
        yes={() => (
            <InternalLayout
                title="Wallet"
            >
                <Grid container direction="column" spacing={24}>
                    <Grid item style={{ width: '100%' }}>
                        <NavigationTabs
                            tabs={tabs}
                            value={6}
                        />
                    </Grid>
                    <Grid item>
                        {stripeEnabled ?
                            <StripeWallet factoringId={id} userId={userId} />
                            : <BrokerWallet factoringId={id} userId={userId} />
                        }
                    </Grid>
                    <Grid item>
                        <WalletHistory factoringId={id} userId={userId} isStripeUser={stripeEnabled} />
                    </Grid>
                </Grid>
            </InternalLayout>
        )}
    /> : <div />;
}
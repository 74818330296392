export default (pennies: number | undefined): string => {
  if (pennies) {
    if (typeof pennies !== "number") {
      return String(pennies);
    }
    try {
      return `$${(pennies / 100)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    } catch (e) {
      return String(pennies);
    }
  }
  return "$0.00";
};

import { actions } from "@storybook/addon-actions";
import { assign } from "lodash";
import {
    FETCH_QUERY_OBLIGATIONS_FAILURE,
    FETCH_QUERY_OBLIGATIONS_REQUEST,
    FETCH_QUERY_OBLIGATIONS_SUCCESS
} from "../types";

export default (
    state = {
        contract_id: undefined,
        loading: false,
        ids: [],
        count: 0,
        offset: 0,
        limit: 20,
        status: undefined,
        ordering: undefined,
        rowsPerPageOptions: [5, 10, 20, 50, 100, 200]
    },
    action
) => {
    switch (action.type) {
        case FETCH_QUERY_OBLIGATIONS_REQUEST:
            return assign({}, state, {
                ids: [],
                contract_id: action.payload.contractId,
                loading: true,
                limit: action.payload.limit,
                offset: action.payload.offset,
                ordering: action.payload.ordering,
                filters: action.payload.filters
            });
        case FETCH_QUERY_OBLIGATIONS_SUCCESS:
            return assign({}, state, {
                loading: false,
                ids: action.payload.items,
                count: action.payload.count
            });
        case FETCH_QUERY_OBLIGATIONS_FAILURE:
            if (sameRequest(state, action.payload)) {
                return assign({}, state, {
                    loading: false
                });
            }
            return state;
        default:
            return state;
    }
};

import { createAction } from "redux-starter-kit";
import { merge, findIndex, get, cloneDeep } from "lodash";

export const SHOW_MODAL = "UI/SHOW_MODAL";
export const HIDE_MODAL = "UI/HIDE_MODAL";

export const openModal = (variant, message) => (dispatch, getState) => {
  const types = updateTypes(getState(), variant, true);
  dispatch(
    createAction(SHOW_MODAL)({
      variant,
      message,
      types
    })
  );
};

const updateTypes = (state, status, show) => {
  const types = cloneDeep(state.ui.modal.types);
  const index = findIndex(types, o => o.status === status);
  types[index] = {
    status,
    show
  };
  return types;
};

export const modalReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_MODAL:
      return {
        ...state,
        ...merge({}, state, {
          modal: {
            variant: payload.variant,
            message: payload.message,
            types: payload.types
          }
        })
      };
    case HIDE_MODAL:
      return {
        ...state,
        ...merge({}, state, {
          modal: {
            variant: payload.variant,
            types: payload.types
          }
        })
      };
    default:
      return {
        ...state
      };
  }
};
export const closeModal = variant => (dispatch, getState) => {
  const types = updateTypes(getState(), variant, false);
  dispatch(
    createAction(HIDE_MODAL)({
      variant,
      types
    })
  );
};
export const getModal = state => get(state, "ui.modal", {});

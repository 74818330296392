import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";
import axios from "axios";

import { addEntities } from "../../entities";
import {
  FETCH_MEMBERS_FAILURE,
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS
} from "../types";

const handleResponse = (
  response,
  dispatch,
  groupMember,
  limit,
  ordering,
  filters
) => {
  const data = normalize(response.results, [groupMember]);
  dispatch(addEntities(data.entities));
  dispatch(
    createAction(FETCH_MEMBERS_SUCCESS)({
      items: data.result,
      count: response.count,
      next: response.next,
      previous: response.previous,
      ordering,
      limit,
      filters
    })
  );
  return response;
};

const debounced = debounce(
  async (dispatch, schema, api, id, limit, ordering, filters) => {
    dispatch(
      createAction(FETCH_MEMBERS_REQUEST)({
        id,
        limit,
        filters,
        ordering
      })
    );
    const response = await api.groups.queryAdminGroupMembers(
      id,
      limit,
      ordering,
      filters
    );
    handleResponse(
      response,
      dispatch,
      schema.groupMember,
      limit,
      ordering,
      filters
    );
  },
  1000,
  { trailing: true }
);

export const next = (id, link, limit, ordering, filters) => async (
  dispatch,
  getState,
  { schema }
) => {
  try {
    dispatch(
      createAction(FETCH_MEMBERS_REQUEST)({
        id,
        limit,
        ordering,
        filters
      })
    );

    const response = await axios.get(link);
    handleResponse(
      response.data,
      dispatch,
      schema.groupMember,
      limit,
      ordering,
      filters
    );
  } catch (err) {
    dispatch(createAction(FETCH_MEMBERS_FAILURE)(err));
    throw err;
  }
};

const fetchMembers = (id, limit, ordering, filters = {}) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    await debounced(dispatch, schema, api, id, limit, ordering, filters);
  } catch (err) {
    dispatch(createAction(FETCH_MEMBERS_FAILURE)(err));
    throw err;
  }
};

export default fetchMembers;

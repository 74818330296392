import { assign, isEqual } from "lodash";
import { CREATE_PAYMENT_FORWARD_SUCCESS } from "../types";

export default (state, action) => {
  switch (action.type) {
    case CREATE_PAYMENT_FORWARD_SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          ids: [action.payload.items, ...state.payments.ids]
        }
      };
    default:
      return state;
  }
};

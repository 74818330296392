import { PayloadAction } from "redux-starter-kit";
import { SET_AUTH_EMAIL, State } from "../types";

const setEmail = (state: State, { type, payload }: PayloadAction): State => {
  switch (type) {
    case SET_AUTH_EMAIL:
      return {
        ...state,
        email: payload.email
      };
    default:
      return {
        ...state
      };
  }
};

export default setEmail;

import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withProps } from 'recompose';
import { findIndex, get, head, isEmpty, reduce } from 'lodash';
import { Link } from 'react-router-dom';

import { DateTime } from 'luxon';
import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar,
} from '../../../../modules/ui';
import withSelect from '../../../../components/withSelect';
import {
  deleteDebtorRelation,
  fetchDebtorRelationHistory,
  fetchFactoringProfile,
  getConnectedDebtors,
  getConnectedDebtorsCount,
  getConnectedDebtorsFilters,
  getConnectedDebtorsIsLoading,
  getConnectedDebtorsPage,
  getConnectedDebtorsRowsPerPage,
  getConnectedDebtorsRowsPerPageOptions,
  getConnectedDebtorsSortBy,
  getConnectedDebtorsSortDirection,
  getDebtorRelationHistory,
  getFactorClient,
  handleConnectedDebtorsChangePage,
  handleConnectedDebtorsChangeRowsPerPage,
  handleConnectedDebtorsSearchFilter,
  handleConnectedDebtorsSort,
  queryConnectedDebtors,
  updateFactoringDebtor,
} from '../../../../modules/factoringClient';
import {
  closeSendNOA,
  fetchAttachments,
  getAttachments,
  getSendNOA,
  sendNOA,
  updateNOADates,
} from '../../../../modules/factoring';
import withTabs from '../../../../components/withTabs';
import formatDate from '../../../../helpers/format/formatDate';
import splitAndCase from '../../../../helpers/format/splitAndCase';
import NOASummary from '../../../../components/NOASummary';
import MoreLinks from './components/MoreLinks';
import PureConnectedDebtors from './ConnectedDebtors';

import columns from './columns';
import tabs from '../tabs';
import CustomTextField from '../../../../components/inputs/CustomTextField';
import PenniesInput from '../../../../components/inputs/PenniesInput';
import { getUserType } from '../../../../modules/auth';
import Can from '../../../../components/Can';
import NOADropDown from '../../../../components/NOADropDown';
import { Select } from "../../../../components/inputs/BoldInput";
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import UploadFile from '../../Payment/ConnectedBrokers/components/icons/UploadFile';

export const ConnectedDebtors = PureConnectedDebtors;

const connectedDebtorForm =
  (factoringClientId, updateFactoringDebtor, openSnackbar, refresh) =>
  (debtor) => ({
    enableReinitialize: true,
    initialValues: {
      debtorId: debtor.id,
      minimum_fee: debtor.minimumFee / 100,
      discount_rate: debtor.discountRate,
      relationship_id: debtor.relationship_id,
      fuel_advance: debtor.fuel_advance,
      fuel_advance_fee: debtor.fuel_advance_fee,
      fuel_advance_rate: debtor.fuel_advance_rate,
      co_broker_allowed: debtor.co_broker_allowed
    },
    onSubmit: async (
      { id, minimum_fee: minimumFee, discount_rate: discountRate, co_broker_allowed: coBrokerAllowed, ...values },
      { setSubmitting }
    ) => {
      try {
        await updateFactoringDebtor(id, factoringClientId, {
          co_broker_allowed: coBrokerAllowed,
          minimum_fee: Math.round(minimumFee * 100),
          discount_rate: discountRate,
          ...reduce(values, (results, value, key) => {
            if (/noa/.test(key)) {
              results[key] = value;
            }
            return results;
          }, {})
        });
        await refresh();
        openSnackbar('success', 'Updated Factoring Debtor!');
      } catch (err) {
        openSnackbar('error', head(err));
      } finally {
        setSubmitting(false);
      }
    },
  });

const fuelAdvanceForm = (
  factoringId,
  debtorId,
  fuelAdvance,
  rate,
  fee,
  updateFactoringDebtor,
  openSnackbar,
  closeDialog,
  refresh
) => ({
  enableReinitialize: true,
  initialValues: {
    fuelAdvance,
    fee: fee / 100,
    rate,
  },
  onSubmit: async (values, { setSubmitting }) => {
    try {
      await updateFactoringDebtor(debtorId, factoringId, {
        fuel_advance: values.fuelAdvance,
        fuel_advance_rate: values.rate,
        fuel_advance_fee: Math.round(values.fee * 100),

      });
      setSubmitting(false);
      openSnackbar('success', 'Updated Fuel Advance Settings!');
    } catch (err) {
      openSnackbar('error', err);
    } finally {
      refresh();
      closeDialog();
    }
  },
});

export const placeNOAForm = (
  openSnackbar,
  attachments,
  sendNOA,
  debtorId,
  factoringId
) => ({
  enableReinitialize: true,
  initialValues: {
    noaToSend: get(head(attachments), 'filename', ''),
    attachments,
  },
  onSubmit: async (values, { setSubmitting }) => {
    try {
      await sendNOA(debtorId, factoringId, undefined, values.noaToSend);
      openSnackbar('success', 'NOA Sent!');
      closeSendNOA();
    } catch (err) {
      openSnackbar('error', head(err));
    } finally {
      setSubmitting(false);
    }
  },
});

const noaActiveForm = ({
  noaSummary: { active, placed },
  debtorId,
  factoringId,
  updateNOADates: update,
  openSnackbar,
  refresh,
}) => ({
  enableReinitialize: true,
  initialValues: {
    active: active || '',
  },
  onSubmit: async (values, { setSubmitting }) => {
    try {
      await update(
        debtorId,
        factoringId,
        values.active !== '' ? DateTime.fromISO(values.active).toISO() : null,
        null
      );
      await refresh();
      openSnackbar('success', 'Updated NOA Dates!');
    } catch (err) {
      openSnackbar('error', head(err));
    } finally {
      setSubmitting(false);
    }
  },
});

const mapStateToProps = (state) => ({
  loading: getConnectedDebtorsIsLoading(state),
  count: getConnectedDebtorsCount(state),
  page: getConnectedDebtorsPage(state),
  rowsPerPage: getConnectedDebtorsRowsPerPage(state),
  rowsPerPageOptions: getConnectedDebtorsRowsPerPageOptions(state),
  sortDirection: getConnectedDebtorsSortDirection(state),
  sortBy: getConnectedDebtorsSortBy(state),
  filters: getConnectedDebtorsFilters(state),
  connectedDebtors: getConnectedDebtors(state),
  getDebtorRelationHistory: getDebtorRelationHistory(state),
  dialog: getDialog(state),
  sendNOADialog: getSendNOA(state),
  attachmentsByFactoringId: getAttachments(state),
  factorClient: getFactorClient(state),
  userType: getUserType(state),
});

const mapDispatchToProps = {
  handleSort: handleConnectedDebtorsSort,
  handleChangePage: handleConnectedDebtorsChangePage,
  handleChangeRowsPerPage: handleConnectedDebtorsChangeRowsPerPage,
  handleFilterChange: handleConnectedDebtorsSearchFilter,
  queryConnectedDebtors,
  updateFactoringDebtor,
  openSnackbar,
  fetchDebtorRelationHistory,
  openDialog,
  closeDialog,
  deleteDebtorRelation,
  closeSendNOA,
  fetchFactoringProfile,
  fetchAttachments,
  sendNOA,
  updateNOADates: updateNOADates(),
};

export default compose(
  withSelect,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      history: {
        location: { pathname },
        push,
      },
      match: {
        params: { id },
      },
      connectedDebtors,
      updateFactoringDebtor,
      openSnackbar,
      queryConnectedDebtors,
      sortDirection,
      sortBy,
      rowsPerPage,
      page,
      filters,
      getDebtorRelationHistory,
      openDialog,
      dialog,
      closeDialog,
      deleteDebtorRelation,
      attachmentsByFactoringId,
      closeSendNOA,
      factorClient,
      sendNOA,
      updateNOADates,
    }) => {
      return {
        factorClient,
        tabs: tabs(id, factorClient.allow_self_finance_fr),
        navIndex: findIndex(tabs(id), (tab) => pathname === tab.route),
        handleRoute: (route) => push(route),
        id,
        columns,
        onSubmitInvoincingSettings: async (values, { setSubmitting }) => {
          try {
            await updateFactoringDebtor(values.debtorId, id, {
              send_debtor_invoice: values.sendDebtorInvoice,
              send_ar_aging_followup: values.sendARAgingFollowup,
            });
            setSubmitting(false);
            openSnackbar('success', 'Factoring Debtor Relationship Updated.');
          } catch (err) {
            openSnackbar('error', err);
          } finally {
            closeDialog();
          }
        },
        connectedDebtors: connectedDebtors.map((debtor) => {
          return {
            ...debtor,
            discountRate: debtor.discount_rate,
            minimumFee: debtor.minimum_fee,
            name: () => (
              <Link to={`/admin/debtors/${debtor.id}/profile`}>
                {debtor.company_name}
              </Link>
            ),
            created: formatDate(debtor.created),
            discount_rate: ({ values, errors, handleChange, handleBlur }) => (
              <CustomTextField
                name="discount_rate"
                value={values.discount_rate}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.discount_rate}
                helperText={errors.discount_rate}
              />
            ),
            minimum_fee: ({ values, errors, setFieldValue, handleBlur }) => (
              <PenniesInput
                name="minimum_fee"
                value={values.minimum_fee}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                error={!!errors.minimum_fee}
                helperText={errors.minimum_fee}
              />
            ),
            coBrokerAllowed: ({ values, errors, handleChange, handleBlur }) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Select
                        fullWidth
                        name="co_broker_allowed"
                        onChange={handleChange}
                        value={values.co_broker_allowed}
                        onBlur={handleBlur}
                        selections={[{
                            text: 'No Acknowledgement on File',
                            value: 'NO_ACKNOWLEDGMENT_ON_FILE'
                        }, {
                            text: 'Acknowledgment Received',
                            value: 'ACKNOWLEDGMENT_RECEIVED'
                        }, {
                            text: 'Approved',
                            value: 'APPROVED'
                        }, {
                            text: 'Declined',
                            value: 'DECLINED'
                        }
                        ]}
                    />
                    <Badge
                        // classes={{ badge: classes.badge }}
                        badgeContent={(debtor.attachments || []).length}
                        color="primary"
                    >
                        <Tooltip title="Upload Co-brokering Documents">
                            <IconButton
                                style={{ width: '48px', height: '48px' }}
                                onClick={() => openDialog('co-brokering-documents', '', '', {
                                    relationshipId: debtor.relationship_id,
                                    attachments: debtor.attachments || []
                                })}
                            >
                                <UploadFile />
                            </IconButton>
                        </Tooltip>
                    </Badge>
                </div>
            ),
            user_type: splitAndCase(get(debtor, 'user.user_type', '')),
            state_zip: () =>
              get(debtor, 'address.state') && get(debtor, 'address.zip')
                ? `${get(debtor, 'address.state', '')}/${get(
                    debtor,
                    'address.zip',
                    ''
                  )}`
                : '',
            summary: ({ values, setFieldValue, handleBlur }) => {
              const attachments = attachmentsByFactoringId(id)
                .filter(
                  (attachment) =>
                    attachment.category === 'noa' ||
                    attachment.category === 'comfreight_noa'
                )
                .map((attachment) => ({
                  ...attachment,
                  modified: formatDate(attachment.modified),
                }));
              return (
                <React.Fragment>
                  <NOADropDown
                    fullWidth
                    row={{
                      noa_requested: debtor.noa_requested,
                      noa_active: debtor.noa_active,
                      noa_sent: debtor.noa_sent,
                      relationship_created: debtor.created
                    }}
                    setFieldValue={setFieldValue}
                    updatedValue={values.noa}
                    onBlur={handleBlur}
                    disabled={isEmpty(attachments)}
                    clientName={get(factorClient, 'company_profile.name', '')}
                    debtorName={debtor.company_name}
                    placeNOAForm={placeNOAForm(
                      openSnackbar,
                      attachments,
                      sendNOA,
                      debtor.id,
                      factorClient.id
                    )}
                    helperText={isEmpty(attachments) && 'No NOA on File.'}
                  />
                </React.Fragment>
              );
            },
            more: ({
              values: {
                relationship_id: relationshipId,
                id,
                fuel_advance,
                fuel_advance_fee,
                fuel_advance_rate,
              },
              handleSubmit,
            }) => (
              <Can
                perform="admin-factorclients-connected_debtors:actions"
                yes={() => (
                  <MoreLinks
                    handleSubmit={handleSubmit}
                    handleAuditLogClick={() =>
                      openDialog('auditlog', 'Audit Log', '', {
                        relationshipId,
                      })
                    }
                    handleDeleteRelation={() =>
                      openDialog(
                        'confirm',
                        'Are you sure',
                        "Delete this debtor's relationship?",
                        {
                          debtor_id: id,
                        }
                      )
                    }
                    handleFuelAdvance={() =>
                      openDialog('fuel-advance-settings', '', '', {
                        debtorId: id,
                        fuel_advance,
                        fee: fuel_advance_fee,
                        rate: fuel_advance_rate,
                      })
                    }
                    handleInvoicingSettings={() =>
                      openDialog('invoincing-settings', '', '', {
                        sendDebtorInvoice: debtor.send_debtor_invoice,
                        sendARAgingFollowup: debtor.send_ar_aging_followup,
                        debtorId: id,
                      })
                    }
                  />
                )}
              />
            ),
          };
        }),
        connectedDebtorForm: connectedDebtorForm(
          id,
          updateFactoringDebtor,
          openSnackbar,
          () =>
            queryConnectedDebtors(
              id,
              sortDirection === 'asc' ? sortBy : `-${sortBy}`,
              rowsPerPage,
              page,
              filters
            )
        ),
        handleDeleteConfirm: async () => {
          try {
            await deleteDebtorRelation(id, get(dialog, ['data', 'debtor_id']));

            await queryConnectedDebtors(
              id,
              sortDirection === 'asc' ? sortBy : `-${sortBy}`,
              rowsPerPage,
              page,
              filters
            );

            openSnackbar('success', 'Deleted Factoring Debtor Relation!');
          } catch (err) {
            openSnackbar('error', head(err));
          } finally {
            closeDialog();
          }
        },
        fuelAdvanceForm: fuelAdvanceForm(
          id,
          get(dialog, ['data', 'debtorId'], ''),
          get(dialog, ['data', 'fuel_advance'], false),
          get(dialog, ['data', 'rate'], 0),
          get(dialog, ['data', 'fee'], 0),
          updateFactoringDebtor,
          openSnackbar,
          closeDialog,
          () =>
            queryConnectedDebtors(
              id,
              sortDirection === 'asc' ? sortBy : `-${sortBy}`,
              rowsPerPage,
              page,
              filters
            )
        ),
      };
    }
  ),
  lifecycle({
    componentDidMount() {
      const { id, factorClient } = this.props;
      if (isEmpty(factorClient)) {
        this.props.fetchFactoringProfile(id);
      }
      this.props.queryConnectedDebtors(id, '-created', this.props.rowsPerPage);
      this.props.fetchAttachments(id);
    },
    async componentDidUpdate(prevProps) {
      // const { fetchDebtorRelationHistory } = this.props;
      //
      // const props = sortBy(
      //   this.props.connectedDebtors.map(debtor => debtor.relationship_id)
      // );
      // const prev = sortBy(
      //   prevProps.connectedDebtors.map(debtor => debtor.relationship_id)
      // );
      //
      // if (!isEqual(props, prev)) {
      //   await Promise.all(props.map(id => fetchDebtorRelationHistory(id)));
      // }
    },
  }),
  withTabs
)(PureConnectedDebtors);

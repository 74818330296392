import formatDate from "../../../../helpers/format/formatDate";
import splitAndCase from "../../../../helpers/format/splitAndCase";

const columns = [
  {
    key: "companyName",
    name: "Company",
    filter: "text",
    filter_key: "company_name"
  },
  {
    key: "contact_email",
    name: "Contact Email",
    filter: "text",
    filter_key: "email"
  },
  {
    key: "user.user_type",
    name: "User Type",
    format: splitAndCase
  },
  {
    key: "created",
    name: "Created At",
    sort: true,
    format: formatDate
  },
  {
    key: "state_zip",
    name: "State / Zip",
    filter: "text",
    filter_key: "address"
  },
  {
    key: "rateField",
    name: "Rate"
  },
  {
    key: "minimumFee",
    name: "Minimum Fee"
  },
  {
    key: "noaSummary",
    name: "NOA Summary"
  },
  {
    key: "more",
    name: "More"
  }
];

export default columns;

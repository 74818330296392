import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';

import { isEmpty, omit } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Grid from '@material-ui/core/Grid/Grid';
import qs from 'qs';
import ReactPixel from 'react-facebook-pixel';
import { useHubspotTracking } from '@haulpay/hooks';

import FactoringPendingView from '../../components/FactoringPendingView';
import Bank from '../../components/Bank';
import Steps from '../../components/Steps';
import Layout from './components/Layout';
import Confirm from './components/Confirm';
import Attach from './components/Attach';
import Summary from './components/Summary';
import SignUpThanks from './components/SignUpThanks';
import SMSAlertPolicyDialog from '../Invited/components/SMSAlertPolicyDialog';

const mapStepText = userType => step => {
  if (step.text === 'Carrier Info') {
    step.text = `${userType.charAt(0).toUpperCase() + userType.slice(1)} Info`;
  }
  return step;
};

const SignUpForm = ({
  step,
  steps,
  moveToNextStep,
  exchangePlaidToken,
  signUpFactoringProfile,
  factoringLoading,
  factoringError,
  factoringProfile: { id: factoringProfileId = '', ...factoringProfile },
  exchangePlaidLoading,
  exchangePlaidError,
  attachments,
  user: currentUser,
  usedPlaid,
  loadingUser,
  attachmentError,
  setAttachmentError,
  updateFactoringProfile,
  updateFactoringProfileLoading,
  exchangePlaid,
  errorMsg,
  setErrorMsg,
  match: {
    params: { userType = 'carrier', token = null }
  },
  location: { search = {} },
  isIframe,
  updateUser,
}) => {
  const currentStepValue = step;
  const { source = null, landing = false } = qs.parse(search, {
    ignoreQueryPrefix: true
  });
  const {
    id,
    factoring_id: factoringId = null,
    factoring_approved: factoringApproved = null
  } = currentUser;

  const { setTrackEvent } = useHubspotTracking();
  const stepMap = {
    confirm: (
      <Confirm
        user={currentUser}
        loadingUser={loadingUser}
        nextStep={moveToNextStep}
        factoringLoading={factoringLoading}
        factoringError={factoringError}
        token={token}
        landing={landing}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        onSubmit={async ({ address, ...values }, actions) => {
          const user = {
            ...(() =>
              !isIframe ? { signup_process: 'factoring_only' } : {})(),
            factoring_approved: 'lead_in',
            username: values.email,
            signup_url: window.location.href,
            referrer_url: document.referrer,
            [userType]: {
              contact_name: `${values.owner_first_name} ${values.owner_last_name}`,
              address_object: {
                ...address,
                street_two: values.street_two
              },
              ...address,
              street_two: values.street_two,
              enable_sms_alerts: values.enable_sms_alerts,
            },
            owner_profile: {
              name_first: values.owner_first_name,
              name_last: values.owner_last_name,
              email: values.email,
              phone_number_cell: values.owner_phone_number,
              address: {
                ...address,
                street_two: values.street_two
              }
            },
            company_profile: {
              name: values.company_name,
              address: {
                ...address,
                street_two: values.street_two
              },
              phone: values.owner_phone_number,
              number_of_trucks: undefined,
              state_incorporated: undefined,
              current_factoring_company: undefined,
              type: undefined
            },
            has_contract_authority_status: undefined,
            has_common_authority_status: undefined,
            has_broker_authority_status: undefined,
            is_allowed_to_operate: undefined,
            type: userType,
            address: address.formatted_address,
            ...values
          };
          user[userType] = {
            ...user[userType],
            mc: values.mc,
            dot: values.dot,
            tax_id: values.tax_id,
            contact_phone: values.owner_phone_number,
            dba: values.company_name
          };
          user.company_profile = {
            ...user.company_profile,
            mc: values.mc,
            dot: values.dot,
            tax_id: values.tax_id
          };
          ReactPixel.trackCustom('HaulPay Lead', {
            email: values.email,
            type: userType
          });
          try {
            if (isEmpty(currentUser)) {
              await signUpFactoringProfile(user, undefined, 'new', source);
              setTrackEvent('haulPay sign-up', omit(user, ['password']));
            } else {
              await signUpFactoringProfile(
                user,
                undefined,
                'create_factoring_profile'
              );
            }
          } catch (e) {
            setErrorMsg(true);
          }
        }}
      />
    ),
    bank: (
      <Bank
        allowSkip
        nextStep={moveToNextStep}
        submitToken={exchangePlaidToken}
        loading={exchangePlaidLoading}
        error={exchangePlaidError}
      />
    ),
    attach: (
      <Attach
        attachmentsValidation={() => { }}
        factoringProfile={factoringProfile}
        attachments={attachments}
        setAttachmentError={setAttachmentError}
        updateFactoringProfile={updateFactoringProfile}
        error={attachmentError}
        factoringProfileId={isEmpty(factoringProfileId) ? factoringId : factoringProfileId}
        usedPlaid={usedPlaid}
        userType={userType}
        moveToNextStep={moveToNextStep}
        updateFactoringProfileLoading={updateFactoringProfileLoading}
      />
    ),
    summary: (
      <Summary
        usedPlaid={usedPlaid}
        factoringProfileId={isEmpty(factoringProfileId) ? factoringId : factoringProfileId}
        factoringProfileData={factoringProfile}
        plaidData={exchangePlaid}
        updateFactoringProfile={updateFactoringProfile}
        updateFactoringLoading={updateFactoringProfileLoading}
        attachments={attachments}
        updateUser={updateUser}
        userId={id}
        userType={userType}
      />
    ),
    thanks: (
      <SignUpThanks
        isIframe={isIframe}
        message="Thank you for submitting your HaulPay Application. Someone will contact you soon."
      />
    )
  };
  const showSteps = currentStepValue !== 'thanks';
  const StepsView = compose()(() => {
    const StepView = stepMap[currentStepValue];
    return <React.Fragment>
        {showSteps && (
          <Steps
            userType={userType}
            activeStep={currentStepValue}
            steps={steps.slice(0, steps.length - 1).map(mapStepText(userType))}
          />
        )}
      {React.cloneElement(StepView)}
      </React.Fragment>
});
  if (token) {
    if (!isEmpty(currentUser)) {
      return (
        <Layout>
          <RenderFactoringProfileView
            userType={userType}
            factoringApproved={factoringApproved}
            factoringId={factoringId}
            StepsView={StepsView}
          />
        </Layout>
      );
    }
    return (
      <Layout>
        {
          <Grid container alignContent="center" justify="center">
            <CircularProgress
              style={{ alignSelf: 'center', justifySelf: 'center', margin: 30 }}
              color="secondary"
              size={50}
            />
          </Grid>
        }
      </Layout>
    );
  }
  return <Layout><StepsView /></Layout>;
};
const RenderFactoringProfileView = ({
  factoringId,
  StepsView,
  factoringApproved,
  userType
}) => {
  if (!factoringId) {
    return <StepsView />;
  }
  switch (factoringApproved) {
    case 'pending':
      return <FactoringPendingView userType={userType} factoringProfileId={factoringId} />
    default:
      return <StepsView />;
  }
};

SignUpForm.defaultProps = {
  step: 'confirm'
};

SignUpForm.propTypes = {
  factoringLoading: PropTypes.bool.isRequired,
  step: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  moveToNextStep: PropTypes.func.isRequired,
  exchangePlaidToken: PropTypes.func.isRequired,
  factoringError: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  exchangePlaidLoading: PropTypes.bool.isRequired,
  exchangePlaid: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  exchangePlaidError: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  signUpFactoringProfile: PropTypes.func.isRequired,
  attachments: PropTypes.array.isRequired
};

export default withState(
  'attachmentError',
  'setAttachmentError',
  ''
)(SignUpForm);

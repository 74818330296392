import React from "react";
import { Redirect } from "react-router-dom";

import InternalLayout from "../components/layouts/InternalLayout";
import Can from "../components/Can";

export default () => (
  <>
    <Can
      perform="index:view"
      yes={() => (
        <InternalLayout>
          <Can
            perform="admin-dashboard:view"
            yes={() => <Redirect to="/admin/dashboard" />}
          />
        </InternalLayout>
      )}
      no={() => (
        <Can
          perform="admin-factorclients:view"
          yes={() => <Redirect to="/admin/factorclients/approved" />}
          no={() => (
            <Can
              perform="ic-panel-view"
              yes={() => <Redirect to="/dashboard/ic_portal" />}
              no={() => <Redirect to="/login" />}
            />
          )}
        />
      )}
    />
  </>
);

import React, { useCallback, useState } from "react";
import { createStyles, Fab, withStyles } from "@material-ui/core";
import { faArchway } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

import colors from "../theme/colors";
import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../modules/ui";
import API from "../api";
import ConfirmDialog from "./dialogs/ConfirmDialog";

const styles = () =>
  createStyles({
    button: {
      boxShadow:
        "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      background: colors.warning,
      color: colors.white,
      textTransform: "none"
    },
    icon: {
      marginRight: 3
    }
  });

const SendBankLinkButton = ({
  classes,
  userId,
  source = "factoring_client"
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const dialog = useSelector(getDialog);

  const sendLink = async () => {
    setLoading(true);
    try {
      await API.factoring.sendBankLink(userId, source);
      dispatch(openSnackbar("success", "Bank link has been sent"));
    } catch (e) {
      dispatch(openSnackbar("error", "Error happened while sending Bank link"));
    }
    dispatch(closeDialog());
    setLoading(false);
  };

  const handleExit = useCallback(async () => {
    dispatch(closeDialog());
  });

  return (
    <>
      <Fab
        variant="extended"
        size="medium"
        className={classes.button}
        disabled={loading}
        onClick={() =>
          dispatch(
            openDialog(
              undefined,
              "Confirm Send Bank Link",
              "Are you sure you want to send bank link?",
              {},
              "confirm_send_Link"
            )
          )
        }
      >
        <FontAwesomeIcon
          icon={faArchway}
          size="sm"
          color={colors.white}
          className={classes.icon}
        />
        Send Link
      </Fab>
      {dialog.action === "confirm_send_Link" && (
        <ConfirmDialog
          open={dialog.show}
          message={dialog.message}
          handleConfirm={sendLink}
          handleExit={handleExit}
        />
      )}
    </>
  );
};
export default withStyles(styles)(SendBankLinkButton);

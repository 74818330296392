import { useState, useCallback } from 'react';
import { concat, includes, filter } from 'lodash';

const useSelect = (initialSelected = []) => {
  const [selected, setSelected] = useState(initialSelected);

  const handleSelect = useCallback(
    (value) => {
      setSelected((prevSelected) =>
        includes(prevSelected, value)
          ? filter(prevSelected, (item) => item !== value)
          : concat(prevSelected, value)
      );
    },
    [setSelected]
  );

  const handleSelectAllClick = useCallback(
    (values) => {
      setSelected((prevSelected) =>
        prevSelected.length === values.length ? [] : values
      );
    },
    [setSelected]
  );

  const clearSelected = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  const isSelected = useCallback(
    (id) => selected.indexOf(id) !== -1,
    [selected]
  );

  const allSelected = useCallback(
      (length) => selected.length === length && length !== 0,
    [selected]
  );

  return {
    selected,
    handleSelect,
    handleSelectAllClick,
    clearSelected,
    isSelected,
    allSelected,
  };
};

export default useSelect;

import React from "react";
import { Formik } from "formik";
import Notes from "../../../../components/Notes";

interface Props {
  updateTransactionNote: (
    noteId: string,
    note: string,
    attachments?: File
  ) => void;
  openSnackbar: Function;
  createTransactionNote: (note: string, attachments?: File) => void;
  transactionNotes: any[];
  deleteTransactionNote: Function;
}

export default ({
  updateTransactionNote,
  openSnackbar,
  createTransactionNote,
  transactionNotes,
  deleteTransactionNote
}: Props): JSX.Element => (
  <Formik
    enableReinitialize
    initialStatus="CREATE"
    initialValues={{
      note: "",
      attachments: undefined,
      notesID: ""
    }}
    onSubmit={async (values, { setSubmitting, resetForm }): Promise<void> => {
      try {
        if (values.notesID !== "") {
          await updateTransactionNote(
            values.notesID,
            values.note,
            values.attachments
          );
          openSnackbar("success", "Updated Note!");
        } else {
          await createTransactionNote(values.note, values.attachments);
          openSnackbar("success", "Created Note!");
        }
      } catch (err) {
        openSnackbar("error", err.message || err);
      } finally {
        setSubmitting(false);
        resetForm();
      }
    }}
  >
    {({
      handleChange,
      handleSubmit,
      handleBlur,
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      status,
      setStatus,
      isSubmitting
    }): JSX.Element => {
      return (
        <Notes
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          handleSubmit={handleSubmit}
          isCreatingNote={isSubmitting}
          notes={transactionNotes}
          isFetching={false}
          notesAttachments={{}}
          isUpdatingNote={isSubmitting}
          handleDeleteNote={async (id: string): Promise<void> => {
            try {
              await deleteTransactionNote(id);
              openSnackbar("success", "Deleted Note!");
            } catch (err) {
              openSnackbar("error", err.message || err);
            }
          }}
          status={status}
          setStatus={setStatus}
        />
      );
    }}
  </Formik>
);

import { isArray, mergeWith } from "lodash";

import {
  UPLOAD_ATTACHMENTS_REQUEST,
  UPLOAD_ATTACHMENTS_FAILURE,
  UPLOAD_ATTACHMENTS_SUCCESS
} from "../types";

function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export default (state, action) => {
  switch (action.type) {
    case UPLOAD_ATTACHMENTS_REQUEST:
      return {
        ...state,
        loadingFactoringAttachments: true
      };
    case UPLOAD_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        loadingFactoringAttachments: false,
        attachments: mergeWith(
          {},
          state.attachments,
          action.payload,
          customizer
        )
      };
    case UPLOAD_ATTACHMENTS_FAILURE:
      return {
        ...state,
        loadingFactoringAttachments: false
      };
    default:
      return state;
  }
};

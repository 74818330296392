import { closeDialog, openSnackbar } from "../../../modules/ui";
import fetchPayments from "../../../modules/finances/actions/fetchPayments";

const deletePayment = paymentId => async (dispatch, getState, { api }) => {
  try {
    const state = getState();
    await api.payments.deletePayment(paymentId);
    dispatch(openSnackbar("success", "Payment successfully deleted"));
    dispatch(
      fetchPayments(
        state.payments.limit,
        state.payments.ordering,
        state.payments.ordering
      )
    );
  } catch (err) {
    dispatch(
      openSnackbar(
        "error",
        "Delete Payment error. Please refresh and try again."
      )
    );
  } finally {
    dispatch(closeDialog());
  }
};

export default deletePayment;

import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import { getFactorCompany } from "../../modules/factorCompany";
import AutoComplete from "./AutoComplete";
import formatLocation from "../../helpers/format/formatAddress";

const DetailsFactorCompany = ({ value }) => {
  if (!value) return <div />;
  return <Typography variant="caption">{value}</Typography>;
};

const FactorCompanyAutoComplete = ({ onNoOptionClick, ...props }) => {
  const dispatch = useDispatch();
  const loadOptions = useCallback(async name => {
    const res = await dispatch(
      getFactorCompany({ name, status: "approved, pending" })
    );
    if (!isEmpty(res.results)) {
      return map(res.results, object => ({ label: object.name, ...object }));
    }
    return [];
  }, []);

  const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
    <>
      <Typography
        color="textSecondary"
        className={selectProps.classes.noOptionsMessage}
        {...innerProps}
      >
        {children}
      </Typography>
      <Typography
        color="secondary"
        variant="caption"
        className={selectProps.classes.noOptionsMessage}
        {...innerProps}
        style={{
          textDecoration: "underline"
        }}
        onClick={onNoOptionClick}
      >
        Didn’t find your factors? Add new one here.
      </Typography>
    </>
  );

  const Option = ({
    innerRef,
    isFocused,
    isSelected,
    innerProps,
    children,
    data,
    ...props
  }) => (
    <ListItem dense divider selected={isFocused} button buttonRef={innerRef}>
      <ListItemText
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
        {...innerProps}
        primary={data.name}
        secondary={
          <React.Fragment>
            <Typography component="span" color="textSecondary">
              {data.email}
            </Typography>
            {formatLocation(data)}
          </React.Fragment>
        }
      />
    </ListItem>
  );

  const Menu = ({ selectProps, innerProps, children }) => (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      <List dense="dense">{children}</List>
    </Paper>
  );

  return (
    <AutoComplete
      loadOptions={loadOptions}
      autoCompleteComponents={{ NoOptionsMessage, Option, Menu }}
      {...props}
    />
  );
};
export default FactorCompanyAutoComplete;

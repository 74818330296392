const steps = [
  {
    text: "Load Details",
    value: "load"
  },
  {
    text: "Funding Request",
    value: "request"
  },
  {
    text: "Upload Files",
    value: "upload"
  },
  {
    text: "Summary",
    value: "summary"
  }
];

export default steps;

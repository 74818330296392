import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../theme/colors";

interface Props {
  message?: string | null;
}

const InfoModal = ({
  message = "This is a error message."
}: Props): JSX.Element => (
  <Grid
    container
    direction="column"
    spacing={16}
    alignContent="center"
    alignItems="center"
  >
    <Grid item>
      <FontAwesomeIcon color={Colors.info} size="5x" icon={faInfoCircle} />
    </Grid>
    <Grid item>
      <Typography style={{ color: Colors.info }} variant="h5">
        Error!
      </Typography>
    </Grid>
    <Grid item>
      <Typography style={{ color: Colors.info }} variant="h6">
        {message}
      </Typography>
    </Grid>
  </Grid>
);

export default InfoModal;

const tabs = count => [
  {
    label: "Pending Boarding",
    route: "/dashboard/ic_portal/pending_boarding",
    value: 0,
    badge: count
    // permission: "admin-finances-debtor-balances:view"
  },
  {
    label: "Connected",
    route: "/dashboard/ic_portal/pending_boarding",
    value: 1
    // permission: "admin-finances:view"
  }
];
export default tabs;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import fetchAll from "../../../helpers/fetchAll";
import {
  FETCH_LINEITEMS_FAILURE,
  FETCH_LINEITEMS_REQUEST,
  FETCH_LINEITEMS_SUCCESS
} from "../types";

export default id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_LINEITEMS_REQUEST)());
    const items = await fetchAll(() => api.factoring.getLineItems(id));
    const data = normalize(items, [schema.lineItem]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_LINEITEMS_SUCCESS)({
        [id]: data.result
      })
    );
    return items;
  } catch (err) {
    dispatch(createAction(FETCH_LINEITEMS_FAILURE)(err));
    throw err;
  }
};

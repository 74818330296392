import { assign } from "lodash";
import {
  FETCH_PURCHASES_ITEMS_FAILURE,
  FETCH_PURCHASES_ITEMS_REQUEST,
  FETCH_PURCHASES_ITEMS_SUCCESS
} from "../types";

export default (state, action) => {
  const { items } = state;
  switch (action.type) {
    case FETCH_PURCHASES_ITEMS_REQUEST:
      return {
        ...state,
        items: assign({}, items, {
          loading: true
        })
      };
    case FETCH_PURCHASES_ITEMS_SUCCESS:
      return {
        ...state,
        items: assign({}, items, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count,
          id: action.payload.id,
          limit: action.payload.limit,
          offset: action.payload.offset,
          ordering: action.payload.ordering
        })
      };
    case FETCH_PURCHASES_ITEMS_FAILURE:
      return {
        ...state,
        items: assign({}, items, {
          ids: [],
          loading: false
        })
      };
    default:
      return state;
  }
};

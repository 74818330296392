import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import {
  UPDATE_FACTORINGDEBTOR_FAILURE,
  UPDATE_FACTORINGDEBTOR_REQUEST,
  UPDATE_FACTORINGDEBTOR_SUCCESS
} from "../types";
import { addEntities } from "../../entities";

const updateFactoringDebtor = (debtorId, factoringId, update) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPDATE_FACTORINGDEBTOR_REQUEST)({}));
    const response = await api.debtors.updateFactoringDebtor(
      debtorId,
      factoringId,
      update
    );
    const data = normalize(response, schema.debtorProfile);
    dispatch(addEntities(data.entities));
    dispatch(createAction(UPDATE_FACTORINGDEBTOR_SUCCESS)({}));
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_FACTORINGDEBTOR_FAILURE)(err));
    throw err;
  }
};

export default updateFactoringDebtor;

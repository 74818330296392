import { MenuItem } from "@material-ui/core";
import React, { ChangeEventHandler } from "react";
import CustomTextField from "./CustomTextField";

const userType = [
  {
    value: "admin",
    label: "Admin"
  },
  {
    value: "sub_admin",
    label: "Sub Admin"
  },
  {
    value: "aladdin_admin",
    label: "Aladdin Admin"
  },
  {
    value: "broker",
    label: "Broker"
  },
  {
    value: "carrier",
    label: "Carrier"
  },
  {
    value: "debtor",
    label: "Debtor"
  },
  {
    value: "dispatcher",
    label: "Dispatcher"
  },
  {
    value: "factoring_admin",
    label: "Factoring Admin"
  },
  {
    value: "factoring_remote_admin",
    label: "Factoring Remote Admin"
  },
  {
    value: "factoring_remote_coordinator",
    label: "Factoring Remote Coordinator"
  },
  {
    value: "shipper",
    label: "Shipper"
  }
];

interface Props {
  values: {
    user_type: string;
  };
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: ChangeEventHandler<HTMLInputElement>;
}

const UserTypeInput = ({ onChange, onBlur, values }: Props): JSX.Element => (
  <CustomTextField
    fullWidth
    select
    label="User Type"
    name="user_type"
    id="user_type"
    onChange={onChange}
    onBlur={onBlur}
    value={values.user_type}
  >
    {userType.map(
      (option): JSX.Element => (
        <MenuItem id={option.value} key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )
    )}
  </CustomTextField>
);

export default UserTypeInput;

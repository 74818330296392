import { Link, LinkProps } from "react-router-dom";
import React from "react";
import { merge } from "lodash";

interface Props {
  underline?: boolean;
}

export default ({
  children,
  underline = true,
  style,
  ...props
}: LinkProps & Props): JSX.Element => (
  <Link
    {...props}
    style={merge({ color: "#4a90e2" }, style, {
      textDecoration: underline ? undefined : "none"
    })}
  >
    {children}
  </Link>
);

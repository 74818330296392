import React from "react";
import { Grid, InputAdornment, FormHelperText } from "@material-ui/core";
import { get } from "lodash";

import Steps from "../../../../../../../components/Steps";
import AutoCompleteAddress from "../../../../../../../components/inputs/AutoCompleteAddress";
import equipmentTypes from "../../../../../../../helpers/equipmentTypes";
import BoldInput, {
  Date,
  DateTime,
  CheckBox,
  Pennies,
  Select
} from "../../../../../../../components/inputs/BoldInput";

interface Props {
  accounts?: any[];
  disabled?: boolean;
  steps: any[];
  status: string;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: Function;
  handleBlur: Function;
  setFieldTouched: Function;
  errors: any;
  touched: any;
  factoringId: string;
  generateLoadNumbers: boolean;
  handleSetStatus: Function;
}

function LoadDetails({
  accounts,
  disabled,
  steps,
  status,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  setFieldTouched,
  errors,
  touched,
  factoringId,
  generateLoadNumbers,
  handleSetStatus
}: Props): JSX.Element {
  return (
    <React.Fragment>
      <div style={{ paddingTop: "16px" }}>
        <Steps steps={steps} activeStep={status} />
      </div>
      <Grid
        container
        direction="column"
        style={{ margin: "auto", padding: 16 }}
        spacing={16}
      >
        <Grid item style={{ width: "100%" }}>
          <AutoCompleteAddress
            label="First Pick Up"
            fullWidth
            name="firstPickUp"
            value={values.firstPickUp}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            withStyle
            disabled={disabled}
            hasError={touched.firstPickUp && !!errors.firstPickUp}
            helperText={touched.firstPickUp ? errors.firstPickUp : ""}
          />
          {touched.firstPickUp && !!errors.firstPickUp && (
            <FormHelperText error id="component-error-text">
              {get(errors, "firstPickUp.formatted_address", "")}
            </FormHelperText>
          )}
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <AutoCompleteAddress
            label="Final Delivery"
            fullWidth
            name="finalDelivery"
            value={values.finalDelivery}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            withStyle
            disabled={disabled}
            hasError={touched.finalDelivery && !!errors.finalDelivery}
            helperText={touched.finalDelivery ? errors.finalDelivery : ""}
          />
          {touched.finalDelivery && !!errors.finalDelivery && (
            <FormHelperText error id="component-error-text">
              {get(errors, "finalDelivery.formatted_address", "")}
            </FormHelperText>
          )}
        </Grid>
        {!generateLoadNumbers && (
          <Grid item style={{ width: "100%" }}>
            <BoldInput
              label="Load #"
              name="loadNumber"
              value={values.loadNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              disabled={disabled}
              error={touched.loadNumber && !!errors.loadNumber}
              helperText={touched.loadNumber ? errors.loadNumber : ""}
            />
          </Grid>
        )}
        <Grid item style={{ width: "100%" }}>
          <Select
            disabled={disabled}
            value={values.equipmentType}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            label="Equipment Type"
            name="equipmentType"
            error={touched.equipmentType && !!errors.equipmentType}
            helperText={touched.equipmentType ? errors.equipmentType : ""}
            selections={equipmentTypes.map(({ value, name }) => ({
              value,
              text: name
            }))}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <BoldInput
            fullWidth
            name="loadLength"
            label="Load Length:"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.loadLength}
            InputProps={{
              endAdornment: <InputAdornment position="end">ft</InputAdornment>
            }}
            disabled={disabled}
            error={touched.loadLength && !!errors.loadLength}
            helperText={touched.loadLength ? errors.loadLength : ""}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <BoldInput
            fullWidth
            name="loadWeight"
            label="Load Weight:"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.loadWeight}
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>
            }}
            disabled={disabled}
            error={touched.loadWeight && !!errors.loadWeight}
            helperText={touched.loadWeight ? errors.loadWeight : ""}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <DateTime
            fullWidth
            name="pickUpOpen"
            label="Pick Up Open"
            value={values.pickUpOpen}
            error={touched.pickUpOpen && !!errors.pickUpOpen}
            helperText={touched.pickUpOpen ? errors.pickUpOpen : ""}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <DateTime
            fullWidth
            name="pickUpClose"
            label="Pick Up Close"
            value={values.pickUpClose}
            error={touched.pickUpClose && !!errors.pickUpClose}
            helperText={touched.pickUpClose ? errors.pickUpClose : ""}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Date
            fullWidth
            name="deliverBy"
            label="Deliver By"
            value={values.deliverBy}
            error={touched.deliverBy && !!errors.deliverBy}
            helperText={touched.deliverBy ? errors.deliverBy : ""}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default LoadDetails;

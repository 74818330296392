import React from "react";
import { createStyles, Modal, Theme, WithStyles } from "@material-ui/core";
import { filter } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import InfoModal from "./InfoModal";
import ErrorModal from "./ErrorModal";
import WarningModal from "./WarningModal";
import SuccessModal from "./SuccessModal";
import SettingForm from "../../screens/Admin/SettingForm";
import DialogForm from "../../screens/Admin/Admins/AdminsDialog/DialogForm";
import { getDialog } from "../../modules/ui";

interface ModalStyle {
  top: string;
  left: string;
  transform: string;
}

function getModalStyle(): ModalStyle {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 470,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 4,
      outline: "none"
    }
  });

interface Modals {
  [index: string]: JSX.Element | undefined;
  success: JSX.Element;
  info: JSX.Element;
  error: JSX.Element;
  warning: JSX.Element;
}

const componentModal = (message: string | null): Modals => ({
  success: <SuccessModal message={message} />,
  info: <InfoModal message={message} />,
  error: <ErrorModal message={message} />,
  warning: <WarningModal message={message} />
});

interface Props extends WithStyles<typeof styles> {
  modals: {
    message: null | string;
    variant: null | string;
    types: string[];
  };
  handleClose: (variant: string | null) => void;
  closeDialog: () => void;
  userId: string;
  updateUser: Function;
  openModal: Function;
}

const StatusModal = ({
  closeDialog,
  modals: { message = null, variant = null, types },
  handleClose,
  classes,
  userId,
  updateUser,
  openModal
}: Props): JSX.Element => {
  const dialog = useSelector(getDialog);
  return (
    <React.Fragment>
      <Modal
        closeAfterTransition
        open={Boolean(
          filter(types, { show: true, status: variant }).length > 0
        )}
        onClose={(): void => handleClose(variant)}
      >
        <div style={getModalStyle()} className={classes.paper}>
          {variant && componentModal(message)[variant]}
        </div>
      </Modal>
      {dialog.variant === "user_settings" && (
        <SettingForm
          callback={updateUser}
          openModal={openModal}
          open={dialog.show}
          handleClose={closeDialog}
          userId={userId}
        />
      )}
      {(dialog.action === "edit_admin_user_form" ||
        dialog.action === "add_admin_user_form") && (
        <DialogForm
          handleClose={closeDialog}
          open={dialog.show}
          openModal={openModal}
          values={dialog.data}
          action={dialog.action}
          callback={(fields: any) =>
            dialog.action === "edit_admin_user_form"
              ? updateUser({ ...fields, userId: dialog.data.id })
              : ""
          }
        />
      )}
    </React.Fragment>
  );
};
export default withStyles(styles)(StatusModal);

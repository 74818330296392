import React from 'react';
import tw, { styled } from 'twin.macro';

interface Props {
  type?: 'primary' | 'secondary' | 'danger' | 'default';
}


export const Button = styled.button<Props>(({ type = 'default' }) => [
  tw`
    inline-flex
    items-center
    px-2.5
    py-1.5
    border
    border-transparent
    text-sm
    font-medium
    rounded
    shadow-sm
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    text-white
    bg-blue-400
    hover:bg-blue-500
    focus:ring-blue-300
  `,
  type === "danger" ? tw`
    bg-red-400
    hover:bg-red-500
    focus:ring-red-300
  ` : undefined,
  type === "primary" ? tw`
    bg-yellow-500
    hover:bg-yellow-600
    focus:ring-yellow-400
  ` : undefined,
  type === "secondary" ? tw`
    bg-green-500
    hover:bg-green-600
    focus:ring-green-400
  ` : undefined,
  type === "default" ? tw`
    bg-white
    border-gray-400
    border
    text-black	
    hover:bg-white
    focus:bg-white
  ` : undefined
]);

export type ButtonProps = React.ComponentProps<typeof Button>;

export default Button;

import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import get from "lodash/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faHandHoldingUsd
} from "@fortawesome/free-solid-svg-icons";

import {
  ExpansionPanelDetails,
  Grid,
  IconButton,
  Paper
} from "@material-ui/core";
import { Redirect } from "react-router";
import { FilterList as FilterIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import HaulPayStatus from "../../../../components/StatusFormat";
import Table from "../../../../components/Table";
import columns from "./columns";
import {
  fetchPaymentProfilePurchases,
  createPurchasesCSV,
  purchasesTableSelectors,
  purchasesTableActions,
  getPaymentProfilePurchases
} from "../../../../modules/payment";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import tabs from "../tabs";
import CustomExpansionPanel from "../../../../components/ExpansionPanel";
import ExpansionPanelSummary from "../../../../components/ExpansionPanelSummary";
import CustomTextField from "../../../../components/inputs/CustomTextField";
import CSVButton from "../../../../components/buttons/CSVButton";
import withSelect from "../../../../components/withSelect";
import ExpandableView from "../../../../components/ExpandableView";
import nestedPurchasesTableColumns from "./nestedColumns";
import {
  clearPurchaseItems,
  fetchPurchaseItems,
  getPurchaseItems
} from "../../../../modules/finances";
import colors from "../../../../theme/colors";
import API from "../../../../api";
import triggerDownload from "../../../../helpers/triggerDownload";
import { openSnackbar } from "../../../../modules/ui";
import WithSimilarPaymentProfile from "../WithSimilarPaymentProfile";
import SelfFinanceIcon from "../../../../components/icons/SelfFinanceIcon";
import formatPennies from "../../../../helpers/format/formatPennies";

const Filters = ({ handleSearchFilter, filters, handleCSV }) => (
  <CustomExpansionPanel>
    <ExpansionPanelSummary expandIcon={<FilterIcon />}>
      <Grid container alignContent="flex-end" alignItems="flex-end">
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "flex-end"
          }}
        >
          <CSVButton handleCSV={handleCSV} />
        </div>
      </Grid>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Grid container spacing={32} style={{ marginRight: 30, marginLeft: 30 }}>
        <Grid item>
          <CustomTextField
            label="Invoice Number"
            name="invoice_number"
            value={filters.invoice_number}
            onChange={handleSearchFilter}
          />
        </Grid>
        <Grid item>
          <CustomTextField
            label="Load Number"
            name="load_number"
            value={filters.user_load_number}
            onChange={handleSearchFilter}
          />
        </Grid>
      </Grid>
    </ExpansionPanelDetails>
  </CustomExpansionPanel>
);

const NestedMoreButton = ({ id }) => (
  <Link to={`/admin/fundingrequest/${id}`}>
    <IconButton
      key="close"
      aria-label="Close"
      style={{ color: colors.green_light }}
    >
      <FontAwesomeIcon size="xs" icon={faHandHoldingUsd} />
    </IconButton>
  </Link>
);

export default compose(withSelect)(function Purchases({
  isSelected,
  handleSelect,
  handleSelectAllClick,
  allSelected,
  selected,
  clearSelected,
  ...props
}) {
  const id = get(props, ["match", "params", "id"], "");
  const dispatch = useDispatch();

  const [count] = WithSimilarPaymentProfile(id);

  useEffect(() => {
    dispatch(fetchPaymentProfilePurchases(id, 20, {}));
  }, [id]);

  const handleExportReports = useCallback(
    async purchaseId => {
      try {
        const response = await API.factoring.paymentProfile.exportPaymentProfilePurchaseReport(
          id,
          purchaseId
        );
        const element = document.createElement("a");
        element.href = response.download_url;
        document.body.appendChild(element);
        element.click();
      } catch (e) {
        dispatch(openSnackbar("error", "Error while download"));
      }
    },
    [id]
  );

  const formattedRows = (useSelector(getPaymentProfilePurchases) || []).map(
    row => ({
      ...row,
      action: (
        <IconButton onClick={() => handleExportReports(row.id)}>
          <FontAwesomeIcon
            icon={faArrowAltCircleDown}
            color={colors.green}
            size="sm"
          />
        </IconButton>
      ),
      status: <HaulPayStatus style={{ fontSize: 14 }} status={row.status} />
    })
  );

  const formattedNestedRows = (useSelector(getPurchaseItems) || []).map(
    row => ({
      ...row,
      amount: () => (
        <Grid container direction="row" alignItems="center">
          {formatPennies(row?.amount)}
          <SelfFinanceIcon contractType={row?.contract_type} />
        </Grid>
      ),
      more: <NestedMoreButton id={get(row, "funding_request")} />
    })
  );

  const fetchPurchasesItems = useCallback((id, limit) =>
    dispatch(fetchPurchaseItems(id, limit))
  );
  const clear = useCallback(() => dispatch(clearPurchaseItems()));

  const purchasesTable = useSelector(state => ({
    isLoading: purchasesTableSelectors.getIsLoading(state),
    count: purchasesTableSelectors.getCount(state),
    page: purchasesTableSelectors.getPage(state),
    rowsPerPage: purchasesTableSelectors.getRowsPerPage(state),
    rowsPerPageOptions: purchasesTableSelectors.getRowsPerPageOptions(state),
    filters: purchasesTableSelectors.getFilters(state)
  }));

  const purchasesActionsTable = useCallback(
    () => ({
      handleSort: (...args) =>
        dispatch(purchasesTableActions.handleSort(...args)),
      handleChangePage: (...args) =>
        dispatch(purchasesTableActions.handleChangePage(...args)),
      handleChangeRowsPerPage: (...args) =>
        dispatch(purchasesTableActions.handleChangeRowsPerPage(...args)),
      handleSearchFilter: (...args) =>
        dispatch(purchasesTableActions.handleSearchFilter(...args))
    }),
    [dispatch]
  );

  const handleCSV = useCallback(
    (id, filters, ids) => {
      dispatch(createPurchasesCSV(id, filters, ids));
      clearSelected();
    },
    [id, purchasesTable.filters, selected]
  );

  return (
    <Can
      perform="admin-payments:view"
      yes={() => (
        <InternalLayout title="Payment Profile">
          <Grid
            container
            direction="column"
            spacing={24}
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <NavigationTabs tabs={tabs(id, count)} value={4} />
            </Grid>
            <Grid item>
              <Paper style={{ padding: "16px" }}>
                <Filters
                  filters={purchasesTable.filters}
                  handleSearchFilter={
                    purchasesActionsTable().handleSearchFilter
                  }
                  handleCSV={() =>
                    handleCSV(id, purchasesTable.filters, selected)
                  }
                />
                <Table
                  columns={columns}
                  rows={formattedRows}
                  isLoading={purchasesTable.isLoading}
                  handleSort={purchasesActionsTable().handleSort}
                  count={purchasesTable.count}
                  page={purchasesTable.page}
                  rowsPerPage={purchasesTable.rowsPerPage}
                  handleChangePage={purchasesActionsTable().handleChangePage}
                  rowsPerPageOptions={purchasesTable.rowsPerPageOptions}
                  handleChangeRowsPerPage={
                    purchasesActionsTable().handleChangeRowsPerPage
                  }
                  filter
                  filters={purchasesTable.filters}
                  handleFilterChange={
                    purchasesActionsTable().handleSearchFilter
                  }
                  allowEmpty
                  collapse
                  colSpan={11}
                  expandableView={id => (
                    <ExpandableView
                      columns={nestedPurchasesTableColumns}
                      fetch={id => fetchPurchasesItems(id, 30)}
                      id={id}
                      tableKey="purchaseItems"
                      clear={clear}
                      data={formattedNestedRows}
                      allowEmpty
                    />
                  )}
                />
              </Paper>
            </Grid>
          </Grid>
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
});

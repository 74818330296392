import { Grid, Typography } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import React from "react";

interface Props {
  id: string;
  label: string;
}

const Attachment = ({ id, label }: Props): JSX.Element => (
  <Grid key={id} container item direction="row">
    <Grid item>
      <Typography component="p">{label}</Typography>
    </Grid>
    <Grid item>
      <AttachFileIcon color="secondary" fontSize="small" />
    </Grid>
  </Grid>
);

export default Attachment;

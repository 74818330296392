import { denormalize } from "normalizr";
import { get } from "lodash";
import { lineItemCategory } from "../../../api/schema";

const getLineItemCategories = state => {
  try {
    const ids = get(state, "debtor.lineItemCategories", []);
    const denormalized = denormalize(
      { ids },
      { ids: [lineItemCategory] },
      state.entities
    );

    return denormalized.ids;
  } catch (err) {
    return [];
  }
};

export default getLineItemCategories;

import { Grid, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { Formik } from "formik";

import {
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../components/Dialog";
import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../modules/ui";
import SnackbarContentWrapper from "../../../../components/Snackbar";
import factorCompanyAPI from "../../../../api/factorCompany";
import CreateFactorCompanyForm from "../../../Invited/components/CreateFactorComapnyForm";
import FactorCompanyAutoComplete from "../../../../components/inputs/FactorCompanyAutocomplete";
import RoundedButton from "../../../../components/buttons/RoundedButton";
import UploadFile from "../../../../components/UploadFile";
import formatLocation from "../../../../helpers/format/formatAddress";

export default function ({ open, brokerFactoringId, formIndex }) {
  const DetailsFactorCompany = ({ value }) => {
    if (!value) return <div />;
    return <Typography variant="caption">{value}</Typography>;
  };
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const closeConfirmDialog = useCallback(args => dispatch(closeDialog()), []);
  const [selectedFactorCompany, setSelectedFactorCompany] = useState(undefined);
  const [uploadNOA, setUploadNOA] = useState(false);
  const [relationship, setRelationship] = useState({});
  const [displayCreateForm, setDisplayCreateForm] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [relationError, setRelationError] = useState("");

  const openConfirmInviteModal = useCallback(
    data =>
      dispatch(
        openDialog(
          "confirm_send_invite",
          "Confirm Send Invite",
          "Are you sure you would like to use this factoring company?",
          data
        )
      ),
    []
  );

  const handleClose = useCallback(async args => dispatch(closeDialog()));

  const onSubmit = useCallback(async fields => {
    try {
      const res = await factorCompanyAPI.createFactorCompany({
        ...fields,
        ...fields.address
      });
      setSelectedFactorCompany({
        ...res,
        label: res.name
      });
    } catch (e) {
      dispatch(openSnackbar("error", e));
    }
    setDisplayCreateForm(false);
  }, []);

  const FactoringCompanyDescriptions = () => (
    <Grid container spacing={16}>
      <Grid item>
        <Typography variant="body2" align="left">
          BEFORE PROCEEDING - We will need a LOR from your previous factor and a NOA from your new factoring company before any changes will be made.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" align="left">
          If you do not upload them in the next step, we will reach out to you, both factoring companies, and the broker/s you are connected with to obtain these documents.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" align="left">
          PLEASE NOTE Your previous factoring company will continue to be paid until we have received and confirmed both the NOA and LOR.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" align="left">
          Please select your New Factoring Company below.        
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle onClose={handleClose}>Select your new factoring company below</DialogTitle>
      <DialogContent>
        {displayCreateForm ? (
          <Grid container direction="column" xs={12}>
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone_number: "",
                address: ""
              }}
              onSubmit={onSubmit}
              component={CreateFactorCompanyForm}
            />
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            xs={12}
            justify="center"
            alignItems="center"
            alignContent="center"
            style={{ margin: "auto", minHeight: 500, padding: 5 }}
            spacing={16}
          >
            <Grid item>
              <Typography variant="body2" align="center">
                {selectedFactorCompany
                  ? `You can send an invite to factoring company you are working with by adding their email, or
if you already have the notice of assignment you can upload it by clicking on the blue button.`
                  : <FactoringCompanyDescriptions />}
              </Typography>
            </Grid>
            <Grid item>
              {error && (
                <SnackbarContentWrapper
                  variant="error"
                  message="Please select a factoring company"
                  onClick={() => setError(false)}
                />
              )}
              {relationError && (
                <SnackbarContentWrapper
                  variant="error"
                  message={relationError}
                  onClick={() => setRelationError("")}
                />
              )}
            </Grid>
            <Grid
              item
              container
              style={{ margin: "auto", width: "100%" }}
              xs={12}
              md={10}
              justify="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={12} md={8}>
                <FactorCompanyAutoComplete
                  label="Company Name"
                  placeholder="Company Name"
                  onNoOptionClick={() => setDisplayCreateForm(true)}
                  value={selectedFactorCompany}
                  onChange={value => {
                    setError(false);
                    setSelectedFactorCompany(value);
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={4}
                justify="flex-end"
                alignItems="flex-end"
                alignContent="flex-end"
              >
                <RoundedButton
                  style={{ width: "100%", paddingLeft: 20 }}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={async () => {
                    handleClose();
                    if (isEmpty(selectedFactorCompany)) return setError(true);
                    await openConfirmInviteModal({
                      factorCompany: selectedFactorCompany,
                      brokerFactoringId,
                      formIndex
                    });
                  }}
                  loading={loading}
                  disabled={isEmpty(selectedFactorCompany)}
                >
                  Use This Factor
                </RoundedButton>
              </Grid>
              {!isEmpty(selectedFactorCompany) && (
                <Grid
                  item
                  container
                  style={{ margin: "auto", width: "100%" }}
                  xs={10}
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={8}>
                    <DetailsFactorCompany value={selectedFactorCompany.email} />
                    <DetailsFactorCompany
                      value={selectedFactorCompany.phone_number}
                    />
                    <DetailsFactorCompany
                      value={formatLocation(selectedFactorCompany)}
                    />
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
              )}
            </Grid>
            {!isEmpty(selectedFactorCompany) && (
              <Grid item style={{ margin: "auto", width: "100%" }} xs={12}>
                {!uploadNOA ? (
                  <RoundedButton
                    style={{ width: "100%" }}
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={async () => {
                      try {
                        setRelationship(
                          await factorCompanyAPI.createFactorCompanyRelation(
                            selectedFactorCompany.id,
                            brokerFactoringId,
                            undefined,
                            {
                              create_relation: false
                            }
                          )
                        );
                        setUploadNOA(true);
                      } catch (e) {
                        setRelationError(e);
                      }
                    }}
                  >
                    I already have the NOA
                  </RoundedButton>
                ) : (
                  <UploadFile
                    single
                    noCategories
                    type="NOA"
                    requestId={relationship.id}
                  />
                )}
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

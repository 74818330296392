import React from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../../../../../theme/colors";
import AttachmentLinks from "../../../../../../components/AttachmentLinks";

const Send = () => (
  <FontAwesomeIcon icon={faShareSquare} color={Colors.green} size="xs" />
);

const CollatedAttachmentsCard = ({
  collatedAttachment = {},
  expanded = false,
  sendTransferDocuments
}) => (
  <Grid container alignContent="center" alignItems="center" justify="center">
    {!collatedAttachment.isLoading ? (
      collatedAttachment.download_url ? (
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          spacing={8}
        >
          <Grid item>
            <Typography inline style={{ fontSize: 12 }}>
              Collated Transferred PDF:
            </Typography>
          </Grid>
          <Grid item>
            <AttachmentLinks attachment={collatedAttachment} />
          </Grid>
          <Grid item>
            <Tooltip title="Send Transfer Documents">
              <IconButton variant="contained" onClick={sendTransferDocuments}>
                <Send />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <Typography inline style={{ margin: 20, fontSize: 12 }}>
          No collated file found.
        </Typography>
      )
    ) : (
      <CircularProgress style={{ margin: 5 }} size="10" />
    )}
  </Grid>
);

export default CollatedAttachmentsCard;

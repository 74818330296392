import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { get } from "lodash";

import colors from "../../theme/colors";

export interface File {
  name: string;
  type: string;
  size: number;
  lastModified: number;
}
interface Props {
  values: {
    attachments?: File;
  };
}

const FileView = ({ values }: Props): JSX.Element => {
  const file = get(values, ["attachments"]);
  if (file) {
    return (
      <Grid item xs={12} direction="row">
        <Typography inline variant="caption">
          <FontAwesomeIcon icon={faCircle} color={colors.grayText} />
          {` ${file.name} `}
          <FontAwesomeIcon icon={faCheck} size="sm" color={colors.green} />
        </Typography>
      </Grid>
    );
  }
  return <Grid />;
};

export default FileView;

import React from 'react';
import { Collapse } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

interface IProps {
    children?: React.ReactNode;
    title: string;
}

const { Panel } = Collapse;

function CollapsedFilterPanel(props: IProps) {
    return (
        <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
                <FilterOutlined rotate={isActive ? 360 : 0} style={{ fontSize: 26 }}/>
            )}
        >
            <Panel
                header={props.title}
                key="1"
                extra={() => <FilterOutlined />}
            >
                {props.children}
            </Panel>
        </Collapse>
    );
}

export default CollapsedFilterPanel;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";

import { addEntities } from "../../entities";
import {
  FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_REQUEST,
  FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_SUCCESS,
  FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_FAILURE
} from "../types";

const debouncedQueryConnectedClientsRequest = debounce(
  async (dispatch, schema, api, id, ordering, limit, filters) => {
    dispatch(
      createAction(FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_REQUEST)({
        id,
        ordering,
        limit,
        filters
      })
    );
    const response = await api.factorCompany.getFactorCompanyRelationships(
      id,
      ordering,
      limit,
      filters
    );
    const data = normalize(response.results, [schema.connectedCarrier]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_SUCCESS)({
        items: data.result,
        count: response.count
      })
    );
    return response;
  },
  1000,
  { trailing: true }
);

const queryConnectedClients = (id, ordering, limit, filters) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    await debouncedQueryConnectedClientsRequest(
      dispatch,
      schema,
      api,
      id,
      ordering,
      limit,
      filters
    );
  } catch (err) {
    dispatch(
      createAction(FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_FAILURE)(err)
    );
    throw err;
  }
};

export default queryConnectedClients;

import { get } from "lodash";
import { denormalize } from "normalizr";
import { group } from "../../../api/schema";

export default state => {
  try {
    const items = state.groups.pendingInvites.ids;

    const denormalized = denormalize(
      { items },
      { items: [group] },
      state.entities
    );
    return {
      data: denormalized.items || [],
      loading: get(state, "groups.pendingInvites.loading", false)
    };
  } catch (err) {
    return [];
  }
};

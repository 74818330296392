import { assign, merge, concat, get } from "lodash";
import { PayloadAction } from "redux-starter-kit";
import {
  FETCH_CREDIT_HISTORY_REQUEST,
  FETCH_CREDIT_HISTORY_SUCCESS,
  FETCH_CREDIT_HISTORY_FAILURE,
  State,
  ADD_CREDIT_HISTORY_SUCCESS
} from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case FETCH_CREDIT_HISTORY_REQUEST:
      return merge({}, state, {
        id: action.payload.id,
        credit: assign({}, state.credit, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          offset: action.payload.offset,
          ordering: action.payload.ordering
        })
      });
    case FETCH_CREDIT_HISTORY_SUCCESS:
      return assign({}, state, {
        credit: assign({}, state.credit, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count
        })
      });
    case FETCH_CREDIT_HISTORY_FAILURE:
      return assign({}, state, {
        credit: merge({}, state.credit, {
          loading: false
        })
      });
    case ADD_CREDIT_HISTORY_SUCCESS:
      return assign({}, state, {
        credit: assign({}, state.credit, {
          ids: concat(action.payload.items, get(state, 'credit.ids', []))
        })
      });
    default:
      return state;
  }
};

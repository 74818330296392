import { PayloadAction } from "redux-starter-kit";
import { FETCH_CURRENTUSER_SUCCESS, State, USER_LOGIN_SUCCESS } from "../types";

function currentUser(state: State, { type, payload }: PayloadAction): State {
  switch (type) {
    case FETCH_CURRENTUSER_SUCCESS:
      return {
        ...state,
        currentUserId: payload.id,
        type: payload.type,
        email: payload.email
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: payload
      };
    default:
      return {
        ...state
      };
  }
}

export default currentUser;

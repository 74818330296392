import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faUserCircle } from "@fortawesome/free-regular-svg-icons";
import React from "react";
import { Link } from "react-router-dom";

import colors from "../../../../../theme/colors";

const Trash = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />;
};

const UserCircle = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.link} icon={faUserCircle} />;
};

interface Props {
  link: string;
  handleDelete: () => void;
}

export default ({ link, handleDelete }: Props): JSX.Element => (
  <Grid container style={{ width: "100px" }}>
    <Grid item>
      <Tooltip title="View Profile">
        <Link to={link}>
          <IconButton style={{ width: "48px", height: "48px" }}>
            <UserCircle />
          </IconButton>
        </Link>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Delete Debtor">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={(): void => handleDelete()}
        >
          <Trash />
        </IconButton>
      </Tooltip>
    </Grid>
  </Grid>
);

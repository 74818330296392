import React from "react";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt, faEdit } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

import colors from "../../../../../theme/colors";

export default ({ link, handleConnectDebtor, handleDeleteRelationship }) => (
  <Grid container style={{ width: "150px" }}>
    <Grid item>
      <Tooltip title="View Debtor Profile">
        <Link to={link}>
          <IconButton style={{ width: "48px", height: "48px" }}>
            <FontAwesomeIcon color={colors.link} icon={faEdit} />
          </IconButton>
        </Link>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Connect Debtor">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={handleConnectDebtor}
        >
          <FontAwesomeIcon color={colors.green_dark} icon={faUserPlus} />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Delete Relationship">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={handleDeleteRelationship}
        >
          <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />
        </IconButton>
      </Tooltip>
    </Grid>
  </Grid>
);

import React from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, Typography } from "@material-ui/core";
import { Formik } from "formik";
import get from "lodash/get";

import { openSnackbar } from "../../../../modules/ui";

import { Pennies } from "../../../../components/inputs/BoldInput";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../components/Dialog";
import { addPurchase } from "../../../../modules/factoring";
import Yup from "../../../../YupValidation";

function FuelAdvancePurchase({
  fundingRequest,
  handleExit,
  open,
  fuelAdvanceAmount,
  amount
}) {
  const dispatch = useDispatch();
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Make a Purchase</DialogTitle>
      <Formik
        initialValues={{
          amount: fuelAdvanceAmount
        }}
        onSubmit={async fields => {
          try {
            await dispatch(
              addPurchase(fundingRequest, {
                funding_request: addPurchase,
                amount: Math.round(fields.amount * 100),
                category: "fuel_advance"
              })
            );
            dispatch(openSnackbar("success", "Saved Fuel Advance Purchase!"));
          } catch (e) {
            if (
              get(e, "0", null) ===
              "Funding Request: Funding request already has a Fuel Advance."
            )
              return dispatch(
                openSnackbar(
                  "error",
                  "Funding request already has a Fuel Advance."
                )
              );
            if (get(e, "0", null) === "Amount: Maximum payable value exceeded")
              return dispatch(
                openSnackbar("error", "Maximum payable value exceeded.")
              );
            dispatch(
              openSnackbar("error", "Error Making Fuel Advance Purchase.")
            );
          }
          handleExit();
        }}
        validationSchema={() =>
          Yup.object().shape({
            amount: Yup.number()
              .moreThan(0, "Amount must be greater than $0")
              .lessThan(amount, "Amount must be less than invoice amount")
              .required("Amount is a required field")
          })
        }
      >
        {({
          errors,
          values,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue
        }) => (
          <>
            <DialogContent>
              <Grid>
                <Pennies
                  fullWidth
                  label="Amount"
                  name="amount"
                  value={values.amount}
                  setFieldValue={setFieldValue}
                  helperText={
                    errors.amount && touched.amount ? (
                      errors.amount
                    ) : (
                      <Typography
                        variant="caption"
                        component="span"
                        color="error"
                      >
                        {errors.amount}
                      </Typography>
                    )
                  }
                  error={errors.amount && touched.amount}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleExit}>
                Cancel
              </Button>
              <Button color="primary" autoFocus onClick={handleSubmit}>
                Submit
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
export default FuelAdvancePurchase;

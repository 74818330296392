import { isArray, mergeWith, merge } from "lodash";
import {
  CREATE_FACTORINGNOTE_REQUEST,
  CREATE_FACTORINGNOTE_SUCCESS,
  CREATE_FACTORINGNOTE_FAILURE
} from "../types";

function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export default (state, action) => {
  switch (action.type) {
    case CREATE_FACTORINGNOTE_REQUEST:
      return {
        ...state,
        loadingNotes: true
      };
    case CREATE_FACTORINGNOTE_SUCCESS:
      return {
        ...state,
        loadingNotes: false,
        notes: mergeWith(merge({}, action.payload), state.notes, customizer)
      };
    case CREATE_FACTORINGNOTE_FAILURE:
      return {
        ...state,
        loadingNotes: false
      };
    default:
      return state;
  }
};

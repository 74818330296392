import { withStyles } from "@material-ui/styles";
import {
  Paper,
  Grid,
  Tab as MuiTab,
  Tabs,
  createStyles
} from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import colors from "../../theme/colors";
import Badge from "../Badge";
import Can from "../Can";

const Tab = withStyles(
  createStyles({
    root: {
      minWidth: 0
    },
    labelContainer: {
      padding: 16
    }
  })
)(MuiTab);

interface TabProps {
  label: string;
  route: string;
  color?: string;
  badge?: string;
  permission?: string;
}

export interface Props {
  tabs?: TabProps[];
  value: number;
  additional?: JSX.Element;
  variant?: "standard" | "scrollable" | "fullWidth";
}

const DesktopTabs = ({
  tabs = [],
  value,
  additional,
  variant = "standard"
}: Props): JSX.Element => (
  <Paper square style={{ flexGrow: 1 }}>
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Tabs value={value} variant={variant}>
          {tabs.map(
            (tab, index): JSX.Element =>
              tab.permission ? (
                <Can
                  perform={tab.permission}
                  yes={() => (
                    <NavLink
                      key={tab.label}
                      to={tab.route}
                      exact
                      activeStyle={{ color: colors.link }}
                      style={{
                        textDecoration: "none",
                        color: tab.color || colors.textColor
                      }}
                    >
                      <div
                        className={value === index ? 'selected' : undefined}
                      >
                        <Tab
                          label={
                            tab.badge ? (
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                <div style={{ paddingRight: "6px" }}>
                                  {tab.label}
                                </div>
                                <Badge count={tab.badge} />
                              </div>
                            ) : (
                              tab.label
                            )
                          }
                        />
                      </div>
                    </NavLink>
                  )}
                  no={() => <div />}
                />
              ) : (
                <NavLink
                  key={tab.label}
                  to={tab.route}
                  exact
                  activeStyle={{ color: colors.link }}
                  style={{
                    textDecoration: "none",
                    color: tab.color || colors.textColor
                  }}
                >
                  <div
                    className={value === index ? 'selected' : undefined}
                  >
                    <Tab
                      label={
                        tab.badge ? (
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ paddingRight: "6px" }}>{tab.label}</div>
                            <Badge count={tab.badge} />
                          </div>
                        ) : (
                          tab.label
                        )
                      }
                    />
                  </div>
                </NavLink>
              )
          )}
        </Tabs>
      </Grid>
      {additional && (
        <Grid item>
          <div style={{ paddingRight: 16 }}>{additional}</div>
        </Grid>
      )}
    </Grid>
  </Paper>
);

// @ts-ignore
export default DesktopTabs;

import React, { useCallback, useState } from "react";
import {
  Divider,
  Grid,
  List,
  ListItem,
  Radio,
  Typography
} from "@material-ui/core";
import { Formik } from "formik";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../../../../api";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions
} from "../../../../../../../components/Dialog";
import formatAddress from "../../../../../../../helpers/format/formatAddress";
import colors from "../../../../../../../theme/colors";
import SaveButton from "../../../../../../../components/buttons/SaveButton";
import DeleteButton from "../../../../../../../components/buttons/DeleteButton";
import GreenButton from "./GreenButton";
import RemoveFactorModal from "../../../../../Payment/Profile/components/RemoveFactorModal";
import { getDialog, openDialog, openSnackbar } from "../../../../../../../modules/ui";
import Stripe from "../../../../../../../helpers/stripe";

const formatAccountBank = account => `xxxx - ${account}`;
const formatAccountDebit = account => `xxxx - xxxx - xxxx - ${account}`;

const paymentMethodsType = {
  DEBIT: "debit",
  ACH: "ach",
  WIRE: "wire",
  FACTORING: "factoring_company"
};

const initialValues = accounts => {
  if (!accounts) return;
  const initialValue = {};
  accounts.map(bank => {
    if (bank.default && bank.account_user_type !== "factoring_company")
      initialValue[`${paymentMethodsType[bank.method]}_account_id`] = bank.id;

    if (bank.default && bank.account_user_type === "factoring_company")
      initialValue.factoring_company_account_id = bank.id;
  });

  return initialValue;
};

const ListAccount = ({
  account,
  values,
  handleChange,
  handleDeleteAccount,
  accountName,
  factoringAccount = false
}) => {
  if (account.method === "CHECK") {
    return (
      <Grid item key={account.account_number_redacted}>
        <List>
          <ListItem>
            <Grid item sm={6}>
              <Typography>Pay Via Check</Typography>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container alignItems="center">
              <Grid item sm={6}>
                <Typography>Default</Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography>
                  <Radio
                    checked={
                      factoringAccount
                        ? values.factoring_company_account_id === account.id
                        : values[
                        `${paymentMethodsType[account.method]}_account_id`
                        ] === account.id
                    }
                    name={`${factoringAccount
                      ? "factoring_company"
                      : paymentMethodsType[account.method]
                      }_account_id`}
                    onChange={handleChange}
                    value={account.id}
                  />
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container alignItems="center">
              <Grid item sm={6}>
                <Typography>Type</Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography>
                  CHECK PAYMENT
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container alignItems="center">
              <Grid item sm={6}>
                <Typography>Legal Account Name</Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography>
                  {account.name}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container alignItems="center">
              <Grid item sm={6}>
                <Typography>Payment Address</Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography>
                  {formatAddress(account.mailing_address)}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
    )
  }
  const last4 = account.type === "DEBIT"
    ? formatAccountDebit(account.account_number_redacted)
    : formatAccountBank(account.account_number_redacted)
  return (
    <Grid item key={account.account_number_redacted}>
      <List>
        {accountName && (
          <ListItem>
            <Grid container alignItems="center">
              <Grid item sm={6}>
                <Typography>Factor Company Name</Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography>{accountName}</Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        <ListItem>
          <Grid container alignItems="center">
            <Grid item sm={6}>
              <Typography>Account # (Last 4 digits)</Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography>
                {account.method === 'STRIPE' ? formatAccountBank('stripe account') : last4}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container alignItems="center">
            <Grid item sm={6}>
              <Typography>Default</Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography>
                <Radio
                  checked={
                    factoringAccount
                      ? values.factoring_company_account_id === account.id
                      : values[
                      `${paymentMethodsType[account.method]}_account_id`
                      ] === account.id
                  }
                  name={`${factoringAccount
                      ? "factoring_company"
                      : paymentMethodsType[account.method]
                    }_account_id`}
                  onChange={handleChange}
                  value={account.id}
                />
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container alignItems="center">
            <Grid item sm={6}>
              <Typography>Type</Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography>
                {`${account.type} ${account.type === "DEBIT" ? "" : `(${account.method})`
                  }`}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container alignItems="center">
            <Grid item sm={6}>
              <Typography>Actions</Typography>
            </Grid>
            <Grid item sm={6}>
              <DeleteButton
                onClick={() => handleDeleteAccount(account.user, account.id)}
              />
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Grid>
  );
};

const SelectMethod = ({
  open,
  handleExit,
  selectDebitCredit,
  selectAch,
  selectWire,
  selectCheck,
  selectSwift,
  selectDebit,
  accounts,
  handleDefaultBankAccount,
  handleDeleteAccount,
  user
}) => {
  const dialog = useSelector(getDialog);
  const dispatch = useDispatch();
  const [paymentProfileId, setPaymentProfileId] = useState();
  const [factoringRelationId, setFactoringRelationId] = useState();
  const removeFactor = useCallback(() => {
    dispatch(
      openDialog(
        dialog.variant,
        dialog.title,
        dialog.message,
        dialog.data,
        "remove_factor_modal"
      )
    );
  }, [paymentProfileId, factoringRelationId]);

  const addBankAccountHandler = useCallback(async event => {
    event.preventDefault();
    try {
      const res = await api.account?.stripeAddBankAccount(user.factoring_id);
      const params = {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: {
            name: res.owner_name || res.company_name,
            email: res.email,
          },
        },
      }
      const options = {
        expand: ['payment_method'],
      }
      const { setupIntent, error } = await Stripe
        .getInstance(res.stripe_account_id)
        .collectBankAccountForSetup(res.client_secret, params, options);
      if (error) {
        // Inform your user that there was an error, check your request logs for errors
        return dispatch(openSnackbar("error", error.message));
      } else if (setupIntent.status === 'requires_payment_method') {
        // Customer canceled the hosted verification modal. Present them with other
        // payment method type options.
      } else if (setupIntent.status === 'requires_confirmation') {
        // We collected an account - possibly instantly verified, but possibly
        // manually-entered. Display payment method details and mandate text
        // to the customer and confirm the intent once they accept
        // the mandate.
        Stripe
          .getInstance(res.stripe_account_id)
          .confirmUsBankAccountSetup(setupIntent.client_secret, {
            payment_method: get(setupIntent, 'payment_method.id'),
          }
          )
          .then(({ setupIntent, error }) => {
            if (error) {
              // The payment failed for some reason.
              return dispatch(openSnackbar("error", error.message));
            } else if (setupIntent.status === "requires_payment_method") {
              console.error("requires_payment_method");
              // Confirmation failed. Attempt again with a different payment method.
            } else if (setupIntent.status === "succeeded") {
              // Confirmation succeeded! The account is now saved.
              // Display a message to customer.
              return dispatch(openSnackbar("success", "Bank account succesfully added."));
            } else if (setupIntent.next_action?.type === "verify_with_microdeposits") {
              // The account needs to be verified via microdeposits.
              // Display a message to consumer with next steps (consumer waits for
              // microdeposits, then enters a statement descriptor code on a page sent to them via email).
            }
      });
      }
    } catch (error) {
      dispatch(openSnackbar("error", error));
    }
  }, []);
  
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues(accounts)}
        onSubmit={async (values, { setSubmitting }) => {
          const data = {
            default: true
          };
          await handleDefaultBankAccount(values, data);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting, handleChange, values }) => {
          const factoringAccounts = accounts.filter(
            account => account.account_user_type === "factoring_company"
          );
          return (
            <React.Fragment>
              <DialogTitle onClose={handleExit}>
                Edit Payment Method
              </DialogTitle>
              <DialogContent>
                <Grid container direction="column" spacing={16}>
                  {/* <Grid item>
                    <Typography style={{ color: colors.link }}>
                      Same Day
                    </Typography>
                  </Grid>
                  <Grid item>
                    <GreenButton onClick={() => selectDebitCredit()}>
                      Debit / Credit
                    </GreenButton>
                  </Grid> */}
                  <Grid item>
                    <Typography style={{ color: colors.link }}>
                      1 Day (Next Day)
                    </Typography>
                  </Grid>
                  <Grid item>
                    <GreenButton onClick={() => selectAch()}>ACH</GreenButton>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: colors.link }}>
                      12 PM CT
                    </Typography>
                  </Grid>
                  <Grid item>
                    <GreenButton onClick={() => selectWire()}>Wire</GreenButton>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: colors.link }}>
                      Check Payment
                    </Typography>
                  </Grid>
                  <Grid item>
                    <GreenButton onClick={() => selectCheck()}>Check</GreenButton>
                  </Grid>
                  {user.user_type === 'broker' &&
                    <>
                      <Grid item>
                        <Typography style={{ color: colors.link }}>
                          Self Finance
                        </Typography>
                      </Grid>
                      <Grid item>
                        <GreenButton onClick={() => selectDebit()}>Debit</GreenButton>
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: colors.link }}>
                          Stripe
                        </Typography>
                      </Grid>
                      <Grid item>
                        <GreenButton onClick={addBankAccountHandler}>Stripe</GreenButton>
                      </Grid>
                    </>
                  }
                  {
                    false &&
                    <>
                      <Grid item>
                        <Typography style={{ color: colors.link }}>
                          International Payment
                        </Typography>
                      </Grid>
                      <Grid item>
                        <GreenButton onClick={() => selectSwift()}>SWIFT</GreenButton>
                      </Grid>
                      <Grid item>
                        <Divider />
                      </Grid>
                      <Grid item>
                        <Typography variant="headline">
                          Select a default payment.
                        </Typography>
                      </Grid>
                    </>

                  }
                  {accounts
                    .filter(
                      account =>
                        account.account_user_type !== "factoring_company"
                    )
                    .map(account => {
                      return (
                        <ListAccount
                          account={account}
                          values={values}
                          handleChange={handleChange}
                          handleDeleteAccount={handleDeleteAccount}
                        />
                      );
                    })}
                  {factoringAccounts.length > 0 && (
                    <Grid item>
                      <Typography variant="headline">
                        Select a default factoring company payment.
                      </Typography>
                    </Grid>
                  )}
                  {factoringAccounts.map(account => {
                    return (
                      <ListAccount
                        accountName={get(
                          account,
                          ["factoring_relation_id", "factoring_company_name"],
                          ""
                        )}
                        account={account}
                        factoringAccount={true}
                        values={values}
                        handleChange={handleChange}
                        handleDeleteAccount={() => {
                          setPaymentProfileId(
                            get(
                              account,
                              ["factoring_relation_id", "payment_profile"],
                              ""
                            )
                          );
                          setFactoringRelationId(
                            get(account, ["factoring_relation_id", "id"], "")
                          );
                          removeFactor();
                        }}
                      />
                    );
                  })}
                </Grid>
              </DialogContent>
              <DialogActions>
                <SaveButton
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                />
              </DialogActions>
              {dialog.action === "remove_factor_modal" && (
                <RemoveFactorModal
                  open={open}
                  handleExit={handleExit}
                  id={factoringRelationId}
                  paymentProfileId={paymentProfileId}
                  setFieldValue={() => { }}
                  content={
                    <Typography variant="caption">
                      Please note this will remove this factor as a payment
                      method for all brokers with an NOA active tied to this
                      payment profile.
                    </Typography>
                  }
                />
              )}
            </React.Fragment>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default SelectMethod;

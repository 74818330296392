import { assign, isEqual } from "lodash";
import {
  FETCH_RECEIVABLE_ENTRIES_FAILURE,
  FETCH_RECEIVABLE_ENTRIES_REQUEST,
  FETCH_RECEIVABLE_ENTRIES_SUCCESS
} from "../types";

const sameRequest = (state, payload) =>
  state.ordering === payload.ordering &&
  state.limit === payload.limit &&
  isEqual(state.filters, payload.filters);

export default (
  state = {
    loading: false,
    ids: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_RECEIVABLE_ENTRIES_REQUEST:
      return assign({}, state, {
        ids: [],
        loading: true,
        limit: action.payload.limit,
        filters: action.payload.filters
      });
    case FETCH_RECEIVABLE_ENTRIES_SUCCESS:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          loading: false,
          ids: action.payload.items,
          next: action.payload.next,
          previous: action.payload.previous
        });
      }
      return state;
    case FETCH_RECEIVABLE_ENTRIES_FAILURE:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          loading: false
        });
      }
      return state;
    default:
      return state;
  }
};

import { closeDialog, openSnackbar } from "../../../modules/ui";

const sendNoaBlast = () => async (dispatch, getState, { api }) => {
  const state = getState();
  const factoringClientId = state.factoringClient.id;

  try {
    await api.factoring.sendNoaBlast(factoringClientId);
    dispatch(openSnackbar("success", "NOA Blast has been sent"));
  } catch (err) {
    dispatch(
      openSnackbar(
        "error",
        "NOA Blast ERROR. Are you sure an NOA is attached to this profile?"
      )
    );
  }
  dispatch(closeDialog());
};

export default sendNoaBlast;

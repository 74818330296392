import React, { MouseEventHandler, ReactElement } from "react";
import { Button } from "@material-ui/core";

interface Props {
  onClick: MouseEventHandler;
  disabled: boolean;
}

const SaveButton = ({ onClick, disabled }: Props): ReactElement => (
  <Button
    variant="contained"
    color="primary"
    onClick={onClick}
    disabled={disabled}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "centered",
        color: "white"
      }}
    >
      Save Updates
    </div>
  </Button>
);

export default SaveButton;

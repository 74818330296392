import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  UPDATE_LINEITEM_FAILURE,
  UPDATE_LINEITEM_REQUEST,
  UPDATE_LINEITEM_SUCCESS
} from "../types";

export default fundingRequest => lineItem => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPDATE_LINEITEM_REQUEST)());
    const item = await api.factoring.updateLineItem(fundingRequest, lineItem);
    const data = normalize(item, schema.lineItem);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(UPDATE_LINEITEM_SUCCESS)({
        fundingRequest,
        lineItem: data.result
      })
    );
    return item;
  } catch (err) {
    dispatch(createAction(UPDATE_LINEITEM_FAILURE)(err));
    throw err;
  }
};

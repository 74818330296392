import { connect } from "react-redux";
import { findIndex, get } from "lodash";
import { compose, lifecycle } from "recompose";
import qs from "qs";
import {
  actionsPaymentTable,
  fetchPayments,
  getPayments,
  selectorsPaymentsTable,
  createPaymentsCSV,
  deletePayment
} from "../../../../modules/finances";
import {
  getDialog,
  openDialog,
  openSnackbar,
  closeDialog
} from "../../../../modules/ui";
import API from "../../../../api";
import Payments from "./Payments";
import tabs from "../tabs";

const mapStateToProps = (state, { location: { pathname } }) => ({
  tabs,
  dialog: getDialog(state),
  isLoading: selectorsPaymentsTable.getIsLoading(state),
  sortDirection: selectorsPaymentsTable.getSortDirection(state),
  sortBy: selectorsPaymentsTable.getSortBy(state),
  page: selectorsPaymentsTable.getPage(state),
  count: selectorsPaymentsTable.getCount(state),
  rowsPerPage: selectorsPaymentsTable.getRowsPerPage(state),
  rowsPerPageOptions: selectorsPaymentsTable.getRowsPerPageOptions(state),
  filters: selectorsPaymentsTable.getFilters(state),
  payments: getPayments(state),
  statusIndex: findIndex(tabs, tab => pathname === tab.route) || 0
});

const mapDispatchToProps = {
  fetchPayments,
  handleSort: actionsPaymentTable.handleSort,
  handleChangePage: actionsPaymentTable.handleChangePage,
  handleChangeRowsPerPage: actionsPaymentTable.handleChangeRowsPerPage,
  handleSearchFilter: actionsPaymentTable.handleSearchFilter,
  openDialog,
  closeDialog,
  openSnackbar,
  createPaymentsCSV,
  deletePayment
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openDetails(id) {
    dispatchProps.openDialog("finance-payment-details", "", "", { id });
  },
  openConfirmDeletePayment(amount, debtor, paymentId) {
    dispatchProps.openDialog(
      "",
      "Confirm Delete Payment",
      `Are you sure you want to delete this payment of ${amount} from this debtor ${debtor}?`,
      { paymentId },
      "confirm-delete-payment"
    );
  },
  callDeletePayment(paymentId) {
    dispatchProps.deletePayment(paymentId);
  },
  closeConfirmDeletePayment() {
    dispatchProps.closeDialog();
  },
  handleCSV: async (params = {}) => {
    try {
      await dispatchProps.createPaymentsCSV(stateProps.filters, params);
    } catch (e) {
      dispatchProps.openSnackbar("error", "Error while exporting data.");
    }
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  lifecycle({
    componentDidMount() {
      const {
        history: { location },
        fetchPayments: queryPayments
      } = this.props;
      const query = qs.parse(get(location, "search"), {
        ignoreQueryPrefix: true
      });
      queryPayments(20, "-created", query);
    }
  })
)(Payments);

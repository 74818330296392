import React, { ReactNode } from "react";
import { FormControl, Input, InputLabel, WithStyles } from "@material-ui/core";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { InputLabelClassKey } from "@material-ui/core/InputLabel";
import { InputClassKey, InputProps } from "@material-ui/core/Input";
import styles from "../CustomTextField/styles";

interface Props extends WithStyles<typeof styles> {
  children?: ReactNode;
  id: string;
  label: string;
  inputClasses: Partial<Record<InputClassKey, string>>;
  labelClasses: Partial<Record<InputLabelClassKey, string>>;
}

const CustomInputField = (props: Props & InputProps): JSX.Element => {
  const { classes, children, id, label, inputClasses, labelClasses } = props;
  const underlineClasses = classNames({
    [classes.underline]: true
  });
  return (
    <FormControl fullWidth>
      <InputLabel
        classes={{
          focused: classes.cssFocused,
          error: classes.errorLabel,
          ...labelClasses
        }}
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <Input
        {...props}
        classes={{
          underline: underlineClasses,
          input: classes.input,
          error: classes.errorInput,
          ...inputClasses
        }}
      >
        {children}
      </Input>
    </FormControl>
  );
};

export default withStyles(styles)(CustomInputField);

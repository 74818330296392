import {
  createStyles,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import classNames from 'classnames';
import { Close as CloseIcon } from "@material-ui/icons";
import React, { MouseEventHandler, ReactNode } from "react";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import colors from "../../theme/colors";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing.unit,
    },
    rootWithBorder: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.grey[500]
    }
  });

interface Props extends WithStyles<typeof styles> {
  children: ReactNode;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  withBorder?: boolean;
}

const DialogTitle = withStyles(styles)(
  ({ children, classes, onClose, withBorder }: Props): JSX.Element => (
    <MuiDialogTitle disableTypography className={classNames(classes.root, {
      [classes.rootWithBorder]: withBorder,
    })}>
      <Typography variant="h6">
        <div style={{ color: colors.orange }}>{children}</div>
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
);

export default DialogTitle;

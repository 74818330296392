import { get, memoize } from "lodash";
import { denormalize } from "normalizr";

import { paymentBillingNotes } from "../../../api/schema";

export default factoringId =>
  memoize(state => {
    try {
      const BillingNotes = get(
        state,
        `payment.billingNotes.${factoringId}`,
        []
      );
      const denormalized = denormalize(
        { BillingNotes },
        { BillingNotes: [paymentBillingNotes] },
        state.entities
      );
      return denormalized.BillingNotes || [];
    } catch (err) {
      return [];
    }
  });

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import fetchAll from "../../../helpers/fetchAll";

import {
  FETCH_ATTACHMENTS_REQUEST,
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_ATTACHMENTS_FAILURE
} from "../types";

const fetchAttachments = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(
      createAction(FETCH_ATTACHMENTS_REQUEST)({
        id
      })
    );
    const response = await fetchAll(() =>
      api.groups.getPaymentProfileAttachments(id)
    );
    const data = normalize(response, [schema.attachment]);
    dispatch(addEntities(data.entities));
    dispatch(createAction(FETCH_ATTACHMENTS_SUCCESS)({ [id]: data.result }));
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_ATTACHMENTS_FAILURE)(err));
    throw err;
  }
};

export default fetchAttachments;

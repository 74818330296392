import React from "react";
import { FieldArray } from "formik";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { find, get } from "lodash";

import Colors from "../../theme/colors";
import splitAndCase from "../../helpers/format/splitAndCase";
import FilePreview from "./FilePreview";
import FileItem from "./FileItem";
import openFileDialog from "./openFileDialog";

import { Attachment, Category, File } from "./types";

interface Props {
  uploadBtnRef: HTMLInputElement | null;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  values: {
    attachments: Attachment[];
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  files: File[];
  categories: Category[];
  handleDelete: (id: string) => void;
  filterCategories?: string[];
  multipleCategories: boolean;
  openDialog: any;
  closeDialog: any;
  dialog: any;
  requestID?: string;
  updateDisplayName?: boolean;
  broker?: boolean;
  source?: boolean;
  // eslint-disable-next-line react/require-default-props
  defaultCategoryValue?: string;
}

const DocumentsView = ({
  uploadBtnRef,
  setFieldTouched,
  values,
  setFieldValue,
  files,
  categories,
  handleDelete,
  filterCategories,
  multipleCategories,
  openDialog,
  requestID,
  updateDisplayName,
  broker = false,
  source = false,
  defaultCategoryValue
}: Props): JSX.Element => (
  <React.Fragment>
    {files
      .filter((attachment): any => attachment.category !== "logo")
      .map(
        (file, index): JSX.Element => {
          return (
            <div style={{ paddingTop: 16, paddingBottom: 16, padding: 8 }}>
              <FileItem
                uploaded={file.uploaded}
                uploadedBy={file.uploaded_by_user_type}
                source={source}
                broker={broker}
                requestID={requestID}
                updateDisplayName={updateDisplayName}
                display_name={file.display_name}
                id={file.id}
                filename={file.filename}
                category={
                  get(
                    find(
                      categories,
                      ({ key }): boolean => file.category === key
                    ),
                    "name"
                  ) || splitAndCase(file.category)
                }
                preview_url={file.preview_url}
                download_url={file.download_url}
                is_new={file.is_new || file.after_issue}
                visible_to={file.visible_to}
                handleDelete={(): void => handleDelete(file.id)}
                openEditToolDialog={(url: string) => {
                  return openDialog("picture-edit-tool", "", "", {
                    file: url,
                    save: (file: File) =>
                      setFieldValue(
                        `attachments.[${values.attachments.length}].file`,
                        file
                      )
                  });
                }}
              />
            </div>
          );
        }
      )}
    <FieldArray
      name="attachments"
      render={(arrayHelpers): JSX.Element => (
        <React.Fragment>
          {values.attachments
            .filter((attachment): any => attachment.category !== "logo")
            .map(
              (attachment, index, attachments): JSX.Element => (
                <FilePreview
                  {...attachment}
                  index={index}
                  categories={categories}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  values={attachments}
                  arrayHelpers={arrayHelpers}
                  files={files}
                  multipleCategories={multipleCategories}
                  defaultCategoryValue={defaultCategoryValue}
                />
              )
            )}
          <Grid
            container
            item
            xs={12}
            justify="flex-end"
            id="attachments-added"
          >
            {filterCategories &&
              filterCategories.length === values.attachments.length && (
                <Typography
                  color="error"
                  style={{ margin: 3 }}
                  variant="caption"
                >
                  You've reached max numbers of file to upload
                </Typography>
              )}
            <IconButton
              disabled={
                filterCategories &&
                filterCategories.length === values.attachments.length
              }
              onClick={(): void => openFileDialog(uploadBtnRef)}
            >
              <FontAwesomeIcon icon={faPlusCircle} color={Colors.grayText} />
            </IconButton>
            <input
              type="file"
              name="attachments"
              ref={(ref): void => {
                uploadBtnRef = ref;
              }}
              style={{ display: "none" }}
              onBlur={(): void => setFieldTouched("attachments", true)}
              onChange={(e): void => {
                const index = values.attachments.length;
                if (
                  filterCategories &&
                  filterCategories.length === values.attachments.length
                )
                  return;

                if (e.currentTarget && e.currentTarget.files) {
                  if (
                    e.currentTarget.files[0].type.match(
                      "image.*|application/pdf"
                    ) === null
                  ) {
                    openDialog("file-type-confirmation");
                  }
                  if (e.currentTarget.files[0].type.match("image.*")) {
                    openDialog("picture-edit-tool", "", "", {
                      file: e.currentTarget.files[0],
                      save: (file: File) =>
                        setFieldValue(`attachments.[${index}].file`, file)
                    });
                  }
                  setFieldValue(
                    `attachments.[${index}].file`,
                    e.currentTarget.files[0]
                  );
                }
                e.currentTarget.value = "";
              }}
            />
          </Grid>
        </React.Fragment>
      )}
    />
  </React.Fragment>
);

export default DocumentsView;

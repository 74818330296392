import { CardHeader, createStyles, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import Card from "../../../../components/Card";
import formatPennies from "../../../../helpers/format/formatPennies";
import colors from "../../../../theme/colors";

const styles = createStyles({
  positive: {
    color: colors.green
  },
  negative: {
    color: colors.danger
  }
});

interface Props extends WithStyles<typeof styles> {
  amount: string;
}

const ActiveReserveCard = ({ classes, amount }: Props): JSX.Element => {
  const amountNumber = Number(amount);
  return (
    <Card color="#e3e3e3">
      <CardHeader
        classes={{
          title: amountNumber >= 0 ? classes.positive : classes.negative,
          subheader: amountNumber >= 0 ? classes.positive : classes.negative
        }}
        title="Active Reserve"
        subheader={formatPennies(amountNumber)}
      />
    </Card>
  );
}

export default withStyles(styles)(ActiveReserveCard);

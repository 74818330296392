import { isArray, merge, mergeWith } from "lodash";
import {
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_REQUEST,
  CREATE_NOTE_SUCCESS
} from "../types";

function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export default (state, action) => {
  switch (action.type) {
    case CREATE_NOTE_REQUEST:
      return {
        ...state,
        loadingFactorCompanyNotes: true
      };
    case CREATE_NOTE_SUCCESS:
      return {
        ...state,
        loadingFactorCompanyNotes: false,
        factorCompanyNotes: mergeWith(
          merge({}, action.payload),
          state.factorCompanyNotes,
          customizer
        )
      };
    case CREATE_NOTE_FAILURE:
      return {
        ...state,
        loadingFactorCompanyNotes: false
      };
    default:
      return state;
  }
};

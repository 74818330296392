import { assign } from "lodash";
import {
  FETCH_PLAIDUSER_FAILURE,
  FETCH_PLAIDUSER_SUCCESS,
  FETCH_PLAIDUSER_REQUEST
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_PLAIDUSER_REQUEST:
      return {
        ...state,
        loadingPlaidUser: true
      };
    case FETCH_PLAIDUSER_SUCCESS:
      return {
        ...state,
        loadingPlaidUser: false,
        plaidUsers: assign({}, state.plaidUsers, action.payload)
      };
    case FETCH_PLAIDUSER_FAILURE:
      return {
        ...state,
        loadingPlaidUser: false
      };
    default:
      return state;
  }
};

import { createAction } from "redux-starter-kit";
import {
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_REQUEST,
  DELETE_NOTE_SUCCESS
} from "../types";

const deleteDebtorNote = (debtorId, noteId) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(DELETE_NOTE_REQUEST)());
    await api.debtors.deleteUserNotes(noteId);
    dispatch(
      createAction(DELETE_NOTE_SUCCESS)({
        debtorId,
        note: noteId
      })
    );
  } catch (err) {
    dispatch(createAction(DELETE_NOTE_FAILURE)(err));
    throw err;
  }
};

export default deleteDebtorNote;

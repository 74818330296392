import {
  createStyles,
  ExpansionPanelDetails,
  Grid,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import React, { ChangeEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import RCSlider from "rc-slider";
import CustomExpansionPanel from "../../../../components/ExpansionPanel";
import ExpansionPanelSummary from "../../../../components/ExpansionPanelSummary";

const styles = createStyles({
  root: {
    width: "100%"
  }
});

interface NewEvent {
  target: {
    name: string;
    value: string;
  };
}

interface Props extends WithStyles<typeof styles> {
  handleSearchFilter: (event: NewEvent | NewEvent[]) => void;
  expanded?: boolean;
}

const marks = {
  0: <Typography>0+</Typography>,
  15: <Typography>15+</Typography>,
  30: <Typography>30+</Typography>,
  40: <Typography>40+</Typography>,
  50: <Typography>50+</Typography>,
  60: <Typography>60+</Typography>,
  90: <Typography>90+</Typography>,
  100: <Typography>∞</Typography>
};

const Filters = withStyles(styles)(
  ({ classes, handleSearchFilter, expanded }: Props): JSX.Element => {
    return (
      <div className={classes.root}>
        <CustomExpansionPanel expanded={expanded}>
          <ExpansionPanelSummary
            expandIcon={<FontAwesomeIcon size="xs" icon={faSlidersH} />}
          />
          <ExpansionPanelDetails>
            <Grid container spacing={32} direction="row">
              <Grid item lg={6}>
                <Typography style={{ paddingBottom: 20 }}>Aging</Typography>
                <RCSlider.Range
                  step={null}
                  min={0}
                  max={100}
                  marks={marks}
                  defaultValue={[0, 100]}
                  onAfterChange={(value: any): void => {
                    if (value[1] === value[0]) {
                      return handleSearchFilter({
                        target: {
                          name: "receivable_age_gte",
                          value: value[1] === 100 ? 10000 : value[1]
                        }
                      });
                    }
                    const agingGte = {
                      target: {
                        name: "receivable_age_lte",
                        value: value[1] === 100 ? 10000 : value[1]
                      }
                    };
                    const agingLte = {
                      target: {
                        name: "receivable_age_gte",
                        value: value[0]
                      }
                    };
                    handleSearchFilter([agingLte, agingGte]);
                  }}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </CustomExpansionPanel>
      </div>
    );
  }
);

export default Filters;

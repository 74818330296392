import { createAction } from "redux-starter-kit";
import { FETCH_PURCHASES_ITEMS_SUCCESS } from "../types";

export const clearPurchaseItems = id => dispatch => {
  dispatch(
    createAction(FETCH_PURCHASES_ITEMS_SUCCESS)({
      id,
      items: []
    })
  );
};

export default clearPurchaseItems;

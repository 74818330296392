import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import { isEmpty, get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions
} from "../../../../../../../components/Dialog";
import Upload from "./Upload";
import FundingRequest from "./FundingRequest";
import steps from "./steps";
import {
  getAccounts,
  getUserId,
  fetchAccounts,
  getFactorClient
} from "../../../../../../../modules/factoringClient";
import ImageCropper from "../../../../../../../components/ImageCropper";
import { getDialog, openDialog } from "../../../../../../../modules/ui";

const validateAttachments = attachments => {
  const tonu = attachments.filter(attachment =>
    get(attachment, "category", "").includes("Truck Order Not Used")
  );
  const rateConfirmation = attachments.filter(attachment =>
    get(attachment, "category", "").includes("Rate Confirmation")
  );

  const bolDelivered = attachments.filter(attachment =>
    get(attachment, "category", "").includes(
      "Signed Bill of Lading (Delivered)"
    )
  );
  const bolPickUp = attachments.filter(attachment =>
    get(attachment, "category", "").includes("Signed Bill of Lading (Pick-Up)")
  );

  return (
    tonu.length > 0 ||
    (rateConfirmation.length > 0 && bolDelivered.length > 0) ||
    bolPickUp.length > 0
  );
};

const validateAttachmentsMessage = attachments => {
  if (!validateAttachments(attachments)) {
    return (
      <div>
        {"Please make sure to add the following document:"}
        <br />
        {"- Rate confirmation"}
        <br />
        {"- Signed Bill of Lading (Delivered)"}
        <br />
        {"Or"}
        <br />
        {"- Signed Bill of Lading (Pick-Up)"}
      </div>
    );
  }
};

function FundingRequestDialog({ factoringId, form, open, handleExit }) {
  const [touchedNext, setTouchedNext] = useState(false);
  const userId = useSelector(getUserId);
  const accounts = useSelector(getAccounts(userId));
  const dispatch = useDispatch();
  const factorClient = useSelector(getFactorClient);

  const generateLoadNumber = get(
    factorClient,
    "auto_generate_load_numbers",
    false
  );

  useEffect(() => {
    const f = async () => {
      dispatch(fetchAccounts(userId));
    };
    f();
  }, [userId]);

  const dialog = useSelector(getDialog);

  return (
    <React.Fragment>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <Formik {...form}>
          {({
            values,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit,
            setStatus,
            errors,
            touched,
            status,
            validateForm,
            setTouched,
            isSubmitting
          }) => (
            <React.Fragment>
              <DialogTitle onClose={() => handleExit()}>
                New Funding Request
              </DialogTitle>
              <DialogContent>
                {status === "request" && (
                  <FundingRequest
                    steps={steps}
                    status={status}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    errors={errors}
                    touched={touched}
                    factoringId={factoringId}
                    accounts={accounts}
                    generateLoadNumbers={generateLoadNumber}
                  />
                )}
                {status === "upload" && (
                  <Upload
                    steps={steps}
                    status={status}
                    files={values.attachments}
                    setFiles={attachments => {
                      setFieldValue("attachments", attachments);

                      const index = attachments.length - 1;

                      if (attachments[index].category === "") {
                        dispatch(
                          openDialog(
                            "factor-client-create-funding-request",
                            "",
                            "",
                            {
                              file: attachments[index],
                              save: file => {
                                const f = {
                                  ...file,
                                  filename: file.name
                                };
                                const attachment = {
                                  ...attachments[index],
                                  file: f
                                };
                                setFieldValue(
                                  `attachments.[${index}]`,
                                  attachment
                                );
                              }
                            },
                            "picture-edit-tool"
                          )
                        );
                      }
                    }}
                    message={
                      values.attachments.length === 0 || !touchedNext
                        ? ""
                        : validateAttachmentsMessage(values.attachments)
                    }
                  />
                )}
                {status === "summary" && (
                  <FundingRequest
                    disabled
                    steps={steps}
                    status={status}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                    errors={errors}
                    factoringId={factoringId}
                    accounts={accounts}
                    generateLoadNumbers={generateLoadNumber}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    isSubmitting ||
                    (status === "upload" && values.attachments.length === 0) ||
                    (status === "request" &&
                      get(values, ["debtor", "credit_approved"], "") ===
                        "declined" &&
                      !values.nonFactored)
                  }
                  onClick={() => {
                    switch (status) {
                      case "request":
                        validateForm().then(validation => {
                          if (isEmpty(validation)) {
                            setStatus("upload");
                          } else {
                            setTouched({
                              amount: true,
                              billToCompany: true,
                              loadLength: true,
                              loadNumber: true,
                              firstPickUp: true,
                              finalDelivery: true,
                              equipmentType: true,
                              wire: true
                            });
                          }
                        });
                        break;
                      case "upload":
                        if (validateAttachments(values.attachments)) {
                          setStatus("summary");
                        } else {
                          setTouchedNext(true);
                        }
                        break;
                      case "summary":
                        handleSubmit();
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  {status === "summary" ? "Submit" : "Next"}
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </Dialog>
      {dialog.action === "picture-edit-tool" && (
        <ImageCropper
          open={dialog.show}
          handleExit={() => {
            dispatch(openDialog(dialog.variant, "", "", {}, ""));
          }}
          data={dialog.data}
        />
      )}
    </React.Fragment>
  );
}

export default FundingRequestDialog;

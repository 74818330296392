import { assign } from "lodash";
import {
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_REQUEST,
  DELETE_NOTE_SUCCESS
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case DELETE_NOTE_REQUEST:
      return {
        ...state,
        loadingDebtorNotes: true
      };
    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        loadingDebtorNotes: false,
        debtorNotes: assign({}, state.debtorNotes, {
          [action.payload.debtorId]: state.debtorNotes[
            action.payload.debtorId
          ].filter(note => {
            return note !== action.payload.note;
          })
        })
      };
    case DELETE_NOTE_FAILURE:
      return {
        ...state,
        loadingDebtorNotes: false
      };
    default:
      return state;
  }
};

const needsReviewCount = () => async (dispatch, getState, { api }) => {
  const response = await api.groups.queryInvitedCarriers(
    undefined,
    undefined,
    undefined,
    {
      status: "needs_review"
    }
  );
  return response.count;
};

export default needsReviewCount;

import React from "react";
import { createStyles, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import moment from "moment";
import { DateTime } from "luxon";

import colors from "../../theme/colors";

const styles = createStyles({
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottom: `2px solid ${colors.borderInputDisableColor}`
    },
    "&:after": {
      borderBottom: `2px solid ${colors.borderInputColor}`
    }
  },
  cssFocused: {
    color: `${colors.borderInputColor} !important`
  },
  errorLabel: {
    color: `${colors.inputLabelErrorColor} !important`
  },
  input: {
    color: `${colors.textColor}`,
    fontFamily: "Avenir",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1.5,
    opacity: 0.9
  }
});
const DatePicker = ({
  classes,
  onBlur,
  style,
  value = "",
  name,
  fullWidth,
  label,
  onChange,
  ...props
}) => {
  const underlineClasses = classNames({
    [classes.underline]: true
  });
  return (
    <TextField
      name={name}
      value={
        value
          ? moment(value)
              .format("YYYY-MM-DD")
              .toString()
          : ""
      }
      label={label}
      fullWidth={fullWidth}
      style={style}
      onBlur={onBlur}
      onChange={event =>
        onChange(DateTime.fromString(event.currentTarget.value, "yyyy-MM-dd"))
      }
      type="date"
      InputLabelProps={{
        classes: {
          focused: classes.cssFocused,
          error: classes.errorLabel
        }
      }}
      InputProps={{
        classes: {
          underline: underlineClasses,
          input: classes.input
        }
      }}
    />
  );
};

export default withStyles(styles)(DatePicker);

import { has } from "lodash";

import updateUser from "./updateUser";
import uploadFile from "./uploadFiles";
import { openModal } from "../../modal";
import { ERROR, SUCCESS } from "../../../helpers/Modals";

const updateUserProfile = profile => async (dispatch, getState, { api }) => {
  try {
    let formGroups = profile.groups;
    if (profile.dispatching_interest) {
      if (!formGroups.find(group => group.name === "Admin Dispatch")) {
        formGroups.push({
          name: "Admin Dispatch",
          roles: [{ role: "dispatch_carrier" }]
        });
      }
    } else {
      formGroups = profile.groups.filter(
        group => group.name !== "Admin Dispatch"
      );
    }
    const address = has(profile.address, "values")
      ? profile.address.values
      : profile.address;
    const data = {
      ...profile,
      cargo_insurance_value: Math.round(profile.cargo_insurance_value * 100),
      factoring: {
        factoring_approved: profile.factoring_approved
      },
      contact_phone: `${profile.contact_phone};${profile.phone_ext}`,
      fax_number: `${profile.fax_number};${profile.fax_ext}`,
      credit_approved:
        typeof profile.credit_approved === "object"
          ? profile.credit_approved.value
          : profile.credit_approved,
      address: address.street_one || "",
      city: address.city || "",
      state: address.state || "",
      zip: address.zip || "",
      country: address.country || "",
      assigned_admin:
        profile.assigned_admin === " " ? null : profile.assigned_admin,
      groups: formGroups
    };
    if (data.password === "") {
      delete data.password;
    }
    if (data.cargo_insurance_provider === "") {
      delete data.cargo_insurance_provider;
    }
    if (profile.attachments.length) {
      await Promise.all(
        profile.attachments.map(attachment =>
          dispatch(
            uploadFile({
              user: profile.id,
              ...attachment
            })
          )
        )
      );
    }
    await dispatch(updateUser({ userId: profile.id, ...data }));
    dispatch(openModal(SUCCESS, `User has been updated successfully.`));
  } catch (err) {
    openModal(ERROR, `Error while updating user. ${err}`);
  }
};

export default updateUserProfile;

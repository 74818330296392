import {
  List,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce"
import API from "../../../../api";
import equipmentTypes from "../../../../helpers/equipmentTypes";
import Card from "../../../../components/Card";
import ListItem from "../../../../components/ListItem";
import BoldInput, {
  Pennies,
  Select
} from "../../../../components/inputs/BoldInput";
import AutoCompleteAddress from "../../../../components/inputs/AutoCompleteAddress";
import ProfileSection from "../../../../components/ProfileSection";
import BackgroundSpinner from "../../../../components/BackgroundSpinner";



const styles = (theme: Theme) => ({
  bootstrapInput: {
    borderRadius: 4,
    position: "relative" as "relative",
    backgroundColor: "rgba(65, 117, 5, 0.3) !important",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "4px 4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    },
    "&:disabled": {
      backgroundColor: "rgba(65, 117, 5, 0.3) !important",
      color: "black"
    }
  }
});

interface Selection {
  text: string;
  value: string;
}

export interface Account {
  id: string;
  method: string;
  account_number_redacted: string;
}

interface Props {
  invoiceNumber: string;
  loadNumber: string;
  clientInvoiceNumber: string;
  firstPickUpAddress: string;
  finalDeliveryAddress: string;
  equipmentType: string;
  loadLength: string;
  handleChange: Function;
  handleBlur: Function;
  errors: any;
  accounts: Account[];
  invoiceAmount: number;
  debtorInvoiceAmount: number;
  amountFunded: number;
  clientInvoiceAmount: number;
  unPaidPurchaseAmount: number;
  paymentMethod: string;
  setFieldValue: Function;
  setFieldTouched: Function;
  paymentProfileId?: string;
  carrierPayAmount: number;
  brokerPayAmount: number;
  brokerDiscountAmount: number;
  carrierDiscountAmount: number;
  fuelAdvanceAmount?: number;
  fuelAdvanceRate?: string;
  fuelAdvanceFee?: number;
  status: string;
  classes: any;
  commodity: string;
  freightClass: string;
  commodities: any[];
  freightClasses: any[];
  brokerGrossPayAmount: number;
  carrierPurchaseAmount: number;
  carrierFundedAmount: number;
  brokerFundedAmount: number;
  reserveHoldback: number;
  carrierProNumber: string;
  paymentProfileAccounts: Account[];
  PaymentProfilePaymentMethod: Account;
  fuelAdvancePaymentMethod: Account;
  factoringCompany: {};
  paymentProfile: {};
  poNumber: string;
  editableInvoiceAmount: boolean;
  carrierPaymentMethod: string;
  PaymentProfilePaymentMethodType: string;
  fundingRequestId: string;
  factoringClientId: string;
  values: any;
}

function InvoiceInfoCard({
  invoiceNumber,
  loadNumber,
  clientInvoiceNumber,
  firstPickUpAddress,
  finalDeliveryAddress,
  equipmentType,
  loadLength,
  handleChange,
  handleBlur,
  errors,
  accounts = [],
  invoiceAmount,
  debtorInvoiceAmount,
  amountFunded,
  clientInvoiceAmount,
  unPaidPurchaseAmount,
  paymentMethod,
  setFieldValue,
  paymentProfileId,
  carrierPayAmount,
  freightClass,
  commodity,
  brokerPayAmount,
  brokerDiscountAmount,
  carrierDiscountAmount,
  setFieldTouched,
  fuelAdvanceAmount,
  fuelAdvanceRate,
  fuelAdvanceFee,
  status,
  classes,
  commodities,
  freightClasses,
  brokerGrossPayAmount,
  carrierPurchaseAmount,
  carrierFundedAmount,
  brokerFundedAmount,
  reserveHoldback,
  carrierProNumber,
  paymentProfileAccounts,
  PaymentProfilePaymentMethod,
  fuelAdvancePaymentMethod,
  factoringCompany,
  paymentProfile,
  poNumber,
  editableInvoiceAmount,
  carrierPaymentMethod,
  PaymentProfilePaymentMethodType,
  fundingRequestId,
  factoringClientId,
  values,
}: Props & WithStyles<typeof styles>): JSX.Element {
  const [loading, setLoading] = useState(false);

  const mappingData: any = {
    factoring_fee_split_rate: {
      name: "factoringFeeSplitRate",
      type: "number"
    },
    invoice_amount: {
      name: ["invoiceAmount", "debtorInvoiceAmount"],
      type: "pennies"
    },
    payment_profile_invoice_amount: {
      name: "paymentProfileInvoiceAmount",
      type: "pennies"
    },
    payment_profile_discount_rate: {
      name: "paymentProfileDiscountRate",
      type: "number"
    },
    factoring_amount: {
      name: "brokerGrossPayAmount",
      type: "pennies"
    },
    reserve_amount: {
      name: "reserveHoldback",
      type: "pennies"
    },
    discount_rate_comfreight: {
      name: "rate",
      type: "number"
    },
    payment_profile_payable: {
      name: "carrierPurchaseAmount",
      type: "pennies"
    },
    factoring_payable: {
      name: "brokerPayAmount",
      type: "pennies"
    },
    factoring_fee: {
      name: "brokerDiscountAmount",
      type: "pennies"
    },
    payment_profile_fee: {
      name: "carrierDiscountAmount",
      type: "pennies"
    }
  };

  const fundingRequestCalculation = useCallback(async (data: any) => {
    try {
      const res = await API.factoring.fundingRequestCalculations(data);
      Object.keys(mappingData).map((key, index) => {
        if (res[key] || res[key] === 0) {
          const value =
            mappingData[key].type === "pennies"
              ? (res[key] || 0) / 100
              : res[key];
          if (Array.isArray(mappingData[key].name)) {
            return mappingData[key].name.map((name: string) => {
              console.log(key, value)
              setFieldValue("Array", name, value);
            });
          }
          return setFieldValue(mappingData[key].name, value);
        }
      });
    } catch (e) { }
  }, []);

  const getCalculation = async (fields: any): Promise<any> => {
    setLoading(true);
    try {
      const data = {
        funding_request_id: fundingRequestId,
        invoice_amount: fields.invoiceAmount * 100,
        debtor: fields.debtor.id,
        factored: fields.factored,
        factoring: factoringClientId,
        discount_rate_comfreight: fields.discountRate,
        apply_reserve_holdback: fields.applyReserveHoldback,
        contract_type: fields.contractType,
        create_rates_revision: false,
        ...(() =>
          fields.factored
            ? {
              payment_profile_invoice_amount: fields.carrierPayAmount * 100,
              payment_speed: parseInt(
                (fields.payoutDays.match(/\d+/g) || [])[0],
                0
              ),
              payment_profile: fields.paymentProfile.id,
              factoring_fee_split_rate: fields.factoringFeeSplitRate
            }
            : {})()
      };
      await fundingRequestCalculation(data);
    } catch (err) {
    }
    setLoading(false);
  }

   const debouncedCalculation = useCallback(debounce(getCalculation, 300), []);

  const approved = status === "approved";
  return (
    <Card>
      {loading && <BackgroundSpinner />}
      <List dense style={{ width: "100%" }}>
        <ListItem>
          <ProfileSection>{"Invoices & Payment Info"}</ProfileSection>
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            disabled
            name="invoiceNumber"
            label="Invoice #:"
            value={invoiceNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.invoiceNumber}
            helperText={errors.invoiceNumber}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="loadNumber"
            label="Load #:"
            value={loadNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.loadNumber}
            helperText={errors.loadNumber}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="carrierProNumber"
            label="Carrier Pro #:"
            value={carrierProNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.carrierProNumber}
            helperText={errors.carrierProNumber}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="poNumber"
            label="PO #:"
            value={poNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.poNumber}
            helperText={errors.poNumber}
          />
        </ListItem>
        {!paymentProfileId && (
          <ListItem>
            <BoldInput
              fullWidth
              name="clientInvoiceNumber"
              label="Client Invoice #:"
              value={clientInvoiceNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.clientInvoiceNumber}
              helperText={errors.clientInvoiceNumber}
            />
          </ListItem>
        )}
        <ListItem>
          <Pennies
            fullWidth
            label="Invoice Amount:"
            value={invoiceAmount}
            name="invoiceAmount"
            onBlur={async (event: any) => {
              setFieldTouched('invoiceAmount', true, false);
              await debouncedCalculation({ ...values, invoiceAmount: event });
            }}
            setFieldValue={setFieldValue}
            disabled={approved && !editableInvoiceAmount}
          />
        </ListItem>
        <ListItem>
          <Pennies
            fullWidth
            disabled
            label="Debtor Invoiced:"
            value={debtorInvoiceAmount}
            name="debtorInvoiceAmount"
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
          />
        </ListItem>
        {paymentProfileId ? (
          <React.Fragment>
            <ListItem>
              <Pennies
                fullWidth
                disabled={![
                    'pending_delivery',
                    'document_issue',
                    'incomplete',
                    'pending',
                    'pending_originals',
                    'remote_approved',
                    'special_pending',
                ].includes(status)}
                label="Carrier Invoice:"
                value={carrierPayAmount}
                name="carrierPayAmount"
                setFieldValue={setFieldValue}
                onBlur={async (event: any) => {
                  setFieldTouched('carrierPayAmount', true, false);
                  await debouncedCalculation({ ...values, payment_profile_invoice_amount: event });
                }}
              />
            </ListItem>
            <ListItem>
              <Pennies
                fullWidth
                disabled
                label="Carrier Pay:"
                value={carrierPurchaseAmount}
                name="carrierPurchaseAmount"
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
            <ListItem>
              <Pennies
                fullWidth
                disabled
                label="Broker Pay:"
                value={brokerPayAmount}
                name="brokerPayAmount"
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
            <ListItem>
              <Pennies
                fullWidth
                disabled
                label="Broker Gross Margin:"
                value={brokerGrossPayAmount}
                name="brokerGrossPayAmount"
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
            <ListItem>
              <Pennies
                fullWidth
                disabled
                label="Carrier Amount Funded:"
                name="carrierFundedAmount"
                value={carrierFundedAmount}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
            <ListItem>
              <Pennies
                fullWidth
                name="brokerFundedAmount"
                disabled
                label="Broker Amount Funded:"
                value={brokerFundedAmount}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
            <ListItem>
              <Pennies
                fullWidth
                disabled
                label="Amount Funded:"
                name="amountFunded"
                value={amountFunded}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
            <ListItem>
              <Pennies
                fullWidth
                disabled
                label="Reserve Holdback:"
                name="reserveHoldback"
                value={reserveHoldback}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ListItem>
              <Pennies
                fullWidth
                disabled
                label="Client Invoice:"
                name="clientInvoiceAmount"
                value={clientInvoiceAmount}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
          </React.Fragment>
        )}
        {paymentProfileId ? (
          <React.Fragment>
            <ListItem>
              <Pennies
                fullWidth
                disabled
                label="Broker Discount Amount:"
                value={brokerDiscountAmount}
                name="brokerDiscountAmount"
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
            <ListItem>
              <Pennies
                fullWidth
                disabled
                label="Carrier Discount Amount:"
                value={carrierDiscountAmount}
                name="carrierDiscountAmount"
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ListItem>
              <Pennies
                fullWidth
                name="unPaidPurchaseAmount"
                disabled
                label="To be Purchased:"
                value={unPaidPurchaseAmount}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
            </ListItem>
          </React.Fragment>
        )}
        <ListItem>
          <Select
            value={paymentMethod}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            name="paymentMethod"
            label="Payment Method"
            selections={accounts.map(
              (account): Selection => ({
                text: `${account.method} ${account.account_number_redacted}`,
                value: `${(account.method || "").toLowerCase()}`
              })
            )}
          />
        </ListItem>
        <ListItem>
          <Select
            value={carrierPaymentMethod}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            name="carrierPaymentMethod"
            label="Carrier Payment Method"
            selections={paymentProfileAccounts.map(
              (account): Selection => {
                return {
                  text: (() => {
                    if (!isEmpty(get(paymentProfile, "factoring_company", {})) && PaymentProfilePaymentMethodType === "check") {
                      return `${get(factoringCompany, "name", "").toUpperCase()} - ${PaymentProfilePaymentMethodType.toUpperCase()}`;
                    }
                    return isEqual(
                      get(paymentProfile, "factoring_company.account_number", ""),
                      account.account_number_redacted
                    )
                      ? `${get(factoringCompany, "name", "").toUpperCase()} ${account.method
                      } ${account.account_number_redacted}`
                      : `${account.method} ${account.account_number_redacted}`
                  })(),
                  value: `${account.id}`
                };
              }
            )}
          />
        </ListItem>
        {fuelAdvanceAmount ? (
          <>
            <ListItem>
              <Select
                value={fuelAdvancePaymentMethod.method}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                name="fuelAdvancePaymentMethod.method"
                label="Fuel Advance Payment Method"
                selections={paymentProfileAccounts.map(
                  (account): Selection => ({
                    text: `${account.method} ${account.account_number_redacted}`,
                    value: `${account.method}`
                  })
                )}
              />
            </ListItem>
            <ListItem>
              <Pennies
                fullWidth
                label="Fuel Advance Amount:"
                value={fuelAdvanceAmount}
                name="fuelAdvanceAmount"
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                customInputClasses={{
                  input: classes.bootstrapInput
                }}
              />
            </ListItem>
            <ListItem>
              <BoldInput
                fullWidth
                disabled
                label="Fuel Advance Rate:"
                value={fuelAdvanceRate}
                name="fuelAdvanceRate"
                onChange={handleChange}
                onBlur={handleBlur}
                customInputClasses={{
                  input: classes.bootstrapInput
                }}
              />
            </ListItem>
            <ListItem>
              <Pennies
                fullWidth
                disabled
                label="Fuel Advance Fee:"
                value={fuelAdvanceFee}
                name="fuelAdvanceFee"
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                customInputClasses={{
                  input: classes.bootstrapInput
                }}
              />
            </ListItem>
          </>
        ) : null}
        <ListItem>
          <AutoCompleteAddress
            fullWidth
            name="firstPickUpAddress"
            label="First Pickup:"
            value={firstPickUpAddress}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        </ListItem>
        <ListItem>
          <AutoCompleteAddress
            fullWidth
            name="finalDeliveryAddress"
            label="Final Delivery:"
            value={finalDeliveryAddress}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        </ListItem>
        <ListItem>
          <Select
            value={equipmentType}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            name="equipmentType"
            label="Equipment Type:"
            selections={equipmentTypes.map(
              (equipment: { name: string; value: string }): Selection => ({
                text: equipment.name,
                value: equipment.value
              })
            )}
          />
        </ListItem>
        <ListItem>
          <Select
            value={commodity}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            name="commodity"
            label="Commodity:"
            selections={commodities
              .map(c => ({
                text: `${c.commodity}${c.trading_symbol ? ` (${c.trading_symbol})` : ""
                  }`,
                value: c.id
              }))
              .concat([
                {
                  text: "N/A",
                  value: "0"
                }
              ])}
          />
        </ListItem>
        <ListItem>
          <Select
            value={freightClass}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            name="freightClass"
            label="Freight Class:"
            selections={freightClasses
              .map(c => ({
                text: `${c.example} (${c.class})`,
                value: c.id
              }))
              .concat([
                {
                  text: "N/A",
                  value: "0"
                }
              ])}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            name="loadLength"
            label="Load Length:"
            onChange={handleChange}
            onBlur={handleBlur}
            value={loadLength}
            error={!!errors.loadLength}
            helperText={errors.loadLength}
          />
        </ListItem>
      </List>
    </Card>
  );
}

export default withStyles(styles)(InvoiceInfoCard);

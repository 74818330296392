import { assign } from "lodash";
import {
  FETCH_OUTSTANDING_TOTALS_FAILURE,
  FETCH_OUTSTANDING_TOTALS_SUCCESS,
  FETCH_OUTSTANDING_TOTALS_REQUEST
} from "../types";

export default (state, action) => {
  const { outstandingTotals } = state;
  switch (action.type) {
    case FETCH_OUTSTANDING_TOTALS_REQUEST:
      return {
        ...state,
        outstandingTotals: assign({}, outstandingTotals, {
          isLoading: true
        })
      };
    case FETCH_OUTSTANDING_TOTALS_SUCCESS:
      return {
        ...state,
        outstandingTotals: assign({}, outstandingTotals, {
          isLoading: false,
          totalBalance: action.payload.total_balance,
          totalReceivables: action.payload.total_receivables
        })
      };
    case FETCH_OUTSTANDING_TOTALS_FAILURE:
      return {
        ...state,
        outstandingTotals: assign({}, outstandingTotals, {
          isLoading: false
        })
      };
    default:
      return state;
  }
};

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  UPDATE_CONNECTEDPROFILESTATUS_FAILURE,
  UPDATE_CONNECTEDPROFILESTATUS_REQUEST,
  UPDATE_CONNECTEDPROFILESTATUS_SUCCESS
} from "../types";

const updateConnectedFactoringProfile = (
  factoringId,
  paymentProfileId,
  update
) => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(UPDATE_CONNECTEDPROFILESTATUS_REQUEST)());
    const response = await api.factoring.updateConnectedFactoringProfile(
      factoringId,
      paymentProfileId,
      update
    );
    const data = normalize(response, schema.factoringProfile);
    dispatch(addEntities(data.entities));
    dispatch(createAction(UPDATE_CONNECTEDPROFILESTATUS_SUCCESS)({}));
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_CONNECTEDPROFILESTATUS_FAILURE)(err));
    throw err;
  }
};

export default updateConnectedFactoringProfile;

import React from "react";
import { List } from "@material-ui/core";

import Card from "../../../../../components/Card";
import BoldInput from "../../../../../components/inputs/BoldInput";
import ListItem from "../../../../../components/ListItem";
import ProfileSection from "../../../../../components/ProfileSection";
import { Attachments } from "../../../../../components/AttachmentsCard";

const fileCategories = [
  {
    key: "driver_license",
    name: "Driver License"
  },
  {
    key: "insurance",
    name: "Insurance"
  },
  {
    key: "voided_check",
    name: "Voided Check"
  },
  {
    key: "comfreight_noa",
    name: "ComFreight NOA"
  },
  {
    key: "transfer_document",
    name: "Transfer Documents"
  },
  {
    key: "",
    name: "Other"
  }
];

export default ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  attachments,
  deleteAttachment,
  openDialog
}) => (
  <Card style={{ height: "100%" }}>
    <List dense>
      <ListItem>
        <ProfileSection>Owner Info</ProfileSection>
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="firstName"
          label="First Name"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.firstName}
          helperText={errors.firstName}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="lastName"
          label="Last Name"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="address"
          label="Address"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.address}
          helperText={errors.address}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="streetTwo"
          label="Street Two"
          value={values.streetTwo}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.streetTwo}
          helperText={errors.streetTwo}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="city"
          label="City"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.city}
          helperText={errors.city}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="state"
          label="State"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.state}
          helperText={errors.state}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="zip"
          label="Zip"
          value={values.zip}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.zip}
          helperText={errors.zip}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="cellPhone"
          label="Cell Phone"
          value={values.cellPhone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.cellPhone}
          helperText={errors.cellPhone}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="homePhone"
          label="Home Phone"
          value={values.homePhone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.homePhone}
          helperText={errors.homePhone}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="email"
          label="Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.email}
          helperText={errors.email}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="dob"
          label="DOB"
          value={values.dob}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.dob}
          helperText={errors.dob}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="ownerSSN"
          label="Last 4 - Owner's SSN"
          value={values.ownerSSN}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.ownerSSN}
          helperText={errors.ownerSSN}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="lienStatus"
          label="Lien Status"
          value={values.lienStatus}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.lienStatus}
          helperText={errors.lienStatus}
        />
      </ListItem>
      <ListItem>
        <ProfileSection>Attachments</ProfileSection>
      </ListItem>
      <div style={{ margin: 8 }}>
        <Attachments
          files={attachments}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleDelete={id => {
            openDialog(
              'confirm',
              'Confirm Delete',
              'Are you sure you want to delete this Attachments?',
              {
                id
              }
            )
          }}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          multipleCategories
          categories={fileCategories}
          source={true}
        />
      </div>
    </List>
  </Card>
);

import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { memoize } from "lodash";
import omitEmptyKeys from "../helpers/omitEmptyKeys";
import deleteCommaKeys from "../helpers/deleteCommaKeys";

const API_KEY_HAULPAY = process.env.REACT_APP_API_KEY_HAULPAY_FRONTEND;
const APP_API_URL = process.env.REACT_APP_API_URL;

const createInvitedUser = async (
  data: Record<string, any>,
  inviteToken: string,
  authToken: string
): Promise<any> => {
  const customAxios = axios.create({
    baseURL: `${APP_API_URL}.1`
  });
  const headers = authToken
    ? {
      headers: {
        authorization: `Bearer ${authToken}`,
        "content-type": "application/json"
      }
    }
    : {};
  const options: AxiosRequestConfig = {
    method: "POST",
    url: `/users/`,
    params: {
      invite_token: inviteToken
    },
    ...headers,
    data
  };
  const response = await customAxios(options);
  return response.data;
};

const createUser = async (
  user: Record<string, any>,
  token: string,
  source?: string
): Promise<any> => {
  const existToken = token ? { invite_token: token } : {};
  const options: AxiosRequestConfig = {
    method: "POST",
    headers: {
      authorization: `Bearer ${token}`,
      "content-type": "application/json"
    },
    data: { ...user, ...existToken },
    url: `/users/?${qs.stringify({
      source: source || `broker-invite`
    })}`
  };
  const response = await axios(options);
  return response.data;
};

const createUserAdmin = async (user: Record<string, any>): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "POST",
    data: user,
    url: `/admin/users/`
  };
  const response = await axios(options);
  return response.data;
};

const fetchToken = async (username: string, password: string): Promise<any> => {
  const data = {
    username: username.toLowerCase(),
    password,
    grant_type: "password",
    client_id: API_KEY_HAULPAY
  };
  const options: AxiosRequestConfig = {
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify(data),
    url: `/o/token/`
  };

  const response = await axios(options);
  return response.data;
};

export const validateEmail = memoize(
  async (email: string): Promise<any> => {
    if (!email) {
      throw new TypeError("`validateEmail`: No `email` passed");
    }
    const response = await axios(`/user/check_exists?email=${email}`);
    return response.data;
  }
);

const currentUser = async (): Promise<any> => {
  const response = await axios(`/user/current/`);
  return response.data;
};

const getUserById = async (id: string): Promise<any> => {
  const response = await axios(`/users/${id}/`);
  return response.data;
};

const queryFetchUsers = async (
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/user/search/`,
    method: "GET",
    params: {
      ordering,
      offset,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryFetchUsersExport = async (
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/user/export/`,
    method: "GET",
    params: {
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const createNote = async (
  user: string,
  note: string,
  attachments?: File
): Promise<any> => {
  const data = new FormData();
  if (attachments) {
    data.append("attachments", attachments);
  }
  data.append("note", note);
  data.append("user", user);
  const options: AxiosRequestConfig = {
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data,
    url: "/admin/user/notes/"
  };
  const response = await axios(options);
  return response.data;
};

const queryFetchUserNotes = async (userId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/notes_timeline/`,
    method: "GET",
    params: {
      user_id: userId
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryLoadAttachment = async ({
  attachmentId,
  attachment
}: {
  attachmentId: string;
  attachment: string;
}): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/user/notes/${attachmentId}/`,
    method: "GET",
    params: {
      attachment
    },
    paramsSerializer: (params: any): string => `attachment=${params.attachment}`
  };
  const response = await axios(options);
  return response.data;
};

const deleteUserNotes = async (notesId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/user/notes/${notesId}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const updateUserNotes = async (
  noteId: string,
  note: string,
  attachments?: File
): Promise<any> => {
  const data = new FormData();
  if (attachments) {
    data.append("attachments", attachments);
  }
  data.append("note", note);
  const options: AxiosRequestConfig = {
    url: `/admin/user/notes/${noteId}/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const updateUser = async ({
  userId,
  ...data
}: Record<string, any> & { userId: string }): Promise<any> => {
  if (!userId) {
    throw new TypeError("`updateUser`: No `user id` passed");
  }
  const options: AxiosRequestConfig = {
    method: "PATCH",
    data,
    url: `/admin/user/${userId}/`
  };
  const response = await axios(options);
  return response.data;
};

const clientUpdateUser = async ({
  userId,
  ...data
}: Record<string, any> & { userId: string }): Promise<any> => {
  if (!userId) {
    throw new TypeError("`updateUser`: No `user id` passed");
  }
  const options: AxiosRequestConfig = {
    method: "PATCH",
    data,
    url: `/users/${userId}/`
  };
  const response = await axios(options);
  return response.data;
};

const deleteUserProfileDocuments = async (fileId: string): Promise<any> => {
  if (!fileId) {
    throw new TypeError("`deleteUserProfile`: No `file id` passed");
  }
  const options: AxiosRequestConfig = {
    method: "DELETE",
    url: `/user/profile/documents/upload/${fileId}/`
  };
  const response = await axios(options);
  return response.data;
};

const deleteUserProfile = async (userId: string): Promise<any> => {
  if (!userId) {
    throw new TypeError("`deleteUserProfile`: No `user id` passed");
  }
  const options: AxiosRequestConfig = {
    method: "DELETE",
    url: `admin/user/${userId}/`
  };
  const response = await axios(options);
  return response.data;
};

const getAccounts = async (userId: string, method?: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: `user/${userId}/accounts/`,
    params: {
      method,
      limit: 100
    }
  };
  const response = await axios(options);
  return response.data;
};

const defaultAccount = async (
  userId: string,
  id: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "PATCH",
    url: `user/${userId}/accounts/${id}/`,
    data
  };
  const response = await axios(options);
  return response.data;
};

const CarrierDefaultAccount = async (
  userId: string,
  id: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "PATCH",
    url: `accounts/carrier/${id}/`,
    data
  };
  const response = await axios(options);
  return response.data;
};

const refreshUserToken = async (
  id: string,
  refreshToken: string
): Promise<any> => {
  try {
    const options: AxiosRequestConfig = {
      url: `/o/token/`,
      method: "POST",
      data: {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        client_id: id
      }
    };
    const res = await axios(options);
    return res.data;
  } catch (err) {
    throw err;
  }
};

const resetPassword = async (email: string): Promise<any> => {
  try {
    const options: AxiosRequestConfig = {
      url: `/user/password/reset/`,
      method: "POST",
      data: {
        email,
        app_name: "haulpay"
      }
    };
    const res = await axios(options);
    return res.data;
  } catch (err) {
    throw err;
  }
};

const confirmResetPassword = async (
  data: Record<string, any>
): Promise<any> => {
  try {
    const options: AxiosRequestConfig = {
      url: `/user/password/reset/confirm/`,
      method: "POST",
      data
    };
    const res = await axios(options);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export default {
  queryFetchUsersExport,
  getUserById,
  currentUser,
  createUser,
  updateUser,
  fetchToken,
  validateEmail,
  queryFetchUsers,
  createNote,
  queryFetchUserNotes,
  queryLoadAttachment,
  deleteUserNotes,
  updateUserNotes,
  deleteUserProfileDocuments,
  deleteUserProfile,
  getAccounts,
  refreshUserToken,
  defaultAccount,
  createInvitedUser,
  resetPassword,
  clientUpdateUser,
  createUserAdmin,
  confirmResetPassword,
  CarrierDefaultAccount
};

import { createAction } from "redux-starter-kit";
import {
  UPDATE_FACTORINGDEBTOR_FAILURE,
  UPDATE_FACTORINGDEBTOR_REQUEST,
  UPDATE_FACTORINGDEBTOR_SUCCESS
} from "../types";

const updateFactoringDebtor = (debtorId, factoringId, data) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(UPDATE_FACTORINGDEBTOR_REQUEST)({}));
    await api.debtors.updateFactoringDebtor(debtorId, factoringId, data);
    dispatch(createAction(UPDATE_FACTORINGDEBTOR_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(UPDATE_FACTORINGDEBTOR_FAILURE)(err));
  }
};

export default updateFactoringDebtor;

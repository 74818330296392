import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

import colors from "../../../../../../theme/colors";

const PaperClip = () => {
    return <FontAwesomeIcon icon={faPaperclip} color={colors.secondary} />;
};

export default PaperClip;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faClock } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import Colors from "../../../../theme/colors";

interface Props {
  status: string;
}

const IconStatus = ({ status }: Props): JSX.Element => {
  switch (status) {
    case "Approved":
      return <FontAwesomeIcon size="xs" color={Colors.green} icon={faCircle} />;
    case "Declined":
      return (
        <FontAwesomeIcon size="xs" color={Colors.danger} icon={faCircle} />
      );
    case "Bad Lead":
      return (
        <FontAwesomeIcon size="xs" color={Colors.danger} icon={faCircle} />
      );
    case "Lead In":
      return (
        <FontAwesomeIcon size="xs" color={Colors.secondary} icon={faCircle} />
      );
    case "Broker Invited":
      return (
        <FontAwesomeIcon size="xs" color={Colors.secondary} icon={faCircle} />
      );
    case "Pending":
      return (
        <FontAwesomeIcon size="xs" color={Colors.warning} icon={faCircle} />
      );
    case "New":
      return (
        <FontAwesomeIcon size="xs" color={Colors.link} icon={faCircle} />
      );
    case "Contract Out":
      return (
        <FontAwesomeIcon size="xs" color={Colors.warning} icon={faCircle} />
      );
    case "Incomplete":
      return (
        <FontAwesomeIcon size="xs" color={Colors.warning} icon={faCircle} />
      );
    case "Remote Approved":
      return (
        <FontAwesomeIcon size="xs" color={Colors.warning} icon={faCircle} />
      );
    case "Document Review":
      return (
        <FontAwesomeIcon size="xs" color={Colors.warning} icon={faCircle} />
      );
    case "Document Issue":
      return (
        <FontAwesomeIcon size="xs" color={Colors.warning} icon={faCircle} />
      );
    case "HaulPay Review":
      return (
        <FontAwesomeIcon size="xs" color={Colors.warning} icon={faCircle} />
      );
    case "Paid":
      return <FontAwesomeIcon size="xs" color={Colors.green} icon={faCircle} />;
    case "Doc Issue":
      return (
        <FontAwesomeIcon size="xs" color={Colors.danger} icon={faCircle} />
      );
    default:
      return <FontAwesomeIcon size="xs" icon={faClock} />;
  }
};

export default IconStatus;

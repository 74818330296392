import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";

import { addEntities } from "../../entities";
import {
  FETCH_RECEIVABLES_ENTRIES_FAILURE,
  FETCH_RECEIVABLES_ENTRIES_REQUEST,
  FETCH_RECEIVABLES_ENTRIES_SUCCESS
} from "../types";

const debounced = debounce(
  async (dispatch, schema, api, id, ordering, limit, filters) => {
    dispatch(
      createAction(FETCH_RECEIVABLES_ENTRIES_REQUEST)({
        id,
        limit,
        ordering,
        filters
      })
    );
    const response = await api.finances.queryReceivablesEntries(
      id,
      ordering,
      limit,
      filters
    );
    const data = normalize(response.results, [schema.receivableEntry]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_RECEIVABLES_ENTRIES_SUCCESS)({
        items: data.result,
        count: response.count,
        next: response.next,
        previous: response.previous,
        limit,
        filters
      })
    );
    return response;
  },
  1000,
  { trailing: true }
);

const fetchReceivablesEntries = (id, ordering, limit, filters = {}) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    await debounced(dispatch, schema, api, id, ordering, limit, filters);
  } catch (err) {
    dispatch(createAction(FETCH_RECEIVABLES_ENTRIES_FAILURE)(err));
    throw err;
  }
};

export default fetchReceivablesEntries;

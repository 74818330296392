import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { BarChart, Bar, Cell, ResponsiveContainer, LabelList } from "recharts";
import { get, isEmpty } from "lodash";

import colors from "../../../../theme/colors";
import StatsCard from "./StatsCard";

export default ({ stats }) => {
  const StatsTab = [];
  if (!isEmpty(stats.items)) {
    StatsTab.push({
      name: "Active",
      value: get(stats, "items.active_client_counts", 0),
      color: colors.green
    });
    StatsTab.push({
      name: "Inactive",
      value: get(stats, "items.inactive_factoring", 0),
      color: colors.danger
    });
  }
  let chart = (
    <ResponsiveContainer>
      <BarChart
        data={StatsTab}
        margin={{
          top: 20
        }}
      >
        <Bar dataKey="value" isAnimationActive={false}>
          <LabelList
            dataKey="value"
            position="top"
            style={{ fontFamily: "Avenir" }}
          />
          {StatsTab.map(entry => (
            <Cell fill={entry.color} strokeWidth={1} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
  return (
    <StatsCard
      title="Clients Stats"
      description={
        <Grid container item xs={12} spacing="32">
          <Grid container xs={2} alignItems="center">
            <div
              style={{ height: 5, width: 30, backgroundColor: colors.green }}
            />
          </Grid>
          <Grid
            container
            xs={10}
            style={{ paddingLeft: 5 }}
            alignItems="center"
            justify="flex-start"
          >
            <Typography variant="caption">Active</Typography>
          </Grid>
          <Grid container xs={2} alignItems="center">
            <div
              style={{ height: 5, width: 30, backgroundColor: colors.danger }}
            />
          </Grid>
          <Grid
            container
            xs={10}
            style={{ paddingLeft: 5 }}
            alignItems="center"
            justify="flex-start"
          >
            <Typography variant="caption">Inactive</Typography>
          </Grid>
        </Grid>
      }
      chart={chart}
    />
  );
};

import { denormalize } from "normalizr";

import { purchase } from "../../../api/schema";

export default state => {
  try {
    const items = state.purchases.items.ids;
    const denormalized = denormalize(
      { items },
      { items: [purchase] },
      state.entities
    );

    return denormalized.items;
  } catch (err) {
    return [];
  }
};

import React from "react";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Tooltip,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faHandHoldingUsd,
  faIdBadge,
  faPeopleCarry,
  faSearchDollar,
  faUserCheck,
  faUsers,
  faUsersCog
} from "@fortawesome/free-solid-svg-icons";
import Can from "./Can";
import colors from "../theme/colors";

interface BoxProps {
  children: JSX.Element;
}

const Box = ({ children }: BoxProps): JSX.Element => (
  <div style={{ fontSize: 30, width: "38px" }}>{children}</div>
);

interface MenuItem {
  title: string;
  Icon: () => JSX.Element;
  link: string;
}

const drawerWidth = 240;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap" as "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden" as "hidden",
      width: theme.spacing.unit * 7 + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing.unit * 9 + 1
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    }
  });

interface MenuItemProps {
  link: string;
  title: string;
  Icon: () => JSX.Element;
  handleLogout: () => void;
  open: boolean;
}

const MenuItem = ({
  link,
  title,
  Icon,
  handleLogout,
  open
}: MenuItemProps): JSX.Element => {
  if (link && open) {
    return (
      <NavLink
        to={link}
        activeStyle={{ color: colors.link }}
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <ListItem
          href={link}
          button
          onClick={(): void => {
            if (link === "/logout/") {
              handleLogout();
            }
          }}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      </NavLink>
    );
  }
  if (link) {
    return (
      <NavLink
        to={link}
        activeStyle={{ color: colors.link }}
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <Tooltip title={title}>
          <ListItem
            button
            onClick={(): void => {
              if (link === "/logout/") {
                handleLogout();
              }
            }}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
        </Tooltip>
      </NavLink>
    );
  }
  if (open) {
    return (
      <ListItem button>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    );
  }
  return (
    <Tooltip title={title}>
      <ListItem button>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </Tooltip>
  );
};

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  open: boolean;
  handleDrawerClose: () => void;
  handleLogout: () => void;
  userType?: string;
}

export default withStyles(styles, { withTheme: true })(
  ({
    classes,
    theme,
    open,
    handleDrawerClose,
    handleLogout,
    userType
  }: Props): JSX.Element => (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      open={open}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={(): void => handleDrawerClose()}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        <Can
          perform="admin-dashboard:view"
          yes={() => (
            <MenuItem
              key="Dashboard"
              Icon={() => (
                <NavLink
                  to="/admin/dashboard/"
                  activeStyle={{ color: colors.link }}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Box>
                    <FontAwesomeIcon size="xs" icon={faChartLine} />
                  </Box>
                </NavLink>
              )}
              link="/admin/dashboard"
              title="Dashboard"
              handleLogout={handleLogout}
              open={open}
            />
          )}
        />
        <Can
          perform="admin-users:view"
          yes={() => (
            <MenuItem
              key="Users"
              Icon={() => (
                <NavLink
                  to="/admin/users/"
                  activeStyle={{ color: colors.link }}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Box>
                    <FontAwesomeIcon size="xs" icon={faUsers} />
                  </Box>
                </NavLink>
              )}
              link="/admin/users"
              title="Users"
              handleLogout={handleLogout}
              open={open}
            />
          )}
        />
        <Can
          perform="admin-factorclients:view"
          yes={() => (
            <MenuItem
              key="Factor Clients"
              Icon={() => (
                <NavLink
                  to="/admin/factorclients/"
                  activeStyle={{ color: colors.link }}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Box>
                    <FontAwesomeIcon size="xs" icon={faUserCheck} />
                  </Box>
                </NavLink>
              )}
              link="/admin/factorclients"
              title="Factor Clients"
              handleLogout={handleLogout}
              open={open}
            />
          )}
        />
        <Can
          perform="admin-debtors:view"
          yes={() => (
            <MenuItem
              key="Debtors"
              Icon={() => (
                <NavLink
                  to="/admin/debtors/"
                  activeStyle={{ color: colors.link }}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Box>
                    <FontAwesomeIcon size="xs" icon={faIdBadge} />
                  </Box>
                </NavLink>
              )}
              link="/admin/debtors"
              title="Debtors"
              handleLogout={handleLogout}
              open={open}
            />
          )}
        />
        <Can
          perform="admin-transactions:view"
          yes={() => (
            <MenuItem
              key="Transactions"
              Icon={() => (
                <NavLink
                  to="/admin/transactions/new_noa"
                  activeStyle={{ color: colors.link }}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Box>
                    <FontAwesomeIcon size="xs" icon={faHandHoldingUsd} />
                  </Box>
                </NavLink>
              )}
              link="/admin/transactions/new_noa"
              title="Transactions"
              handleLogout={handleLogout}
              open={open}
            />
          )}
        />
        <Can
          perform="admin-finances:view"
          yes={() => (
            <MenuItem
              key="Finance"
              Icon={() => (
                <NavLink
                  to="/admin/finances/receivables"
                  activeStyle={{ color: colors.link }}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Box>
                    <FontAwesomeIcon size="xs" icon={faSearchDollar} />
                  </Box>
                </NavLink>
              )}
              link="/admin/finances/receivables"
              title="Finance"
              handleLogout={handleLogout}
              open={open}
            />
          )}
        />
        <Can
          perform="admin-groups:view"
          yes={() => (
            <MenuItem
              key="Groups"
              Icon={() => (
                <NavLink
                  to="/admin/groups"
                  activeStyle={{ color: colors.link }}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Box>
                    <FontAwesomeIcon size="xs" icon={faPeopleCarry} />
                  </Box>
                </NavLink>
              )}
              link="/admin/groups"
              title="Groups"
              handleLogout={handleLogout}
              open={open}
            />
          )}
        />
        <Can
          perform="admin-groups:view"
          yes={() => (
            <MenuItem
              key="Admins"
              Icon={() => (
                <NavLink
                  to="/admin/admins"
                  activeStyle={{ color: colors.link }}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <Box>
                    <FontAwesomeIcon size="xs" icon={faUsersCog} />
                  </Box>
                </NavLink>
              )}
              link="/admin/admins"
              title="Admins"
              handleLogout={handleLogout}
              open={open}
            />
          )}
        />
      </List>
    </Drawer>
  )
);

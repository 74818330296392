import {
  FETCH_PAYMENTS_TERMS_REQUEST,
  FETCH_PAYMENTS_TERMS_SUCCESS,
  FETCH_PAYMENTS_TERMS_FAILURE
} from "../types";

const paymentTerms = (state, { type, payload }) => {
  switch (type) {
    case FETCH_PAYMENTS_TERMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PAYMENTS_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentTerms: payload
      };
    case FETCH_PAYMENTS_TERMS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default paymentTerms;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import { ADD_TMS_REQUEST } from "../types";

const addTMS = name => async (dispatch, getState, { api, schema }) => {
  const response = await api.factoring.tms.addTMS(name);
  const data = normalize([response], [schema.tms]);
  dispatch(addEntities(data.entities));
  dispatch(
    createAction(ADD_TMS_REQUEST)({
      items: data.result
    })
  );
  return response;
};

export default addTMS;

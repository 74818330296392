const resetSteps = state => {
  const startingStep = state.steps[0].value;

  return {
    ...state,
    step: startingStep
  };
};

export default resetSteps;

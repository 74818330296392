import {
  FETCH_FACTORING_PROFILE_REQUEST,
  FETCH_FACTORING_PROFILE_SUCCESS,
  FETCH_FACTORING_PROFILE_FAILURE
} from "../types";

const fetchFactoringProfile = (state, { type, payload }) => {
  switch (type) {
    case FETCH_FACTORING_PROFILE_REQUEST:
      return {
        ...state,
        factoringLoading: true
      };
    case FETCH_FACTORING_PROFILE_SUCCESS:
      return {
        ...state,
        factoringLoading: false,
        factoringProfile: payload
      };
    case FETCH_FACTORING_PROFILE_FAILURE:
      return {
        ...state,
        factoringLoading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchFactoringProfile;

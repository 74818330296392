import React from "react";
import colors from "../theme/colors";

interface BadgeProps {
  count: string;
  id?: string;
}

function Badge({ count, id }: BadgeProps) {
  return (
    <div
      id={id}
      style={{
        backgroundColor: colors.danger,
        color: "white",
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        display: "inline-block"
      }}
    >
      {count}
    </div>
  );
}

export default Badge;

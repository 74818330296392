import React from 'react'
import tw from 'twin.macro'
import Item, { FormItemProps } from './form_item'

const InternalForm = tw.form``

type InternalFormType = typeof InternalForm;

export type FormProps = React.ComponentProps<typeof InternalForm>


interface FormInterface extends InternalFormType {
    Item: typeof Item;
}

export const Form = InternalForm as FormInterface;

Form.Item = Item

export default Form

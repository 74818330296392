import { compose, withHandlers, withStateHandlers } from "recompose";
import { concat, includes, filter } from "lodash";

const withSelect = compose(
  withStateHandlers(
    {
      selected: []
    },
    {
      handleSelect: ({ selected }) => value => {
        return {
          selected: includes(selected, value)
            ? filter(selected, item => item !== value)
            : concat(selected, value)
        };
      },
      handleSelectAllClick: ({ selected }) => values => ({
        selected: selected.length === values.length ? [] : values
      }),
      clearSelected: () => () => ({ selected: [] })
    }
  ),
  withHandlers({
    isSelected: ({ selected }) => id => selected.indexOf(id) !== -1,
    allSelected: ({ selected }) => length =>
      selected.length === length && length !== 0
  })
);

export default withSelect;

import React from "react";
import { Field, Form } from "formik";
import MailIcon from "@material-ui/icons/Mail";
import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import colors from "../../theme/colors";
import ProgressButton from "../buttons/ProgressButton";
import PasswordInput from "../inputs/PasswordInput";

const styles = {
  rootInputStyle: {
    color: colors.primary
  },
  inputStyle: {
    color: colors.textInputColor
  },
  notchedOutline: {
    borderColor: `${colors.secondary} !important`
  },
  progressClasses: {
    color: `${colors.primary} !important`
  }
};

export default withStyles(styles)(
  ({
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    classes,
    authLoading
  }) => (
    <Form
      style={{
        minWidth: "300px"
      }}
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        spacing={24}
      >
        <Grid item style={{ width: "100%", paddingRight: 17 }}>
          <Field name="email">
            {({ field: { value, ...field }, hasError = !!errors.email }) => (
              <TextField
                id="email"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.rootInputStyle,
                    input: classes.inputStyle,
                    notchedOutline: classes.notchedOutline
                  }
                }}
                label="Email"
                placeholder="Email"
                value={value}
                helperText={hasError ? errors.email : null}
                error={hasError}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Field>
        </Grid>
        <Grid item style={{ width: "100%", paddingRight: 30 }}>
          <Field name="password">
            {({ field: { value, ...field }, hasError = !!errors.password }) => (
              <PasswordInput
                fullWidth
                showStartPasswordIcon
                showEndPasswordIcon
                variant="outlined"
                InputProps={{
                  classes: {
                    root: classes.rootInputStyle,
                    input: classes.inputStyle,
                    notchedOutline: classes.notchedOutline
                  }
                }}
                value={value}
                helperText={hasError ? errors.password : null}
                error={hasError}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
                id="password"
              />
            )}
          </Field>
        </Grid>
        <Grid container item style={{ width: "100%" }} justify="flex-end">
          <Typography variant="caption">
            <Link to="/forgetpassword/">Forgot password?</Link>
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignContent="center"
          alignItems="center"
          direction="column"
          spacing={8}
        >
          <Grid item>
            <ProgressButton
              id="signin"
              variant="contained"
              btnColor="primary"
              style={{
                borderRadius: 60,
                color: "#fff"
              }}
              disabled={!!authLoading}
              size="large"
              type="submit"
              onClickHandler={handleSubmit}
              progressClasses={classes.progressClasses}
            >
              SIGN IN
            </ProgressButton>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
);

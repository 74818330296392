import React from "react";
import { ListItem, withStyles, WithStyles } from "@material-ui/core";
import { ListItemProps } from "@material-ui/core/ListItem";

const styles = {
  root: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 8
  }
};

export default withStyles(styles)(
  ({
    classes,
    ...props
  }: ListItemProps & WithStyles<typeof styles>): JSX.Element => (
    <ListItem className={classes.root} {...props} />
  )
);

import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../../../../theme/colors";

const MoreButton = ({ id }) => (
  <Grid container item xs={12} direction="row" style={{ minWidth: 100 }}>
    <Grid item>
      <Tooltip title="Debtor Payments">
        <Link to={`/admin/debtors/${id}/payment`}>
          <IconButton>
            <FontAwesomeIcon
              icon={faFileInvoiceDollar}
              color={Colors.grayIcon}
            />
          </IconButton>
        </Link>
      </Tooltip>
    </Grid>
  </Grid>
);

export default MoreButton;

import { Grid, Typography } from "@material-ui/core";
import React from "react";
import HaulPayLogo from "../HaulPayLogo";

const Header = ({ userType = 'carrier' }): JSX.Element => (
  <Grid
    container
    spacing={8}
    justify="center"
    direction="column"
    alignItems="center"
  >
    <Grid item>
      <Typography align="center" variant="h5">
        {userType === 'admin' ? 'ADMIN' : 'WELCOME BACK TO'}
      </Typography>
    </Grid>
    <Grid item>
      <HaulPayLogo />
    </Grid>
  </Grid>
);

export default Header;

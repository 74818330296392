import React, { useCallback, useEffect, createRef } from "react";
import {
  Paper,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Button
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { get, head } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { Select } from "../../../../components/inputs/BoldInput";
import tabs from "../tabs";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import NavigationTabs from "../../../../components/NavigationTabs";
import Table from "../../../../components/Table";
import Link from "../../../../components/Link";
import {
  fetchFactoringCompany,
  getFactoringCompany
} from "../../../../modules/groups";
import {
  getDialog,
  openDialog,
  closeDialog,
  openSnackbar
} from "../../../../modules/ui";
import {
  queryConnectedClients,
  getConnectedClients,
  connectedClientsActions,
  connectedClientsSelectors,
  updateRelationship,
  createPaymentProfileAttachment,
  removeRelationship
} from "../../../../modules/factorCompany";
import colors from "../../../../theme/colors";
import openFileDialog from "../../../../helpers/openFileDialog";
import awsImageUpload from "../../../../helpers/awsImageUpload";
import formatStatus from "../../../../helpers/format/formatStatus";
import ConfirmNOADialog from "../../../../components/dialogs/ConfirmNOADialog";
import RemoveFactorDialog from "../../../../components/dialogs/RemoveFactorDialog";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";

const PaperClip = () => {
  return <FontAwesomeIcon icon={faPaperclip} color={colors.secondary} />;
};

const Save = () => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.green_dark} icon={faSave} />;
};

const Trash = () => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />;
};

const columns = [
  {
    key: "company_name",
    name: "Carrier",
    filter: "text",
    filter_key: "carrier"
  },
  {
    key: "broker",
    name: "Broker",
    filter: "text",
    filter_key: "broker"
  },
  {
    key: "payment_profile_data.status",
    name: "Status",
    format: formatStatus
  },
  {
    key: "payment_profile_data.owner_email",
    name: "Email",
    filter: "text",
    filter_key: "carrier_email"
  },
  {
    key: "payment_profile_data.owner_phone_number_cell",
    name: "Phone #",
    filter: "text",
    filter_key: "carrier_phone"
  },
  {
    key: "payment_profile_data.company_mc",
    name: "MC #",
    filter: "text",
    filter_key: "carrier_mc"
  },
  {
    key: "payment_profile_data.company_dot",
    name: "DOT #",
    filter: "text",
    filter_key: "carrier_dot"
  },
  {
    key: "noa",
    name: "NOA Status"
  },
  {
    key: "noaConnection",
    name: "NOA Connection"
  },
  {
    key: "confirmNoa",
    name: "Confirm NOA"
  },
  {
    key: "actions",
    name: "Action"
  }
];

export default ({
  match: {
    params: { id }
  }
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(fetchFactoringCompany(id));
      dispatch(queryConnectedClients(id));
    }
  }, [id]);
  const [open, setOpen] = React.useState(false);
  const [send, setSend] = React.useState(false);

  const dialog = useSelector(getDialog);
  const factoringCompany = useSelector(getFactoringCompany(id));
  const carriers = useSelector(getConnectedClients);
  return (
    <InternalLayout title="Factoring Company">
      <Grid container direction="column" spacing={32}>
        <Grid item style={{ width: "100%" }}>
          <Grid container style={{ width: "100%" }} justify="space-between">
            <Grid item>
              <Typography variant="h6">
                {get(factoringCompany, "name", "")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <NavigationTabs tabs={tabs(id)} value={1} />
        </Grid>
        <Grid item>
          <Paper style={{ padding: 16 }}>
            <Table
              columns={columns}
              rows={carriers.map(carrier => {
                const attachments = carrier.attachments.filter(
                  a => a.category === "NOA"
                );
                const carrierId = get(carrier, ["id"]);
                let upload = createRef();
                return {
                  company_name: (
                    <Link
                      to={`/admin/payment/${get(
                        carrier,
                        "payment_profile"
                      )}/profile`}
                    >
                      {get(
                        carrier,
                        ["payment_profile_data", "company_name"],
                        ""
                      )}
                    </Link>
                  ),
                  noa: ({ values, handleChange }) => (
                    <Select
                      fullWidth
                      name="noa_status"
                      onChange={handleChange}
                      value={values.noa_status}
                      selections={[
                        {
                          text: "In-Active",
                          value: "inactive"
                        },
                        {
                          text: "Active",
                          value: "active"
                        }
                      ]}
                    />
                  ),
                  noaConnection: ({ values }) => {
                    return (
                      <Grid container direction="row">
                        <Grid item>
                          {attachments.length > 0 ? (
                            <Tooltip title="View NOA">
                              <IconButton
                                style={{ width: "48px", height: "48px" }}
                                onClick={async () => {
                                  window.open(
                                    head(attachments).preview_url,
                                    "_blank"
                                  );
                                }}
                              >
                                <PaperClip />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <React.Fragment>
                              <Tooltip title="Upload NOA">
                                <IconButton
                                  style={{ width: "48px", height: "48px" }}
                                  onClick={async () => {
                                    openFileDialog(upload);
                                  }}
                                >
                                  <CloudUploadIcon
                                    style={{ color: colors.light_orange }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <input
                                type="file"
                                name="attachments"
                                ref={ref => (upload = ref)}
                                style={{ display: "none" }}
                                onChange={async e => {
                                  const file = e.currentTarget.files[0];
                                  const response = await dispatch(
                                    createPaymentProfileAttachment(
                                      values.id,
                                      file.name,
                                      "NOA"
                                    )
                                  );
                                  await awsImageUpload([
                                    {
                                      file,
                                      upload_url: response.data.upload_url
                                    }
                                  ]);
                                  await dispatch(queryConnectedClients(id));
                                }}
                              />
                            </React.Fragment>
                          )}
                        </Grid>
                      </Grid>
                    );
                  },
                  confirmNoa: () => (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSend(carrierId)}
                      >
                        Send
                      </Button>
                      <ConfirmNOADialog
                        open={send === carrierId}
                        name={get(carrier, ["factoring_company_name"])}
                        companyId={get(carrier, ["factoring_company"])}
                        paymentProfileId={get(carrier, ["payment_profile"])}
                        handleExit={() => setSend(false)}
                      />
                    </React.Fragment>
                  ),
                  actions: ({ handleSubmit }) => {
                    return (
                      <Grid container direction="row">
                        <Grid item>
                          <Tooltip title="Remove Member">
                            <IconButton
                              style={{ width: "48px", height: "48px" }}
                              onClick={async () => {
                                if (carrier.noa_status) {
                                  dispatch(
                                    openDialog(
                                      "confirm",
                                      "Remove Factor Company Member",
                                      "Are you sure you want to remove this member?",
                                      {
                                        carrierId: carrier.id
                                      },
                                      "remove_factoring_company_member"
                                    )
                                  );
                                } else {
                                  setOpen(carrierId);
                                }
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </Tooltip>
                          <RemoveFactorDialog
                            open={open === carrierId}
                            id={get(carrier, "id")}
                            handleExit={() => setOpen(false)}
                            name={get(carrier, ["broker_client_name"])}
                            baseId={carrierId}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip title="Save Updates">
                            <IconButton
                              style={{ width: "48px", height: "48px" }}
                              onClick={() => handleSubmit()}
                            >
                              <Save />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    );
                  },
                  broker: (
                    <Link
                      to={`/admin/factoring_client/${get(
                        carrier,
                        ["broker_relation", "factoring_id"],
                        ""
                      )}/profile`}
                    >
                      {get(carrier, "broker_client_name", "")}
                    </Link>
                  ),
                  ...carrier
                };
              })}
              allowEmpty
              isLoading={useSelector(connectedClientsSelectors.getIsLoading)}
              handleSort={useCallback(
                (...args) =>
                  dispatch(connectedClientsActions.handleSort(...args)),
                [dispatch]
              )}
              sortDirection={useSelector(
                connectedClientsSelectors.getSortDirection
              )}
              sortBy={useSelector(connectedClientsSelectors.getSortBy)}
              count={useSelector(connectedClientsSelectors.getCount)}
              page={useSelector(connectedClientsSelectors.getPage)}
              rowsPerPage={useSelector(
                connectedClientsSelectors.getRowsPerPage
              )}
              handleChangePage={useCallback(
                (...args) =>
                  dispatch(connectedClientsActions.handleChangePage(...args)),
                [dispatch]
              )}
              rowsPerPageOptions={useSelector(
                connectedClientsSelectors.getRowsPerPageOptions
              )}
              handleChangeRowsPerPage={useCallback(
                (...args) =>
                  dispatch(
                    connectedClientsActions.handleChangeRowsPerPage(...args)
                  ),
                [dispatch]
              )}
              filter
              filters={useSelector(connectedClientsSelectors.getFilters)}
              handleFilterChange={useCallback(
                (...args) =>
                  dispatch(connectedClientsActions.handleSearchFilter(...args)),
                [dispatch]
              )}
              formikRow={args => {
                return {
                  enableReinitialize: true,
                  initialValues: {
                    id: get(args, "id"),
                    noa_status: get(args, "noa_status"),
                    discount_rate: get(args, [
                      "broker_relation",
                      "discount_rate"
                    ]),
                    broker_portion: get(args, [
                      "broker_relation",
                      "factoring_fee_split_rate"
                    ])
                  },
                  onSubmit: async (values, { setSubmitting }) => {
                    try {
                      await dispatch(
                        updateRelationship(values.id, {
                          noa_status: values.noa_status,
                          broker_portion: values.broker_portion
                        })
                      );
                      dispatch(
                        openSnackbar("success", "Connected Carrier Updated!")
                      );
                    } catch (err) {
                      dispatch(openSnackbar("error", head(err)));
                    } finally {
                      setSubmitting(false);
                    }
                  }
                };
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      {dialog.variant === "confirm" &&
        dialog.action === "remove_factoring_company_member" && (
          <ConfirmDialog
            title={dialog.title}
            open={dialog.show}
            message={dialog.message}
            handleConfirm={async () => {
              try {
                await dispatch(
                  removeRelationship(get(dialog, ["data", "carrierId"]))
                );
                dispatch(openSnackbar("success", `Member has been unlinked.`));
              } catch (e) {
                dispatch(openSnackbar("error", e));
              } finally {
                dispatch(queryConnectedClients(id));
                dispatch(closeDialog());
              }
            }}
            handleExit={() => {
              dispatch(closeDialog());
            }}
          />
        )}
    </InternalLayout>
  );
};

import React from "react";
import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import SaveIcon from "@material-ui/icons/Save";

import Colors from "../../../../theme/colors";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../components/Dialog";
import RoundedButton from "../../../../components/buttons/RoundedButton";
import { SUCCESS, ERROR } from "../../../../helpers/Modals";
import CustomTextField from "../../../../components/inputs/CustomTextField";
import UserTypeInput from "../../../../components/inputs/UserTypeInput";

const GridColumn = ({ children, numbers }) => (
  <Grid container direction="column" item xs={numbers}>
    {children}
  </Grid>
);

export default ({ open, handleClose, callback, openModal, values, action }) => (
  <Dialog open={open} maxWidth="sm">
    <DialogTitle onClose={handleClose}>
      {`${action === "edit_admin_user_form" ? "Edit" : "Create"} Admin`}
    </DialogTitle>
    <Formik
      onSubmit={async (fields, formikActions) => {
        try {
          await callback(fields);
          await handleClose();
          openModal(
            SUCCESS,
            `Admin has been ${
              action === "edit_admin_user_form" ? "updated" : "created"
            } successfully.`
          );
        } catch (e) {
          openModal(
            ERROR,
            `Error while ${
              action === "edit_admin_user_form" ? "updating" : "creating"
            } Admin.`
          );
        }
        formikActions.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={values}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <React.Fragment>
            <DialogContent>
              <Grid
                container
                spacing={16}
                alignItems="center"
                style={{ padding: 10 }}
              >
                <GridColumn numbers={12}>
                  <CustomTextField
                    fullWidth
                    name="email"
                    label="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </GridColumn>
                <GridColumn numbers={12}>
                  <CustomTextField
                    fullWidth
                    name="name"
                    label="Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </GridColumn>
                <GridColumn numbers={12}>
                  <UserTypeInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </GridColumn>
              </Grid>
            </DialogContent>
            <DialogActions>
              <RoundedButton
                variant="contained"
                color="primary"
                progressStyle={{ color: Colors.primary }}
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                <SaveIcon />
                Save
              </RoundedButton>
            </DialogActions>
          </React.Fragment>
        );
      }}
    </Formik>
  </Dialog>
);

import { denormalize } from "normalizr";
import { get } from "lodash";

import { debtorProfile } from "../../../api/schema";

const getDebtor = state => {
  try {
    const item = get(state, "debtor.id");

    const denormalized = denormalize(
      { item },
      { item: debtorProfile },
      state.entities
    );

    return denormalized.item;
  } catch (err) {
    return {};
  }
};

export default getDebtor;

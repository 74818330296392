import colors from "../theme/colors";

const statusColor = (status: string): string => {
  switch (status) {
    case "new":
      return colors.green;
    case "paid":
      return colors.green;
    case "declined":
      return colors.danger;
    default:
      return "black";
  }
};

export default statusColor;

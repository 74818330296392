import React, { ChangeEventHandler } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { get, map } from "lodash";
import { FormikErrors, FormikTouched } from "formik";
import InfiniteScroll from 'react-infinite-scroller';

import BoldInput from "../inputs/BoldInput";
import Colors from "../../theme/colors";
import formatDate from "../../helpers/format/formatDate";
import FileView, { File } from "./FileView";
import AttachmentsView from "./AttachmentsView";
import openFileDialog from "../../helpers/openFileDialog";

const ACTIONS = {
  EDIT: "EDIT",
  CREATE: "CREATE"
};

interface LoadAttachmentPayload {
  attachmentId: string;
  attachment: string;
}

interface Attachments {
  [index: string]: {
    isLoading: boolean;
    url: string;
  };
}

interface FormValues {
  note: string;
  attachments: File | undefined;
}

export interface Note {
  id: string;
  attachments: string[];
  url: string;
  posted_by_name: string;
  note: string;
  created: string;
}

interface Props {
  values: {
    note: string;
    attachments?: File;
  };
  errors: FormikErrors<FormValues>;
  touched: FormikTouched<FormValues>;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  isFetching: boolean;
  handleSubmit: () => void;
  notes: Note[];
  isCreatingNote: boolean;
  isUpdatingNote: boolean;
  status: string;
  setStatus: (status: string) => void;
  loadNotesAttachments?: ({
    attachmentId,
    attachment
  }: LoadAttachmentPayload) => Promise<string>;
  notesAttachments: Attachments;
  uploadBtnRef?: any;
  handleDeleteNote: (id: string) => Promise<void>;
  loadMore?: Function;
  hasMore?: boolean;
}

const Notes = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
  notes = [],
  isFetching,
  isCreatingNote,
  loadNotesAttachments,
  notesAttachments = {},
  isUpdatingNote,
  uploadBtnRef = React.createRef(),
  handleDeleteNote,
  status,
  setStatus,
  loadMore = () => {},
  hasMore = false
}: Props): JSX.Element => {
  return (
    <Grid container direction="column" spacing={32}>
      <Grid item style={{ width: "100%" }}>
        <Grid container spacing={16} direction="column">
          <Grid item>
            <BoldInput
              value={values.note}
              label="Notes"
              name="note"
              fullWidth
              multiline
              rows={3}
              error={errors.note && touched.note}
              FormHelperTextProps={{
                error: errors.note && touched.note
              }}
              hasError={errors.note && touched.note}
              helperText={errors.note}
              onBlur={handleBlur}
              disabled={isCreatingNote || isUpdatingNote}
              onChange={handleChange}
            />
            <FileView values={values} />
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={isCreatingNote || isUpdatingNote}
                  onClick={(): void => openFileDialog(uploadBtnRef)}
                >
                  <FontAwesomeIcon icon={faPaperclip} style={{ margin: 5 }} />
                  Add File
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={isCreatingNote || isUpdatingNote}
                  onClick={(): void => handleSubmit()}
                >
                  {status === ACTIONS.EDIT ? `Update ` : `Save `}
                  Note
                  {(isCreatingNote || isUpdatingNote) && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      size="sm"
                      spin
                      color={Colors.green}
                      style={{ marginLeft: 5 }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
            <input
              type="file"
              id="attachments"
              name="attachments"
              ref={ref => (uploadBtnRef = ref)}
              style={{ display: "none" }}
              onBlur={(): void => setFieldTouched("attachments", true)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setFieldValue(
                  "attachments",
                  get(e, ["currentTarget", "files", "0"])
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Typography variant="subtitle2">Recent Notes</Typography>
        <Divider variant="fullWidth" />
        {isFetching && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              margin: 20
            }}
          >
            <CircularProgress
              size={35}
              color="primary"
              style={{ margin: 20 }}
            />
          </div>
        )}
        <List dense>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore as (page: number) => void}
            hasMore={hasMore}
          >
            {map(
              notes,
              (note): JSX.Element => {
                return (
                  <ListItem>
                    <AttachmentsView
                      note={note}
                      loadNotesAttachments={loadNotesAttachments}
                      notesAttachments={notesAttachments}
                    />
                    <ListItemText
                      primary={(
                        <Grid container direction="row" spacing={8}>
                          <Grid item style={{ color: "black" }}>
                            {`${get(note, "posted_by_name") ||
                              get(note, "posted_by.name")}:`}
                          </Grid>
                          <Grid item>
                            <div className="dont-break-out">
                              <p style={{ maxHeight: "200px", overflowY: "scroll", padding: "10px", border: "1px solid #eeeeee" }}>
                                {get(note, "note")}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                      secondary={formatDate(get(note, "created", ""))}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        className="delete"
                        aria-label="Delete"
                        onClick={(): Promise<void> =>
                          handleDeleteNote(get(note, "id"))
                        }
                      >
                        <FontAwesomeIcon
                          size="sm"
                          color={Colors.danger}
                          icon={faTrashAlt}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="Edit"
                        className="edit"
                        onClick={(): void => {
                          setStatus("EDIT");
                          setFieldValue("note", get(note, "note"));
                          setFieldValue("notesID", get(note, "id"));
                        }}
                      >
                        <FontAwesomeIcon size="sm" icon={faEdit} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              }
            )}
          </InfiniteScroll>
        </List>
      </Grid>
    </Grid>
  );
};

export default Notes;

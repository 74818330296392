import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  FETCH_CONNECTED_GROUPS_FAILURE,
  FETCH_CONNECTED_GROUPS_SUCCESS,
  FETCH_CONNECTED_GROUPS_REQUEST
} from "../types";

const fetchGroup = () => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_CONNECTED_GROUPS_REQUEST)());
    const response = await api.groups.getConnectedGroups();
    const data = normalize(response, [schema.group]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_CONNECTED_GROUPS_SUCCESS)({
        items: data.result,
        count: response.count,
        next: response.next,
        previous: response.previous
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_CONNECTED_GROUPS_FAILURE)(err));
    throw err;
  }
};

export default fetchGroup;

import React, { useState } from "react";
import { Button, Fade, Grid, Popper } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

interface ExportButtonProps {
  children: React.ReactNode;
}

function ExportButton({ children }: ExportButtonProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | EventTarget & HTMLElement>(
    null
  );
  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="left-start"
        transition
        style={{ paddingRight: 40 }}
      >
        {({ TransitionProps }): JSX.Element => (
          <Fade {...TransitionProps} timeout={100}>
            {children}
          </Fade>
        )}
      </Popper>
      <Button
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
          setAnchorEl(event.currentTarget);
          setOpen(!open);
        }}
      >
        <Grid container spacing={8}>
          <Grid item>
            <FontAwesomeIcon icon={faFilePdf} />
          </Grid>
          <Grid item>Report</Grid>
        </Grid>
      </Button>
    </>
  );
}

export default ExportButton;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  CREATE_FACTORINGNOTE_FAILURE,
  CREATE_FACTORINGNOTE_REQUEST,
  CREATE_FACTORINGNOTE_SUCCESS
} from "../types";

const createFactoringNote = (factoringId, note, attachments) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(CREATE_FACTORINGNOTE_REQUEST)());
    const response = await api.factoring.createFactoringClientNote(
      factoringId,
      note,
      attachments
    );
    const data = normalize(response, schema.notes);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(CREATE_FACTORINGNOTE_SUCCESS)({
        [factoringId]: [data.result]
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(CREATE_FACTORINGNOTE_FAILURE)(err));
    throw err;
  }
};

export default createFactoringNote;

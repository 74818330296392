import { head } from "lodash";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import { openSnackbar } from "../../ui";

const transferToMaster = (id, selected) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    // dispatch(createAction(FETCH_DEBTOR_REQUEST)({}));
    const response = await api.debtors.updateDebtor(id, { master: true });

    const master = normalize(response, schema.debtorProfile);
    dispatch(addEntities(master.entities));

    const responses = await Promise.all(
      selected.map(select =>
        api.debtors.updateDebtor(select, { transferred_to: id })
      )
    );

    const data = normalize(responses, [schema.debtorProfile]);
    dispatch(addEntities(data.entities));

    dispatch(openSnackbar("success", "Transferred Debtors!"));
  } catch (err) {
    openSnackbar("error", head(err));
  }
};

export default transferToMaster;

import { PayloadAction } from "redux-starter-kit";
import { FETCH_DEBTORHISTORY_FAILURE, FETCH_DEBTORHISTORY_REQUEST, FETCH_DEBTORHISTORY_SUCCESS, State } from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case FETCH_DEBTORHISTORY_REQUEST:
      return {
        ...state,
        loadingHistory: true
      };
    case FETCH_DEBTORHISTORY_SUCCESS:
      return {
        ...state,
        loadingHistory: false,
        history: action.payload
      };
    case FETCH_DEBTORHISTORY_FAILURE:
      return {
        ...state,
        loadingHistory: false
      };
    default:
      return state;
  }
};

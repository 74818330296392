import { createAction } from 'redux-starter-kit';
import { normalize } from 'normalizr';

import fetchAll from '../../../helpers/fetchAll';
import { addEntities } from '../../entities';
import {
  FETCH_FACTORINGATTACHMENTS_FAILURE,
  FETCH_FACTORINGATTACHMENTS_REQUEST,
  FETCH_FACTORINGATTACHMENTS_SUCCESS,
} from '../types';

const fetchAttachments =
  (id) =>
  async (dispatch, getState, { api, schema }) => {
    try {
      dispatch(createAction(FETCH_FACTORINGATTACHMENTS_REQUEST)());
      const response = await fetchAll(() =>
        api.factoring.getAttachmentFromProfile(id)
      ) || {};
      const data = normalize(response, [schema.attachment]);
      dispatch(addEntities(data.entities));
      dispatch(
        createAction(FETCH_FACTORINGATTACHMENTS_SUCCESS)({
          [id]: data.result,
        })
      );
      return response;
    } catch (err) {
      dispatch(createAction(FETCH_FACTORINGATTACHMENTS_FAILURE)(err));
      throw err;
    }
  };

export default fetchAttachments;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
    FETCH_QUERY_OBLIGATION_REQUEST,
    FETCH_QUERY_OBLIGATION_SUCCESS,
    FETCH_QUERY_OBLIGATION_FAILURE
} from "../types";

const fetchObligationById = (contractId, obligationId) => async (dispatch, getState, { api, schema }) => {
    try {
        dispatch(createAction(FETCH_QUERY_OBLIGATION_REQUEST)({}));
        const response = await api.factoring.getObligationsById(contractId, obligationId);
        const data = normalize([response], [schema.obligations]);
        dispatch(addEntities(data.entities));
        dispatch(createAction(FETCH_QUERY_OBLIGATION_SUCCESS)(response));
    } catch (err) {
        dispatch(createAction(FETCH_QUERY_OBLIGATION_FAILURE)(err));
        throw err;
    }
};

export default fetchObligationById;

import { OPEN_EDIT_LINEITEM, CLOSE_EDIT_LINEITEM } from "../types";

const editLineItem = (state, { type, payload }) => {
  switch (type) {
    case OPEN_EDIT_LINEITEM:
      return {
        ...state,
        editLineItem: {
          show: true,
          id: payload.id,
          data: payload.data,
        }
      };
    case CLOSE_EDIT_LINEITEM:
      return {
        ...state,
        editLineItem: {
          show: false,
          id: undefined,
          data: {},
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default editLineItem;

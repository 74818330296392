import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../components/Dialog";
import { closeDialog } from "../../../../modules/ui";

export default function({
  open,
  index = 1,
  factoringId,
  openCreateCompanyModal,
  handleSubmit,
  handleExit,
  setNotesForm,
  ...props
}) {
  const dispatch = useDispatch();
  const handleClose = useCallback(async args => dispatch(closeDialog()));
  const [view, setView] = useState(index);
  const [notes, setNotes] = useState("");
  const handleConfirm = useCallback(
    async args => {
      if (view === 2) {
        setNotesForm(notes);
        handleClose();
        return openCreateCompanyModal({ ...props, factoringId });
      }
      if (view === 1) {
        handleSubmit && handleSubmit();
        return setView(3);
      }
      if (view === 5) {
        handleSubmit && handleSubmit();
      }
      handleClose();
    },
    [view, notes]
  );

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose} />
      <DialogContent>
        <Grid container spacing="24">
          {view === 1 && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Are you sure you want to proceed with a direct payment option that you have selected?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                Your profile is currently connected to a factoring company with one or more brokers on our platform.
                    This request will only remove the factoring company with the broker you selected. Any other brokers connected to your
                    factoring company will stay connected.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                In order to process this request we will need an LOR from your factoring company, more information will be sent via email.
                    Please confirm that you want to proceed.

                </Typography>
              </Grid>
            </>
          )}
          {view === 2 && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Are you sure you want to change the factor tied to your
                  profile?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Enter a message about your new factor
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={e => setNotes(e.target.value)}
                  fullWidth
                  placeholder="Enter Your Message"
                  multiline
                  rows={6}
                  rowsMax={10}
                />
              </Grid>
            </>
          )}
          {view === 3 && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Your Request to change from your factoring company to a direct quick pay option has been processed.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                Our Invited Carrier Team has sent an email to you and the broker requesting the LOR from your factoring company AND a
                    copy of a voided check or bank statement listing your business name, business address as listed on FMCSA,
                    and full account and routing number.                 </Typography>
              </Grid>
                            <Grid item xs={12}>
                <Typography variant="body1">
                Please note that until the required documents and information are received and confirmed, all payments
                    will continue to be sent to your factoring company.          </Typography>
              </Grid>
                            <Grid item xs={12}>
                <Typography variant="body1">
                Thank you - The Invited Carrier Team @ HaulPay      </Typography>
              </Grid>

            </>
          )}
          {view === 4 && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Your Request to change from your factoring company has been received.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  If you provided an LOR from your previous factor and an NOA from your new factor, our Invited Carrier Team will be working on confirming these docs and will update your profile ASAP.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  If you did not provide these docs, we will be reaching out to both factoring companies requesting these documents.                    
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Please note that until the required documents and information are received and confirmed, all payments will continue to be sent to your current factoring company.                
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Thank you - The Invited Carrier Team @ HaulPay
                </Typography>
              </Grid>
            </>
          )}
          {view === 5 && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Are you sure you want to change to a new Quick Pay option for
                  this broker connection?
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      {view !== 3 && view !== 4 ? (
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
              handleExit && handleExit();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() =>
              handleConfirm({ factoring_id: factoringId, note: notes })
            }
          >
            Confirm
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            color="primary"
            onClick={() =>
              handleConfirm({ factoring_id: factoringId, note: notes })
            }
          >
            OK
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

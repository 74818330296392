/* global google */
import React, { Component } from "react";
import _ from "lodash";

import GoogleApi from "./google";

function withAutoComplete(WrappedComponent) {
  return class AutoComplete extends Component {
    static specificity = {
      // street_number, street, neighborhood, city, county, state, country, zip, zip_suffix
      ADDRESS: "address",
      // zip, city, county, state, country
      REGION: "(regions)",
      // city, county, state, country
      CITY: "(cities)",
      // any
      ANY: null
    };

    static saveAddress(addressesList, { label, ...value }, withState) {
      if (
        addressesList.length &&
        _.filter(addressesList, object => value.place_id === object.place_id)
          .length === 0
      ) {
        window.localStorage.setItem(
          withState ? "state" : "address",
          _.concat(addressesList, [{ ...value, label }])
        );
      }
      if (_.isEmpty(addressesList)) {
        window.localStorage.setItem(withState ? "state" : "address", [
          { ...value, label }
        ]);
      }
    }

    static getSavedAddress(string) {
      let addressesList = [];
      try {
        addressesList = window.localStorage.getItem(string) || [];
      } catch (e) {}
      return addressesList || [];
    }

    constructor(props) {
      super(props);

      this.googleApi = new GoogleApi();
      this.state = {
        sessionToken: new google.maps.places.AutocompleteSessionToken()
      };
      this.getSuggestionDetails = this.getSuggestionDetails.bind(this);
      this.FilterPlaces = this.FilterPlaces.bind(this);
      this.updateGoogleSessionToken = this.updateGoogleSessionToken.bind(this);
    }

    getSuggestionDetails(suggestion, session) {
      return this.googleApi.getPlaceDetails(suggestion.place_id, session);
    }

    async FilterPlaces(value, withState) {
      const { sessionToken } = this.state;
      try {
        const places = await this.googleApi.getPlacePredictions(
          value,
          withState
            ? AutoComplete.specificity.CITY
            : AutoComplete.specificity.ANY,
          sessionToken
        );
        return places.map(suggestion => ({
          place_id: suggestion.place_id,
          description: suggestion.description,
          label: suggestion.description
        }));
      } catch (err) {}
      return null;
    }

    updateGoogleSessionToken() {
      this.setState({
        sessionToken: new google.maps.places.AutocompleteSessionToken()
      });
    }

    render() {
      return (
        <WrappedComponent
          saveAddress={AutoComplete.saveAddress}
          getSavedAddress={AutoComplete.getSavedAddress}
          FilterPlaces={this.FilterPlaces}
          getSuggestionDetails={this.getSuggestionDetails}
          updateGoogleSessionToken={this.updateGoogleSessionToken}
          {...this.props}
        />
      );
    }
  };
}

export default withAutoComplete;

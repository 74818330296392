import { assign } from "lodash";
import {
  DELETE_CLIENTNOTE_REQUEST,
  DELETE_CLIENTNOTE_SUCCESS,
  DELETE_CLIENTNOTE_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case DELETE_CLIENTNOTE_REQUEST:
      return {
        ...state,
        loadingClientNotes: true
      };
    case DELETE_CLIENTNOTE_SUCCESS:
      return {
        ...state,
        loadingClientNotes: false,
        clientNotes: assign({}, state.clientNotes, {
          [action.payload.factoringId]: state.clientNotes[
            action.payload.factoringId
          ].filter(note => {
            return note !== action.payload.note;
          })
        })
      };
    case DELETE_CLIENTNOTE_FAILURE:
      return {
        ...state,
        loadingClientNotes: false
      };
    default:
      return state;
  }
};

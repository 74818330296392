import { filter, get, isUndefined, memoize } from "lodash";
import { denormalize } from "normalizr";
import { notes } from "../../../api/schema";

export default factorCompanyId =>
  memoize(state => {
    try {
      const factorCompanyNotes = get(
        state,
        ["factorCompany", "factorCompanyNotes", factorCompanyId],
        []
      );

      const denormalized = denormalize(
        { factorCompanyNotes },
        { factorCompanyNotes: [notes] },
        state.entities
      );

      return filter(
        denormalized.factorCompanyNotes,
        note => !isUndefined(note)
      );
    } catch (err) {
      return [];
    }
  });

import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faGasPump,
  faReceipt
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt, faSave } from "@fortawesome/free-regular-svg-icons";
import React from "react";
import colors from "../../../../../theme/colors";

const Save = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.green_dark} icon={faSave} />;
};

const Trash = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />;
};

const Gas = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.primary} icon={faGasPump} />;
};

const Receipt = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.gray} icon={faReceipt} />;
};

interface Props {
  handleSubmit: () => void;
  handleAuditLogClick: () => void;
  handleDeleteRelation: () => void;
  handleFuelAdvance: () => void;
  handleInvoicingSettings: () => void;
}

export default ({
  handleSubmit,
  handleAuditLogClick,
  handleDeleteRelation,
  handleFuelAdvance,
  handleInvoicingSettings
}: Props): JSX.Element => (
  <Grid container>
    <Grid item>
      <Tooltip title="Audit Trail">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={(): void => handleAuditLogClick()}
        >
          <FontAwesomeIcon color={colors.link} icon={faHistory} />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Save Updates">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={(): void => handleSubmit()}
        >
          <Save />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Delete Relationship">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={handleDeleteRelation}
        >
          <Trash />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Fuel Advance">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={(): void => handleFuelAdvance()}
        >
          <Gas />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Invoicing Debtor Settings">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={(): void => handleInvoicingSettings()}
        >
          <Receipt />
        </IconButton>
      </Tooltip>
    </Grid>
  </Grid>
);

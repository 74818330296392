import {
  capitalize,
  isArray,
  join,
  memoize,
  replace,
  split,
  startsWith
} from "lodash";

export default memoize((key, prefix) => {
  const newKey = join(
    split(key, "'")
      .filter((element, index) => index % 2 === 1)
      .map(capitalize),
    " : "
  );

  let finalKey = newKey;

  if (isArray(prefix)) {
    prefix.forEach(pre => {
      if (prefix.length > 0 && startsWith(newKey.toLowerCase(), pre)) {
        finalKey = replace(newKey, capitalize(pre), "");
      }
    });
  }

  if (
    !isArray(prefix) &&
    prefix.length > 0 &&
    startsWith(newKey.toLowerCase(), prefix)
  ) {
    finalKey = replace(newKey, capitalize(prefix), "");
  }

  return finalKey;
});

import { withStyles } from "@material-ui/styles";
import {
  Paper,
  Grid,
  Tab as MuiTab,
  Tabs,
  createStyles,
  Typography
} from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import colors from "../../../theme/colors";
import Badge from "../../../components/Badge";
import withTabs from "../../../components/withTabs";

const Tab = withStyles(
  createStyles({
    root: {
      minWidth: 0,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    labelContainer: {
      padding: 16
    }
  })
)(MuiTab);

const userTabs = withTabs(
  ({
    tabs = [],
    Components,
    variant = "standard",
    handleChange,
    index = 0
  }) => (
    <>
      <Tabs
        value={index}
        variant={variant}
        indicatorColor="primary"
        textColor="primary"
        centered
        onChange={handleChange}
      >
        {tabs.map(tab => (
          <Tab
            label={
              tab.badge ? (
                <Grid container justify="center">
                  <div style={{ paddingRight: "6px" }}>{tab.label}</div>
                  <Badge count={tab.badge} />
                </Grid>
              ) : (
                tab.label
              )
            }
          />
        ))}
      </Tabs>
      <div style={{ width: "100%" }}>{Components[index]}</div>
    </>
  )
);

// @ts-ignore
export default userTabs;

import { get } from "lodash";
import { denormalize } from "normalizr";
import { reserveAggregate } from "../../../api/schema";
import getUserId from "./getUserId";

const getActiveReserve = state => {
  try {
    const id = getUserId(state);
    const reserve = get(state, `factoringClient.reserveAggregates.${id}`);

    const denormalized = denormalize(
      { reserve },
      { reserve: reserveAggregate },
      state.entities
    );
    return denormalized.reserve.total_reserve;
  } catch (err) {
    return 0;
  }
};

export default getActiveReserve;

import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSave } from "@fortawesome/free-regular-svg-icons";
import React from "react";
import colors from "../../../../../theme/colors";

const Save = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.green_dark} icon={faSave} />;
};

const Trash = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />;
};

interface Props {
  handleSubmit: () => void;
  handleDeleteRelation: () => void;
}

export default ({ handleSubmit, handleDeleteRelation }: Props): JSX.Element => (
  <Grid container>
    <Grid item>
      <Tooltip title="Save Updates">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={(): void => handleSubmit()}
        >
          <Save />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Delete Relationship">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={handleDeleteRelation}
        >
          <Trash />
        </IconButton>
      </Tooltip>
    </Grid>
  </Grid>
);

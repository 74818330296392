import { bindActionCreators } from "redux";
import qs from "qs";
import { compose, lifecycle, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";
import { find, findIndex, get, merge, head, keyBy } from "lodash";
import { DateTime } from "luxon";
import withSelect from "../../../../components/withSelect";
import { payment as paymentSchema } from "./validation";

import {
  createPaymentsFile,
  createReceivableCSV,
  fetchFundingRequestReceivables,
  getDebtors,
  getIsUploading,
  getOutstandingTotals,
  getPurchaseItems,
  getPurchases,
  getReceivables,
  getReceivablesById,
  receivablesActionsTable,
  selectorsRecivablesTable
} from "../../../../modules/finances";

import {
  createLineItem,
  fetchLineItemCategories,
  fetchLineItems,
  getLineItemCategories,
  getLineItems
} from "../../../../modules/factoring";

import {
  createDebtorPayment,
  getIsLoading as openFundingRequestsIsLoading,
  getOpenFundingRequests
} from "../../../../modules/debtor";

import { openModal } from "../../../../modules/modal";

import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../modules/ui";

import Receivables from "./Receivables";
import tabs from "../tabs";
import { getUserType } from "../../../../modules/auth";

const paymentForm = (
  selected,
  receivables,
  openSnackbar,
  debtorId,
  createDebtorPayment,
  clearSelected,
  receivablesById,
  refresh
) => ({
  // enableReinitialize: true,
  validationSchema: paymentSchema(selected),
  initialValues: {
    paid_date: "",
    type: "",
    sch: "",
    amount: 0,
    notes: "",
    bank_description: "",
    ...receivables.reduce(
      (acc, curr) =>
        merge({}, acc, { [curr.id]: Number(curr.amount_balance) / 100 }),
      {}
    )
  },
  onSubmit: async (values, { setSubmitting }) => {
    try {
      const data = {
        amount: Math.round(Number(values.amount) * 100),
        category: values.type,
        sch: values.sch,
        paid_date: values.paid_date,
        notes: values.notes.length > 0 ? values.notes : undefined,
        funding_requests: selected.map(id => ({
          id: get(receivablesById, [id, "funding_request", "id"]),
          amount_applied: Math.round(Number(values[id]) * 100)
        }))
      };
      await createDebtorPayment(debtorId, data);
      await refresh();
      clearSelected();
      openSnackbar("success", "Applied Debtor Payment!");
    } catch (err) {
      openSnackbar("error", head(err));
    } finally {
      setSubmitting(false);
    }
  }
});

const mapStateToProps = (
  state,
  { selected = [], selectedReceivable = {} }
) => ({
  userType: getUserType(state),
  receivables: getReceivables(state),
  isLoading: selectorsRecivablesTable.getIsLoading(state),
  sortDirection: selectorsRecivablesTable.getSortDirection(state),
  sortBy: selectorsRecivablesTable.getSortBy(state),
  count: selectorsRecivablesTable.getCount(state),
  page: selectorsRecivablesTable.getPage(state),
  rowsPerPage: selectorsRecivablesTable.getRowsPerPage(state),
  rowsPerPageOptions: selectorsRecivablesTable.getRowsPerPageOptions(state),
  filters: selectorsRecivablesTable.getFilters(state),
  openFundingRequests: getOpenFundingRequests(state),
  openFundingRequestsIsLoading: openFundingRequestsIsLoading(state),
  paymentsIsUploading: getIsUploading(state),
  purchases: getPurchases(state),
  purchaseItems: getPurchaseItems(state),
  LineItemCategories: getLineItemCategories(state),
  dialog: getDialog(state),
  outstandingTotals: getOutstandingTotals(state),
  debtors: getDebtors(state),
  selectedReceivables: getReceivablesById(selected)(state),
  receivableLineItems: getLineItems(
    get(selectedReceivable, ["funding_request", "id"], "")
  )(state)
});

const mapDispatchToProps = (dispatch, { selectedReceivable = {} }) =>
  bindActionCreators(
    {
      handleSort: receivablesActionsTable.handleSort,
      handleChangePage: receivablesActionsTable.handleChangePage,
      handleChangeRowsPerPage: receivablesActionsTable.handleChangeRowsPerPage,
      handleSearchFilter: receivablesActionsTable.handleSearchFilter,
      createReceivableCSV,
      fetchLineItemCategories,
      closeDialog,
      openDialog,
      openModal,
      fetchFundingRequestReceivables,
      fetchLineItems,
      createDebtorPayment,
      createPaymentsFile,
      openSnackbar,
      createLineItem: createLineItem(
        get(selectedReceivable, ["funding_request", "id"], "")
      )
    },
    dispatch
  );

export default compose(
  withSelect,
  withStateHandlers(
    ({
      datetime_end = DateTime.local(),
      datetime_start = DateTime.local(),
      paymentMethod = " ",
      newDocsBadges = {},
      selectedReceivable
    }) => ({ datetime_end, datetime_start, paymentMethod, selectedReceivable }),
    {
      onDateStartChange: () => datetime_start => ({ datetime_start }),
      onDateEndChange: () => datetime_end => ({ datetime_end }),
      onPaymentMethodChange: () => paymentMethod => ({ paymentMethod }),
      setNewDocsBadges: () => newDocsBadges => ({ newDocsBadges }),
      setSelectedReceivable: () => selectedReceivable => ({
        selectedReceivable
      })
    }
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      clearSelected,
      createReceivableCSV,
      filters,
      rowsPerPage,
      location,
      fetchFundingRequestReceivables,
      selected,
      handleSearchFilter,
      receivables = [],
      createDebtorPayment,
      openSnackbar
    }) => {
      const byId = keyBy(receivables, "id");
      const debtors = selected.map(id => get(byId, [id, "debtor"]));
      const singleDebtorSelected = new Set(debtors).size == 1;

      return {
        byId,
        tabs,
        statusIndex: findIndex(tabs, tab => pathname === tab.route) || 0,
        filterStatus: (find(tabs, tab => pathname === tab.route) || {}).value,
        handleSearchFilter: (...args) => {
          handleSearchFilter(...args);
          clearSelected();
        },
        handleRoute: route => push(route),
        handleCSV: async () => {
          try {
            await createReceivableCSV(filters, selected);
            clearSelected();
          } catch (e) {
            openSnackbar("error", "Error while exporting data.");
          }
        },
        handleSetStatus: async () => {
          clearSelected();
        },
        refresh: () => {
          const query = qs.parse(get(location, "search"), {
            ignoreQueryPrefix: true
          });
          fetchFundingRequestReceivables("-time_posted", rowsPerPage, query);
        },
        debtorId: head(debtors),
        singleDebtorSelected
      };
    }
  ),
  lifecycle({
    async componentDidMount() {
      const {
        rowsPerPage,
        fetchFundingRequestReceivables,
        location
      } = this.props;
      const query = qs.parse(get(location, "search"), {
        ignoreQueryPrefix: true
      });
      await fetchFundingRequestReceivables("-time_posted", rowsPerPage, query);
    },
    async componentDidUpdate(prevProps) {
      const {
        statusIndex,
        fetchLineItemCategories,
        fetchFundingRequestReceivables,
        dialog,
        rowsPerPage,
        filters
      } = this.props;
      if (prevProps.dialog !== dialog && dialog.action === "Adjustments") {
        await fetchFundingRequestReceivables(
          "-time_posted",
          rowsPerPage,
          filters
        );
        fetchLineItemCategories();
      }
      if (prevProps.statusIndex !== statusIndex) {
        await fetchFundingRequestReceivables(
          "-time_posted",
          rowsPerPage,
          filters
        );
      }
    }
  })
)(Receivables);

import React from "react";
import { Field, Form } from "formik";
import MailIcon from "@material-ui/icons/Mail";
import {
  Card,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import colors from "../../theme/colors";
import ProgressButton from "../../components/buttons/ProgressButton";
import PasswordInput from "../../components/inputs/PasswordInput";
import PaperSheet from "../../components/PaperSheet";

const styles = {
  rootInputStyle: {
    color: colors.primary
  },
  inputStyle: {
    color: colors.textInputColor
  },
  notchedOutline: {
    borderColor: `${colors.secondary} !important`
  },
  progressClasses: {
    color: `${colors.primary} !important`
  }
};

export default withStyles(styles)(
  ({
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    classes,
    authLoading,
    errorServer
  }) => (
    <Form
      style={{
        minWidth: "300px"
      }}
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        spacing={24}
      >
        <Grid item style={{ width: "100%", paddingRight: 17 }}>
          <Typography variant="h5">Reset Password</Typography>
          <Typography variant="body1">
            {`We'll email you a link to reset your password`}
          </Typography>
        </Grid>
        {errorServer && (
          <div style={{ margin: "10px", width: "100%" }}>
            <PaperSheet variant="error" message={errorServer} />
          </div>
        )}
        <Grid item style={{ width: "100%", paddingRight: 17 }}>
          <Field name="email">
            {({ field: { value, ...field }, hasError = !!errors.email }) => (
              <TextField
                id="email"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.rootInputStyle,
                    input: classes.inputStyle,
                    notchedOutline: classes.notchedOutline
                  }
                }}
                label="Email"
                placeholder="Email"
                value={value}
                helperText={hasError ? errors.email : null}
                error={hasError}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Field>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignContent="center"
          alignItems="center"
          direction="column"
          spacing={8}
        >
          <Grid item>
            <ProgressButton
              id="signin"
              variant="contained"
              btnColor="primary"
              style={{
                borderRadius: 60,
                color: "#fff"
              }}
              disabled={!!authLoading}
              size="large"
              type="submit"
              onClickHandler={handleSubmit}
              progressClasses={classes.progressClasses}
            >
              Reset Password
            </ProgressButton>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
);

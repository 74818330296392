import { createAction } from "redux-starter-kit";
import {
  EXCHANGE_PLAID_TOKEN_FAILURE,
  EXCHANGE_PLAID_TOKEN_REQUEST,
  EXCHANGE_PLAID_TOKEN_SUCCESS
} from "../types";

const exchangePlaidToken = (token, accounts) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(EXCHANGE_PLAID_TOKEN_REQUEST)());
    const response = await api.plaid.exchangeToken(token, accounts);
    dispatch(createAction(EXCHANGE_PLAID_TOKEN_SUCCESS)(response));
  } catch (err) {
    dispatch(createAction(EXCHANGE_PLAID_TOKEN_FAILURE)(err));
  }
};

export default exchangePlaidToken;

import { assign, get, concat } from "lodash";

import {
  FETCH_FACTORINGNOTES_REQUEST,
  FETCH_FACTORINGNOTES_SUCCESS,
  FETCH_FACTORINGNOTES_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_FACTORINGNOTES_REQUEST:
      return {
        ...state,
        loadingNotes: true
      };
    case FETCH_FACTORINGNOTES_SUCCESS:
      const id = Object.keys(action.payload)[0];
      return {
        ...state,
        loadingNotes: false,
        notes: assign({}, state.notes, {
          [id]: concat(
            get(state, ["notes", id], []),
            action.payload[id].results
          )
        }),
        nextNote: assign({}, state.nextNote, {
          [id]: action.payload[id].next
        })
      };
    case FETCH_FACTORINGNOTES_FAILURE:
      return {
        ...state,
        loadingNotes: false
      };
    default:
      return state;
  }
};

import React from "react";
import MoneyColor from "../../../../../components/MoneyColor";
import formatPennies from "../../../../../helpers/format/formatPennies";

const columns = [
  {
    key: "lineItem",
    name: "Line Item"
  },
  {
    key: "amount",
    name: "Amount",
    format: (amount: number): JSX.Element => (
      <MoneyColor amount={amount}>{formatPennies(amount)}</MoneyColor>
    )
  },
  {
    key: "amountApplied",
    name: "Amount Applied"
  },
  {
    key: "balance",
    name: "Balance",
    format: (amount: number): JSX.Element => (
      <MoneyColor amount={amount}>{formatPennies(amount)}</MoneyColor>
    )
  },
  {
    key: "invoiceNumber",
    name: "Invoice #"
  },
  {
    key: "age",
    name: "Age"
  },
  {
    key: "action",
    name: ""
  }
];

export default columns;

import { createAction } from "redux-starter-kit";
import {
  CONFIRM_INVITE_FAILURE,
  CONFIRM_INVITE_REQUEST,
  CONFIRM_INVITE_SUCCESS
} from "../types";

const confirmInvite = (token, createRelation = false) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(CONFIRM_INVITE_REQUEST)());
    const response = await api.groups.confirmInvite(token, createRelation);
    dispatch(createAction(CONFIRM_INVITE_SUCCESS)(response));
    return response;
  } catch (err) {
    if (err.response) {
      dispatch(createAction(CONFIRM_INVITE_FAILURE)(err.response.data));
    } else if (err.request) {
      dispatch(createAction(CONFIRM_INVITE_FAILURE)(err.request));
    } else {
      dispatch(createAction(CONFIRM_INVITE_FAILURE)(err.message));
    }
    dispatch(createAction(CONFIRM_INVITE_FAILURE)(err));
  }
};

export default confirmInvite;

import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import colors from '../../../../../theme/colors';
import Can from 'apps/haulpay-frontend/src/components/Can';

const Trash = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />;
};

const Save = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon color={colors.green_dark} icon={faSave} />;
};

interface Props {
  handleSubmit: () => void;
  handleAuditLogClick: () => void;
  handleDeleteRelation: () => void;
}

export default ({
  handleSubmit,
  handleAuditLogClick,
  handleDeleteRelation,
}: Props): JSX.Element => (
  <Grid container>
    <Can
      perform="admin-debtor-profile-connected_clients:auditTrail"
      yes={() => (
        <Grid item>
          <Tooltip title="Audit Trail">
            <IconButton
              style={{ width: '48px', height: '48px' }}
              onClick={handleAuditLogClick}
            >
              <FontAwesomeIcon color={colors.link} icon={faHistory} />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    />
    {handleSubmit && (
      <Can
        perform="admin-debtor-profile-connected_clients:save"
        yes={() => (
          <Grid item>
            <Tooltip title="Save Updates">
              <IconButton
                style={{ width: '48px', height: '48px' }}
                onClick={handleSubmit}
              >
                <Save />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      />
    )}
    <Can
      perform="admin-debtor-profile-connected_clients:deleteRelationship"
      yes={() => (
        <Grid item>
          <Tooltip title="Delete Relationship">
            <IconButton
              style={{ width: '48px', height: '48px' }}
              onClick={handleDeleteRelation}
            >
              <Trash />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    />
  </Grid>
);

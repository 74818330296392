import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import React, { useState } from 'react';
import { faCropAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import AttachmentLinks from '../AttachmentLinks';
import Colors from '../../theme/colors';
import BoldInput from '../inputs/BoldInput';
import { updateAttachment } from '../../api/factoring';
import Can from '../Can';

const Trash = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon icon={faTrashAlt} color={Colors.danger} size="xs" />;
};

const New = (): JSX.Element => {
  // @ts-ignore
  return <Typography style={{ color: Colors.green }}>NEW!!</Typography>;
};

const Crop = (): JSX.Element => {
  // @ts-ignore
  return (
    <FontAwesomeIcon icon={faCropAlt} color={Colors.secondary} size="xs" />
  );
};

const Check = (): JSX.Element => {
  // @ts-ignore
  return (
    <FontAwesomeIcon icon={faCheckCircle} color={Colors.green} size="xs" />
  );
};

interface Props {
  id: string;
  filename: string;
  category: string;
  handleDelete: () => void;
  preview_url?: string;
  download_url?: string;
  is_new?: boolean;
  openEditToolDialog: (url: string) => void;
  display_name?: string;
  requestID?: string;
  updateDisplayName?: boolean;
  visible_to?: string[];
  broker?: boolean;
  source?: boolean;
  uploadedBy?: string;
  uploaded?: string;
}

const FileItem = ({
  id,
  filename,
  category,
  handleDelete,
  preview_url,
  download_url,
  is_new,
  openEditToolDialog,
  display_name,
  requestID = '',
  updateDisplayName,
  visible_to,
  broker = false,
  source = false,
  uploadedBy,
  uploaded,
}: Props): JSX.Element => {
  let visible;
  if (visible_to) {
    if ((visible_to.includes('shipper') || visible_to.includes('debtor')) && visible_to.includes('carrier')) {
      visible = 'both';
    } else if (visible_to.includes('shipper') || visible_to.includes('debtor')) {
      visible = 'shipper';
    } else if (visible_to.includes('carrier')) {
      visible = 'carrier';
    }
  }

  const [displayInput, setDisplayInput] = useState(false);
  const [visibility, setVisibility] = useState(visible);
  const [inputValue, setInputValue] = useState(display_name);
  return (
    <Grid container direction="column">
      <Grid item>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={source ? 4 : 6}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Grid container spacing={8}>
                  <Grid item>
                    {displayInput && updateDisplayName ? (
                      <div
                        style={{
                          maxWidth: 240,
                          padding: 5,
                        }}
                      >
                        <BoldInput
                          value={inputValue}
                          onBlur={(): void => {
                            setTimeout((): void => {
                              setDisplayInput(false);
                            }, 500);
                          }}
                          onChange={(value: any): void =>
                            setInputValue(value.target.value)
                          }
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          maxWidth: 240,
                        }}
                        onClick={(): void => setDisplayInput(true)}
                      >
                        <Tooltip title={filename}>
                          <>
                            <Typography noWrap>
                              {inputValue || display_name || filename}
                            </Typography>
                            {uploaded && (
                              <Typography>
                                {moment(uploaded).format('l LTS')}
                              </Typography>
                            )}
                          </>
                        </Tooltip>
                      </div>
                    )}
                  </Grid>
                  {Boolean(is_new) && (
                    <Grid item>
                      <New />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {preview_url && download_url ? (
                <Grid item>
                  {displayInput && (
                    <IconButton
                      onClick={async (): Promise<any> => {
                        await updateAttachment(requestID, id, {
                          display_name: inputValue,
                        });
                      }}
                    >
                      <Check />
                    </IconButton>
                  )}
                  <Can
                    perform="admin-attachments:deleteAttachments"
                    yes={() => (
                      <IconButton onClick={(): void => handleDelete()}>
                        <Trash />
                      </IconButton>
                    )}
                  />
                  <Can
                    perform="admin-attachments:attachmentLinks"
                    yes={() => (
                      <AttachmentLinks
                        attachment={{
                          preview_url,
                          download_url,
                        }}
                      />
                    )}
                  />
                  <Can
                    perform="admin-attachments:cropping"
                    yes={() =>
                      filename.match(/\.(jpeg|jpg|gif|png)$/) != null && (
                        <IconButton
                          onClick={(): void => openEditToolDialog(download_url)}
                        >
                          <Crop />
                        </IconButton>
                      )
                    }
                  />
                </Grid>
              ) : (
                <Grid item>
                  <Typography
                    variant="caption"
                    color="error"
                    style={{ padding: 16 }}
                  >
                    Failed Upload
                  </Typography>
                  <IconButton onClick={(): void => handleDelete()}>
                    <Trash />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={source ? 4 : 6}>
            <Tooltip title={filename}>
              <Typography variant="caption" align="center">
                {category}
              </Typography>
            </Tooltip>
          </Grid>
          {source && (
            <Grid
              container
              item
              xs={source ? 4 : 6}
              justify="center"
              alignItems="center"
            >
              <Typography>{uploadedBy}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {broker && (
        <Grid item>
          <RadioGroup
            row
            value={visibility}
            onChange={async (event) => {
              // @ts-ignore
              const value = event.target.value;
              if (value === 'both') {
                await updateAttachment(requestID, id, {
                  visible_to: ['carrier', 'shipper', 'debtor'],
                });
                setVisibility('both');
              } else if (value === 'carrier') {
                await updateAttachment(requestID, id, {
                  visible_to: ['carrier'],
                });
                setVisibility('carrier');
              } else if (value === 'shipper') {
                await updateAttachment(requestID, id, {
                  visible_to: ['shipper', 'debtor'],
                });
                setVisibility('shipper');
              }
            }}
          >
            <FormControlLabel
              control={<Radio />}
              value="carrier"
              label="Carrier Only"
            />
            <FormControlLabel
              control={<Radio />}
              value="shipper"
              label="Debtor/Shipper Only"
            />
            <FormControlLabel
              control={<Radio />}
              value="both"
              label="Carrier & Debtor"
            />
          </RadioGroup>
        </Grid>
      )}
    </Grid>
  );
};

export default FileItem;

import moment from "moment";

export const getPeriods = period => {
  const periods = {
    monthly: {
      datetime_end: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      datetime_start: moment()
        .startOf("month")
        .format("YYYY-MM-DD[T]HH:mm:ss")
    },
    weekly: {
      datetime_end: moment()
        .startOf("week")
        .isoWeekday(7)
        .format("YYYY-MM-DD[T]HH:mm:ss"),
      datetime_start: moment()
        .startOf("week")
        .isoWeekday(1)
        .format("YYYY-MM-DD[T]HH:mm:ss")
    },
    daily: {
      datetime_end: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      datetime_start: moment()
        .startOf("day")
        .format("YYYY-MM-DD[T]HH:mm:ss")
    }
  };
  return periods[period];
};

export const getLastPeriods = period => {
  const periods = {
    monthly: {
      datetime_end: moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD[T]HH:mm:ss"),
      datetime_start: moment()
        .startOf("month")
        .subtract(1, "month")
        .format("YYYY-MM-DD[T]HH:mm:ss")
    },
    weekly: {
      datetime_end: moment()
        .startOf("week")
        .isoWeekday(7)
        .subtract(1, "month")
        .format("YYYY-MM-DD[T]HH:mm:ss"),
      datetime_start: moment()
        .startOf("week")
        .isoWeekday(1)
        .subtract(1, "month")
        .format("YYYY-MM-DD[T]HH:mm:ss")
    },
    daily: {
      datetime_end: moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD[T]HH:mm:ss"),
      datetime_start: moment()
        .startOf("day")
        .subtract(1, "month")
        .format("YYYY-MM-DD[T]HH:mm:ss")
    }
  };
  return periods[period];
};

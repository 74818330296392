import { assign } from "lodash";
import {
  FETCH_QUERY_DEBTORS_FAILURE,
  FETCH_QUERY_DEBTORS_REQUEST,
  FETCH_QUERY_DEBTORS_SUCCESS
} from "../types";

const sameRequest = (state, payload) =>
  state.status === payload.status &&
  state.limit === payload.limit &&
  state.offset === payload.offset &&
  state.ordering === payload.ordering;

export default (
  state = {
    loading: false,
    ids: [],
    count: 0,
    offset: 0,
    limit: 20,
    status: undefined,
    ordering: undefined,
    rowsPerPageOptions: [5, 10, 20, 50, 100, 200]
  },
  action
) => {
  switch (action.type) {
    case FETCH_QUERY_DEBTORS_REQUEST:
      return assign({}, state, {
        ids: [],
        loading: true,
        status: action.payload.status,
        limit: action.payload.limit,
        offset: action.payload.offset,
        ordering: action.payload.ordering,
        filters: action.payload.filters
      });
    case FETCH_QUERY_DEBTORS_SUCCESS:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count
        });
      }
      return state;
    case FETCH_QUERY_DEBTORS_FAILURE:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          loading: false
        });
      }
      return state;
    default:
      return state;
  }
};

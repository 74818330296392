import React from "react";
import { Grid, InputAdornment } from "@material-ui/core";
import { get } from "lodash";

import Steps from "../../../../../../../components/Steps";
import DebtorInput from "../../../../../../../components/inputs/DebtorInput";
import AutoCompleteAddress from "../../../../../../../components/inputs/AutoCompleteAddress";
import equipmentTypes from "../../../../../../../helpers/equipmentTypes";
import BoldInput, {
  CheckBox,
  Pennies,
  Select
} from "../../../../../../../components/inputs/BoldInput";

interface Props {
  accounts?: any[];
  disabled?: boolean;
  steps: any[];
  status: string;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: Function;
  handleBlur: Function;
  setFieldTouched: Function;
  errors: any;
  touched: any;
  factoringId: string;
  generateLoadNumbers: boolean;
}

function FundingRequest({
  accounts,
  disabled,
  steps,
  status,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  setFieldTouched,
  errors,
  touched,
  factoringId,
  generateLoadNumbers
}: Props): JSX.Element {
  const debtorDeclined =
    get(values, ["debtor", "credit_approved"], "") === "declined";
  return (
    <React.Fragment>
      <div style={{ paddingTop: "16px" }}>
        <Steps steps={steps} activeStep={status} />
      </div>
      <Grid
        container
        direction="column"
        style={{ margin: "auto", padding: 16 }}
        spacing={16}
      >
        <Grid item>
          <Pennies
            label="Total Invoice Amount"
            name="amount"
            id="amount"
            value={values.amount}
            setFieldValue={setFieldValue}
            withStyle
            fullWidth
            disabled={disabled}
            error={touched.amount && !!errors.amount}
            helperText={touched.amount ? errors.amount : ""}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <DebtorInput
            label="Bill To Company"
            fullWidth
            name="debtor"
            id="debtor"
            value={values.debtor}
            setFieldValue={(name: string, value: any): void => {
              setFieldValue("billToCompany", value.id);
              setFieldValue("billToCompanyPhone", value.phone_number);
              setFieldValue("billToCompanyEmail", value.email);
              setFieldValue("debtor", value);
            }}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            disabled={disabled}
            factoringId={factoringId}
            helperText={
              debtorDeclined &&
              "This debtor is declined you can only submit this as Non-Factored."
            }
            error={debtorDeclined}
          />
        </Grid>
        {!generateLoadNumbers && (
          <Grid item style={{ width: "100%" }}>
            <BoldInput
              label="Load Number"
              name="loadNumber"
              id="loadNumber"
              value={values.loadNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              disabled={disabled}
              error={touched.loadNumber && !!errors.loadNumber}
              helperText={touched.loadNumber ? errors.loadNumber : ""}
            />
          </Grid>
        )}
        <Grid item style={{ width: "100%" }}>
          <AutoCompleteAddress
            label="First Pick Up"
            fullWidth
            name="firstPickUp"
            id="firstPickUp"
            value={values.firstPickUp}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            withStyle
            disabled={disabled}
            error={touched.firstPickUp && !!errors.firstPickUp}
            helperText={touched.firstPickUp ? errors.firstPickUp : ""}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <AutoCompleteAddress
            label="Final Delivery"
            fullWidth
            name="finalDelivery"
            id="finalDelivery"
            value={values.finalDelivery}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            withStyle
            disabled={disabled}
            error={touched.finalDelivery && !!errors.finalDelivery}
            helperText={touched.finalDelivery ? errors.finalDelivery : ""}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <BoldInput
            fullWidth
            name="clientNotes"
            label="Notes From Client:"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.clientNotes}
            disabled={disabled}
            error={touched.clientNotes && !!errors.clientNotes}
            helperText={touched.clientNotes ? errors.clientNotes : ""}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <BoldInput
            fullWidth
            name="internalTransactionNotes"
            label="Internal Transaction Notes"
            rows="4"
            multiline
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.internalTransactionNotes}
            disabled={disabled}
            error={touched.internalTransactionNotes && !!errors.internalTransactionNotes}
            helperText={touched.internalTransactionNotes ? errors.internalTransactionNotes : ""}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Select
            disabled={disabled}
            value={values.equipmentType}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            label="Equipment Type"
            name="equipmentType"
            selections={equipmentTypes.map(({ value, name }) => ({
              value,
              text: name
            }))}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <BoldInput
            fullWidth
            name="loadLength"
            label="Load Length:"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.loadLength}
            InputProps={{
              endAdornment: <InputAdornment position="end">ft</InputAdornment>
            }}
            disabled={disabled}
            error={touched.loadLength && !!errors.loadLength}
            helperText={touched.loadLength ? errors.loadLength : ""}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Select
            disabled={disabled}
            value={values.payment_method}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            label="Payment Method"
            name="payment_method"
            selections={(accounts || [])
              .map(({ method, account_number_redacted }) => ({
                value: method.toLowerCase(),
                text: `${method} (****${account_number_redacted})`
              }))}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <CheckBox
            disabled={disabled}
            value={values.nonFactored}
            name="nonFactored"
            setFieldValue={setFieldValue}
            label="Non-Factored?"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default FundingRequest;

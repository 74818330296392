import { assign, concat, get, merge } from "lodash";
import { PayloadAction } from "redux-starter-kit";
import {
  FETCH_RECEIVABLENOTES_SUCCESS,
  State,
  UPDATE_RECEIVABLENOTES_SUCCESS
} from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case FETCH_RECEIVABLENOTES_SUCCESS:
      return {
        ...state,
        receivableNotes: assign({}, state.receivableNotes, action.payload)
      };
    case UPDATE_RECEIVABLENOTES_SUCCESS:
      return {
        ...state,
        receivableNotes: merge({}, state.receivableNotes, {
          [action.payload.id]: concat(
            get(state, ["receivableNotes", action.payload.id], []),
            action.payload.data
          )
        })
      };
    default:
      return state;
  }
};

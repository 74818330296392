import React, { ChangeEventHandler } from "react";
import { Person } from "@material-ui/icons";
import { MenuItem, TextField } from "@material-ui/core";

import AvatarCard from "../../../../../components/AvatarCard";
import CustomTextField from "../../../../../components/inputs/CustomTextField";

const statuses = [
  {
    value: "verified",
    name: "Verified"
  },
  {
    value: "not_verified",
    name: "Not Verified"
  },
];

const statusColor = (status: string): string => {
  switch (status) {
    case "verified":
      return "#4dc527";
    case "not_verified":
      return "#d0021b";
    default:
      return "#d0021b";
  }
};

interface Props {
  values: {
    profileVerified: string;
  };
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
  disabled: boolean;
}

const ProfileVerifiedCard = ({
  values,
  handleChange,
  handleBlur,
  disabled
}: Props): JSX.Element => (
  <AvatarCard
    title="Profile Verified"
    color={statusColor(values.profileVerified)}
    icon={<Person style={{ fontSize: "32px" }} />}
    subheader={
      <TextField
        fullWidth
        select
        disabled={disabled}
        name="profileVerified"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.profileVerified}
        InputProps={{
          disableUnderline: true,
          style: { color: "white" }
        }}
>
  {statuses.map(
          (status): JSX.Element => (
            <MenuItem key={status.value} value={status.value}>
              {status.name}
            </MenuItem>
          )
        )}
</TextField>
    }
  />
);

// @ts-ignore
export default ProfileVerifiedCard;

import React, { useEffect, useState } from 'react';
import map from "lodash/map";

import { Select } from "../BoldInput";
import { fetchLineItemCategories } from '../../../api/factoring/contracts';
const LineItemsCategoryInput = ({ value, onBlur, onChange, ...props }) => {
  const [categories, setCategories] = useState([])
  useEffect( () => {
      async function fetchCategory() {
            const res = await fetchLineItemCategories();
            setCategories(res);
      }
      fetchCategory();
  }, []);

  return (
      <Select
        fullWidth
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        selections={map(categories, ({ name = "", id }) => ({
          value: name,
          text: name.toUpperCase()
        }))}
        {...props}
      />
  );
};
export default LineItemsCategoryInput;

import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import "rc-slider/assets/index.css";
import colors from "./colors";
import "./font/font.css";

function pxToRem(value) {
  return `${value / 16}rem`;
}

const breakpoints = createBreakpoints({});

export const themeProperties = {
  breakpoints,
  typography: {
    useNextVariants: true,
    fontFamily: "Avenir-Medium",
    color: colors.grayText
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white"
    },
    secondary: {
      main: colors.secondary
    },
    text: {
      primary: colors.grayText,
      secondary: colors.inputColor,
      disabled: colors.disabledButton
    },
    success: {
      primary: colors.green_light
    },
    textSecondary: {
      main: "#5c6979"
    }
  }
};

export const overrides = {
  MuiExpansionPanelSummary: {
    expanded: {
      color: "#2196f3"
    }
  },
  MuiAvatar: {
    root: {
      fontSize: "0.70rem",
      fontWeight: "700"
    }
  },
  MuiInputBase: {
    // Name of the styleSheet
    root: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "normal",
      colors: "rgba(0, 0, 0, 0.38)"
    },
    inputType: {
      padding: "11px 0 7px"
    }
  },
  MuiFormLabel: {
    root: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.38)"
    },
    focused: {
      color: "#2196f3"
    }
  },
  MuiTypography: {
    headline: {
      fontSize: pxToRem(24),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(20)
      }
    },
    title: {
      fontSize: pxToRem(21),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(24)
      }
    },
    body1: {
      fontSize: pxToRem(14),

      [breakpoints.up("md")]: {
        fontSize: pxToRem(16)
      }
    },
    body2: {
      fontSize: pxToRem(14),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(16)
      }
    },
    button: {
      fontSize: pxToRem(14),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(16)
      }
    }
  },
  MuiButton: {
    label: {
      textTransform: "capitalize"
    }
  },
  MuiStepLabel: {
    alternativeLabel: {
      "&$completed": {
        color: colors.grayText,
        fontSize: 14
      },
      "&$active": {
        color: colors.secondary,
        fontSize: 14
      }
    },
    label: {
      fontSize: 14
    }
  },
  MuiStepIcon: {
    root: {
      "&$completed": {
        color: colors.primary
      },
      "&$active": {
        color: colors.primary
      }
    },
    text: {
      fill: colors.white,
      fontSize: 14
    }
  },
  MuiStepConnector: {
    lineHorizontal: {
      borderTopWidth: 2
    },
    line: {
      borderColor: colors.primary
    }
  },
  MuiSwitch: {
    checked: {
      color: `${colors.primary}!important`,
      "& + $bar": {
        backgroundColor: `${colors.primary}!important`
      }
    },
    switchBase: {
      color: colors.secondaryText,
      "& + $bar": {
        backgroundColor: colors.secondaryText,
        opacity: 0.4
      }
    }
  },
  MuiTab: {
    root: {
      "&:hover": {
        backgroundColor: colors.secondary,
        opacity: 0.8
      },
      "&$tabSelected": {
        color: colors.secondary
      },
      "&$selected": {
        color: colors.secondary
      },
      whiteSpace: "unset",
      minWidth: 0
    },
    wrapper: {
      fontSize: "14px",
      letterSpacing: "0.5px",
      textTransform: "capitalize"
    }
  },
  MuiTabs: {
    root: {
      tabsIndicator: {
        backgroundColor: colors.secondary
      }
    }
  }
};

export const theme = {
  breakpoints,
  typography: {
    useNextVariants: true,
    fontFamily: "Avenir-Medium",
    color: colors.grayText
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white
    },
    secondary: {
      main: colors.secondary
    },
    text: {
      primary: colors.grayText,
      secondary: colors.inputColor,
      disabled: colors.disabledButton
    },
    success: {
      primary: colors.green_light
    }
  },
  overrides
};

export default () => createMuiTheme(theme);

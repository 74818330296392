import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_USER_NOTES_FAILURE,
  FETCH_USER_NOTES_REQUEST,
  FETCH_USER_NOTES_SUCCESS
} from "../types";

const fetchClientNotes = userId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_USER_NOTES_REQUEST)());
    const response = await api.user.queryFetchUserNotes(userId);
    const data = normalize(response.results, [schema.notes]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_USER_NOTES_SUCCESS)({
        [userId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_USER_NOTES_FAILURE)(err));
    throw err;
  }
};

export default fetchClientNotes;

import { assign, isEqual } from "lodash";
import { PayloadAction } from "redux-starter-kit";
import {
  FETCH_OPENFUNDINGREQUEST_FAILURE,
  FETCH_OPENFUNDINGREQUEST_REQUEST,
  FETCH_OPENFUNDINGREQUEST_SUCCESS,
  State
} from "../types";

const sameRequest = (state: State, action: PayloadAction): boolean => {
  return isEqual(state.payments.filters, action.payload.filters);
};

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case FETCH_OPENFUNDINGREQUEST_REQUEST:
      return assign({}, state, {
        payments: assign({}, state.payments, {
          id: action.payload.id,
          ids: [],
          loading: true,
          filters: action.payload.filters
        })
      });
    case FETCH_OPENFUNDINGREQUEST_SUCCESS:
      if (sameRequest(state, action)) {
        return assign({}, state, {
          payments: assign({}, state.payments, {
            loading: false,
            ids: action.payload.items,
            count: action.payload.count
          })
        });
      }
      return state;

    case FETCH_OPENFUNDINGREQUEST_FAILURE:
      return assign({}, state, {
        payments: assign({}, state.payments, {
          loading: false
        })
      });
    default:
      return state;
  }
};

import { createAction } from "redux-starter-kit";
import {
  DELETE_DEBTOR_FAILURE,
  DELETE_DEBTOR_REQUEST,
  DELETE_DEBTOR_SUCCESS
} from "../types";

const deleteDebtor = id => async (dispatch, getState, { api }) => {
  try {
    dispatch(
      createAction(DELETE_DEBTOR_REQUEST)({
        id
      })
    );
    await api.debtors.deleteDebtor(id);
    dispatch(
      createAction(DELETE_DEBTOR_SUCCESS)({
        id
      })
    );
  } catch (err) {
    dispatch(createAction(DELETE_DEBTOR_FAILURE)(err));
    throw err;
  }
};

export default deleteDebtor;

import { connect, MapStateToProps } from "react-redux";
import { compose } from "recompose";

import FundingRequests from "./FundingRequests";
import { State } from "../../../../modules/types";

const mapStateToProps: MapStateToProps<{}, {}, State> = (
) => ({});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

const mapDispatchToProps: DispatchProps = {};

export default compose<{} & DispatchProps, {}>(
  connect<{}, DispatchProps, {}, State>(
    mapStateToProps,
    mapDispatchToProps
  )
)(FundingRequests);

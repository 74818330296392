import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Paper } from "@material-ui/core";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import Link from "../../../../components/Link";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import tabs from "../tabs";
import Table from "../../../../components/Table";
import columns from "./columns";
import {
  fundingRequestTableActions,
  fundingRequestTableSelectors,
  queryFundingRequests,
  getFundingRequests
} from "../../../../modules/payment";
import WithSimilarPaymentProfile from "../WithSimilarPaymentProfile";
import formatPennies from "../../../../helpers/format/formatPennies";
import NegativeMarginIcon from "../../../../components/icons/NegativeMarginIcon";
import SelfFinanceIcon from "../../../../components/icons/SelfFinanceIcon";

export default function ConnectedBrokersCarriers(props) {
  const id = get(props, ["match", "params", "id"], "");
  const dispatch = useDispatch();
  const [count] = WithSimilarPaymentProfile(id);
  useEffect(() => {
    dispatch(queryFundingRequests(id, 20, "-created", {}));
  }, [id]);
  const fundingRequests = useSelector(getFundingRequests);
  const filters = useSelector(fundingRequestTableSelectors.getFilters);
  return (
    <Can
      perform="admin-payments:view"
      yes={() => (
        <InternalLayout title="Payment Profile">
          <Grid
            container
            direction="column"
            spacing={24}
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <NavigationTabs tabs={tabs(id, count)} value={1} />
            </Grid>
            <Grid item>
              <Paper style={{ padding: "16px" }}>
                <Table
                  columns={columns}
                  rows={fundingRequests.map(fr => ({
                    ...fr,
                    amount: (
                      <Grid container direction="row" alignItems="center">
                        {formatPennies(fr.amount)}
                        <Grid item>
                          <NegativeMarginIcon
                            factoringAmount={fr.factoring_amount}
                          />
                        </Grid>
                        <SelfFinanceIcon contractType={fr.contract_type} />
                      </Grid>
                    ),
                    client: (
                      <div>
                        <Link
                          to={`/admin/factorclients/${get(
                            fr,
                            ["factoring", "id"],
                            ""
                          )}/profile`}
                        >
                          {get(
                            fr,
                            ["factoring", "company_profile", "name"],
                            ""
                          )}
                        </Link>
                      </div>
                    ),
                    debtor: (
                      <div>
                        <Link
                          to={`/admin/debtors/${get(
                            fr,
                            ["debtor", "id"],
                            ""
                          )}/profile`}
                        >
                          {get(fr, ["debtor", "dba"], "")}
                        </Link>
                      </div>
                    ),
                    invoice: (
                      <div>
                        <Link
                          to={`/admin/fundingrequest/${get(fr, ["id"], "")}`}
                        >
                          {get(fr, ["invoice_number"], "")}
                        </Link>
                      </div>
                    )
                  }))}
                  allowEmpty
                  isLoading={useSelector(
                    fundingRequestTableSelectors.getIsLoading
                  )}
                  handleSort={useCallback(
                    (...args) =>
                      dispatch(fundingRequestTableActions.handleSort(...args)),
                    [dispatch]
                  )}
                  sortDirection={useSelector(
                    fundingRequestTableSelectors.getSortDirection
                  )}
                  sortBy={useSelector(fundingRequestTableSelectors.getSortBy)}
                  count={useSelector(fundingRequestTableSelectors.getCount)}
                  page={useSelector(fundingRequestTableSelectors.getPage)}
                  rowsPerPage={useSelector(
                    fundingRequestTableSelectors.getRowsPerPage
                  )}
                  handleChangePage={useCallback(
                    (...args) =>
                      dispatch(
                        fundingRequestTableActions.handleChangePage(...args)
                      ),
                    [dispatch]
                  )}
                  rowsPerPageOptions={useSelector(
                    fundingRequestTableSelectors.getRowsPerPageOptions
                  )}
                  handleChangeRowsPerPage={useCallback(
                    (...args) =>
                      dispatch(
                        fundingRequestTableActions.handleChangeRowsPerPage(
                          ...args
                        )
                      ),
                    [dispatch]
                  )}
                  filter
                  filters={filters}
                  handleFilterChange={useCallback(
                    (...args) =>
                      dispatch(
                        fundingRequestTableActions.handleSearchFilter(...args)
                      ),
                    [dispatch]
                  )}
                />
              </Paper>
            </Grid>
          </Grid>
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
}

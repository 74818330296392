import React from 'react';
import AttachmentLinks from '../../../../../components/AttachmentLinks';
import { ListItem, ListItemText } from '@material-ui/core';


export default function NOAAttachmentList ({ attachments }) {
    
    return attachments.map(attachment => 
        <ListItem dense>
            <ListItemText primary={attachment.filename} />
            <AttachmentLinks attachment={attachment} />
        </ListItem>
    ).reverse()
}
import React from "react";
import {
  Avatar,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import FolderIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { lifecycle } from "recompose";
import { truncate, get } from "lodash";

interface Item {
  base64: string;
  type: string;
  name: string;
  status: string;
  progress: number;
  path?: string
}

interface Props {
  item?: Item;
}

const UploadFileView = ({ item }: Props): JSX.Element => {
  return (
    <React.Fragment>
      <ListItem
        style={{
          overflow: "hidden"
        }}
        alignItems="flex-start"
      >
        <ListItemAvatar>
          {item && item.base64 && item.base64.startsWith("data:image") ? (
            <Avatar srcSet={item.base64} />
          ) : (
            <Avatar>
              <FolderIcon />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            noWrap: true
          }}
          primary={
            item ? truncate(get(item, "name", item.path), { length: 25, separator: "..." }) : ""
          }
        />
      </ListItem>
      {item && item.status !== "success" && (
        <LinearProgress
          style={{ marginBottom: 10, marginRight: 30, marginLeft: 30 }}
          variant="determinate"
          value={item.progress}
        />
      )}
    </React.Fragment>
  );
}

interface MountProps {
  getBase64FromFile: (item: Item) => void;
  item?: Item;
}
export default lifecycle<MountProps, {}>({
  componentWillMount(): void {
    const { getBase64FromFile, item } = this.props;
    if (item) {
      getBase64FromFile(item);
    }
  }
})(UploadFileView);

import React from 'react';
import { Redirect } from 'react-router-dom';

import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleDown,
  faPen,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import formatPennies from '../../../../helpers/format/formatPennies';

import ReceivablesDetails from './components/ReceivablesDetails';
import InternalLayout from '../../../../components/layouts/InternalLayout';
import Can from '../../../../components/Can';
import NavigationTabs from '../../../../components/NavigationTabs';
import Table from '../../../../components/Table';
import Link from '../../../../components/Link';
import columns from './columns';
import colors from '../../../../theme/colors';
import UploadStatementButton from './components/UploadStatementButton';
import CSVButton from '../../../../components/buttons/CSVButton';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';

interface Props {
  roles: string[];
  snackbar: any;
  closeSnackbar: () => void;
  statusIndex: number;
  tabs: any[];
  payments: any[];
  isLoading: boolean;
  handleSort: Function;
  sortDirection: string;
  sortBy: string;
  page: number;
  rowsPerPage: number;
  handleChangePage: Function;
  handleChangeRowsPerPage: Function;
  rowsPerPageOptions: number[];
  filters: Record<string, string>;
  handleSearchFilter: Function;
  dialog: Record<string, any>;
  openDetails: (id: string) => void;
  count: number;
  handleCSV: (params?: any) => void;
  openConfirmDeletePayment: (
    amount: string | number,
    debtor: string,
    paymentId: string
  ) => void;
  closeConfirmDeletePayment: () => void;
  callDeletePayment: (paymentId: any) => void;
}

export default function Payments({
  dialog,
  tabs,
  statusIndex,
  payments = [],
  isLoading,
  handleSort,
  sortDirection,
  sortBy,
  page,
  count,
  rowsPerPage,
  handleChangePage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  handleSearchFilter,
  filters,
  openDetails,
  handleCSV,
  openConfirmDeletePayment,
  closeConfirmDeletePayment,
  callDeletePayment,
}: Props): JSX.Element {
  const index = statusIndex === -1 ? 0 : statusIndex;
  const rows = payments.map((payment) => ({
    ...payment,
    debtor: (
      <Link to={`/admin/debtors/${payment.source.id}/profile`}>
        {payment.source.dba}
      </Link>
    ),
    more: (
      <>
        <Tooltip title="View Details">
          <IconButton onClick={(): void => openDetails(payment.id)}>
            <FontAwesomeIcon color={colors.link} size="xs" icon={faPen} />
          </IconButton>
        </Tooltip>
        <Can
          perform="admin-finances-payments:exportSinglePayment"
          yes={(): JSX.Element => (
            <Tooltip title="Export CSV">
              <IconButton onClick={(): void => handleCSV({ id: payment.id })}>
                <FontAwesomeIcon
                  icon={faArrowAltCircleDown}
                  color={colors.green}
                  size="sm"
                />
              </IconButton>
            </Tooltip>
          )}
        />
        <Can
          perform="admin-finances-payments:deletePayment"
          yes={(): JSX.Element => (
            <Tooltip title="Delete Payment">
              <IconButton
                className="trash-icon"
                onClick={(): void =>
                  openConfirmDeletePayment(
                    formatPennies(payment?.amount),
                    payment?.source.dba,
                    payment.id
                  )
                }
              >
                <FontAwesomeIcon
                  color={colors.danger}
                  size="xs"
                  icon={faTrashAlt}
                />
              </IconButton>
            </Tooltip>
          )}
        />
      </>
    ),
  }));
  return (
    <Can
      perform="admin-finances-payments:view"
      yes={(): JSX.Element => (
        <InternalLayout title="Finances">
          <React.Fragment>
            <Grid container direction="row">
              <div style={{ margin: 10, display: 'flex', flex: 0.5 }}>
                <Typography variant="h5">Payments</Typography>
              </div>
              <Can
                perform="admin-finances-payments:uploadStatement"
                yes={(): JSX.Element => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      flex: 0.5,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <UploadStatementButton />
                  </div>
                )}
              />
            </Grid>
            <NavigationTabs tabs={tabs} value={index} />
            <Paper style={{ marginTop: 32, padding: 16 }}>
              <Can
                perform="admin-finances-payments:exportPayments"
                yes={(): JSX.Element => (
                  <Grid item container justify="flex-end">
                    <CSVButton handleCSV={handleCSV} />
                  </Grid>
                )}
              />
              <Table
                columns={columns}
                rows={rows}
                isLoading={isLoading}
                handleSort={handleSort}
                sortDirection={sortDirection}
                sortBy={sortBy}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                filter
                filters={filters}
                handleFilterChange={handleSearchFilter}
                allowEmpty
              />
            </Paper>
            {dialog.action === 'confirm-delete-payment' && (
              <ConfirmDialog
                open={dialog.show}
                message={dialog.message}
                handleConfirm={() => {
                  callDeletePayment(dialog.data.paymentId);
                }}
                handleExit={() => {
                  closeConfirmDeletePayment();
                }}
              />
            )}
          </React.Fragment>
          <ReceivablesDetails />
        </InternalLayout>
      )}
      no={(): JSX.Element => <Redirect to="/" />}
    />
  );
}

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_REFERRAL_SOURCE_FAILURE,
  FETCH_REFERRAL_SOURCE_REQUEST,
  FETCH_REFERRAL_SOURCE_SUCCESS
} from "../types";

const fetchReferralSource = () => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_REFERRAL_SOURCE_REQUEST)());
    const response = await api.factoring.fetchReferralSources();
    const data = normalize(response.results, [schema.referralSource]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_REFERRAL_SOURCE_SUCCESS)({
        items: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_REFERRAL_SOURCE_FAILURE)(err.message));
  }
};

export default fetchReferralSource;

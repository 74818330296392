import { createAction } from "redux-starter-kit";
import { fetchLineItemCategories } from "../../factoring";

import {
  CREATE_PAYMENTS_FILE_FAILURE,
  CREATE_PAYMENTS_FILE_REQUEST,
  CREATE_PAYMENTS_FILE_SUCCESS
} from "../types";

export default data => async (dispatch, _, { api }) => {
  try {
    dispatch(createAction(CREATE_PAYMENTS_FILE_REQUEST)());
    await api.payments.uploadPaymentsFile(data);
    dispatch(await fetchLineItemCategories());
    dispatch(createAction(CREATE_PAYMENTS_FILE_SUCCESS)());
  } catch (err) {
    dispatch(createAction(CREATE_PAYMENTS_FILE_FAILURE)(err));
    throw err;
  }
};

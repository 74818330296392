import React, { ChangeEventHandler, useEffect } from "react";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Typography,
  IconButton,
  Tooltip,
  Grid
} from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { head, get } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";

import BoldInput from "../inputs/BoldInput";
import CustomTextField from "../inputs/CustomTextField";
import { DialogActions, DialogTitle, Dialog, DialogContent } from "../Dialog";
import formatDate from "../../helpers/format/formatDate";
import {
  getSelectedRecievableNotes,
  fetchReceivableNotes,
  createFollowupNote,
  updateFollowupNote,
  deleteFollowupNote
} from "../../modules/debtor";
import { closeDialog, getDialog, openSnackbar } from "../../modules/ui";
import { getReceivable, getReceivablesById, fetchReceivable } from "../../modules/finances";
import { getCurrentUserId } from "../../modules/auth";
import colors from "../../theme/colors";

const statuses = (userType: string, status: string = "", followUp?: boolean): any => {
  const statusType = [
          {
            label: 'Document Issue B',
            value: 'document_issue_b',
          },
          {
            label: 'Document Issue C',
            value: 'document_issue_c',
          },
          {
            label: 'Pending Claim',
            value: 'pending_claim',
          },
          { 
            label: "Payment Issue", 
            value: "payment_issue" 
          },
          { 
            label: "Internal CF", 
            value: "internal_cf" 
          }, 
          {
            label: 'Issue',
            value: 'issue',
          },
        ];
  return [
    { value: "resubmit_invoice", label: "Resubmit Invoice" },
    { value: "promise_to_pay", label: "Promised To Pay" },
    { value: "follow_up", label: "Follow Up" },
    { value: "debtor_issue", label: "Debtor Issue" },
    { label: "Document Issue A", value: "document_issue_a" },
    ...(() =>
      !followUp && userType === 'factoring_remote_admin'
        ? statusType
        : userType !== 'factoring_remote_admin' ? statusType : [])(),
    ...(() =>
      status !== "paid"
        ? [{ label: "No Response", value: "no_response" }]
        : [])()
  ]
};

interface categoriesMapType {
  [key: string]: string;
}

const categoriesMap: categoriesMapType = {
  document_issue_a: "Document Issue A",
  document_issue_b: "Document Issue B",
  document_issue_c: "Document Issue C",
  resubmit_invoice: "Resubmit Invoice",
  promise_to_pay: "Promised To Pay",
  debtor_issue: "Debtor Issue",
  internal_cf: "Internal CF",
  follow_up: "Follow Up",
  pending_claim: "Pending Claim"
};

interface StatusInputProps {
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
  value: string;
  userType: string;
  status?: string;
  followUp?: boolean;
}

interface StatusInputObjectProps {
  value: string;
  label: string;
}

const StatusInput = ({
  handleBlur,
  handleChange,
  value,
  userType,
  status,
  followUp
}: StatusInputProps): JSX.Element => (
  <CustomTextField
    fullWidth
    select
    label="Followup Status"
    name="followupStatus"
    onChange={handleChange}
    onBlur={handleBlur}
    value={value}
  >
    {statuses(userType, status, followUp).map(
      (option: StatusInputObjectProps): JSX.Element => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )
    )}
  </CustomTextField>
);

interface FormValues {
  followupDate: string;
  followupStatus: string;
  notes: string;
}

interface Note {
  id: string;
  posted_by: {
    name: string;
    id: string;
  };
  note: string;
  modified: string;
  response_category?: string;
}

interface Props {
  noteForm: FormikConfig<FormValues>;
  receivableId?: string;
  debtorId?: string;
  selected: string[];
  refresh?: Function;
  userType: string;
  fundingRequestIds: string[];
  status?: string;
  followUp?: boolean;
}

interface NoteForm {
  id: string;
  note: string;
  ownerId: string;
  ownerName: string;
  edit: boolean;
}

export default ({
  refresh,
  receivableId,
  debtorId,
  selected,
  userType,
  fundingRequestIds = [],
  status = "",
  followUp = false
}: Props): JSX.Element => {
  const notes = useSelector<any, Note[]>(getSelectedRecievableNotes);
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);

  const receivable = useSelector(getReceivable(receivableId));
  const receivables = useSelector(getReceivablesById(selected));

  const currentUser = useSelector(getCurrentUserId);

  useEffect(() => {
    if (receivableId) {
      dispatch(fetchReceivableNotes(receivableId));
      dispatch(fetchReceivable(receivableId));
    }
  }, [receivableId]);
  return (
    <Dialog open={dialog.show} maxWidth="sm" fullWidth>
      <Formik
        enableReinitialize
        initialValues={{
          followupStatus: "",
          followupDate: " ",
          notes: ""
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (debtorId) {
              // Purchases
              await dispatch(
                createFollowupNote(
                  debtorId,
                  selected,
                  values.notes,
                  values.followupDate.length
                    ? DateTime.fromISO(values.followupDate).toISO()
                    : values.followupDate,
                  values.followupStatus
                )
              );
            } else {
              if (fundingRequestIds.length > 0) {
                await dispatch(
                  createFollowupNote(
                    "",
                    fundingRequestIds,
                    values.notes,
                    values.followupDate.length
                      ? DateTime.fromISO(values.followupDate).toISO()
                      : values.followupDate,
                    values.followupStatus,
                    receivableId
                  )
                );
              }
              // Receivables
              else if (get(receivable, "id")) {
                await dispatch(
                  createFollowupNote(
                    get(receivable, ["funding_request", "debtor", "id"]),
                    [get(receivable, ["funding_request", "id"])],
                    values.notes,
                    values.followupDate.length
                      ? DateTime.fromISO(values.followupDate).toISO()
                      : values.followupDate,
                    values.followupStatus
                  )
                );
              }
              else if (receivables.length > 0) {
                receivables.forEach(async (rec: any) => {
                  await dispatch(
                    createFollowupNote(
                      get(rec, ["debtor"]),
                      [get(rec, ["funding_request", "id"])],
                      values.notes,
                      values.followupDate.length
                        ? DateTime.fromISO(values.followupDate).toISO()
                        : values.followupDate,
                      values.followupStatus
                    )
                  );
                });
              }
            }
            dispatch(openSnackbar("success", "Created Note(s)!"));
          } catch (err) {
            // @ts-ignore
            dispatch(openSnackbar("error", head(err)));
          } finally {
            // if (refresh) {
            //   refresh();
            // }
            // if (get(receivable, "id", receivableId)) {
            //   dispatch(
            //     fetchReceivableNotes(get(receivable, "id", receivableId))
            //   );
            // }
            setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          isSubmitting
        }): JSX.Element => (
          <React.Fragment>
            <DialogTitle
              onClose={(): void => {
                dispatch(closeDialog());
              }}
            >
              Add Notes/Details
            </DialogTitle>
            <DialogContent>
              <List>
                {userType !== "factoring_remote_admin" &&
                  <ListItem>
                    <CustomTextField
                      name="followupDate"
                      label="Followup Date"
                      type="date"
                      value={values.followupDate}
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </ListItem>
                }
                {!followUp && userType === "factoring_remote_admin" &&
                  <ListItem>
                    <CustomTextField
                      name="followupDate"
                      label="Followup Date"
                      type="date"
                      value={values.followupDate}
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </ListItem>
                }
                <ListItem>
                  <StatusInput
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.followupStatus}
                    userType={userType}
                    status={status}
                    followUp={followUp}
                  />
                </ListItem>
                <ListItem>
                  <CustomTextField
                    id="notes"
                    name="notes"
                    label="Notes/Details"
                    fullWidth
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItem>
              </List>
              {notes && notes.length > 0 && (
                <React.Fragment>
                  <Divider variant="middle" />
                  <List>
                    <Typography variant="title">Recent Notes</Typography>
                    {notes.map(
                      (note): JSX.Element => {
                        return (
                          <ListItem>
                            <Formik<NoteForm>
                              enableReinitialize
                              initialValues={{
                                edit: false,
                                id: note.id,
                                note: note.note,
                                ownerId: get(note, ["posted_by", "id"], ""),
                                ownerName: get(note, ["posted_by", "name"])
                              }}
                              onSubmit={async (values, { setFieldValue }) => {
                                try {
                                  await dispatch(
                                    updateFollowupNote(
                                      get(receivable, "id", receivableId),
                                      values.id,
                                      values.note
                                    )
                                  );
                                  dispatch(
                                    openSnackbar("success", "Updated Note!")
                                  );
                                  setFieldValue("edit", false);
                                } catch (err) {
                                  // @ts-ignore
                                  dispatch(openSnackbar("error", head(err)));
                                }
                              }}
                            >
                              {({
                                values,
                                setFieldValue,
                                handleSubmit,
                                handleChange
                              }) => (
                                <ListItemText
                                  // primary={note.note}
                                  primary={
                                    values.edit ? (
                                      <BoldInput
                                        onChange={handleChange}
                                        name="note"
                                        value={values.note}
                                      />
                                    ) : (
                                      note.note
                                    )
                                  }
                                  secondary={(
                                    <Grid container justify="space-between" alignItems="center">
                                      <Grid item>
                                        <b>{`By ${values.ownerName}`}</b>
                                        {` ${formatDate(note.modified)} (${note.response_category ? categoriesMap[
                                            note.response_category
                                          ] : "N/A"
                                          })`}
                                      </Grid>
                                      <Grid item>
                                        {currentUser === values.ownerId &&
                                          !values.edit && (
                                            <Grid container>
                                              <Grid item>
                                                <Tooltip title="Edit your notes">
                                                  <IconButton
                                                    onClick={() =>
                                                      setFieldValue("edit", true)
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faEdit}
                                                      color={colors.secondary}
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              </Grid>
                                              <Grid item>
                                                <Tooltip title="Delete your notes">
                                                  <IconButton
                                                    onClick={async () => {
                                                      try {
                                                        await dispatch(deleteFollowupNote(receivableId, note.id))
                                                        dispatch(
                                                          openSnackbar("success", "Deleted Note!")
                                                        );
                                                      } catch (err) {
                                                        // @ts-ignore
                                                        dispatch(openSnackbar("error", head(err)));
                                                      }
                                                    }}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faTrashAlt}
                                                      color={colors.danger}
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              </Grid>
                                            </Grid>

                                          )
                                        }
                                        {currentUser === values.ownerId && values.edit && (
                                          <Tooltip title="Save">
                                            <IconButton
                                              onClick={() => handleSubmit()}
                                            >
                                              <FontAwesomeIcon
                                                icon={faSave}
                                                color={colors.green}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </Grid>
                                    </Grid>
                                  )}
                                />
                              )}
                            </Formik>
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </React.Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={(event): void => {
                  event.preventDefault();
                  handleSubmit()
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Formik>
    </Dialog>
  );
};

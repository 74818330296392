const columns = (userType: string) => [
  {
    key: 'dba',
    name: 'DBA',
  },
  {
    key: 'owner_profile.email',
    name: 'Contact Email',
  },
  ...(() =>
    userType !== 'factoring_remote_admin'
      ? [
        {
          key: 'discount_rate',
          name: 'Rate',
        },
      ]
      : [])(),
  {
    key: 'time_posted',
    name: 'Created At',
    sort: true,
  },
  ...(() =>
    userType !== 'factoring_remote_admin'
      ? [
        {
          key: 'minimum_fee',
          name: 'Minimum Fee',
        },
      ]
      : [])(),
  {
    key: 'noa_summary',
    name: 'NOA Summary',
  },
  {
    key: 'more',
    name: 'More',
  },
];

export default columns;

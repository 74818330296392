import { normalize } from "normalizr";
import { addEntities } from "../../entities";

const updateReferralSource = reason => async (dispatch, _, { api, schema }) => {
  const response = await api.factoring.updateReferralSource(reason);
  const data = normalize([response], [schema.referralSource]);
  dispatch(addEntities(data.entities));
  return response;
};

export default updateReferralSource;

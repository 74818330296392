import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import colors from "../../../../../../theme/colors";

const UploadFile = () => {
    return <FontAwesomeIcon icon={faCloudUploadAlt} color={colors.purple} />;
};

export default UploadFile;

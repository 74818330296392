import { PayloadAction } from "redux-starter-kit";
import { FETCH_LINEITEMCATEGORIES_SUCCESS, State } from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case FETCH_LINEITEMCATEGORIES_SUCCESS:
      return {
        ...state,
        lineItemCategories: action.payload.categories
      };
    default:
      return state;
  }
};

import { MenuItem } from "@material-ui/core";
import React, { ChangeEventHandler } from "react";
import CustomTextField from "../../../../components/inputs/CustomTextField";

const PaymentTypeArray = [
  {
    value: " ",
    label: "Type"
  },
  {
    value: "wire",
    label: "Wire"
  },
  {
    value: "ach",
    label: "ACH"
  },
  {
    value: "debit",
    label: "Debit"
  }
];

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
}

const PaymentTypeInput = ({ onChange, value }: Props): JSX.Element => (
  <CustomTextField
    fullWidth
    select
    label="Payment Type"
    name="payment_method"
    id="payment_method"
    onChange={onChange}
    value={value}
  >
    {PaymentTypeArray.map(
      (option): JSX.Element => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )
    )}
  </CustomTextField>
);

export default PaymentTypeInput;

import React from "react";
import { Grid, Typography, CircularProgress, Button } from "@material-ui/core";

import { isEmpty } from "lodash";
import { Document, Page, pdfjs } from "react-pdf";
import Pagination from "material-ui-flat-pagination";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import SaveAlt from "@material-ui/icons/Save";

import Layout from "../../components/layouts/Layout";
import Colors from "../../theme/colors";
import CircularButton from "../../components/buttons/CircularButton";
import PaperSheet from "../../components/PaperSheet";

export default class Invoice extends React.Component {
  state = {
    numPages: null,
    pageNumber: 0
  };

  constructor(Props) {
    super(Props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  nextPage(pageNumber) {
    this.setState({ pageNumber });
  }

  loadingPDF = () => (
    <CircularProgress
      size={24}
      style={{
        color: Colors.secondary
      }}
    />
  );

  errorPDF = () => (
    <PaperSheet
      message="Error While Fetching The Invoice File, Please Contact Our Support team."
      variant="error"
    />
  );

  download = url => {
    setTimeout(() => {
      window.open(url);
    }, 100);
  };

  render() {
    const { pageNumber, numPages } = this.state;
    const {
      getCollatedPdf,
      collatedPdf: { loading = false, data = {}, error = null },
      match: {
        params: { token = null }
      }
    } = this.props;
    return (
      <Layout
        headerProps={{
          noTitle: true
        }}
      >
        <Grid
          container
          direction="column"
          style={{ background: "white", margin: "auto", height: "100%" }}
          alignItems="center"
          alignContent="center"
          justify="center"
        >
          {error && (
            <PaperSheet
              message="Error While Fetching The Invoice File, Please Contact Our Support team."
              variant="error"
            />
          )}
          {isEmpty(data) ? (
            <div
              style={{
                height: 400,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularButton
                variant="contained"
                color="primary"
                loading={loading}
                style={{ borderRadius: 40 }}
                onClick={() => getCollatedPdf({ token })}
              >
                <Typography
                  style={{
                    fontFamily: "Avenir",
                    color: Colors.white,
                    fontSize: 22,
                    fontWeight: "100",
                    textTransform: "none",
                    padding: 5
                  }}
                >
                  Click here to view the invoice
                </Typography>
              </CircularButton>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Button
                style={{ alignSelf: "flex-end", margin: 10 }}
                variant="outlined"
                aria-label="Save"
                onClick={() => {
                  this.download(data.download_url);
                }}
              >
                <Typography style={{ padding: 2 }}>Save</Typography>
                <SaveAlt />
              </Button>
              <Pagination
                style={{
                  padding: 10
                }}
                limit={1}
                offset={pageNumber}
                total={numPages}
                onClick={(e, offset) => this.nextPage(offset)}
                currentPageColor={Colors.textInputColor}
                size="large"
                previousPageLabel={<ChevronLeft />}
                nextPageLabel={<ChevronRight />}
              />
              <Document
                file={data.preview_url}
                onLoadSuccess={this.onDocumentLoadSuccess}
                loading={this.loadingPDF()}
                error={this.errorPDF()}
              >
                <Page pageIndex={pageNumber} />
              </Document>
            </div>
          )}
        </Grid>
      </Layout>
    );
  }
}

import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Typography, Grid, IconButton, Tooltip } from '@material-ui/core/index';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faEye, faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import openFileDialog from '../../../../../../components/AttachmentsCard/openFileDialog';
import Colors from '../../../../../../theme/colors';
import { File } from '../../../../../../components/AttachmentsCard';
import { useHasChanged } from '../../../../../../helpers/hooks';
import AttachmentLinks from '../../../../../../components/AttachmentLinks';
import ProfileSection from '../../../../../../components/ProfileSection';
import Can from 'apps/haulpay-frontend/src/components/Can';

interface Props {
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
  files: File[];
  index: number;
  handleDelete: (id: string) => void;
}

const styles = {
  container: {
    borderBottom: '2px solid black',
  },
};

const Trash = (): JSX.Element => {
  // @ts-ignore
  return <FontAwesomeIcon icon={faTrashAlt} color={Colors.danger} size="xs" />;
};

export default function BrokerLogo({
  setFieldTouched,
  setFieldValue,
  values,
  files,
  index,
  handleDelete,
}: Props) {
  const uploadBtnRef = useRef<HTMLInputElement>(null);

  const [logo, setLogo] = useState(files);

  const hasVal1Changed = useHasChanged(files);

  useEffect(() => {
    if (hasVal1Changed) {
      setLogo(files);
    }
  }, [files]);

  return (
    <Grid container direction="column" style={{ padding: 10 }}>
      <ProfileSection>Add Broker Logo to Invoice</ProfileSection>
      <Grid item style={styles.container}>
        <Typography color="error">
          You must delete the current logo before a replacement.
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row">
          {logo.map(
            (attachment, index, attachments): JSX.Element => (
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Tooltip
                    title={attachment.filename || get(attachment, 'name', '')}
                  >
                    <Typography noWrap variant="caption">
                      {attachment.filename || get(attachment, 'name', '')}
                    </Typography>
                  </Tooltip>
                </Grid>
                {attachment.id && (
                  <>
                    <Grid item>
                      <IconButton
                        onClick={(): void => {
                          handleDelete(attachment.id);
                        }}
                      >
                        <Trash />
                      </IconButton>
                      <AttachmentLinks
                        attachment={{
                          preview_url: attachment.preview_url || '',
                          download_url: attachment.download_url || '',
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            )
          )}
        </Grid>
        {!files.length && (
          <Can
            perform="admin-factor_client-profile:AddBrokerLogo"
            yes={() => (
              <Grid container item justify="flex-end">
                <IconButton
                  onClick={(): void => openFileDialog(uploadBtnRef.current)}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color={Colors.grayText}
                  />
                </IconButton>
              </Grid>
            )}
          />
        )}
        <input
          type="file"
          name="attachments-logo"
          ref={uploadBtnRef}
          style={{ display: 'none' }}
          onBlur={(): void =>
            setFieldTouched(`attachments.[${index}].file`, true)
          }
          onChange={(event): void => {
            if (!get(event, 'currentTarget.files', []).length) {
              return;
            }
            setLogo([get(event, 'currentTarget.files[0]', null)]);
            setFieldValue(
              `attachments.[${index}].file`,
              get(event, 'currentTarget.files[0]', null)
            );
            setFieldValue(`attachments.[${index}].category`, 'logo');
          }}
        />
      </Grid>
    </Grid>
  );
}

import {
  createStyles,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  WithStyles
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useCallback, useRef, useState } from "react";
import { Formik, FormikConfig, FormikProps } from "formik";
import {
  faCalendarCheck,
  faCalendarPlus
} from "@fortawesome/free-regular-svg-icons";

import DatePicker from "../inputs/MDatePicker";
import formatDate from "../../helpers/format/formatDate";
import SendNOADialog from "./SendNOADialog";
import getStep from "./getStep";
import Icon from "./Icon";
import Can from "../Can";

interface ButtonProps {
  onClick: () => void;
}

const SentNOA = ({ onClick }: ButtonProps): JSX.Element => (
  <Tooltip title="Resend">
    <IconButton onClick={onClick}>
      <Icon icon={faCalendarCheck} />
    </IconButton>
  </Tooltip>
);

const NotSentNOA = ({ onClick }: ButtonProps): JSX.Element => (
  <Tooltip title="Send">
    <IconButton onClick={onClick}>
      <Icon icon={faCalendarPlus} />
    </IconButton>
  </Tooltip>
);

interface AlreadyActiveProps {
  onClick: (e: any) => void;
}
const AlreadyActive = ({ onClick }: AlreadyActiveProps): JSX.Element => (
  <Tooltip title="Change Active">
    <IconButton onClick={onClick}>
      <Icon icon={faCalendarCheck} />
    </IconButton>
  </Tooltip>
);

interface SetActiveProps {
  onClick: (e: any) => void;
}
const SetActive = ({ onClick }: SetActiveProps): JSX.Element => (
  <Tooltip title="Set Active">
    <IconButton onClick={onClick}>
      <Icon icon={faCalendarPlus} />
    </IconButton>
  </Tooltip>
);

const AlreadyPlaced = (): JSX.Element => (
  <Tooltip title="Change Placed">
    <IconButton>
      <Icon icon={faCalendarCheck} />
    </IconButton>
  </Tooltip>
);

const SetPlaced = (): JSX.Element => (
  <Tooltip title="Set Placed">
    <IconButton>
      <Icon icon={faCalendarPlus} />
    </IconButton>
  </Tooltip>
);

const styles = createStyles({
  root: {
    padding: 8
  }
});

interface Attachment {
  id: string;
  filename: string;
  modified: string | undefined;
  created: string;
  preview_url: string;
  download_url: string;
}

interface FormValues {
  noaToSend: string;
  attachments: Attachment[];
}

interface NOAFormValues {
  active: string;
  placed: string;
}

export type PlaceNOAForm = FormikConfig<FormValues>;
export type NOAActiveForm = FormikConfig<NOAFormValues>;

interface Props extends WithStyles<typeof styles> {
  requested?: string;
  sent?: string;
  placed?: string;
  active?: string;
  placeNOAForm: PlaceNOAForm;
  clientName: string;
  debtorName: string;
  noaActiveForm: NOAActiveForm;
}

function NOASummary({
  classes,
  requested,
  sent,
  placed,
  active,
  placeNOAForm,
  clientName,
  debtorName,
  noaActiveForm
}: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  
  const steps = [
    {
      label: requested ? "NOA Requested" : "NOA Not Requested",
      value: Boolean(requested)
    },
    {
      label: sent ? "NOA Sent" : "NOA Not Sent",
      value: Boolean(sent)
    },
    {
      label: placed ? "NOA Received" : "NOA Not Recieved",
      value: Boolean(placed)
    },
    {
      label: active ? "NOA Active" : "NOA Not Active",
      value: Boolean(active)
    }
  ];

  const dates: (string | undefined)[] = [requested, sent, placed, active];

  const pickerRef = useRef(null);

  const openPicker = useCallback(
    e => {
      if (pickerRef.current == null) {
        return;
      }
      // @ts-ignore
      pickerRef.current.open(e);
    },
    [pickerRef.current]
  );
  return (
    <Formik {...noaActiveForm}>
      {(props: FormikProps<NOAFormValues>): JSX.Element => (
        <React.Fragment>
          <Stepper
            className={classes.root}
            orientation="vertical"
            activeStep={getStep(requested, sent, placed, active)}
            style={{ minWidth: 250 }}
          >
            {steps.map(
              ({ label, value }, i): JSX.Element => (
                <Step key={label}>
                  <StepLabel
                    optional={
                      <Typography variant="caption">
                        {dates[i] && formatDate(dates[i] as string)}
                      </Typography>
                    }
                  >
                    <Grid container spacing={0}>
                      <Grid item md={10} xs={12}>
                        <Typography inline>
                          {label}
                          <Can
                            perform="noa-summary:send"
                            yes={() => (
                              <>
                                {i === 1 &&
                                  (value ? (
                                    <SentNOA
                                      onClick={(): void => setOpen(true)}
                                    />
                                  ) : (
                                    <NotSentNOA
                                      onClick={(): void => setOpen(true)}
                                    />
                                  ))}
                                {i === 3 &&
                                  (value ? (
                                    <AlreadyActive onClick={openPicker} />
                                  ) : (
                                    <SetActive onClick={openPicker} />
                                  ))}
                              </>
                            )}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </StepLabel>
                </Step>
              )
            )}
          </Stepper>
          <DatePicker
            style={{ display: "none" }}
            clearable
            value={props.values?.active}
            onChange={async (dateTime: any): Promise<void> => {
              let activeTime = null;
              if (dateTime) {
                activeTime = dateTime.toISO();
              }
              await props.setFieldValue("active", activeTime);
              await props.submitForm();
            }}
            pickerRef={pickerRef}
          />
          <SendNOADialog
            open={open}
            handleClose={(): void => setOpen(false)}
            clientName={clientName}
            debtorName={debtorName}
            placeNOAForm={placeNOAForm}
          />
        </React.Fragment>
      )}
    </Formik>
  );
}

export default withStyles(styles)(NOASummary);

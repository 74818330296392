import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Header from "../../../components/Header";

const Layout = ({ children }) => (
  <Grid
    container
    direction="column"
    alignItems="stretch"
    style={{ flex: 1, background: "white", margin: "auto" }}
    justify="center"
    item
    lg={8}
    md={8}
    sm={8}
  >
    <Grid item style={{ paddingTop: "32px", paddingBottom: "32px" }}>
      <Header />
    </Grid>
    <Grid item style={{ paddingLeft: "32px", paddingRight: "32px" }}>
      {children}
    </Grid>
  </Grid>
);

Layout.propTypes = {
  children: PropTypes.array.isRequired
};

export default Layout;

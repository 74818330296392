import React, { useCallback, useEffect } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { get, head } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";

import {
  queryConnectedDebtors,
  getConnectedDebtors,
  connectedDebtorsSelectors,
  connectedDebtorsActions,
  fetchGroup,
  getGroup,
  updateDebtorRelationship
} from "../../../../modules/groups";
import { deleteDebtorRelation } from "../../../../modules/factoringClient";
import {
  openSnackbar,
  getDialog,
  closeDialog,
  openDialog
} from "../../../../modules/ui";
import {
  closeSendNOA,
  fetchAttachments,
  getAttachments,
  sendNOA,
  updateNOADates
} from "../../../../modules/factoring";
import BoldInput, { Pennies } from "../../../../components/inputs/BoldInput";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import NOASummary from "../../../../components/NOASummary";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import Table from "../../../../components/Table";
import Link from "../../../../components/Link";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import MoreLinks from "./components/MoreLinks";
import tabs from "../tabs";
import columns from "./columns";
import formatDate from "../../../../helpers/format/formatDate";

const ConnectedDebtor = (props: any): JSX.Element => {
  const dispatch = useDispatch();
  const id = get(props, ["match", "params", "id"], "");
  const group = useSelector(getGroup(id));
  const dialog = useSelector(getDialog);
  const factoringId = get(group, "factoring_id");
  const attachments = useSelector(getAttachments)(factoringId);
  useEffect((): void => {
    dispatch(queryConnectedDebtors(id));
  }, [id]);
  useEffect((): void => {
    dispatch(fetchGroup(id));
  }, [id]);
  useEffect((): void => {
    if (factoringId) {
      dispatch(fetchAttachments(factoringId));
    }
  }, [factoringId]);

  const handleExit = useCallback(() => dispatch(closeDialog()), [
    dispatch
  ]);

  const sortDirection = useSelector(
    connectedDebtorsSelectors.getSortDirection
  );
  const sortBy = useSelector(
    connectedDebtorsSelectors.getSortBy
  );
  const rowsPerPage = useSelector(connectedDebtorsSelectors.getRowsPerPage);
  const filters = useSelector(connectedDebtorsSelectors.getFilters);
  const handleDeleleteRelation = useCallback(
    () =>
      dispatch(
        openDialog(
          "confirm",
          "Are you sure",
          "Delete this debtor's relationship?",
          {
            debtor_id: id
          }
        )
      ),
    [dispatch]
  );
  const debtors = useSelector(getConnectedDebtors).map(
    (debtor: any): JSX.Element => {
      const zip = get(debtor, ["organization", "zip"]);
      const state = get(debtor, ["organization", "state"]);

      return {
        ...debtor,
        companyName: (
          <Link to={`/admin/debtors/${debtor.id}/profile`}>
            {debtor.company_name}
          </Link>
        ),
        stateZip:
          state && zip ? (
            <Typography>{`${state}/${zip}`}</Typography>
          ) : (
            <React.Fragment />
          ),
        rateField: ({ values, handleChange }: any): JSX.Element => (
          <BoldInput
            name="discount_rate"
            value={values.discount_rate}
            onChange={handleChange}
          />
        ),
        minimumFee: ({ values, setFieldValue }: any): JSX.Element => (
          <Pennies
            name="minimum_fee"
            value={values.minimum_fee}
            setFieldValue={setFieldValue}
          />
        ),
        noaSummary: () => {
          const noaAttachments = attachments
            .filter(
              (attachment: any) =>
                attachment.category === "noa" ||
                attachment.category === "comfreight_noa"
            )
            .map((attachment: any) => ({
              ...attachment,
              modified: formatDate(attachment.modified)
            }));

          return (
            <NOASummary
              clientName={group.company_name}
              requested={debtor.noa_requested}
              debtorName={debtor.company_name}
              active={debtor.noa_active}
              sent={debtor.noa_sent}
              placed={debtor.noa_placed}
              placeNOAForm={{
                initialValues: {
                  noaToSend: get(head(noaAttachments), "filename", ""),
                  attachments: noaAttachments
                },
                onSubmit: async (values, { setSubmitting }) => {
                  try {
                    await dispatch(
                      sendNOA(
                        debtor.id,
                        factoringId,
                        undefined,
                        values.noaToSend
                      )
                    );
                    await dispatch(
                      queryConnectedDebtors(
                        id,
                        rowsPerPage,
                        sortDirection === "asc" ? sortBy : `-${sortBy}`,
                        filters
                      )
                    );
                    dispatch(openSnackbar("success", "NOA Sent!"));
                    closeSendNOA();
                  } catch (err) {
                    dispatch(openSnackbar("error", head(err) as string));
                  } finally {
                    setSubmitting(false);
                  }
                }
              }}
              noaActiveForm={{
                initialValues: {
                  active: debtor.noa_active,
                  placed: debtor.noa_placed
                },
                onSubmit: async (values, { setSubmitting }) => {
                  try {
                    await dispatch(
                      updateNOADates()(
                        debtor.id,
                        factoringId,
                        values.active !== ""
                          ? DateTime.fromISO(values.active).toISO()
                          : null,
                        null
                      )
                    );
                    await dispatch(
                      queryConnectedDebtors(
                        id,
                        rowsPerPage,
                        sortDirection === "asc" ? sortBy : `-${sortBy}`,
                        filters
                      )
                    );
                    dispatch(openSnackbar("success", "Updated NOA Dates!"));
                  } catch (err) {
                    openSnackbar("error", head(err) as string);
                  } finally {
                    setSubmitting(false);
                  }
                }
              }}
            />
          );
        },
        more: ({ handleSubmit }: any): JSX.Element => (
          <MoreLinks
            handleSubmit={(): void => handleSubmit()}
            handleDeleteRelation={handleDeleleteRelation}
          />
        )
      };
    }
  );

  return (
    <Can
      perform="admin-groups:view"
      yes={(): JSX.Element => (
        <InternalLayout title="Groups">
          <Grid container direction="column" spacing={16}>
            <Grid item style={{ width: "100%" }}></Grid>
            <Grid item style={{ width: "100%" }}>
              <NavigationTabs tabs={tabs(id)} value={3} />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Paper style={{ padding: "16px" }}>
                <Table
                  formikRow={(debtor: any) => {
                    return {
                      enableReinitialize: true,
                      initialValues: {
                        discount_rate: debtor.discount_rate,
                        minimum_fee: debtor.minimum_fee / 100
                      },
                      onSubmit: async (values: any, { setSubmitting }: any) => {
                        try {
                          await dispatch(
                            updateDebtorRelationship(group.id, {
                              discount_rate: values.discount_rate,
                              minimum_fee: values.minimum_fee * 100,
                              debtor: debtor.id
                            })
                          );
                          dispatch(
                            openSnackbar(
                              "success",
                              "Updated Debtor Relationship!"
                            )
                          );
                        } catch (err) {
                          dispatch(openSnackbar("error", head(err) as string));
                        } finally {
                          setSubmitting(false);
                        }
                      }
                    };
                  }}
                  columns={columns}
                  rows={debtors}
                  isLoading={useSelector(
                    connectedDebtorsSelectors.getIsLoading
                  )}
                  handleSort={useCallback(
                    (...args: any[]) =>
                      dispatch(connectedDebtorsActions.handleSort(...args)),
                    [dispatch]
                  )}
                  sortDirection={useSelector(
                    connectedDebtorsSelectors.getSortDirection
                  )}
                  sortBy={useSelector(connectedDebtorsSelectors.getSortBy)}
                  count={useSelector(connectedDebtorsSelectors.getCount)}
                  page={useSelector(connectedDebtorsSelectors.getPage)}
                  rowsPerPage={useSelector(
                    connectedDebtorsSelectors.getRowsPerPage
                  )}
                  handleChangePage={useCallback(
                    (...args: any[]) =>
                      dispatch(
                        connectedDebtorsActions.handleChangePage(...args)
                      ),
                    [dispatch]
                  )}
                  rowsPerPageOptions={useSelector(
                    connectedDebtorsSelectors.getRowsPerPageOptions
                  )}
                  handleChangeRowsPerPage={useCallback(
                    (...args: any[]) =>
                      dispatch(
                        connectedDebtorsActions.handleChangeRowsPerPage(...args)
                      ),
                    [dispatch]
                  )}
                  filter
                  filters={filters}
                  handleFilterChange={useCallback(
                    (...args: any[]) =>
                      dispatch(
                        connectedDebtorsActions.handleSearchFilter(...args)
                      ),
                    [dispatch]
                  )}
                  allowEmpty
                />
              </Paper>
            </Grid>
          </Grid>
          {dialog.variant === "confirm" && (
            <ConfirmDialog
              open={dialog.show}
              message={dialog.message}
              handleConfirm={async () => {
                try {
                  await dispatch(
                    deleteDebtorRelation(
                      group.factoring_id,
                      get(dialog, ["data", "debtor_id"])
                    )
                  );
                  dispatch(
                    queryConnectedDebtors(
                      id,
                      rowsPerPage,
                      sortDirection === "asc" ? sortBy : `-${sortBy}`,
                      filters
                    )
                  );
                  dispatch(
                    openSnackbar(
                      "success",
                      "Deleted Factoring Debtor Relation!"
                    )
                  );
                } catch (err) {
                  dispatch(openSnackbar("error", head(err) as string));
                } finally {
                  dispatch(closeDialog());
                }
              }}
              handleExit={handleExit}
            />
          )}
        </InternalLayout>
      )}
      no={(): JSX.Element => <Redirect to="/" />}
    />
  );
};

export default ConnectedDebtor;

import {
  CREATE_USER_PROFILE_REQUEST,
  CREATE_USER_PROFILE_SUCCESS,
  CREATE_USER_PROFILE_FAILURE
} from "../types";

const createUser = (state, { type, payload }) => {
  switch (type) {
    case CREATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfileSuccess: payload
      };
    case CREATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default createUser;

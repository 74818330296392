import { denormalize } from "normalizr";
import { paymentProfile } from "../../../api/schema";

export default id => state => {
  try {
    const denormalized = denormalize(
      { paymentProfile: id },
      { paymentProfile },
      state.entities
    );

    return denormalized.paymentProfile;
  } catch (err) {
    return {};
  }
};

import { Button, Grid, Typography, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { ReactNode, useCallback } from "react";
import { find, isUndefined } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import EmptyDocmentsView from "./EmptyDocmentsView";
import DocumentsView from "./DocumentsView";

import { Attachment, Category, File } from "./types";
import CollapseCard from "../CollapseCard";
import { closeDialog, getDialog, openDialog } from "../../modules/ui";
import ImageCropper from "../ImageCropper";
import FileTypeConfirmModal from "../FileTypeConfirmModal";
import ProfileSection from "../ProfileSection";

export * from "./types";

const styles = {
  container: {
    borderBottom: "2px solid black"
  }
};

const showDocuments = (
  filterCategories?: string[]
): ((files: File[]) => boolean) => (files: File[]): boolean => {
  if (filterCategories) {
    return filterCategories
      .map((category: string): boolean =>
        Boolean(find(files, ["category", category]))
      )
      .reduce((curr, acc): boolean => curr || acc, false);
  }
  return false;
};

interface AttachmentsProps extends WithStyles<typeof styles> {
  files: File[];
  uploadBtnRef?: any;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: {
    attachments: Attachment[];
  };
  categories: Category[];
  handleDelete: (id: string) => void;
  filterCategories?: string[];
  multipleCategories?: boolean;
  sortByCategory?: Function;
  requestID?: string;
  updateDisplayName?: boolean;
  broker?: boolean;
  source?: boolean;
  // eslint-disable-next-line react/require-default-props
  defaultCategoryValue?: string;
}

export const Attachments = withStyles(styles)(
  ({
    multipleCategories = false,
    filterCategories,
    files,
    values,
    categories,
    handleDelete,
    uploadBtnRef = React.createRef(),
    setFieldTouched,
    setFieldValue,
    classes,
    sortByCategory,
    requestID,
    updateDisplayName,
    broker = false,
    source = false,
    defaultCategoryValue
  }: AttachmentsProps) => {
    const dispatch = useDispatch();
    const dialog = useSelector(getDialog);
    const openPictureDialog = useCallback(
      (
        variant: string,
        title?: string,
        message?: string,
        data?: any,
        action?: string
      ) => dispatch(openDialog(variant, title, message, data, action)),
      []
    );
    const handleExit = useCallback(() => dispatch(closeDialog()), []);
    return (
      <React.Fragment>
        <Grid
          classes={{
            container: classes.container
          }}
          container
          item
          xs={12}
          direction="row"
        >
          <Grid
            container
            item
            xs={source ? 4 : 6}
            justify="center"
            alignItems="center"
          >
            <Typography>File Name</Typography>
          </Grid>
          <Grid
            container
            item
            xs={source ? 4 : 6}
            justify="center"
            alignItems="center"
          >
            <Button
              onClick={(): void => {
                if (!isUndefined(sortByCategory)) {
                  sortByCategory();
                }
              }}
            >
              {multipleCategories ? (
                <Typography>Categories</Typography>
              ) : (
                <Typography>Category</Typography>
              )}
            </Button>
          </Grid>
          {source && (
            <Grid
              container
              item
              xs={source ? 4 : 6}
              justify="center"
              alignItems="center"
            >
              <Typography>Source</Typography>
            </Grid>
          )}
        </Grid>
        {showDocuments(filterCategories)(files) ||
        (files && files.length) ||
        (values.attachments && values.attachments.length) ? (
          <DocumentsView
            categories={categories}
            handleDelete={handleDelete}
            uploadBtnRef={uploadBtnRef}
            setFieldTouched={setFieldTouched}
            values={values}
            setFieldValue={setFieldValue}
            files={files}
            filterCategories={filterCategories}
            multipleCategories={multipleCategories}
            openDialog={openPictureDialog}
            closeDialog={handleExit}
            dialog={dialog}
            requestID={requestID}
            updateDisplayName={updateDisplayName}
            broker={broker}
            source={source}
            defaultCategoryValue={defaultCategoryValue}
          />
        ) : (
          <EmptyDocmentsView
            uploadBtnRef={uploadBtnRef}
            setFieldTouched={setFieldTouched}
            values={values}
            setFieldValue={setFieldValue}
            openDialog={openPictureDialog}
            closeDialog={handleExit}
            dialog={dialog}
          />
        )}
        {dialog.variant === "picture-edit-tool" && (
          <ImageCropper
            open={dialog.show}
            handleExit={handleExit}
            data={dialog.data}
          />
        )}
        {dialog.variant === "file-type-confirmation" && (
          <FileTypeConfirmModal open={dialog.show} />
        )}
      </React.Fragment>
    );
  }
);

interface Props {
  files: File[];
  uploadBtnRef?: any;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: {
    attachments: Attachment[];
  };
  categories: Category[];
  handleDelete: (id: string) => void;
  filterCategories?: string[];
  multipleCategories?: boolean;
  children?: ReactNode;
  expanded?: boolean;
  appendTitle?: JSX.Element;
  sortByCategory?: Function;
  requestID?: string;
  updateDisplayName?: boolean;
  source?: boolean;
  // eslint-disable-next-line react/require-default-props
  defaultCategoryValue?: string;
}

const AttachmentsCard = ({
  files = [],
  uploadBtnRef = React.createRef(),
  setFieldTouched,
  setFieldValue,
  values,
  categories,
  handleDelete,
  filterCategories,
  multipleCategories = false,
  children,
  expanded = false,
  appendTitle,
  sortByCategory,
  requestID,
  updateDisplayName,
  source,
  defaultCategoryValue
}: Props): JSX.Element => (
  <CollapseCard
    expanded={expanded}
    title={
      <Grid container alignItems="center" spacing={32}>
        <Grid item>
          <ProfileSection>Attachments</ProfileSection>
        </Grid>
        <Grid item>{appendTitle}</Grid>
      </Grid>
    }
    iconStyle={{
      expandedStyle: {
        color: "#4a90e2"
      }
    }}
    render={({ expanded, Collapse }): JSX.Element => (
      <Collapse in={expanded}>
        <div style={{ padding: 16 }}>
          <Grid container spacing={8}>
            <Attachments
              files={files}
              uploadBtnRef={uploadBtnRef}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              values={values}
              categories={categories}
              handleDelete={handleDelete}
              filterCategories={filterCategories}
              multipleCategories={multipleCategories}
              sortByCategory={sortByCategory}
              requestID={requestID}
              updateDisplayName={updateDisplayName}
              source={source}
              defaultCategoryValue={defaultCategoryValue}
            />
            <Grid>{children}</Grid>
          </Grid>
        </div>
      </Collapse>
    )}
  />
);

export default AttachmentsCard;

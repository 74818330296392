import { createAction } from "redux-starter-kit";
import { FETCH_OPENFUNDINGREQUEST_SUCCESS } from "../types";

const clearOpenFundingRequests = id => dispatch => {
  dispatch(
    createAction(FETCH_OPENFUNDINGREQUEST_SUCCESS)({
      id,
      items: []
    })
  );
};

export default clearOpenFundingRequests;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_FACTORINGCLIENT_FAILURE,
  FETCH_FACTORINGCLIENT_REQUEST,
  FETCH_FACTORINGCLIENT_SUCCESS
} from "../types";

const fetchFactoringProfile = id => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_FACTORINGCLIENT_REQUEST)());
    const response = await api.factoring.getProfile(id);
    const data = normalize(response, schema.factoringProfile);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_FACTORINGCLIENT_SUCCESS)({
        id,
        user: response.user
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_FACTORINGCLIENT_FAILURE)(err));
    throw err;
  }
};

export default fetchFactoringProfile;

import { createAction } from "redux-starter-kit";
import {
  FETCH_OUTSTANDING_TOTALS_FAILURE,
  FETCH_OUTSTANDING_TOTALS_REQUEST,
  FETCH_OUTSTANDING_TOTALS_SUCCESS
} from "../types";

export default () => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_OUTSTANDING_TOTALS_REQUEST)());
    const res = await api.finances.outstandingTotals();
    dispatch(createAction(FETCH_OUTSTANDING_TOTALS_SUCCESS)(res));
  } catch (err) {
    dispatch(createAction(FETCH_OUTSTANDING_TOTALS_FAILURE)(err));
    throw err;
  }
};

const columns = [
  {
    key: "group_data.group_name",
    name: "Broker"
  },
  {
    key: "group_data.company_email",
    name: "Email"
  },
  {
    key: "group_data.company_phone_number",
    name: "Phone #"
  },
  {
    key: "group_data.company_mc",
    name: "MC #"
  },
  {
    key: "group_data.company_dot",
    name: "DOT #"
  },
  {
    key: "boarding",
    name: "Boarding"
  }
];

export default columns;

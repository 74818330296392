import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS
} from "../types";

const fetchUser = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_USER_REQUEST)());
    const response = await api.user.getUserById(id);
    const data = normalize(response, schema.user);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_USER_SUCCESS)({
        [id]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_USER_FAILURE)(err));
    throw err;
  }
};

export default fetchUser;

import { schema } from "normalizr";
import {
  Admin,
  DebtorProfile,
  FactoringProfile,
  GetFundingRequestResponse
} from "./factoring/getFundingRequest";

export const freightClass = new schema.Entity<Admin>("freightClass");

export const commodity = new schema.Entity<Admin>("commodity");

export const noa = new schema.Entity<Admin>("noa");

export const tms = new schema.Entity<Admin>("tms");

export const reasonForLeaving = new schema.Entity<Admin>("reasonForLeaving");

export const referralSource = new schema.Entity<Admin>("referralSource");

export const userName = new schema.Entity<Admin>("userName");

export const attachment = new schema.Entity("attachments");

export const creditReport = new schema.Entity("creditReport");

export const factoringCompany = new schema.Entity("factoringCompany");

export const paymentProfile = new schema.Entity("paymentProfile");

export const emailTemplates = new schema.Entity("emailTemplates");

export interface RoleEntity {
  id: string;
  name: string;
  description: string;
}

export const role = new schema.Entity("roles");

export const paymentEntry = new schema.Entity("paymentEntries");

export const receivableEntry = new schema.Entity("receivableEntries");

export const payment = new schema.Entity("payments");

export const connectedDebtor = new schema.Entity("connectedDebtors");

export const connectedCarrier = new schema.Entity("connectedCarrier");

export interface GroupEntity {
  id: string;
  name: string;
  type: string;
  roles: RoleEntity[];
}

export const group = new schema.Entity<GroupEntity>("groups", {
  roles: [role]
});

export const source = new schema.Entity("source");

export const user = new schema.Entity("user");

export const accounts = new schema.Entity("accounts");

export const lineItemCategory = new schema.Entity("lineItemCategories");

export const factoringProfile = new schema.Entity<FactoringProfile>(
  "factoringProfile",
  {
    user
  }
);

export const notes = new schema.Entity("notes");

export const debtorProfile = new schema.Entity<DebtorProfile>("debtorProfile");

export const debtors = new schema.Entity("debtors", {
  source,
  user
});

export const fundingRequest = new schema.Entity<GetFundingRequestResponse>(
  "fundingRequests",
  {
    assigned_admin: userName,
    debtor: debtorProfile,
    factoring: factoringProfile,
    payment_profile: paymentProfile
  }
);
export interface FundingRequestEntities {
  userName: typeof userName;
  debtorProfile: typeof debtorProfile;
  user: typeof user;
  factoringProfile: typeof factoringProfile;
  fundingRequests: typeof fundingRequest;
}

export const lineItem = new schema.Entity("lineItems");

export const reserveItem = new schema.Entity("reserveItems", {
  line_item: lineItem,
  funding_request: fundingRequest,
  user
});

export const receivable = new schema.Entity("receivables", {
  funding_request: fundingRequest,
  factoring: factoringProfile
});

export const purchase = new schema.Entity("purchases", {
  assigned_admin: userName
});

export const purchasesList = new schema.Entity("purchasesList");

export const debtorRelation = new schema.Entity("debtorRelations", {
  factoring: factoringProfile,
  debtor: debtorProfile
});

export const capitalManagement = new schema.Entity("capitalManagement");

export const transactionNote = new schema.Entity("transactionNotes", {
  posted_by: userName
});

export const clientNote = new schema.Entity("clientNotes", {
  posted_by: userName
});

export const admin = new schema.Entity("admin");

export const paymentProfileRelationship = new schema.Entity(
  "paymentProfileRelationships"
);

export const groupMember = new schema.Entity("groupMembers", {
  relationship: paymentProfileRelationship
});

export const factorClient = new schema.Entity("factorClients");

export const payments = new schema.Entity("payments");

export const paymentBillingNotes = new schema.Entity("paymentBillingNotes");

export const debtorRelationshipHistory = new schema.Entity(
  "debtorRelationshipHistories"
);

export const factoringProfileHistory = new schema.Entity(
  "factoringProfileHistory"
);

export const lineItemHistory = new schema.Entity(
  "lineItemHistory"
);

export const factorClientStats = new schema.Entity("factorClientStats");

export const plaidAccount = new schema.Entity("plaidAccounts");

export const reserveAggregate = new schema.Entity("reserveAggregates");

export const stats = new schema.Entity("stats");

export const factoring_document = new schema.Entity("factoring_documents");

export const obligations = new schema.Entity("obligations");

export const walletTransactions = new schema.Entity("walletTransactions", {}, { idAttribute: "transaction_id" });
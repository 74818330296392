import axios from "axios";
import { concat, isNull } from "lodash";

interface PaginatedResponse<T> {
  results: T[];
  next?: string;
}

async function fetchWhile<T>(url: string, items: T[]): Promise<T[]> {
  const response = await axios.get(url);
  const retrived = concat(items, response.data.results);
  if (!isNull(response.data.next)) {
    return fetchWhile(response.data.next, retrived);
  }
  return retrived;
}

async function fetchAll<T>(
  apiCall: () => Promise<PaginatedResponse<T>>
): Promise<T[]> {
  const response = await apiCall();
  const retrived = response.results;
  if (response.next) {
    return fetchWhile(response.next, retrived);
  }
  return retrived;
}

export default fetchAll;

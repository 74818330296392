import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce, get } from "lodash";
import axios from "axios";
import { addEntities } from "../../entities";

import {
  FETCH_FUNDING_REQUEST_RECEIVABLES_FAILURE,
  FETCH_FUNDING_REQUEST_RECEIVABLES_REQUEST,
  FETCH_FUNDING_REQUEST_RECEIVABLES_SUCCESS
} from "../types";

const handleResponse = (
  response,
  dispatch,
  receivable,
  ordering,
  filters,
  factoringId
) => {
  const data = normalize(response.results, [receivable]);
  dispatch(addEntities(data.entities));
  dispatch(
    createAction(FETCH_FUNDING_REQUEST_RECEIVABLES_SUCCESS)({
      items: data.result,
      count: response.count,
      next: response.next,
      previous: response.previous,
      ordering,
      filters,
      factoringId
    })
  );
  return response;
};

const debounced = debounce(
  async (dispatch, schema, api, limit, ordering, filters, factoringId) => {
    dispatch(
      createAction(FETCH_FUNDING_REQUEST_RECEIVABLES_REQUEST)({
        limit,
        ordering,
        filters,
        factoringId
      })
    );
    const response = await api.factoring.getFundingRequestReceivables(
      ordering,
      limit,
      filters,
      factoringId
    );
    handleResponse(response, dispatch, schema.receivable, ordering, filters);
    return response;
  },
  1000,
  { trailing: true }
);

const fetchFundingRequestReceivablesByDebtors = (
  ordering,
  limit,
  filters = {}
) => async (dispatch, getState, { api, schema }) => {
  const factoringId = get(filters, "factoringId", null);
  try {
    await debounced(
      dispatch,
      schema,
      api,
      limit,
      ordering,
      filters,
      factoringId
    );
  } catch (err) {
    dispatch(createAction(FETCH_FUNDING_REQUEST_RECEIVABLES_FAILURE)(err));
    throw err;
  }
};

export const next = (link, limit, ordering, filters, factoringId) => async (
  dispatch,
  getState,
  { schema }
) => {
  try {
    dispatch(
      createAction(FETCH_FUNDING_REQUEST_RECEIVABLES_REQUEST)({
        limit,
        ordering,
        filters,
        factoringId
      })
    );
    const response = await axios.get(link);
    handleResponse(
      response.data,
      dispatch,
      schema.receivable,
      ordering,
      filters,
      factoringId
    );
  } catch (err) {
    dispatch(createAction(FETCH_FUNDING_REQUEST_RECEIVABLES_FAILURE)(err));
    throw err;
  }
};

export default fetchFundingRequestReceivablesByDebtors;

import React from 'react'
import tw from 'twin.macro'

const Label = tw.label`block text-sm font-medium text-gray-700`
const Description = tw.label`mt-2 text-sm text-gray-500`

export interface FormItemProps {
    label?: string
    description?: string
    id?: string;
    name?: string;
}

const Item: React.FC<FormItemProps> = props => {
    return (
        <div tw='w-full'>
            {props.label ? (
                <Label>{props.label}</Label>
            ) : null}
            <div tw="mt-1">
                {props.children}
            </div>
            {props.description ? (
                <Description
                    id={props.id ? `${props.id}-description` : undefined}
                >
                    {props.description}
                </Description>
            ) : null}
        </div>
    )
}

export default Item;

import * as React from "react";
import { Select, SelectProps } from 'antd';

const { Option, OptGroup } = Select;

export interface IProps extends SelectProps {
    optionProps: Array<{
        className?: string;
        disabled?: boolean;
        title?: string;
        value?: string | number;
    }>;
    optGroup?: boolean;
    optGroupProps?: Array<{
        key?: string;
        label?: string | React.ReactElement;
    }>;
}

function AntSelect(props: IProps ) {
    const { optionProps, optGroup, optGroupProps, ...selectProps } = props;
    const OptionComponent = optionProps.map(option => <Option {...option}>{option.title}</Option>);
    return (
        <Select
            {...selectProps}
        >
            {OptionComponent}
        </Select>
    )
}

export default AntSelect;
import { includes } from "lodash";

const whiteList = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_.()".split(
  ""
);

export default function filenameFilter(str) {
  const name = str.normalize("NFKD").replace(/\s/g, "_");
  return [...name].filter(c => includes(whiteList, c)).join("");
}

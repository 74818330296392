import { merge } from "lodash";
import {
  FETCH_CARRIER_INVOICE_REQUEST,
  FETCH_CARRIER_INVOICE_SUCCESS,
  FETCH_CARRIER_INVOICE_FAILURE
} from "../types";

const fetchInvoice = (state, { type, payload }) => {
  switch (type) {
    case FETCH_CARRIER_INVOICE_REQUEST:
      return {
        ...state,
        loadingCarrierInvoice: true
      };
    case FETCH_CARRIER_INVOICE_SUCCESS:
      return {
        ...state,
        carrierDoc: merge({}, state.carrierDoc, payload),
        loadingCarrierInvoice: false
      };
    case FETCH_CARRIER_INVOICE_FAILURE:
      return {
        ...state,
        loadingCarrierInvoice: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchInvoice;

import React, { ReactNode, MouseEventHandler, CSSProperties } from "react";
import { Button, WithStyles } from "@material-ui/core";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import Colors from "../../theme/colors";

const styles = {
  root: {
    display: "flex",
    alignItems: "center"
  }
};

interface Props extends WithStyles<typeof styles> {
  children?: ReactNode;
  btnColor?: "inherit" | "default" | "primary" | "secondary" | undefined;
  size?: "medium" | "large" | "small" | undefined;
  disabled?: boolean;
  onClickHandler: MouseEventHandler<HTMLButtonElement>;
  variant?:
    | "text"
    | "flat"
    | "contained"
    | "outlined"
    | "raised"
    | "fab"
    | "extendedFab"
    | undefined;
  btnClasses?: string | undefined;
  progressClasses?: string | undefined;
  style?: CSSProperties;
}

const ProgressButton = ({
  children,
  btnColor = "secondary",
  size = "medium",
  btnClasses,
  disabled,
  progressClasses,
  onClickHandler,
  variant = "contained",
  style,
  ...props
}: Props): JSX.Element => (
  <div
    style={{
      position: "relative"
    }}
  >
    <Button
      variant={variant}
      color={btnColor}
      size={size}
      className={btnClasses}
      disabled={disabled}
      onClick={onClickHandler}
      style={style}
      {...props}
    >
      {children}
    </Button>
    {disabled && (
      <CircularProgress
        size={24}
        style={{
          color: Colors.secondary,
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: -12,
          marginLeft: -12
        }}
        className={classNames([progressClasses])}
      />
    )}
  </div>
);

export default withStyles(styles)(ProgressButton);

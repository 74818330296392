import { createAction } from "redux-starter-kit";
import {
  FACTORINGCLIENT_ATTACHMENT_COLLATED_ERROR,
  FACTORINGCLIENT_ATTACHMENT_COLLATED_FETCH,
  FACTORINGCLIENT_ATTACHMENT_COLLATED_SUCCESS
} from "../types";

const fetchFactoringCollatedFiles = id => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(FACTORINGCLIENT_ATTACHMENT_COLLATED_FETCH)());
    const response = await api.factoring.getCollatedAttachment(id);
    dispatch(
      createAction(FACTORINGCLIENT_ATTACHMENT_COLLATED_SUCCESS)({
        [id]: response
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FACTORINGCLIENT_ATTACHMENT_COLLATED_ERROR)(err));
  }
};

export default fetchFactoringCollatedFiles;

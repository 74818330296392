import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { purchase } from "../../../api/schema";

export default fundingRequestId =>
  memoize(state => {
    try {
      const purchases = get(
        state,
        `factoring.purchases.${fundingRequestId}`,
        []
      );

      const denormalized = denormalize(
        { purchases },
        { purchases: [purchase] },
        state.entities
      );

      return denormalized.purchases;
    } catch (err) {
      return [];
    }
  });

import { List, Typography, Grid, IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { get, isEmpty } from "lodash";
import { AccountCircle } from "@material-ui/icons";
import { Formik, FormikConfig } from "formik";

import formatAddress from "../../../../helpers/format/formatAddress";
import formatPhone from "../../../../helpers/format/formatPhone";
import DebtorInput from "../../../../components/inputs/DebtorInput";
import NoaSummary, {
  NOAActiveForm,
  PlaceNOAForm
} from "../../../../components/NOASummary";
import ListItem from "../../../../components/ListItem";
import BoldInput from "../../../../components/inputs/BoldInput";
import Card from "../../../../components/Card";
import Link from "../../../../components/Link";
import Can from "../../../../components/Can";
import ProfileSection from "../../../../components/ProfileSection";
import NOADropDown from "../../../../components/NOADropDown";
import { NOAUpdateFormValues } from "../FundingRequest";

interface Debtor {
  id: string;
  company_name: string;
  dba: string;
  mc: string;
  dot: string;
  credit_approved: string;
  originals_required: boolean;
  requires_scanned_copy: boolean;
}

interface Props {
  setFieldValue: Function;
  setFieldTouched: Function;
  phoneNumber: string;
  email: string;
  credit: string;
  credit_approved_note: string;
  remit_notes: string;
  phone_number: string;
  address: string;
  debtor: Debtor;
  noaForm: PlaceNOAForm;
  requested: string;
  sent: string;
  placed: string;
  active: string;
  clientName: string;
  debtorName: string;
  noaActiveForm: NOAActiveForm;
  noa?: string;
  noaAttachments?: [];
  handleBlur: Function;
  debtorRelationId: string;
  NOAUpdateForm: FormikConfig<NOAUpdateFormValues>;
  relationshipCreated: string;
}

function DebtorInfoCard({
  setFieldValue,
  setFieldTouched,
  phoneNumber,
  email,
  credit,
  credit_approved_note: creditApprovedNote,
  remit_notes: remitNotes,
  phone_number,
  address,
  debtor,
  noaForm,
  requested,
  sent,
  placed,
  active,
  clientName,
  debtorName,
  noaActiveForm,
  noa,
  noaAttachments,
  handleBlur,
  debtorRelationId,
  NOAUpdateForm,
  relationshipCreated
}: Props): JSX.Element {
  let phone = phoneNumber || phone_number;
  if (phone && phone.includes(";")) {
    const phones = phone.split(";");
    phone = "";
    phones.forEach((value: string): void => {
      phone += `${formatPhone(value)}\n`;
    });
  } else {
    phone = formatPhone(phone);
  }
  return (
    <Card>
      <List dense style={{ width: "100%" }}>
        <ListItem>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <ProfileSection>Debtor Info</ProfileSection>
              {(debtor || {}).originals_required && (
                <Typography variant="subtitle1" color="error">
                  Originals Required
                </Typography>
              )}
              {(debtor || {}).requires_scanned_copy && (
                <Typography variant="subtitle1" color="error">
                  Scanned Copies Required
                </Typography>
              )}
            </Grid>
            <Can
              perform="admin-debtor-profile:view"
              yes={() => (
                <Grid item>
                  <Tooltip title="View Profile">
                    <Link to={`/admin/debtors/${get(debtor, "id")}/profile`}>
                      <IconButton color="primary">
                        <AccountCircle />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </Grid>
              )}
            />
          </Grid>
        </ListItem>
        <ListItem>
          <DebtorInput
            fullWidth
            label="Debtor"
            name="debtor"
            value={debtor}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            debtorName={debtorName}
          />
        </ListItem>
        <ListItem>
          <BoldInput fullWidth label="Phone" value={phoneNumber} disabled />
        </ListItem>
        <ListItem>
          <BoldInput fullWidth label="Email" value={email} disabled />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Address"
            value={formatAddress(address)}
            disabled
          />
        </ListItem>
        <ListItem>
          <BoldInput fullWidth label="Credit" value={credit} disabled />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Payment Remit Notes"
            value={remitNotes}
            disabled
          />
        </ListItem>
        {
          relationshipCreated &&
          <>
            <Can
              perform="noa-summary:view"
              yes={() => (
                <ListItem>
                  <ProfileSection>NOA Summary</ProfileSection>
                </ListItem>
              )}
            />
            <Can
              perform="noa-summary:view"
              yes={() => (
                <Formik {...NOAUpdateForm}>
                  {({ values, setFieldValue, handleBlur, handleSubmit }): JSX.Element =>
                    <ListItem>
                      <NOADropDown
                        fullWidth
                        row={{
                          noa_requested: requested,
                          noa_active: active,
                          noa_sent: sent,
                          relationship_created: relationshipCreated
                        }}
                        setFieldValue={setFieldValue}
                        updatedValue={values.noa}
                        onBlur={handleBlur}
                        disabled={isEmpty(noaAttachments)}
                        placeNOAForm={noaForm}
                        clientName={clientName}
                        debtorName={debtorName}
                        relationshipId={debtorRelationId}
                        save={handleSubmit}
                        helperText={isEmpty(noaAttachments) && 'No NOA on File.'}
                      />
                    </ListItem>}
                </Formik>
              )}
            />
          </>
        }
      </List>
    </Card>
  );
}

// @ts-ignore
export default DebtorInfoCard;

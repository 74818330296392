import { compose, lifecycle, withHandlers, withStateHandlers } from "recompose";
import {
  assign,
  filter,
  head,
  includes,
  keys,
  lowerCase,
  reduce,
  reverse,
  slice,
  sortBy,
  split
} from "lodash";

const sortDirection = ordering =>
  head(split(ordering, "")) === "-" ? "desc" : "asc";

const getSortBy = (ordering, direction) => {
  if (direction === "desc") {
    return ordering.substring(1);
  }
  return ordering;
};

const getSortedFiltered = (items, filters, sortByProperty, direction) => {
  let sorted = sortBy(items, split(sortByProperty, "."));
  if (direction !== "asc") {
    sorted = reverse(sorted);
  }

  return filter(sorted, item =>
    reduce(
      // eslint-disable-next-line no-shadow
      keys(filters).map(key =>
        includes(lowerCase(item[key]), lowerCase(filters[key]))
      ),
      (all, included) => all && included,
      true
    )
  );
};

const withLocalTable = key =>
  compose(
    withStateHandlers(
      props => ({
        items: props[key] || [],
        count: 0,
        offset: 0,
        limit: 20,
        ordering: undefined,
        rowsPerPageOptions: [5, 10, 20, 50, 100, 200],
        filters: {}
      }),
      {
        handleChangePage: () => (event, page) => ({ offset: page }),
        handleChangeRowsPerPage: () => event => ({ limit: event.target.value }),
        handleSetItems: () => items => ({ items }),
        handleSearchFilter: ({ filters }) => event => {
          const name = event.target.name;
          const value = event.target.value;
          return {
            filters: assign(filters, {
              [name]: value
            })
          };
        },
        setOrdering: () => ordering => ({ ordering })
      }
    ),
    withHandlers({
      getOffset: ({ offset }) => () => offset,
      getRowsPerPageOptions: ({ rowsPerPageOptions }) => () =>
        rowsPerPageOptions,
      getRowsPerPage: ({ limit }) => () => limit,
      getCount: ({ items, ordering, filters }) => () => {
        const direction = sortDirection(ordering);
        const sortByProperty = getSortBy(ordering, direction);

        return getSortedFiltered(items, filters, sortByProperty, direction)
          .length;
      },
      getSortDirection: ({ ordering }) => () => sortDirection(ordering),
      getSortBy: ({ ordering }) => () => {
        const direction = sortDirection(ordering);
        return getSortBy(ordering, direction);
      },
      getPage: ({ offset }) => () => offset,
      getFilters: ({ filters }) => () => filters,

      getRows: ({ items, offset, limit, ordering, filters }) => () => {
        const direction = sortDirection(ordering);
        const sortByProperty = getSortBy(ordering, direction);

        const filtered = getSortedFiltered(
          items,
          filters,
          sortByProperty,
          direction
        );

        return slice(filtered, limit * offset, limit * offset + limit);
      },

      handleSort: ({ ordering, setOrdering }) => property => () => {
        const direction = sortDirection(ordering);
        const sortByProperty = getSortBy(ordering, direction);

        if (sortByProperty === property) {
          setOrdering(
            direction === "asc" ? `-${sortByProperty}` : sortByProperty
          );
        } else {
          setOrdering(property);
        }
      }
    }),
    lifecycle({
      componentDidUpdate(prevProps) {
        if (prevProps[key] !== this.props[key]) {
          this.props.handleSetItems(this.props[key]);
        }
      }
    })
  );

export default withLocalTable;

import React from "react";
import { Grid } from "@material-ui/core";
import Bank from "../../../components/Bank";
import Header from "../../../components/Header";
import Thanks from "../../../components/Thanks";

const Steps = props => {
  const {
    exchangePlaidToken,
    exchangePlaidLoading,
    exchangePlaidError,
    step
  } = props;
  const stepsView = {
    bank: (
      <Bank
        submitToken={exchangePlaidToken}
        loading={exchangePlaidLoading}
        error={exchangePlaidError}
      />
    ),
    thanks: <Thanks message="Thank you for updating you information." />
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={{ flex: 1, background: "white", margin: "auto" }}
      justify="center"
      item
      lg={8}
      md={8}
      sm={8}
    >
      <Grid item style={{ paddingTop: "32px", paddingBottom: "32px" }}>
        <Header noTitle={false} />
      </Grid>
      <Grid item style={{ minHeight: 600, justifyContent: "center" }}>
        {stepsView[step]}
      </Grid>
    </Grid>
  );
};
export default Steps;

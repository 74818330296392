import { denormalize } from "normalizr";
import { get } from "lodash";

import { factoringProfile } from "../../../api/schema";

const getConnectedProfiles = state => {
  try {
    const items = get(
      state,
      ["factoringClient", "connectedBrokersCarriers", "ids"],
      []
    );

    const denormalized = denormalize(
      { items },
      { items: [factoringProfile] },
      state.entities
    );

    return denormalized.items;
  } catch (err) {
    return [];
  }
};

export default getConnectedProfiles;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE
} from "../types";

const fetchUser = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(
      createAction(FETCH_USER_REQUEST)({
        id
      })
    );
    const response = await api.user.getUserById(id);
    const data = normalize(response, schema.user);
    dispatch(addEntities(data.entities));
    dispatch(createAction(FETCH_USER_SUCCESS)({ id }));
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_USER_FAILURE)(err));
    throw err;
  }
};

export default fetchUser;

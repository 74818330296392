import React from "react";
import {
  Fab,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import { Field } from "formik";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import AutoCompleteAddress from "../../../components/inputs/AutoCompleteAddress";
import PhoneInput from "../../../components/inputs/PhoneInput";
import colors from "../../../theme/colors";

const CreateFactorCompanyForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  setFieldTouched
}) => (
  <Grid container direction="column" spacing={24}>
    <Grid item container justify="center">
      <Typography variant="body2">
        Please enter the information for your factoring company below.
      </Typography>
    </Grid>
    <Grid item>
      <Field name="name">
        {({
          field: { value, ...field },
          form,
          hasError = !!(form.errors.name && form.touched.name)
        }) => (
          <TextField
            placeholder="Enter the company DBA"
            label="DBA"
            value={value}
            fullWidth
            helperText={
              hasError ? (
                <div dangerouslySetInnerHTML={{ __html: form.errors.name }} />
              ) : null
            }
            error={hasError}
            name={field.name}
            onChange={handleChange}
            onBlur={handleBlur}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
      </Field>
    </Grid>
    <Grid item>
      <Field name="email">
        {({
          field: { value, ...field },
          form,
          hasError = !!(form.errors.email && form.touched.email)
        }) => (
          <TextField
            placeholder="Enter the company Email"
            label="Company Email"
            value={value}
            fullWidth
            helperText={
              hasError ? (
                <div dangerouslySetInnerHTML={{ __html: form.errors.email }} />
              ) : null
            }
            error={hasError}
            name={field.name}
            onChange={handleChange}
            onBlur={handleBlur}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
      </Field>
    </Grid>
    <Grid item>
      <Field name="address">
        {({
          field,
          form,
          hasError = !!(form.errors.address && form.touched.address)
        }) => (
          <React.Fragment>
            <AutoCompleteAddress
              name={field.name}
              value={field.value}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              hasError={hasError}
              placeholder="Enter the company Address"
            />
            {hasError && (
              <FormHelperText error id="component-error-text">
                {errors.address}
              </FormHelperText>
            )}
          </React.Fragment>
        )}
      </Field>
    </Grid>
    <Grid item>
      <Field name="phone_number">
        {({
          field: { value, ...field },
          form,
          hasError = !!(form.errors.phone_number && form.touched.phone_number)
        }) => (
          <TextField
            error={hasError}
            placeholder="Enter the company Phone"
            label="Company Phone"
            fullWidth
            value={value}
            name={field.name}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              inputComponent: PhoneInput
            }}
            InputLabelProps={{
              shrink: true
            }}
            helperText={hasError ? errors.phone_number : null}
          />
        )}
      </Field>
    </Grid>
    <Grid item justify="flex-end">
      <Grid container direction="row-reverse">
        <Fab
          aria-label="Continue"
          color="primary"
          onClick={handleSubmit}
          style={{ marginTop: 30, justifySelf: "flex-end" }}
        >
          <ArrowForwardIos style={{ color: colors.white }} />
        </Fab>
      </Grid>
    </Grid>
  </Grid>
);

export default CreateFactorCompanyForm;

import { connect } from "react-redux";
import { compose, lifecycle, withProps, withStateHandlers } from "recompose";
import { find, findIndex, head, groupBy } from "lodash";

import { DateTime } from "luxon";
import {
  openSnackbar,
  openDialog,
  closeDialog,
  getDialog
} from "../../../modules/ui";
import {
  createFundingRequestCSV,
  getCount,
  getFilters,
  getFundingRequests,
  getIsLoading,
  getPage,
  getRowsPerPage,
  getRowsPerPageOptions,
  getSortBy,
  getSortDirection,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter,
  handleSort,
  queryFundingRequests,
  setStatus,
  getBulkError
} from "../../../modules/transactions";

import withSelect from "../../../components/withSelect";
import withStatusDropdown from "../../../components/withStatusDropdown";
import API from "../../../api";

import PureTransactions from "./Transactions";
import tabs from "./tabs";

export { default as Transactions } from "./Transactions";

const mapStateToProps = state => ({
  transactions: getFundingRequests(state),
  isLoading: getIsLoading(state),
  sortDirection: getSortDirection(state),
  sortBy: getSortBy(state),
  count: getCount(state),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  rowsPerPageOptions: getRowsPerPageOptions(state),
  filters: getFilters(state),
  bulkError: getBulkError(state),
  dialog: getDialog(state)
});

const mapDispatchToProps = {
  queryFundingRequests,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  setStatus,
  createFundingRequestCSV,
  handleSearchFilter,
  openSnackbar,
  openDialog,
  closeDialog
};

export default compose(
  withSelect,
  withStatusDropdown,
  withStateHandlers(
    ({
      datetime_end = DateTime.local(),
      datetime_start = DateTime.local(),
      paymentMethod = " ",
      newDocsBadges = {}
    }) => ({ datetime_end, datetime_start, paymentMethod }),
    {
      onDateStartChange: () => datetime_start => ({ datetime_start }),
      onDateEndChange: () => datetime_end => ({ datetime_end }),
      onPaymentMethodChange: () => paymentMethod => ({ paymentMethod }),
      setNewDocsBadges: () => newDocsBadges => ({ newDocsBadges })
    }
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      history: {
        location: { pathname }
      },
      setStatus,
      selected,
      status,
      clearSelected,
      createFundingRequestCSV,
      openSnackbar,
      queryFundingRequests,
      sortDirection,
      sortBy,
      rowsPerPage,
      page,
      filters
    }) => ({
      tabs: tabs(),
      statusIndex: findIndex(tabs(), tab => pathname === tab.route),
      filterStatus: (find(tabs(), tab => pathname === tab.route) || {}).value,
      handleSetStatus: async () => {
        const filterStatus = (find(tabs(), tab => pathname === tab.route) || {})
          .value;
        try {
          await setStatus(selected, status, { bulk: true });
          await queryFundingRequests(
            filterStatus,
            sortDirection === "asc" ? sortBy : `-${sortBy}`,
            rowsPerPage,
            page,
            {
              filters
            }
          );
          openSnackbar("success", "Updated Transactions Status!");
        } catch (err) {
          let message = head(err) || err.message;
          const strings = message.split(",");
          if (strings.length === 6) {
            message = strings
              .slice(3)
              .join(", ")
              .replace("debtor", `debtor: ${strings[2]}`);
          }
          openSnackbar("error", message);
        } finally {
          clearSelected();
        }
      },
      handleCSV: (...args) => createFundingRequestCSV(...args)
    })
  ),
  lifecycle({
    async componentDidMount() {
      const { filterStatus, setNewDocsBadges } = this.props;
      try {
        await this.props.queryFundingRequests(
          filterStatus,
          "-created",
          this.props.rowsPerPage,
          0,
          {}
        );
        const response = await API.factoring.queryFundingRequests(
          "",
          "-created",
          1000,
          0,
          { has_new_upload: true }
        );
        setNewDocsBadges(groupBy(response.results, "status"));
      } catch (e) {}
    },
    async componentDidUpdate(prevProps) {
      if (this.props.filterStatus !== prevProps.filterStatus) {
        const { filterStatus } = this.props;
        await this.props.queryFundingRequests(
          filterStatus,
          "-created",
          this.props.rowsPerPage,
          0,
          this.props.filters
        );
      }
    }
  })
)(PureTransactions);

import React from "react";
import { Redirect } from "react-router-dom";
import { Grid, Paper } from "@material-ui/core";

import CSVButton from "../../../../components/buttons/CSVButton";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import Table from "../../../../components/Table";

const FundingRequests = ({
  loading,
  navIndex,
  handleRoute,
  columns,
  tabs,
  fundingRequests,
  isSelected,
  allSelected,
  handleSelect,
  handleSelectAllClick,
  count,
  page,
  rowsPerPage,
  sortDirection,
  sortBy,
  filters,
  handleSort,
  handleChangePage,
  handleRowsPerPage,
  handleFilterChange,
  rowsPerPageOptions,
  handleCSV,
}) => {
  return (
    <Can
      perform="admin-factorclients:view"
      yes={() => (
        <InternalLayout title="Factor Client">
          <NavigationTabs
            tabs={tabs}
            value={navIndex}
            handleRoute={handleRoute}
          />
          <Paper style={{ marginTop: "32px" }}>
            <Grid container direction="row-reverse" spacing={32}>
            <Can
                perform="admin-factorclients-funding_request:downloadReport"
                yes={() => (
              <Grid item style={{ paddingRight: "32px" }}>
                <CSVButton handleCSV={handleCSV} />
              </Grid>
                )}
                />
            </Grid>
            <Table
              isLoading={loading}
              columns={columns}
              rows={fundingRequests}
              select
              isSelected={isSelected}
              allSelected={allSelected}
              handleSelect={handleSelect}
              handleSelectAllClick={handleSelectAllClick}
              count={count}
              page={page}
              sort
              filter
              rowsPerPage={rowsPerPage}
              sortDirection={sortDirection}
              sortBy={sortBy}
              filters={filters}
              handleSort={handleSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleRowsPerPage}
              handleFilterChange={handleFilterChange}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </Paper>
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
};

export default FundingRequests;

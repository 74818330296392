import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import { MenuItem, TextField } from "@material-ui/core";
import AvatarCard from "../../../../../components/AvatarCard";
import colors from "../../../../../theme/colors";
import CustomTextField from "../../../../../components/inputs/CustomTextField";

const statuses = [
  {
    value: "pending",
    name: "Pending"
  },
  {
    value: "approved",
    name: "Approved"
  },
  {
    value: "declined",
    name: "Declined"
  },
  {
    value: "needs_review",
    name: "Needs Review"
  }
];

const getColor = status => {
  switch (status) {
    case "pending":
    case "approved":
      return colors.green;
    case "declined":
      return colors.danger;
    case "needs_review":
      return colors.warning;
    default:
      return colors.warning;
  }
};

const ApprovalStatusCard = ({
  title,
  status,
  name,
  handleChange,
  handleBlur
}) => (
  <AvatarCard
    title={title}
    color={getColor(status)}
    icon={<FontAwesomeIcon size="lg" icon={faUserClock} />}
    subheader={
      <TextField
        fullWidth
        select
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={status}
        InputProps={{
          disableUnderline: true,
          style: { color: "white" }
        }}
      >
        {statuses.map(status => (
          <MenuItem key={status.value} value={status.value}>
            {status.name}
          </MenuItem>
        ))}
      </TextField>
    }
  />
);

export default ApprovalStatusCard;

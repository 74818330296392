import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import { get, isEmpty } from "lodash";
import mapState from "../../helpers/mapState";
import {
  setEmail,
  getEmail,
  logout,
  isLoggedIn,
  getCurrentUserId,
  getAuthPhoneNumber,
  getUserType
} from "../../modules/auth";
import {
  resetSteps,
  getSteps,
  getCurrentStep,
  getAccountAction,
  getLoading,
  confirmInvite,
  exchangePlaidToken,
  moveToNextStep,
  createFactoringProfileInvite,
  getFactoringLoading,
  getFactoringProfileAction,
  getInvitedEmail,
  getFactoringInviteError,
  getInviteError,
  getExchangePlaidError,
  getExchangePlaid,
  getExchangeLoading,
  getInvitePayload,
  moveToStepByName,
  getUsedPlaid,
  getUpdateFactoringProfile,
  getUpdateFactoringProfileLoading,
  updateFactoringProfile,
  fetchFactoringProfile,
  getFactoringProfile,
  getUserProfileSuccess,
  getPhoneNumber,
  isSmsInvite,
  updatePaymentProfile,
  updateInviteSteps
} from "../../modules/invited";
import { getFiles } from "../../modules/upload";
import { clientUserUpdate as updateUser } from "../../modules/user";
import {
  requestBerbixToken,
  getBerbixValues,
  SaveBerbixRequest
} from "../../modules/berbix";
import Form from "./form";

const mapStateToProps = mapState({
  step: getCurrentStep,
  steps: getSteps,
  loading: getLoading,
  factoringLoading: getFactoringLoading,
  factoringProfileSuccess: getFactoringProfileAction,
  factoringProfile: getFactoringProfile,
  accountAction: getAccountAction,
  authEmail: getEmail,
  email: getInvitedEmail,
  inviteError: getInviteError,
  exchangePlaidError: getExchangePlaidError,
  exchangePlaid: getExchangePlaid,
  exchangePlaidLoading: getExchangeLoading,
  updateFactoringLoading: getUpdateFactoringProfileLoading,
  updateFactoringProfileSuccess: getUpdateFactoringProfile,
  attachments: getFiles,
  user: getUserProfileSuccess,
  invitePayload: getInvitePayload,
  usedPlaid: getUsedPlaid,
  berbixValues: getBerbixValues,
  isLoggedIn,
  currentUserId: getCurrentUserId,
  phoneNumber: getPhoneNumber,
  authPhoneNumber: getAuthPhoneNumber,
  isSmsInvite,
  userType: getUserType
});

const mapDispatchToProps = {
  confirmInvite,
  exchangePlaidToken,
  moveToNextStep,
  moveToStepByName,
  createFactoringProfileInvite,
  updateFactoringProfile,
  setEmail,
  logout,
  resetSteps,
  fetchFactoringProfile,
  requestBerbixToken,
  SaveBerbixRequest,
  updateUser,
  updateInviteSteps
};

export const PureInvitedScreen = Form;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    async componentDidUpdate(prevProps) {
      const createdAccount =
        prevProps.factoringProfile !== this.props.factoringProfile;
      const updatedAccount =
        prevProps.updateFactoringProfileSuccess !==
        this.props.updateFactoringProfileSuccess;
      const uninvitedAccount =
        (this.props.step === "confirm" &&
          this.props.authEmail !== this.props.email &&
          !isSmsInvite) ||
        (this.props.authPhoneNumber !== this.props.phoneNumber && isSmsInvite);

      if (createdAccount || updatedAccount) {
        await this.props.requestBerbixToken();
        this.props.moveToNextStep();
      }
      if (prevProps.email !== this.props.email) {
        this.props.setEmail(this.props.email);
      }
      if (uninvitedAccount) {
        this.props.logout();
        this.props.resetSteps();
        this.props.history.push("/error");
      }

      const plaidTokenExchanged =
        this.props.exchangePlaid !== prevProps.exchangePlaid;

      if (plaidTokenExchanged) {
        this.props.moveToNextStep();
      }
    },
    async componentDidMount() {
      const {
        confirmInvite: confirm,
        match: {
          params: { token }
        },
        moveToStepByName: jumpToStep,
        invitePayload,
        updateInviteSteps: updateSteps,
        history,
        isLoggedIn,
        userType
      } = this.props;
      if (token) {
        try {
          const res = await confirm(token);
          if (get(res, "include_carrier_id_check") === false) {
            updateSteps(get(res, "include_carrier_id_check"));
          }
          if (!isEmpty(get(res, "profiles.payment", {})) && !isLoggedIn) {
            return history.replace(
              `/login?email=${res.email}&group_id=${res.group_id}`
            );
          }
          if (isLoggedIn && res.custom_token && userType === "carrier" 
          || (!isEmpty(get(res, "profiles.payment", {})) && isLoggedIn)) {
            return history.replace(`/dashboard/ic_portal/${res.group_id}`);
          }
        } catch (err) {}
      }
      window.onpopstate = object => {
        jumpToStep("Welcome");
      };
    }
  })
)(Form);

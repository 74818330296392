import { createAction } from "redux-starter-kit";
import {
  CREATE_DEBTORRELATION_FAILURE,
  CREATE_DEBTORRELATION_REQUEST,
  CREATE_DEBTORRELATION_SUCCESS
} from "../types";

const createFactoringDebtorRelationship = (factoringId, debtorId) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(CREATE_DEBTORRELATION_REQUEST)({}));
    await api.debtors.createFactoringDebtorRelationship(factoringId, debtorId);
    dispatch(createAction(CREATE_DEBTORRELATION_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(CREATE_DEBTORRELATION_FAILURE)(err));
    throw err;
  }
};

export default createFactoringDebtorRelationship;

import { createAction } from "redux-starter-kit";
import { assign, find } from "lodash";

import {
  UPDATE_FUNDINGREQUEST_FAILURE,
  UPDATE_FUNDINGREQUEST_REQUEST,
  UPDATE_FUNDINGREQUEST_SUCCESS
} from "../types";
import fetchFundingRequest from "./fetchFundingRequest";
import awsImageUpload from "../../../helpers/awsImageUpload";
import filenameFilter from "../../../helpers/filenameFilter";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const updateFundingRequest = fundingRequest => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(UPDATE_FUNDINGREQUEST_REQUEST)());

    const formatted = assign({}, fundingRequest, {
      attachments: fundingRequest.attachments.map(attachment => ({
        category: attachment.category,
        filename: attachment.file.name
      }))
    });

    const response = await api.factoring.updateFundingRequest(formatted);

    const allFound = fundingRequest.attachments.map(attachment => {
      const found = find(
        response.attachments,
        res =>
          res.filename === filenameFilter(attachment.file.name) && !res.uploaded
      );

      return {
        file: attachment.file,
        upload_url: found.upload_url
      };
    });

    if (allFound.length !== 0) {
      awsImageUpload(allFound).then(async () => {
        await sleep(5000); // Wait for API to catch up
        await dispatch(fetchFundingRequest(fundingRequest.id));
      });
    }

    await dispatch(fetchFundingRequest(fundingRequest.id));

    dispatch(createAction(UPDATE_FUNDINGREQUEST_SUCCESS)({}));
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_FUNDINGREQUEST_FAILURE)(err));
    throw err;
  }
};

export default updateFundingRequest;

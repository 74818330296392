import { denormalize } from "normalizr";
import { debtorRelationshipHistory } from "../../../api/schema";

const getDebtorRelationHistory = id => state => {
  try {
    const denormalized = denormalize(
      { id },
      { id: debtorRelationshipHistory },
      state.entities
    );

    return denormalized.id;
  } catch (err) {
    return {};
  }
};

export default getDebtorRelationHistory;

import * as React from 'react';
import { Table, TablePaginationConfig, TableProps, Spin } from 'antd';
import tw, { styled } from 'twin.macro';
import {
    FilterValue,
    SorterResult,
    TableCurrentDataSource,
} from 'antd/lib/table/interface';
import reduce from 'lodash/reduce';
import { debounce, isEmpty } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';

const Spinner = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type Record<K extends string, T> = {
    [P in K]: T;
};

export interface Props<RecordType> extends TableProps<RecordType> {
    striped?: boolean;
    fetch?: (...args: any[]) => void;
    filters?: Record<string, string>;
    scroll?: Record<string, string>;
    infinityScroll?: boolean;
    next?: string | undefined;
    fetchNext?: () => void;
}

function AntTable(props: Props<Record<string, string | number | any>>) {
    const { striped, ...restProps } = props;
    const TableComponent = tw.table`divide-y divide-gray-200`;
    const TableHeader = tw.thead`bg-gray-400`;
    const HeaderColumn = tw.th`text-left font-bold text-white uppercase tracking-wider`;
    const TableColumn = tw.td`whitespace-nowrap text-gray-500 `;
    const TableRow = styled.tr((props: any) => [
        tw`border-b-2`,
        props['data-row-key'] % 2 === 0 && striped
            ? tw`bg-gray-200`
            : tw`bg-white`,
    ]);

    const handleChange = React.useCallback(
        (
            pagination: TablePaginationConfig,
            tableFilters: Record<string, FilterValue | null>,
            sorter:
                | SorterResult<Record<string, string | number | any>>
                | SorterResult<Record<string, string | number | any>>[],
            extra: TableCurrentDataSource<Record<string, string | number | any>>
        ) => {
            const { current = 0, pageSize = 0 } = pagination;
            const offset = current * pageSize - pageSize;
            const limit = pageSize;
            const params: Record<string, any> = {};
            const filtersMap = reduce(
                tableFilters,
                (result: Record<string, any>, value, key) => {
                    if (value && value.length) {
                        result[key] = value.join(',');
                    }
                    return result;
                },
                {}
            );
            props.fetch &&
                props.fetch({ offset, limit, ...filtersMap, ...props.filters });
        },
        []
    );

    const debouncedOnScroll = React.useCallback(
        debounce(
            function onScroll() {
                if (!props.fetchNext || props.loading) return;
                if (
                    window.innerHeight + window.scrollY >=
                    document.documentElement.scrollHeight - 30
                ) {
                    props.fetchNext();
                }
            },
            200,
            { leading: true }
        ),
        [props.loading]
    );

    React.useEffect(() => {
        window.addEventListener('scroll', debouncedOnScroll, true);
        return function cleanup() {
            window.removeEventListener('scroll', debouncedOnScroll, true);
        };
    }, [props.loading]);

    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                flexGrow: 1,
                overflowY: 'auto',
                flexFlow: 'column nowrap',
                flexDirection: 'column',
            }}
        >
            <Table<Record<string, string | number | any>>
                {...restProps}
                components={{
                    table: TableComponent,
                    header: {
                        wrapper: TableHeader,
                        cell: HeaderColumn,
                    },
                    body: {
                        row: TableRow,
                        cell: TableColumn,
                    },
                }}
                onChange={handleChange}
            />
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexGrow: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 50
                }}
            >
                {props.loading && props.fetchNext && props.next && (
                    <Spin indicator={Spinner} />
                )}
            </div>
        </div>
    );
}

export default AntTable;

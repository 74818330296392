import formatDate from "../../../../helpers/format/formatDate";

const columns = (status: string): any[] =>
  status !== "invited"
    ? [
      {
        key: "company_name",
        name: "DBA",
        filter: "text",
        filter_key: "company_name"
      },
      {
        key: "invited_by",
        name: "Invited By",
        filter: "text",
        filter_key: "invited_by"
      },
      {
        key: "ownerName",
        name: "Owner Name",
        filter: "text",
        filter_key: "owner_name"
      },
      {
        key: "owner_email",
        name: "Owner Email",
        filter: "text",
        filter_key: "owner_email"
      },
      {
        key: "company_mc",
        name: "MC",
        filter: "text",
        filter_key: "mc"
      },
      {
        key: "company_dot",
        name: "DOT",
        filter: "text",
        filter_key: "dot"
      },
      {
        key: "created",
        name: "Created At",
        format: formatDate,
        sort: true
      },
      {
        key: "stateZip",
        name: "State / Zip"
      },
      {
        key: "bankInformation",
        name: "Bank Acct. Update"
      },
      {
        key: "stillNeeded",
        name: "Still Needed"
      },
      {
        key: "doc_status",
        name: "Doc Status",
        filter: "dropdown",
        filter_values: [
          { label: "", value: "" },
          { label: "New", value: "true" },
          { label: "No Change", value: "false" }, 
        ],
        filter_key: "new_uploaded_documents"
      },
      {
        key: "modified",
        name: "Status Time",
        format: formatDate,
        sort: true
      },
      {
        key: "actions",
        name: "Actions"
      }
    ]
    : [
      {
        key: "invited_by",
        name: "Invited By",
        filter: "text",
        filter_key: "invited_by"
      },
      {
        key: "email",
        name: "Contact Email",
        filter: "text",
        filter_key: "email"
      },
      {
        key: "phone_number",
        name: "Phone Number",
        filter: "text",
        filter_key: "phone_number"
      },
      {
        key: "actions",
        name: "Actions"
      }
    ];

export default columns;

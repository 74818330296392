import { bindActionCreators } from "redux";
import { compose, lifecycle, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";
import { DateTime } from "luxon";

import {
  fetchAdminStats,
  fetchRevenueByPeriods,
  getStats
} from "../../../modules/dashboard";
import { getPeriods } from "../../../helpers/getPeriods";
import { closeDialog, getDialog, openDialog } from "../../../modules/ui";
import Dashboard from "./Dashboard";

const mapStateToProps = state => ({
  dialog: getDialog(state),
  stats: getStats(state)
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      openDialog,
      closeDialog,
      fetchAdminStats,
      fetchRevenueByPeriods
    },
    dispatch
  )
});

export default compose(
  withProps({
    getPeriods
  }),
  withStateHandlers(
    ({
      dateRange = [
        DateTime.local()
          .minus({ days: 30 })
          .startOf("day")
          .toISO(),
        DateTime.local()
          .endOf("day")
          .toISO()
      ],
      userType = "debtor",
      period = "monthly"
    }) => ({ dateRange, userType, period }),
    {
      onDateChange: () => dateRange => ({ dateRange }),
      onUserChange: () => userType => ({ userType }),
      onPeriodChange: () => period => ({ period })
    }
  ),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    async componentDidMount() {
      const {
        fetchAdminStats,
        dateRange,
        fetchRevenueByPeriods,
        period
      } = this.props;
      await fetchAdminStats(
        {
          datetime_end: dateRange[1],
          datetime_start: dateRange[0]
        },
        await fetchRevenueByPeriods(period)
      );
    }
  })
)(Dashboard);

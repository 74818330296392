import { findIndex } from "lodash";

const nextStep = state => {
  const steps = state.steps.map(step => step.value);
  const currentStep = state.step;

  const next = steps[findIndex(steps, step => step === currentStep) + 1];

  if (next === "thanks") {
    const { invitePayload, ...rest } = state;
    return {
      ...rest,
      invitePayload: {},
      step: next
    };
  }
  return {
    ...state,
    step: next
  };
};

export default nextStep;

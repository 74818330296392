import axios, { AxiosRequestConfig } from "axios";

const addReasonForLeaving = async (name: string): Promise<any> => {
  const url = `/reason_for_leaving/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      name
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateReasonForLeaving = async ({
  name,
  id
}: {
  name: string;
  id: string;
}): Promise<any> => {
  const url = `/reason_for_leaving/${id}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "PATCH",
    data: {
      name
    }
  };
  const response = await axios(options);
  return response.data;
};

const deleteReasonForLeaving = async (
  id: string): Promise<any> => {
  const url = `/reason_for_leaving/${id}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "DELETE",
  };
  const response = await axios(options);
  return response.data;
};

const fetchReasonForLeaving = async (): Promise<any> => {
  const url = `/reason_for_leaving/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

export default {
  addReasonForLeaving,
  fetchReasonForLeaving,
  updateReasonForLeaving,
  deleteReasonForLeaving
};

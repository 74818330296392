import React from "react";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  createStyles,
  Theme,
  WithStyles
} from "@material-ui/core";
import FolderIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { lifecycle, compose } from "recompose";
import Typography from "@material-ui/core/Typography";
import { truncate } from "lodash";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (themes: Theme) =>
  createStyles({
    resizeFont: {
      [themes.breakpoints.down("sm")]: {
        fontSize: 10
      }
    }
  });

interface Item {
  type: string;
  base64: string;
  name: string;
}

interface Props extends WithStyles<typeof styles> {
  item?: Item;
}

const ErrorFileView = ({ item, classes }: Props): JSX.Element => (
  <ListItem
    style={{
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }}
  >
    <ListItemAvatar>
      {item && item.type.startsWith("image") ? (
        <Avatar srcSet={item.base64} />
      ) : (
        <Avatar>
          <FolderIcon />
        </Avatar>
      )}
    </ListItemAvatar>
    <ListItemText
      primary={
        <Typography
          color="error"
          noWrap
          variant="body2"
          classes={{ body2: classes.resizeFont }}
        >
          {item ? truncate(item.name, { length: 25, separator: "..." }) : ""}
        </Typography>
      }
      secondary={
        <Typography
          color="error"
          noWrap
          variant="caption"
          classes={{ caption: classes.resizeFont }}
        >
          Error occurred while uploading your document.
        </Typography>
      }
    />
  </ListItem>
);

interface Outter {
  getBase64FromFile: (item: Item) => void;
  item?: Item;
}

export default compose<Props, Outter>(
  lifecycle<Outter, {}>({
    componentWillMount(): void {
      const { getBase64FromFile, item } = this.props;
      if (item) {
        getBase64FromFile(item);
      }
    }
  }),
  withStyles(styles)
)(ErrorFileView);

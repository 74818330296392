import { Action } from "redux-starter-kit";
import { LOGOUT, State } from "../types";

function logout(state: State, { type }: Action): State {
  switch (type) {
    case LOGOUT:
      return {
        ...state,
        groups: [],
        token: undefined,
        email: "",
        tokenPayload: {},
        currentUserId: undefined,
        type: undefined
      };
    default:
      return {
        ...state
      };
  }
}

export default logout;

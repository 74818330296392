import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import map from "lodash/map";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/index";

import {
  getReasonForLeaving,
  fetchReasonForLeaving
} from "../../../../../../../modules/factoring";
import { Select } from "../../../../../../..//components/inputs/BoldInput";
import {
  getDialog,
  openDialog,
  closeDialog
} from "../../../../../../../modules/ui";
import ReasonForLeavingModal from "./ReasonForLeavingModal";
import Can from "apps/haulpay-frontend/src/components/Can";

const ReasonForLeavingInput = ({ value, onBlur, onChange, setFieldValue, disabled }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const f = async () => {
      dispatch(fetchReasonForLeaving());
    };
    f();
  }, []);
  const reasons = useSelector(getReasonForLeaving);
  const dialog = useSelector(getDialog);
  const onClick = useCallback(
    args => dispatch(openDialog("reason-for-leaving-modal")),
    []
  );
  const handleClose = useCallback(args => dispatch(closeDialog()), []);

  return (
    <Grid container direction="column">
      {dialog.variant === "reason-for-leaving-modal" && (
        <ReasonForLeavingModal
          open={dialog.show}
          handleClose={handleClose}
          list={reasons.items}
          setFieldPreviewValue={setFieldValue}
        />
      )}
      <Select
        fullWidth
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        name="reasonForLeaving"
        label="Reason for Leaving"
        disabled={disabled}
        selections={map(reasons.items, ({ name = "", id }) => ({
          value: id,
          text: name.toUpperCase()
        }))}
      />
      <Can
        perform="admin-factor_client-profile:AddNewReasonOfLeaving"
        yes={() => (
          <Grid item container justify="flex-end">
        <Typography
          color="secondary"
          variant="caption"
          style={{
            textDecoration: "underline",
            cursor: "pointer"
          }}
          onClick={onClick}
        >
          Add New?
        </Typography>
      </Grid>
        )}
      />
    </Grid>
  );
};
export default ReasonForLeavingInput;

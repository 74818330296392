import React from "react";
import { Grid } from "@material-ui/core";
import { isArray } from "lodash";

import Steps from "../../../../../../../components/Steps";
import { PureUploadFile } from "../../../../../../../components/UploadFile";
import PaperSheet from "../../../../../../../components/PaperSheet";

const Upload = ({ message, steps, status, files, setFiles }) => (
  <React.Fragment>
    <div style={{ paddingTop: "16px" }}>
      <Steps steps={steps} activeStep={status} />
    </div>
    <Grid
      container
      direction="column"
      style={{ margin: "auto", padding: 16 }}
      spacing={16}
    >
      {message && <PaperSheet variant="error" message={message} />}
      <PureUploadFile
        files={files}
        uploadFiles={() => {}}
        newFiles={[]}
        multiple
        updateCategories={({ position, categories }) => {
          files[position].category = categories;
          files[position].categories = categories;
          setFiles(files);
        }}
        categoriesProps={[
          "Signed Bill of Lading (Delivered)",
          "Signed Bill of Lading (Pick-Up)",
          "Rate Confirmation",
          "Truck Order Not Used",
          "Invoice",
          "Freight Bill",
          "Lumper Receipt",
          "Detention Receipt",
          "Late Fee Receipt",
          "Other"
        ]}
        pickFiles={pickedFile => {
          function fakeUpload(file) {
            file.progress = 100;
            file.status = "success";
            file.category = file.category || "";
            file.categories = file.categories || "";
            file.filename = file.filename || file.name;
            return file;
          }
          if (isArray(pickedFile)) {
            setFiles(pickedFile.map(fakeUpload));
          } else {
            setFiles(files.concat(pickedFile).map(fakeUpload));
          }
        }}
        visibleTo
        updateVisibleTo={({ position, visible_to }) => {
          files[position].visible_to = visible_to;
          setFiles(files);
        }}
      />
    </Grid>
  </React.Fragment>
);

export default Upload;

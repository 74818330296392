import React from "react";
import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import SaveIcon from "@material-ui/icons/Save";

import Colors from "../../../theme/colors";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../components/Dialog";
import RoundedButton from "../../../components/buttons/RoundedButton";
import MODALS from "../../../helpers/Modals";
import PasswordInput from "../../../components/inputs/PasswordInput";

const GridColumn = ({ children, numbers, ...props }) => (
  <Grid container direction="column" item xs={numbers} {...props}>
    {children}
  </Grid>
);
export default ({ open, handleClose, callback, openModal, userId }) => (
  <Dialog open={open} maxWidth="sm">
    <DialogTitle onClose={handleClose}>Setting</DialogTitle>
    <Formik
      onSubmit={async (fields, formikActions) => {
        try {
          await callback({
            ...fields,
            userId
          });
          await handleClose();
          openModal(MODALS.SUCCESS, "Password has been updated successfully.");
        } catch (e) {
          openModal(MODALS.ERROR, "Error while updating password.");
        }
        formikActions.setSubmitting(false);
        formikActions.resetForm({
          password: "",
          confirm_password: ""
        });
      }}
      enableReinitialize
      initialValues={{
        password: "",
        confirm_password: ""
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <React.Fragment>
            <DialogContent>
              <Grid
                container
                spacing={16}
                alignItems="center"
                style={{ padding: 10 }}
              >
                <GridColumn numbers={12}>
                  <PasswordInput
                    fullWidth
                    showEndPasswordIcon
                    name="password"
                    label="New Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </GridColumn>
                <GridColumn numbers={12}>
                  <PasswordInput
                    fullWidth
                    showEndPasswordIcon
                    type="password"
                    name="confirm_password"
                    label="Confirm Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirm_password}
                  />
                </GridColumn>
              </Grid>
            </DialogContent>
            <DialogActions>
              <RoundedButton
                variant="contained"
                color="primary"
                progressStyle={{ color: Colors.primary }}
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                <SaveIcon />
                Save
              </RoundedButton>
            </DialogActions>
          </React.Fragment>
        );
      }}
    </Formik>
  </Dialog>
);

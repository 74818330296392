import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import LuxonUtils from '@date-io/luxon';
import 'normalize.css';
import { Router, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './theme/styles/main.css';
import configureStore from './configureStore';
import Routes from './Routes';
import Modals from './components/Modals';
import muiTheme from './theme';
import './middleware/commaQuery';
import './middleware/penniesQuery';
import SnackBar from './components/SnackBarNotification';
import { GlobalStyles } from '@haulpay/ui';

const isProduction = process.env.NODE_ENV === "production";

const history = createBrowserHistory();
const { store, persistor } = configureStore();
if (isProduction) {
  Sentry.init({
  dsn: process.env.REACT_APP_ERROR_REPORTING_KEY,
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost'],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      beforeNavigate: context => {
        return {
          ...context,
          name: window.location.pathname.replace(
            /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/,
            '<uuid>'
          )
        };
      }
    })
  ],
  tracesSampleRate: 0.2
  });
}

const App = () => {
  const [prevPath, setPrevPath] = React.useState(null);
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname !== prevPath) {
      setPrevPath(location.pathname);
      window.analytics.page();
    }
  }, [location.pathname]);

  return (
    <div>
      {JSON.parse(process.env.REACT_APP_ENABLE_TAILWIND || false) && <GlobalStyles /> }
      <MuiThemeProvider theme={muiTheme()}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <div style={{
                maxHeight: '100vh',
                overflowY: 'scroll'
              }}>
                <Routes />
                <Modals />
                <SnackBar />
              </div>
            </PersistGate>
          </ReduxProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  );
};

export default () => (
  <Router history={history}>
    <App />
  </Router>
);

import { Action, createAction } from "redux-starter-kit";
import { ThunkAction } from "redux-thunk";
import { ThunkContext } from "../../types";
import {
  FETCH_CURRENTUSER_FAILURE,
  FETCH_CURRENTUSER_REQUEST,
  FETCH_CURRENTUSER_SUCCESS
} from "../types";

function fetchUserToken(): ThunkAction<
  Promise<void>,
  any,
  ThunkContext,
  Action<any>
> {
  return async (dispatch, getState, { api }): Promise<void> => {
    try {
      dispatch(createAction(FETCH_CURRENTUSER_REQUEST)({}));
      const response = await api.user.currentUser();
      dispatch(createAction(FETCH_CURRENTUSER_SUCCESS)(response));
      return response;
    } catch (err) {
      dispatch(createAction(FETCH_CURRENTUSER_FAILURE)(err));
      throw err;
    }
  };
}

export default fetchUserToken;

import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import Colors from "../../../../theme/colors";
import formatPennies from "../../../../helpers/format/formatPennies";
import Table from "../../../../components/Table";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import ExpandableView from "../../../../components/ExpandableView";
import NestedMoreButton from "./components/NestedMoreButton";
import nestedReceivablesTableColumns from "./nestedColumns";
import MoreButton from "./components/MoreButton";
import columns from "./columns";
import tabs from "../tabs";
import NavigationTabs from "../../../../components/NavigationTabs";

const styles = {
  root: {
    background: "linear-gradient(45deg, #2EBD00 30%, #4dc527 90%)"
  },
  label: {
    fontFamily: "Avenir-Medium",
    textTransform: "capitalize",
    color: Colors.white
  }
};

export default withStyles(styles)(
  ({
    statusIndex,
    outstandingTotals,
    debtors = [],
    openFundingRequests,
    queryOpenFundingRequests,
    openFundingRequestsIsLoading,
    clearOpenFundingRequests,
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearchFilter,
    isLoading,
    sortDirection,
    sortBy,
    count,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    filters
  }) => {
    const index = statusIndex === -1 ? 0 : statusIndex;
    const formattedRows = debtors.map(row => ({
      ...row,
      company_name: (
        <Link
          to={`/admin/debtors/${row.id}/profile`}
          style={{ textDecoration: "none", color: Colors.link }}
        >
          {row.company_name}
        </Link>
      ),
      more: <MoreButton id={row.id} />
    }));
    const formattedNestedRows = openFundingRequests.map(row => ({
      ...row,
      more: <NestedMoreButton id={row.id} />
    }));
    return (
      <Can
        perform="admin-finances-debtor-balances:view"
        yes={() => (
          <InternalLayout title="Finances">
            <div style={{ margin: 10 }}>
              <Typography variant="h5">
                {`All Receivables (${formatPennies(
                  outstandingTotals.totalBalance
                )})`}
              </Typography>
              <Typography variant="h6">
                {`Number of Receivables (${outstandingTotals.totalReceivables})`}
              </Typography>
            </div>
            <NavigationTabs tabs={tabs} value={index} />
            <Paper style={{ marginTop: 32, padding: 16 }}>
              <Table
                sort
                columns={columns}
                rows={formattedRows}
                isLoading={isLoading}
                handleSort={handleSort}
                sortDirection={sortDirection}
                sortBy={sortBy}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                filter
                filters={filters}
                collapse
                colSpan={11}
                expandableView={id => (
                  <ExpandableView
                    key={id}
                    columns={nestedReceivablesTableColumns}
                    fetch={queryOpenFundingRequests}
                    id={id}
                    data={formattedNestedRows}
                    isLoading={openFundingRequestsIsLoading}
                    clear={clearOpenFundingRequests}
                    tableKey="fundingRequests"
                  />
                )}
                handleFilterChange={handleSearchFilter}
                allowEmpty
              />
            </Paper>
          </InternalLayout>
        )}
        no={() => <Redirect to="/" />}
      />
    );
  }
);

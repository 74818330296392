import React from "react";
import PropTypes from "prop-types";
import { Stepper, Step, StepLabel, Grid } from "@material-ui/core";
import { findIndex } from "lodash";

const Steps = ({ activeStep, steps }) => {
  const activeStepNumber = findIndex(steps, step => step.value === activeStep);
  return (
    <div style={{ minWidth: "50%" }}>
      <Stepper activeStep={activeStepNumber} alternativeLabel>
        {steps.map(({ value, text }) => (
          <Step key={value}>
            <StepLabel>{text}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

Steps.propTypes = {
  activeStep: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Steps;

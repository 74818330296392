import React from 'react';
import { Grid, Icon, Typography } from '@material-ui/core';
import Replay from '@material-ui/icons/Replay';
import Close from '@material-ui/icons/Close';
import AvTimer from '@material-ui/icons/AvTimer';
import Done from '@material-ui/icons/Done';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Receipt from '@material-ui/icons/Receipt';
import colors from '../theme/colors';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Store from '@material-ui/icons/Store';
import LocalGasStation from '@material-ui/icons/LocalGasStation';
import DesktopAccessDisabled from '@material-ui/icons/DesktopAccessDisabled'
import { completedReceivable, invoiceCreated, contractApproval } from '../theme/assets';

export default ({ type, description, style = {} }) => {
    const typeMapping = {
        STRIPE_WALLET_WITHDRAWAL: {
            text: 'Stripe Wallet Withdrawal',
            icon: Store,
            color: colors.green
        },
        CONTRACT_APPROVAL: {
            text: 'Contract Approval',
            icon: props => <img alt='Contract Approval' src={contractApproval} {...props}/>,
            color: colors.green,
            filter: 'invert(42%) sepia(29%) saturate(904%) hue-rotate(71deg) brightness(93%) contrast(95%)'
        },
        RELEASE_RECEIVABLE: {
            text: 'Release Receivable',
            icon: props => <img alt='Release Receivable' src={completedReceivable} {...props}/>,
            color: colors.purple,
            filter: 'invert(50%) sepia(95%) saturate(750%) hue-rotate(280deg) brightness(50%) contrast(150%)'
        },
        INVOICE_RECEIVABLE: {
            text: 'Invoice',
            icon: props => <img alt='Invoice' src={invoiceCreated} {...props}/>,
            color: colors.info,
            filter: 'invert(30%) sepia(60%) saturate(1200%) hue-rotate(180deg) brightness(60%) contrast(100%)'
        },
        NON_INVOICE_RECEIVABLE: {
            text: 'Non Invoice',
            icon: Close,
            color: colors.danger
        },
        DEBIT_RECEIVABLE: {
            text: 'Debit',
            icon: AvTimer,
            color: colors.purple
        },
        PURCHASE: {
            text: 'Purchase',
            icon: Done,
            color: colors.green
        },
        EARNINGS: {
            text: 'Earnings',
            icon: AttachMoney,
            color: colors.warning
        },
        PAYABLE: {
            text: `Payable - ${description}`,
            icon: Receipt,
            color: colors.green_dark
        },
        LEDGER_WALLET_WITHDRAWAL: {
            text: 'Ledger Wallet Withdrawal',
            icon: AccountBalanceWalletIcon,
            color: colors.info
        },
        FUEL_ADVANCE_APPROVAL: {
            text: 'Fuel Advance Approval',
            icon: LocalGasStation,
            color: colors.fuelYellow
        },
    };
    if (!type) {
        return <div />;
    }
    const {
        text = type.replace(/[^a-zA-Z0-9 ]/g, ' '),
        icon: Icon = DesktopAccessDisabled,
        color = colors.black,
        filter='',
    } = typeMapping[type] || {};
    return (
        <Grid container alignContent="center">
            <Icon style={{ color, filter, marginRight: 10, fontSize: 25, width: 25, height: 25, ...style }} />
            <Typography inline variant="body2" style={{ textTransform: "capitalize" }}>
                {text}
            </Typography>
        </Grid>
    );
}

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell
} from "recharts";
import colors from "../../../../theme/colors";
import Table from "../../../../components/Table";
import DropdownDrawer from "../../../../components/DropdownDrawer";
import formatPennies from "../../../../helpers/format/formatPennies";

const data = [
  {
    name: "30-45",
    amount: 590,
    color: "#4DC527"
  },
  {
    name: "45-60",
    amount: 868,
    color: "#B7E71C"
  },
  {
    name: "61-75",
    amount: 1397,
    color: "#F8E71C"
  },
  {
    name: "76-90",
    amount: 1480,
    color: "#EECC48"
  },
  {
    name: "91-120",
    amount: 1520,
    color: "#EEAF48"
  },
  {
    name: "121-150",
    amount: 1520,
    color: "#EE8748"
  },
  {
    name: "150+",
    amount: 1400,
    color: "#D0021B"
  }
];

const columns = [
  {
    key: "invoice_number",
    name: "Invoice #",
    filter: "text",
    filter_key: "company_name"
  },
  {
    key: "amount",
    name: "Invoice Amount",
    filter: "text",
    filter_key: "amount"
  },
  {
    key: "debtor",
    name: "Debtor",
    default: "-",
    filter: "text",
    filter_key: "debtor"
  },
  {
    key: "client",
    name: "Client",
    filter: "text",
    filter_key: "client",
    default: "-"
  },
  {
    key: "load_user_numbert",
    name: "Load #",
    filter: "text",
    filter_key: "load_user_numbert",
    default: "-"
  },
  {
    key: "created",
    name: "Age",
    default: "-"
  }
];
const styles = () => ({
  h5: {
    fontWeight: "bold !important"
  }
});

const Finances = ({ classes }) => (
  <DropdownDrawer
    title={
      <Grid container>
        <Grid item xs={2} container alignItems="flex-start">
          <Typography variant="title">Finances</Typography>
        </Grid>
        <Grid
          item
          xs={5}
          container
          alignItems="center"
          justify="center"
          style={{ borderRight: `1px solid ${colors.divider}` }}
        >
          <Typography
            variant="h5"
            classes={{
              h5: classes.h5
            }}
            inline
          >
            Amount Purchased:
          </Typography>
          <Typography
            variant="h6"
            style={{ padding: 5 }}
            color="secondary"
            inline
          >
            1,950,003.00
          </Typography>
        </Grid>
        <Grid item xs={5} container justify="center" alignItems="center">
          <Typography
            variant="h5"
            classes={{
              h5: classes.h5
            }}
            inline
          >
            Amount Paid:
          </Typography>
          <Typography
            variant="h6"
            style={{ padding: 5 }}
            color="secondary"
            inline
          >
            1,500.000
          </Typography>
        </Grid>
      </Grid>
    }
  >
    <Grid container style={{ padding: 20 }}>
      <Grid container item xs={12} direction="row">
        <Grid item xs={3} style={{ height: 400 }}>
          <ResponsiveContainer>
            <ComposedChart
              layout="vertical"
              data={data}
              margin={{
                right: 100
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Legend />
              <Bar dataKey="amount">
                <LabelList
                  dataKey="amount"
                  formatter={formatPennies}
                  position="right"
                  style={{
                    color: colors.textColor,
                    fontSize: "8px !important",
                    fontFamily: "Avenir-medium"
                  }}
                  clockWise={1}
                />
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.color}
                    strokeWidth={0.5}
                  />
                ))}
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={9}>
          <Table columns={columns} allowEmpty />
        </Grid>
      </Grid>
      <Grid container xs={12} justify="flex-end">
        <Typography
          variant="caption"
          style={{ textDecoration: "underline", fontStyle: "italic" }}
          color="secondary"
        >
          View All
        </Typography>
      </Grid>
    </Grid>
  </DropdownDrawer>
);
export default withStyles(styles)(Finances);

import { Action, createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { ThunkAction } from "redux-thunk";
import { ThunkContext } from "../../types";
import { addEntities } from "../../entities";
import { FETCH_GROUPS_FAILURE, FETCH_GROUPS_REQUEST, FETCH_GROUPS_SUCCESS } from "../types";

function getGroups(): ThunkAction<
  Promise<void>,
  any,
  ThunkContext,
  Action<any>
> {
  return async (dispatch, getState, { api, schema }): Promise<void> => {
    try {
      dispatch(createAction(FETCH_GROUPS_REQUEST)());
      const response = await api.groups.getGroups();
      const data = normalize(response.results, [schema.group]);
      dispatch(addEntities(data.entities));
      dispatch(createAction(FETCH_GROUPS_SUCCESS)(data.result));
    } catch (err) {
      dispatch(createAction(FETCH_GROUPS_FAILURE)(err));
      throw err;
    }
  };
}

// @ts-ignore
export default getGroups;

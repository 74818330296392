import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import { find, findIndex } from "lodash";

import {
  closeCreateDebtor,
  createDebtor,
  createDebtorsCSV,
  fetchDebtors,
  getCount,
  getCreateDebtor,
  getDebtors,
  getFilters,
  getIsLoading,
  getPage,
  getRowsPerPage,
  getRowsPerPageOptions,
  getSortBy,
  getSortDirection,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter,
  handleSort,
  openCreateDebtor
} from "../../../modules/debtors";

import { openModal } from "../../../modules/modal";
import withSelect from "../../../components/withSelect";
import withStatusDropdown from "../../../components/withStatusDropdown";

import PureDebtors from "./Debtors";
import tabs from "./tabs";
import columns from "./columns";

export { default as Debtors } from "./Debtors";

const mapStateToProps = state => ({
  debtors: getDebtors(state),
  isLoading: getIsLoading(state),
  sortDirection: getSortDirection(state),
  sortBy: getSortBy(state),
  count: getCount(state),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  rowsPerPageOptions: getRowsPerPageOptions(state),
  createDebtor: getCreateDebtor(state),
  filters: getFilters(state)
});

const mapDispatchToProps = {
  fetchDebtors,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  openCreateDebtor,
  closeCreateDebtor,
  createNewDebtor: createDebtor,
  openModal,
  handleSearchFilter,
  createDebtorsCSV
};

export default compose(
  withSelect,
  withStatusDropdown,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      setStatus,
      selected,
      status,
      clearSelected,
      createDebtorsCSV: createCSV,
      filters
    }) => ({
      tabs,
      columns,
      isNOAPanel: pathname === "/admin/debtors/noa_panel",
      statusIndex: findIndex(tabs, tab => pathname === tab.route),
      filterStatus: (find(tabs, tab => pathname === tab.route) || {}).value,
      handleSetStatus: async () => {
        await setStatus(selected, status);
        clearSelected();
      },
      handleRoute: route => push(route),
      handleCSV: () => createCSV(filters)
    })
  ),
  lifecycle({
    componentDidMount() {
      const { filterStatus, rowsPerPage, isNOAPanel } = this.props;
      if (!isNOAPanel) {
        this.props.fetchDebtors(filterStatus, "-created", rowsPerPage, 0, {});
      }
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.filterStatus !== prevProps.filterStatus &&
        !this.props.isNOAPanel
      ) {
        const { filterStatus, filters } = this.props;
        this.props.fetchDebtors(
          filterStatus,
          "-created",
          this.props.rowsPerPage,
          0,
          filters
        );
      }
    }
  })
)(PureDebtors);

const createPaymentProfileAttachment = (id, fileName, category) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    return await api.factorCompany.createAttachmentNOA({
      id,
      filename: fileName,
      category
    });
  } catch (err) {}
};

export default createPaymentProfileAttachment;

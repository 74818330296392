import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import colors from "../../theme/colors";

const styles = createStyles({
  calendarContainer: {
    fontFamily: "Avenir-Medium",
    fontSize: 14,
    color: colors.textInputColor,
    padding: 10
  },
  calendarView: {
    fontFamily: "Avenir-Medium",
    borderWidth: "0 !important",
    fontSize: 12,
    border: "1px solid gray",
    boxShadow: "1px 1px 3px #888",
    color: colors.textInputColor,
    padding: 10
  }
});

export default withStyles(styles)(({ classes, value, onChange }) => (
  <React.Fragment>
    <DateRangePicker
      calendarClassName={classes.calendarView}
      className={classes.calendarContainer}
      format="MM-d-y"
      calendarIcon={
        <FontAwesomeIcon icon={faCalendar} size="xs" color={colors.primary} />
      }
      clearIcon={
        <FontAwesomeIcon
          icon={faTimes}
          size="xs"
          color={colors.textInputColor}
        />
      }
      value={value}
      onChange={onChange}
    />
  </React.Fragment>
));

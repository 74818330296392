import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from '../../../../../components/inputs/CustomTextField';
import Colors from '../../../../../theme/colors';
import formatDate from '../../../../../helpers/format/formatDate';
import API from '../../../../../api';
import { Dialog, DialogContent, DialogTitle } from '../../../../../components/Dialog';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../../modules/ui';

const CO_BROKERING_APPROVAL_STATUS = {
    NEEDS_REVIEW: 'Needs Review',
    APPROVED: 'Approved',
    DECLINED: 'declined'
}

const StatusNoteDialog = ({ handleExit, open, relationshipId }) => {
    const [notes, setNotes] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();

    const fetchNotes = async () => {
        setIsFetching(true);
        try {
            const { results } = await API.factoring.paymentProfile.factoringPaymentProfileFetchStatusNote(relationshipId);
            setNotes(results);
        } catch (error) {
            console.error('Error fetching notes:', error);
        } finally {
            setIsFetching(false);
        }
    };

    const saveNote = async (note) => {
        try {
            const response = await API.factoring.paymentProfile.factoringPaymentProfileAddStatusNote(relationshipId, note);
            setNotes((prevNotes) => [response, ...prevNotes]);
            dispatch(openSnackbar("success", "Note Successfully Saved."));
        } catch (error) {
            dispatch(openSnackbar("error", "Error Adding Note."));
        }
    };

    useEffect(() => {
        fetchNotes();
    }, []);

    const validationSchema = Yup.object().shape({
        note: Yup.string().required('Note is required')
    });

    const formik = useFormik({
        initialValues: {
            note: ''
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            await saveNote({
                note: values.note
            });
            resetForm();
        }
    });

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle onClose={() => handleExit()}>Status Note</DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={32}>
                        <Grid container item xs={12} spacing={8} direction="row">
                            <Grid container item xs={9}>
                                <CustomTextField
                                    value={formik.values.note}
                                    label="Notes"
                                    name="note"
                                    fullWidth
                                    error={formik.errors.note && formik.touched.note}
                                    FormHelperTextProps={{
                                        error: formik.errors.note && formik.touched.note
                                    }}
                                    hasError={formik.errors.note && formik.touched.note}
                                    helperText={formik.errors.note}
                                    onBlur={formik.handleBlur}
                                    disabled={formik.isSubmitting}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={3} direction="row">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    disabled={isFetching}
                                    type="submit"
                                >
                                    Save Note
                                    {formik.isSubmitting && (
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            size="sm"
                                            spin
                                            color={Colors.green}
                                            style={{ marginLeft: 5 }}
                                        />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">Recent Notes</Typography>
                            <Divider variant="fullWidth" />
                            {isFetching ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: 20
                                    }}
                                >
                                    <CircularProgress size={35} color="primary" style={{ margin: 20 }} />
                                </div>
                            ) : (
                                <List dense>
                                    {notes.map(note => (
                                        <ListItem key={note.id}>
                                            <ListItemText
                                                inset
                                                primary={`${note.note}`}
                                                secondary={`By ${note.posted_by_name} ${formatDate(note.created)} (${CO_BROKERING_APPROVAL_STATUS[note.co_brokering_approval]})`}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default StatusNoteDialog;

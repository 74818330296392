import { AsyncParser } from "json2csv";
import { head, keys } from "lodash";

const csvString = items =>
  new Promise((resolve, reject) => {
    const x = head(items);
    const fields = keys(x);
    const opts = { fields };
    const transformOpts = { highWaterMark: 8192 };

    const asyncParser = new AsyncParser(opts, transformOpts);

    let csv = "";
    asyncParser.processor
      .on("data", chunk => {
        csv += chunk.toString();
      })
      .on("end", () => {
        resolve(csv);
      })
      .on("error", err => {
        reject(err);
      });

    asyncParser.input.push(JSON.stringify(items));
    asyncParser.input.push(null);
  });

const createCSV = async items => {
  const string = await csvString(items);

  const element = document.createElement("a");
  const file = new Blob([string], {
    type: "text/csv"
  });
  element.href = URL.createObjectURL(file);
  element.download = "export.csv";
  document.body.appendChild(element);
  element.click();
};

export default createCSV;

import {
  DELETE_USER_PROFILE_FAILURE,
  DELETE_USER_PROFILE_REQUEST,
  DELETE_USER_PROFILE_SUCCESS
} from "../types";

const deleteUser = (state, { type, payload }) => {
  switch (type) {
    case DELETE_USER_PROFILE_REQUEST:
      return {
        ...state,
        selectedUser: {
          isDeleting: true,
          ...state.selectedUser
        }
      };
    case DELETE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        selectedUser: {
          isDeleting: false,
          ...state.selectedUser
        }
      };
    case DELETE_USER_PROFILE_FAILURE:
      return {
        ...state,
        selectedUser: {
          isDeleting: false,
          ...state.selectedUser
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default deleteUser;

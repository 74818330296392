import axios, { AxiosRequestConfig } from "axios";

const depositReceivables = async (
    data: any
): Promise<any> => {
    const options: AxiosRequestConfig = {
        url: `/factoring/deposit/`,
        method: "POST",
        data
    };
    const response = await axios(options);
    return response.data;
};

export default {
    depositReceivables
}
import { denormalize } from "normalizr";
import { paymentProfile } from "../../../api/schema";

export default id => state => {
  try {
    const denormalized = denormalize(
      { item: id },
      { item: paymentProfile },
      state.entities
    );

    return denormalized.item || {};
  } catch (err) {
    return {};
  }
};

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Typography, WithStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/core/SvgIcon/SvgIcon";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import classNames from "classnames";
import { green, amber } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

interface Props extends WithStyles<typeof styles> {
  title?: string;
  message: string;
  className?: string;
  variant: "success" | "warning" | "error" | "info";
}

const PaperSheet = ({
  classes,
  title,
  message,
  variant,
  className
}: Props): JSX.Element => {
  const Icon = variantIcon[variant];
  return (
    <Paper
      className={classNames(classes.root, classes[variant], className)}
      elevation={1}
    >
      {title && (
        <Typography variant="h5" component="h3">
          {title}
        </Typography>
      )}
      <Typography
        component="p"
        align="center"
        style={{
          flexDirection: "row",
          color: "white",
          fontSize: 16
        }}
      >
        {Icon && (
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
        )}
        {message}
      </Typography>
    </Paper>
  );
};

export default withStyles(styles)(PaperSheet);

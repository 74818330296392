import React from "react";
import { compose } from "recompose";
import withLocalTable from "./withLocalTable";
import WithElement from "./WithElement";

const renderView = ({ children, ...props }) => (
  <WithElement {...props}>{children}</WithElement>
);

const WithLocalTableComponent = key => compose(withLocalTable(key))(renderView);

export default WithLocalTableComponent;

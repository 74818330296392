import formatStatus from "../../../../helpers/format/formatStatus";
import formatPhone from "../../../../helpers/format/formatPhone";

const columns = [
  {
    key: "company_name",
    name: "Company",
    filter: "text",
    filter_key: "company_name",
    sort: true
  },
  {
    key: "credit_approved",
    name: "Status",
    format: formatStatus
  },
  {
    key: "email",
    name: "Email",
    filter: "text",
    filter_key: "email",
    sort: true
  },
  {
    key: "contact_name",
    name: "Contact",
    filter: "text",
    filter_key: "contact_name"
  },
  {
    key: "phone_number",
    name: "Phone #",
    filter: "text",
    filter_key: "phone_number",
    format: formatPhone
  },
  {
    key: "mc",
    name: "MC #",
    filter: "text",
    filter_key: "mc"
  },
  {
    key: "dot",
    name: "DOT #",
    filter: "text",
    filter_key: "dot"
  },
  {
    key: "tax_id",
    name: "Tax ID",
    filter: "text",
    filter_key: "tax_id"
  },
  {
    key: "more",
    name: "More"
  }
];

export default columns;

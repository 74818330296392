import React from 'react'

import colors from '../theme/colors';
import formatPennies from '../helpers/format/formatPennies'
import { Typography } from '@material-ui/core';

interface Props {
    negative: boolean;
    amount: number;
}

const ReserveAmount: React.FC<Props> = props => {
    return (
        <Typography
            variant="caption"
            style={{
                color: props.negative ? colors.danger : undefined
            }}
        >
            {formatPennies(props.amount)}
        </Typography>
    )
}

export default ReserveAmount;
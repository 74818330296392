import { Grid, Typography } from "@material-ui/core";
import React, { ChangeEventHandler, useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import get from "lodash/get";
import { DateTime } from "luxon";
import { Field, FieldProps } from "formik";
import { isEmpty } from "lodash";
import CustomTextField from "../../../../../components/inputs/CustomTextField";
import PenniesInput from "../../../../../components/inputs/PenniesInput";
import DatePicker from "../../../../../components/inputs/DatePicker";
import CSVButton from "../../../../../components/buttons/CSVButton";
import TypeInput from "./TypeInput";
import UploadCSV from "../../../../../components/UploadCSV";
import API from "../../../../../api";
import colors from "../../../../../theme/colors";
import formatPennies from "../../../../../helpers/format/formatPennies";

interface Props {
  values: {
    sch: string;
    amount: string;
    notes: string;
    values: string;
    type: string;
    paid_date: string | DateTime;
    bank_description: string;
  };
  touched: {
    sch: boolean;
    amount: boolean;
    notes: boolean;
    values: boolean;
    type: boolean;
    paid_date: boolean;
    bank_description: boolean;
  };
  errors: {
    sch?: string;
    amount?: string;
    notes?: string;
    values?: string;
    type?: string;
    paid_date?: string;
    bank_description: string;
  };
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleCSV: () => void;
  handleUpload: (file: any) => void;
  amount?: number;
}

const marks = {
  30: <Typography>30+</Typography>,
  40: <Typography>40+</Typography>,
  50: <Typography>50+</Typography>,
  60: <Typography>60+</Typography>,
  90: <Typography>90+</Typography>
};

const Fields = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleCSV,
  setFieldValue,
  handleUpload,
  amount = 0
}: Props): JSX.Element => {
  const [payments, setPayments] = useState([]);
  const history = useHistory();
  const asyncCheckPayments = useCallback(async value => {
    try {
      if (!value) return;
      const res = await API.factoring.checkPayments(value);
      setPayments(res.results);
    } catch (e) {}
  }, []);

  function validateAmount(value: number) {
    let error;
    if (isEmpty(value)) {
      error = "Amount is a required field";
    } else if (amount !== value) {
      error = `${formatPennies(amount)} - ${formatPennies(
        value
      )} = ${formatPennies(amount - value)}`;
    }
    return error;
  }

  const PaymentsView = ({
    id,
    sch
  }: {
    id: string;
    sch: string;
  }): React.ReactElement => (
    <Grid container justify="space-between">
      <Grid item>
        <a
          id="ignore_payment"
          href="#"
          style={{ textDecoration: "none" }}
          onClick={() => setPayments([])}
        >
          <Typography style={{ color: colors.green }}>
            Ignore Payment
          </Typography>
        </a>
      </Grid>
      <Grid item>
        <Link
          target="_blank"
          id="show_payment"
          href="#"
          style={{ textDecoration: "none" }}
          to={{
            pathname: `/admin/finances/payments/?sch=${sch}`,
            state: {
              sch
            }
          }}
        >
          <Typography color="primary">Show Payment</Typography>
        </Link>
      </Grid>
    </Grid>
  );

  return (
    <Grid container justify="space-between">
      <Grid item lg={10}>
        <Grid container spacing={32}>
          <Grid item lg={4}>
            <Grid container direction="column" spacing={16}>
              <Grid item lg={12}>
                <TypeInput
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                />
              </Grid>
              <Grid item lg={12}>
                <CustomTextField
                  fullWidth
                  label="Reference Number#"
                  name="sch"
                  value={values.sch}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e);
                    asyncCheckPayments(e.currentTarget.value);
                  }}
                  error={!!errors.sch && touched.sch}
                  helperText={[
                    !!errors.sch && touched.sch ? errors.sch : "",
                    payments.length > 0 && (
                      <PaymentsView
                        id={get(payments, "[0].id", "")}
                        sch={get(payments, "[0].sch", "")}
                      />
                    )
                  ]}
                />
              </Grid>
              <Grid item lg={12}>
                <Field
                  name="amount"
                  // validate={validateAmount}
                  render={({
                    field: { onChange, name, value },
                    form: { errors, setFieldValue }
                  }: FieldProps) => (
                    <>
                      <PenniesInput
                        id="amount"
                        fullWidth
                        label="Amount"
                        name={name}
                        value={value}
                        setFieldValue={setFieldValue}
                        error={!!errors.amount}
                        helperText={errors.amount ? errors.amount : ""}
                        withStyle
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4}>
            <Grid container direction="column" spacing={16}>
              <Grid item lg={12}>
                <CustomTextField
                  fullWidth
                  label="Notes"
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.notes && touched.notes}
                  helperText={
                    !!errors.notes && touched.notes ? errors.notes : ""
                  }
                />
              </Grid>
              <Grid item lg={12}>
                <DatePicker
                  fullWidth
                  name="paid_date"
                  label="Payment Posting Date"
                  onBlur={handleBlur}
                  value={values.paid_date === "" ? null : values.paid_date}
                  onChange={(date: DateTime): void => {
                    setFieldValue("paid_date", date.toISO());
                  }}
                />
              </Grid>
              <Grid item lg={12}>
                <CustomTextField
                  fullWidth
                  label="Bank Description"
                  name="bank_description"
                  value={values.bank_description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.bank_description && touched.bank_description}
                  helperText={
                    !!errors.bank_description && touched.bank_description
                      ? errors.bank_description
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={16}>
          <Grid item>
            <UploadCSV handleUpload={handleUpload} />
          </Grid>
          <Grid item>
            <CSVButton handleCSV={handleCSV} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Fields;

import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  Grid,
  List,
  Paper,
  Typography,
  InputAdornment,
  FormControlLabel,
  FormGroup,
  Switch,
  FormHelperText
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { get, head } from "lodash";
import { Formik } from "formik";

import ListItem from "../../../../components/ListItem";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import tabs from "../tabs";
import BoldInput from "../../../../components/inputs/BoldInput";
import SaveButton from "./components/SaveButton";
import { fetchGroup, getGroup, updateGroup } from "../../../../modules/groups";
import { openSnackbar } from "../../../../modules/ui";

const Ownership = (props: any): JSX.Element => {
  const dispatch = useDispatch();
  const id = get(props, ["match", "params", "id"], "");
  useEffect((): void => {
    dispatch(fetchGroup(id));
  }, [id]);
  const group = useSelector(getGroup(id));

  return (
    <Can
      perform="admin-groups:view"
      yes={(): JSX.Element => (
        <Formik
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await dispatch(
                updateGroup(id, {
                  name: values.name,
                  custom_invite_token: values.custom_invite_token,
                  hide_onboarding_haulpay_app:
                    values.hideOnboardingHaulPayApp || false,
                  show_rmis_link_onboarding:
                    values.showRmisLinkOnboarding || false,
                  rmis_url: values.rmisUrl,
                  rmis_text: values.rmisText,
                  include_carrier_id_check: values.includeCarrierIDCheck,
                  show_onboarding_haulpay_app: values.showOnboardingHaulpayApp,
                  show_onboarding_comfreight_app: values.showOnboardingComfreightApp
                })
              );
              dispatch(openSnackbar("success", "Saved Group!"));
            } catch (err) {
              dispatch(openSnackbar("error", err as string));
            }
            setSubmitting(false);
          }}
          initialValues={{
            name: group.name,
            type: group.type,
            email: group.email,
            dba: group.company_name,
            custom_invite_token: group.custom_invite_token,
            hideOnboardingHaulPayApp: group.hide_onboarding_haulpay_app,
            showRmisLinkOnboarding: group.show_rmis_link_onboarding,
            rmisUrl: group.rmis_url,
            rmisText: group.rmis_text,
            includeCarrierIDCheck: group.include_carrier_id_check,
            showOnboardingHaulpayApp: group.show_onboarding_haulpay_app,
            showOnboardingComfreightApp: group.show_onboarding_comfreight_app
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue
          }): JSX.Element => (
            <InternalLayout title="Groups">
              <Grid container direction="column" spacing={16}>
                <Grid item style={{ width: "100%" }}>
                  <Grid container direction="row-reverse" spacing={16}>
                    <Grid item>
                      <SaveButton onClick={() => handleSubmit()} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <NavigationTabs tabs={tabs(id)} value={0} />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Paper style={{ padding: 16, height: "100%" }}>
                    <List dense style={{ maxWidth: 400 }}>
                      <ListItem>
                        <Typography>
                          <div style={{ color: "black" }}>Edit Group</div>
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <BoldInput
                          label="Group Name"
                          fullWidth
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                      </ListItem>
                      <ListItem>
                        <BoldInput
                          disabled
                          label="Group Type"
                          fullWidth
                          value={values.type}
                        />
                      </ListItem>
                      <ListItem>
                        <BoldInput
                          disabled
                          label="Owner's Email"
                          fullWidth
                          value={values.email}
                        />
                      </ListItem>
                      <ListItem>
                        <BoldInput
                          label="Owner DBA"
                          disabled
                          fullWidth
                          value={values.dba}
                        />
                      </ListItem>
                      <ListItem>
                        <BoldInput
                          label="Custom Invite Link"
                          fullWidth
                          name="custom_invite_token"
                          onChange={handleChange}
                          value={values.custom_invite_token}
                          startAdornment={
                            <InputAdornment position="start">{`${window.location.hostname}/invited/`}</InputAdornment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <FormHelperText>
                          <a
                            href="#"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                `${window.location.origin}/invited/${values.custom_invite_token}`
                              )
                            }
                          >
                            Copy Link to Share
                          </a>
                        </FormHelperText>
                      </ListItem>
                      {values.showOnboardingHaulpayApp !== undefined && (
                        <ListItem>
                          <FormGroup>
                            <FormControlLabel
                              control={(
                                <Switch
                                  checked={values.showOnboardingHaulpayApp}
                                  onChange={(event, checked)=> {
                                    setFieldValue(
                                      "showOnboardingHaulpayApp",
                                      checked
                                    );
                                    setFieldValue(
                                      "showOnboardingComfreightApp",
                                      !checked
                                    );
                                  }}
                                  value="showOnboardingHaulpayApp"
                                />
                              )}
                              label="Show buttons for HP mobile app on HP Complete Step"
                            />
                          </FormGroup>
                        </ListItem>
                      )}
                      {values.showOnboardingComfreightApp !== undefined && (
                        <ListItem>
                          <FormGroup>
                            <FormControlLabel
                              control={(
                                <Switch
                                  checked={values.showOnboardingComfreightApp}
                                  onChange={(event, checked) => {
                                    setFieldValue(
                                      "showOnboardingComfreightApp",
                                      checked
                                    );
                                    setFieldValue(
                                      "showOnboardingHaulpayApp",
                                      !checked
                                    );
                                  }}
                                  value="showOnboardingComfreightApp"
                                />
                              )}
                              label="Show buttons for CF mobile app on HP Complete Step"
                            />
                          </FormGroup>
                        </ListItem>
                      )}
                      {values.hideOnboardingHaulPayApp !== undefined && (
                        <ListItem>
                          <FormGroup>
                            <FormControlLabel
                              control={(
                                <Switch
                                  checked={values.hideOnboardingHaulPayApp}
                                  onChange={() => {
                                    setFieldValue(
                                      "hideOnboardingHaulPayApp",
                                      !values.hideOnboardingHaulPayApp
                                    );
                                  }}
                                  value="hideOnboardingHaulPayApp"
                                />
                              )}
                              label="Hide buttons for HP mobile app on HP Complete Step"
                            />
                          </FormGroup>
                        </ListItem>
                      )}
                      {values.includeCarrierIDCheck !== undefined && (
                        <ListItem>
                          <FormGroup>
                            <FormControlLabel
                              control={(
                                <Switch
                                  checked={values.includeCarrierIDCheck}
                                  onChange={() => {
                                    setFieldValue(
                                      "includeCarrierIDCheck",
                                      !values.includeCarrierIDCheck
                                    );
                                  }}
                                  value="includeCarrierIDCheck"
                                />
                              )}
                              label="Include Carrier ID Check Step"
                            />
                          </FormGroup>
                        </ListItem>
                      )}
                      {values.showRmisLinkOnboarding !== undefined && (
                        <>
                          <ListItem>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={values.showRmisLinkOnboarding}
                                    onChange={() => {
                                      setFieldValue(
                                        "showRmisLinkOnboarding",
                                        !values.showRmisLinkOnboarding
                                      );
                                    }}
                                    value="showRmisLinkOnboarding"
                                  />
                                }
                                label="Link to Secondary Boarding from HP Complete Step (RMIS, etc.) "
                              />
                            </FormGroup>
                          </ListItem>
                          <ListItem>
                            <BoldInput
                              label="Secondary Boarding Button Text"
                              fullWidth
                              name="rmisText"
                              onChange={handleChange}
                              value={values.rmisText}
                            />
                          </ListItem>
                          <ListItem>
                            <BoldInput
                              label="Secondary Boarding Url"
                              fullWidth
                              name="rmisUrl"
                              value={values.rmisUrl}
                              onChange={handleChange}
                            />
                          </ListItem>
                        </>
                      )}
                    </List>
                  </Paper>
                </Grid>
              </Grid>
            </InternalLayout>
          )}
        </Formik>
      )}
      no={(): JSX.Element => <Redirect to="/" />}
    />
  );
};

export default Ownership;

import PropTypes from "prop-types";
import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

import colors from "../../../theme/colors";
import SnackbarContentWrapper from "../../../components/Snackbar";
import ContinueButton from "../../../components/buttons/ContinueButton";
import CarrierInfo from "./form/CarrierInfo";
import InvitedValidationSchema from "./form/formValidation";

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2
  }
});

const Heading = () => (
  <Typography
    align="center"
    variant="body1"
    style={{
      marginTop: 30,
      marginBottom: 30,
      fontWeight: 400,
      color: colors.gray
    }}
  >
    Please make sure to confirm all of the following information
  </Typography>
);

const getInitialValues = (user, landing) => {
  if (!isEmpty(user)) {
    const name = (user.contact_name || "").split(" ");
    const ownerFirstName = name.slice(0, 1).join(" ");
    const ownerLastName = name.slice(1, name.length).join(" ");
    return {
      owner_first_name: ownerFirstName,
      owner_last_name: ownerLastName,
      email: user.email,
      password: undefined,
      address: undefined,
      owner_phone_number: user.contact_phone,
      mc: user.mc,
      dot: user.dot,
      tax_id: user.tax_id,
      company_name: user.dba,
      terms: true
    };
  }
  if (landing) {
    return {
      owner_first_name: undefined,
      owner_last_name: undefined,
      email: undefined,
      password: undefined,
      owner_phone_number: "",
      address: undefined,
      mc: undefined,
      dot: undefined,
      tax_id: undefined,
      company_name: ""
    };
  }
  return {
    owner_first_name: undefined,
    owner_last_name: undefined,
    email: undefined,
    password: undefined,
    address: undefined,
    owner_phone_number: "",
    mc: undefined,
    dot: undefined,
    tax_id: undefined,
    company_name: "",
    terms: true
  };
};

const Confirm = ({
  carrierForRef = {},
  factoringLoading,
  factoringError,
  setErrorMsg,
  errorMsg,
  classes,
  user,
  loadingUser,
  onSubmit,
  token,
  landing,
}) => (
  <Grid>
    <Heading />
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch"
      spacing={32}
    >
      {errorMsg && (
        <SnackbarContentWrapper
          className={classes.margin}
          variant="error"
          message={factoringError}
          onClick={() => setErrorMsg(false)}
        />
      )}
      {!loadingUser || factoringLoading ? (
        <Formik
          enableReinitialize
          innerRef={ref => {
            carrierForRef = ref;
          }}
          validationSchema={() =>
            InvitedValidationSchema(!isEmpty(user), landing)
          }
          initialValues={getInitialValues(user, landing)}
          onSubmit={onSubmit}
        >{props => (
          <CarrierInfo
            {...props}
            setPassword={isEmpty(user)}
            token={token}
            landing={landing}
          />
        )}
        </Formik>
      ) : (
        <CircularProgress
          style={{ alignSelf: "center", justifySelf: "center", margin: 30 }}
          color="secondary"
          size={80}
        />
      )}
      <Grid
        container
        direction="row-reverse"
        xs={11}
        md={12}
        lg={12}
        alignItems="flex-start"
      >
        <ContinueButton
          disabled={factoringLoading}
          onClick={() => {
            carrierForRef.submitForm();
            if ("parentIFrame" in window)
              window.parentIFrame.scrollToOffset(0, 0);
          }}
        />
      </Grid>
    </Grid>
  </Grid>
);

Confirm.propTypes = {
  nextStep: PropTypes.func.isRequired,
  factoringError: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired
};

export default withStyles(styles)(Confirm);

import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import { DialogTitle, Dialog, DialogContent } from "../../../../../components/Dialog";
import { fetchLineItemHistory } from "../../../../../modules/factoring";
import { getDialog } from "../../../../../modules/ui";
import AuditTrail from "../../../../../components/AuditTrail";

interface Props {
  open: boolean;
  handleExit: () => void;
}

export default function LineItemHistory({
  open,
  handleExit
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const lineItem = get(dialog, ["data", "lineItem"]);
  const fundingRequest = get(dialog, ["data", "fundingRequest"]);
  const [history, setHistory] = useState([]);
  useEffect(() => {
    const f = async () => {
      if (lineItem && fundingRequest) {
        const result = await dispatch(
          fetchLineItemHistory(fundingRequest, lineItem)
        );
        // @ts-ignore
        setHistory(result);
      }
    };
    f();
  }, [lineItem, fundingRequest]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleExit}>Line Item History</DialogTitle>
      <DialogContent>
        <Grid>
          <AuditTrail auditLog={history} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

import { assign } from "lodash";
import {
  FETCH_QUERY_DEBTORS_REQUEST,
  FETCH_QUERY_DEBTORS_SUCCESS,
  FETCH_QUERY_DEBTORS_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_QUERY_DEBTORS_REQUEST:
      return {
        ...state,
        id: action.payload.id,
        connectDebtor: assign({}, state.connectDebtor, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          offset: action.payload.offset,
          ordering: action.payload.ordering
        })
      };
    case FETCH_QUERY_DEBTORS_SUCCESS:
      return {
        ...state,
        connectDebtor: assign({}, state.connectDebtor, {
          loading: false,
          ids: action.payload.ids,
          count: action.payload.count
        })
      };
    case FETCH_QUERY_DEBTORS_FAILURE:
      return {
        ...state,
        connectDebtor: assign({}, state.connectDebtor, {
          loading: false
        })
      };
    default:
      return state;
  }
};

interface Tab {
  label: string;
  route: string;
  value: string;
}

const tabs = (id: string, similarPayments = 0): Tab[] => [
  {
    label: "Profile",
    route: `/admin/payment/${id}/profile`,
    value: "profile"
  },
  {
    label: "Funding Requests",
    route: `/admin/payment/${id}/funding_requests`,
    value: "funding_requests"
  },
  {
    label: "Receivables",
    route: `/admin/payment/${id}/receivables`,
    value: "receivables"
  },
  {
    label: "Connected Brokers",
    route: `/admin/payment/${id}/connected_brokers`,
    value: "connected_brokers"
  },
  {
    label: "Purchases",
    route: `/admin/payment/${id}/purchases`,
    value: "purchases"
  },
  {
    label: "Similar Payment Profiles",
    route: `/admin/payment/${id}/similar_payment_profiles`,
    value: "similar_payment_profiles",
    ...(() => (similarPayments > 0 ? { badge: similarPayments } : {}))()
  }
];

export default tabs;

import React, { ReactNode } from "react";
import { ExpansionPanel, createStyles, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ExpansionPanelProps } from "@material-ui/core/ExpansionPanel";

const styles = createStyles({
  root: {
    boxShadow: "none !important"
  }
});

interface Props extends WithStyles<typeof styles> {
  children: ReactNode;
}

const CustomExpansionPanel = ({
  children,
  classes,
  ...props
}: Props & ExpansionPanelProps): JSX.Element => (
  <ExpansionPanel
    {...props}
    classes={{
      root: classes.root
    }}
    id="expansion"
  >
    {children}
  </ExpansionPanel>
);

export default withStyles(styles)(CustomExpansionPanel);

import formatPennies from "../../../../helpers/format/formatPennies";
import formatDate from "../../../../helpers/format/formatDate";

const columns = [
  {
    key: "company_name",
    name: "Payee",
    filter: "text",
    filter_key: "company_name"
  },
  {
    key: "trace_id",
    name: "Ref #"
  },
  {
    key: "amount",
    name: "Total Funded",
    format: formatPennies
  },
  {
    key: "status",
    name: "Status",
    default: "-"
  },
  {
    key: "company_name",
    name: "Client"
  },
  {
    key: "created",
    name: "Dates",
    format: formatDate,
    default: "-"
  },
  {
    key: "action",
    name: ""
  }
];

export default columns;

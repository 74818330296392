import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';

interface Props {
  value: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  disabled?: boolean;
}

const BillWithFundingRequest = ({
  value,
  setFieldValue,
  disabled,
}: Props): JSX.Element => (
  <FormControlLabel
    control={
      <Checkbox
        checked={value}
        onChange={(event, checked): void => {
          setFieldValue('billWithFundingRequest', checked);
        }}
        disabled={disabled}
      />
    }
    label="Bill With Funding Request?"
  />
);

export default BillWithFundingRequest;

import { assign } from "lodash";
import {
  DELETE_TRANSACTIONNOTE_REQUEST,
  DELETE_TRANSACTIONNOTE_SUCCESS,
  DELETE_TRANSACTIONNOTE_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case DELETE_TRANSACTIONNOTE_REQUEST:
      return {
        ...state,
        loadingTransactionNotes: true
      };
    case DELETE_TRANSACTIONNOTE_SUCCESS:
      return {
        ...state,
        loadingTransactionNotes: false,
        transactionNotes: assign({}, state.transactionNotes, {
          [action.payload.fundingRequest]: state.transactionNotes[
            action.payload.fundingRequest
          ].filter(note => {
            return note !== action.payload.note;
          })
        })
      };
    case DELETE_TRANSACTIONNOTE_FAILURE:
      return {
        ...state,
        loadingTransactionNotes: false
      };
    default:
      return state;
  }
};

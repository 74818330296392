import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_DEBTORRELATIONHISTORY_FAILURE,
  FETCH_DEBTORRELATIONHISTORY_REQUEST,
  FETCH_DEBTORRELATIONHISTORY_SUCCESS
} from "../types";

const fetchDebtorRelationHistory = relationshipId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_DEBTORRELATIONHISTORY_REQUEST)({}));
    const response = await api.factoring.getDebtorRelationHistory(
      relationshipId
    );
    const data = normalize(
      { id: relationshipId, history: response },
      schema.debtorRelationshipHistory
    );
    dispatch(addEntities(data.entities));
    dispatch(createAction(FETCH_DEBTORRELATIONHISTORY_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(FETCH_DEBTORRELATIONHISTORY_FAILURE)(err));
    throw err;
  }
};

export default fetchDebtorRelationHistory;

import { createReducer } from "redux-starter-kit";
import * as TYPES from "./types";
import fetchUsersReducers from "./reducers/fetchUsers";
import withTable from "../withTable";
import queryfetchUsers from "./actions/fetchUsers";

const INITIAL_STATE = {
  loading: false,
  ids: [],
  count: 0,
  offset: 0,
  limit: 20,
  status: undefined,
  ordering: undefined,
  rowsPerPageOptions: [5, 10, 20, 50, 100, 200],
  createDebtorDialog: {
    show: false
  }
};

const {
  selectors,
  actions,
  reducer: tableReducer,
  middleware: tableMiddleware
} = withTable({
  stateKey: "users",
  typeKey: "users"
});

const reducer = createReducer(INITIAL_STATE, {
  [TYPES.FETCH_QUERY_USERS_SUCCESS]: fetchUsersReducers,
  [TYPES.FETCH_QUERY_USERS_FAILURE]: fetchUsersReducers,
  [TYPES.FETCH_QUERY_USERS_REQUEST]: fetchUsersReducers
});

export default tableReducer(reducer);

export const middleware = tableMiddleware(tableSelectors => store => action => {
  const state = store.getState();
  const sortBy = tableSelectors.getSortBy(state);
  const sortDirection = tableSelectors.getSortDirection(state);
  const offset = tableSelectors.getOffset(state);
  const rowsPerPage = tableSelectors.getRowsPerPage(state);
  const filters = tableSelectors.getFilters(state);

  store.dispatch(
    queryfetchUsers(
      sortDirection === "asc" ? sortBy : `-${sortBy}`,
      rowsPerPage,
      offset,
      filters
    )
  );
});
export const {
  getOffset,
  getRowsPerPageOptions,
  getRowsPerPage,
  getCount,
  getSortDirection,
  getSortBy,
  getPage,
  getFilters,
  getIsLoading
} = selectors;

export const {
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter
} = actions;

export { default as queryFetchUsers } from "./actions/fetchUsers";
export { default as getUsers } from "./selectors/getUsers";
export { default as createUsersCSV } from "./actions/createUsersCSV";

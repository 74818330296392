import React, { ReactNode } from "react";
import {
  Grid,
  Typography,
  createStyles,
  WithStyles,
  Theme
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import HaulPayLogo from "./HaulPayLogo";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (themes: Theme) =>
  createStyles({
    headline: {
      [themes.breakpoints.down("sm")]: {
        fontSize: 18
      },
      [themes.breakpoints.down("xs")]: {
        fontSize: 16
      }
    }
  });

interface IHeader extends WithStyles<typeof styles> {
  noTitle?: boolean;
  isInvited?: boolean;
}

const Header = ({
  classes,
  noTitle = false,
  isInvited = false
}: IHeader): JSX.Element => (
  <Grid container direction="row" justify="center" spacing={8} xs={12}>
    {!noTitle && (
      <Grid item xs={12} justify="center" alignItems="center">
        <Typography
          // variant="headline"
          color="secondary"
          style={{
            fontFamily: "Avenir-Book",
            color: "#5C6979",
            textAlign: "center",
            fontSize: "2em"
          }}
          className={classes.headline}
        >
          WELCOME TO
        </Typography>
      </Grid>
    )}
    <Grid container item xs={12} justify="center" alignItems="center">
      <HaulPayLogo
        style={{
          width: "100%"
        }}
      />
    </Grid>
    {isInvited && (
      <Grid item xs={12} justify="center" alignItems="center">
        <Typography
          // variant="headline"
          color="secondary"
          style={{
            fontFamily: "Avenir-Book",
            color: "#5C6979",
            textAlign: "center",
            fontSize: "2em"
          }}
          className={classes.headline}
        >
          Freight Payment Boarding
        </Typography>
      </Grid>
    )}
  </Grid>
);

export default withStyles(styles)(Header);

import {
  DELETE_FUNDINGREQUEST_FAILURE,
  DELETE_FUNDINGREQUEST_REQUEST,
  DELETE_FUNDINGREQUEST_SUCCESS
} from "../types";

const deleteFundingRequest = (state, { type, payload }) => {
  switch (type) {
    case DELETE_FUNDINGREQUEST_REQUEST:
      return {
        ...state,
        isDeletingFundingRequest: true
      };
    case DELETE_FUNDINGREQUEST_SUCCESS:
      return {
        ...state,
        isDeletingFundingRequest: false
      };
    case DELETE_FUNDINGREQUEST_FAILURE:
      return {
        ...state,
        isDeletingFundingRequest: false
      };
    default:
      return {
        ...state
      };
  }
};

export default deleteFundingRequest;

import { assign } from "lodash";
import { PayloadAction } from "redux-starter-kit";
import {
  FETCH_DEBTORCONNECTEDCLIENTS_FAILURE,
  FETCH_DEBTORCONNECTEDCLIENTS_REQUEST,
  FETCH_DEBTORCONNECTEDCLIENTS_SUCCESS,
  State
} from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case FETCH_DEBTORCONNECTEDCLIENTS_REQUEST:
      return assign({}, state, {
        id: action.payload.id,
        connectedClients: assign({}, state.connectedClients, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          offset: action.payload.offset,
          ordering: action.payload.ordering
        })
      });
    case FETCH_DEBTORCONNECTEDCLIENTS_SUCCESS:
      return assign({}, state, {
        connectedClients: assign({}, state.connectedClients, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count
        })
      });
    case FETCH_DEBTORCONNECTEDCLIENTS_FAILURE:
      return {
        ...state,
        connectedClients: assign({}, state.connectedClients, {
          loading: false
        })
      };
    default:
      return state;
  }
};

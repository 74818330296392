import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faClock,
  faMinus,
  faPaperclip
} from "@fortawesome/free-solid-svg-icons";
import { get, last } from "lodash";
import formatStatus from "../helpers/format/formatStatus";
import formatDate from "../helpers/format/formatDate";
import colors from "../theme/colors";

interface IconStatusProps {
  status: string;
}

const IconStatus = ({ status }: IconStatusProps): JSX.Element => {
  switch (status) {
    case "Approved":
      return <FontAwesomeIcon size="xs" color={colors.green} icon={faCircle} />;
    case "Lead In":
      return <FontAwesomeIcon size="xs" color={colors.green} icon={faCircle} />;
    case "Incomplete":
      return (
        <FontAwesomeIcon size="xs" color={colors.warning} icon={faCircle} />
      );
    case "Remote Approved":
      return (
        <FontAwesomeIcon size="xs" color={colors.warning} icon={faCircle} />
      );
    case "Document Review":
      return (
        <FontAwesomeIcon size="xs" color={colors.warning} icon={faCircle} />
      );
    case "Document Issue":
      return (
        <FontAwesomeIcon size="xs" color={colors.warning} icon={faCircle} />
      );
    case "Paid":
      return <FontAwesomeIcon size="xs" color={colors.green} icon={faCircle} />;
    case "Doc Issue":
      return (
        <FontAwesomeIcon size="xs" color={colors.danger} icon={faCircle} />
      );
    case "Declined":
      return (
        <FontAwesomeIcon size="xs" color={colors.danger} icon={faCircle} />
      );
    case "Bad Lead":
      return (
        <FontAwesomeIcon size="xs" color={colors.danger} icon={faCircle} />
      );
    case "Dead":
      return (
        <FontAwesomeIcon size="xs" color={colors.danger} icon={faCircle} />
      );
    case "New":
      return (
        <FontAwesomeIcon size="xs" color={colors.secondary} icon={faCircle} />
      );
    case "New NOA":
      return (
        <FontAwesomeIcon size="xs" color={colors.secondary} icon={faCircle} />
      );
    case "Hold":
    case "Pending":
      return (
        <FontAwesomeIcon size="xs" color={colors.warning} icon={faCircle} />
      );
    case "Pending NOA":
      return (
        <FontAwesomeIcon size="xs" color={colors.warning} icon={faCircle} />
      );
    default:
      return <FontAwesomeIcon size="xs" icon={faClock} />;
  }
};

interface Props {
  status: string;
  statusTimeline?: any;
  newAttachments?: boolean;
  modified: string;
  nonFactoredType?: string;
  factored?: boolean;
}

interface NonFactoredProps {
  bill_out: string;
  standard: string;
}

const nonFactoredValueMap: NonFactoredProps = {
  bill_out: "Bill Out Only",
  standard: "Standard - Pay Carrier"
};

const Status = ({
  status,
  statusTimeline = [],
  newAttachments = false,
  modified,
  nonFactoredType = "",
  factored = undefined
}: Props): JSX.Element => {
  const formattedStatus = formatStatus(status);
  const statusDateTime = get(
    last(statusTimeline),
    "status_set_datetime",
    modified
  );
  return (
    <React.Fragment>
      {status ? (
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ minWidth: 150 }}
          spacing={8}
        >
          <Grid item>
            <IconStatus status={formattedStatus} />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item xs={12}>
                <Typography style={{ fontSize: 12 }}>
                  <b
                    style={{
                      color:
                        status === "paid" || status === "approved"
                          ? colors.green
                          : undefined
                    }}
                  >
                    {` ${formattedStatus}`}
                  </b>
                </Typography>
              </Grid>
              {statusDateTime && (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: 10 }}>
                    {`(${formatDate(statusDateTime)})`}
                  </Typography>
                </Grid>
              )}
              {nonFactoredType && (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: 10 }}>
                    {
                      nonFactoredValueMap[
                        nonFactoredType as keyof NonFactoredProps
                      ]
                    }
                  </Typography>
                </Grid>
              )}
              {newAttachments && (
                <Grid item>
                  <Typography>
                    <div style={{ color: colors.green }}>
                      <FontAwesomeIcon size="xs" icon={faPaperclip} />
                      {' '}
                      NEW!
                    </div>
                  </Typography>
                </Grid>
              )}
              {factored === false ? (
                <Typography style={{ fontSize: 10 }}>Non-Factored</Typography>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <FontAwesomeIcon size="xs" icon={faMinus} />
      )}
    </React.Fragment>
  );
};

// @ts-ignore
export default Status;

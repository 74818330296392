import axios, { AxiosRequestConfig } from "axios";

const addReferralSource = async (name: string): Promise<any> => {
  const url = `/referral_sources/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      name
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateReferralSource = async ({
  name,
  id
}: {
  name: string;
  id: string;
}): Promise<any> => {
  const url = `/referral_sources/${id}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "PATCH",
    data: {
      name
    }
  };
  const response = await axios(options);
  return response.data;
};

const deleteReferralSource = async (
  id: string): Promise<any> => {
  const url = `/referral_sources/${id}/`;
  const options: AxiosRequestConfig = {
    url,
    method: "DELETE",
  };
  const response = await axios(options);
  return response.data;
};

const fetchReferralSources = async (): Promise<any> => {
  const url = `/referral_sources/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

export default {
  addReferralSource,
  updateReferralSource,
  deleteReferralSource,
  fetchReferralSources
};

import { merge } from "lodash";
import {
  FETCH_NOTES_FAILURE,
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_SUCCESS
} from "../types";

const fetchDebtorNotes = (state, { type, payload }) => {
  switch (type) {
    case FETCH_NOTES_REQUEST:
      return {
        ...state,
        loadingDebtorNotes: true
      };
    case FETCH_NOTES_SUCCESS:
      return {
        ...state,
        debtorNotes: merge({}, state.debtorNotes, payload),
        loadingDebtorNotes: false
      };
    case FETCH_NOTES_FAILURE:
      return {
        ...state,
        loadingDebtorNotes: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchDebtorNotes;

import React, { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { get, isNull } from "lodash";
import {
  IconButton,
  Paper,
  Tab,
  Tabs,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RefreshOutlined } from "@material-ui/icons";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import Badge from "../../../../components/Badge";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import Table from "../../../../components/Table";
import columns from "./columns";
import NavigationTabs from "../../../../components/NavigationTabs";
import tabs from "../tabs";
import {
  invitedCarriersTableSelectors,
  invitedCarriersTableActions,
  queryInvitedCarriers,
  getInvitedCarriers,
  inviteMembers,
  needsReviewCount,
} from "../../../../modules/groups";
import {
  openDialog,
  closeDialog,
  openSnackbar,
  getDialog,
} from "../../../../modules/ui";

import EditButton from "./components/EditButton";
import Colors from "../../../../theme/colors";
import AdjustmentsDialog from "../../Finances/Receivables/components/AdjustmentsDialog";
import PaymentProfileModal from "./components/PaymentProfileModal";
import formatDate from "../../../../helpers/format/formatDate";

const New = (): JSX.Element => {
  // @ts-ignore
  return (
    <Typography style={{ color: Colors.green }}>
      <AttachFileIcon /> NEW!!
    </Typography>
  );
};

const DocStatus = ({ isNew }: { isNew: boolean | string }): JSX.Element => {
  if (Boolean(isNew)) {
    return (
      <>
        <New />
        <Typography variant="caption">({isNew})</Typography>
      </>
    );
  }
  return (
      <Typography variant="caption">No Change</Typography>
  );
};
function Groups(): JSX.Element {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(4);
  const [status, setStatus] = useState("needs_review");
  useEffect(() => {
    dispatch(queryInvitedCarriers(20, 0, "-created", { status }));
  }, [status]);
  const handlerOpenDialog = useCallback(
    (variant, data) => dispatch(openDialog("", "", "", data, variant)),
    [dispatch]
  );
  const handleCloseDialog = useCallback(() => dispatch(closeDialog()), [
    dispatch,
  ]);
  const dialog = useSelector(getDialog);
  const invitedCarriers = useSelector(getInvitedCarriers).map(
    (carrier: any): any => {
      const stillNeeded = [];
      if (carrier.needs_account_info) {
        stillNeeded.push("Need Acct. Info");
      }

      if (carrier.needs_noa) {
        stillNeeded.push("Need NOA");
      }

      if (carrier.needs_factor_info) {
        stillNeeded.push("Need Factor Info");
      }

      if (carrier.needs_verified_identity) {
        stillNeeded.push("Verifying Bank Acct or NOA");
      }

      return {
        ...carrier,
        ...(() =>
          status !== "invited"
            ? {
                bankInformation: `${
                  get(carrier, "account_history_display.modified_by", "")
                    ? get(carrier, "account_history_display.modified_by", "")
                    : ""
                } ${
                  get(carrier, "account_history_display.modified", "")
                    ? formatDate(
                        get(carrier, "account_history_display.modified", "")
                      )
                    : ""
                }`,
                ownerName: `${get(carrier, "owner_name_first", "")} ${get(
                  carrier,
                  "owner_name_last",
                  ""
                )}`,
                stillNeeded: stillNeeded.join(", "),
                doc_status: (
                  <DocStatus
                    isNew={get(carrier, "new_uploaded_documents", false)}
                  />
                ),
                stateZip:
                  carrier.company_address_state && carrier.company_address_zip
                    ? `${carrier.company_address_state} / ${carrier.company_address_zip}`
                    : "",
                actions: <EditButton id={carrier.id} />,
              }
            : {
                actions: (
                  <IconButton
                    style={{ color: "#FF7800" }}
                    onClick={async () => {
                      try {
                        await dispatch(
                          inviteMembers({
                            groupId: carrier.group_id,
                            emails: [carrier.email] || [],
                            phones: [carrier.phone_number] || [],
                          })
                        );
                        dispatch(
                          openSnackbar(
                            "success",
                            `Invite has been resent to ${carrier.email}`
                          )
                        );
                      } catch {
                        dispatch(
                          openSnackbar(
                            "error",
                            `Error while sending the invite to ${carrier.email}`
                          )
                        );
                      }
                    }}
                  >
                    <RefreshOutlined />
                  </IconButton>
                ),
              })(),
      };
    }
  );

  const [badge, setBadge] = useState(10);
  useEffect(() => {
    async function f() {
      const badgeCount = await dispatch(needsReviewCount());
      // @ts-ignore
      setBadge(badgeCount);
    }
    f();
  }, [status]);
  return (
    <Can
      perform="admin-groups:view"
      yes={(): JSX.Element => (
        <InternalLayout title="Groups">
          <NavigationTabs tabs={tabs} value={1} />
          <Paper style={{ marginTop: "32px" }}>
            <Grid container justify="flex-end">
              <Grid item style={{ padding: 20 }}>
                <Button
                  variant="contained"
                  color="primary"
                  mini
                  style={{
                    backgroundColor: Colors.green,
                    color: Colors.white,
                  }}
                  onClick={() =>
                    handlerOpenDialog("create-payment-profile", {})
                  }
                >
                  + Add Payment Profile
                </Button>
              </Grid>
            </Grid>
            <Tabs
              value={tab}
              variant="fullWidth"
              indicatorColor="primary"
              onChange={(event, value): void => {
                setTab(value);
                switch (value) {
                  case 0:
                    setStatus("invited");
                    break;
                  case 1:
                    setStatus("pending");
                    break;
                  case 2:
                    setStatus("approved");
                    break;
                  case 3:
                    setStatus("declined");
                    break;
                  case 4:
                    setStatus("needs_review");
                    break;
                  case 5:
                    setStatus("");
                    break;
                  default:
                    setStatus("pending");
                }
              }}
            >
              <Tab
                id={status === "invited" ? "selected_status" : undefined}
                label="Invited (New)"
              />
              <Tab
                id={status === "pending" ? "selected_status" : undefined}
                label="Pending Carriers"
              />
              <Tab
                id={status === "approved" ? "selected_status" : undefined}
                label="Approved Carriers"
              />
              <Tab
                id={status === "declined" ? "selected_status" : undefined}
                label="Declined Carriers"
              />
              <Tab
                id={status === "needs_review" ? "selected_status" : undefined}
                label={
                  badge ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ paddingRight: "6px" }}>Needs Review</div>
                      <Badge id="needs_review_badge" count={String(badge)} />
                    </div>
                  ) : (
                    "Needs Review"
                  )
                }
              />
              <Tab id={status === "" ? "declined" : undefined} label="All" />
            </Tabs>
            <div style={{ paddingLeft: 16 }}>
              <Table
                columns={columns(status)}
                rows={invitedCarriers}
                isLoading={useSelector(
                  invitedCarriersTableSelectors.getIsLoading
                )}
                handleSort={useCallback(
                  (...args: any[]) =>
                    dispatch(invitedCarriersTableActions.handleSort(...args)),
                  [dispatch]
                )}
                sortDirection={useSelector(
                  invitedCarriersTableSelectors.getSortDirection
                )}
                sortBy={useSelector(invitedCarriersTableSelectors.getSortBy)}
                count={useSelector(invitedCarriersTableSelectors.getCount)}
                page={useSelector(invitedCarriersTableSelectors.getPage)}
                rowsPerPage={useSelector(
                  invitedCarriersTableSelectors.getRowsPerPage
                )}
                handleChangePage={useCallback(
                  (...args: any[]) =>
                    dispatch(
                      invitedCarriersTableActions.handleChangePage(...args)
                    ),
                  [dispatch]
                )}
                rowsPerPageOptions={useSelector(
                  invitedCarriersTableSelectors.getRowsPerPageOptions
                )}
                handleChangeRowsPerPage={useCallback(
                  (...args: any[]) =>
                    dispatch(
                      invitedCarriersTableActions.handleChangeRowsPerPage(
                        ...args
                      )
                    ),
                  [dispatch]
                )}
                filter
                filters={useSelector(invitedCarriersTableSelectors.getFilters)}
                handleFilterChange={useCallback(
                  (...args: any) =>
                    dispatch(
                      invitedCarriersTableActions.handleSearchFilter(...args)
                    ),
                  [dispatch]
                )}
                allowEmpty
              />
            </div>
          </Paper>
          {dialog.action === "create-payment-profile" && (
            <PaymentProfileModal
              handleClose={handleCloseDialog}
              open={dialog.show}
              {...dialog.data}
            />
          )}
        </InternalLayout>
      )}
      no={(): JSX.Element => <Redirect to="/" />}
    />
  );
}

export default Groups;

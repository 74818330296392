import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  CREATE_CARD_ACCOUNT_FAILURE,
  CREATE_CARD_ACCOUNT_REQUEST,
  CREATE_CARD_ACCOUNT_SUCCESS
} from "../types";

const createCardAccount = (
  accountNumberRedacted,
  cardNumber,
  expirationDate,
  method,
  type,
  user,
  zipCode
) => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(CREATE_CARD_ACCOUNT_REQUEST)());
    const account = await api.vgs.createAccountByCard(
      accountNumberRedacted,
      cardNumber,
      expirationDate,
      method,
      type,
      user,
      zipCode
    );

    const data = normalize(account, schema.accounts);
    dispatch(addEntities(data.entities));
    dispatch(createAction(CREATE_CARD_ACCOUNT_SUCCESS)(account));
    return account;
  } catch (err) {
    dispatch(createAction(CREATE_CARD_ACCOUNT_FAILURE)(err));
    throw err;
  }
};

export default createCardAccount;

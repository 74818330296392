import axios from "axios";

const getReserve = async (userId: string): Promise<any> => {
  const response = await axios.get(`/reserve/${userId}/grouped/`);
  return response.data;
};

const getGivebacksAndChargeBacks = async (userId: string): Promise<any> => {
  const response = await axios.get(
    `/reserve/${userId}/?line_item=true&pending_chargebacks=true`
  );
  return response.data;
};

const getReserveAggregate = async (userId: string): Promise<any> => {
  const response = await axios.get(`/reserve/${userId}/aggregate/`);
  return response.data;
};

export default {
  getReserve,
  getGivebacksAndChargeBacks,
  getReserveAggregate
};

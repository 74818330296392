import { createAction } from "redux-starter-kit";
import {
  DELETE_FUNDINGREQUEST_FAILURE,
  DELETE_FUNDINGREQUEST_REQUEST,
  DELETE_FUNDINGREQUEST_SUCCESS
} from "../types";
import { deleteRow } from "../../entities";

const deleteFundingRequest = fundingRequest => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(DELETE_FUNDINGREQUEST_REQUEST)());
    const response = await api.factoring.deleteFundingRequest(fundingRequest);
    dispatch(deleteRow({ entity: "fundingRequests", id: fundingRequest.id }));
    dispatch(createAction(DELETE_FUNDINGREQUEST_SUCCESS)());
    return response;
  } catch (err) {
    dispatch(createAction(DELETE_FUNDINGREQUEST_FAILURE)(err));
    throw err;
  }
};

export default deleteFundingRequest;

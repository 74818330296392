import { withStyles } from "@material-ui/styles";
import { ExpansionPanelDetails, Grid } from "@material-ui/core";
import React from "react";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import CSVButton from "../../../../components/buttons/CSVButton";
import CustomExpansionPanel from "../../../../components/ExpansionPanel";
import ExpansionPanelSummary from "../../../../components/ExpansionPanelSummary";
import AdminDropDown from "../../../../components/AdminDropDown";
import BoldInput, {
  CheckBox,
  Select
} from "../../../../components/inputs/BoldInput";
import DatePicker from "../../../../components/inputs/DatePicker";

const Filters = withStyles({
  root: {
    width: "100%"
  }
})(
  ({
    classes,
    filters,
    handleFilterChange,
    handleCSV,
    datetime_end,
    datetime_start,
    onDateStartChange,
    onDateEndChange
  }) => (
    <div className={classes.root}>
      <CustomExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<FontAwesomeIcon size="xs" icon={faSlidersH} />}
        />
        <ExpansionPanelDetails>
          <Grid container direction="column" spacing={16}>
            <Grid item>
              <Grid container spacing={32}>
                <Grid item lg={2}>
                  <BoldInput
                    fullWidth
                    name="company_profile__mc"
                    label="MC"
                    placeholder="Search..."
                    value={filters.company_profile__mc}
                    onChange={event => {
                      const name = event.target.name;
                      handleFilterChange({
                        target: {
                          name: "company_profile__mc",
                          value:
                            name.toString() === "company_profile__mc"
                              ? event.target.value
                              : ""
                        }
                      });
                    }}
                  />
                </Grid>
                <Grid item lg={2}>
                  <BoldInput
                    fullWidth
                    name="company_profile__dot"
                    label="DOT"
                    placeholder="Search..."
                    value={filters.company_profile__dot}
                    onChange={event => {
                      const name = event.target.name;
                      handleFilterChange({
                        target: {
                          name: "company_profile__dot",
                          value:
                            name.toString() === "company_profile__dot"
                              ? event.target.value
                              : ""
                        }
                      });
                    }}
                  />
                </Grid>
                <Grid item lg={2}>
                  <Select
                    value={filters.period || ""}
                    onChange={event => {
                      filters.period = event.target.value;
                      handleFilterChange(event);
                    }}
                    fullWidth
                    name="period"
                    label="Period"
                    selections={[
                      {
                        text: "All",
                        value: ""
                      },
                      {
                        text: "Last 7 days vs Previous 7 Days",
                        value: "week"
                      },
                      {
                        value: "month",
                        text: "Last 30 days vs Previous 30 Days"
                      }
                    ]}
                  />
                </Grid>
                <Grid item lg={2}>
                  <AdminDropDown
                    fullWidth
                    label="Sales Rep"
                    name="sales_rep_id"
                    value={filters.sales_rep_id || " "}
                    onChange={handleFilterChange}
                  />
                </Grid>
                <Grid item lg={2}>
                  <CheckBox
                    value={filters.inactive}
                    setFieldValue={(name, checked) => {
                      const newEvent = {
                        target: {
                          name,
                          value: checked
                        }
                      };
                      handleFilterChange(newEvent);
                    }}
                    label="Only Inactive"
                    name="inactive"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={32}>
                <Grid item lg={4}>
                  <DatePicker
                    fullWidth
                    name="datetime_start"
                    label="Ucc Followup Date From"
                    value={datetime_start}
                    format="dd-MM-yyyy"
                    onChange={event => {
                      onDateStartChange(event.toString());
                      const newEvent = {
                        target: {
                          name: "ucc_followup_date",
                          value: moment(event)
                            .format("YYYY-MM-DD")
                            .toString()
                        }
                      };

                      handleFilterChange(newEvent);
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <DatePicker
                    fullWidth
                    name="datetime_end"
                    label="Ucc Followup Date To"
                    value={datetime_end}
                    format="dd-MM-yyyy"
                    onChange={event => {
                      onDateEndChange(event.toString());
                      const newEvent = {
                        target: {
                          name: "ucc_followup_date_end",
                          value: moment(event)
                            .format("YYYY-MM-DD")
                            .toString()
                        }
                      };
                      handleFilterChange(newEvent);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </CustomExpansionPanel>
    </div>
  )
);

export default Filters;

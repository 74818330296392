import formatPennies from "../../../../helpers/format/formatPennies";
import formatStatus from "../../../../helpers/format/formatStatus";
import formatDate from "../../../../helpers/format/formatDate";

const nestedPurchasesTableColumns = [
  {
    key: "user_load_number",
    name: "Load #",
    filter: "text",
    filter_key: "dba"
  },
  {
    key: "amount",
    name: "Funded Amnt.",
    format: formatPennies,
  },
  {
    key: "invoice_number",
    name: "Invoice #",
    default: "-",
    filter: "text",
    filter_key: "invoice_number"
  },
  {
    key: "assigned_admin.name",
    name: "Admin"
  },
  {
    key: "category",
    name: "Category",
    format: formatStatus,
    default: "-",
    sort: true
  },
  {
    key: "admin_notes",
    name: "Notes",
    default: "-"
  },
  {
    key: "status",
    name: "Status",
    format: formatStatus,
    default: "-",
    sort: true
  },
  {
    key: "created",
    name: "Requested At",
    format: formatDate,
    sort: true
  },
  {
    key: "more",
    name: "More"
  }
];

export default nestedPurchasesTableColumns;

import { concat, filter, flatMap, get, identity, map } from "lodash";
import { denormalize } from "normalizr";
import { group, GroupEntity } from "../../../api/schema";
import { State } from "../../types";

const getRoles = (state: State): string[] => {
  try {
    const groups = get(state, ["auth", "groups"]);
    const userType = get(state, ["auth", "type"]);
    const entities = get(state, "entities");

    const input = { groups };
    const schema = { groups: [group] };
    const denormalized = denormalize(input, schema, entities) as {
      groups: GroupEntity[];
    };

    return filter(
      concat(
        map(
          flatMap(denormalized.groups, (group) => get(group, "roles", [])),
          role => get(role, "name")
        ),
        [userType]
      ),
      identity
    ) as string[];
  } catch (err) {
    return [];
  }
};

export default getRoles;

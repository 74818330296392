import get from "lodash/get";
import has from "lodash/has";
import {
  CONFIRM_INVITE_REQUEST,
  CONFIRM_INVITE_SUCCESS,
  CONFIRM_INVITE_FAILURE
} from "../types";

const confirmInvite = (state, { type, payload }) => {
  switch (type) {
    case CONFIRM_INVITE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CONFIRM_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        accountAction: has(payload, "profiles") ? "update" : "new",
        invitePayload: payload,
        email: get(payload, "email", ""),
        phoneNumber: get(payload, "phone_number", ""),
        isSmsInvite:
          !!get(payload, "phone_number", "") && !get(payload, "email", "")
      };
    case CONFIRM_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        inviteError: payload
      };
    default:
      return {
        ...state
      };
  }
};

export default confirmInvite;

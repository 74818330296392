import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid } from "@material-ui/core";

import { closeDialog, getDialog } from "../../../../../../modules/ui";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../../components/Dialog";

const PaymentProfileLinkDialog = ({ ids = [] }) => {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const handleExit = useCallback(() => dispatch(closeDialog()), [dispatch]);

  return (
    <React.Fragment>
      <Dialog open={dialog.show} maxWidth="sm" fullWidth>
        <DialogTitle onClose={() => handleExit()}>
          Payment Profile MC/DOT Match
        </DialogTitle>
        <DialogContent
          style={{
            height: 300,
            display: "flex",
            flexGrow: 2,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Grid container spacing={16} direction="column">
            {ids.map(id => (
              <Grid item>
                <a href={`/admin/payment/${id}/profile`} target="_blank">
                  {`Payment Profile (${id})`}
                </a>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleExit}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PaymentProfileLinkDialog;

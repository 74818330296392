import {
  Tooltip,
  Button,
  createStyles,
  Grid,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import React, { MouseEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";

import formatDate from "../../../../helpers/format/formatDate";
import Can from "../../../../components/Can";
import colors from "../../../../theme/colors";
import SaveButton from "../../../../components/buttons/SaveButton";
import NFBadge from "../../../../components/NFBadge";
import formatStatus from "../../../../helpers/format/formatStatus";
import SelfFinanceIcon from "../../../../components/icons/SelfFinanceIcon";

const styles = createStyles({
  h4: {
    fontSize: "32px !important"
  }
});

const blackDeleteList = ["Paid", "Declined", "Remote Approved", "Approved"];

interface BoxProps {
  children: React.ReactNode;
}
const Box = ({ children }: BoxProps): JSX.Element => (
  <div style={{ margin: "8px" }}>{children}</div>
);

interface Props extends WithStyles<typeof styles> {
  status: string;
  statusColor: string;
  handleSubmit: MouseEventHandler;
  isSubmitting: boolean;
  openDialog: Function;
  fundingRequestId: string;
  paymentProfileId: string;
  nonFactored: boolean;
  contractType: string;
}

const Title = withStyles(styles)(
  ({
    classes,
    status,
    statusColor,
    handleSubmit,
    isSubmitting,
    openDialog,
    fundingRequestId,
    paymentProfileId,
    nonFactored,
    contractType,
  }: Props): JSX.Element => {
    return (
      <Box>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={8}>
              {paymentProfileId && (
                <React.Fragment>
                  <Grid item>
                    <Tooltip title="Broker-Carrier Funding Request">
                      <FontAwesomeIcon
                        color={colors.green}
                        icon={faHandshake}
                        size="2x"
                      />
                    </Tooltip>
                  </Grid>
                  <SelfFinanceIcon contractType={contractType} width={40} height={40} />
                  <Grid item>
                    <Typography
                      variant="h4"
                      classes={{
                        h4: classes.h4
                      }}
                      style={{ color: statusColor, marginLeft: "10px" }}
                      inline
                    >
                      BROKER
                    </Typography>
                  </Grid>
                </React.Fragment>
              )}
              <Grid item>
                <Typography variant="h4" inline>
                  Funding Request:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  classes={{
                    h4: classes.h4
                  }}
                  style={{ color: statusColor, marginLeft: "10px" }}
                  inline
                >
                  {formatStatus(status)}
                </Typography>
              </Grid>
              {nonFactored && (
                <Grid item>
                  <NFBadge />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            {status === "fuel_advance" && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: colors.purple,
                  marginRight: 10
                }}
                onClick={(): void =>
                  openDialog("make_fuel_advance_payment", "", "", {})
                }
              >
                <Typography style={{ color: colors.white }}>
                  Make a Purchase
                </Typography>
              </Button>
            )}
            {!blackDeleteList.includes(status) && (
              <Can
                perform="admin-funding-request:delete"
                yes={(): JSX.Element => (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: colors.danger,
                      marginRight: 10
                    }}
                    onClick={(): void =>
                      openDialog(
                        "confirm_delete_funding_request",
                        "Confirm Delete Funding Request",
                        "Are you sure you want to delete this Funding Request?",
                        {
                          fundingRequestId
                        }
                      )
                    }
                  >
                    <Typography style={{ color: colors.white }}>
                      Delete
                    </Typography>
                  </Button>
                )}
              />
            )}
            {status === 'approved' ? (
              <Can perform="admin-funding-request-approved:save" yes={() => <SaveButton onClick={handleSubmit} disabled={isSubmitting} />}/>
            ) : null}
            {status === 'remote-approved' ? (
              <Can perform="admin-funding-request-remote-approved:save" yes={() => <SaveButton onClick={handleSubmit} disabled={isSubmitting} />} />
            ) : null}
            {status === 'paid' ? (
              <Can perform="admin-funding-request-paid:save" yes={() => <SaveButton onClick={handleSubmit} disabled={isSubmitting} />} />
            ) : null}
            {!['approved', "remote-approved", 'paid'].includes(status) ? (<SaveButton onClick={handleSubmit} disabled={isSubmitting} />) : null}
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default Title;

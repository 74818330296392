import {
  FormControl,
  FormHelperText,
  Input,
  MenuItem,
  Select
} from "@material-ui/core";
import React from "react";
import { find, get } from "lodash";

import { Category, File } from "./types";

interface Props {
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: {};
  index: number;
  files: File[];
  categories: Category[];
  multipleCategories: boolean;
  // eslint-disable-next-line react/require-default-props
  defaultCategoryValue?: string;
}

const FileCategoriesInput = ({
  setFieldTouched,
  setFieldValue,
  values,
  index,
  files,
  categories,
  multipleCategories,
  defaultCategoryValue = ""
}: Props): JSX.Element => {
  let categoryValue = get(values, `[${index}].category`, "");
  if (
    categoryValue &&
    multipleCategories &&
    typeof categoryValue === "string"
  ) {
    categoryValue = categoryValue.split(",");
  }
  return (
    <FormControl id="file-categories" style={{ width: "90%", padding: 10 }}>
      <Select
        id="select-file-categories"
        multiple={multipleCategories}
        value={categoryValue || [defaultCategoryValue]}
        onBlur={(): void =>
          setFieldTouched(`attachments[${index}].category`, true)
        }
        onChange={(event): void => {
          if (find(values, ["category", event.target.value])) return;
          if (multipleCategories) {
            const eventValue = (event.target.value as unknown) as string[];
            setFieldValue(
              `attachments[${index}].category`,
              eventValue
                .filter((value): boolean => value.length !== 0)
                .join(",")
            );
          } else {
            setFieldValue(`attachments[${index}].category`, event.target.value);
          }
        }}
        input={<Input fullWidth name="category" id="category" />}
      >
        {categories.map(
          (option): JSX.Element => (
            <MenuItem key={option.key} value={option.key}>
              {option.name}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default FileCategoriesInput;

export interface State {
  email: string;
  currentUserId?: string;
  type?: string;
  loading: boolean;
  authError?: string;
  groups: any[];
  token?: string;
  tokenPayload: any;
  user?: any;
}

export const FETCH_TOKEN_REQUEST = "AUTH/FETCH_TOKEN_REQUEST";
export const FETCH_TOKEN_SUCCESS = "AUTH/FETCH_TOKEN_SUCCESS";
export const FETCH_TOKEN_FAILURE = "AUTH/FETCH_TOKEN_FAILURE";

export const USER_LOGIN_REQUEST = "AUTH/USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "AUTH/USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "AUTH/USER_LOGIN_FAILURE";

export const FETCH_GROUPS_REQUEST = "AUTH/FETCH_GROUPS_REQUEST";
export const FETCH_GROUPS_SUCCESS = "AUTH/FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_FAILURE = "AUTH/FETCH_GROUPS_FAILURE";

export const SET_AUTH_EMAIL = "AUTH/SET_AUTH_EMAIL";
export const LOGOUT = "AUTH/LOGOUT";
export const REFRESH_TOKEN = "AUTH/REFRESH_TOKEN";

export const FETCH_CURRENTUSER_REQUEST = "AUTH/FETCH_CURRENTUSER_REQUEST";
export const FETCH_CURRENTUSER_SUCCESS = "AUTH/FETCH_CURRENTUSER_SUCCESS";
export const FETCH_CURRENTUSER_FAILURE = "AUTH/FETCH_CURRENTUSER_FAILURE";

import React from "react";
import { Grid } from "@material-ui/core";

import colors from "../theme/colors";

export default function NFBadge() {
  return (
    <Grid
      container
      style={{
        borderRadius: "2px",
        backgroundColor: colors.success,
        color: colors.white,
        width: "25px",
        height: "25px",

        fontFamily: "Avenir",
        // font-size: 10px;
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal"
      }}
      alignContent="center"
      justify="center"
    >
      <Grid>NF</Grid>
    </Grid>
  );
}

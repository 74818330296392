import { createAction } from "redux-starter-kit";
import { debounce, isArray } from "lodash";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_OPENFUNDINGREQUEST_FAILURE,
  FETCH_OPENFUNDINGREQUEST_REQUEST,
  FETCH_OPENFUNDINGREQUEST_SUCCESS
} from "../types";

const debouncedQueryOpenFundingRequests = debounce(
  async (dispatch, schema, api, id, ordering, limit, offset, filters) => {
    dispatch(
      createAction(FETCH_OPENFUNDINGREQUEST_REQUEST)({
        id,
        filters
      })
    );
    const response = await api.debtors.getDeborPayments(
      id,
      ordering,
      limit,
      offset,
      filters
    );

    // TODO: If this is an array it means there is no limit offset provided, This was happening in Finances Debtor Balances Sub Tables
    if (!isArray(response)) {
      const data = normalize(response.results, [schema.fundingRequest]);

      dispatch(addEntities(data.entities));
      dispatch(
        createAction(FETCH_OPENFUNDINGREQUEST_SUCCESS)({
          id,
          items: data.result,
          count: response.count,
          limit,
          offset,
          filters
        })
      );
    } else {
      const data = normalize(response, [schema.fundingRequest]);

      dispatch(addEntities(data.entities));
      dispatch(
        createAction(FETCH_OPENFUNDINGREQUEST_SUCCESS)({
          id,
          items: data.result,
          filters
        })
      );
    }

    return response;
  },
  1000,
  { trailing: true }
);

const queryOpenFundingRequests = (
  id,
  ordering,
  limit,
  offset,
  filters = {}
) => async (dispatch, getState, { api, schema }) => {
  try {
    await debouncedQueryOpenFundingRequests(
      dispatch,
      schema,
      api,
      id,
      ordering,
      limit,
      offset,
      filters
    );
  } catch (err) {
    dispatch(createAction(FETCH_OPENFUNDINGREQUEST_FAILURE)(err));
    throw err;
  }
};

export default queryOpenFundingRequests;

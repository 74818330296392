import { assign } from "lodash";
import { DELETE_LINEITEM_SUCCESS } from "../types";

const deleteLineItem = (state, { type, payload }) => {
  switch (type) {
    case DELETE_LINEITEM_SUCCESS:
      return {
        ...state,
        lineItems: assign({}, state.lineItems, {
          [payload.fundingRequest]: state.lineItems[
            payload.fundingRequest
          ].filter(lineItem => lineItem !== payload.lineItem)
        })
      };
    default:
      return {
        ...state
      };
  }
};

export default deleteLineItem;

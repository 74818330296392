import React from "react";
import { Redirect } from "react-router-dom";
import { Paper, Typography } from "@material-ui/core";
import columns from "./columns";

import Table from "../../../components/Table";
import formatDate from "../../../helpers/format/formatDate";
import MoreButton from "../../../components/buttons/MoreButton";
import AddButton from "../../../components/buttons/AddButton";
import InternalLayout from "../../../components/layouts/InternalLayout";
import Can from "../../../components/Can";

interface TableActionsProps {
  children: React.ReactNode;
}
const TableActions = ({ children }: TableActionsProps): JSX.Element => (
  <div
    style={{
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      alignItems: "flex-end"
    }}
  >
    {children}
  </div>
);

interface Props {
  isLoading: boolean;
  handleSort: Function;
  sortDirection: string;
  sortBy: Function;
  admins: any[];
  handleSearchFilter: Function;
  openDialog: Function;
  roles: string[];
  filters: any;
}

const PureAdmins = ({
  isLoading,
  handleSort,
  sortDirection,
  sortBy,
  admins,
  handleSearchFilter,
  openDialog,
  roles,
  filters
}: Props): JSX.Element => {
  const formattedRows = admins.map((row: any): any => ({
    ...row,
    created: formatDate(row.created),
    more: (
      <MoreButton
        id={row.id}
        onClick={(): void =>
          openDialog("", "", "", row, "edit_admin_user_form")
        }
      />
    )
  }));
  return (
    <Can
      perform="admin-admins:view"
      yes={(): JSX.Element => (
        <InternalLayout title="Admins">
          <Typography variant="h4">
            {`Total Admins: ${admins.length}`}
          </Typography>
          <Paper style={{ paddingLeft: 8, marginTop: 32 }}>
            <TableActions>
              <AddButton
                onClick={(): void =>
                  openDialog("", "", "", undefined, "add_admin_user_form")
                }
              />
            </TableActions>
            <Table
              columns={columns}
              rows={formattedRows}
              isLoading={isLoading}
              handleSort={handleSort}
              sortDirection={sortDirection}
              sortBy={sortBy}
              filter
              filters={filters}
              handleFilterChange={handleSearchFilter}
              allowEmpty
            />
          </Paper>
        </InternalLayout>
      )}
      no={(): JSX.Element => <Redirect to="/" />}
    />
  );
};

export default PureAdmins;
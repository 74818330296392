import * as Yup from "yup";

export const fundingRequest = Yup.object().shape({
  // invoiceNumber: Yup.string().required("Invoice # is a required field"),
  // loadNumber: Yup.string().required("Load # is a required field"),
  // invoiceAmount: Yup.string().required("Invoice Amount is a required field"),
  // debtorInvoiceAmount: Yup.string().required(
  //   "Debtor Invoice Amount is a required field"
  // ),
  // amountFunded: Yup.string().required("Amount Funded is a required field"),
  // clientInvoiceAmount: Yup.string().required(
  //   "Client Invoice Amount is a required field"
  // ),
  // amountToBePurchased: Yup.string().required(
  //   "Amount To Be Purchased is a required field"
  // ),
  // paymentMethod: Yup.string().required("Payment Method is a required field")
});

export const lineItemEditForm = Yup.object().shape({
  description: Yup.string().required("Description is a required field"),
});
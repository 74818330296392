import { CardHeader, WithStyles, createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

import Card from "../../../../components/Card";

const styles = createStyles({
  title: {
    color: "white"
  }
});

interface Props extends WithStyles<typeof styles> {
  amount: string;
}

const PendingNegativeReserveCard = ({
  classes,
  amount
}: Props): JSX.Element => (
  <Card color="#e50823">
    <CardHeader
      classes={{
        title: classes.title,
        subheader: classes.title
      }}
      title="Pending Negative Reserve"
      subheader={amount}
    />
  </Card>
);

export default withStyles(styles)(PendingNegativeReserveCard);

import _ from "lodash";
import splitAndCase from "./format/splitAndCase";

function getMessage(data, keyName = "") {
  let message = [];
  message = _.reduce(
    data,
    (obj, param, key) => {
      if (typeof param === "object") {
        const result = getMessage(param, key);
        message = message.concat(`${splitAndCase(key)}: ${result}`);
        return message;
      }
      if (typeof param === "string") {
        obj.push(
          param === "This field is required." && keyName
            ? `${splitAndCase(keyName)}: ${param}`
            : param
        );
      }
      return obj;
    },
    []
  );
  return message;
}

const formatError = (err, retention) => {
  let message = [];

  try {
    // sometimes err message is a stringified object
    message.push(JSON.parse(err.message));
  } catch (errception) {
    // pass
  }

  if (typeof err === "string") {
    return message.push(err);
  }
  try {
    if (err.response && err.response.data) {
      const {
        data: {
          error = null,
          error_description: errorDescription = null,
          ...data
        }
      } = err.response;
      if (!error) {
        message = message.concat(getMessage(data));
      } else {
        message.push(errorDescription);
      }
    } else if (err.response === undefined) {
      message.push("Error Server.");
    }
    return message;
  } catch (exception) {
    return message.push(retention);
  }
};

export default formatError;

export const CREATE_USER_PROFILE_REQUEST = "CREATE_USER_PROFILE_REQUEST";
export const CREATE_USER_PROFILE_SUCCESS = "CREATE_USER_PROFILE_SUCCESS";
export const CREATE_USER_PROFILE_FAILURE = "CREATE_USER_PROFILE_FAILURE";

export const CURRENT_USER_REQUEST = "CURRENT_USER_REQUEST";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_FAILURE = "CURRENT_USER_FAILURE";

export const FETCH_FACTORING_PROFILE_REQUEST =
  "FETCH_FACTORING_PROFILE_REQUEST";
export const FETCH_FACTORING_PROFILE_SUCCESS =
  "FETCH_FACTORING_PROFILE_SUCCESS";
export const FETCH_FACTORING_PROFILE_FAILURE =
  "FETCH_FACTORING_PROFILE_FAILURE";

export const FETCH_USER_PROFILE_FAILURE = "USER/FETCH_PROFILE_FAILURE";
export const FETCH_USER_PROFILE_REQUEST = "USER/FETCH_PROFILE_REQUEST";
export const FETCH_USER_PROFILE_SUCCESS = "USER/FETCH_PROFILE_SUCCESS";

export const LINES_USER_FORM = "USER/LINES_FORM";

export const UPDATE_USER_PROFILE_REQUEST = "USER/UPDATE_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "USER/UPDATE_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "USER/UPDATE_PROFILE_FAILURE";

export const DELETE_USER_FILE_REQUEST = "USER/DELETE_FILE_REQUEST";
export const DELETE_USER_FILE_SUCCESS = "USER/DELETE_FILE_SUCCESS";
export const DELETE_USER_FILE_FAILURE = "USER/DELETE_FILE_FAILURE";

export const UPLOAD_USER_FILE_REQUEST = "USER/UPLOAD_FILE_REQUEST";
export const UPLOAD_USER_FILE_SUCCESS = "USER/UPLOAD_FILE_SUCCESS";
export const UPLOAD_USER_FILE_FAILURE = "USER/UPLOAD_FILE_FAILURE";

export const DELETE_USER_PROFILE_FAILURE = "USER/DELETE_PROFILE_FAILURE";
export const DELETE_USER_PROFILE_REQUEST = "USER/DELETE_PROFILE_REQUEST";
export const DELETE_USER_PROFILE_SUCCESS = "USER/DELETE_PROFILE_SUCCESS";

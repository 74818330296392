import { get } from "lodash";
import { denormalize } from "normalizr";
import { lineItem } from "../../../api/schema";

const getLineItemsByFundingRequest = state => id => {
  const ids = get(state, `debtor.lineItems.${id}`, []);
  try {
    const denormalized = denormalize(
      { lineItems: ids },
      { lineItems: [lineItem] },
      state.entities
    );

    return denormalized.lineItems;
  } catch (err) {
    return [];
  }
};

export default getLineItemsByFundingRequest;

import React from "react";
import { Button } from "@material-ui/core";
import colors from "../../../../../theme/colors";

const ClientProfileButton = () => (
  <Button
    variant="contained"
    style={{
      background: colors.green,
      color: colors.white
    }}
    size="medium"
  >
    Client Profile
  </Button>
);

export default ClientProfileButton;

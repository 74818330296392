import { denormalize } from "normalizr";
import { get, head, memoize } from "lodash";

import { fundingRequest } from "../../../api/schema";

export default id =>
  memoize(state => {
    try {
      const denormalized = denormalize(
        { fundingRequest: id },
        { fundingRequest },
        state.entities
      );
      const statusTimes = get(
        denormalized.fundingRequest,
        "status_timeline",
        []
      )
        .map(status => status.status_set_datetime)
        .sort()
        .reverse();
      return head(statusTimes);
    } catch (err) {
      return undefined;
    }
  });

import React from "react";
import { List, Typography } from "@material-ui/core";

import { CheckBox } from "../../../../../components/inputs/BoldInput";
import ListItem from "../../../../../components/ListItem";
import ProfileSection from "../../../../../components/ProfileSection";

export default ({ values, errors, setFieldValue, handleBlur, disabled }) => (
  <List dense>
    <ListItem>
      <ProfileSection>Settings</ProfileSection>
    </ListItem>
    <ListItem>
      <CheckBox
        fullWidth
        name="enable_discount"
        label="Enable Discount:"
        value={values.enable_discount}
        setFieldValue={(name, value) => setFieldValue(name, value)}
        onBlur={handleBlur}
        error={!!errors.enable_discount}
        helperText={errors.enable_discount}
        disabled={disabled}
      />
    </ListItem>
    <ListItem>
      <CheckBox
        fullWidth
        name="send_aging_to_broker"
        label="Send Aging To Broker:"
        value={values.send_aging_to_broker}
        setFieldValue={(name, value) => setFieldValue(name, value)}
        onBlur={handleBlur}
        error={!!errors.send_aging_to_broker}
        helperText={errors.send_aging_to_broker}
        disabled={disabled}
      />
    </ListItem>
  </List>
);

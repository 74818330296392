import { Button, Grid, MenuItem, Typography } from "@material-ui/core";
import React from "react";
import color from "@material-ui/core/colors/amber";
import { filter } from "lodash";
import colors from "../../../../../theme/colors";
import CustomTextField from "../../../../../components/inputs/CustomTextField";
import EmailTemplates from "./EmailTemplates";

interface Props {
  handleSubmit: () => void;
  isSubmitting: boolean;
  handleAddInvoice: () => void;
  handleAddFollowup: () => void;
  handleSettle: () => void;
  handleAddAttachments: () => void;
  selected: string[];
  id: string;
  fundingRequests: [];
}

const TitleButtons = ({
  handleAddFollowup,
  handleSubmit,
  isSubmitting,
  handleSettle,
  selected,
  id,
  fundingRequests,
  handleAddInvoice,
  handleAddAttachments
}: Props): JSX.Element => {
  const disabledSettleButton = !!filter(
    selected,
    num => typeof num === "number"
  ).length;
  return (
    <Grid style={{ paddingTop: "32px" }}>
      <EmailTemplates
        id={id}
        selected={selected}
        fundingRequests={fundingRequests}
      />
      <Grid item container justify="flex-end" spacing={16}>
        <Grid item container justify="flex-end">
          <Button
            style={{
              backgroundColor: colors.link,
              color: colors.white
            }}
            mini
            variant="contained"
            onClick={handleAddAttachments}
            disabled={disabledSettleButton || !selected.length}
          >
            Add Attachments
          </Button>
        </Grid>
        <Grid item container justify="flex-end">
          <Button
            style={{
              backgroundColor: colors.green_dark,
              color: colors.white
            }}
            mini
            variant="contained"
            onClick={(): void => handleAddInvoice()}
          >
            Add Invoice
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        alignContent="center"
        style={{ paddingTop: 20 }}
      >
        <Grid item>
          <Typography variant="h5">All Invoices</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={16} justify="flex-end">
            <Grid item>
              <Button
                style={{
                  backgroundColor: "#f6b352",
                  color: colors.white
                }}
                mini
                variant="contained"
                onClick={(): void => handleAddFollowup()}
                disabled={selected.length === 0}
              >
                Add Followup
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={(): void => handleSubmit()}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: colors.green,
                  color: colors.white
                }}
                mini
                variant="contained"
                onClick={(): void => handleSettle()}
                disabled={disabledSettleButton || !selected.length}
              >
                Settle
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TitleButtons;

import { createReducer } from "redux-starter-kit";
import * as TYPES from "./types";
import fetchObligationsReducer from "./reducers/fetchObligations";
import withTable from "../withTable";
import fetchObligations from "./actions/fetchObligations";

const INITIAL_STATE = {
    loading: false,
    ids: [],
    count: 0,
};

const {
    selectors,
    actions,
    reducer: tableReducer,
    middleware: tableMiddleware
} = withTable({
    stateKey: "obligations",
    typeKey: "obligations"
});

const reducer = createReducer(INITIAL_STATE, {
    [TYPES.FETCH_QUERY_OBLIGATIONS_FAILURE]: fetchObligationsReducer,
    [TYPES.FETCH_QUERY_OBLIGATIONS_REQUEST]: fetchObligationsReducer,
    [TYPES.FETCH_QUERY_OBLIGATIONS_SUCCESS]: fetchObligationsReducer
});

export default tableReducer(reducer);

export const middleware = tableMiddleware(tableSelectors => store => action => {
    const state = store.getState();
    const sortBy = tableSelectors.getSortBy(state);
    const sortDirection = tableSelectors.getSortDirection(state);
    const offset = tableSelectors.getOffset(state);
    const rowsPerPage = tableSelectors.getRowsPerPage(state);
    const filters = tableSelectors.getFilters(state);

    store.dispatch(
        fetchObligations(
            state.obligations.contract_id,
            sortDirection === "asc" ? sortBy : `-${sortBy}`,
            rowsPerPage,
            offset,
            filters
        )
    );
});

export const {
    getOffset,
    getRowsPerPageOptions,
    getRowsPerPage,
    getCount,
    getSortDirection,
    getSortBy,
    getPage,
    getFilters,
    getIsLoading
} = selectors;

export const {
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearchFilter
} = actions;

export { default as fetchObligations } from "./actions/fetchObligations";
export { default as fetchObligationById } from "./actions/fetchObligationById";
export { default as getObligations } from "./selectors/getObligations";
export { default as getObligationById } from "./selectors/getObligationById";

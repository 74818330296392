import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";

import {
  UPDATE_PAYMENT_PROFILE_FAILURE,
  UPDATE_PAYMENT_PROFILE_REQUEST,
  UPDATE_PAYMENT_PROFILE_SUCCESS
} from "../types";
import { addEntities } from "../../entities";

const updatePaymentProfile = (id, update) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPDATE_PAYMENT_PROFILE_REQUEST)({}));
    const response = await api.factoring.updatePaymentProfile(id, update);
    const data = normalize(response, schema.paymentProfile);
    dispatch(addEntities(data.entities));
    dispatch(createAction(UPDATE_PAYMENT_PROFILE_SUCCESS)({}));
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_PAYMENT_PROFILE_FAILURE)(err));
    throw err;
  }
};

export default updatePaymentProfile;

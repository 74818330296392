import React from "react";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";

interface Props {
  factoringAmount: number;
}

const NegativeMarginIcon = ({ factoringAmount }: Props) => {
  return factoringAmount < 0 ? <FormatLineSpacingIcon color="error" /> : null;
};
export default NegativeMarginIcon;

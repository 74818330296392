import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { Edit as EditIcon } from "@material-ui/icons";
import { Redirect } from "react-router-dom";
import { Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { get } from "lodash";

import Table from "../../../../components/Table";
import Colors from "../../../../theme/colors";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import LineItemDialog from "./components/LineItemDialog";
import NavigationTabs from "../../../../components/NavigationTabs";
import columns from "./columns";

const MoreButton = ({ id, openDialog, ...props }) => (
  <React.Fragment>
    <IconButton
      aria-label="Edit"
      onClick={() =>
        openDialog(
          "",
          "",
          "",
          {
            formAction: "Edit",
            ...props
          },
          "LineItemDialog"
        )
      }
    >
      <EditIcon color="secondary" fontSize="small" />
    </IconButton>
  </React.Fragment>
);

const LineItems = ({ LineItemCategories = [], openDialog, ...props }) => {
  const formattedRow = LineItemCategories.map(row => ({
    ...row,
    more: (
      <MoreButton
        id={row.id}
        openDialog={openDialog}
        {...props}
        values={{ ...row, applied_against: row.appliedAgainst }}
      />
    )
  }));
  return <Table columns={columns} rows={formattedRow} allowEmpty />;
};

const styles = {
  root: {
    background: "linear-gradient(45deg, #2EBD00 30%, #4dc527 90%)"
  },
  label: {
    fontFamily: "Avenir-Medium",
    textTransform: "capitalize",
    color: Colors.white
  }
};

export default withStyles(styles)(({ tabs, statusIndex, ...props }) => {
  const {
    dialog,
    closeDialog,
    openDialog,
    deleteLineItemsCategories,
    openModal
  } = props;

  const index = statusIndex === -1 ? 0 : statusIndex;
  return (
    <Can
      perform="admin-finances-line-items:view"
      yes={() => (
        <InternalLayout title="Finances">
          <React.Fragment>
            <div style={{ margin: 10 }}>
              <Typography variant="h5">Line Item Categories</Typography>
            </div>
            <NavigationTabs tabs={tabs} value={index} />
            <Paper style={{ marginTop: 32, padding: 16 }}>
              <Can
                perform="admin-finances-line-items:add"
                yes={() => (
                  <Grid container direction="row-reverse" spacing={16}>
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: Colors.green,
                          color: Colors.white
                        }}
                        mini
                        onClick={() =>
                          openDialog(
                            "",
                            "",
                            "",
                            {
                              formAction: "Add",
                              ...props
                            },
                            "LineItemDialog"
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ margin: 5 }}
                          size="xs"
                        />
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                )}
              />
              <LineItems {...props} />
            </Paper>
            {dialog.action === "LineItemDialog" && (
              <LineItemDialog
                handleClose={closeDialog}
                open={dialog.show}
                openModal={openModal}
                {...dialog.data}
              />
            )}
            {dialog.variant === "confirm" &&
              dialog.action === "delete_line_items_categories" && (
                <ConfirmDialog
                  open={dialog.show}
                  message={dialog.message}
                  handleConfirm={async () => {
                    await deleteLineItemsCategories({
                      id: get(dialog, ["data", "lineItemId"])
                    });
                    closeDialog();
                  }}
                  handleExit={() => closeDialog()}
                />
              )}
          </React.Fragment>
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
});

import axios from "axios";

const getConnectedCarriers = async (factoring, name) => {
  const response = await axios({
    url: `/factoring/${factoring}/payment_profiles/`,
    method: "GET",
    params: {
      limit: 20,
      search: name
    }
  });
  return response.data.results;
};

export default async ({ factoring, name }) => {
  try {
    return await getConnectedCarriers(factoring, name);
  } catch (e) {
    return [];
  }
};

import React from 'react'
import tw, { styled, } from 'twin.macro'

const successStyling = tw`
border border-green-500 text-green-600 placeholder-green-600 focus:outline-none focus:ring-2 focus:ring-green-200
`
const warningStyling = tw`
border border-yellow-500 text-yellow-600 placeholder-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-200
`

const errorStyling = tw`
border border-red-500 text-red-600 placeholder-red-600 focus:outline-none focus:ring-2 focus:ring-red-200
`

const disabledStyling = tw`
bg-gray-100 border text-gray-600 placeholder-gray-300 cursor-not-allowed
`

interface InputExtraProps {
    success?: boolean;
    warning?: boolean;
    error?: boolean;
    disabled?: boolean;
}

export const Input = styled.input<InputExtraProps>`
    ${tw`block
    w-full
    text-sm
    text-gray-800
    rounded-lg
    bg-white
    bg-none
    border-gray-200
    appearance-none
    focus:outline-none
    focus:ring-2
    focus:ring-blue-600
    focus:ring-opacity-30
    focus:border-transparent
    transition duration-500 ease-in-out transform`}
    ${({ success }: InputExtraProps) => success && successStyling}
    ${({ error }: InputExtraProps) => error && errorStyling}
    ${({ warning }: InputExtraProps) => warning && warningStyling}
    ${({ disabled }: InputExtraProps) => disabled && disabledStyling}
`;

export type InputProps = React.ComponentProps<typeof Input>
export default Input;

import { createReducer } from "redux-starter-kit";
import * as TYPES from "./types";
import fetchNOAReducers from "./reducers/fetchNOA";
import withTable from "../withTable";
import queryfetchNOA from "./actions/fetchNOA";
import fetchDebtorsReducers from "../debtors/reducers/fetchDebtors";

const INITIAL_STATE = {
  loading: false,
  ids: [],
  count: 0
};

const {
  selectors,
  actions,
  reducer: tableReducer,
  middleware: tableMiddleware
} = withTable({
  stateKey: "noa",
  typeKey: "noa"
});

const reducer = createReducer(INITIAL_STATE, {
  [TYPES.FETCH_QUERY_NOA_FAILURE]: fetchNOAReducers,
  [TYPES.FETCH_QUERY_NOA_REQUEST]: fetchNOAReducers,
  [TYPES.FETCH_QUERY_NOA_SUCCESS]: fetchNOAReducers
});

export default tableReducer(reducer);

export const middleware = tableMiddleware(tableSelectors => store => action => {
  const state = store.getState();
  const sortBy = tableSelectors.getSortBy(state);
  const sortDirection = tableSelectors.getSortDirection(state);
  const offset = tableSelectors.getOffset(state);
  const rowsPerPage = tableSelectors.getRowsPerPage(state);
  const filters = tableSelectors.getFilters(state);

  store.dispatch(
    queryfetchNOA(
      sortDirection === "asc" ? sortBy : `-${sortBy}`,
      rowsPerPage,
      offset,
      filters
    )
  );
});

export const {
  getOffset,
  getRowsPerPageOptions,
  getRowsPerPage,
  getCount,
  getSortDirection,
  getSortBy,
  getPage,
  getFilters,
  getIsLoading
} = selectors;

export const {
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter
} = actions;

export { default as fetchNOA } from "./actions/fetchNOA";
export { default as getNOA } from "./selectors/getNOA";

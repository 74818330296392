import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../theme/colors";

interface Props {
  message?: string | null;
}

const SuccessModal = ({
  message = "This is a success message."
}: Props): JSX.Element => (
  <Grid
    container
    direction="column"
    spacing={16}
    alignContent="center"
    alignItems="center"
  >
    <Grid item>
      <FontAwesomeIcon color={Colors.success} size="5x" icon={faCheckCircle} />
    </Grid>
    <Grid item>
      <Typography style={{ color: Colors.success }} variant="h5">
        Success!
      </Typography>
    </Grid>
    <Grid item>
      <Typography style={{ color: Colors.success }} variant="h6">
        {message}
      </Typography>
    </Grid>
  </Grid>
);

export default SuccessModal;

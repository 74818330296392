import { merge } from "lodash";
import {
  FETCH_DEBTORRELATION_REQUEST,
  FETCH_DEBTORRELATION_SUCCESS,
  FETCH_DEBTORRELATION_FAILURE
} from "../types";

const fetchDebtorRealtions = (state, { type, payload }) => {
  switch (type) {
    case FETCH_DEBTORRELATION_REQUEST:
      return {
        ...state,
        loadingDebtorRelations: true
      };
    case FETCH_DEBTORRELATION_SUCCESS:
      return {
        ...state,
        debtorRelations: merge({}, state.debtorRelations, payload),
        loadingDebtorRelations: false
      };
    case FETCH_DEBTORRELATION_FAILURE:
      return {
        ...state,
        loadingDebtorRelations: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchDebtorRealtions;

import { connect } from "react-redux";
import { compose, lifecycle, withStateHandlers } from "recompose";
import {
  exchangePlaidToken,
  getExchangeLoading,
  getExchangePlaid,
  getExchangePlaidError
} from "../../modules/signUp";
import { getUserToken } from "../../modules/auth";
import { currentUser, getUserProfileSuccess } from "../../modules/user";
import BankAccount from "./BankAccount";
import { openDialog } from "../../modules/ui";

const mapStateToProps = state => ({
  plaidError: getExchangePlaidError(state),
  exchangePlaid: getExchangePlaid(state),
  plaidIsLoading: getExchangeLoading(state),
  user: getUserProfileSuccess(state),
  token: getUserToken(state)
});
const mapDispatchToProps = {
  exchangePlaidToken,
  currentUser,
  openDialog
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(({ bankUpdated = false }) => ({ bankUpdated }), {
    setBank: props => bankUpdated => ({ bankUpdated })
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const plaidTokenExchanged =
        this.props.exchangePlaid !== prevProps.exchangePlaid;

      if (plaidTokenExchanged) {
        this.props.setBank(true);
      }
    },
    async componentWillMount() {
      const {
        currentUser: getCurrentUser,
        user,
        token,
        match: {
          params: { target = "" }
        }
      } = this.props;
      if (!["payment_profile", "factoring_client"].includes(target)) {
        this.props.history.push(`/404`);
      }
      if (token) {
        await getCurrentUser();
      } else {
        this.props.history.push(`/login/?redirect=/bank/${target}`);
      }
    }
  })
)(BankAccount);

import validNumber from "./validNumber";

const validDecimalNumber = value => {
  if (value.includes(".")) {
    const split = value.split(".");
    if (split.length !== 2) {
      return false;
    }
    return validNumber(split[0]) && validNumber(split[1]);
  }
  return false;
};

export default validDecimalNumber;

import React from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import Fab, { FabProps } from "@material-ui/core/Fab";
import colors from "../../../../../theme/colors";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    button: {
      boxShadow:
        "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      backgroundColor: colors.orange,
      color: colors.white,
      textTransform: "none"
    }
  });

function SubmitButton({
  classes,
  ...props
}: WithStyles<typeof styles> & FabProps): JSX.Element {
  return (
    <Fab variant="extended" size="medium" {...props} className={classes.button}>
      Submit
    </Fab>
  );
}

export default withStyles(styles)(SubmitButton);

import React, { ChangeEventHandler, ReactNode } from "react";
import { Field, FieldProps } from "formik";
import { Grid, TextField, WithStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { compose, lifecycle, withState } from "recompose";
import { TextFieldProps } from "@material-ui/core/TextField";
import styles from "./CustomTextField/styles";

interface WithFieldProps {
  children: ReactNode;
  name: string;
}

const WithField = ({ children, name }: WithFieldProps): JSX.Element => (
  <Field name={name}>
    {(props: FieldProps): JSX.Element => (
      // @ts-ignore
      <React.Fragment>{children ? children(props) : null}</React.Fragment>
    )}
  </Field>
);

type Size =
  | boolean
  | "auto"
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | undefined;

interface Key {
  key: string;
  label: string;
}

interface PlaceHolder {
  [key: string]: string;
  [index: number]: string;
}

interface Props extends WithStyles<typeof styles> {
  withStyle?: boolean;
  names: (Key | string)[];
  placeholder: PlaceHolder;
  selectedName: string;
  setSelected: (name: string) => void;
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  withField?: boolean;
  hasError: boolean;
  buttonSize?: Size;
  inputSize?: Size;
  inputStyles: string;
  selected: string;
  value: string;
  field: TextFieldProps;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.EventHandler<any>;
}

const MultiButtonToggleInputs = ({
  inputStyles,
  selected,
  setSelected,
  names,
  placeholder,
  selectedName,
  withField = true,
  hasError,
  xs = 6,
  sm = 8,
  md = 10,
  lg = 10,
  field,
  onChange,
  value,
  buttonSize = 4,
  inputSize = 8,
  withStyle,
  classes,
  onBlur
}: Props): JSX.Element => {
  const underlineClasses = classNames({
    [classes.underline]: true
  });
  const inputClasses = withStyle
    ? {
        classes: {
          underline: underlineClasses,
          input: classes.input,
          error: classes.errorInput
        }
      }
    : {};
  const labelClasses = withStyle
    ? {
        classes: {
          focused: classes.cssFocused,
          error: classes.errorLabel
        }
      }
    : {};
  return (
    <Grid
      container
      direction="row"
      spacing={0}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      alignItems="flex-end"
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: " 1fr auto",
          width: "100%"
        }}
      >
        <div>
          {names.map(
            (name): JSX.Element => {
              let key: string;

              if (typeof name === "object") {
                // eslint-disable-next-line prefer-destructuring
                key = name.key;
              } else {
                key = name;
              }

              return (
                <div key={key}>
                  {selected === key &&
                    (withField ? (
                      <WithField name={key}>
                        {({
                          field: { value, onChange, ...field }
                        }: FieldProps): JSX.Element => (
                          <TextField
                            key={key}
                            error={hasError}
                            label={placeholder[key]}
                            placeholder={placeholder[key]}
                            fullWidth
                            value={value}
                            className={classNames([
                              inputStyles,
                              name === selected
                                ? { visibility: "hidden" }
                                : null
                            ])}
                            onChange={onChange}
                            InputProps={{
                              ...inputClasses
                            }}
                            InputLabelProps={{
                              ...labelClasses
                            }}
                            {...field}
                            onBlur={onBlur}
                          />
                        )}
                      </WithField>
                    ) : (
                      <React.Fragment>
                        <TextField
                          label={placeholder[key]}
                          placeholder={placeholder[key]}
                          fullWidth
                          name={key}
                          value={value}
                          className={classNames([
                            inputStyles,
                            key === selected ? { visibility: "hidden" } : null
                          ])}
                          InputProps={{
                            ...inputClasses
                          }}
                          InputLabelProps={{
                            ...labelClasses
                          }}
                          onChange={onChange}
                          {...field}
                        />
                      </React.Fragment>
                    ))}
                </div>
              );
            }
          )}
        </div>
        <div style={{ display: "flex", flex: 1, alignItems: "flex-end" }}>
          <ToggleButtonGroup
            value={selected || selectedName}
            exclusive
            selected
            style={{
              boxShadow: "none"
            }}
            onChange={(event, value): void => {
              if (value) {
                setSelected(value);
              }
            }}
          >
            {names.map(name => {
              let key: string;
              let label: string;

              if (typeof name === "object") {
                key = name.key;
                label = name.label;
              } else {
                key = name;
                label = name;
              }

              return (
                <ToggleButton key={key} value={key}>
                  {key === label ? key.replace("_", " ") : label}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </div>
      </div>
    </Grid>
  );
};

interface Outer {
  selectedName: string;
  label?: string;
  withStyle?: boolean;
  names: (Key | string)[];
  placeholder: PlaceHolder;
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  withField?: boolean;
  hasError?: boolean;
  buttonSize?: Size;
  inputSize?: Size;
  inputStyles?: string;
  // value: string;
  // field: TextFieldProps;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

interface LifeCycleProps {
  setSelected: (selected: string) => void;
  selected: string;
  selectedName: string;
}

export default compose<Props, Outer>(
  withState("selected", "setSelected", ""),
  lifecycle<LifeCycleProps, {}>({
    componentWillMount(): void {
      const { setSelected, selectedName } = this.props;
      setSelected(selectedName);
    }
  }),
  withStyles(styles)
)(MultiButtonToggleInputs);

import { createAction } from "redux-starter-kit";
import {
  DELETE_FACTORCLIENTPROFILE_FAILURE,
  DELETE_FACTORCLIENTPROFILE_REQUEST,
  DELETE_FACTORCLIENTPROFILE_SUCCESS
} from "../types";

const deleteDebtorRelation = id => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(DELETE_FACTORCLIENTPROFILE_REQUEST)({}));
    await api.factoring.deleteProfile(id);
    dispatch(createAction(DELETE_FACTORCLIENTPROFILE_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(DELETE_FACTORCLIENTPROFILE_FAILURE)(err));
    throw err;
  }
};

export default deleteDebtorRelation;

import { OPEN_EDIT_NOTE, CLOSE_EDIT_NOTE } from "../types";

const editNote = (state, { type, payload }) => {
  switch (type) {
    case OPEN_EDIT_NOTE:
      return {
        ...state,
        editNote: {
          open: true,
          note: payload.note,
          id: payload.id
        }
      };
    case CLOSE_EDIT_NOTE:
      return {
        ...state,
        editNote: {
          open: false,
          note: "",
          id: undefined
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default editNote;

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../theme/colors";

interface Props {
  message?: string | null;
}

const WarningModal = ({
  message = "This is a warning message."
}: Props): JSX.Element => (
  <Grid
    container
    direction="column"
    spacing={16}
    alignContent="center"
    alignItems="center"
  >
    <Grid item>
      <FontAwesomeIcon
        color={Colors.warning}
        size="5x"
        icon={faExclamationCircle}
      />
    </Grid>
    <Grid item>
      <Typography style={{ color: Colors.warning }} variant="h5">
        Oops!!
      </Typography>
    </Grid>
    <Grid item>
      <Typography style={{ color: Colors.warning }} variant="h6">
        {message}
      </Typography>
    </Grid>
  </Grid>
);

export default WarningModal;

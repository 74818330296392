import splitAndCase from "../../../../helpers/format/splitAndCase";
import formatPennies from "../../../../helpers/format/formatPennies";
import formatDate from "../../../../helpers/format/formatDate";
import formatStatus from '../../../../helpers/format/formatStatus';

const columns = [
  {
    key: "client",
    name: "Client"
  },
  {
    key: "debtor",
    name: "Debtor",
    sort: "debtor",
    filter: "text",
    filter_key: "bill_to_company_name"
  },
  {
    key: "amount",
    name: "Invoice Amnt."
  },
  {
    key: "invoice",
    name: "Invoice #",
    filter: "text",
    filter_key: "invoice_number",
    sort: true
  },
  {
    key: "user_load_number",
    name: "Load #",
    filter: "text",
    filter_key: "user_load_number",
    sort: true
  },
  {
    key: "purchase_order_number",
    name: "PO Number #",
    filter: "text",
    filter_key: "purchase_order_number",
    sort: true
  },
  {
    key: "created",
    name: "Requested At",
    format: formatDate,
    sort: true
  },
  {
    key: "assigned_admin.name",
    name: "Transaction Rep",
    sort: "assigned_admin"
  },
  {
    key: "status",
    name: "Status",
    format: splitAndCase,
    filter_key: "status",
    filter: "dropdown",
    filter_value: "",
    filter_values: [
      { label: "New (NOA)", value: "new_noa" },
      { label: "New", value: "new" },
      { label: "Fuel Advance", value: "fuel_advance" },
      { label: "Pending", value: "pending" },
      { label: "Pending (NOA)", value: "pending_noa" },
      { label: "Pending Originals", value: "pending_originals" },
      { label: "Document Issue", value: "document_issue" },
      { label: "CF Review", value: "haulpay_review" },
      { label: "Document Review", value: "document_review" },
      { label: "Approved", value: "approved" },
      { label: "Incomplete", value: "incomplete" },
      { label: "Remote Approved", value: "remote_approved" },
      { label: "Hold", value: "hold" },
      { label: "Declined", value: "declined" },
      { label: "Paid", value: "paid" },
      { label: "Deleted", value: "deleted" },
      { label: "New - Fast Track", value: "new_fasttrack" }
    ]
  },
  {
    key: "contract_type",
    name: "Contract Type",
    format: formatStatus,
    filter_key: "contract_type",
    filter: "dropdown",
    filter_value: "",
    filter_values: [
        { label: "Standard Carrier Funding Request", value: "STD_CARRIER" },
        { label: "Standard Broker Funding Request", value: "STD_BROKER" },
        { label: "Non Factored Funding Request", value: "NON_FACTORED_STANDARD" },
        { label: "Non Factored Bill Out Funding Request", value: "NON_FACTORED_BILL_OUT" },
        { label: "Payment Forward", value: "NON_FACTORED_PAYMENT_FORWARD" },
        { label: "Self Finance Funding Request without invoice", value: "SELF_FINANCE_NO_INVOICE" },
        { label: "Self Finance Funding Request with invoice", value: "SELF_FINANCE_WITH_INVOICE" },
        { label: "Self Finance Ledger Deposit", value: "SELF_FINANCE_LEDGER_DEPOSIT" },
        { label: "Self Finance Stripe Deposit", value: "SELF_FINANCE_STRIPE_DEPOSIT" }
    ]
  },
];

export default columns;

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_RESERVEAGGREGATE_FAILURE,
  FETCH_RESERVEAGGREGATE_REQUEST,
  FETCH_RESERVEAGGREGATE_SUCCESS
} from "../types";

const fetchReserveAggregate = id => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_RESERVEAGGREGATE_REQUEST)());
    const response = await api.reserve.getReserveAggregate(id);
    const withId = {
      ...response,
      id
    };
    const data = normalize(withId, schema.reserveAggregate);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_RESERVEAGGREGATE_SUCCESS)({
        [id]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_RESERVEAGGREGATE_FAILURE)(err));
    throw err;
  }
};

export default fetchReserveAggregate;

import { createAction } from "redux-starter-kit";
import { debounce, get } from "lodash";
import { getLastPeriods, getPeriods } from "../../../helpers/getPeriods";
import {
  FETCH_ADMIN_REVENUE_BY_PERIOD_FAILURE,
  FETCH_ADMIN_REVENUE_BY_PERIOD_REQUEST,
  FETCH_ADMIN_REVENUE_BY_PERIOD_SUCCESS
} from "../types";

const debounced = debounce(
  async (dispatch, schema, api, data) => {
    const currentPeriod = getPeriods(data);
    const lastCurrentPeriod = getLastPeriods(data);
    dispatch(createAction(FETCH_ADMIN_REVENUE_BY_PERIOD_REQUEST)());
    const currentMonthRes = await api.admin.fetchStats(currentPeriod);
    const lastMonthRes = await api.admin.fetchStats(lastCurrentPeriod);
    const payload = {
      revenue: get(currentMonthRes, "total_invoice_value", 0),
      currentMonthRevenue: get(currentMonthRes, "total_invoice_value", 0),
      lastMonthRevenue: get(lastMonthRes, "total_invoice_value", 0),
      increasePercentage:
        ((get(currentMonthRes, "total_invoice_value", 0) -
          get(lastMonthRes, "total_invoice_value", 0)) /
          get(lastMonthRes, "total_invoice_value", 0)) *
        100
    };
    dispatch(createAction(FETCH_ADMIN_REVENUE_BY_PERIOD_SUCCESS)(payload));
    return currentMonthRes;
  },
  100,
  { trailing: true }
);

export default data => async (dispatch, getState, { api, schema }) => {
  try {
    await debounced(dispatch, schema, api, data);
  } catch (err) {
    dispatch(createAction(FETCH_ADMIN_REVENUE_BY_PERIOD_FAILURE)(err));
    throw err;
  }
};

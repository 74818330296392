import React, { ImgHTMLAttributes, DetailedHTMLProps } from "react";
import { withStyles } from "@material-ui/core/styles";
import { createStyles, Theme, WithStyles } from "@material-ui/core";
import Logo from "./logo.svg";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (themes: Theme) =>
  createStyles({
    logo: {
      width: "12em"
    }
  });

interface Props extends WithStyles<typeof styles> {
  breakpoints?: boolean;
}

type ImageProps = Partial<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
>;

const HaulPayLogo = ({
  classes,
  style,
  breakpoints = true,
  ...props
}: Props & ImageProps): JSX.Element => (
  <img alt="" className={classes.logo} src={Logo} />
);

export default withStyles(styles)(HaulPayLogo);

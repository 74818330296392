import React, { ChangeEventHandler } from "react";
import { Select } from "../../../../../components/inputs/BoldInput";

const factoringStatus = [
  {
    value: "new",
    text: "New"
  },
  {
    value: "lead_in",
    text: "Lead In"
  },
  {
    value: "document_review",
    text: "Document Review"
  },
  {
    value: "document_issue",
    text: "Document Issue"
  },
  {
    value: "pending",
    text: "Pending"
  },
  {
    value: "contract_out",
    text: "Contract Out"
  },
  {
    value: "ucc_hold",
    text: "UCC Hold"
  },
  {
    value: "not_authorized",
    text: "Not Authorized"
  },
  {
    value: "approved",
    text: "Approved"
  },
  {
    value: "declined",
    text: "Declined"
  },
  {
    value: "bad_lead",
    text: "Bad Lead"
  },
  {
    value: "dead",
    text: "Dead"
  }
];

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: ChangeEventHandler<HTMLInputElement>;
  value: string;
}

function FactoringStatusInput({ onChange, onBlur, value }: Props): JSX.Element {
  return (
    <React.Fragment>
      <Select
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        disabled
        name="status"
        label="Status"
        selections={factoringStatus}
      />
    </React.Fragment>
  );
}

export default FactoringStatusInput;

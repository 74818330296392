import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import {
  UPDATE_USER_NOTE_FAILURE,
  UPDATE_USER_NOTE_REQUEST,
  UPDATE_USER_NOTE_SUCCESS
} from "../types";
import { addEntities } from "../../entities";

export default (userId, noteId, note, attachment) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPDATE_USER_NOTE_REQUEST)({}));

    const response = await api.user.updateUserNotes(noteId, note, attachment);

    const data = normalize(response, schema.notes);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(UPDATE_USER_NOTE_SUCCESS)({
        [userId]: [data.result]
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_USER_NOTE_FAILURE)(err));
    throw err;
  }
};

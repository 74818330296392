import { denormalize } from "normalizr";

import { payment } from "../../../api/schema";

export default id => state => {
  try {
    const denormalized = denormalize(
      { item: id },
      { item: payment },
      state.entities
    );
    return denormalized.item || {};
  } catch (err) {
    return {};
  }
};

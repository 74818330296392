import React, { MouseEventHandler } from "react";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import { SnackbarContent, createStyles, WithStyles } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { SnackbarContentProps } from "@material-ui/core/SnackbarContent";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: green[600]
    },
    error: {
      backgroundColor: theme.palette.error.dark
    },
    info: {
      backgroundColor: theme.palette.primary.dark
    },
    warning: {
      backgroundColor: amber[700]
    },
    icon: {
      fontSize: 20
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing.unit
    },
    message: {
      display: "flex",
      alignItems: "center"
    }
  });

interface Props extends WithStyles<typeof styles> {
  className: string;
  message: string | JSX.Element[] | any;
  onClose: MouseEventHandler<HTMLButtonElement>;
  variant: "success" | "error" | "warning" | "info";
}

const MySnackbarContent = ({
  classes,
  className,
  message,
  onClose,
  variant,
  ...other
}: Props & SnackbarContentProps): JSX.Element => {
  const Icon = variantIcon[variant];
  let messageJSX = null;
  if (typeof message === "string") {
    messageJSX = (
      <span id="client-snackbar" className={classes.message}>
        <Icon className={classNames(classes.icon, classes.iconVariant)} />
        {message}
      </span>
    );
  } else if (typeof message === "object") {
    messageJSX = Object.keys(message).map((key, i) => (
      <span id="client-snackbar" key={i} className={classes.message}>
        <Icon className={classNames(classes.icon, classes.iconVariant)} />
        {`${key} : ${message[key as keyof any]}`}
      </span>
    ));
  } else {
    messageJSX = (message as JSX.Element[]).map(value => (
      <Grid container direction="row">
        <Grid item>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
        </Grid>
        <Grid item>
          <span id="client-snackbar" className={classes.message}>
            {value}
          </span>
        </Grid>
      </Grid>
    ));
  }
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={messageJSX}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

export default withStyles(styles)(MySnackbarContent);

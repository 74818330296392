import { denormalize } from "normalizr";
import { factorClient } from "../../../api/schema";

const getConnectedClients = state => {
  try {
    const items = state.debtor.connectedClients.ids;

    const denormalized = denormalize(
      { items },
      { items: [factorClient] },
      state.entities
    );

    return denormalized.items;
  } catch (err) {
    return [];
  }
};

export default getConnectedClients;

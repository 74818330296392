import { createAction } from "redux-starter-kit";
import {
  CREATE_FACTORING_PROFILE_FAILURE,
  CREATE_FACTORING_PROFILE_REQUEST,
  CREATE_FACTORING_PROFILE_SUCCESS
} from "../types";

const createFactoringProfile = user => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(CREATE_FACTORING_PROFILE_REQUEST)());
    const response = await api.factoring.createSelfFactoringClient(user);
    dispatch(createAction(CREATE_FACTORING_PROFILE_SUCCESS)(response));
    return response;
  } catch (err) {
    dispatch(createAction(CREATE_FACTORING_PROFILE_FAILURE)(err));
    throw err;
  }
};

export default createFactoringProfile;

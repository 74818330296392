import { get } from "lodash";
import { denormalize } from "normalizr";
import { reasonForLeaving as reasonForLeavingSchema } from "../../../api/schema";

export default state => {
  try {
    const reasonForLeaving = get(state, `factoring.reason_for_leaving.ids`, []);

    const denormalized = denormalize(
      { reasonForLeaving },
      { reasonForLeaving: [reasonForLeavingSchema] },
      state.entities
    );

    return {
      items: denormalized.reasonForLeaving,
      ...get(state, `factoring.reason_for_leaving`, {})
    };
  } catch (err) {
    return [];
  }
};

import React from "react";
import { List, Typography } from "@material-ui/core";

import onlyNumbers from "../../../../../helpers/format/onlyNumbers";
import businessTypes from "../../../../../helpers/businessType.json";
import states from "../../../../../helpers/states";
import Card from "../../../../../components/Card";
import BoldInput, { Select } from "../../../../../components/inputs/BoldInput";
import ListItem from "../../../../../components/ListItem";
import ProfileSection from "../../../../../components/ProfileSection";
import MailingAddressInfo from "../../../../../components/MailingAddressInfo";

export default ({ values, errors, handleChange, handleBlur }) => (
  <Card style={{ height: "100%" }}>
    <List dense>
      <ListItem>
        <ProfileSection>Business Info</ProfileSection>
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessLegalName"
          label="Legal Business Name:"
          value={values.businessLegalName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessLegalName}
          helperText={errors.businessLegalName}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessAddress"
          label="Address:"
          value={values.businessAddress}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessAddress}
          helperText={errors.businessAddress}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessStreetTwo"
          label="Street Two:"
          value={values.businessStreetTwo}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessStreetTwo}
          helperText={errors.businessStreetTwo}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessCity"
          label="City:"
          value={values.businessCity}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessCity}
          helperText={errors.businessCity}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessState"
          label="State:"
          value={values.businessState}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessState}
          helperText={errors.businessState}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessZip"
          label="Zip:"
          value={values.businessZip}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessZip}
          helperText={errors.businessZip}
        />
      </ListItem>
      <ListItem>
        <Select
          value={values.businessType}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          name="businessType"
          label="Business Type:"
          selections={businessTypes.map(value => ({
            text: value,
            value
          }))}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessMc"
          label="MC:"
          value={onlyNumbers(values.businessMc)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessMc}
          helperText={errors.businessMc}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessDot"
          label="DOT:"
          value={onlyNumbers(values.businessDot)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessDot}
          helperText={errors.businessDot}
        />
      </ListItem>
      <ListItem>
        <Select
          value={values.businessStateIncorporated}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          name="businessStateIncorporated"
          label="State Incorporated:"
          selections={states.map(({ key, value }) => ({
            text: value,
            value: key
          }))}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="businessEin"
          label="EIN:"
          value={onlyNumbers(values.businessEin)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.businessEin}
          helperText={errors.businessEin}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Remittance Email"
          name="remittanceEmail"
          value={values.remittanceEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.remittanceEmail}
          helperText={errors.remittanceEmail}
        />
      </ListItem>
    </List>
    <MailingAddressInfo
      handleBlur={handleBlur} 
      handleChange={handleChange} 
      values={values} 
    />
  </Card>
);

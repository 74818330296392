import React, { useCallback, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { normalize } from "normalizr";

import Colors from "../../../../../theme/colors";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../components/Dialog";
import { openDialog, openSnackbar } from "../../../../../modules/ui";
import FactorCompanyAutoComplete from "../../../../../components/inputs/FactorCompanyAutocomplete";
import formatLocation from "../../../../../helpers/format/formatAddress";
import factorCompanyAPI from "../../../../../api/factorCompany";
import { addEntities } from "../../../../../modules/entities";
import { paymentProfileRelationship } from "../../../../../api/schema";
import { getPaymentProfile } from "../../../../../modules/payment";

const DetailsFactorCompany = ({ value }) => {
  if (!value) return <div />;
  return <Typography variant="body2">{value}</Typography>;
};

function LinkFactorDialog({
  paymentProfileId,
  show,
  handleClose,
  data: { relationship = {} }
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const paymentProfile = useSelector(
    getPaymentProfile(relationship.payment_profile_id)
  );
  const [selectedFactorCompany, setSelectedFactorCompany] = useState(
    get(paymentProfile, ["factoring_company"], {})
  );
  const [buttonClicked, setButtonClicked] = useState(
    !!get(paymentProfile, ["factoring_company"], {})
  );
  const openCreateDialog = useCallback(
    () => dispatch(openDialog("create_factor_modal")),
    [dispatch]
  );

  const connectFactorOnClick = useCallback(async (args, relationship) => {
    setLoading(true);
    handleClose();
    try {
      const relation = await factorCompanyAPI.createFactorCompanyRelation(
        args.id,
        get(relationship, ["factoring", "id"]),
        paymentProfileId
      );
      const data = normalize(
        {
          ...relationship,
          factoring_company_relation: relation,
          payout_days: relation?.payout_days
        },
        paymentProfileRelationship
      );
      dispatch(addEntities(data.entities));
      // await factorCompanyAPI.sendFactorCompanyInvite(args.id);
      dispatch(
        openSnackbar(
          "success",
          `Factoring company ${args.name} has been linked to ${relationship.factoring.company_profile.name}.`
        )
      );
    } catch (e) {
      dispatch(openSnackbar("error", e));
    }
    setLoading(false);
    setSelectedFactorCompany({});
  }, []);

  function handleSetButtonClicked() {
    setButtonClicked(true);
  }

  const ConfirmMessage = ({ selectedFactorCompany, relationship }) => {
    return isEmpty(
      get(relationship, ["payment_profile", "factoring_company"], {})
    ) ? (
      <Grid
        item
        container
        justify="center"
        alignContent="center"
        style={{ padding: 30 }}
      >
        <Typography variant="body1">
          Are you sure you want to connect {selectedFactorCompany.name} for the
          broker {relationship.factoring.company_profile.name}?
        </Typography>
      </Grid>
    ) : (
      <Grid item container direction="column" style={{ padding: 30 }}>
        <Typography variant="body1" style={{ marginBottom: 30 }}>
          Are you sure you want to connect the carrier's factor to this broker?
        </Typography>
        <Typography variant="subheading" style={{ color: Colors.grayBg }}>
          Company Name
        </Typography>
        <Typography variant="body2" style={{ color: Colors.grayText }}>
          {selectedFactorCompany.name}
        </Typography>
      </Grid>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={show}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle onClose={handleClose}>Link Factor</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          {buttonClicked ? (
            <ConfirmMessage
              selectedFactorCompany={selectedFactorCompany}
              relationship={relationship}
            />
          ) : (
            <>
              <Grid item>
                <FactorCompanyAutoComplete
                  label="Company Name"
                  placeholder="Company Name"
                  value={selectedFactorCompany}
                  onChange={value => setSelectedFactorCompany(value)}
                  onNoOptionClick={openCreateDialog}
                />
              </Grid>
              {!isEmpty(selectedFactorCompany) && (
                <Grid item container style={{ margin: "auto", width: "100%" }}>
                  <Grid item xs={6}>
                    <DetailsFactorCompany value={selectedFactorCompany.email} />
                    <DetailsFactorCompany
                      value={selectedFactorCompany.phone_number}
                    />
                    <DetailsFactorCompany
                      value={formatLocation(selectedFactorCompany)}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary">
          Cancel
        </Button>
        {buttonClicked ? (
          <Button
            variant="contained"
            disabled={isEmpty(selectedFactorCompany) || loading}
            onClick={() =>
              connectFactorOnClick(selectedFactorCompany, relationship)
            }
            color="primary"
          >
            Yes
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={isEmpty(selectedFactorCompany) || loading}
            onClick={handleSetButtonClicked}
            color="primary"
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default LinkFactorDialog;

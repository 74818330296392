import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { get } from "lodash";
import axios from "axios";

import { addEntities } from "../../entities";
import {
  FETCH_FACTORINGNOTES_FAILURE,
  FETCH_FACTORINGNOTES_REQUEST,
  FETCH_FACTORINGNOTES_SUCCESS
} from "../types";

const fetchNotesTimelineNext = () => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_FACTORINGNOTES_REQUEST)());

    const state = getState();
    const id = get(state, ["factoringClient", "id"]);
    const next = get(state, ["factoringClient", "nextNote", id]);

    if (next) {
      const request = await axios.get(next);
      const response = request.data;

      const notes = await Promise.all(
        response.results.map(async note => {
          if (note.attachments.length > 0) {
            try {
              const att = await api.factoring.getFactoringClientNoteAttachment(
                note.id,
                note.attachments[0]
              );
              return {
                ...note,
                ...att
              };
            } catch (err) {
              return note;
            }
          }
          return note;
        })
      );

      const data = normalize(notes, [schema.notes]);
      dispatch(addEntities(data.entities));
      dispatch(
        createAction(FETCH_FACTORINGNOTES_SUCCESS)({
          [id]: {
            results: data.result,
            next: response.next
          }
        })
      );
      return response;
    }
  } catch (err) {
    dispatch(createAction(FETCH_FACTORINGNOTES_FAILURE)(err));
    throw err;
  }
};

export default fetchNotesTimelineNext;

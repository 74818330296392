import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_TRANSACTIONNOTES_FAILURE,
  FETCH_TRANSACTIONNOTES_REQUEST,
  FETCH_TRANSACTIONNOTES_SUCCESS
} from "../types";

const fetchTransactionNotes = fundingRequestId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_TRANSACTIONNOTES_REQUEST)());
    const response = await api.factoring.getTransactionNotes(fundingRequestId);
    const data = normalize(response, [schema.transactionNote]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_TRANSACTIONNOTES_SUCCESS)({
        [fundingRequestId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_TRANSACTIONNOTES_FAILURE)(err));
    throw err;
  }
};

export default fetchTransactionNotes;

import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

import IconStatus from "./IconStatus";

interface Props {
  status: string;
}

const HaulPayStatus = ({ status }: Props): JSX.Element => (
  <React.Fragment>
    {status ? (
      <Grid container direction="row" spacing={8} alignItems="center" style={{ minWidth: 100 }}>
        <Grid item>
          <IconStatus status={status} />
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: 12 }}>{` ${status}`}</Typography>
        </Grid>
      </Grid>
    ) : (
      <FontAwesomeIcon size="xs" icon={faMinus} />
    )}
  </React.Fragment>
);

export default HaulPayStatus;

import React from "react";
import { Button, Grid, MenuItem, Typography } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";

import { DialogActions, DialogTitle, Dialog, DialogContent } from "../Dialog";
import AttachmentButton from "../buttons/AttachmentButton";
import CustomTextField from "../inputs/CustomTextField";

interface Attachment {
  id: string;
  filename: string;
  modified: string | undefined;
  created: string;
  preview_url: string;
  download_url: string;
}

interface FormValues {
  noaToSend: string;
  attachments: Attachment[];
}

interface Props {
  placeNOAForm: FormikConfig<FormValues>;
  open: boolean;
  handleClose: () => void;
  clientName: string;
  debtorName: string;
}

export default ({
  open,
  handleClose,
  clientName,
  debtorName,
  placeNOAForm
}: Props): JSX.Element => (
  <Formik {...placeNOAForm}>
    {({
      submitForm,
      handleChange,
      handleBlur,
      values,
      isSubmitting
    }): JSX.Element => (
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle onClose={handleClose}>Send NOA</DialogTitle>
        <DialogContent>
          <Typography>Client: {clientName}</Typography>
          <Typography>Debtor: {debtorName}</Typography>
          {values.attachments && values.attachments.length > 0 ? (
            <Grid container alignItems="center">
              {values.attachments.length > 1 ? (
                <Grid container direction="column">
                  <Grid item>
                    <Typography>
                      NOAs Available:
                      <AttachmentButton attachments={values.attachments} />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      fullWidth
                      name="noaToSend"
                      select
                      placeholder="NOA to Send"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.noaToSend}
                    >
                      {values.attachments.map(
                        (file): JSX.Element => (
                          <MenuItem key={file.filename} value={file.filename}>
                            {file.filename}
                          </MenuItem>
                        )
                      )}
                    </CustomTextField>
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <Typography>NOA to be sent:</Typography>
                  <Grid item>
                    <AttachmentButton attachments={values.attachments} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <Typography>No NOA on file</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void => {
              submitForm();
              handleClose();
            }}
            disabled={isSubmitting || values.noaToSend === ""}
            color="primary"
          >
            Place NOA
          </Button>
        </DialogActions>
      </Dialog>
    )}
  </Formik>
);

import { createAction } from "redux-starter-kit";

import {
  DELETE_NOTES_FAILURE,
  DELETE_NOTES_REQUEST,
  DELETE_NOTES_SUCCESS
} from "../types";

const deleteNotes = (paymentProfileId, noteId) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(
      createAction(DELETE_NOTES_REQUEST)({
        noteId
      })
    );
    const response = await api.payments.deletePaymentProfileNotes(
      paymentProfileId,
      noteId
    );
    dispatch(createAction(DELETE_NOTES_SUCCESS)({ noteId, paymentProfileId }));
    return response;
  } catch (err) {
    dispatch(createAction(DELETE_NOTES_FAILURE)(err));
    throw err;
  }
};

export default deleteNotes;

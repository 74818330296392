import React from "react";
import {
    Button,
    DialogActions,
    Grid,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell, TableBody
} from '@material-ui/core';

import { DialogTitle, Dialog, DialogContent } from "../Dialog";
import UploadFile from '../UploadFile';
import AttachmentLinks from '../AttachmentLinks';

export default function UploadAttachmentDialog({
  open,
  handleExit,
  attachments = [],
  uploadFileProps
}) {
    return (
        <Dialog open={open} maxWidth="md" fullWidth>
            <DialogTitle onClose={() => handleExit()}>Please select a file to upload</DialogTitle>
            <DialogContent>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>File Name</TableCell>
                                <TableCell align="right">Category</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {attachments.map(({ id, filename, category, preview_url, download_url }) => (
                                <TableRow key={id}>
                                    <TableCell component="th" scope="row">
                                        {filename}
                                    </TableCell>
                                    <TableCell align="right">{category}</TableCell>
                                    <TableCell align="right">
                                        <AttachmentLinks
                                            attachment={{
                                                preview_url,
                                                download_url
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
                <UploadFile
                    {...uploadFileProps}
                />
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                        <Button color="secondary" onClick={handleExit}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

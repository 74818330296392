const categories = [
  {
    key: "Signed Bill of Lading (Delivered)",
    name: "Signed Bill of Lading (Delivered)"
  },
  {
    key: "Signed Bill of Lading (Pick-Up)",
    name: "Signed Bill of Lading (Pick-Up)"
  },
  {
    key: "Rate Confirmation",
    name: "Rate Confirmation"
  },
  {
    key: "Truck Order Not Used",
    name: "Truck Order Not Used"
  },
  {
    key: "Invoice",
    name: "Invoice"
  },
  {
    key: "Freight Bill",
    name: "Freight Bill"
  },
  {
    key: "Fuel Advance",
    name: "Fuel Advance"
  },
  {
    key: "Lumper Receipt",
    name: "Lumper Receipt"
  },
  {
    key: "Detention Receipt",
    name: "Detention Receipt"
  },
  {
    key: "Late Fee Receipt",
    name: "Late Fee Receipt"
  },
  {
    key: "Other",
    name: "Other"
  }
];

export default categories;

import { Formik, FormikConfig } from "formik";
import { Card, Grid } from "@material-ui/core";
import React from "react";
import Header from "../../components/Login/Header";
import PaperSheet from "../../components/PaperSheet";
import ForgetPasswordForm from "./ForgetPasswordForm";

interface Values {
  email: string;
}

interface Props {
  form: FormikConfig<Values>;
  errorServer?: string;
}

const PureForgetPassword = ({ form, errorServer }: Props): JSX.Element => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justify="center"
    lg={3}
    style={{ margin: "auto" }}
  >
    <Card style={{ margin: 20 }}>
      <Grid item style={{ padding: 20 }}>
        <div style={{ paddingTop: "32px" }}>
          <Header />
        </div>
      </Grid>
      <Grid item style={{ padding: 20 }}>
        <Formik {...form}>
          {(formik): JSX.Element => (
            <ForgetPasswordForm
              {...formik}
              errorServer={errorServer}
              authLoading={formik.isSubmitting}
            />
          )}
        </Formik>
      </Grid>
    </Card>
  </Grid>
);

export default PureForgetPassword;

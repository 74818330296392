import { createAction } from "redux-starter-kit";
import {
  CREATE_DEBTORPAYMENT_FAILURE,
  CREATE_DEBTORPAYMENT_REQUEST,
  CREATE_DEBTORPAYMENT_SUCCESS
} from "../types";
import queryOpenFundingRequests from "./queryOpenFundingRequests";

const createDebtorPayment = (id, data, params = {}) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(CREATE_DEBTORPAYMENT_REQUEST)({}));
    await api.payments.createDebtorPayment(id, data, params);
    await queryOpenFundingRequests(id);
    dispatch(createAction(CREATE_DEBTORPAYMENT_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(CREATE_DEBTORPAYMENT_FAILURE)(err));
    throw err;
  }
};

export default createDebtorPayment;

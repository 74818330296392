import { Button } from "@material-ui/core";
import React from "react";
import colors from "../../../../../../../theme/colors";

const GreenButton = ({ onClick, children }) => (
  <Button
    variant="contained"
    style={{
      background: colors.green,
      color: colors.white
    }}
    size="medium"
    onClick={onClick}
  >
    {children}
  </Button>
);

export default GreenButton;

import React, { useCallback, useState } from "react";
import {
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText
} from "@material-ui/core";
import { Formik } from "formik";
import SaveIcon from "@material-ui/icons/Save";
import { useDispatch } from "react-redux";
import { getEmojiDataFromNative } from "emoji-mart";
import get from "lodash/get";
import dataEmoji from "emoji-mart/data/all.json";

import Yup from "../../../../../YupValidation";

import { DialogActions, DialogTitle, Dialog, DialogContent } from "../../../../../components/Dialog";
import RoundedButton from "../../../../../components/buttons/RoundedButton";
import BrokerAutoComplete from "../../../../../components/inputs/BrokerAutoComplete";
import Colors from "../../../../../theme/colors";

import AutoCompleteAddress from "../../../../../components/inputs/AutoCompleteAddress";
import CustomTextField from "../../../../../components/inputs/CustomTextField";
import PasswordInput from "../../../../../components/inputs/PasswordInput";
import API from "../../../../../api";
import formatLocation from "../../../../../helpers/format/formatAddress";
import formatPhone from "../../../../../helpers/format/formatPhone";
import { openSnackbar, closeDialog } from "../../../../../modules/ui";

interface Props {
  handleClose: () => void;
  open: boolean;
}

export default function PaymentProfileModal({ handleClose, open }: Props) {
  const dispatch = useDispatch();
  const createPaymentProfile = useCallback(async (fields, formikActions) => {
    try {
      const data = {
        email: fields.email,
        password: fields.password,
        type: "carrier",
        language: fields.language,
        carrier: {
          dba: fields.dba,
          contact_phone: formatPhone(fields.contact_phone),
          contact_name: fields.contact_name,
          mc: fields.mc,
          dot: fields.dot,
          language: fields.language,
          address: formatLocation(fields.address) || "",
          city: get(fields, "address.city", ""),
          state: get(fields, "address.state", ""),
          zip: get(fields, "address.zip", "")
        }
      };
      const res = await API.user.createUserAdmin(data);
      await API.factoring.paymentProfile.createPaymentProfileAdmin({
        user_id: res.id,
        factoring_id: get(fields, "broker.user.factoring_id", null)
      });
      dispatch(
        openSnackbar("success", "Payment Profile was successfully added")
      );
      dispatch(closeDialog())
    } catch (e) {
      dispatch(openSnackbar("error", e));
    }
    formikActions.setSubmitting(false);
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle onClose={handleClose}>Create Payment Profile</DialogTitle>
      <Formik
        validationSchema={() =>
          Yup.object().shape({
            broker: Yup.object().shape({
              id: Yup.string().required("Please fill out this field")
            })
          })
        }
        onSubmit={createPaymentProfile}
        initialValues={{
          name: undefined,
          email: undefined,
          contact_phone: undefined,
          address: {},
          password: undefined,
          language: "en",
          mc: undefined,
          dot: undefined,
          contact_name: undefined,
          broker: {}
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          errors,
          touched
        }) => {
          const brokerError = errors.broker && touched.broker;
          return (
            <>
              <DialogContent>
                <Grid
                  container
                  spacing={16}
                  direction="column"
                  style={{ minHeight: 300, padding: 20 }}
                >
                  {/* <Grid item> */}
                  {/*  <CarrierAutoComplete */}
                  {/*    onNoOptionClick={false} */}
                  {/*    label="Carrier Name" */}
                  {/*    placeholder="Select a Carrier" */}
                  {/*    onChange={(carrier: any) => */}
                  {/*      setFieldValue("carrier", carrier) */}
                  {/*    } */}
                  {/*  /> */}
                  {/* </Grid> */}
                  <Grid container item wrap="wrap">
                    <Grid style={{ flex: 0.5, marginRight: 5 }}>
                      <CustomTextField
                        name="mc"
                        placeholder="MC #"
                        label="MC #"
                        value={values.mc}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid style={{ flex: 0.5, marginLeft: 5 }}>
                      <CustomTextField
                        name="dot"
                        placeholder="DOT #"
                        label="DOT #"
                        value={values.dot}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      name="dba"
                      placeholder="Enter company DBA"
                      label="DBA"
                      value={values.name}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      name="email"
                      placeholder="Enter company Email"
                      label="Company Email"
                      value={values.email}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <PasswordInput
                      fullWidth
                      showEndPasswordIcon
                      name="password"
                      label="Enter Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      name="contact_name"
                      placeholder="Enter Contact Name"
                      label="Contact Name"
                      value={values.contact_name}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      name="contact_phone"
                      placeholder="Enter company Phone"
                      label="Company Phone"
                      value={values.contact_phone}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <AutoCompleteAddress
                      name="address"
                      placeholder="Enter the company Address"
                      fullWidth
                      value={values.address}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      withStyle
                    />
                  </Grid>
                  <Grid item>
                    <BrokerAutoComplete
                      onNoOptionClick={false}
                      label="Broker Name"
                      placeholder="Select a Broker"
                      onChange={(broker: any) =>
                        setFieldValue("broker", broker)
                      }
                      setFieldTouched={setFieldTouched}
                      hasError={brokerError}
                    />
                    {brokerError && (
                      <FormHelperText error id="component-error-text">
                        {get(errors, "broker.id", "")}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <RadioGroup
                      aria-label="Language"
                      name="language"
                      value={values.language}
                      onChange={handleChange}
                      style={{ flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="en"
                        control={<Radio />}
                        label={`EN ${
                          getEmojiDataFromNative("🇺🇸", "apple", dataEmoji)
                            .native
                        }`}
                      />
                      <FormControlLabel
                        value="es"
                        control={<Radio />}
                        label={`ES ${
                          getEmojiDataFromNative("🇲🇽", "apple", dataEmoji)
                            .native
                        }`}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid container justify="flex-end">
                  <Grid item style={{ flex: 0.1 }}>
                    <RoundedButton
                      variant="contained"
                      color="primary"
                      progressStyle={{ color: Colors.primary }}
                      loading={isSubmitting}
                      onClick={() => handleSubmit()}
                    >
                      <SaveIcon fontSize="small" />
                      Done
                    </RoundedButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}

import { assign, merge } from "lodash";
import { PayloadAction } from "redux-starter-kit";
import {
  FETCH_DEBTORINVOICES_FAILURE,
  FETCH_DEBTORINVOICES_REQUEST,
  FETCH_DEBTORINVOICES_SUCCESS,
  State
} from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case FETCH_DEBTORINVOICES_REQUEST:
      return merge({}, state, {
        id: action.payload.id,
        invoices: assign({}, state.invoices, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          offset: action.payload.offset,
          ordering: action.payload.ordering
        })
      });
    case FETCH_DEBTORINVOICES_SUCCESS:
      return assign({}, state, {
        invoices: assign({}, state.invoices, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count
        })
      });
    case FETCH_DEBTORINVOICES_FAILURE:
      return assign({}, state, {
        invoices: merge({}, state.invoices, {
          loading: false
        })
      });
    default:
      return state;
  }
};

import React, { MouseEventHandler } from "react";
import { Edit as EditIcon } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

interface Props {
  id?: string;
  onClick?: MouseEventHandler;
}

export default ({ onClick, id }: Props): JSX.Element => (
  <IconButton style={{ color: "#2196f3" }} id={id} onClick={onClick}>
    <EditIcon />
  </IconButton>
);

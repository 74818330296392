import { denormalize } from "normalizr";
import { get } from "lodash";
import { accounts } from "../../../api/schema";

const getAccounts = userId => state => {
  try {
    const items = get(state, [`factoringClient`, `accounts`, `${userId}`], []);

    const denormalized = denormalize(
      { items },
      { items: [accounts] },
      state.entities
    );

    return denormalized.items;
  } catch (err) {
    return [];
  }
};

export default getAccounts;

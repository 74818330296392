const updatePaymentProfile = (id, user) => async (
  dispatch,
  getState,
  { api }
) => {
  const response = await api.factoring.updatePaymentProfile(id, user);
  return response;
};

export default updatePaymentProfile;

import { normalize } from "normalizr";
import { createAction } from "redux-starter-kit";

import { addEntities } from "../../entities";
import { ADD_CREDIT_HISTORY_SUCCESS } from "../types";

const addCreditCheckData = object => async (
  dispatch,
  getState,
  { api, schema }
) => {
  const response = await api.debtors.addCreditCheckData(object);
  const data = normalize([response], [schema.creditReport]);
  dispatch(addEntities(data.entities));
  dispatch(
    createAction(ADD_CREDIT_HISTORY_SUCCESS)({
      items: data.result
    })
  );
};

export default addCreditCheckData;

import { assign } from "lodash";
import {
  FETCH_CONNECTEDPROFILE_REQUEST,
  FETCH_CONNECTEDPROFILE_SUCCESS,
  FETCH_CONNECTEDPROFILE_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_CONNECTEDPROFILE_REQUEST:
      return {
        ...state,
        id: action.payload.id,
        connectedBrokersCarriers: assign({}, state.connectedBrokersCarriers, {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          ordering: action.payload.ordering
        })
      };
    case FETCH_CONNECTEDPROFILE_SUCCESS:
      return {
        ...state,
        connectedBrokersCarriers: assign({}, state.connectedBrokersCarriers, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        })
      };
    case FETCH_CONNECTEDPROFILE_FAILURE:
      return {
        ...state,
        connectedBrokersCarriers: assign({}, state.connectedBrokersCarriers, {
          loading: false
        })
      };
    default:
      return state;
  }
};

import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import {
  findIndex,
  get,
  head,
  reverse,
  filter,
  flatMap,
  includes
} from "lodash";
import { Edit as EditIcon } from "@material-ui/icons";
import { Grid, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";

import withSelect from "../../../../components/withSelect";
import formatDate from "../../../../helpers/format/formatDate";
import formatPercent from "../../../../helpers/format/formatPercent";
import formatPennies from "../../../../helpers/format/formatPennies";
import {
  createFundingRequestCSV,
  getDebtorCompanyName,
  getDebtorInvoices,
  getDebtorMaster,
  getInvoicesCount,
  getInvoicesFilters,
  getInvoicesIsLoading,
  getInvoicesPage,
  getInvoicesRowsPerPage,
  getInvoicesRowsPerPageOptions,
  getInvoicesSortBy,
  getInvoicesSortDirection,
  handleInvoicesChangePage,
  handleInvoicesChangeRowsPerPage,
  handleInvoicesSearchFilter,
  handleInvoicesSort,
  queryDebtor,
  queryDebtorInvoices,
  fetchSimilarDebtors,
  getSimilarDebtors
} from "../../../../modules/debtor";
import API from "../../../../api";
import PureInvoices from "./Invoices";
import tabs from "../tabs";
import columns from "./columns";
import TransactionStatus from "../../../../components/TransactionStatus";
import SelfFinanceIcon from "../../../../components/icons/SelfFinanceIcon";

export const Invoices = PureInvoices;

const mapStateToProps = state => ({
  invoices: getDebtorInvoices(state),
  similarDebtors: getSimilarDebtors(state),
  isLoading: getInvoicesIsLoading(state),
  count: getInvoicesCount(state),
  page: getInvoicesPage(state),
  rowsPerPage: getInvoicesRowsPerPage(state),
  rowsPerPageOptions: getInvoicesRowsPerPageOptions(state),
  companyName: getDebtorCompanyName(state),
  sortDirection: getInvoicesSortDirection(state),
  sortBy: getInvoicesSortBy(state),
  filters: getInvoicesFilters(state),
  master: getDebtorMaster(state)
});

const mapDispatchToProps = {
  fetchSimilarDebtors,
  queryDebtorInvoices,
  handleSort: handleInvoicesSort,
  handleChangePage: handleInvoicesChangePage,
  handleChangeRowsPerPage: handleInvoicesChangeRowsPerPage,
  handleSearchFilter: handleInvoicesSearchFilter,
  createFundingRequestCSV,
  queryDebtor
};

export default compose(
  withSelect,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      match: {
        params: { id }
      },
      invoices,
      selected,
      similarDebtors,
      ...props
    }) => ({
      columns,
      tabs: tabs(id, similarDebtors.length),
      navIndex: findIndex(tabs(id), tab => pathname === tab.route),
      handleRoute: route => push(route),
      id,
      invoices: invoices.map(invoice => {
        return {
          ...invoice,
          status: (
            <TransactionStatus
              status={invoice.status}
              modified={invoice.modified}
              statusTimeline={invoice.status_timeline}
              newAttachments={get(
                head(reverse(invoice.attachments)),
                "after_issue"
              )}
            />
          ),
          amount: (
            <Grid container direction="row" alignItems="center">
              {formatPennies(invoice.amount)}
              <SelfFinanceIcon contractType={invoice?.contract_type} />
            </Grid>
          ),
          amount_funded: formatPennies(invoice.amount_funded),
          created: formatDate(invoice.created),
          invoice_number: `${invoice.invoice_number} (${formatPercent(
            invoice.discount_rate
          )})`,
          more: (
            <Link to={`/admin/fundingrequest/${invoice.id}/`}>
              <IconButton>
                <EditIcon />
              </IconButton>
            </Link>
          )
        };
      }),
      handleCSV: async () => {
        const invoiceNumber = filter(invoices, o => includes(selected, o.id));
        const response = await API.factoring.queryFundingRequestsExport({
          debtor_id: id,
          invoice_number: (
            flatMap(invoiceNumber, o => o.invoice_number) || []
          ).join(","),
          ...props.filters
        });
        const element = document.createElement("a");
        element.href = response.download_url;
        element.download = "export.xlsx";
        document.body.appendChild(element);
        element.click();
      }
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.queryDebtor(this.props.id);
      this.props.queryDebtorInvoices(
        this.props.id,
        "-created",
        this.props.rowsPerPage,
        0,
        {}
      );
      this.props.fetchSimilarDebtors(this.props.id);
    }
  })
)(PureInvoices);

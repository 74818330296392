import React, { MouseEventHandler } from "react";
import { Fab, createStyles, WithStyles } from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import orange from "@material-ui/core/colors/orange";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import colors from "../../theme/colors";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center"
    },
    wrapper: {
      margin: theme.spacing.unit,
      position: "relative"
    },
    fabProgress: {
      color: orange[500],
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1
    }
  });

interface Props extends WithStyles<typeof styles> {
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const ContinueButton = ({ onClick, disabled, classes }: Props): JSX.Element => (
  <div className={classes.root}>
    <div className={classes.wrapper}>
      <Fab
        aria-label="Continue"
        color="primary"
        disabled={disabled}
        onClick={onClick}
      >
        <ArrowForwardIos style={{ color: colors.white }} />
      </Fab>
      {disabled && (
        <CircularProgress size={68} className={classes.fabProgress} />
      )}
    </div>
  </div>
);

// @ts-ignore
export default withStyles(styles)(ContinueButton);

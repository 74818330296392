import { createAction } from "redux-starter-kit";

import { createUser } from "../../user";
import { fetchUserToken } from "../../auth";
import createFactoringProfile from "../../invited/actions/createFactoringProfile";
import updateFactoringProfile from "../../invited/actions/updateFactoringProfile";

import {
  CREATE_SIGNUP_FACTORING_PROFILE_FAILURE,
  CREATE_SIGNUP_FACTORING_PROFILE_REQUEST,
  CREATE_SIGNUP_FACTORING_PROFILE_SUCCESS
} from "../types";

const signUpFactoringProfile = (
  user,
  token,
  action,
  source = undefined
) => async dispatch => {
  try {
    dispatch(createAction(CREATE_SIGNUP_FACTORING_PROFILE_REQUEST)());
    let response = {};
    if (action === "new") {
      await dispatch(
        createUser(user, token, source ? `Haulpay.co, ${source}` : "Haulpay.co")
      );
      await dispatch(fetchUserToken({ ...user, invite_token: token }));
      window.analytics.identify(user.email, { email: user.email });
      response = await dispatch(createFactoringProfile(user));
      window.analytics.track("HaulPayAppSubmitted", response);
    } else if (action === "create_factoring_profile") {
      response = await dispatch(createFactoringProfile(user));
      window.analytics.track("HaulPayAppSubmitted", response);
    } else {
      response = await dispatch(updateFactoringProfile(user));
      window.analytics.track("HaulPayAppUpdated", response);
    }
    dispatch(createAction(CREATE_SIGNUP_FACTORING_PROFILE_SUCCESS)(response));
  } catch (err) {
    dispatch(createAction(CREATE_SIGNUP_FACTORING_PROFILE_FAILURE)(err));
  }
};

export default signUpFactoringProfile;

import { createAction } from "redux-starter-kit";
import { denormalize, normalize } from "normalizr";
import { assign, debounce, isEqual, pick } from "lodash";
import withTable from "./withTable";
import { addEntities } from "./entities";
import triggerDownload from "../helpers/triggerDownload";
import { fundingRequest } from "../api/schema";

const FETCH_QUERYFUNDINGREQUESTS_REQUEST =
  "TRANSACTIONS/FETCH_QUERYFUNDINGREQUESTS_REQUEST";
const FETCH_QUERYFUNDINGREQUESTS_SUCCESS =
  "TRANSACTIONS/FETCH_QUERYFUNDINGREQUESTS_SUCCESS";
const FETCH_QUERYFUNDINGREQUESTS_FAILURE =
  "TRANSACTIONS/FETCH_QUERYFUNDINGREQUESTS_FAILURE";

const FETCH_UPDATESTATUSES_REQUEST =
  "TRANSACTIONS/FETCH_UPDATESTATUSES_REQUEST";
const FETCH_UPDATESTATUSES_SUCCESS =
  "TRANSACTIONS/FETCH_UPDATESTATUSES_SUCCESS";
const FETCH_UPDATESTATUSES_FAILURE =
  "TRANSACTIONS/FETCH_UPDATESTATUSES_FAILURE";
const TRANSACTIONS_BULK_ERROR = "TRANSACTIONS/BULK_ERROR";
const TRANSACTIONS_CLEAR_BULK_ERROR = "TRANSACTIONS/CLEAR_BULK_ERROR";

const {
  selectors,
  actions,
  reducer: tableReducer,
  middleware: tableMiddleware
} = withTable({
  stateKey: "transactions",
  typeKey: "transactions"
});

const sameRequest = (state, payload) =>
  state.limit === payload.limit &&
  state.offset === payload.offset &&
  state.ordering === payload.ordering &&
  isEqual(state.filters, payload.filters);

const reducer = (
  state = {
    loading: false,
    ids: [],
    status: undefined,
    bulkError: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_QUERYFUNDINGREQUESTS_REQUEST:
      return assign({}, state, {
        ids: [],
        loading: true,
        status: action.payload.status,
        limit: action.payload.limit,
        offset: action.payload.offset,
        ordering: action.payload.ordering,
        filters: action.payload.filters
      });
    case FETCH_QUERYFUNDINGREQUESTS_SUCCESS:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count
        });
      }
      return state;
    case FETCH_QUERYFUNDINGREQUESTS_FAILURE:
      return assign({}, state, {
        loading: false
      });
    case TRANSACTIONS_BULK_ERROR:
      return assign({}, state, {
        bulkError: [...state.bulkError, action.payload]
      });
    case TRANSACTIONS_CLEAR_BULK_ERROR:
      return assign({}, state, {
        bulkError: []
      });
    case FETCH_UPDATESTATUSES_REQUEST:
      return state;
    case FETCH_UPDATESTATUSES_SUCCESS:
      return state;
    case FETCH_UPDATESTATUSES_FAILURE:
      return state;
    default:
      return state;
  }
};

export const getFundingRequests = state => {
  const items = state.transactions.ids;

  const denormalized = denormalize(
    { items },
    { items: [fundingRequest] },
    state.entities
  );

  return denormalized.items;
};

const debounced = debounce(
  async (dispatch, schema, api, status, limit, offset, ordering, filters) => {
    dispatch(
      createAction(FETCH_QUERYFUNDINGREQUESTS_REQUEST)({
        status,
        limit,
        offset,
        ordering,
        filters
      })
    );
    const response = await api.factoring.queryFundingRequests(
      status,
      ordering,
      limit,
      offset,
      filters
    );
    const data = normalize(response.results, [schema.fundingRequest]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_QUERYFUNDINGREQUESTS_SUCCESS)({
        items: data.result,
        count: response.count,
        status,
        limit,
        offset,
        ordering,
        filters
      })
    );
    return response;
  },
  1000,
  { trailing: true }
);

export const queryFundingRequests = (
  status,
  ordering,
  limit,
  offset,
  filters = {}
) => async (dispatch, getState, { api, schema }) => {
  try {
    await debounced(
      dispatch,
      schema,
      api,
      status,
      limit,
      offset,
      ordering,
      filters
    );
  } catch (err) {
    dispatch(createAction(FETCH_QUERYFUNDINGREQUESTS_FAILURE)(err));
    throw err;
  }
};

export const createFundingRequestCSV = (filters, status) => async (
  dispatch,
  getState,
  { api }
) => {
  const response = await api.factoring.queryFundingRequestsExport({
    ...pick(filters, [
      "client_company_name",
      "bill_to_company_name",
      "invoice_number",
      "user_load_number",
      "scheduled_purchase_date",
      "assigned_admin",
      "status"
    ]),
    status
  });
  triggerDownload(response.download_url);
};

export const setStatus = (ids, status, params = {}) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  dispatch(
    createAction(FETCH_UPDATESTATUSES_REQUEST)({
      ids,
      status
    })
  );
  dispatch(createAction(TRANSACTIONS_CLEAR_BULK_ERROR)());
  try {
    const promises = ids.map(async id => {
      try {
        await api.factoring.updateFundingRequest({ id, status }, params);
      } catch (error) {
        return dispatch(
          createAction(TRANSACTIONS_BULK_ERROR)({
            id,
            error
          })
        );
      }
    });
    const results = await Promise.all(promises);
    const data = normalize(results, [schema.fundingRequest]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_UPDATESTATUSES_SUCCESS)({
        ids,
        status
      })
    );
  } catch (err) {
    dispatch(createAction(FETCH_UPDATESTATUSES_FAILURE)(err));
    throw err;
  }
};

export const getIsLoading = state => state.transactions.loading;

export const getBulkError = state => state.transactions.bulkError;

export const {
  getOffset,
  getRowsPerPageOptions,
  getRowsPerPage,
  getCount,
  getSortDirection,
  getSortBy,
  getPage,
  getFilters
} = selectors;

export default tableReducer(reducer);

export const {
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter
} = actions;

export const middleware = tableMiddleware(tableSelectors => store => action => {
  const state = store.getState();
  const sortBy = tableSelectors.getSortBy(state);
  const sortDirection = tableSelectors.getSortDirection(state);
  const offset = tableSelectors.getOffset(state);
  const rowsPerPage = tableSelectors.getRowsPerPage(state);
  const filters = tableSelectors.getFilters(state);

  store.dispatch(
    queryFundingRequests(
      state.transactions.status,
      sortDirection === "asc" ? sortBy : `-${sortBy}`,
      rowsPerPage,
      offset,
      filters
    )
  );
});

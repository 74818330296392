export const FETCH_ADMIN_STATS_FAILURE = "ADMIN/FETCH_STATS_FAILURE";
export const FETCH_ADMIN_STATS_SUCCESS = "ADMIN/FETCH_STATS_SUCCESS";
export const FETCH_ADMIN_STATS_REQUEST = "ADMIN/FETCH_STATS_REQUEST";

export const FETCH_ADMIN_REVENUE_BY_PERIOD_FAILURE =
  "ADMIN/FETCH_REVENUE_BY_PERIOD_FAILURE";
export const FETCH_ADMIN_REVENUE_BY_PERIOD_SUCCESS =
  "ADMIN/FETCH_REVENUE_BY_PERIOD_SUCCESS";
export const FETCH_ADMIN_REVENUE_BY_PERIOD_REQUEST =
  "ADMIN/FETCH_REVENUE_BY_PERIOD_REQUEST";

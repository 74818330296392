import PropTypes from "prop-types";
import React from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import colors from "../../../theme/colors";
import PaperSheet from "../../../components/PaperSheet";

const styles = theme => ({
  welcomeBtn: {
    borderRadius: 25,
    width: "100vw",
    maxWidth: "200px"
  },
  subHeading: {
    lineHeight: 1.5,
    marginBottom: 30,
    fontWeight: 400,
    color: colors.gray
  },
  margin: {
    margin: theme.spacing.unit
  }
});

const Welcome = ({ nextStep, classes, inviteError, disabled, groupName }) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    alignContent="center"
    style={{
      marginTop: 100
    }}
  >
    <Grid item>
      {inviteError ? (
        <PaperSheet
          message="Link has expired"
          variant="error"
          className={classes.margin}
        />
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justify="center"
        >
          <Typography
            align="center"
            variant="h5"
            className={classes.subHeading}
            style={{
              width: "70%"
            }}
          >
            {`Please continue to register for ${groupName} group to create your payment profile to receive payments.`}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            className={classes.welcomeBtn}
            onClick={nextStep}
            disabled={disabled}
          >
            continue
          </Button>
        </Grid>
      )}
    </Grid>
  </Grid>
);

Welcome.propTypes = {
  disabled: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  inviteError: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired
};

export default withStyles(styles)(Welcome);

import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import {
  fetchAdmins,
  getAdmins,
  getCount,
  getFilters,
  getIsLoading,
  getOffset,
  getPage,
  getRowsPerPage,
  getRowsPerPageOptions,
  getSortBy,
  getSortDirection,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter,
  handleSort
} from "../../../modules/admins";
import { updateUser } from "../../../modules/user";
import { closeDialog, getDialog, openDialog } from "../../../modules/ui";
import { openModal } from "../../../modules/modal";

import PureAdmins from "./Admins";

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  sortDirection: getSortDirection(state),
  sortBy: getSortBy(state),
  count: getCount(state),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  rowsPerPageOptions: getRowsPerPageOptions(state),
  offset: getOffset(state),
  filters: getFilters(state),
  admins: getAdmins(state),
  dialog: getDialog(state)
});

const mapDispatchToProps = {
  fetchAdmins,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter,
  openDialog,
  closeDialog,
  openModal,
  updateUser
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentDidMount() {
      const { fetchAdmins, rowsPerPage } = this.props;
      fetchAdmins("-time_posted", rowsPerPage, 0);
    }
  })
)(PureAdmins);
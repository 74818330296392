import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFileInvoiceDollar,
  faHandHoldingUsd,
  faHistory
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Link } from "react-router-dom";

import colors from "../../../../../theme/colors";

interface Props {
  link: string;
  handleNote: () => void;
  handleViewPayments: () => void;
}

export default ({
  link,
  handleNote,
  handleViewPayments
}: Props): JSX.Element => (
  <Grid container style={{ minWidth: "150px" }}>
    <Grid item>
      <Tooltip title="View Payment History">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={handleViewPayments}
        >
          <FontAwesomeIcon color={colors.primary} icon={faHistory} />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="View Funding Request">
        <Link to={link} target="_blank">
          <IconButton style={{ width: "48px", height: "48px" }}>
            <FontAwesomeIcon
              size="xs"
              icon={faHandHoldingUsd}
              color={colors.green_light}
            />
          </IconButton>
        </Link>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="View/Add Notes">
        <IconButton onClick={(): void => handleNote()}>
          <FontAwesomeIcon icon={faEdit} color={colors.secondary} />
        </IconButton>
      </Tooltip>
    </Grid>
  </Grid>
);

import { assign } from "lodash";
import { PayloadAction } from "redux-starter-kit";
import { DELETE_DEBTOR_SUCCESS, State } from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case DELETE_DEBTOR_SUCCESS:
      return assign({}, state, {
        similarDebtors: assign({}, state.similarDebtors, {
          ids: state.similarDebtors.ids.filter(id => id !== action.payload.id),
          count: state.similarDebtors.ids.filter(id => id === action.payload.id)
            .length
        })
      });
    default:
      return state;
  }
};

import { Grid, IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import {
  faEdit,
  faFileInvoiceDollar,
  faHandHoldingUsd,
  faHistory
} from "@fortawesome/free-solid-svg-icons";

import colors from "../../../../../theme/colors";
import { openDialog } from "../../../../../modules/ui";
import Can from "../../../../../components/Can";

function MoreButton({ receivableId, id, frid }) {
  const dispatch = useDispatch();

  return (
    <Grid container item direction="row" spacing={24} style={{ minWidth: 160 }}>
      <Can
        perform="admin-finances-receivables:edit"
        yes={() => (
          <>
            <Grid item xs={3}>
              <Tooltip title="View Log">
                <IconButton
                  onClick={() =>
                    dispatch(
                      openDialog("receivables-payment-history", "", "", {
                        id: receivableId
                      })
                    )
                  }
                >
                  <FontAwesomeIcon icon={faHistory} color={colors.primary} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Link to={`/admin/debtors/${id}/payment`}>
                <Tooltip title="Debtor Payment">
                  <IconButton>
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      color={colors.grayIcon}
                    />
                  </IconButton>
                </Tooltip>
              </Link>
            </Grid>
          </>
        )}
      />
      <Grid item xs={3}>
        <Tooltip title="View/Add Notes">
          <IconButton
            style={{ width: "48px", height: "48px", color: colors.link }}
            onClick={() => {
              dispatch(
                openDialog("receivables-notes", "", "", { receivableId })
              );
            }}
          >
            <FontAwesomeIcon icon={faEdit} color={colors.secondary} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Link
          to={`/admin/fundingrequest/${frid}`}
          style={{ textDecoration: "none" }}
        >
          <Tooltip title="Funding Request">
            <IconButton
              style={{
                width: "48px",
                height: "48px",
                color: colors.green_light
              }}
            >
              <FontAwesomeIcon size="xs" icon={faHandHoldingUsd} />
            </IconButton>
          </Tooltip>
        </Link>
      </Grid>
    </Grid>
  );
}

export default MoreButton;

import { createAction } from "redux-starter-kit";
import {
  DELETE_CLIENTNOTE_FAILURE,
  DELETE_CLIENTNOTE_REQUEST,
  DELETE_CLIENTNOTE_SUCCESS
} from "../types";

const deleteClientNote = (factoringId, noteId) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(DELETE_CLIENTNOTE_REQUEST)());
    await api.factoring.deleteFactoringClientNote(noteId);
    dispatch(
      createAction(DELETE_CLIENTNOTE_SUCCESS)({
        factoringId,
        note: noteId
      })
    );
  } catch (err) {
    dispatch(createAction(DELETE_CLIENTNOTE_FAILURE)(err));
    throw err;
  }
};

export default deleteClientNote;

import React from "react";
import { get, isEmpty } from "lodash";
import { Grid, Typography } from "@material-ui/core";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import StatsCard from "./StatsCard";
import colors from "../../../../theme/colors";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      verticalAnchor="middle"
      dominantBaseline="central"
      scaleToFit
      style={{
        fontFamily: "Avenir-Medium",
        fontSize: 10
      }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default ({ stats }) => {
  const StatsTab = [];
  if (!isEmpty(stats.items)) {
    StatsTab.push({
      name: "Approved",
      value: get(stats, "items.factoring_approved", 0),
      color: colors.green
    });
    StatsTab.push({
      name: "Process",
      value: get(stats, "items.factoring_in_process", 0),
      color: colors.secondary
    });
    StatsTab.push({
      name: "Pending",
      value: get(stats, "items.factoring_pending", 0),
      color: colors.primary
    });
  }
  const chart = (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          isAnimationActive={false}
          data={StatsTab}
          labelLine={false}
          label={renderCustomizedLabel}
          cx="50%"
          cy="50%"
          outerRadius={43}
          fill="#8884d8"
          dataKey="value"
        >
          {StatsTab.map(entry => (
            <Cell fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
  return (
    <StatsCard
      title="Applications"
      description={
        <Grid container item xs={12} spacing="32">
          <Grid container xs={2} alignItems="center">
            <div
              style={{ height: 5, width: 30, backgroundColor: colors.primary }}
            />
          </Grid>
          <Grid
            container
            xs={10}
            style={{ paddingLeft: 5 }}
            alignItems="center"
            justify="flex-start"
          >
            <Typography variant="caption">Pending</Typography>
          </Grid>
          <Grid container xs={2} alignItems="center">
            <div
              style={{
                height: 5,
                width: 30,
                backgroundColor: colors.secondary
              }}
            />
          </Grid>
          <Grid
            container
            xs={10}
            style={{ paddingLeft: 5 }}
            alignItems="center"
            justify="flex-start"
          >
            <Typography variant="caption">Process</Typography>
          </Grid>
          <Grid container xs={2} alignItems="center">
            <div
              style={{ height: 5, width: 30, backgroundColor: colors.green }}
            />
          </Grid>
          <Grid
            container
            xs={10}
            style={{ paddingLeft: 5 }}
            alignItems="center"
            justify="flex-start"
          >
            <Typography variant="caption">Approved</Typography>
          </Grid>
        </Grid>
      }
      chart={chart}
    />
  );
};

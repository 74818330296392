import { Action, createAction } from "redux-starter-kit";
import { ThunkAction } from "redux-thunk";
import { ThunkContext } from "../../types";
import {
  FETCH_TOKEN_FAILURE,
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_SUCCESS
} from "../types";

interface User {
  email: string;
  password: string;
}

function fetchUserToken(
  user: User
): ThunkAction<Promise<void>, any, ThunkContext, Action<any>> {
  return async (dispatch, getState, { api }): Promise<void> => {
    try {
      dispatch(
        createAction(FETCH_TOKEN_REQUEST)({
          email: user.email
        })
      );
      const response = await api.user.fetchToken(user.email, user.password);
      dispatch(createAction(FETCH_TOKEN_SUCCESS)(response));
    } catch (err) {
      dispatch(createAction(FETCH_TOKEN_FAILURE)(err));
      throw err;
    }
  };
}

// @ts-ignore
export default fetchUserToken;

export default (id: string) => [
  {
    label: "Profile",
    route: `/admin/companies/${id}/profile`,
    value: "profile"
  },
  {
    label: "Connected Carriers",
    route: `/admin/companies/${id}/connected_carriers`,
    value: "connected_carriers"
  },
  {
    label: "Purchases",
    route: `/admin/companies/${id}/purchases`,
    value: "purchases"
  }
];

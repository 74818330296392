import React, { ReactNode, useState } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  createStyles,
  WithStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = createStyles({
  summaryText: {
    width: "100%"
  },
  detailsText: {
    opacity: 0.8,
    width: "100%"
  }
});

interface Props extends WithStyles<typeof styles> {
  details: ReactNode;
  summary: ReactNode;
  expanded?: boolean;
}

const ExpandableListItem = ({
  classes,
  details,
  summary,
  expanded
}: Props): JSX.Element => {
  const [state, setstate] = useState(expanded);
  return (
    <ExpansionPanel
      expanded={state}
      onChange={(event: object, expandedValue: boolean) =>
        setstate(expandedValue)
      }
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          classes={{
            root: classes.summaryText
          }}
          gutterBottom
          variant="subtitle1"
        >
          {summary}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography
          classes={{
            root: classes.detailsText
          }}
          gutterBottom
          component="div"
        >
          {details}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default withStyles(styles)(ExpandableListItem);

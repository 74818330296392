import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { get } from "lodash";

import Table from "../../../../components/Table";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import MasterStar from "../../../../components/icons/MasterStar";

export default ({
  tabs,
  navIndex,
  handleRoute,
  columns,
  getRows,
  getCount,
  getPage,
  getRowsPerPage,
  handleChangePage,
  getRowsPerPageOptions,
  handleChangeRowsPerPage,
  isSelected,
  allSelected,
  handleSelect,
  handleSelectAllClick,
  dialog,
  closeDialog,
  deleteDebtor,
  companyName,
  handleTransferToMaster,
  master,
  confirmTransferToMaster
}) => (
  <Can
    perform="admin-debtor-payment:view"
    yes={() => (
      <InternalLayout title="Similar Debtors">
        <Grid
          container
          style={{ paddingBottom: "24px" }}
          justify="space-between"
        >
          <Grid item>
            <Grid container spacing={16}>
              <Grid item>{master === true && <MasterStar />}</Grid>
              <Grid item>
                <Typography variant="h6">{companyName}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={32}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleTransferToMaster()}
                >
                  Transfer
                </Button>
              </Grid>
              <Grid item>
                <DeleteButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <NavigationTabs
          tabs={tabs}
          value={navIndex}
          handleRoute={handleRoute}
        />
        <Paper style={{ marginTop: "32px" }}>
          <Table
            columns={columns}
            rows={getRows()}
            select
            isSelected={isSelected}
            allSelected={allSelected}
            handleSelect={handleSelect}
            handleSelectAllClick={handleSelectAllClick}
            count={getCount()}
            page={getPage()}
            rowsPerPage={getRowsPerPage()}
            handleChangePage={handleChangePage}
            rowsPerPageOptions={getRowsPerPageOptions()}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            allowEmpty
          />
        </Paper>
        {dialog.variant === "confirm" && (
          <ConfirmDialog
            open={dialog.show}
            message={dialog.message}
            handleConfirm={async () => {
              deleteDebtor(get(dialog, "data.id")).finally(() => {
                closeDialog();
              });
            }}
            handleExit={() => closeDialog()}
          />
        )}
        {dialog.action === "transfer-debtor" && (
          <ConfirmDialog
            open={dialog.show}
            message={dialog.message}
            handleConfirm={() => {
              confirmTransferToMaster();
              closeDialog();
            }}
            handleExit={() => closeDialog()}
          />
        )}
      </InternalLayout>
    )}
    no={() => <Redirect to="/" />}
  />
);

const tabs = [
  {
    label: "All Users",
    route: "/admin/users",
    value: ""
  },
  {
    label: "My Users",
    route: "/admin/users/my_users",
    value: "assigned_admin"
  }
];

export default tabs;

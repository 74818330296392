import { connect } from "react-redux";
import { compose, lifecycle, withProps, withStateHandlers } from "recompose";
import { find, findIndex, merge } from "lodash";

import { getCurrentUserId } from "../../../modules/auth";
import {
  createUsersCSV,
  getCount,
  getFilters,
  getIsLoading,
  getPage,
  getRowsPerPage,
  getRowsPerPageOptions,
  getSortBy,
  getSortDirection,
  getUsers,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter,
  handleSort,
  queryFetchUsers
} from "../../../modules/users";

import tabs from "./tabs";
import columns from "./columns";
import PureUsers from "./Users";

export { default as Users } from "./Users";

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  sortDirection: getSortDirection(state),
  sortBy: getSortBy(state),
  count: getCount(state),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  rowsPerPageOptions: getRowsPerPageOptions(state),
  users: getUsers(state),
  currentUserId: getCurrentUserId(state),
  filters: getFilters(state)
});

const mapDispatchToProps = {
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchFilter,
  createUsersCSV,
  queryFetchUsers
};

const filtersValues = {
  has_active_subscription: false
};

export default compose(
  withStateHandlers(
    ({
      has_active_subscription = false,
      equipment_types = "",
      user_type = ""
    }) => ({
      has_active_subscription,
      equipment_types,
      user_type
    }),
    {
      setActiveSubscription: () => has_active_subscription => ({
        has_active_subscription
      }),
      setEquipmentTypes: () => equipment_types => ({ equipment_types }),
      setUserType: () => user_type => ({ user_type })
    }
  ),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      filters,
      createUsersCSV
    }) => ({
      tabs,
      columns,
      statusIndex: findIndex(tabs, tab => pathname === tab.route),
      filterStatus: (find(tabs, tab => pathname === tab.route) || {}).value,
      handleRoute: route => push(route),
      handleCSV: () => createUsersCSV(filters),
      filtersValues
    })
  ),
  lifecycle({
    componentDidMount() {
      const {
        queryFetchUsers,
        rowsPerPage,
        currentUserId,
        filterStatus
      } = this.props;
      const adminFilter = {};
      if (filterStatus === "assigned_admin") {
        adminFilter.assigned_admin = currentUserId;
      }
      queryFetchUsers("-time_posted", rowsPerPage, 0, adminFilter);
    },
    componentDidUpdate(prevProps) {
      const {
        filterStatus,
        queryFetchUsers,
        currentUserId,
        filters
      } = this.props;
      const adminFilter = {};
      if (filterStatus === "assigned_admin") {
        adminFilter.assigned_admin = currentUserId;
      }
      if (filterStatus !== prevProps.filterStatus) {
        queryFetchUsers(
          filterStatus,
          "-time_posted",
          this.props.rowsPerPage,
          0,
          merge({}, filters, adminFilter)
        );
      }
    }
  })
)(PureUsers);

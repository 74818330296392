import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import Notes from "./Notes";
import {
  updateUserNotes,
  createUserNotes,
  deleteUserNotes,
  getUserNotes,
  loadUserNotesAttachments,
  queryFetchNotes
} from "../modules/userNotes";
import {
  openSnackbar,
  getDialog,
  closeDialog,
  openDialog
} from "../modules/ui";
import ConfirmDialog from "./dialogs/ConfirmDialog";

interface LoadAttachmentPayload {
  attachmentId: string;
  attachment: string;
}

interface Props {
  userId: string;
}

export default function InternalUserNotes({ userId }: Props): JSX.Element {
  const dispatch = useDispatch();
  const notes = useSelector(getUserNotes(userId));
  const dialog = useSelector(getDialog);

  useEffect(() => {
    if (userId) {
      dispatch(queryFetchNotes(userId));
    }
  }, [userId]);

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialStatus="CREATE"
        initialValues={{
          note: "",
          attachments: undefined,
          notesID: ""
        }}
        onSubmit={async (
          values,
          { setSubmitting, resetForm }
        ): Promise<void> => {
          try {
            if (values.notesID !== "") {
              await dispatch(
                updateUserNotes(
                  userId,
                  values.notesID,
                  values.note,
                  values.attachments
                )
              );
              dispatch(openSnackbar("success", "Updated Note!"));
            } else {
              await dispatch(
                createUserNotes(userId, values.note, values.attachments)
              );
              dispatch(openSnackbar("success", "Created Note!"));
            }
          } catch (err) {
            dispatch(openSnackbar("error", err.message || err));
          } finally {
            setSubmitting(false);
            resetForm();
          }
        }}
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          status,
          setStatus,
          isSubmitting
        }): JSX.Element => {
          return (
            <Notes
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              handleSubmit={handleSubmit}
              isCreatingNote={isSubmitting}
              notes={notes}
              isFetching={false}
              notesAttachments={{}}
              isUpdatingNote={isSubmitting}
              loadNotesAttachments={({
                attachmentId,
                attachment
              }: LoadAttachmentPayload): Promise<string> => {
                // @ts-ignore
                return dispatch(
                  loadUserNotesAttachments({
                    attachmentId,
                    attachment
                  })
                );
              }}
              handleDeleteNote={async id => {
                dispatch(
                  openDialog(
                    "confirm",
                    "Confirm Delete",
                    "Are you sure you want to delete this Notes?",
                    {
                      notesId: id
                    },
                    "delete_user_note"
                  )
                );
              }}
              status={status}
              setStatus={setStatus}
            />
          );
        }}
      </Formik>
      {dialog.variant === "confirm" && dialog.action === "delete_user_note" && (
        <ConfirmDialog
          open={dialog.show}
          message={dialog.message}
          handleConfirm={(): void => {
            try {
              dispatch(
                deleteUserNotes(userId, get(dialog, ["data", "notesId"]))
              );
              dispatch(queryFetchNotes(userId));
              dispatch(closeDialog());
              dispatch(openSnackbar("success", "Deleted Note!"));
            } catch (err) {
              dispatch(openSnackbar("error", err.message || err));
            }
          }}
          handleExit={(): void => {
            dispatch(closeDialog());
          }}
        />
      )}
    </React.Fragment>
  );
}

import React, { useCallback, useState } from "react";
import { Formik } from "formik";
import { Card, Grid } from "@material-ui/core";
import get from "lodash/get";
import validationSchema from "./validation";

import Header from "../../components/Login/Header";
import ResetPasswordForm from "./ResetPasswordForm";
import API from "../../api";

function ResetPassword(props) {
  const [errorServer, setErrorServer] = useState("");
  const [success, setSuccess] = useState(false);
  const token = get(props, "match.params.token", "");

  const onSubmit = useCallback(async (fields, { setSubmitting }) => {
    setErrorServer("");
    setSuccess(false);
    try {
      const res = await API.user.confirmResetPassword({
        new_password: fields.password,
        reset_password_token: token
      });
      if (res.status === "failure") {
        setSubmitting(false);
        return setErrorServer(res.message);
      }
      setSuccess(true);
      setTimeout(() => {
        setSubmitting(false);
        props.history.push("/login");
      }, 4000);
    } catch (err) {
      setErrorServer("Error while sending email to reset your password");
    }
    setSubmitting(false);
  });

  const form = {
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    onSubmit,
    validationSchema
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      lg={3}
      style={{ margin: "auto" }}
    >
      <Card style={{ margin: 20 }}>
        <Grid item style={{ padding: 20 }}>
          <div style={{ paddingTop: "32px" }}>
            <Header />
          </div>
        </Grid>
        <Grid item style={{ padding: 20 }}>
          <Formik {...form}>
            {formik => (
              <ResetPasswordForm
                {...formik}
                errorServer={errorServer}
                authLoading={formik.isSubmitting}
                success={success}
              />
            )}
          </Formik>
        </Grid>
      </Card>
    </Grid>
  );
}

export default ResetPassword;

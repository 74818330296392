import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import {
  UPDATE_TRANSACTIONNOTE_FAILURE,
  UPDATE_TRANSACTIONNOTE_REQUEST,
  UPDATE_TRANSACTIONNOTE_SUCCESS
} from "../types";
import { addEntities } from "../../entities";
import awsImageUpload from "../../../helpers/awsImageUpload";

export default (factoringId, noteId, note, attachment) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPDATE_TRANSACTIONNOTE_REQUEST)({}));
    // const response = await api.debtors.updateNote(noteId, note, attachment);
    let filename = [];
    if (attachment && attachment.name) {
      filename = [{ filename: attachment.name }];
    }

    const response = await api.factoring.updateTransactionNote(factoringId, {
      id: noteId,
      note,
      attachments: filename
    });

    if (attachment && attachment.name) {
      await awsImageUpload(
        [attachment].map(attachment => ({
          file: attachment,
          upload_url: response.attachments[0].upload_url
        }))
      );
    }

    const data = normalize(response, schema.transactionNote);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(UPDATE_TRANSACTIONNOTE_SUCCESS)({
        [factoringId]: [data.result]
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_TRANSACTIONNOTE_FAILURE)(err));
    throw err;
  }
};

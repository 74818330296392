import { OPEN_SEND_NOA, CLOSE_SEND_NOA } from "../types";

const sendNOA = (state, { type, payload }) => {
  switch (type) {
    case OPEN_SEND_NOA:
      return {
        ...state,
        sendNOA: {
          open: true
        }
      };
    case CLOSE_SEND_NOA:
      return {
        ...state,
        sendNOA: {
          open: false
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default sendNOA;

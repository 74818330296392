import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, MenuItem } from "@material-ui/core";
import map from "lodash/map";
import get from "lodash/get";
import filter from "lodash/filter";
import { isEmpty } from "lodash";
import { sendEmailTemplate } from "../../../../../api/factoring";
import {
  fetchEmailTemplates,
  getEmailTemplates
} from "../../../../../modules/factoring";
import CustomTextField from "../../../../../components/inputs/CustomTextField";
import colors from "../../../../../theme/colors";
import ConfirmDialog from "../../../../../components/dialogs/ConfirmDialog";
import {
  closeDialog,
  openSnackbar,
  getDialog,
  openDialog
} from "../../../../../modules/ui";

export default function EmailTemplates({
  id,
  selected = [],
  fundingRequests = [],
  textFieldWidth = "2"
}) {
  const emailTemplatesMap = {
    short_payment_inquiry: "Short-Payment Inquiry",
    past_due_invoice_40: "Past Due Invoices 40+",
    past_due_invoice_60: "Past Due Invoices 60+",
    post_funding_doc_issue: "Post-Funding Doc Issue",
    past_due_invoice_10: "Past Due Invoices 10+",
    past_due_invoice_30: "Past Due Invoices 30+"
  };
  const [emailTemplate, setEmailTemplate] = useState("");
  const dispatch = useDispatch();

  const handleEmailTemplates = useCallback(
    async (emailTemplateId, selectedReceivables, fundingRequestsArray) => {
      try {
        const selectedReceivablesIds = !isEmpty(fundingRequestsArray)
          ? map(
              filter(fundingRequestsArray, fundingRequest =>
                selectedReceivables.includes(fundingRequest.id)
              ),
              fundingRequest => fundingRequest.receivable_id
            )
          : selectedReceivables;
        await sendEmailTemplate(emailTemplateId, selectedReceivablesIds);
        dispatch(openSnackbar("success", "Email has been sent."));
        dispatch(closeDialog());
      } catch (e) {
        dispatch(openSnackbar("error", e));
      }
    },
    [dispatch]
  );
  const handleCloseDialog = useCallback(args => dispatch(closeDialog()), []);
  const openConfirmDialog = useCallback(
    args =>
      dispatch(
        openDialog(
          "confirm",
          "",
          "Are you sure you want to send emails for the selected receivables?",
          {},
          "confirm_email_template"
        )
      ),
    []
  );

  useEffect(() => dispatch(fetchEmailTemplates(id)), [id]);

  const dialog = useSelector(getDialog);
  const emailTemplates = map(useSelector(getEmailTemplates(id)), template => ({
    label: emailTemplatesMap[template.name],
    value: template.id,
    ...template
  }));

  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
      alignContent="center"
      style={{ paddingBottom: "32px" }}
      spacing={16}
    >
      <Grid item xs={textFieldWidth}>
        <CustomTextField
          fullWidth
          select
          label="Emails"
          name="email_template"
          onChange={event => setEmailTemplate(event.target.value)}
          value={emailTemplate}
        >
          {emailTemplates.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </CustomTextField>
      </Grid>
      <Grid item>
        <Button
          style={{
            backgroundColor: "#0027b3",
            color: colors.white
          }}
          mini
          onClick={openConfirmDialog}
          variant="contained"
          color="primary"
          disabled={selected.length === 0 && emailTemplate}
        >
          Send
        </Button>
      </Grid>
      {dialog.variant === "confirm" &&
        dialog.action === "confirm_email_template" && (
          <ConfirmDialog
            open={dialog.show}
            message={dialog.message}
            handleConfirm={() =>
              handleEmailTemplates(emailTemplate, selected, fundingRequests)
            }
            handleExit={handleCloseDialog}
          />
        )}
    </Grid>
  );
}

import React, { useCallback } from "react";
import {
  Button,
  colors,
  DialogActions,
  DialogContent,
  Grid
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { DialogTitle, Dialog } from "../../../components/Dialog";
import UploadFile from "../../../components/UploadFile";
import { closeDialog } from "../../../modules/ui";
import { getFiles } from "../../../modules/upload";

export default function({
  open,
  paymentProfileId,
  factoringId,
  isInvitedCarrier,
  handleConfirm
}) {
  const dispatch = useDispatch();
  const files = useSelector(state => getFiles(state, "payment"));

  const handleClose = useCallback(async args => dispatch(closeDialog()));
  const uploadFileProps = {
    requestId: factoringId,
    categoriesProps: [{ value: "voided_check", text: "Void Check" }],
    ...(() =>
      isInvitedCarrier
        ? {
            requestId: paymentProfileId,
            type: "payment",
            categoriesProps: ["Void Check", "NOA"]
          }
        : {})(),
    single: true
  };
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle onClose={handleClose}>Add Documents</DialogTitle>
      <DialogContent>
        <Grid container spacing={16} style={{ padding: 20 }}>
          <Grid item xs={12}>
            <UploadFile {...uploadFileProps} />
          </Grid>
        </Grid>
      </DialogContent>
      {files.length > 0 && (
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleClose();
              handleConfirm();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

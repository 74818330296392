import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "./components/Header";
import tabs from "./Tabs";
import UserTabs from "./UserTabs";
import PendingInvites from "./PendingInvites";
import ConnectedGroups from "./Groups";
import API from "../../../api";
import { fetchPendingInvite } from "../../../modules/groups";

export default function() {
  const [pendingInviteCounts, setPendingInviteCounts] = useState(0);
  useEffect(() => {
    const asyncFunction = async () => {
      const response = await API.groups.getPendingInvite();
      setPendingInviteCounts((response || []).length);
    };
    asyncFunction();
  }, []);

  const Components = {
    0: <PendingInvites />,
    1: <ConnectedGroups />
  };
  return (
    <>
      <Header />
      <Grid container xs={12} style={{ padding: 40, width: "100%" }}>
        <Paper style={{ marginTop: 32, padding: 16, width: "100%" }}>
          <UserTabs
            tabs={tabs(pendingInviteCounts)}
            variant="fullWidth"
            Components={Components}
          />
        </Paper>
      </Grid>
    </>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withProps } from 'recompose';
import { findIndex, get, head, isEmpty, reverse } from 'lodash';

import { Grid } from '@material-ui/core';
import formatDate from '../../../../helpers/format/formatDate';
import formatPennies from '../../../../helpers/format/formatPennies';
import { logout } from '../../../../modules/auth';
import withTabs from '../../../../components/withTabs';
import withSelect from '../../../../components/withSelect';
import {
  createFundingRequestCSV,
  fetchFactoringProfile,
  getFactorClient,
  getFundingRequests,
  getFundingRequestsCount,
  getFundingRequestsFilters,
  getFundingRequestsIsLoading,
  getFundingRequestsPage,
  getFundingRequestsRowsPerPage,
  getFundingRequestsRowsPerPageOptions,
  getFundingRequestsSortBy,
  getFundingRequestsSortDirection,
  handleFundingRequestsChangePage,
  handleFundingRequestsRowsPerPage,
  handleFundingRequestsSearchFilter,
  handleFundingRequestsSort,
  queryFundingRequests
} from '../../../../modules/factoringClient';

import PureFundingRequests from './FundingRequests';

import tabs from '../tabs';
import MoreLinks from './components/MoreLinks';
import InvoiceRow from '../../../../components/InvoiceRow';
import TransactionStatus from '../../../../components/TransactionStatus';
import NegativeMarginIcon from '../../../../components/icons/NegativeMarginIcon';
import SelfFinanceIcon from '../../../../components/icons/SelfFinanceIcon';
import formatStatus from '../../../../helpers/format/formatStatus';

export const FundingRequests = PureFundingRequests;

const columns = [
  {
    key: 'factoring.company_profile.name',
    name: 'Client'
  },
  {
    key: 'debtor.dba',
    name: 'Debtor',
    sort: 'debtor',
    filter: 'text',
    filter_key: 'bill_to_company_name'
  },
  {
    key: 'amount',
    name: 'Invoice Amnt.'
  },
  {
    key: 'invoice_number',
    name: 'Invoice #',
    filter: 'text',
    filter_key: 'invoice_number',
    sort: true
  },
  {
    key: 'user_load_number',
    name: 'Load #',
    filter: 'text',
    filter_key: 'user_load_number',
    sort: true
  },
  {
    key: 'created',
    name: 'Requested At',
    sort: true
  },
  {
    key: 'assigned_admin.name',
    name: 'Transaction Rep',
    sort: 'assigned_admin'
  },
  {
    key: 'amount_funded',
    name: 'Amnt. Funded',
    sort: true
  },
  {
    key: 'client_reserve_negative_active',
    name: 'Reserve',
    format: formatPennies
  },
  {
    key: 'status',
    name: 'Status',
    filter_key: 'status',
    filter: 'dropdown',
    filter_value: '',
    filter_values: [
      { label: 'New (NOA)', value: 'new_noa' },
      { label: 'New', value: 'new' },
      { label: 'Fuel Advance', value: 'fuel_advance' },
      { label: 'Pending', value: 'pending' },
      { label: 'Pending (NOA)', value: 'pending_noa' },
      { label: 'Pending Originals', value: 'pending_originals' },
      { label: 'Document Issue', value: 'document_issue' },
      { label: 'CF Review', value: 'haulpay_review' },
      { label: 'Document Review', value: 'document_review' },
      { label: 'Approved', value: 'approved' },
      { label: 'Incomplete', value: 'incomplete' },
      { label: 'Remote Approved', value: 'remote_approved' },
      { label: "Hold", value: "hold" },
      { label: 'Declined', value: 'declined' },
      { label: 'Paid', value: 'paid' },
      { label: 'Non-Factored', value: 'non_factored' },
      { label: 'Deleted', value: 'deleted' },
      { label: 'New - Fast Track', value: 'new_fasttrack' }
    ]
  },
  {
    key: "contract_type",
    name: "Contract Type",
    format: formatStatus,
    filter_key: "contract_type",
    filter: "dropdown",
    filter_value: "",
    filter_values: [
        { label: "Standard Carrier Funding Request", value: "STD_CARRIER" },
        { label: "Standard Broker Funding Request", value: "STD_BROKER" },
        { label: "Non Factored Funding Request", value: "NON_FACTORED_STANDARD" },
        { label: "Non Factored Bill Out Funding Request", value: "NON_FACTORED_BILL_OUT" },
        { label: "Payment Forward", value: "NON_FACTORED_PAYMENT_FORWARD" },
        { label: "Self Finance Funding Request without invoice", value: "SELF_FINANCE_NO_INVOICE" },
        { label: "Self Finance Funding Request with invoice", value: "SELF_FINANCE_WITH_INVOICE" },
        { label: "Self Finance Ledger Deposit", value: "SELF_FINANCE_LEDGER_DEPOSIT" },
        { label: "Self Finance Stripe Deposit", value: "SELF_FINANCE_STRIPE_DEPOSIT" }
    ]
  },
  {
    key: 'more',
    name: 'More'
  }
];

const mapStateToProps = state => ({
  loading: getFundingRequestsIsLoading(state),
  count: getFundingRequestsCount(state),
  page: getFundingRequestsPage(state),
  rowsPerPage: getFundingRequestsRowsPerPage(state),
  rowsPerPageOptions: getFundingRequestsRowsPerPageOptions(state),
  sortDirection: getFundingRequestsSortDirection(state),
  sortBy: getFundingRequestsSortBy(state),
  filters: getFundingRequestsFilters(state),
  fundingRequests: getFundingRequests(state),
  factorClient: getFactorClient(state)
});

const mapDispatchToProps = {
  handleLogout: logout,
  handleSort: handleFundingRequestsSort,
  handleChangePage: handleFundingRequestsChangePage,
  handleRowsPerPage: handleFundingRequestsRowsPerPage,
  handleFilterChange: handleFundingRequestsSearchFilter,
  queryFundingRequests,
  createFundingRequestCSV,
  fetchFactoringProfile
};

export default compose(
  withSelect,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      match: {
        params: { id }
      },
      fundingRequests,
      selected,
      createFundingRequestCSV,
      factorClient
    }) => ({
      factorClient,
      tabs: tabs(id, factorClient.allow_self_finance_fr),
      navIndex: findIndex(tabs(id), tab => pathname === tab.route),
      handleRoute: route => push(route),
      id,
      columns,
      handleCSV: () => createFundingRequestCSV(selected),
      fundingRequests: fundingRequests.map(fundingRequest => ({
        ...fundingRequest,
        amount: (
          <Grid container direction="row" alignItems="center">
            {formatPennies(fundingRequest.amount)}
            <Grid item>
              <NegativeMarginIcon
                factoringAmount={fundingRequest.factoring_amount}
              />
              <SelfFinanceIcon contractType={fundingRequest.contract_type} />
            </Grid>
          </Grid>
        ),
        amount_funded: formatPennies(fundingRequest.amount_funded),
        status: (
          <TransactionStatus
            status={fundingRequest.status}
            modified={fundingRequest.modified}
            statusTimeline={fundingRequest.status_timeline}
            newAttachments={get(
              head(reverse(fundingRequest.attachments)),
              'after_issue'
            )}
          />
        ),
        created: formatDate(fundingRequest.created),
        invoice_number: (
          <InvoiceRow
            discountRate={fundingRequest.discount_rate}
            invoiceNumber={fundingRequest.invoice_number}
            paymentMethod={fundingRequest.payment_method}
          />
        ),
        more: () => <MoreLinks id={fundingRequest.id} push={push} />
      }))
    })
  ),
  lifecycle({
    async componentDidMount() {
      const { id, factorClient } = this.props;
      await this.props.queryFundingRequests(
        id,
        '-created',
        this.props.rowsPerPage,
        0
      );
      if (isEmpty(factorClient)) {
        await this.props.fetchFactoringProfile(id);
      }
    }
  }),
  withTabs
)(PureFundingRequests);

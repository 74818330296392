import React, { useCallback } from "react";
import { Fab, FormHelperText, Grid, Typography } from "@material-ui/core";
import { Formik } from "formik";
import Icon from "@material-ui/core/Icon";

import BoldInput from "../../../../../components/inputs/BoldInput";
import {
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../components/Dialog";
import AutoCompleteAddress from "../../../../../components/inputs/AutoCompleteAddress";
import PhoneInput from "../../../../../components/inputs/PhoneInput";
import colors from "../../../../../theme/colors";
import factorCompanyAPI from "../../../../../api/factorCompany";

const AddFactoringCompanyForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  setFieldTouched
}) => (
  <Grid container direction="column" spacing={24}>
    <Grid item container justify="center">
      <Typography variant="body2">
        Please enter the information for your factoring company below.
      </Typography>
    </Grid>
    <Grid item>
      <BoldInput
        placeholder="Enter the company DBA"
        label="DBA"
        name="name"
        fullWidth
        helperText={
          Boolean(errors.name && touched.name) ? (
            <div dangerouslySetInnerHTML={{ __html: errors.name }} />
          ) : null
        }
        error={Boolean(errors.name && touched.name)}
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Grid>
    <Grid item>
      <BoldInput
        placeholder="Enter the company Email"
        label="Company Email"
        name="email"
        fullWidth
        helperText={
          Boolean(errors.email && touched.email) ? (
            <div dangerouslySetInnerHTML={{ __html: errors.email }} />
          ) : null
        }
        error={Boolean(errors.email && touched.email)}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Grid>
    <Grid item>
      <BoldInput
        error={Boolean(errors.phone_number && touched.phone_number)}
        placeholder="Enter the company Phone"
        label="Company Phone"
        fullWidth
        value={values.phone_number}
        name="phone_number"
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{
          inputComponent: PhoneInput
        }}
        helperText={
          Boolean(errors.phone_number && touched.phone_number)
            ? errors.phone_number
            : null
        }
      />
    </Grid>
    <Grid item>
      <AutoCompleteAddress
        value={values.address}
        name="address"
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        hasError={Boolean(errors.address && touched.address)}
        placeholder="Enter the company Address"
      />
      {Boolean(errors.address && touched.address) && (
        <FormHelperText error id="component-error-text">
          {errors.address}
        </FormHelperText>
      )}
    </Grid>
    <Grid item justify="flex-end">
      <Grid container direction="row-reverse">
        <Fab
          aria-label="Continue"
          color="primary"
          onClick={handleSubmit}
          style={{ marginTop: 30, justifySelf: "flex-end" }}
        >
          <Icon style={{ color: colors.white }}>check</Icon>
        </Fab>
      </Grid>
    </Grid>
  </Grid>
);

export default function AddFactoringCompanyModal({
  open,
  handleExit,
  setSelectedFactorCompany
}) {
  const onSubmit = useCallback(async fields => {
    try {
      const res = await factorCompanyAPI.createFactorCompany({
        ...fields,
        ...fields.address
      });
      setSelectedFactorCompany({
        ...res,
        label: res.name
      });
      handleExit();
    } catch (e) {}
  }, []);
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={() => handleExit()}>
        Add Factoring Company
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone_number: "",
            address: ""
          }}
          onSubmit={onSubmit}
          component={AddFactoringCompanyForm}
        />
      </DialogContent>
    </Dialog>
  );
}

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
    CREATE_CHECK_ACCOUNT_FAILURE,
    CREATE_CHECK_ACCOUNT_REQUEST,
    CREATE_CHECK_ACCOUNT_SUCCESS
} from "../types";

const createWireAccount = (
    mailingAddress,
    user,
    name,
) => async (dispatch, getState, { api, schema }) => {
    try {
        dispatch(createAction(CREATE_CHECK_ACCOUNT_REQUEST)());
        const account = await api.vgs.createAccountByCheck(
            mailingAddress,
            user,
            name,
        );

        const data = normalize(account, schema.accounts);
        dispatch(addEntities(data.entities));
        dispatch(createAction(CREATE_CHECK_ACCOUNT_SUCCESS)(account));
        return account;
    } catch (err) {
        dispatch(createAction(CREATE_CHECK_ACCOUNT_FAILURE)(err));
        throw err;
    }
};

export default createWireAccount;

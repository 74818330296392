import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

export interface CropToolProps {
  image: File | string  | Blob | undefined;
}
export interface RefProps {
  rotateAngle: (args: number) => void;
  getCropData: () => File | null;
}

export const CropTool = forwardRef((props: CropToolProps, ref) => {
  const [cropper, setCropper] = useState<Cropper>();
  const [degree, setDegree] = useState(0);

  useImperativeHandle(ref, () => ({
    rotateAngle: (angle: number) => {
      if (typeof cropper !== 'undefined') {
        setDegree(angle + degree);
        cropper.rotateTo(degree);
      }
    },
    getCropData: async (): Promise<string> => {
      if (typeof cropper !== 'undefined') {
        const croppedFile = cropper.getCroppedCanvas().toDataURL();
        return croppedFile;
      }
      return '';
    },
  }));

  return (
    <div tw="min-h-full m-8">
      {props.image && (
        <Cropper
          style={{ height: 400, width: '100%' }}
          zoomTo={0.5}
          initialAspectRatio={1}
          // @ts-ignore
          src={props.image}
          viewMode={2}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={true}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
      )}
    </div>
  );
});

export default CropTool;

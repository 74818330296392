import { denormalize } from "normalizr";
import { connectedCarrier } from "../../../api/schema";

const getConnectedClients = state => {
  try {
    const items = state.factorCompany.connectedClients.ids;

    const denormalized = denormalize(
      { items },
      { items: [connectedCarrier] },
      state.entities
    );

    return denormalized.items;
  } catch (err) {
    return [];
  }
};

export default getConnectedClients;

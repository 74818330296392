import React, { Component } from "react";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

import DataList from "./TableList";
import DataTable from "./Table";

const styles = {
  root: {
    width: "100%"
  }
};

class ResponsiveTable extends Component {
  render() {
    const { classes, rows } = this.props;
    return (
      <div className={classes.root}>
        {/* DESKTOP BIG TABLE */}

        <Hidden only={["xs", "sm", "md"]} implementation="js">
          <DataTable {...this.props} />
        </Hidden>

        {/* MOBILE EXPANDABLE LIST OF CARDS */}

        <Hidden only={["lg", "xl"]} implementation="js">
          <DataList {...this.props} data={rows} />
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(ResponsiveTable);

export default {
  paid_carriers: "Paid Carriers",
  paid_brokers: "Paid Broker",
  paid_shippers: "Paid Shippers",
  free_carriers: "Free Carriers",
  free_brokers: "Free Brokers",
  free_shippers: "free_shippers",
  shipper_awarded_bids: "Shipper Loads Awarded",
  broker_awarded_bids: "Broker Loads Awarded",
  total_shipper_loads: "Shipper Loads Posted",
  total_broker_loads: "Broker Loads Posted",
  total_dispatched_count: "Total Loads Dispatched",
  total_dispatched_amount: "Total Dispatch Revenue",
};

import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { find, get, isEmpty } from "lodash";
import Header from "../../components/Header";
import Bank from "../../components/Bank";
import Thanks from "../../components/Thanks";
import colors from "../../theme/colors";
import api from "../../api";
import { getDialog } from "../../modules/ui";
import UploadAttachments from "./components/UploadAttachments";
import { getCurrentUser } from "../../modules/auth";

const bankAccount = ({
  plaidError,
  plaidIsLoading,
  exchangePlaidToken,
  bankUpdated,
  openDialog,
  setBank,
  classes
}) => {
  const params = useParams();
  const user = useSelector(getCurrentUser) || {};
  const isFactoring = user.factoring_approved !== "approved";
  const isIC = get(params, "target") === "payment_profile";

  const dialog = useSelector(getDialog);

  if (bankUpdated) {
    return (
      <Grid container spacing={16} style={{ padding: 20 }}>
        <Thanks message="Thank you for submitting your bank account info. Someone will contact you soon." />
      </Grid>
    );
  }
  return (
    <Grid container spacing={16} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12}>
        <Bank
          submitToken={exchangePlaidToken}
          loading={plaidIsLoading}
          error={plaidError}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="p"
          align="center"
          style={{
            flexDirection: "row",
            color: colors.danger
          }}
        >
          Or
        </Typography>
      </Grid>
      <Grid container justify="center" item xs={12}>
        <Button
          onClick={() => openDialog("add-voided-check-bank")}
          variant="contained"
          color="primary"
          size="medium"
          className={classes.plaidBtn}
          style={{ paddingTop: 5 }}
        >
          {isIC
            ? `Tap or Click Here to Upload a Voided Check or Your Factor's NOA`
            : `Click Here to Upload A Voided Check`}
        </Button>
      </Grid>
      <Grid container justify="center" item xs={12}>
        <Typography variant="caption" style={{ color: colors.danger }}>
          Please enter your bank information for us to accelerate your
          application
        </Typography>
      </Grid>
      {dialog.variant === "add-voided-check-bank" && (
        <UploadAttachments
          isInvitedCarrier={isIC}
          factoringId={user.factoring_id}
          open={dialog.show}
          paymentProfileId={user.payment_profile_id}
          handleConfirm={() => setBank(true)}
        />
      )}
    </Grid>
  );
};
const styles = theme => ({
  plaidBtn: {
    borderRadius: 25,
    width: 250
  },
  plaidLinkBtn: {
    backgroundColor: "transparent",
    width: "100%",
    borderColor: "transparent",
    color: "white",
    fontSize: 14
  }
});
export default withStyles(styles)(bankAccount);

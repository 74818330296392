import { compose, lifecycle, mapProps, withState } from "recompose";
import { connect } from "react-redux";
import includes from "lodash/includes";
import get from "lodash/get";
import {
  exchangePlaidToken,
  getExchangeLoading,
  getExchangePlaid,
  getExchangePlaidError,
  getFactoringProfile,
  getUsedPlaid
} from "../../modules/signUp";
import { getEmail, getUserToken, logout, setEmail } from "../../modules/auth";
import {
  currentUser,
  getLoading,
  getUserProfileSuccess
} from "../../modules/user";
import { updateFactoringProfile } from "../../modules/factoring";
import Steps from "./components/Steps";
import mapState from "../../helpers/mapState";
import { getFiles } from "../../modules/upload";
import { getInvitePayload } from "../../modules/invited";

const mapStateToProps = mapState({
  authEmail: getEmail,
  exchangePlaidError: getExchangePlaidError,
  exchangePlaid: getExchangePlaid,
  exchangePlaidLoading: getExchangeLoading,
  user: getUserProfileSuccess,
  usedPlaid: getUsedPlaid,
  loadingUser: getLoading,
  userToken: getUserToken,
  attachments: getFiles,
  factoringProfile: getFactoringProfile,
  invitePayload: getInvitePayload
});

const mapDispatchToProps = {
  exchangePlaidToken,
  setEmail,
  logout,
  currentUser,
  updateFactoringProfile
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState("attachmentError", "setAttachmentError", ""),
  mapProps(props => ({
    ...props,
    step: get(props, "match.params.step", "bank"),
    factoringProfileId:
      get(props.factoringProfile, "id", undefined) ||
      get(props.invitePayload, "profiles.payment.id", undefined),
    pushToThanks: () => props.history.push(`/update/thanks`)
  })),
  lifecycle({
    componentDidUpdate(prevProps) {
      const plaidTokenExchanged =
        this.props.exchangePlaid !== prevProps.exchangePlaid;

      if (plaidTokenExchanged) {
        this.props.pushToThanks();
      }
    },
    async componentWillMount() {
      const { step, currentUser: getCurrentUser, userToken } = this.props;
      if (!includes(["confirm", "bank", "attach", "summary", "thanks"], step)) {
        this.props.history.push("/error");
      }
      if (step && userToken) {
        await getCurrentUser();
      } else if (!userToken && step) {
        this.props.history.push(`/login/?redirect=/update/${step}`);
      }
    }
  })
)(Steps);

import axios from "axios";
import set from "lodash/set";

import { getUserToken } from "../modules/auth";
import formatError from "../helpers/formatError";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  response => response,
  error => {
    const errorMsg = formatError(error, "Server Error.");
    return Promise.reject(errorMsg);
  }
);

const addAuthorizationToHeaders = (state, config) => {
  const userToken = getUserToken(state);

  if (!userToken) {
    localStorage.setItem('token', null)
    return config;
  }

  set(config, "headers.Content-Type", "application/json");
  set(config, "headers.Authorization", `Bearer ${userToken}`);
  localStorage.setItem('token', userToken)

  return config;
};

const authorizationProvider = store => next => action => {
  axios.interceptors.request.use(config => {
    const state = store.getState(store);
    return addAuthorizationToHeaders(state, config);
  });

  return next(action);
};

export default authorizationProvider;

import React from "react";
import { compose, lifecycle } from "recompose";
import Table from "./Table";
import colors from "../theme/colors";
import WithLocalTableComponent from "./WithLocalTableComponent";

const ExpandableView = ({ columns, data = [], isLoading, tableKey }) => {
  const WithLocalTable = WithLocalTableComponent(tableKey);
  return (
    <WithLocalTable>
      {tableProps => (
        <div style={{ width: "100%" }}>
          <Table
            columnStyle={{ color: colors.primary }}
            columns={columns}
            rows={data}
            isLoading={isLoading}
            {...tableProps}
            allowEmpty
          />
        </div>
      )}
    </WithLocalTable>
  );
};
export default compose(
  lifecycle({
    async componentWillMount() {
      const { id, clear } = this.props;
      await clear(id);
    },
    async componentDidMount() {
      const { id, fetch } = this.props;
      await fetch(id);
    }
  })
)(ExpandableView);

export const FETCH_QUERYFUNDINGREQUESTS_REQUEST =
  "FACTORINGCLIENT/FETCH_QUERYFUNDINGREQUESTS_REQUEST";
export const FETCH_QUERYFUNDINGREQUESTS_SUCCESS =
  "FACTORINGCLIENT/FETCH_QUERYFUNDINGREQUESTS_SUCCESS";
export const FETCH_QUERYFUNDINGREQUESTS_FAILURE =
  "FACTORINGCLIENT/FETCH_QUERYFUNDINGREQUESTS_FAILURE";

export const FETCH_FACTORINGCLIENT_REQUEST =
  "FACTORINGCLIENT/FETCH_FACTORINGCLIENT_REQUEST";
export const FETCH_FACTORINGCLIENT_SUCCESS =
  "FACTORINGCLIENT/FETCH_FACTORINGCLIENT_SUCCESS";
export const FETCH_FACTORINGCLIENT_FAILURE =
  "FACTORINGCLIENT/FETCH_FACTORINGCLIENT_FAILURE";

export const FETCH_FACTORINGATTACHMENTS_REQUEST =
  "FACTORINGCLIENT/FETCH_FACTORINGATTACHMENTS_REQUEST";
export const FETCH_FACTORINGATTACHMENTS_SUCCESS =
  "FACTORINGCLIENT/FETCH_FACTORINGATTACHMENTS_SUCCESS";
export const FETCH_FACTORINGATTACHMENTS_FAILURE =
  "FACTORINGCLIENT/FETCH_FACTORINGATTACHMENTS_FAILURE";

export const FETCH_USER_REQUEST = "FACTORINGCLIENT/FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FACTORINGCLIENT/FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FACTORINGCLIENT/FETCH_USER_FAILURE";

export const DELETE_ACCOUNT_REQUEST = "FACTORINGCLIENT/DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "FACTORINGCLIENT/DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "FACTORINGCLIENT/DELETE_ACCOUNT_FAILURE";

export const FETCH_PLAIDUSER_REQUEST =
  "FACTORINGCLIENT/FETCH_PLAIDUSER_REQUEST";
export const FETCH_PLAIDUSER_SUCCESS =
  "FACTORINGCLIENT/FETCH_PLAIDUSER_SUCCESS";
export const FETCH_PLAIDUSER_FAILURE =
  "FACTORINGCLIENT/FETCH_PLAIDUSER_FAILURE";

export const FETCH_RESERVEAGGREGATE_REQUEST =
  "FACTORINGCLIENT/FETCH_RESERVEAGGREGATE_REQUEST";
export const FETCH_RESERVEAGGREGATE_SUCCESS =
  "FACTORINGCLIENT/FETCH_RESERVEAGGREGATE_SUCCESS";
export const FETCH_RESERVEAGGREGATE_FAILURE =
  "FACTORINGCLIENT/FETCH_RESERVEAGGREGATE_FAILURE";

export const FETCH_CONNECTEDDEBTORS_REQUEST =
  "FACTORINGCLIENT/FETCH_CONNECTEDDEBTORS_REQUEST";
export const FETCH_CONNECTEDDEBTORS_SUCCESS =
  "FACTORINGCLIENT/FETCH_CONNECTEDDEBTORS_SUCCESS";
export const FETCH_CONNECTEDDEBTORS_FAILURE =
  "FACTORINGCLIENT/FETCH_CONNECTEDDEBTORS_FAILURE";

export const UPDATE_FACTORINGDEBTOR_REQUEST =
  "FACTORINGCLIENT/UPDATE_FACTORINGDEBTOR_REQUEST";
export const UPDATE_FACTORINGDEBTOR_SUCCESS =
  "FACTORINGCLIENT/UPDATE_FACTORINGDEBTOR_SUCCESS";
export const UPDATE_FACTORINGDEBTOR_FAILURE =
  "FACTORINGCLIENT/UPDATE_FACTORINGDEBTOR_FAILURE";

export const FETCH_DEBTORRELATIONHISTORY_REQUEST =
  "FACTORINGCLIENT/FETCH_DEBTORRELATIONHISTORY_REQUEST";
export const FETCH_DEBTORRELATIONHISTORY_SUCCESS =
  "FACTORINGCLIENT/FETCH_DEBTORRELATIONHISTORY_SUCCESS";
export const FETCH_DEBTORRELATIONHISTORY_FAILURE =
  "FACTORINGCLIENT/FETCH_DEBTORRELATIONHISTORY_FAILURE";

export const DELETE_DEBTORRELATIONHISTORY_REQUEST =
  "FACTORINGCLIENT/DELETE_DEBTORRELATIONHISTORY_REQUEST";
export const DELETE_DEBTORRELATIONHISTORY_SUCCESS =
  "FACTORINGCLIENT/DELETE_DEBTORRELATIONHISTORY_SUCCESS";
export const DELETE_DEBTORRELATIONHISTORY_FAILURE =
  "FACTORINGCLIENT/DELETE_DEBTORRELATIONHISTORY_FAILURE";

export const FETCH_CONNECTEDPROFILE_REQUEST =
  "FACTORINGCLIENT/FETCH_CONNECTEDPROFILE_REQUEST";
export const FETCH_CONNECTEDPROFILE_SUCCESS =
  "FACTORINGCLIENT/FETCH_CONNECTEDPROFILE_SUCCESS";
export const FETCH_CONNECTEDPROFILE_FAILURE =
  "FACTORINGCLIENT/FETCH_CONNECTEDPROFILE_FAILURE";

export const UPDATE_CONNECTEDPROFILESTATUS_REQUEST =
  "FACTORINGCLIENT/UPDATE_CONNECTEDPROFILESTATUS_REQUEST";
export const UPDATE_CONNECTEDPROFILESTATUS_SUCCESS =
  "FACTORINGCLIENT/UPDATE_CONNECTEDPROFILESTATUS_SUCCESS";
export const UPDATE_CONNECTEDPROFILESTATUS_FAILURE =
  "FACTORINGCLIENT/UPDATE_CONNECTEDPROFILESTATUS_FAILURE";

export const FETCH_QUERY_DEBTORS_REQUEST =
  "FACTORINGCLIENT/FETCH_QUERY_DEBTORS_REQUEST";
export const FETCH_QUERY_DEBTORS_SUCCESS =
  "FACTORINGCLIENT/FETCH_QUERY_DEBTORS_SUCCESS";
export const FETCH_QUERY_DEBTORS_FAILURE =
  "FACTORINGCLIENT/FETCH_QUERY_DEBTORS_FAILURE";

export const DELETE_FACTORCLIENTPROFILE_REQUEST =
  "FACTORINGCLIENT/DELETE_FACTORCLIENTPROFILE_REQUEST";
export const DELETE_FACTORCLIENTPROFILE_SUCCESS =
  "FACTORINGCLIENT/DELETE_FACTORCLIENTPROFILE_SUCCESS";
export const DELETE_FACTORCLIENTPROFILE_FAILURE =
  "FACTORINGCLIENT/DELETE_FACTORCLIENTPROFILE_FAILURE";

export const FETCH_ACCOUNTS_REQUEST = "FACTORINGCLIENT/FETCH_ACCOUNTS_REQUEST";
export const FETCH_ACCOUNTS_SUCCESS = "FACTORINGCLIENT/FETCH_ACCOUNTS_SUCCESS";
export const FETCH_ACCOUNTS_FAILURE = "FACTORINGCLIENT/FETCH_ACCOUNTS_FAILURE";

export const CREATE_CARD_ACCOUNT_REQUEST =
  "FACTORINGCLIENT/CREATE_CARD_ACCOUNT_REQUEST";
export const CREATE_CARD_ACCOUNT_SUCCESS =
  "FACTORINGCLIENT/CREATE_CARD_ACCOUNT_SUCCESS";
export const CREATE_CARD_ACCOUNT_FAILURE =
  "FACTORINGCLIENT/CREATE_CARD_ACCOUNT_FAILURE";

export const CREATE_SWIFT_ACCOUNT_REQUEST =
  "FACTORINGCLIENT/CREATE_SWIFT_ACCOUNT_REQUEST";
export const CREATE_SWIFT_ACCOUNT_SUCCESS =
  "FACTORINGCLIENT/CREATE_SWIFT_ACCOUNT_SUCCESS";
export const CREATE_SWIFT_ACCOUNT_FAILURE =
  "FACTORINGCLIENT/CREATE_DWIFT_ACCOUNT_FAILURE";

export const CREATE_BANK_ACCOUNT_REQUEST =
  "FACTORINGCLIENT/CREATE_BANK_ACCOUNT_REQUEST";
export const CREATE_BANK_ACCOUNT_SUCCESS =
  "FACTORINGCLIENT/CREATE_BANK_ACCOUNT_SUCCESS";
export const CREATE_BANK_ACCOUNT_FAILURE =
  "FACTORINGCLIENT/CREATE_BANK_ACCOUNT_FAILURE";

export const CREATE_WIRE_ACCOUNT_REQUEST =
  "FACTORINGCLIENT/CREATE_WIRE_ACCOUNT_REQUEST";
export const CREATE_WIRE_ACCOUNT_SUCCESS =
  "FACTORINGCLIENT/CREATE_WIRE_ACCOUNT_SUCCESS";
export const CREATE_WIRE_ACCOUNT_FAILURE =
  "FACTORINGCLIENT/CREATE_WIRE_ACCOUNT_FAILURE";

export const CREATE_CHECK_ACCOUNT_REQUEST =
  "FACTORINGCLIENT/CREATE_CHECK_ACCOUNT_REQUEST";
export const CREATE_CHECK_ACCOUNT_SUCCESS =
  "FACTORINGCLIENT/CREATE_CHECK_ACCOUNT_SUCCESS";
export const CREATE_CHECK_ACCOUNT_FAILURE =
  "FACTORINGCLIENT/CREATE_CHECK_ACCOUNT_FAILURE";

export const FETCH_FACTORINGNOTES_REQUEST =
  "FACTORINGCLIENT/FETCH_FACTORINGNOTES_REQUEST";
export const FETCH_FACTORINGNOTES_SUCCESS =
  "FACTORINGCLIENT/FETCH_FACTORINGNOTES_SUCCESS";
export const FETCH_FACTORINGNOTES_FAILURE =
  "FACTORINGCLIENT/FETCH_FACTORINGNOTES_FAILURE";

export const FETCH_RESERVE_REQUEST = "FACTORINGCLIENT/FETCH_RESERVE_REQUEST";
export const FETCH_RESERVE_SUCCESS = "FACTORINGCLIENT/FETCH_RESERVE_SUCCESS";
export const FETCH_RESERVE_FAILURE = "FACTORINGCLIENT/FETCH_RESERVE_FAILURE";

export const UPDATE_FACTORINGPROFILE_REQUEST =
  "FACTORINGCLIENT/UPDATE_FACTORINGPROFILE_REQUEST";
export const UPDATE_FACTORINGPROFILE_SUCCESS =
  "FACTORINGCLIENT/UPDATE_FACTORINGPROFILE_SUCCESS";
export const UPDATE_FACTORINGPROFILE_FAILURE =
  "FACTORINGCLIENT/UPDATE_FACTORINGPROFILE_FAILURE";

export const UPDATE_USER_REQUEST = "FACTORINGCLIENT/UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "FACTORINGCLIENT/UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "FACTORINGCLIENT/UPDATE_USER_FAILURE";

export const UPLOAD_ATTACHMENTS_REQUEST =
  "FACTORINGCLIENT/UPLOAD_ATTACHMENTS_REQUEST";
export const UPLOAD_ATTACHMENTS_SUCCESS =
  "FACTORINGCLIENT/UPLOAD_ATTACHMENTS_SUCCESS";
export const UPLOAD_ATTACHMENTS_FAILURE =
  "FACTORINGCLIENT/UPLOAD_ATTACHMENTS_FAILURE";

export const DELETE_ATTACHMENT_REQUEST =
  "FACTORINGCLIENT/DELETE_ATTACHMENT_REQUEST";
export const DELETE_ATTACHMENT_SUCCESS =
  "FACTORINGCLIENT/DELETE_ATTACHMENT_SUCCESS";
export const DELETE_ATTACHMENT_FAILURE =
  "FACTORINGCLIENT/DELETE_ATTACHMENT_FAILURE";

export const CREATE_FACTORINGNOTE_REQUEST =
  "FACTORINGCLIENT/CREATE_FACTORINGNOTE_REQUEST";
export const CREATE_FACTORINGNOTE_SUCCESS =
  "FACTORINGCLIENT/CREATE_FACTORINGNOTE_SUCCESS";
export const CREATE_FACTORINGNOTE_FAILURE =
  "FACTORINGCLIENT/CREATE_FACTORINGNOTE_FAILURE";

export const DELETE_FACTORINGNOTE_REQUEST =
  "FACTORINGCLIENT/DELETE_FACTORINGNOTE_REQUEST";
export const DELETE_FACTORINGNOTE_SUCCESS =
  "FACTORINGCLIENT/DELETE_FACTORINGNOTE_SUCCESS";
export const DELETE_FACTORINGNOTE_FAILURE =
  "FACTORINGCLIENT/DELETE_FACTORINGNOTE_FAILURE";

export const CREATE_FUNDINGREQUEST_REQUEST =
  "FACTORINGCLIENT/CREATE_FUNDINGREQUEST_REQUEST";
export const CREATE_FUNDINGREQUEST_SUCCESS =
  "FACTORINGCLIENT/CREATE_FUNDINGREQUEST_SUCCESS";
export const CREATE_FUNDINGREQUEST_FAILURE =
  "FACTORINGCLIENT/CREATE_FUNDINGREQUEST_FAILURE";

export const FETCH_FACTORINGPROFILEHISTORY_REQUEST =
  "FACTORINGCLIENT/FETCH_FACTORINGPROFILEHISTORY_REQUEST";
export const FETCH_FACTORINGPROFILEHISTORY_SUCCESS =
  "FACTORINGCLIENT/FETCH_FACTORINGPROFILEHISTORY_SUCCESS";
export const FETCH_FACTORINGPROFILEHISTORY_FAILURE =
  "FACTORINGCLIENT/FETCH_FACTORINGPROFILEHISTORY_FAILURE";

export const FETCH_STATS_REQUEST = "FACTORINGCLIENT/FETCH_STATS_REQUEST";
export const FETCH_STATS_SUCCESS = "FACTORINGCLIENT/FETCH_STATS_SUCCESS";
export const FETCH_STATS_FAILURE = "FACTORINGCLIENT/FETCH_STATS_FAILURE";

export const CREATE_DEBTORRELATION_REQUEST =
  "FACTORINGCLIENT/CREATE_DEBTORRELATION_REQUEST";
export const CREATE_DEBTORRELATION_SUCCESS =
  "FACTORINGCLIENT/CREATE_DEBTORRELATION_SUCCESS";
export const CREATE_DEBTORRELATION_FAILURE =
  "FACTORINGCLIENT/CREATE_DEBTORRELATION_FAILURE";

export const FACTORINGCLIENT_ATTACHMENT_COLLATED_FETCH =
  "FACTORINGCLIENT/ATTACHMENT_COLLATED_FETCH";
export const FACTORINGCLIENT_ATTACHMENT_COLLATED_SUCCESS =
  "FACTORINGCLIENT/ATTACHMENT_COLLATED_SUCCESS";
export const FACTORINGCLIENT_ATTACHMENT_COLLATED_ERROR =
  "FACTORINGCLIENT/ATTACHMENT_COLLATED_ERROR";

export const UPDATE_FACTORINGNOTE_REQUEST =
  "FACTORINGCLIENT/UPDATE_FACTORINGNOTE_REQUEST";
export const UPDATE_FACTORINGNOTE_SUCCESS =
  "FACTORINGCLIENT/UPDATE_FACTORINGNOTE_SUCCESS";
export const UPDATE_FACTORINGNOTE_FAILURE =
  "FACTORINGCLIENT/UPDATE_FACTORINGNOTE_FAILURE";

export const FETCH_TERMS_REQUEST = "FACTORINGCLIENT/FETCH_TERMS_REQUEST";
export const FETCH_TERMS_SUCCESS = "FACTORINGCLIENT/FETCH_TERMS_SUCCESS";
export const FETCH_TERMS_FAILURE = "FACTORINGCLIENT/FETCH_TERMS_FAILURE";

export const UPDATE_TERMS_REQUEST = "FACTORINGCLIENT/UPDATE_TERMS_REQUEST";
export const UPDATE_TERMS_SUCCESS = "FACTORINGCLIENT/UPDATE_TERMS_SUCCESS";
export const UPDATE_TERMS_FAILURE = "FACTORINGCLIENT/UPDATE_TERMS_FAILURE";

import React from "react";
import { Button, Grid } from "@material-ui/core";

import columns from "./columns";
import Table from "../../../../../components/Table";

interface Props {
  lineItems: any[];
  openEditLineItem: (...args: any) => void;
}

export default ({ lineItems, openEditLineItem }: Props): JSX.Element => (
  <div>
    <Table columns={columns} rows={lineItems} allowEmpty />
    <Grid container item direction="row-reverse" style={{ paddingTop: 16 }}>
      <Button
        size="small"
        color="primary"
        variant="outlined"
        onClick={(): void => openEditLineItem("", { type: "Add" })}
      >
        + New Line Item
      </Button>
    </Grid>
  </div>
);

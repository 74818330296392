import { assign } from "lodash";
import {
  FETCH_EMAIL_TEMPLATES_FAILURE,
  FETCH_EMAIL_TEMPLATES_REQUEST,
  FETCH_EMAIL_TEMPLATES_SUCCESS
} from "../types";

const fetchEmailTemplates = (state, { type, payload }) => {
  switch (type) {
    case FETCH_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        loadingEmailTemplates: true
      };
    case FETCH_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: assign({}, state.emailTemplates, payload),
        loadingEmailTemplates: false
      };
    case FETCH_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        loadingEmailTemplates: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchEmailTemplates;

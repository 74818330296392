import { denormalize } from "normalizr";
import get from "lodash/get";

import { purchasesList } from "../../../api/schema";

export default state => {
  try {
    const items = get(state, "payment.purchases.ids", []);
    const denormalized = denormalize(
      { items },
      { items: [purchasesList] },
      state.entities
    );
    return denormalized.items;
  } catch (err) {
    return [];
  }
};

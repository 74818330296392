import React from "react";
import { Grid, Typography } from "@material-ui/core";

interface ProfileHeaderProps {
    icon?: JSX.Element;
    title: string;
    links?: JSX.Element[];
    actions?: JSX.Element[];
}
  
const ProfileHeader = (props: ProfileHeaderProps): JSX.Element => {
    return (
        <Grid item style={{ width: "100%" }}>
            <Grid container direction="row" justify="space-between">
                <Grid item>
                    <Grid container direction="column">
                        <Grid container alignItems="center" spacing={8}>
                            {props.icon && (
                                <Grid item>
                                    {props.icon}
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant="h6">{props.title}</Typography>
                            </Grid>
                        </Grid>
                        {props.links && (
                        <Grid item container direction="row" spacing={8}>
                            {props.links.map(link => (<Grid item>{link}</Grid>))}
                        </Grid>
                        )}
                    </Grid>
                </Grid>
                {props.actions && (
                    <Grid item>
                        <Grid container spacing={16}>
                        {props.actions.map(action => (
                            <Grid item>
                                {action}
                            </Grid>
                        ))}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
};

export default ProfileHeader;

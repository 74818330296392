import React, { MouseEventHandler, CSSProperties, ReactNode } from "react";
import { ButtonProps } from "@material-ui/core/Button";

import CircularButton from "./CircularButton";
import Colors from "../../theme/colors";

interface Props extends ButtonProps {
  style?: CSSProperties;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  progressStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
}

const RoundedButton = ({
  children,
  style = {},
  onClick = undefined,
  loading = false,
  progressStyle = {},
  wrapperStyle = {},
  ...props
}: Props): JSX.Element => (
  <CircularButton
    style={{
      borderRadius: 20,
      color: Colors.white,
      fontSize: 14,
      fontWeight: "bold",
      ...style
    }}
    progressStyle={progressStyle}
    onClick={onClick}
    loading={loading}
    wrapperStyle={wrapperStyle}
    {...props}
  >
    {children}
  </CircularButton>
);

export default RoundedButton;

import React, { useCallback, useReducer, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { filter, get, head, indexOf, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHdd,
  faSpinner,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";

import Table from "../../../../components/Table";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import CSVButton from "../../../../components/buttons/CSVButton";
import AdjustmentsDialog from "./components/AdjustmentsDialog";
import FollowUp from "./components/FollowUp";
import MoreButton from "./components/MoreButton";
import fundingRequestReceivables from "./columns";
import NavigationTabs from "../../../../components/NavigationTabs";
import Link from "../../../../components/Link";
import ReceivablesAttachment from "./components/ReceivablesAttachment";
import PaymentsHistory from "./components/PaymentsHistory";
import NotesDialog from "../../../../components/dialogs/NotesDialog";
import TimeZone from "../../../../components/TimeZone";
import Filters from "./components/Filters";
import CustomTextField, {
  CustomPenniesField
} from "../../../../components/inputs/CustomTextField";
import ClientAutoComplete from "../../../../components/inputs/ClientAutoComplete";
import colors from "../../../../theme/colors";
import Yup from "../../../../YupValidation";
import { createPaymentForward } from "../../../../modules/debtor";
import DebtorInput from "../../../../components/inputs/DebtorInput";
import UploadBulkAttachments from "../../Debtor/Payment/components/UploadBulkAttachments";
import formatPennies from "apps/haulpay-frontend/src/helpers/format/formatPennies";
import PenniesInput from "apps/haulpay-frontend/src/components/inputs/PenniesInput";
import SelfFinanceIcon from '../../../../components/icons/SelfFinanceIcon';

const styles = {
  root: {
    background: "linear-gradient(45deg, #2EBD00 30%, #4dc527 90%)"
  },
  label: {
    fontFamily: "Avenir-Medium",
    textTransform: "capitalize",
    color: colors.white
  }
};

export default withStyles(styles)(
  ({
    statusIndex,
    handleCSV,
    selectedReceivables,
    fetchLineItems,
    receivableLineItems,
    selectedReceivable,
    setSelectedReceivable,
    createLineItem,
    open,
    email,
    handleLogout,
    handleDrawerOpen,
    handleDrawerClose,
    openUserSetting,
    receivables = [],
    isSelected,
    allSelected,
    handleSelect,
    handleSelectAllClick,
    dialog,
    closeDialog,
    openDialog,
    openModal,
    LineItemCategories,
    selected,
    createDebtorPayment,
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearchFilter,
    isLoading,
    sortDirection,
    sortBy,
    count,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    filters,
    openSnackbar,
    createPaymentsFile,
    refresh,
    userType,
    tabs,
    match,
    singleDebtorSelected,
    ...props
  }) => {
    const index = statusIndex === -1 ? 0 : statusIndex;
    const id = get(match, ["params", "id"], "");
    const formattedRows = receivables.map(row => {
      return {
        ...row,
        debtor_name: () => (
          <Grid container direction="row" alignItems="center" spacing={8}>
            <Grid item>
              <Link to={`/admin/debtors/${get(row, "debtor", "")}/profile`}>
                {get(row, "debtor_name", "")}
              </Link>
            </Grid>
            <Grid item>
              <TimeZone zone={get(row, "debtor_operating_timezone", "")} />
            </Grid>
          </Grid>
        ),
        company_name: () => (
          <Link
            to={`/admin/factorclients/${get(
              row,
              ["factoring_id"],
              ""
            )}/profile`}
          >
            {get(row, ["factoring_company_profile", "name"], "")}
          </Link>
        ),
        payment_profile_name: () => (
          <Link
            to={`/admin/payment/${get(row, "payment_profile_id", "")}/profile`}
          >
            {get(row, "payment_profile_name", "")}
          </Link>
        ),
        funding_request_invoice_number: () => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
            <div>
              {get(row, 'funding_request_invoice_number', '')}
            </div>
            <SelfFinanceIcon contractType={get(row, 'contract_type')} />
          </div>
        ),
        more: (
          <MoreButton
            receivableId={get(row, "id", "")}
            id={get(row, "debtor", "")}
            frid={get(row, ["funding_request", "id"], "")}
            status={get(row, "status")}
          />
        )
      };
    });

    const formColumn = {
      id: Math.floor(Math.random() * 999 + 1),
      invoice_number: {
        default: "#####"
      },
      charge_non_factored_fee: {
        initialValue: true
      },
      carrier: {
        initialValue: "",
        component: ({
          field,
          form: { touched, setFieldValue, handleBlur, errors },
          ...inputProps
        }) => (
          <div>
            <ClientAutoComplete
              id="client"
              {...field}
              {...inputProps}
              DropdownIndicator={false}
              onChange={carrier => {
                setFieldValue("carrier", carrier);
              }}
              hasError={touched[field.name] && errors[field.name]}
            />
          </div>
        )
      },
      amount: {
        initialValue: 0.0,
        component: ({
          field,
          form: { touched, errors, setFieldValue, handleBlur, ...form },
          ...inputProps
        }) => (
          <div style={{ paddingTop: 5 }}>
            <CustomPenniesField
              id="amount"
              {...field}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              {...inputProps}
              error={touched[field.name] && errors[field.name]}
            />
          </div>
        )
      },
      debtor_name: {
        initialValue: "",
        component: ({
          field,
          form: { touched, errors, setFieldValue, handleBlur, ...form },
          ...inputProps
        }) => (
          <div style={{ paddingBottom: 12 }}>
            <DebtorInput
              fullWidth
              customInput
              id="debtor"
              {...field}
              setFieldValue={setFieldValue}
              {...inputProps}
              {...form}
              error={touched[field.name] && errors[field.name]}
            />
          </div>
        )
      },
      amount_due: {
        initialValue: 0.0,
        component: ({
          field,
          form: { touched, errors, setFieldValue, handleBlur, values, ...form },
          ...inputProps
        }) => (
          <div style={{ paddingTop: 5 }}>
            <CustomPenniesField
              id="amount_due"
              {...field}
              setFieldValue={(...args) => {
                setFieldValue(...args);
              }}
              {...inputProps}
              error={touched[field.name] && errors[field.name]}
            />
          </div>
        )
      },
      user_load_number: {
        initialValue: "",
        component: ({ field, form: { touched, errors }, ...inputProps }) => (
          <div style={{ paddingTop: 5 }}>
            <CustomTextField
              id="user_load_number"
              {...field}
              {...inputProps}
              error={touched[field.name] && errors[field.name]}
            />
          </div>
        )
      },
      actions: ({ handleSubmit, setFieldValue, index, isSubmitting }) => {
        return (
          <Grid container style={{ minWidth: "200px" }}>
            <Grid item>
              <Tooltip title="Save">
                <IconButton
                  id="save"
                  style={{ width: "48px", height: "48px" }}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FontAwesomeIcon
                      color={colors.green_dark}
                      spin
                      icon={faSpinner}
                    />
                  ) : (
                    <FontAwesomeIcon color={colors.green_dark} icon={faHdd} />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Switch
                name="charge_non_factored_fee"
                onChange={(event, checked) =>
                  setFieldValue("charge_non_factored_fee", checked)
                }
                defaultChecked
              />
              <Typography style={{ paddingLeft: 10 }} variant="caption">
                NF Fee
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="delete">
                <IconButton
                  style={{ width: "48px", height: "48px" }}
                  onClick={() => deleteRow(index)}
                >
                  <FontAwesomeIcon color={colors.danger} icon={faTrashAlt} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      }
    };

    const initialState = {
      rowsForm: [],
      amountAdded: [],
      totalAmount: 0,
      displayBulkAttachmentsModal: false,
      displayAttachmentsModal: false,
      fundingRequestId: ""
    };

    function reducer(state, action) {
      let form = [];
      switch (action.type) {
        case "ADD_ROW":
          return { ...state, rowsForm: [formColumn, ...state.rowsForm] };
        case "OPEN_BULK_ATTACHMENT_MODAL":
          return { ...state, displayBulkAttachmentsModal: true };
        case "CLOSE_BULK_ATTACHMENT_MODAL":
          return { ...state, displayBulkAttachmentsModal: false };
        case "OPEN_ATTACHMENT_MODAL":
          return {
            ...state,
            displayAttachmentsModal: true,
            fundingRequestId: action.payload
          };
        case "CLOSE_ATTACHMENT_MODAL":
          return {
            ...state,
            displayAttachmentsModal: false,
            fundingRequestId: ""
          };
        case "DELETE_ROW":
          form = filter(state.rowsForm, row => row.id !== action.payload);
          return { ...state, rowsForm: form };
        case "DELETE_SELECTED_INDEX":
          if (indexOf(selected, action.payload) !== -1) {
            handleSelect(action.payload);
          }
          return state;
        default:
          throw new Error();
      }
    }

    const dispatch = useDispatch();
    const [status, setStatus] = useState([]);
    const [state, localDispatch] = useReducer(reducer, initialState);

    const handleAddInvoice = () => {
      localDispatch({ type: "ADD_ROW" });
    };

    const handleAddAttachments = () =>
      localDispatch({ type: "OPEN_BULK_ATTACHMENT_MODAL" });
    const handleAddAttachment = payload =>
      localDispatch({ type: "OPEN_ATTACHMENT_MODAL", payload });

    const deleteRow = index => {
      localDispatch({ type: "DELETE_ROW", payload: index });
      localDispatch({ type: "DELETE_SELECTED_INDEX", payload: index });
    };

    const formValidation = Yup.object().shape({
      amount: Yup.number().required("Please fill out this field"),
      debtor_name: Yup.object().shape({
        id: Yup.string().required("Please fill out this field")
      }),
      user_load_number: Yup.string().required("Please fill out this field")
    });

    const onSubmit = useCallback(
      async (index, fields, { setSubmitting }) => {
        try {
          setSubmitting(true);
          const res = await dispatch(
            createPaymentForward({
              debtor_id: get(fields, "debtor_name.id"),
              factoring_id: id,
              amount: fields.amount * 100,
              user_load_number: fields.user_load_number,
              charge_non_factored_fee: fields.charge_non_factored_fee,
              filters: { response_type: "factoring_receivables" }
            })
          );
          deleteRow(index);
          if (indexOf(selected, index) !== -1) {
            handleSelect(res.id);
          }
          dispatch(openSnackbar("success", "Invoice successfully added."));
          handleAddAttachment(res.id);
        } catch (err) {
          dispatch(openSnackbar("error", head(err)));
        }
        setSubmitting(false);
      },
      [selected]
    );

    const disabledSettleButton = !!filter(
      selected,
      num => typeof num === "number"
    ).length;

    return (
      <Can
        perform="admin-factorclients:view"
        yes={() => (
          <InternalLayout title="Factor Client">
            <React.Fragment>
              <div style={{ margin: 10 }}>
                <Typography variant="h5">All Receivables</Typography>
              </div>
              <NavigationTabs tabs={tabs} value={index} />
              <Paper style={{ marginTop: 32, padding: 16 }}>
                <Grid container direction="row-reverse" spacing={16}>
                  <Grid item>
                    <ReceivablesAttachment
                      isUploading={false}
                      openSnackbar={openSnackbar}
                      createPaymentsFile={createPaymentsFile}
                    />
                  </Grid>
                  <Can
                    perform="admin-finances-receivables:edit"
                    yes={() => (
                      <Grid item>
                        <CSVButton handleCSV={handleCSV} />
                      </Grid>
                    )}
                  />
                </Grid>
                <Grid container direction="row-reverse" spacing={16}>
                  <Can
                    perform="admin-finances-receivables:edit"
                    yes={() => (
                      <Grid item container spacing={16}>
                        <Grid item container justify="flex-end">
                          <Button
                            style={{
                              backgroundColor: colors.link,
                              color: colors.white
                            }}
                            mini
                            variant="contained"
                            onClick={handleAddAttachments}
                            disabled={disabledSettleButton || !selected.length}
                          >
                            Add Attachments
                          </Button>
                        </Grid>
                        <Grid item container justify="flex-end">
                          <Button
                            style={{
                              backgroundColor: colors.green_dark,
                              color: colors.white
                            }}
                            mini
                            variant="contained"
                            onClick={() => handleAddInvoice()}
                          >
                            Add Invoice
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  />
                  <Can
                    perform="admin-finances-receivables:edit"
                    yes={() => (
                      <>
                        <Grid item>
                          <Button
                            disabled={isEmpty(selected)}
                            variant="contained"
                            color="primary"
                            mini
                            style={{
                              backgroundColor: colors.green,
                              color: colors.white
                            }}
                            onClick={() =>
                              openDialog("", "", "", {}, "Adjustments")
                            }
                          >
                            Settle
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            disabled={isEmpty(selected) && singleDebtorSelected}
                            variant="contained"
                            color="primary"
                            mini
                          >
                            Submit
                          </Button>
                        </Grid>
                      </>
                    )}
                  />
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={isEmpty(selected)}
                      style={{
                        backgroundColor: colors.light_orange,
                        color: colors.white
                      }}
                      onClick={() => {
                        dispatch(openDialog("receivables-notes", "", ""));
                      }}
                      mini
                    >
                      + Add FollowUp
                    </Button>
                  </Grid>
                </Grid>
                <Filters handleSearchFilter={handleSearchFilter} {...props} />
                <Table
                  columns={fundingRequestReceivables(
                    status,
                    setStatus,
                    userType
                  )}
                  rows={formattedRows}
                  isLoading={isLoading}
                  handleSort={handleSort}
                  sortDirection={sortDirection}
                  sortBy={sortBy}
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  filter
                  handleFilterChange={handleSearchFilter}
                  allowEmpty
                  select
                  isSelected={isSelected}
                  handleSelect={handleSelect}
                  handleSelectAllClick={handleSelectAllClick}
                  allSelected={allSelected}
                  filters={filters}
                  formKeys={[
                    "amount",
                    "carrier",
                    "amount_due",
                    "user_load_number",
                    "debtor_name",
                    "charge_non_factored_fee"
                  ]}
                  rowsForm={state.rowsForm}
                  formTableProps={{
                    onSubmit,
                    validationSchema: formValidation
                  }}
                />
              </Paper>
              <UploadBulkAttachments
                open={state.displayBulkAttachmentsModal}
                handleClose={() =>
                  localDispatch({ type: "CLOSE_BULK_ATTACHMENT_MODAL" })
                }
                selected={selected}
              />
              <UploadBulkAttachments
                open={state.displayAttachmentsModal}
                handleClose={() =>
                  localDispatch({ type: "CLOSE_ATTACHMENT_MODAL" })
                }
                selected={[state.fundingRequestId]}
              />
              {dialog.action === "Adjustments" && (
                <AdjustmentsDialog
                  handleClose={closeDialog}
                  open={dialog.show}
                  openModal={openModal}
                  receivables={selectedReceivables}
                  fetchLineItems={fetchLineItems}
                  lineItems={receivableLineItems}
                  selectedReceivable={selectedReceivable}
                  setSelectedReceivable={setSelectedReceivable}
                  LineItemCategories={LineItemCategories}
                  createLineItem={createLineItem}
                  {...dialog.data}
                />
              )}
              {dialog.action === "followup" && (
                <FollowUp
                  userType={userType}
                  handleClose={closeDialog}
                  open={dialog.show}
                  openModal={openModal}
                  receivables={selectedReceivables}
                  createDebtorPayment={createDebtorPayment}
                  {...dialog.data}
                />
              )}
              {dialog.variant === "receivables-notes" && (
                <NotesDialog
                  receivableId={get(dialog, ["data", "receivableId"])}
                  selected={selected}
                  refresh={refresh}
                  userType={userType}
                />
              )}
              <PaymentsHistory />
            </React.Fragment>
          </InternalLayout>
        )}
        no={() => <Redirect to="/" />}
      />
    );
  }
);

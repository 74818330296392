import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import {
  closeDialog,
  getDialog,
  openSnackbar
} from "../../../../../../modules/ui";
import BrokerAutoComplete from "../../../../../../components/inputs/BrokerAutoComplete";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from "../../../../../../components/Dialog";
import API from "../../../../../../api";

const PaymentProfileDialog = ({ user }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [broker, setBroker] = useState({});
  const dialog = useSelector(getDialog);
  const handleExit = useCallback(() => dispatch(closeDialog()), [dispatch]);

  const handleSubmit = useCallback(async args => {
    try {
      setIsSubmitting(true);
      await API.factoring.paymentProfile.createPaymentProfileFromBrokerAdmin({
        brokerFactoringId: get(args, "user.factoring_id"),
        factoringUserId: user
      });
      dispatch(
        openSnackbar("success", "Payment Profile was successfully added")
      );
    } catch (e) {
      dispatch(openSnackbar("error", e));
    }
    setIsSubmitting(false);
  }, []);
  return (
    <React.Fragment>
      <Dialog open={dialog.show} maxWidth="sm" fullWidth>
        <DialogTitle onClose={() => handleExit()}>
          Create Payment Profile from Factoring Client Profile
        </DialogTitle>
        <DialogContent
          style={{
            height: 300,
            display: "flex",
            flexGrow: 2,
            justifyContent: "center",
            alignItems: "center",
            margin: 20
          }}
        >
          <BrokerAutoComplete
            label="Select Broker Client to Connect To"
            value={broker}
            onChange={value => setBroker(value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={isEmpty(broker) || isSubmitting}
            onClick={() => handleSubmit(broker)}
          >
            create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PaymentProfileDialog;

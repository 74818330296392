import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { get } from "lodash";
import {
  fetchDebtorRelationHistory,
  getDebtorRelationHistory
} from "../../../../../modules/debtor";
import { DialogActions, DialogTitle, Dialog, DialogContent } from "../../../../../components/Dialog";
import AuditTrail from "../../../../../components/AuditTrail";

interface Props {
  relationshipId: string;
  handleClose: () => void;
  open: boolean;
}

export default function AuditLogDialog({
  relationshipId,
  handleClose,
  open
}: Props): JSX.Element {
  const auditLog: any[] = get(
    useSelector(getDebtorRelationHistory(relationshipId)),
    "history",
    []
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (relationshipId) {
      dispatch(fetchDebtorRelationHistory(relationshipId));
    }
  }, [relationshipId]);
  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={handleClose}>Audit Log</DialogTitle>
      <DialogContent>
        <AuditTrail auditLog={auditLog} prefix="fdr_" />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

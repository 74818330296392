import React from 'react';
import formatDollars from '../../../../../helpers/format/formatDollars';
import formatDate from '../../../../../helpers/format/formatDate';
import { Link } from "react-router-dom";

const columns = [
    {
        key: 'description',
        name: 'Reason',
    },
    {
        key: ['invoice_number', 'funding_request_id'],
        name: 'Invoice#',
        format: (invoice_number: string, funding_request_id: string): JSX.Element => {
            if(!invoice_number)
                return <div/>
            let splitted = invoice_number.split("-");
            if (splitted.length > 0) {
                return <Link to={`/admin/fundingrequest/${funding_request_id}/`}>
                        {splitted[0]}
                    </Link>
            }
        return <div />
        }
    },
    {
        key: 'total_amount',
        name: 'Amount',
        format: formatDollars,
    },
    {
        key: 'created',
        name: 'Date Created',
        format: formatDate,
    },
    {
        key: 'action'
    }
   ];

export default columns;


export const UPDATE_FACTORING_PROFILE_REQUEST =
  "FACTORING/UPDATE_FACTORING_PROFILE_REQUEST";
export const UPDATE_FACTORING_PROFILE_SUCCESS =
  "FACTORING/UPDATE_FACTORING_PROFILE_SUCCESS";
export const UPDATE_FACTORING_PROFILE_FAILURE =
  "FACTORING/UPDATE_FACTORING_PROFILE_FAILURE";

export const GET_COLLATED_PDF_SUCCESS = "FACTORING/GET_COLLATED_PDF_SUCCESS";
export const GET_COLLATED_PDF_REQUEST = "FACTORING/GET_COLLATED_PDF_REQUEST";
export const GET_COLLATED_PDF_FAILURE = "FACTORING/GET_COLLATED_PDF_FAILURE";

export const FETCH_LINEITEM_HISTORY_SUCCESS =
  "FACTORING/FETCH_LINEITEM_HISTORY_SUCCESS";
export const FETCH_LINEITEM_HISTORY_REQUEST =
  "FACTORING/FETCH_LINEITEM_HISTORY_REQUEST";
export const FETCH_LINEITEM_HISTORY_FAILURE =
  "FACTORING/FETCH_LINEITEM_HISTORY_FAILURE";

export const FETCH_FUNDINGREQUEST_REQUEST =
  "FACTORING/FETCH_FUNDINGREQUEST_REQUEST";
export const FETCH_FUNDINGREQUEST_SUCCESS =
  "FACTORING/FETCH_FUNDINGREQUEST_SUCCESS";
export const FETCH_FUNDINGREQUEST_FAILURE =
  "FACTORING/FETCH_FUNDINGREQUEST_FAILURE";

export const FETCH_LINEITEMS_REQUEST = "FACTORING/FETCH_LINEITEMS_REQUEST";
export const FETCH_LINEITEMS_SUCCESS = "FACTORING/FETCH_LINEITEMS_SUCCESS";
export const FETCH_LINEITEMS_FAILURE = "FACTORING/FETCH_LINEITEMS_FAILURE";

export const FETCH_RECEIVABLES_REQUEST = "FACTORING/FETCH_RECEIVABLES_REQUEST";
export const FETCH_RECEIVABLES_SUCCESS = "FACTORING/FETCH_RECEIVABLES_SUCCESS";
export const FETCH_RECEIVABLES_FAILURE = "FACTORING/FETCH_RECEIVABLES_FAILURE";

export const FETCH_PURCHASES_REQUEST = "FACTORING/FETCH_PURCHASES_REQUEST";
export const FETCH_PURCHASES_SUCCESS = "FACTORING/FETCH_PURCHASES_SUCCESS";
export const FETCH_PURCHASES_FAILURE = "FACTORING/FETCH_PURCHASES_FAILURE";
export const ADD_PURCHASES_SUCCESS = "FACTORING/ADD_PURCHASES_SUCCESS";

export const FETCH_DEBTORRELATION_REQUEST =
  "FACTORING/FETCH_DEBTORRELATION_REQUEST";
export const FETCH_DEBTORRELATION_SUCCESS =
  "FACTORING/FETCH_DEBTORRELATION_SUCCESS";
export const FETCH_DEBTORRELATION_FAILURE =
  "FACTORING/FETCH_DEBTORRELATION_FAILURE";

export const UPDATE_NOADATES_REQUEST = "FACTORING/UPDATE_NOADATES_REQUEST";
export const UPDATE_NOADATES_SUCCESS = "FACTORING/UPDATE_NOADATES_SUCCESS";
export const UPDATE_NOADATES_FAILURE = "FACTORING/UPDATE_NOADATES_FAILURE";

export const UPDATE_FUNDINGREQUEST_REQUEST =
  "FACTORING/UPDATE_FUNDINGREQUEST_REQUEST";
export const UPDATE_FUNDINGREQUEST_SUCCESS =
  "FACTORING/UPDATE_FUNDINGREQUEST_SUCCESS";
export const UPDATE_FUNDINGREQUEST_FAILURE =
  "FACTORING/UPDATE_FUNDINGREQUEST_FAILURE";

export const FETCH_TRANSACTIONNOTES_REQUEST =
  "FACTORING/UPDATE_FUNDINGREQUEST_REQUEST";
export const FETCH_TRANSACTIONNOTES_SUCCESS =
  "FACTORING/FETCH_TRANSACTIONNOTES_SUCCESS";
export const FETCH_TRANSACTIONNOTES_FAILURE =
  "FACTORING/FETCH_TRANSACTIONNOTES_FAILURE";

export const OPEN_EDIT_NOTE = "FACTORING/OPEN_EDIT_NOTE";
export const CLOSE_EDIT_NOTE = "FACTORING/CLOSE_EDIT_NOTE";

export const OPEN_EDIT_LINEITEM = "FACTORING/OPEN_EDIT_LINEITEM";
export const CLOSE_EDIT_LINEITEM = "FACTORING/CLOSE_EDIT_LINEITEM";

export const UPDATE_TRANSACTIONNOTE_REQUEST =
  "FACTORING/UPDATE_TRANSACTIONNOTE_REQUEST";
export const UPDATE_TRANSACTIONNOTE_SUCCESS =
  "FACTORING/UPDATE_TRANSACTIONNOTE_SUCCESS";
export const UPDATE_TRANSACTIONNOTE_FAILURE =
  "FACTORING/UPDATE_TRANSACTIONNOTE_FAILURE";

export const FETCH_LINEITEMCATEGORIES_REQUEST =
  "FACTORING/FETCH_LINEITEMCATEGORIES_REQUEST";
export const FETCH_LINEITEMCATEGORIES_SUCCESS =
  "FACTORING/FETCH_LINEITEMCATEGORIES_SUCCESS";
export const FETCH_LINEITEMCATEGORIES_FAILURE =
  "FACTORING/FETCH_LINEITEMCATEGORIES_FAILURE";

export const DELETE_LINEITEM_REQUEST = "FACTORING/DELETE_LINEITEM_REQUEST";
export const DELETE_LINEITEM_SUCCESS = "FACTORING/DELETE_LINEITEM_SUCCESS";
export const DELETE_LINEITEM_FAILURE = "FACTORING/DELETE_LINEITEM_FAILURE";

export const CREATE_LINEITEM_REQUEST = "FACTORING/CREATE_LINEITEM_REQUEST";
export const CREATE_LINEITEM_SUCCESS = "FACTORING/CREATE_LINEITEM_SUCCESS";
export const CREATE_LINEITEM_FAILURE = "FACTORING/CREATE_LINEITEM_FAILURE";

export const FETCH_FACTORINGATTACHMENTS_REQUEST =
  "FACTORING/FETCH_FACTORINGATTACHMENTS_REQUEST";
export const FETCH_FACTORINGATTACHMENTS_SUCCESS =
  "FACTORING/FETCH_FACTORINGATTACHMENTS_SUCCESS";
export const FETCH_FACTORINGATTACHMENTS_FAILURE =
  "FACTORING/FETCH_FACTORINGATTACHMENTS_FAILURE";

export const FETCH_FACTORINGPROFILE_REQUEST =
  "FACTORING/FETCH_FACTORINGPROFILE_REQUEST";
export const FETCH_FACTORINGPROFILE_SUCCESS =
  "FACTORING/FETCH_FACTORINGPROFILE_SUCCESS";
export const FETCH_FACTORINGPROFILE_FAILURE =
  "FACTORING/FETCH_FACTORINGPROFILE_FAILURE";

export const FETCH_DEBTORPROFILE_REQUEST =
  "FACTORING/FETCH_DEBTORPROFILE_REQUEST";
export const FETCH_DEBTORPROFILE_SUCCESS =
  "FACTORING/FETCH_DEBTORPROFILE_SUCCESS";
export const FETCH_DEBTORPROFILE_FAILURE =
  "FACTORING/FETCH_DEBTORPROFILE_FAILURE";

export const SEND_NOA_REQUEST = "FACTORING/SEND_NOA_REQUEST";
export const SEND_NOA_SUCCESS = "FACTORING/SEND_NOA_SUCCESS";
export const SEND_NOA_FAILURE = "FACTORING/SEND_NOA_FAILURE";

export const OPEN_SEND_NOA = "FACTORING/OPEN_SEND_NOA";
export const CLOSE_SEND_NOA = "FACTORING/CLOSE_SEND_NOA";

export const DELETE_FUNDINGREQUEST_REQUEST =
  "FACTORING/DELETE_FUNDINGREQUEST_REQUEST";
export const DELETE_FUNDINGREQUEST_SUCCESS =
  "FACTORING/DELETE_FUNDINGREQUEST_SUCCESS";
export const DELETE_FUNDINGREQUEST_FAILURE =
  "FACTORING/DELETE_FUNDINGREQUEST_FAILURE";

export const FETCH_INVOICE_REQUEST = "FACTORING/FETCH_INVOICE_REQUEST";
export const FETCH_INVOICE_SUCCESS = "FACTORING/FETCH_INVOICE_SUCCESS";
export const FETCH_INVOICE_FAILURE = "FACTORING/FETCH_INVOICE_FAILURE";

export const FETCH_CARRIER_INVOICE_REQUEST =
  "FACTORING/FETCH_CARRIER_INVOICE_REQUEST";
export const FETCH_CARRIER_INVOICE_SUCCESS =
  "FACTORING/FETCH_CARRIER_INVOICE_SUCCESS";
export const FETCH_CARRIER_INVOICE_FAILURE =
  "FACTORING/FETCH_CARRIER_INVOICE_FAILURE";

export const FETCH_COLLATED_REQUEST = "FACTORING/FETCH_COLLATED_REQUEST";
export const FETCH_COLLATED_SUCCESS = "FACTORING/FETCH_COLLATED_SUCCESS";
export const FETCH_COLLATED_FAILURE = "FACTORING/FETCH_COLLATED_FAILURE";

export const FETCH_CLIENTNOTES_REQUEST = "FACTORING/FETCH_CLIENTNOTES_REQUEST";
export const FETCH_CLIENTNOTES_SUCCESS = "FACTORING/FETCH_CLIENTNOTES_SUCCESS";
export const FETCH_CLIENTNOTES_FAILURE = "FACTORING/FETCH_CLIENTNOTES_FAILURE";

export const CREATE_TRANSACTIONNOTE_REQUEST =
  "FACTORING/CREATE_TRANSACTIONNOTE_REQUEST";
export const CREATE_TRANSACTIONNOTE_SUCCESS =
  "FACTORING/CREATE_TRANSACTIONNOTE_SUCCESS";
export const CREATE_TRANSACTIONNOTE_FAILURE =
  "FACTORING/CREATE_TRANSACTIONNOTE_FAILURE";

export const DELETE_TRANSACTIONNOTE_REQUEST =
  "FACTORING/DELETE_TRANSACTIONNOTE_REQUEST";
export const DELETE_TRANSACTIONNOTE_SUCCESS =
  "FACTORING/DELETE_TRANSACTIONNOTE_SUCCESS";
export const DELETE_TRANSACTIONNOTE_FAILURE =
  "FACTORING/DELETE_TRANSACTIONNOTE_FAILURE";

export const CREATE_CLIENTNOTE_REQUEST = "FACTORING/CREATE_CLIENTNOTE_REQUEST";
export const CREATE_CLIENTNOTE_SUCCESS = "FACTORING/CREATE_CLIENTNOTE_SUCCESS";
export const CREATE_CLIENTNOTE_FAILURE = "FACTORING/CREATE_CLIENTNOTE_FAILURE";

export const UPDATE_CLIENTNOTE_REQUEST = "FACTORING/UPDATE_CLIENTNOTE_REQUEST";
export const UPDATE_CLIENTNOTE_SUCCESS = "FACTORING/UPDATE_CLIENTNOTE_SUCCESS";
export const UPDATE_CLIENTNOTE_FAILURE = "FACTORING/UPDATE_CLIENTNOTE_FAILURE";

export const DELETE_CLIENTNOTE_REQUEST = "FACTORING/DELETE_CLIENTNOTE_REQUEST";
export const DELETE_CLIENTNOTE_SUCCESS = "FACTORING/DELETE_CLIENTNOTE_SUCCESS";
export const DELETE_CLIENTNOTE_FAILURE = "FACTORING/DELETE_CLIENTNOTE_FAILURE";

export const UPDATE_LINEITEM_REQUEST = "FACTORING/UPDATE_LINEITEM_REQUEST";
export const UPDATE_LINEITEM_SUCCESS = "FACTORING/UPDATE_LINEITEM_SUCCESS";
export const UPDATE_LINEITEM_FAILURE = "FACTORING/UPDATE_LINEITEM_FAILURE";

export const FETCH_TMS_SUCCESS = "FACTORING/FETCH_TMS_SUCCESS";
export const FETCH_TMS_FAILURE = "FACTORING/FETCH_TMS_FAILURE";
export const FETCH_TMS_REQUEST = "FACTORING/FETCH_TMS_REQUEST";
export const ADD_TMS_REQUEST = "FACTORING/ADD_TMS_REQUEST";
export const UPDATE_TMS_REQUEST = "FACTORING/UPDATE_TMS_REQUEST";
export const DELETE_TMS_REQUEST = "FACTORING/DELETE_TMS_REQUEST";

export const FETCH_REASON_FOR_LEAVING_SUCCESS = "FACTORING/FETCH_REASON_FOR_LEAVING_SUCCESS";
export const FETCH_REASON_FOR_LEAVING_FAILURE = "FACTORING/FETCH_REASON_FOR_LEAVING_FAILURE";
export const FETCH_REASON_FOR_LEAVING_REQUEST = "FACTORING/FETCH_REASON_FOR_LEAVING_REQUEST";
export const ADD_REASON_FOR_LEAVING_REQUEST = "FACTORING/ADD_REASON_FOR_LEAVING_REQUEST";
export const UPDATE_REASON_FOR_LEAVING_REQUEST = "FACTORING/UPDATE_REASON_FOR_LEAVING_REQUEST";
export const DELETE_REASON_FOR_LEAVING_REQUEST = "FACTORING/DELETE_REASON_FOR_LEAVING_REQUEST";

export const FETCH_REFERRAL_SOURCE_SUCCESS = "FACTORING/FETCH_REFERRAL_SOURCE_SUCCESS";
export const FETCH_REFERRAL_SOURCE_FAILURE = "FACTORING/FETCH_REFERRAL_SOURCE_FAILURE";
export const FETCH_REFERRAL_SOURCE_REQUEST = "FACTORING/FETCH_REFERRAL_SOURCE_REQUEST";
export const ADD_REFERRAL_SOURCE_REQUEST = "FACTORING/ADD_REFERRAL_SOURCE_REQUEST";
export const UPDATE_REFERRAL_SOURCE_REQUEST = "FACTORING/UPDATE_REFERRAL_SOURCE_REQUEST";
export const DELETE_REFERRAL_SOURCE_REQUEST = "FACTORING/DELETE_REFERRAL_SOURCE_REQUEST";

export const FETCH_EMAIL_TEMPLATES_FAILURE =
  "FACTORING/FETCH_EMAIL_TEMPLATES_FAILURE";
export const FETCH_EMAIL_TEMPLATES_REQUEST =
  "FACTORING/FETCH_EMAIL_TEMPLATES_REQUEST";
export const FETCH_EMAIL_TEMPLATES_SUCCESS =
  "FACTORING/FETCH_EMAIL_TEMPLATES_SUCCESS";

export const FETCH_FR_EMAIL_TEMPLATES_FAILURE =
  "FACTORING/FETCH_FR_EMAIL_TEMPLATES_FAILURE";
export const FETCH_FR_EMAIL_TEMPLATES_REQUEST =
  "FACTORING/FETCH_FR_EMAIL_TEMPLATES_REQUEST";
export const FETCH_FR_EMAIL_TEMPLATES_SUCCESS =
  "FACTORING/FETCH_FR_EMAIL_TEMPLATES_SUCCESS";

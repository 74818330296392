import { assign } from "lodash";
import {
  FETCH_RESERVEAGGREGATE_REQUEST,
  FETCH_RESERVEAGGREGATE_SUCCESS,
  FETCH_RESERVEAGGREGATE_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_RESERVEAGGREGATE_REQUEST:
      return {
        ...state,
        loadingReserveAggregate: true
      };
    case FETCH_RESERVEAGGREGATE_SUCCESS:
      return {
        ...state,
        loadingReserveAggregate: false,
        reserveAggregates: assign({}, state.reserveAggregates, action.payload)
      };
    case FETCH_RESERVEAGGREGATE_FAILURE:
      return {
        ...state,
        loadingReserveAggregate: false
      };
    default:
      return state;
  }
};

import { concat, keys, omit } from "lodash";
import deepmerge from "deepmerge";
import { ADD_ENTITIES, DELETE_ROW } from "./types";

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

const addEntities = (state, { type, payload }) => {
  const updated = {};
  let entities = [];
  switch (type) {
    case ADD_ENTITIES:
      entities = concat(keys(state), keys(payload));
      entities.forEach(entity => {
        if (state[entity] && payload[entity]) {
          updated[entity] = deepmerge(state[entity], payload[entity], {
            arrayMerge: overwriteMerge
          });
        } else if (payload[entity]) {
          updated[entity] = payload[entity];
        } else {
          updated[entity] = state[entity];
        }
      });
      return updated;
    case DELETE_ROW:
      entities = keys(state);
      entities.forEach(entity => {
        updated[entity] = { ...state[entity] };
      });
      updated[payload.entity] = omit(updated[payload.entity], [payload.id]);
      return updated;
    default:
      return state;
  }
};

export default addEntities;

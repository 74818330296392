export interface State {
  similarDebtors: {
    ids: string[];
    count: number;
  };
  loadingNotes: boolean;
  notes: Record<string, any>;
  loadingHistory: boolean;
  history: Record<string, any>;
  lineItemCategories: any;
  lineItems: Record<string, any>;
  receivableNotes: Record<string, any>;
  connectedClients: Record<string, any>;
  loading: boolean;
  id: string;
  invoices: Record<string, any>;
  payments: Record<string, any>;
  credit: Record<string, any>;
}

export const FETCH_RECEIVABLES_ENTRIES_FAILURE =
  "DEBTOR/FETCH_RECEIVABLES_ENTRIES_FAILURE";
export const FETCH_RECEIVABLES_ENTRIES_REQUEST =
  "DEBTOR/FETCH_RECEIVABLES_ENTRIES_REQUEST";
export const FETCH_RECEIVABLES_ENTRIES_SUCCESS =
  "DEBTOR/FETCH_RECEIVABLES_ENTRIES_SUCCESS";

export const FETCH_DEBTOR_REQUEST = "DEBTOR/FETCH_DEBTOR_REQUEST";
export const FETCH_DEBTOR_SUCCESS = "DEBTOR/FETCH_DEBTOR_SUCCESS";
export const FETCH_DEBTOR_FAILURE = "DEBTOR/FETCH_DEBTOR_FAILURE";

export const DELETE_DEBTORRELATION_REQUEST =
  "DEBTOR/DELETE_DEBTORRELATION_REQUEST";
export const DELETE_DEBTORRELATION_SUCCESS =
  "DEBTOR/DELETE_DEBTORRELATION_SUCCESS";
export const DELETE_DEBTORRELATION_FAILURE =
  "DEBTOR/DELETE_DEBTORRELATION_FAILURE";

export const FETCH_DEBTORCONNECTEDCLIENTS_REQUEST =
  "DEBTORCONNECTEDCLIENTS/FETCH_DEBTORCONNECTEDCLIENTS_REQUEST";
export const FETCH_DEBTORCONNECTEDCLIENTS_SUCCESS =
  "DEBTORCONNECTEDCLIENTS/FETCH_DEBTORCONNECTEDCLIENTS_SUCCESS";
export const FETCH_DEBTORCONNECTEDCLIENTS_FAILURE =
  "DEBTORCONNECTEDCLIENTS/FETCH_DEBTORCONNECTEDCLIENTS_FAILURE";

export const FETCH_DEBTORHISTORY_REQUEST = "DEBTOR/FETCH_DEBTORHISTORY_REQUEST";
export const FETCH_DEBTORHISTORY_SUCCESS = "DEBTOR/FETCH_DEBTORHISTORY_SUCCESS";
export const FETCH_DEBTORHISTORY_FAILURE = "DEBTOR/FETCH_DEBTORHISTORY_FAILURE";

export const UPDATE_DEBTOR_REQUEST = "DEBTOR/UPDATE_DEBTOR_REQUEST";
export const UPDATE_DEBTOR_SUCCESS = "DEBTOR/UPDATE_DEBTOR_SUCCESS";
export const UPDATE_DEBTOR_FAILURE = "DEBTOR/UPDATE_DEBTOR_FAILURE";

export const DELETE_DEBTOR_REQUEST = "DEBTOR/DELETE_DEBTOR_REQUEST";
export const DELETE_DEBTOR_SUCCESS = "DEBTOR/DELETE_DEBTOR_SUCCESS";
export const DELETE_DEBTOR_FAILURE = "DEBTOR/DELETE_DEBTOR_FAILURE";

export const FETCH_OPENFUNDINGREQUEST_REQUEST =
  "DEBTORPAYMENTS/FETCH_OPENFUNDINGREQUEST_REQUEST";
export const FETCH_OPENFUNDINGREQUEST_SUCCESS =
  "DEBTORPAYMENTS/FETCH_OPENFUNDINGREQUEST_SUCCESS";
export const FETCH_OPENFUNDINGREQUEST_FAILURE =
  "DEBTORPAYMENTS/FETCH_OPENFUNDINGREQUEST_FAILURE";

export const CREATE_DEBTORPAYMENT_REQUEST =
  "DEBTOR/CREATE_DEBTORPAYMENT_REQUEST";
export const CREATE_DEBTORPAYMENT_SUCCESS =
  "DEBTOR/CREATE_DEBTORPAYMENT_SUCCESS";
export const CREATE_DEBTORPAYMENT_FAILURE =
  "DEBTOR/CREATE_DEBTORPAYMENT_FAILURE";

export const FETCH_DEBTORINVOICES_REQUEST =
  "DEBTORINVOICES/FETCH_DEBTORINVOICES_REQUEST";
export const FETCH_DEBTORINVOICES_SUCCESS =
  "DEBTORINVOICES/FETCH_DEBTORINVOICES_SUCCESS";
export const FETCH_DEBTORINVOICES_FAILURE =
  "DEBTORINVOICES/FETCH_DEBTORINVOICES_FAILURE";

export const UPDATE_FACTORINGDEBTOR_REQUEST =
  "DEBTOR/UPDATE_FACTORINGDEBTOR_REQUEST";
export const UPDATE_FACTORINGDEBTOR_SUCCESS =
  "DEBTOR/UPDATE_FACTORINGDEBTOR_SUCCESS";
export const UPDATE_FACTORINGDEBTOR_FAILURE =
  "DEBTOR/UPDATE_FACTORINGDEBTOR_FAILURE";

export const FETCH_DEBTORRELATIONHISTORY_REQUEST =
  "DEBTOR/FETCH_DEBTORRELATIONHISTORY_REQUEST";
export const FETCH_DEBTORRELATIONHISTORY_SUCCESS =
  "DEBTOR/FETCH_DEBTORRELATIONHISTORY_SUCCESS";
export const FETCH_DEBTORRELATIONHISTORY_FAILURE =
  "DEBTOR/FETCH_DEBTORRELATIONHISTORY_FAILURE";

export const FETCH_SIMILARDEBTORS_REQUEST =
  "DEBTOR/FETCH_SIMILARDEBTORS_REQUEST";
export const FETCH_SIMILARDEBTORS_SUCCESS =
  "DEBTOR/FETCH_SIMILARDEBTORS_SUCCESS";
export const FETCH_SIMILARDEBTORS_FAILURE =
  "DEBTOR/FETCH_SIMILARDEBTORS_FAILURE";

export const FETCH_NOTES_REQUEST = "DEBTOR/FETCH_NOTES_REQUEST";
export const FETCH_NOTES_SUCCESS = "DEBTOR/FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_FAILURE = "DEBTOR/FETCH_NOTES_FAILURE";

export const CREATE_NOTE_REQUEST = "DEBTOR/CREATE_NOTE_REQUEST";
export const CREATE_NOTE_SUCCESS = "DEBTOR/CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_FAILURE = "DEBTOR/CREATE_NOTE_FAILURE";

export const UPDATE_NOTE_REQUEST = "DEBTOR/UPDATE_NOTE_REQUEST";
export const UPDATE_NOTE_SUCCESS = "DEBTOR/UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAILURE = "DEBTOR/UPDATE_NOTE_FAILURE";

export const DELETE_NOTE_REQUEST = "DEBTOR/DELETE_NOTE_REQUEST";
export const DELETE_NOTE_SUCCESS = "DEBTOR/DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAILURE = "DEBTOR/DELETE_NOTE_FAILURE";

export const FETCH_RECEIVABLENOTES_REQUEST =
  "DEBTOR/FETCH_RECEIVABLENOTES_REQUEST";
export const FETCH_RECEIVABLENOTES_SUCCESS =
  "DEBTOR/FETCH_RECEIVABLENOTES_SUCCESS";
export const FETCH_RECEIVABLENOTES_FAILURE =
  "DEBTOR/FETCH_RECEIVABLENOTES_FAILURE";

export const UPDATE_RECEIVABLENOTES_SUCCESS =
  "DEBTOR/UPDATE_RECEIVABLENOTES_SUCCESS";

export const CREATE_FOLLOWUPNOTE_REQUEST = "DEBTOR/CREATE_FOLLOWUPNOTE_REQUEST";
export const CREATE_FOLLOWUPNOTE_SUCCESS = "DEBTOR/CREATE_FOLLOWUPNOTE_SUCCESS";
export const CREATE_FOLLOWUPNOTE_FAILURE = "DEBTOR/CREATE_FOLLOWUPNOTE_FAILURE";

export const UPDATE_FOLLOWUPNOTE_REQUEST = "DEBTOR/UPDATE_FOLLOWUPNOTE_REQUEST";
export const UPDATE_FOLLOWUPNOTE_SUCCESS = "DEBTOR/UPDATE_FOLLOWUPNOTE_SUCCESS";
export const UPDATE_FOLLOWUPNOTE_FAILURE = "DEBTOR/UPDATE_FOLLOWUPNOTE_FAILURE";

export const DELETE_FOLLOWUPNOTE_REQUEST = "DEBTOR/DELETE_FOLLOWUPNOTE_REQUEST";
export const DELETE_FOLLOWUPNOTE_SUCCESS = "DEBTOR/DELETE_FOLLOWUPNOTE_SUCCESS";
export const DELETE_FOLLOWUPNOTE_FAILURE = "DEBTOR/DELETE_FOLLOWUPNOTE_FAILURE";

export const FETCH_LINEITEMCATEGORIES_REQUEST =
  "DEBTOR/FETCH_LINEITEMCATEGORIES_REQUEST";
export const FETCH_LINEITEMCATEGORIES_SUCCESS =
  "DEBTOR/FETCH_LINEITEMCATEGORIES_SUCCESS";
export const FETCH_LINEITEMCATEGORIES_FAILURE =
  "DEBTOR/FETCH_LINEITEMCATEGORIES_FAILURE";

export const CREATE_LINEITEM_REQUEST = "DEBTOR/CREATE_LINEITEM_REQUEST";
export const CREATE_LINEITEM_SUCCESS = "DEBTOR/CREATE_LINEITEM_SUCCESS";
export const CREATE_LINEITEM_FAILURE = "DEBTOR/CREATE_LINEITEM_FAILURE";

export const FETCH_LINEITEMS_REQUEST = "DEBTOR/FETCH_LINEITEMS_REQUEST";
export const FETCH_LINEITEMS_SUCCESS = "DEBTOR/FETCH_LINEITEMS_SUCCESS";
export const FETCH_LINEITEMS_FAILURE = "DEBTOR/FETCH_LINEITEMS_FAILURE";

export const FETCH_CREDIT_HISTORY_REQUEST =
  "DEBTOR/FETCH_CREDIT_HISTORY_REQUEST";
export const FETCH_CREDIT_HISTORY_SUCCESS =
  "DEBTOR/FETCH_CREDIT_HISTORY_SUCCESS";
export const FETCH_CREDIT_HISTORY_FAILURE =
  "DEBTOR/FETCH_CREDIT_HISTORY_FAILURE";
export const ADD_CREDIT_HISTORY_SUCCESS = "DEBTOR/ADD_CREDIT_HISTORY_SUCCESS";

export const CREATE_PAYMENT_FORWARD_REQUEST =
  "DEBTOR/CREATE_PAYMENT_FORWARD_REQUEST";
export const CREATE_PAYMENT_FORWARD_SUCCESS =
  "DEBTOR/CREATE_PAYMENT_FORWARD_SUCCESS";
export const CREATE_PAYMENT_FORWARD_FAILURE =
  "DEBTOR/CREATE_PAYMENT_FORWARD_FAILURE";

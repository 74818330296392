import { connect } from "react-redux";
import { compose, lifecycle, withState } from "recompose";
import { createAction } from "redux-starter-kit";
import { includes } from "lodash";

import mapState from "../../helpers/mapState";
import { setEmail, getEmail, logout, getUserToken } from "../../modules/auth";
import {
  resetSteps,
  getSteps,
  getCurrentStep,
  exchangePlaidToken,
  moveToNextStep,
  getExchangePlaidError,
  getExchangePlaid,
  getExchangeLoading,
  getUsedPlaid,
  signUpFactoringProfile,
  moveToStepByName,
  getFactoringLoading,
  getFactoringError,
  getFactoringProfile
} from "../../modules/signUp";
import {
  currentUser,
  getUserProfileSuccess,
  getLoading,
  clientUserUpdate as updateUser
} from "../../modules/user";
import { 
  openDialog,
  getDialog,
  closeDialog,
} from "../../modules/ui";
import { getFiles } from "../../modules/upload";
import {
  updateFactoringProfile,
  getUpdateFactoringProfileLoading,
  getUpdateFactoringProfile
} from "../../modules/factoring";
import { FETCH_TOKEN_SUCCESS } from "../../modules/auth/types";
import Form from "./form";

const mapStateToProps = mapState({
  step: getCurrentStep,
  steps: getSteps,
  authEmail: getEmail,
  exchangePlaidError: getExchangePlaidError,
  exchangePlaid: getExchangePlaid,
  exchangePlaidLoading: getExchangeLoading,
  attachments: getFiles,
  user: getUserProfileSuccess,
  usedPlaid: getUsedPlaid,
  loadingUser: getLoading,
  factoringLoading: getFactoringLoading,
  factoringError: getFactoringError,
  factoringProfile: getFactoringProfile,
  updateFactoringProfileLoading: getUpdateFactoringProfileLoading,
  updateFactoringProfileSuccess: getUpdateFactoringProfile,
  userToken: getUserToken,
});

const haveToken = token =>
  createAction(FETCH_TOKEN_SUCCESS)({ access_token: token });

const mapDispatchToProps = {
  exchangePlaidToken,
  moveToNextStep,
  setEmail,
  logout,
  resetSteps,
  currentUser,
  signUpFactoringProfile,
  updateFactoringProfile,
  moveToStepByName,
  haveToken,
  updateUser,
};
export const PureSignUpScreen = Form;
export default compose(
  withState("errorMsg", "setErrorMsg", false),
  withState("isIframe", "setIsIframe", false),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      const createdAccount =
        prevProps.factoringProfile !== this.props.factoringProfile;
      const updateAccount =
        prevProps.updateFactoringProfileSuccess !==
        this.props.updateFactoringProfileSuccess;
      if (createdAccount || updateAccount) {
        this.props.moveToNextStep();
      }
      const plaidTokenExchanged =
        this.props.exchangePlaid !== prevProps.exchangePlaid;

      if (plaidTokenExchanged) {
        this.props.moveToNextStep();
      }
    },
    async componentWillMount() {
      const {
        match: {
          params: { userType = null, token = null, step = null },
          path
        },
        currentUser: getCurrentUser,
        userToken,
        moveToStepByName: jumpToStep,
        setIsIframe
      } = this.props;
      if (window.location !== window.parent.location) {
        setIsIframe(true);
      }
      if (token) {
        this.props.haveToken(token);
        await getCurrentUser();
      }
      if (path === "/update/:step") {
        if (!includes(["confirm", "bank", "attach", "summary"], step)) {
          this.props.history.push("/error");
        }
        if (step && userToken) {
          await getCurrentUser();
          jumpToStep(step);
        } else if (!userType && step) {
          this.props.history.push(`/login/?redirect=/update/${step}`);
        }
      } else if (userType !== "carrier" && userType !== "broker") {
        this.props.history.push("/error");
      }
    }
  })
)(Form);

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import React, { ReactNode } from "react";
import { ButtonProps } from "@material-ui/core/Button";
import Colors from "../../theme/colors";

interface Props extends ButtonProps {
  children: ReactNode;
  disabled?: boolean;
}

const SpinnerButton = ({ children, ...props }: Props): JSX.Element => {
  const { disabled } = props;
  return (
    <Button {...props}>
      {children}
      {disabled && (
        <FontAwesomeIcon
          icon={faSpinner}
          size="sm"
          spin
          color={Colors.green}
          style={{ marginLeft: 5 }}
        />
      )}
    </Button>
  );
};

export default SpinnerButton;

import { Link } from "react-router-dom";
import React from "react";
import MoreButton from "../../../../components/buttons/MoreButton";

interface Props {
  id: string;
}

const MoreLink = ({ id }: Props): JSX.Element => (
  <Link to={`/admin/fundingrequest/${id}`}>
    <MoreButton />
  </Link>
);

export default MoreLink;

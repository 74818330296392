import { compose } from "recompose";
import { createReducer } from "redux-starter-kit";
import withTable from "../withTable";

import queryConnectedClients from "./actions/queryConnectedClients";
import queryDebtorInvoices from "./actions/queryDebtorInvoices";
import queryOpenFundingRequests from "./actions/queryOpenFundingRequests";

import * as TYPES from "./types";
import queryDebtorReducer from "./reducers/queryDebtor";
import updateDebtorReducer from "./reducers/updateDebtor";
import fetchHistoryReducer from "./reducers/fetchHistory";
import deleteDebtorReducer from "./reducers/deleteDebtor";
import queryOpenFundingRequestsReducer from "./reducers/queryOpenFundingRequests";
import queryConnectedClientsReducer from "./reducers/queryConnectedClients";
import queryDebtorInvoicesReducer from "./reducers/queryDebtorInvoices";
import fetchSimilarDebtorsReducer from "./reducers/fetchSimilarDebtors";
import fetchDebtorNotesReducer from "./reducers/fetchDebtorNotes";
import createDebtorNoteReducer from "./reducers/createDebtorNote";
import fetchReceivableNotesReducer from "./reducers/fetchReceivableNotes";
import fetchLineItemCategoriesReducer from "./reducers/fetchLineItemCategories";
import fetchLineItemsReducer from "./reducers/fetchLineItems";
import deleteDebtorNote from "./reducers/deleteDebtorNote";
import fetchReceivablesEntriesReducer from "./reducers/fetchReceivablesEntries";
import queryCreditReportReducer from "./reducers/queryCreditReport";
import createPaymentForwardReducer from "./reducers/createPaymentForward";

const {
  selectors: connectedClientsSelectors,
  actions: connectedClientsActions,
  reducer: connectedClientsTableReducer,
  middleware: connectedClientsMiddleware
} = withTable({
  stateKey: "debtor.connectedClients",
  typeKey: "debtorconnectedclients"
});

const {
  selectors: invoiceTableSelectors,
  actions: invoiceTableActions,
  reducer: invoicesReducer,
  middleware: invoicesMiddleware
} = withTable({
  stateKey: "debtor.invoices",
  typeKey: "debtorinvoices"
});

const {
  selectors: paymentSelectors,
  actions: paymentActions,
  reducer: paymentsReducer,
  middleware: paymentsMiddleware
} = withTable({
  stateKey: "debtor.payments",
  typeKey: "debtorpayments"
});

export { default as getLineItemsByFundingRequest } from "./selectors/getLineItemsByFundingRequest";
export { default as getLineItemCategories } from "./selectors/getLineItemCategories";
export { default as getDebtor } from "./selectors/getDebtor";
export { default as getDebtorCompanyName } from "./selectors/getDebtorCompanyName";
export { default as getOpenFundingRequests } from "./selectors/getOpenFundingRequests";
export { default as getHistory } from "./selectors/getHistory";
export { default as getDebtorMaster } from "./selectors/getDebtorMaster";
export { default as getNotes } from "./selectors/getNotes";
export { default as getInvoice } from "./selectors/getInvoice";
export { default as getReceivablesEntriesLoading } from "./selectors/getReceivablesEntriesLoading";
export { default as getIsLoading } from "./selectors/getIsLoading";
export { default as getDebtorInvoices } from "./selectors/getDebtorInvoices";
export { default as getSimilarDebtors } from "./selectors/getSimilarDebtors";
export { default as getReceivablesEntries } from "./selectors/getReceivablesEntries";
export { default as getConnectedClientsIsLoading } from "./selectors/getConnectedClientsIsLoading";
export { default as getDebtorRelationHistory } from "./selectors/getDebtorRelationHistory";
export { default as getInvoicesIsLoading } from "./selectors/getInvoicesIsLoading";
export { default as getConnectedClients } from "./selectors/getConnectedClients";
export { default as getSelectedRecievableNotes } from "./selectors/getSelectedRecievableNotes";
export { default as getSelectedFundingRequests } from "./selectors/getSelectedFundingRequests";
export { default as getDebtorId } from "./selectors/getDebtorId";
export { default as getCreditReport } from "./selectors/getCreditReport";

export { default as fetchLineItems } from "./actions/fetchLineItems";
export { default as createPaymentForward } from "./actions/createPaymentForward";
export { default as createFollowupNote } from "./actions/createFollowupNote";
export { default as updateFollowupNote } from "./actions/updateFollowupNote";
export { default as deleteFollowupNote } from "./actions/deleteFollowupNote";
export { default as updateDebtor } from "./actions/updateDebtor";
export { default as deleteDebtor } from "./actions/deleteDebtor";
export { default as fetchSimilarDebtors } from "./actions/fetchSimilarDebtors";
export { default as createLineItem } from "./actions/createLineItem";
export { default as deleteDebtorNote } from "./actions/deleteDebtorNote";
export { default as fetchDebtorRelationHistory } from "./actions/fetchDebtorRelationHistory";
export { default as queryDebtor } from "./actions/queryDebtor";
export { default as queryCreditReport } from "./actions/queryCreditReport";
export { default as fetchHistory } from "./actions/fetchHistory";
export { default as queryOpenFundingRequests } from "./actions/queryOpenFundingRequests";
export { default as deleteDebtorRelationship } from "./actions/deleteDebtorRelationship";
export { default as clearOpenFundingRequests } from "./actions/clearOpenFundingRequests";
export { default as queryConnectedClients } from "./actions/queryConnectedClients";
export { default as createDebtorPayment } from "./actions/createDebtorPayment";
export { default as queryDebtorInvoices } from "./actions/queryDebtorInvoices";
export { default as fetchDebtorNotes } from "./actions/fetchDebtorNotes";
export { default as createDebtorNote } from "./actions/createDebtorNote";
export { default as updateDebtorNote } from "./actions/updateDebtorNote";
export { default as fetchReceivablesEntries } from "./actions/fetchReceivablesEntries";
export { default as addCreditCheckData } from "./actions/addCreditCheckData";
export { default as loadDebtorNoteAttachments } from "./actions/loadDebtorNoteAttachments";
export { default as createConnectedClientsCSV } from "./actions/createConnectedClientsCSV";
export { default as fetchReceivableNotes } from "./actions/fetchReceivableNotes";
export { default as fetchLineItemCategories } from "./actions/fetchLineItemCategories";
export { default as updateFactoringDebtor } from "./actions/updateFactoringDebtor";
export { default as transferToMaster } from "./actions/transferToMaster";

export const uploadFundingRequestCSV = (debtorId, file) => async (
  dispatch,
  getState,
  { api }
) => {
  await api.debtors.uploadReport(debtorId, file);
};

export const createFundingRequestCSV = (
  debtorId,
  receivableStatus,
  responseCategory,
  factoringCompanyProfileName,
  invoiceNumber,
  userLoadNumber,
  receivableAgeGTE,
  receivableAgeLTE,
  referenceNumber
) => async (dispatch, getState, { api }) => {
  const response = await api.debtors.generateReport(
    debtorId,
    receivableStatus,
    responseCategory,
    factoringCompanyProfileName,
    invoiceNumber,
    userLoadNumber,
    receivableAgeGTE,
    receivableAgeLTE,
    referenceNumber
  );

  const element = document.createElement("a");
  element.href = response.download_url;
  element.download = "export.xlsx";
  document.body.appendChild(element);
  element.click();
};

export const getConnectedClientsCount = connectedClientsSelectors.getCount;
export const getConnectedClientsPage = connectedClientsSelectors.getPage;
export const getConnectedClientsRowsPerPage =
  connectedClientsSelectors.getRowsPerPage;
export const getConnectedClientsRowsPerPageOptions =
  connectedClientsSelectors.getRowsPerPageOptions;
export const getConnectedClientsSortDirection =
  connectedClientsSelectors.getSortDirection;
export const getConnectedClientsSortBy = connectedClientsSelectors.getSortBy;
export const getConnectedClientsFilters = connectedClientsSelectors.getFilters;

export const handleConnectedClientsSort = connectedClientsActions.handleSort;
export const handleConnectedClientsChangePage =
  connectedClientsActions.handleChangePage;
export const handleConnectedClientsChangeRowsPerPage =
  connectedClientsActions.handleChangeRowsPerPage;
export const handleConnectedClientsSearchFilter =
  connectedClientsActions.handleSearchFilter;

export const getInvoicesCount = invoiceTableSelectors.getCount;
export const getInvoicesPage = invoiceTableSelectors.getPage;
export const getInvoicesRowsPerPage = invoiceTableSelectors.getRowsPerPage;
export const getInvoicesRowsPerPageOptions =
  invoiceTableSelectors.getRowsPerPageOptions;
export const getInvoicesSortDirection = invoiceTableSelectors.getSortDirection;
export const getInvoicesSortBy = invoiceTableSelectors.getSortBy;
export const getInvoicesFilters = invoiceTableSelectors.getFilters;

export const handleInvoicesSort = invoiceTableActions.handleSort;
export const handleInvoicesChangePage = invoiceTableActions.handleChangePage;
export const handleInvoicesChangeRowsPerPage =
  invoiceTableActions.handleChangeRowsPerPage;
export const handleInvoicesSearchFilter =
  invoiceTableActions.handleSearchFilter;

export const getPaymentsCount = paymentSelectors.getCount;
export const getPaymentsPage = paymentSelectors.getPage;
export const getPaymentsRowsPerPage = paymentSelectors.getRowsPerPage;
export const getPaymentsRowsPerPageOptions =
  paymentSelectors.getRowsPerPageOptions;
export const getPaymentsSortDirection = paymentSelectors.getSortDirection;
export const getPaymentsSortBy = paymentSelectors.getSortBy;
export const getPaymentsFilters = paymentSelectors.getFilters;

export const handlePaymentsSort = paymentActions.handleSort;
export const handlePaymentsChangePage = paymentActions.handleChangePage;
export const handlePaymentsChangeRowsPerPage =
  paymentActions.handleChangeRowsPerPage;
export const handlePaymentsSearchFilter = paymentActions.handleSearchFilter;

const initial = {
  loading: false,
  loadingHistory: false,
  history: [],
  loadingNotes: false,
  lineItemCategories: [],
  notes: {},
  id: undefined,
  ids: [],
  receivableNotes: {},
  lineItems: {},
  invoices: {
    loading: false,
    ids: [],
    count: 0
  },
  connectedClients: {
    loading: false,
    ids: [],
    count: 0
  },
  receivablesEntries: {
    loading: false,
    ids: [],
    count: 0
  },
  similarDebtors: {
    loading: false,
    ids: [],
    count: 0
  },
  loadingDebtorNotes: false,
  debtorNotes: {}
};

const reducer = createReducer(initial, {
  [TYPES.FETCH_DEBTOR_REQUEST]: queryDebtorReducer,
  [TYPES.FETCH_DEBTOR_SUCCESS]: queryDebtorReducer,
  [TYPES.FETCH_DEBTOR_FAILURE]: queryDebtorReducer,
  [TYPES.UPDATE_DEBTOR_REQUEST]: updateDebtorReducer,
  [TYPES.UPDATE_DEBTOR_SUCCESS]: updateDebtorReducer,
  [TYPES.UPDATE_DEBTOR_FAILURE]: updateDebtorReducer,
  [TYPES.FETCH_DEBTORHISTORY_REQUEST]: fetchHistoryReducer,
  [TYPES.FETCH_DEBTORHISTORY_SUCCESS]: fetchHistoryReducer,
  [TYPES.FETCH_DEBTORHISTORY_FAILURE]: fetchHistoryReducer,
  [TYPES.DELETE_DEBTOR_REQUEST]: deleteDebtorReducer,
  [TYPES.DELETE_DEBTOR_SUCCESS]: deleteDebtorReducer,
  [TYPES.DELETE_DEBTOR_FAILURE]: deleteDebtorReducer,
  [TYPES.FETCH_OPENFUNDINGREQUEST_REQUEST]: queryOpenFundingRequestsReducer,
  [TYPES.FETCH_OPENFUNDINGREQUEST_SUCCESS]: queryOpenFundingRequestsReducer,
  [TYPES.FETCH_OPENFUNDINGREQUEST_FAILURE]: queryOpenFundingRequestsReducer,
  [TYPES.FETCH_DEBTORCONNECTEDCLIENTS_REQUEST]: queryConnectedClientsReducer,
  [TYPES.FETCH_DEBTORCONNECTEDCLIENTS_SUCCESS]: queryConnectedClientsReducer,
  [TYPES.FETCH_DEBTORCONNECTEDCLIENTS_FAILURE]: queryConnectedClientsReducer,
  [TYPES.FETCH_DEBTORINVOICES_REQUEST]: queryDebtorInvoicesReducer,
  [TYPES.FETCH_DEBTORINVOICES_SUCCESS]: queryDebtorInvoicesReducer,
  [TYPES.FETCH_DEBTORINVOICES_FAILURE]: queryDebtorInvoicesReducer,
  [TYPES.FETCH_SIMILARDEBTORS_REQUEST]: fetchSimilarDebtorsReducer,
  [TYPES.FETCH_SIMILARDEBTORS_SUCCESS]: fetchSimilarDebtorsReducer,
  [TYPES.FETCH_SIMILARDEBTORS_FAILURE]: fetchSimilarDebtorsReducer,
  [TYPES.FETCH_NOTES_REQUEST]: fetchDebtorNotesReducer,
  [TYPES.FETCH_NOTES_SUCCESS]: fetchDebtorNotesReducer,
  [TYPES.FETCH_NOTES_FAILURE]: fetchDebtorNotesReducer,
  [TYPES.CREATE_NOTE_REQUEST]: createDebtorNoteReducer,
  [TYPES.CREATE_NOTE_SUCCESS]: createDebtorNoteReducer,
  [TYPES.CREATE_NOTE_FAILURE]: createDebtorNoteReducer,
  [TYPES.FETCH_RECEIVABLENOTES_SUCCESS]: fetchReceivableNotesReducer,
  [TYPES.FETCH_LINEITEMCATEGORIES_SUCCESS]: fetchLineItemCategoriesReducer,
  [TYPES.FETCH_LINEITEMS_SUCCESS]: fetchLineItemsReducer,
  [TYPES.DELETE_NOTE_SUCCESS]: deleteDebtorNote,
  [TYPES.DELETE_NOTE_REQUEST]: deleteDebtorNote,
  [TYPES.DELETE_NOTE_FAILURE]: deleteDebtorNote,

  [TYPES.FETCH_RECEIVABLES_ENTRIES_REQUEST]: fetchReceivablesEntriesReducer,
  [TYPES.FETCH_RECEIVABLES_ENTRIES_SUCCESS]: fetchReceivablesEntriesReducer,
  [TYPES.FETCH_RECEIVABLES_ENTRIES_FAILURE]: fetchReceivablesEntriesReducer,

  [TYPES.FETCH_CREDIT_HISTORY_REQUEST]: queryCreditReportReducer,
  [TYPES.FETCH_CREDIT_HISTORY_SUCCESS]: queryCreditReportReducer,
  [TYPES.FETCH_CREDIT_HISTORY_FAILURE]: queryCreditReportReducer,
  [TYPES.ADD_CREDIT_HISTORY_SUCCESS]: queryCreditReportReducer,
  [TYPES.UPDATE_RECEIVABLENOTES_SUCCESS]: fetchReceivableNotesReducer,
  [TYPES.CREATE_PAYMENT_FORWARD_SUCCESS]: createPaymentForwardReducer
});

export default compose(
  connectedClientsTableReducer,
  invoicesReducer,
  paymentsReducer
)(reducer);

export const middleware = [
  connectedClientsMiddleware(selectors => store => action => {
    const state = store.getState();
    const sortBy = selectors.getSortBy(state);
    const sortDirection = selectors.getSortDirection(state);
    const offset = selectors.getOffset(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const filters = selectors.getFilters(state);

    store.dispatch(
      queryConnectedClients(
        state.debtor.id,
        sortDirection === "asc" ? sortBy : `-${sortBy}`,
        rowsPerPage,
        offset,
        filters
      )
    );
  }),
  invoicesMiddleware(selectors => store => action => {
    const state = store.getState();
    const sortBy = selectors.getSortBy(state);
    const sortDirection = selectors.getSortDirection(state);
    const offset = selectors.getOffset(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const filters = selectors.getFilters(state);

    store.dispatch(
      queryDebtorInvoices(
        state.debtor.id,
        sortDirection === "asc" ? sortBy : `-${sortBy}`,
        rowsPerPage,
        offset,
        filters
      )
    );
  }),
  paymentsMiddleware(selectors => store => action => {
    const state = store.getState();
    const sortBy = selectors.getSortBy(state);
    const sortDirection = selectors.getSortDirection(state);
    const offset = selectors.getOffset(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const filters = selectors.getFilters(state);

    store.dispatch(
      queryOpenFundingRequests(
        state.debtor.payments.id,
        sortDirection === "asc" ? sortBy : `-${sortBy}`,
        rowsPerPage,
        offset,
        filters
      )
    );
  })
];

import * as Yup from "yup";
import { isInteger, sum } from "lodash";
import formatPennies from "../../../../helpers/format/formatPennies";

export const payment = selected => {
  let totalAmount = 0;
  let selectedAmount = 0;
  return Yup.object().shape({
    type: Yup.string().required("Type is a required field"),
    sch: Yup.string().required("Reference Number is a required field"),
    amount: Yup.string()
      .required("Amount is a required field")
      .test("equalAmounts", "Error", function(amount) {
        if ((selected || []).length === 0) {
          return true;
        }
        selectedAmount = amount * 100;
        totalAmount =
          sum(
            selected.map(id => {
              if (isInteger(id)) {
                return 0;
              }
              return Number(this.parent[id]) || 0;
            })
          ) *
            100 +
          this.parent.amountAdded * 100;
        if (Number(amount).toFixed(2) !== (totalAmount / 100).toFixed(2)) {
          return this.createError({
            message: `${formatPennies(selectedAmount)} - ${formatPennies(
              totalAmount
            )} = ${formatPennies(selectedAmount - totalAmount)}`
          });
        }
        return true;
      })
  });
};

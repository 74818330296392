import splitAndCase from "../../../../helpers/format/splitAndCase";
import formatDate from "../../../../helpers/format/formatDate";

const columns = [
  {
    key: "name",
    name: "Group Name",
    filter: "text",
    filter_key: "name"
  },
  {
    key: "type",
    name: "Group Type",
    filter_key: "type",
    format: splitAndCase,
    filter: "dropdown",
    filter_values: [
      { label: "HaulPay Broker", value: "haulpay_broker" },
      { label: "Dispatch Broker", value: "dispatch_broker" },
      { label: "Dispatch", value: "dispatch" }
    ]
  },
  {
    key: "company_name",
    name: "DBA",
    filter: "text",
    filter_key: "company_name"
  },
  {
    key: "member_count",
    name: "Number Of Members"
  },
  {
    key: "mc",
    name: "MC",
    filter: "text",
    filter_key: "mc"
  },
  {
    key: "dot",
    name: "DOT",
    filter: "text",
    filter_key: "dot"
  },
  {
    key: "created",
    name: "Created At",
    format: formatDate
  },
  {
    key: "email",
    name: "Admin Email",
    filter: "text",
    filter_key: "email"
  },
  {
    key: "actions",
    name: "Actions"
  }
];

export default columns;

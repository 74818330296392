import React, { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";
import { get, head } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";

import colors from "../../../../theme/colors";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Link from "../../../../components/Link";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import Table from "../../../../components/Table";
import { Select, CheckBox } from "../../../../components/inputs/BoldInput";
import tabs from "../tabs";
import { columns, paymentProfileColumns } from "./columns";
import InviteNewButton from "./components/InviteNewButton";
import InviteMembers from "./components/InviteMembers";
import {
  fetchMembers,
  getMembers,
  membersTableSelectors,
  membersTableActions,
  updatePaymentProfileRelationship,
  getGroup,
  fetchGroup
} from "../../../../modules/groups";
import { openSnackbar, openDialog } from "../../../../modules/ui";
import formatPennies from "../../../../helpers/format/formatPennies";
import { fetchTerms } from "../../../../modules/factoringClient";

const memberForm = (value: any) => {
  const dispatch = useDispatch();
  return {
    enableReinitialize: true,
    initialValues: {
      brokers_percent: get(value, ["relationship", "factoring_fee_split_rate"]),
      discount_rate: get(value, ["relationship", "discount_rate"]),
      relationship_id: get(value, ["relationship", "id"]),
      carrier_limit: get(value, [
        "relationship",
        "funding_request_payout_limit"
      ]),
      payout_days: get(value, ["relationship", "payout_days"])
    },
    onSubmit: async (
      { id, brokers_percent, payout_days, carrier_limit }: any,
      { setSubmitting }: any
    ) => {
      try {
        const factoringId = get(value, ["relationship", "factoring_id"]);
        const relationshipId = get(value, ["relationship", "id"]);
        await dispatch(
          updatePaymentProfileRelationship(factoringId, relationshipId, {
            factoring_fee_split_rate: brokers_percent,
            payout_days,
            funding_request_payout_limit: carrier_limit
          },{ send_update_email: true })
        );
        dispatch(openSnackbar("success", "Updated Group Member!"));
      } catch (err) {
        dispatch(openSnackbar("error", head(err) || ""));
      } finally {
        setSubmitting(false);
      }
    }
  };
};

const Members = (props: any): JSX.Element => {
  const dispatch = useDispatch();
  const id = get(props, ["match", "params", "id"], "");
  const group = useSelector(getGroup(id));
  const [options, setOptions] = useState([]);
  const [onlyPaymentProfile, setOnlyPaymentProfile] = useState(false);
  useEffect((): void => {
    dispatch(
      fetchMembers(id, 20, "-created", { payment_profiles: onlyPaymentProfile })
    );
    dispatch(fetchGroup(id));
  }, [id, onlyPaymentProfile]);
  useEffect((): void => {
    async function f() {
      const response = await dispatch(fetchTerms(get(group, "factoring_id")));
      // @ts-ignore
      setOptions(response.filter(term => term.enable_payment_profile));
    }
    f();
  }, [id, onlyPaymentProfile]);
  const members = useSelector(getMembers).map((member: any) => {
    const paymentId = get(member, ["profiles", "payment", "id"]);
    const factoringId = get(member, ["profiles", "factoring", "id"]);

    return {
      ...member,
      dba: paymentId ? (
        <Link to={`/admin/payment/${paymentId}/profile`}>
          {member.company_name}
        </Link>
      ) : (
        <Link to={`/admin/factorclients/${factoringId}/profile`}>
          {member.company_name}
        </Link>
      ),
      carriers_rate: ({ values }: any) => {
        return <Typography>{`${values.discount_rate}%`}</Typography>;
      },
      payout_days: ({ values, handleChange, handleBlur }: any) => (
        <Select
          fullWidth
          name="payout_days"
          value={values.payout_days}
          onChange={handleChange}
          onBlur={handleBlur}
          selections={options
            // @ts-ignore
            .map(option => option.payout_days)
            .map(value => {
              if (value === 0) {
                return 1;
              }
              return value;
            })
            .map(days => ({
              text: `${days} Days`,
              value: `ach_${days}_day`
            }))}
        />
      ),
      carrier_limit: ({ values, handleBlur, handleChange }: any) => (
        <Select
          fullWidth
          name="carrier_limit"
          value={values.carrier_limit}
          onChange={handleChange}
          onBlur={handleBlur}
          selections={[
            500000,
            1500000,
            2000000,
            3000000,
            5000000,
            10000000,
            20000000,
            30000000,
            50000000,
            70000000,
            100000000
          ].map(option => ({
            value: option,
            text: formatPennies(option)
          }))}
        />
      ),
      broker_percent: ({
        values,
        handleChange,
        handleBlur
      }: any): JSX.Element => {
        return (
          <Select
            name="brokers_percent"
            fullWidth
            value={values.brokers_percent}
            onChange={handleChange}
            onBlur={handleBlur}
            selections={[
              {
                text: "0%",
                value: "0"
              },
              {
                text: "50%",
                value: "50"
              },
              {
                text: "100%",
                value: "100"
              }
            ]}
          />
        );
      },
      mc: get(member, ['profiles', 'payment', 'company_mc']) || get(member, ['profiles', 'factoring', 'company_mc']),
      dot: get(member, ['profiles', 'payment', 'company_dot']) || get(member, ['profiles', 'factoring', 'company_dot']),
      action: ({ handleSubmit }: any): JSX.Element => (
        <Grid container>
          <Grid item>
            <Tooltip title="Save Edits">
              <IconButton
                style={{ width: "48px", height: "48px" }}
                onClick={(): void => handleSubmit()}
              >
                <FontAwesomeIcon color={colors.green} icon={faSave} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )
    };
  });

  return (
    <Can
      perform="admin-groups:view"
      yes={(): JSX.Element => (
        <InternalLayout title="Groups">
          <Grid container direction="column" spacing={16}>
            <Grid item style={{ width: "100%" }}></Grid>
            <Grid item style={{ width: "100%" }}>
              <NavigationTabs tabs={tabs(id)} value={1} />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Paper style={{ padding: "16px" }}>
                <Grid container direction="row-reverse" justify="space-between">
                  <Grid item>
                    <InviteNewButton
                      onClick={useCallback(
                        () => dispatch(openDialog("group-invite-member")),
                        [dispatch]
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <CheckBox
                      value={onlyPaymentProfile}
                      setFieldValue={() =>
                        setOnlyPaymentProfile(!onlyPaymentProfile)
                      }
                      label="Only Payment Profile"
                    />
                  </Grid>
                </Grid>
                <Table
                  columns={onlyPaymentProfile ? paymentProfileColumns : columns}
                  rows={members}
                  isLoading={useSelector(membersTableSelectors.getIsLoading)}
                  handleSort={useCallback(
                    (...args: any[]) =>
                      dispatch(membersTableActions.handleSort(...args)),
                    [dispatch]
                  )}
                  sortDirection={useSelector(
                    membersTableSelectors.getSortDirection
                  )}
                  sortBy={useSelector(membersTableSelectors.getSortBy)}
                  count={useSelector(membersTableSelectors.getCount)}
                  page={useSelector(membersTableSelectors.getPage)}
                  rowsPerPage={useSelector(
                    membersTableSelectors.getRowsPerPage
                  )}
                  handleChangePage={useCallback(
                    (...args: any[]) =>
                      dispatch(membersTableActions.handleChangePage(...args)),
                    [dispatch]
                  )}
                  rowsPerPageOptions={useSelector(
                    membersTableSelectors.getRowsPerPageOptions
                  )}
                  handleChangeRowsPerPage={useCallback(
                    (...args: any[]) =>
                      dispatch(
                        membersTableActions.handleChangeRowsPerPage(...args)
                      ),
                    [dispatch]
                  )}
                  filter
                  filters={useSelector(membersTableSelectors.getFilters)}
                  handleFilterChange={useCallback(
                    (...args: any[]) =>
                      dispatch(membersTableActions.handleSearchFilter(...args)),
                    [dispatch]
                  )}
                  allowEmpty
                  formikRow={memberForm}
                />
              </Paper>
            </Grid>
          </Grid>
          <InviteMembers groupId={id} />
        </InternalLayout>
      )}
      no={(): JSX.Element => <Redirect to="/" />}
    />
  );
};

export default Members;

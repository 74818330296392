import { denormalize } from "normalizr";
import { purchasesList } from "../../api/schema";
import createCSV from "../../helpers/createCSV";

export default ids => async (dispatch, getState) => {
  const state = getState();
  const denormalized = denormalize(
    { ids },
    { ids: [purchasesList] },
    state.entities
  );

  const purchasesItems = denormalized.ids;

  await createCSV(purchasesItems);
};

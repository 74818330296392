import React, { useEffect, useState } from "react";
import { ClickAwayListener, MenuList, Paper, Popper } from "@material-ui/core";
import { get } from "lodash";
import BoldInput from "../BoldInput";
import ConnectedCarrierItem from "./ConnectedCarrierItem";
import getConnectedCarriers from "./getConnectedCarriers";

// interface Debtor {
//   id: string;
//   company_name: string;
//   dba: string;
//   mc: string;
//   dot: string;
//   credit_approved: string;
//   email: string;
// }

interface Props {
  factoringId: string;
  label: string;
  setFieldValue: Function;
  setFieldTouched: Function;
  name: string;
  carrierName?: string;
}

function ConnectedCarrierDropDown({
  factoringId,
  label,
  setFieldValue,
  setFieldTouched,
  name,
  carrierName,
  ...props
}: Props & any): JSX.Element {
  const [debtors, setDebtors] = useState([]);
  const [closed, setClosed] = useState(true);
  const [query, setQuery] = useState(carrierName || "");

  useEffect((): void => {
    if (carrierName !== query && (query === undefined || query === "")) {
      setQuery(carrierName || get(props, ["value", "payment_profile", "company_name"], ""));
    }
  }, [carrierName]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect((): void => {
    const searchForCarriers = async (): Promise<void> => {
      const search = await getConnectedCarriers({
        factoring: factoringId,
        name: query
      });
      setDebtors(search.slice(0, 5));
    };
    searchForCarriers();
  }, [query]);

  return (
    <React.Fragment>
      <BoldInput
        {...props}
        fullWidth
        label={label}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          if (!anchorEl) {
            setAnchorEl(event.currentTarget);
          }
          setQuery(event.target.value);
          setClosed(false);
          setFieldTouched(name, true);
        }}
        onBlur={(): void => {
          setFieldTouched(name, true);
        }}
        value={query}
      />
      {anchorEl && (
        <ClickAwayListener
          onClickAway={(): void => {
            setClosed(true);
            setAnchorEl(null);
          }}
        >
          <Popper
            open={!closed}
            anchorEl={anchorEl}
            style={{ zIndex: 2000 }}
            placement="bottom-start"
          >
            <Paper>
              <MenuList>
                {debtors.map(
                  (carrier: any): JSX.Element => {
                    return (
                      <ConnectedCarrierItem
                        id={carrier.id}
                        key={carrier.id}
                        email={get(carrier, ["payment_profile", "owner_email"])}
                        companyName={get(carrier, ["payment_profile", "company_name"])}
                        dba={carrier.dba}
                        mc={get(carrier, ["payment_profile", "company_mc"])}
                        dot={get(carrier, ["payment_profile", "company_dot"])}
                        approved={carrier.credit_approved === "approved"}
                        status={get(carrier, ["payment_profile", "status"])}
                        onClick={(): void => {
                          setFieldValue(name, carrier);
                          setQuery(get(carrier, ["payment_profile", "company_name"]));
                          setClosed(true);
                        }}
                      />
                    );
                  }
                )}
              </MenuList>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
}

export default ConnectedCarrierDropDown;

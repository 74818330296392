import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";
import { addEntities } from "../../entities";
import {
    FETCH_QUERY_OBLIGATIONS_FAILURE,
    FETCH_QUERY_OBLIGATIONS_REQUEST,
    FETCH_QUERY_OBLIGATIONS_SUCCESS
} from "../types";

const debounced = debounce(
    async (dispatch, schema, api, contractId, limit, offset, ordering, filters) => {
        dispatch(
            createAction(FETCH_QUERY_OBLIGATIONS_REQUEST)({
                contractId,
                limit,
                offset,
                ordering,
                filters
            })
        );
        const response = await api.factoring.getObligations(
            contractId,
            ordering,
            limit,
            offset,
            filters
        );
        const data = normalize(response.results, [schema.obligations]);
        dispatch(addEntities(data.entities));
        dispatch(
            createAction(FETCH_QUERY_OBLIGATIONS_SUCCESS)({
                items: data.result,
                count: response.count,
                contractId,
                limit,
                offset,
                ordering,
                filters
            })
        );
        return response;
    },
    1000,
    { trailing: true }
);

export default (contractId, ordering, limit, offset, filters = {}) => async (
    dispatch,
    getState,
    { api, schema }
) => {
    try {
        await debounced(
            dispatch,
            schema,
            api,
            contractId,
            limit,
            offset,
            ordering,
            filters
        );
    } catch (err) {
        dispatch(createAction(FETCH_QUERY_OBLIGATIONS_FAILURE)(err));
        throw err;
    }
};

import { denormalize } from "normalizr";
import { get } from "lodash";
import { plaidAccount } from "../../../api/schema";
import formatPlaid from "../../../helpers/format/formatPlaid";

const getPlaidUser = state => {
  try {
    const id = get(state, "factoringClient.user");
    const items = get(state, `factoringClient.plaidUsers.${id}`);

    const denormalized = denormalize(
      { items },
      { items: [plaidAccount] },
      state.entities
    );

    return formatPlaid(denormalized.items);
  } catch (err) {
    return {};
  }
};

export default getPlaidUser;

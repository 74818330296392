import axios, { AxiosRequestConfig } from "axios";

export const upload = async (data: Record<string, any>): Promise<any> => {
  const formData = new FormData();
  formData.append("filename", data.file);
  ["user_label", "category", "user"].forEach(key => {
    if (data[key]) {
      formData.append(key, data[key]);
    }
  });
  const options: AxiosRequestConfig = {
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
    url: `/user/profile/documents/upload/`
  };
  const response = await axios(options);
  return response.data;
};

export default {
  upload
};

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import colors from "../theme/colors";

interface Props {
  invoiceNumber: string;
  discountRate: string;
  paymentMethod: string;
}

const InvoiceRow = ({
  invoiceNumber,
  discountRate,
  paymentMethod
}: Props): JSX.Element => {
  return (
    <React.Fragment>
      <Grid container direction="row" spacing={8}>
        <Grid item>
          <Typography style={{ fontSize: 14 }} inline>
            {`${invoiceNumber} `}
          </Typography>
        </Grid>
        {paymentMethod === "wire" && (
          <Grid item>
            <div style={{ fontSize: 17 }}>
              <FontAwesomeIcon icon={faCheck} color={colors.danger} />
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container direction="row">
        <Grid item>
          <Typography style={{ fontSize: 12 }}>
            {`(${discountRate}%)`}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default InvoiceRow;

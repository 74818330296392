import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import isUndefined from "lodash/isUndefined";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/Table";
import {
  getPendingInvite,
  fetchPendingInvite
} from "../../../../modules/groups";
import columns from "./columns";
import { moveToStepByName } from "../../../../modules/signUp";
import InvitationConfirmModal from "../components/InvitationConfirmModal";
import { getDialog, openDialog } from "../../../../modules/ui";
import segmentsEvents from "../../../../helpers/segmentsEvents";

export default function() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [state, setstate] = useState("initialState");

  const groupId = get(params, "groupId");

  const pendingInvite = useSelector(getPendingInvite);
  const dialog = useSelector(getDialog);
  const rows = (pendingInvite.data || []).map(o => ({
    ...o,
    boarding: (
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          dispatch(moveToStepByName("welcome"));
          window.analytics.track(segmentsEvents.HAULPAY_PAYMENT_PROFILE_INVITED_IC_PORTAL_PENDING_BOARDING);
          history.push(`/dashboard/ic_portal/invited/${o.token}`);
        }}
      >
        Next
      </Button>
    )
  }));
  useEffect(() => {
    const asyncFunction = async () => {
      await dispatch(fetchPendingInvite());
    };
    asyncFunction();
  }, []);

  useEffect(() => {
    if (
      groupId &&
      isUndefined(find(rows, ["group_id", groupId])) &&
      dialog.variant !== "invite-confirmation" &&
      state === "initialState"
    ) {
      dispatch(openDialog("invite-confirmation"));
      setstate("secondState");
    }
  }, [groupId, rows]);

  return (
    <div style={{ width: "100%" }}>
      <Table
        columns={columns}
        rows={rows}
        allowEmpty
        isLoading={pendingInvite.loading}
        expanded
      />
      {dialog.variant === "invite-confirmation" && (
        <InvitationConfirmModal
          open={dialog.show}
          groupId={groupId}
          handleExit={() => history.push("/dashboard/ic_portal/")}
          handleSubmit={token => {
            dispatch(moveToStepByName("welcome"));
            history.push(`/dashboard/ic_portal/invited/${token}`);
          }}
        />
      )}
    </div>
  );
}

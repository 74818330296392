import React from "react";
import { withStateHandlers } from "recompose";
import { DateTime } from "luxon";
import {
  Checkbox,
  createStyles,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import {
  get,
  has,
  isArray,
  isEmpty,
  join,
  omit,
  split,
  sumBy,
  zip,
  find,
  isUndefined
} from "lodash";
import { Field, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

import { makeStyles } from "@material-ui/styles";
import colors from "../../theme/colors";
import BoldInput, { Select, Date, Pennies } from "../inputs/BoldInput";
import formatCurrency from "../../helpers/format/formatCurrency";
import HeaderCell from "./HeaderCell";

const TableCell = withStyles(
  createStyles({
    root: {
      padding: 0,
      paddingLeft: 4,
      paddingTop: 4,
      paddingBottom: 4
    }
  })
)(MuiTableCell);

const handleDateChange = handleChange => (
  name,
  format = "toDate"
) => datetime => {
  handleChange({
    target: {
      name: `${name}`,
      value: format === "toDate" ? datetime.toISODate() : datetime.toISO()
    }
  });
};

function Filters({ padding, column, handleFilterChange, filters }) {
  const filterKey = column.filter_key;
  switch (column.filter) {
    case "penny":
      return (
        <TableCell
          key={column.filter_key}
          style={{ margin: 10 }}
          padding={padding}
          colSpan={column.colSpan || 1}
        >
          <BoldInput
            name={filterKey}
            placeholder="Search..."
            onChange={event => {
              handleFilterChange({
                target: {
                  name: filterKey,
                  value: parseFloat(event.target.value).toFixed(2)
                }
              });
            }}
            value={(filters[filterKey] || '').replace(/\.00$/, '') || 0}
          />
        </TableCell>
      );
    case "text":
      return (
        <TableCell
          key={column.filter_key}
          style={{ margin: 10 }}
          padding={padding}
          colSpan={column.colSpan || 1}
        >
          <BoldInput
            name={filterKey}
            placeholder="Search..."
            onChange={handleFilterChange}
            value={filters[filterKey] || ""}
          />
        </TableCell>
      );
    case "date":
      return (
        <TableCell
          key={filterKey}
          style={{ margin: 10 }}
          padding={padding}
          colSpan={column.colSpan || 1}
        >
          <Date
            name={filterKey}
            format="yyyy-MM-dd"
            type="date"
            onChange={handleDateChange(handleFilterChange)(
              filterKey,
              column.filter_format
            )}
            disableFuture
            value={
              filters[filterKey] !== "" && filters[filterKey]
                ? DateTime.fromISO(filters[filterKey]).toISO()
                : null
            }
          />
        </TableCell>
      );
    case "dropdown":
      if (isArray(filterKey)) {
        return (
          <TableCell
            key={join(filterKey)}
            style={{ margin: 10 }}
            padding={padding}
            colSpan={column.colSpan || 1}
          >
            <Select
              value={filters[join(filterKey)] || ""}
              onChange={event => {
                const names = split(event.target.name, ",");
                const values = split(event.target.value, ",");
                zip(names, values).forEach(function(filter) {
                  handleFilterChange({
                    target: {
                      name: filter[0],
                      value: filter[1]
                    }
                  });
                });
                handleFilterChange(event);
              }}
              fullWidth
              name={join(filterKey)}
              selections={column.filter_values.map(option => ({
                text: option.label,
                value: option.value
              }))}
            />
          </TableCell>
        );
      }
      return (
        <TableCell
          key={filterKey}
          style={{ margin: 10 }}
          padding={padding}
          colSpan={column.colSpan || 1}
        >
          <Select
            value={filters[filterKey] || column.filter_value || ""}
            onChange={event => {
              if (column.filter_update_value) {
                column.filter_update_value(event.target.value);
              }
              handleFilterChange(event, filterKey);
            }}
            fullWidth
            name={filterKey}
            selections={column.filter_values.map(option => ({
              text: option.label,
              value: option.value
            }))}
            selectProps={column.selectProps}
          />
        </TableCell>
      );
    case "component":
      return (
        <TableCell
          key={filterKey}
          style={{ margin: 10 }}
          padding={padding}
          colSpan={column.colSpan || 1}
        >
          {React.createElement(column.filter_component, {
            onChange: event => {
              column.filter_value = event.target.value;
              handleFilterChange(event);
            },
            value: column.filter_value,
            name: filterKey
          })}
        </TableCell>
      );
    default:
      return <TableCell colSpan={column.colSpan || 1} padding={padding} />;
  }
}

const useStyles = makeStyles(() => ({
  customTooltip: {
    fontSize: "15px",
    color: colors.danger,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.grayBg
  }
}));

function SimpleTable(props) {
  const classes = useStyles();
  const {
    columns = [],
    rows = [],

    select,
    handleSelectAllClick,
    allSelected,
    isSelected = () => false,
    handleSelect,

    form,
    handleChange = () => {},
    handleSubmit = () => {},
    setFieldValue = () => {},
    values = {},

    formikRow,

    isLoading = false,
    colSpan: ColSpanProps,
    handleSort = () => {},
    sortDirection,
    sortBy,
    columnStyle = {},
    count,
    page,
    rowsPerPage,
    handleChangePage,
    rowsPerPageOptions,
    handleChangeRowsPerPage,
    collapse = false,
    filter,
    handleFilterChange,
    handleClickExpand,
    expanded,
    expandableView,
    padding = "dense",
    allowEmpty,
    filters,
    bulkError,
    rowsForm = [],
    formKeys = [],
    formTableProps
  } = props;

  const rowProps = id => {
    let toPass = {
      id,
      isSelected: isSelected(id),
      handleChange,
      handleSubmit,
      setFieldValue,
      values
    };
    if (!select) {
      toPass = omit(toPass, ["isSelected"]);
    }
    if (!form) {
      toPass = omit(toPass, [
        "setFieldValue",
        "handleChange",
        "handleSubmit",
        "values"
      ]);
    }
    return toPass;
  };

  const colSpanNumber = sumBy(columns, "colSpan") || 0;
  return (
    <Grid container item lg={12} spacing={24}>
      <Grid item lg={12}>
        <Table padding={padding}>
          <TableHead>
            <TableRow>
              {collapse && <TableCell />}
              {select && (
                <TableCell padding={padding}>
                  <Checkbox
                    checked={allSelected(rows.length)}
                    onChange={event =>
                      handleSelectAllClick(rows.map(row => row.id), event.target?.checked)
                    }
                  />
                </TableCell>
              )}
              {columns.map(column => (
                <TableCell colSpan={column.colSpan || 1} padding={padding}>
                  <HeaderCell
                    key={column.key}
                    column={column}
                    handleSort={handleSort(
                      column.sort && column.sort === true
                        ? column.filter_key || column.key
                        : column.sort
                    )}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    columnStyle={columnStyle}
                  />
                </TableCell>
              ))}
            </TableRow>
            {filter && (
              <TableRow>
                {select && <TableCell />}
                {collapse && <TableCell />}
                {columns.map(column => (
                  <Filters
                    column={column}
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    padding={padding}
                  />
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {!isLoading &&
              rowsForm.map((row, index) => {
                let initialVales = {};
                formKeys.map(key => {
                  initialVales = {
                    ...initialVales,
                    [key]: get(row, `${key}.initialValue`, undefined)
                  };
                });
                return (
                  <TableRow
                    key={row.id}
                    className="form_row"
                    style={{
                      backgroundColor: "transparent"
                    }}
                  >
                    <Formik
                      // enableReinitialize
                      initialValues={{ ...initialVales, id: row.id }}
                      {...formTableProps}
                      onSubmit={(...args) =>
                        get(formTableProps, "onSubmit", () => ({}))(
                          row.id,
                          ...args
                        )
                      }
                      key={row.id}
                    >
                      {formProps => {
                        return (
                          <>
                            {select && (
                              <TableCell padding={padding}>
                                <Checkbox
                                  checked={isSelected(row.id)}
                                  onClick={event => {
                                    formProps.setFieldValue(
                                      "checked",
                                      isSelected(row.id)
                                    );
                                    handleSelect(row.id, event.target?.checked);
                                  }}
                                />
                              </TableCell>
                            )}
                            {columns.map(column => {
                              const { input: Input, component: Component } =
                                row[column.formKey] || {};
                              let CustomInputComponent;
                              if (!isUndefined(column.formKey) && Input) {
                                CustomInputComponent = ({
                                  field,
                                  form: { touched, errors },
                                  ...inputProps
                                }) => (
                                  <div>
                                    <Input {...field} {...inputProps} />
                                    {touched[field.name] &&
                                      errors[field.name] && (
                                        <div className="error">
                                          {errors[field.name]}
                                        </div>
                                      )}
                                  </div>
                                );
                              }
                              if (typeof row[column.formKey] === "function") {
                                return (
                                  <TableCell
                                    className={column.key}
                                    padding={padding}
                                    colSpan={column.colSpan || 1}
                                  >
                                    {get(
                                      row,
                                      column.formKey,
                                      "-"
                                    )({ ...formProps, index: row.id })}
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell
                                  className={column.key}
                                  padding={padding}
                                  colSpan={column.colSpan || 1}
                                >
                                  {isUndefined(column.formKey) ? (
                                    get(row, `${column.key}.default`, "-")
                                  ) : (
                                    <Field
                                      render={Component || CustomInputComponent}
                                      name={column.formKey}
                                    />
                                  )}
                                </TableCell>
                              );
                            })}
                          </>
                        );
                      }}
                    </Formik>
                  </TableRow>
                );
              })}
            {!isLoading &&
              rows.map((row, index) => {
                const { error = "" } =
                  find(bulkError, object => object.id === row.id) || {};
                const Row = (additional = {}) => (
                  <React.Fragment>
                    <TableRow
                      className="table_row"
                      key={row.id}
                      style={{
                        backgroundColor: error ? "#F26F63" : "transparent"
                      }}
                    >
                      {collapse && (
                        <TableCell padding="checkbox">
                          {expanded === index ? (
                            <IconButton onClick={() => handleClickExpand(-1)}>
                              <FontAwesomeIcon icon={faMinus} size="xs" />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => handleClickExpand(index)}
                            >
                              <FontAwesomeIcon icon={faPlus} size="xs" />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                      {select && (
                        <TableCell padding={padding}>
                          <Checkbox
                            checked={isSelected(row.id)}
                            onClick={event =>
                              handleSelect(row.id, event.target?.checked)
                            }
                          />
                        </TableCell>
                      )}
                      {columns.map(column => (
                        <TableCell
                          className={column.key}
                          padding={padding}
                          colSpan={column.colSpan || 1}
                        >
                          {typeof row[column.key] === "function"
                            ? get(
                                row,
                                column.key,
                                column.default
                              )({
                                ...rowProps(row.id),
                                ...additional
                              })
                            : has(column, "format")
                            ? Array.isArray(column.key)
                              ? column.format.apply(
                                  this,
                                  column.key.map(object =>
                                    get(row, object, column.default)
                                  )
                                )
                              : column.format(
                                  get(row, column.key, column.default)
                                )
                            : get(row, column.key, column.default)}
                        </TableCell>
                      ))}
                      {error ? (
                        <TableCell>
                          <Tooltip
                            title={error}
                            classes={{ tooltip: classes.customTooltip }}
                          >
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              size="2x"
                              color="red"
                            />
                          </Tooltip>
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                    {collapse && expanded === index && (
                      <TableRow key={row.id}>
                        <TableCell
                          style={{
                            border: "none"
                          }}
                          align="center"
                          variant="body"
                          padding="default"
                          colSpan={
                            ColSpanProps +
                              (select ? 1 : 0) +
                              colSpanNumber +
                              1 ||
                            columns.length +
                              (select ? 1 : 0) +
                              colSpanNumber +
                              1
                          }
                        >
                          <Grid
                            container
                            xl={12}
                            style={{
                              height: 300,
                              backgroundColor: colors.backgroundTable,
                              overflowX: "scroll"
                            }}
                          >
                            {expandableView(row.id)}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
                if (formikRow) {
                  const form = formikRow(row, index);
                  return (
                    <Formik
                      {...form}
                      initialValues={{ ...form.initialValues, id: row.id }}
                    >
                      {formProps => Row(formProps)}
                    </Formik>
                  );
                }
                return Row();
              })}
          </TableBody>
        </Table>
        {count && !isLoading ? (
          <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            nextIconButtonProps={{
              id: "next"
            }}
            backIconButtonProps={{
              id: "previous"
            }}
          />
        ) : null}
      </Grid>
      {isLoading && (
        <Grid
          item
          lg={12}
          style={{
            padding: 20
          }}
        >
          <LinearProgress />
        </Grid>
      )}
      {allowEmpty && !isLoading && isEmpty(rows) && (
        <Grid
          container
          item
          lg={12}
          style={{ width: "100%" }}
          justify="center"
          alignItems="center"
        >
          <Typography variant="subtitle1" color="primary">
            No Data Available
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default withStateHandlers(
  ({ expanded = -1 }) => ({
    expanded
  }),
  {
    handleClickExpand: () => expanded => ({ expanded })
  }
)(SimpleTable);

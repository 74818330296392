import React from "react";
import { Redirect } from "react-router-dom";
import { Paper } from "@material-ui/core";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import Table from "../../../../components/Table";
import NavigationTabs from "../../../../components/NavigationTabs";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";

const ConnectDebtor = ({
  columns,
  handleRoute,
  tabs,
  navIndex,
  debtors,
  loading,
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  sortDirection,
  sortBy,
  filters,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleFilterChange,
  dialog,
  closeDialog,
  handleConfirmConnectDebtor,
  handleDeleteDebtorRelation
}) => {
  return (
    <Can
      perform="admin-factorclients:view"
      yes={() => (
        <InternalLayout title="Factor Client">
          <NavigationTabs
            tabs={tabs}
            value={navIndex}
            handleRoute={handleRoute}
          />
          <Paper style={{ paddingLeft: 16, marginTop: 32 }}>
            <Table
              sort
              filter
              columns={columns}
              rows={debtors}
              isLoading={loading}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              sortDirection={sortDirection}
              sortBy={sortBy}
              filters={filters}
              handleSort={handleSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleFilterChange={handleFilterChange}
              allowEmpty
            />
          </Paper>
          {dialog.variant === "confirm-create-relation" && (
            <ConfirmDialog
              open={dialog.show}
              title={dialog.title}
              message={dialog.message}
              handleConfirm={handleConfirmConnectDebtor}
              handleExit={() => closeDialog()}
            />
          )}
          {dialog.variant === "confirm-delete-relation" && (
            <ConfirmDialog
              open={dialog.show}
              title={dialog.title}
              message={dialog.message}
              handleConfirm={handleDeleteDebtorRelation}
              handleExit={() => closeDialog()}
            />
          )}
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
};

export default ConnectDebtor;

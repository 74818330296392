import React from 'react';
import { FormControlLabel, FormGroup, List, Switch, Typography } from '@material-ui/core';
import ListItem from '../../../../../components/ListItem';
import BoldInput, { Select } from '../../../../../components/inputs/BoldInput';
import Card from '../../../../../components/Card';
import ProfileSection from '../../../../../components/ProfileSection';
import MailingAddressInfo from '../../../../../components/MailingAddressInfo';

export default ({
  routingNumber,
  accountNumber,
  accountType,
  remittanceEmail,
  handleChange,
  handleBlur,
  setFieldValue,
  noaRequestEmail,
  uccLorRequestEmail,
  enablePayViaCheck,
  mailingAddressOne,
  mailingAddressTwo,
  mailingAddressCity,
  mailingAddressState,
  mailingAddressZip,
  mailingAddressCountry
}) => (
  <Card style={{ height: '100%' }}>
    <List dense style={{ width: '100%' }}>
      <ListItem>
        <ProfileSection>Payment</ProfileSection>
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Account Number"
          name="accountNumber"
          value={accountNumber}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Routing Number"
          name="routingNumber"
          value={routingNumber}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <Select
          value={accountType}
          onChange={handleChange}
          fullWidth
          name="accountType"
          label="Account Type"
          selections={[
            {
              text: 'Checking',
              value: 'CHECKING'
            },
            {
              text: 'Savings',
              value: 'SAVINGS'
            }
          ]}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="Remittance Email"
          name="remittanceEmail"
          value={remittanceEmail}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="NOA Request Email"
          name="noaRequestEmail"
          value={noaRequestEmail}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          label="UCC/LOR Request Email"
          name="uccLorRequestEmail"
          value={uccLorRequestEmail}
          onChange={handleChange}
        />
      </ListItem>
      <ListItem>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={enablePayViaCheck}
                onChange={() => {
                  setFieldValue(
                    'enablePayViaCheck',
                    !enablePayViaCheck
                  );
                }}
                value="enablePayViaCheck"
              />
            }
            label="Enable Pay Via Check"
          />
        </FormGroup>
      </ListItem>
      <MailingAddressInfo
        handleBlur={handleBlur}
        handleChange={handleChange}
        values={{
          mailingAddressOne,
          mailingAddressTwo,
          mailingAddressCity,
          mailingAddressZip,
          mailingAddressCountry,
          mailingAddressState
        }}
      />
    </List>
  </Card>
);

import { get, values, includes } from "lodash";
import { denormalize } from "normalizr";
import { fundingRequest } from "../../../api/schema";

const getReceivableIdsFromFundingRequests = state => ids => {
  try {
    const denormalized = denormalize(
      { frs: ids },
      { frs: [fundingRequest] },
      state.entities
    );

    const fundings = denormalized.frs;

    let receivables = fundings
      .map(funding => get(funding, "receivable_id", ""))
      .filter(rec => rec !== "");

    if (receivables.length === 0) {
      receivables = values(get(state, ["entities", "receivables"], {}))
        .filter(rec => includes(ids, rec.funding_request))
        .map(rec => rec.id);
    }

    return receivables;
  } catch (err) {
    return [];
  }
};

export default getReceivableIdsFromFundingRequests;

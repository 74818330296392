import { denormalize } from "normalizr";
import { get, memoize } from "lodash";

import { lineItemCategory } from "../../../api/schema";

export default memoize(state => {
  try {
    const ids = get(state, "factoring.lineItemCategories", []);
    const denormalized = denormalize(
      { categories: ids },
      { categories: [lineItemCategory] },
      state.entities
    );
    return denormalized.categories.map(
      ({ id, name, applied_against: appliedAgainst, ...values }) => ({
        id,
        name,
        appliedAgainst,
        ...values
      })
    );
  } catch (err) {
    return [];
  }
});

import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { debtorProfile } from "../../../api/schema";

export default memoize(state => debtorId => {
  try {
    const profile = get(state, `factoring.debtorProfile.${debtorId}`, {});

    const denormalized = denormalize(
      { profile },
      { profile: debtorProfile },
      state.entities
    );

    return denormalized.profile;
  } catch (err) {
    return {};
  }
});

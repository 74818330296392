import { compose, lifecycle, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";
import { find, findIndex } from "lodash";
import withSelect from "../../../../components/withSelect";

import {
  clearPurchaseItems,
  fetchOutstandingTotals,
  fetchReceivables,
  getDebtors,
  getOutstandingTotals,
  receivablesDebtorsActionsTable,
  selectorsReceivablesDebtorsTable
} from "../../../../modules/finances";

import {
  clearOpenFundingRequests,
  getIsLoading as openFundingRequestsIsLoading,
  getOpenFundingRequests,
  queryOpenFundingRequests
} from "../../../../modules/debtor";

import { getDialog } from "../../../../modules/ui";

import DebtorBalances from "./DebtorBalances";
import tabs from "../tabs";

const mapStateToProps = state => ({
  isLoading: selectorsReceivablesDebtorsTable.getIsLoading(state),
  sortDirection: selectorsReceivablesDebtorsTable.getSortDirection(state),
  sortBy: selectorsReceivablesDebtorsTable.getSortBy(state),
  count: selectorsReceivablesDebtorsTable.getCount(state),
  page: selectorsReceivablesDebtorsTable.getPage(state),
  rowsPerPage: selectorsReceivablesDebtorsTable.getRowsPerPage(state),
  rowsPerPageOptions: selectorsReceivablesDebtorsTable.getRowsPerPageOptions(
    state
  ),
  filters: selectorsReceivablesDebtorsTable.getFilters(state),
  openFundingRequests: getOpenFundingRequests(state),
  openFundingRequestsIsLoading: openFundingRequestsIsLoading(state),
  dialog: getDialog(state),
  outstandingTotals: getOutstandingTotals(state),
  debtors: getDebtors(state)
});

const mapDispatchToProps = {
  handleSort: receivablesDebtorsActionsTable.handleSort,
  handleChangePage: receivablesDebtorsActionsTable.handleChangePage,
  handleChangeRowsPerPage:
    receivablesDebtorsActionsTable.handleChangeRowsPerPage,
  handleSearchFilter: receivablesDebtorsActionsTable.handleSearchFilter,
  queryOpenFundingRequests,
  clearOpenFundingRequests,
  clearPurchaseItems,
  fetchOutstandingTotals,
  fetchReceivables
};

export default compose(
  withSelect,
  withStateHandlers(({ selectedReceivable = {} }) => ({ selectedReceivable }), {
    setSelectedReceivable: () => selectedReceivable => ({
      selectedReceivable
    })
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      selected,
      clearSelected,
      createReceivableCSV
    }) => ({
      tabs,
      statusIndex: findIndex(tabs, tab => pathname === tab.route) || 0,
      filterStatus: (find(tabs, tab => pathname === tab.route) || {}).value,
      handleRoute: route => push(route),
      handleCSV: () => createReceivableCSV(selected),
      handleSetStatus: async () => {
        clearSelected();
      }
    })
  ),
  lifecycle({
    async componentDidMount() {
      const {
        fetchReceivables,
        fetchOutstandingTotals,
        rowsPerPage
      } = this.props;
      await Promise.all([
        fetchOutstandingTotals(),
        fetchReceivables("-outstanding_balance", rowsPerPage, 0, {})
      ]);
    },
    async componentDidUpdate(prevProps) {
      const {
        statusIndex,
        fetchReceivables,
        fetchOutstandingTotals,
        fetchLineItemCategories,
        rowsPerPage,
        filters,
        dialog
      } = this.props;
      if (prevProps.dialog !== dialog && dialog.action === "Adjustments") {
        fetchLineItemCategories();
      }
      if (prevProps.statusIndex !== statusIndex) {
        await Promise.all([
          fetchOutstandingTotals(),
          fetchReceivables("-outstanding_balance", rowsPerPage, 0, filters)
        ]);
      }
    }
  })
)(DebtorBalances);

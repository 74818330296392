import React from "react";
import { compose } from "recompose";
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleUp,
  faCheck,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { withFormik } from "formik";
import { get } from "lodash";

import Colors from "../../../../../theme/colors";
import openFileDialog from "../../../../../helpers/openFileDialog";
import attachementsValidation from "./validation/attachments";

const UploadButton = ({ setFieldTouched, setFieldValue, uploadBtnRef }) => (
  <Grid container direction="row">
    <Button
      variant="outlined"
      color="secondary"
      mini
      onClick={() => {
        openFileDialog(uploadBtnRef);
      }}
    >
      <FontAwesomeIcon icon={faArrowAltCircleUp} style={{ margin: 5 }} />
      CSV
    </Button>
    <input
      type="file"
      name="attachments"
      ref={ref => (uploadBtnRef = ref)}
      style={{ display: "none" }}
      onBlur={() => setFieldTouched("file", true)}
      onChange={e => setFieldValue("file", e.currentTarget.files[0])}
    />
  </Grid>
);

const FileView = ({
  values,
  isUploading,
  handleSubmit,
  setFieldValue,
  errors
}) => {
  const file = get(values, ["file"], "");
  if (file) {
    return (
      <Grid item xs={12} style={{ marginLeft: 20 }} direction="row">
        <Typography inline variant="caption">
          {` ${file.name} `}
        </Typography>
        {!isUploading ? (
          <React.Fragment>
            <IconButton onClick={() => setFieldValue("file", "")}>
              <FontAwesomeIcon icon={faTimes} size="xs" color={Colors.danger} />
            </IconButton>
            <IconButton onClick={() => handleSubmit()}>
              <FontAwesomeIcon icon={faCheck} size="xs" color={Colors.green} />
            </IconButton>
          </React.Fragment>
        ) : (
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="xs"
            color={Colors.danger}
          />
        )}
        {errors.file && (
          <FormHelperText style={{ color: Colors.danger }}>
            {errors.file}
          </FormHelperText>
        )}
      </Grid>
    );
  }
  return <Grid />;
};

const ReceivablesAttachment = ({
  uploadBtnRef = React.createRef(),
  setFieldTouched,
  setFieldValue,
  values,
  isUploading,
  handleSubmit,
  errors
}) => {
  if (get(values, ["file"], "")) {
    return (
      <FileView
        values={values}
        isUploading={isUploading}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        errors={errors}
      />
    );
  }
  return (
    <UploadButton
      setFieldTouched={setFieldTouched}
      setFieldValue={setFieldValue}
      uploadBtnRef={uploadBtnRef}
    />
  );
};

export default compose(
  withFormik({
    mapPropsToValues: props => props,
    validationSchema: attachementsValidation,
    handleSubmit: async (
      values,
      { props: { createPaymentsFile, openSnackbar }, ...FormikBag }
    ) => {
      try {
        await createPaymentsFile(values);
        FormikBag.setFieldValue("file", "");
        openSnackbar("success", "File has been uploaded!");
      } catch (e) {
        openSnackbar("error", e.message);
      }
    }
  })
)(ReceivablesAttachment);

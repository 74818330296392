import { get } from "lodash";
import { denormalize } from "normalizr";
import { referralSource as referralSourceSchema } from "../../../api/schema";

export default state => {
  try {
    const referralSource = get(state, `factoring.referral_source.ids`, []);

    const denormalized = denormalize(
      { referralSource },
      { referralSource: [referralSourceSchema] },
      state.entities
    );

    return {
      items: denormalized.referralSource,
      ...get(state, `factoring.referral_source`, {})
    };
  } catch (err) {
    return [];
  }
};

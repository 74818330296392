import { MenuItem } from "@material-ui/core";
import React, { ChangeEventHandler } from "react";
import CustomTextField from "../../../../../components/inputs/CustomTextField";

const types = [
  {
    value: "check",
    label: "Check"
  },
  {
    value: "ach",
    label: "ACH"
  },
  {
    value: "wire",
    label: "Wire"
  }
];

interface Props {
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
  values: {
    type: string;
  };
  errors: {
    type?: string;
  };
  touched: {
    type?: boolean;
  };
}

const TypeInput = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched
}: Props): JSX.Element => (
  <div id="payment_type">
    <CustomTextField
      fullWidth
      select
      label="Type"
      name="type"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.type}
      error={!!errors.type && touched.type}
      helperText={!!errors.type && touched.type ? errors.type : ""}
    >
      {types.map(
        (option): JSX.Element => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )
      )}
    </CustomTextField>
  </div>
);

// @ts-ignore
export default TypeInput;

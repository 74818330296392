import { createAction } from "redux-starter-kit";
import {
  FETCH_HISTORY_SUCCESS,
  FETCH_HISTORY_REQUEST,
  FETCH_HISTORY_FAILURE
} from "../types";

const fetchHistory = id => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(FETCH_HISTORY_REQUEST)({}));
    const response = await api.factorCompany.getProfileHistory(id);
    dispatch(createAction(FETCH_HISTORY_SUCCESS)(response));
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_HISTORY_FAILURE)(err));
    throw err;
  }
};

export default fetchHistory;

import * as React from "react";
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';

function AntTextAreaInput(props: TextAreaProps) {
    return (
        <Input.TextArea
            {...props}
        />
    )
}
export default AntTextAreaInput;
import { denormalize } from "normalizr";
import { factoringProfile } from "../../../api/schema";

export default profile => state => {
  try {
    const denormalized = denormalize(
      { profile },
      { profile: factoringProfile },
      state.entities
    );

    return denormalized.profile;
  } catch (err) {
    return {};
  }
};

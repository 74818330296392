import { assign, concat, remove } from "lodash";
import {
  FETCH_REFERRAL_SOURCE_FAILURE,
  FETCH_REFERRAL_SOURCE_REQUEST,
  FETCH_REFERRAL_SOURCE_SUCCESS,
  ADD_REFERRAL_SOURCE_REQUEST,
  DELETE_REFERRAL_SOURCE_REQUEST
} from "../types";

const referral_source = (state, { type, payload }) => {
  switch (type) {
    case FETCH_REFERRAL_SOURCE_REQUEST:
      return {
        ...state,
        referral_source: {
          ids: {},
          loading: false
        }
      };
    case FETCH_REFERRAL_SOURCE_SUCCESS:
      return {
        ...state,
        referral_source: assign({}, state.referral_source, {
          ids: payload.items,
          loading: false
        })
      };
    case ADD_REFERRAL_SOURCE_REQUEST:
      return {
        ...state,
        referral_source: assign({}, state.referral_source, {
          ids: concat(payload.items, state.referral_source.ids)
        })
      };
    case DELETE_REFERRAL_SOURCE_REQUEST:
      const ids = remove(
        [...state.referral_source.ids],
        object => object !== payload.id
      );
      return {
        ...state,
        referral_source: assign({}, state.referral_source, {
          ids
        })
      };
    case FETCH_REFERRAL_SOURCE_FAILURE:
      return {
        ...state,
        referral_source: {
          ids: {},
          loading: false
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default referral_source;

const getStep = (
  requested: string | undefined,
  sent: string | undefined,
  placed: string | undefined,
  active: string | undefined
): -1 | 0 | 1 | 2 | 3 => {
  if (active) {
    return 3;
  }
  if (placed) {
    return 2;
  }
  if (sent) {
    return 1;
  }
  if (requested) {
    return 0;
  }
  return -1;
};

export default getStep;

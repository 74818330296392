import * as Yup from "yup";

export const client = Yup.object().shape({
  discount_rate: Yup.string()
    .required("Rate is a required field")
    .test("number", "Must be a Number", val => !Number.isNaN(Number(val))),
  minimum_fee: Yup.string()
    .required("Minimum Fee is a required field")
    .test("number", "Must be a Number", val => !Number.isNaN(Number(val)))
});

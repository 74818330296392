import { withState, compose } from "recompose";
import { Link } from "react-router-dom";
import {
  Paper,
  IconButton,
  Typography,
  Dialog,
  ListItem,
  List,
  ListItemText,
  createStyles,
  WithStyles
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import colors from "../../theme/colors";
import Can from "../Can";

const styles = createStyles({
  activeListItem: {
    borderLeft: `4px solid ${colors.secondary}`,
    borderRadius: "0px",
    "& $listItemText": {
      color: colors.secondary
    }
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      borderLeft: `4px solid ${colors.secondary}`,
      borderRadius: "0px"
    }
  }
});

interface Tab {
  route: string;
  label: string;
  permission?: string;
}

interface Props extends WithStyles<typeof styles> {
  tabs: Tab[];
  value: number;
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

const MobileTabs = ({
  tabs = [],
  value,
  opened,
  setOpened,
  classes
}: Props): JSX.Element => (
  <Paper style={{ padding: 10 }}>
    <IconButton
      aria-label="More"
      aria-haspopup="true"
      onClick={(): void => setOpened(true)}
    >
      <MoreVertIcon />
    </IconButton>
    <Typography variant="h6" inline>
      Menu
    </Typography>
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={opened}
      onClose={(): void => setOpened(false)}
    >
      <List component="nav" disablePadding>
        {tabs.map(
          (tab, index): JSX.Element => tab.permission ? (
            <Can perform={tab.permission} yes={() => (
              <Link to={tab.route} style={{ textDecoration: "none" }}>
                <ListItem
                  classes={{
                    selected: classes.activeListItem
                  }}
                  className={classes.listItem}
                  key={tab.label}
                  button
                  selected={index === value}
                >
                  <ListItemText primary={tab.label} />
                </ListItem>
              </Link>
            )} />
          ) : (
            <Link to={tab.route} style={{ textDecoration: "none" }}>
              <ListItem
                classes={{
                  selected: classes.activeListItem
                }}
                className={classes.listItem}
                key={tab.label}
                button
                selected={index === value}
              >
                <ListItemText primary={tab.label} />
              </ListItem>
            </Link>
          )
        )}
      </List>
    </Dialog>
  </Paper>
);

export interface Outter {
  tabs: Tab[];
  value: number;
}

export default compose<Props, Outter>(
  withState("opened", "setOpened", false),
  withStyles(styles)
)(MobileTabs);

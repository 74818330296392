import { createAction } from "redux-starter-kit";
import { get } from "lodash";
import {
  LOAD_USER_NOTES_ATTACHMENTS_FAILURE,
  LOAD_USER_NOTES_ATTACHMENTS_REQUEST,
  LOAD_USER_NOTES_ATTACHMENTS_SUCCESS
} from "../types";

export default data => async (dispatch, getState, { api }) => {
  try {
    dispatch(
      createAction(LOAD_USER_NOTES_ATTACHMENTS_REQUEST)({
        attachmentId: data.attachmentId
      })
    );
    const response = await api.user.queryLoadAttachment(data);
    dispatch(
      createAction(LOAD_USER_NOTES_ATTACHMENTS_SUCCESS)({
        ...response,
        attachmentId: data.attachmentId
      })
    );
    return get(response, "url");
  } catch (err) {
    dispatch(
      createAction(LOAD_USER_NOTES_ATTACHMENTS_FAILURE)({
        ...err,
        attachmentId: data.attachmentId
      })
    );
    throw err;
  }
};

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import { MenuItem } from "@material-ui/core";
import AvatarCard from "../../../../../components/AvatarCard";
import colors from "../../../../../theme/colors";
import CustomTextField from "../../../../../components/inputs/CustomTextField";

const statuses = [
  {
    value: "pending",
    name: "Pending"
  },
  {
    value: "approved",
    name: "Approved"
  },
  {
    value: "declined",
    name: "Declined"
  }
];

const getColor = status => {
  switch (status) {
    case "pending":
      return colors.warning;
    case "approved":
      return colors.green;
    case "declined":
      return colors.danger;
    default:
      return colors.warning;
  }
};

const ClientApprovalStateCard = ({ status, handleChange }) => (
  <AvatarCard
    title="Approval Status"
    color={getColor(status)}
    icon={<FontAwesomeIcon size="lg" icon={faUserClock} />}
    subheader={
      <CustomTextField
        fullWidth
        select
        name="status"
        onChange={handleChange}
        value={status}
        InputProps={{
          disableUnderline: true,
          style: { color: "white" }
        }}
      >
        {statuses.map(status => (
          <MenuItem key={status.value} value={status.value}>
            {status.name}
          </MenuItem>
        ))}
      </CustomTextField>
    }
  />
);

export default ClientApprovalStateCard;

import { createAction } from "redux-starter-kit";
import {
  UPDATE_PAYMENTS_TERMS_REQUEST,
  UPDATE_PAYMENTS_TERMS_SUCCESS,
  UPDATE_PAYMENTS_TERMS_FAILURE
} from "../types";

const updatePaymentTerms = (id, paymentTermId, paymentTerm, params) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(UPDATE_PAYMENTS_TERMS_REQUEST)());
    const response = await api.factoring.updatePaymentTerms(
      id,
      paymentTermId,
      paymentTerm,
      params
    );
    dispatch(
      createAction(UPDATE_PAYMENTS_TERMS_SUCCESS)({
        // [id]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_PAYMENTS_TERMS_FAILURE)(err));
    throw err;
  }
};

export default updatePaymentTerms;

import formatDate from "../../../../helpers/format/formatDate";
import formatStatus from "../../../../helpers/format/formatStatus";

export default [
  {
    key: "name",
    name: "Name",
    filter: "text",
    filter_key: "name"
  },
  {
    key: "email",
    name: "Email",
    filter: "text",
    filter_key: "email"
  },
  {
    key: "contact_name",
    name: "Contact"
  },
  {
    key: "status",
    name: "Status",
    format: formatStatus
  },
  {
    key: "created",
    name: "Created At",
    format: formatDate
  },
  {
    key: "more",
    name: "More"
  }
];

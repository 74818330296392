import React, { useEffect, useCallback, useState } from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { CssBaseline, Theme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import SentryErrorBoundary from "../SentryErrorBoundary";
import AppBar from "../AppBar";
import Drawer from "../Drawer";
import { getUserType, logout } from "../../modules/auth";
import {
  closeDrawer,
  isDrawerOpen,
  openDrawer,
  openUserSetting
} from "../../modules/ui";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    marginTop: "64px"
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  }
});

interface Props extends WithStyles<typeof styles> {
  title?: string;
  color?: string;
  children: React.ReactNode;
}

interface State {
  anchorEl: EventTarget & HTMLElement | null;
}

function Layout(props: Props): JSX.Element {
  const { title, classes, children, color } = props;
  const [anchorEl, setAnchor] = useState<null | HTMLElement>(null);
  const open = useSelector(isDrawerOpen);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDrawerOpen = useCallback(() => dispatch(openDrawer()), [
    dispatch
  ]);
  const handleDrawerClose = useCallback(() => dispatch(closeDrawer()), [
    dispatch
  ]);
  const handleLogout = useCallback( userType => {
    history.push(`${userType === "admin" && "/admin"}/login`);
    dispatch(logout());
  }, [dispatch, history]);
  const openSettings = useCallback(() => dispatch(openUserSetting()), [
    dispatch
  ]);

  const userType = useSelector(getUserType);

  return (
    <div className={classes.root} style={{ maxWidth: "100vw" }}>
      <Helmet>
        <title>{title ? `${title} | HaulPay` : "HaulPay"}</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        title={title}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        handleUserMenuOpen={(event: React.MouseEvent<HTMLElement>): void => {
          setAnchor(event.currentTarget);
        }}
        handleUserMenuClose={() => setAnchor(null)}
        anchorEl={anchorEl}
        openUserSetting={openSettings}
        handleLogout={() => handleLogout(userType)}
      />
      <Drawer
        handleDrawerClose={handleDrawerClose}
        open={open}
        handleLogout={() => handleLogout(userType)}
        userType={userType}
      />
      <main className={classes.content} style={{ backgroundColor: color }}>
        <SentryErrorBoundary>{children}</SentryErrorBoundary>
      </main>
    </div>
  );
}

interface Outter {
  title?: string;
  color?: string;
}

export default compose<Props, Outter>(withStyles(styles, { withTheme: true }))(
  Layout
);

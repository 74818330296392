import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import Notes from "./Notes";
import {
  fetchNotes,
  updateNotes,
  deleteNotes,
  createNotes,
  getBillingNotes,
  getLoadingBillingNotes
} from "../modules/payment";
import {
  openSnackbar,
  getDialog,
  closeDialog,
  openDialog
} from "../modules/ui";
import ConfirmDialog from "./dialogs/ConfirmDialog";

interface LoadAttachmentPayload {
  attachmentId: string;
  attachment: string;
}

interface Props {
  paymentProfileId: string;
}

export default function BillingNotes({ paymentProfileId }: Props): JSX.Element {
  const dispatch = useDispatch();
  const notes = useSelector(getBillingNotes(paymentProfileId));
  const dialog = useSelector(getDialog);

  useEffect(() => {
    if (paymentProfileId) {
      dispatch(fetchNotes(paymentProfileId));
    }
  }, [paymentProfileId]);

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialStatus="CREATE"
        initialValues={{
          note: "",
          attachments: undefined,
          notesID: ""
        }}
        onSubmit={async (
          values,
          { setSubmitting, resetForm }
        ): Promise<void> => {
          try {
            if (values.notesID !== "") {
              await dispatch(
                updateNotes(paymentProfileId, values.notesID, {
                  note: values.note,
                  attachments: values.attachments
                })
              );
              dispatch(openSnackbar("success", "Updated Note!"));
            } else {
              await dispatch(
                createNotes(paymentProfileId, {
                  note: values.note,
                  attachments: values.attachments
                })
              );
              dispatch(openSnackbar("success", "Created Note!"));
            }
          } catch (err) {
            dispatch(openSnackbar("error", err.message || err));
          } finally {
            setSubmitting(false);
            resetForm();
          }
        }}
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          status,
          setStatus,
          isSubmitting
        }): JSX.Element => {
          return (
            <Notes
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              handleSubmit={handleSubmit}
              isCreatingNote={isSubmitting}
              notes={notes}
              isFetching={false}
              notesAttachments={notes.attachments || []}
              isUpdatingNote={isSubmitting}
              handleDeleteNote={async id => {
                dispatch(
                  openDialog(
                    "confirm",
                    "Confirm Delete",
                    "Are you sure you want to delete this Notes?",
                    {
                      notesId: id
                    },
                    "delete_user_note"
                  )
                );
              }}
              status={status}
              setStatus={setStatus}
            />
          );
        }}
      </Formik>
      {dialog.variant === "confirm" && dialog.action === "delete_user_note" && (
        <ConfirmDialog
          open={dialog.show}
          message={dialog.message}
          handleConfirm={(): void => {
            try {
              dispatch(
                deleteNotes(paymentProfileId, get(dialog, ["data", "notesId"]))
              );
              dispatch(fetchNotes(paymentProfileId));
              dispatch(closeDialog());
              dispatch(openSnackbar("success", "Deleted Note!"));
            } catch (err) {
              dispatch(openSnackbar("error", err.message || err));
            }
          }}
          handleExit={(): void => {
            dispatch(closeDialog());
          }}
        />
      )}
    </React.Fragment>
  );
}

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  UPDATE_PAYMENT_PROFILE_RELATIONSHIP_REQUEST,
  UPDATE_PAYMENT_PROFILE_RELATIONSHIP_SUCCESS,
  UPDATE_PAYMENT_PROFILE_RELATIONSHIP_FAILURE
} from "../types";

const updatePaymentProfileRelationship = (
  factoringId,
  relationshipId,
  relationship,
  params
) => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(
      createAction(UPDATE_PAYMENT_PROFILE_RELATIONSHIP_REQUEST)({
        factoringId,
        relationshipId
      })
    );
    const response = await api.groups.updatePaymentProfileRelationship(
      factoringId,
      relationshipId,
      relationship,
      params
    );
    const data = normalize(response, schema.paymentProfileRelationship);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(UPDATE_PAYMENT_PROFILE_RELATIONSHIP_SUCCESS)({
        factoringId,
        relationshipId
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_PAYMENT_PROFILE_RELATIONSHIP_FAILURE)(err));
    throw err;
  }
};

export default updatePaymentProfileRelationship;

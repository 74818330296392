import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";

import { addEntities } from "../../entities";
import {
  QUERY_FUNDINGREQUESTS_REQUEST,
  QUERY_FUNDINGREQUESTS_SUCCESS,
  QUERY_FUNDINGREQUESTS_FAILURE
} from "../types";

const handleResponse = (
  response,
  dispatch,
  fundingRequest,
  limit,
  offset,
  ordering,
  filters
) => {
  const data = normalize(response.results, [fundingRequest]);
  dispatch(addEntities(data.entities));
  dispatch(
    createAction(QUERY_FUNDINGREQUESTS_SUCCESS)({
      items: data.result,
      count: response.count,
      offset,
      ordering,
      limit,
      filters
    })
  );
  return response;
};

const debounced = debounce(
  async (dispatch, schema, api, groupId, limit, offset, ordering, filters) => {
    dispatch(
      createAction(QUERY_FUNDINGREQUESTS_REQUEST)({
        id: groupId,
        limit,
        offset,
        filters,
        ordering
      })
    );
    const response = await api.groups.queryFundingRequests(
      groupId,
      limit,
      offset,
      ordering,
      filters
    );
    handleResponse(
      response,
      dispatch,
      schema.fundingRequest,
      limit,
      offset,
      ordering,
      filters
    );
  },
  1000,
  { trailing: true }
);

const queryFundingRequests = (
  groupId,
  limit,
  offset,
  ordering,
  filters = {}
) => async (dispatch, getState, { api, schema }) => {
  try {
    await debounced(
      dispatch,
      schema,
      api,
      groupId,
      limit,
      offset,
      ordering,
      filters
    );
  } catch (err) {
    dispatch(createAction(QUERY_FUNDINGREQUESTS_FAILURE)(err));
    throw err;
  }
};

export default queryFundingRequests;

const updateFactoringCompany = id => async (
  dispatch,
  getState,
  { api, schema }
) => {
  const response = await api.groups.deleteFactoringCompany(id);
  return response;
};

export default updateFactoringCompany;

import { openDialog } from "../../ui";

const handleDeleteAttachment = id => async (dispatch, getState) => {
  dispatch(
    openDialog(
      "confirm",
      "Confirm Delete File",
      "Are you sure you want to delete this file?",
      {
        fileId: id
      },
      "delete_user_file"
    )
  );
};

export default handleDeleteAttachment;

export default function formatDollar(num: number): string {
    const p =(Number(num)).toFixed(2).split('.');
    return (
        '$' +
        p[0]
            .split('')
            .reverse()
            .reduce(function (acc, num, i, orig) {
                return num + (num != '-' && i && !(i % 3) ? ',' : '') + acc;
            }, '') +
        '.' +
        p[1]
    );
}

import * as Sentry from "@sentry/react";
import React, { ReactNode, Component } from "react";
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActions
} from "@material-ui/core";

interface State {
  error: any;
  eventId: any;
}

interface Props {
  children: ReactNode;
}

class SentryErrorBoundary extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  public componentDidCatch(error: any, errorInfo: any): void {
    this.setState({ error });
    Sentry.withScope(
      (scope): void => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      }
    );
  }

  public render(): JSX.Element {
    const { children } = this.props;
    const { error, eventId } = this.state;

    if (!error) {
      return <div>{children}</div>;
    }

    return (
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <Card>
            <CardHeader title={"We're sorry - something's gone wrong."} />
            <CardContent>
              <Typography variant="subtitle1">
                Our team has been notified, but please fill out a report.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                onClick={(): void => Sentry.showReportDialog({ eventId })}
              >
                Fill Out Report
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default SentryErrorBoundary;

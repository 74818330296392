import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS
} from "../types";

const updateGroup = (id, group) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(
      createAction(UPDATE_GROUP_REQUEST)({
        id
      })
    );
    const response = await api.groups.updateGroup(id, group);
    const data = normalize(response, schema.group);
    dispatch(addEntities(data.entities));
    dispatch(createAction(UPDATE_GROUP_SUCCESS)({ id }));
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_GROUP_FAILURE)(err));
    throw err;
  }
};

export default updateGroup;

import axios from "axios";
import { get, merge } from "lodash";
import commaQuery from "../helpers/commaQuery";

axios.interceptors.request.use(
  config => {
    const newConfig = { params: {} };

    ["invoice_number", "user_load_number"].forEach(path => {
      const value = get(config.params, path);
      if (value) {
        newConfig.params[path] = commaQuery(value);
      }
    });

    return merge({}, config, newConfig);
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

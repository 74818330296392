import { get } from "lodash";
import { denormalize } from "normalizr";

import { paymentProfileRelationship } from "../../../api/schema";

export default state => {
  try {
    const relationships = get(state, ["payment", "relationships", "ids"], []);

    const denormalized = denormalize(
      { relationships },
      { relationships: [paymentProfileRelationship] },
      state.entities
    );
    return denormalized.relationships;
  } catch (err) {
    return [];
  }
};

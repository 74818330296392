import { find, head, get } from "lodash";

export default plaidResponse => {
  const account = find(
    get(head(plaidResponse), "accounts", []),
    account => account.default === true
  );

  const name = get(head(plaidResponse), "names", "");

  const phone = get(
    find(
      get(head(plaidResponse), "phone_numbers", []),
      phone => phone.primary === true
    ),
    "phone_number",
    ""
  );

  const email = get(
    find(
      get(head(plaidResponse), "emails", []),
      email => email.primary === true
    ),
    "email_id",
    ""
  );

  const address = find(
    get(head(plaidResponse), "addresses", []),
    address => address.primary === true
  );

  const street = get(address, "street", "");
  const city = get(address, "city", "");
  const state = get(address, "state", "");
  const zip = get(address, "zip_code", "");

  const bankAccount = get(account, "account_number_redacted", "");
  const bankName = get(account, "bank_name", "");
  const routingNumber = get(account, "routing_number", "");

  return {
    name,
    email,
    bankAccount,
    bankName,
    phone,
    routingNumber,
    address: street,
    city,
    state,
    zip
  };
};

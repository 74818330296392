export const FETCH_RECEIVABLES_BY_DEBTORS_REQUEST =
  "FINANCES/FETCH_RECEIVABLES_BY_DEBTORS_REQUEST";
export const FETCH_RECEIVABLES_BY_DEBTORS_SUCCESS =
  "FINANCES/FETCH_RECEIVABLES_BY_DEBTORS_SUCCESS";
export const FETCH_RECEIVABLES_BY_DEBTORS_FAILURE =
  "FINANCES/FETCH_RECEIVABLES_BY_DEBTORS_FAILURE";

export const FETCH_FUNDING_REQUEST_RECEIVABLES_REQUEST =
  "FINANCES/FETCH_FUNDING_REQUEST_RECEIVABLES_REQUEST";
export const FETCH_FUNDING_REQUEST_RECEIVABLES_SUCCESS =
  "FINANCES/FETCH_FUNDING_REQUEST_RECEIVABLES_SUCCESS";
export const FETCH_FUNDING_REQUEST_RECEIVABLES_FAILURE =
  "FINANCES/FETCH_FUNDING_REQUEST_RECEIVABLES_FAILURE";

export const FETCH_PURCHASES_REQUEST = "FINANCES/FETCH_PURCHASES_REQUEST";
export const FETCH_PURCHASES_SUCCESS = "FINANCES/FETCH_PURCHASES_SUCCESS";
export const FETCH_PURCHASES_FAILURE = "FINANCES/FETCH_PURCHASES_FAILURE";

export const FETCH_PAYMENT_ENTRIES_REQUEST =
  "FINANCES/FETCH_PAYMENT_ENTRIES_REQUEST";
export const FETCH_PAYMENT_ENTRIES_SUCCESS =
  "FINANCES/FETCH_PAYMENT_ENTRIES_SUCCESS";
export const FETCH_PAYMENT_ENTRIES_FAILURE =
  "FINANCES/FETCH_PAYMENT_ENTRIES_FAILURE";

export const FETCH_PAYMENTS_REQUEST = "FINANCES/FETCH_PAYMENTS_REQUEST";
export const FETCH_PAYMENTS_SUCCESS = "FINANCES/FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAILURE = "FINANCES/FETCH_PAYMENTS_FAILURE";

export const CREATE_PAYMENTS_FILE_REQUEST =
  "FINANCES/CREATE_PAYMENTS_FILE_REQUEST";
export const CREATE_PAYMENTS_FILE_SUCCESS =
  "FINANCES/CREATE_PAYMENTS_FILE_SUCCESS";
export const CREATE_PAYMENTS_FILE_FAILURE =
  "FINANCES/CREATE_PAYMENTS_FILE_FAILURE";

export const FETCH_PURCHASES_ITEMS_FAILURE =
  "FINANCES/FETCH_PURCHASE_ITEMS_FAILURE";
export const FETCH_PURCHASES_ITEMS_REQUEST =
  "FINANCES/FETCH_PURCHASES_ITEMS_REQUEST";
export const FETCH_PURCHASES_ITEMS_SUCCESS =
  "FINANCES/FETCH_PURCHASES_ITEMS_SUCCESS";

export const CREATE_LINEITEMS_CATEGORIES_REQUEST =
  "FINANCES/CREATE_LINEITEMS_CATEGORIES_REQUEST";
export const CREATE_LINEITEMS_CATEGORIES_SUCCESS =
  "FINANCES/CREATE_LINEITEMS_CATEGORIES_SUCCESS";
export const CREATE_LINEITEMS_CATEGORIES_FAILURE =
  "FINANCES/CREATE_LINEITEMS_CATEGORIES_FAILURE";

export const UPDATE_LINEITEMS_CATEGORIES_REQUEST =
  "FINANCES/UPDATE_LINEITEMS_CATEGORIES_REQUEST";
export const UPDATE_LINEITEMS_CATEGORIES_SUCCESS =
  "FINANCES/UPDATE_LINEITEMS_CATEGORIES_SUCCESS";
export const UPDATE_LINEITEMS_CATEGORIES_FAILURE =
  "FINANCES/UPDATE_LINEITEMS_CATEGORIES_FAILURE";

export const DELETE_LINEITEMS_CATEGORIES_REQUEST =
  "FINANCES/DELETE_LINEITEMS_CATEGORIES_REQUEST";
export const DELETE_LINEITEMS_CATEGORIES_SUCCESS =
  "FINANCES/DELETE_LINEITEMS_CATEGORIES_SUCCESS";
export const DELETE_LINEITEMS_CATEGORIES_FAILURE =
  "FINANCES/DELETE_LINEITEMS_CATEGORIES_FAILURE";

export const FETCH_OUTSTANDING_TOTALS_FAILURE =
  "FINANCES/FETCH_OUTSTANDING_TOTALS_FAILURE";
export const FETCH_OUTSTANDING_TOTALS_SUCCESS =
  "FINANCES/FETCH_OUTSTANDING_TOTALS_SUCCESS";
export const FETCH_OUTSTANDING_TOTALS_REQUEST =
  "FINANCES/FETCH_OUTSTANDING_TOTALS_REQUEST";

export const FETCH_RECEIVABLE_REQUEST = "FINANCES/FETCH_RECEIVABLE_REQUEST";
export const FETCH_RECEIVABLE_SUCCESS = "FINANCES/FETCH_RECEIVABLE_SUCCESS";
export const FETCH_RECEIVABLE_FAILURE = "FINANCES/FETCH_RECEIVABLE_FAILURE";

export const FETCH_PAYMENT_REQUEST = "FINANCES/FETCH_PAYMENT_REQUEST";
export const FETCH_PAYMENT_SUCCESS = "FINANCES/FETCH_PAYMENT_SUCCESS";
export const FETCH_PAYMENT_FAILURE = "FINANCES/FETCH_PAYMENT_FAILURE";

export const FETCH_RECEIVABLE_ENTRIES_REQUEST =
  "FINANCES/FETCH_RECEIVABLE_ENTRIES_REQUEST";
export const FETCH_RECEIVABLE_ENTRIES_SUCCESS =
  "FINANCES/FETCH_RECEIVABLE_ENTRIES_SUCCESS";
export const FETCH_RECEIVABLE_ENTRIES_FAILURE =
  "FINANCES/FETCH_RECEIVABLE_ENTRIES_FAILURE";

export const ADD_FUNDING_REQUEST_RECEIVABLES_SUCCESS =
  "FINANCES/ADD_FUNDING_REQUEST_RECEIVABLES_SUCCESS";

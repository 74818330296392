import { createAction } from "redux-starter-kit";
import { UPDATE_INVITE_STEPS } from "../types";

const updateInviteSteps = (
  includeCarrierIDCheck,
  isBoarding
) => async dispatch =>
  dispatch(
    createAction(UPDATE_INVITE_STEPS)({ includeCarrierIDCheck, isBoarding })
  );

export default updateInviteSteps;

import React, { useCallback, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import API from "../../../../../api";
import openFileDialog from "../../../../../helpers/openFileDialog";
import SpinnerButton from "../../../../../components/buttons/SpinnerButton";
import { openModal } from "../../../../../modules/modal";
import { Select } from "../../../../../components/inputs/BoldInput";

const UploadStatementButton = ({}) => {
  const dispatch = useDispatch();
  const [uploaded, setUploaded] = useState(false);
  const [accountType, setAccountType] = useState("AR");
  const uploadBtnRef = useRef(null);
  const uploadFile = useCallback(async (file, accountType) => {
    try {
      setUploaded(true);
      await API.finances.uploadStatement(file, accountType);
      dispatch(
        openModal("success", "Bank statement was uploaded successfully.")
      );
    } catch (e) {
      dispatch(openModal("error", "Error While Uploading File."));
    }
    setUploaded(false);
  }, []);
  return (
    <>
      <Select
        selections={[
          {
            text: "Accounts Receivable",
            value: "AR"
          },
          {
            text: "Accounts Payable",
            value: "AP"
          }
        ]}
        value={accountType}
        onChange={event => setAccountType(event.target.value)}
      />

      <SpinnerButton
        disabled={uploaded}
        variant="outlined"
        color="primary"
        onClick={() => openFileDialog(uploadBtnRef.current)}
        style={{
          marginLeft: 10
        }}
      >
        Upload Bank Statement{" "}
      </SpinnerButton>
      <input
        type="file"
        ref={uploadBtnRef}
        style={{ display: "none" }}
        onChange={async e => {
          uploadFile(e.target.files[0], accountType);
          e.currentTarget.value = "";
        }}
      />
    </>
  );
};

export default UploadStatementButton;

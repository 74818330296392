import { denormalize } from "normalizr";
import { fundingRequest } from "../../../api/schema";

const getInvoice = id => state => {
  try {
    const denormalized = denormalize(
      { item: id },
      { item: fundingRequest },
      state.entities
    );

    return denormalized.item || {};
  } catch (err) {
    return {};
  }
};

export default getInvoice;

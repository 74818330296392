import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  FETCH_PENDING_INVITE_REQUEST,
  FETCH_PENDING_INVITE_SUCCESS,
  FETCH_PENDING_INVITE_FAILURE
} from "../types";

const fetchPendingInvite = () => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_PENDING_INVITE_REQUEST)());
    const response = await api.groups.getPendingInvite();
    const data = normalize(response, [schema.group]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_PENDING_INVITE_SUCCESS)({
        items: data.result,
        count: response.count,
        next: response.next,
        previous: response.previous
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_PENDING_INVITE_FAILURE)(err));
    throw err;
  }
};

export default fetchPendingInvite;

import React from 'react';
import { Grid, IconButton, List, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';

import Card from '../../../../../components/Card';
import formatDate from '../../../../../helpers/format/formatDate';
import BoldInput from '../../../../../components/inputs/BoldInput';
import ListItem from '../../../../../components/ListItem';
import Setting from './Settings';
import ClientApproval from './ClientApproval';

import ProfileSection from '../../../../../components/ProfileSection';

export default ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  paymentProfile,
  paymentProfileId,
  factorCompany = {},
}) => (
  <Card style={{ height: '100%', width: '100%' }}>
    <List dense>
      <ListItem>
        <ProfileSection>Client Info</ProfileSection>
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="carrierContactName"
          label="Contact Name"
          disabled
          value={values.carrierContactName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.carrierContactName}
          helperText={errors.carrierContactName}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="email"
          label="Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.email}
          helperText={errors.email}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="carrierPhone"
          label="Phone"
          disabled
          value={values.carrierPhone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.carrierPhone}
          helperText={errors.carrierPhone}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="carrierCreatedAt"
          label="Created At"
          disabled
          value={formatDate(values.carrierCreatedAt)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.carrierCreatedAt}
          helperText={errors.carrierCreatedAt}
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          name="carrierLastLogin"
          label="Last Login"
          disabled
          value={formatDate(values.carrierLastLogin)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.carrierLastLogin}
          helperText={errors.carrierLastLogin}
        />
      </ListItem>
      {values.businessCurrentFactoringCompany && (
        <ListItem>
          <Grid container direction="row">
            <Grid item xs={10}>
              <BoldInput
                fullWidth
                name="businessCurrentFactoringCompany"
                label="Current Factoring Company:"
                value={values.businessCurrentFactoringCompany}
                disabled
              />
            </Grid>
            <Grid item xs={2} container alignContent="center" justify="center">
              <Tooltip title="View Profile">
                <Link
                  to={`/admin/companies/${values.businessCurrentFactoringCompanyId}/profile`}
                >
                  <IconButton color="primary">
                    <AccountCircle fontSize="large" />
                  </IconButton>
                </Link>
              </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {factorCompany.status &&
      ["declined", "pending"].includes(factorCompany.status) ? (
        <ListItem>
          <Link to={`/admin/companies/${factorCompany.id}/profile`}>
            <Typography variant="body2">{`Factoring Company Profile is ${factorCompany.status} Status`}</Typography>
          </Link>
        </ListItem>
      ) : null}
    </List>
    <Setting
      setFieldValue={setFieldValue}
      values={values}
      paymentProfile={paymentProfile}
      paymentProfileId={paymentProfileId}
    />
    <ClientApproval setFieldValue={setFieldValue} values={values} />
  </Card>
);

import React from "react";
import AdminAvatarChip from "../../../components/AdminAvatarChip";

const columns = [
  {
    key: "email",
    name: "Email",
    filter: "text",
    filter_key: "email"
  },
  {
    key: "name",
    name: "Name",
    format: (name: string): JSX.Element => <AdminAvatarChip name={name} />,
    filter: "text",
    filter_key: "name"
  },
  {
    key: "user_type",
    name: "User Type",
    default: "-",
    filter: "dropdown",
    filter_values: [
      { label: "Debtor", value: "debtor" },
      { label: "Broker", value: "broker" },
      { label: "Carrier", value: "carrier" }
    ],
    filter_key: "user_type"
  },
  {
    key: "user_type",
    name: "User Type",
    filter_key: "user_type",
    filter: "dropdown",
    filter_values: [
      { label: "All", value: "" },
      { label: "Debtor", value: "debtor" },
      { label: "Broker", value: "broker" },
      { label: "Carrier", value: "carrier" }
    ]
  },
  {
    key: "created",
    name: "Created At",
    sort: true
  },
  {
    key: "more",
    name: "More"
  }
];

export default columns;

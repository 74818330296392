import triggerDownload from "../../helpers/triggerDownload";

export default (id, filters, ids = []) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    const response = await api.factoring.paymentProfile.exportPaymentProfilePurchases(
      id,
      filters,
      ids
    );
    triggerDownload(response.download_url);
  } catch (e) {
    throw e;
  }
};

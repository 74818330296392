import { isArray, merge, mergeWith, assign, remove } from "lodash";
import {
  CREATE_NOTES_FAILURE,
  CREATE_NOTES_REQUEST,
  CREATE_NOTES_SUCCESS,
  DELETE_NOTES_FAILURE,
  DELETE_NOTES_REQUEST,
  DELETE_NOTES_SUCCESS,
  FETCH_NOTES_SUCCESS
} from "../types";

function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export default (state, action) => {
  switch (action.type) {
    case CREATE_NOTES_REQUEST:
    case DELETE_NOTES_REQUEST:
      return {
        ...state,
        loadingBillingNotes: true
      };
    case FETCH_NOTES_SUCCESS:
      return {
        ...state,
        billingNotes: merge({}, state.billingNotes, action.payload),
        loadingBillingNotes: false
      };
    case CREATE_NOTES_SUCCESS:
      return {
        ...state,
        loadingBillingNotes: false,
        billingNotes: mergeWith(
          merge({}, action.payload),
          state.billingNotes,
          customizer
        )
      };
    case CREATE_NOTES_FAILURE:
    case DELETE_NOTES_FAILURE:
      return {
        ...state,
        loadingBillingNotes: false
      };
    default:
      return state;
  }
};

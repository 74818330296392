import formatDate from "../../../../../helpers/format/formatDate";
import formatPennies from "../../../../../helpers/format/formatPennies";
import formatDays from "../../../../../helpers/format/formatDays";
import splitAndCase from "../../../../../helpers/format/splitAndCase";

const columns = [
  {
    key: "invoice_date",
    name: "Date Invoiced",
    format: formatDate
  },
  {
    key: "amount_balance",
    name: "Balance Amount",
    format: formatPennies
  },
  {
    key: "amount_paid",
    name: "Paid Amount",
    format: formatPennies
  },
  {
    key: "aging",
    name: "Aging",
    format: formatDays
  },
  {
    key: "paid_date",
    name: "Paid Date",
    format: formatDate
  },
  {
    key: "sch",
    name: "Check/ACH Number"
  },
  {
    key: "admin_notes",
    name: "Notes"
  },
  {
    key: "followup_date",
    name: "Follow up Date"
  },
  {
    key: "response_category",
    name: "Response",
    format: splitAndCase
  },
  {
    key: "action",
    name: "Action"
  }
];

export default columns;

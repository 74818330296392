import { assign, isEqual } from "lodash";
import {
  FETCH_RECEIVABLES_ENTRIES_FAILURE,
  FETCH_RECEIVABLES_ENTRIES_REQUEST,
  FETCH_RECEIVABLES_ENTRIES_SUCCESS
} from "../types";

const sameRequest = (state, payload) =>
  // state.ordering === payload.ordering &&
  state.receivablesEntries.limit === payload.limit &&
  isEqual(state.receivablesEntries.filters, payload.filters); // &&
// state.id === payload.id;

export default (
  state = {
    loading: false,
    ids: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_RECEIVABLES_ENTRIES_REQUEST:
      return assign({}, state, {
        receivablesEntries: {
          ids: [],
          loading: true,
          limit: action.payload.limit,
          filters: action.payload.filters
        }
      });
    case FETCH_RECEIVABLES_ENTRIES_SUCCESS:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          receivablesEntries: {
            loading: false,
            ids: action.payload.items,
            next: action.payload.next,
            previous: action.payload.previous
          }
        });
      }
      return state;
    case FETCH_RECEIVABLES_ENTRIES_FAILURE:
      if (sameRequest(state, action.payload)) {
        return assign({}, state, {
          receivablesEntries: {
            loading: false
          }
        });
      }
      return state;
    default:
      return state;
  }
};

import React from "react";
import { createStyles, Fab, withStyles, WithStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import colors from "../../../../../theme/colors";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = () =>
  createStyles({
    button: {
      boxShadow:
        "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      textTransform: "none"
    }
  });

interface Props extends WithStyles<typeof styles> {
  onClick: () => void;
  disabled: boolean;
}

export default withStyles(styles)(
  ({ onClick, classes, disabled }: Props): JSX.Element => (
    <Fab
      variant="extended"
      size="medium"
      color="primary"
      className={classes.button}
      onClick={(): void => onClick()}
    >
      Save
      {disabled && (
        <FontAwesomeIcon
          icon={faSpinner}
          size="sm"
          spin
          color={colors.green}
          style={{ marginLeft: 5 }}
        />
      )}
    </Fab>
  )
);

import { createAction } from "redux-starter-kit";

import * as TYPES from "../types";

const pickFiles = (payload, requestId, category) => (dispatch, getState) => {
  if (Array.isArray(payload)) {
    payload.forEach((item, i) => {
      item.status = "Picked";
      item.progress = 0;
      item.id = i;
      item.requestId = requestId;
      item.category = category;
    });
  } else {
    const { files } = getState().upload;
    payload.status = "Picked";
    payload.progress = 0;
    payload.id = files.length;
    payload.requestId = requestId;
    payload.category = category;
  }
  dispatch(createAction(TYPES.PICK_FILES)({ newFiles: payload }));
};

export default pickFiles;

import React from "react";
import { get } from "lodash";
import { Grid, List } from "@material-ui/core";
import ListItem from "../../../../../components/ListItem";
import BoldInput from "../../../../../components/inputs/BoldInput";

export default ({ verification }) => (
  <Grid container>
    <Grid item xs={4}>
      <List dense>
        <ListItem>
          <BoldInput
            fullWidth
            disabled
            value={get(verification, ["plaid", "names"], "")}
            label="Plaid Name"
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            disabled
            value={get(verification, ["plaid", "addresses", "street"], "")}
            label="Plaid Address"
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            disabled
            value={get(verification, ["plaid", "addresses", "city"], "")}
            label="Plaid City"
          />
        </ListItem>
        <ListItem>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                disabled
                value={get(verification, ["plaid", "addresses", "state"], "")}
                label="Plaid State"
                error={true}
              />
            </Grid>
            <Grid item xs={6}>
              <BoldInput
                fullWidth
                disabled
                value={get(
                  verification,
                  ["plaid", "addresses", "zip_code"],
                  ""
                )}
                label="Plaid Zip"
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            disabled
            value={get(verification, ["plaid", "phones", "phone_id"], "")}
            label="Plaid Phone"
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            disabled
            value={get(verification, ["plaid", "emails", "email_id"], "")}
            label="Plaid Email"
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            disabled
            label="Plaid Account"
            value={get(
              verification,
              ["plaid", "accounts", "account_number_redacted"],
              ""
            )}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            disabled
            value={get(
              verification,
              ["plaid", "accounts", "routing_number"],
              ""
            )}
            label="Plaid Routing Number"
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            disabled
            value={get(verification, ["plaid", "accounts", "bank_name"], "")}
            label="Plaid Bank Name"
          />
        </ListItem>
      </List>
    </Grid>
    <Grid item xs={4}>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["berbix", "name"], "")}
          label="Berbix Name"
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["berbix", "address_street"], "")}
          label="Birbix Address"
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["berbix", "address_city"], "")}
          label="Birbix City"
        />
      </ListItem>
      <ListItem>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <BoldInput
              fullWidth
              disabled
              value={get(verification, ["berbix", "address_state"], "")}
              label="Birbix State"
            />
          </Grid>
          <Grid item xs={6}>
            <BoldInput
              fullWidth
              disabled
              value={get(verification, ["berbix", "address_zip_code"], "")}
              label="Birbix Zip"
            />
          </Grid>
        </Grid>
      </ListItem>
    </Grid>
    <Grid item xs={4}>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          name="fmcsaName"
          value={get(verification, ["fmcsa", "legal_name"], "")}
          label="FMCSA Name"
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["fmcsa", "dba"], "")}
          label="FMCSA DBA"
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["fmcsa", "physical_address_street"], "")}
          label="FMCSA Physical Address"
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["fmcsa", "physical_address_city"], "")}
          label="FMCSA Physical City"
        />
      </ListItem>
      <ListItem>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <BoldInput
              fullWidth
              disabled
              value={get(verification, ["fmcsa", "physical_address_state"], "")}
              label="FMCSA Physical State"
            />
          </Grid>
          <Grid item xs={6}>
            <BoldInput
              fullWidth
              disabled
              value={get(
                verification,
                ["fmcsa", "physical_address_zip_code"],
                ""
              )}
              label="FMCSA Physical Zip"
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["fmcsa", "mailing_address_street"], "")}
          label="FMCSA Mailing Address"
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["fmcsa", "mailing_address_city"], "")}
          label="FMCSA Mailing City"
        />
      </ListItem>
      <ListItem>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <BoldInput
              fullWidth
              disabled
              value={get(verification, ["fmcsa", "mailing_address_state"], "")}
              label="FMCSA Mailing State"
            />
          </Grid>
          <Grid item xs={6}>
            <BoldInput
              fullWidth
              disabled
              value={get(verification, ["fmcsa", "mailing_address_zip"], "")}
              label="FMCSA Mailing Zip"
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["fmcsa", "mc"], "")}
          label="FMCSA MC #"
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["fmcsa", "dot"], "")}
          label="FMCSA DOT #"
        />
      </ListItem>
      <ListItem>
        <BoldInput
          fullWidth
          disabled
          value={get(verification, ["fmcsa", "entity_type"], "")}
          label="FMCSA Company Type"
        />
      </ListItem>
    </Grid>
  </Grid>
);

import React from "react";
import { Redirect } from "react-router-dom";
import { Paper } from "@material-ui/core";

import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import Table from "../../../../components/Table";
import NavigationTabs from "../../../../components/NavigationTabs";

const ConnectedBrokersCarriers = ({
  tabs,
  columns,
  navIndex,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSort,
  handleFilterChange,
  connectedForm,
  loading,
  count,
  connectedProfiles,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  sortDirection,
  sortBy,
  filters
}) => {
  return (
    <Can
      perform="admin-factorclients:view"
      yes={() => (
        <InternalLayout title="Factor Client">
          <NavigationTabs tabs={tabs} value={navIndex} />
          <Paper style={{ marginTop: "32px", paddingLeft: "16px" }}>
            <Table
              columns={columns}
              rows={connectedProfiles}
              filter
              sort
              isLoading={loading}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              sortDirection={sortDirection}
              sortBy={sortBy}
              filters={filters}
              handleSort={handleSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleFilterChange={handleFilterChange}
              formikRow={connectedForm}
              allowEmpty
            />
          </Paper>
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
};

export default ConnectedBrokersCarriers;

import triggerDownload from "../../helpers/triggerDownload";

export default (filters = {}, params = {}) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    const response = await api.factoring.exportPayment(filters, params);
    triggerDownload(response.download_url);
  } catch (e) {
    throw e;
  }
};

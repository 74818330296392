import { createAction } from "redux-starter-kit";
import {
  DELETE_FOLLOWUPNOTE_REQUEST,
  DELETE_FOLLOWUPNOTE_SUCCESS,
  DELETE_FOLLOWUPNOTE_FAILURE
} from "../types";
import fetchReceivableNotes from "./fetchReceivableNotes";

const deleteFollowupNote = (receivableId, noteId) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(createAction(DELETE_FOLLOWUPNOTE_REQUEST)({}));
    await api.factoring.deleteReceivableNote(receivableId, noteId);
    dispatch(fetchReceivableNotes(receivableId));
    dispatch(createAction(DELETE_FOLLOWUPNOTE_SUCCESS)({}));
  } catch (err) {
    dispatch(createAction(DELETE_FOLLOWUPNOTE_FAILURE)(err));
    throw err;
  }
};

export default deleteFollowupNote;

import { createAction } from "redux-starter-kit";
import {
  DELETE_DEBTORRELATION_FAILURE,
  DELETE_DEBTORRELATION_REQUEST,
  DELETE_DEBTORRELATION_SUCCESS
} from "../types";

const deleteDebtorRelationship = (debtorId, factorClientId) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    dispatch(
      createAction(DELETE_DEBTORRELATION_REQUEST)({
        debtor: debtorId,
        client: factorClientId
      })
    );
    await api.factoring.deleteDebtorRelationship(debtorId, factorClientId);
    dispatch(
      createAction(DELETE_DEBTORRELATION_SUCCESS)({
        debtor: debtorId,
        client: factorClientId
      })
    );
  } catch (err) {
    dispatch(createAction(DELETE_DEBTORRELATION_FAILURE)(err));
    throw err;
  }
};

export default deleteDebtorRelationship;

import React from 'react'
import tw from 'twin.macro'

export const Link = tw.a`
  cursor-pointer
  text-blue-600
  hover:text-blue-700
  underline

  visited:text-purple-600
  visited:hover:text-purple-700
`

export type LinkProps = React.ComponentProps<typeof Link>

export default Link;

import axios, { AxiosRequestConfig } from "axios";
import omitEmptyKeys from "../helpers/omitEmptyKeys";
import deleteCommaKeys from "../helpers/deleteCommaKeys";
import qs from "qs";
import removeSpaceComma from "../helpers/removeSpaceComma";

const queryFetchPurchases = async (
  limit: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/purchases/`,
    method: "GET",
    params: {
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryPayments = async (
  limit: number,
  ordering: string,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/payments/`,
    method: "GET",
    params: {
      limit,
      ...deleteCommaKeys(omitEmptyKeys(removeSpaceComma(filters)))
    },
    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
  };
  const response = await axios(options);
  return response.data;
};

const queryFetchPurchaseItems = async (
  id: string,
  limit: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/purchases/${id}/funding_requests/`,
    method: "GET",
    params: {
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryPaymentEntries = async (
  id: string,
  ordering: string,
  limit: string,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/payments/${id}/entries/`,
    method: "GET",
    params: {
      limit,
      ordering,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryReceivablesEntries = async (
  id: string,
  ordering: string,
  limit: string,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/receivables/${id}/entries/`,
    method: "GET",
    params: {
      limit,
      ordering,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const createLineItemsCategories = async (
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/line_items/categories/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const updateLineItemsCategories = async ({
  id,
  ...data
}: Record<string, any> & { id: string }): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/line_items/categories/${id}/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const deleteLineItemsCategories = async ({
  id
}: {
  id: string;
}): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/funding_requests/line_items/categories/${id}/`,
    method: "delete"
  };
  const response = await axios(options);
  return response.data;
};

const outstandingTotals = async (): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/debtor/outstanding_totals/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const uploadStatement = async (file: File, accountType: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("account_type", accountType);
  const options: AxiosRequestConfig = {
    url: `payments/statement/upload/csv/`,
    headers: { "Content-Type": "multipart/form-data" },
    method: "POST",
    data: formData
  };
  const response = await axios(options);
  return response.data;
};

export default {
  queryReceivablesEntries,
  queryPayments,
  queryFetchPurchases,
  queryFetchPurchaseItems,
  createLineItemsCategories,
  updateLineItemsCategories,
  deleteLineItemsCategories,
  outstandingTotals,
  queryPaymentEntries,
  uploadStatement
};

import { createAction } from "redux-starter-kit";
import { get } from "lodash";

import {
  CREATE_FUNDINGREQUEST_FAILURE,
  CREATE_FUNDINGREQUEST_REQUEST,
  CREATE_FUNDINGREQUEST_SUCCESS
} from "../types";
import awsImageUpload from "../../../helpers/awsImageUpload";

const createFundingRequest = (
  amount,
  attachments,
  billToCompanyEmail,
  billToCompanyPhone,
  debtorId,
  factoringId,
  finalDestinationLocation,
  firstOriginLocation,
  loadLength,
  loadTrailerType,
  loadNumber,
  paymentMethod,
  nonFactored = false,
  clientNote = ""
) => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(CREATE_FUNDINGREQUEST_REQUEST)({}));
    const response = await api.factoring.createFundingRequest(
      amount,
      attachments,
      billToCompanyEmail,
      billToCompanyPhone,
      debtorId,
      factoringId,
      finalDestinationLocation,
      firstOriginLocation,
      loadLength,
      loadTrailerType,
      loadNumber,
      paymentMethod,
      nonFactored,
      clientNote
    );
    const fromAPI = get(response, "attachments", []);

    const toUpload = fromAPI.map((attachment, i) => ({
      file: attachments[i],
      upload_url: attachment.upload_url
    }));

    const files = await Promise.all(
      toUpload.map(async attachment => {
        const blob = await fetch(attachment.file.base64).then(res =>
          res.blob()
        );
        return {
          ...attachment,
          file: blob
        };
      })
    );

    await awsImageUpload(files);

    dispatch(createAction(CREATE_FUNDINGREQUEST_SUCCESS)({}));
    return response;
  } catch (err) {
    dispatch(createAction(CREATE_FUNDINGREQUEST_FAILURE)(err));
    throw err;
  }
};

export default createFundingRequest;

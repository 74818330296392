import React from "react";
import {
  Grid,
  LinearProgress,
  TablePagination,
  Typography
} from "@material-ui/core";
import { isEmpty } from "lodash";

import { Formik } from "formik";
import { CellRenderer, LabelRenderer } from "./Renderer";
import ExpandableListItem from "./ExpandableListItem";

const createListItemTitle = (columns, row, data) => {
  const primaryColumns = columns.filter(column => column.primary);
  return primaryColumns.length === 0 ? (
    <CellRenderer column={columns[0]} row={row} data={data} />
  ) : (
    primaryColumns
      .map(column => (
        <CellRenderer key={column.key} column={column} row={row} />
      ))
      .reduce((prev, next) => [prev, " ", next])
  );
};

const createListItemDescription = (
  columns,
  row,
  data,
  excludePrimary,
  additional
) => (
  <div>
    {columns
      .filter(column => !excludePrimary || !column.primary)
      .map(column => (
        <Grid spacing={8} key={column.key} container alignItems="center">
          <Grid item xs>
            <LabelRenderer column={column} />
          </Grid>
          <Grid item xs>
            <CellRenderer column={column} row={row} additional={additional} />
          </Grid>
        </Grid>
      ))}
  </div>
);

const TableList = ({
  columns,
  rows = [],
  isLoading,
  count,
  page,
  rowsPerPage,
  handleChangePage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  allowEmpty,
  formikRow,
  expanded
}) => (
  <Grid container spacing={16} style={{ padding: 10 }} justify="center">
    <Grid
      item
      xs={12}
      style={{
        padding: 20
      }}
    >
      {isLoading && <LinearProgress />}
    </Grid>
    {rows.map((row, index) => {
      const Row = (additional = {}) => (
        <Grid item xs={12}>
          <ExpandableListItem
            expanded={expanded}
            summary={createListItemTitle(columns, row, rows)}
            details={createListItemDescription(
              columns,
              row,
              rows,
              false,
              additional
            )}
          />
        </Grid>
      );
      if (formikRow) {
        const form = formikRow(row, index);
        return (
          <Formik
            {...form}
            initialValues={{ ...form.initialValues, id: row.id }}
          >
            {formProps => Row(formProps)}
          </Formik>
        );
      }
      return Row();
    })}
    <Grid item>
      {count && !isLoading ? (
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </Grid>
    <Grid
      container
      item
      xs={12}
      style={{ width: "100%" }}
      justify="center"
      alignItems="center"
    >
      {allowEmpty && !isLoading && isEmpty(rows) && (
        <Typography variant="subtitle1" color="primary">
          No Data Available
        </Typography>
      )}
    </Grid>
  </Grid>
);
export default TableList;

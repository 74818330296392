import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import React, { ReactElement } from "react";

interface Props {
  handleCSV: () => void;
  filters?: Record<string, any>;
}

const CSVButton = ({ handleCSV }: Props): ReactElement => (
  <Button
    variant="contained"
    color="secondary"
    onClick={(): void => handleCSV()}
  >
    <FontAwesomeIcon icon={faArrowAltCircleDown} style={{ margin: 5 }} />
    CSV
  </Button>
);

export default CSVButton;

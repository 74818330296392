import { Action, createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { ThunkAction } from "redux-thunk";
import { ThunkContext } from "../../types";
import { GetFundingRequestResponse } from "../../../api/factoring/getFundingRequest";
import { addEntities } from "../../entities";
import { FundingRequestEntities } from "../../../api/schema";
import { FETCH_FUNDINGREQUEST_FAILURE, FETCH_FUNDINGREQUEST_REQUEST, FETCH_FUNDINGREQUEST_SUCCESS } from "../types";

function fetchFundingRequest(
  id: string
): ThunkAction<
  Promise<GetFundingRequestResponse>,
  any,
  ThunkContext,
  Action<any>
> {
  return async (
    dispatch,
    getState,
    { api, schema }
  ): Promise<GetFundingRequestResponse> => {
    try {
      dispatch(createAction(FETCH_FUNDINGREQUEST_REQUEST)());
      const response = await api.factoring.getFundingRequest(id);
      const data = normalize<
        typeof schema.fundingRequest,
        FundingRequestEntities,
        string
      >(response, schema.fundingRequest);
      dispatch(addEntities(data.entities));
      dispatch(createAction(FETCH_FUNDINGREQUEST_SUCCESS)(data.result));
      return response;
    } catch (err) {
      dispatch(createAction(FETCH_FUNDINGREQUEST_FAILURE)(err));
      throw err;
    }
  };
}

export default fetchFundingRequest;

import React, { useCallback } from "react";
import { Formik } from "formik";
import { Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
    closeDialog,
    getDialog,
    openDialog,
    openSnackbar
} from "../../../../../../../../modules/ui";
import { getFactorClient } from "../../../../../../../../modules/factoringClient";

import SpinnerButton from "../../../../../../../../components/buttons/SpinnerButton";
import { DialogActions, DialogTitle, Dialog, DialogContent } from "../../../../../../../../components/Dialog";
import { Pennies, Select } from "../../../../../../../../components/inputs/BoldInput";
import API from "../../../../../../../../api";


export default ({
    open,
}) => {
    const dispatch = useDispatch()
    const factorClient = useSelector(getFactorClient);
    const onSubmit = useCallback(
        async (fields, formikActions) => {
            try {
                const data = {
                    deposit_amount: parseInt(fields.deposit_amount),
                    ...fields,
                }
                const res = await API.factoring.depositReceivables(data);
                dispatch(closeDialog());
                setTimeout(() => dispatch(openDialog(
                    undefined,
                    "",
                    "",
                    {
                        invoiceNumber: res.invoice_number
                    },
                    "deposit_created_successfully"
                )));
            } catch(err) {
                dispatch(openSnackbar("error", head(err)));
            }
            formikActions.setSubmitting(false);
    },
    [],
    )

    const handleExit = useCallback(() => dispatch(closeDialog()), [])
    return (
        <Dialog open={open} maxWidth="sm" fullWidth className="confirm_modal">
            <DialogTitle>Create Deposit</DialogTitle>
            <Formik
                onSubmit={onSubmit}
                initialValues={{
                    deposit_type: 'self_finance_wallet',
                    deposit_amount: 0,
                    company_id: factorClient?.id
                }}
            >
                {({
                    values,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => {
                    return (
                        <>
                            <DialogContent style={{ margin: 20 }}>
                                <Grid container direction="column" spacing="32">
                                    <Grid item>
                                        <Select
                                            fullWidth
                                            label="Deposit Type"
                                            name="deposit_type"
                                            value={values.deposit_type}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            selections={[{
                                                value: 'self_finance_wallet',
                                                text: 'Self-Finance Wallet'
                                            }]}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Pennies
                                            label="Deposit Amount"
                                            name="deposit_amount"
                                            value={values.deposit_amount}
                                            setFieldValue={setFieldValue}
                                            withStyle
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <SpinnerButton
                                    id="yes"
                                    size="small"
                                    color="secondary"
                                    onClick={handleExit}
                                >
                                    cancel
                                </SpinnerButton>
                                <SpinnerButton
                                    id="yes"
                                    size="small"
                                    color="primary"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </SpinnerButton>
                            </DialogActions>
                        </>
                    )
                }}
            </Formik>
        </Dialog>
    )
};

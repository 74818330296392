import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Switch
} from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import Yup from "../../../../../../../YupValidation";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions
} from "../../../../../../../components/Dialog";
import CustomTextField from "../../../../../../../components/inputs/CustomTextField";

const validatorSchema = () => {
  const schema = {
    routingNumber: Yup.string().required("Please fill out this field").routingNumberIsValid(),
  }
  return Yup.object().shape(schema);
}

const ACH = ({ open, selectNone, handleExit, createAccount, title }) => (
  <Dialog open={open} maxWidth="sm" fullWidth>
    <Formik
      initialValues={{
        accountType: "",
        accountNumber: "",
        routingNumber: "",
        isThisABusinessAccount: false
      }}
      validationSchema={validatorSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await createAccount(
            values.accountType,
            values.accountNumber,
            values.routingNumber,
            values.isThisABusinessAccount
          );
          selectNone();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue
      }) => (
        <React.Fragment>
          <DialogTitle onClose={handleExit}>{title || "Add ACH"}</DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <CustomTextField
                  fullWidth
                  select
                  id="accountType"
                  name="accountType"
                  label="Account Type"
                  value={values.accountType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="CHECKING">Checking</MenuItem>
                  <MenuItem value="SAVINGS">Savings</MenuItem>
                </CustomTextField>
              </Grid>
              <Grid item>
                <CustomTextField
                  fullWidth
                  name="accountNumber"
                  label="Account Number"
                  value={values.accountNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.accountNumber}
                  error={!!errors.accountNumber}
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  fullWidth
                  name="routingNumber"
                  label="Routing Number"
                  value={values.routingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.routingNumber}
                  error={!!errors.routingNumber}
                />
              </Grid>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isThisABusinessAccount}
                        onChange={() => {
                          setFieldValue(
                            "isThisABusinessAccount",
                            !values.isThisABusinessAccount
                          );
                        }}
                        value="isThisABusinessAccount"
                      />
                    }
                    label="Is this a business account?"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => selectNone()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Add Bank
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Formik>
  </Dialog>
);

export default ACH;

import {
  UPDATE_FACTORING_PROFILE_FAILURE,
  UPDATE_FACTORING_PROFILE_REQUEST,
  UPDATE_FACTORING_PROFILE_SUCCESS
} from "../types";

const updateFactoringProfile = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_FACTORING_PROFILE_REQUEST:
      return {
        ...state,
        updateFactoringProfileLoading: true
      };
    case UPDATE_FACTORING_PROFILE_SUCCESS:
      return {
        ...state,
        updateFactoringProfileLoading: false,
        updateFactoringProfileSuccess: payload
      };
    case UPDATE_FACTORING_PROFILE_FAILURE:
      return {
        ...state,
        updateFactoringProfileLoading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default updateFactoringProfile;

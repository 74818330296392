import { assign } from "lodash";
import {
  FETCH_ADMIN_STATS_FAILURE,
  FETCH_ADMIN_STATS_SUCCESS,
  FETCH_ADMIN_STATS_REQUEST
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_ADMIN_STATS_REQUEST:
      return assign({}, state, {
        loading: true
      });
    case FETCH_ADMIN_STATS_SUCCESS:
      return assign({}, state, {
        loading: false,
        items: action.payload.items
      });
    case FETCH_ADMIN_STATS_FAILURE:
      return assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
};

import { merge, assign } from "lodash";
import {
  DELETE_ATTACHMENT_REQUEST,
  DELETE_ATTACHMENT_FAILURE,
  DELETE_ATTACHMENT_SUCCESS
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case DELETE_ATTACHMENT_REQUEST:
      return {
        ...state,
        loadingFactoringAttachments: true
      };
    case DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        loadingFactoringAttachments: false,
        attachments: merge(
          {},
          assign({}, state.attachments, {
            [action.payload.fundingRequestId]: state.attachments[
              action.payload.fundingRequestId
            ].filter(attachment => attachment !== action.payload.attachmentId)
          })
        )
      };
    case DELETE_ATTACHMENT_FAILURE:
      return {
        ...state,
        loadingFactoringAttachments: false
      };
    default:
      return state;
  }
};

import React, { useCallback, useRef, useState } from 'react';
import map from 'lodash/map'
import moment from 'moment';
import { faHistory, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip, Typography } from '@material-ui/core';

import { Select } from '../inputs/BoldInput';
import DatePicker from "../inputs/MDatePicker";
import SendNOADialog from '../NOASummary/SendNOADialog';
import AuditLogDialog from '../../screens/Admin/Debtor/ConnectedClients/components/AuditLogDialog';
import colors from '../../theme/colors';

const NOADropDown = props => {
    const selectValue = {
        relationship_created: 'Relationship Created',
        noa_requested: 'NOA Requested',
        noa_sent: 'NOA Sent',
        noa_active: 'NOA Active',
    }
    const NOARules = status => {
        switch(status) {
            case 'relationship_created':
                return ['noa_requested', 'noa_sent', 'noa_active'];
            case 'noa_requested':
                return ['noa_sent', 'noa_active'];
            case 'noa_sent':
                return ['noa_active'];
            default:
                return ['noa_requested', 'noa_sent', 'noa_active'];       
        }
    }
    const getNoaStatus = row => {
        if (!row.noa_active && !row.noa_sent && !row.noa_requested) {
            return { noa_status: 'relationship_created', date: row.relationship_created || '' }
        }
        if (row.noa_active) {
            return { noa_status: 'noa_active', date: row.noa_active };
        }
        if (row.noa_sent) {
            return { noa_status: 'noa_sent', date: row.noa_sent };
        }
        if (row.noa_requested) {
            return { noa_status: 'noa_requested', date: row.noa_requested };
        }
    }
    const [openSentNOAModal, setOpenSentNOAModal] = useState(false);
    const [openAuditLog, setOpenAuditLog] = useState(false);
    const [NOADates, setNOADates] = useState(props.row);
    
    const pickerRef = useRef(null);

    const openPicker = useCallback(
        e => {
            if (pickerRef.current == null) {
                return;
            }
            // @ts-ignore
            pickerRef.current.open(e);
        },
        [pickerRef.current]
    );
    const { noa_status: NOAStatus } = getNoaStatus(props.row);
    return (
        <>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <Select
                    {...props}
                    selectProps={{
                        IconComponent: 'empty'
                    }}
                    value={props.updatedValue || NOAStatus}
                    name="noa"
                    onChange={event => {
                        props.setFieldValue("noa", event.target.value);
                        if (event.target.value === 'noa_active') {
                            return openPicker();
                        }
                        if (event.target.value === 'noa_sent') {
                            setOpenSentNOAModal(true);
                        }
                        setNOADates({
                            ...props.row,
                            [event.target.value]: moment().toISOString()
                        });
                        props.setFieldValue(event.target.value, moment().toISOString());
                        NOARules(event.target.value).map(status => props.setFieldValue(status, null));
                    }}
                    selections={map(selectValue, (option, key) => ({
                        value: key,
                        text: (
                            <>
                                <Typography variant='body2' style={{ marginRight: 10 }}>
                                    {option}
                                </Typography>
                                {NOADates[key] && <Typography noWrap node="span" variant='caption' color='secondary'>
                                    {moment(NOADates[key]).format('lll')}
                                </Typography>}
                            </>
                        )
                    }))}
                    autoWidth
                />
                {props.save && <Tooltip title="Save Updates">
                    <IconButton
                        style={{ width: '48px', height: '48px' }}
                        onClick={props.save}
                    >
                        <FontAwesomeIcon color={colors.green_dark} icon={faSave} />
                    </IconButton>
                </Tooltip>}
                {props.relationshipId && <Tooltip title="Audit Trail">
                    <IconButton
                        style={{ width: '48px', height: '48px' }}
                        onClick={() => setOpenAuditLog(true)}
                    >
                        <FontAwesomeIcon color={colors.link} icon={faHistory} />
                    </IconButton>
                </Tooltip>}
            </div>
            <DatePicker
                style={{ display: "none" }}
                clearable
                value={props.values?.active}
                onChange={async (dateTime) => {
                    let activeTime = null;
                    if (dateTime) {
                        activeTime = dateTime.toISO();
                        setNOADates({
                            ...props.row,
                            'noa_active': dateTime.toISO()
                        });
                    }
                    props.setFieldValue('noa_active', activeTime);
                }}
                pickerRef={pickerRef}
            />
            <SendNOADialog
                open={openSentNOAModal}
                handleClose={() => setOpenSentNOAModal(false)}
                clientName={props.clientName}
                debtorName={props.debtorName}
                placeNOAForm={props.placeNOAForm}
            />
            <AuditLogDialog
                open={openAuditLog}
                handleClose={() => setOpenAuditLog(false)}
                relationshipId={props.relationshipId}
            />
        </>
    )
}
export default NOADropDown;

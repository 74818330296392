import axios, { AxiosRequestConfig } from "axios";
import deleteCommaKeys from "../helpers/deleteCommaKeys";
import omitEmptyKeys from "../helpers/omitEmptyKeys";

const fetchFactorCompany = async (params: object): Promise<any> => {
  const url = `/factoring/company/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET",
    params
  };
  const response = await axios(options);
  return response.data;
};

const createFactorCompany = async (data: object): Promise<any> => {
  const url = `/factoring/company/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const getProfileHistory = async (profileId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/${profileId}/history/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const sendFactorCompanyInvite = async (
  companyId: string,
  paymentProfile?: string
): Promise<any> => {
  const url = `/factoring/company/${companyId}/send_invite/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: paymentProfile ? { payment_profile: paymentProfile } : {}
  };
  const response = await axios(options);
  return response.data;
};

const createFactorCompanyRelation = async (
  companyId: string,
  brokerClient: string,
  paymentProfileId?: string,
  data?: Record<string, string>
): Promise<any> => {
  const url = `/factoring/company/${companyId}/relations/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      broker_client: brokerClient,
      payment_profile: paymentProfileId,
      ...data
    }
  };
  const response = await axios(options);
  return response.data;
};

const getRelationByFactorCompanyId = async (
  companyId: string
): Promise<any> => {
  const url = `/factoring/company/${companyId}/relations/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const createAttachmentNOA = async ({
  id,
  filename,
  category,
  for_relation
}: {
  id: string;
  filename: string;
  category?: string;
  for_relation?: string;
}): Promise<any> => {
  const url = `/factoring/company/relations/${id}/attachments/`;
  const options: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      filename,
      category,
      for_relation
    }
  };
  return axios(options);
};

const getFactorCompanyRelationships = async (
  id: string,
  ordering: number,
  limit: string,
  filters: Record<string, string>
) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/${id}/relations/`,
    method: "GET",
    params: {
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateFactorCompanyRelationship = async (id: string, data: string) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/relations/${id}/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const deleteFactorCompanyRelationship = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/relations/${id}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const getFactorCompanyRelationship = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/relations/${id}/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getFactorCompanyNotes = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/${id}/notes/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const createNote = async (
  factorCompanyId: string,
  data: Record<string, any>
): Promise<any> => {
  let filename;
  if (data.attachments && data.attachments.name) {
    filename = [{ filename: data.attachments.name }];
  }
  const options: AxiosRequestConfig = {
    url: `/factoring/company/${factorCompanyId}/notes/`,
    method: "POST",
    data: {
      note: data.note,
      attachments: filename
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateNote = async (
  factorCompanyId: string,
  data: Record<string, any>
): Promise<any> => {
  let filename;
  if (data.attachments && data.attachments.name) {
    filename = [{ filename: data.attachments.name }];
  }
  const options: AxiosRequestConfig = {
    url: `/factoring/company/${factorCompanyId}/notes/${data.id}/`,
    method: "PATCH",
    data: {
      note: data.note,
      attachments: filename
    }
  };
  const response = await axios(options);
  return response.data;
};

const deleteNote = async (
  factorCompanyId: string,
  noteId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/company/${factorCompanyId}/notes/${noteId}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const getFactoringCompanyPurchases = (
  factoringCompanyId: string,
  limit: number,
  filters: Record<string, string>
): Promise<any> => {
  const url = `/factoring/company/${factoringCompanyId}/payments/`;
  const options: AxiosRequestConfig = {
    url,
    method: "GET",
    params: {
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  return axios(options);
};

const exportFactoringCompanyPurchaseReport = async (
  factoringCompanyId: string,
  purchaseId: string
): Promise<any> => {
  let url;
  if (purchaseId) {
    url = `/factoring/company/${factoringCompanyId}/payments/${purchaseId}/export/`;
  } else {
    url = `/factoring/company/${factoringCompanyId}/payments/export/`;
  }
  const options: AxiosRequestConfig = {
    url,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const downloadPurchaseReport = async (purchaseId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/purchases/${purchaseId}/export/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

export default {
  deleteNote,
  createNote,
  updateNote,
  getFactorCompanyNotes,
  fetchFactorCompany,
  createAttachmentNOA,
  createFactorCompanyRelation,
  sendFactorCompanyInvite,
  getRelationByFactorCompanyId,
  getFactorCompanyRelationships,
  updateFactorCompanyRelationship,
  deleteFactorCompanyRelationship,
  getFactorCompanyRelationship,
  getFactoringCompanyPurchases,
  exportFactoringCompanyPurchaseReport,
  downloadPurchaseReport,
  getProfileHistory,
  createFactorCompany
};

import { Card } from "@material-ui/core";
import React, { CSSProperties, ReactNode } from "react";

interface Props {
  children: ReactNode;
  color?: string;
  style?: CSSProperties;
}

export default ({ children, color, style = {} }: Props): JSX.Element => (
  <Card
    style={{
      backgroundColor: color || "white",
      boxShadow: "0px 2px 4px 0 rgba(0,0,0,0.50)",
      ...style
    }}
  >
    {children}
  </Card>
);

import axios, { AxiosRequestConfig } from "axios";
import omitEmptyKeys from "../helpers/omitEmptyKeys";
import deleteCommaKeys from "../helpers/deleteCommaKeys";

const getDebtors = async (
  status: string,
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/debtors/`,
    method: "GET",
    params: {
      status,
      ordering,
      offset,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getDebtorsExport = async (
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/debtors/export/`,
    method: "GET",
    params: {
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getConnectedClientsExport = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/debtors/${id}/export/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getDebtorHistory = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/debtor/${id}/history/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const getDebtor = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/debtors/${id}/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const updateDebtor = async (
  id: string,
  data: Record<string, any>
): Promise<any> => {
  const host = process.env.REACT_APP_VGS_HOST;
  const options: AxiosRequestConfig = {
    url: `${host}/admin/debtors/${id}/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const getDeborPayments = async (
  id: string,
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/debtor/${id}/funding_requests/open/`,
    method: "GET",
    params: {
      ordering,
      limit,
      offset,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getSimilarDebtors = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/debtor/${id}/related/suggestions/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const createDebtor = async (debtor: string): Promise<any> => {
  const host = process.env.REACT_APP_VGS_HOST;
  const options: AxiosRequestConfig = {
    url: `${host}/admin/debtors/`,
    method: "POST",
    data: debtor
  };
  const response = await axios(options);
  return response.data;
};

const deleteDebtor = async (id: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/debtors/${id}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const getCreditReport = async (
  id: string,
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/debtors/${id}/credit_report/`,
    method: "GET",
    params: {
      ordering,
      limit,
      offset,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getConnectedClients = async (
  id: string,
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/debtor/${id}/clients/`,
    method: "GET",
    params: {
      ordering,
      limit,
      offset,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getDebtorInvoices = async (
  id: string,
  ordering: string,
  limit: number,
  offset: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/`,
    method: "GET",
    params: {
      line_item_stats: true,
      client_reserve_stats: true,
      debtor_id: id,
      ordering,
      limit,
      offset,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const updateFactoringDebtor = async (
  debtor: string,
  factoring: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/debtors/`,
    method: "PATCH",
    data: {
      debtor,
      factoring,
      ...data
    }
  };
  const response = await axios(options);
  return response.data;
};

const deleteFactoringDebtorRelationship = async (
  factoringId: string,
  debtorId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/debtors/${factoringId}/`,
    method: "DELETE",
    data: {
      debtor: debtorId
    }
  };
  const response = await axios(options);
  return response.data;
};

const createFactoringDebtorRelationship = async (
  factoringId: string,
  debtorId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/debtors/${factoringId}/`,
    method: "POST",
    data: {
      debtor: debtorId,
      factoring: factoringId
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryConnectedDebtors = async (
  factoringId: string,
  ordering: string,
  limit: number,
  filters: Record<string, string>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/factoring/debtors/${factoringId}/`,
    method: "GET",
    params: {
      ordering,
      limit,
      ...deleteCommaKeys(omitEmptyKeys(filters))
    }
  };
  const response = await axios(options);
  return response.data;
};

const getNotesTimeline = async (debtorId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/debtors/notes_timeline/`,
    method: "GET",
    params: {
      debtor_id: debtorId
    }
  };
  const response = await axios(options);
  return response.data;
};

const queryLoadAttachment = async ({
  attachmentId,
  attachment
}: {
  attachmentId: string;
  attachment: string;
}): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/debtors/notes/${attachmentId}/`,
    method: "GET",
    params: {
      attachment
    },
    paramsSerializer: (params: any): string => `attachment=${params.attachment}`
  };
  const response = await axios(options);
  return response.data;
};

const createNote = async (
  debtorId: string,
  note: string,
  attachment: any
): Promise<any> => {
  const data = new FormData();
  if (attachment && attachment !== [] && attachment !== "") {
    data.append("attachments", attachment);
  }
  data.append("note", note);
  data.append("debtor", debtorId);
  const options: AxiosRequestConfig = {
    url: `/admin/debtors/notes/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const updateNote = async (
  noteId: string,
  note: string,
  attachment: any
): Promise<any> => {
  const data = new FormData();
  if (attachment && attachment !== [] && attachment !== "") {
    data.append("attachments", attachment);
  }
  data.append("note", note);

  const options: AxiosRequestConfig = {
    url: `/admin/debtors/notes/${noteId}/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const deleteUserNotes = async (notesId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/debtors/notes/${notesId}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const generateReport = async (
  debtorId: string,
  receivableStatus: string,
  responseCategory: string,
  factoringCompanyProfileName: string,
  invoiceNumber: string,
  userLoadNumber: string,
  receivableAgeGTE: string,
  receivableAgeLTE: string,
  referenceNumber: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/debtor/${debtorId}/generate_report/`,
    method: "GET",
    params: {
      receivable_status: receivableStatus,
      response_category: responseCategory,
      factoring_company_profile_name: factoringCompanyProfileName,
      invoice_number: invoiceNumber,
      user_load_number: userLoadNumber,
      receivable_age_gte: receivableAgeGTE,
      receivable_age_lte: receivableAgeLTE,
      reference_number: referenceNumber
    }
  };
  const response = await axios(options);
  return response.data;
};

const uploadReport = async (debtorId: string, file: any): Promise<any> => {
  const data = new FormData();
  data.append("file", file);

  const options: AxiosRequestConfig = {
    url: `/debtor/${debtorId}/generate_report/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

const addCreditCheckData = async (data: any): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/admin/debtors/${data.debtor}/credit_report/`,
    method: "POST",
    data
  };
  const response = await axios(options);
  return response.data;
};

export default {
  getDebtors,
  getDebtorHistory,
  deleteFactoringDebtorRelationship,
  createDebtor,
  getDebtor,
  updateDebtor,
  deleteDebtor,
  getDeborPayments,
  getConnectedClients,
  queryConnectedDebtors,
  getDebtorInvoices,
  updateFactoringDebtor,
  getSimilarDebtors,
  getNotesTimeline,
  createNote,
  updateNote,
  generateReport,
  uploadReport,
  createFactoringDebtorRelationship,
  getDebtorsExport,
  getConnectedClientsExport,
  queryLoadAttachment,
  deleteUserNotes,
  getCreditReport,
  addCreditCheckData
};

import React from "react";
import userTypeArray from "../../../helpers/userTypeArray";
import StatusFormat from "../../../components/StatusFormat";

const columns = [
  {
    key: "company_name",
    name: "DBA",
    filter: "text",
    filter_key: "company_name"
  },
  {
    key: "email",
    name: "Email",
    filter: "text",
    filter_key: "email"
  },
  {
    key: "mc",
    name: "MC#",
    filter: "text",
    filter_key: "mc"
  },
  {
    key: "user_type",
    name: "User Type"
  },
  {
    key: "credit_approved",
    name: "Credit Approved",
    format: (value: string): JSX.Element => <StatusFormat status={value} />
  },
  {
    key: "source.type",
    name: "Source",
    filter: "dropdown",
    filter_key: "user_type",
    filter_value: "",
    filter_values: userTypeArray
  },
  {
    key: "created",
    name: "Created At",
    sort: true
  },
  {
    key: "address.state",
    name: "State",
    default: "-",
    filter: "text",
    filter_key: "state"
  },
  {
    key: "address.zip",
    name: "Zip",
    filter: "text",
    filter_key: "zip"
  },
  {
    key: "more",
    name: "More"
  }
];

export default columns;

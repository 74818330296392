import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";

import { addEntities } from "../../entities";
import {
  FETCH_PURCHASES_ITEMS_FAILURE,
  FETCH_PURCHASES_ITEMS_REQUEST,
  FETCH_PURCHASES_ITEMS_SUCCESS
} from "../types";

const debounced = debounce(
  async (id, dispatch, schema, api, limit, filters) => {
    dispatch(
      createAction(FETCH_PURCHASES_ITEMS_REQUEST)({
        id,
        limit,
        filters
      })
    );
    const response = await api.finances.queryFetchPurchaseItems(
      id,
      limit,
      filters
    );
    const data = normalize(response.results, [schema.purchase]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_PURCHASES_ITEMS_SUCCESS)({
        id,
        items: data.result,
        count: response.count,
        limit,
        filters
      })
    );
    return response;
  },
  100,
  { trailing: true }
);

const fetchPurchaseItems = (id, limit, filters = {}) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    await debounced(id, dispatch, schema, api, limit, filters);
  } catch (err) {
    dispatch(createAction(FETCH_PURCHASES_ITEMS_FAILURE)(err));
    throw err;
  }
};

export default fetchPurchaseItems;

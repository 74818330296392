import { createAction } from "redux-starter-kit";
import {
  CURRENT_USER_FAILURE,
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  FETCH_FACTORING_PROFILE_FAILURE,
  FETCH_FACTORING_PROFILE_REQUEST,
  FETCH_FACTORING_PROFILE_SUCCESS
} from "../types";

const currentUser = () => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(CURRENT_USER_REQUEST)());
    const response = await api.user.currentUser();
    const user = await api.user.getUserById(response.id);
    window.analytics.identify(user.id, user);
    dispatch(createAction(CURRENT_USER_SUCCESS)(user));
    if (user.factoring_id) {
      dispatch(createAction(FETCH_FACTORING_PROFILE_REQUEST)());
      const factoringResponse = await api.factoring.getProfile(
        user.factoring_id
      );
      dispatch(
        createAction(FETCH_FACTORING_PROFILE_SUCCESS)(factoringResponse)
      );
    }
  } catch (err) {
    dispatch(createAction(FETCH_FACTORING_PROFILE_FAILURE)(err));
    dispatch(createAction(CURRENT_USER_FAILURE)(err));
    throw err;
  }
};

export default currentUser;

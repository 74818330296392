import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_TMS_FAILURE,
  FETCH_TMS_REQUEST,
  FETCH_TMS_SUCCESS
} from "../types";

const fetchTMS = () => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_TMS_REQUEST)());
    const response = await api.factoring.tms.fetchTMS();
    const data = normalize(response.results, [schema.tms]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_TMS_SUCCESS)({
        items: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_TMS_FAILURE)(err));
    throw err;
  }
};

export default fetchTMS;

import { merge } from "lodash";
import {
  FETCH_DEBTORPROFILE_REQUEST,
  FETCH_DEBTORPROFILE_SUCCESS,
  FETCH_DEBTORPROFILE_FAILURE
} from "../types";

const fetchDebtorProfile = (state, { type, payload }) => {
  switch (type) {
    case FETCH_DEBTORPROFILE_REQUEST:
      return {
        ...state,
        loadingDebtorProfile: true
      };
    case FETCH_DEBTORPROFILE_SUCCESS:
      return {
        ...state,
        debtorProfile: merge({}, state.debtorProfile, payload),
        loadingDebtorProfile: false
      };
    case FETCH_DEBTORPROFILE_FAILURE:
      return {
        ...state,
        loadingDebtorProfile: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchDebtorProfile;

import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import map from "lodash/map";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/index";

import { getTMS, fetchTMS } from "../../../modules/factoring";
import { Select } from "../BoldInput";
import { getDialog, openDialog, closeDialog } from "../../../modules/ui";
import TMSModal from "./components/TMSModal";

const TmsInput = ({ value, onBlur, onChange, setFieldValue }) => {
  const dispatch = useDispatch();
  useEffect(async () => dispatch(fetchTMS()), []);
  const tms = useSelector(getTMS);
  const dialog = useSelector(getDialog);
  const onClick = useCallback(args => dispatch(openDialog("tms-modal")), []);
  const handleClose = useCallback(args => dispatch(closeDialog()), []);

  return (
    <Grid container direction="column">
      {dialog.variant === "tms-modal" && (
        <TMSModal
          open={dialog.show}
          handleClose={handleClose}
          tmsList={tms.items}
          setFieldPreviewValue={setFieldValue}
        />
      )}
      <Select
        fullWidth
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        name="tms"
        label="TMS"
        selections={map(tms.items, ({ name = "", id }) => ({
          value: id,
          text: name.toUpperCase()
        }))}
      />
      <Grid item container justify="flex-end">
        <Typography
          color="secondary"
          variant="caption"
          style={{
            textDecoration: "underline",
            cursor: "pointer"
          }}
          onClick={onClick}
        >
          Add New?
        </Typography>
      </Grid>
    </Grid>
  );
};
export default TmsInput;

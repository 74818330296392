import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  FETCH_RECEIVABLE_FAILURE,
  FETCH_RECEIVABLE_REQUEST,
  FETCH_RECEIVABLE_SUCCESS
} from "../types";

const fetchReceivable = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(
      createAction(FETCH_RECEIVABLE_REQUEST)({
        id
      })
    );
    const response = await api.factoring.getFundingRequestReceivable(id);
    const data = normalize(response, schema.receivable);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_RECEIVABLE_SUCCESS)({
        id
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_RECEIVABLE_FAILURE)(err));
    throw err;
  }
};

export default fetchReceivable;

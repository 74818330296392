import { merge } from "lodash";
import {
  FETCH_TRANSACTIONNOTES_REQUEST,
  FETCH_TRANSACTIONNOTES_SUCCESS,
  FETCH_TRANSACTIONNOTES_FAILURE
} from "../types";

const fetchTransactionNotes = (state, { type, payload }) => {
  switch (type) {
    case FETCH_TRANSACTIONNOTES_REQUEST:
      return {
        ...state,
        loadingTransactionNotes: true
      };
    case FETCH_TRANSACTIONNOTES_SUCCESS:
      return {
        ...state,
        transactionNotes: merge({}, state.transactionNotes, payload),
        loadingTransactionNotes: false
      };
    case FETCH_TRANSACTIONNOTES_FAILURE:
      return {
        ...state,
        loadingTransactionNotes: false
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchTransactionNotes;

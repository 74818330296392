import { denormalize } from "normalizr";
import { get } from "lodash";
import { debtorProfile } from "../../../api/schema";

const getConnectedDebtors = state => {
  try {
    const items = get(
      state,
      ["factoringClient", "connectedDebtors", "ids"],
      []
    );

    const denormalized = denormalize(
      { items },
      { items: [debtorProfile] },
      state.entities
    );
    return denormalized.items;
  } catch (err) {
    return [];
  }
};

export default getConnectedDebtors;

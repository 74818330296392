export const FETCH_GROUPS_SUCCESS = "GROUPS/FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_REQUEST = "GROUPS/FETCH_GROUPS_REQUEST";
export const FETCH_GROUPS_FAILURE = "GROUPS/FETCH_GROUPS_FAILURE";

export const UPDATE_GROUP_SUCCESS = "GROUPS/UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_REQUEST = "GROUPS/UPDATE_GROUP_REQUEST";
export const UPDATE_GROUP_FAILURE = "GROUPS/UPDATE_GROUP_FAILURE";

export const FETCH_GROUP_SUCCESS = "GROUPS/FETCH_GROUP_SUCCESS";
export const FETCH_GROUP_REQUEST = "GROUPS/FETCH_GROUP_REQUEST";
export const FETCH_GROUP_FAILURE = "GROUPS/FETCH_GROUP_FAILURE";

export const FETCH_MEMBERS_SUCCESS = "GROUPS/FETCH_MEMBERS_SUCCESS";
export const FETCH_MEMBERS_REQUEST = "GROUPS/FETCH_MEMBERS_REQUEST";
export const FETCH_MEMBERS_FAILURE = "GROUPS/FETCH_MEMBERS_FAILURE";

export const UPDATE_DEBTOR_RELATIONSHIP_SUCCESS =
  "GROUPS/UPDATE_DEBTOR_RELATIONSHIP_SUCCESS";
export const UPDATE_DEBTOR_RELATIONSHIP_REQUEST =
  "GROUPS/UPDATE_DEBTOR_RELATIONSHIP_REQUEST";
export const UPDATE_DEBTOR_RELATIONSHIP_FAILURE =
  "GROUPS/UPDATE_DEBTOR_RELATIONSHIP_FAILURE";

export const UPDATE_PAYMENT_PROFILE_RELATIONSHIP_REQUEST =
  "GROUPS/UPDATE_PAYMENT_PROFILE_RELATIONSHIP_REQUEST";
export const UPDATE_PAYMENT_PROFILE_RELATIONSHIP_SUCCESS =
  "GROUPS/UPDATE_PAYMENT_PROFILE_RELATIONSHIP_REQUEST";
export const UPDATE_PAYMENT_PROFILE_RELATIONSHIP_FAILURE =
  "GROUPS/UPDATE_PAYMENT_PROFILE_RELATIONSHIP_REQUEST";

export const QUERY_FUNDINGREQUESTS_SUCCESS =
  "GROUPS/QUERY_FUNDINGREQUESTS_SUCCESS";
export const QUERY_FUNDINGREQUESTS_REQUEST =
  "GROUPS/QUERY_FUNDINGREQUESTS_REQUEST";
export const QUERY_FUNDINGREQUESTS_FAILURE =
  "GROUPS/QUERY_FUNDINGREQUESTS_FAILURE";

export const QUERY_INVITED_CARRIERS_REQUEST =
  "GROUPS/QUERY_INVITED_CARRIERS_REQUEST";
export const QUERY_INVITED_CARRIERS_SUCCESS =
  "GROUPS/QUERY_INVITED_CARRIERS_SUCCESS";
export const QUERY_INVITED_CARRIERS_FAILURE =
  "GROUPS/QUERY_INVITED_CARRIERS_FAILURE";

export const QUERY_FACTORING_COMPANIES_REQUEST =
  "GROUPS/QUERY_FACTORING_COMPANIES_REQUEST";
export const QUERY_FACTORING_COMPANIES_SUCCESS =
  "GROUPS/QUERY_FACTORING_COMPANIES_SUCCESS";
export const QUERY_FACTORING_COMPANIES_FAILURE =
  "GROUPS/QUERY_FACTORING_COMPANIES_FAILURE";

export const QUERY_CONNECTED_DEBTORS_REQUEST =
  "GROUPS/QUERY_CONNECTED_DEBTORS_REQUEST";
export const QUERY_CONNECTED_DEBTORS_SUCCESS =
  "GROUPS/QUERY_CONNECTED_DEBTORS_SUCCESS";
export const QUERY_CONNECTED_DEBTORS_FAILURE =
  "GROUPS/QUERY_CONNECTED_DEBTORS_FAILURE";

export const SEND_MEMBER_INVITE_SUCCESS = "GROUPS/SEND_MEMBER_INVITE_SUCCESS";
export const SEND_MEMBER_INVITE_REQUEST = "GROUPS/SEND_MEMBER_INVITE_REQUEST";
export const SEND_MEMBER_INVITE_FAILURE = "GROUPS/SEND_MEMBER_INVITE_FAILURE";

export const FETCH_FACTORING_COMPANY_REQUEST =
  "GROUPS/FETCH_FACTORING_COMPANY_REQUEST";
export const FETCH_FACTORING_COMPANY_SUCCESS =
  "GROUPS/FETCH_FACTORING_COMPANY_SUCCESS";
export const FETCH_FACTORING_COMPANY_FAILURE =
  "GROUPS/FETCH_FACTORING_COMPANY_FAILURE";

export const FETCH_PENDING_INVITE_REQUEST =
  "GROUPS/FETCH_PENDING_INVITE_REQUEST";
export const FETCH_PENDING_INVITE_SUCCESS =
  "GROUPS/FETCH_PENDING_INVITE_SUCCESS";
export const FETCH_PENDING_INVITE_FAILURE =
  "GROUPS/FETCH_PENDING_INVITE_FAILURE";

export const FETCH_CONNECTED_GROUPS_REQUEST =
  "GROUPS/FETCH_CONNECTED_GROUPS_REQUEST";
export const FETCH_CONNECTED_GROUPS_SUCCESS =
  "GROUPS/FETCH_CONNECTED_GROUPS_SUCCESS";
export const FETCH_CONNECTED_GROUPS_FAILURE =
  "GROUPS/FETCH_CONNECTED_GROUPS_FAILURE";

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_PLAIDUSER_FAILURE,
  FETCH_PLAIDUSER_REQUEST,
  FETCH_PLAIDUSER_SUCCESS
} from "../types";

const fetchPlaidUser = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_PLAIDUSER_REQUEST)());
    const response = await api.plaid.getUserPlaid(id);
    const data = normalize(response, [schema.plaidAccount]);

    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_PLAIDUSER_SUCCESS)({
        [id]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_PLAIDUSER_FAILURE)(err));
    throw err;
  }
};

export default fetchPlaidUser;

import { createAction } from "redux-starter-kit";
import { debounce } from "lodash";
import {
  FETCH_ADMIN_STATS_FAILURE,
  FETCH_ADMIN_STATS_REQUEST,
  FETCH_ADMIN_STATS_SUCCESS
} from "../types";
import formatStats from "../../../helpers/formatStats";

export default data => async (dispatch, getState, { api, schema }) => {
  try {
    await debounced(dispatch, schema, api, data);
  } catch (err) {
    dispatch(createAction(FETCH_ADMIN_STATS_FAILURE)(err));
    throw err;
  }
};
const debounced = debounce(
  async (dispatch, schema, api, data) => {
    dispatch(createAction(FETCH_ADMIN_STATS_REQUEST)(data));
    const response = await api.admin.fetchStats(data);
    const userLoadStats = [];
    Object.keys(formatStats).map((key, index) =>
      userLoadStats.push({
        name: formatStats[key],
        value: response[key]
      })
    );
    dispatch(
      createAction(FETCH_ADMIN_STATS_SUCCESS)({
        items: {
          ...response,
          userLoadStats
        }
      })
    );
    return response;
  },
  100,
  { trailing: true }
);

import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import moment from "moment";
import { get } from "lodash";

import colors from "../../../../theme/colors";
import formatPennies from "../../../../helpers/format/formatPennies";

const data = [
  {
    name: "Monday",
    funding: 2500
  },
  {
    name: "Tuesday",
    funding: 1000
  },
  {
    name: "Wednesday",
    funding: 10000
  },
  {
    name: "Thursday",
    funding: 4000
  },
  {
    name: "Friday",
    funding: 5000
  },
  {
    name: "Saturday",
    funding: 4000
  },
  {
    name: "Sunday",
    funding: 4500
  }
];

const styles = () => ({
  body2: {
    fontSize: "18px !important"
  }
});

const InvoiceStats = ({ stats, dateRange, classes }) => (
  <Paper>
    <Grid style={{ padding: 20 }} container direction="row" spacing="8">
      <Grid xs={8} style={{ height: 300 }}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="funding"
              stroke={colors.primary}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
      <Grid container xs={4} spacing="32">
        <Grid item>
          <Typography variant="title" color="textPrimary">
            Invoice stats
          </Typography>
          <Typography variant="caption" color="textPrimary">
            {`From ${moment(dateRange[0]).format("L")} to ${moment(
              dateRange[1]
            ).format("L")}`}
          </Typography>
        </Grid>
        <Grid container item spacing={16}>
          <Grid item xs={12} container justify="center">
            <Typography
              variant="body2"
              classes={{
                body2: classes.body2
              }}
              color="primary"
            >
              Value Invoices Factored
            </Typography>
          </Grid>
          <Grid item xs={12} container justify="center">
            <Typography variant="subtitle1" color="textSecondary">
              {formatPennies(get(stats, "items.total_invoice_value", 0))}
            </Typography>
          </Grid>
          <Grid item xs={12} container justify="center">
            <Typography
              variant="body2"
              classes={{
                body2: classes.body2
              }}
              color="primary"
            >
              Invoices Factored
            </Typography>
          </Grid>
          <Grid item xs={12} container justify="center">
            <Typography variant="subtitle1" color="textSecondary">
              {get(stats, "items.total_invoice_count", 0)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Paper>
);

export default withStyles(styles)(InvoiceStats);

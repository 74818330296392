import React, { useEffect, useState } from "react";
import { lifecycle } from "recompose";
import BerbixVerify from "berbix-react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { useSelector } from "react-redux";
import colors from "../../../theme/colors";
import BackHomeBtn from "../../Dashboard/ICPortal/components/BackHomeBtn";
import { getBerbixValues } from "../../../modules/berbix";

function Berbix({
  SaveBerbixRequest,
  moveToNextStep,
  requestBerbixToken,
  backButton = false
}) {
  const [showSkipButton, setSowSkipButton] = useState(false);
  const berbixValues = useSelector(getBerbixValues);
  useEffect(() => {
    async function asyncFunction() {
      try {
        await requestBerbixToken();
      } catch (e) {}
    }
    if (isEmpty(berbixValues.clientToken)) {
      asyncFunction();
    }
  }, [berbixValues.clientToken]);
  return (
    <Grid
      container
      direction="column"
      xs={10}
      sm={9}
      md={8}
      style={{ alignSelf: "center", width: "100%" }}
    >
      <Grid item>
        <Typography
          align="center"
          variant="body1"
          style={{
            margin: "10px 30px",
            fontWeight: 400,
            color: colors.gray
          }}
        >
          The last step is to verify that you are a real person. To do this
          please follow the ID Verify steps below.
        </Typography>
      </Grid>
      {(get(berbixValues, "isSaving", false) === true ||
        !get(berbixValues, "clientToken", "")) && (
        <Grid
          item
          style={{ paddingTop: 20, paddingBottom: 20 }}
          container
          justify="center"
        >
          <CircularProgress
            style={{ alignSelf: "center", justifySelf: "center" }}
          />
        </Grid>
      )}
      <Grid item>
        {get(berbixValues, "clientToken", "") && (
          <BerbixVerify
            clientId={`${berbixValues.clientId}`}
            clientToken={berbixValues.clientToken}
            onStateChange={({ step }) =>
              step === -1 ? setSowSkipButton(true) : ""
            }
            onComplete={async value => {
              await SaveBerbixRequest({
                transaction_id: value,
                ...berbixValues
              });
              moveToNextStep();
            }}
          />
        )}
      </Grid>
      <Grid
        item
        container
        style={{
          marginTop: get(berbixValues, "clientToken", "") ? "-90px" : 0,
          zIndex: 2
        }}
      >
        {showSkipButton && (
          <Grid item container justify="center">
            <a onClick={moveToNextStep}>
              <Typography
                style={{
                  color: colors.textLink,
                  textDecoration: "underline",
                  fontSize: 18,
                  padding: 5
                }}
              >
                Skip This Step
              </Typography>
            </a>
          </Grid>
        )}
        {backButton && (
          <Grid
            item
            style={{ paddingTop: 20, paddingBottom: 20 }}
            container
            justify="center"
          >
            <BackHomeBtn />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Berbix;

import { bindActionCreators } from "redux";
import qs from "qs";
import { compose, lifecycle, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";
import { find, findIndex, get, merge, head, keyBy, filter } from "lodash";
import { DateTime } from "luxon";
import withSelect from "../../../../components/withSelect";

import {
  createPaymentsFile,
  createReceivableCSV,
  fetchFundingRequestReceivables,
  getDebtors,
  getIsUploading,
  getOutstandingTotals,
  getPurchaseItems,
  getPurchases,
  getReceivables,
  getReceivablesById,
  receivablesActionsTable,
  selectorsRecivablesTable
} from "../../../../modules/finances";

import {
  createLineItem,
  fetchLineItemCategories,
  fetchLineItems,
  getLineItemCategories,
  getLineItems
} from "../../../../modules/factoring";

import {
  createDebtorPayment,
  getIsLoading as openFundingRequestsIsLoading,
  getOpenFundingRequests
} from "../../../../modules/debtor";

import { openModal } from "../../../../modules/modal";

import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../modules/ui";

import Receivables from "./Receivables";
import tabs from "../tabs";
import { getUserType } from "../../../../modules/auth";

const mapStateToProps = (
  state,
  { selected = [], selectedReceivable = {} }
) => ({
  userType: getUserType(state),
  receivables: getReceivables(state),
  isLoading: selectorsRecivablesTable.getIsLoading(state),
  sortDirection: selectorsRecivablesTable.getSortDirection(state),
  sortBy: selectorsRecivablesTable.getSortBy(state),
  count: selectorsRecivablesTable.getCount(state),
  page: selectorsRecivablesTable.getPage(state),
  rowsPerPage: selectorsRecivablesTable.getRowsPerPage(state),
  rowsPerPageOptions: selectorsRecivablesTable.getRowsPerPageOptions(state),
  filters: selectorsRecivablesTable.getFilters(state),
  openFundingRequests: getOpenFundingRequests(state),
  openFundingRequestsIsLoading: openFundingRequestsIsLoading(state),
  paymentsIsUploading: getIsUploading(state),
  purchases: getPurchases(state),
  purchaseItems: getPurchaseItems(state),
  LineItemCategories: getLineItemCategories(state),
  dialog: getDialog(state),
  outstandingTotals: getOutstandingTotals(state),
  debtors: getDebtors(state),
  selectedReceivables: getReceivablesById(selected)(state),
  receivableLineItems: getLineItems(
    get(selectedReceivable, ["funding_request", "id"], "")
  )(state)
});

const mapDispatchToProps = (dispatch, { selectedReceivable = {} }) =>
  bindActionCreators(
    {
      handleSort: receivablesActionsTable.handleSort,
      handleChangePage: receivablesActionsTable.handleChangePage,
      handleChangeRowsPerPage: receivablesActionsTable.handleChangeRowsPerPage,
      handleSearchFilter: receivablesActionsTable.handleSearchFilter,
      createReceivableCSV,
      fetchLineItemCategories,
      closeDialog,
      openDialog,
      openModal,
      fetchFundingRequestReceivables,
      fetchLineItems,
      createDebtorPayment,
      createPaymentsFile,
      openSnackbar,
      createLineItem: createLineItem(
        get(selectedReceivable, ["funding_request", "id"], "")
      )
    },
    dispatch
  );

export default compose(
  withSelect,
  withStateHandlers(
    ({
      datetime_end = DateTime.local(),
      datetime_start = DateTime.local(),
      paymentMethod = " ",
      newDocsBadges = {},
      selectedReceivable
    }) => ({ datetime_end, datetime_start, paymentMethod, selectedReceivable }),
    {
      onDateStartChange: () => datetime_start => ({ datetime_start }),
      onDateEndChange: () => datetime_end => ({ datetime_end }),
      onPaymentMethodChange: () => paymentMethod => ({ paymentMethod }),
      setNewDocsBadges: () => newDocsBadges => ({ newDocsBadges }),
      setSelectedReceivable: () => selectedReceivable => ({
        selectedReceivable
      })
    }
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      history: {
        location: { pathname },
        push
      },
      clearSelected,
      createReceivableCSV,
      filters,
      rowsPerPage,
      location,
      fetchFundingRequestReceivables,
      selected,
      handleSearchFilter,
      receivables = [],
      createDebtorPayment,
      openSnackbar,
      match
    }) => {
      const byId = keyBy(receivables, "id");
      const debtors = selected.map(id => get(byId, [id, "debtor"]));
      const singleDebtorSelected = new Set(debtors).size == 1;

      return {
        byId,
        tabs,
        handleSearchFilter: (...args) => {
          handleSearchFilter(...args);
          clearSelected();
        },
        handleRoute: route => push(route),
        handleCSV: async () => {
          try {
            await createReceivableCSV(filters, selected);
            clearSelected();
          } catch (e) {
            openSnackbar("error", "Error while exporting data.");
          }
        },
        handleSetStatus: async () => {
          clearSelected();
        },
        refresh: () => {
          const id = get(match, ["params", "id"], "");
          const query = { payment_profile_id: id };
          fetchFundingRequestReceivables("-time_posted", rowsPerPage, query);
        },
        singleDebtorSelected,
        debtorId: head(debtors)
      };
    }
  ),
  lifecycle({
    async componentDidMount() {
      const { rowsPerPage, fetchFundingRequestReceivables, match } = this.props;
      const id = get(match, ["params", "id"], "");
      const query = { payment_profile_id: id };
      await fetchFundingRequestReceivables("-time_posted", rowsPerPage, query);
    },
    async componentDidUpdate(prevProps) {
      const {
        statusIndex,
        fetchLineItemCategories,
        fetchFundingRequestReceivables,
        dialog,
        rowsPerPage,
        filters
      } = this.props;
      if (prevProps.dialog !== dialog && dialog.action === "Adjustments") {
        await fetchFundingRequestReceivables(
          "-time_posted",
          rowsPerPage,
          filters
        );
        fetchLineItemCategories();
      }
      if (prevProps.statusIndex !== statusIndex) {
        await fetchFundingRequestReceivables(
          "-time_posted",
          rowsPerPage,
          filters
        );
      }
    }
  })
)(Receivables);

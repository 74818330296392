import React, { useCallback } from "react";
import { DialogActions, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { DialogTitle, Dialog, DialogContent } from "../Dialog";
import SubmitButton from "../../screens/Admin/Group/Members/components/Submit";
import UploadFile from "../UploadFile";
import { clearFiles, getFiles } from "../../modules/upload";
import { openSnackbar } from "../../modules/ui";

const styles = {
  linePadding: {
    marginTop: 20
  },
  captionDiv: {
    marginTop: 50,
    flex: 1
  },
  upload: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  marginText: {
    marginTop: 20
  }
};

export default function RemoveFactorModal({
  open,
  handleExit,
  id,
  name,
  baseId,
  extraSubmitFunction
}) {
  const dispatch = useDispatch();
  const submitButton = useCallback(async () => {
    try {
      dispatch(clearFiles());
      if (extraSubmitFunction) {
        extraSubmitFunction();
      }
      handleExit();
      dispatch(openSnackbar("success", `Factoring company has been unlinked.`));
    } catch (e) {
      // dispatch(openSnackbar("error", e));
    }
  }, [dispatch]);
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={() => handleExit()}>
        Remove Factor Connection to Broker
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          You are removing the factor connection via LOR for {name}
        </Typography>
        <Typography variant="h6" style={styles.marginText}>
          Please Attach Letter of Release (LOR)
        </Typography>
        <hr />
        <div style={styles.upload}>
          <UploadFile
            single
            noCategories
            type="LOR"
            requestId={id}
            baseId={baseId}
          />
        </div>
        <hr style={styles.linePadding} />
        <div style={styles.captionDiv}>
          <Typography variant="caption">
            <b>Please</b> ensure that this payment profile has a default payment
            method and payment speed if no factor will be used for this broker
            connection moving forward
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <SubmitButton onClick={submitButton} />
      </DialogActions>
    </Dialog>
  );
}

import React from "react";
import { Redirect } from "react-router-dom";
import { Grid, Paper } from "@material-ui/core";
import { get } from "lodash";

import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import NavigationTabs from "../../../../components/NavigationTabs";
import tabs from "../tabs";
import Table from "../../../../components/Table";
import columns from "./columns";
import WithSimilarPaymentProfile from "../WithSimilarPaymentProfile";

export default function ConnectedBrokersCarriers(props) {
  const id = get(props, ["match", "params", "id"], "");
  const [count, data, loading] = WithSimilarPaymentProfile(id);

  return (
    <Can
      perform="admin-payments:view"
      yes={() => (
        <InternalLayout title="Similar Payment Profile">
          <Grid
            container
            direction="column"
            spacing={24}
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <NavigationTabs tabs={tabs(id, count)} value={5} />
            </Grid>
            <Grid item>
              <Paper style={{ padding: "16px" }}>
                <Table
                  columns={columns}
                  rows={data.map(o => ({
                    ...o,
                    company_name: (
                      <a href={`/admin/payment/${o.id}/profile`}>
                        {o.company_name}
                      </a>
                    ),
                    factoring_company_name: (
                      <a
                        href={`/admin/companies/${get(
                          o,
                          "factoring_company.id"
                        )}/profile`}
                      >
                        {get(o, "factoring_company.name")}
                      </a>
                    )
                  }))}
                  allowEmpty
                  isLoading={loading}
                />
              </Paper>
            </Grid>
          </Grid>
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
}

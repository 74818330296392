import Select from "react-select/lib/Async";
import NoSsr from "@material-ui/core/NoSsr";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import components from "../AutoCompleteAddress/components";

const useStyles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    padding: "3px 0 7px"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.38)"
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

const selectStyles = theme => ({
  input: base => ({
    ...base,
    color: theme.palette.text.primary,
    "& input": {
      font: "inherit"
    }
  })
});

const AutoComplete = ({
  classes,
  theme,
  name,
  onChange,
  onBlur,
  hasError,
  value,
  loadOptions,
  placeholder = "",
  label = "",
  withStyle = false,
  disabled = false,
  defaultOptions = [],
  autoCompleteComponents,
  DropdownIndicator = true
}) => {
  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          name={name}
          classes={classes}
          styles={selectStyles(theme)}
          textFieldProps={{
            label,
            withStyle
          }}
          isDisabled={disabled}
          value={value}
          cacheOptions
          defaultOptions={defaultOptions}
          error={hasError}
          label={label}
          onChange={onChange}
          onBlur={onBlur}
          components={{
            ...components,
            ...autoCompleteComponents,
            ...(() =>
              !DropdownIndicator
                ? {
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }
                : {})()
          }}
          loadOptions={loadOptions}
          placeholder={placeholder}
          isClearable
        />
      </NoSsr>
    </div>
  );
};

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired
  // value
};

export default withStyles(useStyles, { withTheme: true })(AutoComplete);

import { createReducer } from "redux-starter-kit";
import * as TYPES from "./types";
import requestBerbixTokenReducer from "./reducers/requestToken";
import requestBerbixRequestReducer from "./reducers/saveRequest";

const INITIAL_STATE = {
  isSaving: false
};

const reducer = createReducer(INITIAL_STATE, {
  [TYPES.FETCH_BERBIX_TOKEN_ERROR]: requestBerbixTokenReducer,
  [TYPES.FETCH_BERBIX_TOKEN_SUCCESS]: requestBerbixTokenReducer,
  [TYPES.FETCH_BERBIX_TOKEN_REQUEST]: requestBerbixTokenReducer,
  [TYPES.FETCH_BERBIX_REQUEST_ERROR]: requestBerbixRequestReducer,
  [TYPES.FETCH_BERBIX_REQUEST_REQUEST]: requestBerbixRequestReducer,
  [TYPES.FETCH_BERBIX_REQUEST_SUCCESS]: requestBerbixRequestReducer
});

export default reducer;

export { default as requestBerbixToken } from "./actions/requestToken";
export { default as SaveBerbixRequest } from "./actions/saveRequest";
export { default as getBerbixValues } from "./selectors/getBerbixTokenValues";

import { Grid } from "@material-ui/core";
import React from "react";
import Hidden from "@material-ui/core/Hidden";
import DesktopTabs, { Props as DesktopProps } from "./DesktopTabs";
import MobileTabs, { Outter as MobileProps } from "./MobileTabs";

type Props = DesktopProps & MobileProps;

const NavigationTabs = (props: Props): JSX.Element => (
  <Grid>
    {/* DESKTOP BIG TABLE */}

    <Hidden only={["xs", "sm", "md"]} implementation="js">
      <DesktopTabs {...props} />
    </Hidden>

    {/* MOBILE EXPANDABLE LIST OF CARDS */}

    <Hidden only={["lg", "xl"]} implementation="js">
      <MobileTabs {...props} />
    </Hidden>
  </Grid>
);

export default NavigationTabs;

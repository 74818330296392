import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS
} from "../types";

const updateUser = userId => (
  dispatch,
  getState,
  { api, schema }
) => async update => {
  try {
    dispatch(createAction(UPDATE_USER_REQUEST)({}));
    const response = await api.user.updateUser({
      userId,
      ...update
    });
    const data = normalize(response, schema.user);
    dispatch(addEntities(data.entities));
    dispatch(createAction(UPDATE_USER_SUCCESS)({}));
    return response;
  } catch (err) {
    dispatch(createAction(UPDATE_USER_FAILURE)(err));
    throw err;
  }
};

export default updateUser;

import { createAction } from "redux-starter-kit";
import {
  CREATE_DEBTOR_FAILURE,
  CREATE_DEBTOR_REQUEST,
  CREATE_DEBTOR_SUCCESS
} from "../types";

export default debtor => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(CREATE_DEBTOR_REQUEST)());
    const response = await api.debtors.createDebtor(debtor);
    dispatch(createAction(CREATE_DEBTOR_SUCCESS)(response.response));
  } catch (err) {
    dispatch(createAction(CREATE_DEBTOR_FAILURE)(err));
    throw err;
  }
};

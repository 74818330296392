import React, { useCallback } from "react";
import {
  Grid,
  List,
  ListItem,
  withStyles,
  DialogActions as MuiDialogActions
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import head from "lodash/head";

import BoldInput, { Pennies } from "../../../../../components/inputs/BoldInput";
import { DialogTitle, Dialog } from "../../../../../components/Dialog";
import { getDialog, openSnackbar } from "../../../../../modules/ui";
import { addCreditCheckData } from "../../../../../modules/debtor";
import Yup from "../../../../../YupValidation";
import SpinnerButton from "../../../../../components/buttons/SpinnerButton";

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

const formValidation = () =>
  Yup.object().shape({
    risk_score: Yup.number()
      .min(0, "Field should be equal or greater than 0")
      .max(100, "field should less or equal a 100")
  });

const AddCreditCheckDataModal = ({ id, handleClose }) => {
  const dispatch = useDispatch();
  const dialog = useSelector(getDialog);
  const addCredit = useCallback(async (arg, formikActions) => {
    try {
      await dispatch(addCreditCheckData(arg));
      dispatch(openSnackbar("success", "Data has been added"));
      handleClose();
    } catch (err) {
      dispatch(openSnackbar("error", head(err)));
    }
    formikActions.setSubmitting(false);
  }, []);
  return (
    <Dialog open={dialog.show} fullWidth>
      <Formik
        initialValues={{
          risk_score: "",
          days_to_pay: "",
          alerts: "",
          average_monthly_balance: "",
          total_companies_reporting: "",
          debtor: id
        }}
        onSubmit={addCredit}
        validationSchema={formValidation}
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          status,
          setStatus,
          isSubmitting
        }) => {
          return (
            <>
              <DialogTitle onClose={handleClose}>
                Add Credit Check Data Manually
              </DialogTitle>
              <Grid>
                <List dense style={{ width: "100%" }}>
                  <ListItem>
                    <BoldInput
                      fullWidth
                      label="Risk Score"
                      name="risk_score"
                      onChange={handleChange}
                      value={values.risk_score}
                      error={!!errors.risk_score}
                      helperText={errors.risk_score ? errors.risk_score : ""}
                    />
                  </ListItem>
                  <ListItem>
                    <BoldInput
                      fullWidth
                      label="Average DTP"
                      name="days_to_pay"
                      onChange={handleChange}
                      value={values.days_to_pay}
                      error={!!errors.days_to_pay}
                      helperText={errors.days_to_pay ? errors.days_to_pay : ""}
                    />
                  </ListItem>
                  <ListItem>
                    <BoldInput
                      fullWidth
                      label="Alerts"
                      name="alerts"
                      onChange={handleChange}
                      value={values.alerts}
                      error={!!errors.alerts}
                      helperText={errors.alerts ? errors.alerts : ""}
                    />
                  </ListItem>
                  <ListItem>
                    <Pennies
                      fullWidth
                      label="Average Monthly Balance"
                      name="average_monthly_balance"
                      value={values.average_monthly_balance}
                      onChangeValue={value =>
                        setFieldValue("average_monthly_balance", value)
                      }
                      onBlur={handleBlur}
                      error={!!errors.average_monthly_balance}
                      helperText={
                        errors.average_monthly_balance
                          ? errors.average_monthly_balance
                          : ""
                      }
                      withStyle
                    />
                  </ListItem>
                  <ListItem>
                    <BoldInput
                      fullWidth
                      label="Total Companies Reporting"
                      name="total_companies_reporting"
                      onChange={handleChange}
                      value={values.total_companies_reporting}
                      error={!!errors.total_companies_reporting}
                      helperText={
                        errors.total_companies_reporting
                          ? errors.total_companies_reporting
                          : ""
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <DialogActions>
                <SpinnerButton
                  variant="raised"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Save Updates
                </SpinnerButton>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};
export default AddCreditCheckDataModal;

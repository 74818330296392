import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import {
  CREATE_CLIENTNOTE_FAILURE,
  CREATE_CLIENTNOTE_REQUEST,
  CREATE_CLIENTNOTE_SUCCESS
} from "../types";
import { addEntities } from "../../entities";
import awsImageUpload from "../../../helpers/awsImageUpload";

export default (factoringProfileId, note, attachment) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(CREATE_CLIENTNOTE_REQUEST)({}));
    let filename = [];
    if (attachment && attachment.name) {
      filename = [{ filename: attachment.name }];
    }

    const response = await api.factoring.createFactoringClientNote(
      factoringProfileId,
      note,
      filename
    );

    if (attachment && attachment.name) {
      await awsImageUpload(
        [attachment].map(attachment => ({
          file: attachment,
          upload_url: response.attachments[0].upload_url
        }))
      );
    }

    const data = normalize(response, schema.clientNote);

    dispatch(addEntities(data.entities));
    dispatch(
      createAction(CREATE_CLIENTNOTE_SUCCESS)({
        [factoringProfileId]: [data.result]
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(CREATE_CLIENTNOTE_FAILURE)(err));
    throw err;
  }
};

import { normalize } from "normalizr";
import { createAction } from "redux-starter-kit";

import { addEntities } from "../../entities";
import { ADD_PURCHASES_SUCCESS } from "../types";

const addPurchase = (fundingRequest, payload) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  const response = await api.factoring.addPurchase(fundingRequest, payload);
  const data = normalize([response], [schema.purchase]);
  dispatch(addEntities(data.entities));
  dispatch(
    createAction(ADD_PURCHASES_SUCCESS)({
      data: data.result,
      id: fundingRequest
    })
  );
  return response;
};

export default addPurchase;

import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { factoring_document } from "../../../api/schema";

export default fundingRequestId =>
  memoize(state => {
    try {
      const item = get(state, `factoring.invoiceDocs.${fundingRequestId}`);
      const denormalized = denormalize(
        { item },
        { item: factoring_document },
        state.entities
      );
      return get(denormalized, "item", {});
    } catch (err) {
      return {};
    }
  });

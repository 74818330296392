import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import AutoComplete from "./AutoComplete";
import API from "../../api";
import styles from "./CustomTextField/styles";
import CustomTextField from "./CustomTextField";

const CarrierAutoComplete = ({ onNoOptionClick, ...props }) => {
  const loadOptions = useCallback(async name => {
    const res = await API.groups.queryInvitedCarriers(20, 0, "", {
      company_name: name,
      ...(() =>
        props.brokerClientId
          ? { broker_client_id: props.brokerClientId }
          : {})()
    });
    if (!isEmpty(res.results)) {
      return map(res.results, object => ({
        label: object.company_name,
        ...object
      }));
    }
    return [];
  }, []);

  const Option = ({
    innerRef,
    isFocused,
    isSelected,
    innerProps,
    children,
    data,
    ...props
  }) => (
    <ListItem dense divider selected={isFocused} button buttonRef={innerRef}>
      <ListItemText
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
        {...innerProps}
        primary={data.company_name}
        secondary={
          <React.Fragment>
            <Typography component="span" color="textSecondary">
              {`${data.owner_name_first} ${data.owner_name_last}`}
            </Typography>
            {data.company_mc && `MC: ${data.company_mc}\n`}
            {data.company_dot && `DOT: ${data.company_dot}\n`}
            {data.company_tax_id && `DOT: ${data.company_tax_id}`}
          </React.Fragment>
        }
      />
    </ListItem>
  );

  const Menu = ({ selectProps, innerProps, children }) => (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      <List dense="dense">{children}</List>
    </Paper>
  );

  const inputComponent = ({ inputRef, ...props }) => {
    return <div ref={inputRef} {...props} />;
  };

  const Control = withStyles(styles)(
    ({ classes, selectProps, innerRef, children, innerProps }) => {
      const {
        textFieldProps: { withStyle }
      } = selectProps;
      const underlineClasses = classNames({
        [classes.underlineAddress]: true
      });
      const inputClasses = withStyle
        ? {
            classes: {
              underline: underlineClasses,
              input: classes.input,
              error: classes.errorInput
            }
          }
        : {};
      const labelClasses = withStyle
        ? {
            classes: {
              focused: classes.cssFocused,
              error: classes.errorLabel
            }
          }
        : {};
      return (
        <CustomTextField
          fullWidth
          error={selectProps.error}
          InputProps={{
            inputComponent,
            ...inputClasses,
            inputProps: {
              className: selectProps.classes.input,
              inputRef: innerRef,
              children,
              ...innerProps
            }
          }}
          {...selectProps.textFieldProps}
          InputLabelProps={{
            ...labelClasses,
            shrink: true
          }}
        />
      );
    }
  );

  return (
    <AutoComplete
      loadOptions={name => loadOptions(name)}
      autoCompleteComponents={{ Option, Menu, Control }}
      {...props}
    />
  );
};
export default CarrierAutoComplete;

import React from "react";
import { compose } from "recompose";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { get, head } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPen } from "@fortawesome/free-solid-svg-icons";
import withLocalTable from "../../../../../../components/withLocalTable";
import Table from "../../../../../../components/Table";
import formatPennies from "../../../../../../helpers/format/formatPennies";
import formatDate from "../../../../../../helpers/format/formatDate";
import GridColumn from "../../../../Dashboard/components/GridColumn";
import NotesDialog from "../../../../../../components/dialogs/NotesDialog";
import colors from "../../../../../../theme/colors";
import LineItemDialog from "../../../../FundingRequest/components/LineItemDialog";
import ProfileSection from "../../../../../../components/ProfileSection";

const columns = [
  {
    key: "category_name",
    name: "Line Item"
  },
  {
    key: "cb_cg",
    name: "CB/CG"
  },
  {
    key: "amount",
    name: "Amount"
  },
  {
    key: "user_load_number",
    name: "Load #"
  },
  {
    key: "invoice_number",
    name: "Invoice #"
  },
  {
    key: "balance_amount",
    name: "Balance Amount"
  },
  {
    key: "age",
    name: "Age"
  },
  {
    key: "created",
    name: "Created"
  },
  {
    key: "actions",
    name: "Actions"
  }
];

const formattedRows = (rows, handleEditReceivableNotes, openEditLineItem) =>
  rows.map(row => ({
    id: row.id,
    category_name: (
      <Grid spacing={8}>
        <GridColumn
          numbers={12}
          key="category_name--1"
          style={{ minHeight: 50 }}
        >
          {row.category_name}
        </GridColumn>
        {row.reserve_line_item.map((object, key) => (
          <GridColumn
            numbers={12}
            key={`category_name-${key}`}
            style={{ minHeight: 50 }}
          >
            {""}
          </GridColumn>
        ))}
      </Grid>
    ),
    user_load_number: (
      <Grid spacing={8}>
        <GridColumn
          numbers={12}
          key="user_load_number--1"
          style={{ minHeight: 50 }}
        >
          {row.user_load_number}
        </GridColumn>
        {row.reserve_line_item.map((object, key) => (
          <GridColumn
            numbers={12}
            key={`user_load_number-${key}`}
            style={{ minHeight: 50 }}
          >
            {object.user_load_number}
          </GridColumn>
        ))}
      </Grid>
    ),
    cb_cg: (
      <Grid spacing={8}>
        <GridColumn
          numbers={12}
          key="user_load_number--1"
          style={{ minHeight: 50 }}
        />
        {row.reserve_line_item.map((object, key) => (
          <GridColumn
            numbers={12}
            key={`category_name-${key}`}
            style={{ minHeight: 50 }}
          >
            {object.category_name}
          </GridColumn>
        ))}
      </Grid>
    ),
    amount: (
      <Grid spacing={8}>
        <GridColumn
          numbers={12}
          key="user_load_number--1"
          style={{ minHeight: 50 }}
        >
          {formatPennies(row.amount_requested)}
        </GridColumn>
        {row.reserve_line_item.map((object, key) => (
          <GridColumn
            numbers={12}
            key={`amount_requested-${key}`}
            style={{ minHeight: 50 }}
          >
            {formatPennies(object.amount_requested)}
          </GridColumn>
        ))}
      </Grid>
    ),
    invoice_number: (
      <Grid spacing={8}>
        <GridColumn
          numbers={12}
          key="invoice_number--1"
          style={{ minHeight: 50 }}
        >
          <Link to={`/admin/fundingrequest/${row.funding_request_id}`}>
            {row.invoice_number}
          </Link>
        </GridColumn>
        {row.reserve_line_item.map((object, key) => (
          <GridColumn
            numbers={12}
            key={`invoice_number-${key}`}
            style={{ minHeight: 50 }}
          >
            <Link to={`/admin/fundingrequest/${object.funding_request_id}`}>
              {object.invoice_number}
            </Link>
          </GridColumn>
        ))}
      </Grid>
    ),
    balance_amount: (
      <Grid spacing={8}>
        <GridColumn
          numbers={12}
          key="balance_amount--1"
          style={{ minHeight: 50 }}
        >
          {formatPennies(row.balance_amount)}
        </GridColumn>
        {row.reserve_line_item.map((object, key) => (
          <GridColumn
            numbers={12}
            key={`balance_amount-${key}`}
            style={{ minHeight: 50 }}
          >
            {formatPennies(object.balance_amount)}
          </GridColumn>
        ))}
      </Grid>
    ),
    age: (
      <Grid spacing={8}>
        <GridColumn
          numbers={12}
          key="category_name--1"
          style={{ minHeight: 50 }}
        >
          {row.age}
        </GridColumn>
        {row.reserve_line_item.map((object, key) => (
          <GridColumn
            numbers={12}
            key={`category_name-${key}`}
            style={{ minHeight: 50 }}
          >
            {object.age}
          </GridColumn>
        ))}
      </Grid>
    ),
    created: (
      <Grid spacing={8}>
        <GridColumn
          numbers={12}
          key="category_name--1"
          style={{ minHeight: 50 }}
        >
          {formatDate(row.created)}
        </GridColumn>
        {row.reserve_line_item.map((object, key) => (
          <GridColumn
            numbers={12}
            key={`category_name-${key}`}
            style={{ minHeight: 50 }}
          >
            {formatDate(object.created)}
          </GridColumn>
        ))}
      </Grid>
    ),
    actions: (
      <ActionButton
        receivableId={row.receivable_id}
        handleEditReceivableNotes={handleEditReceivableNotes}
        fundingRequest={row.funding_request_id}
        lineItems={row.reserve_line_item}
        openEditLineItem={openEditLineItem}
      />
    )
  }));

const ActionButton = ({
  receivableId,
  handleEditReceivableNotes,
  fundingRequest,
  lineItems,
  openEditLineItem
}) => (
  <Grid container direction="column">
    <Grid item style={{ minHeight: 50 }}>
      <Tooltip title="View/Add Notes">
        <IconButton
          style={{ width: "25px", height: "25px", color: colors.link }}
          onClick={() =>
            handleEditReceivableNotes("", "", {
              receivableId,
              selected: [fundingRequest]
            })
          }
        >
          <FontAwesomeIcon icon={faEdit} color={colors.secondary} />
        </IconButton>
      </Tooltip>
    </Grid>
    {lineItems.map(lineItem => (
      <Grid item style={{ minHeight: 50 }}>
        <Tooltip title="View/Edit Line Item">
          <IconButton
            style={{ width: "25px", height: "25px", color: colors.link }}
            onClick={() => openEditLineItem("", "", { lineItem, type: "view" })}
          >
            <FontAwesomeIcon color={colors.link} size="xs" icon={faPen} />
          </IconButton>
        </Tooltip>
      </Grid>
    ))}
  </Grid>
);

export default compose(withLocalTable("reserve"))(
  ({
    getRows,
    getSortBy,
    getSortDirection,
    handleSort,
    getCount,
    getPage,
    getRowsPerPage,
    handleChangePage,
    getRowsPerPageOptions,
    handleChangeRowsPerPage,
    dialog,
    handleEditReceivableNotes,
    openEditLineItem,
    closeEditLineItem,
    updateLineItem,
    openSnackbar,
    lineItemCategories
  }) => (
    <React.Fragment>
      <ProfileSection>Chargebacks / Givebacks History</ProfileSection>
      <Table
        columns={columns}
        rows={formattedRows(
          getRows(),
          handleEditReceivableNotes,
          openEditLineItem
        )}
        allowEmpty
        count={getCount()}
        page={getPage()}
        rowsPerPage={getRowsPerPage()}
        handleChangePage={handleChangePage}
        rowsPerPageOptions={getRowsPerPageOptions()}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sort
        handleSort={handleSort}
        sortDirection={getSortDirection()}
        sortBy={getSortBy()}
      />
      {dialog.variant === "factor-receivables-notes" && (
        <NotesDialog
          receivableId={get(dialog, ["data", "receivableId"])}
          fundingRequestIds={get(dialog, ["data", "selected"])}
        />
      )}
      {dialog.variant === "factor-open-line-item" && (
        <LineItemDialog
          open={dialog.show}
          lineItemForm={{
            enableReinitialize: true,
            initialValues: {
              amountRequested:
                get(dialog, ["data", "lineItem", "amount_requested"]) / 100,
              discountRate: get(dialog, ["data", "lineItem", "discount_rate"]),
              againstWho: get(dialog, [
                "data",
                "lineItem",
                "category_applied_against"
              ]),
              category: get(dialog, ["data", "lineItem", "category"]),
              description: get(dialog, ["data", "lineItem", "description"]),
              approve: get(dialog, ["data", "lineItem", "approved"]),
              billWithFundingRequest: get(dialog, [
                "data",
                "lineItem",
                "bill_with_funding_request"
              ])
            },
            onSubmit: async (values, { setSubmitting }) => {
              try {
                await updateLineItem({
                  id: get(dialog, ["data", "lineItem.id"]),
                  amount_requested: Math.round(values.amountRequested * 100),
                  category: values.category,
                  description: values.description,
                  discount_rate: values.discountRate,
                  applied_against: values.againstWho,
                  bill_with_funding_request: values.billWithFundingRequest,
                  approval_status: values.approved
                });
                openSnackbar("success", "Line Item Modified!");
              } catch (err) {
                openSnackbar("error", head(err));
              } finally {
                setSubmitting(false);
              }
            }
          }}
          categories={lineItemCategories}
          handleClose={closeEditLineItem}
        />
      )}
    </React.Fragment>
  )
);

import React from "react";
import { get } from "lodash";
import { Grid, Typography } from "@material-ui/core";

import DropdownDrawer from "../../../../components/DropdownDrawer";
import colors from "../../../../theme/colors";

const MoreStats = ({ stats }) => (
  <DropdownDrawer
    title={<Typography variant="title">More Stats</Typography>}
    expanded={false}
  >
    <Grid container spacing={8}>
      {get(stats, ["items", "userLoadStats"], []).map((values, index) => (
        <Grid
          container
          direction="row"
          item
          xs={12}
          style={{
            backgroundColor: index % 2 !== 0 ? colors.white : colors.column
          }}
        >
          <Grid container item xs={6} justify="flex-start">
            <Typography variant="caption">{values.name}</Typography>
          </Grid>
          <Grid container item xs={6} justify="flex-end">
            <Typography variant="caption">{values.value || 0}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </DropdownDrawer>
);

export default MoreStats;

import { filter, flatMap, identity, merge, reduce } from "lodash";
import { useSelector } from "react-redux";
import { getRoles } from "../modules/auth";
import roleRules from "../roleRules";

const check = (
  rules: typeof roleRules,
  roles: string[],
  action: string,
  data?: any
): boolean => {
  const permissions = reduce(
    // @ts-ignore
    filter(flatMap(roles, role => rules[role]), identity),
    merge,
    {}
  );

  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

interface Props {
  yes: Function;
  no?: Function;
  perform: string;
  data?: any;
}

function Can({
  perform,
  data,
  yes = (): null => null,
  no = (): null => null
}: Props) {
  const roles = useSelector(getRoles);
  return check(roleRules, roles, perform, data) ? yes() : no();
}

export default Can;

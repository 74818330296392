import {
  DialogActions as MuiDialogActions,
  withStyles,
  createStyles
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const DialogActions = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit
    }
  })
)(MuiDialogActions);

export default DialogActions;

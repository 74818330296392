import { createAction } from "redux-starter-kit";
import {
  DELETE_USER_FILE_FAILURE,
  DELETE_USER_FILE_REQUEST,
  DELETE_USER_FILE_SUCCESS
} from "../types";

const deleteFile = fileId => async (dispatch, getState, { api }) => {
  try {
    dispatch(createAction(DELETE_USER_FILE_REQUEST)());
    const response = await api.user.deleteUserProfileDocuments(fileId);
    dispatch(createAction(DELETE_USER_FILE_SUCCESS)(response));
  } catch (err) {
    dispatch(createAction(DELETE_USER_FILE_FAILURE)(err));
    throw err;
  }
};

export default deleteFile;

import { withStyles } from "@material-ui/styles";
import { createStyles, ExpansionPanelDetails, Grid, WithStyles } from "@material-ui/core";
import React, { ReactNode } from "react";
import { concat } from "lodash";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BoldInput, { CheckBox, Select } from "../../../../components/inputs/BoldInput";
import CustomExpansionPanel from "../../../../components/ExpansionPanel";
import ExpansionPanelSummary from "../../../../components/ExpansionPanelSummary";
import equipmentTypeArray from "../../../../helpers/equipmentTypes";

const styles = createStyles({
  root: {
    width: "100%"
  },
  headerButton: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end"
  }
});

interface NewEvent {
  target: {
    name: string;
    value: any;
  };
}

interface Props extends WithStyles<typeof styles> {
  children?: ReactNode;
  user_type: string;
  setUserType: (type: string) => void;
  equipment_types: string;
  setEquipmentTypes: (equipment: string) => void;
  has_active_subscription: boolean;
  setActiveSubscription: (active: boolean) => void;
  handleSearchFilter: (event: NewEvent) => void;
  stripeEmail: string;
}

const Filters = withStyles(styles)(
  ({
    children,
    classes,
    handleSearchFilter,
    user_type,
    setUserType,
    equipment_types,
    setEquipmentTypes,
    has_active_subscription,
    setActiveSubscription,
    stripeEmail
  }: Props): JSX.Element => (
    <div className={classes.root}>
      <CustomExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<FontAwesomeIcon size="xs" icon={faSlidersH} />}
        >
          <div className={classes.headerButton}>{children}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid
            container
            spacing={32}
            style={{ marginRight: 30, marginLeft: 30 }}
          >
            <Grid item xs={3}>
              <BoldInput
                fullWidth
                label="Stripe Email"
                name="stripe_email"
                value={stripeEmail}
                onChange={handleSearchFilter}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                value={equipment_types || ""}
                onChange={(event: any): void => {
                  setEquipmentTypes(event.target.value);
                  handleSearchFilter(event);
                }}
                fullWidth
                label="Equipment Types"
                placeholder="Equipment Types"
                name="equipment_types"
                selections={concat(
                  [
                    {
                      value: "",
                      name: "None"
                    }
                  ],
                  equipmentTypeArray
                ).map((option: any): any => ({
                  value: option.value,
                  text: option.name
                }))}
              />
            </Grid>
            <Grid item xs={3}>
              <BoldInput
                fullWidth
                label="Address"
                name="address"
                onChange={handleSearchFilter}
              />
            </Grid>
            <Grid item xs={3}>
              <BoldInput
                fullWidth
                label="Business Name"
                name="dba"
                onChange={handleSearchFilter}
              />
            </Grid>
            <Grid item xs={3}>
              <CheckBox
                value={has_active_subscription}
                name="has_active_subscription"
                setFieldValue={(name, value) => {
                  setActiveSubscription(value);
                  const newEvent = {
                    target: {
                      name,
                      value
                    }
                  };
                  handleSearchFilter(newEvent);
                }}
                label="Is Subscribed?"
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </CustomExpansionPanel>
    </div>
  )
);

export default Filters;

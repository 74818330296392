import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { factoringProfile } from "../../../api/schema";

export default memoize(state => factoringId => {
  try {
    const profile = get(
      state,
      [`factoring`, `factoringProfile`, factoringId],
      {}
    );

    const denormalized = denormalize(
      { profile },
      { profile: factoringProfile },
      state.entities
    );

    return denormalized.profile;
  } catch (err) {
    return {};
  }
});

import axios, { AxiosRequestConfig } from "axios";

interface Address {
  street_one: string;
  street_two: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface Account {
  id: string;
  account_number_redacted: string;
  method: string;
}

interface Status {
  status: string;
  status_set_datetime: string;
  status_set_by_name: string;
  status_set_by_id: string;
}

export interface Admin {
  name: string;
  id: string;
}

interface Attachment {
  category: string;
  uploaded: string;
  created: string;
  preview_url: string;
  download_url: string;
  filename: string;
  after_issue: boolean;
  id: string;
}

export interface FactoringProfile {
  id: string;
  company_profile: {
    name: string;
    address: Address;
    phone_number: string;
    type: string;
    tax_id: string;
    mc: string;
    dot: string;
    state_incorporated: string;
    number_of_trucks: number;
    current_factoring_company: string;
    number_of_owner_operators: null | number;
    number_of_trailers: null | number;
    years_in_business: null | number;
  };
  owner_profile: {
    name: string;
    name_first: string;
    name_last: string;
    address: Address;
    phone_number_home: string;
    phone_number_cell: string;
    email: string;
    date_of_birth: string;
    social_number: string;
    tax_lien_status: string;
  };
  factoring_approved: string;
  factoring_message: string;
  program_type: string;
  discount_rate: number;
  noa_attachments: any[];
  minimum_fee: number;
  bank_info: boolean;
  aladdin_owned: boolean;
  accounts: Account[];
  attachments: string[];
  voided_checks: { filename: string }[];
  has_broker_authority_status: boolean;
  has_contract_authority_status: boolean;
  is_allowed_to_operate: boolean;
  last_submitted_funding_request: string;
  status_timeline: Status[];
  assigned_admin: Admin;
  admin_message: string;
  broker_approved: string;
  fuel_advance_rate: number;
  fuel_advance_fee: number;
  fuel_advance: boolean;
  fuel_advance_limit: number;
  user_type: string;
  assigned_admin_name: string;
  time_posted: string;
  modified: string;
  zoho_user_id: string;
  agreement_number: 667;
  admin_inactive_last_emailed_on: string;
  last_used_load_length: number | null;
  last_used_trailer_type: string;
  should_email_user: false;
  _minimum_fee: number;
  ucc_transferred: boolean;
  aladdin_id: string;
  origin: number;
  last_edited_by_id: string | null;
  last_edited_by_name: string;
  last_edited_by_user_type: string;
  trace_id_number: number;
  trace_id: string;
  user: {
    id: string;
    email: string;
    stripe_email: string;
    created: string;
    last_login: string;
    modified: string;
    user_type: string;
    contact_status: string;
    assigned_admin: string;
    assigned_admin_name: string;
    haulpay_interest: boolean;
    source: string;
    about: string;
    address: string;
    avgpay: null | number;
    cargo_insurance_provider: string;
    cargo_insurance_value: null | number;
    city: string;
    contact_email: string;
    contact_name: string;
    contact_phone: string;
    country: string;
    dba: string;
    dot: string;
    external: string;
    fax_number: string;
    image: string;
    mc: string;
    public_contact_info: boolean;
    credit_approved: boolean;
    state: string;
    url: string;
    zip: string;
    factoring: {
      factoring_approved: string;
      id: string;
    };
    factoring_id: string;
    debtor_id: string | null;
    groups: any[];
    activity: Record<string, any>;
    files: any[];
    full_truck_loads_per_month: null | number;
    ltl_loads_per_month: null | number;
    commodities_shipped: string;
    permissions: {
      is_subscribed: boolean;
      is_trailing: boolean;
    };
  };
}

export interface DebtorProfile {
  dba: string;
  credit_approved: string;
  originals_required: boolean;
  credit_available: number;
  transferred_to: null | string;
  id: string;
}

export interface GetFundingRequestResponse {
  created: string;
  modified: string;
  id: string;
  amount: number;
  bill_to_address: Address;
  first_origin_location: Address;
  final_destination_location: Address;
  load_length: null | number;
  load_trailer_type: string;
  factoring: FactoringProfile;
  attachments: Attachment[];
  status: string;
  funded_at: string;
  amount_funded: number;
  user_reference_number: string;
  discount_rate: number;
  bill_to_company_email: string;
  bill_to_company_phone: string;
  debtor: DebtorProfile;
  status_note: string;
  user_load_number: string;
  load: null | string;
  status_timeline: Status[];
  payment_method: string;
  source: string;
  source_user: string;
  assigned_admin: Admin;
  user_notes: string;
  needs_admin_review: boolean;
  unpaid_purchase_amount: number;
  fuel_advance_fee: number;
  fuel_advance_rate: number;
  fuel_advance_amount: null | number;
  invoice_notes: string;
  days_to_pay: number;
  accounts: Account[];
  client_invoice_amount: number;
  net_worth: number;
}

const getFundingRequest = async (id: string): Promise<GetFundingRequestResponse> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/funding/request/${id}/`,
    method: "GET",
    params: {
      details: "extended"
    }
  };
  const response = await axios(options);
  return response.data;
};

export default getFundingRequest;

import { createReducer } from "redux-starter-kit";
import { compose } from "recompose";
import { get } from "lodash";
import withTable from "../withTable";

import queryConnectedClientsReducer from "./reducers/queryConnectedClients";
import * as TYPES from "./types";
import queryConnectedClients from "./actions/queryConnectedClients";
import fetchFactorCompanyNotesReducer from "./reducers/fetchFactorCompanyNotes";
import createFactorCompanyNoteReducer from "./reducers/createFactorCompanyNote";
import deleteFactorCompanyNoteReducer from "./reducers/deleteFactorCompanyNote";
import fetchPaymentProfilePurchases, {
  next as nextfetchPaymentProfilePurchases
} from "./actions/fetchPurchases";

export { default as getFactorCompany } from "./actions/getFactorCompany";
export {
  default as queryConnectedClients
} from "./actions/queryConnectedClients";
export { default as removeRelationship } from "./actions/removeRelationship";
export {
  default as fetchFactorCompanyPurchases
} from "./actions/fetchPurchases";
export {
  default as getConnectedClients
} from "./selectors/getConnectedClients";
export { default as updateRelationship } from "./actions/updateRelationship";
export { default as deleteRelationship } from "./actions/deleteRelationship";
export {
  default as createPaymentProfileAttachment
} from "./actions/createPaymentProfileAttachment";
export {
  default as fetchPaymentProfileAttachment
} from "./actions/fetchPaymentProfileAttachment";
export {
  default as fetchFactorCompanyNotes
} from "./actions/fetchFactorCompanyNotes";
export { default as getNotes } from "./selectors/getNotes";
export {
  default as createFactorCompanyNote
} from "./actions/createFactorCompanyNote";
export {
  default as updateFactorCompanyNote
} from "./actions/updateFactorCompanyNote";
export {
  default as deleteFactorCompanyNote
} from "./actions/deleteFactorCompanyNote";
export { default as fetchHistory } from "./actions/fetchHistory";

const connectedClientsTable = withTable({
  stateKey: "factorCompany.connectedClients",
  typeKey: "fetch_factoring_company_connected_clients"
});

export const connectedClientsSelectors = connectedClientsTable.selectors;
export const connectedClientsActions = connectedClientsTable.actions;

const initial = {
  connectedClients: {
    loading: false,
    ids: [],
    count: 0
  }
};

const reducer = createReducer(initial, {
  [TYPES.FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_REQUEST]: queryConnectedClientsReducer,
  [TYPES.FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_SUCCESS]: queryConnectedClientsReducer,
  [TYPES.FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_FAILURE]: queryConnectedClientsReducer,

  [TYPES.FETCH_NOTES_REQUEST]: fetchFactorCompanyNotesReducer,
  [TYPES.FETCH_NOTES_SUCCESS]: fetchFactorCompanyNotesReducer,
  [TYPES.FETCH_NOTES_FAILURE]: fetchFactorCompanyNotesReducer,

  [TYPES.CREATE_NOTE_REQUEST]: createFactorCompanyNoteReducer,
  [TYPES.CREATE_NOTE_SUCCESS]: createFactorCompanyNoteReducer,
  [TYPES.CREATE_NOTE_FAILURE]: createFactorCompanyNoteReducer,

  [TYPES.DELETE_NOTE_REQUEST]: deleteFactorCompanyNoteReducer,
  [TYPES.DELETE_NOTE_SUCCESS]: deleteFactorCompanyNoteReducer,
  [TYPES.DELETE_NOTE_FAILURE]: deleteFactorCompanyNoteReducer
});

const purchasesTable = withTable({
  stateKey: "payment.purchases",
  typeKey: "payment_profile/purchases"
});

export const factorCompanyPurchasesTableSelectors = purchasesTable.selectors;
export const factorCompanyPurchasesTableActions = purchasesTable.actions;

export default compose(
  connectedClientsTable.reducer,
  purchasesTable.reducer
)(reducer);

export const middleware = [
  purchasesTable.middleware(selectors => store => action => {
    const state = store.getState();
    const filters = selectors.getFilters(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const prevPage = selectors.getPrevPage(state);
    const links = selectors.getLinks(state);
    const id = get(state, ["factorCompany", "id"]);

    if (action.type.includes("UPDATE_PAGE")) {
      if (action.payload.page > prevPage) {
        store.dispatch(
          nextfetchPaymentProfilePurchases(id, links.next, rowsPerPage, filters)
        );
      } else {
        store.dispatch(
          nextfetchPaymentProfilePurchases(
            id,
            links.previous,
            rowsPerPage,
            filters
          )
        );
      }
    } else {
      store.dispatch(fetchPaymentProfilePurchases(id, rowsPerPage, filters));
    }
  }),
  connectedClientsTable.middleware(selectors => store => action => {
    const state = store.getState();
    const sortBy = selectors.getSortBy(state);
    const sortDirection = selectors.getSortDirection(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const filters = selectors.getFilters(state);

    store.dispatch(
      queryConnectedClients(
        state.factorCompany.id,
        sortDirection === "asc" ? sortBy : `-${sortBy}`,
        rowsPerPage,
        filters
      )
    );
  })
];

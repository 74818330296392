import { createReducer } from "redux-starter-kit";
import * as TYPES from "./types";
import fetchUserNotesReducers from "./reducers/fetchUseNotes";
import createUserNotesReducers from "./reducers/createUserNotes";
import updateUserNotesReducers from "./reducers/updateUserNotes";
import LoadUserNotesAttachmentsReducers from "./reducers/LoadUserNotesAttachments";
import deleteUserNoteReducer from "./reducers/deleteUserNote";

const INITIAL_STATE = {
  isCreating: false,
  isFetching: false,
  items: [],
  attachments: {},

  loadingUserNotes: false,
  userNotes: {}
};

const reducer = createReducer(INITIAL_STATE, {
  [TYPES.FETCH_USER_NOTES_FAILURE]: fetchUserNotesReducers,
  [TYPES.FETCH_USER_NOTES_SUCCESS]: fetchUserNotesReducers,
  [TYPES.FETCH_USER_NOTES_REQUEST]: fetchUserNotesReducers,
  [TYPES.CREATE_USER_NOTE_FAILURE]: createUserNotesReducers,
  [TYPES.CREATE_USER_NOTE_REQUEST]: createUserNotesReducers,
  [TYPES.CREATE_USER_NOTE_SUCCESS]: createUserNotesReducers,
  [TYPES.LOAD_USER_NOTES_ATTACHMENTS_SUCCESS]: LoadUserNotesAttachmentsReducers,
  [TYPES.LOAD_USER_NOTES_ATTACHMENTS_REQUEST]: LoadUserNotesAttachmentsReducers,
  [TYPES.LOAD_USER_NOTES_ATTACHMENTS_FAILURE]: LoadUserNotesAttachmentsReducers,
  [TYPES.UPDATE_USER_NOTE_SUCCESS]: updateUserNotesReducers,
  [TYPES.UPDATE_USER_NOTE_REQUEST]: updateUserNotesReducers,
  [TYPES.UPDATE_USER_NOTE_FAILURE]: updateUserNotesReducers,

  [TYPES.DELETE_USER_NOTE_REQUEST]: deleteUserNoteReducer,
  [TYPES.DELETE_USER_NOTE_SUCCESS]: deleteUserNoteReducer,
  [TYPES.DELETE_USER_NOTE_FAILURE]: deleteUserNoteReducer
});

export default reducer;

export { default as queryFetchNotes } from "./actions/fetchUserNotes";
export { default as createUserNotes } from "./actions/CreateUserNotes";
export { default as updateUserNotes } from "./actions/updateUserNotes";
export { default as deleteUserNotes } from "./actions/DeleteUserNotes";
export {
  default as loadUserNotesAttachments
} from "./actions/loadUserNotesAttachments";

export { default as getEntityUserNotes } from "./selectors/getEntityUserNotes";
export { default as getAttachments } from "./selectors/getAttachmentsNotes";
export { default as getUserNotes } from "./selectors/getUserNotes";

import { createReducer } from "redux-starter-kit";
import { compose } from "recompose";
import get from "lodash/get";
import * as TYPES from "./types";
import fetchWalletHistory, { next } from "./actions/fetchWalletHistory";
import fetchWalletHistoryReducer from "./reducers/fetchWalletHistory";
import withTable from "../withTable";

const walletTransactionsTable = withTable({
    stateKey: "ledger.walletTransactions",
    typeKey: "ledger/walletTransactions"
});

const reducer = createReducer(
    {},
    {
        [TYPES.FETCH_WALLET_HISTORY_FAILURE]: fetchWalletHistoryReducer,
        [TYPES.FETCH_WALLET_HISTORY_SUCCESS]: fetchWalletHistoryReducer,
        [TYPES.FETCH_WALLET_HISTORY_REQUEST]: fetchWalletHistoryReducer,
    }
);
export const middleware = walletTransactionsTable.middleware(selectors => store => action => {
    const state = store.getState();
    const sortBy = selectors.getSortBy(state);
    const sortDirection = selectors.getSortDirection(state);
    const rowsPerPage = selectors.getRowsPerPage(state);
    const filters = selectors.getFilters(state);
    const links = selectors.getLinks(state);
    const prevPage = selectors.getPrevPage(state);
    const ordering = sortDirection === "asc" ? sortBy : `-${sortBy}`;
    const factoringId = get(state, "ledger.walletTransactions.factoringId");
    const userId = get(state, "ledger.walletTransactions.userId");
    const isStripeUser = get(state, "ledger.walletTransactions.isStripeUser");
    if (action.type.includes("UPDATE_PAGE")) {
        if (action.payload.page > prevPage) {
            store.dispatch(
                next(links.next, rowsPerPage, ordering, filters)
            );
        } else {
            store.dispatch(
                next(links.previous, rowsPerPage, ordering, filters)
            );
        }
    } else {
        store.dispatch(
            fetchWalletHistory(
                userId,
                factoringId,
                isStripeUser,
                rowsPerPage,
                sortDirection === "asc" ? sortBy : `-${sortBy}`,
                filters
            )
        );
    }
});

export default compose(
    walletTransactionsTable.reducer,
)(reducer);

export const walletTransactionsTableSelectors = walletTransactionsTable.selectors;
export const walletTransactionsTableActions = walletTransactionsTable.actions;

export {
    default as fetchWalletHistory
} from "./actions/fetchWalletHistory";
export {
    default as getWalletTransactions
} from "./selectors/getWalletTransactions";
import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import {
  FETCH_RESERVE_FAILURE,
  FETCH_RESERVE_REQUEST,
  FETCH_RESERVE_SUCCESS
} from "../types";

const fetchReserve = id => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_RESERVE_REQUEST)());
    const response = await api.reserve.getReserve(id);
    const data = normalize(response, [schema.reserveItem]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_RESERVE_SUCCESS)({
        [id]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_RESERVE_FAILURE)(err));
    throw err;
  }
};

export default fetchReserve;

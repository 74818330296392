import { createAction, createReducer } from "redux-starter-kit";
import { denormalize, normalize } from "normalizr";
import { assign, get, merge } from "lodash";

import { addEntities } from "./entities";
import { reserveAggregate, reserveItem } from "../api/schema";

export const FETCH_RESERVEAGGREGATE_REQUEST =
  "RESERVE/FETCH_RESERVEAGGREGATE_REQUEST";
export const FETCH_RESERVEAGGREGATE_SUCCESS =
  "RESERVE/FETCH_RESERVEAGGREGATE_SUCCESS";
export const FETCH_RESERVEAGGREGATE_FAILURE =
  "RESERVE/FETCH_RESERVEAGGREGATE_FAILURE";

export const FETCH_RESERVE_REQUEST = "RESERVE/FETCH_RESERVE_REQUEST";
export const FETCH_RESERVE_SUCCESS = "RESERVE/FETCH_RESERVE_SUCCESS";
export const FETCH_RESERVE_FAILURE = "RESERVE/FETCH_RESERVE_FAILURE";
export const FETCH_GIVEBACKSANDCHARGEBACKS_REQUEST =
  "RESERVE/FETCH_GIVEBACKSANDCHARGEBACKS_REQUEST";
export const FETCH_GIVEBACKSANDCHARGEBACKS_SUCCESS =
  "RESERVE/FETCH_GIVEBACKSANDCHARGEBACKS_SUCCESS";
export const FETCH_GIVEBACKSANDCHARGEBACKS_FAILURE =
  "RESERVE/FETCH_GIVEBACKSANDCHARGEBACKS_FAILURE";

export const FETCH_LINKRELATEDLINEITEMS_REQUEST =
  "RESERVE/FETCH_LINKRELATEDLINEITEMS_REQUEST";
export const FETCH_LINKRELATEDLINEITEMS_SUCCESS =
  "RESERVE/FETCH_LINKRELATEDLINEITEMS_SUCCESS";
export const FETCH_LINKRELATEDLINEITEMS_FAILURE =
  "RESERVE/FETCH_LINKRELATEDLINEITEMS_FAILURE";

const initial = {
  items: {},
  chargeBacksAndGivebacks: {},
  loadingChargebacksAndGivebacks: false,
  loadingReserve: false,
  loadingReserveAggregate: false,
  reserveAggregates: {}
};

const fetchReserveReducer = (state, { type, payload }) => {
  switch (type) {
    case FETCH_RESERVE_REQUEST:
      return {
        ...state,
        loadingReserve: true
      };
    case FETCH_RESERVE_SUCCESS:
      return {
        ...state,
        loadingReserve: false,
        items: merge({}, state.items, payload)
      };
    case FETCH_RESERVE_FAILURE:
      return {
        ...state,
        loadingReserve: false
      };
    default:
      return {
        ...state
      };
  }
};

const fetchReserveAggregateReducer = (state, action) => {
  switch (action.type) {
    case FETCH_RESERVEAGGREGATE_REQUEST:
      return {
        ...state,
        loadingReserveAggregate: true
      };
    case FETCH_RESERVEAGGREGATE_SUCCESS:
      return {
        ...state,
        loadingReserveAggregate: false,
        reserveAggregates: assign({}, state.reserveAggregates, action.payload)
      };
    case FETCH_RESERVEAGGREGATE_FAILURE:
      return {
        ...state,
        loadingReserveAggregate: false
      };
    default:
      return state;
  }
};

const fetchGivebacksAndChargebacksReducer = (state, { type, payload }) => {
  switch (type) {
    case FETCH_GIVEBACKSANDCHARGEBACKS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_GIVEBACKSANDCHARGEBACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        givebacksAndChargebacks: assign(
          {},
          state.givebacksAndChargebacks,
          payload
        )
      };
    case FETCH_GIVEBACKSANDCHARGEBACKS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return {
        ...state
      };
  }
};

const reducer = createReducer(initial, {
  [FETCH_RESERVE_REQUEST]: fetchReserveReducer,
  [FETCH_RESERVE_SUCCESS]: fetchReserveReducer,
  [FETCH_RESERVE_FAILURE]: fetchReserveReducer,
  [FETCH_GIVEBACKSANDCHARGEBACKS_REQUEST]: fetchGivebacksAndChargebacksReducer,
  [FETCH_GIVEBACKSANDCHARGEBACKS_SUCCESS]: fetchGivebacksAndChargebacksReducer,
  [FETCH_GIVEBACKSANDCHARGEBACKS_FAILURE]: fetchGivebacksAndChargebacksReducer,
  [FETCH_RESERVEAGGREGATE_REQUEST]: fetchReserveAggregateReducer,
  [FETCH_RESERVEAGGREGATE_SUCCESS]: fetchReserveAggregateReducer,
  [FETCH_RESERVEAGGREGATE_FAILURE]: fetchReserveAggregateReducer
});

export default reducer;

export const linkRelatedLineItem = fundingRequestId => (
  relatedLineItem,
  amount
) => async (dispatch, getState, { api, schema }) => {
  try {
    dispatch(createAction(FETCH_LINKRELATEDLINEITEMS_REQUEST)());
    const response = await api.factoring.linkRelatedLineItems(
      fundingRequestId,
      {
        amount_requested: amount,
        funding_request: fundingRequestId,
        related_line_item: relatedLineItem
      }
    );
    // const data = normalize(response, schema.reserveItem);
    // dispatch(addEntities(data.entities));
    // Refreshes in the funding request
    dispatch(createAction(FETCH_LINKRELATEDLINEITEMS_SUCCESS)({}));
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_LINKRELATEDLINEITEMS_FAILURE)(err));
    throw err;
  }
};

export const fetchChargebacksAndGivebacks = userId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_GIVEBACKSANDCHARGEBACKS_REQUEST)());
    const response = await api.reserve.getGivebacksAndChargeBacks(userId);
    const data = normalize(response, [schema.reserveItem]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_GIVEBACKSANDCHARGEBACKS_SUCCESS)({
        [userId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_GIVEBACKSANDCHARGEBACKS_FAILURE)(err));
    throw err;
  }
};

export const fetchReserve = userId => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_RESERVE_REQUEST)());
    const response = await api.reserve.getReserve(userId);
    const data = normalize(response, [schema.reserveItem]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_RESERVE_SUCCESS)({
        [userId]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_RESERVE_FAILURE)(err));
    throw err;
  }
};

export const fetchReserveAggregate = id => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(createAction(FETCH_RESERVEAGGREGATE_REQUEST)());
    const response = await api.reserve.getReserveAggregate(id);
    const withId = {
      ...response,
      id
    };
    const data = normalize(withId, schema.reserveAggregate);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_RESERVEAGGREGATE_SUCCESS)({
        [id]: data.result
      })
    );
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_RESERVEAGGREGATE_FAILURE)(err));
    throw err;
  }
};

export const getPendingNegativeReserve = id => state => {
  try {
    const reserve = get(state, `reserve.reserveAggregates.${id}`);

    const denormalized = denormalize(
      { reserve },
      { reserve: reserveAggregate },
      state.entities
    );
    return denormalized.reserve.negative_pending;
  } catch (err) {
    return 0;
  }
};

export const getChargeBacksAndGiveBacks = userId => state => {
  const items = get(state, `reserve.givebacksAndChargebacks.${userId}`, []);

  const denormalized = denormalize(
    { items },
    { items: [reserveItem] },
    state.entities
  );

  return denormalized.items;
};

export const getActiveReserve = id => state => {
  try {
    const reserve = get(state, `reserve.reserveAggregates.${id}`);
    const denormalized = denormalize(
      { reserve },
      { reserve: reserveAggregate },
      state.entities
    );
    return denormalized.reserve.total_reserve;
  } catch (err) {
    return 0;
  }
};

export const getPendingPositiveReserve = id => state => {
  try {
    const reserve = get(state, `reserve.reserveAggregates.${id}`);

    const denormalized = denormalize(
      { reserve },
      { reserve: reserveAggregate },
      state.entities
    );
    return denormalized.reserve.positive_pending;
  } catch (err) {
    return 0;
  }
};

import { Grid, Typography } from "@material-ui/core";
import React from "react";

import openFileDialog from "./openFileDialog";
import { Attachment, File } from "./types";

interface Props {
  uploadBtnRef: HTMLInputElement | null;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  values: {
    attachments: Attachment[];
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  openDialog: any;
  closeDialog: any;
  dialog: any;
}

const EmptyDocmentsView = ({
  uploadBtnRef,
  setFieldTouched,
  values,
  setFieldValue,
  openDialog
}: Props): JSX.Element => (
  <Grid container item xs={12} spacing={8}>
    <Grid container item xs={12} justify="center" alignItems="center">
      <Typography variant="caption" color="textSecondary">
        No Documents Yet!
      </Typography>
    </Grid>
    <Grid container item xs={12} justify="center" alignItems="center">
      <Typography
        variant="caption"
        color="secondary"
        onClick={(): void => {
          openFileDialog(uploadBtnRef);
        }}
        style={{
          textDecoration: "underline",
          fontStyle: "italic"
        }}
      >
        Click here to add
      </Typography>
    </Grid>
    <input
      type="file"
      name="attachments"
      ref={(ref): void => {
        uploadBtnRef = ref;
      }}
      id="add-document-btn"
      style={{ display: "none" }}
      onBlur={(): void => setFieldTouched("attachments", true)}
      onChange={(e): void => {
        const index = values.attachments.length;
        if (values.attachments.length === 2) return;
        if (e.currentTarget && e.currentTarget.files) {
          if (
            e.currentTarget.files[0].type &&
            e.currentTarget.files[0].type.match("image.*|application/pdf") ===
              null
          ) {
            openDialog("file-type-confirmation");
          }
          if (e.currentTarget.files[0].type.match("image.*")) {
             openDialog("picture-edit-tool", "", "", {
              file: e.currentTarget.files[0],
              save: (file: File) =>
                setFieldValue(`attachments.[${index}].file`, file)
            });
          }
          setFieldValue(
            `attachments.[${index}].file`,
            e.currentTarget.files[0]
          );
          e.currentTarget.value = "";
        }
      }}
    />
  </Grid>
);

export default EmptyDocmentsView;

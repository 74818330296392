import { get } from "lodash";

const getNotesTimelineMore = state => {
  const id = get(state, "factoringClient.id", "");
  const nextNote = get(state, `factoringClient.nextNote.${id}`);
  const loadingNotes = get(state, `factoringClient.loadingNotes`, false);
  return Boolean(nextNote && !loadingNotes);
};

export default getNotesTimelineMore;

import React from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import { Formik } from "formik";

import Attachment from "../../../components/Attachment";
import ContinueButton from "../../../components/buttons/ContinueButton";
import InvitedValidationSchema from "./validation";
import CarrierInfo from "./CarrierInfo";

const getBankInfoAccount = accounts => {
  const account = _.find(accounts, object => object.default === true);
  if (!account) {
    return accounts[0];
  }
  return account;
};

const getInitialValuesConfirm = (
  values,
  usedPlaid,
  defaultBankAccount,
  initialValues
) => ({
  ...initialValues,
  bank_info: usedPlaid
    ? `${defaultBankAccount.type}******${defaultBankAccount.account_number_redacted}`
    : null
});

const Summary = ({
  usedPlaid,
  carrierForRef = {},
  factoringProfileId,
  factoringProfileData: company_profile = {},
  plaidData: { accounts = [], ...plaidData },
  defaultBankAccount = getBankInfoAccount(accounts),
  updateFactoringProfile,
  updateFactoringLoading,
  attachments,
  onSubmit,
  initialValues
}) => (
  <Grid
    container
    direction="column"
    xs={10}
    sm={9}
    md={8}
    style={{ alignSelf: "center", width: "100%" }}
  >
    <Formik
      innerRef={ref => {
        carrierForRef = ref;
      }}
      validationSchema={InvitedValidationSchema()}
      initialValues={getInitialValuesConfirm(
        company_profile,
        usedPlaid,
        defaultBankAccount,
        initialValues
      )}
      render={props => <CarrierInfo isSummary {...props} />}
      onSubmit={onSubmit}
    />
    {attachments.length
      ? attachments.map(({ file_id: id, categories }) => (
          <Attachment id={id} label={categories} />
        ))
      : null}
    <Grid container direction="row-reverse" alignItems="flex-start" xs={12}>
      <ContinueButton
        disabled={updateFactoringLoading}
        onClick={() => carrierForRef.executeSubmit()}
      />
    </Grid>
  </Grid>
);

export default Summary;

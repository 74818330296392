import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";

import { addEntities } from "../../entities";
import {
  FETCH_DEBTORCONNECTEDCLIENTS_FAILURE,
  FETCH_DEBTORCONNECTEDCLIENTS_REQUEST,
  FETCH_DEBTORCONNECTEDCLIENTS_SUCCESS
} from "../types";

const debouncedQueryConnectedClientsRequest = debounce(
  async (dispatch, schema, api, id, ordering, limit, offset, filters) => {
    dispatch(
      createAction(FETCH_DEBTORCONNECTEDCLIENTS_REQUEST)({
        id,
        ordering,
        limit,
        offset,
        filters
      })
    );
    const response = await api.debtors.getConnectedClients(
      id,
      ordering,
      limit,
      offset,
      filters
    );
    const data = normalize(response.results, [schema.factorClient]);
    dispatch(addEntities(data.entities));
    dispatch(
      createAction(FETCH_DEBTORCONNECTEDCLIENTS_SUCCESS)({
        items: data.result,
        count: response.count
      })
    );
    return response;
  },
  1000,
  { trailing: true }
);

const queryConnectedClients = (id, ordering, limit, offset, filters) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    await debouncedQueryConnectedClientsRequest(
      dispatch,
      schema,
      api,
      id,
      ordering,
      limit,
      offset,
      filters
    );
  } catch (err) {
    dispatch(createAction(FETCH_DEBTORCONNECTEDCLIENTS_FAILURE)(err));
    throw err;
  }
};

export default queryConnectedClients;

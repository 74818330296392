import { createAction } from "redux-starter-kit";
import * as TYPES from "../types";

const updateFileWithProgress = (state, payload) => {
  const { files } = state().upload;
  const newFiles = files.slice(0);
  files[payload.id].progress = payload.progress;
  return newFiles;
};

const UpdateProgress = payload => (dispatch, getState) => {
  const files = updateFileWithProgress(getState, payload);
  dispatch(createAction(TYPES.UPLOAD_PROGRESS)(files));
};

export default UpdateProgress;

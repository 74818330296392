import { assign } from "lodash";
import {
  FETCH_ADMIN_REVENUE_BY_PERIOD_FAILURE,
  FETCH_ADMIN_REVENUE_BY_PERIOD_SUCCESS,
  FETCH_ADMIN_REVENUE_BY_PERIOD_REQUEST
} from "../types";

export default (state, action) => {
  const { revenues } = state;
  switch (action.type) {
    case FETCH_ADMIN_REVENUE_BY_PERIOD_REQUEST:
      return {
        ...state,
        revenues: assign({}, revenues, {
          isLoading: true
        })
      };
    case FETCH_ADMIN_REVENUE_BY_PERIOD_SUCCESS:
      return {
        ...state,
        revenues: assign({}, revenues, {
          isLoading: false,
          ...action.payload
        })
      };
    case FETCH_ADMIN_REVENUE_BY_PERIOD_FAILURE:
      return {
        ...state,
        revenues: assign({}, revenues, {
          isLoading: false
        })
      };
    default:
      return state;
  }
};

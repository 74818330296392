import { denormalize } from "normalizr";
import { fundingRequest } from "../../../api/schema";

const getReceivableIdsFromFundingRequests = state => ids => {
  try {
    const denormalized = denormalize(
      { frs: ids },
      { frs: [fundingRequest] },
      state.entities
    );

    return denormalized.frs;
  } catch (err) {
    return [];
  }
};

export default getReceivableIdsFromFundingRequests;

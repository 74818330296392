import React, { useState, useEffect } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { get } from "lodash";
import {
  Button,
  ExpansionPanelDetails,
  Grid,
  Paper,
  Tab
} from "@material-ui/core";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/styles";
import { Add as AddIcon } from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { needsReviewCount } from "../../../modules/debtors";
import Colors from "../../../theme/colors";
import commaQuery from "../../../helpers/commaQuery";
import PhoneInput from "../../../components/inputs/PhoneInput";
import MultiButtonToggleInputs from "../../../components/inputs/MultiButtonToggleInputs";
import CustomExpansionPanel from "../../../components/ExpansionPanel";
import NavigationTabs from "../../../components/NavigationTabs";
import MoreButton from "../../../components/buttons/MoreButton";
import Table from "../../../components/Table";
import formatDate from "../../../helpers/format/formatDate";
import formatStatus from "../../../helpers/format/formatStatus";
import splitAndCase from "../../../helpers/format/splitAndCase";
import AddDebtorDialog from "./AddDebtorDialog";
import CustomTextField from "../../../components/inputs/CustomTextField";
import Link from "../../../components/Link";
import ExpansionPanelSummary from "../../../components/ExpansionPanelSummary";
import CustomInputField from "../../../components/inputs/CustomInputField";
import InternalLayout from "../../../components/layouts/InternalLayout";
import Can from "../../../components/Can";
import CSVButton from "../../../components/buttons/CSVButton";
import MasterStar from "../../../components/icons/MasterStar";
import NOAPanel from "./NOAPanel";

const styles = {
  root: {
    background: "linear-gradient(45deg, #2EBD00 30%, #4dc527 90%)"
  },
  label: {
    fontFamily: "Avenir-Medium",
    textTransform: "capitalize",
    color: Colors.white
  }
};

const MoreLink = ({ id }) => (
  <Link to={`/admin/debtors/${id}/profile`}>
    <MoreButton />
  </Link>
);

const Filters = withStyles({
  root: {
    width: "100%"
  },
  headerButton: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end"
  }
})(({ children, classes, handleCSV, handleSearchFilter }) => (
  <div className={classes.root}>
    <CustomExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<FontAwesomeIcon size="xs" icon={faSlidersH} />}
      >
        <div className={classes.headerButton}>{children}</div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={32} direction="column">
          <Grid item>
            <Grid container spacing={32}>
              <Grid container item xs={3} spacing={40}>
                <Grid item xs={12}>
                  <MultiButtonToggleInputs
                    names={["mc", "dot", "tax_id"]}
                    placeholder={{
                      mc: "MC#",
                      dot: "DOT#",
                      tax_id: "TAX ID#"
                    }}
                    withField={false}
                    selectedName="mc"
                    label="MC / DOT / TAX ID"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    inputSize={6}
                    buttonSize={6}
                    onChange={handleSearchFilter}
                    withStyle
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    fullWidth
                    label="Contact Name"
                    name="name"
                    onChange={event => {
                      const newEvent = {
                        target: {
                          name: event.target.name,
                          value: commaQuery(event.target.value)
                        }
                      };
                      handleSearchFilter(newEvent);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={3} spacing={40}>
                <Grid item xs={12}>
                  <CustomTextField
                    fullWidth
                    label="Address"
                    name="address"
                    onChange={handleSearchFilter}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInputField
                    fullWidth
                    label="Phone"
                    name="phone_number"
                    onChange={event => {
                      const newEvent = {
                        target: {
                          name: event.target.name,
                          value: commaQuery(event.target.value)
                        }
                      };
                      handleSearchFilter(newEvent);
                    }}
                    inputComponent={PhoneInput}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={1} spacing={40}>
                <Grid item xs={12}>
                  <CSVButton handleCSV={handleCSV} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </CustomExpansionPanel>
  </div>
));

const Debtors = ({
  tabs,
  columns,
  statusIndex,
  isLoading,
  handleSort,
  sortDirection,
  sortBy,
  count,
  page,
  rowsPerPage,
  handleChangePage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  isSelected,
  allSelected,
  handleSelect,
  handleSelectAllClick,
  debtors,
  fetchDebtors,
  handleRoute,
  openCreateDebtor,
  closeCreateDebtor,
  createDebtor,
  classes,
  createNewDebtor,
  openModal,
  handleSearchFilter,
  handleCSV,
  filters,
  isNOAPanel
}) => {
  const formattedRows = debtors.map(row => {
    return {
      ...row,
      user_type: splitAndCase(get(row, "user.type", "")),
      company_name: (
        <Grid container alignItems="center" spacing={8}>
          <Grid item>
            <Link to={`/admin/debtors/${row.id}/profile`}>
              {row.company_name}
              {row.operating_timezone
                ? ` (${row.operating_timezone})`
                : undefined}
            </Link>
          </Grid>
          {row.master && <MasterStar />}
        </Grid>
      ),
      created: formatDate(row.created),
      status: formatStatus(row.status),
      more: <MoreLink id={row.id} />
    };
  });

  return (
    <Can
      perform="admin-debtors:view"
      yes={() => {
        const dispatch = useDispatch();
        const [badge, setBadge] = useState(0);
        useEffect(() => {
          async function f() {
            const badgeCount = await dispatch(needsReviewCount());
            setBadge(badgeCount);
          }
          f();
        }, [statusIndex]);

        return (
          <InternalLayout title="Debtors">
            <React.Fragment>
              <NavigationTabs
                tabs={tabs.map(tab => {
                  if (tab.value === "needs_review") {
                    return Object.assign({}, tab, { badge });
                  }
                  return tab;
                })}
                value={statusIndex}
                handleRoute={handleRoute}
                additional={
                  <NavLink
                    key={"NOA Panel"}
                    to={"/admin/debtors/noa_panel"}
                    exact
                    activeStyle={{ color: Colors.link }}
                    style={{
                      textDecoration: "none",
                      color: Colors.textColor
                    }}
                  >
                    <Tab label="NOA Panel" />
                  </NavLink>
                }
              />
              <Paper style={{ marginTop: "32px" }}>
                {!isNOAPanel && (
                  <Filters
                    handleSearchFilter={handleSearchFilter}
                    handleCSV={handleCSV}
                  >
                    <Button
                      mini
                      classes={{
                        root: classes.root,
                        label: classes.label
                      }}
                      onClick={() => {
                        openCreateDebtor();
                      }}
                    >
                      <AddIcon />
                      Add Debtor
                    </Button>
                  </Filters>
                )}
                {isNOAPanel ? (
                  <NOAPanel />
                ) : (
                  <div style={{ paddingLeft: 16 }}>
                    <Table
                      columns={columns}
                      rows={formattedRows}
                      isLoading={isLoading}
                      handleSort={handleSort}
                      sortDirection={sortDirection}
                      sortBy={sortBy}
                      count={count}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      rowsPerPageOptions={rowsPerPageOptions}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      isSelected={isSelected}
                      handleSelect={handleSelect}
                      handleSelectAllClick={handleSelectAllClick}
                      allSelected={allSelected}
                      filter
                      filters={filters}
                      handleFilterChange={handleSearchFilter}
                      allowEmpty
                    />
                  </div>
                )}
              </Paper>
              <AddDebtorDialog
                openModal={openModal}
                open={createDebtor.show}
                handleClose={closeCreateDebtor}
                callback={createNewDebtor}
              />
            </React.Fragment>
          </InternalLayout>
        );
      }}
      no={() => <Redirect to="/" />}
    />
  );
};

export default withStyles(styles)(Debtors);

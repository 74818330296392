import formatPennies from "../../../../helpers/format/formatPennies";
import formatDate from "../../../../helpers/format/formatDate";
import formatStatus from "../../../../helpers/format/formatStatus";

const nestedReceivablesTableColumns = [
  {
    key: "user_load_number",
    name: "Load #",
    filter: "text",
    filter_key: "dba"
  },
  {
    key: "amount",
    name: "Invoice Amnt.",
    format: formatPennies,
    filter: "text",
    filter_key: "email"
  },
  {
    key: "invoice_number",
    name: "Invoice #",
    default: "-",
    filter: "text",
    filter_key: "user_type"
  },
  {
    key: "factoring_company_profile.name",
    name: "Client"
  },
  {
    key: "created",
    name: "Invoice At",
    format: formatDate,
    sort: true
  },
  {
    key: "mc",
    name: "Email Status",
    sort: true
  },
  {
    key: "receivable_status",
    name: "Status",
    sort: true,
    format: formatStatus
  },
  {
    key: "receivable_age",
    name: "Aging",
    format: (value: string | undefined): string =>
      value && Number.isFinite(Number(value))
        ? `${value} day${Number(value) > 1 ? "s" : ""}`
        : "-",
    sort: true
  },
  {
    key: "more",
    name: "More"
  }
];

export default nestedReceivablesTableColumns;

import { head, concat, get, omit, reduce, keys, pickBy, fromPairs, includes } from 'lodash';
import formatPhone from '../../../../helpers/format/formatPhone';
import maskEIN from '../../../../helpers/format/maskEIN';
import { createFundingRequest as createFundingRequestSchema } from './validation';

const contractTypes = {
    STD_BROKER: false,
    NON_FACTORED_STANDARD: false,
    NON_FACTORED_BILL_OUT: false,
    SELF_FINANCE_NO_INVOICE: false,
    SELF_FINANCE_WITH_INVOICE: false
};
export const factorClientForm = (
    updateFactoringProfile,
    updateUser,
    openSnackbar,
    {
        datasync_option: dataSyncOption,
        send_rate_confirmation_remote_approved: sendRateConfirmationRemoteApproved,
        credit_status_email: creditStatusEmail,
        referral_source: referralSource,
        document_issue_phone: documentIssuePhone,
        document_issue_email: documentIssueEmail,
        enable_reserve_holdback_hybrid: enableReserveHoldbackHybrid,
        default_enable_reserve_holdback: defaultEnableReserveHoldback,
        allow_bulk_import: allowBulkImport,
        send_carrier_remittance_to_broker: sendCarrierRemittanceToBroker,
        send_daily_invoice_settlement: sendDailyInvoiceSettlement,
        send_debtor_open_ar_copy: sendDebtorOpenARCopy,
        auto_recovery: autoRecovery,
        margin_discount_rate: marginDiscountRate,
        allow_pending_reserve_access: allowPendingReserveAccess,
        ach_remit_batches: achRemitBatches,
        ach_remit_batches_options: achRemitBatchesOptions,
        show_debtor_declined: showDebtorDeclined,
        allow_negative_margin: allowNegativeMargin,
        negative_margin_value: negativeMarginValue,
        negative_margin_limit: negativeMarginLimit,
        negative_margin_count: negativeMarginCount,
        allow_fr_without_carrier: allowFrWithoutCarrier,
        disable_send_client_invoices: disableSendClientInvoices,
        cc_debtor_client_invoices_email: debtorInvoiceCCEmail,
        cc_debtor_client_invoices: ccClientDebtorInvoiceEmail,
        no_noa_debit_program: noNOADebitProgram,
        remittance_email: remittanceEmail,
        tms,
        reason_for_leaving: reasonForLeaving,
        cc_broker_rate_confirmation: ccBrokerRateConfirmation,
        cc_broker_rate_confirmation_email: ccBrokerRateConfirmationEmail,
        edit_speed_display: editSpeedDisplay,
        auto_fr_create_email: autoFRCreateEmail,
        non_factored_fee: nonFactoredFee,
        wire_fee: wireFee,
        reserve_holdback: reserveHoldback,
        reserve_holdback_rate: reserveHoldbackRate,
        fuel_advance_auto_approve: fuelAdvanceAutoApprove,
        create_non_factored_fr: factoringCreateNonFactoredFr,
        revenue_share: referralPartnerShare,
        revenue_share_type: referralMarginType,
        enable_discount,
        send_aging_to_broker,
        send_rate_confirmation_pdf: sendRateConfirmationPdf,
        require_signed_bol: requireSignedBOLDelivered,
        report_ansonia_history: reportAnsoniaHistory,
        default_broker_carrier_payout_limit: defaultCarrierLimit,
        broker_carrier_payout_limit_options: defaultCarrierLimitOptions,
        term: contractTerm,
        factoring_approved: creditApprovalStatus,
        admin_message: creditInternalStatusNotes,
        discount_rate: creditDiscountRate,
        program_type: creditClientProgramType,
        fuel_advance_fee: creditFuelAdvanceFee,
        broker_approved: creditBrokerApproval,
        apply_priority_rate: creditPriorityRate,
        fuel_advance_limit: creditFuelAdvanceLimit,
        minimum_fee: creditMinimumFee,
        factoring_message: creditMessageToClient,
        fuel_advance: creditFuelAdvance,
        has_contract_authority_status: ownerHasContractAuthorityStatus,
        has_common_authority_status: ownerHasCommonAuthorityStatus,
        has_broker_authority_status: ownerHasBrokerAuthorityStatus,
        is_allowed_to_operate: ownerIsAllowedToOperate,
        ucc_transferred: ownerUccTransferComplete,
        auto_generate_load_numbers: autoGenerateLoadNumber,
        contact_notes: contactNotes,
        referral_business,
        company_profile: {
            name: businessLegalName,
            number_of_trucks: businessNumberOfTrucks,
            mc: businessMc,
            dot: businessDot,
            tax_id: businessEin,
            phone_number: businessPhone,
            address: {
                street_one: businessAddress,
                zip: businessZip,
                state: businessState,
                city: businessCity
            } = {},
            current_factoring_company: businessCurrentFactoringCompany,
            types_of_trailers: businessTypeOfTrailers,
            years_in_business: businessYears,
            state_incorporated: businessStateIncorporated,
            number_of_owner_operators: businessNumberOfOwnerOperators,
            type: businessType
        } = {},
        owner_profile: {
            name_first: ownerFirstName,
            name_last: ownerLastName,
            tax_lien_status: ownerLienStatus,
            social_number: ownerSsn,
            date_of_birth: ownerDateOfBirth,
            email: ownerEmail,
            phone_number_home: ownerHomePhone,
            phone_number_cell: ownerCellPhone,
            address: {
                street_one: ownerAddress,
                city: ownerCity,
                state: ownerState,
                zip: ownerZip
            } = {}
        } = {},
        mailing_address: {
            street_one: mailingAddressOne,
            street_two: mailingAddressTwo,
            city: mailingAddressCity,
            state: mailingAddressState,
            zip: mailingAddressZip,
            country: mailingAddressCountry
        } = {},
        enable_pay_via_check: enablePayViaCheck,
        allow_self_finance_fr_declined_debtors: allowSelfFinanceFrDeclinedDebtors,
        allow_self_finance_fr_approved_debtors: allowSelfFinanceFrApprovedDebtors,
        allow_self_finance_fr: allowSelfFinanceFr,
        self_finance_fr_limit: selfFinanceFRLimit,
        self_finance_rolling_30_day_fr_limit: selfFinanceRolling30DayFRLimit,
        allow_line_itemized_items: allowLineItemizedItems,
        default_fr_type_api: defaultFrTypeApi,
        enable_hide_debtor: enableHideDebtor,
        allow_self_finance_approved_debtors_out_of_credit: allowSelfFinanceApprovedDebtorsOutOfCredit,
        self_finance_approval_without_noa: allowSFApprovalWithoutNOA,
        enabled_contract_types: enabledContractTypes,
        cc_broker_default_payment_email: ccBrokerDefaultPayment,
        stripe_enabled: stripeEnabled,
        include_tms_invoice: includeTMSInvoice,
        include_tms_rate_confirmation: includeTMSRateConfirmation,
        allow_co_broker_shipper_debtor: allowCoBrokerShipperDebtor,
        use_new_non_factored_funding_requests: useNewNonFactoredFundingRequests,
        report_reserve_to_credit_agencies: reportReserveToCreditAgencies,
        allow_do_not_contact_debtors: allowDoNotContactDebtors,
        default_do_not_contact_debtors: defaultDoNotContactDebtors,
        enable_hold_review_funding_request_status: enableHoldReviewFundingRequestStatus,
        enable_displaying_client_reserve_balance: enableDisplayingClientReserveBalance,
        ...rest
    } = {},
    {
        equipment_types: ownerEquipmentType,
        assigned_admin: assignedAdmin,
        referrer,
        account_manager: accountManager
    } = {},
    uploadAttachments,
    id,
    userType
) => {
    return {
        enableReinitialize: true,
        initialValues: {
            externalCode: dataSyncOption ? true : false,
            dataSyncOption: dataSyncOption ? true : false,
            sendRateConfirmationRemoteApproved:
                sendRateConfirmationRemoteApproved || false,
            accountManager: accountManager || '',
            creditStatusEmail: creditStatusEmail || '',
            enableReserveHoldbackHybrid: enableReserveHoldbackHybrid || false,
            defaultEnableReserveHoldback: defaultEnableReserveHoldback || false,
            sendDailyInvoiceSettlement: sendDailyInvoiceSettlement || false,
            sendDebtorOpenARCopy: sendDebtorOpenARCopy || false,
            autoRecovery: autoRecovery || false,
            marginDiscountRate: marginDiscountRate || 0,
            allowPendingReserveAccess: allowPendingReserveAccess || false,
            achRemitBatches: achRemitBatches || [],
            achRemitBatchesOptions: achRemitBatchesOptions || [],
            showDebtorDeclined: showDebtorDeclined || false,
            allowNegativeMargin: allowNegativeMargin || false,
            negativeMarginValue: (negativeMarginValue || 0) / 100,
            negativeMarginLimit: negativeMarginLimit || 0,
            negativeMarginCount: negativeMarginCount || 0,
            disableSendClientInvoices: disableSendClientInvoices || false,
            debtorInvoiceCCEmail: debtorInvoiceCCEmail || false,
            ccClientDebtorInvoiceEmail: ccClientDebtorInvoiceEmail || false,
            allowFrWithoutCarrier: allowFrWithoutCarrier || false,
            noNOADebitProgram: noNOADebitProgram || false,
            remittanceEmail: remittanceEmail || '',
            ccBrokerRateConfirmationEmail: ccBrokerRateConfirmationEmail || '',
            ccBrokerRateConfirmation: ccBrokerRateConfirmation || false,
            editSpeedDisplay: editSpeedDisplay || false,
            autoFRCreateEmail: autoFRCreateEmail || '',
            nonFactoredFee: (nonFactoredFee || 500) / 100,
            wireFee: (wireFee || 2500) / 100,
            reserveHoldback: reserveHoldback || false,
            reserveHoldbackRate: reserveHoldbackRate || 0,
            fuelAdvanceAutoApprove: fuelAdvanceAutoApprove || false,
            factoringCreateNonFactoredFr: factoringCreateNonFactoredFr || false,
            tms: tms || '',
            reasonForLeaving: reasonForLeaving || '',
            referralPartnerShare: referralPartnerShare || 0,
            referralSource: referralSource || '',
            referrer: referrer || '',
            sendRateConfirmationPdf: sendRateConfirmationPdf || false,
            reportAnsoniaHistory: reportAnsoniaHistory || false,
            attachments: [],
            autoGenerateLoadNumber,
            contractTerm,
            referralMarginType: referralMarginType || 0,
            assignedAdmin: assignedAdmin || ' ',
            creditApprovalStatus: creditApprovalStatus || '',
            businessLegalName: businessLegalName || '',
            businessPhone: businessPhone || '',
            businessAddress: businessAddress || '',
            businessCity: businessCity || '',
            businessState: businessState || '',
            businessZip: businessZip || '',
            businessType: businessType || '',
            businessMc: businessMc || '',
            businessDot: businessDot || '',
            businessYears: businessYears || '',
            businessNumberOfTrucks: businessNumberOfTrucks || 0,
            businessTypeOfTrailers: businessTypeOfTrailers || '',
            businessNumberOfOwnerOperators:
                businessNumberOfOwnerOperators || '',
            businessStateIncorporated: businessStateIncorporated || '',
            businessEin: (businessEin || '').includes('tok')
                ? maskEIN(businessEin)
                : businessEin || '',
            businessCurrentFactoringCompany:
                businessCurrentFactoringCompany || '',
            ownerFirstName: ownerFirstName || '',
            ownerLastName: ownerLastName || '',
            ownerAddress: ownerAddress || '',
            ownerCity: ownerCity || '',
            ownerZip: ownerZip || '',
            ownerState: ownerState || '',
            ownerCellPhone: ownerCellPhone || '',
            ownerCellPhoneExt: '',
            ownerHomePhone: ownerHomePhone || '',
            ownerHomePhoneExt: '',
            ownerEmail: ownerEmail || '',
            ownerDateOfBirth: ownerDateOfBirth || '',
            ownerSsn: ownerSsn || '',
            ownerLienStatus: ownerLienStatus || "'",
            ownerEquipmentType: ownerEquipmentType || [],
            ownerHasContractAuthorityStatus:
                ownerHasContractAuthorityStatus || false,
            ownerHasCommonAuthorityStatus:
                ownerHasCommonAuthorityStatus || false,
            ownerHasBrokerAuthorityStatus:
                ownerHasBrokerAuthorityStatus || false,
            ownerIsAllowedToOperate: ownerIsAllowedToOperate || false,
            ownerUccTransferComplete: ownerUccTransferComplete || false,
            creditInternalStatusNotes: creditInternalStatusNotes || '',
            creditDiscountRate: creditDiscountRate || 0,
            creditPriorityRate,
            creditClientProgramType: creditClientProgramType || '',
            creditFuelAdvanceFee: (creditFuelAdvanceFee || 0) / 100,
            creditBrokerApproval: creditBrokerApproval || '',
            creditFuelAdvanceLimit: (creditFuelAdvanceLimit || 0) / 100,
            creditMinimumFee: (creditMinimumFee || 0) / 100,
            creditMessageToClient: creditMessageToClient || '',
            creditFuelAdvance: creditFuelAdvance || false,
            contactNotes: contactNotes || '',
            defaultCarrierLimit,
            defaultCarrierLimitOptions: defaultCarrierLimitOptions || [],
            enable_discount,
            send_aging_to_broker,
            requireSignedBOLDelivered: requireSignedBOLDelivered || false,
            sendCarrierRemittanceToBroker:
                sendCarrierRemittanceToBroker || false,
            documentIssuePhone: documentIssuePhone || '',
            documentIssueEmail: documentIssueEmail || '',
            mailingAddressOne: mailingAddressOne || '',
            mailingAddressTwo: mailingAddressTwo || '',
            mailingAddressCity: mailingAddressCity || '',
            mailingAddressZip: mailingAddressZip || '',
            mailingAddressState: mailingAddressState || '',
            mailingAddressCountry: mailingAddressCountry || '',
            enablePayViaCheck: enablePayViaCheck || false,
            allowSelfFinanceFr: allowSelfFinanceFr || false,
            allowSelfFinanceFrDeclinedDebtors:
                allowSelfFinanceFrDeclinedDebtors || false,
            allowSelfFinanceFrApprovedDebtors:
                allowSelfFinanceFrApprovedDebtors || false,
            allowSelfFinanceApprovedDebtorsOutOfCredit:
                allowSelfFinanceApprovedDebtorsOutOfCredit || false,
            selfFinanceFRLimit: (selfFinanceFRLimit || 0) / 100,
            selfFinanceRolling30DayFRLimit:
                (selfFinanceRolling30DayFRLimit || 0) / 100,
            allowLineItemizedItems: allowLineItemizedItems || false,
            defaultFrTypeApi: defaultFrTypeApi || false,
            enableHideDebtor: enableHideDebtor || false,
            allowSFApprovalWithoutNOA: allowSFApprovalWithoutNOA || false,
            enabledContractTypes: reduce(
                enabledContractTypes,
                (results, value, key) =>
                    value ? concat(results, [key]) : results,
                []
            ),
            ccBrokerDefaultPayment: ccBrokerDefaultPayment || false,
            stripeEnabled: stripeEnabled || false,
            allowBulkImport: allowBulkImport || false,
            includeTMS: keys(pickBy({
                include_tms_invoice: includeTMSInvoice || false,
                include_tms_rate_confirmation: includeTMSRateConfirmation || false
                }, Boolean)),
            useNewNonFactoredFundingRequests: useNewNonFactoredFundingRequests || false,
            allowCoBrokerShipperDebtor: allowCoBrokerShipperDebtor|| false,
            reportReserveToCreditAgencies: reportReserveToCreditAgencies || false,
            allowDoNotContactDebtors: allowDoNotContactDebtors || false,
            defaultDoNotContactDebtors: defaultDoNotContactDebtors || false,
            enableHoldReviewFundingRequestStatus: enableHoldReviewFundingRequestStatus || false,
            enableDisplayingClientReserveBalance: enableDisplayingClientReserveBalance || false
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                await uploadAttachments(id, values.attachments);
                let factoringProfile = {
                    allow_bulk_import: values.allowBulkImport,
                    datasync_option: values.dataSyncOption ? 'aljex' : '',
                    send_rate_confirmation_remote_approved:
                        values.sendRateConfirmationRemoteApproved,
                    credit_status_email: values.creditStatusEmail,
                    referral_source: values.referralSource,
                    enable_reserve_holdback_hybrid:
                        values.enableReserveHoldbackHybrid,
                    default_enable_reserve_holdback:
                        values.defaultEnableReserveHoldback,
                    send_daily_invoice_settlement:
                        values.sendDailyInvoiceSettlement,
                    send_debtor_open_ar_copy: values.sendDebtorOpenARCopy,
                    send_carrier_remittance_to_broker:
                        values.sendCarrierRemittanceToBroker,
                    auto_recovery: values.autoRecovery,
                    margin_discount_rate: values.marginDiscountRate,
                    allow_pending_reserve_access:
                        values.allowPendingReserveAccess,
                    ach_remit_batches: values.achRemitBatches,
                    show_debtor_declined: values.showDebtorDeclined,
                    allow_negative_margin: values.allowNegativeMargin,
                    negative_margin_value: Math.round(
                        values.negativeMarginValue * 100
                    ),
                    negative_margin_limit: values.negativeMarginLimit,
                    disable_send_client_invoices:
                        values.disableSendClientInvoices,
                    cc_debtor_client_invoices_email:
                        values.debtorInvoiceCCEmail,
                    cc_debtor_client_invoices:
                        values.ccClientDebtorInvoiceEmail,
                    allow_fr_without_carrier: values.allowFrWithoutCarrier,
                    no_noa_debit_program: values.noNOADebitProgram,
                    remittance_email: values.remittanceEmail,
                    cc_broker_rate_confirmation_email:
                        values.ccBrokerRateConfirmationEmail,
                    cc_broker_rate_confirmation:
                        values.ccBrokerRateConfirmation,
                    edit_speed_display: values.editSpeedDisplay,
                    auto_fr_create_email: values.autoFRCreateEmail,
                    non_factored_fee: Math.round(values.nonFactoredFee * 100),
                    wire_fee: Math.round(values.wireFee * 100),
                    reserve_holdback: values.reserveHoldback,
                    reserve_holdback_rate: values.reserveHoldbackRate,
                    fuel_advance_auto_approve: values.fuelAdvanceAutoApprove,
                    require_signed_bol: values.requireSignedBOLDelivered,
                    tms: values.tms,
                    reason_for_leaving: values.reasonForLeaving,
                    // referral_business,
                    revenue_share:
                        values.referralPartnerShare === 0
                            ? null
                            : values.referralPartnerShare,
                    create_non_factored_fr: values.factoringCreateNonFactoredFr,
                    revenue_share_type: values.referralMarginType,
                    send_rate_confirmation_pdf: values.sendRateConfirmationPdf,
                    report_ansonia_history: values.reportAnsoniaHistory,
                    enable_discount: values.enable_discount,
                    send_aging_to_broker: values.send_aging_to_broker,
                    default_broker_carrier_payout_limit:
                        values.defaultCarrierLimit,
                    term: values.contractTerm,
                    auto_generate_load_numbers: values.autoGenerateLoadNumber,
                    factoring_approved: values.creditApprovalStatus,
                    admin_message: values.creditInternalStatusNotes,
                    discount_rate: values.creditDiscountRate,
                    apply_priority_rate: values.creditPriorityRate,
                    program_type: values.creditClientProgramType,
                    fuel_advance_fee: Math.round(
                        values.creditFuelAdvanceFee * 100
                    ),
                    broker_approved: values.creditBrokerApproval,
                    fuel_advance_limit: Math.round(
                        values.creditFuelAdvanceLimit * 100
                    ),
                    minimum_fee: Math.round(values.creditMinimumFee * 100),
                    factoring_message: values.creditMessageToClient,
                    fuel_advance: values.creditFuelAdvance,
                    has_contract_authority_status:
                        values.ownerHasContractAuthorityStatus,
                    has_common_authority_status:
                        values.ownerHasCommonAuthorityStatus,
                    has_broker_authority_status:
                        values.ownerHasBrokerAuthorityStatus,
                    is_allowed_to_operate: values.ownerIsAllowedToOperate,
                    ucc_transferred: values.ownerUccTransferComplete,
                    contact_notes: values.contactNotes,
                    company_profile: {
                        phone_number: formatPhone(values.businessPhone),
                        name: values.businessLegalName,
                        number_of_trucks: values.businessNumberOfTrucks,
                        mc: values.businessMc,
                        dot: values.businessDot,
                        tax_id:
                            values.businessEin.trim() !== ''
                                ? values.businessEin
                                : null,
                        address:
                            concat(
                                values.businessAddress,
                                values.businessZip,
                                values.businessState,
                                values.businessCity
                            ) !== ''
                                ? {
                                      street_one:
                                          values.businessAddress !== ''
                                              ? values.businessAddress
                                              : null,
                                      zip:
                                          values.businessZip !== ''
                                              ? values.businessZip
                                              : null,
                                      state:
                                          values.businessState !== ''
                                              ? values.businessState
                                              : null,
                                      city:
                                          values.businessCity !== ''
                                              ? values.businessCity
                                              : null
                                  }
                                : null,
                        current_factoring_company:
                            values.businessCurrentFactoringCompany,
                        types_of_trailers: values.businessTypeOfTrailers,
                        years_in_business: values.businessYears,
                        state_incorporated: values.businessStateIncorporated,
                        number_of_owner_operators:
                            values.businessNumberOfOwnerOperators,
                        type: values.businessType
                    },
                    owner_profile: {
                        name_first: values.ownerFirstName,
                        name_last: values.ownerLastName,
                        tax_lien_status: values.ownerLienStatus,
                        social_number: values.ownerSsn,
                        date_of_birth: values.ownerDateOfBirth,
                        email: values.ownerEmail,
                        phone_number_home: formatPhone(values.ownerHomePhone),
                        phone_number_cell: formatPhone(values.ownerCellPhone),
                        address: {
                            street_one: values.ownerAddress,
                            city: values.ownerCity,
                            state: values.ownerState,
                            zip: values.ownerZip
                        }
                    },
                    document_issue_phone: values.documentIssuePhone,
                    document_issue_email: values.documentIssueEmail,
                    mailing_address: {
                        street_one: values.mailingAddressOne,
                        street_two: values.mailingAddressTwo,
                        city: values.mailingAddressCity,
                        state: values.mailingAddressState,
                        zip: values.mailingAddressZip,
                        country: values.mailingAddressCountry
                    },
                    enable_pay_via_check: values.enablePayViaCheck,
                    allow_self_finance_fr: values.allowSelfFinanceFr,
                    allow_self_finance_fr_declined_debtors:
                        values.allowSelfFinanceFrDeclinedDebtors,
                    allow_self_finance_fr_approved_debtors:
                        values.allowSelfFinanceFrApprovedDebtors,
                    allow_self_finance_approved_debtors_out_of_credit:
                        values.allowSelfFinanceApprovedDebtorsOutOfCredit,
                    allow_line_itemized_items: values.allowLineItemizedItems,
                    default_fr_type_api: values.defaultFrTypeApi,
                    enable_hide_debtor: values.enableHideDebtor,
                    self_finance_fr_limit: Math.round(
                        values.selfFinanceFRLimit * 100
                    ),
                    self_finance_rolling_30_day_fr_limit: Math.round(
                        values.selfFinanceRolling30DayFRLimit * 100
                    ),
                    self_finance_approval_without_noa:
                        values.allowSFApprovalWithoutNOA,
                    enabled_contract_types: {
                        ...contractTypes,
                        ...reduce(
                            values.enabledContractTypes,
                            (results, value) => {
                                results[value] = true;
                                return results;
                            },
                            {}
                        )
                    },
                    cc_broker_default_payment_email: values.ccBrokerDefaultPayment,
                    stripe_enabled: values.stripeEnabled,
                    ...fromPairs(['include_tms_invoice', 'include_tms_rate_confirmation'].map(key => [key, includes(values.includeTMS, key)])),
                    use_new_non_factored_funding_requests: values.useNewNonFactoredFundingRequests,
                    allow_co_broker_shipper_debtor: values.allowCoBrokerShipperDebtor,
                    report_reserve_to_credit_agencies: values.reportReserveToCreditAgencies,
                    allow_do_not_contact_debtors: values.allowDoNotContactDebtors,
                    default_do_not_contact_debtors: values.defaultDoNotContactDebtors,
                    enable_hold_review_funding_request_status: values.enableHoldReviewFundingRequestStatus,
                    enable_displaying_client_reserve_balance: values.enableDisplayingClientReserveBalance
                };
                if (
                    (
                        get(
                            factoringProfile,
                            ['company_profile', 'tax_id'],
                            ''
                        ) || ''
                    ).includes('*')
                ) {
                    factoringProfile = omit(factoringProfile, [
                        'company_profile.tax_id'
                    ]);
                }
                await updateFactoringProfile(factoringProfile);
                if (userType !== 'factoring_remote_admin') {
                    await updateUser({
                        account_manager: values.accountManager,
                        assigned_admin: values.assignedAdmin.trim(),
                        equipment_types: values.ownerEquipmentType
                    });
                    openSnackbar(
                        'success',
                        'Updated User & Factoring Profile!'
                    );
                } else {
                    openSnackbar('success', 'Updated Factoring Profile!');
                }
            } catch (err) {
                openSnackbar('error', head(err));
            } finally {
                setSubmitting(false);
                resetForm();
            }
        }
    };
};

export const noteForm = (
    id,
    createFactoringNote,
    updateFactoringNote,
    openSnackbar
) => ({
    initialValues: {
        note: '',
        attachments: '',
        notesID: ''
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
        try {
            if (values.notesID === '') {
                await createFactoringNote(id, values.note, values.attachments);
                resetForm();
                openSnackbar('success', 'Created Note');
            } else {
                await updateFactoringNote(
                    id,
                    values.notesID,
                    values.note,
                    values.attachments
                );
                resetForm();
                openSnackbar('success', 'Updated Note');
            }
        } catch (err) {
            openSnackbar('error', head(err));
        } finally {
            setSubmitting(false);
        }
    },
    initialStatus: 'CREATE',
    enableReinitialize: true
});

export const createFundingRequestForm = (
    createFundingRequest,
    factoringId,
    openSnackbar,
    closeDialog,
    createTransactionNote
) => ({
    enableReinitialize: true,
    validationSchema: createFundingRequestSchema,
    initialStatus: 'request',
    initialValues: {
        internalTransactionNotes: '',
        payment_method: '',
        nonFactored: false,
        attachments: [],
        amount: 0,
        billToCompany: '',
        billToCompanyEmail: '',
        debtor: {},
        billToCompanyPhone: '',
        loadNumber: '',
        clientNotes: '',
        firstPickUp: {
            formatted_address: ''
        },
        finalDelivery: {
            formatted_address: ''
        },
        equipmentType: '',
        loadLength: ''
    },
    onSubmit: async (values, { setSubmitting }) => {
        try {
            const response = await createFundingRequest(
                Math.round(values.amount * 100),
                values.attachments.map(({ ...attachment }) => {
                    if (!attachment.filename) {
                        return {
                            ...attachment,
                            filename: get(
                                attachment,
                                'file.filename',
                                attachment.filename
                            )
                        };
                    }
                    return attachment;
                }),
                values.billToCompanyEmail,
                formatPhone(values.billToCompanyPhone),
                values.billToCompany,
                factoringId,
                {
                    street_one: values.finalDelivery.address,
                    city: values.finalDelivery.city,
                    state: values.finalDelivery.state,
                    zip: values.finalDelivery.zip,
                    country: values.finalDelivery.country
                },
                {
                    street_one: values.firstPickUp.address,
                    city: values.firstPickUp.city,
                    state: values.firstPickUp.state,
                    zip: values.firstPickUp.zip,
                    country: values.firstPickUp.country
                },
                values.loadLength === '' ? null : values.loadLength,
                values.equipmentType,
                values.loadNumber,
                values.payment_method,
                values.nonFactored,
                values.clientNotes
            );
            if (values.internalTransactionNotes.length > 0) {
                await createTransactionNote(
                    response.id,
                    values.internalTransactionNotes
                );
            }
            openSnackbar('success', 'Funding Request Created');
            closeDialog();
        } catch (err) {
            openSnackbar('error', err);
        } finally {
            setSubmitting(false);
        }
    }
});

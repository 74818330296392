import { createReducer } from "redux-starter-kit";

import * as TYPES from "./types";
import updateFactoringProfileReducer from "./reducers/updateFactoringProfile";
import fetchLineItemsReducer from "./reducers/fetchLineItems";
import fetchReceivablesReducer from "./reducers/fetchReceivables";
import fetchPurchasesReducer from "./reducers/fetchPurchases";
import fetchDebtorRelationReducer from "./reducers/fetchDebtorRelation";
import getCollatedPdfReducer from "./reducers/GetCollatedPdf";
import updateNOADatesReducer from "./reducers/updateNOADates";
import fetchTransactionNotesReducer from "./reducers/fetchTransactionNotes";
import editNoteReducer from "./reducers/editNote";
import fetchLineItemCategoriesReducer from "./reducers/fetchLineItemCategories";
import deleteFundingRequestReducer from "./reducers/deleteFundingRequest";
import deleteLineItemReducer from "./reducers/deleteLineItem";
import editLineItemReducer from "./reducers/editLineItem";
import createLineItemReducer from "./reducers/createLineItem";
import fetchAttachmentsReducer from "./reducers/fetchAttachments";
import fetchFactoringProfileReducer from "./reducers/fetchFactoringProfile";
import fetchDebtorProfileReducer from "./reducers/fetchDebtorProfile";
import sendNOAReducer from "./reducers/sendNOA";
import fetchInvoiceReducer from "./reducers/fetchInvoice";
import fetchCollatedReducer from "./reducers/fetchCollated";
import fetchClientNotesReducer from "./reducers/fetchClientNotes";
import createTransactionNoteReducer from "./reducers/createTransactionNote";
import updateTransactionNoteReducer from "./reducers/updateTransactionNote";
import deleteTransactionNoteReducer from "./reducers/deleteTransactionNote";
import createClientNoteReducer from "./reducers/createClientNote";
import updateClientNoteReducer from "./reducers/updateClientNote";
import deleteClientNoteReducer from "./reducers/deleteClientNote";
import fetchCarrierInvoiceReducer from "./reducers/fetchCarrierInvoice";
import tmsReducer from "./reducers/tms";
import reasonForLeavingReducer from "./reducers/reasonForLeaving";
import referralSourceReducer from "./reducers/referralSource";
import fetchEmailTemplates from "./reducers/fetchEmailTemplates";
import fetchFREmailTemplates from "./reducers/fetchFREmailTemplates";

const initial = {
  updateFactoringProfileLoading: false,
  updateFactoringProfileSuccess: {},
  loadingLineItems: false,
  loadingReceivables: false,
  loadingPurchases: false,
  loadingDebtorRelations: false,
  loadingFundingRequest: false,
  isDeletingFundingRequest: false,
  loadingTransactionNotes: false,
  loadingAttachments: false,
  loadingInvoice: false,
  loadingCarrierInvoice: false,
  invoiceDocs: {},
  loadingCollated: false,
  loadingClientNotes: false,
  clientNotes: {},
  collatedDocs: {},
  carrierDoc: {},
  editNote: {
    open: false,
    note: "",
    id: undefined
  },
  editLineItem: {
    open: false,
    id: undefined
  },
  sendNOA: {
    open: false
  },
  factoringProfile: {},
  lineItems: {},
  lineItemCategories: [],
  receivables: {},
  purchases: {},
  debtorRelations: {},
  collatedPdf: {
    error: false
  },
  transactionNotes: {},
  attachments: {},
  emailTemplates: {},
  loadingEmailTemplates: false,
  reason_for_leaving: {
    ids: {},
    loading: false
  },
  referral_source: {
    ids: {},
    loading: false
  }
};

const reducer = createReducer(initial, {
  [TYPES.UPDATE_FACTORING_PROFILE_SUCCESS]: updateFactoringProfileReducer,
  [TYPES.UPDATE_FACTORING_PROFILE_REQUEST]: updateFactoringProfileReducer,
  [TYPES.UPDATE_FACTORING_PROFILE_FAILURE]: updateFactoringProfileReducer,
  [TYPES.FETCH_LINEITEMS_REQUEST]: fetchLineItemsReducer,
  [TYPES.FETCH_LINEITEMS_SUCCESS]: fetchLineItemsReducer,
  [TYPES.FETCH_LINEITEMS_FAILURE]: fetchLineItemsReducer,
  [TYPES.FETCH_RECEIVABLES_REQUEST]: fetchReceivablesReducer,
  [TYPES.FETCH_RECEIVABLES_SUCCESS]: fetchReceivablesReducer,
  [TYPES.FETCH_RECEIVABLES_FAILURE]: fetchReceivablesReducer,
  [TYPES.FETCH_PURCHASES_REQUEST]: fetchPurchasesReducer,
  [TYPES.FETCH_PURCHASES_SUCCESS]: fetchPurchasesReducer,
  [TYPES.FETCH_PURCHASES_FAILURE]: fetchPurchasesReducer,
  [TYPES.ADD_PURCHASES_SUCCESS]: fetchPurchasesReducer,
  [TYPES.FETCH_DEBTORRELATION_REQUEST]: fetchDebtorRelationReducer,
  [TYPES.FETCH_DEBTORRELATION_SUCCESS]: fetchDebtorRelationReducer,
  [TYPES.FETCH_DEBTORRELATION_FAILURE]: fetchDebtorRelationReducer,
  [TYPES.GET_COLLATED_PDF_SUCCESS]: getCollatedPdfReducer,
  [TYPES.GET_COLLATED_PDF_FAILURE]: getCollatedPdfReducer,
  [TYPES.GET_COLLATED_PDF_REQUEST]: getCollatedPdfReducer,
  [TYPES.UPDATE_NOADATES_REQUEST]: updateNOADatesReducer,
  [TYPES.UPDATE_NOADATES_SUCCESS]: updateNOADatesReducer,
  [TYPES.UPDATE_NOADATES_FAILURE]: updateNOADatesReducer,
  [TYPES.FETCH_TRANSACTIONNOTES_REQUEST]: fetchTransactionNotesReducer,
  [TYPES.FETCH_TRANSACTIONNOTES_SUCCESS]: fetchTransactionNotesReducer,
  [TYPES.FETCH_TRANSACTIONNOTES_FAILURE]: fetchTransactionNotesReducer,
  [TYPES.OPEN_EDIT_NOTE]: editNoteReducer,
  [TYPES.CLOSE_EDIT_NOTE]: editNoteReducer,
  [TYPES.OPEN_EDIT_LINEITEM]: editLineItemReducer,
  [TYPES.CLOSE_EDIT_LINEITEM]: editLineItemReducer,
  [TYPES.UPDATE_TRANSACTIONNOTE_REQUEST]: updateTransactionNoteReducer,
  [TYPES.UPDATE_TRANSACTIONNOTE_SUCCESS]: updateTransactionNoteReducer,
  [TYPES.UPDATE_TRANSACTIONNOTE_FAILURE]: updateTransactionNoteReducer,
  [TYPES.FETCH_LINEITEMCATEGORIES_REQUEST]: fetchLineItemCategoriesReducer,
  [TYPES.FETCH_LINEITEMCATEGORIES_SUCCESS]: fetchLineItemCategoriesReducer,
  [TYPES.FETCH_LINEITEMCATEGORIES_FAILURE]: fetchLineItemCategoriesReducer,
  [TYPES.DELETE_LINEITEM_REQUEST]: deleteLineItemReducer,
  [TYPES.DELETE_LINEITEM_SUCCESS]: deleteLineItemReducer,
  [TYPES.DELETE_LINEITEM_FAILURE]: deleteLineItemReducer,
  [TYPES.CREATE_LINEITEM_REQUEST]: createLineItemReducer,
  [TYPES.CREATE_LINEITEM_SUCCESS]: createLineItemReducer,
  [TYPES.CREATE_LINEITEM_FAILURE]: createLineItemReducer,
  [TYPES.FETCH_FACTORINGATTACHMENTS_REQUEST]: fetchAttachmentsReducer,
  [TYPES.FETCH_FACTORINGATTACHMENTS_SUCCESS]: fetchAttachmentsReducer,
  [TYPES.FETCH_FACTORINGATTACHMENTS_FAILURE]: fetchAttachmentsReducer,
  [TYPES.FETCH_FACTORINGPROFILE_REQUEST]: fetchFactoringProfileReducer,
  [TYPES.FETCH_FACTORINGPROFILE_SUCCESS]: fetchFactoringProfileReducer,
  [TYPES.FETCH_FACTORINGPROFILE_FAILURE]: fetchFactoringProfileReducer,
  [TYPES.FETCH_DEBTORPROFILE_REQUEST]: fetchDebtorProfileReducer,
  [TYPES.FETCH_DEBTORPROFILE_SUCCESS]: fetchDebtorProfileReducer,
  [TYPES.FETCH_DEBTORPROFILE_FAILURE]: fetchDebtorProfileReducer,
  [TYPES.OPEN_SEND_NOA]: sendNOAReducer,
  [TYPES.CLOSE_SEND_NOA]: sendNOAReducer,
  [TYPES.DELETE_FUNDINGREQUEST_SUCCESS]: deleteFundingRequestReducer,
  [TYPES.DELETE_FUNDINGREQUEST_REQUEST]: deleteFundingRequestReducer,
  [TYPES.DELETE_FUNDINGREQUEST_FAILURE]: deleteFundingRequestReducer,
  [TYPES.FETCH_INVOICE_REQUEST]: fetchInvoiceReducer,
  [TYPES.FETCH_INVOICE_SUCCESS]: fetchInvoiceReducer,
  [TYPES.FETCH_INVOICE_FAILURE]: fetchInvoiceReducer,
  [TYPES.FETCH_COLLATED_REQUEST]: fetchCollatedReducer,
  [TYPES.FETCH_COLLATED_SUCCESS]: fetchCollatedReducer,
  [TYPES.FETCH_COLLATED_FAILURE]: fetchCollatedReducer,
  [TYPES.FETCH_CLIENTNOTES_REQUEST]: fetchClientNotesReducer,
  [TYPES.FETCH_CLIENTNOTES_SUCCESS]: fetchClientNotesReducer,
  [TYPES.FETCH_CLIENTNOTES_FAILURE]: fetchClientNotesReducer,
  [TYPES.CREATE_TRANSACTIONNOTE_REQUEST]: createTransactionNoteReducer,
  [TYPES.CREATE_TRANSACTIONNOTE_SUCCESS]: createTransactionNoteReducer,
  [TYPES.CREATE_TRANSACTIONNOTE_FAILURE]: createTransactionNoteReducer,
  [TYPES.DELETE_TRANSACTIONNOTE_REQUEST]: deleteTransactionNoteReducer,
  [TYPES.DELETE_TRANSACTIONNOTE_SUCCESS]: deleteTransactionNoteReducer,
  [TYPES.DELETE_TRANSACTIONNOTE_FAILURE]: deleteTransactionNoteReducer,
  [TYPES.CREATE_CLIENTNOTE_REQUEST]: createClientNoteReducer,
  [TYPES.CREATE_CLIENTNOTE_SUCCESS]: createClientNoteReducer,
  [TYPES.CREATE_CLIENTNOTE_FAILURE]: createClientNoteReducer,
  [TYPES.UPDATE_CLIENTNOTE_REQUEST]: updateClientNoteReducer,
  [TYPES.UPDATE_CLIENTNOTE_SUCCESS]: updateClientNoteReducer,
  [TYPES.UPDATE_CLIENTNOTE_FAILURE]: updateClientNoteReducer,
  [TYPES.DELETE_CLIENTNOTE_REQUEST]: deleteClientNoteReducer,
  [TYPES.DELETE_CLIENTNOTE_SUCCESS]: deleteClientNoteReducer,
  [TYPES.DELETE_CLIENTNOTE_FAILURE]: deleteClientNoteReducer,
  [TYPES.ADD_TMS_REQUEST]: tmsReducer,
  [TYPES.FETCH_TMS_SUCCESS]: tmsReducer,
  [TYPES.FETCH_TMS_REQUEST]: tmsReducer,
  [TYPES.FETCH_TMS_FAILURE]: tmsReducer,
  [TYPES.UPDATE_TMS_REQUEST]: tmsReducer,
  [TYPES.DELETE_TMS_REQUEST]: tmsReducer,
  [TYPES.ADD_REASON_FOR_LEAVING_REQUEST]: reasonForLeavingReducer,
  [TYPES.FETCH_REASON_FOR_LEAVING_SUCCESS]: reasonForLeavingReducer,
  [TYPES.FETCH_REASON_FOR_LEAVING_REQUEST]: reasonForLeavingReducer,
  [TYPES.FETCH_REASON_FOR_LEAVING_FAILURE]: reasonForLeavingReducer,
  [TYPES.UPDATE_REASON_FOR_LEAVING_REQUEST]: reasonForLeavingReducer,
  [TYPES.DELETE_REASON_FOR_LEAVING_REQUEST]: reasonForLeavingReducer,
  [TYPES.ADD_REFERRAL_SOURCE_REQUEST]: referralSourceReducer,
  [TYPES.FETCH_REFERRAL_SOURCE_SUCCESS]: referralSourceReducer,
  [TYPES.FETCH_REFERRAL_SOURCE_REQUEST]: referralSourceReducer,
  [TYPES.FETCH_REFERRAL_SOURCE_FAILURE]: referralSourceReducer,
  [TYPES.UPDATE_REFERRAL_SOURCE_REQUEST]: referralSourceReducer,
  [TYPES.DELETE_REFERRAL_SOURCE_REQUEST]: referralSourceReducer,
  [TYPES.FETCH_CARRIER_INVOICE_FAILURE]: fetchCarrierInvoiceReducer,
  [TYPES.FETCH_CARRIER_INVOICE_SUCCESS]: fetchCarrierInvoiceReducer,
  [TYPES.FETCH_CARRIER_INVOICE_REQUEST]: fetchCarrierInvoiceReducer,
  [TYPES.FETCH_EMAIL_TEMPLATES_FAILURE]: fetchEmailTemplates,
  [TYPES.FETCH_EMAIL_TEMPLATES_REQUEST]: fetchEmailTemplates,
  [TYPES.FETCH_EMAIL_TEMPLATES_SUCCESS]: fetchEmailTemplates,
  [TYPES.FETCH_FR_EMAIL_TEMPLATES_FAILURE]: fetchFREmailTemplates,
  [TYPES.FETCH_FR_EMAIL_TEMPLATES_REQUEST]: fetchFREmailTemplates,
  [TYPES.FETCH_FR_EMAIL_TEMPLATES_SUCCESS]: fetchFREmailTemplates
});

export default reducer;

export { default as fetchReasonForLeaving } from "./actions/fetchReasonForLeaving";
export { default as ReversalLineItems } from "./actions/ReversalLineItems";
export { default as addReasonForLeaving } from "./actions/addReasonForLeaving";
export { default as updateReasonForLeaving } from "./actions/updateReasonForLeaving";
export { default as deleteReasonForLeaving } from "./actions/deleteReasonForLeaving";
export { default as fetchReferralSource } from "./actions/fetchReferralSource";
export { default as addReferralSource } from "./actions/addReferralSource";
export { default as updateReferralSource } from "./actions/updateReferralSource";
export { default as deleteReferralSource } from "./actions/deleteReferralSource";
export { default as fetchTMS } from "./actions/fetchTMS";
export { default as addTms } from "./actions/addTMS";
export { default as updateTMS } from "./actions/updateTMS";
export { default as deleteTMS } from "./actions/deleteTMS";
export { default as fetchEmailTemplates } from "./actions/fetchEmailTemplates";
export { default as fetchFREmailTemplates } from "./actions/fetchFREmailTemplates";

export { default as deleteClientNote } from "./actions/deleteClientNote";
export { default as updateClientNote } from "./actions/updateClientNote";
export { default as createClientNote } from "./actions/createClientNote";
export { default as deleteAttachment } from "./actions/deleteAttachment";
export { default as fetchClientNotes } from "./actions/fetchClientNotes";
export { default as fetchCollated } from "./actions/fetchCollated";
export { default as fetchInvoice } from "./actions/fetchInvoice";
export { default as fetchCarrierInvoice } from "./actions/fetchCarrierInvoice";
export { default as createLineItem } from "./actions/createLineItem";
export { default as updateLineItem } from "./actions/updateLineItem";
export { default as updateTransactionNote } from "./actions/updateTransactionNote";
export { default as updateFactoringProfile } from "./actions/updateFactoringProfile";
export { default as fetchFundingRequest } from "./actions/fetchFundingRequest";
export { default as deleteTransactionNote } from "./actions/deleteTransactionNote";
export { default as updateFundingRequest } from "./actions/updateFundingRequest";
export { default as deleteFundingRequest } from "./actions/deleteFundingRequest";
export { default as fetchLineItems } from "./actions/fetchLineItems";
export { default as fetchReceivables } from "./actions/fetchReceivables";
export { default as fetchPurchases } from "./actions/fetchPurchases";
export { default as addPurchase } from "./actions/addPurchase";
export { default as fetchDebtorRelation } from "./actions/fetchDebtorRelation";
export { default as getCollatedPdf } from "./actions/getCollatedPdf";
export { default as updateNOADates } from "./actions/updateNOADates";
export { default as fetchTransactionNotes } from "./actions/fetchTransactionNotes";
export { default as fetchLineItemHistory } from "./actions/fetchLineItemHistory";
export { default as closeEditNote } from "./actions/closeEditNote";
export { default as openEditNote } from "./actions/openEditNote";
export { default as closeEditLineItem } from "./actions/closeEditLineItem";
export { default as openEditLineItem } from "./actions/openEditLineItem";
export { default as openSendNOA } from "./actions/openSendNOA";
export { default as closeSendNOA } from "./actions/closeSendNOA";
export { default as createTransactionNote } from "./actions/createTransactionNote";
export { default as fetchLineItemCategories } from "./actions/fetchLineItemCategories";
export { default as deleteLineItem } from "./actions/deleteLineItem";
export { default as fetchAttachments } from "./actions/fetchAttachments";
export { default as fetchFactoringProfile } from "./actions/fetchFactoringProfile";
export { default as fetchDebtorProfile } from "./actions/fetchDebtorProfile";
export { default as sendNOA } from "./actions/sendNOA";
export { default as fetchFreightClass } from "./actions/fetchFreightClass";
export { default as fetchCommodities } from "./actions/fetchCommodities";

export { default as getUpdateFactoringProfile } from "./selectors/getUpdateFactoringProfile";
export { default as getUpdateFactoringProfileLoading } from "./selectors/getUpdateFactoringProfileLoading";
export { default as getFundingRequest } from "./selectors/getFundingRequest";
export { default as getLineItems } from "./selectors/getLineItems";
export { default as getReceivables } from "./selectors/getReceivables";
export { default as getPurchases } from "./selectors/getPurchases";
export { default as getDebtorRelation } from "./selectors/getDebtorRelation";
export { default as collatedPdf } from "./selectors/getCollatedPdf";
export { default as getTransactionNotes } from "./selectors/getTransactionNotes";
export { default as getEditNote } from "./selectors/getEditNote";
export { default as getEditLineItem } from "./selectors/getEditLineItem";
export { default as getUserForFundingRequest } from "./selectors/getUserForFundingRequest";
export { default as getDebtorForFundingRequest } from "./selectors/getDebtorForFundingRequest";
export { default as getFactoringIdForFundingRequest } from "./selectors/getFactoringIdForFundingRequest";
export { default as getLineItemCategories } from "./selectors/getLineItemCategories";
export { default as getAttachments } from "./selectors/getAttachments";
export { default as getIsDeletingFundingRequest } from "./selectors/getIsDeletingFundingRequest";
export { default as getFactoringProfile } from "./selectors/getFactoringProfile";
export { default as getDebtorProfile } from "./selectors/getDebtorProfile";
export { default as getSendNOA } from "./selectors/getSendNOA";
export { default as getClientNotes } from "./selectors/getClientNotes";
export { default as getPaymentProfile } from "./selectors/getPaymentProfile";

export { default as getInvoiceDoc } from "./selectors/getInvoiceDoc";
export { default as getCollatedDoc } from "./selectors/getCollatedDoc";
export { default as getCarrierInvoiceDoc } from "./selectors/getCarrierInvoiceDoc";
export { default as getFactoringProfileById } from "./selectors/getFactoringProfileById";
export { default as getFundingRequestStatusAt } from "./selectors/getFundingRequestStatusAt";
export { default as getTMS } from "./selectors/getTMS";
export { default as getReasonForLeaving } from "./selectors/getReasonForLeaving";
export { default as getReferralSources } from "./selectors/getReferralSources";
export { default as getEmailTemplates } from "./selectors/getEmailTemplates";

import * as React from "react";
import { Tooltip, TooltipProps } from "antd";

function AntTooltip(props: TooltipProps) {
    return (
        <Tooltip
            {...props}
        />
    )
}
export default AntTooltip;
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import React from "react";

import colors from "../../../../../theme/colors";

export default ({ saveSubmtitting, handleSave }) => (
  <Grid container style={{ width: "150px" }}>
    <Grid item>
      <Tooltip title="Save Updates">
        <IconButton
          style={{ width: "48px", height: "48px" }}
          onClick={handleSave}
          disabled={saveSubmtitting}
        >
          <FontAwesomeIcon color={colors.green} icon={faSave} />
        </IconButton>
      </Tooltip>
    </Grid>
  </Grid>
);

import { get } from "lodash";
import { denormalize } from "normalizr";
import { fundingRequest, notes } from "../../../api/schema";

const getSelectedRecievableNotes = state => {
  try {
    const fr = get(state, "ui.dialog.data.fundingRequestId", "");

    const denormalized = denormalize(
      { fr },
      { fr: fundingRequest },
      state.entities
    );

    const funding = denormalized.fr;

    const receivable = get(
      funding,
      "receivable_id",
      get(state, "ui.dialog.data.receivableId", "")
    );

    const receivableNotes = get(
      state,
      `debtor.receivableNotes.${receivable}`,
      []
    );

    const denormalizedNotes = denormalize(
      { receivableNotes },
      { receivableNotes: [notes] },
      state.entities
    );
    return denormalizedNotes.receivableNotes;
  } catch (err) {
    return [];
  }
};

export default getSelectedRecievableNotes;

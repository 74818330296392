import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import BalanceCard from './components/BalanceCard';
import API from '../../../../api';

export default function ({ factoringId, userId }) {
    const [accountBalance, setAccountBalance] = useState({});
    useEffect(() => {
        async function f() {
            const { pending, balance } = await API.factoring.stripeWalletBalance(userId, factoringId);
            setAccountBalance({
                pending,
                balance
            });
        }
        if(factoringId) {
            f();
        }
    }, [factoringId])
    return <Grid container justify="center">
        <BalanceCard balance={parseFloat(accountBalance.balance, 0)} pending={parseFloat(accountBalance.pending, 0)} />
    </Grid>
}
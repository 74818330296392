import { Chip } from "@material-ui/core";
import React from "react";

const TimeZone = ({ zone }) => {
  if (zone === "PT") {
    return <Chip style={{ backgroundColor: "#C52727" }} label="PST" />;
  }
  if (zone === "MT") {
    return <Chip style={{ backgroundColor: "#FA9D4A" }} label="MST" />;
  }
  if (zone === "CT") {
    return <Chip style={{ backgroundColor: "#F8E71C" }} label="CST" />;
  }
  if (zone === "ET") {
    return <Chip style={{ backgroundColor: "#B8E986" }} label="EST" />;
  }
  if (zone === "AKT") {
    return <Chip style={{ backgroundColor: "#94B9E5" }} label="AKST" />;
  }
  return <React.Fragment />;
};

export default TimeZone;

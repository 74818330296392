import axios, { AxiosRequestConfig } from "axios";

const createDebtorPayment = async (
  debtorId: string,
  data: Record<string, any>,
  params: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/payments/debtor/${debtorId}/funding_requests/`,
    method: "POST",
    data,
    params
  };
  const response = await axios(options);
  return response.data;
};

const uploadPaymentsFile = async ({ file }: { file: any }): Promise<any> => {
  const data = new FormData();
  data.append("file", file);
  const options: AxiosRequestConfig = {
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data,
    url: `/funding_requests/receivables/upload/`
  };
  const response = await axios(options);
  return response.data;
};

const getProfileHistory = async (paymentProfileId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/${paymentProfileId}/history/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

export const updatePayment = async (
  paymentId: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/payments/${paymentId}/update/`,
    method: "PATCH",
    data
  };
  const response = await axios(options);
  return response.data;
};

const deletePayment = async (
  paymentId: string,
  data: Record<string, any>
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/payments/${paymentId}/`,
    method: "DELETE",
    data
  };
  const response = await axios(options);
  return response.data;
};

const getPaymentProfileNotes = async (
  paymentProfileId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/${paymentProfileId}/notes/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
};

const createPaymentProfileNotes = async (
  paymentProfileId: string,
  data: Record<string, any>
): Promise<any> => {
  let filename;
  if (data.attachments && data.attachments.name) {
    filename = [{ filename: data.attachments.name }];
  }
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/${paymentProfileId}/notes/`,
    method: "POST",
    data: {
      note: data.note,
      attachments: filename
    }
  };
  const response = await axios(options);
  return response.data;
};

const deletePaymentProfileNotes = async (
  paymentProfileId: string,
  noteId: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/${paymentProfileId}/notes/${noteId}/`,
    method: "DELETE"
  };
  const response = await axios(options);
  return response.data;
};

const updatePaymentProfileNotes = async (
  paymentProfileId: string,
  noteId: string,
  data: Record<string, any>
): Promise<any> => {
  let filename;
  if (data.attachments && data.attachments.name) {
    filename = [{ filename: data.attachments.name }];
  }
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/${paymentProfileId}/notes/${noteId}/`,
    method: "PATCH",
    data: {
      note: data.note,
      attachments: filename
    }
  };
  const response = await axios(options);
  return response.data;
};

const getPaymentProfileAuditLog = async (relationshipId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    url: `/factoring/payment_profiles/relationships/${relationshipId}/history/`,
    method: "GET"
  };
  const response = await axios(options);
  return response.data;
}

export default {
  createDebtorPayment,
  uploadPaymentsFile,
  getProfileHistory,
  updatePayment,
  deletePayment,
  getPaymentProfileNotes,
  createPaymentProfileNotes,
  deletePaymentProfileNotes,
  updatePaymentProfileNotes,
  getPaymentProfileAuditLog
};

export default (words: string): string => {
  if (typeof words === "string") {
    return words
      .split("_")
      .map(
        (word: string): string => word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(" ");
  }
  return words;
};

import React, { useState, useRef, useEffect } from 'react';
import { CropToolProps, CropTool, RefProps } from '@haulpay/ui';
import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import RotateLeft from '@material-ui/icons/RotateLeft';
import RotateRight from '@material-ui/icons/RotateRight';
import Crop from '@material-ui/icons/Crop';
import Close from '@material-ui/icons/Close';
import Save from '@material-ui/icons/Save';
import rotateImage, { generateName } from '../../helpers/rotateImage';

const dataUrlToFile = async (
  dataUrl: string,
  fileName: string
): Promise<File> => {
  const res = await fetch(dataUrl);
  const blobFile = await res.blob();
  return new File([blobFile], fileName, { type: 'image/png' }); // mimetype always PNG
};

interface ImageCropperProps {
  open: boolean;
  handleExit: () => void;
  data: {
    file: File | string;
    save: (args: File | string | null) => void;
  };
}
function ImageCropper(props: ImageCropperProps) {
  const {
    open = true,
    handleExit,
    data: { file, save },
  } = props;

  const [image, setImage] = useState<File | string | Blob | null>('');
  const [fileName, setFileName] = useState<string>('');
  const cropper = useRef<RefProps>(null);

  useEffect(() => {
    if (typeof file === 'string') {
      const request = new XMLHttpRequest();
      request.open('GET', file, true);
      request.responseType = 'blob';
      request.onload = () => {
        const reader = new FileReader();
        reader.addEventListener('load', () => setImage(reader.result as any));
        reader.readAsDataURL(request.response);
      };
      request.send();
      setFileName(generateName());
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImage(reader.result as any));
      reader.readAsDataURL(file);
      setFileName(file.name);
    }
  }, [file]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      //   TransitionComponent={Transition}
    >
      <Grid>
        <AppBar color="secondary" style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton color="inherit" onClick={handleExit} aria-label="Close">
              <Close />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Edit Image Tool
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid style={{ minHeight: '100%', margin: 20 }}>
        <Grid container justify="center">
          {image && <CropTool image={image} ref={cropper} />}
        </Grid>
        <Grid
          container
          direction="row"
          style={{ width: '100%', paddingTop: 20 }}
          alignContent="flex-end"
        >
          <Grid item xs={4}>
            <div>
              <Button
                variant="raised"
                color="secondary"
                size="medium"
                onClick={handleExit}
              >
                <Close />
                Cancel
              </Button>
            </div>
          </Grid>
          <Grid container item xs={4} justify="center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                if (cropper.current !== null) {
                  cropper?.current.rotateAngle(-90);
                }
              }}
            >
              <RotateLeft fontSize="large" />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={async () => {
                if (cropper.current !== null) {
                  const data = await cropper?.current.getCropData();
                  setImage(data);
                }
              }}
            >
              <Crop fontSize="large" />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                if (cropper.current !== null) {
                  cropper?.current.rotateAngle(90);
                }
              }}
            >
              <RotateRight fontSize="large" />
            </IconButton>
          </Grid>
          <Grid
            container
            item
            xs={4}
            justify="flex-end"
            direction="row"
            spacing={8}
          >
            <Grid item>
              <Button
                variant="raised"
                size="medium"
                color="secondary"
                onClick={() => {
                  if (typeof file !== 'string') {
                    // save(file);
                  }
                  handleExit();
                }}
              >
                <Save />
                Don't Crop
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="raised"
                size="medium"
                color="primary"
                onClick={async () => {
                  if (cropper.current !== null) {
                    const data = await cropper?.current.getCropData();
                    const img = await dataUrlToFile(data as any, fileName);
                    save(img as any);
                  }
                  handleExit();
                }}
              >
                <Save />
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
export default ImageCropper;

import React from "react";
import StatusFormat from "../../../../components/StatusFormat";

const columns = [
  {
    name: "Carrier",
    key: "company_name"
  },
  {
    name: "Factor",
    key: "factoring_company_name"
  },
  {
    key: "status",
    name: "Status",
    format: (value: string): JSX.Element => <StatusFormat status={value} />
  },
  {
    key: "owner_email",
    name: "Email"
  },

  {
    name: "Phone #",
    key: "owner_phone_number_cell"
  },
  {
    key: "company_mc",
    name: "MC #"
  },
  {
    key: "company_dot",
    name: "DOT #"
  }
];

export default columns;

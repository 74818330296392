import { assign } from "lodash";

import {
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILURE
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case FETCH_ACCOUNTS_REQUEST:
      return {
        ...state,
        loadingAccounts: true
      };
    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loadingAccounts: false,
        accounts: assign({}, state.accounts, action.payload)
      };
    case FETCH_ACCOUNTS_FAILURE:
      return {
        ...state,
        loadingAccounts: false
      };
    default:
      return state;
  }
};

import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Header from "../../../components/Header";

const Layout = ({
  children,
  isInvited = false,
  noTitle = false,
  extraHeader = <div />
}) => (
  <div style={{ background: "white", width: "100%", height: '100vh' }}>
    <Grid
      container
      item
      style={{ paddingTop: "32px", paddingBottom: "32px" }}
      xs={12}
    >
      <Header isInvited={isInvited} noTitle={noTitle} />
      {extraHeader}
    </Grid>
    <Grid
      container
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
      style={{ padding: "0 10px" }}
    >
      {children}
    </Grid>
  </div>
);

Layout.propTypes = {
  children: PropTypes.element.isRequired
};

export default Layout;

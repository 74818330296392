import React, { useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import InternalLayout from "../../../../components/layouts/InternalLayout";
import Can from "../../../../components/Can";
import Table from "../../../../components/Table";
import columns from "./columns";
import NavigationTabs from "../../../../components/NavigationTabs";
import Link from "../../../../components/Link";
import tabs from "../tabs";
import EditButton from "./components/EditButton";
import {
  fetchGroups,
  getGroups,
  groupsTableSelectors,
  groupsTableActions
} from "../../../../modules/groups";

function Groups(): JSX.Element {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGroups(20, "-created", {}));
  }, []);
  const groups = useSelector(getGroups);

  const formattedGroups = groups.map((group: any) => {
    return {
      ...group,
      company_name: (): JSX.Element => (
        <Link to={`/admin/factorclients/${group.factoring_id}/profile`}>
          {group.company_name}
        </Link>
      ),
      name: (): JSX.Element => (
        <Link to={`/admin/groups/${group.id}/ownership`}>{group.name}</Link>
      ),
      actions: <EditButton id={group.id} />
    };
  });

  return (
    <Can
      perform="admin-groups:view"
      yes={(): JSX.Element => (
        <InternalLayout title="Groups">
          <NavigationTabs tabs={tabs} value={0} />
          <Paper style={{ marginTop: "32px" }}>
            <div style={{ paddingLeft: 16 }}>
              <Table
                columns={columns}
                rows={formattedGroups}
                isLoading={useSelector(groupsTableSelectors.getIsLoading)}
                handleSort={useCallback(
                  (...args: any[]) =>
                    dispatch(groupsTableActions.handleSort(...args)),
                  [dispatch]
                )}
                sortDirection={useSelector(
                  groupsTableSelectors.getSortDirection
                )}
                sortBy={useSelector(groupsTableSelectors.getSortBy)}
                count={useSelector(groupsTableSelectors.getCount)}
                page={useSelector(groupsTableSelectors.getPage)}
                rowsPerPage={useSelector(groupsTableSelectors.getRowsPerPage)}
                handleChangePage={useCallback(
                  (...args: any[]) =>
                    dispatch(groupsTableActions.handleChangePage(...args)),
                  [dispatch]
                )}
                rowsPerPageOptions={useSelector(
                  groupsTableSelectors.getRowsPerPageOptions
                )}
                handleChangeRowsPerPage={useCallback(
                  (...args: any[]) =>
                    dispatch(
                      groupsTableActions.handleChangeRowsPerPage(...args)
                    ),
                  [dispatch]
                )}
                filter
                filters={useSelector(groupsTableSelectors.getFilters)}
                handleFilterChange={useCallback(
                  (...args: any) =>
                    dispatch(groupsTableActions.handleSearchFilter(...args)),
                  [dispatch]
                )}
                allowEmpty
              />
            </div>
          </Paper>
        </InternalLayout>
      )}
      no={(): JSX.Element => <Redirect to="/" />}
    />
  );
}

export default Groups;

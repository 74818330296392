import React from "react";
import { Paper } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { get } from "lodash";

import Link from "../../../components/Link";
import NavigationTabs from "../../../components/NavigationTabs";
import Table from "../../../components/Table";
import CSVButton from "../../../components/buttons/CSVButton";
import MoreButton from "./components/MoreButton";
import Filters from "./components/Filters";
import InternalLayout from "../../../components/layouts/InternalLayout";
import Can from "../../../components/Can";

const Users = ({
  tabs,
  columns,
  statusIndex,
  isLoading,
  handleSort,
  sortDirection,
  sortBy,
  count,
  page,
  rowsPerPage,
  handleChangePage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  users,
  handleSearchFilter,
  handleCSV,
  user_type: userType,
  setUserType,
  equipment_types: equipmentTypes,
  setEquipmentTypes,
  has_active_subscription: hasActiveSubscription,
  setActiveSubscription,
  filters
}) => {
  const formattedRows = users.map(row => ({
    ...row,
    contact_name: (
      <Link to={`/admin/users/${row.id}/profile`}>{row.contact_name}</Link>
    ),
    more: <MoreButton id={row.id} />
  }));

  return (
    <Can
      key="Users"
      perform="admin-debtors:view"
      yes={() => (
        <InternalLayout title="Users">
          <React.Fragment>
            <NavigationTabs tabs={tabs} value={statusIndex} />
            <Paper style={{ marginTop: "32px" }}>
              <Filters
                handleSearchFilter={handleSearchFilter}
                user_type={userType}
                setUserType={setUserType}
                equipment_types={equipmentTypes}
                setEquipmentTypes={setEquipmentTypes}
                has_active_subscription={hasActiveSubscription}
                setActiveSubscription={setActiveSubscription}
                stripeEmail={get(filters, "stripe_email", "")}
              >
              </Filters>
              <div style={{ paddingLeft: 16 }}>
                <Table
                  columns={columns}
                  rows={formattedRows}
                  isLoading={isLoading}
                  handleSort={handleSort}
                  sortDirection={sortDirection}
                  sortBy={sortBy}
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  filter
                  filters={filters}
                  handleFilterChange={handleSearchFilter}
                  allowEmpty
                />
              </div>
            </Paper>
          </React.Fragment>
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
};

export default Users;

import { denormalize } from "normalizr";
import { user } from "../../../api/schema";
import getUserId from "./getUserId";

const getUser = state => {
  try {
    const item = getUserId(state);

    const denormalized = denormalize({ item }, { item: user }, state.entities);

    return denormalized.item;
  } catch (err) {
    return {};
  }
};

export default getUser;

import formatPennies from "../../../../helpers/format/formatPennies";
import formatDate from "../../../../helpers/format/formatDate";

const columns = [
  {
    key: "company_name",
    name: "Payee",
    filter: "text",
    filter_key: "company_name"
  },
  {
    key: "factoring_company_name",
    name: "Factor Company",
    filter: "text",
    filter_key: "factoring_company_name"
  },
  {
    key: "check_number",
    name: "Check #",
    filter: "text",
    filter_key: "check_number"
  },
  {
    key: "trace_id",
    name: "Ref #",
    filter: "text",
    filter_key: "trace_id_number_string"
  },
  {
    key: "amount",
    name: "Total Funded",
    format: formatPennies,
    filter: "penny",
    filter_key: "amount"
  },
  {
    key: "account",
    name: "Account"
  },
  {
    key: "status",
    name: "Status",
  },
  {
    key: "created",
    name: "Created",
    format: formatDate,
    default: "-"
  },
  {
    key: "action",
    name: ""
  }
];

export default columns;

import { normalize } from "normalizr";
import { addEntities } from "../../entities";

const updateFactoringCompany = (id, company) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  const response = await api.groups.updateFactoringCompany(id, company);
  const data = normalize(response, schema.factoringCompany);
  dispatch(addEntities(data.entities));
  return response;
};

export default updateFactoringCompany;

const columns = [
  {
    key: "category",
    name: "Category"
  },
  {
    key: "amount",
    name: "Amount"
  },
  {
    key: "account",
    name: "Account"
  },
  {
    key: "purchasedAt",
    name: "Purchased At"
  },
  {
    key: "approvalDate",
    name: "Approval Date"
  },
  {
    key: "purchaseCheckNumber",
    name: "Check #"
  },
  {
    key: "purchaseTraceId",
    name: "Ref #"
  },
  {
    key: "status",
    name: "Status"
  },
  {
    key: "action",
    name: ""
  }
];

export default columns;

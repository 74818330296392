import { assign } from "lodash";
import { PayloadAction } from "redux-starter-kit";
import {
  FETCH_SIMILARDEBTORS_FAILURE,
  FETCH_SIMILARDEBTORS_REQUEST,
  FETCH_SIMILARDEBTORS_SUCCESS,
  State
} from "../types";

export default (state: State, action: PayloadAction): State => {
  switch (action.type) {
    case FETCH_SIMILARDEBTORS_REQUEST:
      return assign({}, state, {
        similarDebtors: assign({}, state.similarDebtors, {
          loading: true,
          ids: [],
          count: 0
        })
      });
    case FETCH_SIMILARDEBTORS_SUCCESS:
      return assign({}, state, {
        similarDebtors: assign({}, state.similarDebtors, {
          loading: false,
          ids: action.payload.ids,
          count: action.payload.ids.length
        })
      });
    case FETCH_SIMILARDEBTORS_FAILURE:
      return assign({}, state, {
        similarDebtors: assign({}, state.similarDebtors, {
          loading: false
        })
      });
    default:
      return state;
  }
};

import React from "react";
import {
    Card,
    CardContent,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import formatDollar from "../../../../../helpers/format/formatDollars";
import colors from "../../../../../theme/colors";

const useStyles = makeStyles(() => ({
    card: {
        display: 'flex',
        width: '25rem',
        justifyContent: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    title: {
        fontSize: 25,
        fontWeight: 600,
        textAlign: 'center',
    },
    balance: {
        fontSize: 20,
        fontWeight: 400,
        color: colors.green_dark,
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 16,
        fontWeight: 300,
        color: colors.grayText,
        textAlign: 'center',
    },
    pendingBalance: {
        paddingTop: 10,
        fontSize: 16,
        fontWeight: 400,
        color: colors.grayText,
        textAlign: 'right',
    },
}));

function BalanceCard({ balance, pending = 0 }) {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                <Typography className={classes.title}>
                    My Wallet
                </Typography>
                <Typography className={classes.balance}>
                    {formatDollar(balance || 0)}
                </Typography>
                <Typography className={classes.subtitle}>
                    Current Balance
                </Typography>
                    {!!pending &&
                        <Typography className={classes.pendingBalance}>
                            {`Pending: ${formatDollar(pending)}`}
                        </Typography>
                    }
            </CardContent>
        </Card>
    );

}
export default BalanceCard;
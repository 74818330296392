import formatDate from "../../../../../../helpers/format/formatDate";
import splitAndCase from "../../../../../../helpers/format/splitAndCase";
import formatPennies from "../../../../../../helpers/format/formatPennies";

const columns = [
  {
    key: "invoice_number",
    name: "Invoice Number",
    filter: "text",
    filter_key: "funding_request_invoice_number"
  },
  {
    key: "receivable.funding_request.user_load_number",
    name: "Load #",
    filter: "text",
    filter_key: "funding_request_load"
  },
  {
    key: "factoring.company_profile.name",
    name: "Client",
    filter: "text",
    filter_key: "funding_request_client"
  },
  {
    key: "created",
    name: "Created At",
    format: formatDate
  },
  {
    key: "debit",
    name: "Debit Amnt.",
    format: formatPennies
  },
  {
    key: "receivable.funding_request.amount",
    name: "Invoice Amnt.",
    format: formatPennies
  },
  {
    key: "status",
    name: "Status",
    format: splitAndCase
  },
  {
    key: "more",
    name: "More"
  }
];

export default columns;

import { IconButton, Tooltip, Zoom } from "@material-ui/core";
import React from "react";
import SourceIcon from "./SourceIcon";

interface Props {
  source: string;
  width?: number;
  height?: number;
}

const index = ({ source, width = 40, height = 40 }: Props): JSX.Element => (
  <Tooltip TransitionComponent={Zoom} title={source}>
    <IconButton aria-label="source">
      <SourceIcon width={width} height={height} source={source} />
    </IconButton>
  </Tooltip>
);

export default index;

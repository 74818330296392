import React, { useState } from "react";
import {
  assign,
  findIndex,
  includes,
  isArray,
  keys,
  map,
  mapKeys,
  slice,
  toPairs,
  values,
  isEmpty,
  get
} from "lodash";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { faMinus, faPlus, faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import formatAuditLogKey from "../helpers/formatAuditLogKey";
import splitAndCase from "../helpers/format/splitAndCase";
import formatDate from "../helpers/format/formatDate";
import formatStatus from "../helpers/format/formatStatus";
import formatPennies from "../helpers/format/formatPennies";
import formatDays from "../helpers/format/formatDays";
import Colors from "../theme/colors";

function stringFormatter(s: string, key: string): string {
  switch (key) {
    case "minimum_fee":
      return formatPennies(parseInt(s, 0));
  }
  return formatStatus(formatDate(splitAndCase(s)));
}

function numberFormatter(key: string, value: number): string {
  switch (key) {
    case "enable_reserve_holdback_hybrid":
      return String(value);
    case "debtor_dtp_allowance":
      return formatDays(value);
    case "payout_days":
      return formatDays(value);
    case "last_used_load_length":
      return String(value);
    case "minimum_fee":
      return formatPennies(value);
    default:
      return formatPennies(value);
  }
}

interface User {
  name: string;
  id?: string;
  user_type: string;
}

interface ResponsibleUserProps {
  time: string;
  user: User;
  diff: any[];
  created?: boolean;
}

function ResponsibleUser({
  time,
  user: { name, id },
  diff,
  created
}: ResponsibleUserProps): JSX.Element {
  let username = name;
  const message = created ? "created." : "Modified these fields:";
  if (username === "") {
    username = "HaulPay Dashboard";
  }

  const values = diff.map(
    ([key], i) => splitAndCase(key) + (i !== diff.length - 1 ? ", " : "")
  );

  const UserLink = (): JSX.Element => (
    <Link to={`/admin/users/${id}/profile`}>{`${username} ${message}`}</Link>
  );

  return (
    <ListItemText secondary={values}>
      <Typography variant="caption">{time}</Typography>
      <UserLink />
    </ListItemText>
  );
}

interface DiffProps {
  diff: any[];
}

function Diff({ diff }: DiffProps): JSX.Element {
  return (
    <Table style={{ backgroundColor: "#DFECFA" }}>
      <TableHead>
        <TableRow>
          <TableCell>Field</TableCell>
          <TableCell>Old Value</TableCell>
          <TableCell>New Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {map(diff, ([key, value]) => {
          const { old_value, new_value } = value;

          let formattedOldValue;
          let formattedNewValue;

          switch (typeof old_value) {
            case "string":
              formattedOldValue = stringFormatter(old_value, key);
              break;
            case "number":
              formattedOldValue = numberFormatter(key, old_value);
              break;
            default:
              JSON.stringify(old_value);
          }

          switch (typeof new_value) {
            case "string":
              formattedNewValue = stringFormatter(new_value, key);
              break;
            case "number":
              formattedNewValue = numberFormatter(key, new_value);
              break;
            case "object":
              if (isArray(new_value)) {
                formattedNewValue = new_value.map(value => {
                  return (
                    <>
                      <a href={value.preview_url}>{value.filename}</a>
                      <br />
                    </>
                  );
                });
                break;
              }
              formattedNewValue = JSON.stringify(new_value);
              break;
            default:
              formattedNewValue = JSON.stringify(new_value);
          }
          return (
            <TableRow>
              <TableCell>{splitAndCase(key)}</TableCell>
              <TableCell>{formattedOldValue}</TableCell>
              <TableCell>{formattedNewValue}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

interface RowProps {
  diff: {
    files?: any[];
    values_changed: Record<string, any>;
  };
  current: Record<string, any>;
  responsibleUser: User;
  prefix: string | string[];
}

function Row({
  diff,
  current,
  responsibleUser,
  prefix
}: RowProps): JSX.Element {
  const [expanded, setExpended] = useState(false);
  let currentResponsibleUser = responsibleUser;
  let changed = diff.values_changed;
  if (!isEmpty(diff.files || []) && typeof prefix === "string") {
    const files = {
      [`root[${prefix}'attachments']`]: {
        new_value: diff.files,
        old_value: ""
      }
    };
    currentResponsibleUser = get(diff, "files[0].responsible_user", "");
    changed = assign(changed, files);
  }
  if (isArray(prefix)) {
    if (prefix.length > 0) {
      keys(changed).forEach((key: string): void => {
        const found = prefix.reduce(
          (acc, cur): boolean => key.includes(cur) || acc,
          false
        );
        if (!found) {
          delete changed[key];
        }
      });
    }
  } else if (prefix.length > 0) {
    keys(changed).forEach((key: string): void => {
      if (!key.includes(prefix)) {
        delete changed[key];
      }
    });
  }

  const pairs = toPairs(
    mapKeys(changed, (value, key): string => formatAuditLogKey(key, prefix))
  );

  const i = findIndex(keys(current), (key: string): boolean =>
    includes(key, "modified")
  );
  const time = formatDate(values(current)[i]);

  const toFilter: string[] = ["last_edited_by", "modified"];
  const filteredPairs = pairs.filter(
    item =>
      !toFilter.reduce((acc, curr) => acc || item[0].includes(curr), false)
  );
  return (
    <div>
      {filteredPairs.length > 0 && (
        <div>
          <ListItem onClick={(): void => setExpended(!expanded)} button>
            <ListItemIcon>
              {expanded ? (
                <FontAwesomeIcon color={Colors.link} icon={faMinus} />
              ) : (
                <FontAwesomeIcon color={Colors.link} icon={faPlus} />
              )}
            </ListItemIcon>
            <ResponsibleUser
              time={time}
              user={currentResponsibleUser}
              diff={slice(filteredPairs, 0, 3)}
            />
          </ListItem>
          {expanded && (
            <ListItem>
              <Grid container item>
                <Diff diff={filteredPairs} />
              </Grid>
            </ListItem>
          )}
        </div>
      )}
    </div>
  );
}

interface Changed {
  new_value: string;
  old_value: string;
}

interface Diff {
  values_changed: {
    [name: string]: Changed;
  };
}

export interface AuditLogEntry {
  current_row: Record<string, any>;
  diff?: Diff;
  responsible_user: User;
}

interface EntryProps {
  entry: AuditLogEntry;
  prefix?: string | string[];
}

function Entry({ entry, prefix = "" }: EntryProps): JSX.Element {
  return (
    <Row
      current={entry.current_row}
      diff={
        entry.diff || {
          values_changed: {}
        }
      }
      responsibleUser={entry.responsible_user}
      prefix={prefix}
    />
  );
}

interface AuditTrailProps {
  auditLog: AuditLogEntry[];
  prefix?: string | string[];
}

export default function AuditTrail({
  auditLog = [],
  prefix
}: AuditTrailProps): JSX.Element {
  const lastIndex = auditLog.length - 1;
  return (
    <List>
      {auditLog.map(
        (entry, index): JSX.Element => {
          if (lastIndex === index) {
            const i = findIndex(
              keys(entry.current_row),
              (key: string): boolean => includes(key, "modified")
            );
            const time = formatDate(values(entry.current_row)[i]);
            return (
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon color={Colors.link} icon={faBookOpen} />
                </ListItemIcon>
                <ResponsibleUser
                  time={time}
                  user={entry.responsible_user}
                  diff={[]}
                  created
                />
              </ListItem>
            );
          }
          return <Entry entry={entry} prefix={prefix} />;
        }
      )}
    </List>
  );
}

import React from "react";
import FormatBool from "../../../../components/FormatBool";
import formatApplied from "../../../../helpers/format/formatApplied";

const columns = [
  {
    key: "name",
    name: "Name",
    filter: "text",
    filter_key: "name"
  },
  {
    key: "appliedAgainst",
    name: "Applied Against",
    format: formatApplied,
    filter: "text",
    filter_key: "applied_against"
  },
  {
    key: "applied_by",
    name: "Applied By",
    format: formatApplied,
    default: "-",
    filter: "text",
    filter_key: "user_type"
  },
  {
    key: "is_debtor_invoiced",
    name: "On Debtor Invoice",
    format: (bool: boolean): JSX.Element => <FormatBool bool={bool} />,
    sort: true
  },

  {
    key: "is_client_invoiced",
    name: "On Client Invoice",
    format: (bool: boolean): JSX.Element => <FormatBool bool={bool} />,
    sort: true
  },
  {
    key: "apply_discount",
    name: "Apply Discount",
    format: (bool: boolean): JSX.Element => <FormatBool bool={bool} />,
    sort: true
  },
  {
    key: "discount_rate",
    name: "Discount Rate",
    default: "-"
  },
  {
    key: "collated",
    name: "Collated",
    format: (bool: boolean): JSX.Element => <FormatBool bool={bool} />,
    sort: true
  },
  {
    key: "description",
    name: "Description",
    sort: true
  },
  {
    key: "invoice_name",
    name: "Name On Invoice",
    default: "-"
  },
  {
    key: "more",
    name: "More"
  }
];

export default columns;

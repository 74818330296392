import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";

import {
  FETCH_PAYMENT_PROFILE_FAILURE,
  FETCH_PAYMENT_PROFILE_REQUEST,
  FETCH_PAYMENT_PROFILE_SUCCESS
} from "../types";

const fetchPaymentProfile = id => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    dispatch(
      createAction(FETCH_PAYMENT_PROFILE_REQUEST)({
        id
      })
    );
    const response = await api.factoring.getPaymentProfile(id);
    const data = normalize(response, schema.paymentProfile);
    dispatch(addEntities(data.entities));
    dispatch(createAction(FETCH_PAYMENT_PROFILE_SUCCESS)({ id }));
    return response;
  } catch (err) {
    dispatch(createAction(FETCH_PAYMENT_PROFILE_FAILURE)(err));
    throw err;
  }
};

export default fetchPaymentProfile;

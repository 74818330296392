import { assign } from "lodash";
import {
  FETCH_CONNECTED_GROUPS_FAILURE,
  FETCH_CONNECTED_GROUPS_SUCCESS,
  FETCH_CONNECTED_GROUPS_REQUEST
} from "../types";

export default (
  state = {
    connectedGroups: {
      loading: false,
      ids: []
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_CONNECTED_GROUPS_FAILURE:
      return {
        connectedGroups: assign({}, state.connectedGroups, {
          ids: [],
          loading: true
        })
      };
    case FETCH_CONNECTED_GROUPS_SUCCESS:
      return {
        ...state,
        connectedGroups: assign({}, state.connectedGroups, {
          loading: false,
          ids: action.payload.items,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        })
      };
    case FETCH_CONNECTED_GROUPS_REQUEST:
      return {
        connectedGroups: assign({}, state.connectedGroups, {
          loading: false
        })
      };
    default:
      return state;
  }
};

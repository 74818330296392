import React from 'react';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Switch,
  InputAdornment
} from '@material-ui/core';
import { Formik } from 'formik';
import SaveIcon from '@material-ui/icons/Save';

import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent
} from '../../../../../components/Dialog';
import RoundedButton from '../../../../../components/buttons/RoundedButton';
import Colors from '../../../../../theme/colors';
import { ERROR, SUCCESS } from '../../../../../helpers/Modals';
import CustomTextField from '../../../../../components/inputs/CustomTextField';

const appliedAgainst = [
  {
    value: 'client',
    label: 'Client'
  },
  {
    value: 'invited_carrier',
    label: 'Invited Carrier'
  },
  {
    value: 'debtor',
    label: 'Debtor'
  },
  {
    value: 'organization',
    label: 'ComFreight'
  },
  {
    value: 'proof',
    label: 'Proof'
  }
];

const appliedBy = [
  {
    value: 'client',
    label: 'Client'
  },
  {
    value: 'debtor',
    label: 'Debtor'
  },
  {
    value: 'organization',
    label: 'ComFreight'
  },
  {
    value: 'client_reserve_negative',
    label: "Take out of Client's Reserve"
  },
  {
    value: 'client_reserve_positive',
    label: "Add to Client's Reserve"
  }
];

const AppliedAgainstInput = ({ values, handleChange, handleBlur }) => (
  <CustomTextField
    fullWidth
    select
    label="Who's Charged"
    name="applied_against"
    id="applied_against"
    onChange={handleChange}
    onBlur={handleBlur}
    value={values.applied_against}
  >
    {appliedAgainst.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </CustomTextField>
);

const AppliedByInput = ({ values, handleChange, handleBlur }) => (
  <CustomTextField
    fullWidth
    select
    label="Who Charges"
    name="applied_by"
    id="applied_by"
    onChange={handleChange}
    onBlur={handleBlur}
    value={values.applied_by}
  >
    {appliedBy.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </CustomTextField>
);

const GridColumn = ({ children, numbers, ...props }) => (
  <Grid direction="column" item xs={numbers} {...props}>
    {children}
  </Grid>
);
export default ({
  open,
  handleClose,
  openModal,
  formAction = 'Add',
  values = {
    applied_against: '',
    applied_by: ''
  },
  createLineItemsCategories,
  updateLineItemsCategories
}) => (
  <Dialog open={open} maxWidth="sm">
    <DialogTitle onClose={handleClose}>{`${formAction} Line Item`}</DialogTitle>
    <Formik
      onSubmit={async (fields, formikActions) => {
        try {
          if (formAction === 'Add') {
            await createLineItemsCategories(fields);
          } else {
            await updateLineItemsCategories(fields);
          }
          handleClose();
          openModal(
            SUCCESS,
            `Debtor has been ${
              formAction === 'Add' ? 'created' : 'updated'
            } successfully.`
          );
        } catch (e) {
          openModal(
            ERROR,
            `Error while ${
              formAction === 'Add' ? 'creating' : 'updating'
            } debtor.`
          );
        }
        formikActions.setSubmitting(false);
      }}
      initialValues={values}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <React.Fragment>
          <DialogContent>
            <Grid
              container
              spacing={16}
              alignItems="center"
              style={{ padding: 10 }}
            >
              <GridColumn numbers={12}>
                <CustomTextField
                  fullWidth
                  name="name"
                  label="Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </GridColumn>
              <GridColumn numbers={6}>
                <AppliedAgainstInput
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </GridColumn>
              <GridColumn numbers={6}>
                <AppliedByInput
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </GridColumn>
              <GridColumn numbers={12}>
                <CustomTextField
                  fullWidth
                  name="description"
                  label="Description"
                  multiline
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
              </GridColumn>
              <GridColumn numbers={12}>
                <CustomTextField
                  fullWidth
                  type="number"
                  name="discount_rate"
                  label="Discount Rate"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    )
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.discount_rate}
                />
              </GridColumn>
              <GridColumn numbers={12}>
                <CustomTextField
                  fullWidth
                  name="invoice_name"
                  label="Name on Invoice"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.invoice_name}
                />
              </GridColumn>
              <GridColumn numbers={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.is_client_invoiced}
                        onChange={() => {
                          setFieldValue(
                            'is_client_invoiced',
                            !values.is_client_invoiced
                          );
                        }}
                        value="is_client_invoiced"
                      />
                    }
                    label="Is Client Invoiced"
                  />
                </FormGroup>
              </GridColumn>
              <GridColumn numbers={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.is_debtor_invoiced}
                        onChange={() => {
                          setFieldValue(
                            'is_debtor_invoiced',
                            !values.is_debtor_invoiced
                          );
                        }}
                        value="is_debtor_invoiced"
                      />
                    }
                    label="Is Debtor Invoiced"
                  />
                </FormGroup>
              </GridColumn>
              <GridColumn numbers={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.apply_discount}
                        onChange={() => {
                          setFieldValue(
                            'apply_discount',
                            !values.apply_discount
                          );
                        }}
                        value="apply_discount"
                      />
                    }
                    label="Apply Discount"
                  />
                </FormGroup>
              </GridColumn>
              <GridColumn numbers={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.collated}
                        onChange={() => {
                          setFieldValue('collated', !values.collated);
                        }}
                        value="collated"
                      />
                    }
                    label="Should Collated"
                  />
                </FormGroup>
              </GridColumn>
            </Grid>
          </DialogContent>
          <DialogActions>
            <RoundedButton
              variant="contained"
              color="primary"
              progressStyle={{ color: Colors.primary }}
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              <SaveIcon fontSize="small" />
              Save
            </RoundedButton>
          </DialogActions>
        </React.Fragment>
      )}
    </Formik>
  </Dialog>
);

import { UPDATE_INVITE_STEPS } from "../types";
import steps from "../steps";

const updateInviteSteps = (
  state,
  { type, payload: { includeCarrierIDCheck = true, isBoarding = false } }
) => {
  switch (type) {
    case UPDATE_INVITE_STEPS:
      return {
        ...state,
        steps: steps(includeCarrierIDCheck, isBoarding)
      };
    default:
      return {
        ...state
      };
  }
};

export default updateInviteSteps;

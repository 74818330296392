import * as Yup from "yup";

export const createFundingRequest = Yup.object().shape({
  amount: Yup.number()
    .moreThan(0, "Amount must be greater than $0")
    .required("Amount is a required field"),
  billToCompany: Yup.string().required("Bill to Company is a required field"),
  equipmentType: Yup.string().required("Equipment Type is a required field"),
  loadLength: Yup.string().required("Load Length is a required field")
});

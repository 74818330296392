import React, { useEffect, useState, useCallback } from "react";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import { isEmpty, get, omit, map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions
} from "../../../../../../../components/Dialog";
import Upload from "./Upload";
import FundingRequest from "./FundingRequest";
import LoadDetails from "./LoadDetails";
import steps from "./steps";
import {
  getAccounts,
  getUserId,
  fetchAccounts,
  getFactorClient,
  brokerCreateFR
} from "../../../../../../../modules/factoringClient";
import ImageCropper from "../../../../../../../components/ImageCropper";
import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../../../../modules/ui";
import API from "../../../../../../../api/factoring";
import colors from "../../../../../../../theme/colors";
import validationSchema from "./validations";
import { NO_DEBTOR_ID } from "../../../../../../../helpers";

const validateAttachments = attachments => {
  const tonu = attachments.filter(attachment =>
    get(attachment, "category", "").includes("Truck Order Not Used")
  );
  const rateConfirmation = attachments.filter(attachment =>
    get(attachment, "category", "").includes("Rate Confirmation")
  );

  const bolDelivered = attachments.filter(attachment =>
    get(attachment, "category", "").includes(
      "Signed Bill of Lading (Delivered)"
    )
  );
  const bolPickUp = attachments.filter(attachment =>
    get(attachment, "category", "").includes("Signed Bill of Lading (Pick-Up)")
  );

  return (
    tonu.length > 0 ||
    (rateConfirmation.length > 0 && bolDelivered.length > 0) ||
    bolPickUp.length > 0
  );
};

const validateAttachmentsMessage = attachments => {
  if (!validateAttachments(attachments)) {
    return (
      <div>
        {"Please make sure to add the following document:"}
        <br />
        {"- Rate confirmation"}
        <br />
        {"- Signed Bill of Lading (Delivered)"}
        <br />
        {"Or"}
        <br />
        {"- Signed Bill of Lading (Pick-Up)"}
      </div>
    );
  }
};

function FundingRequestDialog({ factoringId, form }) {
  const dialog = useSelector(getDialog);
  const [touchedNext, setTouchedNext] = useState(false);
  const [load, setLoad] = useState({});
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("load");
  const userId = useSelector(getUserId);
  const accounts = useSelector(getAccounts(userId));
  const dispatch = useDispatch();
  const factorClient = useSelector(getFactorClient);
  const handleExit = () => {
    dispatch(closeDialog());
  };

  const generateLoadNumber = get(
    factorClient,
    "auto_generate_load_numbers",
    false
  );

  const allowLineItemizedItem = get(
    factorClient,
    "allow_line_itemized_items",
    false
  );

  const selfFinanceFrLimit = get(factorClient, 'self_finance_fr_limit', 0);
  const selfFinanceFrRemainingBalance = get(factorClient, 'self_finance_fr_remaining_balance', 0);

  useEffect(() => {
    const f = async () => {
      dispatch(fetchAccounts(userId));
    };
    f();
  }, [userId]);

  const createLoad = useCallback(async values => {
    try {
      setLoading(true);
      const resp = await API.postBrokerLoad({
        origin_locations: [
          {
            name: get(values, ["firstPickUp", "formatted_address"]),
            street_one: get(values, ["firstPickUp", "street_one"]),
            city: get(values, ["firstPickUp", "city"]),
            state: get(values, ["firstPickUp", "state"]),
            country: get(values, ["firstPickUp", "country"])
          }
        ],
        destination_locations: [
          {
            name: get(values, ["finalDelivery", "formatted_address"]),
            street_one: get(values, ["finalDelivery", "street_one"]),
            city: get(values, ["finalDelivery", "city"]),
            state: get(values, ["finalDelivery", "state"]),
            country: get(values, ["finalDelivery", "country"])
          }
        ],
        trailerType: get(values, "equipmentType"),
        targetRate: get(values, "shipper_amount", 0),
        weight: Number(get(values, "loadWeight")),
        length: Number(get(values, "loadLength")),
        time_start: get(values, "pickUpOpen").toString(),
        time_end: get(values, "pickUpClose").toString(),
        time_delivery: get(values, "deliverBy").toString(),
        post_to: "all",
        groups_post_to: "private",
        user_load_number: get(values, "loadNumber"),
        factoring_id: get(factorClient, "id")
      });
      setLoad(resp);
      setLoading(false);
      dispatch(openSnackbar("success", "Load Created"));
    } catch (err) {
      setLoading(false);
      dispatch(openSnackbar("error", err));
      throw err;
    }
  }, []);

  return (
    <React.Fragment>
      <Dialog open={dialog.show} maxWidth="sm" fullWidth>
        <Formik
          enableReinitialize
          initialStatus="load"
          initialValues={{
            payment_method: "",
            nonFactored: false,
            attachments: [],
            carrier_amount: 0,
            loadWeight: "",
            shipper_amount: 0,
            debtor: {},
            carrier: {},
            loadNumber: get(load, "user_load_number", ""),
            clientNotes: "",
            firstPickUp: {
              formatted_address: ""
            },
            finalDelivery: {
              formatted_address: ""
            },
            equipmentType: "",
            loadLength: "",
            pickUpOpen: "",
            pickUpClose: "",
            deliverBy: "",
            broker_portion: "",
            payment_speed: "",
            billOutOnly: false,
            loadId: get(load, "id", ""),
            line_items: [],
            contract_type: 'STD_BROKER',
          }}
          validationSchema={() => validationSchema(generateLoadNumber, status)}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              let data = {
                attachments: values.attachments,
                amount: Math.round(Number(get(values, "shipper_amount")) * 100),
                debtor: get(values, ["debtor", "id"], NO_DEBTOR_ID),
                payment_profile_amount: Math.round(
                  Number(get(values, "carrier_amount")) * 100
                ),
                payment_profile_id: get(values, [
                  "carrier",
                  "payment_profile_id"
                ]),
                factoring_amount:
                  Math.round(Number(get(values, "shipper_amount")) * 100) -
                  Math.round(Number(get(values, "carrier_amount")) * 100),
                factoring_fee_split_rate: get(values, "broker_portion"),
                payout_days: `ach_${get(values, "payment_speed")}_day`,
                factoring_id: get(factorClient, "id"),
                carrier_pro_number: get(values, "carrierProNumber"),
                non_factored: values.nonFactored,
                bill_out_only: values.billOutOnly,
                line_items: map(values.line_items, ({ amount, ...props }) => ({
                  amount: Math.round(Number(amount) * 100),
                  ...props
                })),
                contract_type: values.contract_type,
                self_finance_requested: values.self_finance_requested,
              };
              if(['ach', 'wire', 'check'].includes(get(values, "payment_method"))) {
                data = {
                  ...data,
                  payment_profile_payment_method: get(values, "payment_method"),
                }
              } else {
                data = {
                  ...data,
                  factoring_company: get(values, "payment_method"),
                }
              }
              if (values.nonFactored && !values.billOutOnly) {
                data = {
                  ...omit(data, ["factoring_fee_split_rate", "payout_days"]),
                  payment_profile: values.carrier.payment_profile_id,
                  non_factored_type: "standard"
                };
              }
              if (values.billOutOnly) {
                data = {
                  ...omit(data, ["factoring_fee_split_rate", "payout_days"]),
                  non_factored_type: "bill_out"
                };
              }
              await dispatch(brokerCreateFR(values.loadId, data, factoringId));
              dispatch(openSnackbar("success", "Funding Request Created"));
              dispatch(closeDialog());
            } catch (err) {
              dispatch(openSnackbar("error", err));
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            validateForm,
            setTouched
          }) => (
            <React.Fragment>
              <DialogTitle onClose={() => handleExit()}>
                New Funding Request
              </DialogTitle>
              <DialogContent>
                {status === "load" && (
                  <LoadDetails
                    steps={steps}
                    factoringId={factoringId}
                    status={status}
                    errors={errors}
                    values={values}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    generateLoadNumbers={generateLoadNumber}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    handleSetStatus={async () => setStatus("request")}
                  />
                )}
                {status === "request" && (
                  <FundingRequest
                    steps={steps}
                    status={status}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    errors={errors}
                    touched={touched}
                    factoringId={factoringId}
                    accounts={accounts}
                    generateLoadNumbers={generateLoadNumber}
                    allowLineItemizedItem={allowLineItemizedItem}
                    selfFinanceFrLimit={selfFinanceFrLimit}
                    selfFinanceFrRemainingBalance={selfFinanceFrRemainingBalance}
                  />
                )}
                {status === "upload" && (
                  <Upload
                    steps={steps}
                    status={status}
                    files={values.attachments}
                    setFiles={attachments => {
                      setFieldValue("attachments", attachments);

                      const index = attachments.length - 1;

                      if (attachments[index].category === "") {
                        dispatch(
                          openDialog(
                            "factor-client-create-funding-request",
                            "",
                            "",
                            {
                              file: attachments[index],
                              save: file => {
                                const f = {
                                  ...file,
                                  filename: file.name
                                };
                                const attachment = {
                                  ...attachments[index],
                                  file: f
                                };
                              }
                            },
                            "picture-edit-tool"
                          )
                        );
                      }
                    }}
                    message={
                      values.attachments.length === 0 
                      || !touchedNext 
                      || values.contract_type === "SELF_FINANCE_NO_INVOICE"
                        ? ""
                        : validateAttachmentsMessage(values.attachments)
                    }
                  />
                )}
                {status === "summary" && (
                  <FundingRequest
                    disabled
                    steps={steps}
                    status={status}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                    errors={errors}
                    factoringId={factoringId}
                    generateLoadNumbers={generateLoadNumber}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    (status === "upload" && values.attachments.length === 0 && !values.contract_type === "SELF_FINANCE_NO_INVOICE") ||
                    (status === "request" &&
                      get(values, ["debtor", "credit_approved"], "") ===
                      "declined" &&
                      !values.nonFactored && !["SELF_FINANCE_NO_INVOICE", "SELF_FINANCE_WITH_INVOICE"].includes(values.contract_type)) 
                  }
                  onClick={async () => {
                    switch (status) {
                      case "load":
                        validateForm().then(async validation => {
                          if (isEmpty(validation)) {
                            try {
                              await createLoad(values);
                              setStatus("request");
                            } catch (err) { }
                          } else {
                            setTouched({
                              loadNumber: true,
                              loadLength: true,
                              equipmentType: true,
                              loadWeight: true,
                              firstPickUp: true,
                              finalDelivery: true,
                              pickUpOpen: true,
                              pickUpClose: true,
                              deliverBy: true
                            });
                          }
                        });
                        break;
                      case "request":
                        validateForm().then(validation => {
                          if (isEmpty(validation)) {
                            setStatus("upload");
                          } else {
                            setTouched({
                              amount: true,
                              billToCompany: true,
                              loadLength: true,
                              loadNumber: true,
                              firstPickUp: true,
                              finalDelivery: true,
                              equipmentType: true,
                              wire: true
                            });
                          }
                        });
                        break;
                      case "upload":
                        if (validateAttachments(values.attachments) || values.contract_type === "SELF_FINANCE_NO_INVOICE") {
                          setStatus("summary");
                        } else {
                          setTouchedNext(true);
                        }
                        break;
                      case "summary":
                        handleSubmit();
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  {status === "summary" ? "Submit" : "Next"}
                  {loading && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      size="sm"
                      spin
                      color={colors.green}
                      style={{ marginLeft: 5 }}
                    />
                  )}
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </Dialog>
      {dialog.action === "picture-edit-tool" && (
        <ImageCropper
          open={dialog.show}
          handleExit={() => {
            dispatch(openDialog(dialog.variant, "", "", {}, ""));
          }}
          data={dialog.data}
        />
      )}
    </React.Fragment>
  );
}

export default FundingRequestDialog;

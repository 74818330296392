import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { debounce } from "lodash";
import axios from "axios";
import { addEntities } from "../../entities";
import {
  FETCH_PAYMENT_PROFILE_PURCHASES_ERROR,
  FETCH_PAYMENT_PROFILE_PURCHASES_REQUEST,
  FETCH_PAYMENT_PROFILE_PURCHASES_SUCCESS
} from "../../payment/types";

const debounced = debounce(
  async (id, dispatch, schema, api, limit, filters) => {
    dispatch(
      createAction(FETCH_PAYMENT_PROFILE_PURCHASES_REQUEST)({
        id,
        limit,
        filters
      })
    );
    const response = await api.factorCompany.getFactoringCompanyPurchases(
      id,
      limit,
      filters
    );
    handleResponse(
      response.data,
      dispatch,
      id,
      limit,
      filters,
      schema.purchasesList
    );
    return response;
  },
  100,
  { trailing: true }
);

const fetchPurchases = (id, limit, filters = {}) => async (
  dispatch,
  getState,
  { api, schema }
) => {
  try {
    await debounced(id, dispatch, schema, api, limit, filters);
  } catch (err) {
    dispatch(createAction(FETCH_PAYMENT_PROFILE_PURCHASES_ERROR)(err));
    throw err;
  }
};

export const next = (id, link, limit, filters) => async (
  dispatch,
  getState,
  { schema }
) => {
  try {
    dispatch(
      createAction(FETCH_PAYMENT_PROFILE_PURCHASES_REQUEST)({
        limit,
        filters
      })
    );

    const response = await axios.get(link);
    handleResponse(
      response.data,
      dispatch,
      id,
      limit,
      filters,
      schema.purchasesList
    );
  } catch (err) {
    dispatch(createAction(FETCH_PAYMENT_PROFILE_PURCHASES_ERROR)(err));
    throw err;
  }
};

const handleResponse = (
  response,
  dispatch,
  id,
  limit,
  filters,
  purchasesList
) => {
  const data = normalize(response.results, [purchasesList]);
  dispatch(addEntities(data.entities));
  dispatch(
    createAction(FETCH_PAYMENT_PROFILE_PURCHASES_SUCCESS)({
      id,
      items: data.result,
      count: response.count,
      next: response.next,
      previous: response.previous,
      limit,
      filters
    })
  );
  return response;
};

export default fetchPurchases;

import { merge } from "lodash";
import {
  FETCH_SIMILAR_PAYMENT_PROFILE_FAILURE,
  FETCH_SIMILAR_PAYMENT_PROFILE_SUCCESS,
  FETCH_SIMILAR_PAYMENT_PROFILE_REQUEST
} from "../types";

const fetchSimilarPaymentProfile = (state, { type, payload }) => {
  switch (type) {
    case FETCH_SIMILAR_PAYMENT_PROFILE_REQUEST:
      return {
        ...state,
        similarPaymentProfile: {
          loading: true
        }
      };
    case FETCH_SIMILAR_PAYMENT_PROFILE_SUCCESS:
      return {
        ...state,
        similarPaymentProfile: {
          id: payload.id,
          items: payload.items,
          count: payload.count,
          next: payload.next,
          previous: payload.previous,
          loading: false
        }
      };
    case FETCH_SIMILAR_PAYMENT_PROFILE_FAILURE:
      return {
        ...state,
        similarPaymentProfile: {
          loading: false
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default fetchSimilarPaymentProfile;

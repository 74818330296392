import { get } from "lodash";
import { denormalize } from "normalizr";

import { fundingRequest } from "../../../api/schema";

export default state => {
  try {
    const fundingRequests = get(
      state,
      ["payment", "fundingRequests", "ids"],
      []
    );

    const denormalized = denormalize(
      { fundingRequests },
      { fundingRequests: [fundingRequest] },
      state.entities
    );
    return denormalized.fundingRequests;
  } catch (err) {
    return [];
  }
};

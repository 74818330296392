import React, { useState, ReactNode } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { InputProps as InputProp } from "@material-ui/core/Input";
import {
  StandardTextFieldProps,
  OutlinedTextFieldProps
} from "@material-ui/core/TextField";

interface Props {
  children?: ReactNode;
  InputProps?: InputProp;
  showEndPasswordIcon?: boolean;
  showStartPasswordIcon?: boolean;
  PasswordIcon?: JSX.Element;
  label?: string;
  placeholder?: string;
}

function PasswordInput({
  children,
  InputProps,
  showEndPasswordIcon = false,
  showStartPasswordIcon = false,
  PasswordIcon = <LockIcon />,
  label,
  placeholder,
  ...props
}: Props & (StandardTextFieldProps | OutlinedTextFieldProps)): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      InputProps={{
        endAdornment: showEndPasswordIcon ? (
          <IconButton
            aria-label="Toggle password visibility"
            onClick={(): void => setShowPassword(!showPassword)}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ) : null,
        startAdornment: showStartPasswordIcon ? (
          <InputAdornment position="start">{PasswordIcon}</InputAdornment>
        ) : null,
        ...InputProps
      }}
      label={label || "Password"}
      type={showPassword ? "text" : "password"}
      placeholder={placeholder || "Password"}
      autoComplete="current-password"
      {...props}
    >
      {children}
    </TextField>
  );
}

export default PasswordInput;

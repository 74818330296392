import { get } from "lodash";

const getNewFiles = (state, type) => {
  let files = get(state, ["upload", "newFiles"]);

  try {
    if ((type === "NOA" || type === "LOR") && files) {
      files = files.filter(file => file.category === type);
    }
  } catch (e) {
    files = get(state, ["upload", "newFiles"], []);
  }
  return files;
};

export default getNewFiles;

import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import { lifecycle } from "recompose";
import { Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import isEqual from "lodash/isEqual";

import colors from "../../../theme/colors";
import SnackbarContentWrapper from "../../../components/Snackbar";
import ContinueButton from "../../../components/buttons/ContinueButton";
import CarrierInfo from "./CarrierInfo";
import InvitedValidationSchema from "./validation";

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2
  }
});

const Heading = () => (
  <Typography
    align="center"
    variant="body1"
    style={{
      marginTop: 30,
      marginBottom: 30,
      fontWeight: 400,
      color: colors.gray
    }}
  >
    Please make sure to confirm all of the following information
  </Typography>
);

const Confirm = ({
  accountAction,
  carrierForRef = {},
  factoringLoading,
  errorMsg,
  classes,
  onSubmit,
  initialValues,
  setPassword,
  clearError,
  isSmsInvite,
  email,
  customToken,
  token,
  groupId
}) => (
  <Grid container direction="column">
    <Heading />
    <Grid
      item
      xs={10}
      sm={9}
      md={8}
      style={{ alignSelf: "center", width: "100%" }}
    >
      <Grid
        item
        container
        xs="12"
        style={{ justifyContent: "center", width: "100%" }}
      >
        {errorMsg.display && errorMsg.message && (
          <SnackbarContentWrapper
            className={classes.margin}
            variant="error"
            message={errorMsg.message}
            onClick={clearError}
          />
        )}
      </Grid>
      <Formik
        innerRef={ref => (carrierForRef = ref)}
        initialValues={initialValues}
        validationSchema={() =>
          InvitedValidationSchema(
            accountAction !== "new",
            isSmsInvite || customToken,
            customToken ? token : "",
            groupId,
            customToken
          )
        }
        render={props => (
          <CarrierInfo
            {...props}
            setPassword={setPassword}
            isSmsInvite={isSmsInvite}
            email={email}
          />
        )}
        onSubmit={onSubmit}
      />
      <Grid container direction="row-reverse" alignContent="flex-start" xs={12}>
        <ContinueButton
          disabled={factoringLoading}
          onClick={() => {
            carrierForRef.submitForm();
          }}
        />
      </Grid>
    </Grid>
  </Grid>
);

Confirm.propTypes = {
  factoringError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  factoringProfileSuccess: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired
};
const getStepByNumber = {
  1: "confirm",
  2: "bank",
  3: "attach"
};
export default compose(
  withStyles(styles),
  lifecycle({
    componentWillMount() {
      const {
        accountAction,
        token,
        history: { push },
        isLoggedIn,
        invitePayload: { id, step },
        currentUserId,
        moveToStepByName,
        customToken
      } = this.props;

      if (accountAction === "update" && (!isLoggedIn || currentUserId !== id)) {
        return push(`/login/?redirect=/invited/${token}`);
      }
      if (isLoggedIn && customToken && step > 1 && step <= 3) {
        return moveToStepByName(getStepByNumber[step]);
      }
      if (isLoggedIn && currentUserId === id && step > 1 && step <= 3) {
        return moveToStepByName(getStepByNumber[step]);
      }
      if (isLoggedIn && currentUserId === id && step > 3) {
        return moveToStepByName("summary");
      }
    }
  })
)(Confirm);

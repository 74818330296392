import { assign } from "lodash";
import {
    FETCH_WALLET_HISTORY_FAILURE,
    FETCH_WALLET_HISTORY_SUCCESS,
    FETCH_WALLET_HISTORY_REQUEST
} from "../types";

export default (
    state = {
        walletTransactions: {
            loading: false,
            ids: []
        }
    },
    action
) => {
    switch (action.type) {
        case FETCH_WALLET_HISTORY_REQUEST:
            return {
                walletTransactions: assign({}, state.walletTransactions, {
                    ids: [],
                    loading: true,
                    ordering: action.payload.ordering,
                    limit: action.payload.limit,
                    filters: action.payload.filters,
                    userId: action.payload.userId,
                    factoringId: action.payload.factoringId,
                })
            };
        case FETCH_WALLET_HISTORY_SUCCESS:
            return {
                ...state,
                walletTransactions: assign({}, state.walletTransactions, {
                    loading: false,
                    ids: action.payload.items,
                    count: action.payload.count,
                    next: action.payload.next,
                    previous: action.payload.previous,
                    ordering: action.payload.ordering,
                    limit: action.payload.limit,
                    filters: action.payload.filters,
                })
            };
        case FETCH_WALLET_HISTORY_FAILURE:
            return {
                walletTransactions: assign({}, state.walletTransactions, {
                    loading: false
                })
            };
        default:
            return state;
    }
};

import { denormalize } from "normalizr";
import { get } from "lodash";
import { notes } from "../../../api/schema";

const getNotesTimeline = state => {
  try {
    const id = get(state, "factoringClient.id", "");
    const noteIds = get(state, `factoringClient.notes.${id}`, []);

    const denormalized = denormalize(
      { notes: noteIds },
      { notes: [notes] },
      state.entities
    );

    return denormalized.notes;
  } catch (err) {
    return [];
  }
};

export default getNotesTimeline;

import { assign, concat, remove } from "lodash";
import {
  FETCH_TMS_FAILURE,
  FETCH_TMS_REQUEST,
  FETCH_TMS_SUCCESS,
  ADD_TMS_REQUEST,
  DELETE_TMS_REQUEST
} from "../types";

const tms = (state, { type, payload }) => {
  switch (type) {
    case FETCH_TMS_REQUEST:
      return {
        ...state,
        tms: {
          ids: {},
          loading: false
        }
      };
    case FETCH_TMS_SUCCESS:
      return {
        ...state,
        tms: assign({}, state.tms, {
          ids: payload.items,
          loading: false
        })
      };
    case ADD_TMS_REQUEST:
      return {
        ...state,
        tms: assign({}, state.tms, {
          ids: concat(payload.items, state.tms.ids)
        })
      };
    case DELETE_TMS_REQUEST:
      const ids = remove([...state.tms.ids], object => object !== payload.id);
      return {
        ...state,
        tms: assign({}, state.tms, {
          ids
        })
      };
    case FETCH_TMS_FAILURE:
      return {
        ...state,
        tms: {
          ids: {},
          loading: false
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default tms;

import React, { useEffect } from 'react';
import { Button, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { get, head, isEqual } from 'lodash';

import SwipeableViews from 'react-swipeable-views';
import SaveButton from '../../../../components/buttons/SaveButton';
import tabs from '../tabs';
import InternalLayout from '../../../../components/layouts/InternalLayout';
import NavigationTabs from '../../../../components/NavigationTabs';
import CompanyCard from './components/CompanyCard';
import PaymentCard from './components/PaymentCard';
import ApprovalStatusCard from './components/ApprovalStatusCard';
import {
  fetchFactoringCompany,
  getFactoringCompany,
  updateFactoringCompany,
  deleteFactoringCompany
} from '../../../../modules/groups';
import {
  getAccounts,
  fetchAccounts,
  createBankAccount
} from '../../../../modules/factoringClient';
import colors from '../../../../theme/colors';
import {
  openDialog,
  openSnackbar,
  closeDialog,
  getDialog
} from '../../../../modules/ui';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import Notes from '../../../../components/Notes';
import {
  fetchFactorCompanyNotes,
  getNotes,
  createFactorCompanyNote,
  updateFactorCompanyNote,
  deleteFactorCompanyNote,
  fetchHistory
} from '../../../../modules/factorCompany';
import AuditTrail from '../../../../components/AuditTrail';
import EditPaymentMethodDialog from '../../FactorClient/Profile/components/dialogs/EditPaymentMethodDialog';

export default ({
  history: { push },
  match: {
    params: { id }
  },
  handleChange: handleTabChange,
  handleChangeIndex: handleTabChangeIndex,
  index
}) => {
  const dispatch = useDispatch();
  const [history, setHistory] = React.useState([]);

  const handleEditPayments = () => {
    dispatch(openDialog("edit-payment"));
  }
  useEffect(() => {
    if (id) {
      dispatch(fetchFactoringCompany(id));
      dispatch(fetchFactorCompanyNotes(id));
    }
  }, [id]);

  useEffect(() => {
    const f = async () => {
      if (id) {
        const his = await dispatch(fetchHistory(id));
        setHistory(his);
      }
    };

    f();
  }, [id]);

  const dialog = useSelector(getDialog);
  const factoringCompany = useSelector(getFactoringCompany(id));
  const notes = useSelector(getNotes(id));

  const userId = get(factoringCompany, 'user');
  useEffect(() => {
    if (userId) {
      dispatch(fetchAccounts(userId));
    }
  }, [userId]);

  const accounts = useSelector(getAccounts(userId)) || [];
  const account = get(accounts, 0, {});

  const redacted = get(account, 'account_number_redacted');

  return (
    <InternalLayout title="Factoring Company">
      <Formik
        enableReinitialize
        initialValues={{
          accountId: get(account, 'id'),
          accountNumber: redacted ? `*****${redacted}` : '',
          routingNumber: get(account, 'routing_number', ''),
          accountType: get(account, 'type', ''),

          status: get(factoringCompany, 'status', ''),
          contactName: get(factoringCompany, 'contact_name', ''),
          email: get(factoringCompany, 'email', ''),
          phoneNumber: get(factoringCompany, 'phone_number', ''),
          businessName: get(factoringCompany, 'name', ''),
          city: get(factoringCompany, 'city', ''),
          street_one: get(factoringCompany, 'street_one', ''),
          street_two: get(factoringCompany, 'street_two', ''),
          state: get(factoringCompany, 'state', ''),
          zip: get(factoringCompany, 'zip_code', ''),
          remittanceEmail: get(factoringCompany, 'remittance_email', ''),
          noaRequestEmail: get(factoringCompany, 'noa_request_email', ''),
          uccLorRequestEmail: get(factoringCompany, 'ucc_lor_request_email', ''),
          enablePayViaCheck: get(factoringCompany, 'enable_pay_via_check', false),
          mailingAddressOne: get(factoringCompany, 'mailing_address.street_one', ''),
          mailingAddressTwo: get(factoringCompany, 'mailing_address.street_two', ''),
          mailingAddressState: get(factoringCompany, 'mailing_address.state', ''), 
          mailingAddressCity: get(factoringCompany, 'mailing_address.city', ''),
          mailingAddressZip: get(factoringCompany, 'mailing_address.zip', ''),
          mailingAddressCountry: get(factoringCompany, 'mailing_address.country', ''),
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            if (
              !isEqual(values.accountNumber, `*****${redacted}`) ||
              !isEqual(values.routingNumber, account.routing_number) ||
              !isEqual(values.accountType, account.type)
            ) {
              if (
                values.accountNumber &&
                values.routingNumber &&
                values.accountType
              ) {
                await dispatch(
                  createBankAccount(
                    values.accountNumber,
                    `${values.accountNumber.slice(
                      values.accountNumber.length - 4,
                      values.accountNumber.length
                    )}`,
                    true,
                    values.routingNumber,
                    values.accountType,
                    userId
                  )
                );
              }
            }

            await dispatch(
              updateFactoringCompany(id, {
                status: values.status,
                contact_name: values.contactName,
                email: values.email,
                phone_number: values.phoneNumber,
                name: values.businessName,
                street_one: values.street_one,
                street_two: values.street_two,
                city: values.city,
                state: values.state,
                zip_code: values.zip,
                remittance_email: values.remittanceEmail,
                noa_request_email: values.noaRequestEmail,
                ucc_lor_request_email: values.uccLorRequestEmail,
                enable_pay_via_check: values.enablePayViaCheck,
                mailing_address: {
                  street_one: values.mailingAddressOne,
                  street_two: values.mailingAddressTwo,
                  city: values.mailingAddressCity,
                  state: values.mailingAddressState,
                  zip: values.mailingAddressZip,
                  country: values.mailingAddressCountry
                },
              })
            );

            dispatch(
              openSnackbar(
                'success',
                'Saved Factor Company Profile and Account!'
              )
            );
          } catch (err) {
            dispatch(openSnackbar('error', head(err)));
          } finally {
            resetForm();
            setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, handleSubmit, handleChange, setFieldValue, handleBlur }) => (
          <Grid container direction="column" spacing={16}>
            <Grid item style={{ width: '100%' }}>
              <Grid container style={{ width: '100%' }} justify="space-between">
                <Grid item>
                  <Typography variant="h6">
                    {get(factoringCompany, 'name', '')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{
                          boxShadow:
                            "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
                          background: colors.info,
                          color: colors.white,
                          textTransform: "none",
                          marginRight: 10
                        }}
                        onClick={handleEditPayments}
                      >
                        <Typography style={{ color: colors.white }}>
                          Edit Payment Method
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: colors.danger,
                          marginRight: 10
                        }}
                        onClick={() =>
                          dispatch(
                            openDialog(
                              'confirm_delete_factor_company',
                              'Confirm Delete Factor Company',
                              'Are you sure you want to delete this Factor Company?',
                              {
                                id
                              }
                            )
                          )
                        }
                      >
                        <Typography style={{ color: colors.white }}>
                          Delete
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <SaveButton
                        disabled={isSubmitting}
                        onClick={() => handleSubmit()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <NavigationTabs tabs={tabs(id)} value={0} />
            </Grid>
            <Grid item>
              <Grid direction="row" container spacing={16}>
                <Grid item xs={4}>
                  <Grid container direction="column" spacing={16}>
                    <Grid item>
                      <ApprovalStatusCard
                        status={values.status}
                        handleChange={handleChange}
                      />
                    </Grid>
                    <Grid item>
                      <CompanyCard {...values} handleChange={handleChange} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <PaymentCard {...values} handleChange={handleChange} setFieldValue={setFieldValue} handleBlur={handleBlur}  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Paper>
                <Tabs
                  value={index}
                  onChange={handleTabChange}
                  variant="fullWidth"
                >
                  <Tab label="Notes" />
                  <Tab label="Audit Log" />
                </Tabs>
                <SwipeableViews
                  axis="x"
                  index={index}
                  onChangeIndex={handleTabChangeIndex}
                >
                  <Grid
                    container
                    justify="center"
                    style={{
                      padding: '32px'
                    }}
                  >
                    <Grid item lg={11}>
                      <Formik
                        enableReinitialize
                        initialStatus="CREATE"
                        initialValues={{
                          note: '',
                          attachments: '',
                          notesID: ''
                        }}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          try {
                            if (values.notesID !== '') {
                              await dispatch(
                                updateFactorCompanyNote(
                                  id,
                                  values.notesID,
                                  values.note,
                                  values.attachments
                                )
                              );
                              dispatch(
                                openSnackbar('success', 'Updated Note!')
                              );
                            } else {
                              await dispatch(
                                createFactorCompanyNote(
                                  id,
                                  values.note,
                                  values.attachments
                                )
                              );
                              dispatch(
                                openSnackbar('success', 'Created Note!')
                              );
                            }
                          } catch (err) {
                            dispatch(openSnackbar('error', err.message || err));
                          } finally {
                            setSubmitting(false);
                            resetForm();
                          }
                        }}
                      >
                        {({
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                          status,
                          setStatus,
                          isSubmitting
                        }) => {
                          return (
                            <Notes
                              values={values}
                              errors={errors}
                              touched={touched}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              handleSubmit={handleSubmit}
                              isCreatingNote={isSubmitting}
                              notes={notes}
                              isFetching={isSubmitting}
                              loadNotesAttachments={() => {}}
                              notesAttachments={[]}
                              isUpdatingNote={isSubmitting}
                              handleDeleteNote={noteId => {
                                dispatch(deleteFactorCompanyNote(id, noteId));
                              }}
                              status={status}
                              setStatus={setStatus}
                            />
                          );
                        }}
                      </Formik>
                    </Grid>
                  </Grid>
                  <AuditTrail auditLog={history} />
                </SwipeableViews>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Formik>
      {dialog.variant === 'confirm_delete_factor_company' && (
        <ConfirmDialog
          open={dialog.show}
          message={dialog.message}
          title={dialog.title}
          handleConfirm={async () => {
            try {
              await dispatch(deleteFactoringCompany(id));
              await dispatch(closeDialog());
              push('/admin/companies/approved');
            } catch (err) {
              dispatch(openSnackbar('error', err.message || err));
            }
            await dispatch(closeDialog());
          }}
          handleExit={() => dispatch(closeDialog())}
        />
      )}
      {dialog.variant === 'edit-payment' && (
        <EditPaymentMethodDialog userId={userId} user={factoringCompany} updateProfile={values => dispatch(updateFactoringCompany(id, values))} />
      )}
    </InternalLayout>
  );
};

import axios, { AxiosRequestConfig } from "axios";

const exchangeToken = async (
  token: string,
  accounts: string[]
): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "POST",
    data: {
      public_token: token,
      account_ids: accounts
    },
    url: `/plaid/exchange_token/`
  };
  const response = await axios(options);
  return response.data;
};

const getUserPlaid = async (userId: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    params: {
      user_id: userId
    },
    url: `/plaid/exchange_token/`
  };
  const response = await axios(options);
  return response.data;
};

export default {
  exchangeToken,
  getUserPlaid
};

import { createAction } from "redux-starter-kit";
import { normalize } from "normalizr";
import { addEntities } from "../../entities";
import { CREATE_LINEITEM_SUCCESS } from "../types";

export default (itemId, fundingRequest, description, amount) => async (dispatch, getState, { api, schema }) => {
    try {
        const items = await api.factoring.reversalLineItems(itemId, fundingRequest, description, amount);
        const data = normalize(items, schema.lineItem);
        dispatch(addEntities(data.entities));
        dispatch(
            createAction(CREATE_LINEITEM_SUCCESS)({
                fundingRequest,
                lineItem: data.result
            })
        );
        return items;
    } catch (err) {
        throw err;
    }
};

import { get, memoize } from "lodash";
import { denormalize } from "normalizr";
import { emailTemplates as emailTemplatesShema } from "../../../api/schema";

export default fundingRequestId =>
  memoize(state => {
    try {
      const emailTemplates = get(
        state,
        ["factoring", "emailTemplates", fundingRequestId],
        ""
      );
      const denormalized = denormalize(
        { emailTemplates },
        { emailTemplates: [emailTemplatesShema] },
        state.entities
      );
      return denormalized.emailTemplates;
    } catch (err) {
      return [];
    }
  });

export const FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_REQUEST =
  "FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_REQUEST";
export const FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_SUCCESS =
  "FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_SUCCESS";
export const FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_FAILURE =
  "FETCH_FACTORING_COMPANY_CONNECTED_CLIENTS_FAILURE";

export const FETCH_NOTES_SUCCESS = "FACTORCOMPANY/FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_REQUEST = "FACTORCOMPANY/FETCH_NOTES_REQUEST";
export const FETCH_NOTES_FAILURE = "FACTORCOMPANY/FETCH_NOTES_FAILURE";

export const CREATE_NOTE_SUCCESS = "FACTORCOMPANY/CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_REQUEST = "FACTORCOMPANY/CREATE_NOTE_REQUEST";
export const CREATE_NOTE_FAILURE = "FACTORCOMPANY/CREATE_NOTE_FAILURE";

export const UPDATE_NOTE_SUCCESS = "FACTORCOMPANY/UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_REQUEST = "FACTORCOMPANY/UPDATE_NOTE_REQUEST";
export const UPDATE_NOTE_FAILURE = "FACTORCOMPANY/UPDATE_NOTE_FAILURE";

export const DELETE_NOTE_SUCCESS = "FACTORCOMPANY/DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_REQUEST = "FACTORCOMPANY/DELETE_NOTE_REQUEST";
export const DELETE_NOTE_FAILURE = "FACTORCOMPANY/DELETE_NOTE_FAILURE";

export const FETCH_HISTORY_SUCCESS = "FACTORCOMPANY/FETCH_HISTORY_SUCCESS";
export const FETCH_HISTORY_REQUEST = "FACTORCOMPANY/FETCH_HISTORY_REQUEST";
export const FETCH_HISTORY_FAILURE = "FACTORCOMPANY/FETCH_HISTORY_FAILURE";

import React from 'react';
import toUpper from "lodash/toUpper";
import formatPennies from "../../../../helpers/format/formatPennies";
import formatDate, { formatDayMonthYear } from "../../../../helpers/format/formatDate"
import { Tooltip, Typography } from "@material-ui/core"

const columns = [
  {
    key: "debtor",
    name: "Debtor",
    filter: "text",
    filter_key: "debtor_name"
  },
  {
    key: "sch",
    name: "Check/ACH #",
    filter: "text",
    filter_key: "sch"
  },
  {
    key: "posting_date",
    name: "Posted At",
    filter_key: "posting_date",
    filter: "date",
    filter_format: "toDateTime",
    format: formatDayMonthYear
  },
  {
    key: "created",
    name: "Created At",
    filter_key: "created",
    filter: "date",
    filter_format: "toDateTime",
    format: formatDate
  },
  {
    key: "category",
    name: "Type",
    format: toUpper
  },
  {
    key: "amount",
    name: "Amount",
    format: formatPennies,
    filter_key: "amount",
    filter: "text",
  },
  {
    key: "notes",
    name: "Notes"
  },
  {
    key: "changed_by_name",
    name: "Applied By"
  },
  {
    key: "bank_description",
    name: "Bank Description",
    format: (value: string): JSX.Element => (
      <Tooltip title={value}>
        <Typography
          variant="body1"
        >
          {(value || "").length > 10 ? value.slice(0, 10) + "..." : value}
        </Typography>
      </Tooltip>
    )
  },
  {
    key: "more",
    name: "More"
  }
];

export default columns;

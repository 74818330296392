import { useEffect, useRef } from "react";
import { shallowEqual } from "recompose";

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useHasChanged = (val: any) => {
  const prevVal = usePrevious(val) || {};
  return !shallowEqual(prevVal, val);
};

export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(function setIsMounted() {
    isMounted.current = true;

    return function cleanupSetIsMounted() {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

export const useUpdateEffect = (effect: Function, dependencies: any[]) => {
  const isMounted = useIsMounted();
  const isInitialMount = useRef(true);

  useEffect(() => {
    let effectCleanupFunc = function noop() { };

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effectCleanupFunc = effect() || effectCleanupFunc;
    }
    return () => {
      effectCleanupFunc();
      if (!isMounted.current) {
        isInitialMount.current = true;
      }
    };
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};


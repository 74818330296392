import { denormalize } from "normalizr";
import { creditReport } from "../../../api/schema";

const getCreditReport = state => {
  try {
    const items = state.debtor.credit.ids;

    const denormalized = denormalize(
      { items },
      { items: [creditReport] },
      state.entities
    );

    return denormalized.items;
  } catch (err) {
    return [];
  }
};

export default getCreditReport;

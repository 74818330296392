import deleteFile from "./deleteFile";
import getUserById from "./getUserById";
import { closeDialog } from "../../ui";

const handleConfirmDeleteUserFile = () => async (
  dispatch,
  getState,
  { api }
) => {
  const state = getState();
  const dialog = state.ui.dialog;
  const profile = state.user.selectedUser?.data;
  await dispatch(deleteFile(dialog?.data?.fileId));
  await dispatch(getUserById(profile?.id));
  dispatch(closeDialog());
};

export default handleConfirmDeleteUserFile;

import axios, { AxiosPromise } from "axios";
import { keys } from "lodash";

interface AttachmentUpload {
  file: Blob;
  upload_url: {
    url: string;
    fields: {
      [index: string]: string;
    };
  };
}

const awsImagesUpload = (attachments: AttachmentUpload[]): Promise<any[]> => {
  const instance = axios.create();
  return Promise.all(
    attachments.map(
      ({ file, upload_url: { url, fields } }): AxiosPromise<any> => {
        const formData = new FormData();
        keys(fields).map((key: string): void => {
          formData.append(key, fields[key]);
        });
        formData.append("file", file);
        return instance.post(url, formData);
      }
    )
  );
};

export default awsImagesUpload;

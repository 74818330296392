import React from "react";
import { concat } from "lodash";

import AdminDropDown from "../../../components/AdminDropDown";
import formatPhone from "../../../helpers/format/formatPhone";
import HaulPayStatus from "./components/HaulPayStatus";
import formatStatus from "../../../helpers/format/formatStatus";
import Source from "../../../components/icons/SourceIcon";
import formatDate from "../../../helpers/format/formatDate";
import splitAndCase from "../../../helpers/format/splitAndCase";
import AdminAvatarChip from "../../../components/AdminAvatarChip";
import userTypeArray from "../../../helpers/userTypeArray";
import { Select } from "../../../components/inputs/BoldInput";
import NameFormat from "../../../components/LanguageFormat";

const columns = [
  {
    key: ["contact_name", "language"],
    name: "Name",
    format: (name: string, language: string): JSX.Element => (
      <NameFormat name={name} language={language} />
    ),
    filter: "text",
    filter_key: "contact_name",
  },
  {
    key: "email",
    name: "Email",
    filter: "text",
    filter_key: "email",
  },
  ,
  {
    key: "dba",
    name: "DBA",
    filter: "text",
    filter_key: "dba",
  },
  {
    key: "assigned_admin_name",
    name: "Assigned",
    format: (name: string): JSX.Element => <AdminAvatarChip name={name} />,
    filter: "component",
    filter_value: "",
    filter_component: AdminDropDown,
    filter_key: "assigned_admin",
  },
  {
    key: "user_type",
    name: "User Type",
    format: splitAndCase,
    filter: "component",
    filter_value: "",
    filter_key: "user_type",
    filter_component: ({
      onChange,
      value,
      name,
    }: {
      onChange: Function;
      value: string;
      name: string;
    }): JSX.Element => {
      return (
        <Select
          value={value || ""}
          onChange={onChange}
          fullWidth
          placeholder="User Type"
          name={name}
          selections={userTypeArray.map((option: any): any => ({
            value: option.value,
            text: option.label,
          }))}
        />
      );
    },
  },
  {
    key: "factoring.factoring_approved",
    name: "HP Status",
    filter: "dropdown",
    filter_key: "hp_status",
    filter_value: "",
    colSpan: 2,
    filter_values: [
      {
        value: "",
        label: "All",
      },
      {
        value: "pending",
        label: "Pending",
      },
      {
        value: "approved",
        label: "Approved",
      },
      {
        value: "declined",
        label: "Declined",
      },
    ],
    format: (value: string): JSX.Element => (
      <HaulPayStatus status={formatStatus(value)} />
    ),
  },
  {
    key: "state",
    name: "State",
    filter: "text",
    filter_key: "state",
  },
  {
    key: "mc",
    name: "MC",
    filter: "text",
    filter_key: "mc",
  },
  {
    key: "dot",
    name: "DOT",
    filter: "text",
    filter_key: "dot",
  },
  {
    key: "contact_phone",
    name: "Phone",
    filter: "text",
    filter_key: "contact_phone",
    format: (value: string): string =>
      value
        .split(";")
        .map(formatPhone)
        .join("\n"),
  },
  {
    key: "source",
    name: "Source",
    format: (value: string): JSX.Element => <Source source={value} />,
  },
  {
    key: "created",
    name: "Created At",
    sort: true,
    colSpan: 2,
    format: formatDate,
  },
  {
    key: "last_login",
    name: "Last Login",
    colSpan: 2,
    format: formatDate,
  },
  {
    key: "more",
    name: "More",
  },
];

export default columns;
